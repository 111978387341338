import CollectionView from 'base/collection-view';
import UiListItem from 'components/ui-list/item';
import Collection from 'base/collection';


const ExistBatchView = UiListItem.extend({
	text: m => m.get('idString'),
	onTextClick () {
		this.trigger('clicked', this.model.get('idString'));
	}
});

const ExistBatchEmptyView = UiListItem.extend({
	text: 'С такими компонентами нет ни одной партии'
});

export const ExistsBatchesView = CollectionView.extend({
	initialize () {
		this.collection = new Collection(this.getOption('data') || []);
	},
	childView: ExistBatchView,
	emptyView: ExistBatchEmptyView,
	childViewTriggers: {
		clicked: 'clicked'
	}
});

