// import { ProductListItemView as Base } from 'mod/productionProducts/views';
// import { Ui2AtomView } from '../../../../../../components/ui2/atom';
import { UiMoleculeView } from 'components/ui2/molecule';

export const CycleListItemView = UiMoleculeView.extend({
	openCardButton: v => `res/prod/products/${v.model.get('productId')}`,
	content: {
		showSmall: true,
		text: v => v.model.get('productName'),
		smallText: v => v.model.get('cycleName')
	}
});
