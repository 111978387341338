import CollectionView from 'base/collection-view';
import childView from 'mod/departments/views/list-item';
import departments from 'mod/departments/singleton';

export default CollectionView.extend({
	tagName: 'ul',
	emptyView: childView.Empty,
	childView: childView.extend({ tagName: 'li' }),
	collection: departments,
	viewFilter (v) {
		return v.model.get('parentId') === this.model.id;
	}
});
