import { _ } from 'vendors';
import BaseView from 'pages/view';

// import ModelProperties from 'components/ui-model-card/properties';
// import h from 'components/ui-model-card/helpers';


// import createNestedContragent from 'mod/contragents/views/nested/create';

// import ToDoBlock from 'mod/tasks/views/todo';
// import createLogs from 'components/logs/views/create-nested';


import mix from 'helpers/mix';
// import ViewSocketsHandler from 'components/sockets/view-handler-mixin';

import SocketEntityEvents from 'components/sockets/entity-events-mixin';
import { FileService, selectFiles } from 'components/files';


import { initializeProductionLayout } from './production';
import { initializeBuyLayout } from './buy';
import { initializeCommonLayout } from './common-blocks';


export default mix(BaseView).with(SocketEntityEvents).extend({
	className: 'process-page common',
	template: BaseView.emptyTemplate,
	modelEvents: {
		'open:selectFilesToUpload' () {
			selectFiles(blobs => this.fileSvc.addBlobs(blobs));
		}
	},
	initialize () {
		this.fileSvc = new FileService({
			shouldUseNotifier: true,
			shouldUseUploadOnNewFiles: true,
			uploadOptions: {
				url: _.result(this.model, 'url') + '/upload'
			}
		});
		this.on('destroy', () => this.fileSvc.destroy());
		this.on('render', () => this.fileSvc.start());


		switch (this.model.get('type')) {
		case 'buy': return initializeBuyLayout(this);
		case 'productionLine': return initializeProductionLayout(this);
		default: return initializeCommonLayout(this);
		}
	},
	onRender () {
		this.showAllNested();
	},
	socketEntityEvents: {
		'process:changed' (ev) {
			const hash = _.omit(ev.data, 'team', 'tasks');
			this.model.set(hash);
		},
		'process:completed' (ev) {
			const hash = _.pick(ev.data, 'completeState', 'completeText', 'modified');
			this.model.set(hash);
		},
		'process:restarted' (ev) {
			const hash = _.pick(ev.data, 'completeState', 'completeText', 'modified');
			this.model.set(hash);
		},
		'process:canceled' (ev) {
			const hash = _.pick(ev.data, 'completeState', 'completeText', 'modified');
			this.model.set(hash);
		}

	},
	socketEvents: {
		'task' (event, type) {
			const tasks = this.model.getTasks();
			const task = tasks.get(event.dataId);

			switch (type) {
			case 'changed':
			case 'checker:changed':
			case 'responsible:changed':
			case 'deadline:changed':
				if (!task) return;
				task.set(event.data, { flat: true });
				return;
			case 'created':
				tasks.add(event.data);
			}
		}
	}
});
