import Collection from '../common/models/processes';
// import action from 'helpers/action';

// import createAction from '../create-task-action';

export default (Base) => Base.extend({

	constructor (opts = {}) {
		this.registerNestedEntity('processes', { class: Collection });
		if (this.completedTasksEnabled) {
			const fn = this.getCompletedProcessesDefinition ? this.getCompletedProcessesDefinition.bind(this) : arg => arg;
			this.registerNestedEntity('completedProcesses', fn(Collection));
		}
		Base.apply(this, arguments);


		// let actions = [].concat((this.actions || []), (opts.actions || []));

		// let rule = () => {
		// 	if(_.isFunction(this.getAddTaskRule)) {
		// 		return this.getAddTaskRule();
		// 	}
		// };

		// let rights = () => {
		// 	if(_.isFunction(this.getAddTaskRights)) {
		// 		return this.getAddTaskRights();
		// 	}
		// };

		// actions.push(action('add:task','поставить задачу', rights(), { rule: rule() } ));

		// this.actions = actions;
	},
	getProcesses () {
		return this.entity('processes');
	},
	getCompletedProcesses () {
		return this.entity('completedProcesses');
	}

    // actionAddTask(action, opts){
	// 	let options = _.extend({ parent:this }, opts);
	// 	createAction(options);
	// }
});
