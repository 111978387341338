import Collection from 'base/collection';
import { SocketEvent as model } from './models';
// import comparator from 'helpers/comparator';
export default Collection.extend({
	initialize () {
		// this.on('viewed:change', this.sort);
	},
	model
	// comparator: (v1,v2) => comparator([v2,v1, m => m.get('date')])
});
