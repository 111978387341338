import { _ } from 'vendors';
import proto from 'components/values/get-prototype';
import busData from 'bus/data';
import enums from 'helpers/enums';

// import enums from 'helpers/enums';

const filtersPrototypes = [
	{
		id: 'task:text',
		valueId: 'text',
		label: 'поиск по ключевому слову',
		hidden: true,
		valuePrototype: proto('text'),
		textSearch: true
	},
	{
		id: 'task:state',
		valueId: 'state',
		label: 'состояние задачи',
		pinned: true,
		valueLabel: (val) => enums.get('employeeTaskStates', val),
		valuePrototype: () => proto('enum', {
			multiple: false,
			sourceValues: () => _.omit(enums.store.employeeTaskStates, 'completed')
		})
	},
	{
		id: 'task:type',
		valueId: 'type',
		label: 'тип задачи',
		pinned: true,
		valueLabel: (val) => enums.get('employeeTaskTypes', val),
		valuePrototype: () => proto('enum', {
			multiple: false,
			sourceValues: () => enums.store.employeeTaskTypes
		})
	},
	{
		id: 'task:department',
		valueId: 'depid',
		label: 'отдел',
		pinned: true,
		valueLabel: val => val ? busData.departmentName(val) : '&mdash;',
		valuePrototype: {
			modelType: 'single',
			multiple: false,
			sourceValues: () => busData.departments(),
			controlType: 'departmentsSelect'
		}
	},
	{
		id: 'task:responsible',
		valueId: 'rid',
		label: 'ответственный',
		pinned: true,
		valueLabel: (val) => busData.employeeName(val),
		valuePrototype: {
			modelType: 'single',
			multiple: false,
			sourceValues: () => busData.employees(),
			controlType: 'employeesSelect'
		}
	},
	{
		id: 'task:checker',
		valueId: 'chid',
		label: 'проверяющий',
		pinned: true,
		valueLabel: (val) => busData.employeeName(val),
		valuePrototype: {
			modelType: 'single',
			multiple: false,
			sourceValues: () => busData.employees(),
			controlType: 'employeesSelect'
		}
	},
	{
		id: 'task:creator',
		valueId: 'cid',
		label: 'инициатор',
		pinned: true,
		valueLabel: (val) => busData.employeeName(val),
		valuePrototype: {
			modelType: 'single',
			multiple: false,
			sourceValues: () => busData.employees(),
			controlType: 'employeesSelect'
		}
	},
	{
		id: 'task:dur',
		valueId: 'dur',
		label: 'длительность',
		pinned: true,
		valueLabel: (val) => enums.get('aproximateDurations', val),
		valuePrototype: () => proto('enum', {
			multiple: false,
			sourceValues: () => enums.store.aproximateDurations
		})
	},
	// {
	// 	id:'contragent:roles',
	// 	valueId:'roles',
	// 	label: 'роль',
	// 	pinned: true,
	// 	valueLabel: (val) => enums.get('contragentRoles',val),
	// 	valuePrototype: () => proto('enum',{
	// 		multiple:true,
	// 		sourceValues:() => enums.store.contragentRoles,
	// 	}),
	// }
];

export default filtersPrototypes;
