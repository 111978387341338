// import entries from 'mod/groups/singleton';
import data from 'bus/data';
import SelectSegmented from 'components/controls/select-segmented';

export default SelectSegmented.extend({
	className: 'c-select-groups',
	segments: [
		{
			id: 'my',
			name: 'мои отделы'
		}
	],
	getSelectOptions () {
		const hash = {
			proxyTo: this,
			multiple: this.getOption('multiple'),
			excludeValues: this.getOption('excludeValues'),
			sourceValues: data.groups(),
			modelType: 'single'
		};

		return hash;
	},
	controlsEvents: {
		'control:change' (value) {
			this.value = value;
			this.triggerChange(value);
		}
	},
	getValue () {
		return this.value;
	}
});
