
import { Behavior } from 'base/vendors';
// { behaviorClass: ClickableBehavior, selector: '.owner', triggerEvent: 'owner:clicked' }
export const ClickableBehavior = Behavior.extend({

	events () {
		const handlerKey = this.buildHandlerKey();
        // console.log(' -- handlerKey --', handlerKey);
		return {
			[handlerKey]: '_click'
		};
	},

	buildHandlerKey () {
		let key = 'click';
		let selector;
		const ownSelector = this.getOption('selector');
		if (ownSelector) {
			selector = ownSelector;
		} else {
			const viewSelector = this.view.getOption('clickElementSelector');
			if (viewSelector) {
				selector = viewSelector;
			}
		}
		if (selector) {
			key += ' ' + selector;
		}
		return key;
	},

	_click (event) {
		const eventName = this.getOption('triggerEvent') || 'clicked';
		this.view.triggerMethod(eventName, event, this.view);
	}

});

/**
 *
 * @param {string} selector dom jquery selector
 * @param {string} trigger event name to trigger
 * @returns
 */
ClickableBehavior.options = (selector, triggerEvent) => {
	return { behaviorClass: ClickableBehavior, selector, triggerEvent };
};
