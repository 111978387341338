import BasePage from 'pages/auth';
import Search from './search';
// import Board from './board';
import Create from './create';
import Contragent from './contragent';
// import Deps from './deps';

const Page = BasePage.extend({
	moduleRights: { contragents: 'view' },
	name: 'contragents page',
	label: 'Контрагенты',
	route: 'contragents',
	children: [Search, Create, Contragent],
	icon: 'contragents'
});

export default Page;
