import View from 'components/ui-card/item';
import BaseViewClass from './list-item';

const viewClass = BaseViewClass.extend({
	smallText () {
		return this.model.display('occupation');
	}
});

export default View.extend({
	cardLines: {
		modelView: { viewClass }
	}
});
