import View from 'components/ui-list/item';
import editValue from 'bus/edit';

export default View.extend({
	// initialize () {
	// 	this.model;
	// },
	cssClassModifiers: [
		'filter-item',
		(m) => m.hasValue() ? 'filled' : ''
	],
	topText () {
		return this.model.get('label');
	},
	text () {
		// debugger;
		return this.model.getDisplayValue();
	},
	onTextClick () {
		if (this.model.get('veryFast')) return;
		this.openEditDialogue();

		// let cfg = this.model.getEditConfig();
		// cfg.return = 'control';
		// let ev = editValue.do(cfg);
		// //this.listenTo(ev, 'control:change', (values) => this.model.setValues(values));
		// ev.promise.then(
		// 	(values) => this.model.setValues(values),
		// 	(result) => this.model.setValues(result.value)
		// );
	},
	openEditDialogue () {
		const cfg = this.model.getEditConfig();
		cfg.return = 'control';
		// console.log(' >> C F G', Object.assign({}, cfg));
		const ev = editValue.do(cfg);
		// this.listenTo(ev, 'control:change', (values) => this.model.setValues(values));
		ev.promise.then(
			(values) => this.model.setValues(values),
			(result) => this.model.setValues(result.value)
		);
	},
	onCloseClick () {
		this.model.setValues([]);
	},
	closeButton: true
});
