import { fetchOnScrollMixin } from '../../mixins/view/fetchOnScroll';
// import { stateMixin } from 'mixins/view/state';
// import { classNameMixin } from 'mixins/view/className';

import CollectionView from '../collection-view';
import { EmptyView } from './empty-view';
import { stateMixin } from 'mixins/view/state';
import { BaseCollectionView } from '../base-collection-view';

function sendFetchRequest (data, options = {}) {

	const method = this.getOption('fetchMethod') || this.collection.fetchMethod || 'GET';
	const remove = options.move === undefined;
	// const merge = options.
	const fetchOptions = { data, method, remove, merge: true };

	if (method === 'POST' && data && typeof data === 'object') {
		Object.assign(fetchOptions, {
			processData: false,
			contentType: 'application/json',
			data: JSON.stringify(data)
		});
	}

	return this.collection.fetch(fetchOptions);
}

export const FetchOnScrollView = CollectionView.extend({

	...fetchOnScrollMixin,

	className: 'fetchable-container',

	constructor: function () {
		CollectionView.apply(this, arguments);
		this.initializeFetchOnScrollMixin();
	},

	sendFetchRequest

});


export const ModernFetchOnScrollView = BaseCollectionView.extend({

	...stateMixin,
	...fetchOnScrollMixin,

	baseClassName: 'fetchable-container',

	constructor: function () {
		BaseCollectionView.apply(this, arguments);
		this.initializeClassNameMixin();
		this.initializeFetchableView();
		this.initializeFetchOnScrollMixin();
	},

	sendFetchRequest,

	initializeFetchableView () {
		this.setElAttribute('data-scrollable', true);
		this.initializeCollection();
		this.initializeCollectionState();
		this.initializeFilterModel();
	},

	initializeCollection () {
		if (!this.collection) {
			const Collection = this.getOption('Collection', true);
			this.collection = new Collection();
		}
		const layout = this.getOption('layoutView');
		if (layout) {
			layout.triggerMethod('data:collection:initialized', this.collection);
		}
	},

	initializeCollectionState () {
		this.state('emptyState', 'waiting');

		this.listenTo(this.collection, 'sync', () => {
			if (this.collection.length) {
				this.state('emptyState', false);
			} else {
				this.state('emptyState', 'notFound');
			}
		});

		this.on('filter', () => {
			if (this.children._views.length) {
				this.state('emptyState', false);
			} else {
				this.state('emptyState', 'empty');
			}
		});

	},

	initializeFilterModel () {
		if (this._filtersInitialized) { return; }

		this.oldFilters = this.getOption('oldFilters', true);
		if (!this.oldFilters) {
			this.filterModel = this.getOption('filterModel', true);
		}

		const filters = this.oldFilters || this.filterModel;

		if (filters) {
			this.listenTo(filters, 'apply', this.fetch);
			this._filtersInitialized = true;
		}

		// if (this.filterModel) { return; }
		// this.filterModel = this.getOption('filterModel', true);
		// if (this.filterModel) {
		// 	this.listenTo(this.filterModel, 'apply', this.fetch);
		// }
	},

	getRequestData () {

		if (this.oldFilters) {
			return this.oldFilters.toFilter();
		}

		if (!this.filterModel) { return; }

		const data = this.filterModel.getRequestData();

		return data;
	},

	emptyViewOptions () {
		return {
			emptyState: this.state('emptyState')
		};
	},

	emptyView: EmptyView

});
