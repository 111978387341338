import BaseCollection from 'base/collection';
import model from './model';
import comparator from 'helpers/comparator';
export default BaseCollection.extend({
	model,
	initialize () {
		this._initializeParent();
		this.on('add remove change', this.sort);
	},
	_initializeParent () {
		if (this.parent == null) {
			throw Error('Initialize EntityContacts collection error, parent not provided');
		}
		this._onParentIdChange();
		this.listenTo(this.parent, 'change:id', this._onParentIdChange);
	},
	_onParentIdChange () {
		this.parentId = this.parent.id;
		this.url = this.parent.url() + '/contacts';
	},
	comparator (m1, m2) {
		const res = comparator(
			[m2, m1, (m) => m.isPrimary()],
			[m2, m1, (m) => m.getOrder()],
			[m1, m2, (m) => m.display('name').toLowerCase()]
		);
		return res;
	}
});
