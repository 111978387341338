import { _ } from 'vendors';
import { Object as MnObject } from 'base/vendors';
import { cell } from './template-utils';

import { simpleInvoke } from '../../../helpers/invoke'; // '../../helpers/invoke';

function buildModelSchema (schema) {
	const defaultSchema = {
		_display (key, model) {
			if (this[key] && this[key].display) {
				return this[key].display(model.get(key));
			}
		}
	};

	const schemaHash = schema.reduce((hash, s, index) => {
		hash[s.key] = Object.assign({ index }, s);
		return hash;
	}, defaultSchema);

	return schemaHash;
}


function buildHeaderHash (schema) {
	return schema.reduce((hash, s) => {
		hash[s.key] = undefined;
		return hash;
	}, { H: true });
}

function buildTemplate (schema, isHeader, wrap = true) {
	const tmpl = schema.map(s => cell(s, isHeader)).join('\r\n');
	if (wrap) { return _.template(tmpl); } else { return tmpl; }
}


function normalizeField (field, index) {
	if (!('index' in field)) {
		field.index = field;
	}

	if (typeof field.value === 'function') {
		field.contextKey = `vals["${field.key}"]`;
	}
}

function sortProps (raw) {
	const newarr = raw.map((r, index) => {
		normalizeField(r, index);
		return r;
	});

	newarr.sort((a, b) => {
		const ordr = (b.order || 0) - (a.order || 0);
		if (ordr !== 0) { return ordr; }

		return a.index - b.index;
	});

	return newarr;
}


export const ReportSchema = MnObject.extend({
	constructor () {
		MnObject.apply(this, arguments);
	},
	initialize (options) {
		this.mergeOptions(options, ['name', 'rawSchema']);
		const fs = this.getOption('filters') || [];
		this.filters = fs.map(f => ({ ...f }));
		this.build();
	},
	build () {
		const raw = this.rawSchema;
		const sorted = this.sortedSchema = sortProps(raw);
		this.modelSchema = buildModelSchema(sorted);
		this.headerHash = buildHeaderHash(sorted);

		const templateText = buildTemplate(sorted, false, false);

		this.templateText = templateText;
		this.template = _.template(templateText);
            // buildModelTemplate(sorted);
		const headerTemplateText = buildTemplate(sorted, true, false);
		this.headerTemplateText = headerTemplateText;
		this.headerTemplate = _.template(headerTemplateText);
            // buildHeaderTemplate(sorted);
		console.log('-reportschema-', this);
	},
	setRawSchema (raw) {
		this.rawSchema = raw;
		this.build();
		this.triggerMethod('schema:changed');
	},
	invoke (arg, view, context) {
        // let context = Object.assign({}, view, this.getOption('valuesMixin'));
		return simpleInvoke(arg, view, context, view);
	},
	buildTemplateContext (view) {
		const context = {};
        // context.display = this.display.bind(this);
		const baseInvokeContext = Object.assign({}, view, this.getOption('valuesMixin'));

		context.display = key => this.modelSchema._display(key, view.model);

		const schema = this.sortedSchema;
		context.css = {};
		context.vals = {};

		schema.forEach(field => {
            // invokeContext.fieldKey = s.key;
			const invokeContext = {
				...baseInvokeContext,
				fieldKey: field.key,
				field
			};
			if (field.value) {
				context.vals[field.key] = this.invoke(field.value, view, invokeContext);
			}
			if (field.addValueCssClass) {
				context.css[field.key] = this.invoke(field.addValueCssClass, view, invokeContext);
			}
		});

		return context;
	}
});
