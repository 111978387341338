import { HamburgerView } from 'base/view-hamburger';
import { ProductionTree } from './products-layouts/ProductionTree';
import { ProductsTableLayout } from './products-layouts/TableLayout';
export const ProductsContent = HamburgerView.extend({
	initialize () {
		// console.log('THIS THIS THIS', this);
		// const rootView = this.getOption('rootView');
		// this.listenTo(rootView, 'header:tab', (tabId) => {
		// 	rootView.currentTab = tabId;
		// 	this.render();
		// });
	},
	getCustomViews () {
		return [
			this.buildContentView()
		];
	},
	buildContentView () {
		const rootView = this.getOption('rootView');
		const view = (ext) => ({
			rootView,
			model: this.model,
			filterModel: this.getOption('filterModel'),
			...ext
		});

		switch (rootView.currentTab) {
		case 'table':
			return view({ class: ProductsTableLayout });
		case 'source-product':
			return view({ direction: 'fromSource', class: ProductionTree });
		case 'product-source':
			return view({ direction: 'fromProduction', class: ProductionTree });
		}
	}
});
