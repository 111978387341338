import { _ } from 'vendors';
import Model from 'base/model';
import Collection from 'base/collection';

import UiListItem from 'components/ui-list/item';
import CollectionView from 'base/collection-view';
import BaseCollectionView from 'pages/collection-view';
import UiCardBlock from 'components/ui-card-block/block';
import smartOpen from 'helpers/smart-open';

const MenuModel = Model.extend({
	getLinkUrl () {
		let url = this.get('url') || this.id;
		if (url === 'root') {
			url = '';
		}
		if (!url.startsWith('/') && url) {
			url = '/' + url;
		}
		return url;
	}
});

const MenuItemView = UiListItem.extend({
	className: 'ui-list-item whole-btn',
	cssClassModifiers: [
		m => {
			const thisids = (m.get('ids') || []).join('/');
			if (thisids === '') {
				return m.collection.currentId === thisids ? 'active' : '';
			} else {
				return m.collection.currentId.indexOf(thisids) === 0 ? 'active' : '';
			}
		}
	],
	events: {
		'click' (e) {
			this.triggerMethod('click', this.model, e);
			return false;
		}
	}
});

const MenuView = CollectionView.extend({
	initialize (options) {
		this.mergeOptions(options, ['page', 'actionContext']);
		this.basePath = document.location.pathname;
		const menuItems = this.getOption('menuItems') || [];
		this.collection = new Collection(menuItems, { model: MenuModel });
		this.collection.currentId = (this.getOption('menuIds') || []).join('/');
	},
	childView: MenuItemView,
	getChildrenRootPage () {
		if (this.page.getChildRootPage) {
			return this.page.getChildRootPage();
		} else {
			return this.page;
		}
	},
	getBaseRoute () {
		const root = this.getChildrenRootPage();
		return root.getRoute();
	},
	getModelUrl (model) {
		let route = [this.getBaseRoute()];
		const ids = model.get('ids') || [];
		route.push(...ids);
		route = this.page._normalizeRoute(route.join('/'));
		return this.page.parseUrl(route, this.actionContext.args);
	},
	childViewEvents: {
		'click' (model, event) {
			const url = this.getModelUrl(model);
			smartOpen(url, event);
			return false;
		}
	},
	viewFilter (v) {
		if (!v.model) return true;
		const filter = v.model.get('filter');
		if (typeof (filter) !== 'function') {
			return true;
		}
		return filter(this.getOption('page'));
	}
});


const ColumnView = CollectionView.extend({
	className: 'whole-column'
});

export default BaseCollectionView.extend({
	addClass: 'page-with-menu',
	getBaseUrlForChild () {
		let page = this.page;
		if (!this.isRoot()) {
			page = page.getParent();
		}
		return page.url(this.actionContext.args);
	},
	getMenuId () {
		if (this.isRoot()) {
			return 'root';
		} else {
			return this.getChildId();
		}
	},
	isRoot () {
		return !this.getChildId();
	},
	getChildId (normalize) {
		let id = this.page.getOption('childId');
		if (!normalize || !id) return id;

		if (id.startsWith(':')) {
			id = id.substring(1);
		}
		id = _.camelCase(id.replace(/-/gmi, ':'));
		return id;
	},
	buildMenuView () {
		return new UiCardBlock({
			renderOnModelChange: true,
			addClass: 'menu-block',
			header: false,
			content: MenuView,
			model: this.model,
			contentOptions: {
				tagName: 'ul',
				addClass: 'scrolled-y',
				menuItems: this.getOption('menuItems'),
				page: this.page,
				actionContext: this.actionContext,
				childViewOptions: {
					tagName: 'li',
					page: this.page,
					actionContext: this.actionContext
					// model: this.model,
				},
				baseUrl: this.getBaseUrlForChild(),
				menuId: this.getMenuId(),
				menuIds: this.page.getOption('childIds')
			}
		});
	},
	buildUnderMenuContents () {
		const id = this.getChildId(true);
		const defs = this.getOption('underMenuContents', { args: [this] }) || [];
		let owns;
		if (id) {
			owns = this.getOption(id + 'UnderMenuContents', { args: [this] }) || [];
		}
		return owns || defs;
	},
	buildColumnView (views, options) {
		options = _.extend({}, options, {
			customViews: [
				...views
			]
		});
		return new ColumnView(options);
	},
	buildMenuColumnView () {
		const views = [
			this.buildMenuView(),
			...this.buildUnderMenuContents()
		];

		return this.buildColumnView(views, {
			className: 'whole-column menu-aside'
		});
		// return new ColumnView({
		// 	customViews: [
		// 		this.buildMenuView(),
		// 		...this.buildUnderMenuContents()
		// 	]
		// });
	},
	// override
	_defaultPageContents () {
		const id = this.getChildId(true) || 'root';
		// if (id.startsWith(':')) {
		// 	id = id.substring(1);
		// }
		// id = _.camelCase(id.replace(/-/gmi,':'));
		const key = id + 'Contents';
		const result = this.getOption(key) || [];
		if (!result.length) {
			console.warn('#-- NO CONTENT for: [' + key + ']', this._viewName);
		}
		return result;
	},
	buildPageContents () {
		const own = this.getOption('pageContents', { args: [this] });
		if (Array.isArray(own)) {
			return own;
		}
		return this._defaultPageContents();
	},
	customViews () {
		return [
			this.buildMenuColumnView(),
			...this.buildPageContents()
		];
	}
});
