import Card from 'components/ui-card/item';
import viewClass from './list-item-view';
import ListItem from 'components/ui-list/item';

// const viewClass = BaseViewClass.extend({
// 	// smallText(){
// 	// 	return this.model.display('occupation');
// 	// }
// });

// export default Card.extend({
// 	cardLines:{
// 		'modelView':{ viewClass }
// 	}
// });


const LastComment = ListItem.extend({
	className: 'last-comment',
	getText () {
		const model = this.model;
		return model.isNotClosed() ? model.display('lastComment.text') : model.display('completeText');
	},
	getSmallText () {
		const model = this.model;
		return model.isNotClosed() ? model.display('lastComment.authorId') : '';
	}
	// text: m => m.get('lastComment.text'),
	// smallText: m => m.empName('lastComment.authorId')
});

export default Card.extend({
	addClass: 'whole-btn process-card',
	cssClassModifiers: [
		m => m && 'process-' + m.get('type'),
		m => m && m.get('completeState')
	],
	cardLines: {
		modelView: { viewClass },
		lastComment: { viewClass: LastComment }
	},
	clickAction: 'open:card'
});
