import BasePage from 'pages/auth';
import Emps from './emps';
import Deps from './deps';

const Page = BasePage.extend({
	name: 'staff page',
	label: 'Cотрудники и отделы',
	route: 'staff',
	children: [Emps, Deps],
	icon: 'staff'
});

export default Page;
