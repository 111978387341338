// import { UiSchemaMoleculeView } from '../molecule';
import { viewSchemaMixin } from 'apis/schema';
import { HamburgerView } from 'base/view-hamburger';
import { invokeValue, isViewClass } from 'utils';
import { modelSchemaApi } from '../../../apis/schema';
import { BasePropertyView } from './BasePropertyView';

export { SchemaLabeledValueView } from './SchemaLabeledValueView';
export { SchemaLabelView } from './SchemaLabelView';
export { SchemaValueView } from './SchemaValueView';
export { SchemaTextView } from './SchemaTextView';

export {
	BasePropertyView
};

// function logAndRet (arg) {
// 	console.log(arg);
// 	return arg;
// }

export const UiSchemaPropertiesView = HamburgerView.extend({
	...viewSchemaMixin,
	baseClassName: 'schema-properties-list',
	customViewOptions () {
		const opts = {
			modelSchema: this.getModelSchema(),
			model: this.model,
			collection: this.collection
		};
		return opts;
	},
	getCustomViews () {
		const views = this._getPropertiesViews();

		return views;
	},
	_getPropertiesViews () {
		const properties = this.getProperties();
		return properties.map(property => this._buildPropertyView(property));
	},
	getProperties () {
		const properties = this.getOption('properties', true) || [];
		return properties;
	},
	_buildPropertyView (property) {

		property = invokeValue(property, this, this);

		if (!property) { return; }

		if (isViewClass(property)) { return property; }

		if (typeof property === 'string') {
			return this._buildTextPropertyView(property);
		}

		if (typeof property !== 'object') { return; }

		if (!property.class) {
			return this._buildSchemaPropertyView();
		}

		return property;
	},
	_buildTextPropertyView (property) {
		const schema = this.getModelSchema();
		const propertySchema = modelSchemaApi.getPropertySchema(property, schema) || { property };
		return this._buildSchemaPropertyView(propertySchema);
	},
	_buildSchemaPropertyView (propertySchema) {
		const PropertyView = this.getOption('PropertyView', true) || BasePropertyView;
		return {
			class: PropertyView,
			propertySchema
		};
	}
});

