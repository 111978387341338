
import View from 'base/view';
// import getRawFilters from 'mods/store/filters/get';

import { TypesSwitcherView } from './types-switcher-view';
import { EntriesView } from './entries-view';

const emptyObject = {};
const hasProp = (v, key) => attrsGetter(v)[key] !== undefined;
const attrsGetter = v => (v.model || emptyObject).attributes || emptyObject;
const extNameGetter = (v, key1, key2) => (attrsGetter(v)[key1] || emptyObject)[key2] || '';
// const nameGetter = v => (((v.model || emptyObject).attributes || emptyObject).product || emptyObject).fullName || '';
const stringComparer = (v1, v2) => {
	const n1 = extNameGetter(v1, 'product', 'fullName');
	const n2 = extNameGetter(v2, 'product', 'fullName');
    // console.log('< :: >', n1, ' ++ ', n2);
	if (n1 < n2) {
		return -1;
	} else if (n1 > n2) {
		return 1;
	} else {
		if (hasProp(v1, 'contragent')) {
			const c1 = extNameGetter(v1, 'contragent', 'name');
			const c2 = extNameGetter(v2, 'contragent', 'name');
			return c1 < c2
				? -1
				: c1 > c2
					? 1
					: 0;
		} else {
			return 0;
		}
	}
};
// Entries Box
export const ReportLayout = View.extend({
	className: 'managed-table-view full-screen report-module',
	template: '<div class="manager"></div><div class="entries-container"><div class="types-switcher"></div><div class="entries"></div></div>',
	regions: {
		manager: '.manager',
		entries: '.entries',
		switcher: '.types-switcher'
	},

	initializeManagerView () {},

	onRender () {
		this.entriesView = this.getEntriesView();
		this.showChildView('entries', this.entriesView);
		this.showChildView('manager', this.getManagerView());

		if (!this.getOption('noTopMenu')) { this.showChildView('switcher', this.getSwitcherView()); } else { this.showChildView('switcher', new View({ template: false }), { replaceElement: true }); }

		this.entriesView.fetch();
	},

	getSwitcherView () {
		const reportSchema = this.getOption('reportSchema');
		return new TypesSwitcherView({ filterer: this.entriesView.filtersInstance, reportSchema });
	},
	getManagerView () {
		return this.entriesView.buildEntriesManagerView();
	},
	getEntriesView () {
		const reportSchema = this.getOption('reportSchema');
		if (!reportSchema) {
			throw new Error('reportSchema was not provided');
		}
		const entriesViewOptions = this.getOption('entriesViewOptions') || {};
		const infoOptions = this.getOption('entriesInfo') !== false;
		const _entriesViewOptions = {

			fetchNextDisabled: this.getOption('fetchNextDisabled'),
			viewComparator: stringComparer,
			collection: this.collection,
			reportSchema,
            // entriesManager: this.entriesManager,
            // filtersInstance: this.filtersInstance,
			entriesManagerOptions: {
				infoOptions,
				filtersOptions: {
					id: 'page:report:' + reportSchema.name,
					getRaw: () => reportSchema.filters || [],
					instantApply: true
				},
				actionsEnabled: true
			},
			...entriesViewOptions
		};
		return this.buildEntriesView(EntriesView, _entriesViewOptions);
        // let view =  new EntriesView();
        // return view;
	},
	buildEntriesView (View, options) {
		return new View(options);
	}
});

