import { BaseTextView } from 'base/base-view';
import { propertySchemaApi } from '../../../apis/schema';

const propertySchemaApiKeys = ['getValue', 'displayValue', 'getLabel', 'hasValue'];
// Object.keys(propertySchemaApi);

const apiMixin = propertySchemaApiKeys.reduce((memo, key) => {

	const method = propertySchemaApi[key];

	if (typeof method !== 'function') { return memo; }

	memo[key] = function () {

		const propSchema = this.getPropertySchema
			? this.getPropertySchema()
			: this.propertySchema || this.property;

		const modSchema = this.getModelSchema
			? this.getModelSchema()
			: this.modelSchema;

		const model = this.getModel
			? this.getModel()
			: this.model;

		return method.call(propertySchemaApi, propSchema, model, modSchema);

	};

	return memo;

}, {});

// console.error('apiMixin', apiMixin);

export const SchemaTextView = BaseTextView.extend({
	constructor: function (options) {
		BaseTextView.apply(this, arguments);
		this.mergeOptions(options, ['property', 'propertySchema', 'modelSchema']);
		this.api = propertySchemaApi;
	},
	...apiMixin,
	getText () {
		const propertySchema = this.getPropertySchema();
		const modelSchema = this.getModelSchema();
		const model = this.getModel();

		// if (this.getOption('DEBUGGER')) {
		// 	debugger;
		// }

		const text = this.getOption('text', { invoke: true, invokeArgs: [this, propertySchema, model, modelSchema] });
		// console.error(text, propertySchema, modelSchema);
		return text;
	},
	getPropertySchema () {
		return this.propertySchema || this.property;
	},
	getModelSchema () {
		return this.modelSchema;
	},
	getModel () {
		return this.model;
	}
});
