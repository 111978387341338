import BasePage from 'pages/auth';
import FullProcTree from './full-proc-tree';
import Deps from './departments';
import Builds from './builds';
import Batches from './batches';
// import Cycles from './cycles';

// import { Layout, NewLayout } from './full-proc-tree/layout';
// //import { NewLayout } from './new-layout';
// import { ProductsCollection, productionSalesApiUrl, productionAllInfoApiUrl } from './full-proc-tree/models';
// import Collection from 'base/collection';
// import Model from 'base/model';



// const _Page = BasePage.extend({
// 	moduleRights: { productProduction: 'view' },
// 	label: 'Производство',
// 	route: 'prod',
// 	Layout: NewLayout,
// 	//Layout,
// 	//children:[Production],
// 	icon: 'product-production',
// 	onBeforeStart(){

// 		if(!this.collection) {
// 			let col = new ProductsCollection();
// 			col.url = productionSalesApiUrl;
// 			this.addCollection(col);
// 			this.addStartPromise(col.fetchIfNot());
// 		}

// 		if (!this.model) {
// 			let model = new Model();
// 			model.url = productionAllInfoApiUrl;
// 			this.addModel(model);
// 			this.addStartPromise(model.fetchIfNot());
// 		}
// 		//model.fetch();

// 	},
// });

const Page = BasePage.extend({
	moduleRights: { productProduction: 'view' },
	label: 'Производство',
	route: 'prod',
	icon: 'product-production',
	children: [Deps, FullProcTree, Builds, Batches]
});

export default Page;
