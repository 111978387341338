import { _ } from 'vendors';
import { busFiles } from './bus';
import { BusService } from 'components/bus-service';
import { NotifierHelper } from './notifier';
import { upload } from './uploader';

export const FileService = BusService.extend({
	shouldUseNotifier: false,
	shouldUseUploadOnNewFiles: false,
	constructor (options) {
		BusService.apply(this, arguments);
		this.initializeNotifierHelper();
	},
	service: busFiles,
	initializeNotifierHelper () {
		if (this.getOption('shouldUseNotifier')) {
			this.notifier = new NotifierHelper();
		}
	},
	events: {
		drop (files) {
			const res = this.triggerMethod('drop', files);
			if (res !== false) { this.addBlobs(files); }
				// this.triggerMethod('new:files', files);
		},
		paste (files) {
			const res = this.triggerMethod('paste', files);
			if (res !== false) { this.addBlobs(files); }
				// this.triggerMethod('new:files', files);
		},
		form (files) {
			const res = this.triggerMethod('form', files);
			if (res !== false) { this.addBlobs(files); }
				// this.triggerMethod('new:files', files);
		}
	},
	_tryAddNotifierToOptions (options = {}) {
		if (this.getOption('shouldUseNotifier')) {
			if (!options.proxyEventsTo) {
				options.proxyEventsTo = this.notifier;
			} else {
				if (!_.isArray(options.proxyEventsTo)) {
					options.proxyEventsTo = [options.proxyEventsTo];
				}
				options.proxyEventsTo.push(this.notifier);
			}
		}
		return options;
	},
	getUploadOptions () {
		const options = this.getOption('uploadOptions');
		return options;
	},
	onNewFiles (blobs) {
		if (this.getOption('shouldUseUploadOnNewFiles')) {
			const options = this.getUploadOptions(blobs);
			_.extend(options, { blobs });
			this.upload(options);
		}
	},
	addBlobs (blobs) {
		this.triggerMethod('new:files', blobs);
	},
	upload (options = {}) {
		this._tryAddNotifierToOptions(options);
		upload(options);
	}
});
