import { _ } from 'vendors';
export default function phoneHtml (phone) {
	if (!phone || !_.isString(phone)) return '';

	const chunks = phone.split('.');
	const number = chunks.pop();
	const left = '+' + chunks.join(' ');

	const len = number.length;
	const res = [];
	_(number.split('').reverse()).each((ch, i) => {
		res.unshift(ch);
		if (i % 2 === 1 && i > 0 && (len - i) >= 4) { res.unshift('-'); }
	});

	const label = left + ' ' + res.join('');
	const tel = '+' + number;
	return `<a href="tel:${tel}">${label}</a>`;
}
