import { _ } from 'vendors';
import InputPlaceholdered from 'components/controls/input-placeholdered';

export const ContactIdNameInput = InputPlaceholdered.extend({
	initialize () {
		this.value = _.extend({}, this.getOption('value'));
	},
	// className: 'contact-idname-input',
	inputOptions () {
		return {
			value: this.value.value
		};
	},
	controlsEvents: {
		'control:change' (text) {
			if (text === '' || text == null) {
				this.value = undefined;
			} else if (this.value.value !== text) {
				this.value = { value: text };
			}
			this.triggerChange();
		},
		'control:done' (text) {
			this.value.value = text;
			this.triggerDone(text);
		},
		'control:validate:error' (...args) {
			this._triggerEvent('validate:error', ...args);
		}
	},
	onChildviewPredefinedClick (view) {
		const newval = view.model.get('value');
		if (newval) {
			_.extend({}, newval);
		}
		_.defer(() => {
			this.value = newval;
			this.render();
		});
		// this.triggerChange();
	}

});
