import { NextCollectionView, Collection } from 'base/vendors';
import { BatchLineView } from './batch-line-view';

export const BatchLinesView = NextCollectionView.extend({
	className: 'batches-lines',
	initialize () {
		this.filterModel = this.getOption('filterModel');

		if (!this.model.batches) {
			this.model.batches = new Collection();
		}
		this.collection = this.model.batches;
		this._updateCollection();

		this.listenTo(this.filterModel, 'change:modelsSet search', (args) => {
			if (this.filterModel.filter(this.model)) {
                // console.log('-sort-batches-', this.children);
				this.sort();
			}
		});

        /*

        this.listenTo(this.model, 'batch:newAmount', (args) => {
            //console.log('-sort by new amount-!');
            console.log('-sort-batches-', args);
            this.sort();
        });

        this.on('render:children', () => {
            //console.log('-render-children-');
            if (this._focusedView) {
                this._focusedView.setFocus();
            }
        });
        */
	},
	_updateCollection () {
		const models = this.model.get('batches') || [];
		this.collection.reset(models);
	},
	childView: BatchLineView,
	childViewOptions () {
		return {
			product: this.model
		};
	},
	viewComparator: 'created',
	viewFilter (v) {
		if (!this.filterModel.filter(this.model)) { return false; }

		const batchOk = this.filterModel.filterBatch(v.model, this.model);
        // console.log('-batchok-', this.model.id, batchOk);

		return batchOk;
	},
	childViewEvents: {
		focus (child) {
            // console.log('-focus-');
			this._focusedView = child;
		}
        // blur(child) {
        //     console.log('-blur-');
        //     if (this._focusedView === child) {
        //         delete this._focusedView;
        //     }
        // }
	},
	modelEvents: {
		'batch:newAmount' (product, batchId) {
            // console.log('-try to detach-');
			const batch = this.collection.get(batchId);
			if (!batch || !this.filterModel.filter(product)) {
                // console.log('batch: ', typeof batch, 'model filter:', this.filterModel.filter(product));
				return;
			}

			if (!this.filterModel.filterBatch(batch, product)) {
				const view = this.children.findByModel(batch);
				if (view) {
					this.filterChildView(view, { force: true });
                    // view.$el.detach();
                    // this.detachChildView(view);
				} else {
					console.log('view not found');
				}
			}
            // else {
            //     console.log('filterBatch is ok');
            // }
            // console.log('-new amount-', this.children);
		}
	}

});
