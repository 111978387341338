import BasePage from 'pages/auth';
import Layout from './layout';
// import Emps from './emps/emps';

const Page = BasePage.extend({
	moduleRights: { admin: 'view, manageAdmins' },
	id: 'admin/administrators',
	name: 'admin management page',
	label: 'Управление администраторами',
	route: 'admin',
	Layout,
	icon: 'administrators'
	// children:[Emps]
});

export default Page;
