import Model from 'base/model';
import Collection from 'base/collection';

// import Collection from 'base/collection';
import paths from 'helpers/paths';
import _ from 'underscore';
// import busData from 'bus/data';
export const departmentsApiUrl = paths.urls.api('production/departments');

export const DepartmentModel = Model.extend({
	urlRoot: departmentsApiUrl,
	initializeProductToProcessMap () {
		// const products = this.get('products');
		const procs = this.get('productionProcesses');
		this.productProcesses = _.groupBy(procs, proc => proc.product.id);
		const moveprocs = this.get('moveProcesses') || [];
		this.productMoveProcesses = _.groupBy(moveprocs, proc => proc.inputProduct.id);
		this.productMoveProcessesOutput = _.groupBy(moveprocs, proc => proc.product.id);
	}
});

export const storeApi = {
	collection: new Collection([], { model: DepartmentModel }),
	fetchedAt: new Map(),

	_get (id) {
		let model = this.collection.get(id);
		if (!model) {
			model = this.collection.add({ id });
		}
		return model;
	},

	get (id) {
		const model = this._get(id);
		let fetchInfo = this.fetchedAt.get(model);
		if (this._needFetch(fetchInfo)) {
			fetchInfo = this._fetchModel(model);
		}
		return { model, promise: fetchInfo.promise };
	},

	async getAsync (id) {

		const model = this._get(id);
		let fetchInfo = this.fetchedAt.get(model);

		if (this._needFetch(fetchInfo)) {
			fetchInfo = this._fetchModel(model);
		}

		await fetchInfo.promise;

		return model;
	},
	_fetchModel (model) {
		const fetchInfo = {
			time: Date.now(),
			promise: model.fetch()
		};
		this.fetchedAt.set(model, fetchInfo);
		return fetchInfo;
	},
	_needFetch (info) {
		if (!info) { return true; }
		return Date.now() - info.time > 60000 * 15; // 15 минут
	}
};
