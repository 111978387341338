import { _ } from 'vendors';
import twin from './twin';

export default function twinContext (date, opts = {}) {
	let cssClass = 'two-line-date ' + (opts.cssClass || '');

	if (opts.small) { cssClass += 'small'; }
	cssClass = cssClass.trim();

	return _.extend({}, twin(date), { cssClass });
}
