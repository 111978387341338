// import proto from 'components/values/get-prototype';
// import enums from 'helpers/enums';
import busData from 'bus/data';

export const departmentFilters = [
	{
		id: 'byproddep',
		valueId: 'byproddep',
		label: 'отдел / производственный участок',
		pinned: true,
		valuePrototype: {
			modelType: 'single',
			multiple: false,
			controlType: 'departmentsSelect',
			sourceValues: () => busData.departments()
		},
		valueLabel (id) {
			return busData.departments(id).display('name');
		}
	}
];
