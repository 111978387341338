import { BaseCollectionView } from 'base/base-collection-view';
import { Ui2AtomView } from 'components/ui2/atom';
import { UiMoleculeView } from '../../../../../../components/ui2/molecule';
import { getCycleProcessCollection } from './prodCycleProc-collection';
import busData from 'bus/data';
import { processPageCardUrl } from '../../../../../../modules/processes/constants';

const NoProcsView = Ui2AtomView.extend({
	text: '- нет производственных процессов -'
});
const ChildView = UiMoleculeView.extend({
	openCardButton: v => processPageCardUrl + '/' + v.model.id,
	content: {
		showSmall: true,
		smallText: v => v.model.get('name'),
		text: v => busData.employeeName(v.model.get('ownerId')) + ' &mdash; ' + v.model.attributes.contragent.name
	}
});

export const ProdCycleProcessesView = BaseCollectionView.extend({
	emptyView: NoProcsView,
	childView: ChildView,
	initialize (options) {
		this.mergeOptions(options, ['product']);
		this.collection = getCycleProcessCollection(this.model);
	}
});
