export const cardUrls = {
	batch: '/prod/batches/',
	product: '/res/prod/products/',
	productionDepartment: '/prod/deps/',
	cycle: '/prod/cycles/',
	process: '/procs/'
}

export function openCardButton(arg, text) {

	const url = normalizeUrl(arg);

	let addtext = '';
	if (text) {
		addtext = `<span>${text}</span>`;
	}
	return `<a href="${url}" class="like-button"><i class="fa fa-link"></i>${addtext}</a>`;
}

function normalizeUrl(arg) {
	if (typeof arg === 'string') {
		return arg;
	}
	if (arg && typeof arg === 'object') {
		return cardUrls[arg.type] + arg.id;
	}
}