import CollectionView from 'base/collection-view';
export const BaseBlockLayout = CollectionView.extend({
	initialize (options) {
		this.mergeOptions(options, ['ac']);
		this.page = this.ac.page;
	},
	getChildDefaultOptions () {
		return {
			product: this.model,
			page: this.page,
			ac: this.ac
		};
	},
	className: 'double-width product-store-block ui2-block whole-column'
});
