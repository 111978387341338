import BasePage from 'pages/default';
import ChangePass from './change-pass';
import Login from './login';
import ForgotPass from './forgot-pass';

const Page = BasePage.extend({
	name: 'account page',
	label: 'Учётная запись',
	route: 'acc',
	children: [Login, ForgotPass, ChangePass],
	order: 1000,
	icon: 'identity'
});

export default Page;
