import { _ } from 'vendors';
import Yat from 'marionette.yat';
import envCfg from '../config';

const get = function (type, name) {
	return paths[type].entries[name];
};

const getPrefix = function (type, options = {}) {
	if (options.relative) { return ''; }

	const hash = paths[type];
	const defPrefix = [];
	const addServer = options.server !== false;
	const addVersion = options.version !== false;
	const addPrefix = options.prefix !== false;
	const prefix = typeof options.prefix === 'string' ? options.prefix : hash.prefix;

	if (addServer && hash.server && hash.protocol) { defPrefix.push(hash.protocol); }

	if (addServer) defPrefix.push(hash.server);
	if (addVersion) defPrefix.push(hash.version);
	if (addPrefix) defPrefix.push(prefix);
	const result = _(defPrefix).filter((p) => !!p).join('/');
	return result;
};

function getRelativePrefix (type, name) {
	const chunks = (name || '').split(':');
	chunks.pop();
	const prefix = get(type, chunks.join(':'));
	return prefix;
}


const api = function (name, ...chunks) {
	let res = get('api', name);
	if (res) {
		const add = _.reduce(chunks, (memo, chunk) => {
			if (chunk) {
				memo.push(chunk);
			}
			return memo;
		}, []).join('/');
		if (add) {
			res += '/' + add;
		}
	}
	return res;
};

const url = function (name) {
	const res = get('url', name);
	return res;
};
const route = function (name) {
	const res = get('route', name);
	return res;
};

const link = function (url) {
	const prefix = !envCfg.pushState ? '#' : '';
	return prefix + url;
};

const paths_map = ['protocol', 'server', 'version', 'prefix'];
const paths = {
	api: {
		protocol: envCfg.apiProtocol,
		server: envCfg.apiServer,
		version: envCfg.apiVersion,
		prefix: envCfg.apiPrefix,
		entries: {}
	},
	url: {
		entries: {}
	},
	route: {
		entries: {}
	}
};

const set = function (type, name, url, options = {}) {
	const hash = paths[type];
	if (hash == null) { throw new Error('path chunk not found', type); }
	if (name == null) { throw new Error('path name is empty', name, url); }

	url = url || '';
	let prefix = options.relative ? getRelativePrefix(type, name) : getPrefix(type, options);
	if (prefix.length) {
		if (!prefix.endsWith('/')) { prefix = prefix + '/'; }
		url = prefix + url;
	}

	hash.entries[name] = url;


	return this;
};

// Console.warn('paths', paths);

Yat.TemplateContext.add({
	_link: function (url) {
		return link(url);
	},
	_url: function (key) {
		let res = url(key);
		if (res.startsWith('http') || res.startsWith('#')) { return res; }

		const ps = envCfg.pushState;
		if (ps && !res.startsWith('/')) { res = '/' + res; } else if (!ps && !res.startsWith('#')) { res = '#' + res; }

		return res;
	}
});

// console.warn('paths api', paths.api);

function replaceParams (text, params = {}) {
	if (!text) return text;
	return text.replace(/:\w+/g, (found) => {
		if (!found) return '';
		const key = found.substring(1);
		const val = params[key] || '';
		return val;
	});
}


function apiNext (...args) {
	let options;
	const pathsChunks = [''];
	_.some(args, arg => {
		if (arg && typeof arg === 'object' && !Array.isArray(arg)) {
			options = arg;
			return true;
		} else {
			pathsChunks.push(arg);
		}
	});
	options = options || {};
	const defOptions = _.omit(paths.api, 'entries');
	_.defaults(options, defOptions);
	const base = _.reduce(paths_map, (chunks, key) => {
		const chunk = options[key];
		if (chunk) {
			chunks.push(chunk);
		}
		return chunks;
	}, []).join('/');
	return base + pathsChunks.join('/');
}

export default {
	link,
	api,
	apiNext,
	url,
	route,
	set,
	paths,
	urls: {
		api (url, { version = 'v2', prefix = 'ent', args } = {}) {
			const chunks = [paths.api.protocol, paths.api.server];
			if (version) {
				chunks.push(version);
			}
			if (prefix) {
				chunks.push(prefix);
			}
			if (url) {
				if (args) {
					url = replaceParams(url, args);
					// url.replace(/:\w+/g, (found) => {
					// 	if (!found) return '';
					// 	let key = found.substring(1);
					// 	let val = args[key] || '';
					// 	return val;
					// });
				}
				chunks.push(url);
			}
			return chunks.join('/');
		},
		page (url, args) {
			return replaceParams(url, args);
		}
	}
};
