import version from './version';



const config = {
	release: {
		pushState: true,
		apiProtocol: '/',
		apiServer: 'api.palma-med.ru',
		apiVersion: 'v2',
		apiPrefix: 'ent',
		tokenUrl: 'auth/token',
		refreshTokenUrl: 'auth/refreshToken',
		socketsJsApiUrl: 'scripts/jquery.signalR-2.2.3.min.js',
		socketsJsHubUrl: '_aws/hubs',
		socketsName: 'appWsHub',
		socketsLoadScripts: false
	},
	debug: {
		pushState: true,
		apiProtocol: '/',
		apiServer: 'api.palma-med.loc',
		apiVersion: 'v2',
		apiPrefix: 'ent',
		tokenUrl: 'auth/token',
		refreshTokenUrl: 'auth/refreshToken',
		socketsJsApiUrl: 'scripts/jquery.signalR-2.2.3.min.js',
		socketsJsHubUrl: '_aws/hubs',
		socketsName: 'appWsHub',
		socketsLoadScripts: false
	}
};

export default version.release ? config.release : config.debug;
