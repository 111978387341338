import { _ } from 'vendors';

import Bb from 'backbone';
import Yat from 'marionette.yat';
import { buildViewByKey } from 'helpers/buildViewByKey';
_.mixin({
	camelCase: function (text, first) {
		if (!_.isString(text)) return text;
		const splitter = first === true ? /(^|:)(\w)/gi : /(:)(\w)/gi;
		return text.replace(splitter, function (match, prefix, text) { return text.toUpperCase(); });
	},
	colonedString: function (arg, ...other) {
		if (arg == null) return;
		let process = [];
		if (_.isString(arg)) { process.push(arg); }

		process.push(...other);

		process = _(process).flatten();

		const chunks = _(process).chain()
			.map((item) => !!item && item.toString().split(/(?=[A-Z])/))
			.flatten()
			.filter((item) => !!item)
			.map((item) => item.charAt(0).toLowerCase() + item.slice(1))
			.value();

		return chunks.join(':');
	},
	notValue: (arg) => Yat.Functions.common.notValue(arg),
	isView: function (arg) {
		return arg instanceof Bb.View;
	},
	isModel: function (arg) {
		return arg instanceof Bb.Model;
	},
	isCollection: function (arg) {
		return arg instanceof Bb.Collection;
	},
	isCtor: function (arg) {
		return Yat.Helpers.isKnownCtor(arg);
	},
	isViewClass: function (arg) {
		return _.isFunction(arg) && (arg === Bb.View || arg.prototype instanceof Bb.View);
	},
	displayNum (value, digits, options = {}) {
		if (_.isObject(digits)) {
			options = digits;
			digits = undefined;
		}
		digits = digits || options.digits || 0;
		const empty = options.empty || '';
		value = parseFloat(value, 10);
		if (isNaN(value)) {
			return empty;
		}
		const power = Math.pow(10, digits);
		if (power > 0) {
			const method = options.round || 'round';
			value = Math[method](value * power) / power;
			// value = (value * power) / power;
		}

		if (value.toLocaleString) {
			return value.toLocaleString('ru', { maximumFractionDigits: digits || 0 });
		} else {
			return _.rusNum(value);
		}
	},
	rusNum (value) {
		if (value == null || isNaN(value)) return;
		const num = value.toString().split('.');
		let int = _.map(num[0], a => a);
		let fl = num[1];
		int.reverse();
		int = _.reduce(int, (memo, char, index) => {
			if (index > 0 && (index % 3) === 0) {
				char = char + ' ';
			}
			memo = char + memo;
			return memo;
		}, '');
		if (fl) {
			fl = ',' + fl;
		}
		return int + fl;
	},
	displayPhone: function (val) {
		if (!val) return '';

		const arr = val.split('.');
		let phone = _.toArray(arr[2] || '');
		phone.reverse();
		const res = [];
		_(phone).each((c, i) => {
			res.push(c);
			if ((i === 1 || i === 3) && phone.length > i + 1) { res.push('-'); }
		});
		res.reverse();
		phone = res;

		return `+${arr[0]} ${arr[1]} ${phone.join('')}`;
	},
	isSimple (arg) {
		if (arg == null) { return true; }

		if (!_.isObject(arg)) { return true; }

		if (_.isDate(arg) || _.isString(arg) || _.isNumber(arg)) { return true; }

		return false;
	},
	toBoolean (arg) {
		if (arg == null) return false;
		if (_.isBoolean(arg)) return arg;
		if (_.isNumber(arg)) return !isNaN(arg) && arg !== 0;
		arg = arg.toString().toLowerCase();
		if (!arg.length) return false;
		return !(['0', 'no', 'false'].indexOf(arg) > -1);
	},
	buildViewByKey () {
		return buildViewByKey(...arguments);
	},
	resultArgs (context, key, args) {
		if (!context) return;
		let val = context[key];
		if (typeof val === 'function') {
			if (Array.isArray(args) && args.length > 0) {
				val = val.apply(context, args);
			} else {
				val = val.call(context);
			}
		}
		return val;
	}
});
