import { _ } from 'vendors';
import data from 'bus/data';
import Collection from './models/collection';

const ProductTypes = Collection.extend({
	noApi: true
});

const productTypes = new ProductTypes();
export const productTypesCollection = productTypes;

data.productTypes = function (...args) {
	return data.request('productTypes', ...args);
};

data.productTypeName = function (id) {
	if (id == null || id === '') { return ''; }
	return data.productTypes(id, { transform: (e) => e && e.display('name') });
};

data.reply('productTypes', function (id, opts) {
	opts || (opts = {});
	if (arguments.length === 0) return productTypes;
	if (id == null) return null;

	const productType = productTypes.get(id);
	if (!_.size(opts) || !productType) return productType;

	if (opts.transform) { return opts.transform(productType); }

	return productType;
});


export function productTypeName(id) {
	if (id == null || id === '') { return ''; }
	const productType = productTypesCollection.get(id);
	if (!productType) { return ''; }
	return productType.display('name') || '';
}

export default productTypes;
