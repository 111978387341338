
function loadScript (url, dependOn) {
	const script = document.createElement('script');
	script.src = url;

	const scriptPromise = new Promise((resolve, reject) => {
		script.onload = () => resolve();
		script.onerror = () => reject();
	});


	Promise.all([dependOn]).then(
		() => {
			document.body.appendChild(script);
		}
	);

	return scriptPromise;
}

export default loadScript;

