import { _ } from 'vendors';
import View from 'base/view';

export const ButtonView = View.extend({
	tagName: 'button',
	className: 'header-button',
	cssClassModifiers: [
		m => m.get('active') ? 'active' : ''
	],
	template: _.template('<%= name %>'),
	clickEventTrigger: 'tab:click',
	isAvailable () {
		return true;
	},
	isClickAllowed () {
		return this.model.get('active') !== true && this.isAvailable();
	},
	events: {
		click (e) {
			if (this.isClickAllowed()) {
				this.trigger(this.getOption('clickEventTrigger'), this);
			}
		}
	}
});

export const ProcButtonView = ButtonView.extend({
	cssClassModifiers: [
		(m, v) => !v.isAvailable() ? 'disabled' : ''
	],
	initialize () {
		this.parentModel = this.getOption('parentModel');
		this.listenTo(this.parentModel, 'change:typeDetails.items', this.render);
	},
	isAvailable () {
		const fltr = this.model.get('filter');
		return fltr(this.parentModel);
		// let items = this.parentModel.get('typeDetails.items');
		// return items.some(item => !item.tempProcessId);
	}
});
