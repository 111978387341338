import { _, moment } from 'vendors';
import CollectionView from 'base/collection-view';
import View from 'base/view';
import { ModelPropertyViewWithTemplate, ModelPropertyView } from 'components/views';

// import Item from './list-item';
import Card from 'components/ui-card/item';
// import ListItem from 'components/ui-list/item';

import ActionsPopover from 'behaviors/actions-popover';
import user from 'app/user';
import busData from 'bus/data';
import { getPointsString, toNewPoints } from '../models/utils';
import { BaseTextView } from '../../../base/base-view';

const TaskCardActionsPopover = ActionsPopover.extend({
	reference: null
});

// const viewClass = Item.extend({ tagName: 'div' });

// const LastComment = ListItem.extend({
// 	className: 'last-comment',
// 	text: m => m.get('lastComment.text'),
// 	smallText: m => m.empName('lastComment.authorId')
// });

const DateTimeView = ModelPropertyViewWithTemplate.extend({
	tagName: 'span',
	className: 'inline-item',
	template: _.template('<span class="date"><%=date%></span><span class="time"><%=time%></span>'),
	templateContext () {
		const value = this.getPropertyValue() || new Date();
		const di = Date.info(value);
		let date;
		let time;
		// if (di.calendar.month > 4) {
		// 	date = moment(value).fromNow();
		// } else if (di.calendar.month === 0) {
		// 	date = moment(value).format('DDD');
		// }

		// console.log(this);

		if (di.calendar.months === 0 && di.calendar.days < 14) {
			date = moment(value).format('Do');
			time = moment(value).format('в HH:mm');
		} else if (di.calendar.months === 0) {
			date = moment(value).format('Do');
		} else if (di.calendar.years === 0 || (di.calendar.years > 0 && di.calendar.months < 3)) {
			date = moment(value).format('Do MMM');
		} else {
			date = moment(value).fromNow();
		}

		return {
			date,
			time
		};
	}
});

const IconView = ModelPropertyViewWithTemplate.extend({
	tagName: 'span',
	addClass: 'icon',
	// className(){
	// 	return 'icon ' + this.property.replace(/\./gm,'-');
	// },
	template: _.template('<i></i>')
});

function propertyOptions (model, property, ext) {
	return _.extend({ model, property }, ext);
}



const SystemView = CollectionView.extend({
	className: 'system',
	renderAllCustoms: true,
	customViews () {
		const p = _.partial(propertyOptions, this.model);
		const checker = this.model.display('checkerId');
		const responsible = this.model.display('responsibleId');
		const department = this.model.get('departmentId');
		const depName = this.model.display('departmentId');
		let hasDuration = this.model.get('aproximateDuration');
		if (hasDuration && hasDuration === 'none') {
			hasDuration = undefined;
		}

		let prio = '';
		let oriPrio = '';
		let titlePrio = '';
		
		let txtPrio = this.model.display('urgentImportantPriority') || '';
		if (txtPrio && txtPrio.trim() === 'не указано') {
			txtPrio = ''
		}
		if (txtPrio) {
			oriPrio = txtPrio;
			const chunks = txtPrio.split(/,\s+/);
			txtPrio = (chunks[0] && !chunks[0].startsWith('н') ? chunks[0][0] : '')
			+ (chunks[1] && !chunks[1].startsWith('н') ? chunks[1][0] : '')
		}
		let numPrio = this.model.display('numericPriority');
		if (numPrio === 'не указано') {
			numPrio = undefined;
		}
		if (numPrio || txtPrio) {
			console.warn('#priority', `[${txtPrio}]`, `[${numPrio}]`);
			prio = [txtPrio,numPrio].join(' ');
			titlePrio = [oriPrio, numPrio].join(' ');
		}

		return [
			new IconView(p('options', { behaviors: [TaskCardActionsPopover] })),
			this.model.isClosed() ? null : new DateTimeView(p('dates.remindAt', { addClass: 'date deadline', title: 'дата напоминая: ' + moment(this.model.getRemindAt()).format('Do MMM YYYY, в HH:mm') })),
			this.model.isNotClosed() ? null : new DateTimeView(p('dates.closed', { addClass: 'date closed', title: 'дата завершения: ' + moment(this.model.getDate('closed')).format('Do MMM YYYY, в HH:mm') })),
			new ModelPropertyView(p('state', { addClass: this.model.get('result'), useDisplayValue: true, addValueToClassName: true, title: 'статус' })),
			new ModelPropertyView(p('responsibleId', { className: 'responsible', useDisplayValue: true, title: `ответственный: ${responsible}, проверяющий: ${checker}` })),
			department && new ModelPropertyView(p('departmentId', { className: 'department', useDisplayValue: true, title: `отдел: ${depName}` })),
			hasDuration && new ModelPropertyView(p('aproximateDuration', { className: 'duration', useDisplayValue: true, title: `предположительная длительность` })),
			prio && new BaseTextView({ text: prio, className: 'priority', attributes: { title: 'приоритет: ' + titlePrio } })
			// new ModelPropertyView(p('checkerId', { className: 'checker', useDisplayValue: true })),
		];
	},
	events: {
		'click icon.options' (e) {
			e.stopPropagation();
		}
	}
});

const TextSubject = View.extend({
	className: 'toDo',
	template: _.template('<%= text %>'),
	templateContext () {
		const text = this.getOption('text', { args: [this.model, this] });
		return {
			text
		};
	}
});

const SubjectView = CollectionView.extend({
	renderAllCustoms: true,
	transportRequestText () {
		const det = this.model.get('typeDetails') || {};
		if (det.type === 'requestTransport' || det.type === 'transportRequest') {
			return getPointsString(det.points) || '[не установлоено]';
		} else {
			if (!det.points) {
				// console.log(det);
				det.points = toNewPoints(det);
			}
			return getPointsString(det.points) || '[не установлоено]';
			// let cnt = det.fromAddress.contragent || {};
			// let res = [];
			// cnt.name && res.push(cnt.name) || res.push('контрагент не указан');
			// det.cargo && res.push(det.cargo);
			// return res.join(', ');
		}
	},
	customViews () {
		const p = _.partial(propertyOptions, this.model);
		const itr = this.model.isTransportRequest();
		const model = this.model;
		return [
			new IconView(p('type', { addValueToClassName: true, title: 'тип задачи: ' + this.model.display('type') })),
			itr
				? new TextSubject({ model, text: this.transportRequestText() })
				: new ModelPropertyView(p('toDo', { useDisplayValue: true }))
		];
	}
});

const ProcessView = CollectionView.extend({
	renderAllCustoms: true,
	customViews () {
		const entity = this.model.get('process');
		if (!_.isObject(entity)) return;
		return [
			new IconView(),
			new TextSubject({ model: this.model, text: entity.name, className: 'process-name' })
		];
		// let p = _.partial(propertyOptions, this.model);
		// let itr = this.model.isTransportRequest();
		// let model = this.model;
		// return [
		// 	new IconView(p('type', { addValueToClassName: true, title: 'тип задачи: ' + this.model.display('type') } )),
		// 	itr ? new TextSubject({ model, text: this.transportRequestText() })
		// 		: new ModelPropertyView(p('toDo', { useDisplayValue: true }))
		// ];
	}
});


const LastCommentPropertyView = ModelPropertyView.extend({
	getDisplayValue () {
		const value = ModelPropertyView.prototype.getDisplayValue.apply(this, arguments);
		if (!value) return value;
		return `<em class="author">${this.model.display('lastComment.authorId')}</em>: ${value}`;
	},
	title () {
		let title = this.getDisplayValue() || '';
		title = title.replace(/<[^>]+>/g, '');
		return title;
	}
});

const LastCommentView = CollectionView.extend({
	customViews () {
		const comm = this.model.get('lastComment.text');
		if (!comm) return;
		const p = _.partial(propertyOptions, this.model);
		return [
			new IconView(p('comment')),
			new LastCommentPropertyView(p('lastComment.text', { useDisplayValue: true }))
		];
	}
});

const IconTextView = View.extend({
	tagName: 'span',
	className: 'task-entity',
	cssClassModifiers: [(m, v) => v.options.key],
	onBeforeRender () {
		this._setAttributes({
			title: this.getOption('title')
		});
	},
	template: _.template('<span class="icon <%= key %>"><i></i></span><span class="name"><%= text %></span>'),
	templateContext () {
		return _.pick(this.options, 'key', 'text');
	}
});

const EntitiesView = CollectionView.extend({
	addTaskEntityView (key, arr, title) {
		const entity = this.model.get(key);
		if (!_.isObject(entity)) return;

		const view = new IconTextView({
			key,
			text: entity.name,
			title: title + entity.name
		});
		arr.push(view);
	},
	addEmployeeNameView (key, arr, title) {
		const id = this.model.get(key);
		if (!id || user.isMe(id)) return;
		const view = new IconTextView({
			key,
			text: busData.employeeName(id),
			title
		});
		arr.push(view);
	},
	customViews () {
		const res = [];
		this.addTaskEntityView('contragent', res, 'контрагент: ');
		// this.addTaskEntityView('process', res, 'процесс: ');
		this.addEmployeeNameView('checkerId', res, 'проверяющий');
		if (this.model.get('checkerId') !== this.model.get('creatorId')) {
			this.addEmployeeNameView('creatorId', res, 'инициатор');
		}

		if (this.model.get('dates.deadline')) {
			const view = new IconTextView({
				key: 'deadline',
				text: moment(this.model.getDeadline()).fromNow(),
				title: 'крайний срок: ' + this.model.display('dates.deadline')
			});
			res.push(view);
		}

		return res;
	}
});

export default Card.extend({
	className() {
		return this.getOption('showAsRow', true) ? 'line' : 'card lined';
	},
	addClass: 'whole-btn task',
	cssClassModifiers: [
		m => m.get('result'),
		m => m.get('state'),
		m => m.isMy() ? 'my' : 'not-my',
		m => m.isInPast() ? 'past' : '',
		m => m.isStrict() ? 'strict-date' : '',
		m => m.isDeadlineNear() ? 'deadline-near' : '',
		m => 'type-' + m.get('type'),
		m => 'form-' + m.get('form'),
		m => m.get('departmentId') ? 'for-department' : '',
		m => !m.get('responsibleId') ? 'responsibless' : '',
		(m,v) => v.getOption('showAsRow', true) ? 'as-row' : 'as-plate',
	],
	cardLines: {
		system: { viewClass: SystemView },
		subject: { viewClass: SubjectView },
		process: { viewClass: ProcessView },
		// 'modelView':{ viewClass },
		lastComment: { viewClass: LastCommentView },
		entities: { viewClass: EntitiesView }
	},
	clickAction: 'open:card'
});


