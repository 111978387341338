import View from 'base/view';


export const HeaderTab = View.extend({
	className: 'header-tab',
	template: '<%= name %>',
	cssClassModifiers: [
		(m) => m.get('active') ? 'active' : ''
	],
	triggers: {
		click: 'tab:click'
	}
});
