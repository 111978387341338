import { _ } from 'vendors';
import busData from 'bus/data';
const _meas = v => busData.measures(v, 'short');

export default {

	inputMeasureId: {
		display: {
			label: 'единица измерения',
			ifEmtpy: '&mdash;',
			transform: (value, opts, model) => {
				if (!value) {
					return 'установите';
				}
				return busData.measures(value, 'short');
			}
		},
		sourceValues: () => busData.measures(),
		controlType: 'select'
	},

	baseMeasureId: {
		display: {
			label: 'учётная единица измерения',
			ifEmtpy: '&mdash;',
			transform: (value, opts, model) => {
				return busData.measures(value, 'short');
			}
		}
	},

	baseUnitsInInputUnit: {
		display: {
			label: 'сколько это учётных единиц',
			ifEmtpy: '&mdash;',
			transform: (value, opts, model) => {
				const input = model.get('inputMeasureId');
				const b_in_s = model.get('baseUnitsInInputUnit');
				if (!value || !input || !(b_in_s > 0)) {
					return 'установите';
				}
				const base_m = model.display('baseMeasureId');
				const inp_m = _meas(input);
				return `1 ${inp_m} = ${b_in_s} ${base_m}`;
                // return model.baseUnitsInStoreUnit;
			}
		}
	},

	amount: {
		display: {
			label: 'Количество',
			ifEmpty: '&mdash;'
		},
		type: 'number'
	},

	calculatedAmount: {
		display: {
			label: 'результат',
			transform (v, o, model) {
				const b_in_inp = model.get('baseUnitsInInputUnit');
				const inputAmount = model.get('amount');
				const in_m = model.get('inputMeasureId');
				const b_m = model.get('baseMeasureId');
				if (!(b_in_inp > 0 && inputAmount > 0 && in_m && b_m)) {
					return 'заполните форму';
				}
				const inputText = _.displayNum(inputAmount, 4) + ' ' + _meas(in_m);
				const baseText = _.displayNum(inputAmount * b_in_inp, 4) + ' ' + _meas(b_m);
				const add = b_m !== in_m ? ` (${inputText})` : '';
				return baseText + add;
			}
		}
	},

	maximumAmount: {
		display: {
			label: 'Доступное количество',
			transform (v, o, model) {
				const b_m = model.get('baseMeasureId');
				const amount = model.get('maximumAmountInBaseUnits');
				return _.displayNum(amount, 4) + ' ' + _meas(b_m);
			}
		}
	}
};
