import View from 'components/ui-card/item';
import BaseViewClass from './list-item';

const viewClass = BaseViewClass.extend({

});

export default View.extend({
	cardLines: {
		modelView: (m, v) => ({ viewClass, textAction: v.getOption('textAction') })
	}
});
