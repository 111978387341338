import { _, $ } from 'vendors';
import View from 'base/view';
import { InlineControlMixin } from '../common/inline-mixin';
// import Bb from 'backbone';

const optionsMappers = {
	singleLabel: (label, selected) => {
		const selectedTxt = selected === label ? ' selected' : '';
		return `<option${selectedTxt}>${label}</option>`;
	},
	withValue: (label, value, selected) => {
		if (value == null) { value = ''; }
		const selectedTxt = selected === value ? ' selected' : '';
        // console.log('-is selected?-', selected, value);
		return `<option value="${value}"${selectedTxt}>${label}</option>`;
	}
};

export const InlineSelectControl = View.extend({
	template: '<label><%= label %></label><select<%= selectAttrs %>><%= options %></select>',

	...InlineControlMixin,

	_delegateControlEvents () {
		this.delegate('change', 'select', (event) => {
			let val = $(event.target).val();
			this.value = val;
			if (val === '') { val = undefined; }
			this.trigger('control:change', val);
            // console.log('!-chpok-!', $(event.target).val());
		});
	},

	buildSelectOptions () {
		const items = this.getOption('selectItems', { args: [this] });

		return items;
	},

	_buildSelectOptions () {
		const items = this.buildSelectOptions();
		if (Array.isArray(items)) {
			let withValues;
			_.each(items, item => {
				if (!withValues) { withValues = Object.keys(item).length > 1; }
			});

			const emptyLabel = this.getOption('emptyLabel');
			const emptyValue = this.getOption('emptyValue');
			if (emptyLabel != null) {
				const emptyItem = { label: emptyLabel };
				if (withValues) {
					emptyItem.value = emptyValue;
				}
				items.unshift(emptyItem);
			}

			const mapper = withValues
				? item => optionsMappers.withValue(item.label, item.value, this.value)
				: item => optionsMappers.singleLabel(item.label, this.value);

			return items.map(mapper).join('');
		} else {
			return '';
		}
	},

	templateContext () {
		const options = this._buildSelectOptions();
		const selectAttrs = this._buildControlAttrs();
		const label = this.getLabel();
		return {
			label,
			selectAttrs,
			options
		};
	}
});
