// import { UiTableRow } from '../table-row/UiTableRow';

import { UiTableRow } from '../ui2/table-row/UiTableRow';

export const TableRowView = UiTableRow.extend({
	constructor: function() {
		UiTableRow.apply(this, arguments);
		const url = this.getOption('rowClickUrl', true);
		if (url) {
			this.url = url;
			this.initializeClickableMixin();
		}
	},
	baseClassName: 'ui2-block with-wrapper',
	useWrapper: true,
	renderOnModelChange: true,
	rerender () {
		return this.render();
	}
});
