import childView from './year';
import CollectionView from 'base/collection-view';
export default CollectionView.extend({
	className: 'c-dt-years',
	childView,
	childViewEvents () {
		return {
			'click' (view) {
				this.model.trigger('year:selected', view.model.id);
			}
		};
	}
});
