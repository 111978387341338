import { _ } from 'vendors';
import editValue from 'bus/edit';
import ControlsMixin from 'components/controls/mixin';
import Textarea from 'components/controls/textarea';
import View from 'base/view';
import mix from 'helpers/mix';

const BaseControl = mix(View).with(ControlsMixin);

const Comment = BaseControl.extend({
	className: 'provide-comment-control',
	template: _.template(`
<div class="additional"></div>
<div class="comment-holder">
	<label><%= commentHeader %></label>
	<div class="comment"></div>
</div>
`),
	readyOnRender: true,
	initialize () {
		this.value = {};
	},
	regions: {
		additional: '.additional',
		comment: '.comment'
	},
	onRender () {
		this.showAdditionalChunk();
		this.showCommentChunk();
	},
	showAdditionalChunk () {
		let options = this.getOption('controlViewOptions');
		const Control = this.getOption('controlView');
		if (!Control) return;
		options = _.extend({}, options, {
			proxyTo: this,
			controlName: 'additional'
		});
		const view = new Control(options);

		this.showChildView('additional', view);
	},
	showCommentChunk () {
		const options = {
			proxyTo: this,
			controlName: 'comment',
			value: this.value.comment
		};
		const view = new Textarea(options);
		this.showChildView('comment', view);
	},
	templateContext () {
		return {
			commentHeader: this.getOption('commentHeader')
		};
	},
	controlsEvents: {
		'additional:change' (value) {
			let trigger = false;
			if (_.isObject(value)) {
				_.extend(this.value, value);
				trigger = true;
			} else {
				const prop = this.getOption('controlProperty');
				if (_.isString(prop) && !!prop) {
					this.value[prop] = value;
					trigger = true;
				}
			}
			trigger && this.triggerChange();
		},
		'comment:change' (value) {
			this.value.comment = value;
			this.triggerChange();
		}
	},
	isValueValid (value) {
		if (!value || !value.comment) return false;

		const validate = this.getOption('validate', { force: false });

		if (_.isFunction(validate)) {
			const res = validate(value);
			if (!res) return false;
		}

		return value.comment.length > 3;
	}
});


export default function provideComment ({
	context,
	header,
	commentHeader,
	controlView,
	controlViewOptions,
	controlProperty,
	validate,
	onConfirm,
	onSuccess,
	onError
} = {}) {
	!onConfirm && (onConfirm = () => {});
	!onSuccess && (onSuccess = () => {});
	!onError && (onError = () => {});

	editValue.do({
		header,
		controlView: Comment,
		controlViewOptions: {
			commentHeader,
			controlView,
			controlViewOptions,
			controlProperty,
			validate
		},
		resetButton: false,
		applyLabel: 'подтвердить',
		cancelLabel: 'отставить'
	}).then(
		(...args) => onConfirm.apply(context, args).then(onSuccess, onError),
		() => {}
	);
}
