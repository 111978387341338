import { _ } from 'vendors';
import action from 'helpers/action';
import Collection from '../models/collection';
import convert from '../convert';
const RightsCollectionV2 = Collection.extend({
	parse (data) {
		if (_.isArray(data)) {
			return data;
		} else if (_.isObject(data)) {
			return convert(data);
			// _.map(data, (value, id) => ({ id, value }));
		} else {
			return {};
		}
	},
	toJSON () {
		return this.reduce((memo, model) => {
			memo[model.id] = model.get('value');
			return memo;
		}, {});
	}
});

function initializeActions () {
	this._actions || (this._actions = []);
	const update = action('update:rights', 'изменить права', this.rightsConfig.update.rights, { hidden: true });
	this._actions.push(update);
}

// function initializeCollection () {
// 	this.registerNestedCollection({
// 		name: 'rights',
// 		Collection,
// 		transformOnSet: (col) => col.reduce((memo, model) => {
// 			memo[model.id] = model.get('value');
// 			return memo;
// 		}, {})
// 	});
// }

export default (Base) => {
	const Ctor = Base; // Base.NestedCollections ? Base : NestedCollections(Base);

	return Ctor.extend({
		constructor () {
			// this.initEntityWithRightsHandlers();
			Base.apply(this, arguments);
			this.registerNestedEntity('rights', { class: RightsCollectionV2, parse: true });
			// initializeCollection.call(this);
			initializeActions.call(this);
			// this._actions || (this._actions = []);
			// this.initEntityWithRights();
		},
		getRights () {
			return this.entity('rights');
		},
		/*
		initEntityWithRights(){
			let update = action('update:rights','изменить права', this.rightsConfig.update.rights, {hidden:true});
			this._actions.push(update);
		},
		getRights(opts = {}){
			let groupRights = _.extend({}, this.get('rights'));
			return convert(groupRights, _.extend(opts, { principalId: this.id }));
		},
		getRightsCollection(){
			if(!this.rightsCollection) {
				this.rightsCollection = new Collection(null, { principalId: this.id });
			}
			return this.rightsCollection;
		},
		*/


		// rights actions
		updateRights (model, value) {
			return model.patch({ value }, { wait: true });
		},
		actionUpdateRights (action, opts = {}) {
			if (!opts.rightsModel) { throw new Error('`entity:with:rights` updateRights error. rightsModel missing'); }

			this.updateRights(opts.rightsModel, opts.rightsValue);
		}
	});
};
