import { _ } from 'vendors';
import View from 'base/view';
import Select from 'components/controls/select';
import mix from 'helpers/mix';
import BaseControlMixin from 'components/controls/mixin';
import template from './template.html';
import SegmentCollection from './segment-collection';
import Segments from './segments';

const defaultSegments = [
	{
		id: 'all',
		name: 'все',
		filter: () => true,
		collection: undefined
	},
	{
		id: 'selected',
		name: 'отобранные',
		filter: (v) => v.isSelected(),
		collection: undefined
	}
];

export default mix(View).with(BaseControlMixin).extend({
	template,
	regions: {
		segments: { el: '.segments', replaceElement: true },
		entries: { el: '.entries', replaceElement: true }
	},
	onRender () {
		this.showSegments();
		// this.applySegmentation();
		this.showSelect();
	},


	getSegmentsCollection () {
		let raw = (this.getOption('segments') || []).concat(defaultSegments);

		const appendSegments = this.getOption('appendSegments') || [];
		const prependSegments = this.getOption('prependSegments') || [];

		let exclude = this.getOption('excludeSegments');
		if (_.isString(exclude)) { exclude = exclude.split(/\s*,\s*/g); }
		exclude && (raw = _.filter(raw, m => exclude.indexOf(m.id) < 0));

		raw.unshift(...prependSegments);
		raw.push(...appendSegments);

		return new SegmentCollection(raw);
	},
	showSegments () {
		/*
		var exclude = this.getOption('excludeSegments');
		if(_.isString(exclude))
			exclude = exclude.split(/\s*,\s* /g);

		let viewFilter = _.isArray(exclude) && (v => exclude.indexOf(v.model.id) < 0);
		let view = new Segments({
			collection: this.getSegmentsCollection(),
			viewFilter,
		});
		*/
		const collection = this.segmentsCollection = this.getSegmentsCollection();
		const view = this.segments = new Segments({ collection });
		this.showChildView('segments', view);
		const segment = collection.first();
		segment.trigger('settle', segment);
	},
	applySegmentation () {
		// let view = _(this.segments.children._views).find(f => f.isRendered());
		// var segment = view.model;
		// segment.trigger('settle',segment);

		// var segment = this.segments.first();
		// segment.trigger('settle',segment);
	},
	getSelectOptions () {
		// was: return  _.extend({}, this.optinos, {proxyTo:this});
		// this.optinos - possible type, not checked;
		console.log('## - optinos is:', this.optinos);
		return _.extend({}, { proxyTo: this });
	},
	SelectView: Select,
	showSelect () {
		const options = this.getSelectOptions();

		if (this.entriesFilter) {
			options.viewFilter = this.entriesFilter;
		}
		const view = this.entries = new this.SelectView(options);

		// this.listenTo(view,'childview:render:children', () => this.trigger('refresh'));

		this.showChildView('entries', view);
	},
	setEntriesFilter (filter) {
		this.entriesFilter = filter;
		if (!this.entries) return;
		this.entries.setViewFilter(filter);
	},
	childViewEvents: {
		'segment:select' (segment) {
			this.setEntriesFilter(segment && segment.get('filter'));
		}
	},
	childViewTriggers: {
		'childview:render:children': 'refresh'
	},
	controlsEvents () {
		const controlName = (this.getOption('controlName') || 'control');
		const event = controlName + ':change';
		return {
			[event] (value) {
				this.triggerChange(value);
			}
		};
	}
});
