import { _ } from 'vendors';
function toBoolean (value) {
	const trueValues = ['true', '1', '-1', 1, -1, 'yes', 'да'];
	const falseValues = ['false', '0', '-0', 0, -0, 'no', 'нет'];
	if (_.isBoolean(value)) { return value; }
	if (_.isString(value) || _.isNumber(value)) {
		const sValue = value.toString().trim().toLowerCase();
		if (trueValues.indexOf(sValue) > -1) { return true; } else if (falseValues.indexOf(sValue) > -1) { return false; }
	}
}
export default function transformBeforeGet (value, valueType) {
	valueType || (this && (valueType = this.valueType));
	switch (valueType) {
	case 'boolean':
		return toBoolean(value);
	default:
		return value;
	}
}
