import View from 'base/view';

export const EmptyBlockView = View.extend({
	template: '<%= text %>',
	templateContext () {
		return {
			text: this.getOption('text') || 'подождите, идёт загрузка'
		};
	}
});
