// import Model from 'base/model';
// import Collection from 'base/collection';
// import View from 'base/view';
// import BaseView from 'pages/view';
// import UiListItem from 'components/ui-list/item';
// import CollectionView from 'base/collection-view';
// import BaseCollectionView from 'pages/collection-view';
import UiCardBlock from 'components/ui-card-block/block';
import ModelProperties from 'components/ui-model-card/properties';
import h from 'components/ui-model-card/helpers';
import JoinedProducts from './joined-products';
import JoinedContragents from './joined-contragents';
import { ActivityListUiBlock } from 'mods/logs/views';
import user from 'app/user';

import { PriceChangesUiBlock } from './priceChanges';
import { ProductProcessesUiBlock } from './processes';
import BaseLayout from './base-layout';

// import { UiStoreBlock } from './store';
import { UiProdFromBlock } from './prod-cycle';
import { UiUsedInProdBlock } from './used-in-prod';
// ss
// function DEPRECATED () {
// 	// export default
// 	BaseCollectionView.extend({
// 		className: 'staff-emp',
// 		// template: BaseView.emptyTemplate,
// 		initialize () {

// 			/*
// 			let m = this.model;

// 			this.createNested('main', {
// 				viewClass: ModelProperties.extend({className:'card lined with-border'}),
// 				viewOptions: {
// 					header: { text: 'основное', addClass:'bg-main-dark'},
// 					properties: [
// 						h.ui('fullName', { editButton: m.mainInfoCanBeChanged() } ),
// 						h.ui('shortName', { editButton: m.mainInfoCanBeChanged() } ),
// 						h.ui('innerName', { editButton: m.mainInfoCanBeChanged() } ),
// 						h.ui('entityName', { editButton: user.checkRights({ resourcesProduction: 'productsAdmin' }) } ),
// 						h.ui('unitMeasureId', { editButton: m.mainInfoCanBeChanged() } ),
// 						h.ui('purchaseUnitMeasureId', { editButton: m.mainInfoCanBeChanged() } ),
// 						h.ui('typeId', { editButton: m.mainInfoCanBeChanged() } ),
// 						h.ui('origin', { editButton: m.mainInfoCanBeChanged() } ),
// 						h.ui('purpose', { editButton: m.mainInfoCanBeChanged() } ),
// 						h.ui('note', { editButton: m.mainInfoCanBeChanged() } ),
// 					],
// 				},
// 				regionTemplate: { replaceElement: true }
// 			});


// 			this.createNested('producedFrom', {
// 				viewClass: UiCardBlock,
// 				viewOptions: {
// 					header:{ text: 'производится из', addClass:'bg-main-dark'},
// 					content: JoinedProducts,
// 					contentOptions(){
// 						return {
// 							tagName:'ul',
// 							addClass: 'scrolled-y',
// 							collection: m.getJoinedProducts(),
// 							junction: 'production',
// 							viewFilter: v => v.model.get('joinedAs') === 'production' && !!v.model.get('child'),
// 							childViewOptions:{
// 								tagName:'li',
// 							}
// 						};
// 					},
// 					action:'add:produced:from'
// 				},
// 				regionTemplate: { replaceElement:true }
// 			});

// 			this.createNested('sourceFor', {
// 				viewClass: UiCardBlock,
// 				viewOptions: {
// 					header:{ text: 'циклы производства', addClass:'bg-main-dark'},
// 					content: JoinedProducts,
// 					contentOptions(){
// 						return {
// 							tagName:'ul',
// 							addClass: 'scrolled-y',
// 							collection: m.getJoinedProducts(),
// 							junction: 'production',
// 							viewFilter: v => v.model.get('joinedAs') === 'production' && !!v.model.get('parent'),
// 							childViewOptions:{
// 								tagName:'li',
// 								unitMeasureId: m.get('unitMeasureId'),
// 							}
// 						};
// 					},
// 					//action:'add:produced:from'
// 				},
// 				regionTemplate: { replaceElement:true }
// 			});

// 			this.createNested('store', {
// 				viewClass: ModelProperties.extend({className:'card lined with-border'}),
// 				viewOptions: {
// 					header: { text: 'склад', addClass:'bg-main-dark'},
// 					model: this.model.getStoreModel(),
// 					properties: [
// 						h.ui('currentAmount', { editButton: false } ),
// 						h.ui('normalRemainder', { editButton: m.mainInfoCanBeChanged() } ),
// 						h.ui('averageMonthConsumption', { editButton: m.mainInfoCanBeChanged() } ),
// 						h.ui('unitMeasureId', { editButton: m.mainInfoCanBeChanged() } ),
// 						h.ui('unitsInStoreUnit', { editButton: m.mainInfoCanBeChanged() } ),
// 						h.ui('storeUnitToUnitLoose', { editButton: m.mainInfoCanBeChanged() } ),
// 					],
// 				},
// 				regionTemplate: { replaceElement: true }
// 			});

// 			this.createNested('joinedContragents', {
// 				viewClass: UiCardBlock,
// 				viewOptions: {
// 					header:{ text: 'контрагенты', addClass:'bg-main-dark'},
// 					content: JoinedContragents,
// 					contentOptions(){
// 						return {
// 							tagName:'ul',
// 							addClass: 'scrolled-y',
// 							collection: m.getJoinedContragents(),
// 							childViewOptions:{
// 								tagName:'li',
// 								type: 'contragent'
// 							}
// 						};
// 					},
// 					action:'add:joined:contragent'
// 				},
// 				regionTemplate: { replaceElement:true }
// 			});

// 			this.createNested('activity', {
// 				viewClass: ActivityListUiBlock,
// 				viewOptions:{
// 					model: this.model,
// 					fileSvc: this.fileSvc,
// 					activeFilterId: 'all'
// 				}
// 			});

// 			*/

// 		}
// 	});
// }

export default BaseLayout.extend({
	modelEvents: {
		'change:isAbstractGroup' () {
			this.render();
		}
	},
	rootContents () {
		const m = this.model;
		const isAbstract = m.get('isAbstractGroup') === true;


		const usualProperties = isAbstract
			? []
			: [
				h.ui('storeNormalRemainder', { editButton: m.mainInfoCanBeChanged(), third: true }),
				h.ui('storeAverageDaysToPurchase', { editButton: m.mainInfoCanBeChanged(), third: true }),
				h.ui('storeAverageConsumptionPerDay', { editButton: m.mainInfoCanBeChanged(), third: true }),

				h.ui('typeId', { editButton: m.mainInfoCanBeChanged() }),
				h.ui('origin', { editButton: m.mainInfoCanBeChanged() }),
				h.ui('purpose', { editButton: m.mainInfoCanBeChanged() })

			];

		return [
			new ModelProperties({
				className: 'card lined with-border double-width product-properties-box',
				classNames: [
					v => v.model.get('isArchived') ? 'archived' : '',
					v => v.model.get('isDeleted') ? 'deleted' : '',
					v => v.model.get('isAbstractGroup') ? 'abstract-group' : ''
				],
				header: { text: 'основное', addClass: 'bg-main-dark' },
				properties: [

					h.ui('isAbstractGroup', { editButton: user.checkRights({ resourcesProduction: 'manageAbstractGroups' }) }),
					h.ui('fullName', { editButton: m.mainInfoCanBeChanged() }),
					h.ui('shortName', { editButton: m.mainInfoCanBeChanged() }),
					// h.ui('innerName', { editButton: m.mainInfoCanBeChanged() } ),
					h.ui('entityName', { editButton: user.checkRights({ resourcesProduction: 'productsAdmin' }) }),
					h.ui('notNeedNegotiateOnPurchase', { editButton: user.checkRights({ resourcesProduction: 'productsAdmin' }) }),
					h.ui('unitMeasureId', { editButton: m.mainInfoCanBeChanged(), half: true }),
					h.ui('purchaseUnitMeasureId', { editButton: m.mainInfoCanBeChanged(), half: true }),
					h.ui('storeUnitMeasureId', { editButton: m.mainInfoCanBeChanged(), half: true }),
					h.ui('baseUnitsInStoreUnit', { editButton: m.mainInfoCanBeChanged(), half: true }),


					...usualProperties,

					h.ui('note', { editButton: m.mainInfoCanBeChanged() }),

					h.ui('isArchived', { editButton: user.checkRights({ resourcesProduction: 'productsEdit' }), half: true }),
					h.ui('isDeleted', { editButton: user.checkRights({ admin: true, resourcesProduction: 'productsEdit' }), half: true })


				],
				model: this.model
			})
		];
	},
	activityContents () {
		return [
			new ActivityListUiBlock({
				model: this.model,
				fileSvc: this.fileSvc,
				activeFilterId: 'all',
				logConfig: {
					entities: ['productionCycle']
				}
			})
		];
	},
	productionContents () {
		const m = this.model;
		return [
			this.buildColumnView(
				[
					new UiCardBlock({
						model: this.model,
						header: { text: 'производится из', addClass: 'bg-main-dark' },
						content: JoinedProducts,
						contentOptions () {
							return {
								model: this.model,
								tagName: 'ul',
								addClass: 'scrolled-y',
								collection: m.getJoinedProducts(),
								junction: 'production',
								viewFilter: v => v.model.get('joinedAs') === 'production' && !!v.model.get('child'),
								childViewOptions: {
									tagName: 'li'
								}
							};
						},
						action: 'add:produced:from'
					})
				]
			),
			this.buildColumnView(
				[
					new UiCardBlock({
						model: this.model,
						header: { text: 'участвует в производстве', addClass: 'bg-main-dark' },
						content: JoinedProducts,
						contentOptions () {
							return {
								model: this.model,
								tagName: 'ul',
								addClass: 'scrolled-y',
								collection: m.getJoinedProducts(),
								junction: 'production',
								viewFilter: v => v.model.get('joinedAs') === 'production' && !!v.model.get('parent'),
								childViewOptions: {
									tagName: 'li',
									unitMeasureId: m.get('unitMeasureId')
								}
							};
						}
						// action:'add:produced:from'
					})
				]
			)
		];
	},
	purchaseContents () {
		const m = this.model;
		return [
			new UiCardBlock({
				model: this.model,
				addClass: 'double-width',
				header: { text: 'контрагенты', addClass: 'bg-main-dark' },
				content: JoinedContragents,
				contentOptions () {
					return {
						tagName: 'ul',
						addClass: 'scrolled-y',
						collection: m.getJoinedContragents(),
						model: this.model,
						childViewOptions: {
							tagName: 'li',
							type: 'contragent'
						}
					};
				},
				action: 'add:joined:contragent'
			})
		];
	},
	pricechangesContents () {
		return [
			new PriceChangesUiBlock({
				model: this.model
			})
		];
	},
	processesContents () {
		return [
			new ProductProcessesUiBlock({
				model: this.model
			})
		];
	},

	// prodSourceContents() {
	// 	let m = this.model;
	// 	return [
	// 		new UiCardBlock({
	// 			addClass: 'double-width',
	// 			model: this.model,
	// 			header:{ text: 'является сырьем для', addClass:'bg-main-dark'},
	// 			content: JoinedProducts,
	// 			contentOptions(){
	// 				return {
	// 					model: this.model,
	// 					tagName:'ul',
	// 					addClass: 'scrolled-y',
	// 					collection: m.getJoinedProducts(),
	// 					junction: 'production',
	// 					viewFilter: v => v.model.get('joinedAs') === 'production' && !!v.model.get('parent'),
	// 					childViewOptions:{
	// 						tagName:'li',
	// 						unitMeasureId: m.get('unitMeasureId'),
	// 					}
	// 				};
	// 			},
	// 			//action:'add:produced:from'
	// 		})

	// 	];
	// },
	__prodFromContents () {
		const m = this.model;
		return [
			new UiCardBlock({
				addClass: 'double-width',
				model: this.model,
				header: { text: 'производится из', addClass: 'bg-main-dark' },
				content: JoinedProducts,
				contentOptions () {
					return {
						model: this.model,
						tagName: 'ul',
						addClass: 'scrolled-y',
						collection: m.getJoinedProducts(),
						junction: 'production',
						viewFilter: v => v.model.get('joinedAs') === 'production' && !!v.model.get('child'),
						childViewOptions: {
							tagName: 'li'
						}
					};
				},
				action: 'add:produced:from'
			})

		];
	},
	prodFromContents () {
		return [
			new UiProdFromBlock({ model: this.model }),
			new UiUsedInProdBlock({ model: this.model })
		];
	}
	// storeContents() {
	// 	console.log('this ##', this)
	// 	return [ new UiStoreBlock({ model: this.model }) ];
	// }
});
