import View from 'base/view';

const defShowOptions = { replaceElement: true };

export default View.extend({
	className: 'collapsable-block ui2-block',
	template: `<header class="ui2-molecule">
        <span class="icon"><i></i></span>
        <div class="name"><%= header %></div>
        <div class="header-view"></div>
</header>
<section></section>`,
	regions: {
		content: '> section',
		headerView: '> header > .header-view'
	},
	initialize (options) {
		this.initializeCollapsable(options);
	},
	initializeCollapsable (options) {
		this.mergeOptions(options, ['regionName']);
		this.delegate('click', '> header > .icon', () => this._toggleCollapse());
		if (this.getOption('collapsed')) {
			this._collapsed = true;
		}
	},
	onRender () {
		if (this._collapsed) {
			this._toggleCollapse();
			delete this._collapsed;
		}
		this.showContent();
		this.showHeaderView();
	},

	_getViewShowOptions (viewName) {
		let options = this.getOption(viewName + 'ShowOptions');
		if (!options) {
			options = defShowOptions;
		}
		return options;
	},



	showContent () {
		const view = this.buildContentView();
		if (!view) return;
		this._showContent(view);
	},
	buildContentView () {
		return new View({ tagName: 'section', template: '<div class="padding-vh padding-hv">- пока в разработке -</div>' });
	},
	_showContent (view, options) {
		options = options || this.getShowContentOptions();
		this.showChildView('content', view, options);
	},
	getShowContentOptions () {
		return this._getViewShowOptions('content');
	},

	showHeaderView () {
		const view = this.buildHeaderView();
		if (!view) return;
		this._showHeaderView(view);
	},
	buildHeaderView () { },
	_showHeaderView (view, options) {
		options = options || this.getShowHeaderViewOptions();
		this.showChildView('headerView', view, options);
	},
	getShowHeaderViewOptions () {
		return this._getViewShowOptions('headerView');
	},

	templateContext () {
		return {
			header: this.getOption('header')
		};
	},
	_toggleCollapse () {
		let collapsed;
		if (this.$el.hasClass('collapsed')) {
			collapsed = false;
			this.$el.removeClass('collapsed');
		} else {
			this.$el.addClass('collapsed');
			collapsed = true;
		}
		this.trigger('collapsed', collapsed);
		console.log('trigger collapsed: ', collapsed);
	}
});
