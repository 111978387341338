import { NextCollectionView } from 'base/vendors';
import { ProductLineView } from './product-line-view';

export const ProductLinesView = NextCollectionView.extend({
	className: 'products-lines',
	childView: ProductLineView,
	viewComparator: 'name',
	initialize () {
		this.filterModel = this.getOption('filterModel');
		this.listenTo(this.filterModel, 'change:modelsSet search', () => {
			this.sort();
		});
		this.on('filter', () => {
			this.filterModel.trigger('after:filter', this.children);
		});
	},
	childViewOptions () {
		return {
			filterModel: this.filterModel
		};
	},
	viewFilter (v) {
		return this.filterModel.filter(v.model);
	},
	collectionEvents: {
		'batch:newAmount' (product, batchId) {
            // console.log('-try to detach product-');
			const shouldBeShown = this.filterModel.filter(product);

			if (!shouldBeShown) {
				const view = this.children.findByModel(product);
				if (view) {
					view.$el.detach();
                    // this.detachChildView(view);
				} else {
					console.log('product view not found');
				}
			}
            // else {
            //     console.log('filter is ok');
            // }
            // console.log('-new amount-', this.children);
		}
	}
});
