import _ from 'underscore';
import $ from 'underscore';
import Mn from 'backbone.marionette';
//import Bb from 'backbone';
import mix from './helpers/mix';
import GlobalTemplateContext from './mixins/global-template-context';
import GetOptionProperty from './mixins/get-option-property';
import Region from './YatRegion';
import ViewStateApi from './helpers/view-state-api';

export default mix(Mn.View).with(GlobalTemplateContext, GetOptionProperty).extend({
	
	instantRender: false,
	renderOnReady: false,
	nextRegionEnabled: false,
	stateApiEnabled:false,

	constructor(...args){
		
		this._fixRegionProperty(args[0]);

		Mn.View.apply(this, args);

		let options = args[0];
		this.mergeOptions(options, ['instantRender','renderOnReady', 'triggerReady', 'manualAfterInitialize']);

		if(this.renderOnReady === true)
			this.once('ready',this.render);
		if(this.instantRender === true && !this.renderOnReady)
			this.render();
		else if(this.instantRender === true && this.renderOnReady === true)
			this.triggerReady();

	},
	_fixRegionProperty(options = {}){
		
		if(!this.nextRegionEnabled) return;

		let detachable = this.getProperty('detachableRegion');
		if(options.detachableRegion != null)
			detachable = options.detachableRegion === true;

		let stateApi;
		if(this.stateApiEnabled || options.stateApiEnabled)
			stateApi = this.stateApi();

		let ViewRegion = stateApi ? Region.extend({ stateApi }) : Region;
		this.regionClass = detachable ? ViewRegion.Detachable() : ViewRegion;

	},
	triggerReady(){
		this.trigger('ready', this);
	},
	
	stateApi(){
		if(!this.stateApiEnabled) return;

		if(this._stateApiInstance) return this._stateApiInstance;
		let options = this.getOption('stateApiOptions');
		this._stateApiInstance = new ViewStateApi(options);
		return this._stateApiInstance;
	},
	stateApiOptions(){
		let _this = this;
		return { 
			storeIdPrefix: () => this.getOption('id'),
			states:['scrollable'] 
		};
	}
});
