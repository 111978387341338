import enums from 'helpers/enums';
// import user from 'app/user';
import { Layout } from './layout';
import { ProdCycleModel } from './prodCycles-model';
// import paths from 'helpers/paths';
import { buildTabsPageChildren } from './tabs';

const beforeStart = function (ac) {
	const cycle = new ProdCycleModel({ id: ac.args.cycleId });
	cycle.productId = ac.args.productId;
	this.addStartPromise(cycle.fetch());
	this.layoutOptions.cycle = cycle;
};

export default {
	id: 'prod-from',
	label: 'Производство',
	children: [
		{
			id: ':cycleId',
			label: 'производственный цикл',
			Layout,
			children: [
				...buildTabsPageChildren(Layout, {
					beforeStart
				})
			],
			beforeStart
			// beforeStart(ac) {
			// 	let cycle = new ProdCycleModel({ id: ac.args.cycleId });
			// 	cycle.productId = ac.args.productId;
			// 	this.addStartPromise(cycle.fetch());
			// 	this.layoutOptions.cycle = cycle;
			// },
		}
	],
	filter: p => !p.model.get('isAbstractGroup') && enums.has(p.model.get('origin'), 'production') // || enums.has(p.model.get('purpose'), 'production')
};
