import CollectionView from 'base/collection-view';
import childView from './item-view';
import TextTagView from 'base/text-tag-view';
import { FoldableSibblings } from 'behaviors';
export default CollectionView.extend({
	className: 'c-dt-predefined',
	initialize () {
		// this.customViews.push
		this.addCustomView(TextTagView.li('<i></i> Быстрые значения', {
			collectionPlace: 'top',
			className: 'foldable-sibblings with-caret',
			behaviors: [FoldableSibblings]
		}));
	},
	tagName: 'ul',
	childView,
	childViewTriggers: {
		'text:click': 'predefined:selected'
	}
});
