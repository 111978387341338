import { MnCollectionView } from 'base/views';
import { classNameMixin } from '../../mixins/view/className';
import { stateMixin } from '../../mixins/view/state';
import { FilterItemView } from './FilterItemView';

export const FiltersView = MnCollectionView.extend({
	baseClassName: 'ui2-filters-list',
	...classNameMixin,
	...stateMixin,
	initialize () {
		this.excludeFilters = this.getOption('excludeFilters') || [];
		if (!this.collection) {
			this.collection = this.model.values;
		}
	},
	childView: FilterItemView,
	viewFilter (view) {
		if (this.excludeFilters.length && this.excludeFilters.indexOf(view.model.id) > -1) {
			return false;
		}
		return true;
	}
});
