
import { HamburgerView } from 'base/view-hamburger';
import { BaseHeaderView } from 'base/base-view';
import { BaseCollectionView } from 'base/base-collection-view';
import { getNestedCollection } from 'utils/getNestedCollection';

// import { UiMoleculeView, UiSchemaMoleculeView } from '../../../../components/ui2/molecule';
// import { buildComponentSchema } from 'mods/product-production/builds';

// import { SchemaTextView, SchemaValueView } from 'components/ui2/schema-properties';


// const ComponentView = UiSchemaMoleculeView.extend({
// 	// childView: ,
// 	thisClassName: 'component-item',
// 	customViewOptions () {
// 		console.log(this.model.attributes);
// 		const opts = {
// 			modelSchema: buildComponentSchema,
// 			model: this.model
// 		};
// 		return opts;
// 	},
// 	leftItems: [
// 		{
// 			thisClassName: 'amount fixed',
// 			//topText: v => v.label('amount'),
// 			text: v => v.display('amountPostfix'),
// 			bottomText: '&nbsp;',
// 			topText: '&nbsp;',
// 		},
// 		{
// 			thisClassName: 'product fixed',
// 			//topText: v => v.label('componentName'),
// 			text: v => v.display('componentName'),
// 			url: v => '/res/prod/products/' + v.schenaValue('componentId'),
// 			bottomText: '&nbsp;',
// 			topText: '&nbsp;',
// 		},
// 		{
// 			thisClassName: 'party fixed',
// 			//topText: v => v.label('componentBatchIdString'),
// 			text: v => v.display('componentBatchIdString'),
// 			bottomText: '&nbsp;',
// 			topText: '&nbsp;',
// 		},
// 	]
// });

const CommonListView = BaseCollectionView.extend({
	baseClassName: 'with-borders list-items',
	initialize () {
		const dataKey = this.getOption('modelCollectionKey', true);
		this.collection = getNestedCollection(this.model, { dataKey });
		console.log('>>', this.collection, dataKey, this);
	},
});

export const CommonListBlockView = HamburgerView.extend({
	baseClassName: 'ui2-block',
	customViewOptions () {
		return {
			model: this.model
		};
	},
	customViews: v => [
		{
			class: BaseHeaderView,
			thisClassName: 'just-text',
			text: v.getOption('header', true)
		},
		{
			class: CommonListView,
			modelCollectionKey: v.getOption('modelCollectionKey', true),
			childView: v.getOption('listChildView', true)
		}
	]
});