import { BaseHamburgerView } from "base/base-hamburger-view";
import { BaseCollectionView } from 'base/base-collection-view';
import { BaseCollection } from 'base/base-collection';
import { BaseView } from 'base/base-view';
import smartOpen from 'helpers/smart-open';
import { invokeValue } from 'utils/invoke';
import { actionsApi } from "apis/actions";
import { FileService, selectFiles } from 'components/files';
import { _ } from 'vendors';

const baseSetOptions = BaseView.prototype._setOptions;
const optionsKeys = ['page', 'entityPageModel', 'entityModel'];
const commonMixin = {
	_setOptions() {
		baseSetOptions.apply(this, arguments);
		this.mergeOptionsKeys(optionsKeys, true);
	},
	childViewOptions() {
		return {
			ac: this.getOption('ac'),
			page: this.page,
			entityModel: this.entityModel,
			entityPageModel: this.entityPageModel
		}
	},
}

const Button = BaseView.extend({
	tagName: 'button',
	thisClassName: v => v?.model?.get('name'),
	classNames: [
		v => v.model.get('current') ? 'current' : ''
	],
	template: '<%= iconHtml %><span><%= text %></span>',
	templateContext() {
		const iconHtml = this.getIconHtml();
		return {
			iconHtml
		}
	},
	tagTitle: v => v.model.get('tagTitle'),
	getIconHtml() {
		const icon = this.model.get('icon');
		if (!icon) return '';
		if (icon === true) {
			return '<i></i>'
		}
		return `<i class="${icon}"></i>`;
	},
	onRender() {
		this.updateClassName();
	},
	events: {
		click(e) {
			const entity = this.getOption('entityModel');
			const action = this.model.get('action');
			const actionType = typeof action;
			console.log('ACTION', action, actionType === 'object' && entity && entity.executeAction);
			if (actionType === 'function') {				
				action(e);
				return;
			} else if (actionType === 'object' && entity && entity.executeAction) {
				console.log('EXECUTING');
				entity.executeAction(action, e);
				return;
			}

			const url = this.model.get('url');
			if (url) {
				smartOpen(url, e);
				return;
			}
			const name = this.model.get('name');
			if (name) {
				this.trigger('click:' + name);
			}
		}
	}
});

const Buttons = BaseCollectionView.extend({
	baseClassName: 'buttons-container',
	childView: Button,
	initialize() {
		
		const buttons = (this.getOption('buttons', true) || [])
			.map(btn => invokeValue(btn, this, this));
		
		this.collection = new BaseCollection(buttons);
	},
	childViewOptions() {
		return {
			entityModel: this.getOption('entityModel')
		}
	}
	// childViewOptions(child) {
	// 	const { tagTitle } = child.attributes;
	// 	return {
	// 		tagTitle
	// 	};
	// }
});

export const ButtonsGroup = BaseHamburgerView.extend({
	...commonMixin,
	baseClassName: 'menu-group',
	initialize() {
		console.log('BUTTONS GROUP', this.options);
	},

	getChildrenViews() {
		const header = this.getHeader();
		const footer = this.getFooter();
		const buttons = this.getButtonsView();
		return [
			header,
			buttons,
			footer
		]
	},

	getHeader() {

	},
	getFooter() {

	},
	getButtonsView() {
		const buttons = this.getButtons();
		return {
			class: Buttons,
			buttons,
		}
	},
	getButtons() {
		return this.getOption('buttons', true) || [];
	}
});

export const PageButtonsGroup = ButtonsGroup.extend({
	remapPageModel(pageModel) {
		if (!pageModel.isAvailableForSubmenu) {
			return;
		}
		const model = { ...pageModel };
		delete model.id;
		model.text = model.menuName || model.label;
		model.current = document.location.pathname == model.url;
		return model;
	},
	getButtons() {
		const pageModel = this.getOption('entityPageModel', true);
		const btns = pageModel.children.reduce((remaped, child) => { 
			const btn = this.remapPageModel(child);
			if (btn) {
				remaped.push(btn);
			}
			return remaped;
		}, []);
		btns.unshift(this.remapPageModel(pageModel));
		console.log('BTNS', btns);
		return btns;
	}
});

export const ActionsButtonsGroup = ButtonsGroup.extend({
	getButtons() {
		return (this.getOption('actions') || []).map(action => ({ text: action.label, action }));
	}
});

export const AsideView = BaseHamburgerView.extend({
	...commonMixin,
	tagName: 'aside',
	baseClassName: 'page-menu',
	template: '<div class="hide-trigger"><i class="fa fa-arrow-left"></i> скрыть</div>',
	// childViewOptions() {
	// 	return {
	// 		entityPageModel: this.getOption('entityPageModel')
	// 	}
	// },
	initialize() {
		console.log('ASIDE', this.options);
	},
	getChildrenViews() {

		return this.getButtonGroups();

	},

	getButtonGroups() {
		return this.getOption('buttonGroups') || []
	},

	events: {
		'click .hide-trigger'() {
			this.trigger('toggle-nav');
		}
	}
});

const PageHeader = BaseHamburgerView.extend({
	
	tagName: 'header',
	template: `<button><i class="fa fa-bars"></i></button><div><%= menuName %></div>`,
	templateContext() {
		const page = this.getOption('page');
		return {
			menuName: page.getOption('menuName')
		}
	},
	events: {
		'click button'() {
			console.warn('trig trig')
			this.trigger('toggle-nav');
		}
	}
});

const ArticleView = BaseHamburgerView.extend({
	...commonMixin,
	tagName: 'article',
	baseClassName: 'entity-page-content',
	// childViewOptions() {
	// 	return {
	// 		page: this.getOption('page'),
	// 		entityPageModel: this.getOption('entityPageModel')
	// 	}
	// },
	getChildrenViews() {
		return [
			PageHeader,
			this.getOption('content')
		];
	},
	childViewEvents: {
		'toggle-nav'() {
			console.warn('trig trig 222');
		}
	},
	childViewTriggers: {
		'toggle-nav':'toggle-nav'
	}
});

export const EntityPageLayout = BaseHamburgerView.extend({
	...commonMixin,

	constructor: function(options) {
		BaseHamburgerView.apply(this, arguments);
		this.entityModel = this.model;
		this.createFileService();
		this.listenTo(this.model, 'all', e => console.error('[model]', e));
	},

	baseClassName: [
		'entity-page-layout',
		v => v.model.get('completed') ? 'closed' : '',
		v => v.model.get('completeState')
	],
	stateClassNames: ['nav-on'],

	// childViewOptions() {
	// 	return {
	// 		page: this.getOption('page'),
	// 		entityPageModel: this.getOption('entityPageModel')
	// 	}
	// },
	getChildrenViews() {
		const asideOptions = this.getOption('asideOptions', true) || {};

		const views = [
			{ 
				class: AsideView,
				...asideOptions,
			},
			{
				class: ArticleView,
				content: this.getOption('content')
			}
		];

		return views;
	},
	childViewEvents: {
		'toggle-nav'() {
			this.state('nav-on', !this.state('nav-on'));
		}
	},
	// initialize() {
	// 	this.entityModel = this.model;
	// 	this.createFileService();
	// },
	createFileService() {
		const entity = this.model;
		const hasFiles = entity.getAction('upload:files');

		if (!hasFiles) { return; }

		this.fileSvc = new FileService({
			shouldUseNotifier: true,
			shouldUseUploadOnNewFiles: true,
			uploadOptions: {
				url: _.result(entity, 'url') + '/upload'
			}
		});

		this.on('destroy', () => this.fileSvc.destroy());
		this.on('render', () => this.fileSvc.start());
		this.listenTo(entity, 'open:selectFilesToUpload', () => {
			selectFiles(blobs => this.fileSvc.addBlobs(blobs));
		});

	}
});