module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="controls line"><button class="backward"><i></i></button></div>\n<div class="line whole-btn-bg"><div class="date"><b>'+
((__t=( _label ))==null?'':__t)+
'</b><span>'+
((__t=( _date ))==null?'':__t)+
'</span></div><button class="edit"><i></i></button></div>\n<div class="controls line"><button class="forward"><i></i></button></div>\n';
}
return __p;
};
