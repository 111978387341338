import { MnView } from 'vendors';
import { commonViewMixin } from 'mixins/view/common-view';
import { stringTemplateMixin } from 'mixins/view/string-template';
import { stateMixin } from 'mixins/view/state';
import { optionsMixin } from '../mixins/options';

export const BaseMnView = MnView.extend({
	...stringTemplateMixin
});

export const BaseView = MnView.extend({
	constructor: function () {
		MnView.apply(this, arguments);
		this.initializeClassNameMixin();
		this.initializeTagTitle();
		// const title = this.getOption('tagTitle', true);
		// if (title != null) {
		// 	this.el.setAttribute('title', title);
		// }
	},
	...optionsMixin,
	...commonViewMixin,
	...stringTemplateMixin,
	...stateMixin
});

export const BaseTextView = BaseView.extend({
	template: '<%= text %>',
	getText () {
		return this.getOption('text', true);
	},
	templateContext () {
		return {
			text: this.getText()
		};
	}
});

export const BaseHeaderView = BaseTextView.extend({
	baseClassName: 'bg-main-dark',
	tagName: 'header'
});
