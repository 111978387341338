import { BackboneModel } from 'vendors';
import { propertySchemaApi } from 'apis/schema';
import paths from 'helpers/paths';

export const BaseModel = BackboneModel.extend({
	url () {
		const url = BackboneModel.prototype.url.apply(this, arguments);
		return this._normalizeUrl(url);
	},
	_normalizeUrl (url) {
		if (!url || url.startsWith('http') || url.startsWith('//')) {
			return url;
		}
		return paths.urls.api(url);
	},
	display (key, schema) {
		schema = this.getSchema(schema);
		return propertySchemaApi.displayValue(key, this, schema);
	},
	getSchema (schema) {
		return schema || this.schema;
	}
});
