import { _ } from 'vendors';
import storage from './local-storage';


const store = {

	_normalizeValue (value) {
		let normValue = value;
		if (_.isObject(value) && _.isFunction(value.toJSON)) { normValue = value.toJSON(); }
		if (_.isDate(value) && !_.isNaN(value.valueOf())) { normValue = `date(${normValue})`; }
		return normValue;
	},

	_createItem (value, expireAt) {
		return { expireAt, value };
	},

	jsonParse (key, value) {
		const datePattern = /^date\((\d{4,4}-\d{2,2}-\d{2,2}([T\s]\d{2,2}:\d{2,2}:\d{2,2}(\.\d*)?Z?)?)\)$/;
		if (_.isString(value) && datePattern.test(value)) {
			const textDate = value.replace(datePattern, '$1');
			return new Date(textDate);
		}
		return value;
	},
	_jsonParse (key, value, context) {
		if (!key) return value;
		return this.jsonParse(key, value, context);
	},
	_parse (raw) {
		const _this = this;
		const item = JSON.parse(raw, function (key, value) { return _this._jsonParse(key, value, this); });
		if ('expireAt' in item && 'value' in item) { return item; } else { return this._createItem(item, 0); }
	},
	_get (key) {
		const raw = storage.getItem(key);
		if (raw == null) return;
		return this._parse(raw);
	},
	get (key, opts = { checkExpire: true }) {
		const item = this._get(key);
		if (item == null) return;
		const expired = this._isExpired(item);
		if (!expired || !opts.checkExpire) { return item.value; } else if (expired) { this.remove(key); }
	},
	set (key, value, opts = {}) {
		const expireAt = Date.now() + this.getExpireAt(opts);
		const normValue = this._normalizeValue(value);
		const item = this._createItem(normValue, expireAt);
		this._set(key, item);
	},
	remove (key) {
		storage.removeItem(key);
	},
	expire (key) {
		const item = this._get(key);
		if (!item) return;
		item.expireAt = 0;
		this._set(key, item);
	},
	getExpireAt (opts = {}) {
		if (!isNaN(opts.expireAt)) { return opts.expireAt; }
		if (!isNaN(opts.seconds)) { return opts.seconds * 1000; }
		if (!isNaN(opts.minutes)) { return opts.minutes * 60 * 1000; }
		if (!isNaN(opts.hours)) { return opts.hours * 60 * 60 * 1000; }
		if (!isNaN(opts.days)) { return opts.days * 24 * 60 * 60 * 1000; } else { return 10 * 60 * 1000; }
	},
	_set (key, item) {
		const text = JSON.stringify(item);
		storage.setItem(key, text);
	},
	isExpired (key) {
		const item = this._get(key);
		if (item == null) return true;
		return this._isExpired(item);
	},
	_isExpired (item) {
		return item.expireAt < Date.now();
	}
};

//Console.warn('store', store);

export default store;
