import CollectionView from 'base/collection-view';
// import View from 'base/view';
import { BlockHeaderWithRightButtons /*, BlockFooterTextAction */ } from 'components/ui2';
import UiListItem from 'components/ui-list/item';

import busData from 'bus/data';
import smartOpen from 'helpers/smart-open';
import editValue from 'bus/edit';

import { productPageCardUrl } from 'app/constants';

const _meas = v => busData.measures(v, 'short');

const CycleView = CollectionView.extend({
	className: 'cycle-info-block ui2-block',
	customViews () {
		const m = this.model;
		const p = m.get('product');
		const c = m.get('productionCycle');

		const punit = p.unitMeasureId;
		const cunit = c.productionMeasureId;
		const camount = c.baseUnitsInProductionUnit;
		let prodAdd = ' &mdash; 1 ' + _meas(cunit);
		if (punit !== cunit || camount > 0) {
			prodAdd += ` (${camount} ${_meas(punit)})`;
		}

		return [
			new BlockHeaderWithRightButtons({
				text: 'производственный цикл ' + this.model.get('productionCycle').name,
				buttons: [
					// { id: 'withdraw:resources', text: 'выдать' },
					// { id: 'accept::resources', text: 'забрать' },
					// { id: 'accept::resources', text: 'списать брак' },
					// { id: 'accept:production', text: 'принять продукцию' },
				]
			}),
			new UiListItem({
				className: 'ui-list-item product-name-line',
				topText: 'производится:',
				text: p.fullName + prodAdd,
				leftButtons: [
					{
						class: 'open-card', icon: true, 'data-trigger': 'open:product:card', title: 'открыть страницу продукции в новой вкладке'
					}
				]
			})

		];
	},
	childViewEvents: {
		'open:product:card:click' (aa, event) {
			const productId = this.model.get('product').id;
			const options = {
				ctrlKey: !event.ctrlKey
			};
			const url = productPageCardUrl + '/' + productId;
			// busData.request('product:page:url') + '/' + productId; // product.cardUrlRoot + '/' + this.model.id;
			smartOpen(url, options);
		},
		'accept:production' () {
			editValue.do({
				modalOptions: {
					modifiers: {
						'after:render': null,
						refresh: null
					}
				},
				header: 'Приёмка готовой продукции и списание ресурсов у контрагента',
				// 'resetLabel',
				// 'cancelLabel',
				// 'applyButton',
				applyLabel: 'далее',
				resetButton: false,
				cancelButton: false,

				controlView: undefined, // будет падать. специально. TODO: разобраться почему тут было это: AcceptProductionLayout,
				controlViewOptions: {
					process: this.model,
					readyOnRender: true
				}
			}).then(value => {
				// let url = this.model.url() + '/production/get'
				// console.log('##', value);

				this.selectBatchIdString(value);
			});
		}
	}
});


export function cycleBlock (view) {
	console.log('cycleBlock in USE');
	view.createNested('cycleblock', {
		viewClass: CycleView,
		viewOptions: {
			model: view.model
		},
		regionTemplate: { replaceElement: true }
	});
}
