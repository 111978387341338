import version from './version';
// import './moment';
import './config';
import './_extends/index';
import './app/config';
import './_env-cfgs/index';

import './app/user';

import './components';
// import modules from './modules/index';
import App from './app/app';
import Root from 'pages/root';

import '../less-bundle/bundle.less';
// import Backbone from 'backbone';
// console.log(Backbone.history);
// localStorage.clear(); //////

import './fonts';
import { $, moment } from 'vendors';
import { registerAuthHref } from 'helpers/auth-href';




$(function () {
	if (!window.moment) {
		window.moment = moment;
	}
	registerAuthHref();

	const app = new App({ version });
	app.createChild(Root);
	app.start();

	console.warn(version);
	
});
