// import BasePage from 'pages/auth';
import { Layout as ReportLayout } from './report/layout';

import { ReportPage } from '../reports/cmn';

// const Page = ReportPage.extend({
// 	label: 'Отчет по продукции',
// 	reportType: 'products',
// });

const Page = ReportPage.extend({
	moduleRights: { productsPurchase: 'view' },
	label: 'Закупки',
	route: 'purchase',
	// children:[Production],
	icon: 'purchase',
	reportType: 'purchase',
	reportLayoutOptions: {
        // noTopMenu: true,
		entriesInfo: false
	},
	Layout: ReportLayout
});

export default Page;
