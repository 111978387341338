import { isKnownCtor } from './is';

const emptyArguments = [];

export function invokeValue (value, invokeContext, invokeArgs) {

	if (value == null || typeof value !== 'function' || isKnownCtor(value)) {
		return value;
	}

	if (invokeArgs == null) {
		invokeArgs = emptyArguments;
	}

	if (!Array.isArray(invokeArgs)) {
		return value.call(invokeContext, invokeArgs);
	}

	return value.apply(invokeContext, invokeArgs);

	// if (invokeArgs == null) {
	// 	return value.call(invokeContext);
	// } else if (Array.isArray(invokeArgs)) {
	// 	return value.apply(invokeContext, invokeArgs);
	// } else {
	// 	return value.call(invokeContext, invokeArgs);
	// }


}

