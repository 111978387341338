import { _, moment } from 'vendors';
// import busData from 'bus/data';
import Collection from 'base/collection';
import AppCollectionView from 'base/collection-view';
import Model from 'base/model';
import UiCardBlock from 'components/ui-card-block/block';
// import ColView from 'base/collection-view';
import View from 'base/view';
import modals from 'helpers/modals';
import { NextCollectionView as CollectionView } from 'backbone.marionette';
import { getPriceInfo } from 'mod/productionProducts/models/product-contragent';
import enums from 'helpers/enums';

const ColView = AppCollectionView;

function normalizeDate (a, key) {
	if (_.isDate(a[key])) return;
	a[key] = new Date(a[key]);
}


const DtoModel = Model.extend({
	parse (data) {
		normalizeDate(data, 'date');
		normalizeDate(data, 'created');
		if (data.changes) {
			data.changes.sort((a, b) => {
				normalizeDate(a, 'date');
				normalizeDate(b, 'date');
				return b.date.valueOf() - a.date.valueOf();
			});
			if (data.changes.length === 1) {
				normalizeDate(data.changes[0], 'date');
			}
			const lastDate = data.changes[0].date;
			data.lastDate = lastDate > data.date ? lastDate : data.date;
		}
		if (!data.lastDate) {
			data.lastDate = data.date;
		}
		return data;
	}
});

const FilterValueModel = Model.extend({
	isNew () {
		return true;
	}
});
const FilterModel = Model.extend({
	addValue (key, value) {
		let store = this.get(key);
		if (!(store instanceof Collection)) {
			store = new Collection(null, { model: FilterValueModel });
			this.set(key, store, { silent: true });
		}
		store.add({ id: value });
		this.trigger('change:' + key, this);
		this.trigger('change', this);
	},
	removeValue (key, value) {
		const store = this.get(key);
		if (!(store instanceof Collection)) {
			return;
		}
		const model = store.get(value);
		if (model) {
			model.destroy();
			this.trigger('change:' + key, this);
			this.trigger('change', this);
		}
	},
	isMatch (model) {
		return _.every(this.attributes, (filterValue, modelKey) => this._isValueMatch(model.get(modelKey), filterValue));
	},
	_isValueMatch (modelValue, filterValue) {
		// console.log('> ?', modelValue, filterValue);
		if (filterValue instanceof Collection) {
			return filterValue.has(modelValue);
		} else {
			throw new Error('not implemented filter case');
		}
	}
});

const ContragentChangeView = View.extend({
	tagName: 'li',
	className: 'ui-list-item',
	template: `
		<div>
			<span><%= _.displayNum(pricePerUnit, 4) %> <%= currencySign %></span>
		</div>		
		<div title="<%= created %>">
			<span><%= moment(date).fromNow() %></span>
		</div>
		<div>
			<span><%= processStateLabel %></span>
		</div>		
	`,
	templateContext () {
		const info = getPriceInfo(_.omit(this.model.attributes, 'changes', 'contragent', 'process'));
		return {
			processStateLabel: enums.get('appProcessCompleteStates', this.model.get('processState')),
			...info
		};
	}
});

const AllContragentChangesCV = CollectionView.extend({
	// className: 'card lined with-border',
	initialize () {
		this.valuesFilter = this.options.valuesFilter;
		this.collection = new Collection(this.model.get('changes'), { model: DtoModel, parse: true });
		this.listenTo(this.valuesFilter, 'change', () => {
			// console.log('WAS CHANGE', this.valuesFilter.get('processState').toJSON());
			this.sort();
		});
	},
	childView: ContragentChangeView,
	viewFilter (v) {
		if (!v.model) return true;
		return this.valuesFilter.isMatch(v.model);
	},
	viewComparator (_a, _b) {
		const a = _a.model;
		const b = _b.model;
		return b.get('lastDate').valueOf() - a.get('lastDate').valueOf();
	}
});

const CheckView = View.extend({
	className: 'checkbox-list-item',
	dynamicClassName () {
		return [this.model.get('active') ? 'active' : ''];
	},
	tagName: 'span',
	template: '<i class="icon"></i><span><%= label %></span>',
	triggers: {
		click: 'click'
	}
});
const FilterView = AppCollectionView.extend({
	className: 'checkbox-list small',
	initialize () {
		const notActives = ['canceled', 'failured'];
		this.valuesFilter = this.options.valuesFilter;
		const states = enums.store.appProcessCompleteStates;
		const models = _.map(states, (label, id) => ({ id, label, active: notActives.indexOf(id) === -1 }));
		models.forEach(m => {
			if (!m.active) return;
			this.valuesFilter.addValue('processState', m.id);
		});
		this.collection = new Collection(models);
	},
	childViewEvents: {
		'click' (view) {
			const m = view.model;
			if (!m) return;
			m.set('active', !m.get('active'));
			const isActive = !!m.get('active');
			if (isActive) {
				this.valuesFilter.addValue('processState', m.id);
			} else {
				this.valuesFilter.removeValue('processState', m.id);
			}
		}
	},
	childView: CheckView
});

const AllContragentChanges = AppCollectionView.extend({
	className: 'contragent-price-changes',
	customViews () {
		const valuesFilter = new FilterModel();
		const res = [
			new View({ tagName: 'header', template: 'История процессов закупок' }),
			new FilterView({ model: this.model, valuesFilter }),
			new AllContragentChangesCV({ model: this.model, valuesFilter })
		];
		return res;
	}
});

const PriceChangeView = View.extend({
	initialize () {
		this.items = this.model.get('items') || [];
	},
	className: 'ui-list-item price-changes',
	tagName: 'li',
	template: `
		<div title="название контрагента">		
			<span><%= name %></span>
		</div>
		<div title="последняя цена">
			<span><%= _.displayNum(lastPrice, 4) %> <%= currencySign %></span>
		</div>
		<div title="<%= lastDate %>" class="last-date <%= count > 1 ? 'clickable' : '' %>">
			<% if (count > 1) {%><b>процессы: <%= count%></b><% } %>
			<span><%= lastFromNow %></span>
		</div>
	`,
	templateContext () {
		const info = getPriceInfo(_.omit(this.model.attributes, 'changes', 'contragent', 'process'));
		let date = this.model.get('date');
		const children = this.model.get('changes') || [];
		const childDates = _.map(children, child => child.date);
		const childDate = _.max(childDates);

		date = childDate.valueOf() > date.valueOf() ? childDate : date;

		return {
			name: this.model.get('contragent').name,
			lastFromNow: moment(date).fromNow(),
			lastDate: date,
			count: (this.model.get('changes') || []).length, // items.length,
			currencySign: info.currencySign,
			lastPrice: info.pricePerUnit
		};
	},
	events: {
		'click .last-date.clickable' (e) {
			modals.show(new AllContragentChanges({ model: this.model }), { type: 'full' });
		}
	}
});

const PriceChangesCollection = Collection.extend({
	model: DtoModel,
	url () {
		const url = this.parent.url();
		return url + '/price-changes';
	},
	getAggregatedEntries () {
		const ca = {};
		const models = [];
		this.each(model => {
			const atrs = model.attributes;
			let ca_model = ca[atrs.contragentId];
			if (!ca_model) {
				ca_model = ca[atrs.contragentId] = {
					id: atrs.contragentId,
					contragent: atrs.contragent,
					items: []
				};
				models.push(ca_model);
			}
			ca_model.items.push(atrs);
		});
		return models;
	}
});

const PriceChanges = ColView.extend({
	initialize () {
		// this.entries = new PriceChangesCollection();
		// this.entries.parent = this.model;


		// this.collection = new Collection(null, { model: DtoModel });
		// this.collection.listenTo(this.entries, 'sync', () => this.collection.reset(this.entries.getAggregatedEntries(), { parse: true }));
		// this.entries.fetch();
		this.collection = new PriceChangesCollection();
		this.collection.parent = this.model;
		this.collection.fetch();
	},
	childView: PriceChangeView,
	viewComparator (_a, _b) {
		const a = _a.model;
		const b = _b.model;
		return b.get('lastDate').valueOf() - a.get('lastDate').valueOf();
	}
});

export const PriceChangesUiBlock = UiCardBlock.extend({
	addClass: 'double-width',
	header: { text: 'изменение цены', addClass: 'bg-main-dark' },
	content: PriceChanges,
	contentOptions () {
		return {
			tagName: 'ul',
			addClass: 'scrolled-y',
			model: this.model
		};
	}
});
