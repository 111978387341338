import { _ } from 'vendors';
import View from 'base/view';
import CollectionView from 'base/collection-view';
import { ItemLine } from './purchaseItem';
import Collection from 'base/collection';
import { PurchaseItemModel } from 'mod/tasks/models/requestPurchase-model';
import MeasureForProducts from 'mod/productionMeasures/models/forProducts-model';

// import bus from 'bus';
// import { BasePointsView } from './mixins';

const ExtPurchaseItemModel = PurchaseItemModel.extend({
	idAttr: 'productionId'
});

const BaseMixin = {
	modelClass: ExtPurchaseItemModel,
	initialize (options) {
		this.mergeOptions(options, ['details', 'items', 'modelClass']);
		this.initializeItemsCollection();
	},
	initializeItemsCollection () {
		if (this.collection) return;

		const items = this.model.get('typeDetails.items');
		this.collection = new Collection(items, { model: this.modelClass });
	},
	viewFilter (v) {
		return !v.model.get('tempProcessId');
	},
	initializeItems () {
		if (!this.items) {
			this.items = this.details.items;
		}
	}
};

const BaseView = CollectionView.extend({ ...BaseMixin });

export const PurchaseItems = BaseView.extend({
	className: 'purchaseitems',
	childView: ItemLine,
	childViewOptions () {
		return {
			taskModel: this.model,
			productsMeasures: this.productsMeasures
		};
	},
	initialize () {
		this.collection = new Collection([], { model: this.modelClass });
		this.listenTo(this.model, 'change:typeDetails.items', this._reinitCollection);
		this.listenTo(this.collection, 'edited', () => {
			const items = this.collection.toJSON();
			const current = this.model.get('typeDetails') || {};
			const typeDetails = { ...current };
			typeDetails.items = items;
			this.model.patchProperty('typeDetails', typeDetails);
		});
		this._reinitCollection();
	},
	_reinitCollection () {
		const items = this.model.get('typeDetails.items') || [];
		const ids = _.pluck(items, 'productionId').filter(f => !!f);
		const cb = () => this.collection.reset(items);
		this._reinitProductsMeasures(ids).then(cb, cb);
		// this.collection.reset(items);
		// this._reinitProductsMeasures();
	},
	_reinitProductsMeasures (ids) {
		if (!this.productsMeasures) {
			this.productsMeasures = new MeasureForProducts();
		} else {
			this.productsMeasures.clear();
		}
		// let ids = this.collection.pluck('productionId').filter(f => !!f);
		return this.productsMeasures.save(null, { wait: true, attrs: ids });
	}
});


export const ExtPurchaseItems = PurchaseItems.extend({
	addClass: 'double-size',
	modelEvents: {
		'change:typeDetails' () {
			this._reinitCollection();
		}
	},
	emptyView: View.extend({ className: 'empty-content', template: _.template('<header>нет нераспределённой продукции</header>') })
});
