import { PageWithDivisionsLayout } from 'components/page-sub-division/PageWithDivisionsLayout';
import { CyclesContent } from './CyclesContent';
// import { FilterModel } from './FilterModel';
// import { ProductsContent } from './ProductsContent';
// import { ProductsHeader } from './ProductsHeader';

export const CyclesLayout = PageWithDivisionsLayout.extend({
	// initialize () {
	// 	this.filterModel = new FilterModel();
	// },
	// header: {
	// 	class: ProductsHeader
	// },
	content: {
		class: CyclesContent,
		className: 'content'
	},
	// currentTab: 'table',
	contentOptions () {
		return {
			filterModel: this.filterModel,
			model: this.model
		};
	},
	onBeforeRender () {
		this.model.initializeProductToProcessMap();
	}
	// onSearchByName (text) {
	// 	this.filterModel.set('name', text);
	// 	this.filterModel.trigger('filter');
	// },
	// onHeaderTab (tabId) {
	// 	this.currentTab = tabId;
	// 	this.render();
	// }
});
