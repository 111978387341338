import { _ } from 'vendors';

export const scrollMixin = {

	initializeScrollMixin () {

		if (this._scrollMixinInitialized) return;
		this.delegate('scroll', this._tryTriggerScrollEvent.bind(this));
		this.on('clear:scroll', this.clearScrollEventTrigger);
		this._scrollMixinInitialized = true;

	},
	hasScroll (edge) {

		const { clientHeight, scrollHeight, clientWidth, scrollWidth } = this.el;

		if (edge) {

			switch (edge) {

			case 'up':
			case 'down':
				return clientHeight < scrollHeight;
			case 'left':
			case 'right':
				return clientWidth < scrollWidth;

			}
			return;

		}
		const down = this.hasScroll('down');
		const right = this.hasScroll('right');
		return {
			down,
			up: down,
			right,
			left: right
		};

	},
	_tryTriggerScrollEvent () {

		if (!this._lastScrollEventTrigger) {

			this._lastScrollEventTrigger = {};

		}

		const prevTop = this._lastScroll?.scrollTop || 0;
		const prevLeft = this._lastScroll?.scrollLeft || 0;

		const { scrollTop, scrollLeft, clientHeight, scrollHeight, clientWidth, scrollWidth } = this.el;
		const yDelta = scrollTop - prevTop;
		const xDelta = scrollLeft - prevLeft;
		const ySpaceMinimum = clientHeight / 3;
		const xSpaceMinimum = clientWidth / 3;

		const triggerEvents = [];


		const yStartReached = scrollTop < ySpaceMinimum;
		const yEndReached = scrollHeight - (scrollTop + clientHeight) < ySpaceMinimum;
		const yEvent = this._getScrollTriggerEvent(yDelta, 'up', 'down', yStartReached, yEndReached);
		if (yEvent && yEvent in this._lastScrollEventTrigger === false) {

			triggerEvents.push(yEvent);

		}

		const xStartReached = scrollLeft < xSpaceMinimum;
		const xEndReached = scrollWidth - (scrollLeft + clientWidth) < xSpaceMinimum;
		const xEvent = this._getScrollTriggerEvent(xDelta, 'left', 'right', xStartReached, xEndReached);
		if (xEvent && xEvent in this._lastScrollEventTrigger === false) {

			triggerEvents.push(xEvent);

		}

		this._lastScroll = { scrollTop, scrollLeft, yEvent, xEvent };

		triggerEvents.forEach(event => {

			this._lastScrollEventTrigger[event] = 1;
			this.triggerMethod('scrolled:to:' + event);
			this.triggerMethod('scrolled:to:edge', event);

		});

	},
	_getScrollTriggerEvent (delta, negative, positive, startReached, endReached) {

		if (!delta) { return; }
		const movement = delta > 0 ? positive : negative;
		const reached = delta > 0 ? endReached : startReached;
		if (reached) {

			return movement;

		}

	},
	clearScrollEventTrigger (event) {

		if (!event) {

			this._lastScrollEventTrigger = {};

		} else if (this._lastScrollEventTrigger) {

			delete this._lastScrollEventTrigger[event];

		}

	}

};


const regularDirections = {
	down: 1,
	right: 1
};

const defautlGrowDirections = {
	down: 1
};

function arrayToObject (arr) {

	return arr.reduce((memo, key) => {

		memo[key] = 1;
		return memo;

	}, {});

}

function normalizeDirection (growDirection) {

	if (growDirection !== undefined && !growDirection) {

		growDirection = false;

	} else if (typeof growDirection === 'string') {

		const directions = growDirection.split(/\s*,\s*/);
		growDirection = arrayToObject(directions);

	} else if (Array.isArray(growDirection)) {

		growDirection = arrayToObject(growDirection);

	} else if (growDirection === 'object') {

		growDirection = { ...growDirection };

	} else {

		growDirection = { ...defautlGrowDirections };

	}
	return growDirection;

}

const isRegular = directions => !directions || Object.keys(directions).every(key => key in regularDirections);


export const fetchMixin = {

	getGrowDirections () {

		if (this._growDirections) {

			return this._growDirections;

		}

		const growDirection = this.getOption('growDirection', true) || 'down';
		const silent = true;
		return this.setGrowDirection(growDirection, silent);


	},
	isRegularGrowDirection () {

		if (this._isRegularGrowDirection != null) {

			return this._isRegularGrowDirection;

		}

		const directions = this.getGrowDirections();
		this._isRegularGrowDirection = isRegular(directions);

		return this._isRegularGrowDirection;

	},
	setGrowDirection (growDirection, silent) {

		this._growDirections = normalizeDirection(growDirection);
		this._isRegularGrowDirection = isRegular(this._growDirections);
		if (!silent) {

			this.triggerMethod('grow:direction:change', this._growDirections);

		}
		return this._growDirections;

	},

	_getScrollInfo () {

		const { scrollTop, scrollLeft, scrollHeight, scrollWidth } = this.el;
		return { scrollTop, scrollLeft, scrollHeight, scrollWidth };

	},
	_fixScrollPosition (prevInfo) {

		if (this.isRegularGrowDirection() || !prevInfo) { return; }
		const newInfo = this._getScrollInfo();
		const x = newInfo.scrollLeft + (newInfo.scrollWidth - prevInfo.scrollWidth);
		const y = newInfo.scrollTop + (newInfo.scrollHeight - prevInfo.scrollHeight);
		this.el.scrollTo(x, y);

	},
	scrollToStart () {

		let xCoord = 0;
		let yCoord = 0;
		const edges = this.getGrowDirections();
		Object.keys(edges).forEach(edge => {

			switch (edge) {

			case 'up':
				yCoord = (this.el.scrollHeight - this.el.clientHeight); break;
			case 'left':
				xCoord = (this.el.scrollWidth - this.el.clientWidth); break;

			}

		});

		this.el.scrollTo(xCoord, yCoord);

	},
	tryScrollToStart () {

		if (this.isRendered()) {

			this.scrollToStart();

		} else {

			this.once('render', this.scrollToStart());

		}

	},
	updateScrollPosition (move, scrollInfo) {

		if (move == null) {

			this.tryScrollToStart();

		} else {

			this._fixScrollPosition(scrollInfo);

		}

	},

	async fetch (options = {}) {

		if ('move' in options && this.isEndOfData && this.isEndOfData()) {
			return;
		}


		delete this._fetchException;
		this.triggerMethod('before:fetch', options);

		const requestData = this._getRequestData(options);
		let responseData;
		if (typeof this.sendFetchRequest !== 'function') {
			throw new Error('sendFetchRequest is not implemented');
		}
		console.error('FETCH WTF', options, requestData, requestData === options);
		try {

			responseData = await this.sendFetchRequest(requestData, options);
			this.processFetchResponse(responseData, options);

		} catch (exception) {

			this._fetchException = exception;
			this.triggerMethod('fetch:error', exception, requestData, options);

		}

		this.triggerMethod('fetch', responseData, requestData, options);

	},

	// sendFetchRequest (data, options) {
	// 	throw new Error('Not implemented');
	// },

	processFetchResponse (responseData, fetchOptions) {

	},

	_getRequestData (options) {

		const requestData = this.getRequestData(options);
		const moveData = this.getRequestMoveData(options);
		const data = (requestData || moveData)
			? Object.assign({}, requestData, moveData)
			: undefined;
		return data;

	},

	getRequestData () {

	},

	getRequestMoveData (options = {}) {

		const moveForward = options.move;

		if (moveForward == null) return;

		const result = this.getResultModel();
		if (!result) return;

		let { skip, skiped, take, taked } = result.attributes;
		skip = skip || skiped || 0;
		take = take || taked || 100;

		skip = moveForward ? skip + take : skip - take;

		return { skip, take };

	},

	getResultModel () {

		const resultModel = this.getOption('resultModel', true);
		return resultModel ?? this.collection?.result;

	}

};


const oppositeEdges = {
	down: 'up',
	right: 'left',
	up: 'down',
	left: 'right'
};


export const fetchOnScrollMixin = {
	...scrollMixin,
	...fetchMixin,
	initializeFetchOnScrollMixin () {

		this.initializeScrollMixin();

		if (this._fetchOnScrollMixinInitialized) return;

		this._setFetchOnScrollHandlers();
		this.on({
			'grow:direction:change': this._setFetchOnScrollHandlers,
			'before:fetch': this._beforeFetch,
			fetch: this._afterFetch
		});
		if (this.getOption('shouldFetch', true)) {

			this.fetch();

		}
		this._fetchOnScrollMixinInitialized = true;

	},
	_setFetchOnScrollHandlers () {

		if (this._fetchOnScrollHandlers) {

			_.each(this._fetchOnScrollHandlers, (event, handler) => this.off(event, handler));

		}

		const grow = this.getGrowDirections();
		if (!grow) { return; }

		const backwardFetchAllowed = this.getOption('backwardFetchAllowed', true);

		const moveForward = true;
		const moveBackward = false;
		const fetchHandler = this.fetch;
		this._fetchOnScrollHandlers = Object.keys(grow).reduce((handlers, edge) => {

			handlers['scrolled:to:' + edge] = fetchHandler.bind(this, { move: moveForward });
			const oppositeEdge = oppositeEdges[edge];
			if (backwardFetchAllowed && oppositeEdge) {

				handlers['scrolled:to:' + oppositeEdge] = fetchHandler.bind(this, { move: moveBackward });

			}
			return handlers;

		}, {});

		this.on(this._fetchOnScrollHandlers);

	},
	_beforeFetch (options = {}) {

		options.scrollInfo = this._getScrollInfo();

	},
	_afterFetch (data, body, options = {}) {

		if (this._fetchException) { return; }

		this.clearScrollEventTrigger();
		const move = options.fetchMore ? undefined : options.move;
		this.updateScrollPosition(move, options.scrollInfo);
		this._fetchMore();

	},
	_fetchMore () {

		if (this.isEndOfData()) {
			return;
		}

		const directions = this.getGrowDirections();

		if (_.every(directions, (__, direction) => {

			const has = !this.hasScroll(direction);
			return has;

		})) {

			this.fetch({ move: true, fetchMore: true });

		}

	},
	isEndOfData () {
		const result = this.getResultModel();
		if (!result) { return true; }

		if (typeof result.isEndOfData === 'function') {

			return result.isEndOfData();

		}

		const { endOfData } = result.attributes;

		return endOfData !== false;

	}
};
