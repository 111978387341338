import Bb from 'backbone';
import mix from '../helpers/mix';
import _ from 'underscore';

const CollectionBulkchange = {

	_initBulkChange(opts = {}){
		let enabled = opts.bulkChangeEnabled != null ? opts.bulkChangeEnabled : this.bulkChangeEnabled;
		if(!enabled || this._bulkChangeInitialized) return;
		this._triggerBulkChange = _.debounce(this._triggerBulkChange, 20);
		this.on('change', this._bulkChangeOnChange)
		this.on('update', this._bulkChangeOnUpdate)
		this.on('reset', this._bulkChangeOnReset)
		//this.on('bulk:change', () => //console.log('bulked', this.cid));
		this._bulkChangeInitialized = true;
	},

	_getBulkChanges(key){
		this._bulkChanges || (this._bulkChanges = {});
		if(!key)
			return this._bulkChanges;
		else {
			this._bulkChanges[key] || (this._bulkChanges[key] = {});
			return this._bulkChanges[key];
		}
	},
	_clearBulkChanges(){
		delete this._bulkChanges;
	},
	_modifyBulkChanges(type, models){
		!_.isArray(models) && (models = [models]);
		_(models).each((model) => this._addBulkChangesEntry(type, model));
		this._triggerBulkChange();
	},
	_addBulkChangesEntry(type, model){
		let cid = model.cid;
		let all = this._getBulkChanges('all');
		let changes = this._getBulkChanges(type);
		changes[cid] = model;
		all[cid] = model;
	},


	//finalizes bulkChange hash and triggers `bulk:change` event
	_triggerBulkChange(){
		let changes = this._getBulkChanges();
		this._clearBulkChanges();
		changes.inside = {};
		
		_(changes.all).each((model) => { 
			let cid = model.cid;
			let inside = this.has(model);
			if(inside){
				changes.inside[cid] = model;
				if(changes.removed)
					delete changes.removed[cid];
			}else{
				if(changes.added)
					delete changes.added[cid];
			}
		});
		this.trigger('bulk:change', changes, this);
	},

	//collection event handlers
	//populates bulkChange hash
	_bulkChangeOnChange(m, opts){
		this._modifyBulkChanges('changed', m);
	},
	_bulkChangeOnUpdate(col, opts = {}){
		let changes = opts.changes || {};
		this._modifyBulkChanges('added', changes.added);
		this._modifyBulkChanges('changed', changes.merged);
		this._modifyBulkChanges('removed', changes.removed);
	},
	_bulkChangeOnReset(col, opts = {}){
		this._modifyBulkChanges('added', this.models);
		this._modifyBulkChanges('removed', opts.previousModels);
	},

};

const CollectionIterateAndUpdate = {
	iterate(func, opts = {}){
		let processed = [];
		if(!_.isFunction(func)) return processed;
		_(this.models).each((model, index) => {
			
			opts.index = index;
			
			if(_.isFunction(opts.filter) && !opts.filter(model, index, opts)) return;

			func(model, index, opts);
			processed.push(model);

		});
		return processed;
	},
	update(arg, opts = {}){

		let func = this._createUpdateFunc(arg, opts);
		let merged = this.iterate(func, opts);

		if(opts.silent || opts.silentUpdate) return merged;

		this.trigger('update', this, { changes:{ added:[], removed:[], merged } });
		return merged;

	},
	_createUpdateFunc(arg, opts = {}){
		let hash;
		if(_.isFunction(arg)){
			if(opts.wrapFunc === false){
				return arg;
			}else {
				hash = (model, index, options) => arg(model, index, options);
			}
		}else if(_.isObject(arg)){
			hash = arg;
		}
		else return;

		let ready = (model, index, options = {}) => {
			let setHash = _.isFunction(hash) ? hash(model, index, options) : hash;
			let setOptions = options.silentChange ? _.extend({}, options, {silent:true}) : options;
			model.set(setHash, setOptions);
		}
		return ready;
	},
}

export default mix(Bb.Collection).with(CollectionBulkchange, CollectionIterateAndUpdate).extend({
	bulkChangeEnabled: false,

	constructor: function(){
		this.cid = _.uniqueId('col');
		Bb.Collection.apply(this,arguments);
		this._initBulkChange();
	},

});
