import version from '../version';
if (version.release) {
  // console.log('++');
	window.Console = {
		error () {},
		warn () {},
		log () {}
	};
} else {
  // console.log('--');
	window.Console = console;
}
