module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
if(label){
__p+='\n	<label>'+
((__t=( label ))==null?'':__t)+
'</label>\n';
}
__p+='\n<div class="time"></div>\n<div class="c-dt-selected-date">'+
((__t=( date ))==null?'':__t)+
'</div>\n<button class="clear">&times;</button>\n';
}
return __p;
};
