
/*
import testValue from 'helpers/validate/test-value';
import enums from 'helpers/enums';
import Contact from 'components/contacts/models/contact-value-model';

import InputPlaceholdered from 'components/controls/input-placeholdered';
import Phone from './phone';
import MobilePhone from './phone';
import Email from './email';
import Website from './website';
import Social from './social';

import Values from 'components/values';

import CvType from 'components/controls/inline-select-trigger';

import Checks from 'components/controls/checks';
*/

// import template from './layout.html';

import { _ } from 'vendors';
import View from 'base/view';
import TextTagView from 'base/text-tag-view';
import mix from 'helpers/mix';
import ControlsMixin from 'components/controls/mixin';
import enums from 'helpers/enums';
import Person from 'components/controls/person';
import Checks from 'components/controls/checks';
import ContactsList from 'components/contacts/views/contacts-list';
import EntityPersonModel from 'components/contacts/models/model';

import testValue from 'helpers/validate/test-value';

export * from './contact-idname';

export default mix(View).with(ControlsMixin).extend({
	className: 'c-econtact',
	template: _.template(''),
	ignoreModel: true,
	initialize () {
		this.initValue();

		this.createNested('person', {
			skipModels: true,
			viewClass: Person,
			viewOptions: {
				controlName: 'person',
				proxyTo: this,
				value: this.model.toJSON()
			}
		});
		this.createNested('funcs', {
			skipModels: true,
			viewClass: Checks,
			viewOptions: {
				className: 'buttons-group show-selected-icon',
				proxyTo: this,
				controlName: 'funcs',
				type: 'checkbox',
				value: this.model.getFuncs(),
				sourceValues: enums.store.contragentContactFunctions,
				multiple: true,
				customViews: () => [TextTagView.header('функции', { collectionPlace: 'top' })]
			}
		});
		this.createNested('contacts', {
			skipModels: true,
			viewClass: ContactsList,
			viewOptions: {
				addClass: 'no-first-border',
				proxyTo: this,
				controlName: 'contacts',
				listHeader: true,
				model: this.model,
				collection: this.model.getContactValues(),
				header: 'Контакты'
				// customViews: [
				// 	() => TextTagView.header('контакты',{ collectionPlace:'top' })
				// ],
			}
		});
	},
	initValue () {
		let model = this.getOption('value');
		if (!_.isModel(model)) { model = new EntityPersonModel(model, { parent: this.getOption('parent') }); }

		this.model = model;

		this.value = _.omit(model.toJSON(), 'id', 'values');
		const values = model.getContactValues();
		if (this.model.isNew()) { this.listenTo(values, 'add remove change update', () => this.triggerChange(this.getValue())); }
	},
	onRender () {
		this.showAllNested();
		this.trigger('content:ready');
	},
	controlsEvents: {
		'funcs:change' (values) {
			if (_.isArray(values)) { values = values.join(', '); }
			this.value.contragentFuncs = values;
			this.triggerChange(this.getValue());
			// if(this.value.isNew()){
			// 	this.value.setFuncs(values);
			// 	this.triggerChange(this.getValue());
			// } else {
			// 	this.value.save({contragentFunc: values},{wait:true, preloader:true});
			// }
		},
		'person:change' (hash) {
			_.extend(this.value, hash);

			this.triggerChange(this.getValue());
			// console.log('PERSON CHANGE');
			// if(this.value.isNew()){
			// 	this.value.set(hash);
			// 	this.triggerChange(this.getValue());
			// }else {
			// 	this.value.save(hash, {wait:true, preloader:true});
			// }
		},
		'person:validate:error' () {
			this.triggerChange({});
		}
	},
	validate (value, opts = {}) {
		opts.errors || (opts.errors = []);
		const ownValue = value; // this.getValue();
		let nameTest = ownValue.contactName || (ownValue.person && ownValue.person.name);
		if (nameTest && _.isObject(nameTest)) {
			nameTest = _.reduce(nameTest, (memo, val, key) => {
				memo += (val || '').trim();
				return memo;
			}, '');
		}

		if (
			testValue(nameTest, 'invalidContactName', opts.errors) &&
			testValue(this.model.hasAnyContactValue(), 'invalidContactValues', opts.errors)
		) {
			return true;
		} else {
			return false;
		}
	},
	getValue () {
		const value = _.extend({}, this.value);
		if (this.model.isNew()) {
			value.values = this.model.getContactValues().toJSON();
		}
		return value;
	}
});
