// import { FilterModel } from './FilterModel';
import { filters } from './filters';
import { ReportCollection } from './ReportCollection';
import { tableSchema } from './tableSchema';
// import { TableLayoutView } from 'components/schema-table';
// import { commonTableLayoutMixin } from '../table-layout-mixin';
import { CommonReportLayout } from '../common-report-layout';
// s

const src = [
	'product',

	'totalBefore',
	'storeBefore',
	'contragentsBefore',
	// 'inProductsBefore',

	'incrementNotManual',

	'decrementSum',
	// 'decrementCalculated',

	// 'usedForProduction',

	// 'inProductsAfter',
	'contragentsAfter',
	'storeAfter',
	'totalAfter'

];

export const OutputProductionReportLayout = CommonReportLayout.extend({

	rawFilters: filters,
	_cells: [
		'product',
		'totalBefore', 'storeBefore', 'contragentsBefore',
		'appeared',
		// 'produced',
		// 'defect',
		'disappeared',
		// 'customDecrement',
		'contragentsAfter', 'storeAfter', 'totalAfter'
		// 'totalBefore', 'totalAfter', 'totalDelta',
		// 'storeBefore', 'storeAfter', 'storeDelta'
		// 'contragentsBefore', 'contragentsAfter', 'contragentsDelta',
		// 'inProductsBefore', 'inProductsAfter', 'inProductsDelta'
	],
	cells: [
		...src
	],
	// rowClassName: 'table-report-entry',
	// listClassName: 'strict-table',

	// rowUseWrapper: false,
	tableSchema,
	Collection: ReportCollection

});
