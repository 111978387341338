import { _ } from 'vendors';
import Prop from './property';
import Base from 'base/view';


export const editPropertyClickHandler = async function (property, model, view) {
	// console.log('ARGS::', arguments);
	const customHandler = view.getOption('editClick', { force: false });
	const editOptions = view.getOption('editOptions');

	if (_.isFunction(customHandler)) {
		customHandler(model, view, editOptions);
	} else if (_.isFunction(model.editProperty)) {
		let onResolve = this.getOption('onEditPropertyResolve', { force: false });
		if (!onResolve) {
			onResolve = v => {
				this.triggerMethod('edit:property:' + property, v, model);
				this.triggerMethod('edit:property', property, v, model);
			};
		}
		const behavior = this.getOption('changeBehavior');
		const shouldOnlyApply = behavior === 'apply'
			? true
			: behavior === 'patch'
				? true
				: undefined;


		model.editProperty(property, { view, shouldOnlyApply, onResolve, flatPatch: this.getOption('flatPatch'), controlViewOptions: editOptions });
	} else {
		Console.warn('Model do not implement editableProperty');
	}
};


export default Base.extend({
	constructor (opts) {
		Base.apply(this, arguments);
		this.mergeOptions(opts, ['properties', 'header', 'action']);
		this.createNestedHeader();
		this.createNestedProperties();
		this.createNestedAction();
	},
	template: Base.emptyTemplate,
	createNestedHeader () {
		if (!this.header) return;
		const name = this._cidedName('header');
		const headerText = this.header.text || '&nbsp;';
		this.createNested(name, {
			viewClass: Base.extend({ tagName: 'header', className: this.header.addClass, template: _.template(headerText) }),
			regionTemplate: { replaceElement: true }
		});
	},
	getNestedActionContext () {
		let action = this.getOption('action', { args: [this] });
		if (!action) return;
		if (_.isCtor(action)) return;
		if (_.isFunction(action)) { action = action.call(this, this); }
		if (!_.isObject(action)) return;

		return action;
	},
	createNestedAction () {
		const context = this.getNestedActionContext();
		if (!context) return;
		const nestedOptions = _.extend({}, context);
		const name = this._cidedName('action');
		this.createNested(name, nestedOptions);
	},
	_normalizeLine (line, property) {
		if (_.isFunction(line)) {
			line = line.call(this, this.model, this);
		}
		const fix = (v) => {
			if (!_.isString(v)) return v;
			return _.camelCase(v.replace(/\./g, ':'));
		};
		if (_.isString(line)) {
			return { property: line, name: fix(line) };
		} else if (_.isObject(line)) {
			return _.extend({ property, name: fix(line.name) || fix(line.property) }, line);
		} else { return { property, name: fix(property) }; }
	},
	createNestedProperties () {
		const NestedView = this.getProperty('nestedViewClass') || Prop;
		const properties = this.getOption('properties', { args: [this.model, this] });

		this._properties = {};
		_(properties).each((line, name) => {
			if (line == null || !line) {
				return;
			}
			line = this._normalizeLine(line, name);
			name = line.name;

			this._properties[name] = line;

			const model = this.model;
			const schema = this.getOption('schema');
			const lineOpts = _.extend({}, _.extend({ model, propertyModel: model, schema }, line), line);
			const View = line.viewClass ? line.viewClass : NestedView;

			if (lineOpts.editButton) {
				let cfg = model && model.getPropertyConfig(name);
				if (!cfg && schema && schema.getPropertyConfig) {
					cfg = schema.getPropertyConfig(name);
				}
				if (cfg && _.isFunction(cfg.editEnabled)) { lineOpts.editButton = cfg.editEnabled; }
			}
			const condition = lineOpts.condition;
			delete lineOpts.condition;

			// console.log('# - create: ', name, condition);
			this.createNested(name, {
				viewClass: View.extend({ className: name + ' card-line' }),
				viewOptions: lineOpts,
				regionTemplate: { replaceElement: true },
				condition
				// textOptions: line.valueOptions
			});
		});
	},
	onRender () {
		this._showOwn('header');
		this._showNestedProperties();
		this._showOwn('action');
	},
	_showNestedProperties () {
		_(this._properties).each((line, name) => {
			line = this._normalizeLine(line, name);
			name = line.name;

			const method = _.camelCase('show:' + name);
			if (!_.isFunction(this[method])) {
				Console.warn(`method ${method} not exist on this`, this);
			} else {
				// console.log('# - method: ', method);
				this[method]();
			}
		});
	},
	_cidedName (name, prefix = '') {
		return prefix + _.camelCase(this.cid + ':' + name, !!prefix);
	},
	_showOwn (name) {
		const method = this._cidedName(name, 'show');
		if (_.isFunction(this[method])) { this[method](); }
	},
	childViewEvents: {
		'edit:property:click': editPropertyClickHandler,
		// async 'edit:property:click'(property, model, view){
		// 	let customHandler = view.getOption('editClick',{force:false});
		// 	let editOptions = view.getOption('editOptions');

		// 	if(_.isFunction(customHandler)){

		// 		customHandler(model, view, editOptions);
		// 	} else if (_.isFunction(model.editProperty)) {
		// 		let onResolve = this.getOption('onEditPropertyResolve', { force:false });
		// 		if (!onResolve) {
		// 			onResolve = v => {
		// 				console.log('resolved');
		// 				this.triggerMethod('edit:property:' + property, v, model);
		// 				this.triggerMethod('edit:property', property, v, model);
		// 			}
		// 		}
		// 		let behavior = this.getOption('changeBehavior');
		// 		let shouldOnlyApply = behavior === 'apply' ? true
		// 			: behavior === 'patch' ? true
		// 				: undefined;
		// 		model.editProperty(property, { view, shouldOnlyApply, onResolve, flatPatch: this.getOption('flatPatch'), controlViewOptions: editOptions });
		// 	} else {

		// 		Console.warn('Model do not implement editableProperty');
		// 	}
		// },
		'close:click' (model, view) {
			const customHandler = view.getOption('closeClick', { force: false });
			if (_.isFunction(customHandler)) {
				customHandler(model, view);
			}
		}
	}
});
