import { HamburgerView } from 'base/view-hamburger';
import { commonMixin } from './common';
import { SchemaLabelView } from './SchemaLabelView';
import { SchemaValueView } from './SchemaValueView';

export const SchemaLabeledValueView = HamburgerView.extend({

	...commonMixin,

	baseClassName: 'schema-property-label-value',

	getCustomViews () {
		const valueView = this.getValueView();
		return [SchemaLabelView, valueView];
	},

	getValueView () {
		return SchemaValueView;
	}

});
