import { HamburgerView } from '../../base/view-hamburger';
import { EntriesManagerView } from '../entries-manager';
import { ModernFetchOnScrollView } from 'base/views';
// import { _ } from 'vendors';
import createFiltersInstance from 'components/filters/create-instance';

export const ManagedCollectionView = HamburgerView.extend({
	shouldFetch: true,
	constructor: function () {
		HamburgerView.apply(this, arguments);
		this._initializeManagedCollectionView();
		// if (this.getOption('shouldFetch', true)) {
		// 	this.once('render', () => this.fetch());
		// }
	},
	baseClassName: 'managed-collection',
	getCollection () {
		return this.collection;
	},

	_initializeManagedCollectionView () {
		this._initializeFiltersInstance();
	},
	_initializeFiltersInstance () {

		if (this.filtersInstance) { return; }

		let filtersInstance = this.getOption('filtersInstance', true);
		if (!filtersInstance) {
			filtersInstance = this._buildFiltersInstance();
		}
		this.once('before:destroy', () => filtersInstance.destroy());

		this.filtersInstance = filtersInstance;

	},
	_buildFiltersInstance () {

		const filtersOptions = this.getOption('filtersOptions', true);

		const opts = Object.assign({ entries: this.collection }, filtersOptions);

		return createFiltersInstance(opts);
	},

	getCustomViews () {
		const manager = this.getManagerView();
		const entries = this.getEntriesView();
		return [manager, entries];
	},

	getManagerView () {

		return {
			class: EntriesManagerView,
			collection: this.getCollection(),
			model: this.model,
			filtersInstance: this.filtersInstance,
			filtersViewOptions: this.getOption('filtersViewOptions'),
			infoViewOptions: this.getOption('infoViewOptions'),
			actionsEnabled: this.getOption('actionsEnabled'),
			actionsContext: this.getOption('actionsContext')
		};
	},

	getEntriesView () {
		const EntriesView = this.getOption('EntriesView', true) || ModernFetchOnScrollView;

		return {
			class: EntriesView,
			viewName: 'entries',
			shouldFetch: true,
			modernFetch: true,
			thisClassName: this.getOption('entriesClassName'),
			emptyView: this.getOption('emptyView'),
			childView: this.getOption('childView'),
			childViewOptions: this.getOption('childViewOptions'),
			collection: this.getCollection(),
			model: this.model,
			oldFilters: this.filtersInstance
		};

	},


	setupCustomView (view) {
		if (view.getOption('viewName') === 'entries') {
			this.filtersInstance.setView(view);
		}
	}

	// showPreloaderOnFetch: true,

	// fetch (fetchData, opts) {
	// 	const options = this._buildFetchOptions(fetchData, opts);
	// 	const collection = this.getCollection();
	// 	return collection.fetch(options);
	// },

	// _buildFetchOptions (fetchData, opts = {}) {
	// 	const data = this.getFetchData(fetchData, opts);
	// 	if (data) {
	// 		opts.data = _.clone(data);
	// 	}

	// 	if (this.getOption('showPreloaderOnFetch', true)) {
	// 		opts.preloader = this;
	// 	}

	// 	const options = this.getFetchOptions(data, opts);
	// 	return _.extend(opts, this.getOption('fetchOptions', true), options);
	// },

	// getFetchData: (data) => data,

	// getFetchOptions () {},

	// getNextPageData () {
	// 	const col = this.getCollection();
	// 	if (col.fetchingInProcess) return;

	// 	const res = col.getResultJSON();

	// 	if (res.endOfData) return;

	// 	const take = res.taked ?? 50;

	// 	const page = {
	// 		skip: (res.skiped || 0) + (res.returned || take),
	// 		take
	// 	};

	// 	return page;
	// },

	// fetchNext () {

	// 	const nextPage = this.getNextPageData();

	// 	if (!nextPage) {
	// 		return;
	// 	}

	// 	return this.fetch(nextPage, { remove: false });
	// }


});
// EntriesManagerView;
