import { _, $ } from 'vendors';
import Yat from 'marionette.yat';
import View from 'base/view';
import getZIndex from 'helpers/get-z-index';
import Popper from 'popper.js';

/*
import { Region } from 'backbone.marionette';

const PopoverRegion = Region.extend({
	renderOnInitialize: true,
	constructor (options = {}) {
		if (!options.el) {
			options.el = $('<div/>');
		}

		Region.call(this, options);

		if(this.getOption('renderOnInitialize'))
			this.render();

	},
	render(opts){
		this.appendToContext();
		let view = this.getPopoverView();
		this.setupPopoverView(view);
		this.show(view, opts);
		this.createPopover();
	},
	appendToContext(){
		// let $context = this.getContext();
		// $context.append(this.el);
	},
	getPopoverView(){
		return this.getOption('view');
	},
	setupPopoverView(view){
		this.listenToOnce(view, 'destroy', () => this.destroy());
	},
	createPopover()
	{
		let referer = this.getReferer();
		let element = this.getElement();
		let options = this.getPopoverOptions();
		let popover = this.popover = this.buildPopover(referer, element, options);
		return popover;
	},
	buildPopover(ref, el, options){
		if (ref instanceof Element && el instanceof Element) {
			return new Popper(ref, el, options);
		} else {
			throw new Error('Create popover exception: referer and element must be DOM element');
		}
	},
	getPopoverOptions(){

	},
	_normalizeEl(arg) {
		if (arg instanceof Element) {
			return arg;
		} else if (arg && arg.jquery) {
			return arg.get(0);
		} else if (arg && arg.el && arg.el instanceof Element) {
			return arg.el;
		} else if (arg && arg.$el && arg.$el.jquery) {
			return arg.$el.jquery;
		}
	},
	getReferer() {
		let ref = this.getOption('referer') || this.getOption('parent');
		return this._normalizeEl(ref);
	},
	getElement() {
		return this._normalizeEl(this.el);
	},
});

function inPopover(opts = {}){
	let region = new PopoverRegion(opts);
	return region;
}

*/

const Behavior = Yat.Behaviors.Behavior;
// let DynamicClass = Yat.Behaviors.DynamicClass;
const PopoverView = View.extend({
	className: 'popover-wrapper',
	template: _.template('<section></section><div class="popover-arrow" x-arrow></div>'),
	regions: { content: { el: 'section', replaceElement: true } },
	onRender () {
		const context = this.getOption('context') || $('body');
		this.$el.appendTo(context);
		const content = this.getOption('content');
		if (_.isView(content) && !content.inModal) { content.inModal = this; }
		this.showChildView('content', content);
	},
	onChildviewBeforeDestroy () {
		this.outsideDestroying = true;
		this.destroy();
	},
	onBeforeDestroy () {
		if (!this.isRendered() || this.outsideDestroying) return;
		const region = this.getRegion('content');
		if (!region.currentView) return;
		region.detachView();
	}
});

const Popover = Behavior.extend({
	showOn: 'click over',
	closeOnOutsideClick: true,
	onRender () {
		this.initializeTriggerBehavior();
	},
	initializeTriggerBehavior () {
		if (this._initialized) return;

		this._showOn = {};
		const show = (this.getOption('showOn') || '').split(' ');
		_(show).each((on) => this._showOn[on] = true); // eslint-disable-line

		if (this._showOn.click) { this._registerOnClick(); }

		if (this._showOn.over) { this._registerOnOver(); }

		this._initialized = true;
	},

	buildPopoverContent () {
		const content = this.getOption('content') || this.view.getOption('popoverInfoView');
		const contentOptions = this.getOption('contentOptions') || this.view.getOption('popoverInfoViewOptions');
		if (_.isView(content)) {
			return content;
		} else if (_.isString(content) || !content) {
			return content || ' ';
		} else if (_.isViewClass(content)) {
			const options = _.extend({ model: this.view.model, collection: this.view.collection }, contentOptions);
			return new content(options); // eslint-disable-line
		} else { return ' '; }
	},
	buildPopoverView () {
		const content = this.buildPopoverContent();
		const addClass = this.getOption('popoverViewCssClass');
		const context = this._getPopoverContext();
		return new PopoverView({ content, addClass, context });
	},
	getPopoverView () {
		if (this._popoverView) return this._popoverView;

		this._popoverView = this.buildPopoverView();
		this.listenToOnce(this._popoverView, 'before:destroy', this._onBeforePopoverViewDestroy);

		return this._popoverView;
	},
	_getPopoverContext () {
		const modal = this.view.$el.closest('[data-role="modal-wrapper"]');
		const body = $('body');
		if (modal.length) { return modal; } else { return body; }
	},
	_getPopoverViewport () {
		const parent = this._getPopoverContext();
		const viewPort = parent === $('body') ? 'viewport' : this.view.$el.parent().get(0);
		return this.getOption('viewport') || viewPort;
	},

	_createPopover (opts = {}) {
		if (opts.soft === true && this._popover) return this._popover;

		const ref = this._getReference$el();
		const placement = this.getOption('position') || 'right';

		const data = getZIndex(this.view.$el);
		const view = this.getPopoverView();
		view.$el.css('z-index', data.zIndex);
		view.render();

		const viewport = this.getOption('viewport');
		let preventOverflow;
		if (viewport !== '*') {
			preventOverflow = {
				boundariesElement: viewport
			};
		}

		const popoverOptions = {
			placement,
			modifiers: {
				preventOverflow,
				hide: {
					enabled: true
				},
				flip: {
					behavior: ['right', 'bottom']
				}
			}
		};

		this._popover = new Popper(
			ref.get(0),
			view.$el.get(0),
			popoverOptions
		);
		this._popover.originEvent = opts.event;


		this._popover.scheduleUpdate();
		this.listenToOnce(this.view, 'detach', this._onViewDetach);
		return this._popover;
	},

	_getReference$el () {
		const el = this.getOption('reference');
		if (!!el && el.jquery) return el;
		else if (typeof el === 'string') { return this.$(el); } else { return this.$el; }
	},
	_getTriggerElement () {
		const el = this.getOption('triggerElement') || this.getOption('reference');
		if (el && el.jquery) { return el; } else if (typeof el === 'string') { return { el: this.$el, selector: el }; } else { return this.$el; }
	},



	_registerOnClick () {
		this._clickPopover = _.bind(this.__clickPopover, this);
		this._outsideClickListener = _.bind(this.__outsideClickListener, this);

		let $t = this._getTriggerElement();
		let selector = null;
		if ($t.selector) {
			selector = $t.selector;
			$t = $t.el;
		}
		$t.on('click', selector, this._clickPopover);
	},
	__clickPopover (e) {
		e.stopPropagation();

		this.$el.trigger('popover:click');
		// at this point _popover is empty in any way. -- CHECK

		const outside = this.getOption('closeOnOutsideClick');

		if (!this._popover) {
			this._createPopover({ event: e });
			if (outside) {
				// e.stopPropagation();
				const $doc = $(document);
				// $(this._popover.reference).on('click', )
				$doc.on('click', this._outsideClickListener);
				$doc.on('popover:click', this._outsideClickListener);
			}
		} else {
			this._destroyPopover();
		}
	},
	__outsideClickListener (e) {
		if (this._popover) {
			const sameEvent = this._popover.originEvent.originalEvent === e.originalEvent;
			// console.log('SAMEEVENT CHECK')
			if (sameEvent) return;
			// console.log('POPOVER CHECK', $(e.target), $(this._popover.reference))
			// if ($(e.target).closest(this._popover.reference).length) {
			// 	console.log('THE BUTTON!')
			// 	return;
			// }
		}
		const $e = $(e.target).closest('.popover-wrapper');
		if ($e.length) return;

		e.stopPropagation();
		e.preventDefault();
		const $doc = $(document);
		$doc.off('click', this._outsideClickListener);
		$doc.off('popover:click', this._outsideClickListener);
		this._destroyPopover();
	},
	_registerOnOver () {
		this._delayedPopoverDestroy = _.bind(this.__delayedPopoverDestroy, this);
		this._overTrigger = _.bind(this.__overTrigger, this);
		this._overPopover = _.bind(this.__overPopover, this);
		let $t = this._getTriggerElement();
		let selector = null;
		if ($t.selector) {
			selector = $t.selector;
			$t = $t.el;
		}
		$t.on('mouseenter', selector, this._overTrigger);
		$t.on('mouseleave', selector, this._delayedPopoverDestroy);
	},
	__overTrigger () {
		if (this._popover) {
			this._outTimer && clearTimeout(this._outTimer);
		} else {
			this._createPopover();
			const view = this.getPopoverView();
			view.$el.on('mouseenter', this._overPopover);
			view.$el.on('mouseleave', this._delayedPopoverDestroy);
		}
	},
	__delayedPopoverDestroy () {
		this._outTimer = setTimeout(() => this._destroyPopover(), 300);
	},
	__overPopover () {
		if (!this._popoverView) return;
		clearTimeout(this._outTimer);
	},
	_destroyPopover () {
		setTimeout(() => {
			if (this._popover) {
				this._popover.destroy();
				delete this._popover;
			}
			this._popoverView && this._popoverView.destroy();
			this.stopListening(this.view, 'detach', this._onViewDetach);
		}, 0);
	},
	_onViewDetach () {
		this._destroyPopover();
	},
	onBeforeDestroy () {
		this._destroyPopover();
	},
	_onBeforePopoverViewDestroy () {
		this._popover && this._popover.destroy();
		delete this._popover;

		this._popoverView && this._popoverView.$el.off('mouseenter', this._overPopover);
		this._popoverView && this._popoverView.$el.off('mouseleave', this._delayedPopoverDestroy);
		delete this._popoverView;
	}
});

export default Popover;
