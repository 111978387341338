import promise from 'es6-promise';

if (!window.Promise) {
	promise.polyfill();
}

function promiseState (p) {
	const t = {};
	return Promise.race([p, t])
		.then(
			v => (v === t) ? 'pending' : 'fulfilled',
			() => 'rejected'
		);
}
/* eslint-disable */
Promise.prototype.state = function () {
	return promiseState(this);
};
