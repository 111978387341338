import { _ } from 'vendors';
import View from 'base/view';
import template from 'base/empty-template';
import YearsView from './years';
import Collection from 'base/collection';
import Controls from './controls';

export default View.extend({
	template,
	className: 'c-dt-years-selection',
	initialize () {
		this.step = 20;
		this.startYear = (this.getOption('year') || this.model.get('year') || (new Date()).getFullYear()) - this.step / 2;
		this.collection = new Collection();
		this.resetCollection();
		this.initNested();
	},
	childViewEvents: {
		'go:next' () {
			this.changeStartYear(1);
		},
		'go:prev' () {
			this.changeStartYear(-1);
		}
	},
	changeStartYear (where) {
		this.startYear += this.step * where;
		this.resetCollection();
	},

	resetCollection: function () {
		const x = []; x[this.step - 1] = true;
		const start = this.startYear;
		const models = _(x).map((v, id) => ({ id: start + id }));
		this.collection.reset(models);
		const label = this.collection.first().id + ' &mdash; ' + this.collection.last().id;
		this.$('.c-dt-c-years').html(label);
	},

	initNested () {
		this.createNested('controls', {
			viewClass: Controls,
			regionTemplate: {
				el: '.controls',
				replaceElement: true
			}
		});
		this.createNested('years', {
			viewClass: YearsView,
			regionTemplate: {
				el: '.years',
				replaceElement: true
			}
		});
	},
	onRender () {
		this.showAllNested();
	}

});
