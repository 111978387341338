import { _, $ } from 'vendors';
import UiCardBlock from 'components/ui-card-block/block';
import UiItem from 'components/ui-list/item';
import View from 'base/view';
import CollectionView from 'base/collection-view';
import Collection from 'base/collection';
import { ProcessGoalModel } from 'mod/commonProcesses/models/processGoal';
import paths from 'helpers/paths';
import smartOpen from 'helpers/smart-open';

import twinContext from 'helpers/date/twin-context';
import twinTemplate from 'helpers/date/twin.html';

// import ListItem from 'components/ui-list/item';
import { SwappableBehavior } from 'behaviors/sortable';



const GoalItemView = UiItem.extend({
	initialize () {
		this.process = this.getOption('process');
	},
	tagName: 'li',
	className: 'ui-list-item goal-item check-list-item',
	cssClassModifiers: [
		m => m.isJoinedCanceled() ? 'canceled' : '',
		m => m.isJoinedFailed() ? 'failed' : '',
		m => m.isClosed() ? 'closed' : '',
		m => m.isOptional() ? 'optional' : '',
		m => m.hasJoined() ? 'has-joined' : ''
	],
	leftButtons () {
		const btns = [];

		if (this.getOption('editMode')) return btns;

		if (!this.model.isOptional()) {
			const checkbtn = { label: '<i></i>', class: 'checker', 'data-role': 'checker' };
			if (this.model.hasJoined()) {
				checkbtn.disabled = 'disabled';
				checkbtn.title = 'Эта цель превращена в задачу или процесс и завершится автоматически при завершении связанной задачи или процесса';
			}
			btns.push(checkbtn);
		}
		return btns;
	},
	rightButtons () {
		const btns = [];

		if (this.getOption('editMode')) return ['orderer'];

		if (this.process.amIAdmin() && this.model.isOptional()) {
			btns.push({ label: '<i></i>', class: 'discard-optional', 'data-role': 'skip:optional', title: 'Нажмите эту кнопку елси хотите исключить эту опциональную цель' });
			btns.push({ label: '<i></i>', class: 'accept-optional', 'data-role': 'accept:optional', title: 'Нажмите эту кнопку если хотите добавить эту опциональную цель' });
		}
		if (this.process.amIAdmin() && !this.model.isClosed() && !this.model.hasJoined() && !this.model.isOptional()) {
			btns.push({ label: '<i></i>', class: 'join-task', 'data-role': 'create:joined:task', title: 'Нажмите эту кнопку если хотите превратить эту цель в задачу' });
		}
		return btns;
	},
	rightItems () {
		const items = [];

		if (this.getOption('editMode')) return items;

		const date = this.model.getClosedDate();
		if (this.model.isClosed() && date) {
			items.push(
				{
					template: twinTemplate,
					templateContext () {
						return twinContext(date);
					}
				}
			);
		}

		return items;
	},
	onClickChecker () {
		if (!this.process.amIAdmin()) return;
		this.model.toggleClosed();
	},
	onClickSkipOptional () {
		this.model.setDisabled();
	},
	onClickAcceptOptional () {
		this.model.acceptOptional();
	},
	onClickCreateJoinedTask () {
		this.model.createJoinedTask();
	},
	text () {
		const joined = this.model.getJoined();
		if (joined) {
			return joined.toDo || joined.name;
		}
		return this.model.get('name');
	},
	topText () {
		if (this.model.hasJoinedTask()) {
			return 'задача';
		} else if (this.model.hasJoinedProcess()) {
			return 'процесс';
		}
	},
	smallText () {
		const comment = this.model.get('closeComment');
		if (!this.model.isClosed() && !comment) return;
		return comment;
	},
	bottomText () {
		if (this.model.isJoinedCanceled()) {
			return 'отменена';
		}
		if (this.model.isJoinedFailed()) {
			return 'провалена';
		}
		return '';
	},
	addEvents: {
		'click > button[data-role]' (e) {
			const role = $(e.target).closest('button').data('role');
			this.triggerMethod('click:' + role, e);
		}
	},
	onTextClick (e) {
		if (!this.model.hasJoined()) return;
		const task = this.model.getJoinedTask();
		if (task) {
			const url = paths.url('tasks') + '/' + task.id;
			smartOpen(url, e);
		}
	}
});

const BlockHeader = View.extend({
	className: 'header-with-buttons',
	tagName: 'header',
	template: _.template(`
		<span>Цели</span>
		<a data-show="show-all" title="показать все цели">все</a>
		<a data-show="show-unchecked" title="показать ещё недостигнутые цели">не достигнутые</a>
		<a data-show="show-checked" title="показать только достигнуты цели">достигнутые</a>
		<i></i>
		<%if (sortable) {%><a data-edit="change-order" title="изменить порядок целей">из. порядок</a><% } %>
	`),
	templateContext () {
		const process = this.getOption('process');
		const sortable = process.amIAdmin();
		const available = !!this.collection.length;
		return { sortable, available };
	},
	onRender () {
		if (!this.showState) {
			this.showState = 'show-all';
		}
		this.updateAClasses();
	},
	updateAClasses () {
		this.$('[data-show]').each((ind, el) => {
			el = $(el);
			if (el.data('show') === this.showState) {
				el.addClass('active');
			} else {
				el.removeClass('active');
			}
		});
		const $edit = this.$('[data-edit]');
		if (this.editState) {
			$edit.addClass('active');
		} else {
			$edit.removeClass('active');
		}
	},
	events: {
		'click [data-show]' (e) {
			if (this.editState) {
				this._toggleEdit();
			}
			const $el = $(e.target).closest('[data-show]');
			const type = $el.data('show');
			this.showState = type;
			this.updateAClasses();
			this.triggerMethod('change:show', this.showState);
		},
		'click [data-edit]' (e) {
			this._toggleEdit();
		}
	},
	_toggleEdit () {
		if (this.editState) {
			this.editState = false;
			this.showState = 'show-all';
			this.updateAClasses();
		} else {
			this.editState = true;
			this.showState = null;
			this.updateAClasses();
		}
		this.triggerMethod('before:toggle:edit:order');
	}
});
const GoalsItemsOrderView = CollectionView.extend({
	childView: GoalItemView,
	viewFilter (v) {
		return !v.model.get('isDisabled');
	},
	childViewOptions () {
		return {
			editMode: true,
			process: this.getOption('process')
		};
	},
	behaviors: [SwappableBehavior.extend({ selector: '.orderer', property: 'order', swapOptions: { silent: true } })], // [OrdererBehavior],
	initialize () {
		const takedIndexes = {};
		const missedModels = [];
		const freeIndexes = [];
		this.collection.each(model => {
			const index = model.get('order');
			if (_.isNumber(index)) {
				takedIndexes[index] = true;
			} else {
				missedModels.push(model);
			}
		});
		if (!missedModels.length) return;
		for (let x = 0; x < this.collection.length; x++) {
			if (!takedIndexes[x]) {
				freeIndexes.push(x);
			}
		}
		_.each(missedModels, model => {
			model.set('order', freeIndexes.pop(), { silent: true });
		});
	},
	viewComparator (v1, v2) {
		return v1.model.get('order') - v2.model.get('order');
	},
	onInteractionEnd (view, changed) {
		if (!changed || !changed.length) {
			return;
		}
		const map = changed.reduce((memo, model) => {
			memo[model.id] = model.get('order');
			return memo;
		}, {});
		this.collection.trigger('change');
		this.collection.trigger('edited');
		this.collection.trigger('update:order', map);
	}
});

const GoalsItemsView = CollectionView.extend({
	className: 'ui-list',
	tagName: 'ul',
	childView: GoalItemView,
	childViewOptions () {
		return {
			process: this.getOption('process')
		};
	},
	viewFilter (v) {
		// console.log('fltr ->', this.cid, this._listType);
		if (this._listType === 'show-all') {
			return !v.model.get('isDisabled');
		} else if (this._listType === 'show-unchecked') {
			return !v.model.get('isDisabled') && !v.model.get('closed');
		} else if (this._listType === 'show-checked') {
			return !v.model.get('isDisabled') && !!v.model.get('closed');
		} else {
			return !v.model.get('isDisabled');
		}
	},
	viewComparator (v1, v2) {
		return v1.model.get('order') - v2.model.get('order');
	},
	onSwapShow (type) {
		console.log('SWAPING');
		this._listType = type;
		this.sort();
	},
	collectionEvents: {
		'change:closed' (e) {
			this.sort();
		}
	}
});


function buildCollection(model) {
	const items = model.get('goals') || [];
	const collection = new Collection(items, { model: ProcessGoalModel });
	collection.listenTo(model, 'new:goal', goal => collection.add(goal));

	collection.comparator = 'order';
	collection.sort();
	return collection;
}

function getViewOptions(_this) {
	const collection = buildCollection(_this.model);
	const opts = {
		onBeforeDestroy () {
			collection.stopListening();
		},
		model: _this.model,
		collection,
		className: 'card lined with-border goals-block',
		header: new BlockHeader({ process: _this.model, collection }),
		content: new GoalsItemsView({ collection, process: _this.model }),
		childViewEvents: {
			'change:show' (type) {
				if (this._editMode) return;
				this.getRegion('content').currentView.triggerMethod('swap:show', type);
			},
			'before:toggle:edit:order' () {
				if (this._editMode) {
					this._editMode = false;
					this.showChildView('content', new GoalsItemsView({ collection, process: _this.model }));
				} else {
					this._editMode = true;
					this.showChildView('content', new GoalsItemsOrderView({ collection, process: _this.model }));
				}
			}
		},
		collectionEvents: {
			'update:order' (map) {
				// console.log('update-order', map);
				this.model.apiPost('goals/update-order', map);
			}
		},
		action: 'add:goal'
	}
	return opts;
}

export function goalsBlock (_this) {
	const items = _this.model.get('goals') || [];
	const collection = new Collection(items, { model: ProcessGoalModel });
	collection.listenTo(_this.model, 'new:goal', goal => collection.add(goal));

	collection.comparator = 'order';
	collection.sort();
	const block = {
		viewClass: UiCardBlock,
		viewOptions: {
			onBeforeDestroy () {
				collection.stopListening();
			},
			model: _this.model,
			collection,
			className: 'card lined with-border goals-block',
			header: new BlockHeader({ process: _this.model, collection }),
			content: new GoalsItemsView({ collection, process: _this.model }),
			childViewEvents: {
				'change:show' (type) {
					if (this._editMode) return;
					this.getRegion('content').currentView.triggerMethod('swap:show', type);
					// this.content
				},
				'before:toggle:edit:order' () {
					if (this._editMode) {
						this._editMode = false;
						this.showChildView('content', new GoalsItemsView({ collection, process: _this.model }));
					} else {
						this._editMode = true;
						this.showChildView('content', new GoalsItemsOrderView({ collection, process: _this.model }));
					}
				}
			},
			collectionEvents: {
				'update:order' (map) {
					// console.log('update-order', map);
					this.model.apiPost('goals/update-order', map);
				}
			},
			action: 'add:goal'
		}
	};
	_this.createNested('goalsBlock', block);
}

export function goalsContent(view) {
	const opts = getViewOptions(view);
	opts.class = UiCardBlock;
	return opts;
}

export function getLastFewGoals(process) {
	const collection = buildCollection(process);
	return {
		class: GoalsItemsView,
		collection,
		process,
		onRender() {
			this.onSwapShow('show-unchecked');
		}
	}
}