import { _, $ } from 'vendors';

import View from 'base/view';
import { Model } from 'base/models';
import ControlMixin from 'components/controls/mixin';
import { ExistsBatchesView } from './ExistBatchesView';

export const SelectBatchIdView = ControlMixin(View).extend({
	className: 'enter-new-batch-id',
	initialize (opts) {
		this.mergeOptions(opts, ['process', 'value']);
		this.value = opts.value;
		this.delegate('input', 'input.input-batch-name', _.debounce(this.inputBatchName, 300).bind(this));
	},
	template: `
		<div class="input-wrapper">
			<div class="ui-list-item">
			<span>
				<input class="input-batch-name" type="text" placeholder="укажите номер партии">
			</span>
			</div>
		</div>
		<div class="exists-wrapper">
			<h5>Существующие партии с теми же компонентами</h5>
			<div class="exist-batches">Подождите, идёт проверка</div>
		</div>
	`,
	ui: {
		inp: 'input'
	},
	regions: {
		exists: '.exist-batches'
	},
	onRender () {
		const checker = new Model();
		checker.url = this.process.url() + '/production/get-product-batch-id';
		checker.save(null, { wait: true, method: 'post', attrs: this.value }).then(data => {
			this.showExists(data);
		});
	},
	showExists (data) {
		const view = new ExistsBatchesView({ data });
		this.showChildView('exists', view);
	},
	inputBatchName (e) {
		this.setValue($(e.target).val());
	},
	setValue (value) {
		this.value.idString = value;
		this.triggerChange();
	},
	validate () {
		return this.value.idString && this.value.idString.length > 1;
	},
	childViewEvents: {
		clicked (idString) {
			this.ui.inp.val(idString);
			this.setValue(idString);
		}
	}
});
