import '../api-config';
import BaseCollection from 'base/collection';
import Model from './model';
import paths from 'helpers/paths';

const Collection = BaseCollection.extend({
	model: Model,
	url: paths.api('staff:employees'),
	getName (entry) {
		const founded = this.get(entry);
		return founded && founded.getName();
	}
});



export default Collection;
