import BasePage from 'pages/auth';
import Layout from './layout';
import Model from 'mod/productionTypes/models/model';
import PageErr from 'pages/error';
import busData from 'bus/data';
const Page = BasePage.extend({
	name: 'contragent page',
	label: (p) => 'Тип продукции: ' + ((p.display && p.display('name')) || ''),
	route: ':id',
	skipMenu: true,
	Layout,
	wrapLayout: true,
	socketGroups: (m) => 'producttype:' + m.id.toString().toLowerCase(),
	preventStart: function (ac) {
		const prev = BasePage.prototype.preventStart.call(this, ac);
		if (prev) return prev;

		if (!ac) { return; }

		if (!busData.productTypes(ac.args.id)) { return PageErr.NotFound('Тип продукции не найден', this.url()); }
	},
	onBeforeStart (ac) {
		const model = new Model({ id: ac.args.id });
		this.addModel(model);
		this.addStartPromise(model.fetch());
	}
});

export default Page;
