import busData from 'bus/data';
import { buildFilters as baseBuildFilters } from 'components/filters-v2/buildFilters';

export const commonFilters = {
	period: {
		label: 'период',
		valueType: 'datetime',
		range: true
	},
	productId: v => {
		const sourceValues = v.getSelectModels('products');
		return {
			label: 'продукция',
			valueType: 'string',
			controlType: 'select',
			sourceValues
		};
	},
	actorId: v => {
		const sourceValues = v.getSelectModels('employees');
		return {
			label: 'сотрудник',
			valueType: 'string',
			controlType: 'select',
			sourceValues
		};
	},
	contragentId: v => {
		const sourceValues = v.getSelectModels('contragents');
		return {
			label: 'контрагент',
			valueType: 'string',
			controlType: 'select',
			sourceValues
		};
	},
	departmentId: {
		hidden: true,
		valueType: 'string'
	}
};

export function buildFilters (filters, options) {
	return baseBuildFilters(filters, options);
}
