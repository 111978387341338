import ModelProperties from 'components/ui-model-card/properties';
import h from 'components/ui-model-card/helpers';
import ControlMixin from 'components/controls/mixin';
import Model from 'base/model';
import EditableProperties from 'mixins/editable-properties';
import enums from 'helpers/enums';
import busData from 'bus/data';


export const productCycleProperties = () => ({
	properties: {
		name: {
			display: {
				label: 'Название производственного цикла!',
				ifEmpty: '&mdash;'
				// transform: (v,o,m) => v || (m && m.get('fullName'))
			}
		},
		type: {
			sourceValues: () => enums.store.productionCycleTypes,
			type: 'enum',
			display: {
				label: 'тип производственного цикла',
				transform: (v) => enums.get('productionCycleTypes', v),
				ifEmpty: '&mdash;'
			}
		},
		state: {
			sourceValues: () => enums.store.productionCycleStates,
			type: 'enum',
			display: {
				label: 'статус',
				transform: (v) => enums.get('productionCycleStates', v),
				ifEmpty: '&mdash;'
			}
		},
		departmentId: {
			nested: (id) => busData.departments(id),
			sourceValues: () => busData.departments(),
			controlType: 'departmentsSelect',
			display: {
				label: 'отдел / производственный участок',
				transform: (v) => busData.departmentName(v)
			}
		},
		responsibleId: {
			notNull: true,
			nested: (eId) => busData.employees(eId),
			sourceValues: () => busData.employees(),
			controlType: 'employeesSelect',
			display: {
				label: 'ответственный',
				transform: (v) => busData.employeeName(v)
			}
		},
		creatorId: {
			// notNull: true,
			// nested: (eId) => busData.employees(eId),
			// sourceValues: () => busData.employees(),
			// controlType: 'employeesSelect',
			display: {
				label: 'создатель',
				transform: (v) => busData.employeeName(v)
			}
		},
		productionMeasureId: {
			sourceValues: () => busData.measures(),
			controlType: 'select',
			display: {
				label: 'Производственная ед.',
				ifEmtpy: '&mdash;',
				transform: (value, opts, model) => {
					return busData.measures(value, 'short');
				}
			}
		},

		baseUnitsInProductionUnit: {
			display: {
				label: 'Учётных в 1 производственной',
				ifEmtpy: '&mdash;',
				transform: (value, opts, model) => {
					const p_m = model.display('productionMeasureId');
					const b_m = model.product && model.product.display('unitMeasureId');
					const b_in_p = model.get('baseUnitsInProductionUnit');
					return `1 ${p_m} = ${b_in_p} ${b_m}`;
					// return model.baseUnitsInStoreUnit;
				}
			}
		}
	}
});

export const AddProduceCycleModel = EditableProperties(Model).extend({

	...productCycleProperties()

	// properties: {
	// 	name: {
	// 		display:{
	// 			label:'Название производственного цикла',
	// 			ifEmpty: '&mdash;'
	// 			//transform: (v,o,m) => v || (m && m.get('fullName'))
	// 		},
	// 	},
	// 	state: {
	// 		sourceValues: () => enums.store.productionCycleStates,
	// 		type: 'enum',
	// 		display:{
	// 			label: 'статус',
	// 			transform: (v) => enums.get('productionCycleStates', v),
	// 			ifEmpty: '&mdash;'
	// 		}
	// 	},
	// 	responsibleId: {
	// 		notNull:true,
	// 		nested: (eId) => busData.employees(eId),
	// 		sourceValues: () => busData.employees(),
	// 		controlType:'employeesSelect',
	// 		display: {
	// 			label:'ответственный',
	// 			transform: (v) => busData.employeeName(v)
	// 		}
	// 	},

	// }
});



const addDefs = { editButton: true };
const addOpts = function (...args) {
	return Object.assign({}, addDefs, ...args);
};


const BaseChange = ControlMixin(ModelProperties);

export const AddProduceCycle = BaseChange.extend({
	constructor () {
		BaseChange.apply(this, arguments);
	},
	initialize () {
		// console.log('product', this.product, );
		const prod = this.getOption('product');
		if (!this.model) {
			console.log('ADDING MODEL', prod.get('unitMeasureId'));
			this.model = new AddProduceCycleModel();
		}
		this.model.product = prod;
		this.once('render', () => this.triggerChange());
	},
	className: 'card lined with-border add-produce-cycle',
	isEditMode () {
		return this.getOption('editMode');
	},
	properties: (m, v) => ([
		h.ui('name', addOpts()),
		v.isEditMode() ? h.ui('state', addOpts({ editButton: m.amIAdmin() })) : null,
		v.isEditMode() ? h.ui('type', addOpts({ editButton: m.amIAdmin() })) : null,
		v.isEditMode() ? h.ui('departmentId', addOpts({ editButton: m.amIAdmin() })) : null,
		v.isEditMode() ? h.ui('responsibleId', addOpts({ editButton: m.amIAdmin() })) : null,
		v.isEditMode() ? h.ui('creatorId', addOpts({ editButton: false })) : null,
		v.isEditMode() ? h.ui('productionMeasureId', addOpts({ half: true, editButton: m.amIAdmin() })) : null,
		v.isEditMode() ? h.ui('baseUnitsInProductionUnit', addOpts({ half: true, editButton: m.amIAdmin() })) : null
	]),
	getValue () {
		return this.model.toJSON();
		// return _.extend({joinAs:'purchase'}, this.model.toJSON());
	},
	modelEvents: {
		'change' () {
			this.triggerChange();
		}
	},
	validate (value) {
		return (value && value.name) || false;
		// if (this.type === 'contragent') {
		// 	return value.contragentId != null; //(value.childId && value.production && value.production.amount);
		// } else if (this.type === 'product') {
		// 	return value.productId != null;
		// }
		// return false;
	}
});
