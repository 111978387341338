import View from 'base/view';
import CollectionView from 'mod/tasks/views/cards';
import TextTagView from 'base/text-tag-view';
import PreventWheel from 'mixins/view/prevent-wheel';
import BaseCollectionView from 'base/collection-view';
export default PreventWheel(View).extend({
	template: View.emptyTemplate,
	className: 'column-fullheight board-column bad-entries',
	dynamicClassConfig: {
		events: ['childview:render:children']
	},
	cssClassModifiers: [
		(m, v) => v.isInPast ? 'past-column' : '',
		(m, v) => { return v.getFilteredModelsCount() ? '' : 'hide-if-empty'; }
	],
	getFilteredModelsCount: BaseCollectionView.prototype.getFilteredModelsCount,
	initialize () {
		this.entries = this.getOption('entries');

		if (!this.collection.isFetched()) {
			this.collection.fetch();
		}
		this.createNested('header', {
			viewClass: TextTagView.View,
			viewOptions: {
				tagName: 'header',
				text: 'просроченные задачи'
			}
		});
		this.createNested('cards', {
			viewClass: CollectionView,
			viewOptions: {
				addClass: 'column-scrollable',
				viewFilter: this.getOption('viewFilter', { force: false })
			}
		});
		this.initCollectionsListeners();
	},
	onRender () {
		this.showAllNested();
	},
	initCollectionsListeners () {
		this.listenTo(this.collection, 'change', this._onBadChange);
		this.listenTo(this.entries, 'change', this._onGoodChange);
		this.listenTo(this.collection, 'update', () => this.trigger('refresh'));
	},
	modelEvents: {
		'change:type' () {
			this.collection.fetch();
		}
	},
	_onBadChange (model) {
		const d = model.getDate('remindAt');
		if (d >= new Date()) {
			this.collection.remove(model);
			this.entries.add(model);
		} else {
			this.collection.sort();
		}
	},
	_onGoodChange (model) {
		const d = model.getDate('remindAt');
		if (d < new Date()) {
			this.entries.remove(model);
			this.collection.add(model);
		}
	}

});
