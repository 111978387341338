export default (Base) => {
	const Mixin = Base.extend({
		constructor: function () {
			Base.apply(this, arguments);
			this._initSingleFetch();
			this.on('reset', this._onResetSingleFetch);
		},
		_initSingleFetch () {
			this.singleFetchPromise = new Promise((resolve, reject) => {
				this.once('fetch:complete', (success) => {
					if (success) {
						resolve(this);
					} else {
						reject(this);
					}
				});
			});
		},
		fetchIfNot () {
			if (this.isNeverFetched()) { this.fetch(); }

			return this.singleFetchPromise;
		},
		_onResetSingleFetch (data, options = {}) {
			if (options.reinit !== true) return;
			this._initSingleFetch();
		}
	});
	Mixin.SingleFetch = true;
	return Mixin;
};
