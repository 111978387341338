import CollectionView from 'base/collection-view';
import { componentComparator } from 'mod/productionProducts/models/prod-cycle/prodCycleProd-model';
import { GetInfoMixin } from './common';
import { ComponentView } from './ComponentView';

export const ComponentsListView = CollectionView.extend({
	...GetInfoMixin,
	className: 'cycle-components',
	initialize () {
		const proc = this.getProcess();
		const data = proc.get('productionCycleComponents');
		this.collection.reset(data); // = new Collection(data);
	},
	childView: ComponentView,
	childViewOptions () {
		return this.getDefOptions();
	},
	viewComparator (v1, v2) {
		return componentComparator(v1.model, v2.model);
	}
});
