import PopoverBehavior from './popover';

const InfoPopoverBehavior = PopoverBehavior.extend({
	// reference:'button.options',
	position: 'right-start',
	popoverViewCssClass: 'popover-info',
	showOn: 'click'
});

export default InfoPopoverBehavior;
