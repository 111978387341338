import { bus } from './bus';
import { GetInfoMixin, _meas } from './common';
import { _ } from 'vendors';
import UiListItem from 'components/ui-list/item';

export const InputAmountView = UiListItem.extend({
	...GetInfoMixin,
	addClass: 'produce-amount',
	text: '<input>',
	topText: (m, v) => 'укажите количество ' + v.getUnitText(),
	ui: { inp: 'input' },
	initialize () {
		const cb = _.debounce(this.textInputed.bind(this), 100);
		this.delegate('input', 'input', cb);
	},
	textInputed (event) {
		let text = this.ui.inp.val();
		text = (text || '').replace(',', '.');
		text = text === '' ? undefined : parseFloat(text, 10);
		if (text == null || text > 0) {
			bus.newamount(text);
		}
	},
	measureInfo () {
		const prod = this.getRawProduct();
		const base_m = _meas(prod.unitMeasureId);
		const cycle = this.getRawCycle();
		const cycle_m = _meas(cycle.productionMeasureId);
		const baseInCycle = cycle.baseUnitsInProductionUnit;
		return {
			baseUnit: base_m,
			cycleUnit: cycle_m,
			baseInCycle
		};
	},
	getUnitText () {
		const info = this.measureInfo();
		return info.cycleUnit;
	}
});
