export function storeUnsended(key, value) {
	if (!localStorage) { return; }
	if (!key) {
		console.warn('unable to store unsended message, key is not defined');
		return;
	}
	if (value == null) {
		localStorage.removeItem(key);
	} else {
		localStorage.setItem(key, value);
	}
	// console.warn('unsended stored', key, value);
}

export function restoreUnsended(key) {
	if (!localStorage) { return; }
	if (!key) {
		console.warn('unable to restore unsended message, key is not defined');
		return;
	}
	const value = localStorage.getItem(key);
	// console.warn('unsended restored', key, value ? value.length : undefined);
	return value || '';
}

export function getModelUnsendedKey(model) {
	const type = model.socketEntity || model.entityName;
	if (type && model.id) {
		return ['unsended', user.get('employeeId'), type, model.id].join(':')
	}
}

export const storeUnsendedMixin = {
	_storeUnsended(value) {
		if ('_unsendedCacheId' in this === false) {
			this._unsendedCacheId = this.getOption('unsendedCacheId', true);
		}
		storeUnsended(this._unsendedCacheId, value);
	},
	_restoreUnsended() {
		if ('_unsendedCacheId' in this === false) {
			this._unsendedCacheId = this.getOption('unsendedCacheId', true);
		}
		return restoreUnsended(this._unsendedCacheId);
	}
}

