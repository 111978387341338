import busData from 'bus/data';
import { measuredAmount } from 'utils';

export const tableSchema = {
	created: {
		thisClassName: 'created fixed',
		onAfterInitialize () {
			if (this.isHeader()) { return; }
			this.value = new Date(this.model.get('created'));
		},
		headerText: 'дата',
		text: v => v.value.toLocaleDateString(),
		smallText: v => v.value.toLocaleTimeString()
	},
	amount: {
		// class: Ui2AtomView,
		thisClassName: 'amount',
		text: v => {

			const {
				amount: sourceAmount,
				baseUnitMeasureId: sourceMeasureId
				// producedUnitMeasureId: sourceMeasureId,
				// baseUnitsInProducedUnit: destinationUnitsInSourceUnit
			} = v.model.attributes;

			const options = {
				sourceAmount,
				sourceMeasureId,
				destinationMeasureId: sourceMeasureId,
				destinationUnitsInSourceUnit: 1,
				display: true
			};

			return measuredAmount(options);
		},
		// bottomText: 'произведено',
		headerText: 'произведено'
		// topText: v => 'склад до: ' + v.model.get('amountInStoreBefore'),
		// bottomText: 'объём транзакции'
			// v => 'склад после: ' + v.model.get('amountInStoreAfter')
	},
	product: {
		thisClassName: 'product fixed',
		text: v => v.model.get('productName'),
		headerText: 'продукция'
	},
	batchName: {
		thisClassName: 'batch',
		text: v => v.model.get('batchIdString'),
		textHtml: (v, nov) => `<span class="batch-identifier">${v == null ? nov : v}</span>`
	},
	productAndBatchNames: {
		thisClassName: 'product fixed',
		smallText: v => v.model.get('productBatchIdString'),
		smallTextHtml: (v, nov) => `<span class="batch-identifier">${v == null ? nov : v}</span>`,
		text: v => v.model.get('productName'),
		headerText: 'продукция'
	},
	actor: {
		thisClassName: 'actor',
		text: v => busData.employeeName(v.model.get('actorId')),
		headerText: 'сотрудник'
	}

};
