import { _ } from 'vendors';
export function extractErrorMessage (arg) {
	if (!arg || _.isString(arg)) return arg;
	if ('message' in arg && arg.message) {
		return arg.message;
	} else if ('error' in arg && arg.error) {
		return arg.error;
	}

	if ('responseJSON' in arg) {
		const mes = extractErrorMessage(arg.responseJSON);
		if (mes) return mes;
	}

	if ('statusText' in arg && arg.statusText) {
		return arg.statusText;
	}
}
