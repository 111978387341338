import { _ } from 'vendors';
import View from './item';
export default View.extend({
	addClass: 'as-button action',
	collectionPlace: 'bottom',
	onTextClick () {
		const action = this.getOption('action');
		if (!action) return;

		const context = this.getOption('actionContext') || this;

		if (_.isFunction(context.executeAction)) { return context.executeAction(action, { callback: this.getOption('actionCallback', { force: false }) }); } else if (_.isFunction(context.triggerMethod)) { context.triggerMethod(action); }
	}
});
