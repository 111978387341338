import CollectionView from 'base/collection-view';
import childView from './list-item';
import TimeChangeMonitor from 'components/time-change-monitor';
import BaseCollection from 'base/collection';
import TaskModel from '../models/model';

export default CollectionView.extend({
	constructor () {
		CollectionView.apply(this, arguments);
		if (!this.collection) {
			const tasks = this.getOption('tasks');
			if (this.getOption('shouldCreateCollection')) {
				this._createSimpleCollection(tasks);
			}
		}
		this.timeChangeMonitor = new TimeChangeMonitor({
			collection: this.collection,
			getMs: (m) => m.getRemindAt(),
			callback: (m) => m.trigger('change', m),
			context: this
		});
	},
	tagName: 'ul',
	childView,
	// buildEmptyView(){
	// 	//console.log('~~~~~');
	// 	return CollectionView.prototype.buildEmptyView.apply(this, arguments);
	// },
	_createSimpleCollection(tasks) {
		this.collection = new BaseCollection(tasks, { model: TaskModel })
	},
	emptyViewOptions () {
		return {
			entries: this.collection
		};
	},
	emptyView: childView.Empty.extend({
		initialize () {
			this.waitText = 'подождите, задачи подгружаются';
			this.emptyText = 'пусто';
			const col = this.getCol();
			this.listenTo(col, 'fetch:complete', () => {
				if (!this.isDestroyed()) { this.render(); }
			});
		},
		getCol () {
			return this.getOption('entries');
		},
		text () {
			const col = this.getCol();
			if (col.isFetching()) {
				return this.waitText;
			} else {
				return this.emptyText;
			}
		}
	})
});
