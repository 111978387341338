import BasePage from 'pages/auth';
import Layout from './layout';
// import { RequestType } from 'mod/configs/models/requestResponsible';
import { requestTypes } from 'mod/configs/models/requestResponsible';
// import Emps from './emps';
// import Deps from './deps';

const Page = BasePage.extend({
	name: 'requesttype page',
	label () { return 'тип заявки: ' + ((this.model && this.model.display('name')) || ''); },
	route: ':id',
	skipMenu: true,
	Layout,
	// wrapLayout: true,
	// socketGroups: (m) => 'contragent:'+ m.id.toString().toLowerCase(),
	async onBeforeStart (ac) {
		// let model = new RequestType({id : ac.args.id});
		this.addStartPromise(async () => {
			await requestTypes.fetchIfNot();
			const model = requestTypes.get(ac.args.id);
			this.addModel(model);
		});
	}
});

export default Page;
