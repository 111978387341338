import { _ } from 'vendors';
import View from 'base/view';
export default View.extend({
	className: 'c-select-segment',
	template: _.template('<%=name%>'),
	cssClassModifiers: [(m) => !!m.get('current') && 'current'],
	triggers: {
		click: 'click'
	},
	modelEvents: {
		change: 'render'
	}
});
