// import ControlMixin from 'components/controls/mixin';
import { _ } from 'vendors';
import ModelProperties from 'components/ui-model-card/properties';
import h from 'components/ui-model-card/helpers';
import { TransportPointModel } from '../../models/requestTransport-model';
// import CollectionView from 'base/collection-view';
import BaseView from 'base/view';

const MyTextView = BaseView.extend({
	className: 'properties-collection-controls',
	template: _.template('<button class="<%= type %>"><i></i></button>'),
	templateContext () {
		return {
			type: this.getOption('type')
		};
	},
	events: {
		click (e) {
			this.triggerMethod('clicked', e);
		}
	}
});

const defs = { editButton: true, addClass: 'whole-btn', textAction: 'edit' };

const EditTransportPointView = ModelProperties.extend({
	initialize () {
		if (!this.model) {
			this.model = new TransportPointModel();
		}
		this._inCollectionViewBehavior();
	},
	isInsideCollection () {
		return this.getOption('inCollectionView');
	},
	_inCollectionViewBehavior () {
		// if (!this.isInsideCollection()) {
		// 	return;
		// }
		// this.on('before:render', () => {
		// 	this.isFisrtInCollection = this.triggerMethod('is:first:in:collection', this);
		// });
	},
	className: 'props-place card lined with-border',
	removeThisItem () {
		this.model.isNew = () => true;
		this.model.destroy();
	},
	properties: (m, v) => ([
		{
			name: 'controlheader',
			viewClass: MyTextView,
			type: 'remove',
			onClicked () {
				v.removeThisItem();
			},
			addClass: 'properties-collection-controls',
			condition () {
				const inColl = this.isInsideCollection();
				const notFirst = inColl && !this.triggerMethod('is:first:in:collection', this);
				return inColl && notFirst;
			}
		},
		h.ui('contragent', { ...defs, half: true, destroyOnChange: false }),
		h.ui('contact', { ...defs, half: true, destroyOnChange: false }),
		h.ui('address', { ...defs, destroyOnChange: false }),
		h.ui('takeCargo', { ...defs, half: true, destroyOnChange: false }),
		h.ui('dropCargo', { ...defs, half: true, destroyOnChange: false }),
		h.ui('workHours', { ...defs, half: true, destroyOnChange: false }),
		h.ui('notifyFirst', { ...defs, half: true, destroyOnChange: false }),
		h.ui('comment', { ...defs, destroyOnChange: false })
	]),
	modelEvents: {
		'change:contragent' () {
			const { contact, address } = {};
			this.model.set({ contact, address });
		}
	}
});
// BaseView.mixWith(ControlMixin).extend({
// 	onRender() {

// 	}
// });

export default EditTransportPointView;
