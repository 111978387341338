import CollectionView from 'base/collection-view';
import View from 'base/view';
import Collection from 'base/collection';
import { _ } from 'vendors';

const PredefinedCollection = Collection.extend({
	parse (data = []) {
		const models = _.map(data, item => {
			if (!_.isObject(item)) {
				return { key: item, value: item };
			} else {
				return item;
			}
		});
		return models;
	}
});

const ItemView = View.extend({
	className: 'predefined-value',
	template: _.template('<%= key %>'),
	triggers: {
		click: 'click'
	}
});


export default CollectionView.extend({
	childView: ItemView,
	initialize () {
		const values = this.getOption('values');
		this.collection = new PredefinedCollection(values, { parse: true });
	},
	childViewTriggers: {
		click: 'predefined:click'
	}
});
