import EditableProperties from 'mixins/editable-properties';
import properties from './properties';
import Base from 'base/model';

const ModelProperties = {
	properties: {
		...properties
	}
};

export const MeasuredValueModel = Base.mixWith(EditableProperties, ModelProperties).extend({
	enablePropertyChangeEvents: true,
	onChangeInputMeasureId (model, value) {
		let baseUnitsInInputUnit;
		if (value === this.get('baseMeasureId')) {
			baseUnitsInInputUnit = 1;
		} else if (value && value === this.get('storeMeasureId')) {
			baseUnitsInInputUnit = this.get('baseUnitsInStoreUnit');
		}
		this.set('baseUnitsInInputUnit', baseUnitsInInputUnit);
	}
});

