// import { _, $ } from 'vendors';
// import CollectionView from 'base/collection-view';
// import UiListItem from 'components/ui-list/item';
// import { BlockHeaderWithRightButtons, BlockFooterTextAction } from 'components/ui2';
// import Collection from 'base/collection';
// import editValue from 'bus/edit';
// import modalError from 'helpers/modals/error';
// import View from 'base/view';
// import { Model } from '../../../../../base/models';
// import busModels from 'bus/models';
// import busData from 'bus/data';
// import Object from 'base/object';
// import ControlMixin from 'components/controls/mixin';
// import PreventWheelMixin from 'mixins/view/prevent-wheel';
// import smartOpen from 'helpers/smart-open';
// import { editMeasuredValueInModal } from 'components/controls/measured-value';
// import { AcceptReadyProductInModal } from './accept-ready-product';
// // import { componentComparator } from '../../../../../modules/resources/production/products/models/prod-cycle/prodCycleProd-model';
// import { componentComparator } from 'mod/productionProducts/models/prod-cycle/prodCycleProd-model';

import { ContragentView } from './contragent-block/ContragentView';
export function productionContragentBlock (view) {
	view.createNested('productioncontragentblock', {
		viewClass: ContragentView,
		viewOptions: {
			model: view.model
			// //header: 'действия',
			// refreshOnModelEvents: ['change:actions'],
			// actions: ['upload:files','show:tasksBoard','add:to:board','remove:from:board','complete:process', 'cancel:process', 'restart:process'],
			// emptyViewText: '...', //(m) => m.isClosed() ? 'задача завершена' : 'нет доступных действий'
		},
		regionTemplate: { replaceElement: true }
	});
}

export function productionContragentContent(view) {
	const opts = {
		class: ContragentView,
		model: view.model
	}
	return opts;
}

// import { productionApiUrl } from 'pages/product-production/models';

// bus.amount = 0;

// const WriteoffSourceView = View.extend({
// 	// UiListItem.extend({
// 	className: 'production-component-details',
// 	template: `
// 		<div class="name">
// 			<span><%= name %></span>
// 		</div>
// 		<div class="info">
// 			<span><span><%= selectBatch %></span></span>
// 			<span>
// 				<i>будет списано</i>
// 				<span>
// 					<span><span class="writeoff-amount"></span><small><%= produceUnit %></small></span>
// 					<span>(<span class="writeoff-amount-store"></span><small><%= storeUnit %></small>)</span>
// 				</span>
// 			</span>
// 			<span>
// 				<i>есть у контрагента</i>
// 				<span><span class="available-amount"><%= _.displayNum(amount, 4) %></span><small><%= storeUnit %></small></span>
// 			</span>
// 		</div>
// 	`,
// 	templateContext () {
// 		const selectBatch = this.buildSelectBatch(true, true);
// 		return {
// 			selectBatch,
// 			produceUnit: busData.measures(this.raw.produceUnitMeasureId, 'short'),
// 			storeUnit: busData.measures(this.raw.storeUnitMeasureId, 'short')
// 		};
// 	},
// 	initialize () {
// 		this.batchesById = (this.model.get('batches') || []).reduce((memo, batch) => {
// 			memo[batch.id] = batch;
// 			return memo;
// 		}, {});
// 		this.raw = this.model.attributes;
// 		this.listenTo(bus, 'change', this.onAmountChange);
// 		this.product = this.getOption('process').get('product');
// 	},
// 	onAmountChange () {
// 		if (!this.isRendered()) return;
// 		this.updateWriteoffAmount();
// 		this.updateAvailableAmount();
// 		const prevValue = this._validated;
// 		if (this.validate()) {
// 			if (!prevValue) {
// 				this.makeValid();
// 			}
// 			this.$el.removeClass('invalid');
// 		} else {
// 			if (prevValue) {
// 				this.makeInvalid();
// 			}
// 			this.$el.addClass('invalid');
// 		}
// 	},
// 	updateAvailableAmount () {
// 		const batch = this.getCurrentBatch();
// 		if (!batch) {
// 			this.ui.availableAmount.html('&ndash;');
// 			return;
// 		}
// 		this.ui.availableAmount.html(batch.amount);
// 	},
// 	updateWriteoffAmount () {
// 		const batch = this.getCurrentBatch();
// 		const $el = this.ui.writeoffAmount;
// 		const $el_s = this.ui.writeoffAmountStore;
// 		if (!batch) {
// 			$el.html('&ndash;');
// 			$el_s.html('&ndash;');
// 			return;
// 		}
// 		// busData.measures(inf.unitMeasureId, 'short')
// 		const baseUnits = this.raw.amount / this.raw.produceUnitsInBaseUnit;
// 		const totalBaseUnits = baseUnits * bus.value.amount;
// 		const storeUnits = totalBaseUnits / this.raw.baseUnitsInStoreUnit;
// 		this.storeUnits = storeUnits;
// 		// console.log('::', this.raw.baseUnitsInStoreUnit, storeUnits, totalBaseUnits);
// 		$el.html(totalBaseUnits);
// 		$el_s.html(_.displayNum(storeUnits, 4));
// 	},
// 	buildSelectBatch (addEmpty, addSelect) {
// 		const m = this.model;
// 		const options = (m.get('batches') || []).map(m => `<option value="${m.id}">${m.idString}</option>`).join('');
// 		let result = options;
// 		if (addEmpty) {
// 			result = '<option></option>' + result;
// 		}
// 		if (addSelect) {
// 			result = '<select class="form-control">' + result + '</select>';
// 		}
// 		return result;
// 	},
// 	onRender () {
// 		this.setBatchId();
// 	},
// 	ui: {
// 		select: 'select',
// 		writeoffAmount: '.writeoff-amount',
// 		availableAmount: '.available-amount',
// 		writeoffAmountStore: '.writeoff-amount-store'
// 	},
// 	events: {
// 		'change select' () {
// 			this.setBatchId(this.ui.select.val());
// 		}
// 	},
// 	makeValid () {
// 		this.setBatchId(this.selectedBatchId);
// 	},
// 	makeInvalid () {
// 		this.setBatchId(this.selectedBatchId, true);
// 	},
// 	setBatchId (batchId, invalid) {
// 		this.selectedBatchId = batchId;
// 		bus.setProductBatch(this.model.id, invalid ? null : batchId);
// 		bus.trigger('change');
// 		// this.triggerChange();
// 		// this.updateAvailableAmount();
// 		// this.updateWriteoffAmount();
// 	},
// 	validate () {
// 		const batch = this.getCurrentBatch();
// 		if (!batch) {
// 			return false;
// 		}
// 		const valid = this._validated = this.storeUnits > 0 && this.storeUnits <= batch.amount;
// 		return valid;
// 	},
// 	getCurrentBatch () {
// 		return this.batchesById[this.selectedBatchId];
// 	},


// 	text: m => m.get('name'),
// 	rightItems () {
// 		const m = this.model;
// 		const batches = (m.get('batches') || []).map(m => `<option value="${m.id}">${m.idString}</option>`).join('');
// 		return [
// 			{
// 				template: `
// 				<div class="batch-id">
// 					<span>
// 						<select>
// 							<option></option>
// 							${batches}
// 						</select>
// 					</span>
// 				</div>`
// 			},
// 			{
// 				template: `
// 					<div class="amount">
// 						<span></span>
// 					</div>
// 				`
// 			}
// 		];
// 	}


// });

// const WriteoffSourcesView = CollectionView.extend({
// 	className: 'batches-list',
// 	initialize () {
// 		this.initializeCollection();
// 		// this.refreshCollectionData(this.process.get('productionCycleComponents') || []);
// 	},
// 	initializeCollection () {
// 		this.collection = new Collection();
// 		this.collection.url = this.model.url() + '/production-components/contragent-batches';
// 		this.collection.fetch().then(() => bus.trigger('data:fetched'));
// 	},
// 	refreshCollectionData (data) {
// 		this.collection.reset(data);
// 	},
// 	childView: WriteoffSourceView,
// 	childViewOptions () {
// 		return {
// 			process: this.model
// 		};
// 	}
// });

// DEPRECATED
// const AcceptProductionLayout = ControlMixin(View).extend({
// 	initialize () {
// 		this.process = this.getOption('process');
// 		// bus.clear();
// 		this.listenTo(bus, 'change', this.onBusChange);
// 		this.value = bus.value = { amount: 0, productBatches: {} };
// 		bus.amountValid = false;
// 		bus.productBatchesValid = false;
// 		// this.listenTo(bus, 'data:fetched', this.onBusChange);
// 		// this.editPartName = _.debounce(this._editPartName, 300);
// 		// this.delegate('input', 'input.produce-part', this.editPartName.bind(this));
// 	},
// 	className: 'accept-production-dialogue',
// 	template: `
// 		<div class="amount-box">
// 			<h4>Укажите количество готовой продукции и номер партии</h4>
// 			<div>
// 				<input class="produce-amount" type="number" placeholder="количество">
// 				<!-- <input class="produce-part" type="text" placeholder="номер партии"> -->
// 			</div>
// 		</div>
// 		<div class="resources-box">
// 			<h4>Списание сырья</h4>
// 			<!-- <div><label><input type="checkbox" name="shouldWriteoff" checked="checked"> списывать сырье</label></div> -->
// 			<div class="sources-region"></div>
// 		</div>
// 	`,
// 	regions: {
// 		sources: '.sources-region'
// 	},
// 	ui: {
// 		inputAmount: 'input.produce-amount'
// 		// inputPart: 'input.produce-part',
// 	},
// 	events: {
// 		'input input.produce-amount' (e) {
// 			const num = $(e.target).val();
// 			bus.value.amount = parseFloat(num, 10);
// 			if (isNaN(bus.value.amount)) {
// 				bus.value.amount = 0;
// 			}
// 			bus.amountValid = bus.value.amount > 0;
// 			bus.trigger('change', bus.value.amount);
// 		}
// 	},
// 	onBusChange () {
// 		this.validateInputs();
// 		this.triggerChange();
// 	},
// 	onRender () {
// 		this.validateInputs();
// 		const view = new WriteoffSourcesView({ model: this.process });
// 		this.showChildView('sources', view);
// 	},
// 	validateInputs () {
// 		if (!this.ui.inputAmount.val()) {
// 			this.ui.inputAmount.addClass('invalid');
// 		} else {
// 			this.ui.inputAmount.removeClass('invalid');
// 		}
// 	},
// 	validate () {
// 		return bus.amountValid && bus.productBatchesValid;
// 	}
// 	// _editPartName() {
// 	// 	bus.batchIdString = this.ui.inputPart.val();

// 	// 	console.log('valildate', this.ui.inputPart.val());
// 	// }
// });
