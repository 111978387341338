import BasePage from 'pages/auth';
// import Create from './create';
import Layout from './layout';
import Model from 'mod/departments/models/model';
import deps from 'mod/departments/singleton';
import PageErr from 'pages/error';

const Page = BasePage.extend({
	moduleRights: { departments: 'view' },
	id: 'staff/deps/#id',
	name: 'department card page',
	label () { return 'Отдел: ' + ((this.model && this.model.display('name')) || ''); },
	route: ':departmentId',
	skipMenu: true,
	Layout,
	wrapLayout: true,
	socketGroups: (m) => 'department:' + m.id,
	preventStart: function (ac) {
		const prev = BasePage.prototype.preventStart.call(this, ac);
		if (prev) return prev;

		if (!ac) { return; }
		const dep = deps.get(ac.args.departmentId);
		if (!dep) { return PageErr.NotFound('Отдел не найден', this.url()); }
	},
	onBeforeStart (rd) {
		const model = new Model({ id: rd.args.departmentId });
		this.addModel(model);
	}
});

export default Page;
