import { isString, isView, isObject, isViewClass, isFunction } from "utils/is";
import { toCamelCase } from 'utils/transform';
import { invokeValue } from "utils/invoke";
import { BaseView } from "./base-view";

export const BaseHamburgerView = BaseView.extend({

	constructor: function () {
		this._children = new Set();
		BaseView.apply(this, arguments);
		//this.on('all', e => console.warn('[hv]:', e));
		this.on('destroy', this._removeChildrenViews);
	},

	template: false,

	render() {
    if (this._isDestroyed) {
      return this;
    }

    this.triggerMethod('before:render', this);

    // If this is not the first render call, then we need to
    // re-initialize the `el` for each region
    // if (this._isRendered) {
    //   this._reInitRegions();
    // }
    this._renderTemplate();
    this.bindUIElements();

		this._renderChildren();

    this._isRendered = true;
    this.triggerMethod('render', this);

    return this;
  },

	_initRegions() { },

	_removeChildrenViews() {
		this._children.forEach(child => {
			this.stopListening(child.view);

			if (child.replacedEl) {
				child.view.el.replaceWith(child.replacedEl);
				// this.el.replaceChild(child.replacedEl, child.view.el);
			}
			child.view.destroy();
		});
		this._children.clear();
	},

	_renderChildren() {

		this._removeChildrenViews();

		this.triggerMethod('before:children:render', this);

		const protoViews = this.getChildrenViews() || [];
		const childrenCount = Array.isArray(protoViews) ? protoViews.length : Object.keys(protoViews).length;

		if (childrenCount) {

			const defChildViewOptions = this._getChildViewOptions();
	
			const renderOptions = Object.assign({
				childView: this.getChildView(),
				childViewOptions: defChildViewOptions,
				childViewContainer: this._getChildViewContainer(),
				prepend: this.getOption('childViewContainerPrepend', true)
			}, this.getOption('renderChildrenOptions', true));
	
			for (let index in protoViews) {

				let viewName = typeof index === 'string' ? index : undefined;
				renderOptions.viewName = viewName;
				renderOptions.index = index;

				const protoView = protoViews[index];
				this._renderChild(protoView, renderOptions);
			}

		}

		this.triggerMethod('children:render', this);

	},

	getChildView() {
		return this.getOption('childView', true);
	},

	getChildrenViews() {
		let protoViews = this.getOption('childrenViews', true);
		return protoViews;
	},


	_getChildViewOptions() {
		const options = this.getOption('childViewOptions', true);
		
		let modOptions;
		let colOptions;

		if (this.model) {
			modOptions = { model: this.model };
		}
		if (this.collection) {
			colOptions = { collection: this.collection };
		}

		return Object.assign({}, modOptions, colOptions, options);

	},

	_getChildViewContainer() {
		const elSelector = this.getOption('childViewContainer', true);
		let el;
		if (isString(elSelector)) {
			el = this.$(elSelector).get(0);
		}
		return el || this.el;
	},

	_renderChild(protoView, renderOptions)	 {
		const view = this._buildChildView(protoView, renderOptions);
		if (view == null) { return; }
		this._proxyChildViewEvents(view);
		this._setupChildView(view, renderOptions);
		this._attachChildView(view, renderOptions);
		return view;
	},

	_buildChildView(protoView, renderOptions) {

		if (isView(protoView)) {
			if (protoView.isDestroyed()) {
				console.warn('build child view failed, provided argument is destroyed view', protoView);
			}
			return protoView;
		}

		let Ctor;

		if (isViewClass(protoView)) {

			Ctor = protoView;
			protoView = undefined;

		} else {

			protoView = invokeValue(protoView, this, this);
			if (isString(protoView) && isFunction(this.buildStringChildView)) {
				protoView = this.buildStringChildView(protoView, renderOptions)
			}

			Ctor = protoView?.class || renderOptions.childView;

		}

		if (!Ctor) {
			console.warn('unable to build childview, class not defined', protoView);
			return;
		}

		const options = this._normalizeBuildChildOptions(protoView, renderOptions);

		const view = this.buildChildView(Ctor, options);

		return view;
	},

	buildChildView(View, options) {
		return new View(options);
	},

	_normalizeBuildChildOptions(buildOptions, renderOptions) {
		const viewName = renderOptions.viewName;
		const defChildViewOptions = renderOptions.childViewOptions;

		if (buildOptions || defChildViewOptions) {
			const viewNameExt = viewName ? { viewName } : {};
			buildOptions = Object.assign({}, defChildViewOptions, buildOptions, viewNameExt);
			delete buildOptions.class;
			return buildOptions;
		}

	},

	_setupChildView(view, renderOptions) {

		const viewName = view.getOption('viewName', true);
		const shouldTriggerChildSetup = this.getOption('shouldTriggerChildSetup', true);

		// named view setup
		if (viewName) {
			const setupMethodName = toCamelCase('setup', viewName);
			if (isFunction(this[setupMethodName])) {
				this[setupMethodName](view, renderOptions);
			} 
			if (shouldTriggerChildSetup) {
				this.triggerMethod('setup:' + viewName, view, renderOptions, this);
			}
		}

		// all views setup
		this.setupChildView(view);

		// end setup hook
		view.triggerMethod('setup', view, this);


	},

	setupChildView() { },

	_attachChildView(view, renderOptions) {

		if (!view.isRendered()) {
			view.render();
		}

		let replacedEl;
		const viewName = view.getOption('viewName', true);
		if (viewName) {
			replacedEl = this.$(viewName).get(0);
		}

		if (replacedEl) {

			replacedEl.replaceWith(view.el);
			this.listenTo(view, 'before:destroy', () => {
				view.el.replaceWith(replacedEl);
			});

		} else {

			const { childViewContainer, prepend } = renderOptions;

			if (prepend) {
				childViewContainer.prepend(view.el);
			} else {
				childViewContainer.append(view.el);
			}

		}

		this._children.add({
			view,
			viewName,
			replacedEl,
			index: renderOptions.index
		});

	}

});