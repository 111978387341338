import { _ } from 'vendors';
import protos from './prototypes';
export default function get (key, opts = {}) {
	let proto = protos[key];
	let add = {};
	const isFunc = _.isFunction(proto);
	if (isFunc) {
		proto = proto(opts);
	} else {
		add = _.extend(add, opts);
	}
	// _.isFunction(proto) && (proto = proto(opts));
	return _.extend({}, add, proto);
}
