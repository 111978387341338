import List from 'mod/productionTypes/views/items-list';

export default List.extend({
	tagName: 'ul',
	emptyView () {
		return this.getOption('childView').Empty;
	},
	viewFilter (v) {
		return v.model && v.model.get('parentId') === this.model.id;
	}
});
