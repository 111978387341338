import { _ } from 'vendors';

import Yat from 'marionette.yat';

import DynamicClass from 'mixins/view/dynamic-class';
import FixedComparatorMixin from 'mixins/collection-view/fixed-comparator';
import CustomViewsMixin from 'mixins/collection-view/custom-views';
import NextAddChildMixin from 'mixins/collection-view/next-add-child';

import mix from 'helpers/mix';
const GetOptionProperty = Yat.Mixins.GetOptionProperty;


export const BaseCollectionView = mix(Yat.CollectionView).with(NextAddChildMixin);
BaseCollectionView.mixWith = function () {
	return mix(this).with(...arguments);
};


const BaseView = mix(BaseCollectionView).with(GetOptionProperty, FixedComparatorMixin, CustomViewsMixin, DynamicClass);
// const CleanBaseView = mix(Mn.NextCollectionView).with(GetOptionProperty, FixedComparatorMixin, CustomViewsMixin, DynamicClass);
const View = BaseView.extend({
	constructor (opts = {}) {
		const emptyViewOptions = opts.emptyViewOptions;
		delete opts.emptyViewOptions;

		BaseView.apply(this, arguments);

		this.options.emptyViewOptions = emptyViewOptions;

		if (!this.collection) return;

		if (this.collection.isFetching()) { this.dataState = 'fetching'; } else if (this.collection.isFetched()) { this.dataState = 'fetched'; }
	},
	isCustomView (v) {
		return !this.isModelView(v);
	},
	isModelView (v) {
		return this.collection && v.model && this.collection.has(v.model);
	},
	// accepts: (model, index)
	emptyViewOptions () {
		const _options = this.getOption('emptyViewOptions', { deep: false });
		const options = _.extend({ searchApplied: this.searchApplied, dataState: this.getDataState(), collection: this.collection, collectionView: this }, _options);
		return options;
	},
	getDataState () {
		if (!this.collection) {
			return;
		}
		if (this.collection.isFetching()) {
			return 'fetching';
		} else if (this.collection.isFetched() || this.collection.populated) {
			return 'fetched';
		}
	},
	isChildAttached (view) {
		return this._fixIsAttachedOnFirstRender ? true : view.isAttached();
	},
	getRenderedViews (opts = {}) {
		const children = this.children;
		if (opts.type == null || opts.type === 'collection') {
			return _(children._views).filter((view) => {
				return (this.isChildAttached(view) && !!view.model && view.isRendered() && !!children._indexByModel[view.model.cid]);
			});
		} else if (opts.type === 'custom') {
			return _(children._views).filter((view) => {
				return (this.isChildAttached(view) && view.isRendered() && (!view.model || !children._indexByModel[view.model.cid]));
			});
		} else if (opts.type === 'both') {
			return _(children._views).filter((view) => {
				return this.isChildAttached(view) && view.isRendered();
			});
		} else { return []; }
	},
	/* _renderChildren(views) {

		this.triggerMethod('before:render:children', this, views);

		if (this.isEmpty(!views.length)) {
			this._showEmptyView();
		} else {
			this._destroyEmptyView();
			var els = this._getBuffer(views);
			this._attachChildren(els, views);
		}
		this.triggerMethod('render:children', this, views);
	}, */
	getFilteredModelsCount () {
		if (!this.collection.length) return 0;
		const vFltr = this.getOption('viewFilter', { force: false }) || (() => true);
		const fltr = model => vFltr({ model });
		return this.collection.filter(fltr).length;
	},

	detachHtml (view) {
		const promise = this.getOption('childViewDetachAnimation') || view.getOption('detachAnimation');
		const afterDetach = promise ? () => view.triggerMethod('after:detach:animation') : null;
		if (promise) {
			const detach = () => {
				// this.Dom.detachEl(view.el, view.$el);
				BaseView.prototype.detachHtml.apply(this, arguments);
				afterDetach && afterDetach();
			};
			promise.then(detach, detach);
		} else {
			return BaseView.prototype.detachHtml.apply(this, arguments);
		}
	}
});



export default View;
