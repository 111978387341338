import CollectionView from 'base/collection-view';
import Collection from 'base/collection';
import { toNewPoints } from '../../models/utils';
import { TransportPointModel } from '../../models/requestTransport-model';

export const collectionMixin = {
	modelClass: TransportPointModel,
	initialize (options) {
		this.mergeOptions(options, ['details', 'points', 'modelClass']);
		this.initializePointsCollection();
	},
	initializePointsCollection () {
		if (this.collection) return;
		this.initializePoints();
		if (!this.points) return;
		this.collection = new Collection(this.points, { model: this.modelClass });
	},
	initializePoints () {
		if (!this.points) {
			this.points = toNewPoints(this.details);
		}
	}
};

export const BasePointsView = CollectionView.extend({
	...collectionMixin
});
