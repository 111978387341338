import BaseCollectionView from 'base/collection-view';
import UserInList from 'mod/users/views/list-item';


const AdminsList = BaseCollectionView.extend({
	tagName: 'ul',
	emptyView: UserInList.Empty,
	childView: UserInList.extend({
		tagName: 'li',
		cssClassMOdifiers: [
			() => 'one-line'
		],
		closeButton: true
	}),
	childViewEvents: {
		'close:click' (model) {
			model.executeAction('remove:admin');
		}
	}
});

export default AdminsList;
