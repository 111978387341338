import { _ } from 'vendors';
import Radio from './radio';

const bus = Radio.channel('models');
bus.getModel = function (name, ext) {
	let Model = bus.request(name);
	if (_.isObject(ext) && !!Model) { Model = Model.extend(ext); }
	return Model;
};
bus.setModel = function (name, Model) {
	return bus.reply(name, () => Model);
};

function label (proto, propertyName) {
	if (!proto.properties) return propertyName;
	const property = proto.properties[propertyName];
	if (!property) return propertyName;
	const display = property.display;
	if (!display) return propertyName;
	return display.label || propertyName;
}

bus.display = function (modelName, propertyName, value, options = {}) {
	const Model = bus.getModel(modelName);
	if (!Model) return value;
	options.value = value;
	options.skipGet = true;
	return Model.prototype.display(propertyName, options);
};

bus.label = function (modelName, propertyName) {
	const Model = bus.getModel(modelName);
	if (!Model) return propertyName;
	return label(Model.prototype, propertyName);
};

export default bus;
