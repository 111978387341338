import { _ } from 'vendors';
import Model from './model';
import mix from 'helpers/mix';
import Actionable from 'mixins/actionable';

import action from 'helpers/action';
import paths from 'helpers/paths';
import smartOpen from 'helpers/smart-open';

import store from 'helpers/store';
import token from 'app/token';
// import appBus from 'bus/app';

const Base = mix(Model).with(Actionable);

paths.set('api', 'identity', 'identity', { prefix: '' });
paths.api('identity');

const UserActions = {
	actions: [
		action('change:pass', 'изменить пароль'),
		action('log:out', 'выйти')
	],
	actionLogOut () {
		token.clear();
		this.clear();
		// appBus.navigate('');
	},
	actionChangePass (action, options) {
		return smartOpen(paths.url('acc:change:pass'), options);
	}
};


const UserIdentity = {
	isMe (id) {
		return id === this.getIdentityId();
	},
	isAnonym () {
		return !this.isAuthenticated();
	},
	// has employee id
	isEmployeed () {
		return this.isAuthenticated() && !!this.getIdentityId();
	},
	// has employees
	isEmployeer () {

	},
	isMyEmployee () {

	},
	isAuthenticated () {
		return this.get('authenticated') === true;
	},
	getIdentityId () {
		return this.get('employeeId');
	},
	getRights () {
		return this.get('modules') || {};
	},
	isAdmin () {
		return this.checkRights({ admin: 'view' });
	},
	isModuleAvailable (name) {
		return this.getRights()[name] != null;
	},
	checkRights (check, opts = {}) {
		if (_.isFunction(check)) {
			const { context } = opts;
			check = check.call(context, context);
			if (_.isBoolean(check)) {
				return check;
			} else if (!_.isObject(check)) {
				return false;
			}
		}
		const rights = this.getRights();
		const n = (s) => (typeof s === 'string') ? s.split(/\s*,\s*/gi) : [];
		return _.every(check, (rightsMask, moduleName) => {
			if (_.isBoolean(rightsMask)) {
				return !!rights[moduleName];
			} else if (typeof rightsMask === 'string') {
				return _.every(n(rightsMask),
					expectedValue => n(rights[moduleName]).indexOf(expectedValue) > -1
				);
			}
			return false;
		});
		// return _(check).every((mask, module) => _(n(mask)).every((maskValue) => n(rights[module]).indexOf(maskValue)>-1));
	},
	getMyEmployees () {

	}
};

const UserStore = {
	initStore () {
		this.on('change', this.store);
	},
	store () {
		store.set('user', this.toJSON(), { days: 20 });
	}
};
const BaseUser = mix(Base).with(UserActions, UserIdentity, UserStore);
export default BaseUser.extend({
	fetch () {
		return BaseUser.prototype.fetch.apply(this, arguments);
	},

	url: paths.api('identity'),

	// default is 'merge'
	fetchBehavior: 'replace',

	initialize () {
		this.initStore();
	},
	whenAuth () {
		const promise = new Promise((resolve) => {
			if (this.isAuthenticated()) {
				resolve();
			} else {
				const listener = () => {
					if (this.isAuthenticated()) {
						this.off('change:authenticated', listener);
						resolve();
					}
				};
				this.on('change:authenticated', listener);
			}
		});
		return promise;
	},
	init () {
		if (this.initPromise) return this.initPromise;

		if (!this.initPromises.length && !this.isAuthenticated()) { this.initPromise = Promise.resolve(); }

		this.initPromise = Promise.all(this.initPromises).then(
			() => this.refresh().then(
				() => {},
				() => { this.clear(); }
			),
			() => { this.clear(); }
		);

		return this.initPromise;
	},
	addInitPromise (promises) {
		this.initPromises || (this.initPromises = []);
		this.initPromises.push(promises);
	},
	setToken (token) {
		this.token = token;
		this.listenTo(token, 'changed', this._onTokenChange);
	},
	_onTokenChange () {
		if (this.token.hasToken()) { this.refresh(); } else { this.clear(); }
		// this.token.ensureToken().then(() => this.refresh(),() => this.clear());
	},
	refresh () {
		if (this.refreshing) return this.refreshing;

		this.refreshing = this.fetch().then(
			(xhr) => { delete this.refreshing; return xhr; },
			(xhr) => { delete this.refreshing; return xhr; }
		);

		return this.refreshing;
	}
});
