import { _ } from 'vendors';
import View from 'base/view';
export default View.extend({
	className: 'fdc-container',
	template: _.template('<div></div>'),
	regions: {
		content: { el: 'div', replaceElement: true }
	},
	onRender () {
		let content = this.getOption('content');
		if (!_.isView(content)) {
			const options = this.getOption('contentOptions');
			content = new content(options); // eslint-disable-line
		}
		this.showChildView('content', content);
	}
});
