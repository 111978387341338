import { BackboneModel } from 'vendors';
import { FilterValuesCollection } from './FilterValuesCollection';

export const FilterModel = BackboneModel.extend({
	constructor: function (data) {
		this.values = new FilterValuesCollection();
		BackboneModel.call(this, null);
		this.setFilters(data);
	},
	getRequestData () {
		return this.values.getRequestData();
	},
	setFilters (data) {
		if (typeof data === 'function') {
			data = data();
		}
		this.values.setFilters(data);
	},
	setValues (data) {
		if (typeof data === 'function') {
			data = data();
		}
		this.values.setValues(data);
	},
	getValueModel (key) {
		return this.values.get(key);
	},
	hasValue (key, ...valueKeys) {
		const model = this.getValueModel(key);
		return model && model.hasValue(...valueKeys);
	}
});
