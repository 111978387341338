import user from 'app/user';
import { ConcretesLayout } from './layout';
export default 	{
	id: 'concretes',
	label: 'Продукция',
	filter: (p) => p.model.get('isAbstractGroup') && user.checkRights({ resourcesProduction: 'view' }),
	Layout: ConcretesLayout
	// children: [
	// 	{
	// 		id: 'logs',
	// 		label: 'История',
	// 		filter: () => user.checkRights({ productsStore: 'view' }),
	// 		Layout: StoreLayout
	// 	}
	// ]
};
