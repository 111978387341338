import { productFilters, takeFilters } from '../common';

const exclude = ['byemployee', 'byproduct'];
const _filters = takeFilters(productFilters, exclude);
// console.log('>>>>', _filters);

export const filters = [

	..._filters

];
