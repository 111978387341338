import { HamburgerView } from 'base/view-hamburger';
//import { ComponentsListBlockView } from './ComponentsListBlockView';

import { MainPropertiesBlockView } from './MainPropertiesBlockView';
import { BuildsListView } from './BuildsListView';
import { PurchasesListView } from './PurchasesListView';
import { UsedInBuildsListView } from './UsedInBuildsListView';

function notEmpty(arg) {
	return arg && Array.isArray(arg) && arg.length > 0;
}

export const Layout = HamburgerView.extend({
	baseClassName: 'ui2-blocks',
	customViewOptions () {
		return {
			model: this.model
		};
	},
	getBuildsView() {
		if (notEmpty(this.model.get('builds'))) {
			return BuildsListView;
		}
	},
	getPurchasesView() {
		if (notEmpty(this.model.get('purchases'))) {
			return PurchasesListView;
		}

	},
	getUsedInBuildsView() {
		if (notEmpty(this.model.get('usedInBuilds'))) {
			return UsedInBuildsListView;
		}
	},
	customViews: v => [
		MainPropertiesBlockView,
		v.getBuildsView(),
		v.getPurchasesView(),
		v.getUsedInBuildsView(),
		//ComponentsListBlockView
	]
});
