import { _ } from 'vendors';
import ModelProperties from 'components/ui-model-card/properties';
import h from 'components/ui-model-card/helpers';
import { JoinedProductModel } from '../models/joined-products';
import ControlMixin from 'components/controls/mixin';

import CollectionView from 'base/collection-view';
import UiItem from 'components/ui-list/item';
import busData from 'bus/data';
import busModels from 'bus/models';

function initModel (model) {
	if (model) return;
	this.model = new JoinedProductModel();
}

const addDefs = { editButton: true, addClass: 'whole-btn', textAction: 'edit' };
const BaseChange = ControlMixin(ModelProperties);

export const AddJoined = BaseChange.extend({
	constructor (opts) {
		initModel.call(this, this.model || opts.model, this.parent || opts.parent);
		BaseChange.apply(this, arguments);
		this.on('render', () => this.trigger('content:ready'));
	},
	className: 'card lined with-border create-product-type',
	properties: [
		h.ui('childId', _.clone(addDefs)),
		h.ui('production.amount', _.clone(addDefs)),
		h.ui('production.defectPercent', _.clone(addDefs)),
		h.ui('production.note', _.clone(addDefs))
	],
	getValue () {
		return _.extend({}, this.model.toJSON(), { joinedAs: 'production' });
	},
	modelEvents: {
		'change' () {
			this.triggerChange();
		}
	},
	validate (value) {
		return (value.childId && value.production && value.production.amount);
	}
});


export const EditJoined = BaseChange.extend({
	constructor (opts) {
		// initModel.call(this, this.model || opts.model, this.parent || opts.parent);
		this.initValue(opts);
		BaseChange.apply(this, arguments);
		this.on('render', () => this.trigger('content:ready'));
	},
	initValue (opts = {}) {
		let model = this.value || opts.value;
		if (!_.isModel(model)) { model = new JoinedProductModel(model, { parent: opts.parent }); }

		this.model = model;

		// this.value = _.omit(model.toJSON(),'id','values');
		// let values = model.getContactValues();
		// if(this.model.isNew())
		// 	this.listenTo(values, 'add remove change update',() => this.triggerChange(this.getValue()));
	},
	className: 'card lined with-border create-product-type',
	properties: [
		h.ui('production.amount', _.clone(addDefs)),
		h.ui('production.defectPercent', _.clone(addDefs)),
		h.ui('production.note', _.clone(addDefs))
	],
	getValue () {
		return _.extend({}, this.model.toJSON(), { joinedAs: 'production' });
	},
	modelEvents: {
		'change' () {
			this.triggerChange();
		}
	}
	// validate(value){
	// 	return (value.childId && value.production && value.production.amount);
	// }
});
busModels.reply('products:joinedProductEditView', () => EditJoined);



const JoinedProductView = UiItem.extend({
	text: m => m.getLabel(),
	editButton: () => busData.user().checkRights({ resourcesProduction: 'productsEdit' }),
	leftButtons: [
		{
			icon: true,
			class: 'info'
		}
	],
	rightItems: [
		{
			template: _.template('<span><span><%= production.amount %></span><small><%= measure %></small></span>'),
			templateContext () {
				// let measure = this.model.getMeasureLabel() : busData.measures(this.model.get('parent.'), 'short');
				return {
					measure: this.model.getMeasureLabel(this.getOption('unitMeasureId'))
				};
			}
		}
	],
	onEditClick () {
		this.model.executeAction('edit:produced:from');
	},
	onInfoClick (event) {
		this.model.openEntity(event);
	}
});

const JoinedProductsView = CollectionView.extend({
	childView: JoinedProductView,
	emptyView: UiItem.Empty
});

export {
	JoinedProductView,
	JoinedProductsView
};
