import { _ } from 'vendors';
import Model from 'base/model';
import Collection from 'base/collection';
import busData from 'bus/data';
import mix from 'helpers/mix';
// import action from 'helpers/action';
import Actionable from 'mixins/actionable';
import user from 'app/user';
// import editValue from 'bus/edit';
// import enums from 'helpers/enums';

export const ObserverModel = mix(Model).with(Actionable).extend({

	url () {
		const parent = (this.collection && this.collection.parent) || undefined;
		if (!parent || !parent.url) return;

		let url = _.result(parent, 'url');
		url += '/employees/' + this.get('employeeId');
		return url;
	},

	properties: {
		name: {
			display: {
				transform () {
					const emp = this.getEmployee();
					return emp && emp.display('name');
				}
			}
		}
	},
	getEmployee () {
		return busData.employees(this.get('employeeId'));
	},
	roleOrder () {
		return 0;
	},
	isVirtual () {
		return this.getEmployee().isVirtual();
	},
	isMe () {
		return user.isMe(this.get('employeeId'));
	},
	isNotMe () {
		return !this.isMe();
	},

	actions: [
		// action('change:joinedAs', 'изменение роли', null,
		// 	{
		// 		rule() {
		// 			if (!this.collection || !this.collection.parent) {
		// 				return false;
		// 			}
		// 			let proc = this.collection.parent;
		// 			return proc.isEditable('major');
		// 		}
		// 	}
		// )
	]

	// actionChangeJoinedAs()
	// {
	// 	let member = this;
	// 	let proc = member.collection && member.collection.parent || undefined;

	// 	editValue.do({
	// 		header: 'изменение роли',
	// 		valueType: 'enum',
	// 		modelType: 'single',
	// 		sourceValues: enums.store.processEmployeeRoles,
	// 		controlOptions: {
	// 			value: this.get('joinedAs'),
	// 		}
	// 	}).then(
	// 		values => {
	// 			member.save(null, { wait: true, method: 'PATCH', attrs: { joinedAs: values } });
	// 		},
	// 		() => {}
	// 	);
	// },

});

export const ObserversCollection = Collection.extend({
	model: ObserverModel,
	parse (data) {
		return _.map(data, employeeId => ({ id: employeeId, employeeId }));
	}
});
