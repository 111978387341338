import View from 'components/ui-list/item';
import beh from 'behaviors';
import popoverInfoView from './person-popover-info';

export default View.extend({
	behaviors: [
		beh.InfoPopover.extend({
			triggerElement: '> div',
			viewport: 'viewport'
		})
	],

	tagName: 'li',
	addClass: 'person-in-list whole-btn',
	topText: (m) => m.display('funcs'),
	bottomText: (m) => m.display('note'),
	text: (m) => m.display('name'),
	smallText () {
		const contactValue = this.model.getFirstContactValue();
		if (!contactValue) return 'без контактов';
		return contactValue.display('value');
	},
	popoverInfoView,
	popoverInfoViewOptions () {
		return {
			model: this.model,
			collection: this.model.getContactValues()
		};
	},
	editButton () { return this.model.canBeEdited(); },
	onEditClick () {
		this.model.executeAction('edit');
	}

});
