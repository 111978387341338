import { _ } from 'vendors';
import modals from './base-modals';

modals.addTypeConfig('error', {
	css: {
		wrapper: 'yat-modal-wrapper error'
	},
	show: {
		bg: true,
		close: true
	},
	options: {
		closeOnClickOutside: true,
		closeOnPromise: true,
		preventClose: false,
		asPromise: false
	}

});

function getErrorMessage (err) {
	const json = err && err.responseJSON;
	if (_.isObject(json)) {
		const message = json.message || json.error_description || json.error;
		if (message) {
			return message;
		}
	}


	if (err && err.message) { return err.message; }

	if (_.isArray(err)) { return err.join('<br/>'); }
}

export default function modalError (text, xhr) {
	// console.log('exploring xhr', xhr.jquery, xhr);
	const message = getErrorMessage(xhr);
	if (message) { text += '<br/>' + message; }

	return modals.show({
		text,
		type: 'error'
	});
}
