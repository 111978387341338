import { _ } from 'vendors';
import BaseView from 'pages/view';
import ModelProperties from 'components/ui-model-card/properties';
import h from 'components/ui-model-card/helpers';

// import PersonsList from 'components/contacts/views/persons-list';

// import { ActivityListUiBlock } from 'mods/logs/views';

// import nestedDetails from 'components/bank-details/entity-nested-details';

import { FileService, selectFiles } from 'components/files';
import ModelActions from 'components/ui-model-card/actions';


import ContragentPagesBlockView from './pagesBlock';

const template = _.template('');



export default BaseView.extend({
	className: 'contragent-page',
	template,
	modelEvents: {
		'open:selectFilesToUpload' () {
			selectFiles(blobs => {
				this.fileSvc.addBlobs(blobs);
			});
		}
	},
	initializeFileSvc () {
		this.fileSvc = new FileService({
			shouldUseNotifier: true,
			shouldUseUploadOnNewFiles: true,
			uploadOptions: {
				url: _.result(this.model, 'url') + '/upload'
			}
		});
		this.on('destroy', () => this.fileSvc.destroy());
		this.on('render', () => this.fileSvc.start());
	},
	initializeNesteds () {
	},
	initializeGeneralNesteds () {
		const m = this.model;

		this.createNested('main', {
			viewClass: ModelProperties.extend({ className: 'card lined with-border' }),
			viewOptions: {
				header: { text: 'основное', addClass: 'bg-main-dark' },
				properties: [
					h.ui('name', { editButton: m.mainInfoCanBeChanged() }),
					h.ui('roles', { editButton: m.mainInfoCanBeChanged() }),
					h.ui('occupation', { editButton: m.mainInfoCanBeChanged() }),
					h.ui('note', { editButton: m.mainInfoCanBeChanged() })
					// h.ui('id', {editButton: false } ),
				]
			},
			regionTemplate: { replaceElement: true }
		});

		this.createNested('pages', {
			viewClass: ContragentPagesBlockView,
			viewOptions: {
				currentSubpage: this.getOption('currentSubpage')
			},
			regionTemplate: { replaceElement: true }
		});

		this.createNested('third', {
			viewClass: ModelActions.extend({ className: 'card lined with-border' }),
			viewOptions: {
				refreshOnModelEvents: ['change:actions'],
				actions: ['upload:files'],
				emptyViewText: '...'
			},
			regionTemplate: { replaceElement: true }
		});
	},
	initialize () {
		// let contragent = this.model;

		this.initializeFileSvc();

		this.initializeGeneralNesteds();

		this.initializeNesteds();
	},
	onRender () {
		this.showAllNested();
	}
});
