import { _ } from 'vendors';
import BaseModel from 'base/model';
import ContactValueModel from './value-model';
import mix from 'helpers/mix';
import enums from 'helpers/enums';
import Actionable from 'mixins/actionable';
import editValue from 'bus/edit';
import action from 'helpers/action';
import error from 'helpers/modals/error';
import busModels from 'bus/models';
// import displayContactValue from './display-value';
// import enums from 'helpers/enums';
// import mix from 'helpers/mix';

const YesNo = {
	true: 'Да',
	false: 'Нет'
};

const PropertiesMixin = {
	properties: {
		primary: {
			type: 'boolean',
			sourceValues: YesNo,
			display: {
				label: 'основной контакт',
				transform: (v) => YesNo[v]
			}
		},
		'value.type': {
			display: {
				label: 'тип',
				transform: (v) => enums.get('contactValues', v)
			}
		},
		'value.value': {
			display: {
				label: 'контакт'
			}
		},
		note: {
			display: {
				label: 'дополнительно'
			}
		},
		purpose: {
			display: {
				transform: (v) => enums.get('contactValuePurposes', v)
			}
		},
		value: {
			display: {
				transform () {
					const value = this.getValueModel();
					if (!value) return 'без контактов';
					const result = [value.toHtml()];
					let addValue = this.get('addValue') || '';
					addValue && (addValue = 'доб. ' + addValue);
					if (addValue) { result.push(addValue); }

					return result.join(', ');
				}
			}
		}
	}
};

const ActionsMixin = {
	actions: [
		action('edit', 'изменить контакт')
	],
	actionEdit () {
		editValue.do({
			header: 'изменение контакта',
			controlType: 'contactValue',
			actionOnDestroy: 'cancel',
			applyLabel: 'сохранить изменения',
			cancelLabel: 'закрыть без сохранения',
			resetLabel: 'удалить контакт',
			resetButtonCssClass: 'delete-button',
			resetButtonConfirm: 'Вы собираетесь удалить эти контактные данные',
			value: this.toJSON()
		}).then(
			(data) => {
				if (this.isNew()) {
					this.set(data);
					this.trigger('edited', this);
				} else {
					this.save(data, { wait: true }).then(
						() => {
							this.trigger('edited', this);
						},
						(xhr) => error('не удалось применить изменения', xhr)
					);
				}
			},
			(reason) => {
				if (reason.reason === 'reset') {
					this.destroy({ wait: true }).then(
						() => {},
						(xhr) => error('Неудалось удалить контакт', xhr)
					);
				}
			}
		);
	},
	hasValue () {
		const value = this.getValueModel();
		return (value.get('value') || '').toString().length > 0;
	}
};
const ContactValue = mix(BaseModel).with(PropertiesMixin, Actionable, ActionsMixin).extend({
	constructor () {
		BaseModel.apply(this, arguments);
		this.initializeValueModel();
	},
	initializeValueModel () {
		if (this._valueModelInitialized) return;

		const raw = this.getRawValueModel();
		this.valueModel = new ContactValueModel(raw);
		this.on('change:value', this.updateValueModel);

		this._valueModelInitialized = true;
	},
	getValueModel () {
		return this.valueModel;
	},
	getRawValueModel () {
		return _.extend({}, this.get('value'));
	},
	updateValueModel () {
		const raw = this.getRawValueModel();
		const value = this.getValueModel();
		value.set(raw);
	},

	// getLabel(){
	// 	return this.display('value');
	// },

	getType () {
		const value = this.getValueModel();
		return value.get('type');
	},
	getTypeOrder () {
		const value = this.getValueModel();
		return value.getTypeOrder();
	},
	isPhone () {
		const value = this.getValueModel();
		return value.isPhone();
	},
	isEmail () {
		const value = this.getValueModel();
		return value.isEmail();
	},
	isPrimary () {
		return this.get('primary') === true;
	}

});
export default ContactValue;
busModels.reply('ContactValue', () => ContactValue);
