import { _ } from 'vendors';
import MnObject from 'base/object';
import busNotify from 'bus/notify';
import { BaseEvent } from 'helpers/notifier/models';
import { extractErrorMessage } from 'helpers/extract-error-message';

function sizeString (value, postfixes = ['б', 'кб', 'мб', 'гб']) {
	const postfix = postfixes.shift();
	if (!postfixes.length) {
		return value + ' ' + postfix;
	} else {
		if (value < 1000) {
			return (Math.round(value * 10) / 10) + ' ' + postfix;
		} else {
			return sizeString(value / 1024, postfixes);
		}
	}
}

const SessionEventModel = BaseEvent.extend({
	defaults: {
		store: true
	},
	isNew: () => true,
	getName: () => 'upload-session',
	getText () {
		return this.get('helpMessage');
	},
	getType () {
		return this.get('type');
	},
	getEventType: () => 'system',
	getSmallText () {
		const names = _.map(this.getFileNames(), name => `<em>${name}</em>`);
		return names;
	},
	getBottomText () {
		const totalBytes = this.get('totalBytes');
		const sendedBytes = this.get('sendedBytes');
		return `<progress value="${sendedBytes}" max="${totalBytes}"></progress>`;
	},
	getFiles () {
		return this.get('files') || [];
	},
	getFileNames () {
		const files = this.getFiles();
		return _.map(files, file => file.name + ' ' + sizeString(file.size));
	}
});


export const NotifierHelper = MnObject.extend({
	notify (...args) {
		busNotify.request(...args);
	},
	notifySession (type, session, helpMessage) {
		const hash = _.extend({ type, helpMessage, viewedDelay: 0 }, session);
		busNotify.request('notify', type, hash, { modelClass: SessionEventModel });
	},
	_applyUploadProgress (event, session) {
		const computable = event.lengthComputable;
		if (computable) {
			session.totalBytes = event.total;
			session.sendedBytes = event.loaded;
		}
	},
	onUploadProgress (event, session) {
		this._applyUploadProgress(event, session);
		this.notifySession('wait', session, 'идет загрузка');
	},
	onUploadComplete (event, session) {
		session.viewed = false;
		this.notifySession('wait', session, 'идет обработка');
	},
	onSession (data, session) {
		session.destroyDelay = 1;
		session.store = false;
		session.viewed = false;
		// session.viewedDelay = 2;
		this.notifySession('message', session, 'загрузка завершена');
	},
	onSessionAwaiting (session) {
		this.notifySession('wait', session, 'ожидает загрузки');
	},
	onSessionFail (data, session) {
		// debugger;
		const message = extractErrorMessage(data) || 'вероятно файл слишком большой';

		this.notifySession('error', session, 'произошла ошибка, ' + message);
	},
	onFail () {
		this.notify('error', { text: 'Загрузка файлов сорвалась', store: false });
	},
	onSuccess () {
		this.notify('message', { text: 'Загрузка файлов завершена', destroyDelay: 2 });
	}

});
