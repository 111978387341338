import CollectionView from 'base/collection-view';
// import childView from './department';
import comparator from 'helpers/comparator';

export default CollectionView.extend({
	className: 'departments-list',
	// childView,
	viewFilter (v) {
		const parentId = this.model && this.model.id;
		return v.model.get('parentId') === parentId;
	},
	viewComparator (v1, v2) {
		return comparator([v1, v2, (m) => m.display('name')]);
	}

});
