import Yat from 'marionette.yat';
import envCfg from '../config';

const cfg = Yat.createConfig('app:cfg');

cfg.set('pushState', envCfg.pushState);
cfg.set('pageUrl', function (url) {
	return (this.get('pushState') !== true ? '#' : '') + (url || '');
});


export default cfg;
