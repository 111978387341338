import '../api-config';
import BaseModel from './model';
// import BbStore from 'base/bb-store';
import paths from 'helpers/paths';
import action from 'helpers/action';

const Model = BaseModel.extend({
	adminsCheck: true,
	urlRoot: paths.api('users:admins'),
	actions: [
		action('remove:admin', 'отобрать права администратора', { admin: 'manageAdmins' })
	],
	actionRemoveAdmin () {
		this.destroy({ wait: true });
	}
});

// const StoreModel = BbStore(Model);

export default Model;
