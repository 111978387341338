import _ from 'underscore';
import isKnownCtor from '../helpers/isKnownCtor.js';
import getProperty from '../functions/common/get-property';

function invokeFunc(func, context, args) {
	if (args == null) {
		return func.call(context);
	} else if (!Array.isArray(args)) {
		return func.call(context, args);
	} else {
		return func.apply(context, args);
	}
}

function getValue(obj, key, options) {
	
	if (obj == null) { return; }

	let value = obj[key];
	let { invoke, force } = options;
	if (force != null) {
		invoke = force;
	}
	if (typeof value === 'function' && invoke === true && !isKnownCtor(value)) {
		let { invokeContext, context, invokeArgs, args } = options;
		if (context != null) {
			invokeContext = context;
		}
		if (args != null) {
			invokeArgs = args;
		}
		value = invokeFunc(value, invokeContext, invokeArgs);
	}
	return value;
}

function hasKey(obj, key) {
	return !!obj && obj[key] !== 'undefined';
}

function normalizeOptions(options, defs, invokeContext) {
	if (typeof options === 'boolean') {
		options = { invoke: options, force: options };
	}
	options = Object.assign({ invokeContext }, options);
	return _.defaults(options, defs);
}
const defaultOptions = { force: true, deep: true };

function extract(first, second, key, options, context) {
	options = normalizeOptions(options, defaultOptions, context);
	let { deep } = options;
	const value = getValue(first, key, options);
	if (!deep || value !== undefined) {
		return value;
	}
	return getValue(second, key, options);
}

function log(kind, options) {
	if (options && options.args && options.args[0]) {
		console.log(kind, '[',options,']');
	}
}

export default (Base) => {
	let Mixin = Base.extend({

		//property first approach
		getProperty(key, options){
			// OLD FASHION
			// if (typeof options === 'boolean') {
			// 	options = { force: options }
			// }
			// return getProperty(this, key, options, this.getOption);
			// log('property', options);
			// console.log('property: [', options, ']');
			return extract(this, this.options, key, options, this);

		},

		//options first approach
		getOption(key, options){
			// OLD FASHION
			// if (typeof options === 'boolean') {
			// 	options = { force: options }
			// }
			// options.context = this;
			// return getProperty(this.getProperty('options',{deep:false}), key, options, this.getProperty);
			// console.log('option: [', options, ']');
			// log('options', options);
			return extract(this.options, this, key, options, this);

		},
		
	});
	return Mixin;
}
