import BasePage from 'pages/auth';
import Layout from './layout';
const Page = BasePage.extend({
	moduleRights: { productsStore: 'storeAdmin' },
	label: 'Корректировка количества продукции',
	route: 'edit-amounts',
	icon: 'edit',
	Layout
});

export default Page;
