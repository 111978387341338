import '../api-config';
import BaseModel from 'base/model';
import mix from 'helpers/mix';
import appData from 'mod/appData';
import _enum from 'helpers/enums/get';
import paths from 'helpers/paths';

const Properties = {
	properties: {
		id: {
			display: {
				label: 'модуль',
				transform: (v) => appData.getModuleName(v)
			}
		},
		value: {
			display: {
				label: 'права',
				transform (v) {
					return _enum(appData.getModuleEnum(this.id), v);
				}
			}
		}
	}
};

export default mix(BaseModel).with(Properties).extend({
	defaults: {
		id: undefined,
		value: undefined
	},
	urlRootPattern: paths.api('principal:rights')
});
