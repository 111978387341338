import { moment } from "vendors";
import { propertySchemaApi } from "../../../../apis/schema";
import { displayUnit } from "../../../../apis/units";
import { addPostfix, postfixFunc } from "../../../../utils";
import { openCardButton } from "../../../../utils/openCardButton";
import { employeeName } from "../../../staff/emps";

export const batchPurchaseSchema = {
	baseAmount: {
		label: 'на складе',
		valueType: 'number'
	},
	baseAmountPostfix: {
		label: 'на складе',
		displayValue (v, m, s) {
			v = propertySchemaApi.displayValue('baseAmount', m, s);
			const unit = propertySchemaApi.getValue('baseUnitMeasureId', m, s);
			const dunit = displayUnit(unit);
			return addPostfix(v, postfixFunc(dunit));
		}
	},
	originProcess: {
		label: 'процесс',
		displayValue (v, m, s) {
			v = propertySchemaApi.getValue('originProcess', m, s);
			if (v == null) {
				return;
			}
			return v.name;
		}
	},
	originProcessUrl: {
		label: 'процесс',
		displayValue (v, m, s) {
			v = propertySchemaApi.getValue('originProcess', m, s);
			if (v == null) {
				return;
			}
			const id = v.iId || v.id;
			//const url = cardUrls.process + id;
			return openCardButton({ id, type: 'process' }, v.name);
		}
	},
	created: {
		label: 'дата транзакции',
		valueType: 'datetime',
		displayValue (v, m, s) {
			const created = propertySchemaApi.getValue('created', m, s);
			return moment(created).format('DD.MM.YY hh:mm:ss');
		}
	},
	actorId: {
		label: 'сотрудник',
		displayValue (v, m, s) {
			return employeeName(v);
		}
	},
}