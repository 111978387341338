// import BasePage from 'pages/auth';
// import { DepartmentModel } from '../../models';
import { ProdDepPage, PageLayout } from '../common';

import ContentLayout from './layout';

const Layout = PageLayout.extend({
	className: 'production-department',
	contentView: ContentLayout
    // contentViewOptions() {
    //     console.log('<< chpok >>', this.actionContext);
    //     return {
    //         ac: this.actionContext
    //     }
    // }
});

const Page = ProdDepPage.extend({
	// moduleRights: { productProduction: 'view' },
	label () {
		return 'Контрагенты отдела ' + ((this.model && this.model.display('name')) || '') + ' ';
	},
	route: 'contragents-transactions',
	icon: 'contragents',
	pageId: 'contragents',
	Layout
    // onBeforeStart(rd){
	// 	let model = new DepartmentModel({id : rd.args.departmentId});
	// 	this.addModel(model);
	// 	this.addStartPromise(this.model.fetch());
	// },
});

export default Page;
