import { _ } from 'vendors';
import Yat from 'marionette.yat';
import ExtendedFetch from 'mixins/fetch-ext';
import ModelFineIdMixin from 'mixins/model-fine-id';
import ModelPropertiesMixin from 'mixins/model-properties';
import ModelApi from 'mixins/model-ajaxApi';
import BackboneExt from 'mixins/backbone-ext';
import ModelChangePropertyMixin from 'mixins/model/change-property-handler';
import merge from 'helpers/merge';
// import toDeepJson from 'helpers/to-deep-json';
import set from './set';
import save from './save';
import mix from 'helpers/mix';
import Bb from 'backbone';
import apiSendMixin from './mixin.apiSend';
import paths from 'helpers/paths';

Yat.Model.mixWith = function (...args) {
	return mix(this).with(...args);
};

const ThisMixin = {
};

const BaseAppModel = mix(Yat.Model).with(ThisMixin, ExtendedFetch, ModelFineIdMixin, ModelPropertiesMixin, BackboneExt, ModelApi, apiSendMixin, ModelChangePropertyMixin);

const AppModel = BaseAppModel.extend({

	// default is 'merge'
	fetchBehavior: 'merge',

	_initProperties (opts = {}) {
		if (_.isObject(opts.properties)) {
			this.properties || (this.properties = {});
			merge(this.properties, opts.properties);
		}
	},

	constructor (hash, opts = {}) {
		this._initProperties(opts);

		if (opts.parent) { this.parent = opts.parent; }

		BaseAppModel.apply(this, arguments);
	},

	/* toJSON(opts = {}){
		let originToJson = Yat.Model.prototype.toJSON;
		if (opts.deep === false)
			return originToJson.call(this, opts);
		else {
			opts.originToJson = originToJson;
			return toDeepJson(this, opts);
		}
	}, */

	set,
	save (key, val, options) {
		const result = save.call(this, key, val, options);
		if (result === false) { return Promise.reject(this.validationError); } else { return result; }
	},
	postProcessFetchedData (fetched) {
		if (this.fetchBehavior !== 'replace') return;
		_(this.attributes).each((val, key) => {
			if (key in fetched) return;
			this.unset(key);
		});
	},
	_buildPageUrlArgs (args) {
		if (args && !args.thisId) {
			args.thisId = this.id;
		}
		return args;
	},
	pageUrl (args) {
		const urlPattern = _.resultArgs(this, 'pageUrlPattern', [this, args]);
		if (!urlPattern) {
			return;
		}
		args = this._buildPageUrlArgs(args);
		return paths.urls.page(urlPattern, args);
	}

});
Bb.AppModel = AppModel;

AppModel.getSchema = function () {
	const methods = ['getPropertyConfig', 'properties', 'getPropertyLabel', 'display', 'getPropertyDisplay', 'get', '_rawGet', 'getPropertyNested', 'editProperty'];
	const schema = { attributes: {} };
	_.each(methods, methodName => {
		const property = this.prototype[methodName];
		if (_.isFunction(property)) { schema[methodName] = property.bind(schema); } else { schema[methodName] = property; }
	});
	return schema;
};

export default AppModel;
