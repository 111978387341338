import '../api-config';
import BaseCollection from 'base/collection';
import PagedCollection from 'mixins/paged-collection';
import mix from 'helpers/mix';
import Model from './model';
import paths from 'helpers/paths';
import bus from 'bus/models';
import { contragentApiSearchUrl } from '../constants';

const Collection = mix(BaseCollection).with(PagedCollection).extend({
	model: Model,
	url: paths.api('contragents')
});

const store = new BaseCollection([], { model: Model });

export function newContragentsSearchCollection () {
	const col = new BaseCollection([], { model: Model });
	col.urlPattern = contragentApiSearchUrl;
	return col;
}

bus.reply('contragents:store', () => store);
bus.reply('new:contragents:search', (opts = {}) => {
	const col = new BaseCollection([], { model: Model });
	col.url = paths.api('contragents') + '/search';
	col.once('sync', () => {
		store.add(col.models, { remove: false, merge: true });
	});

	if (opts.fetch) {
		col.fetch(opts.fetchOptions);
	}
	return col;
	// return new Collection();
});

bus.reply('new:contragents:address:search', (opts) => {
	const col = new BaseCollection([], { model: Model });
	col.url = paths.api('contragents') + '/search-address';
	if (opts.fetch) {
		col.fetch(opts.fetchOptions);
	}
	return col;
	// return new Collection();
});



export default Collection;
