import { _ } from 'vendors';
// import BaseView from 'base/view';
import collectionView from './collection-view';
import SearchCollectionView from 'base/search-collection-view';
// import Collection from 'base/collection';

import Selector from 'helpers/selector';
import Values from 'components/values';

import mix from 'helpers/mix';
// import ItemViewMixin from './item-view-mixin';
import BaseControlMixin from 'components/controls/mixin';

const compare = function (l, e) {
	const lV = _.isModel(l) ? l.id : l;
	const eV = _.isModel(e) ? e.id : e;
	return lV === eV;
};
const inList = function (list, entry) {
	return _(list).some((l) => compare(l, entry));
};
const notInList = function (list, entry) {
	return !inList(list, entry);
};

const reCreateColl = function (_col, exclude) {
	if (!exclude || !exclude.length) { return _col; }


	const col = new _col.constructor();
	const models = _col.filter((f) => {
		return notInList(exclude, f);
	});

	col.reset(models);

	return col;
};

const BaseSelector = mix(SearchCollectionView).with(BaseControlMixin);
export default BaseSelector.extend({
	className () {
		return this.getOption('multiple') ? 'select-multiple' : 'select-single';
	},
	constructor (opts = {}) {
		opts._collection = opts.collection;
		delete opts.collection;

		BaseSelector.apply(this, arguments);

		this.normalizeCollection();

		this.mergeOptions(opts, ['multiple']);

		this.prepareInitialValues();



		this.selector = new Selector({
			multiple: this.multiple,
			collection: this.collection,
			valueType: this.getOption('valueType'),
			initialValue: this.initialValue,
			children: () => this.entriesView.children,
			childrenFiler: (v) => this.entriesView && this.entriesView.viewFilter ? this.entriesView.viewFilter(v) : true
		});
	},
	normalizeCollection () {
		const _col = this.getOption('_collection');
		const exclude = this.getOption('excludeValues');
		if (_col) {
			this.collection = reCreateColl(_col, exclude);
		}
		if (!this.collection) {
			this.collection = this.tryCreateCollection();
		}
	},
	tryCreateCollection () {
		const src = this.getOption('sourceValues');
		if (src == null) { throw new Error('SelectControl: there is no data provided'); }
		console.log('trying create a collection', this.options);
		const _col = Values.collection(src, {
			type: this.getOption('modelType'),
			valueType: this.getOption('valueType'),
			excludeValues: this.getOption('excludeValues'),
			allowedValues: this.getOption('allowedValues'),
			valuesFilterMask: this.getOption('valuesFilterMask')
		});

		const exclude = this.getOption('excludeValues');
		return reCreateColl(_col, exclude);
	},
	collectionView,
	collectionViewOptions () {

		// let childView = this.getOption('childView');
		// if(childView)
		// 	childView = mix(childView).with(ItemViewMixin);
		return {
			selector: this.selector,
			viewComparator: this.getOption('viewComparator', { force: false }),
			shouldSearchOnType: this.getOption('shouldSearchOnType'),
			childViewMixin: this.getOption('collectionChildViewMixin')
			// childView,
		};
	},
	getModelValue (m) {
		if (this.getOption('shouldReturnModel')) {
			return m;
		}
		return m == null
			? m
			: _.isFunction(m.getValue)
				? m.getValue()
				: m.id;
	},
	getValue () {
		const raw = this.selector.getValue();
		let result;
		if (_.isArray(raw)) { result = _(raw).map((v) => this.getModelValue(v)); } else { result = this.getModelValue(raw); }

		return this.transformValueOut(result);
	},
	onSearchReady () {
		this.selector.selectInitialValues();
	},
	childViewEvents: {
		'change' () {
			this.triggerChange(this.getValue());
			// if (this.multiple) {
			// } else {
			// 	this.triggerDone(this.getValue());
			// }
		}
	}
});
