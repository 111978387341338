import { _, moment } from 'vendors';
import Model from 'base/model';
import mix from 'helpers/mix';
import ActionableMixin from 'mixins/actionable';
import action from 'helpers/action';
import busData from 'bus/data';
import enums from 'helpers/enums';
import busViews from 'bus/views';
import modals from 'helpers/modals';
import busEdit from 'bus/edit';

const PropertiesMixin = {
	properties: {
		actorId: {
			nested: (m) => busData.employees(m),
			display: {
				transform: (v) => busData.employees(v, { transform: (e) => e.display('name') })
			}
		},
		created: {
			editGetOptions: { convert: 'date' },
			transform (v, opts = {}) {
				const di = Date.info(v);
				if (opts.convert === 'moment') { return di.valid && moment(di.date); } else if (opts.convert === 'info') { return di; } else { return di.valid && di.date; }
			}
		},
		type: {
			display: {
				transform: (v) => enums.get('appEventTypes', v)
			}
		},
		task: {
			display: {
				label: 'задача',
				transform: (v) => (v || {}).name
			}
		},
		process: {
			display: {
				label: 'процесс',
				transform: (v) => (v || {}).name
			}
		},
		contragent: {
			display: {
				label: 'контрагент',
				transform: (v) => (v || {}).name
			}
		}


	}
};

const ActionsMixin = {
	actions: [
		action('edit:text', 'редактировать', null, {
			rule: m => m.isTextEditable()
		}),
		action('edit:marks', 'изменить маркеры', null, {
			rule: m => m.isEditable()
		}),
		action('delete', 'удалить', null, {
			rule: m => m.isDeleteable()
		})
	],
	actionEditText () {
		const model = this;

		const ExtendedEdit = busViews.request('EditCommentEntry');
		modals.show({
			asPromise: true,
			returnPromise: true,
			header: 'редактирование комментария',
			content: new ExtendedEdit({ model }),
			type: 'message-flex',
			show: {
				actions: false
			}
		}).then(
			(attrs) => {
				console.log('>>> saving', attrs, model);
				model.save(null, { attrs, wait: true, method: 'patch' });
			},
			(data) => {}
		);
	},
	actionEditMarks () {
		// console.log('~~', this);
		const model = this;
		busEdit.do({
			header: 'изменение маркеров',
			valueType: 'enum',
			sourceValues: enums.store.appLogMarks,
			excludeValues: ['none'],
			controlType: 'select',
			multiple: true,
			value: this.get('marks')
		}).then(marks => {
			model.save(null, { attrs: { marks }, wait: true, method: 'patch' });
		});
	},
	actionDelete () {
		const model = this;
		modals.confirm('подтвердите удаление').then(
			() => { model.destroy({ wait: true }); },
			() => { console.log(false); }
		);
	}
};

const Base = mix(Model).with(ActionableMixin, ActionsMixin, PropertiesMixin);
export const CommentEntryModel = Base.extend({
	getInternalEntityByName (name) {
		let val = this.get(name);
		if (_.isObject(val)) {
			return val;
		}
		const json = this.get('json');
		if (!_.isObject(json)) return;
		val = json[name];
		if (_.isObject(val)) {
			return val;
		}
	},
	getEntityName () {
		let name;
		_.some(['task', 'process', 'contragent', 'productionCycle'], key => {
			if (this.has(key)) {
				name = key;
				return true;
			}
		});
		return name;
	},
	getEntityFullId () {
		const name = this.getEntityName();
		if (!name) return;
		return name + ':' + this.get(name).id;
	},
	getDateCheckPass () {
		const date = this.getDate();
		const dateCheckPass = date.valueOf() > Date.now() - (60000 * 1440); // 1440 mins / 1 day
		return dateCheckPass;
	},
	isTextEditable () {
		// let date = this.getDate();
		// let dateCheckPass = date.valueOf() > Date.now() - (60000 * 1440); // 1440 mins / 1 day
		const dateCheckPass = this.getDateCheckPass();
		const canBeEdited = (this.isMy() && dateCheckPass) || busData.user().isAdmin();
		return canBeEdited && this.get('type') === 'comment';
		// return this.isEditable() && dateCheckPass && this.get('type') === 'comment';
	},
	isEditable () {
		const user = busData.user();
		return this.isMy() || user.isAdmin();
	},
	isDeleteable () {
		const dateCheckPass = this.getDateCheckPass();
		return (this.isEditable() && dateCheckPass) || busData.user().isAdmin();
	},
	isMy () {
		// console.log(":::", );
		const user = busData.user();
		return user.isMe(this.get('actorId'));
	},
	getDateInfo () {
		return Date.info(this.get('created'));
	},
	getDate () {
		const di = this.getDateInfo();
		return di.valid && di.date;
	},
	getChange (key) {
		const changes = this.getChanges();
		return changes[key];
	},
	getChanges () {
		const result = {};
		let changes, entity, entityType;
		const json = this.get('json');
		if (_.isArray(json)) { changes = json; } else if (_.isObject(json) && _.isArray(json.changes)) {
			entity = json.entity;
			entityType = json.entityType;
			changes = json.changes;
		}
		if (!_.isArray(changes)) { return result; }

		_(changes).each((change) => {
			result[change.path] = _.omit(change, 'path');
		});

		if (!entity) { return result; } else {
			return {
				entity,
				entityType,
				changes: result
			};
		}
	}
});
