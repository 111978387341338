import { _ } from 'vendors';

/*
{
	id:'action:name',
	label: 'action label',
	rights:{},
	rule:{},
	order: 0,
}
*/

function action (a1, a2, a3, a4) {
	const options = {};
	const len = arguments.length;

	if (len === 1 && _.isObject(a1)) {
		_.extend(options, a1);
	} else if (len === 1 && _.isString(a1)) {
		_.extend(options, { id: a1, label: a1 });
	} else if (len === 2 && (_.isString(a1) && _.isString(a2))) {
		_.extend(options, { id: a1, label: a2 });
	} else if (len === 2 && (_.isString(a1) && _.isObject(a2))) {
		_.extend(options, { id: a1, label: a1, rights: a2 });
	} else if (len === 3 && (_.isString(a1) && _.isString(a2) && _.isObject(a3))) {
		_.extend(options, { id: a1, label: a2, rights: a3 });
	} else if (len === 3 && (_.isString(a1) && _.isObject(a2) && _.isObject(a3))) {
		_.extend(options, { id: a1, label: a1, rights: a2 }, a3);
	} else if (len >= 3 && (_.isString(a1) && _.isString(a2) && !_.isString(a3))) {
		_.extend(options, { id: a1, label: a2, rights: a3 }, a4);
	} else if (_.isString(a1) && _.isString(a2) && _.isString(a3) && _.isString(a4)) {
		_.extend(options, { id: a1, label: a2, rights: { [a3]: a4 } });
	} else if (_.isString(a1) && _.isFunction(a2)) {
		_.extend(options, { id: a1, label: a2, rights: a3 }, a4);
	}

	return options;
}
export default action;
