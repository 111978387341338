// import { BlockTabsHeader } from 'components/ui2';
// import smartOpen from 'helpers/smart-open';

import { buildTabsHeader as baseBuildTabsHeader } from 'components/ui2';

export const tabs = [
	{ id: 'root', name: 'действующие' },
	{ id: 'closed', name: 'завершенные' }
];

export function buildTabsHeader (startTab, ac, isRoot) {
	return baseBuildTabsHeader(startTab, tabs, ac, isRoot);
	// return new BlockTabsHeader({
	// 	startTab,
	// 	tabs,
	// 	//makeTabActiveOnClick: true,
	// 	onTabClick(tab, event) {
	// 		if (isRoot && tab.id === 'root') return;

	// 		let root = isRoot ? ac.page : ac.page.getParent();

	// 		let url;
	// 		if (!isRoot && tab.id === 'root')  {
	// 			url = root.url(ac.args);
	// 		} else {
	// 			let route = root.getRoute() + '/' + tab.id;
	// 			route = root._normalizeRoute(route);
	// 			url = root.parseUrl(route, ac.args);
	// 		}
	// 		smartOpen(url, event);
	// 		// console.log('GOING', url);
	// 		// console.log('GOING NESTED', url);
	// 	}
	// });
}
