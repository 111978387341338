import { UiSchemaMoleculeView } from '../molecule';
import { commonMixin } from './common';
import { SchemaLabeledValueView } from './SchemaLabeledValueView';



export const BasePropertyView = UiSchemaMoleculeView.extend({
	baseClassName: 'schema-property',
	...commonMixin,
	content: SchemaLabeledValueView
});
