import ListView from 'mod/commonProcesses/views/list';
import Collection from 'base/collection';
import Model from 'mod/commonProcesses/models/process';
import { CreateProcessView2 } from 'mod/commonProcesses/views/create';
import modals from 'helpers/modals';
import { BaseHamburgerView } from '../../../../base/base-hamburger-view';
import { $ } from 'vendors';

export function getLastFewSubprocs(subprocs, process) {

	const collection = new Collection(subprocs, { model: Model });

	return {
		class: ListView,
		collection,
		emptyView: null
	}

}

export function createDialog(process, resolveAction) {

	if (!process || typeof process.id !== 'string') {
		return Promise.reject();
	}
	
	return modals.show({
		asPromise: true,
		returnPromise: true,
		header: 'создание нового подпроцесса',
		content: new CreateProcessView2({ 
			parentProcessId: process.id,
			resolveAction 
		}),
		type: 'full',
		show: {
			actions: false
		}
	}).then(
		(attrs) => {
			// console.log('>>> saving', attrs, model);
			// model.save(null, { attrs, wait: true, method: 'patch' });
		},
		(data) => {

		}
	);
}

const OwnList = ListView.extend({
	tagName: 'div',
	className: 'sub-processes',
});

const SubProcsContent = BaseHamburgerView.extend({
	template: '<header class="subprocesses-buttons separate-buttons blue" ><button data-action="create-new">добавить новый подпроцесс</button><button data-action="join-exist">присоединить существующий процесс</button></header>',
	events: {
		'click header [data-action]'(e) {
			const $btn = $(e.target).closest('[data-action');
			const action = $btn.data('action');
			this.executeAction(action);
		}
	},
	executeAction(action) {
		switch(action) {
			case 'create-new':
				return createDialog(this.getOption('process'), (attrs) => this.createSubProcess(attrs));
			default:
				return modals.message('пока в разработке');
		}
	},
	initialize() {
		const proc = this.model;
		const procs = new Collection([], { model: Model });
		procs.url = proc.url() + '/subprocesses';
		procs.fetch();
		this.subprocesses = procs;
	},
	createSubProcess(attrs) {
		const model = new Model(attrs);
		return model.save({ wait: true }).then(model => {
			this.subprocesses.add(model, { at: 0 });
		});
	},
	childrenViews: [v => ({ class: OwnList, collection: v.subprocesses })]

})

export function subProcsContent(v) {
	return {
		class: SubProcsContent,
		process: v.model,
	}
}