import app from './app';
import edit from './edit';
import data from './data';
import views from './views';
import Radio from './radio';
import appEvents from './app-events';
import notify from './notify';

export default {
	views,
	data,
	edit,
	app,
	appEvents,
	notify,
	request (...args) { return Radio.request(...args); },
	reply (...args) { return Radio.reply(...args); },
	channel (...args) { return Radio.channel(...args); }
};
