import { _ } from 'vendors';
import { Collection } from './base-models';
import ListItemModel from './list-item-model';


const ListItemsCollection = Collection.extend({
	comparator: 'order',
	sortAndFix () {
		this.sort();
		const changed = [];
		this.each((model, index) => {
			const modelIndex = model.get('order');
			if (modelIndex !== index) {
				changed.push({ id: model.id, order: index, listId: model.get('listId') });
				// model.set('order', index, { silent: true });
			}
		});
		this.parent.set(changed, { merge: true, remove: false, add: false, silent: true });
		return changed;
	}
});

export default Collection.extend({
	constructor () {
		Collection.apply(this, arguments);
		this.initializeGrouper();
	},

	groupIteratee: model => model.get('listId'),

	initializeGrouper () {
		this._ensureList();

		this._bulkChange('add', this.models);


		this.on({
			update: this._onUpdate,
			reset: this._onReset,
			'change:listId': this._onChangeListId
		});
	},
	/* Ultimate
	getGroupResult(){
		let groups = this.groupBy(this.groupIteratee);
		let keys = _.reduce(this.collections, (memo, c, id) => {
			memo[id] = [];
			return memo;
		}, {});
		_.defaults(groups, keys);
		return groups;
	}, */

	_onReset () {
		_.each(this.collections, (col) => {
			col.reset();
		});
	},
	_onUpdate (_this, options = {}) {
		const changes = options.changes || {};
		const { added = [], merged = [], removed = [] } = changes;

		if (added.length) {
			this._bulkChange('add', added);
		}
		if (merged.length) {
			this._bulkChange('set', merged, { remove: false, merge: true, add: true });
		}
		if (removed.length) {
			this._bulkChange('remove', removed);
		}

		/* ULTIMATE
		let groups = this.getGroupResult();
		_.each(groups, (models, id) => {
			this._ensureList(id);
			this.collections[id].set(models, { add: true, remove: true, merge: true });
		});
		*/
	},
	_bulkChange (method = 'add', allModels = [], options = {}) {
		if (!allModels.length) return;
		const byList = _.groupBy(allModels, this.groupIteratee);
		_.each(byList, (models, listId) => {
			this._ensureList(listId);
			const list = this.collections[listId];
			list[method](models, options);
		});
	},

	_onChangeListId (model) {
		const prev = model._previousAttributes;
		if (!prev) {
			console.warn('Board List Item _onSingleChange: _previousAttributes undefined');
		}
		const prevId = prev && prev.listId;
		if (prevId == null) {
			console.warn('Board List Item _onSingleChange: listId undefined');
		}
		const newId = model.get('listId');
		this._ensureList(newId);
		this._ensureList(prevId);

		const prevCol = this.collections[prevId];
		prevCol && prevCol.remove(model);
		const newCol = this.collections[newId];
		newCol && newCol.add(model);
	},

	model: ListItemModel,

	_ensureList (id)	{
		if (!this.collections) {
			this.collections = {};
		}
		if (id != null) {
			if (this.collections[id]) return;
			this.collections[id] = new ListItemsCollection();
			this.collections[id].parent = this;
		}
	},
	getListItemsCollection (list) {
		if (list == null) return;
		const listId = _.isObject(list) ? list.id : list;
		if (listId == null) return;

		this._ensureList(listId);
		return this.collections[listId];
	}
});
