import BaseView from 'base/search-collection-view';
// import groups from 'mod/groups/singleton';
import childView from 'mod/groups/views/card-item';
import dataBus from 'bus/data';

export default BaseView.extend({
	// className:'',
	collection: dataBus.groups(),
	childView

});
