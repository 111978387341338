import BasePage from 'pages/auth';
import Production from './production';
// import Deps from './deps';

const Page = BasePage.extend({
	moduleRights: { resources: 'view' },
	label: 'Ресурсы',
	route: 'res',
	children: [Production],
	icon: 'resources'
});

export default Page;
