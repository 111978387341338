import { _ } from 'vendors';
import template from './layout.html';
import View from 'base/view';
// import store from 'helpers/store';
// import isJqXhr from 'helpers/is-jqxhr';
import token from 'app/token';
// import user from 'app/user';
// import beh from 'behaviors';
// import appBus from 'bus/app';

import preloader from 'helpers/modals/preloader';
import modalError from 'helpers/modals/error';

const Layout = View.extend({
	className: 'card maxw600px w100p margin-t',
	template,
	// behaviors:[beh.FormToHash],
	initialize () {
		// this.loginPromise = new Promise((resolve, reject) => {
		// 	this.once('resolve:login', () => resolve());
		// 	this.once('destroy', () => this.inModal ? reject('no login') : resolve());
		// 	//Yat.identity.user.fetch();
		// });
	},
	events: {
		'click .apply' () {
			const hash = {
				username: this.$('input[name="username"]').val(),
				password: this.$('input[name="password"]').val()
			};
			this.onValuesApply(hash);
		}
	},
	onValuesApply (hash) {
		if (!_.size(hash) || !hash.username || !hash.password) return;
		hash.grant_type = 'password';
		this.login(hash.username, hash.password);
	},
	login (username, pass) {
		const freezer = preloader();
		token.login(username, pass).then(
			() => { },
			(xhr) => { modalError('Не удалось авторизоваться', xhr); }
		).then(
			() => freezer.destroy({ force: true })
		);
	},
	templateContext () {
		return {
			header: this.getOption('header'),
			username: ''// store.get('username'),
		};
	}
});

export default Layout;
