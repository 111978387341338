// import { BaseView } from 'base/base-view';
// import { UiMoleculeView } from 'components/ui2/molecule';
import { CommonListItemView } from '../common/ListItemView';
// import { Ui2AtomView } from 'components/ui2/atom';
export default CommonListItemView.extend({
	openCardButton: v => `procs/${v.model.id}`,
	content: {
		text: v => v.model.get('name')
	}
});
