import View from 'base/view';
import { ClickableBehavior } from 'behaviors';
import smartOpen from 'helpers/smart-open';
import { processPageBaseUrl } from 'mods/processes/cfg';

import user from 'app/user';
export const ProcessCardView = View.extend({
	behaviors: [ClickableBehavior],
    // clickElementSelector: '.owner',
	cssClassModifiers: [
		m => user.isMe(m.get('owner').id) ? 'my' : ''
            // busData.user().isMe(v.model.get('owner').id) ? 'my' : ''
	],
	className: 'ui2-block with-wrapper department-product-process clickable',
	template: `<div class="wrapper">
    <div class="ui2-molecule">
        <!-- <div class="owner"><%= owner.name %></div>
        <div class="product"><%= product.name %></div> -->
				<div class="ui2-atom">
					<i>ответственный</i>
					<span><%= owner.name %></span>
				</div>
				<div class="ui2-atom">
					<i>продукт</i>
					<span><%= product.name %></span>
				</div>
    </div>
    <div class="ui2-molecule process">
        <!-- <div class="name"><%= name %></div> -->
				<div class="ui2-atom">
					<i>название процесса</i>
					<span><%= name %></span>
				</div>
    </div>
</div>`,
	onClicked (event) {
		const options = { ctrlKey: !event.ctrlKey };

		const url = processPageBaseUrl + this.model.id;
		smartOpen(url, options);
	}
});
