import { _ } from 'vendors';
import Collection from 'base/collection';

export default Collection.extend({
	initialize (data, opts = {}) {
		this.valueType = opts.valueType;
	},
	getByValue (arg) {
		let value;
		if (this.type === 'single') {
			if (_.isModel(arg)) { value = arg.get('value'); } else if (_.isDate(arg) || !_.isObject(arg)) { value = arg; } else { value = arg.value; }
			return this.findWhere({ value });
		} else if (this.type === 'range') {
			if (_.isModel(arg)) { value = arg.toJSON(); } else if (_.isObject(arg) && ('from' in arg || 'to' in arg)) { value = arg; }

			if (value == null) return;

			return this.findWhere({
				from: value.from,
				to: value.to
			});
		}
	},
	getValues () {
		return this.filter((m) => m.hasValue());
	},
	parse (models) {
		const modelParse = this.model.prototype.parse;
		const valueType = this.valueType;
		if (!_.isFunction(modelParse)) { return models; }
		return _(models).map((model) => modelParse(model, valueType));
	},
	toUrl (key) {
		const values = this.getValues();
		let index = 0;
		const redo = (memo, value) => _.extend(memo, value.toUrl(key, index++));
		const result = _.reduce(values, redo, {});
		return result;
	}
});
