import Model from 'base/model';
import busData from 'bus/data';
import ndsEnum from 'helpers/enums/nds-enum';
import EditableProperties from 'mixins/editable-properties';
import action from 'helpers/action';
import Actionable from 'mixins/actionable';
import busEdit from 'bus/edit';

import { EditWraper, EditProductView } from './EditProductView';
import { AcceptProductView } from './AcceptProductView';

import modalError from 'helpers/modals/error';
import modalConfirm from 'helpers/modals/confirm';
import smartOpen from 'helpers/smart-open';

import { productPageCardUrl } from 'app/constants';

// import paths from 'helpers/paths';
// import smartOpen from 'helpers/smart-open';
// import token from 'app/token';

const BuyProductActions = {
	actions: [
		action('open:card', 'открыть карточку в новой вкладке'),
		action('edit', 'редактировать'),
		action('accept', 'принять'),
		action('finalize', 'зачеркнуть - больше поставок не будет', null, { rule: m => !m.get('isFullyAccepted') }),
		action('remove', 'удалить', null, { rule: m => !m.get('acceptedAmount') })
	],
	actionEdit (action, options) {
		const buyProductModel = this;
		const proc = buyProductModel.process;
		// let col = buyProductModel.collection;
		// let proc = buyProductModel.process;

		const content = new EditProductView({ model: buyProductModel, changeBehavior: 'apply' });
		busEdit.doNext({
			header: 'Изменение ' + this.get('fullName'),
			labels: {
				resolve: 'Сохранить изменения'
			},
			content: new EditWraper({
				content
			}),
			resolve () {
				// return Promise.reject();
				// let json = buyProductModel.toJSON();
				const attrs = buyProductModel.omit('id', 'processId');
				console.log('>>> ####', attrs);
				// let attrs = _.pick(json, 'amount', 'unitMeasureId', 'pricePerUnit', 'nds', 'priceUnitMeasureId', 'priceUnitsInPurchaseUnit', 'priceCurrencyId' );
				// let colJson = col.toJSON();
				// console.log('##', attrs, json, colJson);
				return buyProductModel
					.save(null, { attrs, method: 'PATCH', wait: true })
					.then(() => {
						proc.trigger('change:actions');
						// proc.set('productsToBuy', colJson);
						// buyProductModel.trigger('refresh', buyProductModel.attributes)
						// //console.log('### ?? patched', colJson);
						// //proc.trigger('items:patched');
					}, xhr => {
						modalError('Не удалось отредактировать продукцию', xhr);
					});
			}
		}).then(value => {
			console.log('resolved', value);
		}).catch(value => {
			console.log('catched', value);
		});
	},
	actionAccept (action, options) {
		const buyProductModel = this;
		const proc = buyProductModel.process;
		const content = new AcceptProductView({
			product: buyProductModel,
			process: proc
		});
		busEdit.doNext({
			header: 'Приёмка ' + this.get('fullName'),
			labels: {
				resolve: 'Принять'
			},
			content,
			// content: new EditWraper({
			// 	content
			// }),
			resolve (...args) {
				const product = this.content.getOption('product');
				const model = this.content.model;
				const data = {
					unitId: model.get('unitId'),
					acceptType: model.get('acceptType'),
					parties: model.parties.toJSON()
				};

				const acceptModel = new Model();
				acceptModel.url = product.url + '/accept';

				acceptModel.save(null, {
					wait: true,
					url: product.url + '/accept',
					attrs: data,
					method: 'POST'
				}).then(
					x => {
						product.set(x);
						product.process.trigger('change:actions');
						// let colJsn = product.collection.toJSON();
						// proc.set('productsToBuy', colJsn);

						// product.trigger('refresh');
						// proc.trigger('items:patched');

						// console.log('- done -', x);
					},
					xhr => {
						modalError('Не удалось принять продукцию', xhr);
					}
				);


				// console.log('RESOLVE = -', data, model.attributes);
				// let json = buyProductModel.toJSON();
				// let attrs = _.pick(json, 'amount', 'unitMeasureId', 'pricePerUnit', 'nds', 'priceUnitMeasureId', 'priceUnitsInPurchaseUnit', 'priceCurrencyId' );
				// let colJson = col.toJSON();
				// return buyProductModel
				// 	.save(null, { attrs, method: 'PATCH', wait: true })
				// 	.then(() => {
				// 		proc.set('productsToBuy', colJson);
				// 		console.log('### ?? patched', colJson);
				// 		proc.trigger('items:patched');
				// 	}, xhr => {
				// 		modalError("Не удалось отредактировать продукцию", xhr);
				// 	});
			}
		})
			.then(() => {})
			.catch(ex => console.log(ex));
	},
	actionRemove (action, options) {
		modalConfirm('Подтвердите удаление продукции из процесса закупки').then(() => {
			const proc = this.process;
			this.destroy();
			proc.trigger('change:actions');
		}, () => {});
	},
	actionFinalize (action, options) {
		const buyProductModel = this;
		modalConfirm('Вы собираетесь зачеркнуть позицию. подтвердите своё действие').then(() => {
			const proc = this.process;
			buyProductModel.save(null, { attrs: { isFullyAccepted: true }, method: 'PATCH' }).then(ok => console.log('ok ok ok', ok), err => console.log('oatch - error', err));
			proc.trigger('change:actions');
		}, () => {});
	},
	actionOpenCard (action, options) {
		const root = productPageCardUrl;
		// busData.request('product:page:url');
		const opts = {
			ctrlKey: !options.ctrlKey
		};
		smartOpen(root + '/' + this.id, opts);
	}

};


const properties = {
	amount: {
		modelType: 'single',
		multiple: false,
		valueType: 'number',
		display: {
			label: 'количество',
			ifEmpty: '&mdash;',
			transform: v => v != null ? v.toLocaleString('ru') : undefined
		}
	},
	unitMeasureId: {
		sourceValues: () => busData.measures(),
		controlType: 'select',
		display: {
			label: 'учетная единица измерения',
			transform: (v) => busData.measures(v, 'short')
		}
	},
	priceUnitMeasureId: {
		sourceValues: () => busData.measures(),
		controlType: 'select',
		display: {
			label: 'Закупочная единица измерения',
			transform: (v) => busData.measures(v, 'short')
		}
	},
	pricePerUnit: {
		modelType: 'single',
		multiple: false,
		valueType: 'number',
		display: {
			label: 'цена за учётную единицу',
			ifEmpty: '&mdash;',
			transform: v => v != null ? v.toLocaleString('ru') : undefined
		}
	},
	priceCurrencyId: {
		sourceValues: () => busData.currenciesEnum(),
		controlType: 'select',
		display: {
			label: 'Валюта',
			transform: (v) => busData.currencies(v, 'sign')
		}
	},
	priceUnitsInPurchaseUnit: {
		modelType: 'single',
		multiple: false,
		valueType: 'number',
		display: {
			label: 'количество Учётных единиц в одной Закупочной',
			ifEmpty: '&mdash;',
			transform: v => v != null ? v.toLocaleString('ru') : undefined
		}
	},
	nds: {
		modelType: 'single',
		multiple: false,
		valueType: 'enum',
		sourceValues: ndsEnum,
		edit: {
			applyValue (model, prop, val) {
				const fVal = parseFloat(val, 10);
				model.set(prop, fVal);
			}
		},
		display: {
			label: 'ндс',
			ifEmpty: '&mdash;',
			transform: v => v != null ? ndsEnum[v] : undefined
		}
	}
};

export const BuyProcessProductModel = Model.mixWith(EditableProperties, BuyProductActions, Actionable).extend({
	// isNew() {
	// 	return true;
	// },
	properties,
	getPriceInfo () {
		const hash = this.pick('unitMeasureId', 'amount', 'acceptedAmount', 'priceUnitMeasureId', 'pricePerUnit', 'priceUnitsInPurchaseUnit', 'priceCurrencyId');

		hash.pricePerPriceUnit = hash.pricePerUnit * (hash.priceUnitsInPurchaseUnit || 1);
		hash.price = hash.pricePerPriceUnit * hash.amount;
		hash.unitMeasure = this.display('unitMeasureId');
		hash.priceUnitMeasure = this.display('priceUnitMeasure');
		hash.currency = busData.currencies(this.get('priceCurrencyId') || 'rub');
		hash.currencySign = hash.currency.get('sign');

		// hash.currencySign = busData.currencies(this.get('priceCurrencyId'), 'sign');
		return hash;
	}
});
