import { _ } from 'vendors';
import View from 'base/view';
import CollectionView from 'mod/tasks/views/cards';
import TextTagView from 'base/text-tag-view';
import PreventWheel from 'mixins/view/prevent-wheel';

export default PreventWheel(View).extend({
	template: View.emptyTemplate,
	className: 'column-fullheight board-column',
	dynamicClassConfig: {
		events: ['childview:render:children', 'childview:childview:render:children', 'render:children']
	},
	cssClassModifiers: [
		(m, v) => v.isInPast ? 'past-column' : '',
		(m, v) => v.collection.length ? '' : 'shrink-if-empty'
	],
	initialize (opts) {
		this.mergeOptions(opts, ['group']);

		this.createNested('header', {
			viewClass: TextTagView.View,
			viewOptions: {
				tagName: 'header',
				text: this.getGroupHeader()
			}
		});
		this.createNested('cards', {
			viewClass: CollectionView,
			viewOptions: {
				addClass: 'column-scrollable',
				comparatorKey: this.getOption('comparatorKey'),
				newInTop: this.getOption('newInTop')
			}
		});

		this.listenTo(this.collection, 'update', () => this.trigger('refresh'));
	},
	onRender () {
		this.showAllNested();
	},
	getGroupHeader () {
		let result = '';
		const group = this.getOption('group');
		if (group == null) return result;
		result = group.header && _.result(group, 'header');
		if (group.date && _.isDate(group.date) && Date.isToday(group.date)) {
			result = 'СЕГОДНЯ, ' + result;
		}
		return result || group.id;
	},
	onBeforeDestroy () {
		delete this.options.group;
		delete this.group;
	}
});
