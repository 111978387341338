import { _ } from 'vendors';
import ModelProperties from 'components/ui-model-card/properties';
import h from 'components/ui-model-card/helpers';
import ControlsMixin from 'components/controls/mixin';
import View from 'base/view';


export const EditWraper = View.mixWith(ControlsMixin).extend({
	template: _.template('<div></div>'),
	regions: { content: '> div' },
	onRender () {
		const view = this.getOption('content');
		this.showChildView('content', view);
		view.onEditProperty();
	},
	childViewTriggers: {
		'switch:resolve': 'switch:resolve'
	}
});

const defs = { editButton: true, addClass: 'whole-btn', textAction: 'edit', half: true };

export const EditProductView = ModelProperties.mixWith(ControlsMixin).extend({
	className: 'props-place card lined with-border',
	properties: [
		h.ui('pricePerUnit', _.extend({}, defs, { half: false, third: true })),
		h.ui('priceCurrencyId', _.extend({}, defs, { half: false, third: true })),
		h.ui('nds', _.extend({}, defs, { half: false, third: true })),

		h.ui('amount', _.extend({}, defs, { half: true })),
		h.ui('unitMeasureId', _.clone(defs)),
		h.ui('priceUnitMeasureId', _.clone(defs)),
		h.ui('priceUnitsInPurchaseUnit', _.clone(defs))
	],
	onEditProperty () {
		const lockUnlock = this.validate() ? 'unlock' : 'lock';
		this.triggerMethod('switch:resolve', lockUnlock);
	},
	validate () {
		const hasPrice = this.model.get('pricePerUnit') > 0;
		const hasAmount = this.model.get('amount') > 0;
		return hasPrice && hasAmount;
	}
});
