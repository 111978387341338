import { _, moment } from 'vendors';
import Model from 'base/model';
import mix from 'helpers/mix';
import busData from 'bus/data';
import enums from 'helpers/enums';

const PropertiesMixin = {
	properties: {
		actorId: {
			nested: (m) => busData.employees(m),
			display: {
				transform: (v) => busData.employees(v, { transform: (e) => e.display('name') })
			}
		},
		created: {
			editGetOptions: { convert: 'date' },
			transform (v, opts = {}) {
				const di = Date.info(v);
				if (opts.convert === 'moment') { return di.valid && moment(di.date); } else if (opts.convert === 'info') { return di; } else { return di.valid && di.date; }
			}
		},
		type: {
			display: {
				transform: (v) => enums.get('appEventTypes', v)
			}
		},
		task: {
			display: {
				label: 'задача',
				transform: (v) => (v || {}).name
			}
		},
		process: {
			display: {
				label: 'процесс',
				transform: (v) => (v || {}).name
			}
		},
		contragent: {
			display: {
				label: 'контрагент',
				transform: (v) => (v || {}).name
			}
		}


	}
};

const Base = mix(Model).with(PropertiesMixin);
export default Base.extend({
	isMy () {
		return busData.user().isMe(this.get('actorId'));
	},
	getDateInfo () {
		return Date.info(this.get('created'));
	},
	getDate () {
		const di = this.getDateInfo();
		return di.valid && di.date;
	},
	getChange (key) {
		const changes = this.getChanges();
		return changes[key];
	},
	getChanges () {
		const result = {};
		let changes, entity, entityType;
		const json = this.get('json');
		if (_.isArray(json)) { changes = json; } else if (_.isObject(json) && _.isArray(json.changes)) {
			entity = json.entity;
			entityType = json.entityType;
			changes = json.changes;
		}
		if (!_.isArray(changes)) { return result; }

		_(changes).each((change) => {
			result[change.path] = _.omit(change, 'path');
		});

		if (!entity) { return result; } else {
			return {
				entity,
				entityType,
				changes: result
			};
		}
	}
});
