import ModelProperties from 'components/ui-model-card/properties';
import h from 'components/ui-model-card/helpers';
import ControlMixin from 'components/controls/mixin';
import Model from 'base/model';
import EditableProperties from 'mixins/editable-properties';
import bus from 'bus/models';
import busData from 'bus/data';

import { ProdCycleProdComponentTypesEnum, ProdCycleProdComponentTypeMixin } from '../../models/prod-cycle/prodCycleProd-model';

const YesNo = {
	true: 'Да',
	false: 'Нет'
};

// const ComponentType = {
// 	main: 'Важный-обязательный (образует партию, расходуется обязательно)',
// 	footprintless: 'Обязательный (НЕ образует партию, расходуется обязательно)',
// 	optional: 'Опциональный (не образует партию, может не расходоваться)'
// };

const ModifyOnlyByInteger = {
	true: 'Списывается только целыми числами',
	false: 'Списывается целыми и дробными числами'
};

export const AddProduceCycleProductModel = EditableProperties(Model).extend({
	properties: {
		productId: {
			controlType: 'select-api',
			modelType: 'single',
			multiple: false,
			// sourceValues: () => new bus.request('products:search'),
			controlOptions: () => ({
				fetchAtStart: false,
				apiSearch: true,
				collection: bus.request('new:products:search')
				// ((Collection) => new Collection())(bus.request('products:search')),
			}),
			display: {
				label: 'продукция',
				transform (v, d, m) {
					const name = m && m.get('name');
					if (name) {
						return name;
					}
					return (v && bus.request('new:products:model', { id: v }).getLabel()) || undefined;
				},
				ifEmpty: '&mdash;'
			}
		},
		amount: {
			display: {
				label: 'необходимое количество для производства одной единицы',
				ifEmpty: '&mdash;'
			},
			type: 'number'
		},
		produceUnitMeasureId: {
			sourceValues: () => busData.measures(),
			controlType: 'select',
			display: {
				label: 'единица измерения',
				transform: (v) => busData.measures(v, 'one')
			}
		},
		produceUnitsInBaseUnit: {
			display: {
				label: 'кол. производственных единиц в учётной',
				ifEmpty: '&mdash;'
			},
			type: 'number'
		},
		componentType: {
			type: 'enum',
			sourceValues: ProdCycleProdComponentTypesEnum,
			edit: {

				applyValue (model, property, value) {
					model.setComponentType(value);
					/*
					let upd = {
						optional: false,
						footprintless: false
					}
					if (value === 'optional') {
						upd.optional = true;
					} else if (value === 'footprintless') {
						upd.footprintless = true;
					}
					model.set(upd);
					*/
				}
			},
			display: {
				label: 'тип компонента',
				transform (v) {
					const fullName = true;
					return this.getComponentTypeName(fullName);
					/*
					let { optional, footprintless } = this.attributes;
					if (optional) {
						return ComponentType.optional;
					} else if (footprintless) {
						return ComponentType.footprintless;
					}
					return ComponentType.main;
					*/
				}
				// : (v) => ComponentType[v || 'main'],
			}
		},
		optional: {
			type: 'boolean',
			sourceValues: YesNo,
			display: {
				label: 'опциональный компонет (не образует партию, может не расходоваться)',
				transform: (v) => YesNo[v || false]
			}
		},
		footprintless: {
			type: 'boolean',
			sourceValues: YesNo,
			display: {
				label: 'не образует партию (расходуется обязательно)',
				transform: (v) => YesNo[v || false]
			}
		},
		modifyOnlyByInteger: {
			type: 'boolean',
			sourceValues: ModifyOnlyByInteger,
			display: {
				label: 'Как можно списывать компонент',
				transform: (v) => ModifyOnlyByInteger[v || false]
			}
		},
		order: {
			type: 'number',
			display: {
				label: 'Порядок сортировки'
			}
		}
		// storeUnitMeasureId: {
		// 	display: {
		// 		label: ''
		// 	}
		// },
		// amount: {
		// 	display:{
		// 		label:'Количество',
		// 		ifEmpty: '&mdash;'
		// 		//transform: (v,o,m) => v || (m && m.get('fullName'))
		// 	},
		// 	label: 'Количество',
		// 	type: 'number',
		// }
	},
	...ProdCycleProdComponentTypeMixin
});



const addDefs = { editButton: true, addClass: 'whole-btn', textAction: 'edit' };
const addOpts = function (...args) {
	return Object.assign({}, addDefs, ...args);
};


const BaseChange = ControlMixin(ModelProperties);

export const AddProduceCycleProduct = BaseChange.extend({
	initialize () {
		const hash = this.getOption('hash') || {};
		this.model = new AddProduceCycleProductModel(hash);
		this.once('render', () => this.triggerChange());
	},
	className: 'card lined with-border add-produce-cycle',
	isProductIdEnabled () {
		return this.getOption('mode') !== 'edit';
	},
	properties: (m, v) => ([
		v.isProductIdEnabled() ? h.ui('productId', addOpts()) : null,
		h.ui('amount', addOpts()),
		h.ui('produceUnitMeasureId', addOpts()),
		h.ui('produceUnitsInBaseUnit', addOpts()),
		h.ui('componentType', addOpts()),
		h.ui('modifyOnlyByInteger', addOpts()),
		h.ui('order', addOpts())
		// h.ui('optional', addOpts()),
		// h.ui('footprintless', addOpts()),
	]),
	getValue () {
		return this.model.toJSON();
		// return _.extend({joinAs:'purchase'}, this.model.toJSON());
	},
	modelEvents: {
		'change' () {
			this.triggerChange();
		}
	},
	validate (value) {
		return (value && value.productId && value.amount > 0 && value.produceUnitMeasureId && value.produceUnitsInBaseUnit > 0) || false;
		// if (this.type === 'contragent') {
		// 	return value.contragentId != null; //(value.childId && value.production && value.production.amount);
		// } else if (this.type === 'product') {
		// 	return value.productId != null;
		// }
		// return false;
	}
});

export const EditProduceCycleProduct = BaseChange.extend({
	initialize () {
		this.once('render', () => this.triggerChange());
	},
	className: 'card lined with-border add-produce-cycle',
	properties: () => ([
		h.ui('productId', addOpts()),
		h.ui('amount', addOpts()),
		h.ui('produceUnitMeasureId', addOpts()),
		h.ui('produceUnitsInBaseUnit', addOpts())
	]),
	getValue () {
		return this.model.toJSON();
		// return _.extend({joinAs:'purchase'}, this.model.toJSON());
	},
	modelEvents: {
		'change' () {
			this.triggerChange();
		}
	},
	validate (value) {
		return (value && value.productId && value.amount > 0 && value.produceUnitMeasureId && value.produceUnitsInBaseUnit > 0) || false;
		// if (this.type === 'contragent') {
		// 	return value.contragentId != null; //(value.childId && value.production && value.production.amount);
		// } else if (this.type === 'product') {
		// 	return value.productId != null;
		// }
		// return false;
	}
});
