import bus from 'bus/models';

const defaultFilter = {
	nestedModel () {
		if (this._nestedModelInitialized) {
			return;
		}
		const id = this.getValue();
		if (id) {
			const model = bus.request('new:products:model', { id });
            // model.fetchIfNot().then(ok => console.log('fetchIfNot OK',ok), err => console.log('fetchIfNot ERR', err));
			this.listenToOnce(model, 'change', () => this.trigger('change', this));
			this._nestedModelInitialized = model;
		}
	},
	valueLabel (id) {
		return bus.request('new:products:model', { id }).display('fullName');
	}
};
const defaultValuePrototype = {
	controlType: 'select-api',
	modelType: 'single',
	multiple: false,
	sourceValues: () => {
		const col = bus.request('new:products:search');
		col.fetchIfNot();
		return col;
	}
};
export function buildProductSelectFilter (userData = {}) {
	const userValuePrototype = userData.valuePrototype;
	const filter = {
		...defaultFilter,
		...userData,
		valuePrototype: {
			...defaultValuePrototype,
			...userValuePrototype
		}
	};
	return filter;
}
