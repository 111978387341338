import DynamicClass from 'mixins/view/dynamic-class';
import mix from 'helpers/mix';

export default (Base) => {
	const Mixed = mix(Base).with(DynamicClass);
	Mixed.SelectItemMixin = true;

	return Mixed.extend({
		constructor () {
			Mixed.apply(this, arguments);
			this.cssClassModifiers = (this.cssClassModifiers || []).slice();
			this.cssClassModifiers.push(
				(m, v) => v.isSelected() ? 'selected' : ''
			);
			// v.isSelected() ? 'selected' : ''
		},
		isSelected () {
			const sl = this.getOption('selector');
			const res = sl.isSelected(this);
			return res;
		},
		selectButton: true
	});
};
