import BasePage from 'pages/auth';
import Layout from './layout';
import Model from 'mod/contragents/models/model';
import ProcessesPage from './processes';
import TasksPage from './tasks';
import BuyProductsPage from './buy-products';
// import Emps from './emps';
// import Deps from './deps';

const Page = BasePage.extend({
	name: 'contragent page',
	label () { return 'Контрагент: ' + ((this.model && this.model.display('name')) || ''); },
	route: ':id',
	skipMenu: true,
	Layout,
	wrapLayout: true,
	children: [
		ProcessesPage,
		TasksPage,
		BuyProductsPage
	],
	socketGroups: (m) => 'contragent:' + m.id.toString().toLowerCase(),
	onBeforeStart (ac) {
		const model = new Model({ id: ac.args.id });
		this.addModel(model);
		this.addStartPromise(model.fetch());
	}
});

export default Page;
