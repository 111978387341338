import AppError from 'base/error';

const PageError = AppError.extend({}, {
	Status (status, message, fragment) {
		return new this({ status, message, fragment });
	},
	NotFound (message, fragment) {
		return this.Status(404, message, fragment);
	},
	NotAuthorized (message, fragment) {
		return this.Status(401, message, fragment);
	},
	Denied (message, fragment) {
		return this.Status(403, message, fragment);
	},
	Redirect (message) {
		return this.Status('redirect', message);
	},
	Token (message) {
		return this.Status('token', message);
	},
	Xhr (xhr, message) {
		return this.Status(xhr.status, message);
	}
});

export default PageError;
