import UiListItem from 'components/ui-list/item';
import { _meas } from './common';
import { _ } from 'vendors';
// import busData from 'bus/data';
import smartOpen from 'helpers/smart-open';
import { productPageCardUrl } from 'app/constants';
import { UiMoleculeView } from '../../../../../../components/ui2/molecule';
import { Ui2AtomView } from '../../../../../../components/ui2/atom';
import { componentSchema } from './componentSchema';
import { propertySchemaApi } from '../../../../../../apis/schema';
// console.error('OPEN CARD', productPageCardUrl);

export const _ComponentView = UiListItem.extend({
	addClass: 'contragent-component',
	text: m => m.get('name'),

	getProcess () {
		return this.getOption('process');
	},
	getRawParentProduct () {
		return this.getProcess().get('product');
	},
	getRawCycle () {
		return this.getProcess().get('productionCycle');
	},
	isConcrete () {
		return !this.model.get('isAbstractGroup');
	},
	getConcreteRightItems () {
		const m = this.model;
		const parentProduct = this.getRawParentProduct();
		const b_meas = _meas(m.get('baseUnitMeasureId'));
		const cycle = this.getRawCycle();
		const c_amount = m.get('contragentAmount'); // in base units
		const c_amount_d = _.displayNum(c_amount, 4) + ' ' + b_meas;
		const s_amount = m.get('storeAmount');
		const s_amount_d = _.displayNum(s_amount, 4) + ' ' + b_meas;
		const amount = m.get('amount') || 0;
		const prodUnitsInBaseUnit = m.get('produceUnitsInBaseUnit');

		const p_amount = c_amount / (amount / prodUnitsInBaseUnit) * cycle.baseUnitsInProductionUnit;
		// this.getRawCycle().baseUnitsInProductionUnit;

		const p_amount_d = _.displayNum(p_amount, 0, { round: 'floor' }) + ' ' + _meas(parentProduct.unitMeasureId);
		return [
			{
				template: '<div class="amount"><b>хватит на</b><span title="хватит на производство такого объема"><%= p_amount_d %></span></div>',
				templateContext: {
					p_amount,
					p_amount_d
				}
			},
			{
				template: '<div class="amount"><b>у кон-та</b><span title="у контрагента на руках"><%= c_amount_d %></span></div>',
				templateContext: {
					c_amount,
					c_amount_d
				}
			},
			{
				template: '<div class="amount"><b>на складе</b><span title="на складе"><%= s_amount_d %></span></div>',
				templateContext: {
					s_amount,
					s_amount_d
				}
			}
		];
	},
	rightItems () {
		if (this.isConcrete()) {
			return this.getConcreteRightItems();
		} else {
			return [];
		}
	},
	rightButtons () {
		const m = this.model;
		const c_amount = m.get('contragentAmount');
		const s_amount = m.get('storeAmount');
		return [
			{ class: 'withdraw' + (s_amount ? '' : ' disabled'), icon: true, title: 'выдать' },
			{ class: 'take' + (c_amount ? '' : ' disabled'), icon: true, title: 'забрать' }
		];
	},
	leftButtons: [
		{
			class: 'open-card', icon: true, 'data-trigger': 'open:product:card', title: 'открыть страницу продукции в новой вкладке'
		}
	],
	triggers: {
		'click .withdraw:not(.disabled)': 'withdraw',
		'click .take:not(.disabled)': 'take'
	},
	onOpenProductCardClick (arg1, arg2, event) {
		const productId = this.model.id;
		const options = {
			ctrlKey: !event.ctrlKey
		};
		const url = productPageCardUrl + '/' + productId;
		smartOpen(url, options);
	}
});


const schemaMixin = {
	getModelSchema () {
		return this.getOption('modelSchema', true);
	},
	display (key) {
		const schema = this.getModelSchema();
		const value = propertySchemaApi.displayValue(key, this.model, schema);
		return value;
	}
};

const ChildView = Ui2AtomView.extend({
	...schemaMixin
});

export const ComponentView = UiMoleculeView.extend({
	thisClassName: 'contragent-component-2',
	childView: ChildView,
	customViewOptions () {
		return {
			model: this.model,
			modelSchema: componentSchema
		};
	},
	openCardButton: v => productPageCardUrl + '/' + v.model.id,
	content: {
		thisClassName: 'elastic product',
		text: v => {
			let name = v.model.get('name');
			if (v.model.get('isAbstractGroup')) {
				name = `[${name}]`;
			}
			return name;
		},
		bottomText: v => {
			const isAbstract = v.model.get('isAbstractGroup');
			if (!isAbstract) { return ''; }
			const concretes = v.model.get('concretes') || [];
			return 'варианты: ' + concretes.length;
		}
	},
	rightItems: [
		{
			thisClassName: 'amount fixed enough-for',
			text: v => v.display('enoughFor'),
			bottomText: 'хватит на'
		},
		{
			thisClassName: 'amount fixed at-contragent',
			text: v => v.display('contragentAmount'),
			bottomText: 'выдано'
		},
		{
			thisClassName: 'amount fixed at-store',
			text: v => v.display('storeAmount'),
			bottomText: 'на складе'
		}
	],
	rightButtons: [
		'withdraw:plus', 'take:minus'
	],
	childViewTriggers: {
		'withdraw:click': 'withdraw',
		'take:click': 'take'
	}
});
