import { _ } from 'vendors';
import View from 'base/view';
import template from './template.html';
import beh from 'behaviors';
import validate from 'helpers/validate';
import modals from 'helpers/modals';
import user from 'app/user';
import bus from 'bus/app';
import paths from 'helpers/paths';
const rule = {
	email: {
		required: {
			message: 'укажите свой email'
		}
	},
	password: {
		required: {
			message: 'укажите новый пароль'
		},
		validators: [
			{
				func: (v, hash) => v === hash.passwordConfirm,
				message: 'пароль и подтверждение пароля не совпадают'
			}
		]
	}
};

export default View.extend({

	template,
	className: 'card maxw600px margin-t',
	behaviors: [beh.FormToHash],
	onValuesApply (hash) {
		hash.code = this.actionContext.qs.code;

		const errors = validate(hash, rule);
		if (errors) {
			const messages = _(errors).pluck('message').join('<br/>');
			modals.error(messages);
		} else {
			user.post({
				url: 'passwordRecovery/end',
				method: 'post',
				data: hash,
				preloader: true
			}).then(
				() => {
					modals.message('Отлично, пароль изменён');
					bus.navigate(paths.url('acc:login'));
				},
				() => modals.error('Что-то пошло не так, перепроверьте всё и попробуйте ещё раз')
			);
		}
	}
});
