import CollapasableBlock from './collapasable-block';
import CollectionView from 'base/collection-view';
import Collection from 'base/collection';
import { stringComparator } from './common';
import { ProcessView } from './ProcessView';
// import View from 'base/view';
// import { ClickableBehavior } from 'behaviors';
// import smartOpen from 'helpers/smart-open';
// import { processPageBaseUrl } from 'mods/processes/cfg';
// import user from 'app/user';
// import busData from 'bus/data';



const ProductProcesses = CollectionView.extend({
	initialize () {
		const models = this.getModels();
		this.collection = new Collection(models);
	},
	getModels () {
		const processes = this.model.get('productionProcesses');
		return processes;
	},
	childView: ProcessView,
	viewComparator: (v1, v2) => stringComparator([v => v.model.get('product').name, v => v.model.get('owner').name, v => v.model.get('name')], v1, v2)
});

export default CollapasableBlock.extend({
	addClas: 'processes',
	header: 'Процессы производства',
	buildContentView () {
		const view = new ProductProcesses({ model: this.model });
		return view;
	}
});
