import { _ } from 'vendors';
import ModelProperties from 'components/ui-model-card/properties';
import h from 'components/ui-model-card/helpers';
import { ProductContragentModel } from '../models/product-contragent';
import ControlMixin from 'components/controls/mixin';

import CollectionView from 'base/collection-view';
import UiItem from 'components/ui-list/item';
import busData from 'bus/data';
import busViews from 'bus/views';



import PopoverBehavior from 'behaviors/popover';

const InfoBehavior = PopoverBehavior.extend({
	popoverViewCssClass: 'popover-common max-width-400px',
	// showOn:'click',
	content () {
		return new DetailedInfo({
			model: this.view.model
		});
	}
});

const defs = { destroyOnChange: false }; // { addClass:'whole-btn', textAction:'edit' };
export const DetailedInfo = ModelProperties.extend({
	isInsideCollection () {
		return this.getOption('inCollectionView');
	},
	className: 'props-place card lined with-border transportpoint-props',
	properties: () => ([
		h.ui('details.pricePerUnit', { ...defs, third: true }),
		h.ui('details.nds', { ...defs, third: true }),
		h.ui('details.priceCurrencyId', { ...defs, third: true }),
		h.ui('details.priceUnitMeasureId', { ...defs, third: true }),
		h.ui('details.priceUnitsInPurchaseUnit', { ...defs, third: true }),
		h.ui('details.unitMeasureId', { ...defs, third: true }),
		// h.ui('details.purchaseUnitToStoreUnit', { ...defs }),
		// h.ui('details.priceInfo', { ...defs }),
		h.ui('modified', { ...defs })
	])
});


// import busModels from 'bus/models';

function initModel (model) {
	if (model) return;
	this.model = new ProductContragentModel();
}

const addDefs = { editButton: true, addClass: 'whole-btn', textAction: 'edit' };
const BaseChange = ControlMixin(ModelProperties);

export const AddJoined = BaseChange.extend({
	constructor (opts) {
		this.type = opts.type;
		this.parentType = opts.parentModelType;
		this.parentModel = opts.parentModel;
		initModel.call(this, this.model || opts.model, this.parent || opts.parent);
		BaseChange.apply(this, arguments);
		if (this.parentType === 'product') {
			this.updateMeasures(this.parentModel);
		}
		this.on('render', () => this.trigger('content:ready'));
		// this.on('all', e => console.log('# event: ', e));
	},
	onEditPropertyProductId (id) {
		console.log('# on productId');
		const found = busData.request('from:products:search', id);
		if (found) {
			this.updateMeasures(found);
		}
		// console.log('# args', ...arguments);
	},
	updateMeasures (productModel) {
		let unitMeasureId = productModel.get('unitMeasureId');
		const purchaseUnitMeasureId = productModel.get('purchaseUnitMeasureId');
		let priceUnitMeasureId;
		if (purchaseUnitMeasureId != null) {
			priceUnitMeasureId = unitMeasureId;
			unitMeasureId = purchaseUnitMeasureId;
		}

		this.model.set('details.unitMeasureId', unitMeasureId);
		this.model.set('details.priceUnitMeasureId', priceUnitMeasureId);

		console.log('# prod mod', productModel.attributes);
	},
	className: 'card lined with-border add-product-contragent',
	properties: (m, v) => ([
		v.type === 'contragent' && h.ui('contragentId', _.clone(addDefs)),
		v.type === 'product' && h.ui('productId', _.clone(addDefs)),

		h.ui('details.pricePerUnit', { ...addDefs, third: true }),
		h.ui('details.nds', { ...addDefs, third: true }),
		h.ui('details.priceCurrencyId', { ...addDefs, third: true }),
		h.ui('details.priceUnitMeasureId', { ...addDefs, third: true }),
		h.ui('details.priceUnitsInPurchaseUnit', { ...addDefs, third: true }),
		h.ui('details.unitMeasureId', { ...addDefs, third: true })

		// h.ui('details.price', _.clone(addDefs)),
		// h.ui('details.priceInfo', _.clone(addDefs)),
		// h.ui('details.unitMeasureId', _.clone(addDefs)),
		// h.ui('details.purchaseUnitToStoreUnit', _.clone(addDefs)),
	]),
	getValue () {
		return _.extend({ joinAs: 'purchase' }, this.model.toJSON());
	},
	modelEvents: {
		'change' () {
			this.triggerChange();
		}
	},
	validate (value) {
		if (this.type === 'contragent') {
			return value.contragentId != null; // (value.childId && value.production && value.production.amount);
		} else if (this.type === 'product') {
			return value.productId != null;
		}
		return false;
	}
});
busViews.reply('add:productContragent', () => AddJoined);

export const EditJoined = BaseChange.extend({
	constructor (opts) {
		initModel.call(this, this.model || opts.model, this.parent || opts.parent);
		BaseChange.apply(this, arguments);
		this.on('render', () => this.trigger('content:ready'));
	},
	className: 'card lined with-border add-product-contragent',
	properties: [
		h.ui('details.pricePerUnit', _.extend({}, addDefs, { third: true })),
		h.ui('details.nds', _.extend({}, addDefs, { third: true })),
		h.ui('details.priceCurrencyId', _.extend({}, addDefs, { third: true })),
		h.ui('details.priceUnitMeasureId', _.extend({}, addDefs, { third: true })),
		h.ui('details.priceUnitsInPurchaseUnit', _.extend({}, addDefs, { third: true })),
		h.ui('details.unitMeasureId', _.extend({}, addDefs, { third: true }))
		// h.ui('details.priceInfo', _.clone(addDefs)),
		// h.ui('details.purchaseUnitToStoreUnit', _.clone(addDefs)),
	],
	getValue () {
		return _.extend({ joinAs: 'purchase' }, this.model.toJSON());
	},
	modelEvents: {
		'change' () {
			this.triggerChange();
		}
	}
	// validate(value){
	// 	return value.contragentId != null; //(value.childId && value.production && value.production.amount);
	// }
});
busViews.reply('edit:productContragent', () => EditJoined);
// busModels.reply('products:joinedContragentEditView', () => EditJoined);



const JoinedContragentView = UiItem.extend({
	behaviors: [InfoBehavior],
	cssClassModifiers: [
		() => 'whole-btn',
		() => 'product-contragent',
		(m, v) => v.getOption('type')
	],
	text: m => m.getLabel(),
	closeButton: () => busData.user().checkRights({ resourcesProduction: 'productsEdit' }),
	leftButtons: [
		{
			icon: true,
			class: 'info'
		}
	],
	rightItems: [
		{
			template: _.template(`
				<span title="стоимость учётной единицы">
					<i>цена учётной</i>
					<span><%= unitPrice %> <small><%= currencySign %></small></span>
					<small>за <%= unit %></small>
				</span>
				<span title="количество учетных единиц в 1 закупочной">
					<i>соотношение</i>
					<% if (!noPriceUnit) { %>
					<span><%= unitsInPriceUnit %> <small><%= unit %></small></span><small>в 1 <%= priceUnit %></small>
					<% } %>
				</span>				
				<span title="стоимость закупочной единицы">
					<i>цена закупочной</i>
					<% if (!noPriceUnit) { %>
					<span><%= priceUnitPrice %> <small><%= currencySign %></small></span><small><%= priceUnit %></small>
					<% } %>
				</span>
			`),
			templateContext () {
				const unit = this.model.get('details.unitMeasureId');
				const priceUnit = this.model.get('details.priceUnitMeasureId');
				let noPriceUnit = false;
				if (priceUnit == null || unit === priceUnit) {
					noPriceUnit = true;
				}

				const pricePerUnit = this.model.get('details.pricePerUnit');
				const unitsInPriceUnit = this.model.get('details.priceUnitsInPurchaseUnit') || 1;
				const priceUnitPrice = unitsInPriceUnit * pricePerUnit;
				const unitPrice = pricePerUnit;

				const currency = this.model.get('details.priceCurrencyId') || 'rub';
				const currencySign = busData.currencies(currency, 'sign');
				const m = v => busData.measures(v, 'short');
				return {
					priceUnit: m(priceUnit),
					priceUnitPrice,
					unit: m(unit),
					unitPrice,
					currencySign,
					noPriceUnit,
					unitsInPriceUnit
					// priceUnitPrice,
					// priceUnit: busData.measures(priceUnit, 'short') || 'ед.',
					// purchaseUnitPrice,
					// purchaseUnit: busData.measures(purchaseUnit, 'short') || 'ед.',
					// priceInPurchase,
					// noPurchaseUnit
				};
			}
		}
		// {
		// 	template: _.template(`
		// 		<span title="стоимость складской единицы">
		// 			<span><%= price %> <small>р.</small></span><small>за <%= unit %></small></span>
		// 	`),
		// 	templateContext(){
		// 		let koef = parseFloat(this.model.get('details.purchaseUnitToStoreUnit') || 1, 10);
		// 		let buyPrice = this.model.getPrice();
		// 		let price = buyPrice / koef;
		// 		if (isNaN(price)) {
		// 			price = '&mdash;';
		// 		}
		// 		let unit = '';
		// 		return {
		// 			price,
		// 			unit: busData.measures(unit, 'one') || 'единицу',
		// 		};
		// 	}
		// }
	],

	onCloseClick () {
		this.model.executeAction('remove:joined:contragent');
	},
	onTextClick () {
		this.model.executeAction('edit:joined:contragent');
	},
	onInfoClick (event) {
		this.model.openEntity(this.model.collection.type, event);
	}
});

const JoinedContragentsView = CollectionView.extend({
	childView: JoinedContragentView,
	emptyView: UiItem.Empty
});

export {
	JoinedContragentView,
	JoinedContragentsView
};
