import Model from 'base/model';
import user from 'app/user';
import paths from 'helpers/paths';
import { productCycleProperties } from 'mod/productionProducts/views/prod-cycle/add';
import EditableProperties from 'mixins/editable-properties';
export const productProdCycleUrlPattern = 'res/prod/products/:productId/prod-cycles';
export const productProdCycleUrlPatternFull = paths.urls.api(productProdCycleUrlPattern);


export const ProdCycleModel = EditableProperties(Model).extend({

	...productCycleProperties(),

	urlRootPattern: productProdCycleUrlPattern,
	pageUrlPattern: '/res/prod/products/:productId/prod-from/:thisId',

	isStarted () {
		return this.get('isStarted');
	},
	isMy () {
		return user.isMe(this.get('responsibleId'));
	},
	isByMe () {
		return user.isMe(this.get('creatorId'));
	},
	canBeEdited () {
		return (this.isMy() || this.amIGlobalAdmin()) && !this.isStarted();
	},
	amIGlobalAdmin () {
		return user.checkRights({ resourcesProduction: 'productsAdmin' });
	},
	amIAdmin () {
		return this.isMy() || this.isByMe() || this.amIGlobalAdmin();
	}

});
