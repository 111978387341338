import View from 'base/view';
import template from './template.html';

import ModelProperties from 'components/ui-model-card/properties';
import h from 'components/ui-model-card/helpers';


export default View.extend({
	template,
	initialize () {
		this.createNested('main', {
			viewClass: ModelProperties.extend({ className: 'card lined with-border' }),
			viewOptions: {
				header: { text: 'свойства', addClass: 'bg-main-dark' },
				properties: [
					h.ui('host', { editButton: true }),
					h.ui('port', { editButton: true }),
					h.ui('login', { editButton: true }),
					h.ui('pass', { editButton: true }),
					h.ui('enableSsl', { editButton: true })
				]
			},
			regionTemplate: { replaceElement: true }
		});
	},
	onRender () {
		this.showAllNested();
	}
});
