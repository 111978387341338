import { HamburgerView } from 'base/views';
import { tableRowCommonMixin } from './common';
import { UiTableCellView } from './UiTableCellView';

const mixin = {
	cellView: UiTableCellView,
	customViewOptions () {
		const cellOptions = this.getOption('cellOptions') || {};
		return {
			model: this.model,
			onlyHeaders: this.getOption('onlyHeaders', true),
			tableConfig: this.getOption('tableConfig', true),
			tableSchema: this.getOption('tableSchema', true),
			...cellOptions
		};
	},
	getCustomViews () {
		const cells = this.getCells();
		return cells.map(cell => this.getCellView(cell));
	},
	getCells () {
		return this.getOption('cells') || [];
	},
	getCellView (cell) {
		if (typeof cell === 'string') {
			return this.getCellViewById(cell);
		} else {
			return cell;
		}
	},
	getCellViewById (id) {
		const tableSchema = this.getOption('tableSchema', true);
		let cell = tableSchema[id];
		if (!cell) { return cell; }


		cell = Object.assign({}, cell);

		if (cell.class == null) {
			cell.class = this.getOption('cellView', { force: false });
		}

		return cell;
	}
};

export const UiTableRow = HamburgerView.extend({

	...tableRowCommonMixin,
	...mixin,

	customViewsContainer () {
		if (this.hasMolecule()) {
			return '.ui2-molecule';
		}
	},

	template: `<% if (wrapper) {%><div class="wrapper"><% } %>
<% if (molecule) {%><div class="ui2-molecule"></div><% } %>
<% if (wrapper) {%></div><% } %>`,

	hasWrapper () {
		return this.getOption('useWrapper') === true;
	},
	hasMolecule () {
		return this.getOption('useMolecule') !== false;
	},
	templateContext () {
		return {
			wrapper: this.hasWrapper(),
			molecule: this.hasMolecule()
		};
	}


});


export const UiTableRowSimple = HamburgerView.extend({
	...tableRowCommonMixin,
	...mixin
});
