import DefaultPage from './default';
import PageErr from 'pages/error';
// import user from 'app/user';
import busData from 'bus/data';
// import appData from 'mod/appData';

const AuthPage = DefaultPage.extend({

	employeeNeeded: true,
	preventStart: function () {
		if (busData.user().isAnonym() || (this.employeeNeeded && !busData.user().isEmployeed())) { return PageErr.NotAuthorized('Вы не авторизованы', this.url()); } else if (!this.isAvailable()) { return PageErr.NotAuthorized('Этот раздел вам недоступен', this.url()); }
	}
});

export default AuthPage;
