import { _ } from 'vendors';
import model from './model';
import Collection from 'base/collection';
export default Collection.extend({

	model,
	toUrl () {
		return this.reduce(
			(memo, model) => _.extend(memo, model.toUrl())
			, {}
		);
	},
	toFilter () {
		return this.reduce(
			(memo, model) => _.extend(memo, model.toFilter())
			, {}
		);
	},
	applyQueryStringObject (hash) {
		_.each(hash, (value, key) => {
			this.applyQsValue(key, value);
		});
	},
	applyObject (hash) {
		_(hash).each((value, key) => {
			this.applyValue(key, value);
		});
	},
	applyQsValue (key, value) {
		const item = this.find((m) => m.getUrlKey() === key);
		item && item.setValues(value);
	},
	applyValue (key, value) {
		const item = this.find((m) => m.get('valueId') === key);
		item && item.setValues(value);
	}
});
