import proto from 'components/values/get-prototype';
// import enums from 'helpers/enums';

const filtersPrototypes = [
	{
		id: 'measure:text',
		valueId: 'text',
		label: 'поиск по ключевому слову',
		hidden: true,
		valuePrototype: proto('text'),
		textSearch: true
	}
];

export default filtersPrototypes;
