import '../api-config';
import Collection from 'base/collection';
import BaseModel from 'base/card-model';
import paths from 'helpers/paths';
// import BbStore from 'base/bb-store';
// import mix from 'helpers/mix';
// import EditableProperties from 'mixins/editable-properties';
// import Actionable from 'mixins/actionable';
// import action from 'helpers/action';
import busData from 'bus/data';
// const Model = mix(BaseModel).with(EditableProperties).extend({
// 	urlRoot: paths.api('admin:configs'),
// });

export const RequestTypeEmployee = BaseModel.extend({
	url () {
		return paths.api('admin:taskRequests') + '/' + this.get('requestFormId') + '/' + this.id;
	},
	idAttribute: 'responsibleId',
	properties: {
		responsibleId: {
			sourceValues: () => busData.employees(),
			controlType: 'employeesSelect',
			display: {
				label: 'ответственный',
				ifEmpty: ' - ',
				transform: (v) => {
					console.log('privaet');
					return busData.employees(v).display('name');
				}
			}
		}
	}
});

export const RequestType = BaseModel.extend({
	cardUrlRoot: paths.url('admin:taskRequests'),
	urlRoot: paths.api('admin:taskRequests'),
	actions: [
		// action('edit','редактировать'),
	]
});

export const RequestTypes = Collection.extend({
	url: paths.api('admin:taskRequests'),
	model: RequestType
});


export const requestTypes = new RequestTypes();

// export const requestTypes = [
// 	new RequestType({
// 		id: 'requestTransport',
// 		name: 'заявка на транспорт'
// 	}),
// 	new RequestType({
// 		id: 'requestPurchase',
// 		name: 'заявка на закупку'
// 	}),
// ];
