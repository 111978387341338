import ModelProperties from 'components/ui-model-card/properties';
import ControlMixin from 'components/controls/mixin';
import h from 'components/ui-model-card/helpers';

const buttonDefs = { editButton: 'true', addClass: 'whole-btn', textAction: 'edit', third: true };

export const InputMeasuredValueView = ControlMixin(ModelProperties).extend({
	initialize () {
		this.once('render', () => this.triggerChange());
	},
	className: 'card lined with-border',
	properties: (m, v) => {
		return [
			h.ui('amount', buttonDefs),
			h.ui('inputMeasureId', buttonDefs),
			h.ui('baseUnitsInInputUnit', buttonDefs),
			h.ui('calculatedAmount', { addClass: 'bg-lightorange' }),
			m.get('maximumAmountInBaseUnits')
				? h.ui('maximumAmount', {
					addClass: 'whole-btn',
					onTextClick () {
						const m = this.model;
						const attrs = m.attributes;
						const { maximumAmountInBaseUnits, baseMeasureId } = attrs;
						this.model.set({
							amount: maximumAmountInBaseUnits,
							inputMeasureId: baseMeasureId,
							baseUnitsInInputUnit: 1
						});
					}
				})
				: null
		];
	},
	getValue () {
		return this.model.toJSON();
	},
	modelEvents: {
		'change' () {
			this.triggerChange();
		}
	},
	validate (value) {
		if (!value) return false;
		let { amount, inputMeasureId, baseMeasureId, baseUnitsInInputUnit } = value; // eslint-disable-line
		amount = parseFloat(amount, 10);
		const amountPositive = !isNaN(amount) && amount > 0;
		const res = amountPositive && inputMeasureId && baseUnitsInInputUnit > 0;
		return res;
	}
});
