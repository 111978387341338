import { _, $ } from 'vendors';
import View from 'base/view';
import modals from './base-modals';

modals.addTypeConfig('confirm', {
	css: {
		wrapper: 'yat-modal-wrapper confirm'
	},
	show: {
		bg: true,
		close: true,
		resolve: true,
		reject: true,
		header: false
	},
	options: {
		closeOnClickOutside: true,
		closeOnPromise: true,
		preventClose: false,
		asPromise: true
	}
});


export default function confirm (message = 'подтвердите действие', resolve = 'так точно', reject = 'отставить') {
	return modals.show(message, {
		// header: 'подтвердите действие',
		// addBodyCssClass: 'blur-not-last',
		type: 'confirm',
		returnPromise: true,
		labels: {
			resolve, reject
		}
	});
}


const ConfirmReason = View.extend({
	template: _.template(`
	<div><%= message %></div>
	<div class="reason-container">
		<div class="reason-label"><%= label %>:</div>
		<textarea><%= defaultValue %></textarea>
	</div>
`),
	events: {
		'input textarea' (e) {
			const value = $(e.target).val();
			if (this._parentModal) {
				this._parentModal.resolve = value;
			}
		}
	},
	onBeforeModalIn (modal) {
		this._parentModal = modal;
		this._parentModal.resolve = this.getOption('defaultValue');
	},
	onBeforeDestroy () {
		delete this._parentModal;
	},
	templateContext () {
		return {
			message: this.getOption('message'),
			label: this.getOption('label') || 'пояснение',
			defaultValue: this.getOption('defaultValue') || ''
		};
	}
});

export function confirmReason (message = 'подтвердите действие', resolve = 'так точно', reject = 'отставить') {
	let options;
	if (resolve && typeof resolve === 'object') {
		options = resolve;
		if (options.reject) {
			reject = options.reject;
		}
		resolve = options.resolve ? options.resolve : 'так точно';
	}

	options = options || {};

	const view = new ConfirmReason({
		message,
		...options
	});
	return modals.show(view, {
		// header: 'подтвердите действие',
		// addBodyCssClass: 'blur-not-last',
		type: 'confirm',
		returnPromise: true,
		labels: {
			resolve, reject
		}
	});
}
