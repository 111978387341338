import { _ } from 'vendors';
import { Behavior } from 'behaviors/behavior';
import { busFiles } from './bus';

export const DropFilesBehavior = Behavior.extend({
	events: {
		drop (event) {
			const dt = event.originalEvent.dataTransfer;
			if (!dt || !dt.items) return;

			event.preventDefault();
			event.stopPropagation();

			const files = _.reduce(dt.items, (memo, item) => {
				memo.push(item.getAsFile());
				return memo;
			}, []);
			busFiles.trigger('drop', files);
			// console.log('triggered drop');
		},
		dragover (e) {
			e.preventDefault();
		}
	}
});
