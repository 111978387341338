import _ from 'underscore';
import App from './YatApp.js';
import mixin from './helpers/mix';
import Mx from './mixins/Mixins';
import Startable from './mixins/startable';
import GetNameLabel from './mixins/get-name-label';
import LinkModel from './models/link';
//import Bb from 'backbone';
import identity from './singletons/identity';
import Router from './Router';







/*
	PageRouter
*/

const PageRouter = Router.extend({
	classicMode:false,
	isRouterHoldsActions : false,
	isRouteChaining: false,	
	processCallback(actionContext, routeType){

		routeType || (routeType = 'route');
		this.triggerRouteEvents(routeType, actionContext.name, actionContext);
		return actionContext.page.start(actionContext).catch((error, ...args) => {

			this.processStartError(actionContext, error);

		});
	},
	processStartError(actionContext, error){
		actionContext.error = error;
		let errorEvents = [this.createErrorEvent(error, actionContext)];
		let customErrors = this.processCustomError(error, actionContext);
		customErrors && !_.isArray(customErrors) && (customErrors = [customErrors]);
		this.triggerErrors(actionContext, errorEvents.concat(customErrors || []));		
	},
	createErrorEvent(risedError, actionContext, name){
		name != null && (name = ':' + String(name));
		!name && (name='');
		let error = {
			event: `error${name}`,
			args: [risedError, actionContext]
		};
		return error;
	},
	processCustomError(error, actionContext){
		let errors = [];
		if(error.status != null){
			errors.push(this.createErrorEvent(error, actionContext, error.status));
		}
		return errors;
	},
	triggerErrors(actionContext, errorEvents){
		//console.log('START ERRORS', errorEvents);
		let page = actionContext.page;
		_(errorEvents).each((errorEvent) => {
			page.trigger(errorEvent.event,  ...(errorEvent.args || []));
		});
	},

	/*
	createActionContext(page, fragment, options = {}){
		let routeContext = this.getContextByFragment(fragment);
		if(page != routeContext.page) return;
		return this._createExecuteActionContext(routeContext, fragment, options);
	},
	*/
});



/* 
	YatPage
*/



const PageLinksMixin = {

	hasLink(){
		return !this.getProperty('skipMenu') && !this.getProperty('preventStart');
	},
	getLink(level, index){
		return this.buildLink(level,index);
	},
	buildLink(level, index){
		if(!this.hasLink()) return;
		let parentId = (this.getParentLink() || {}).id;
		let url = this.url();
		let label = this.getLabel();
		let order = this.getProperty('order') || 0;
		return { id: this.id || this.cid, parentId, url, label, level, index, order };
	},
	getParentLink(){
		let parent = this.getParent();
		if (parent === this) {
			parent = undefined;
		}
		return parent && parent.getLink && parent.getLink();
	},
	getLinks(level = 0, index = 0){
		let link = this.getLink(level, index);
		let result = link ? [link] : [];		
		let sublinks = this._getSubLinks(level);
		return result.concat(sublinks);
	},
	_getSubLinks(level){
		let children = this.getStartableChildren();
		if(!children || !children.length) return [];
		let sublinks = _(children).filter((child) => child.hasLink());
		sublinks = _(sublinks).map((child, index) => child.getLinks(level + 1, index));
		return _.flatten(sublinks);
	},

}

const PageLayoutMixin = {
	getLayout(){
		
		/*
		let currentView = this._layoutView;

		if (opts.rebuild || !currentView || currentView.isDestroyed()) {
			this._layoutView = this._buildLayout();
		}

		return this._layoutView;
		*/
		return this._buildLayout.apply(this, arguments);
	},
	buildLayout(View, options){
		return new View(options);
	},
	_buildLayout(...args){
		let Layout = this.getProperty('Layout');
		
		if (Layout == null) return;
		let opts = _.extend({}, this.getProperty('layoutOptions'));

		if (this.model && !opts.model)
			_.extend(opts, { model: this.model });

		if (this.collection && !opts.collection)
			_.extend(opts, { collection: this.collection });
			
		let options = this.buildLayoutOptions(opts, ...args);
		options.page = this;
		//this._layoutView = 
		return this.buildLayout(Layout, options);
		//return this._layoutView;
	},
	buildLayoutOptions(rawOptions){
		return rawOptions;
	},	
}

const PageRouteMixin = {

	_PageRouter: PageRouter,
	_initializeRouter(){
		this.router = new this._PageRouter();
		this.on('children:init', this._registerRoutes);
	},

	_initializePageRoutes(){
		let rawroutes = this.getProperty('route');
		if(rawroutes == null) return;


		let contexts = this._buildRoutesContext(rawroutes);

		let hash = {};
		let firstRoute, mainRoute;
		_(contexts).each((context) => {
			if(!firstRoute) firstRoute = context;
			if(context.main) mainRoute = context;
			hash[context.route] = context;
		});
		if(!mainRoute) mainRoute = firstRoute;
		this._mainRouteContext = mainRoute;
		this._routesContexts = hash;
	},

	_registerRoutes(){
		//console.log(this.getAllRoutesContexts());
		this.router.addRoutes(this.getAllRoutesContexts());
		// if(!this.router) return;
		// let router = this.router;
		// let reverted = _(this._routesContexts).map((c) => c);
		// _(reverted).each((context) => { 
		// 	//console.log(context);
		// 	router.addRoute(context)
		// });
	},

	_buildRouteContext(raw){
		let page = this;
		let defs = {
			page,
			name: this.name
			//action: (...args) => page.start(...args) 
		};

		if(raw == null) return;

		let ext, rawRoute, id, route;
		if(_.isString(raw)){
			rawRoute = raw;
			ext = {};
		}else if(_.isObject(raw)){
			rawRoute = raw.route;
			ext = raw;
		}

		//id = this.getRoute(rawRoute, {raw:true});
		route = this.getRoute(rawRoute);
		let result = _.extend({}, defs, ext, { route });
		result.rawRoute = result.route;
		//result.pattern = Bb.Router.prototype._routeToRegExp(id);
		return result;
	},

	_buildRoutesContext(raw){
		if(_.isArray(raw))
			return _(raw).map((r) => this._buildRouteContext(r));
		else
			return [this._buildRouteContext(raw)];
	},


	executeRoute(){
		if(this.router){
			this.router.executePage(this);
		}else{
			throw new Error('Page Router not defined:', this.name);
		}
	},

	getRoute(route, opts = {raw:false}){
		
		if(route == null) return this.getMainRoute();

		let relative = this.getProperty('relativeRoute');

		if(!relative) return route;

		let parent = this.getParent();
		let prefix = parent && parent.getRoute && parent.getRoute() || '';

		if(prefix && !/\(?\/\)?$/.test(prefix))
			prefix += '/';

		//prefix && (prefix += '/' );
		return prefix + route;
		// let normalized = this._normalizeRoute(prefix + route);
		// return normalized != '/' ? normalized : '';

	},

	getMainRoute(){
		let route = this._mainRouteContext && this._mainRouteContext.rawRoute;
		return route;
	},	

	_normalizeRoute(route, opts={}){		
		route = route.replace(/\/+/gmi,'/').replace(/^\//,'');
		if(opts.raw == true){
			return route;
		}
		else{
			let res = route.replace(/\(\/\)/gmi,'/').replace(/\/+/gmi,'/');
			return res;
		}
	},

	parseUrl(route, options) {
		if (options && _.isObject(options)) {
			route = route.replace(/(:\w+)/gmi, _key => {
				let value = options[_key.substring(1)];
				return value == null || value === '' ? _key : value;
			});
		}
		return route;
	},
	url(options) {
		let route = this.getRoute();
		let normalize = this._normalizeRoute(route);
		return this.parseUrl(normalize, options);
	},
	matchUrl(url){
		if(url == null || !_.isString(url) || !this._routesContexts) return false;
		return _(this._routesContexts).some((cntx) => cntx.pattern.test(url));
	},
	getRouteContextByUrl(url){
		let routeCntx;
		_(this._routesContexts).some((cntx) => {
			let res = cntx.pattern.test(url);
			if(res) routeCntx = cntx
			return res;
		});
		return routeCntx;
	},
	getPatternByUrl(url){
		let cntx = this.getRouteContextByUrl(url);
		return cntx && cntx.pattern;
	},
	getAllRoutesContexts(){
		let ownContext = _(this._routesContexts).map((c) => c);
		let children = this.getChildren();
		let childrenContexts = _(children).chain().map((child) => child.getAllRoutesContexts()).flatten().value();
		Array.prototype.push.apply(ownContext, childrenContexts);
		return ownContext;
	}
}

const PageModelAndCollectionMixin = {
	addModel(model, opts = {}){				
		if(!model) return;
		this.model = model;
		let fetch = opts.fetch || this.getOption('fetchModelOnAdd');
		if(fetch === undefined){
			fetch = this.getProperty('fetchDataOnAdd');
		}
		if(fetch === true){
			this.addStartPromise(model.fetch(opts));
		}
	},
	addCollection(collection, opts = {}){
		if(!collection) return;
		this.collection = collection;
		let fetch = opts.fetch != null ? opts.fetch : this.getOption('fetchCollectionOnAdd');
		if(fetch === undefined){
			fetch = this.getProperty('fetchDataOnAdd');
		}
		if(fetch === true){
			this.addStartPromise(collection.fetch(opts));
		}
	},
	_initializeLayoutModels(opts = {}){
		this.addModel(opts.model, opts);
		this.addCollection(opts.collection, opts);
	},
}


const PageProxyEventsMixin = {
	_proxyEvents(){
		let proxyContexts = this._getProxyContexts();
		this._proxyEventsTo(proxyContexts);
	},
	_getProxyContexts(){
		let rdy = [];
		let manager = this.getProperty('proxyEventsTo');
		if(manager){
			let allowed = this.getProperty('proxyThisEvents');
			rdy.push({ context:manager, allowed });
		}
		return rdy;
	},
	_proxyEventsTo(contexts){
		let all = [];
		let eventsHash = {};
		_(contexts).each((context) => {
			let events = [];
			if(!context.allowed)
				all.push(context.context);
			else {
				_(context.allowed).each(function(allowed){
					eventsHash[allowed] || (eventsHash[allowed] = []);
					eventsHash[allowed].push(context.context)
				});
			}
		});
		let page = this;
		page.on('all', (eventName, ...args) => {
			let hashKey;
			_(eventsHash).some((v, key) => {
				let found = key.endsWith(':') ? eventName.startsWith(key.substring(0, key.length - 1)) : key == eventName;
				if(found){
					hashKey = key;
				}
				return found
			});
			let contexts = (!!hashKey && hashKey in eventsHash) ? eventsHash[hashKey] : all;
			let triggerArguments = [page].concat(args);
			_(contexts).each((context) => context.triggerMethod(`page:${eventName}`, ...triggerArguments))
		});

	},
}

const PageChildrenMixin = {
	getStartableChildren(){
		return this.getChildren({
			filter: (c) => {
				return !c.getProperty('preventStart');
			}
		});
	},
}


let Base = mixin(App).with(GetNameLabel, PageLinksMixin, PageLayoutMixin, PageModelAndCollectionMixin, PageProxyEventsMixin, PageRouteMixin, PageChildrenMixin);
export default Base.extend({

	constructor:function(...args){
		if(this.root){
			this.root = this;
			this._initializeRouter();
		}

		Base.apply(this,args);


	},

	freezeWhileStarting: true,
	allowStopWithoutStart: true,
	allowStartWithoutStop: true,
	shouldCreateRouter: false,

	initializeChildrenable(opts){
		this._initializePageRoutes();
		//this._registerRoutes();
		this._initializeLayoutModels(opts);
		this._proxyEvents();		
	},

	proxyThisEvents:() => ['start:begin','before:start','start','start:decline','before:stop','stop','stop:decline', 'error:'],

	triggerStartBegin(...args){ 
		this.triggerMethod('start:begin', ...args);
		//this._initStartRouteData(...args)
	},

	// _initStartRouteData(routeData, ...args){
	// 	this.lastStartContext = {
	// 		routeData,
	// 		args
	// 	};		
	// },

});
