import { _ } from 'vendors';
import View from 'base/view';
import Input from 'components/controls/input';
import template from './layout.html';
import Single from 'components/values/single';
import mix from 'helpers/mix';
import ControlsMixin from 'components/controls/mixin';
import testValue from 'helpers/validate/test-value';

export default mix(View).with(ControlsMixin).extend({
	className: 'c-phone',
	template,
	regions: {
		country: '.c-phone-country',
		code: '.c-phone-code',
		number: '.c-phone-number'
	},
	initialize () {
		this.prepareInitialValues();
		this.initValue();
		this.initModel();
	},
	initValue () {
		this.value = {};
		this.value.country = 7;
		if (this.getOption('type') === 'phone') { this.value.code = 495; }
		if (this.initialValue) {
			this.applyStringToValue(this.initialValue);
		}
	},
	initModel () {
		if (this.model) {
			this.applyModelToValue();
		} else {
			this.model = new Single();
			this.applyChanges({ silent: true });
		}
	},
	applyModelToValue () {
		const val = this.model.getValue();
		this.applyStringToValue(val);
	},
	getChunk (chunk) {
		return this.value[chunk];
	},
	setChunk (name, value) {
		// name !== 'number' && (value = this.toNum(value));
		this.value[name] = value;
	},
	applyStringToValue (str) {
		const aVal = (str || '').split('.');
		this.setChunk('country', aVal[0]);
		this.setChunk('code', aVal[1]);
		this.setChunk('number', aVal[2]);
	},

	onRender () {
		this.showChunk('country', {
			type: 'text',
			inputType: 'tel',
			maxLength: 2,
			charPattern: '[0-9]'
		});

		this.showChunk('code', {
			type: 'text',
			inputType: 'tel',
			maxLength: 5,
			novalidate: true,
			charPattern: '[0-9]'
		});

		this.showChunk('number', {
			type: 'text',
			inputType: 'tel',
			maxLength: 7,
			novalidate: true,
			charPattern: '[0-9]'
		});
	},

	showChunk (controlName, raw) {
		const options = _.extend({
			proxyTo: this,
			controlName,
			value: this.getChunk(controlName)
		}, raw);
		const view = new Input(options);
		this.showChildView(controlName, view);
	},

	controlsEvents: {
		'country:change' (value) {
			this._changeChunk('country', value);
		},
		'code:change' (value) {
			this._changeChunk('code', value);
		},
		'number:change' (value) {
			this._changeChunk('number', value);
		}
	},
	_changeChunk (name, value) {
		this.setChunk(name, value);
		this.applyChanges();
	},

	validate (val, opts) {
		const result = testValue(this.isNum(this.getChunk('country')), 'countryInvalid', opts.errors) &&
						testValue(this.isNum(this.getChunk('code')), 'codeInvalid', opts.errors) &&
						testValue(this.isNum(this.getChunk('number')), 'numberInvalid', opts.errors);
		// && testValue(this.getChunk('number') >= 999,'numberInvalid', opts.errors);
		return result;
	},
	isNum (v) {
		return !isNaN(parseInt(v));
	},
	toNum (v) {
		v = parseInt(v);
		return (!isNaN(v) && v) || undefined;
	},
	applyChanges (opts = {}) {
		const raw = this.getRawValue();
		const noErrors = this.validate(null, opts);

		if (!noErrors) {
			this._triggerEvent('validate:error', raw, opts);
		} else {
			this.model.set('value', raw);
			if (!opts.silent) { this.triggerChange(this.model.getValue(), _.extend(opts, { skipValidate: true })); }
		}
	},
	getRawValue () {
		return [this.getChunk('country'), this.getChunk('code'), this.getChunk('number')].join('.');
	}
});
