import Layout from './layout';
// import { DepartmentModel } from '../models';
import Contragents from './contragents';
import Production from './production';

import { PageWithDivisionsLayout } from 'components/page-sub-division';
// import BasePage from 'pages/auth';

import { ProdDepPage, ProdDepPage2 } from './common';
import { ProductsHeader } from './new-layout/products/ProductsHeader';
import Model from 'base/model';
import { ProductsLayout } from './new-layout/products/ProductsLayout';
import { ProcessesLayout } from './new-layout/processes/ProcessesLayout';
import { CyclesLayout } from './new-layout/cycles/CyclesLayout';
import { ContragentsLayout } from './new-layout/contragents/ContragentsLayout';
import { ContragentsLogLayout } from './new-layout/contragents-log';
import { ProductionLogLayout } from './new-layout/production-log';
import { ConsumptionLogLayout } from './new-layout/consumption-log';
import { ProductionReportLayout } from './new-layout/production-report';
import { SourcesReportLayout } from './new-layout/sources-report';
import { OutputProductionReportLayout } from './new-layout/output-production-report';

const Page = ProdDepPage.extend({
	// moduleRights: { productProduction: 'view' },
	label () {
		return 'Отдел: ' + ((this.model && this.model.display('name')) || '');
	},
	route: ':departmentId',
	icon: 'staff-deps',
	skipMenu: true,
	children: [Contragents, Production],
	Layout,
	pageId: 'department'
    // onBeforeStart(rd){
	// 	let model = new DepartmentModel({ id : rd.args.departmentId, pageId: this.pageId });
	// 	this.addModel(model);
	// 	this.addStartPromise(this.model.fetch());
	// },
});

const NewPage = ProdDepPage2.extend({
	// moduleRights: { productProduction: 'view' },
	label () {
		return 'Отдел: ' + ((this.model && this.model.display('name')) || '');
	},
	pageId: 'department',
	route: ':departmentId',
	icon: 'staff-deps',
	divisionRoot: true,
	menuName: 'продукция',
	Layout: ProductsLayout,
	children: [
		ProdDepPage2.extend({
			route: 'output-production-report',
			label: 'отчет по производству',
			Layout: OutputProductionReportLayout
		}),
		ProdDepPage2.extend({
			route: 'production-report',
			label: 'отчет по продукции',
			Layout: ProductionReportLayout
		}),
		ProdDepPage2.extend({
			route: 'sources-report',
			label: 'отчет по сырью',
			Layout: SourcesReportLayout
		}),
		ProdDepPage2.extend({
			route: 'production-log',
			label: 'журнал производства',
			Layout: ProductionLogLayout
		}),
		ProdDepPage2.extend({
			route: 'contragents-log',
			label: 'журнал выдачи сырья контрагентам',
			Layout: ContragentsLogLayout
		}),
		ProdDepPage2.extend({
			route: 'consumption-log',
			label: 'журнал расхода сырья',
			Layout: ConsumptionLogLayout
		}),
		// ProdDepPage2.extend({
		// 	route: 'employees',
		// 	label: 'персонал'
		// }),
		ProdDepPage2.extend({
			route: 'contragents',
			label: 'список контрагентов',
			Layout: ContragentsLayout
		}),
		ProdDepPage2.extend({
			pageId: 'cycles',
			route: 'cycles',
			label: 'список производственных циклов',
			Layout: CyclesLayout
		}),
		ProdDepPage2.extend({
			pageId: 'processes',
			route: 'processes',
			label: 'список процессов',
			Layout: ProcessesLayout
		})
	]
});

export default NewPage;
