import { $ } from 'vendors';
// import CollectionView from 'base/collection-view';
import collection from './stack';
import childView from './notify-view';
import { NextCollectionView as CollectionView } from 'backbone.marionette';
import comparator from 'helpers/comparator';
export default CollectionView.extend({
	className: 'fast-notifies',
	sortsWithCollection: false,
	sortWithCollection: false,
	initialize () {
		this.$el.appendTo($('body'));
		this.notViewedFilter = this.viewFilter;
		this.triggerMethod('ready');
	},
	onReady () {
		this.render();
		this._isAttached = true;
	},
	collection,
	childView (child) {
		if (child.viewClass) {
			return child.viewClass;
		} else {
			return childView;
		}
	},
	viewComparator: (v1, v2) => comparator([v2, v1, m => m.get('date')]),
	viewFilter: v => !v.model.get('viewed'),
	// _renderChildren(){
	// 	//debugger;
	// 	return CollectionView.prototype._renderChildren.apply(this, arguments);
	// },
	collectionEvents: {
		'change:viewed' () {
			this.filter();
		}
	},
	childViewEvents: {
		'timer' (view) {
			if (this.viewFilter) {
				view.animateAndSetViewed();
			} else {
				view.model.setViewed();
			}
		}
	},
	attributes: {
		'data-scrollable': true
	}

});
