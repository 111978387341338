import 'mod/productionMeasures';
import BasePage from 'pages/auth';
import Layout from './layout';
// import busData from 'bus/data';
import MeasurePage from './measure';
import { measuresSingleton } from '../../../../modules/resources/production/measures';

const Page = BasePage.extend({

	moduleRights: { resources: 'view', resourcesProduction: 'view, measuresEdit' },
	label: 'Единицы измерения',
	route: 'measures',
	children: [MeasurePage],
	icon: 'measures',
	onBeforeStart () {
		if (!this.collection) {
			// const measures = busData.measures();
			this.addCollection(measuresSingleton);
		}
	},
	Layout
});

export default Page;
