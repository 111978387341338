import { _ } from 'vendors';
import data from 'bus/data';
import { MeasuresCollection } from './collection';


const measures = new MeasuresCollection();

data.measures = function (...args) {
	return data.request('measures', ...args);
};

data.shortMeasure = function (v) {
	return data.measures(v, 'short');
};


data.reply('measures', function (id, opts) {
	opts || (opts = {});
	if (arguments.length === 0) return measures;
	if (id == null) return null;

	const item = measures.get(id);

	if (item && _.isString(opts)) { return item.display(opts); }

	if (!_.size(opts) || !item) return item;

	if (opts.transform) { return opts.transform(item); }

	return item;
});


export default measures;
