import CollectionView from 'base/collection-view';
import childView from './segment';
export default CollectionView.extend({
	className: 'c-select-segments',
	childView,
	childViewEvents: {
		'click' (v) {
			this.swap(v.model);
		}
	},
	collectionEvents: {
		'settle' (segment) {
			this.swap(segment);
		}
	},
	swap (segment) {
		this.prev && this.prev.set('current');
		this.prev = segment;
		segment.set('current', true);
		this.trigger('segment:select', segment);
	}
});
