import { _ } from 'vendors';
import Collection from 'base/collection';
import model from './model';
import paths from 'helpers/paths';
// import convert from '../convert';
import { internalConvert } from '../internal-convert';

export default Collection.extend({
	parse (data, opts) {
		if (_.isArray(data)) { return data; } else if (!_.isObject(data)) { return data; }

		const res = internalConvert(data, this.constructor, this.model, opts);
		// convert(data, opts);

		return res;
	},
	model,
	urlPattern: paths.api('principal:rights'),
	getFilledNames () {
		return this
			.filter(f => f.display('value'))
			.map(m => m.display('id'));
	}
});
