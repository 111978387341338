import View from 'pages/view';
import Collection from 'mod/contragents/models/collection';
import CardBlock from 'components/ui-card-block/block';
import List from 'mod/contragents/views/list';
import ListItem from 'mod/contragents/views/list-item';

// import h from 'components/ui-model-card/helpers';

import Create from './create';

const Simmilar = List.extend({
	tagName: 'ul',
	childView: ListItem.extend({ tagName: 'li' }),
	emptyView: ListItem.Empty
});
export default View.extend({
	className: 'create-contragent',
	initialize () {
		this.collection = new Collection();

		this.createNested('create', {
			viewClass: Create,
			viewOptions: {
				header: { text: 'Добавление контрагента', addClass: 'bg-main-dark' }
			}
		});

		this.createNested('similar', {
			viewClass: CardBlock,
			viewOptions: {
				addClass: 'column-fullheight',
				header: { text: 'похожие контрагенты', addClass: 'bg-main-dark' },
				content: Simmilar.extend({ collection: this.collection })
			}
		});
	},
	onRender () {
		this.showAllNested();
	},
	childViewEvents: {
		'change:name' (name) {
			if (!name) {
				this.collection.reset();
			} else {
				const data = { 'name.v': name, 'result.take': 10 };
				this.collection.fetch({ data });
			}
		}
	}
});
