import { moment } from 'vendors';
import Base from './base';
import between from '../between';
export default Base.extend({
	groups () {
		const isActive = this.model.get('state') === 'active';
		const range = this.getDateRange();

		const start = Date.toMonth(range.from);
		const test = Date.toMonth(start);
		test.addMonths(1);
		test.addDays(-1);
		const days = test.getDate();

		const groups = [];
		const now = new Date();
		for (let x = 0; x < days; x++) {
			let from = Date.toDay(start);
			from.addDays(x);
			const to = Date.toDay(from);
			to.setHours(23, 59, 59, 999);

			if (isActive && from < now) { from = now; }


			this.createGroup(to, {
				date: from,
				header: moment(from).format('Do MMMM, ddd'),
				testModel: (m) => between(this.getModelDate(m), from, to),
				isInPast: to <= now
			}, groups);
		}
		return groups;
	}
});
