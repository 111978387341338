import { BaseObject } from 'base/base-object';

export const Selector = BaseObject.extend({

	constructor: function (options) {
		this.selectedItems = new Map();
		BaseObject.apply(this, arguments);
	},

	value (mapper) {
		const views = this.selectedItems.values();
		const multiple = this.isMultiple();
		const size = this.selectedItems.size;
		const values = [];
		for (const view of views) {
			let value = this._getValue(view);
			if (mapper) {
				value = mapper(value);
			}
			values.push(value);
			if (!multiple && size > 1) { break; }
		}
		if (multiple) {
			return values;
		} else {
			return values[0];
		}
	},

	isToggleAllowed () {
		if (this.isMultiple()) {
			return true;
		}
		return this.getOption('toggleAllowed', true) === true;
	},

	isMultiple () {
		return this.getOption('multiple', true) === true;
	},

	isSelected (view) {
		const key = this._getKey(view);
		return this.selectedItems.has(key);
	},

	select (view, options = {}) {
		const toggleAllowed = this.isToggleAllowed();
		const alreadySelected = this.isSelected(view);
		if (toggleAllowed) {
			return alreadySelected
				? this._unselect(view)
				: this._select(view);
		} else {
			if (alreadySelected) {
				return;
			}
			this._unselectAll();
			return this._select(view);
		}
	},

	unselect (view, options = {}) {
		this._unselect(view);
	},

	_unselectAll ()	 {
		const views = this.selectedItems.values();
		for (const view of views) {
			this._unselect(view, { noChange: true });
			this.trigger('change');
		}
		// views.forEach(view => {});
	},

	_select (view) {
		const key = this._getKey(view);
		this.selectedItems.set(key, view);
		view.triggerMethod('selected');
		this.trigger('change');
		return true;
	},

	_unselect (view, { noChange } = {}) {
		const key = this._getKey(view);
		this.selectedItems.delete(key);
		view.triggerMethod('unselected');
		if (!noChange) {
			this.trigger('change');
		}
		return false;
	},

	_getKey (view) {
		return this._getValue(view);
	},

	_getValue (view) {
		const valueType = this._getValueType();
		switch (valueType) {
		case 'view':
			return view;
		case 'model':
			return view.model;
		case 'id':
			return view.model.id;
		default: throw new Error('unable to get selector2 value');
		}
	},

	_getValueType () {
		const valueType = this.getOption('valueType', true) || 'model';
		return valueType;
	},

	hasSelected () {
		return this.selectedItems.size > 0;
	}

});
