import { _, moment } from 'vendors';
import View from 'base/view';
export default View.extend({
	className () {
		const current = (this.model.id === (new Date()).getMonth());
		return current ? 'current' : undefined;
	},
	template: _.template('<%= label %>'),
	getLabel () {
		return moment().month(this.model.id).format('MMMM');
	},
	templateContext () {
		return {
			label: this.getLabel()
		};
	},
	triggers: { click: 'click' }
});
