import BaseView from 'pages/view';
import template from './layout.html';

import ModelProperties from 'components/ui-model-card/properties';
import h from 'components/ui-model-card/helpers';

import nestedEmployees from 'mod/employees/views/entity-nested-employees';
import nestedRights from 'mod/rights/views/entity-nested-rights';


export default BaseView.extend({
	className: 'group-card',
	template,
	initialize () {
		const m = this.model;

		this.createNested('system', {
			viewClass: ModelProperties.extend({ className: 'card lined with-border' }),
			viewOptions: {
				header: { text: 'детали', addClass: 'bg-main-dark' },
				properties: [
					h.ui('name', { editButton: true })
				],
				action: m.getAction('edit-details')
			},
			regionTemplate: { replaceElement: true }
		});

		this.createNested('rights', nestedRights(m));
		this.createNested('employees', nestedEmployees(m, {}, { addChildViewOptions: { employeeId: v => v.model.get('eId') } }));
	},
	onRender () {
		this.showAllNested();
	}
	// childViewEvents:{
	// 	'remove:employee:click'(model){

	// 	}
	// }
});
