import { _ } from 'vendors';
import View from 'base/view';
import Entries from 'components/filters/views/pinned-items';
import FiltersHeader from './filters-header';
import UiView from 'components/ui-list/item';
// import Card from 'components/ui-card/item';
// import Item from 'components/ui-list/item';

export default Entries.extend({
	initialize (opts) {
		this.mergeOptions(opts, ['instance']);

		// this.customViews || (this.customViews = []);
		// console.warn('-поиск по параметрам-', this);
		// this.customViews.push
		this.addCustomView(() => new UiView({ cssClassModifiers: ['card-block-header blue'], text: 'поиск по параметрам:', collectionPlace: 'top' }));

		const create = () => {
			const txtSearch = this.collection.findWhere({ textSearch: true });
			if (!txtSearch) return;
			return new FiltersHeader({
				shouldNotApply: this.getOption('textShouldNotApply', true),
				model: this.collection.findWhere({ textSearch: true })
			});
		};
		// this.customViews.push
		this.addCustomView(create);



		if (!this.instance.getOption('instantApply')) {
			// this.customViews.push
			this.addCustomView(() => new View({
				tagName: 'footer',
				className: 'ui-list-item as-button action',
				collectionPlace: 'bottom',
				template: _.template('<div><span>применить фильтры</span></div>'),
				triggers: {
					click: 'click:apply'
				}
			}));
		}

		// console.error('kuku');
	},
	addClass: 'card lined with-border fast-filters',
	childViewOptions () {
		return {
			instance: this.instance
		};
	},
	childViewEvents: {
		'text:search:apply' () {
			// if(!this.instance.getOption('instantApply'))
			this.instance.triggerApply();
		},
		'click:apply' () {
			this.instance.triggerApply();
		}
	}
});
