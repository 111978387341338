import { GetInfoMixin, _meas } from './common';
import UiListItem from 'components/ui-list/item';

export const ProductInfoView = UiListItem.extend({
	...GetInfoMixin,
	text: (m, v) => v.getRawProduct().fullName,
	rightItems () {
		const prod = this.getRawProduct();
		const base_m = _meas(prod.unitMeasureId);
		const cycle = this.getRawCycle();
		const cycle_m = _meas(cycle.productionMeasureId);
		const baseInProduce = cycle.baseUnitsInProductionUnit;

		let baseText = '';
		const produceText = cycle_m;
		if (baseInProduce !== 1) {
			baseText = 'это ' + baseInProduce + ' ' + base_m;
		}

		return [
			{
				template: '<div><i>производим</i><b><%= baseText %></b><span><%= produceText %></span></div>',
				templateContext: {
					produceText,
					baseText
				}
			}
		];
	}
});
