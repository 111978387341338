import { _ } from 'vendors';
import View from 'base/view';
import restrict from './restrict';
import mix from 'helpers/mix';
import ControlsMixin from 'components/controls/mixin';

function float (arg) {
	if (arg == null) return undefined;
	const num = parseFloat(arg);
	return isNaN(num) ? undefined : num;
}
const Base = mix(View).with(ControlsMixin);
const input = Base.extend({
	_type: 'input',
	constructor (opts = {}) {
		if (opts.attributes) {
			opts.attrs = opts.attributes;
		}
		delete opts.attributes;
		this.options = opts;
		Base.apply(this, arguments);
	},
	tagName: 'input',
	template: _.noop,
	doneOnEnter: true,
	focusOnRender: true,
	initialize (opts) {
		this.mergeOptions(opts, ['controlName']);
		this.currentValue = opts.value;
	},
	onAttach () {
		if (this.getOption('focusOnRender')) { this.$el.focus(); }
		this.trigger('content:ready');
	},
	getValue () {
		const r = this.restrictions;
		const raw = this.$el.val();
		if (r.actualType === 'number') {
			const val = parseFloat(raw);
			return isNaN(val) ? null : val;
		} else { return raw; }
	},
	fixValueBeforeSet (value) {
		return value;
	},
	setValue (val) {
		this.$el.val(val);
		this.$el.trigger('js:change');
	},
	attributes () {
		const ownAttrs = this.getOption('attrs') || {};
		const own = _.extend({}, this.buildRestrictions());
		delete own.actualType;
		delete own.pattern;
		if (this.getOption('inputType')) {
			own.type = this.getOption('inputType');
		}
		if (ownAttrs.pattern) {
			own.pattern = ownAttrs.pattern;
		}
		const attrs = _.extend({ value: this.getOption('value') }, ownAttrs, own);
		return attrs;
	},
	buildRestrictions () {
		const res = this.restrictions = {};
		res.type = this.getOption('type');
		res.actualType = this.getOption('actualType') || this.getOption('valueType') || this.getOption('type');
		res.min = float(this.getOption('min'));
		res.maxLength = float(this.getOption('maxLength'));
		res.max = float(this.getOption('max'));
		res.pattern = this.buildRegexPattern(this.getOption('pattern'));
		res.charPattern = this.buildRegexPattern(this.getOption('charPattern'));
		return res;
	},
	buildRegexPattern (pattern) {
		if (pattern instanceof RegExp) return pattern;
		if (_.isString(pattern) && pattern.length) {
			return new RegExp(pattern);
		}
	},
	applyRestrict (eventName, event) {
		const options = _.extend({
			context: this,
			input: this.el,
			restrictions: this.restrictions
		}, { eventName, event });
		restrict(options);
	},
	events () {
		let events;
		if (['checkbox', 'radio'].indexOf(this.restrictions.type) > -1) {
			events = {
				'change' (event) { 		this.applyRestrict('radiocheckbox', event); }
			};
		} else {
			events = {
				'keydown' (event) { 		this.applyRestrict('keydown', event); },
				'keypress' (event) { 		this.applyRestrict('keypress', event); },
				'keyup' (event) { 		this.applyRestrict('keyup', event); },
				'paste' (event) { this.applyRestrict('paste', event); },
				'blur' (event) { this.applyRestrict('blur', event); },
				'js:change' (event) { this.applyRestrict('js:change', event); },
				'focus' () {				this.$el.select(); }
			};
		}
		events.click = (e) => {
			// console.log(document.hasFocus());
			// console.log('input CLICKED', this);
			if (!document.hasFocus()) {
				// console.log(this.el.ownerDocument === document, this.el.ownerDocument === window.document);
				this.$el.focus();
				// console.log(this.el.ownerDocument === document, this.el.ownerDocument === window.document);
			}
		};
		return events;
	}
});


export default input;
