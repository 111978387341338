export const periodFilter = {
	id: 'period',
	valueId: 'period',
	label: 'период',
	pinned: true,
	valuePrototype: () => ({
		multiple: false,
        // sourceValues:() => busData.productTypes(),
        // controlType:'select',
		modelType: 'range',
		valueType: 'date'
	})
};
