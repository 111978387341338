import { _ } from 'vendors';

import CollectionView from 'base/collection-view';
import Collection from 'base/collection';
import { bus } from './bus';

import editValue from 'bus/edit';
import modalError from 'helpers/modals/error';
import busModels from 'bus/models';

import { Model } from 'base/models';

import { componentComparator } from 'mod/productionProducts/models/prod-cycle/prodCycleProd-model';
import { returnTypesEnum, _meas } from './common';

import { editMeasuredValueInModal } from 'components/controls/measured-value';
import { ComponentView } from './ComponentView';
import { displayAmount, selectConcreteProductAsync } from '../common';

export const ComponentsListView = CollectionView.extend({
	className: 'contragent-components-list',
	initialize (opts) {
		this.mergeOptions(opts, ['process']);
		this.initializeCollection();
		this.refreshCollectionData(this.process.get('productionCycleComponents') || []);
	},
	initializeCollection () {
		this.collection = new Collection();
		const proc = this.getOption('process');
		this.collection.product = proc.get('product');
		this.collection.cycle = proc.get('productionCycle');
		this.listenTo(bus, 'refresh:contragent:store', this.refreshCollectionData);
	},
	refreshCollectionData (data, updateModel) {
		this.collection.reset(data);
		if (updateModel) {
			this.process.set('productionCycleComponents', data);
		}
	},
	childView: ComponentView,
	childViewOptions () {
		return {
			process: this.getOption('process')
		};
	},
	viewComparator (v1, v2) {
		return componentComparator(v1.model, v2.model);
	},
	childViewEvents: {
		'withdraw' (view, event) {
			this.selectStoreProduct(view.model);
			// this.selectStoreBatch(view.model);
		},
		'take' (view, event) {
			this.selectContragentProduct(view.model);
			// editValue.do({
			// 	header: 'укажите количество складских единиц',
			// 	valueType: 'number'
			// }).then(value => {
			// 	this.take(view.model, value);
			// });
		}
	},
	getProcess () {
		return this.getOption('process');
	},
	getRawParentProduct () {
		const proc = this.getProcess();
		return proc.get('product');
	},
	selectProductParty (sourceValues, component, header = '') {
		const unit = _meas(component.get('baseUnitMeasureId'));

		return editValue.do({
			header: (header ? header + ': ' : '') + component.get('name') + ': Выберите партию',
			controlType: 'select-api',
			controlOptions: {
				searchDisabled: true,
				shouldReturnModel: true,
				collectionChildViewMixin: {
					text: m => m.get('idString') + ' &mdash; ' + m.get('baseAmount') + ' ' + unit
				}
			},
			sourceValues
		}).then((batch) => {
			console.log('DS < ВЫБРАНО', batch);
			if (!batch) {
				return Promise.reject();
			}
			return batch;
		}, (reason) => {
			return reason;
		});


		// editValue.do({
		// 	header: 'Выберите партию продукции',
		// 	controlType:'select-api',
		// 	controlOptions: {
		// 		searchDisabled: true,
		// 		shouldReturnModel: true,
		// 		collectionChildViewMixin: {
		// 			text: m => m.get('idString') + ' &mdash; ' + m.get('amount') + busData.measures(m.get('batchUnitMeasureId'), 'short'),
		// 			//smallText: m => m.get('contactName') + '<br/>' + m.get('address')
		// 		},
		// 	},
		// 	sourceValues: sourceValues,
		// })
	},
	selectContragentBatch (component) {
		const contragentId = this.process.get('contragent').id;
		const src = () => busModels.request('api:contragent:product:batches', { productId: component.id, contragentId, fetch: true });
		const partySelector = this.selectProductParty(src, component, 'списание');
		// partySelector.then(batch => {

		// 	editValue.do({
		// 		header: 'укажите вид списания',
		// 		valueType: 'enum',
		// 		sourceValues: { return: 'Возврат на склад', defect: 'Списать брак' }
		// 	}).then(transferType => {
		// 		editValue.do({
		// 			header: 'укажите количество складских единиц',
		// 			valueType: 'number'
		// 		}).then(amount => {
		// 			//this.take(view.model, value);
		// 			console.log('#?', transferType, amount);
		// 			this.takeBatchAmount(component, batch, transferType, amount);
		// 		});
		// 		//this.take(view.model, value);
		// 	});
		// 	//this.obtainBatchValue(component, batch);
		// 	//this.withdrawBatch(component, batch);
		// });

		// console.log('>>>>> component', component);

		const selectReturnTypeOptions = {
			header: component.get('name') + ': укажите вид списания',
			valueType: 'enum',
			sourceValues: returnTypesEnum
		};

		partySelector
			.then(
				batch => {
					console.log('++ batch ++', batch);
					return editValue
						.do(selectReturnTypeOptions)
						.then(transferType => ({
							transferType, batchId: batch.id, productId: component.id, maximumAmountInBaseUnits: batch.get('baseAmount')
						}));
				}
			)
			.then(data =>
				editMeasuredValueInModal({
					header: returnTypesEnum[data.transferType] + ': ' + component.get('name'),
					baseMeasureId: component.get('baseUnitMeasureId'),
					storeMeasureId: component.get('storeUnitMeasureId'),
					baseUnitsInStoreUnit: component.get('baseUnitsInStoreUnit'),
					maximumAmountInBaseUnits: data.maximumAmountInBaseUnits
				}).then(model => ({
					...model,
					...data
				}))
			).then(data => this.takeBatchAmount_v2(data)).then(() => {}, xhr => modalError('Неудалось списать продукции', xhr));
	},
	async selectContragentProduct (model) {
		const sourceValues = (model.get('concretes') || []).filter(m => m.contragentAmount > 0);
		const smallText = m => displayAmount(m, 'contragentAmount') + ' у контрагента';
		const options = { sourceValues, smallText };

		const concrete = await this.selectConcreteProduct(model, options);
		if (!concrete) {
			return;
		}
		await this.selectContragentBatch(concrete);
	},
	async selectStoreProduct (model) {

		const sourceValues = (model.get('concretes') || []).filter(m => m.storeAmount > 0);
		const smallText = m => displayAmount(m, 'storeAmount') + ' на складе';
		const options = { sourceValues, smallText };
		const concrete = await this.selectConcreteProduct(model, options);
		if (!concrete) {
			return;
		}
		await this.selectStoreBatch(concrete);
	},

	async selectConcreteProduct (model, selectOptions) {

		if (!model.get('isAbstractGroup')) { return model; }

		// const sourceValues = (model.get('concretes') || []).filter(m => m.storeAmount > 0);
		// // const addText = m => ', <small>на складе: ' + displayAmount(m, 'storeAmount') + '</small>';
		// const smallText = m => displayAmount(m, 'storeAmount') + ' на складе';

		// const options = Object.assign({ sourceValues }, selectOptions);

		const concrete = await selectConcreteProductAsync(selectOptions);
		// let concrete;
		// try {

		// 	const result = await editValue.do({
		// 		header: 'Выберите конкретную продукцию',
		// 		controlType: 'select',
		// 		controlOptions: {
		// 			searchDisabled: true,
		// 			shouldReturnModel: true,
		// 			collectionChildViewMixin: {
		// 				text: m => m.get('name') + ' &mdash; ' + m.get('contragentAmount') // m.get('idString') + ' &mdash; ' + m.get('baseAmount') + ' ' + unit
		// 			}
		// 		},
		// 		sourceValues
		// 	});

		// 	concrete = result;

		// } catch {}
		return concrete;
	},

	selectStoreBatch (component) {
		const src = () => busModels.request('api:product:batches', { productId: component.id, fetch: true });
		const partySelector = this.selectProductParty(src, component);
		// partySelector.then(batch => {
		// 	this.obtainBatchValue(component, batch);
		// 	//this.withdrawBatch(component, batch);
		// });
		// console.log('comp', component);
		partySelector.then(batch =>
			editMeasuredValueInModal({
				header: component.get('name') + ' &mdash; ' + batch.get('idString'),
				baseAmount: _.displayNum(batch.get('baseAmount'), 4) + ' ' + _meas(component.get('baseUnitMeasureId')),
				baseMeasureId: component.get('baseUnitMeasureId'),
				storeMeasureId: component.get('storeUnitMeasureId'),
				baseUnitsInStoreUnit: component.get('baseUnitsInStoreUnit'),
				maximumAmountInBaseUnits: batch.get('baseAmount')
			}).then(data => {
				data.batchId = batch.id;
				data.productId = component.id;
				data.processId = this.getProcess().id;
				return data;
			})
		).then(data => this.withdrawBatchAmount_v2(data)).then(() => {}, xhr => modalError('Неудалось выдать партию продукции', xhr));
	},
	// deprecated
	obtainBatchValue (component, batch) {
		editValue.do({
			header: 'укажите количество складских единиц',
			valueType: 'number'
		}).then(value => {
			this.withdrawBatchAmount(component, batch, value);
		});
	},
	getComponentsUrl () {
		return this.process.url() + '/production-components';
	},
	getComponentUrl (component) {
		const id = typeof component === 'object' ? component.id : component;
		return this.getComponentsUrl() + '/' + id;
	},

	_sendTakeWithdrawRequest (attrs, url) {
		const post = new Model();
		post.url = url;
		return post.save(null, { wait: true, method: 'post', attrs })
			.then((data) => {
				this.refreshCollectionData(data, true);
				return data;
			});
	},
	withdrawBatchAmount_v2 (attrs) {
		const url = this.getComponentUrl(attrs.productId) + '/withdraw';
		return this._sendTakeWithdrawRequest(attrs, url);
	},
	takeBatchAmount_v2 (attrs) {
		const url = this.getComponentUrl(attrs.productId) + '/take';
		return this._sendTakeWithdrawRequest(attrs, url);
	},

	// deprecated
	takeBatchAmount (component, batch, transferType, amount) {
		const url = this.getComponentUrl(component) + '/take';
		const post = new Model();
		const attrs = { batchId: batch.id, productId: component.id, amount, writeoffDefect: transferType === 'defect' };
		post.url = url;
		post.save(null, { wait: true, method: 'post', attrs })
			.then((data) => {
				this.refreshCollectionData(data);
			}, xhr => modalError('Не удалось списать сырье', xhr));
	},

	// deprecated
	withdrawBatchAmount (component, batch, amount) {
		const url = this.getComponentUrl(component) + '/withdraw';
		const post = new Model();
		const attrs = { batchId: batch.id, productId: component.id, amount };

		post.url = url;
		post.save(null, { wait: true, method: 'post', attrs })
			.then((data) => {
				this.refreshCollectionData(data);
			}, xhr => modalError('Не удалось выдать сырье', xhr));
	}


});
