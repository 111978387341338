import { BatchLogsLayout } from '../BatchLogsLayout';
import { rawFilters } from './filters';
import { LogCollection } from './LogCollection';


export const ConsumptionLogLayout = BatchLogsLayout.extend({
	cells: ['created', 'productAndBatchNames', 'amount', 'sourceAndDestination', 'actor', 'typeIcon'],
	Collection: LogCollection,
	rawFilters
});
