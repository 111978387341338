import View from 'base/view';
import { HamburgerView } from 'base/view-hamburger';
import { isDesktop } from 'utils/isDesktop';
import { setOptionsMixin } from './common';
import { DivisionsView } from './DivisionsView';

export const PageAsideView = HamburgerView.extend({
	className: 'page-with-divisions_aside',
	initialState () {
		const rootView = this.getOption('rootView');
		const desktop = isDesktop();

		let visible = rootView.state('aside-visible');
		if (visible) {
			return { visible, desktop };
		}
		visible = desktop ? 'visible' : 'hidden';
		return { visible, desktop };
	},
	cssClassModifiers: [
		(m, v) => v.state('visible'),
		(m, v) => v.state('desktop')
	],

	...setOptionsMixin(HamburgerView),

	getCustomViews () {
		return [
			this.getInfoView(),
			this.getDivisionsView(),
			this.getActionsView(),
			new View({
				tagName: 'button',
				className: 'toggle-aside',
				template: '<i></i>',
				events: {
					click: event => this.toggleAside()
				}
			})
		];
	},

	toggleAside () {
		const rootView = this.getOption('rootView');
		const newstate = this.state('visible') === 'visible' ? 'hidden' : 'visible';
		this.state('visible', newstate);
		rootView.state('aside-visible', newstate);
	},

	getInfoView () {

	},
	getDivisionsView () {
		const options = this.getDefaultChildViewOptions();
		// const options = { ...this.options };
		const view = new DivisionsView(options);
		return view;
	},
	getActionsView () {

	}
});
