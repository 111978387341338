import '../api-config';
import BaseModel from 'base/card-model';
import BbStore from 'base/bb-store';
import paths from 'helpers/paths';
// import rights from 'mod/rights';
// import EmployeeModel from 'mod/employees/models/model';
// import Collection from 'base/collection';
// import action from 'helpers/action';
// import editValue from 'components/edit-value';
// import action from 'helpers/action';
import NestedEntitiesV2mixin from 'mixins/model/nested-entities-v2';
import EntityWithRights from 'mod/rights/mixins/entity-with-rights';
import EntityWithEmployees from 'mod/employees/mixins/entity-with-employees';

import mix from 'helpers/mix';


import EditableProperties from 'mixins/editable-properties';
// import action from 'helpers/action';

const PropertiesMixin = {
	properties: {
		name: {
			type: 'text',
			display: {
				label: 'название группы'
			}
		}
	}
};


const Model = mix(BaseModel).with(PropertiesMixin, EditableProperties, NestedEntitiesV2mixin, EntityWithRights, EntityWithEmployees).extend({
	cardUrlRoot: paths.url('groups'),
	urlRoot: paths.api('groups'),
	rightsConfig: {
		update: {
			rights: { admin: 'manageRights' }
		}
	},
	employeesActionsConfig: {
		add: {
			rights: { admin: 'manageGroups' }
		},
		remove: {
			rights: { admin: 'manageGroups' }
		}
	},
	getName () {
		return this.display('name');
	}
});

const StoreModel = BbStore(Model);

export default StoreModel;
