import CollectionView from 'base/collection-view';
import childView from './list-item-view';
// import TimeChangeMonitor from 'components/time-change-monitor';


export default CollectionView.extend({
	// constructor(){
	// 	CollectionView.apply(this, arguments);
	// 	this.timeChangeMonitor = new TimeChangeMonitor({
	// 		collection: this.collection,
	// 		getMs: (m) => m.getRemindAt(),
	// 		callback: (m) => m.trigger('change', m),
	// 		context: this
	// 	});
	// },
	tagName: 'ul',
	childView,
	// buildEmptyView(){
	// 	//console.log('~~~~~');
	// 	return CollectionView.prototype.buildEmptyView.apply(this, arguments);
	// },
	emptyViewOptions () {
		return {
			entries: this.collection
		};
	},
	emptyView: childView.Empty.extend({
		initialize () {
			this.waitText = 'подождите, процессы подгружаются';
			this.emptyText = 'пусто';
			const col = this.getCol();
			this.listenTo(col, 'fetch:complete', () => {
				if (!this.isDestroyed()) { this.render(); }
			});
		},
		getCol () {
			return this.getOption('entries');
		},
		text () {
			const col = this.getCol();
			if (col.isFetching()) {
				return this.waitText;
			} else {
				return this.emptyText;
			}
		}
	})
});
