import { _ } from 'vendors';
import CollectionView from 'base/collection-view';
import Collection from 'base/collection';
import comparator from 'helpers/comparator';
import getEventFields from 'helpers/get-event-fields';
import ChildView from 'components/ui-list/item';

const defaultExtend = {
	className: 'ui-list-item as-button action'
};

export default CollectionView.extend({
	constructor () {
		CollectionView.apply(this, arguments);
		this._initContext();
		this._initCollection();
	},
	childView () {
		let View = this.getOption('ChildView') || ChildView;
		const ext = this.getOption('extendChildView') || defaultExtend;
		if (_.isObject(ext)) { View = View.extend(ext); }
		return View;
	},
	childViewOptions (child) {
		const options = {};
		const validators = this.getOption('actionValidators') || {};
		const validator = validators[child.id];
		if (validator) {
			options.isEnabled = validator;
		}
		return options;
	},
	_initContext () {
		this.context = this.getOption('context') || this.model;
	},
	getRawActions () {
		const ownActions = this.getOption('actions');
		const contextActions = this.context && _.isFunction(this.context.getActions) && this.context.getActions();

		return ownActions || contextActions || [];
	},
	getActionsForCollection () {
		const raw = this.getRawActions();

		const predicate = this.getPredicate();
		if (!predicate) {
			return raw;
		} else {
			return _.filter(raw || [], predicate);
		}
	},
	getPredicate () {
		const predicate = this.getOption('predicate');
		if (predicate == null) return;
		if (_.isFunction(predicate)) {
			return predicate;
		}
		if (_.isString(predicate)) {
			return m => m.id === predicate;
		}
		if (_.isArray(predicate)) {
			return m => predicate.indexOf(m.id) > -1;
		}
	},
	_initCollection () {
		const actions = this.getActionsForCollection();
		this.collection = new Collection(actions);
	},
	viewComparator (v1, v2) {
		return comparator(v1, v2, (m) => m.get('order') || 0);
	},
	_getActionOptions (actionModel, event) {
		const eventHash = getEventFields(event);
		return _.extend({ inModal: this.inModal, actionModel }, eventHash);
	},
	childViewEvents: {
		'text:click' (action, view, event) {
			const enabled = view.getOption('isEnabled');
			const disabled = enabled === false;
			if (disabled) {
				event.preventDefault();
				event.stopPropagation();
				return false;
			}
			if (this.context && _.isFunction(this.context.executeAction)) {
				const actionOptions = this._getActionOptions(action, event);
				this.context.executeAction(action.toJSON(), actionOptions);
			} else {
				Console.warn('actions/list: no actions context or executeAction missing');
			}
		}
	}
});
