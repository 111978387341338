import ModelProperties from 'components/ui-model-card/properties';
import h from 'components/ui-model-card/helpers';

export function createNestedDeliveryDetails (view) {
	// const m = view.model;
	view.createNested('delivery', {
		viewClass: ModelProperties.extend({ className: 'card lined with-border' }),
		viewOptions: {
			header: { text: 'доставка', addClass: '' },

			flatPatch: true,
			// cssClassModifiers:[
			// 	'main-info-block',
			// 	m => m.get('completeState'),
			// ],
			properties: [
				h.ui('buyDetails.delivery.responsible', { editButton: true }),
				h.ui('buyDetails.delivery.isDeliveryNegotiated', { editButton: true }),
				h.ui('buyDetails.delivery.deliverAtPlan', { editButton: true }),
				h.ui('buyDetails.delivery.deliverAtFact', { editButton: true }),
				h.ui('buyDetails.delivery.from', { editButton: true }),
				h.ui('buyDetails.delivery.to', { editButton: true })
			]
		},
		regionTemplate: { replaceElement: true }
	});
}
