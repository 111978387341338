import { propertySchemaApi } from 'apis/schema';
import { displayNum } from 'utils';
// import { _meas } from './common';
// import { _ } from 'vendors';
import { displayUnit, getUnitAmount } from 'apis/units';

function getAmountValue (key, m, s) {
	const isAbstract = propertySchemaApi.getValue('isAbstractGroup', m, s);

	if (!isAbstract) {
		return m.get(key);
	}

	const concretes = propertySchemaApi.getValue('concretes', m, s) || [];
	const amount = concretes.reduce((prev, item) => prev + item[key], 0);

	return amount;
}

export const componentSchema = {
	storeAmount: {
		valueType: 'number',
		getValue (m, s) {
			return getAmountValue('storeAmount', m, s);
		},
		displayValue (v, m, s) {
			return displayNum(v, 4) + ' ' + propertySchemaApi.displayValue('baseUnitMeasureId', m, s);
		}
	},
	contragentAmount: {
		valueType: 'number',
		getValue (m, s) {
			return getAmountValue('contragentAmount', m, s);
			// const isAbstract = propertySchemaApi.getValue('isAbstractGroup', m, s);

			// if (!isAbstract) {
			// 	return m.get('contragentAmount');
			// }

			// const concretes = propertySchemaApi.getValue('concretes', m, s) || [];
			// const amount = concretes.reduce((prev, item) => prev + item.contragentAmount, 0);

			// return amount;
		},

		displayValue (v, m, s) {
			return displayNum(v, 4) + ' ' + propertySchemaApi.displayValue('baseUnitMeasureId', m, s);
		}

	},

	baseUnitMeasureId: {
		displayValue (v, m, s) {
			return displayUnit(v); // _meas(v);
		}
	},

	context: {
		getValue (m, s) {
			if (!m.collection) return {};
			const { product, cycle } = m.collection;
			return {
				product, cycle
			};
		}
	},
	productProductionMeasureId: {
		getValue (m, s) {
			const cycle = m.collection && m.collection.cycle;
			if (!cycle) { return; }
			return cycle.productionMeasureId;
		}
	},
	enoughFor: {
		displayValue (v, m, s) {

			const produceAmount = propertySchemaApi.getValue('amount', m, s);
			const produceUnitsInBaseUnit = propertySchemaApi.getValue('produceUnitsInBaseUnit', m, s);
			const baseAmountForOneProduced = getUnitAmount(produceAmount, undefined, produceUnitsInBaseUnit);
			const contragentValue = propertySchemaApi.getValue('contragentAmount', m, s);
			const canProduce = contragentValue / baseAmountForOneProduced;

			const productProductionMeasureId = propertySchemaApi.getValue('productProductionMeasureId', m, s);

			let unit = '';
			if (productProductionMeasureId) {
				unit = ' ' + displayUnit(productProductionMeasureId);
			}

			return displayNum(canProduce, 1) + unit;
		}
	}
};
