import { _ } from 'vendors';
import EntriesManager from 'components/entries-manager';
import TasksSwitcher from './tasks-switcher';
import StateSwitcher from './state-switcher';
import DateSwitcher from './date-switcher';
import Card from 'components/ui-card/item';
import createTaskAction from 'mod/tasks/create-task-action';

export default EntriesManager.extend({
	initialize () {
		this.initSwitcher();
		this.initFilters();
		this.initCreateTask();
	},
	initSwitcher () {
		this.createNested('tasksSwitcher', {
			viewClass: TasksSwitcher
		});
		this.createNested('stateSwitcher', {
			viewClass: StateSwitcher
		});
		this.createNested('dateSwitcher', {
			viewClass: DateSwitcher
		});
	},
	initCreateTask () {
		const col = this.getOption('entries');
		this.createNested('createTask', {
			viewClass: Card,
			viewOptions: {
				addClass: 'btn light-yellow',
				cardLines: {
					content: { template: _.template('<div><span>поставить задачу</span></div>') }
				},
				events: {
					'click' () {
						createTaskAction({
							success: m => col.add(m)
						});
					}
				}
			}
		});
	}
});
