
import { DumbView } from '../../base/dumb-view';
import busData from 'bus/data';
import enums from 'helpers/enums';
import { measuredAmount } from '../../utils/measures';
import { invokeValue } from 'utils';

const typeShorts = {
	issueForProduction: 'выдано',
	revokeFromProduction: 'возврат'
};

function participantText (model, participantType) {
	const type = model.get(participantType + 'ParticipantType');
	if (type === 'none') {
		return undefined;
	} else if (type === 'contragent') {
		return model.get(participantType + 'ContragentName');
	} else {
		return enums.get('batchTransferParticipants', type);
	}
}


function isDefect (v) {
	return v.model.get('type') === 'defect';
}

function iif (func, ifTrue, ifNot, context) {
	if (invokeValue(func, context, context)) {
		return invokeValue(ifTrue, context, context);
	} else {
		return invokeValue(ifNot, context, context);
	}
}

export const tableSchema = {
	created: {
		thisClassName: 'created fixed',
		onAfterInitialize () {
			if (this.isHeader()) { return; }
			this.value = new Date(this.model.get('created'));
		},
		headerText: 'дата',
		text: v => v.value.toLocaleDateString(),
		smallText: v => v.value.toLocaleTimeString()
	},
	type: {
		thisClassName: 'type',
		headerText: 'тип транзакции',
		text: v => {
			const val = v.model.get('type');
			const text = typeShorts[val] || enums.get('productBatchTransferTypes', val) || val;
			return text;
		}
	},
	amount: {
		thisClassName: 'amount',
		headerText: 'объём транзакции',
		text: v => {
			const {
				transferAmount: sourceAmount,
				transferUnitMeasureId: sourceMeasureId,
				baseUnitMeasureId: destinationMeasureId,
				baseUnitsInTransferUnit: destinationUnitsInSourceUnit
			} = v.model.attributes;

			const options = {
				sourceAmount,
				sourceMeasureId,
				destinationMeasureId,
				destinationUnitsInSourceUnit,
				display: true
			};

			return measuredAmount(options);
		},
		// topText: v => 'склад до: ' + v.model.get('amountInStoreBefore'),
		bottomText: 'объём транзакции'
			// v => 'склад после: ' + v.model.get('amountInStoreAfter')
	},
	source: {
		thisClassName: 'source',
		headerText: 'откуда',
		text: v => participantText(v.model, 'source')
	},
	destination: {
		thisClassName: 'destination',
		headerText: 'куда',
		text: v => participantText(v.model, 'destination')
	},
	// arrowRight: {
	// 	class: DumbView,
	// 	className: 'arrow table-cell',
	// 	html: '<i class="fa fa-arrow-right"></i>'
	// },
	sourceAndDestination: {
		thisClassName: 'source-destination',
		headerText: 'откуда куда',
		text: v => {
			const defect = v.model.get('type') === 'defect';
			if (defect) {
				return '<span>списание брака</span>';
			}
			let src = participantText(v.model, 'source');
			let dst = participantText(v.model, 'destination');
			const arrow = '<span class="fa fa-arrow-right"></span>';
			if (src == null) {
				src = '';
				// arrow = '';
			}
			if (dst == null) {
				dst = '';
				// arrow = '';
			}
			const { sourceParticipantType, destinationParticipantType } = v.model.attributes;
			return `<span class="source ${sourceParticipantType}">${src}</span>${arrow}<span class="destination ${destinationParticipantType}">${dst}</span>`;
		},
		smallText: v => v.model.get('description'),
		bottomText: v => iif(isDefect(v), '', enums.get('productBatchTransferTypes', v.model.get('type')))
	},
	product: {
		thisClassName: 'product fixed',
		headerText: 'продукция',
		text: v => v.model.get('productName')
	},
	batchName: {
		thisClassName: 'batch',
		headerText: 'номер партии',
		text: v => v.model.get('batchIdString'),
		textHtml: (v, nov) => `<span class="batch-identifier">${v == null ? nov : v}</span>`
	},
	productAndBatchNames: {
		thisClassName: 'product fixed',
		headerText: 'продукция',
		smallText: v => v.model.get('batchIdString'),
		smallTextHtml: (v, nov) => `<span class="batch-identifier">${v == null ? nov : v}</span>`,
		text: v => v.model.get('productName')
	},
	actor: {
		thisClassName: 'actor',
		headerText: 'сотрудник',
		text: v => busData.employeeName(v.model.get('actorId'))
	},
	typeIcon: {
		class: DumbView,
		className: 'type-icon table-cell fixed no-print',
		html: '<i></i>'
	}

};
