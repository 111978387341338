import { CommonListBlockView } from "./common-list-view";

import { UiMoleculeView, UiSchemaMoleculeView } from '../../../../components/ui2/molecule';
import { buildSchema, ListItemView } from 'mods/product-production/builds';

import { SchemaTextView, SchemaValueView } from 'components/ui2/schema-properties';


// const ChildView = UiSchemaMoleculeView.extend({
// 	// childView: ,
// 	thisClassName: 'build-item',
// 	customViewOptions () {
// 		const opts = {
// 			modelSchema: buildComponentSchema,
// 			model: this.model
// 		};
// 		return opts;
// 	},
// 	leftItems: [
// 		{
// 			thisClassName: 'amount fixed',
// 			//topText: v => v.label('amount'),
// 			text: v => v.display('amountPostfix'),
// 			bottomText: '&nbsp;',
// 			topText: '&nbsp;',
// 		},
// 		{
// 			thisClassName: 'product fixed',
// 			//topText: v => v.label('componentName'),
// 			text: v => v.display('componentName'),
// 			url: v => '/res/prod/products/' + v.schenaValue('componentId'),
// 			bottomText: '&nbsp;',
// 			topText: '&nbsp;',
// 		},
// 		{
// 			thisClassName: 'party fixed',
// 			//topText: v => v.label('componentBatchIdString'),
// 			text: v => v.display('componentBatchIdString'),
// 			bottomText: '&nbsp;',
// 			topText: '&nbsp;',
// 		},
// 	]
// });

const BuildListItemView = ListItemView.extend({
	initialize() {
		this.initializeClickableMixin();
	},
	url() {
		return '/prod/builds/' + this.model.id;
	}
})

export const BuildsListView = CommonListBlockView.extend({
	modelCollectionKey: 'builds',
	header: 'производство',
	listChildView: BuildListItemView
});