import { BaseMnView } from 'base/base-view';
// import template from './card.html';
import getLabel from 'helpers/get-label';
// import { simpleInvoke } from '../../helpers/invoke';

const template = `<header class="card-header"><%- label %></header>
<% if(icon) {%><i class="icon <%=icon%>"></i><%}%>
<% if(!icon && faIcon) {%><i class="icon fa fa-<%=faIcon %>"></i><%}%>
<div class="card-caption"></div>
`;

const Card = BaseMnView.extend({
	className: 'card',
	template,
	templateContext () {
		return {
			label: this.getLabel(),
			icon: this.getIcon(),
			faIcon: this.getFaIcon()
		};
	},
	getIcon () {
		return this.model.get('icon');
	},
	getFaIcon () {
		return this.model.get('faIcon');
	},
	getLabel () {
		return getLabel(this.model);
	}
});

export default Card;
