import { _ } from 'vendors';
import View from 'base/view';
import BasePage from './default';
const NotFoundView = View.extend({
	template: _.template('Страница не найдена')
});

const NotFoundPage = BasePage.extend({
	name: 'NotFoundPage',
	label: 'страница не найдена',
	Layout: NotFoundView,
	route: '*NotFound',
	relativeRoute: false,
	skipMenu: true
});

export default NotFoundPage;

