import { _ } from 'vendors';
import Yat from 'marionette.yat';
const errorProps = ['description', 'fileName', 'lineNumber', 'name', 'message', 'number', 'status', 'fragment'];

const AppError = Yat.Helpers.mix(Error).with({
	urlRoot: '',
	constructor: function constructor (message, options) {
		if (_.isObject(message)) {
			options = message;
			message = options.message;
		} else if (!options) {
			options = {};
		}

		const error = Error.call(this, message);
		_.extend(this, _.pick(error, errorProps), _.pick(options, errorProps));

		this.captureStackTrace();

		if (options.url) {
			this.url = this.urlRoot + options.url;
		}
	},
	captureStackTrace: function captureStackTrace () {
		if (Error.captureStackTrace) {
			Error.captureStackTrace(this, AppError);
		}
	},
	toString: function toString () {
		return this.name + ': ' + this.message + (this.url ? ' See: ' + this.url : '');
	}
});



export default AppError;
