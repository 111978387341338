import InputPlaceholdered from 'components/controls/input-placeholdered';

export default InputPlaceholdered.extend({
	className: 'c-contact-name',
	label: 'название',
	inputOptions () {
		const res = {
			type: 'text',
			maxLength: 256,
			value: this.getOption('value')
		};
		if (this.getOption('focusOnRender') != null) { res.focusOnRender = this.getOption('focusOnRender'); }
		return res;
	}
});
