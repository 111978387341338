import { _ } from 'vendors';
import BaseView from 'base/view';

import Entries from '../entries';
import Collection from 'mod/commonProcesses/models/processes';

// import Views from 'mod/employees/views';
// import mix from 'helpers/mix';
// import FDC from 'mixins/view/flex-dynamic-column';
// import comparator from 'helpers/comparator';
// import EntriesManager from 'components/entries-manager';

export default BaseView.extend({
	id: 'cmn-procs-active',
	className: 'common-processes fdc-container',
	// isInvulnerable: true,
	template: _.noop,
	regions: {
		content: '.content-region'
	},
	initialize () {
		this.initCollection();
		this.createNested('content', {
			viewClass: Entries,
			viewOptions: {
				type: 'active'
			},
			regionTemplate: { replaceElement: true }
		});
	},
	collectionState: 'active',
	initCollection () {
		if (this.collection) return;
		this.collection = new Collection([], { state: this.getOption('collectionState') });
	},
	onRender () {
		if (this._wasRendered) return;
		this.showContent();
		this._wasRendered = true;
	}

});
