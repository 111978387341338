import Collection from 'base/collection';
import Model from 'base/model';

import { bus } from './bus';
import { recalcComponentValue } from './common';


const PartyModel = Model.extend({
	isNew: () => true,
	recalcForNewAmount (amount, component) {
		const value = recalcComponentValue(component, amount);
		this.setPostAmount(value);
	},
	setPostAmount (value) {
		if (value != null) {
			if (typeof value === 'string') {
				value = value.replace(',', '.');
			}

			value = parseFloat(value, 10);
			if (isNaN(value) || value < 0) {
				value = 0;
			}
		}
		this.set('postAmount', value);
	},
	getPostAmount () {
		return this.get('postAmount');
	}
});

export const PartiesCollection = Collection.extend({
	model: PartyModel,

	initialize (data, options) {
		this.component = options.component;
		this.listenTo(bus, 'new:amount', amount => this.recalcForNewAmount(amount));
	},

	getInputedAmount () {
		return this.reduce((counted, model) => counted + (model.getPostAmount() || 0), 0);
	},

	getAwaitedAmount () {
		return recalcComponentValue(this.component, this.amount);
	},

	recalcForNewAmount (amount) {
		if (arguments.length === 1) {
			this.amount = amount;
		}
		this.invoke('recalcForNewAmount', this.amount, this.component);
		this.trigger('recalced', amount);
	}

});
