// import { BaseView } from 'base/base-view';
import { ButtonView } from 'components/ui2/buttons';
import { HamburgerView } from 'base/view-hamburger';

export const ActionsView = HamburgerView.extend({
	proxyChildEvents: true,
	initialize () {
		this.selector = this.getOption('selector');
		// this.on('all', e => console.log('#e', e));
	},
	baseClassName: 'ui2-list-item-margined',
	customViews: [
		v => ({
			class: ButtonView,
			buttonName: 'transfer',
			text: 'передать',
			canBeEnabled: () => {
				return v.selector.hasSelected();
			}
		})
	],
	setupCustomView (view) {
		// view.on('all', e => console.log('#e', e));
		view.listenTo(this.selector, 'change', () => view.render());
	},
	childViewTriggers: {
		'transfer:click': 'transfer:click'
	}
});
// BaseView.extend({
// 	template: '<div class="ui2-list-item-margined" style="text-align:center;"><button>передать</button></div>'
// });
