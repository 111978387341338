import { _ } from 'vendors';
import View from 'base/view';
import editValue from 'bus/edit';

import { GetInfoMixin } from './common';
import { PartiesCollection } from './PartiesCollection';
import { ComponentInfoView } from './ComponentInfoView';
import { ComponentPartiesView } from './ComponentPartiesView';
import { displayAmount, selectConcreteProductAsync } from '../common';

export const ComponentView = View.extend({
	...GetInfoMixin,
	className: 'cycle-component',
	template: '<div class="info"></div><div class="parties"></div>',
	regions: {
		info: '.info',
		parties: '.parties'
	},

	childViewEvents: {
		'add:party:click' () {
			this.addBatch();
		}
	},

	initialize () {
		this.parties = this.createPartiesCollection();
		this.model.parties = this.parties;
		this.addBatch(true);
	},

	createPartiesCollection () {
		const parties = new PartiesCollection(null, { component: this.model });
		return parties;
	},

	async getConcreteAsync (autoSelect) {
		if (this.model.get('isAbstractGroup')) {

			if (autoSelect) { return; }
			const concretes = this.model.get('concretes') || [];
			console.error('conc', concretes);
			const opts = {
				sourceValues: concretes.filter(m => m.contragentAmount > 0),
				smallText: m => displayAmount(m, 'contragentAmount') + ' у контрагента'
			};

			const concrete = await selectConcreteProductAsync(opts);

			if (concrete) {
				return concrete;
			}

		} else {
			return this.model;
		}
	},

	async addBatch (autoSelect) {

		const concrete = await this.getConcreteAsync(autoSelect);

		if (!concrete) { return; }


		const allbatches = this.getBatches();
		const batches = allbatches.getBatches(concrete.id);

		if (!batches.length) return;

		let batch;

		if (batches.length > 1 && !autoSelect) {
			// const onManyPartiesAction = (batches) => editValue.do({
			// 	header: 'выберите партию',
			// 	sourceValues: batches.map(item => ({ id: item.id, value: item.id, label: `${item.idString} (${item.baseAmount})` })),
			// 	controlType: 'select'
			// }).then(data => _.findWhere(batches, { id: data }));

			const partyId = await editValue.do({
				header: 'выберите партию',
				sourceValues: batches.map(item => ({ id: item.id, value: item.id, label: `${item.idString} (${item.baseAmount})` })),
				controlType: 'select'
			});

			batch = _.findWhere(batches, { id: partyId });

			// batch = await onManyPartiesAction(batches);
			// .then(batch => this._addBatch(batch));
		} else if (batches.length === 1) {
			batch = batches[0];
		}

		this._addBatch(batch);

	},

	_addBatch (batch) {
		this.parties.add(batch);
		this.parties.recalcForNewAmount();
	},

	onRender () {
		this.showParties();
		this.showInfo();
	},

	showInfo () {
		const parties = this.parties;
		const view = new ComponentInfoView({ ...this.getDefOptions(), model: this.model, parties });
		this.showChildView('info', view);
	},

	showParties () {
		const parties = this.parties;
		const view = new ComponentPartiesView({ ...this.getDefOptions(), model: this.model, collection: parties });
		this.showChildView('parties', view);
	}

});
