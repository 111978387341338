import '../api-config';
import paths from 'helpers/paths';
import Model from 'base/model';


const MeasuresForProducts = Model.extend({

	urlRootPattern: paths.api('res:production:measures:for-products'),
	getPropertyName (id, unitId) {
		console.log('## check', JSON.stringify(this.attributes));
		const hash = this.get(id);
		if (!hash) {
			return false;
		}
		return unitId === hash.unitId
			? 'priceUnitMeasureId'
			: unitId === hash.purchaseUnitId
				? 'unitMeasureId'
				: false;
	},
	check (id, unitId) {
		const property = this.getPropertyName(id, unitId);
		return !!property;
	}
});

export default MeasuresForProducts;
