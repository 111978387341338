import BasePage from 'pages/auth';
import Layout from './layout';
// import Emps from './emps';
// import Deps from './deps';

const Page = BasePage.extend({
	moduleRights: { contragents: 'create' },
	name: 'contragents create page',
	label: 'Добавление нового контрагента',
	route: 'create',
	// children:[Emps, Deps],
	icon: 'create contragents',
	Layout,
	wrapLayout: true
});

export default Page;
