import { _, BackboneModel } from 'vendors';
import { isCollection } from 'utils';

import enums from 'helpers/enums';
const schemaProperties = ['label', 'property', 'valueType', 'valueSubType', 'sourceValues', 'range', 'multiple', 'controlType', 'display'];

function isEmpty (val) {
	return val == null || val === '';
}

function displayValue (val, type) {
	if (val == null) { return; }
	if (type === 'number') {
		return _.displayNum(val, 4);
	} else if (type === 'datetime' || type === 'date') {
		return val.toLocaleDateString();
	}
}

export const FilterValueModel = BackboneModel.extend({
	getRequestData () {
		const value = this.getValue();
		if (value !== undefined) {
			const key = this.getRequestKey();
			return { [key]: value };
		}
	},
	getRequestKey () {
		const schema = this.getSchema();
		return schema.property;
	},
	setValue (value) {
		this.set('value', value);
	},
	getValue () {
		return this.get('value');
	},
	getSchema () {
		const schema = Object.assign({ property: this.id }, _.pick(this.attributes, ...schemaProperties));
		return schema;
	},
	getLabel () {
		const schema = this.getSchema();
		return schema.label || schema.property;
	},
	displayValue () {
		const value = this.getValue();
		if (value == null) {
			return '&mdash;';
		}
		const display = this.get('display');
		if (typeof display === 'function') {
			return display(value) || '';
		}
		const { valueType, valueSubType, range, sourceValues } = this.attributes;
		if (valueType === 'enum') {
			return enums.get(valueSubType, value);
		}
		if (range) {
			const f_p = valueType === 'number' ? 'от' : 'с';
			const t_p = valueType === 'number' ? 'до' : 'по';
			const { from, to } = value;
			const fromD = displayValue(from, valueType);
			const toD = displayValue(to, valueType);
			const res = [];
			if (from != null) {
				res.push(f_p);
				res.push(fromD);
			}
			if (to != null) {
				res.push(t_p);
				res.push(toD);
			}
			return res.join(' ');
		}

		if (sourceValues) {
			let items;
			if (isCollection(sourceValues)) {
				items = sourceValues.models;
			} else if (Array.isArray(sourceValues)) {
				items = sourceValues;
			}
			if (items) {
				const found = _.find(sourceValues, { value });
				if (found && typeof found === 'object') {
					const hash = found.attributes ? found.attributes : found;
					if ('label' in hash) {
						return hash.label || '[без названия]';
					}
				}
			}
		}

		return value;
	},
	hasValue (...keys) {
		const value = this.getValue();

		if (this.isRange()) {
			if (!value || typeof value !== 'object') {
				return false;
			}
			let methodName = 'every';
			if (!keys.length) {
				methodName = 'some';
				keys = ['from', 'to'];
			}
			const method = keys[methodName].bind(keys);
			return method(key => !isEmpty(value[key]));
		}
		return isEmpty(value);
	},
	isRange () {
		const schema = this.getSchema();
		return schema.range === true;
	}
});
