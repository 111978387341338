import { productFields } from '../common';

export const fields = [
	...productFields,
	{
        // cssClass: 'value-with-unit valuable-store-change',
		key: 'stats.storeAmount',
		header: 'на складе в данный момент',
		value: v => v.getInBaseUnits({ display: true })
	},
	{
		key: 'stats.contragentsAmount',
		header: 'у конт-ов в данный момент',
		value: v => v.getInBaseUnits({ field: 'stats.contragentsAmount', display: true })
	},
	{
		key: 'stats.totalAmount',
		header: 'всего',
		title: 'склад + контрагенты в данный момент',
		value: v => v.getInBaseUnits({ fieldsSum: ['stats.storeAmount', 'stats.contragentsAmount'], display: true })
	},
	{
		key: 'stats.storeAmountBefore',
		header: 'было на складе',
		cssClass: 'clean',
		value: v => v.getInBaseUnits({ display: true })
	},
	{
		key: 'stats.storeAmountAfter',
		header: 'стало на складе',
		cssClass: 'clean',
		value: v => v.getInBaseUnits({ display: true })
	},
	{
        // cssClass: 'with-sign',
		key: 'stats.amountDelta',
		header: 'изменение склада',
		value: v => v.getInBaseUnits({ fieldsSub: ['stats.storeAmountAfter', 'stats.storeAmountBefore'], display: true, addSign: true, positive: true }),
		addValueCssClass: v => {
            // console.log('-check-', v.positive);
			return v.positive;
		}
	},
	{
		key: 'stats.amountByTypes.produced',
		header: 'произведено',
		value: v => v.getInBaseUnits({ display: true })
	},
	{
		key: 'stats.amountByTypes.purchaseAccept',
		header: 'закуплено',
		value: v => v.getInBaseUnits({ display: true })
	},
	{
		key: 'stats.amountByTypes.usedForProduction',
		header: 'ушло в производство',
		cssClass: 'clean',
		value: v => v.getInBaseUnits({ display: true })
	},
	{
		key: 'stats.amountByTypes.issueForProduction',
		header: 'выдано конт-у',
		value: v => v.getInBaseUnits({ display: true })
	},
	{
		key: 'stats.amountByTypes.revokeFromProduction',
		header: 'возврат',
		value: v => v.getInBaseUnits({ display: true })
	},
	{
		key: 'stats.amountByTypes.defect',
		header: 'брак',
		value: v => v.getInBaseUnits({ display: true })
	},
	{
		key: 'stats.amountByTypes.manualCorrection',
		header: 'ручной ввод',
		value: v => v.getInBaseUnits({ display: true })
	}

    // {
    //     key: 'stats.normalReminder',
    //     header: 'допустимый остаток',
    //     title: 'неснижаемый остаток',
    //     value: v => v.getInBaseUnits({ field: 'stats.normalReminder', display: true })
    // },

    // {
    //     key: 'stats.daysToEmpty',
    //     header: 'дней до 0 (руч.)',
    //     title: 'количество дней до опустошения, ручной ввод',
    //     value: v => v.getDaysToZero({ display: true }),
    // },

    // {
    //     key: 'stats.avgDaysToPurchase',
    //     header: 'ср. закупка в днях (руч.)',
    //     title: 'длительность средней закупки в днях, ручной ввод',
    //     value: v => v.getAvgDaysToPurchase({ display: true }),
    // },

    // {
    //     key: 'stats.daysToEmptyAuto',
    //     header: 'дней до 0 (авто)',
    //     title: 'количество дней до опустошения, автоматический подсчет',
    //     value: v => v.getDaysToZero({ display: true, auto: true }),
    // },

    // {
    //     key: 'stats.avgDaysToPurchaseAuto',
    //     header: 'ср. закупка в днях (авто)',
    //     title: 'длительность средней закупки в днях, автоматический подсчет',
    //     value: v => v.getAvgDaysToPurchase({ display: true, auto: true }),
    // },


    // {
    //     key: 'stats.awaitingAmount',
    //     header: 'ожидается',
    //     value: v => v.getInBaseUnits({ field: 'stats.awaitingAmount', display: true }),
    // },
];
