import { _ } from 'vendors';
import modals from 'helpers/modals';
import modalError from 'helpers/modals/error';
import modalConfirm from 'helpers/modals/confirm';
import CreateTaskView from './views/create';
import CreateRequestTransportView from './views/createRequestTransport';
import CreateRequestPurchaseView from './views/createRequestPurchase';
import smartOpen from 'helpers/smart-open';
// import Task from './models/model';


function createOnParent (task, opts = {}) {
	if (!opts.parent) { throw Error('Can\'t create task on undefined parent'); }
	const tasks = opts.parent.getTasks();
	if (opts.parent.taskEntity) {
		task.set(opts.parent.taskEntity + 'Id', opts.parent.id, { silent: true });
	}
	return tasks.create(task, { wait: true }).then(
		(model) => {
			if (_.isFunction(opts.success)) { opts.success(model); }
			return model;
		},
		(xhr) => modalError('Произошла ошибка', xhr)
	);
}

function createParentless (task, opts = {}) {
	return task.save(null, { wait: true }).then(
		() => {
			if (_.isFunction(opts.success)) { opts.success(task); }
			return task;
		},
		(xhr) => modalError('Произошла ошибка', xhr)
	);
}

const buildTaskCreateView = (options, View) => {
	const CreateView = View || CreateTaskView;
	return new CreateView(options);
};

const taskTypes = {
	default: {
		header: 'Постановка задачи',
		content: buildTaskCreateView
	},
	'request:transport': {
		header: 'Заявка на транспорт',
		content: options => new CreateRequestTransportView(options)
	},
	'request:purchase': {
		header: 'Заявка на закупку',
		content: options => new CreateRequestPurchaseView(options)
	}
};

function createTaskAction (opts = {}) {
	const CreateTask = opts.CreateView;
	let createCallback = opts.parent
		? _.partial(createOnParent, _, opts)
		: _.partial(createParentless, _, opts);

	if (opts.createCallback) {
		createCallback = opts.createCallback;
	}

	const createTaskOptions = _.extend({ parent: opts.parent }, opts.actionOptions);
	const taskType = opts.taskType || 'default';
	const cfg = taskTypes[taskType];
	const header = cfg.header;

	console.log('--->', createTaskOptions);
	const content = cfg.content(createTaskOptions, CreateTask);
	// new CreateTask(createTaskOptions);


	return modals.show({
		header,
		content,
		type: 'full',
		css: {
			wrapper: 'flex-modal-wrapper task-create-modal',
			bg: 'flex-modal-bg',
			contentWrapper: 'flex-modal-content-wrapper',
			box: 'flex-modal-box',
			close: 'flex-modal-close',
			header: 'flex-modal-header',
			content: 'flex-modal-content',
			actions: 'flex-modal-actions',
			resolve: 'flex-modal-resolve',
			reject: 'flex-modal-reject'
		},
		modifiers: {
			'after:render': null,
			refresh: null
		},
		show: {
			reject: true,
			resolve: true
		},
		labels: {
			reject: 'отставить',
			resolve: 'поставить задачу'
		},
		blockResolve: true,
		asPromise: true,
		returnPromise: true,
		childViewTriggers: {
			refresh: 'refresh'
		},
		resolve () {
			return this.content.model;
		}
	}).then(
		(task) => {
			if (!task.isMy() && !task.get('responsibleId')) {
				const dates = task.get('dates');
				delete dates.remindAt;
			}
			return createCallback(task);
		},
		() => {}
	).then(model => {
		if (model == null) { return; }
		modalConfirm('Перейти на страницу задачи?').then(() => smartOpen(model.cardUrl()));
	});
}

export default createTaskAction;
