import { _, moment } from 'vendors';
import Collection from 'base/collection';
import Model from 'base/model';

import EditableProperties from 'mixins/editable-properties';
import Actionable from 'mixins/actionable';
import mix from 'helpers/mix';
import bus from 'bus/models';

// import busData from 'bus/data';
// import editValue from 'bus/edit';
import action from 'helpers/action';
// //import  { SearchProducts } from './collection';
// import modalError from 'helpers/modals/error';
// import smartOpen from 'helpers/smart-open';

import editValue from 'bus/edit';
import modalError from 'helpers/modals/error';
import busViews from 'bus/views';
// import { Model as BackboneModel } from 'backbone';
// import { AddJoined } from '../views/joined-products';
// import { AddJoined as AddJoinedContragent } from '../views/joined-contragents';

import enums from 'helpers/enums';
import ndsEnum from 'helpers/enums/nds-enum';

import busData from 'bus/data';
const BaseModel = mix(Model).with(EditableProperties, Actionable);


export const commonPriceInfos = {
	ndsInside: 'цена включает ндс',
	transportInside: 'цена включает транспортные расходы'
};


export function getPriceInfo (details) {
	// this.display('details.unitMeasureId', { labelType: 'one'});
	const hash = _.pick(details, 'unitMeasureId', 'amount', 'priceUnitMeasureId', 'pricePerUnit', 'priceUnitsInPurchaseUnit', 'priceCurrencyId');
	hash.priceUnitsInPurchaseUnit = hash.priceUnitsInPurchaseUnit || 1;
	hash.pricePerPriceUnit = hash.pricePerUnit * (hash.priceUnitsInPurchaseUnit || 1);
	hash.price = hash.pricePerPriceUnit * (hash.amount || 1);
	hash.unitMeasure = busData.measures(hash.unitMeasureId, 'one'); // this.display('details.unitMeasureId');
	hash.unitMeasureShort = busData.measures(hash.unitMeasureId, 'short'); // this.display('details.unitMeasureId', { labelType: 'short' });
	hash.priceUnitMeasure = busData.measures(hash.priceUnitMeasureId, 'one'); // this.display('details.priceUnitMeasureId');
	hash.priceUnitMeasureShort = busData.measures(hash.priceUnitMeasureId, 'short'); // this.display('details.priceUnitMeasureId', { labelType: 'short' });
	hash.currency = busData.currencies(hash.priceCurrencyId || 'rub');
	hash.currencySign = hash.currency.get('sign');
	hash.hasTwoMeassures = !!hash.priceUnitMeasureId && !!hash.unitMeasureId && hash.priceUnitMeasureId !== hash.unitMeasureId;
	// hash.currencySign = busData.currencies(this.get('priceCurrencyId'), 'sign');
	return hash;
}


export const BaseProductContragentModel = BaseModel.extend({
	properties: {
		contragentId: {
			controlType: 'select-api',
			modelType: 'single',
			multiple: false,
			controlOptions: () => ({
				fetchAtStart: false,
				apiSearch: true,
				collection: bus.request('new:contragents:search')

			}),
			display: {
				label: 'контрагенты',
				transform (v) {
					return (v && bus.request('new:contragents:model', { id: v }).display('name')) || undefined;
				},
				ifEmpty: '&mdash;'
			}
		},
		productId: {
			controlType: 'select-api',
			modelType: 'single',
			multiple: false,
			controlOptions: () => ({
				fetchAtStart: false,
				apiSearch: true,
				collection: bus.request('new:products:search')

			}),
			display: {
				label: 'продукция',
				transform: v => {
					return (v && bus.request('new:products:model', { id: v }).display('fullName')) || undefined;
				},
				ifEmpty: '&mdash;'
			}
		},
		// deprecated
		'details.price': {
			modelType: 'single',
			multiple: false,
			valueType: 'number',
			display: {
				label: 'стоимость за 1 единицу',
				ifEmpty: '&mdash;',
				transform: v => (v && v.toLocaleString('ru')) || undefined
			}
		},
		// deprecated
		'details.priceInfo': {
			multiple: true,
			valueType: 'enum',
			sourceValues: () => commonPriceInfos,
			controlType: 'select',
			display: {
				label: 'уточнения по цене',
				transform: (v) => enums.get(commonPriceInfos, v)
			}
		},
		'details.unitMeasureId': {
			sourceValues: () => busData.measures(),
			controlType: 'select',
			display: {
				label: 'Учетная единица измерения',
				transform: (v, { labelType = 'one' } = {}) => busData.measures(v, labelType)
			}
		},
		// deprecated
		'details.purchaseUnitToStoreUnit': {
			modelType: 'single',
			multiple: false,
			valueType: 'number',
			display: {
				label: 'сколько складских единиц в одной закупочной единице',
				ifEmpty: '&mdash;',
				transform: v => (v && v.toLocaleString('ru')) || undefined
			}
		},


		'details.priceUnitMeasureId': {
			sourceValues: () => busData.measures(),
			controlType: 'select',
			display: {
				label: 'Закупочная единица измерения',
				transform: (v, { labelType = 'one' } = {}) => busData.measures(v, labelType)
			}
		},
		'details.pricePerUnit': {
			modelType: 'single',
			multiple: false,
			valueType: 'number',
			display: {
				label: 'цена за 1 учетную единицу',
				ifEmpty: '&mdash;',
				transform: v => (v && v.toLocaleString('ru')) || undefined
			}
		},
		'details.priceCurrencyId': {
			sourceValues: () => busData.currenciesEnum(),
			controlType: 'select',
			display: {
				label: 'Валюта',
				transform: (v) => busData.currencies(v, 'sign')
			}
		},
		'details.priceUnitsInPurchaseUnit': {
			modelType: 'single',
			multiple: false,
			valueType: 'number',
			display: {
				label: 'количество Учётных единиц в 1 Закупочной',
				ifEmpty: '&mdash;',
				transform: v => (v != null && v.toLocaleString('ru')) || undefined
			}
		},
		'details.nds': {
			modelType: 'single',
			multiple: false,
			valueType: 'enum',
			sourceValues: ndsEnum,
			edit: {
				applyValue (model, prop, val) {
					const fVal = parseFloat(val, 10);
					model.set(prop, fVal);
				}
			},
			display: {
				label: 'ндс',
				ifEmpty: '&mdash;',
				transform: v => (v && ndsEnum[v]) || undefined
			}
		},


		modified: {
			display: {
				label: 'дата изменения',
				transform (v) {
					return v ? moment(v).format('DD MMM YYYY') : '&ndash;';
				},
				alternative (v) {
					return v ? moment(v).fromNow() : '';
				}
			}
		}
	},


	initialize () {
		this.normalizeDetailsHash();
	},

	normalizeDetailsHash () {
		const d = this.get('details');
		if (!d) return;
		// console.log('#det', d);
		if (!('pricePerUnit' in d)) {
			d.pricePerUnit = d.price;
		}
		delete d.price;
		if (!('priceCurrencyId' in d)) {
			d.priceCurrencyId = 'rub';
		}
	},

	getPriceInfo () {
		const details = this.get('details') || {};
		return getPriceInfo(details);
		// //this.display('details.unitMeasureId', { labelType: 'one'});
		// let details = this.get('details');
		// let hash = _.pick(details, 'unitMeasureId', 'amount', 'priceUnitMeasureId', 'pricePerUnit', 'priceUnitsInPurchaseUnit', 'priceCurrencyId');
		// hash.priceUnitsInPurchaseUnit = hash.priceUnitsInPurchaseUnit || 1;
		// hash.pricePerPriceUnit = hash.pricePerUnit * (hash.priceUnitsInPurchaseUnit || 1);
		// hash.price = hash.pricePerPriceUnit * (hash.amount || 1);
		// hash.unitMeasure = this.display('details.unitMeasureId');
		// hash.unitMeasureShort = this.display('details.unitMeasureId', { labelType: 'short' });
		// hash.priceUnitMeasure = this.display('details.priceUnitMeasureId');
		// hash.priceUnitMeasureShort = this.display('details.priceUnitMeasureId', { labelType: 'short' });
		// hash.currency = busData.currencies(this.get('details.priceCurrencyId') || 'rub');
		// hash.currencySign = hash.currency.get('sign');
		// hash.hasTwoMeassures = !!hash.priceUnitMeasureId && !!hash.unitMeasureId;
		// //hash.currencySign = busData.currencies(this.get('priceCurrencyId'), 'sign');
		// return hash;
	}

});



export const ProductContragentModel = BaseProductContragentModel.extend({
	// initialize() {
	// 	this.on('change', () => console.log('ko-ko=-ko', this.attributes));
	// },


	getLabel () {
		let type;
		if (this.collection) {
			type = this.collection.type;
		}
		if (type === 'products') {
			return this.getProductName();
		} else if (type === 'contragents') {
			return this.getContragentName();
		}
	},
	getJoinedEntity (type, opts) {
		if (type === 'products') {
			return this.getProduct(opts);
		} else if (type === 'contragents') {
			return this.getContragent(opts);
		}
	},
	getProduct ({ asModel } = {}) {
		const product = this.get('product');
		if (!asModel) return product;
		const Model = bus.request('products:model');
		return new Model(product);
	},
	getContragent ({ asModel } = {}) {
		const contragent = this.get('contragent');
		if (!asModel) return contragent;
		const model = bus.request('contragents:store').get(contragent.id);
		if (model) return model;
		const Model = bus.request('Contragent');
		return new Model(contragent);
	},
	getProductName () {
		const product = this.getProduct();
		return (product && product.fullName) || '';
	},
	getContragentName () {
		const contragent = this.getContragent();
		return (contragent && contragent.name) || '&ndash;';
	},
	getDetails () {
		return this.get('details');
	},
	getPrice () {
		return this.display('details.price');
	},
	actions: [
		action('remove:joined:contragent', 'удалить привязку', { resourcesProduction: 'productsEdit' }),
		action('edit:joined:contragent', 'редактировать привязку', { resourcesProduction: 'productsEdit' })
	],

	openEntity (type, event) {
		const entity = this.getJoinedEntity(type, { asModel: true });
		if (!entity) return;
		entity.executeAction('open:card', event);
	},

	updateDetails (details) {
		const url = this.url() + '/' + this.getJoinedEntity(this.collection.type).id; // this.get('contragent.id');
		// return BackboneModel.prototype.save.call(this, null, { attrs: { details }, url, method: 'PATCH', wait: true });
		return this.save(null, { attrs: { details }, url, method: 'PATCH' });
	},

	actionRemoveJoinedContragent () {
		if (!this.collection || !this.collection.type) {
			return;
		}
		const type = this.collection.type;
		let id;
		if (type === 'products') {
			id = this.get('product.id');
		} else if (type === 'contragents') {
			id = this.get('contragent.id');
		}
		if (!id) return;
		this.set('id', id);
		this.destroy({ wait: true });
	},

	actionEditJoinedContragent () {
		const EditJoinedContragent = busViews.request('edit:productContragent');
		// let collection = this.getJoinedContragents();
		editValue.do({
			header: 'Изменение прикреплённого контрагента',
			controlView: EditJoinedContragent,
			controlOptions: {
				model: this
			},
			applyLabel: 'изменить',
			resetButton: false,
			cancelLabel: 'отставить'
			// value: this.model.toJSON(),
			// model: this,
		}).then(
			(data) => {
				this.updateDetails(data.details);
				// collection.create(data, {preloader: true, wait:true}).then(
				// 	() => {},
				// 	(xhr) => modalError('неудалось прикрепить контрагента', xhr)
				// );
			},
			error => modalError('Не удалось внести изменения', error)
		);
	}

});

export const ProductContragentCollection = Collection.extend({
	model: ProductContragentModel,
	initialize (models, { type } = {}) {
		this.type = type;
		if (type == null) throw new Error('ProductContragentCollection type must be specified');
	},
	url () {
		let type = this.type;
		type = type.substring(0, 1).toUpperCase() + type.substring(1);
		return this.parent.url() + '/joined' + type;
	}

});
ProductContragentCollection.Contragent = ProductContragentCollection.extend({
	type: 'contragents',
	initialize () {}
});
ProductContragentCollection.Product = ProductContragentCollection.extend({
	type: 'products',
	initialize () {}
});
