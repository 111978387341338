import BaseView from 'base/view';
import beh from 'behaviors';
import template from './layout.html';
import Model from 'mod/groups/models/model';
import entries from 'mod/groups/singleton';
import modalError from 'helpers/modals/error';
export default BaseView.extend({
	template,
	behaviors: [beh.DynamicClass, beh.FormToHash],
	onValuesApply (hash) {
		if (!hash.name) {
			modalError('укажите название группы');
		} else {
			const model = new Model(hash);
			model.save({ wait: true }).then(() => {
				entries.add(model);
			});
		}
	}
});
