import Base from 'components/ui-list/item';
// import Beh from 'behaviors';

const PropertyView = Base.extend({
	// behaviors:[Beh.DynamicClass],
	/* dynamicClassName(){
		let res = [];
		this.getOption('half') && res.push('half');
		let display = this.model.getPropertyDisplay(this.property);
		if(display.preserveWhiteSpace)
			res.push('text-preserve-white-space');
		return res.join(' ');
	}, */
	getPropertyConfig () {
		const schema = this.schema;
		let cfg = this.model && this.model.getPropertyConfig(this.property);
		if (!cfg && schema) {
			cfg = schema.getPropertyConfig(this.property);
		}
		return cfg;
	},
	getPreserveWhiteSpaceCss () {
		const cfg = this.getPropertyConfig();
		// this.model && this.model.getPropertyConfig(this.property);
		if (!cfg) return '';

		if (cfg.type === 'bigtext' || (cfg.display && cfg.display.preserveWhiteSpace)) {
			return 'text-preserve-white-space';
		}
		return '';
	},
	cssClassModifiers: [
		(m, v) => v.getOption('third') ? 'third' : '',
		(m, v) => v.getOption('half') ? 'half' : '',
		(m, v) => v.getPreserveWhiteSpaceCss()
	],
	initialize (options) {
		this.mergeOptions(options, ['property', 'schema']);
		const title = this.getOption('title');
		if (!title) {
			const cfg = this.model.getPropertyDisplay(this.property);
			if (cfg && cfg.title) {
				this.tagTitle = cfg.title;
			}
			// console.log('CFG!', this);
		}
	},
	getTopText () {
		const schema = this.model || this.schema;
		return schema.getPropertyLabel(this.property);
	},
	getText () {
		const opts = this.getOption('textOptions');
		const schema = this.model || this.schema;
		const value = schema.display(this.property, opts);
		if (value != null) {
			return value;
		}
		return this.getOption('text', { args: [this.model, this] });
	},
	getBottomText () {
		const schema = this.model || this.schema;
		const value = schema.display(this.property, { alternative: true });
		if (value != null) {
			return value;
		}
		return this.getOption('bottomText', { args: [this.model, this] });
	},
	onEditClick () {
		this.triggerPropertyEdit();
	},
	onTextClick () {
		if (this.getOption('textAction') === 'edit') {
			this.triggerPropertyEdit();
		}
	},
	triggerPropertyEdit () {
		this.triggerMethod('edit:property:click', this.getOption('property'), this.getOption('propertyModel'), this);
	}
});

export default PropertyView;
