// import View from 'base/view';
import CollectionView from 'base/collection-view';
import BaseLayout from '../base-layout';
import { ProdCycleProductsView } from './prod-cycle';
import user from 'app/user';
import { /* BlockTextHeader, */ BlockFooterTextAction /*, BlockHeaderWithRightButtons */ } from 'components/ui2';
// import ModelProperties from 'components/ui-model-card/properties';
// import h from 'components/ui-model-card/helpers';
import { AddProduceCycle } from 'mod/productionProducts/views/prod-cycle/add';
// import UiCardBlock from 'components/ui-card-block/block';
import smartOpen from 'helpers/smart-open';
import { getCollection as getCycleProdCollection } from './prodCycleProd-collection';

import { buildTabsHeader } from './tabs';
import { ProdCycleProcessesView } from './prod-cycle-processes';
import { getCycleProcessCollection } from './prodCycleProc-collection';

const ProdCycleBoxView = CollectionView.extend({
	initialize (options) {
		this.mergeOptions(options, ['cycle', 'page', 'ac']);
	},
	className: 'double-width product-prod-cycles-block ui2-block',
	customViewsByTabs: {
		root ({ defs, cycle, product } = {}) {
			const addAllowed = user.checkRights({ productProduction: 'editProduction' }) && cycle.canBeEdited();
			const addButton = addAllowed
				? new BlockFooterTextAction({ tagName: 'div', template: 'добавить сырье', clickAction: 'add:cycle:prod:click', cycle, product })
				: null;

			return [
				new ProdCycleProductsView(defs),
				addButton
			];
		},
		procs (opts) {
			const { defs, cycle, product } = opts;
			const procsCol = getCycleProcessCollection(cycle);
			const addAllowed = !procsCol.hasMy();
			const addButton = addAllowed
				? new BlockFooterTextAction({ tagName: 'div', template: 'добавить процесс производства', clickAction: 'add:cycle:proc:click', cycle, product })
				: null;

			return [
				new ProdCycleProcessesView(defs),
				addButton
			];
		}
	},
	customViews () {
		const cycle = this.cycle;
		const product = this.model;
		const defs = { model: cycle, product: this.model };
		const tabId = this.getOption('tabId');
		const tabsCustomViews = this.customViewsByTabs[tabId];
		let views = [];
		if (!tabsCustomViews) {
			console.warn('missing customViews for tab: ', tabId);
		} else {
			views = tabsCustomViews.call(this, { defs, cycle, product });
		}
		return [
			buildTabsHeader(tabId, this.ac, tabId === 'root'),
			...views
		];


		// return [
		// 	// new BlockTextHeader('компоненты ' + cycle.get('name')),
		// 	new BlockHeaderWithRightButtons({
		// 		text: 'компоненты ' + cycle.get('name'),
		// 		buttons: [
		// 			{ id: 'refresh:view', text: 'обновить' }
		// 		]
		// 	}),
		// 	new ProdCycleProductsView(defs),
		// 	(user.checkRights({ productProduction: 'editProduction' }) && cycle.canBeEdited())
		// 		? new BlockFooterTextAction({ tagName: 'div', template: 'добавить сырье', clickAction: 'add:cycle:prod:click', cycle, product })
		// 		: null
		// 	// new BlockFooterTextAction({ template: 'назад к списку', clickAction: 'show:list:click' })
		// ];
	},
	childViewEvents: {
		'click:refresh:view' () {
			this.render();
		},
		// 'show:list:click'(event) {
		// 	let url = this.page.getParent().url(this.ac.args);
		// 	smartOpen(url, event);
		// },
		'add:cycle:prod:click' (event) {
			const cycle = this.cycle;
			const collection = getCycleProdCollection(cycle);
			this.model.executeAction('add:produce:cycle:product', collection, cycle, this.model);
		},
		'add:cycle:proc:click' (event) {
			const cycle = this.cycle;
			const collection = getCycleProcessCollection(cycle);
			this.model.executeAction('add:produce:cycle:process', collection, cycle, this.model);
		}
	}
});

export const Layout = BaseLayout.extend({
	_viewName: 'cycle-id-layout',

	_buildCycleIdContentsViews (tabId) {
		const cycle = this.getOption('cycle');
		const model = this.model;
		const page = this.page;
		const acArgs = this.actionContext.args;
		const ac = this.actionContext;
		const isRoot = tabId === 'root';
		const toCyclesAction = () => {
			let parent = page.getParent();
			if (!isRoot) {
				parent = parent.getParent();
			}
			const url = parent.url(acArgs);
			smartOpen(url);
		};
		return [
			new CollectionView({
				className: 'whole-column double-width',
				customViews () {
					return [
						new ProdCycleBoxView({ cycle, model, page, ac, tabId }),
						new CollectionView({
							className: 'ui2-box  double-width',
							customViews () {
								return [
									new BlockFooterTextAction({
										template: 'назад к списку циклов производства',
										clickAction: toCyclesAction
									})
								];
							}
						})
					];
				}
			})
		];
	},

	cycleIdContents () {
		console.log(' -- cycle id contents --');
		return this._buildCycleIdContentsViews('root');

		/*

		return [
			new AddProduceCycle({
				header: { text: 'основное', addClass:'bg-main-dark'},
				className:'card lined with-border double-width',
				editMode: true,
				model: cycle,
				product: model,
			}),
			new ProdCycleBoxView({ cycle, model, page: this.page, ac: this.actionContext }),
			new CollectionView({
				className: 'ui2-box  double-width',
				customViews() {
					return [
						new BlockFooterTextAction({
							template: 'назад к списку',
							clickAction: () => {
								let url = page.getParent().url(acArgs);
								smartOpen(url, event);
							}
						})
					];
				},
				// childViewEvents:{
				// 	'show:list:click'(event) {
				// 		let url = page.getParent().url(acArgs);
				// 		smartOpen(url, event);
				// 	},
				// }
			}),
		];

		*/
	},
	procsContents () {
		return this._buildCycleIdContentsViews('procs');
	},
	cycleIdUnderMenuContents () {
		const cycle = this.getOption('cycle');
		const model = this.model;
		// const page = this.page;
		// const acArgs = this.actionContext.args;
		return [
			// new CollectionView({
			// 	className: 'ui2-box',
			// 	customViews() {
			// 		return [
			// 			new BlockFooterTextAction({ template: 'назад к списку', clickAction: 'show:list:click' })
			// 		];
			// 	}
			// })
			new AddProduceCycle({
				header: { text: 'основное', addClass: 'bg-main-dark' },
				className: 'card lined with-border',
				editMode: true,
				model: cycle,
				product: model
			})
		];
	}
});
