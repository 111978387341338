import { _ } from 'vendors';
import BaseView from 'base/view';
import template from './block.html';
// import beh from 'behaviors';
import ListItem from 'components/ui-list/item';
import Model from 'base/model';
import mix from 'helpers/mix';
import DynamicClass from 'mixins/view/dynamic-class';
import PreventWheel from 'mixins/view/prevent-wheel';
import CollectionView from 'base/collection-view';

const ActionItem = ListItem.extend({
	monitorViewEvents: false,
	className: 'ui-list-item as-button action',
	text () {
		let label = this.model.get('label');
		if (_.isFunction(label)) {
			const model = this.getModel();
			label = label.call(model, model);
		}
		return label;
	},
	onTextClick (action) {
		const model = this.getModel();
		const actionOptions = this.getOption('actionOptions');
		const options = { view: this, actionOptions };
		let args = this.getOption('actionArguments');
		if (!args) {
			args = [options];
		}
		model && model.executeAction(action.toJSON(), ...args);
		if (!model) { Console.warn('no main model'); }
	},
	getModel () {
		return this.getOption('mainModel');
	}
});

const BaseUiBlock = mix(BaseView).with(DynamicClass, PreventWheel);


export default BaseUiBlock.extend({
	constructor () {
		BaseUiBlock.apply(this, arguments);
		this._toShow = {};
		this._initActionsModelEvents();
		// this.initAction();
	},
	template,
	cssClassModifiers: [
		'card lined with-border'
	],
	getScrollEl () {
		const ul = this.$('ul');
		return ul.length && ul;
	},
	regions: {
		header: { el: 'header' },
		content: { el: 'section' },
		footer: { el: 'footer' }
	},
	onBeforeRender () {
		_.each(['header', 'content', 'footer'], this._buildContent.bind(this));
	},
	onRender () {
		_.each(['header', 'content', 'footer'], this.showContent.bind(this));
		this.triggerMethod('after:ready');
	},
	_initActionsModelEvents () {
		const events = this.getOption('refreshOnActionsModelEvents');
		const model = this.getActionsModel();
		if (!model || !events) return;
		_.each(events, event => {
			this.listenTo(model, event, () => {
				this.render();
			});
		});
	},
	/*
	initAction(){
		let actionName = this.getOption('action');
		let action = this.model && this.model.getAction(actionName);
		if (!action) {
			this.footer = undefined;
			return;
		}
		this.buildActionView(action);
		// let actionModel = new Model(action);
		// this.footer = new ActionItem({model: actionModel, mainModel: this.model, actionOptions: this.getOption('actionOptions', { force: false }) });
	},
	*/
	getActionsModel () {
		return this.getOption('actionsModel') || this.model;
	},
	getActionHash () {
		const actionName = this.getOption('action');
		const model = this.getActionsModel();
		if (_.isString(actionName)) {
			return model ? model.getAction(actionName) : undefined;
		} else if (_.isArray(actionName) && model) {
			return actionName.reduce((memo, name) => {
				const action = model.getAction(name);
				action && memo.push(action);
				return memo;
			}, []);
		}
	},
	buildActionModel (action) {
		return new Model(action);
	},
	buildActionView (action) {
		// debugger;
		if (!action) {
			action = this.getActionHash();
		}
		if (!action) return;
		const actionsModel = this.getActionsModel();
		if (!_.isArray(action)) {
			return this._buildActionView(action, actionsModel);
			// let model = this.buildActionModel(action);
			// return new ActionItem({
			// 	model,
			// 	mainModel: actionsModel,
			// 	actionOptions: this.getOption('actionOptions', { force: false })
			// });
		} else {
			return new CollectionView({
				customViews: () => {
					return action.map(act => {
						return this._buildActionView(act, actionsModel);
					});
				}
			});
		}
	},
	_buildActionView (action, actionsModel) {
		const actionOptions = this.getOption('actionOptions', { force: true });
		const actionArguments = this.getOption('actionArguments', { force: true });
		const model = this.buildActionModel(action);
		return new ActionItem({
			model,
			mainModel: actionsModel,
			actionOptions,
			actionArguments
		});
	},
	showAll () {
		this.showContent('header');
		this.showContent('content');
		this.showContent('footer');
	},
	_buildContent (name) {
		let content = this.getContent(name);
		const options = {};
		// console.log(">>", name, content);
		if (_.isString(content)) {
			content = _.template(content);
			options.replaceElement = false;
		} else if (_.isView(content)) {
			options.replaceElement = true;
		} else {
			this._toShow[name] = false;
			return;
		}
		this._toShow[name] = [content, options];
	},
	showContent (name) {
		const args = this._toShow[name];
		if (!args) return;
		const [content, options] = args;
		if (content) {
			this.showChildView(name, content, options);
		}
	},
	getContent (name) {
		let content = this.getOption(name);
		// if (content == null) return;
		if (name === 'header') {
			if (content === false) {
				return;
			}
			if (_.isView(content)) { return content; }

			if (_.isViewClass(content)) {
				const viewOptions = this.getOption('headerOptions');
				return new content(viewOptions); // eslint-disable-line
			}

			const exts = {
				tagName: 'header',
				template: _.template('')
			};
			if (_.isString(content)) {
				exts.template = _.template(content);
			} else if (_.isObject(content)) {
				const template = content.text ? _.template(content.text) : exts.template;

				_.extend(exts, { template }, content);
			}

			content = BaseView.extend(exts);
		} else if (name === 'footer' && (content == null)) {
			const actionView = this.buildActionView();
			if (actionView) {
				content = actionView;
			}
		}

		if (_.isString(content) || _.isView(content)) { return content; } else if (_.isViewClass(content)) { return this.buildContent(name, content); }
	},
	buildContent (name, View) {
		const options = _.extend({ model: this.getOption('contentModel') }, this.getOption('childContentOptions'), this.getOption(name + 'Options'));
		return new View(options);
	},
	isFooter () {
		return !!this._toShow.footer;
		// return !!this.getOption('footer');
	},
	isHeader () {
		return !!this._toShow.header;
		// return !!this.getOption('header');
	},
	templateContext () {
		const hc = this.getOption('headerClassName');
		const headerClass = hc ? ` class="${hc}"` : '';
		return {
			footer: this.isFooter(),
			header: this.isHeader(),
			headerClass
		};
	}
});
