import { _, $ } from 'vendors';
import Mn from 'backbone.marionette';
import Yat from 'marionette.yat';
import busViews from 'bus/views';
// import busViews from 'bus/views';
// import BaseView from 'base/view';
const Base = Yat.Object;

function create$Element (definition, opts = { tagName: 'div' }) {
	if (definition.jquery) { return definition; } else if (definition instanceof Element) { return $(definition); }

	if (!_.isString(definition)) { throw new Mn.Error({ name: 'create$Element RuntimeError', message: 'element definition must be string, dom element or jquery object' }); }

	definition = definition.trim();

	const tagName = opts.tagName || 'div';
	const $el = $(`<${tagName}/>`);

	if (/^\.\S+$/.test(definition)) {
		// definition is a css class
		const css = definition.substring(1);
		$el.addClass(css);
	} else if (/^#\S+$/.test(definition)) {
		// definition is a dom id
		const id = definition.substring(1);
		$el.attr({ id });
	} else if (/^\[.+\]$/.test(definition)) {
		const tmp = definition.replace(/^\[(.+)\]$/, '$1');
		const aTmp = tmp.split('=');
		const name = aTmp[0];
		let value = aTmp[1];
		const validValue = (value == null || /^('|").*\1$|^$|^[\w-]+$/.test(value));
		const validName = !!name && /^[\w-]+$/.test(name);
		if (!(validValue && validName)) { throw new Mn.Error({ name: 'create$Element RuntimeError', message: 'wrong string definition: ' + definition }); }

		if (value != null && /^'|"/.test(value)) { value = value.substring(1, value.length - 1); }
		$el.attr(name, value);
	}
	return $el;
}

const NestedViewManager = Base.extend({
	initialize (options) {
		this.mergeOptions(options, ['name', 'context', 'condition']);

		if (this.context == null) { throw new Error('NestedViewManager context is null'); }

		if (this.name) {
			const showName = _.camelCase('show:' + this.name);
			this.context[showName] = (opts) => this.show(opts);
		}

		const view = this.getOption('view');

		if (view && !this.getOption('viewClass')) { this.viewClass = view.constructor; }

		if (view && !this.getOption('viewOptions')) { this.viewOptions = _.extend({}, view.options); }

		this.context._nestedViews || (this.context._nestedViews = {});
		this.context._nestedViews[this.name] = this;
		this.listenToOnce(this.context, 'before:destroy', this._onContextDestroy);
		this.listenTo(this.context, 'before:render', this._onContextBeforeRender);

		const regionTemplate = this.getOption('regionTemplate'); // === false ? null : _.extend({}, this.getOption('regionTemplate'));

		if (regionTemplate !== false) {
			// let regionTemplate = this.getOption('regionTemplate');
			const hash = _.extend({ el: `.region-${this.name}`, replaceElement: true }, regionTemplate);
			if (!hash.exist) { this.$region = create$Element(hash.el, hash); }
			this.region = this.context.addRegion(this.name, hash);
		}
	},
	show (opts) {
		const view = this.get(opts);
		if (!view) return;
		if (this.$region) { this.context.$el.append(this.$region); }
		this.context.showChildView(this.name, view);
	},
	get (opts = {}) {
		const view = this.view;
		if (this.needsRebuild(view, opts)) {
			this.view = this.build(opts);
		}
		// if (this.view && !this.view.isDestroyed()) {
		// 	this.view.destroy();
		// }
		return this.view;
	},
	build (opts) {
		const View = this.getOption('viewClass') || busViews.getView('BaseView'); // || BaseView;
		const options = this.buildOptions(opts);
		const condition = this.condition; // this.getOption('condition', { force: false });

		if (!condition || (_.isFunction(condition) && condition.call(this.context, this.context))) {
			const view = new View(options);
			
			return view;
		}
		
	},
	buildOptions (opts) {
		const skipModels = this.getOption('skipModels');
		const model = this.context.model;
		const collection = this.context.collection;
		const contextModels = {};

		if (model != null && !skipModels) { contextModels.model = model; }
		if (collection != null && !skipModels) { contextModels.collection = collection; }

		return _.extend({ parentView: this.context }, contextModels, this.getOption('viewOptions'), opts);
	},
	needsRebuild (view, opts = {}) {
		const needs = !(view && !view.isDestroyed() && !opts.rebuild);
		
		return needs;
	},
	onBeforeDestroy () {
		delete this.context._nestedViews[this.name];
	},
	_onContextBeforeRender () {
		if (!this.view || this.view.isDestroyed()) return;
		this.view.destroy();
	},
	_onContextDestroy () {
		this.view && this.view.destroy();
		delete this.context._nestedViews[this.name];

		// _.each(this.context._nestedViews, view => {
		// 	view.destroy();
		// });
		// delete this.context._nestedViews;
		this.destroy();
	}
});

export default NestedViewManager;
