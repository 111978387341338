import { _, moment } from 'vendors';
// import CollectionView from 'base/collection-view';
import UiListItem from 'components/ui-list/item';
import View from 'base/view';
import Collection from 'base/collection';
import { Model, PagedData } from 'base/models';

// import FetchOnScrollCollectionView from 'base/fetch-on-scroll-collection-view';

import busData from 'bus/data';
import enums from 'helpers/enums';
import ScrollHandlerCollectionView from 'base/scroll-handler-collection-view';

const _measure = v => busData.measures(v, 'short');

const defVal = undefined;
const LogModel = Model.extend({
	defaults: {
		id: defVal,
		type: defVal, // ProductBatchTransferTypes
		actorId: defVal,
		amount: defVal,
		transferUnitMeasureId: defVal,
		transferUnitsInStoreUnit: defVal,
		storeUnitMeasureId: defVal,
		amountInStoreAfter: defVal,
		amountInStoreBefore: defVal,
		batchId: defVal,
		batchIdString: defVal,
		created: defVal,
		description: defVal,
		destinationParticipantType: defVal, // BatchTransferParticipants
		sourceParticipantType: defVal,
		sourceContragentName: defVal,
		sourceContragentId: defVal,
		destinationContragentName: defVal,
		destinationContragentId: defVal
	},
	isDestinationStore () {
		return this.get('destinationParticipantType') === 'store';
	},
	isSourceStore () {
		return this.get('sourceParticipantType') === 'store';
	},
	isIncrease () {
		return this.isDestinationStore() && !this.isSourceStore();
	},
	isDecrease () {
		return !this.isDestinationStore() && this.isSourceStore();
	},
	isStoreChange () {
		return this.isIncrease() || this.isDecrease();
	},
	getSideType () {
		return this.isDecrease()
			? this.get('destinationParticipantType')
			: this.isIncrease()
				? this.get('sourceParticipantType')
				: undefined;
	},
	getChangeSign (letters) {
		if (this.isIncrease()) {
			return letters ? 'plus' : '+';
		} else if (this.isDecrease()) {
			return letters ? 'minus' : '&ndash;';
		}
	},
	properties: {

	},
	displayHash () {

	}
});

const LogsCollection = Collection.extend({
	model: LogModel
});


export function buildLogsCollection (model) {
	const models = [];
	const col = new LogsCollection(models, { parent: model });
	col.productId = model.id;
	col.url = model.url() + '/store/logs';
	return col;
}

export function getLogsCollection (model) {
	if (!model.logsCollection) {
		model.logsCollection = buildLogsCollection(model);
	}
	return model.logsCollection;
}

export function buildPagedData (model, data) {
	return new PagedData(data, {
		url: model.url() + '/store/logs',
		collectionClass: LogsCollection,
		collectionOptions: {
			parent: model
		}
	});
}

export function getPagedData (model, data) {
	if (!model.logsPagedData) {
		model.logsPagedData = buildPagedData(model, data);
	}
	return model.logsPagedData;
}


const EmptyView = UiListItem.extend({
	text () {
		return this.hasFilter() ? 'ничего не найдено' : 'лог пуст';
	},
	hasFilter () {
		return this.getOption('wasFiltered');
	}

});


const LogEntryView = View.extend({
	className: 'ui2-line transaction-entry',
	tagTitle () {
		return this.model.get('description');
	},
	cssClassModifiers: [
		(m) => m.getChangeSign(true)
	],
	template: `
	<div class="created" title="<%- _type %>">
		<span><%= date %></span>
		<span><%= time %></span>
	</div>
	<div class="amount">
		<small><%= transferAmount %> <%= transferUnit %></small>
		<span><%= amountSign %><%= baseAmount %> <%= baseUnit %></span>
	</div>
	<div class="batch" title="<%- type %>: <%= direction %>">
		<span class="id"><%= batchIdString %></span>
		<span class="direction"><%= direction %></span>
		<span class="comment"><%- description %></span>
		<% if (description && 1==2) { %><span class="comment"><%- description %></span><% } %>
	</div>
	<div class="store">
		<span class="actor"><%= actorName %></span>
		<span class="ballance"><%= amountInStoreBefore %> &rarr; <%= amountInStoreAfter %> <%= baseUnit %></span>
	</div>	
	`,
	templateContext () {
		const v = k => this.model.get(k);
		// const d = (...args) => this.model.display(...args);
		const dn = key => _.displayNum(this.model.get('key'), 4);
		const dis = n => _.displayNum(n, 4);
		const attrs = this.model.attributes;
		const created = moment(this.model.get('created'));
		const sideType = this.model.getSideType();
		const bamount = this.model.get('amount');
		const tamount = this.model.get('transferAmount');
		const t_difs = this.model.get('baseUnitMeasureId') !== this.model.get('transferUnitMeasureId');
		const tamount_d = t_difs ? dis(tamount) : '';
		const transferUnit = t_difs ? _measure(v('transferUnitMeasureId')) : '';
		const type = this.model.get('type');
		let _direction;
		const isDefect = this.model.get('type') === 'defect';

		let sourceType = enums.get('batchTransferParticipants', this.model.get('sourceParticipantType'));
		let destinationType = enums.get('batchTransferParticipants', this.model.get('destinationParticipantType'));

		if (attrs.sourceParticipantType === 'contragent') {
			sourceType = attrs.sourceContragentName;
		}

		if (attrs.destinationParticipantType === 'contragent') {
			destinationType = attrs.destinationContragentName;
		}


		if (type === 'defect') {
			_direction = 'списана бракованая продукция';
		} else if (type === 'purchaseAccept') {
			_direction = 'закупка &rarr; склад';
		} // eslint-disable-line
		// else if (type === 'issueForProduction') {
		// 	_direction = `${sourceType} &rarr; ${attrs.destinationContragentName}`;
		// } else if (type === 'usedForProduction') {
		// 	_direction = `${attrs.sourceContragentName} &rarr; ${destinationType}`;
		// }
		else {
			_direction = `${sourceType} &rarr; ${destinationType}`;
		}

		return {
			isDefect,
			date: created.format('DD.MM.YYYY'),
			time: created.format('HH:mm:SS'),
			direction: _direction,
			description: this.model.get('description'),
			baseAmount: dis(bamount),
			baseUnit: _measure(v('baseUnitMeasureId')),

			transferAmount: tamount_d,
			transferUnit,


			amount: dn('amount'),
			amountSign: this.model.getChangeSign(),
			_type: type,
			actorName: busData.employeeName(v('actorId')),
			sideType: sideType ? enums.get('batchTransferParticipants', sideType) : '',
			type: enums.get('productBatchTransferTypes', this.model.get('type')),
			sourceType: enums.get('batchTransferParticipants', this.model.get('sourceParticipantType')),
			destinationType: enums.get('batchTransferParticipants', this.model.get('destinationParticipantType'))
		};
	}
});


export const TransactionsView = ScrollHandlerCollectionView.extend({
	className: 'store-transaction-list',
	emptyView: EmptyView,
	childView: LogEntryView,
	allowedScrollEdges: ['bottom'],
	initialize () {

		const pd = getPagedData(this.model);
		this.collection = new Collection();
		this.listenTo(pd, 'items:fetch', (models, { remove } = {}) => {
			// remove = remove === true;
			const setOptions = { remove, merge: true, add: true };
			this.collection.set(models, setOptions);

			this.triggerMethod('edges:clear');
		});
		pd.fetch();
		this.pagedData = pd;
		// this.listenTo(this.collection, {
		// 	//'id:contains': text => { this._idContains = text; this.filter(); },
		// });
		// this.collection.fetch();
	},
	onBeforeRender () {
		console.log(this.collection.toJSON());
	},
	async onScrolledToBottom () {
		await this.pagedData.fetch({ move: true });
	}

});
