import RightsItemView from 'mod/rights/views/list-item';
// import SearchCollection from 'base/search-collection-view';
import CollectionView from 'base/collection-view';
import editValue from 'components/edit-value';
import enums from 'helpers/enums';



const childView = RightsItemView.extend({
	tagName: 'li',
	editButton: true,
	onEditClick () {
		return editValue({
			header: 'изменение прав',
			multiple: true,
			valueType: 'enum',
			sourceValues: enums.store[this.model.get('enum')],
			value: this.model.get('value')
		}).then(
			(value) => {
				this.trigger('update:rights', this.model, value);
				// this.model.patch({ value }, {wait:true});
			},
			(reason) => {
				if (reason.reason === 'reset') { this.trigger('update:rights', this.model, null); }
				// this.model.patch({ value: null }, {wait:true});
			}
		);
	}
});


export default CollectionView.extend({
	tagName: 'ul',
	emptyView: childView.Empty,
	childView,
	initialize () {
		if (this.getOption('shouldFetch')) {
			this.collection.fetch({
				preloader: { overlay: this }
			});
		}
	},
	childViewEvents: {
		'update:rights' (rightsModel, rightsValue) {
			this.model.executeAction('update:rights', { rightsModel, rightsValue });
		}
	}
});
