import { _ } from 'vendors';
import Model from './models/model';
import Collection from './models/collection';
// import appData from 'mod/appData';
import { internalConvert } from './internal-convert';

export default function convert (hash, opts = {}) {

	return internalConvert(hash, Collection, Model, opts);

	// const modules = appData.getModules();

	// const items = _.map(modules, (value, module) => {

	// 	if (!hash[module]) { 
	// 		hash[module] = '';
	// 	}

	// 	const item = { id: module, value: hash[module], enum: value.rightsType };

	// 	if (opts.asModels) {
	// 		return new Model(item);
	// 	} else {
	// 		return item;
	// 	}

	// });


	// if (opts.asCollection) { 
	// 	return new Collection(items, { principalId: opts.principalId }); 
	// } else { 
	// 	return items; 
	// }

}
