// import { FilterModel } from './ReportFilterModel';
// import { filters } from './filters';
// import { ReportCollection } from './ReportCollection';
// import { tableSchema } from './tableSchema';
// import { TableLayoutView } from 'components/schema-table';
// import { commonTableLayoutMixin } from './table-layout-mixin';
import Collection from 'base/collection';
import busEdit from 'bus/edit';
// import { FilterItemView } from '../../../../../components/filters-v2/FilterItemView';
import { View, CollectionView } from 'base/views';

// const protoGetContentView = TableLayoutView.prototype.getContentView;
// const protoGetContentHeaderView = TableLayoutView.prototype.getContentHeaderView;

const ButtonView = View.extend({
	tagName: 'button',
	template: '<%= text%>',
	triggers: { click: 'click' }
});

function getDate (month, end, offset) {
	const date = new Date();
	date.setMilliseconds(0);
	date.setSeconds(0);
	date.setMinutes(0);
	date.setHours(0);
	if (month) {
		date.setDate(1);
		if (offset) {
			date.setMonth(date.getMonth() + offset);
		}
		if (end) {
			date.setMonth(date.getMonth() + 1);
			date.setMilliseconds(-1);
		}
	} else {
		const add = 1 - (date.getDay() || 7);
		date.setDate(date.getDate() + add);
		if (offset) {
			date.setDate(date.getDate() + offset * 7);
		}
		if (end) {
			date.setDate(date.getDate() + 7);
			date.setMilliseconds(-1);
		}
	}
	console.log('DATE>>>', date.toLocaleString());
	return date;
}


export const SelectInitialPeriodView = CollectionView.extend({
	className: 'period-select-view',
	initialize () {
		const week = false;
		const month = true;
		const end = true;
		const start = false;
		const buttons = [
			{
				text: 'за эту неделю',
				from: getDate(week, start),
				to: getDate(week, end)
			},
			{
				text: 'за предыдущую неделю',
				from: getDate(week, start, -1),
				to: getDate(week, end, -1)
			},
			{
				text: 'за этот месяц',
				from: getDate(month),
				to: getDate(month, end)
			},
			{
				text: 'за предыдущий месяц',
				from: getDate(month, start, -1),
				to: getDate(month, end, -1)
			},
			{
				text: 'произвольный период',
				click: () => busEdit.do({ header: 'укажите период', valueType: 'date', modelType: 'range' })
			}
		];
		this.collection = new Collection(buttons);
	},
	childView: ButtonView,
	childViewEvents: {
		'click' (view) {
			const custom = view.model.get('click');
			if (typeof custom === 'function') {
				custom().then(value => {
					this.setPeriod(value);
				});
			} else {
				this.setPeriod(view.model.attributes);
			}
		}
	},
	setPeriod (rangeDate) {
		const filterModel = this.getOption('filterModel');
		filterModel.setValues({
			period: rangeDate
		});
		filterModel.trigger('period:changed');
	}
});
