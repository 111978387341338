import { _ } from 'vendors';
import enums from 'helpers/enums';
import { dateTransform } from 'helpers/date/smart';
import busModels from 'bus/models';

import { toLocaleStringTransform } from 'utils';

const deliveryNegotiationEnum = {
	true: 'согласована',
	false: 'не согласована'
};



export const BuyProcessProperties = {

	contragentId: {
		controlType: 'select-api',
		sourceValues: () => busModels.request('new:contragents:search', { fetch: true }),
		edit: {
			beforeChange (mod) {
				const json = mod == null ? null : mod.toJSON();
				this.set('contragent', json, { silent: true });
				return (mod && mod.id) || null;
			}
		},
		editOptions: {
			addClass: 'long-content'
		},
		controlOptions: {
			multiple: false,
			fetchAtStart: true,
			apiSearch: true,
			shouldReturnModel: true
		},
		display: {
			label: 'контрагент',
			ifEmpty: 'отсутствует',
			transform () {
				return this.get('contragent.name');
			}
		}
	},

	'buyDetails.payment.advancePercent': {
		modelType: 'single',
		multiple: false,
		valueType: 'number',
		display: {
			label: 'процент аванса',
			ifEmpty: '&mdash;',
			transform: toLocaleStringTransform
		}
	},
	'buyDetails.payment.payFirstTill': {
		type: 'datetime',
		controlOptions () {
			return {
				defaultTime: '12:00',
				revertPredefinedValues: true
			};
		},
		display: {
			label: 'Сделать первый платёж до',
			transform: (v, o) => dateTransform(v, _.extend(o, { smartDateOptions: { time: true } })), // (v) => smartDate.main(v,{time:true})
			alternative: (v) => dateTransform(v, { format: 'fromNow', smartDateOptions: { time: true } })
		}
	},
	'buyDetails.payment.payFullTill': {
		type: 'datetime',
		controlOptions () {
			return {
				defaultTime: '12:00',
				revertPredefinedValues: true
			};
		},
		display: {
			label: 'Поолностью оплатить до',
			transform: (v, o) => dateTransform(v, _.extend(o, { smartDateOptions: { time: true } })), // (v) => smartDate.main(v,{time:true})
			alternative: (v) => dateTransform(v, { format: 'fromNow', smartDateOptions: { time: true } })
		}
	},
	// 'buyDetails.payment.daysToRestPayment': {
	// 	modelType: 'single',
	// 	multiple: false,
	// 	valueType: 'number',
	// 	display: {
	// 		label: 'количество дней до полной оплаты',
	// 		ifEmpty: '&mdash;',
	// 		transform: toLocaleStringTransform
	// 	}
	// },
	'buyDetails.payment.totalPrice': {
		modelType: 'single',
		multiple: false,
		valueType: 'number',
		display: {
			label: 'полная сумма',
			ifEmpty: '&mdash;',
			transform: toLocaleStringTransform
		}
	},
	'buyDetails.payment.donePriceAmount': {
		modelType: 'single',
		multiple: false,
		valueType: 'number',
		display: {
			label: 'уже выплачено',
			ifEmpty: '&mdash;',
			transform: toLocaleStringTransform
		}
	},


	'buyDetails.delivery.responsible': {
		type: 'enum',
		multiple: false,
		sourceValues: () => enums.store.deliveryResponsibles,
		display: {
			label: 'кто осуществляет доставку',
			ifEmpty: '&mdash;',
			transform: (v) => enums.get('deliveryResponsibles', v)
		}
	},

	'buyDetails.delivery.deliverAtPlan': {
		type: 'datetime',
		controlOptions () {
			return {
				defaultTime: '12:00',
				revertPredefinedValues: true
			};
		},
		display: {
			label: 'Запланированная дата доставки',
			transform: (v, o) => dateTransform(v, _.extend(o, { smartDateOptions: { time: true } })), // (v) => smartDate.main(v,{time:true})
			alternative: (v) => dateTransform(v, { format: 'fromNow', smartDateOptions: { time: true } })
		}
	},

	'buyDetails.delivery.deliverAtFact': {
		type: 'datetime',
		controlOptions () {
			return {
				defaultTime: '12:00',
				revertPredefinedValues: true
			};
		},
		display: {
			label: 'Фактическая дата доставки',
			transform: (v, o) => dateTransform(v, _.extend(o, { smartDateOptions: { time: true } })), // (v) => smartDate.main(v,{time:true})
			alternative: (v) => dateTransform(v, { format: 'fromNow', smartDateOptions: { time: true } })
		}
	},
	'buyDetails.delivery.isDeliveryNegotiated': {
		type: 'boolean',
		sourceValues: deliveryNegotiationEnum,
		display: {
			label: 'Согласована ли доставка',
			transform: (v) => deliveryNegotiationEnum[v || false]
		}
	},
	'buyDetails.delivery.from': {
		controlType: 'advanced-address',
		controlOptions: {
			predefinedValues (model) {
				const contragentId = model && model.get('contragent.id');
				if (!contragentId) return;

				return busModels.request('contragent:addresses', contragentId).then(data => {
					return Promise.resolve(data.map(item => ({ key: item.label, value: item.value })));
				});
			}
		},
		edit: {
			beforeChange (v) {
				if (!v) return v;
				const newval = {
					text: v.address
				};
				v.coords && Object.assign(newval, v.coords);
				return newval;
			},
			beforeEdit (v) {
				if (!v) return v;
				const newval = {
					address: v.text
				};
				if (v.lat && v.lng) {
					newval.coords = {
						lat: v.lat,
						lng: v.lng
					};
				}
				console.log('BEF ED', newval);
				return newval;
			}
		},
		display: {
			label: 'откуда везем',
			transform: v => {
				if (!v) return;
				return v.text;
			}
		}
	},
	'buyDetails.delivery.to': {
		controlType: 'advanced-address',
		controlOptions: {
			// predefinedValues(model) {
			// 	let contragentId = model && model.get('contragent.id');
			// 	if (!contragentId) return;

			// 	return busModels.request('contragent:addresses', contragentId).then(data => {
			// 		return Promise.resolve(data.map(item => ({ key: item.label, value: item.value })));
			// 	});
			// }
		},
		edit: {
			beforeChange (v) {
				if (!v) return v;
				const newval = {
					text: v.address
				};
				v.coords && Object.assign(newval, v.coords);
				return newval;
			},
			beforeEdit (v) {
				if (!v) return v;
				const newval = {
					address: v.text
				};
				if (v.lat && v.lng) {
					newval.coords = {
						lat: v.lat,
						lng: v.lng
					};
				}
				console.log('BEF ED', newval);
				return newval;
			}
		},
		display: {
			label: 'куда везем',
			transform: v => {
				if (!v) return;
				return v.text;
			}
		}
	}
};

/*
public DeliveryResponsibles Responsible { get; set; }
public DateTime? DeliverAtPlan { get; set; }
public DateTime? DeliverAtFact { get; set; }
public bool? IsDeliveryNegotiated { get; set; }
*/
