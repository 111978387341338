import { measures } from '../../modules/resources/production/measures';

function unitAmount (amount, koef, multiply) {
	return multiply ? amount * koef : amount / koef;
}

export function getUnitAmount (amount, outputUnitsInInput, inputUnitsInOutput) {

	if (!amount) { return amount; }

	if (outputUnitsInInput && inputUnitsInOutput) {
		throw new Error('неправильное использование функции');
	} else if (!outputUnitsInInput && !inputUnitsInOutput) {
		return amount;
	} else if (outputUnitsInInput) {
		return unitAmount(amount, outputUnitsInInput, true);
	} else {
		return unitAmount(amount, inputUnitsInOutput, false);
	}
}

export function displayUnit (unitId, variant = 'short') {
	return measures(unitId, variant);
}
