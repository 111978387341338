import store from './store';
import products from './products';
import sources from './sources';
import contragents from './contragents';
import purchase from './purchase';

const types = {
	store,
	products,
	sources,
	contragents,
	purchase
};

export function getSchemaRaw (reportType) {
	return types[reportType];
}
