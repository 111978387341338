import { BaseModel } from './base-models';
export default BaseModel.extend({
	isNew: () => 1,
	getBoard () {
		return this.collection.parent;
	},
	getModelCollection () {
		const board = this.getBoard();
		const collection = board.getModelsCollection();
		return collection;
	},
	getModel () {
		const collection = this.getModelCollection();
		const model = collection.get(this.id);
		return model;
	}
});
