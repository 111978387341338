import { _ } from 'vendors';
import editValueBus from 'bus/edit';

import modals from 'helpers/modals';
import Select from 'components/controls/select';
import Datetime from 'components/controls/datetime';
import EntityPerson, { ContactIdNameInput } from 'components/controls/entity-person';
import Person from 'components/controls/person';
import PersonName from 'components/controls/person/name';
import HumanName from 'components/controls/person/name/human';
import EmployeesSelect from 'components/controls/employees-select';
import DepartmentsSelect from 'components/controls/departments-select';
import GroupsSelect from 'components/controls/groups-select';
import Input from 'components/controls/input';
import Textarea from 'components/controls/textarea';
import Email from 'components/controls/contact-value/email';
import Phone from 'components/controls/contact-value/phone';
import BooleanSelect from 'components/controls/boolean-select';
import SelectApi from 'components/controls/select-api';
import ProductsSelect from 'components/controls/products-select';
import Address, { AdvancedAddress } from 'components/controls/contact-value/address';
import InputPlaceholdered from 'components/controls/input-placeholdered';

import EditView from './view';
import ContactValue from 'components/controls/contact-value';
import enums from 'helpers/enums';

const controlOptionKeys = [
	'controlType',
	'multiple',
	'valueType',
	'valueSubType',
	'modelType',
	'sourceValues',
	'allowedValues',
	'filterValuesMask',
	'value',
	'resetValue',
	'excludeValues',
	'collection'
];

const editOptionKeys = [
	'controlName',
	'controlView',
	'proxyTo',
	'destroyOnAction',	// immidiately destroys view after action button clicked
	'destroyOnChange', 	// immidiately destroys view after change occur
	'actionOnDestroy', 	// apply this action on close: apply | cancel | reset
	'applyOnChange',		// triggers apply on change
	'noConfirm',
	'applyLabel',
	'resetLabel',
	'cancelLabel',
	'applyButton',
	'resetButton',
	'cancelButton',
	'resetButtonCssClass',
	'resetButtonConfirm',
	'beforeApply',
	'onControlChange'
];



const modalOptionKeys = [
	'header',
	'type',
	'return',
	'addBodyCssClass',
	'parentView'
];

function extractOptions (opts, keys, mergeWith) {
	if (mergeWith) {
		mergeWith = mergeWith.split(/\s*,\s*/gmi);
	}
	const newOpts = {};
	_(keys).each((key) => {
		if (!(key in opts)) return;
		newOpts[key] = opts[key];
		delete opts[key];
	});

	_(mergeWith).each((key) => {
		_.extend(newOpts, opts[key]);
		delete opts[key];
	});

	return newOpts;
}

function normalizeModalOptions (opts) {
	if (opts.type == null) { opts.type = 'full'; }

	return opts;
}

function normalizeEditOptions (opts, cntrl, initOpts = {}) {
	opts.controlView = getControlView(cntrl, opts);
	opts.controlViewOptions = getControlViewOptions(cntrl, initOpts.controlViewOptions);
	opts.value = cntrl.value;
	opts.resetValue = cntrl.resetValue;
}
function normalizeControlOptions (opts, edit, initOpts = {}) {
	opts.controlName = edit.controlName;
	if (opts.valueType === 'date') { opts.noTime = true; }

	if (opts.collection && _.isFunction(opts.collection)) {
		opts.collection = opts.collection();
	}

	_.extend(opts, initOpts.controlOptions);
}

function getControlView (opts, allopts) {
	let View;
	if (allopts.controlView) { return allopts.controlView; }

	switch (opts.controlType) {
	case 'select':
		View = Select; break;
	case 'select-api':
		View = SelectApi; break;
	case 'employeesSelect':
		View = EmployeesSelect; break;
	case 'departmentsSelect':
		View = DepartmentsSelect; break;
	case 'groupsSelect':
		View = GroupsSelect; break;
	case 'productsSelect':
		View = ProductsSelect; break;
	case 'datetime':
		View = Datetime; break;
	case 'contactValue':
		View = ContactValue; break;
	case 'humanName':
		View = HumanName; break;
	case 'personName':
		View = PersonName; break;
	case 'person':
		View = Person; break;
	case 'entityPerson':
		View = EntityPerson; break;
	case 'address':
		View = Address; break;
	case 'advanced-address':
		View = AdvancedAddress; break;
	case 'input-placeholdered':
		View = InputPlaceholdered; break;
	case 'contact-idname':
		View = ContactIdNameInput; break;
	}

	if (View == null) {
		if (opts.valueType === 'enum') {
			View = Select;
		} else if (opts.valueType === 'bigtext') {
			View = Textarea;
		} else if (!opts.valueType || ['text', 'number'].indexOf(opts.valueType) > -1) {
			View = Input;
		} else if (opts.valueType === 'datetime' || opts.valueType === 'date') {
			View = Datetime;
		} else if (opts.valueType === 'phone' || opts.valueType === 'mobilePhone') {
			View = Phone;
		} else if (opts.valueType === 'email') {
			View = Email;
		} else if (opts.valueType === 'boolean') {
			View = BooleanSelect;
		}
	}



	if (View == null) { throw new Error('editValue: control View not detected'); }

	return View;
}

function getControlViewOptions (cntrl, add) {

	const options = _.extend({}, cntrl, add);
	if (options.valueType === 'enum' && !options.sourceValues) {
		options.sourceValues = enums.store[options.valueSubType];
	}
	if (options.valueType === 'datetime') {
		options.valueType = 'date';
	}
	return options;
}

function getShowMethod (opts = {}) {
	let show = () => false;
	if (opts.show == null || opts.show === 'inModal') {
		show = (view, options) => modals.show(view, options);
	} else if (_.isFunction(opts.show)) {
		show = opts.show;
	}
	return show;
}

function prepareEditOptions (opts = {}) {
	const editOptions = extractOptions(opts, editOptionKeys, 'editOptions');
	const controlOptions = extractOptions(opts, controlOptionKeys, 'controlOptions');
	normalizeEditOptions(editOptions, controlOptions, opts);
	normalizeControlOptions(controlOptions, editOptions, opts);
	return editOptions;
}

function prepareModalOptions (opts = {}) {
	const modalOptions = normalizeModalOptions(extractOptions(opts, modalOptionKeys));
	_.extend(modalOptions, opts.modalOptions);
	return modalOptions;
}


editValueBus.reply('edit:value', (...args) => editValue(...args));


const predefinedEdits = {
	flexible: {
		type: 'full',
		css: {
			wrapper: 'flex-modal-wrapper',
			bg: 'flex-modal-bg',
			contentWrapper: 'flex-modal-content-wrapper',
			box: 'flex-modal-box',
			close: 'flex-modal-close',
			header: 'flex-modal-header',
			content: 'flex-modal-content',
			actions: 'flex-modal-actions',
			resolve: 'flex-modal-resolve',
			reject: 'flex-modal-reject'
		},
		modifiers: {
			'after:render': null,
			refresh: null
		},
		show: {
			reject: true,
			resolve: true
		},
		labels: {
			reject: 'отставить',
			resolve: 'поставить задачу'
		},
		blockResolve: true,
		asPromise: true,
		returnPromise: true,
		childViewTriggers: {
			refresh: 'refresh'
		},
		resolve () {
			return this.content.model;
		}
	}
};

function predefinedEdit (type, opts) {
	opts.modalOptions = predefinedEdits[type];
	return editValue(opts);
}


export default function editValue (opts = {}) {
	console.log('edit raw options', { ...opts });
	if (arguments.length === 2) {
		return predefinedEdit.apply(null, arguments);
	}
	const show = getShowMethod(opts);
	const options = prepareEditOptions(opts);

	const modalOptions = prepareModalOptions(opts);

	const view = new EditView(options);
	if (modalOptions.parentView) {
		view.listenTo(modalOptions.parentView, 'destroy', () => view.destroy());
		// view.listenTo(modalOptions.parentView, 'detach', () => view.destroy());
	}

	console.log('edit options', options);
	show(view, modalOptions);

	if (modalOptions.return === 'control') { return view; } else { return view.promise; }
}


editValueBus.doNext = function (options = {}) {
	const { header, content, resolve, show, labels } = options;

	// let CreateTask = opts.CreateView;
	// let createCallback = opts.parent
	// 	? _.partial(createOnParent, _, opts)
	// 	: _.partial(createParentless, _, opts);

	// let createTaskOptions = _.extend({ parent: opts.parent }, opts.actionOptions);
	// let taskType = opts.taskType || 'default';
	// let cfg = taskTypes[taskType];
	// let header = cfg.header;


	// let content = cfg.content(createTaskOptions, CreateTask);
	// //new CreateTask(createTaskOptions);

	const defs = {
		type: 'full',
		css: {
			wrapper: 'flex-modal-wrapper',
			bg: 'flex-modal-bg',
			contentWrapper: 'flex-modal-content-wrapper',
			box: 'flex-modal-box',
			close: 'flex-modal-close',
			header: 'flex-modal-header',
			content: 'flex-modal-content',
			actions: 'flex-modal-actions',
			resolve: 'flex-modal-resolve',
			reject: 'flex-modal-reject'
		},
		modifiers: {
			'after:render': null,
			refresh: null
		},
		blockResolve: true,
		asPromise: true,
		returnPromise: true,
		childViewTriggers: {
			refresh: 'refresh'
		}

	};

	const defsShow = {
		reject: true,
		resolve: true
	};
	const defsLables = {
		reject: 'отставить',
		resolve: 'подтвердить'
	};

	const readyOptions = _.extend({}, defs, options, {
		header,
		content,
		resolve,
		show: _.extend({}, defsShow, show),
		labels: _.extend({}, defsLables, labels)
	});

	return modals.show(readyOptions);
};
