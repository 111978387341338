import { _ } from 'vendors';
import Radio from './radio';
const bus = Radio.channel('views');
bus.getView = function (name, ext) {
	let View = bus.request(name);
	if (_.isObject(ext) && !!View) { View = View.extend(ext); }
	return View;
};
bus.setView = function (name, View) {
	return bus.reply(name, () => View);
};
export default bus;
