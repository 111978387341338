import { $ } from 'vendors';
import View from 'base/view';
import template from './tasks-switcher.html';
export default View.extend({
	className: 'card lined tasks-switcher switcher',
	template,
	cssClassModifiers: [
		(m) => m.get('type')
	],
	events: {
		'click .line' (e) {
			const $line = $(e.target).closest('.line');
			this.model.set('type', $line.data('id'));
		}
	}
});
