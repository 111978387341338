import BaseView from 'pages/view';
import ModelProperties from 'components/ui-model-card/properties';
import h from 'components/ui-model-card/helpers';
// import UiCardBlock from 'components/ui-card-block/block';
// import createLogs from 'components/logs/views/create-nested';
import busData from 'bus/data';
import Subtypes from './subtypes';
import UiCardBlock from 'components/ui-card-block/block';



function getParentIdPropertyConfig (model) {
	const pid = model.get('parentId');
	let pidProp = { editButton: false };
	if (pid) {
		pidProp = {
			editButton: false,
			addClass: 'whole-btn',
			onTextClick () {
				const parent = busData.productTypes(model.get('parentId'));
				parent && parent.executeAction('open:card');
			}
		};
	}
	return pidProp;
}

export default BaseView.extend({
	className: 'productstypes-page',
	template: BaseView.emptyTemplate,
	initialize () {
		const model = this.model;


		this.createNested('main', {
			viewClass: ModelProperties.extend({ className: 'card lined with-border' }),
			viewOptions: {
				header: { text: 'основное', addClass: 'bg-main-dark' },
				properties: [
					h.ui('name', { editButton: model.mainInfoCanBeChanged() }),
					h.ui('parentId', getParentIdPropertyConfig(model))
				]
			},
			regionTemplate: { replaceElement: true }
		});

		// this.createNested('subtypes',{
		// 	viewClass: Subtypes,
		// 	viewOptions:{
		// 		model,
		// 		header: 'подтипы'
		// 	}
		// });

		this.createNested('subtypes', {
			viewClass: UiCardBlock,
			viewOptions: {
				header: 'подтипы',
				headerOptions: {
					className: 'bg-main-dark'
				},
				content: Subtypes,
				contentOptions () {
					return {
						model,
						collection: busData.productTypes()
					};
				},
				action: 'add:type'
			},
			regionTemplate: { replaceElement: true }
		});

		// createLogs(this, this.model.getLogs(), {
		// 	configs:() => ({}),
		// });
	},
	onRender () {
		this.showAllNested();
	}
});
