import { invokeValue } from 'utils';
export const tableRowCommonMixin = {
	isHeader () {
		return this.getOption('onlyHeaders', true) === true;
	},

	ifHeader (headerArg, entryArg) {
		const arg = this.isHeader() ? headerArg : entryArg;
		return invokeValue(arg, this, this);
	}
};
