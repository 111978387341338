import ModelActions from 'components/ui-model-card/actions';


export function actionsBlock (view) {
	view.createNested('third', {
		viewClass: ModelActions,
		viewOptions: {
			// header: 'действия',
			className: 'card lined with-border',
			refreshOnModelEvents: ['change:actions'],
			actions: ['upload:files', 'show:tasksBoard', 'add:to:board', 'remove:from:board', 'complete:process', 'cancel:process', 'restart:process'],
			emptyViewText: '...', // (m) => m.isClosed() ? 'задача завершена' : 'нет доступных действий'
			onBeforeRender () {
				this.model.on('change:actions', () => {
					console.log('==== >>>> ACTIONS CHANGED');
				});
			}
		},
		regionTemplate: { replaceElement: true }
	});
}
