import { _ } from 'vendors';
const buyGoals = {
	'buyProcess.goal.accountantDocs': 'бухгалтерские документы',
	'buyProcess.goal.confirmGoods': 'проверка сырья',
	'buyProcess.goal.negotiateChanges': 'согласование изменений',
	'buyProcess.goal.payment': 'оплатить счет',
	'buyProcess.goal.qualityPassport': 'паспорт качества',
	'buyProcess.goal.receiveGoods': 'получение сырья',
	'buyProcess.goal.receiveInvoice': 'получить счет',
	'buyProcess.goal.sendRequestToSupplier': 'отправить заявку поставщику',
	'buyProcess.goal.shipmentReady': 'готовность к отгрузке',
	'buyProcess.goal.transportRequest': 'заявка на доставку'
};

export const getBuyGoals = () => _.extend({}, buyGoals);
