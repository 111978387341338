import Collection from 'base/collection';
import PagedCollection from 'mixins/paged-collection';
import mix from 'helpers/mix';
import paths from 'helpers/paths';

const BaseCollection = mix(Collection).with(PagedCollection);

export const ReportCollection = BaseCollection.extend({
	constructor: function (data, options) {
		options = options || {};
		if (options.type) {
			this.type = options.type;
		}
		BaseCollection.apply(this, arguments);
	},
	url () {
		return paths.apiNext('reports', this.type);
	}
});
