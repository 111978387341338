import CollapasableBlock from './collapasable-block';

import CollectionView from 'base/collection-view';
import Collection from 'base/collection';
import View from 'base/view';
// import { ClickableBehavior, stringComparator } from './common';
import { ClickableBehavior } from 'behaviors';
import smartOpen from 'helpers/smart-open';

import { cyclePageBaseUrl } from 'mods/resources/production/products/cfg';


import user from 'app/user';
// import busData from 'bus/data';

const CycleView = View.extend({
	behaviors: [ClickableBehavior],
	cssClassModifiers: [
		m => user.isMe(m.get('responsible').id) ? 'my' : ''
	],
	className: 'ui2-block with-wrapper department-product-cycle clickable',
	template: `<div class="wrapper">
    <div class="ui2-molecule cycle">
        <div class="name"><%= name %></div>
    </div>
    <div class="ui2-molecule">
        <div class="responsible"><%= responsible.name %></div>
        <div class="product"><%= product.name %></div>
    </div>
</div>`,
	onClicked (event) {
		const options = { ctrlKey: !event.ctrlKey };
		const url = cyclePageBaseUrl(this.model.get('product').id) + this.model.id;
		smartOpen(url, options);
	}
});

const Cycles = CollectionView.extend({
	initialize () {
		const models = this.getModels();
		this.collection = new Collection(models);
	},
	getModels () {
		const processes = this.model.get('cycles');
		return processes;
	},
	childView: CycleView
    // viewComparator: (v1,v2) => stringComparator([v => v.model.get('product').name, v => v.model.get('owner').name, v => v.model.get('name')], v1, v2)
});


export default CollapasableBlock.extend({
	addClas: 'cycles',
	header: 'Производственные циклы',
	buildContentView () {
		const view = new Cycles({ model: this.model });
		return view;
	}
});
