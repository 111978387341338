import BaseView from 'base/view';
import Entries from './entries';
import Collection from 'mod/productionProducts/models/collection';

export default BaseView.extend({
	id: 'products-search',
	className: 'products fdc-container',
	isInvulnerable: true,
	template: BaseView.emptyTemplate,
	regions: {
		content: '.content-region'
	},
	initialize () {
		this.collection = new Collection();
		this.createNested('content', {
			viewClass: Entries,
			regionTemplate: { replaceElement: true }
		});
	},

	onRender () {
		if (this._wasRendered) return;
		this.showContent();
		this._wasRendered = true;
	}

});
