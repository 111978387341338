
import CollectionView from 'base/collection-view';


import mix from 'helpers/mix';


import ViewManagedCollection from 'mixins/view-managed-collection';
import ScrollHandlerMixin from 'mixins/view/scroll-handler';
import FetchableMixin from 'mixins/collection-view/fetchable';
import FetchOnScrollMixin from 'mixins/collection-view/fetch-on-scroll';

import { EntryView, EntryHeaderView } from './entry-view';

// let BaseView = mix(CollectionView).with(FDC, FetchableMixin, FetchOnScrollMixin, ViewManagedCollection, ScrollHandlerMixin);

const BaseView = mix(CollectionView).with(FetchableMixin, FetchOnScrollMixin, ScrollHandlerMixin, ViewManagedCollection);

export const EntriesView = BaseView.extend({
	initialize () {
		this._viewName = 'EntriesView';
		this.addCustomView(() => {
			return new EntryHeaderView({
				header: true,
                    // className: 'store-item header',
				...this.childViewOptions()
			});
		});
		this.updateHeaderPosition = this._updateHeaderPosition.bind(this);
	},

	childView: EntryView,

	childViewOptions () {
		return {
			period: this.periodFilter,
			reportSchema: this.getOption('reportSchema')
		};
	},
	onBeforeRender () {
		this.periodFilter = this.filtersInstance.get('period');
		if (!this._periodFilterListener) {
			this._periodFilterListener = true;
			this.listenTo(this.periodFilter, 'change', () => {
				this.collection.reset();
			});
		}
	},
	_updateHeaderPosition () {
		const h = this.$('.report-entry-item.header');
		const el = h.get(0);
		if (!el) return;

		el.style.top = this.el.scrollTop + 0 + 'px';
	},

	scrollDirection: 'bottom',
	fixMaxWidthOn: ['childview:childview:render:children'],


    // id:'report-entries-list',
	className: 'report-entries-list',

	initializeManagerView () {},

	attributes () {
		return {
			'data-scrollable': 'report-entries-list'
		};
	},
	events: {
		'scroll' () {
			this.updateHeaderPosition();
		}
	}
});
