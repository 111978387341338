import { _ } from 'vendors';
import View from 'base/view';
import BasePage from './default';
const ErrorView = View.extend({
	template: _.template('<%= message %>'),
	templateContext () {
		const error = this.getOption('error') || {};
		_.defaults(error, { message: 'Произошла неизвестная ошибка' });
		return error;
	}
});

const ErrorPage = BasePage.extend({
	name: 'Error400',
	label: 'страница не найдена',
	Layout: ErrorView,
	layoutOptions () {
		return {
			error: this.error
		};
	},
	onBeforeStart (ac = {}) {
		const options = ac.options || {};
		const args = options.executeArgs || [];
		this.error = args[1];
		Console.warn('ErrorPage:', this.error, options);
	},
	route: '*Error400',
	relativeRoute: false,
	skipMenu: true
});

export default ErrorPage;

