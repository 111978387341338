import { _ } from 'vendors';
import UiCardBlock from 'components/ui-card-block/block';
import { BuyProcessProductsView, BuyProcessProductsFooterView, BuyProcessProductsViewForClipboard } from './BuyProcessProductsView';
import View from 'base/view';
import modals from 'helpers/modals';
import editValue from 'bus/edit';
import Model from 'base/model';
import modalError from 'helpers/modals/error';

function copyFormatted (html) {
	// Create container for the HTML
	// [1]
	const container = document.createElement('div');
	container.innerHTML = html;

	// Hide element
	// [2]
	container.style.position = 'fixed';
	container.style.pointerEvents = 'none';
	container.style.opacity = 0;

	// // Detect all style sheets of the page
	// var activeSheets = Array.prototype.slice.call(document.styleSheets)
	// 	.filter(function (sheet) {
	// 		return !sheet.disabled;
	// 	});

	// Mount the container to the DOM to make `contentWindow` available
	// [3]
	document.body.appendChild(container);

	// Copy to clipboard
	// [4]
	window.getSelection().removeAllRanges();

	const range = document.createRange();
	range.selectNode(container);
	window.getSelection().addRange(range);

	// [5.1]
	document.execCommand('copy');

	// // [5.2]
	// for (var i = 0; i < activeSheets.length; i++) activeSheets[i].disabled = true;

	// // [5.3]
	// document.execCommand('copy');

	// // [5.4]
	// for (var i = 0; i < activeSheets.length; i++) activeSheets[i].disabled = false;

	// Remove the container
	// [6]
	document.body.removeChild(container);
}


const Header = View.extend({
	className: 'header-with-buttons',
	tagName: 'header',
	template: _.template(`
		<button class="plus" data-role="add-product" title="Добавить продукцию"><i></i></button>
		<div>Закупка</div>
		<a data-action="copy-to-buffer" title="Скопировать в буфер обмена для вставки в письмо">скопировать</a>
	`),
	events: {
		'click a[data-action="copy-to-buffer"]' () {
			const view = new BuyProcessProductsViewForClipboard({ process: this.model });
			view._isAttached = true;
			view.render();
			const html = view.el.outerHTML;
			copyFormatted(html);
			modals.message('Позиции скопированы в буфер обмена');
		},
		'click [data-role=add-product]' () {
			const processModel = this.model;
			const options = {
				header: 'Выберите продукцию из каталога',
				controlType: 'productsSelect'
			};
			editValue.do(options).then((arg) => {
				const url = this.model.url() + '/buyproducts/';
				const productModel = new Model();
				productModel.url = url;
				productModel
					.save(null, { attrs: { id: arg.id }, method: 'POST', wait: true })
					.then((data) => {
						processModel.productsToBuyCollection.add(data);
						processModel.trigger('change:actions');
					}, xhr => modalError('Не удалось добавить продукцию', xhr));
			});
		}

	}
});

export function createNestedProductsToBuy (view) {
	view.createNested('productsToBuy', {
		viewClass: UiCardBlock,
		viewOptions: {

			header: new Header({ model: view.model }),
			addClass: 'buyprocess-products-block',
			content: BuyProcessProductsView,
			contentOptions: {
				process: view.model
			},
			footer: BuyProcessProductsFooterView,
			footerOptions: {
				process: view.model
			}
			// modelEvents: {
			// 	'refetch:all'() {
			// 		this.model.fetch(() => this.render());
			// 	}
			// }
			// onInitialize() {
			// 	console.log('chpok initialized');
			// }
			// refreshOnModelEvents: ['change:actions'],
			// actions: ['upload:files','show:tasksBoard','add:to:board','remove:from:board','complete:process', 'cancel:process', 'restart:process'],
			// emptyViewText: '...', //(m) => m.isClosed() ? 'задача завершена' : 'нет доступных действий'
		},
		regionTemplate: { replaceElement: true }
	});
}

export function productsToBuyContent(view) {
	const opts = {
		class: UiCardBlock,
		header: new Header({ model: view.model }),
		addClass: 'buyprocess-products-block',
		content: BuyProcessProductsView,
		contentOptions: {
			process: view.model
		},
		footer: BuyProcessProductsFooterView,
		footerOptions: {
			process: view.model
		}
	}
	return opts;
}