import Details from './collection';
import action from 'helpers/action';
// import modals from 'helpers/modals';
// import modalError from 'helpers/modals/error';
// import EditDetails from './edit';

import editAction from './edit-action';

export default (Base) => Base.extend({

	constructor () {
		const model = this;
		this.registerNestedEntity('details', { class: Details, options: () => ({ parent: model }) });
		Base.apply(this, arguments);
		this.actions.push(
			action('add:details', 'добавить реквизиты')
		);
	},
	getDetails () {
		return this.entity('details');
	},

	actionAddDetails () {
		editAction(null, this);
	}

});
