import { _ } from 'vendors';
import Collection from 'base/collection';
import { SmartLogModel } from './smart-log-model';
import { CommentEntryModel } from './comment-entry-model';

const mixin = {
	model: SmartLogModel,
	_ensureEntry (entry, options = {}) {
		if (!(entry instanceof CommentEntryModel)) {
			entry = new CommentEntryModel(entry);
		}
		if (options.collection && !entry.collection) {
			entry.collection = options.collection;
		}
		return entry;
	},
	addEntry (entry, options = {}) {
		entry = this._ensureEntry(entry, options);
		const last = this.getLastByEntry(entry, options);
		let added;
		if (!options.preprocess) {
			this.trigger('before:new:item', entry, options);
		}
		if (!last) {
			added = this.addByEntry(entry, options);
		} else {
			added = last.addEntry(entry, options);
		}
		// added.baseUrl = this.url;
		if (added && !options.preprocess) {
			this.trigger('new:item', added, options);
		}
		return added;
	},
	getLastByEntry (entry, options) {
		const last = options.preprocess ? this.first() : this.last();
		if (!last) return;

		const lastEntry = last.getLastEntry();
		if (!lastEntry)	return last;

		if (lastEntry.get('actorId') !== entry.get('actorId')) {
			return;
		}

		const lastType = lastEntry.get('type');
		const nextType = entry.get('type');

		const typeChecked = (lastType === 'comment' && nextType === 'comment') || (lastType !== 'comment' && nextType !== 'comment');
		if (!typeChecked) {
			return;
		}

		if (lastEntry.getEntityFullId() !== entry.getEntityFullId()) {
			return;
		}

		const ldi = lastEntry.getDate();
		const ndi = entry.getDate();
		const hour = 1000 * 60 * 60;
		if (Math.abs((ldi.valueOf() - ndi.valueOf())) > hour * 3) {
			return;
		}

		return last;
	},
	addByEntry (entry, { preprocess = false } = {}) {
		// if(entry.get('text').indexOf('ответственного') > -1){
		// 	//console.log('oplty');
		// }
		let evenOrOdd = false;
		if (!entry.isMy()) {
			const last = this.first();
			if (last && !last.isMy()) {
				const lastEntry = last.getFirstEntry();
				if (!lastEntry || lastEntry.get('actorId') !== entry.get('actorId')) {
					evenOrOdd = !last.evenOrOdd();
				} else {
					evenOrOdd = last.evenOrOdd();
				}
			}
		}
		const model = new SmartLogModel({ evenOrOdd }, { commentsUrl: this.url });
		// console.log('-- #', model.cid, model.commentsUrl);
		model.addEntry(entry);
		const addOptions = preprocess ? { at: 0 } : undefined;
		this.add(model, addOptions);
		return model;
	}
};


const EntriesProcessor = Collection.extend({
	initialize () {
		this._EPROC = 'separate';
	},
	processEntries (entries, reset) {
		if (reset) {
			this.reset();
		}
		_.each(entries, (model, index) => this.addEntry(model, { reverseOrder: true, silent: true, _index: index, preprocess: true }));
	}

});
_.extend(EntriesProcessor.prototype, mixin);

export const SmartLogsCollection = Collection.extend({
	initialize () {
		this._EPROC = 'smart';
	},
	url () {
		return this._backCollection?.url();
	},
	setBackend (col) {
		this._backCollection = col;
		this.listenTo(col, 'sync', this._processSync);
	},
	getBackend () {
		return this._backCollection;
	},
	fetchBackend (opts = {}) {
		if (!this._backCollection) { return; }
		if (opts.reset) {
			this.reset();
		}
		this.listenToOnce(this._backCollection, 'fetch:complete', (...args) => this.trigger('fetch:complete', ...args));
		return this._backCollection.fetch(opts);
	},
	isEndOfData () {
		return this._backCollection?.isEndOfData();
	},
	getResultJSON () {
		return this._backCollection.getResultJSON();
	},
	_ensureProcessor () {
		if (!this._processor) {
			this._processor = new EntriesProcessor();
			this._processor.url = this._backCollection?.url();
		} else {
			this._processor.reset(this.first());
		}
	},
	_processSync (col) {
		// console.log('SMARTLOG on SYNC', col);
		this._ensureProcessor();
		this._processor.processEntries(col.models);
		this.set(this._processor.models, { remove: false, merge: true, at: 0 });
		this.trigger('processed', this);
	},
	fakeSet(models) {
		this._ensureProcessor();
		this._processor.processEntries(models);
		this.set(this._processor.models, { remove: false, merge: true, at: 0 });
		this.trigger('processed', this);
	}
});
_.extend(SmartLogsCollection.prototype, mixin);


export const SmartLogsCollection2 = Collection.extend({
	...mixin,
	initialize () {
		this._processor = new EntriesProcessor();
	},
	processEntries (entries, reset) {
		this._processor.processEntries(entries, reset);
		if (reset) {
			this.reset();
		}
		this.set(this._processor.models, { remove: false, merge: true, at: 0 });
		this.trigger('processed', this);
	}
});
