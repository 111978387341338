import { HamburgerView } from 'base/view-hamburger';
import { BaseHeaderView } from 'base/base-view';
import { BaseCollectionView } from 'base/base-collection-view';
import { getNestedCollection } from 'utils/getNestedCollection';

import { UiMoleculeView, UiSchemaMoleculeView } from '../../../../components/ui2/molecule';

// import { SchemaTextView, SchemaValueView } from 'components/ui2/schema-properties';

import { buildComponentSchema } from 'mods/product-production/builds';

const ComponentView = UiSchemaMoleculeView.extend({
	// childView: ,
	thisClassName: 'component-item',
	customViewOptions () {
		console.log(this.model.attributes);
		const opts = {
			modelSchema: buildComponentSchema,
			model: this.model
		};
		return opts;
	},
	leftItems: [
		{
			thisClassName: 'amount fixed',
			//topText: v => v.label('amount'),
			text: v => v.display('amountPostfix'),
			bottomText: '&nbsp;',
			topText: '&nbsp;',
		},
		{
			thisClassName: 'product fixed',
			//topText: v => v.label('componentName'),
			text: v => v.display('componentName'),
			url: v => '/res/prod/products/' + v.schenaValue('componentId'),
			bottomText: '&nbsp;',
			topText: '&nbsp;',
		},
		{
			thisClassName: 'party fixed',
			//topText: v => v.label('componentBatchIdString'),
			text: v => v.display('componentBatchIdString'),
			bottomText: '&nbsp;',
			topText: '&nbsp;',
		},
	]
});

const ComponentsListView = BaseCollectionView.extend({
	thisClassName: 'with-borders components-list',
	initialize () {
		this.collection = getNestedCollection(this.model, { dataKey: 'components' });
	},
	childView: ComponentView
});

export const ComponentsListBlockView = HamburgerView.extend({
	baseClassName: 'ui2-block production-build-components',
	customViewOptions () {
		return {
			model: this.model
		};
	},
	customViews: [
		{
			class: BaseHeaderView,
			thisClassName: 'just-text',
			text: 'сырьё'
		},
		ComponentsListView
	]
});
