// import entries from 'mod/departments/singleton';
// import data from 'bus/data';
import SelectSegmented from 'components/controls/select-segmented';
import SelectApi from 'components/controls/select-api';
import bus from 'bus/models';
import enums from 'helpers/enums';
import { _ } from 'vendors';

export default SelectSegmented.extend({

	SelectView: SelectApi,

	className: 'c-select-products',
	segments: [
		{
			id: 'purchase',
			name: 'закупка',
			filter: v => enums.has(v.model.get('origin'), 'purchase')
		}
	],
	getSelectOptions () {
		const shouldReturnModel = this.getOption('shouldReturnModel') !== false;
		const hash = {
			proxyTo: this,
			multiple: this.getOption('multiple'),
			excludeValues: this.getOption('excludeValues'),
			sourceValues: bus.request('new:products:search', { fetch: true }),
			modelType: 'single',
			// shouldReturnModel: true,
			shouldReturnModel,
			childViewOptions: {
				text: m => m.get('shortName') ? m.display('shortName') : m.display('fullName'),
				smallText: m => m.get('shortName') ? m.display('fullName') : null
			}
		};

		return hash;
	},
	controlsEvents: {
		'control:change' (value) {
			if (value) {
				value = _.omit(value.attributes, '__');
				// _.pick(value.attributes, 'id', 'fullName', 'typeId', 'unitMeasureId');
			}
			this.value = value;
			this.triggerChange(value);
		}
	},
	getValue () {
		return this.value;
	}
});
