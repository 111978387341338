import { _ } from 'vendors';
import Base from 'base/view';
import uiList from 'components/ui-list/item';

export default Base.extend({
	constructor () {
		Base.apply(this, arguments);
		this.initLines();
	},
	className: 'card lined',
	template: Base.emptyTemplate,
	renderOnModelChange: true,
	nestedViewClass: uiList,
	initLines () {
		this.lines = this.getOption('cardLines') || [];
		const NestedView = this.getProperty('nestedViewClass') || Base;
		_(this.lines).each((line, name) => {
			if (_.isFunction(line)) {
				line = line.call(this, this.model, this);
			}
			name = line.name || name;
			// console.log('LINE>', line);
			const model = this.model;
			const options = this.getOption(name + 'Options');
			const lineOpts = _.extend({}, { model }, options, line);
			const View = line.viewClass ? line.viewClass : NestedView;
			this.createNested(name, {
				viewClass: View.extend({ className: name + ' card-line' }),
				viewOptions: lineOpts,
				regionTemplate: { replaceElement: true }
			});
		});
	},
	onRender () {
		_(this.lines).each((line, name) => {
			name = line.name || name;
			const method = _.camelCase('show:' + name);
			this[method]();
		});
	},
	events: {
		click: 'triggerClick'
	},
	triggerClick (e) {
		const action = this.getOption('clickAction', { args: [this.model, this] });
		if (action) {
			if (this.model && _.isFunction(this.model.executeAction)) {
				e.stopPropagation();
				this.model.executeAction(action);
			}
			if (_.isFunction(this.executeAction)) {
				e.stopPropagation();
				this.executeAction(action);
			}
		}
	}
});
