import BasePage from 'pages/auth';
import CreateProcessView from 'mod/commonProcesses/views/create';

const Page = BasePage.extend({
	moduleRights: { processes: 'startProcess' },
	label: 'Запуск нового процесса',
	route: 'start',
	// children:[Production],
	icon: 'processes-start',
	Layout: CreateProcessView,
	wrapLayout: true
});

export default Page;
