import { _ } from 'vendors';
// import template from './layout.html';
import View from 'base/view';
// import store from 'helpers/store';
// import isJqXhr from 'helpers/is-jqxhr';
import token from 'app/token';
// import paths from 'helpers/paths';
// import user from 'app/user';
// import beh from 'behaviors';
// import appBus from 'bus/app';

import preloader from 'helpers/modals/preloader';
import modalError from 'helpers/modals/error';
import user from 'app/user';

const template = '<input type="text"/><button class="apply">-&gt;</button>';

const Layout = View.extend({
	className: 'card maxw600px w100p margin-t',
	template: _.template(template),
	// behaviors:[beh.FormToHash],
	initialize () {
		// this.loginPromise = new Promise((resolve, reject) => {
		// 	this.once('resolve:login', () => resolve());
		// 	this.once('destroy', () => this.inModal ? reject('no login') : resolve());
		// 	//Yat.identity.user.fetch();
		// });
	},
	events: {
		'click .apply' () {
			const hash = {
				username: this.$('input[type="text"]').val(),
				myId: user.id
			};
			this.login(hash);
		}
	},
	// onValuesApply(hash){
	// 	if(!_.size(hash) || !hash.username || !hash.password) return;
	// 	hash.grant_type = 'password';
	// 	this.login(hash);
	// },
	login (hash) {
		const freezer = preloader();
		// $.post(paths.api('admin:login-as'), {
		// 	data: JSON.stringify(hash)
		// });
		token.login(hash.username, undefined, user.id, 'grghts').then(
			() => { },
			(xhr) => { modalError('Не удалось авторизоваться', xhr); }
		).then(
			() => freezer.destroy({ force: true })
		);
	},
	templateContext () {
		return {
			header: this.getOption('header'),
			username: ''// store.get('username'),
		};
	}
});

export default Layout;
