import { _ } from 'vendors';
const ui = function (property, opts) {
	return _.extend({ property }, opts);
};
const hui = function (property, opts) {
	return _.extend({ property, half: true }, opts);
};
export default {
	ui, hui
};
