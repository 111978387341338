import BasePage from 'pages/auth';
import ActiveProcesses from './active';
import ClosedProcesses from './completed';
import AllProcesses from './all';
import StartProcess from './start';
import ProcessPage from './process';
import ProcessesBoard from './board';
// import ProcessesGoals from './goals';

const Page = BasePage.extend({
	// moduleRights: { processes: 'view' },
	label: 'Процессы',
	route: 'procs',
	children: [AllProcesses, ActiveProcesses, ClosedProcesses, ProcessesBoard, StartProcess, ProcessPage],
	icon: 'processes'
});

export default Page;
