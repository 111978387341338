import testValue from 'helpers/validate/test-value';

export default function emailValidate (email, opts = {}) {
	opts.errors || (opts.errors = []);
	if (email == null) {
		if (!testValue(!opts.required, 'nullOrEmpty', opts.errors)) { return false; } else { email = ''; }
	}

	const chunks = email.split('@');
	const left = chunks[0];
	const right = chunks[1];



	if (
		testValue(chunks.length === 2, 'wrongFormat', opts.errors) &&
		testValue(/^[a-z0-9\-_.+]+$/gmi.test(left), 'wrongFormat', opts.errors) &&
		testValue(/^[a-z0-9\-_]+\.[a-z0-9\-_]+(\.[a-z0-9\-_]+)*$/gmi.test(right), 'wrongFormat', opts.errors)
	) { return true; } else { return false; }
}
