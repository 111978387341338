// import { PageWithDivisionsLayout } from 'components/page-sub-division/PageWithDivisionsLayout';
// import { ContragentsLogContent } from './ContragentsLogContent';
import { FilterModel } from './FilterModel';
import { logFilters } from './logFilters';
import { LogContentView } from './LogContentView';
import { LogListView } from './LogListView';
import { LogTableRowView } from './LogTableRowView';
import { TableLayoutView } from '../schema-table';
// import { FilterModel } from './FilterModel';
// import { ProductsContent } from './ProductsContent';
// import { ProductsHeader } from './ProductsHeader';

export const LogLayoutView = TableLayoutView.extend({
	FilterModel,
	getFilterModelData () {
		return logFilters();
	},

	ContentView: LogContentView,
	ListView: LogListView,
	RowView: LogTableRowView

});
