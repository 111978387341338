import { _ } from 'vendors';

function isChar (event) {
	return event.key && event.key.length === 1;
}

function isNumber (event) {
	const num = '\\d';
	const float = '.,';
	const pattern = '^[' + num + float + '-]$';
	const pat = new RegExp(pattern, 'i');
	return pat.test(event.key);
}


function truncateValue (input, maxLength) {
	if (!maxLength) return;
	const overflow = input.value.substring(maxLength);
	const val = input.value.substring(0, maxLength);
	input.value = val;
	return overflow;
}

function processKeydown (opts = {}) {
	let { context, restrictions, event } = opts; /* eslint-disable-line*/
	if (!isChar(event) || !restrictions.charPattern) return;
	if (!restrictions.charPattern.test(event.key)) {
		event.stopPropagation();
		event.preventDefault();
	}
}

function fixNumberMinMax (opts = {}) {
	const { context, restrictions } = opts;
	if (restrictions.actualType !== 'number') return true;
	const value = context.getValue();

	if (value > restrictions.max) { context.setValue(restrictions.max); }
	if (value < restrictions.min) { context.setValue(restrictions.min); }
}



function processKeypress (opts = {}) {
	const { event, restrictions, input, context } = opts;

	if (!isChar(event)) return;

	context.currentValue = input.value;

	if (restrictions.actualType === 'number') {
		let shouldPrevent = false;

		if (!isNumber(event)) {
			shouldPrevent = true;
		}

		if (event.key === ',') {
			input.value += '.';
			shouldPrevent = true;
			event.stopPropagation();
		}

		if (shouldPrevent) {
			event.preventDefault();
		}
	}
}

function processKeyup (opts = {}) {
	const { input, restrictions, context } = opts;
	const len = input.value.length;
	if (len > restrictions.maxLength) {
		const overflow = truncateValue(input, restrictions.maxLength);
		if (overflow && !overflow) { context.trigger('overflow', overflow); }
	}
	triggerChange(opts);
}

function processPaste (opts = {}) {
	const { event, input, restrictions } = opts;

	if (restrictions.type === 'number') {
		event.preventDefault();
		const text = event.originalEvent.clipboardData.getData('text/plain');
		// context.setValue(text, { input: true });
		input.value = text;
	}

	triggerChange(opts);
}

function processBlur (opts = {}) {
	const { input, restrictions, context } = opts;
	if (restrictions.actualType === 'number') {
		const val = parseFloat(input.value);
		if (isNaN(val) && restrictions.min != null) { input.value = restrictions.min; }
	}
	triggerChange(opts);
	if (context.getOption('doneOnBlur') !== false) {
		context.triggerDone(input.value);
	}
}

function processJsChange (opts) {
	fixNumberMinMax(opts);
	processKeyup(opts);
}


function processEnter (opts) {
	const { event, context, input } = opts;

	// context.getOption('doneOnEnter')

	if (event.keyCode === 13) {
		triggerChange(opts);
		context.triggerDone(input.value);
	}
}

// function processRadiocheckbox(opts){
// 	let { context, input } = opts;
// }

function equalValues (val, cur) {
	const vEmpty = val == null || val === '';
	const cEmpty = cur == null || cur === '';
	if (vEmpty && cEmpty) {
		return true;
	} else if (vEmpty !== cEmpty) {
		return false;
	} else {
		return val === cur;
	}
}
function triggerChange (opts = {}) {
	const { context, input } = opts;
	const value = context.fixValueBeforeSet(input.value);
	const isEql = equalValues(value, context.currentValue);
	if (!isEql) {
		context.currentValue = value;
		context.triggerChange(value);
	}
}


export default function restrict (opts = {}) {
	const { restrictions, eventName } = opts;

	// let wasRestricted = false;


	if (!restrictions || !_.size(restrictions)) return;

	if (eventName === 'keydown') { processKeydown(opts); } else if (eventName === 'keypress') {
		processKeypress(opts);
		processEnter(opts);
	} else if (eventName === 'keyup') { processKeyup(opts); } else if (eventName === 'paste') { processPaste(opts); } else if (eventName === 'blur') { processBlur(opts); } else if (eventName === 'js:change') {
		opts.event.stopPropagation();
		processJsChange(opts);
	} else if (eventName === 'radiocheckbox') {
		opts.event.stopPropagation();
		// processRadiocheckbox(opts);
	}
}
