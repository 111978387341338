export const viewsConfig = {
	input: {
		header: 'Сырье'
		// modelFilter: model => model.get('deepness') === 'input',
		// viewFilter (v) { return this.modelFilter(v.model); }
	},
	intermediate: {
		header: 'Полуфабрикаты'
		// viewFilter: v => v.model.get('deepness') === 'intermediate'
	},
	output: {
		header: 'Готовая продукция'
		// viewFilter: v => v.model.get('deepness') === 'output'
	}

};
