import load from './load-signalr';
import cfg from '../../config';
import ready from './ready-promise';
import Handler from './handler';

export default {
	start () {
		if (cfg.socketsLoadScripts) {
			load();
		} else {
			ready.resolve();
		}

		ready.promise.then(() => {
			const handler = this.handler = new Handler({
				hubName: cfg.socketsName
			});
			handler.start();
		}).catch(() => {
			Console.warn('sockets failed to start');
		});
	}
};
