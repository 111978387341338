import BasePage from 'pages/auth';
import { EntriesView } from './EntriesView';
import BatchChild from './batch';
// import { EntriesView } from './EntriesView';
// import Layout from './layout';
// import Collection from 'base/collection';
// import { departmentsApiUrl } from './models';
// import DepartmentPage from './department';

const Page = BasePage.extend({
	moduleRights: { productProduction: 'view' },
	label: 'каталог партий',
	route: 'batches',
	faIcon: 'cube',
	Layout: EntriesView,
	wrapLayout: false,
	children: [BatchChild]
});

export default Page;
