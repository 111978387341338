import ToDoBlock from 'mod/tasks/views/todo';

export function checklistBlock (view) {
	const m = view.model;

	if (view.model.hasCheckList()) {
		view.createNested('todoPane', {
			viewClass: ToDoBlock,
			viewOptions: {
				// header: 'Вехи',
				modelEvents: {
					'new:state change:completeState' () {
						view.render();
					}
				},
				rights: () => ({
					change: m.isNotClosed() && m.amIAdmin(),
					delete: m.isNotClosed() && m.amIAdmin()
				}),
				canBeSwapped () {
					return m.isNotClosed() && m.amIAdmin();
				}
			}
		});
	}
}
