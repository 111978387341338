import { isModel, isSimpleValue, isNotValue } from './is';

export function flatObject (instance, fields, root) {
	if (instance == null || typeof instance !== 'object') return;

	fields = fields || {};
	root = root || '';

	const hash = isModel(instance) ? instance.attributes : instance;

	const props = Object.getOwnPropertyNames(hash);

	for (let x = 0; x < props.length; x++) {
		const name = props[x];
		const prop = instance[name];

		if (isSimpleValue(prop) || Array.isArray(prop)) {
            // (prop == null || typeof prop !== 'object' || (prop instanceof Date || prop instanceof Array)) {

			fields[root + name] = prop;
		} else if (prop && typeof prop === 'object') {

			flatObject(prop, fields, root + name + '.');

		}
	}

	return fields;
}


export function unFlatObject (instance) {
	if (instance == null || instance === window || typeof instance !== 'object') { return instance; }

	const result = {};
	for (const property in instance) {
		setValueByPath(result, property, instance[property], true);
	}
	return result;
}



function getProperty (instance, name) {
	if (isModel(instance)) {
		return instance.get(name);
        // if (name in instance.attributes)
        //     return instance.get(name);
        // else
        //     throw new Error("property not found");
	} else {
		return instance[name];
        // if(name in instance)
        //     return instance[name];
        // else
        //     throw Error("property not found");
	}
}

function getByPathArr (instance, propertyName, pathArr, options) {

	options || (options = {});

	if (instance == null || typeof instance !== 'object') { return; }

	if (typeof propertyName !== 'string' || propertyName === '') { throw new Error('can not get value from object by path. propertyName is empty'); }

	const prop = getProperty(instance, propertyName);
    // try {
    //     prop = getProperty(instance, propertyName);
    //     if (pathArr.length === 0)
    //         return prop;
    // }
    // catch(e) {

    //     if (options.warn) {
    //         console.warn('property not exist:', options.path);
    //     }

    //     return;
    // }

	if (pathArr.length === 0) { return prop; }

	const nextName = pathArr.shift();

	return getByPathArr(prop, nextName, pathArr, options);

}

function setProperty (instance, name, value, silent) {
	if (isModel(instance)) {
        // var sethash = {};
        // sethash[name] = value;
        // var optshash = {};
        // if (silent) optshash.silent = true;
		instance.set(name, value, { silent });
	} else {
		instance[name] = value;
	}
    // var nprop;
    // try {
    //     nprop = getProperty(instance, name);
    // } catch(e) {
    //     //do nothing
    // }
    // return nprop;
	return getProperty(instance, name);
}

function setByPathArr (instance, propertyName, pathArr, value, force, silent) {

	if (typeof propertyName !== 'string' || propertyName === '') { throw new Error('can not set value on object by path. propertyName is empty'); }

	if (pathArr.length === 0) { return setProperty(instance, propertyName, value); }

	let prop = getProperty(instance, propertyName);

    // try {
    //     prop = getProperty.call(this, propertyName);
    // } catch(e){
    //     //do nothing
    // }

	if (typeof prop !== 'object' && !force) {
		return;
	} else if ((prop == null || typeof prop !== 'object') && force) {
		prop = setProperty(instance, propertyName, {});
	}


	const nextName = pathArr.shift();

	return setByPathArr(prop, nextName, pathArr, value, force);
}


export function getValueByPath (instance, pathStr, options) {
	options = options || {};
	options.path || (options.path = pathStr);

	if (instance === window) { throw new Error('can not get value, context is window'); }

	if (typeof pathStr !== 'string' || pathStr === '') { throw new Error('can not get value from object by path. path is empty'); }

	const arr = pathStr.split('.');
	const prop = arr.shift();

	return getByPathArr(instance, prop, arr, options);
}

export function setValueByPath (instance, pathStr, value, force, silent) {
	if (pathStr && typeof pathStr === 'object') {
		value = pathStr.value;
		force = pathStr.force;
		silent = pathStr.silent;
		pathStr = pathStr.path;
	}

	if (typeof pathStr !== 'string' || pathStr === '') { throw new Error('can not set value to object by path. path is empty'); }


	const arr = pathStr.split('.');
	const arrlen = arr.length;
	const prop = arr.shift();

	force = isNotValue(force) ? true : force;

	setByPathArr(instance, prop, arr, value, force, silent);

	if (isModel(instance) && arrlen > 1 && !silent) {
		instance.trigger('change:' + prop, instance);
		instance.trigger('change', instance);
	}

	return instance;

}

