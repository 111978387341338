import { _ } from 'vendors';
import Yat from 'marionette.yat';
// import originalSet from './original-set';
import flat from 'helpers/flat';

const __ = Yat.Functions.common;

function isPath (str) {
	return str.indexOf('.') > -1;
}

function getAttr (context, attr) {
	return __.getByPath(context, attr);
	// return context[attr];
}
function setAttr (context, attr, val) {
	__.setByPath(context, attr, val);
	// context[attr] = val;
}
function deleteAttr (context, attr) {
	if (isPath(attr)) {
		const chunks = attr.split('.');
		const last = chunks.pop();
		const lastContext = __.getByPath(context, chunks.join('.'));
		if (_.isObject(lastContext)) { delete lastContext[last]; }
	} else delete context[attr];
}

export default function BackboneSmartSet (key, val, options) {
	if (key == null || _.isModel(key)) return this;

	// Handle both `"key", value` and `{key: value}` -style arguments.
	let attrs;
	if (typeof key === 'object') {
		attrs = key;
		options = val;
	} else {
		(attrs = {})[key] = val;
	}
	options || (options = {});

	if (options.flat === true) { attrs = flat(attrs); }

	if (!this._validate(attrs, options)) return false;

	// Extract attributes and options.
	const unset = options.unset;
	const silent = options.silent;
	const changes = [];
	const changing = this._changing;
	this._changing = true;

	if (!changing) {
		this._previousAttributes = _.clone(this.attributes);
		this.changed = {};
	}

	const current = this.attributes;
	const changed = this.changed;
	const prev = this._previousAttributes;

	// For each `set` attribute, update or delete the current value.
	for (const attr in attrs) {
		val = attrs[attr];
		if (!_.isEqual(getAttr(current, attr), val)) changes.push(attr);
		if (!_.isEqual(getAttr(prev, attr), val)) {
			setAttr(changed, attr, val);
		} else {
			deleteAttr(changed, attr);
			// delete changed[attr];
		}
		unset ? deleteAttr(current, attr) : setAttr(current, attr, val);
	}

	// Update the `id`.
	if (this.idAttribute in attrs) this.id = this.get(this.idAttribute);

	// Trigger all relevant attribute changes.
	if (!silent) {
		if (changes.length) this._pending = options;
		for (let i = 0; i < changes.length; i++) {
			this.trigger('change:' + changes[i].replace(/\./g, ':'), this, getAttr(current, changes[i]), options);
		}
		if (!changes.length) this.trigger('change', this);
	}

	// You might be wondering why there's a `while` loop here. Changes can
	// be recursively nested within `"change"` events.
	if (changing) return this;

	if (!silent) {
		while (this._pending) {
			options = this._pending;
			this._pending = false;
			this.trigger('change', this, options);
		}
	}
	this._pending = false;
	this._changing = false;
	return this;
}

