import { _ } from 'vendors';
import { stateMixin } from '../../mixins/view/state';

const commonSetOptionsKeys = ['page', 'req'];

export function setOptionsMixin (Base) {
	return {

		...stateMixin,

		_setOptions (options) {
			Base.prototype._setOptions.apply(this, arguments);
			this._setPageWithDivisionsOptions(options, commonSetOptionsKeys);
			if (this.beforeInitialize) {
				this.beforeInitialize(options);
			}
		},
		_setPageWithDivisionsOptions (options, keys) {
			this.mergeOptions(options, keys);
		},
		defaultChildViewOptions () {
			return {
				page: this.page,
				req: this.req,
				model: this.model,
				collection: this.collection
			};
		},
		getDefaultChildViewOptions (options, ext) {
			let result = this.getOption('defaultChildViewOptions');

			const omitKeys = [];
			let { noModel, noCollection, noData } = (options || {});
			if (noData) {
				noModel = noCollection = true;
			}
			if (noModel) { omitKeys.push('model'); }
			if (noCollection) { omitKeys.push('collection'); }
			if (omitKeys.length) {
				result = _.omit(result, ...omitKeys);
			}
			result = _.extend(result, ext);
			return result;
		}
	};
}
