import { CommonListItemView } from '../common/ListItemView';
import enums from 'helpers/enums';
export default CommonListItemView.extend({
	openCardButton: v => `res/prod/products/${v.model.get('productId')}/prod-from/${v.model.id}`,
	content: {
		topText: v => enums.get('productionCycleTypes', v.model.get('type')),
		text: v => v.model.get('name'),
		bottomText: v => enums.get('productionCycleStates', v.model.get('state'))
	}
});
