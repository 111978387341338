import { _ } from 'vendors';

function get (val, label, hash) {
	return _.isFunction(val)
		? val(label, hash)
		: val;
}

const defMessages = {
	required: (l) => 'необходимо заполнить ' + l
};

function getMessage (type, key, cfg = {}, hash) {
	const messages = cfg.messages || {};
	const message = get(messages[type], cfg.label, hash) ||
	get(defMessages, cfg.label, hash);
	return message;
}

export default function error (type, _message, key, cfg, hash) {
	const message = _message || getMessage(type, key, cfg, hash);
	return {
		type,
		key,
		message
	};
}
