import View from 'base/view';
import mix from 'helpers/mix';
import ControlsMixin from 'components/controls/mixin';
import InputPlaceholdered from 'components/controls/input-placeholdered';
import template from './template.html';
import testValue from 'helpers/validate/test-value';

export default mix(View).with(ControlsMixin).extend({
	className: 'c-human-name',
	template,
	regions: {
		last: { el: '.last', replaceElement: true },
		first: { el: '.first', replaceElement: true },
		middle: { el: '.middle', replaceElement: true }
	},
	initialize () {
		this.initModel();
	},
	initModel () {
		this.value = this.getOption('value', { deep: false }) || {};
	},
	onRender () {
		this.showControl('last');
		this.showControl('first');
		this.showControl('middle');
		this.trigger('content:ready');
	},
	getPlaceholder (name) {
		return name === 'first'
			? 'имя'
			: name === 'last'
				? 'фамилия'
				: name === 'middle'
					? 'отчество'
					: undefined;
	},
	showControl (name) {
		const view = new InputPlaceholdered({
			DEBUG: true,
			label: this.getPlaceholder(name),
			className: `c-hn-${name}`,
			value: this.value[name],
			proxyTo: this,
			controlName: name,
			type: 'text',
			maxLength: 128,
			focusOnRender: false
		});
		this.showChildView(name, view);
	},
	getChunk (name) {
		return this.value[name];
	},
	setChunk (name, value) {
		this.value[name] = value;
		this.triggerChange(this.getValue());
	},
	controlsEvents: {
		'first:change' (value) { this.setChunk('first', value); },
		'last:change' (value) { this.setChunk('last', value); },
		'middle:change' (value) { this.setChunk('middle', value); },
		'first:done' () { this.triggerDone(this.getValue()); },
		'last:done' () { this.triggerDone(this.getValue()); },
		'middle:done' () { this.triggerDone(this.getValue()); }
	},
	validate (value, opts = {}) {
		opts.errors || (opts.errors = []);
		const check = (this.getChunk('first') || '').trim() + (this.getChunk('last') || '').trim() + (this.getChunk('middle') || '').trim();
		if (testValue(check.length > 0, 'invalidHumanName', opts.errors)) {
			return true;
		} else {
			return false;
		}
	}
});
