import Collection from 'base/collection';
import { bus } from './bus';

export const ComponentsCollection = Collection.extend({

	initialize () {
		this.listenTo(bus, 'new:amount', amount => this.produceAmount = amount); // eslint-disable-line
	},

	getComponents () {
		return this.map(this.toPostComponent.bind(this));
	},

	toPostComponent (component) {
        // console.log(component.toJSON());
		const res = {
			productId: component.id,
			productName: component.get('name'),
			awaitedAmount: component.get('awaitedAmount'),
			parties: component.parties.map(this.toPostParty.bind(this)),
			optional: component.get('optional')
		};
		// console.log('COMP', res);
		return res;
	},

	toPostParty (party) {
		return {
			partyId: party.id,
			postAmount: party.get('postAmount'),
			baseAmount: party.get('baseAmount'),
			idString: party.get('idString')
		};
	},
	getPostModel () {
		return {
			produceAmount: this.produceAmount,
			components: this.getComponents()
		};
	}
});
