import { _ } from 'vendors';
import Collection from 'base/collection';
import { Behavior } from './behavior';
import { setupManipulatorEnter } from './helpers';



export const InteractionBehavior = Behavior.extend({
	constructor () {
		Behavior.apply(this, arguments);
		this._viewEntered = _.debounce(this.viewEntered, 50, true);
	},
	onAddChild (parent, view) {
		setupManipulatorEnter({
			$el: view.$el,
			onManipulatorEnter: (...args) => {
				view.trigger('enter');
				this._viewEntered(view, args);
			},
			onStart: () => this.startInteraction(view),
			onEnd: (event, args) => this.endInteraction(view, event, args),
			// onMove: (args) => {
			// 	_.each(this._moveHandlers || {}, handler => handler(...args));
			// },
			selector: this.getOption('selector'),
			elementEnterArguments: () => this.getOption('elementEnterArguments')
		});

		this.setupView(view);
	},
	viewEntered (view, args = []) {
		this.view.triggerMethod('view:entered', view, ...args);
		this.triggerMethod('own:view:entered', view, ...args);
	},
	startInteraction (view) {
		this.dragging = view;
		view.dragging = true;
		const options = this.getOption('interactionStartOptions');
		this.view.triggerMethod('interaction:start', view, options);
		this.triggerMethod('own:interaction:start', view, options);
	},
	endInteraction (view, event, args) {
		const options = this.getOption('interactionEndOptions') || {};
		options.args = args;
		options.event = event;
		this.view.triggerMethod('interaction:end', view, options);
		this.triggerMethod('own:interaction:end', view, options);
		delete this.dragging;
		delete view.dragging;
	},
	setupView: _.noop
});

export const SwappableBehavior = InteractionBehavior.extend({
	onInitialize (view) {
		this.initializeSwappable(this.options, view.options);
	},
	property: 'index',
	swapOptions: { silent: true },
	initializeSwappable (options = {}, viewOptions = {}) {
		this.mergeOptions(options, ['swap', 'property', 'canBeSwapped']);
		this.mergeOptions(viewOptions, ['canBeSwapped']);

		if (_.isString(this.swap)) {
			this.swap = this.view.getOption(this.swap, { force: false });
		}
		if (_.isFunction(this.swap)) {
			this.swap = this.swap.bind(this.view);
		} else if (this.property) {
			this.swap = this.swapModelsProperty;
		} else {
			delete this.swap;
		}
		this.changedModels = new Collection();
	},

	interactionEndOptions () {
		const models = _.clone(this.changedModels.models);
		this.changedModels.reset();
		return models;
	},


	viewEntered (view) {
		const result = this.view.triggerMethod('view:entered', view);
		if (result === false) return;
		this.swapViews(view);
	},



	storeChangedModel (model) {
		this.changedModels.add(model);
	},
	canBeSwapped () {
		return true;
	},

	swapViews (entered) {
		if (!this.dragging) return;
		if (!this.canBeSwapped(entered)) return;

		// console.log('SWAP', this.cid, this.view.cid, entered, this.dragging);

		this.view.swapChildViews(this.dragging, entered);

		this.dragging.model && this.storeChangedModel(this.dragging.model);
		entered.model && this.storeChangedModel(entered.model);

		this.view.triggerMethod('swap:views', this.dragging, entered);
	},
	onSwapViews (one, two) {
		this.swap(one, two, this.getOption('swapOptions'));
	},
	swapModelsProperty (v1, v2, opts) {
		if (this.property == null) return;
		const key = this.property;

		if (v1.model == null || v2.model == null) return;

		const m1 = v1.model;
		const m2 = v2.model;
		const temp = m1.get(key);
		m1.set(key, m2.get(key), opts);
		m2.set(key, temp, opts);
	}

});
