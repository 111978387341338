import BasePage from 'pages/auth';
import Layout from './layout';
import Model from 'mod/productionMeasures/models/model';
import PageErr from 'pages/error';
import busData from 'bus/data';
const Page = BasePage.extend({
	label: (p) => 'Единица измерения: ' + ((p.display && p.display('one')) || ''),
	route: ':id',
	skipMenu: true,
	Layout,
	wrapLayout: true,
	// socketGroups: (m) => 'producttype:'+ m.id.toString().toLowerCase(),
	preventStart: function (ac) {
		const prev = BasePage.prototype.preventStart.call(this, ac);
		if (prev) return prev;

		if (!ac) { return; }

		if (!busData.measures(ac.args.id)) { return PageErr.NotFound('единица измерения не найдена', this.url()); }
	},
	onBeforeStart (ac) {
		const model = new Model({ id: ac.args.id });
		this.addModel(model);
		// this.addStartPromise(model.fetch());
	}
});

export default Page;
