import { bus } from './bus';
import { GetInfoMixin } from './common';
import CollectionView from 'base/collection-view';
import { ComponentPartyView, EmptyComponentPartyView } from './ComponentPartyView';


export const ComponentPartiesView = CollectionView.extend({
	...GetInfoMixin,
	className: 'cycle-component-parties',
	initialize () {
		this.listenTo(bus, this.model.id + ':batch:selected', (...args) => this.onBatchSelected(...args));
	},
	onBatchSelected (batch) {
		const exist = this.collection.get(batch.id);
		if (exist) return;
		this.collection.add(batch);
	},
	childViewOptions () {
		return {
			...this.getDefOptions(),
			component: this.model
		};
	},
	childView: ComponentPartyView,
	emptyView: EmptyComponentPartyView
});
