import { moment } from 'vendors';
import BaseView from 'base/view';
import template from './controls.html';
export default BaseView.extend({
	className: 'c-dt-controls',
	template,
	events: {
		'click button:first-child' (e) {
			e.stopPropagation();
			this.model.addMonth(-1);
		},
		'click button:last-child' (e) {
			e.stopPropagation();
			this.model.addMonth(1);
		}
	},
	ui: {
		year: '.c-dt-c-year',
		monthName: '.c-dt-c-month'
	},
	modelEvents: {
		'change' () {
			if (this.isRendered()) {
				this.ui.year.html(this.model.get('year'));
				this.ui.monthName.html(this.templateContext().monthName);
			}
		}
	},

	templateContext () {
		return {
			monthName: moment().month(this.model.get('month')).format('MMMM')
		};
	}
});
