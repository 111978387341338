// import BottomAction from 'components/ui-list/action';
// import View from 'base/collection-view';
// import childView from './contact-list-item';
import ContactsList from './contacts-list';
export default ContactsList.extend({});
// export default View.extend({
// 	tagName: 'ul',
// 	className: 'with-border',
// 	childView,
// 	customViews(){
// 		return [
// 			() => new BottomAction({
// 				text:'добавить контакт',
// 				action:'add:contact',
// 				actionContext: this.model
// 			})
// 		];
// 	},
// });
