import { _ } from 'vendors';
import Obj from 'base/object';
export default Obj.extend({
	subtractOffset: 100,
	constructor (opts) {
		this.intervals = {};

		Obj.apply(this, arguments);
		this.mergeOptions(opts, ['collection', 'getMs', 'callback', 'context']);

		if (!this.collection || !this.getMs) { return; }


		this.listenTo(this.collection, 'update reset', this._onCollectionUpdate);
		this.listenTo(this.collection, 'change', this._onCollectionChange);

		this._setModelsIntervals(this.collection.models);

		if (this.context && _.isFunction(this.context.destroy)) { this.listenTo(this.context, 'before:destroy', this.destroy); }

		this.on('before:destroy', this._clearAllModelIntervals);
	},

	filter (model) {
		const offset = this.getOffset(model);
		return offset && offset > 0;
	},
	_onCollectionChange (model) {
		this._setModelsIntervals([model]);
	},
	_onCollectionUpdate (opts = {}) {
		const changes = opts.changes || {};
		const updated = [].concat(changes.added || [], changes.merged || []);
		const removed = [].concat(opts.previousModels || [], changes.removed || []);
		this._clearModelsIntervals(removed);
		this._setModelsIntervals(updated);
	},

	_setModelsIntervals (models) {
		_(models).each((model) => {
			if (this.filter(model)) { this._setModelInterval(model, this.getOption('callback', { force: false })); } else { this._clearModelInterval(model); }
		});
	},
	_clearModelsIntervals (models) {
		_(models).each((model) => this._clearModelInterval(model));
	},
	_clearAllModelIntervals () {
		_(this.intervals).each((interval) => clearInterval(interval));
		this.intervals = {};
	},
	_clearModelInterval (model) {
		const interval = this.intervals[model.cid];
		delete this.intervals[model.cid];
		if (interval) { clearInterval(interval); }
	},
	_setModelInterval (model, callback) {
		this._clearModelInterval(model);
		const offset = this.getOffset(model, true);
		if (offset == null || !_.isFunction(callback)) return;

		if (offset <= 0) { callback(model); } else {
			const interval = setInterval(() => callback(model), offset);
			this.intervals[model.cid] = interval;
			return true;
		}
	},
	getOffset (model) {
		if (!_.isFunction(this.getMs)) { return; }
		let ms = this.getMs(model);
		if (_.isDate(ms)) { ms = ms.valueOf(); }

		if (isNaN(ms)) return;

		let offset = ms - Date.now();
		if (offset > 43200000) { offset = 43200000; }
		// if(offset > 2147483647)
		// 	offset = 2147483647;

		return (offset > 0 && offset) || 0;
	}
});
