import { _ } from 'vendors';
import EnumsStore from './store';

const defaultEnumResultOptions = {
	returnAs: 'string',
	returnAsStringDelimeter: ', '
};

function enumArray (en, array) {
	if (!en) return;
	return _(array).map((v) => ({ [v]: en[v] }));
}

function enumResult (data, opts) {
	const options = _.extend({}, defaultEnumResultOptions, opts);
	let result = [];
	if (options.returnAs === 'hash') {
		result = {};
		if (!data) return result;

		_(data).each((element) => _.extend(result, element));

		return result;
	} else if (options.returnAs === 'array') {
		if (!data) return result;
		_(data).each((d) => _(d).each((value) => result.push(value)));

		return result;
	} else if (options.returnAs === 'string') {
		if (!data) return;

		_(data).each((d) => _(d).each((value) => result.push(value)));

		return result.join(options.returnAsStringDelimeter);
	}
}



export default function _enum (name, arg1, arg2) {
	const en = _.isString(name) ? EnumsStore[name] : name;
	if (!en || arg1 == null) return;
	if (typeof arg1 === 'string' && arg1.indexOf(',') === -1) { return en[arg1]; } else if (typeof arg1 === 'string' && arg1.indexOf(',') >= 0) {
		const result = enumArray.call(this, en, arg1.split(/\s*,\s*/));
		return enumResult(result, arg2);
	} else if (arg1 instanceof Array) {
		return enumResult(arg1, arg2);
	}
}
