import editValue from 'bus/edit';
import { newContragentsSearchCollection } from './models/collection';
export async function selectContragentAsync () {

	const options = {
		header: 'выберите контрагента',
		controlType: 'select-api',
		editOptions: {
			addClass: 'long-content',
			applyLabel: 'выбрать',
			cancelLabel: 'отставить',
			resetButton: false
		},
		controlOptions: {
			multiple: false,
			fetchAtStart: true,
			apiSearch: true,
			shouldReturnModel: true,
			collection: newContragentsSearchCollection()
		}
	};
	const res = await editValue.do(options);

	console.warn('contragent', res);

	return res;

}
