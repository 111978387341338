import { _, $ } from 'vendors';
import busData from 'bus/data';
import Collection from 'base/collection';
import Model from 'base/model';
import paths from 'helpers/paths';

const Currency = Model.extend({});

const Currencies = Collection.extend({
	noApi: true
});

const currencies = new Currencies([
	{
		id: 'rub',
		sign: '₽',
		value: 1
	},
	{
		id: 'eur',
		sign: '€',
		value: 80
	},
	{
		id: 'usd',
		sign: '$',
		value: 70
	}
], { model: Currency });

busData.currencies = function (...args) {
	return busData.request('currencies', ...args);
};
busData.toRubles = function (amount, id) {
	const cur = busData.currencies(id);
	if (!cur) return;
	return amount * cur.get('value');
};

busData.currenciesEnum = function () {
	return currencies.reduce((memo, model) => {
		memo[model.id] = model.get('sign');
		return memo;
	}, {});
};

function _getCurrenciesFromCBR () {
	// var now = new Date();
	// var day = now.getDate().toString();
	// if (day.length === 1) {
	// 	day = '0' + day;
	// }
	// var month = (now.getMonth() + 1).toString();
	// if (month.length === 1 ) {
	// 	month = '0'+month;
	// }
	// var year = now.getFullYear();
	// let url = `http://www.cbr.ru/scripts/XML_daily.asp?date_req=${day}/${month}/${year}`;
	const url = 'http://www.cbr.ru/scripts/XML_daily.asp';
	const proxyUrl = 'http://www.whateverorigin.org/get?url=' + encodeURIComponent(url) + '&callback=?';
	// $.get(url)
	// 	.then(obj => console.log('currencies!', obj), xhr => console.log('currencies exception', xhr));

	$.getJSON(proxyUrl)
		.done(function (data) {
			const xml = $.parseXML(data.contents);
			const $xml = $(xml);
			$xml.find('Valute').each((ind, el) => {
				try {
					const $el = $(el);
					const code = $el.find('CharCode').get(0).innerHTML;
					const currency = currencies.get(code.toLowerCase());
					if (!currency) return;
					const val = $el.find('Value').get(0);
					const num = parseFloat(val.innerHTML.replace(',', '.'), 10);
					currency.set('value', num);
				} catch (ex) {
					// nothing to do
				}
			});
		})
		.fail(function (jqxhr, textStatus, error) {
			console.log('CURRENCIES FAILS', jqxhr);
		});
}

function parseXML (data) {
	const xml = $.parseXML(data);
	const $xml = $(xml);
	const parsed = [];
	$xml.find('Valute').each((ind, el) => {
		const item = {};
		parsed.push(item);
		try {
			const $el = $(el);
			const code = $el.find('CharCode').get(0).innerHTML;
			const currency = currencies.get(code.toLowerCase());
			item.code = code;
			item.model = currency;
			if (!currency) return;
			const val = $el.find('Value').get(0);
			const num = parseFloat(val.innerHTML.replace(',', '.'), 10);
			currency.set('value', num);
			item.num = num;
		} catch (ex) {
			// nothing to do
		}
	});
	return parsed;
}

function getCurrenciesFromCBR () {
	const url = paths.urls.api('currency/remote') + '?remoteUrl=' + encodeURIComponent('http://www.cbr.ru/scripts/XML_daily.asp');
	// console.warn('fetching currencies:', url);
	const options = {
		// credentials: 'include',
		// mode: 'cors',
	};
	fetch(url, options)
		.then(data => data.text())
		.then(text => parseXML(text))
		.then(obj => console.warn('CURRENCIES OK'))
		.catch(err => console.error('CURRENCIES ERROR', err));
}

getCurrenciesFromCBR();


busData.reply('currencies', function (id, opts) {
	opts || (opts = {});
	if (arguments.length === 0) return currencies;
	if (id == null) return null;
	// console.log('### chpock', id);
	const item = currencies.get(id);

	if (item && _.isString(opts)) { return item.display(opts); }

	if (!_.size(opts) || !item) return item;

	if (opts.transform) { return opts.transform(item); }

	return item;
});
