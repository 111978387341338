import { _, $ } from 'vendors';
import View from 'base/view';

const BasePrintView = View.extend({
	className: 'print-preview',
	template: _.template(`
	<button class="close top-icon"><i></i></button>
	<div class="printable-area">
		<div class="printable-styles"></div>
		<div class="printable-content"></div>
	</div>
	<div class="controls-area">
		<button class="print">распечатать</button>
		<button class="close">закрыть</button>
	</div>
	`),
	regions: {
		content: '.printable-content',
		styles: '.printable-styles'
	},
	ui: {
		content: '.printable-content',
		styles: '.printable-styles',
		area: '.printable-area'
	},
	onRender () {
		this.showChunk('styles');
		this.showChunk('content');
		const handleEsc = event => {
			if (event.keyCode === 27) {
				event.preventDefault();
				event.stopPropagation();
				this.destroy();
			}
		};
		document.addEventListener('keyup', handleEsc);
		this.once('before:destroy', () => {
			document.removeEventListener('keyup', handleEsc);
		});
	},
	showChunk (name) {
		const content = this.getOption(name);

		if (content.render) {
			this.showChildView(name, content);
			return;
		}

		let html;
		if (typeof content === 'string') {
			html = $(content);
		} else if (content.jquery) {
			html = content;
		}
		if (!html) return;
		this.ui[name].append(html);
	},
	events: {
		'click .close' () {
			this.destroy();
		},
		'click .print' () {
			const html = this.ui.area.html();
			const w = window.open();
			w.document.write(html);
			w.print();
			w.close();
		}
	}
});

export default BasePrintView;
