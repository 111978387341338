import { _ } from 'vendors';
import Model from 'base/model';
import bus from 'bus/models';
// import paths from 'helpers/paths';

// export const apiProductsUrl = paths.urls.api('res/prod/products');
// export const apiProductUrl = paths.urls.api('res/prod/products/:productId');
// export const apiProductUrl = paths.urls.api('res/prod/products/:productId');

// export const getApiProductProductionCycleUrl = (id) => paths.urls.api('res/prod/products/:productId', id);

const ComponentTypes = {
	main: {
		index: 0,
		name: 'Важный-Обязательный',
		description: 'образует партию, расходуется обязательно'
	},
	footprintless: {
		index: 1,
		name: 'Обязательный',
		description: 'НЕ образует партию, расходуется обязательно'
	},
	optional: {
		index: 2,
		name: 'Опциональный',
		description: 'не образует партию, может не расходоваться'
	}
};

export const ProdCycleProdComponentTypesEnum = _.reduce(ComponentTypes, (memo, meta, key) => {
	meta.fullName = meta.name + ` (${meta.description})`;
	memo[key] = meta.fullName;
	return memo;
}, {});

export const ProdCycleProdComponentTypeMixin = {
	getComponentTypeInt () {
		const type = this.getComponentType();
		return ComponentTypes[type].index;
	},
	getComponentTypeName (returnFull) {
		const type = this.getComponentType();
		const key = returnFull ? 'fullName' : 'name';
		return ComponentTypes[type][key];
	},
	getComponentType (key) {
		let id;
		if (this.get('optional')) { id = 'optional'; } else if (this.get('footprintless')) { id = 'footprintless'; } else { id = 'main'; }

		if (key == null) {
			return id;
		}
		return ComponentTypes[id][key];
	},
	setComponentType (id) {
		const upd = {
			optional: false,
			footprintless: false
		};
		if (id === 'optional') {
			upd.optional = true;
		} else if (id === 'footprintless') {
			upd.footprintless = true;
		}
		this.set(upd);
	}
};

export const ProdCycleProdModel = Model.extend({
    // initialize() {
    //     console.log('[*init*]')
    // },
	...ProdCycleProdComponentTypeMixin,


	getProductName () {
		let name = this.get('name') || bus.request('new:products:model', { id: this.id }).getLabel();
		if (this.get('isAbstractGroup')) {
			name = `[${name}]`;
		}
		return name;
	}

	// urlRootPattern: productProdCycleUrlPattern
	// paths.api('res:production:products')
});


function getComponentName (obj) {
	if (obj.name) { return obj.name; } else if (obj.id) {
		return bus.request('new:products:model', { id: obj.id }).getLabel();
	}
}

function getComponentTypeIndex (obj) {
	let id = 'main';
	if (obj.optional) { id = 'optional'; } else if (obj.footprintless) { id = 'footprintless'; }
	return ComponentTypes[id].index;
}

const propGetter = (obj, prop) => obj[prop];

function subtract (a, b, getter, prop) {
	if (getter == null) {
		getter = propGetter;
	}
	const va = getter(a, prop);
	const vb = getter(b, prop);
	return va - vb;
}

function textcompare (a, b, getter) {
	let va = a;
	let vb = b;
	if (getter) {
		va = getter(a);
		vb = getter(b);
	}
	return va < vb
		? -1
		: va > vb
			? 1
			: 0;
}
export function componentComparator (a, b) {
	const aa = a.attributes;
	const ba = b.attributes;

	const order = subtract(ba, aa, null, 'order');
	if (order !== 0) {
		return order;
	}

	const index = subtract(ba, aa, getComponentTypeIndex);
	if (index !== 0) {
		return index;
	}

	return textcompare(aa, ba, getComponentName);
        /*
		let ao = a.get('order') || 0;
		let bo = b.get('order') || 0;

		if (ao != bo) {
			return bo - ao;
		}

		let index = a.getComponentTypeInt() - b.getComponentTypeInt();
		if (index !== 0) {
			return index;
		}
		let aName = a.getProductName();
		let bName = b.getProductName();

		return aName < bName ? -1
			: aName === bName ? 0
			: 1;
        */
}
