import { _, $ } from 'vendors';
import BaseView from 'base/view';
import template from './layout.html';
import mix from 'helpers/mix';
import ControlsMixin from 'components/controls/mixin';

const SearchBase = mix(BaseView).with(ControlsMixin);
export default SearchBase.extend({
	constructor () {
		SearchBase.apply(this, arguments);

		if (this.getOption('applyOnChange')) {
			this._handleKeyUp = _.debounce(this.handleKeyUp, 200);
		}
	},
	className: 'search-control',
	template,
	events: {
		'click button.resetSearch' (e) {
			e.stopPropagation();
			this.resetValue();
		},
		'click button.applySearch' (e) {
			e.stopPropagation();
			this.applyValue();
		},
		'focus input' (e) {
			$(e.target).select();
		},
		'keydown' (e) {
			if (e.which === 13) {
				e.stopPropagation();
				this.applyValue();
			} else if (e.which === 27) {
				$(e.target).blur();
			}
		},
		'keyup' (e) {
			this._handleKeyUp(e);
		}
	},
	_handleKeyUp () {},
	handleKeyUp () {
		this.applyValue();
	},
	resetValue () {
		this.$('input').val('');
		this.applyValue();
	},
	getValue () {
		return this.$('input').val();
	},
	applyValue () {
		this.triggerChange(this.getValue());
		// this.triggerMethod('search:text',this.$('input').val());
	}
});
