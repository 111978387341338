import { _ } from 'vendors';
import Collection from 'base/collection';
import CollectionView from 'base/collection-view';
import View from 'base/view';

import PopoverBehavior from 'behaviors/popover';
// import { BasePointsView } from './mixins';
import ModelProperties from 'components/ui-model-card/properties';
import h from 'components/ui-model-card/helpers';

const PointInfoBehavior = PopoverBehavior.extend({
	popoverViewCssClass: 'popover-common max-width-400px',
	// showOn:'click',
	content () {
		return new DetailedTransportPoint({
			model: this.view.model
		});
	}
});

const templateItemMain = `
	<div class="transportpoint-where"><%= nameInfo %></div>
	<div class="transportpoint-what ui-list">
		<% if (takeCargo) {%><div class="take ui-list-item preserveWhiteSpace"><div><i>забираем:</i><span><%= takeCargo %></span></div></div><% } %>
		<% if (dropCargo) {%><div class="drop ui-list-item preserveWhiteSpace"><div><i>отдаём:</i><span><%= dropCargo %></span></div></div><% } %>
	</div>
`;
const PointItemMain = View.extend({
	template: _.template(templateItemMain),
	getNameInfo () {
		const contragent = this.model.get('contragent.name') || '';
		const contact = this.model.get('contact.value') || '';
		const address = this.model.get('address.address');
		if (!contragent && !contact) {
			return address;
		} else if (!(contragent && contact)) {
			return contragent || contact;
		}

		if (contragent.toLowerCase().trim() === contact.toLowerCase().trim()) {
			return contragent;
		}
		return `${contragent}, ${contact}`;
	},
	templateContext () {
		const { dropCargo, takeCargo } = this.model.attributes;
		return _.extend({
			nameInfo: this.getNameInfo()
		}, { dropCargo, takeCargo });
	}
});


const defs = { destroyOnChange: false }; // { addClass:'whole-btn', textAction:'edit' };
export const DetailedTransportPoint = ModelProperties.extend({
	isInsideCollection () {
		return this.getOption('inCollectionView');
	},
	className: 'props-place card lined with-border transportpoint-props',
	properties: () => ([
		h.ui('contragent', { ...defs }),
		h.ui('contact', {
			...defs,
			async onRender () {
				let values = this.model.get('contact.values');
				const update = values => {
					this.options.smallText = values && values.filter(v => /phone/i.test(v.type) || /email/i.test(v.type)).map(v => `<span class="contact-value ${v.type}">${(/phone/i.test(v.type) ? _.displayPhone(v.value) + (' ' + (v.addValue || '').trim()) : v.value)}</span>`).join('');
				};
				if (!values) {
					values = await this.model.fetchContactValues();
				}
				update(values);
				if (values && !this._contactValuesFetched) {
					this._contactValuesFetched = true;
					this.render();
				}

				// console.log('rendered', values);
			}
		}),
		h.ui('address', { ...defs }),
		h.ui('takeCargo', { ...defs }),
		h.ui('dropCargo', { ...defs }),
		h.ui('workHours', { ...defs, half: true }),
		h.ui('notifyFirst', { ...defs, half: true }),
		h.ui('comment', { ...defs })
	])
});



const PointItemLabels = CollectionView.extend({
	className: 'transportpoint-badges',
	_propertyToModel (type) {
		const value = this.model.get(type);
		if (!value) return;
		return { type, value };
	},
	_propertiesCollection (...properties) {
		const models = properties.reduce((memo, property) => {
			const model = this._propertyToModel(property);
			if (model) {
				memo.push(model);
			}
			return memo;
		}, []);
		return new Collection(models);
	},
	initialize () {
		if (!this.collection) {
			this.collection = this._propertiesCollection('workHours', 'notifyFirst');
		}
	},
	childView: View.extend({
		cssClassModifiers: [
			'transportpoint-badge',
			m => m.get('type')
		],
		template: _.template('<%= value %>')
	})
});

export const PointItem = CollectionView.extend({
	className: 'transportpoint-item',
	customViews () {
		return [
			new PointItemMain({ model: this.model }),
			new PointItemLabels({ model: this.model })
		];
	}
});

export const PointLine = View.extend({
	behaviors: [PointInfoBehavior],
	className: 'transportpoint-lineitem',
	template: _.template('<div data-region="item"></div>'),
	regions: {
		item: '> [data-region="item"]'
	},
	onRender () {
		this.showChildView('item', new PointItem({ model: this.model }), { replaceElement: true });
	},
	templateContext () {
		return {
			index: this.model.collection.indexOf(this.model) + 1
		};
	}
});
