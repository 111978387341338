export function tableSchemaValue (context, key, tableSchema, model) {
	if (!tableSchema) {
		console.warn('# no tableSchema provided');
	}
	const cellSchema = tableSchema[key];
	if (cellSchema && cellSchema.value) {
		return cellSchema.value.call(context, context);
	}
	if (model) {
		return model.get(key);
	}

}
