import BaseView from 'pages/view';
import template from './layout.html';

import ModelProperties from 'components/ui-model-card/properties';
import h from 'components/ui-model-card/helpers';

import nestedRights from 'mod/rights/views/entity-nested-rights';
import Departments from './departments';
import Groups from './groups';
import UiCardBlock from 'components/ui-card-block/block';
import user from 'app/user';

export const MainLayoutView = BaseView.extend({
	className: 'staff-emp',
	template,
	initialize () {
		
		const m = this.model;

		this.createNested('main', {
			viewClass: ModelProperties.extend({ className: 'card lined with-border' }),
			viewOptions: {
				header: { text: 'основное', addClass: 'bg-main-dark' },
				properties: [
					h.ui('name.last', { editButton: true }),
					h.ui('name.first', { editButton: true }),
					h.ui('name.middle', { editButton: true }),
					h.ui('birthday.date', { editButton: true }),
					h.ui('phone', { editButton: true }),
					h.ui('email', { editButton: true }),
					h.ui('userId', {
						closeButton: () => !!m.get('userId') && m.userCanBeChanged(),
						editButton: () => !m.get('userId') && m.userCanBeChanged(),
						editClick (model, view) {
							model.executeAction('attach:user', {
								callback: () => view.render()
							});
						},
						closeClick (model, view) {
							model.executeAction('detach:user', {
								callback: () => view.render()
							});
						}
					})
				]
			},
			regionTemplate: { replaceElement: true }
		});

		this.createNested('additional', {
			viewClass: ModelProperties.extend({ className: 'card lined with-border' }),
			viewOptions: {
				header: { text: 'дополнительно', addClass: 'bg-main-dark' },
				properties: [
					h.ui('occupation', { editButton: true }),
					h.ui('roomNumber', { editButton: true }),
					h.ui('innerPhone', { editButton: true }),
					h.ui('locationType', { editButton: true }),
					h.ui('locationNote', { editButton: true }),
					h.ui('note', { editButton: true })
				]
			},
			regionTemplate: { replaceElement: true }
		});

		this.createNested('system', {
			viewClass: ModelProperties.extend({ className: 'card lined with-border' }),
			viewOptions: {
				header: { text: 'системная информация', addClass: 'bg-main-dark' },
				properties: [
					h.ui('created', { editButton: false }),
					h.ui('modified', { editButton: false }),
					h.ui('hired', { editButton: false }),
					h.ui('fired', { editButton: false }),
					h.ui('status', { editButton: true }),
					h.ui('disabled', { editButton: true })
				]
			},
			regionTemplate: { replaceElement: true }
		});

		this.createNested('departments', {
			viewClass: UiCardBlock,
			viewOptions: {
				thisClassName: 'full-column',
				header: 'отделы',
				headerOptions: {
					className: 'bg-main-dark'
				},
				content: Departments,
				contentOptions () {
					return {
						model: m,
						collection: m.getDepartmentsCollection()
					};
				},
				action: 'add:departments'
			},
			regionTemplate: { replaceElement: true }
		});


		// if (user.checkRights({ admin: 'manageGroups' })) {
		// 	this.createNested('groups', {
		// 		viewClass: UiCardBlock,
		// 		viewOptions: {
		// 			header: 'группы',
		// 			headerOptions: {
		// 				className: 'bg-main-dark'
		// 			},
		// 			content: Groups,
		// 			contentOptions () {
		// 				return {
		// 					model: m,
		// 					collection: m.getGroupsCollection()
		// 				};
		// 			},
		// 			action: 'add:groups'
		// 		},
		// 		regionTemplate: { replaceElement: true }
		// 	});
		// }

		// // this.createNested('employees', nestedEmployees(m, {withRoles:true}));
		// if (user.checkRights({ admin: 'manageRights' })) {
		// 	this.createNested('rights', nestedRights(m, { shouldFetch: true }));
		// }
	},
	onRender () {
		this.showAllNested();
	}
});
