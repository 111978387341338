import GroupListItem from 'mod/groups/views/list-item';
import CollectionView from 'base/collection-view';
import groups from 'mod/groups/singleton';


const childView = GroupListItem.extend({
	tagName: 'li',
	topText () {},
	smallText () {},
	closeButton: m => m.employeesCanBeRemoved(),
	onCloseClick () {
		const model = (this.getOption('employee') || {}).id;
		this.model.executeAction('remove:employee', { model });
	}
});
export default CollectionView.extend({
	initialize () {
		this.listenTo(groups, 'change update remove', () => {
			this.model.getGroupsCollection();
		});
	},
	tagName: 'ul',
	childView,
	emptyView: childView.Empty,
	childViewOptions () {
		return {
			employee: this.model
		};
	}
});
