import BaseView from 'base/view';
import { _ } from 'vendors';

export const TabTriggerView = BaseView.extend({
	template: _.template('<i></i><span><%= label %></span>'),
	cssClassModifiers: [
		'tab',
		(m, v) => v.getActiveState(),
		(m) => m.id + '-tab'
	],
	getActiveState () {
		return this.model.isActive() ? 'active' : '';
	},
	events: {
		'click' () {
			if (this.model.isActive()) return;
			this.model.makeActive();
		}
	}
});
