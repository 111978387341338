import { _, moment } from 'vendors';
import Model from 'base/model';
import Actionable from 'mixins/actionable';
import mix from 'helpers/mix';
import editValue from 'bus/edit';
import action from 'helpers/action';
import smartDate from 'helpers/date/smart';
import paths from 'helpers/paths';
import store from 'helpers/store';

const typeEnum = {
	responsible: 'мои',
	checker: 'проверяю',
	observer: 'наблюдаю',
	creator: 'от меня',
	all: 'все'
};

const stateEnum = {
	active: 'в работе',
	completed: 'завершённые'
};
const viewEnum = {
	list: 'список плитками',
	// listtable: 'список таблицей',
	day: 'день',
	week: 'неделя',
	month: 'месяц'
};

export const listsKeys = {
	list: 1,
	listtable: 1
}

export default mix(Model).with(Actionable).extend({
	initialize () {
		this.on('change', function () {
			const toStore = _.omit(this.toJSON(), 'date');
			store.set('tasks:default:view', toStore, { days: 30 });
		});
	},
	defaults: () => {
		let def = {
			type: 'responsible',
			state: 'active',
			view: 'week',
			date: new Date(),
			outOfDate: true
		};

		const stored = store.get('tasks:default:view');



		if (_.isObject(stored)) {
			def = _.extend({}, def, stored, { date: new Date() });
		}

		return def;
	},
	properties: {
		type: {
			type: 'enum',
			sourceValues: typeEnum,
			display: {
				transform: (v) => typeEnum[v]
			}
		},
		state: {
			type: 'enum',
			sourceValues: stateEnum,
			display: {
				transform: (v) => stateEnum[v]
			}
		},
		view: {
			type: 'enum',
			sourceValues: viewEnum,
			display: {
				transform: (v) => viewEnum[v]
			}
		},
		date: {
			type: 'date',
			display: {
				transform (v) {
					const di = Date.info(v);
					const date = di.date;
					const type = this.get('view');
					if (type in listsKeys) return 'список';

					if (type === 'day') {
						if (Date.sameDay(date)) { return 'Сегодня'; } else if (Date.nextDay(date)) { return 'Завтра'; } else if (Date.prevDay(date)) { return 'Вчера'; } else { return smartDate.main(date, { noTime: true }); }
					} else if (type === 'week') {
						if (Date.sameWeek(date)) {
							return 'На этой неделе';
						} else if (Date.nextWeek(date)) {
							return 'На следующей неделе';
						} else if (Date.prevWeek(date)) {
							return 'На прошлой неделе';
						} else {
							const week = Date.toWeek(date);
							const weekEnd = Date.toWeek(date);
							weekEnd.addDays(6);
							const firstMonth = Date.sameMonth(week, weekEnd) ? '' : ' MMM';
							const year = Date.sameYear(weekEnd) ? '' : moment(weekEnd).format(' YYYY');
							return moment(week).format(`[с ]Do${firstMonth}`) + moment(weekEnd).format(`[ по ]Do MMM${year}`);
						}
					} else if (type === 'month') {
						if (Date.sameMonth(date)) {
							return 'В этом месяце';
						} else if (Date.nextMonth(date)) {
							return 'В следующем месяце';
						} else if (Date.prevMonth(date)) {
							return 'в предыдущем месяце';
						} else {
							return moment(date).format('MMM YYYY');
						}
					}

					return '...';
				}
			}
		}
	},
	getPath (opts = {}, forApi) {
		const type = this.get('type');
		const state = this.get('state');
		let view = this.get('view');
		if (forApi && view in listsKeys) {
			view = 'list';
		}
		let path = `${type}/${state}/${view}`;

		if (opts.bad) {
			return `${type}/bad`;
		}

		if (view !== 'list') {
			const di = Date.info(this.get('date') || new Date());
			let date = di.date;
			if (view === 'month') { date = Date.toMonth(date); } else if (view === 'week') { date = Date.toWeek(date); }

			const value = moment(date).format('YYYY-MM-DD');
			path += `/${value}`;
		}
		return path;
	},
	getPageUrl () {
		const path = this.getPath();
		let url = `${paths.url('tasks')}/${path}`;
		if (document.location.search) {
			url += document.location.search;
		}
		return url;
	},
	getApiUrl (opts) {
		const path = this.getPath(opts, true);
		const url = `${paths.api('tasks')}/${path}`;
		return url;
	},
	actions: [
		action('date:forward', 'вперёд'),
		action('date:backward', 'назад'),
		action('date:change', 'выбрать дату'),
		action('view:change', 'изменить представление')
	],
	actionDateChange () {
		editValue.do({
			valueType: 'date',
			modelType: 'single',
			header: 'Выберите день',
			value: this.get('date'),
			controlOptions: {
				startTime: new Date()
			},
			editOptions: {
				applyOnChange: true,
				destroyOnAction: true
			}

		}).then(
			(v) => this.set('date', v),
			() => {}
		);
	},
	actionViewChange () {
		editValue.do({
			valueType: 'enum',
			modelType: 'single',
			multiple: false,
			header: 'Выберите представление',
			value: this.get('view'),
			sourceValues: viewEnum,
			editOptions: {
				applyOnChange: true,
				destroyOnAction: true
			}
		}).then(
			(view) => {
				const date = this._getNewViewDate(view);
				this.set({ view, date });
			},
			() => {}
		);
	},
	actionDateForward () {
		this._changeDate(true);
	},
	actionDateBackward () {
		this._changeDate();
	},
	_getNextDate (increase) {
		const raw = this.get('date') || new Date();
		const di = Date.info(raw);
		let date = new Date(di.date.valueOf());
		const view = this.get('view');
		let start = new Date();
		start.setHours(0, 0, 0, 0);

		if (view === 'list') return;
		if (view === 'day') {
			date.addDays(increase ? 1 : -1);
		} else if (view === 'week') {
			date = Date.toWeek(date);
			date.addDays(increase ? 7 : -7);
			start = Date.toWeek(start);
		} else if (view === 'month') {
			date = Date.toMonth(date);
			date.addMonths(increase ? 1 : -1);
			start = Date.toMonth(start);
		}
		const valid = date >= start || this.get('state') === 'completed';
		return valid && date;
	},
	isValidDate (date, view) {
		view = view || this.get('view');
		if (view === 'list') return true;

		const validStart = this._getNewViewDate(view);
		return date >= validStart;
	},
	_getNewViewDate (view, state) {
		const date = this.get('date') || new Date();
		const now = new Date();

		if (view === 'list') {
			return;
		}

		if (view === 'day') {
			return date >= now || state === 'completed' ? date : now;
		} else if (view === 'week') {
			const startDate = Date.toWeek(new Date());
			const res = Date.toWeek(date);
			return res >= startDate || state === 'completed' ? res : startDate;
		} else if (view === 'month') {
			const startDate = Date.toMonth(new Date());
			const res = Date.toMonth(date);
			return res >= startDate || state === 'completed' ? res : startDate;
		}
	},
	_changeDate (increase) {
		const date = this._getNextDate(increase);
		if (date) {
			this.set('date', date);
		}
	},
	canGoBackward () {
		return !!this._getNextDate();
	},
	getDateRange () {
		const view = this.get('view');
		if (view === 'list') return;

		const from = Date.info(this.get('date')).date;
		const to = this._getNextDate(true);
		to.addMilliseconds(-1);
		return { from, to };
	}
});
