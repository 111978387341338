import { $ } from 'vendors';
import View from 'components/ui-list/item';
// import editValue from 'bus/edit';

export default View.extend({
	collectionPlace: 'top',
	cssClassModifiers: [
		'filters-text-search'
	],
	text () {
		return '<div data-placeholder="поиск по ключевому слову"><input type="text" value="' + (this.model.getValue() || '') + '"></div>';
	},
	addEvents: {
		'keypress input' (e) {
			if (e.keyCode === 13) {
				this.apply();
			}
		},
		'input input' () {
			const value = this.$('input').val();
			this.model.setValues(value, { silent: true });
		},
		'focus input' (e) {
			$(e.target).select();
		}
	},
	apply () {
		const value = this.$('input').val();
		this.model.setValues(value);
		if (!this.getOption('shouldNotApply')) {
			this.trigger('text:search:apply');
		}
	},
	_isCustomView: true
});
