import { _, $ } from 'vendors';
import View from 'base/view';
import yatModals from './base-modals';

yatModals.addTypeConfig('preloader', {
	css: {
		wrapper: 'yat-preloader-wrapper',
		bg: 'yat-preloader-bg',
		contentWrapper: 'yat-preloader-content-wrapper',
		content: 'yat-preloader-content'
	},
	options: {
		closeOnClickOutside: false,
		closeOnPromise: false,
		preventClose: true,
		asPromise: false
	},
	show: {
		bg: false,
		close: false
	}
});


const Overlay = View.extend({

	className: 'overlayed-preloader',
	instantRender: true,
	template: _.template('<i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>'),
	onRender () {
		const over = this.getOption('over');
		const $el = this.over$el = over.$el || $(over);

		const attach = () => {
			if ($el.css('position') !== 'relative') { $el.css('position', 'relative'); }

			let ezi = parseInt($el.css('z-index'));
			ezi = isNaN(ezi) ? 1 : ezi + 1;
			const offset = $el.offset();
			this.$el.css({
				position: 'absolute',
				top: offset.top + 'px',
				left: offset.left + 'px',
				height: $el.outerHeight(),
				width: $el.outerWidth(),
				'z-index': ezi
			});
			this.$el.insertAfter($el);
		};

		if (_.isView(over)) {
			this.listenTo(over, 'all', this.updateCss);
			if (!over.isAttached()) {
				this.listenToOnce(over, 'attach', attach);
			} else {
				attach();
			}
		} else {
			attach();
		}


		// $el.css('overflow','hidden');
		// this.once('destroy', () => $el.css('overflow',''));
	},
	updateCss () {
		const $el = this.over$el;
		this.$el.css({
			height: $el.outerHeight(),
			width: $el.outerWidth()
		});
	},
	onBeforeDestroy () {
		if (!this.over$el) return;
		this.over$el.css('position', '');
	}
});

function showOverlay (over) {
	return new Overlay({ over });
}

export default function preloader (opts = {}) {
	if (opts.overlay) {
		return showOverlay(opts.overlay);
	}
	const modalPreloaderOptions = _.extend({
		type: 'preloader',
		text: '<i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw margin-bottom"></i>'
	}, opts);


	const modal = yatModals.show(modalPreloaderOptions);

	return modal;
}
