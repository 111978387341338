import '../api-config';
import paths from 'helpers/paths';
import Model from 'base/card-model';
import BbStore from 'base/bb-store';
import mix from 'helpers/mix';
import EditableProperties from 'mixins/editable-properties';
import busData from 'bus/data';


// import EntityWithLogs from 'components/logs/entity-with-logs';
// import action from 'helpers/action';
// import openAdd from '..//views/open-add';

const ModelProperties = {
	properties: {
		one: {
			display: {
				label: 'один / одна ...'
			}
		},
		few: {
			display: {
				label: 'два / две ...'
			}
		},
		many: {
			display: {
				label: 'десять ...'
			}
		},
		short: {
			display: {
				label: 'сокращение'
			}
		}
	}
};

const ModelActions = {
	// actions:[
	// 	action('add:type', 'добавить подтип', {resourcesProduction:'typesEdit'})
	// ],
	// actionAddType(){
	// 	return openAdd(this);
	// }
};


const Base = mix(Model).with(EditableProperties, ModelProperties, ModelActions);
const Measure = Base.extend({
	cardUrlRoot: paths.url('res:production:measures'),
	urlRootPattern: paths.api('res:production:measures'),
	isCommentable: () => false,
	mainInfoCanBeChanged () {
		return busData.user().checkRights({ resourcesProduction: 'measuresEdit' });
	},
	getLabel () {
		return this.display('one');
	}
});

const StoredMeasure = BbStore(Measure);
export default StoredMeasure;
