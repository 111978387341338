import { HamburgerView } from 'base/view-hamburger';
import { CyclesList } from './CyclesList';
// import { ProductionTree } from './products-layouts/ProductionTree';
// import { ProductsTableLayout } from './products-layouts/TableLayout';

export const CyclesContent = HamburgerView.extend({
	getCustomViews () {
		return [
			this.buildContentView()
		];
	},
	buildContentView () {
		const rootView = this.getOption('rootView');
		const view = (ext) => ({
			rootView,
			model: this.model,
			filterModel: this.getOption('filterModel'),
			...ext
		});
		return view({ class: CyclesList });

	}
});
