import { _ } from 'vendors';

const _disallowedKeys = ['setItem', 'key', 'getItem', 'removeItem', 'clear'];
function allowedKey (key) { return _disallowedKeys.indexOf(key) < 0; }
const fake = {
	setItem (id, val) {
		if (!allowedKey(id)) return;
		return this[id] = String(val); // eslint-disable-line
	},
	getItem (id) {
		if (!allowedKey(id)) return;
		return this[id];
	},
	removeItem (id) {
		if (!allowedKey(id)) return;
		delete this[id];
	},
	clear () {
		const keys = _(this).keys();
		_(keys).each((key) => this.removeItem(key));
	}
};

export default (typeof localStorage === 'undefined') ? fake : localStorage;
