import BasePage from 'pages/auth';
import { BuildModel } from 'mods/product-production/builds';
import { Layout } from './layout';

// import Layout from './layout';
// import Collection from 'base/collection';
// import { departmentsApiUrl } from './models';
// import DepartmentPage from './department';

const Page = BasePage.extend({
	moduleRights: { productProduction: 'view' },
	// label: 'журнал производства',
	label () {
		return 'Билд: ' + ((this.model && this.model.display('name')) || '');
	},
	route: ':buildId',
	skipMenu: true,
	// faIcon: 'list-alt'
	Layout,
	onBeforeStart (ac) {
		const model = new BuildModel({ id: ac.args.buildId });
		this.addModel(model);
		this.addStartPromise(model.fetch());
	}
	// children: [DepartmentPage]
});

export default Page;
