import { _ } from 'vendors';
// import merge from 'helpers/merge';
import { mergeAlt } from 'helpers/merge';
import Employees from './entity-employees';
import UiCardBlock from 'components/ui-card-block/block';


const EmployeeBlock = UiCardBlock.extend({
	cssClassModifiers: [],
	className: 'nested-employees',
	// addClass:'h100p',
	childViewEvents: {
		'childview:childview:render' () {
			const pv = this.getOption('parentView');
			if (!pv) {
				return;
			}
			pv.trigger('refresh');
		},
		'remove:employee:click' (model) {
			const actionsModel = this.getActionsModel();
			if (actionsModel == null) {
				console.error('remove:employee:click fails - no actionModel defined');
			}
			const shouldConfirm = this.getOption('shouldConfirmRemove') !== false;
			return actionsModel.executeAction('remove:employee', { model, shouldConfirm });
		}
	}

});

export default (_model, opts = {}, config = {}) => {
	// console.warn('model is', _model);
	const viewOptionsHeader = config.viewOptionsHeader || 'Сотрудники';
	const collectionViewOptions = config.collectionViewOptions;
	const addChildViewOptions = config.addChildViewOptions;
	const contentOptions = config.contentOptions;
	const modelGetter = typeof _model === 'function' ? _model : undefined;
	const providedModel = _model;
	// const modelGetter = actionsModel;

	const defs = {
		
		viewClass: EmployeeBlock,
		viewOptions: {
			header: viewOptionsHeader,
			addClass: 'minus-header-and-footer',
			headerOptions: {
				className: 'bg-main-dark'
			},
			action: 'add:employees',
			content: Employees,
			contentOptions () {
				
				const model = modelGetter ? modelGetter() : providedModel;
				
				const collection = model.getEmployees();
				
				const options = {
					model,
					collection,
					withRoles: opts.withRoles,
					collectionViewOptions: {
						model,
						withRoles: opts.withRoles,
						addChildViewOptions
					}
				};

				_.extend(
					options.collectionViewOptions,
					collectionViewOptions
				);

				return _.extend(options, contentOptions);
			}
		},
		regionTemplate: { replaceElement: true }
	};

	const defsViewOptions = {
		model: !modelGetter ? providedModel : undefined,
		actionsModel: modelGetter,
	}

	// console.warn('pre-result', defs, opts);
	
	const result2 = mergeAlt(defs, opts);
	const _opts_model = opts.viewOptions?.model;
	const _actionsModel = opts.viewOptions?.actionsModel;
	if (!(_opts_model || _actionsModel)) {
		result2.viewOptions = Object.assign({}, result2.viewOptions, defsViewOptions);
	}
	
	// console.warn('result', result2);
	

	return result2;
};
