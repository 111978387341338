import { _ } from 'vendors';
import Base from './base-value';
import transform from './transform-before-get';
// import labelConverters from './label-converters';

const Model = Base.extend({

	type: 'single',
	urlKeys: {
		value: 'v'
	},
	defaults: {
		value: undefined
	},

	parse (value, valueType) {
		valueType || (valueType = this && this.valueType);
		if (_.isSimple(value)) {
			value = transform.call(this, value, valueType);
			return { id: value, value };
		} else {
			if (!('value' in value)) {
				const keys = (this && this.urlKeys) || Model.prototype.urlKeys;
				if (keys.value in value) {
					value.value = transform.call(this, value[keys.value], valueType);
					delete value[keys.value];
				}
			} else {
				value.value = transform.call(this, value.value, valueType);
			}
			return value;
		}
	},

	getValue () {
		const value = this.get('value', { transform });
		return value;
	},
	setValue (value) {
		this.set('value', value);
	}

	/* toValue(...args){
		let hash = Base.prototype.toValue.apply(this, args);
		delete hash.label;
		return hash;
	},
	getLabel() {
		let value = this.get('label');
		if(value)
			return value;
		else
			return labelConverters.convert(this.getValue(), this.valueType);
	},
	getValue() {
		return this.get('value') || this.get('id');
	}, */
});

export default Model;
