import { moment } from 'vendors';
import { propertySchemaApi } from '../../../../apis/schema';
import { displayUnit } from '../../../../apis/units';
import { addPostfix, displayParty, postfixFunc,  } from '../../../../utils';
import { openCardButton } from '../../../../utils/openCardButton';
import { employeeName } from '../../../staff/emps';


function getProductName(prod) {
	return prod.shortName || prod.fullName || '';
}

export const batchSchema = {
	// name: {
	// 	label: 'название',
	// 	displayValue (v, m, s) {
	// 		const created = propertySchemaApi.displayValue('createdShort', m, s);
	// 		const amount = propertySchemaApi.displayValue('amountPostfix', m, s);
	// 		const product = propertySchemaApi.displayValue('productName', m, s);
	// 		const batch = propertySchemaApi.getValue('productBatchIdString', m, s);
	// 		return [created, amount, batch, product].join(' - ');
	// 	}
	// },



  "idString": {
		label: 'отпечаток',		
	},
  // "stored": false,
  // "deleted": false,
  // "archived": false,
	product: {
		label: 'название продукции',
		displayValue(v,m,s) {
			const id = v.id;
			v = getProductName(v);
			return openCardButton({ id, type: 'product' }, v);
		}
	},

	"id": {
		label: 'идентификатор',		
	},

	"componentsFootprint": {
		label: 'отпечаток',		
	},

  "origin": {
		label: 'источник',
		valueType: 'enum',
		valueSubType: 'productBatchOrigins'
	},

  "created": {
		label: 'дата создания',
		displayValue(v, m ,s) {
			return moment(v).format('DD.MM.YYYY hh:mm:ss');
		}
	},
	"creatorId": {
		label: 'создал сотрудник',
		displayValue (v, m, s) {
			return employeeName(v);
		}
	},

	"modified": {
		label: 'дата изменения',
		displayValue(v, m ,s) {
			return moment(v).format('DD.MM.YYYY hh:mm:ss');
		}
	},
	"lastTransfer": {
		label: 'дата последней транзакции',
		displayValue(v, m ,s) {
			return moment(v).format('DD.MM.YYYY hh:mm:ss');
		}
	},

	//"aggregated": false


	baseAmount: {
		label: 'на складе',
		valueType: 'number'
	},
	baseAmountPostfix: {
		label: 'на складе',
		displayValue (v, m, s) {
			v = propertySchemaApi.displayValue('baseAmount', m, s);
			const unit = propertySchemaApi.getValue('product.unitMeasureId', m, s);
			const dunit = displayUnit(unit);
			return addPostfix(v, postfixFunc(dunit));
		}
	},
	idString: {
		label: 'номер партии',
		displayValue (v, m, s) {
			
			//// const url = cardUrls.batch + id;
			return displayParty(v, '');
		}
	},
	productBatchIdStringUrl: {
		label: 'номер партии',
		displayValue (v, m, s) {
			v = propertySchemaApi.displayValue('productBatchIdString', m, s);
			const id = propertySchemaApi.getValue('productBatchId', m, s);
			//// const url = cardUrls.batch + id;
			return openCardButton({ id, type: 'batch' }, v);
		}
	},
	createdFromNow: {
		label: 'дата производства',
		displayValue (v, m, s) {
			v = propertySchemaApi.getValue('created', m, s);
			return moment(v).fromNow();
		}
	},
	created: {
		label: 'дата производства',
		valueType: 'datetime'
	},
	createdShort: {
		label: 'дата производства',
		valueType: 'datetime',
		displayValue (v, m, s) {
			const created = propertySchemaApi.getValue('created', m, s);
			return moment(created).format('DD.MM.YY');
		}
	},
	actorId: {
		label: 'сотрудник',
		displayValue (v, m, s) {
			return employeeName(v);
		}
	},
	productNameUrl: {
		label: 'название продукции',
		displayValue(v,m,s) {
			v = propertySchemaApi.displayValue('productName', m, s);
			const id = propertySchemaApi.getValue('productId', m, s);
			//const url = cardUrls.product + id;
			return openCardButton({ id, type: 'product' }, v);
		}
	},	
	departmentUrl: {
		label: 'участок',
		displayValue (v, m, s) {
			if (v == null) {
				return;
			}
			const id = v.id;
			//const url = cardUrls.productionDepartment + id;
			return openCardButton({ id, type: 'productionDepartment' }, v.name);
		}
	},
	originProcessUrl: {
		label: 'процесс',
		displayValue (v, m, s) {
			v = propertySchemaApi.getValue('originProcess', m, s);
			if (v == null) {
				return;
			}
			const id = v.iId || v.id;
			//const url = cardUrls.process + id;
			return openCardButton({ id, type: 'process' }, v.name);
		}
	},
	cycle: {
		label: 'цикл производства',
		displayValue(v,m,s) {
			return v?.name;
		}
	},
	cycleUrl: {
		label: 'цикл производства',
		displayValue (v, m, s) {
			if (v == null) {
				return;
			}
			const id = v.id;
			//const url = cardUrls.cycle + id;
			return openCardButton({ id, type: 'cycle' }, v.name);
		}
	},
};
