import Base from 'components/ui-list/item';
import ActionsPopover from 'behaviors/actions-popover';
// import DynamicClass from 'mixins/view/dynamic-class';
// import mix from 'helpers/mix';

export default Base.extend({
	behaviors: [ActionsPopover],
	cssClassModifiers: [
		'contragent'
	],
	text () {
		return this.model.display('name');
	},
	topText () {
		return this.model.display('roles');
	},
	textAction: 'open:card',
	optionsButton: true,
	infoButton: true
});
