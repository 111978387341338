import { _ } from 'vendors';
import toJsonPath from 'helpers/to-json-patch';

const methodUnMap = {
	POST: 'create',
	PUT: 'update',
	PATCH: 'patch',
	DELETE: 'delete',
	GET: 'read'
};

export default (Base) => Base.extend({



	patch (key, value, opts) {
		let hash;
		if (key == null) return;
		if (_.isObject(key)) {
			hash = key;
			opts = value || {};
		} else if (_.isString(key)) {
			hash = { [key]: value };
		}
		opts || (opts = {});

		opts.patch = true;

		if (opts.patchType === 'rfc6902') {
			opts.attrs = toJsonPath(hash);
		} else {
			opts.attrs = hash;
		}

		return this.save(null, opts);
	},

	send (method, data, url, opts = {}) {
		if (_.isObject(method)) {
			_.extend(opts, method);
			method = opts.method;
			url = opts.url;
			data = opts.data;
		} else {
			opts.url = url;
			opts.data = data;
		}


		if (opts.relativeUrl || !/^https*:\/\//g.test(opts.url)) {
			if (!opts.methodName) { opts.methodName = opts.url + ':' + method.toLowerCase(); }
			opts.url = _.result(this, 'url') + '/' + opts.url;
		}

		//! !data && _.isObject(data) && (data = JSON.stringify(data));
		opts.data && _.isObject(opts.data) && (opts.data = JSON.stringify(opts.data));
		opts.validate = false;
		opts.parse = false;
		opts.wait = true;
		opts.contentType = 'application/json';

		return this.sync(methodUnMap[method], this, opts);
	},

	post (data, url, opts = {}) {
		if (arguments.length <= 2 && _.isString(data)) {
			_.extend(opts, url);
			url = data;
			data = undefined;
		}

		if (_.isObject(data)) {
			opts = _.extend(opts, data);
		} else {
			if (url) { opts.url = url; }
			if (data) { opts.data = data; }
		}

		opts.method = 'POST';
		return this.send(opts);
	},
	delete (url, opts = {}) {
		if (_.isObject(url)) { _.extend(opts, url); } else { opts.url = url; }
		opts.method = 'DELETE';
		return this.send(opts);
	}

});
