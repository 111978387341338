import store from 'helpers/store';
import User from 'base/user';
import token from './token';
import busData from 'bus/data';
import busEvents from 'bus/app-events';

const storedUser = store.get('user');
const user = new User(storedUser, { silent: true, flat: false });

user.setToken(token);
user.addInitPromise(token.init());
busData.reply('user', user);
busData.user = function () {
	return busData.request('user');
};
user.init();
// user.init().then(() => user.setToken(token));

// Console.warn('user', user);


// Claims Listener
busEvents.request('Handler', {
	radioEvents: {
		'claimsChanged' () {
			token.refresh();
			// .then(() => user.fetch());
		}
	}
});


export default user;
