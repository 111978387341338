import View from 'base/view';
import template from './template.html';
import Employee from './employee';
export default View.extend({
	template,
	initialize () {
		this.createNested('employee', {
			viewClass: Employee,
			regionTemplate: { replaceElement: true }
		});
	},
	onRender () {
		this.showAllNested();
	},
	childViewEvents: {
		'switch:resolve' (type, value) {
			this.trigger('switch:resolve', type);
			if (value === 'lock') {
				this.inModal && (this.inModal.resolve = undefined);
			} else {
				this.inModal && (this.inModal.resolve = value);
			}
		}
	}
});
