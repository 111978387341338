import nestedEmployees from 'mod/employees/views/entity-nested-employees';
import enums from 'helpers/enums';

export function teamBlock (view) {
	
	const mmm = view.model;

	view.createNested('employees',
		nestedEmployees(mmm,
			{
				type: 'processTeam',
				viewOptions: {
					className: 'nested-employees',
					modelEvents: {
						'new:state' () {
							this.render();
						}
					}
				}
			},
			{
				viewOptionsHeader: 'Участники процесса',
				addChildViewOptions: {
					bottomText: m => enums.get('processEmployeeRoles', m.get('joinedAs')),
					optionsButton: (em) => {
						// return mmm.isEditable('major') && em.isNotMe()
						const show = mmm.amIAdmin() && !mmm.isEmployeeOwner(em.get('employeeId')) && em.isNotMe();
						// console.warn('	show?', show, em.get('employeeId'));
						return show;	
					},
					cssClassModifiers: [
						'process-team',
						m => m.get('joinedAs')
					],
					employeeId: v => v.model.get('employeeId')
				},
				contentOptions: {
					searchDisabled: true
				}
			}
		)
	);
}

export function teamContent(view) {
	const mmm = view.model;
	const ctx = nestedEmployees(mmm,
		{
			type: 'processTeam',
			viewOptions: {
				className: 'nested-employees',
				modelEvents: {
					'new:state' () {
						this.render();
					}
				}
			}
		},
		{
			viewOptionsHeader: 'Участники процесса',
			addChildViewOptions: {
				bottomText: m => enums.get('processEmployeeRoles', m.get('joinedAs')),
				optionsButton: (em) => {
					//debugger;
					// return mmm.isEditable('major') && em.isNotMe()
					const show = mmm.amIAdmin() && !mmm.isEmployeeOwner(em.get('employeeId'));
					console.warn('	show?', show, em.get('employeeId'));
					return show;
				},
				// editOptions: false,
				cssClassModifiers: [
					'process-team',
					m => m.get('joinedAs')
				],
				employeeId: v => v.model.get('employeeId')
			},
			contentOptions: {
				searchDisabled: true
			}
		}
	);

	const opts = {
		class: ctx.viewClass,
		...ctx.viewOptions
	}

	console.error('team-content', opts);

	return opts;
}