import InputPlaceholdered from 'components/controls/input-placeholdered';
import validate from './validate';

export default InputPlaceholdered.extend({
	inputOptions: {
		maxLength: 512,
		type: 'text',
		novalidate: true,
		validate
	}
});
