import { _ } from 'vendors';
import View from 'base/view';
import List from './by-list/list';
import TableList from './by-list/listtable';
import Day from './by-calendar/day';
import Week from './by-calendar/week';
import Month from './by-calendar/month';

import mix from 'helpers/mix';
import ViewSocketsHandler from 'components/sockets/view-handler-mixin';


export default mix(View).with(ViewSocketsHandler).extend({
	id: 'tasks-list-container',
	className: 'tasks fdc-container',
	renderOnModelChange: true,
	isInvulnerable: false,
	template: _.template('<div></div>'),
	regions: {
		content: { el: 'div', replaceElement: true }
	},
	initialize () {
		const page = this.getOption('page');
		this.badEntries = page && page.badEntries;
	},
	getContentViewClass () {
		const view = this.model.get('view');
		switch (view) {
		case 'list':
			return List;
		case 'listtable':
			return TableList;
		case 'day':
			return Day;
		case 'week':
			return Week;
		case 'month':
			return Month;
		}
	},

	onRender () {
		this.showContent();
	},
	showContent () {
		const View = this.getContentViewClass();

		const options = {
			model: this.model,
			entries: this.collection,
			badEntries: this.badEntries
		};
		if (this.model.get('state') === 'completed') {
			options.comparatorKey = 'closed';
			options.newInTop = true;
			// if (this.model.get('view') === 'list') {
			// }
		}

		this.showChildView('content', new View(options));

		// this._wasRendered = true;
	},
	// modelEvents:{
	// 	'change':'onModelChange'
	// },
	// onModelChange(){
	// 	this.showContent();
	// },
	socketsHandler: {
		radioEvents: {
			taskChanged (ev) {
				this._applySocketsChange(ev);
			},
			taskDeadlineChanged (ev) {
				this._applySocketsChange(ev);
			},
			taskResponsibleChanged (ev) {
				this._applySocketsChange(ev);
			},
			taskCheckerChanged (ev) {
				this._applySocketsChange(ev);
			},
			taskCreated (ev) {
				this._applySocketsChange(ev);
			}
		}
	},
	_applySocketsChange (ev) {
		let task = this.findTask(ev.dataId);
		if (task) { task.set(ev.data, { flat: true }); } else {
			const Model = this.collection.model;
			task = new Model({ id: ev.dataId });
			task.fetch().then(() => task.isVisible() && this.collection.add(task));
		}
	},
	findTask (id) {
		return this.collection.get(id) || this.badEntries.get(id);
	}
});
