import BasePage from 'pages/anon';
import Layout from './layout';
import PageErr from 'pages/error';
// import paths from 'helpers/paths';

// paths.set('url','acc:forgot:pass','acc/forgotPass');

const Page = BasePage.extend({
	name: 'forgotPassword confirm page',
	label: 'восстановление пароля, шаг 2',
	route: 'confirm',
	Layout,
	skipMenu: true,
	preventStart (ac) {
		if (!ac || !ac.qs.code) {
			return PageErr.Redirect('');
		}
	}
});

export default Page;
