// import { betterResult, isCollection, isModel, isModelClass, isCollectionClass, clone } from 'bbmn-utils';
import Model from 'base/model';
import Collection from 'base/collection';
import clone from 'helpers/clone';
import _ from 'underscore';
import betterResult from 'helpers/better-result';
import { isModelClass, isCollectionClass, isCollection, isModel } from 'helpers/is-class';

export default Base => Base.extend({

	// usage:
	// model.entity('users');
	entity (key, options = {}) {
		const entity = this._getNestedEntity(key, options);
		return entity;
	},

	// override this if you need to do something with just created entity
	// by default here is settled change handlers
	setupNestedEntity (context) {
		if (!context.entity) return;
		this._setNestedEntityHandlers(context);
		this._setNestedEntityParent(context.entity, context.parentKey);
	},
	registerNestedEntity (name, context) {
		this._initEntitiesStore();
		if (this._nestedEntitiesInitialized) {
			throw new Error('its too late to register nestedEntities, they already initialized');
		}
		if (!name) {
			throw new Error('Name must be provided');
		}
		this._runtimeNestedEntities[name] = context;
		/*
		let toAdd;
		if (_.isFunction(context)) {
			let wrapper = function() {
				let contextInvoked = context.call(this);
				if (!_.isObject(contextInvoked)) {
					throw new Error('Context must be an object with name and class properties');
				}
				contextInvoked.name = name;
				return contextInvoked;
			};
			toAdd = wrapper;
		} else if(_.isObject(context)) {
			context.name = name;
			toAdd = context;
		}
		if (toAdd == null) {
			throw new Error('nestedEntity Context undefined and failed to register');
		}
		this._runtimeNestedEntities.push(toAdd);
		*/
	},

	_getNestedEntity (key, options) {
		// get sure there is a nestedEntities store initialized;
		this._initEntitiesStore();
		// compiling passed `nestedEntities` contexts, occurs only at first call
		this._initOwnEntities();

		const context = this._nestedEntities[key];
		if (!context) { return; }
		if (!context.entity && !context._compiled) {
			context.entity = this._buildNestedEntity(context, options);
			if (context.entity) {
				this.setupNestedEntity(context);
			}
		}
		return context.entity;
	},
	_buildNestedEntity (context, options) {
		let data = this.get(context.name);
		if (_.isFunction(context.build)) {
			context.entity = context.build.call(this, data, context, this);
		} else {
			data = data || context.data;
			let args = context.args;
			if (_.isFunction(args)) {
				args = args(this, context);
			}
			if (!args) {
				args = [data];
				if (context.parse) {
					if (!options) options = {};
					if (!('parse' in options)) {
						options.parse = context.parse;
					}
				}
				if (options || context.options) {
					let contextOptions = context.options;
					if (_.isFunction(contextOptions)) {
						contextOptions = contextOptions(this, context);
					}
					args.push(_.extend({}, contextOptions, options));
				}
			}
			const Ctor = context.class;
			context.entity = new Ctor(...args);
		}
		// context._compiled;
		return context.entity;
	},
	_initOwnEntities () {
		if (this._nestedEntitiesInitialized) {
			return;
		}
		const memo = this._nestedEntities;

		const additional = this._runtimeNestedEntities;

		/*
		_.each(additional, context => {
			if (_.isFunction(context)) {
				context = context.call(this);
			}
			if (!context.name) return;
			if (!isModelClass(context.class) && !isCollectionClass(context.class) ) {
				return;
			}
			memo[context.name] = clone(context, { functions: true });
		});
		*/

		const compiled = _.extend({}, additional, betterResult(this, 'nestedEntities', { args: [this] }));
		_.each(compiled, (context, key) => {
			// handle the case where its a runtime function or class definition
			context = betterResult({ context }, 'context', { args: [key] });
			if (isModelClass(context) || isCollectionClass(context)) {
				context = {
					class: context
				};
			} else if (_.isString(context)) {
				// when its just a property name, trying to determine type of data and use default class
				context = {
					name: context
				};
			} else if (!_.isObject(context)) {
				context = {};
			}

			const name = context.name || ((_.isString(key) && key) || undefined);

			if (!_.isString(name)) {
				return;
			}

			if (!context.name) {
				context.name = name;
			}

			if (!context.class) {
				const data = this.get(context.name);
				if (_.isArray(data)) {
					context.class = this.NestedCollectionClass || Collection;
				} else {
					context.class = this.NestedModelClass || Model;
				}
			}

			memo[name] = clone(context, { functions: true });
		});

		this._nestedEntitiesInitialized = true;
	},
	_initEntitiesStore () {
		if (!_.has(this, '_nestedEntities')) {
			this._nestedEntities = {};
			this._runtimeNestedEntities = {};
		}
	},
	_setNestedEntityHandlers (context) {
		const { name, entity } = context;
		let entityChangeEvents = 'change';
		const entityParent = this;

		if (isCollection(entity)) {
			entityChangeEvents = ' update reset';
		}

		// if entity get changed outside we should keep in sync this model property value
		if (!context.onEntityChange) {
			context.onEntityChange = (instance, { changeInitiator, ecounter = 0 } = {}) => {
				// let isNaturalChange = changeInitiator == null;
				// if (isNaturalChange) return

				// if (changeInitiator != null) return;
				if (changeInitiator === entity || changeInitiator === entityParent) return;
				const silent = changeInitiator != null;
				// if change initiator is null then changeInitiator is entity itself
				if (changeInitiator == null) {
					changeInitiator = entity;
				}

				const json = entity.toJSON();
				// if (context.saveOnChange && !this.isNew() && !silent) {
				// 	this.save(name, json, { changeInitiator });
				// } else {
				// }
				this.set(name, json, { changeInitiator, silent, ecounter: ecounter++, merge: true, remove: true, add: true });
			};
		}
		this.listenTo(entity, entityChangeEvents, context.onEntityChange);

		// if this model property get changed outside we should keep in sync our nested entity
		if (!context.onPropertyChange) {
			context.onPropertyChange = (instance, _newvalue, { changeInitiator, ecounter } = {}) => {
				// if (changeInitiator != null) return;
				if (changeInitiator === entity || changeInitiator === entityParent) return;
				const silent = changeInitiator != null;
				// if change initiator is null then changeInitiator is entity's parent
				if (changeInitiator == null) {
					changeInitiator = entityParent;
				}

				const defaultValue = isCollectionClass(context.class) ? [] : {};

				const val = this.get(name) || defaultValue;

				if (isModel(entity) && changeInitiator !== entity) {
					const unset = _.reduce(entity.attributes, (memo, _val, key) => {
						if (key in val) return memo;
						memo[key] = undefined;
						return memo;
					}, {});
					entity.set(_.extend({}, val, unset), { changeInitiator, silent });
					entity.set(unset, { unset: true, silent: true, changeInitiator });
				} else if (isCollection(entity) && changeInitiator !== entity) {
					entity.set(val, { changeInitiator, silent });
				}
			};
		}
		this.on('change:' + name, context.onPropertyChange);
	},
	_setNestedEntityParent (entity, parentKey = 'parent') {
		entity[parentKey] = this;
	},
	_unsetNestedEntityParent (entity, parentKey) {
		parentKey || (parentKey = 'parent');
		delete entity[parentKey];
	},
	destroy () {
		this.dispose({ destroying: true });
		const destroy = Base.prototype.destroy;
		return destroy && destroy.apply(this, arguments);
	},
	dispose (opts) {
		this._disposeEntities(opts);
		const dispose = Base.prototype.dispose;
		return dispose && dispose.apply(this, arguments);
	},
	_disposeEntities (opts) {
		_.each(this._nestedEntities, context => this._disposeEntity(context, opts));
		delete this._nestedEntities;
	},
	_disposeEntity ({ entity, name, onEntityChange, onPropertyChange, parentKey } = {}, { destroying } = {}) {
		this.stopListening(entity, null, onEntityChange);
		this.off('change:' + name, onPropertyChange);
		this._unsetNestedEntityParent(entity, parentKey);
		const method = destroying ? 'destroy' : 'dispose';
		entity[method] && entity[method]();
	}
});
