import { BackboneCollection as BbCollection } from 'vendors';
// import { fetchPromiseMixin } from 'core/mixins';
import { SchemaItemModel } from './SchemaItemModel';

const BaseCollection = BbCollection;
const protoFetch = BbCollection.prototype.fetch;

// const BaseCollection = fetchPromiseMixin(BbCollection);

export const SchemaItemsCollection = BaseCollection.extend({

	constructor: function (items, options) {
		options || (options = {});
		if (options.url) {
			this.url = options.url;
		}

		BaseCollection.apply(this, arguments);

	},

	model: SchemaItemModel,
	initialize () {
		this._fetched = false;
	},
	// url: function () {
	// 	return cfg.urls.apiV('realties/owners/schema');
	// },
	fetch () {
		if (this._fetched) {
			return Promise.resolve(this);
		} else {

			if (!this._fetching) {
				this._fetching = protoFetch.call(this)
					.then(arg => {
						this.prepareGroups();
						this._fetching = false;
						this._fetched = true;
						return this.onAfterFetch(arg);
					});
			}

			return this._fetching;
		}
	},
	prepareGroups () {
		this._fixRootGroup();
		this._prepareGroups();
	},
	onAfterFetch (arg) {
		return arg;
	},
	_fixRootGroup () {
		let root = this.get('root');
		if (!root) {
			root = this.add({ id: 'root', label: 'Основные х-ки', fullLabel: 'Основные х-ки', isGroup: true, order: 10000 });
			this.filter(f => !f.get('isGroup') && !f.get('parentId')).forEach(model => {
				model.set('parentId', 'root');
			});
		}
	},
	_prepareGroups () {

		this.byParentId = this.groupBy('parentId');

		this.groups = this.byParentId.undefined; // .filter(f => f.get('isGroup'));
		delete this.byParentId.undefined;
	}
});
