import { CommonListBlockView } from "./common-list-view";

import { UiMoleculeView, UiSchemaMoleculeView } from '../../../../components/ui2/molecule';
import { buildSchema, ListItemView } from 'mods/product-production/builds';

import { SchemaTextView, SchemaValueView } from 'components/ui2/schema-properties';
import { batchPurchaseSchema } from "../../../../modules/product-production/batches";



const listChildView = UiSchemaMoleculeView.extend({
	// childView: ,
	thisClassName: 'purchase-item',
	customViewOptions () {
		const opts = {
			modelSchema: batchPurchaseSchema,
			model: this.model
		};
		return opts;
	},
	initialize() {
		this.initializeClickableMixin();
	},
	url: v => '/procs/' + v.model.get('originProcess').id,
	leftItems: [
		{
			thisClassName: 'amount fixed',
			//topText: v => v.label('amount'),
			text: v => v.display('baseAmountPostfix'),
			bottomText: '&nbsp;',
			topText: '&nbsp;',
		},
		{
			thisClassName: 'process elastic',
			//topText: v => v.label('componentName'),
			text: v => v.display('originProcess'),
			bottomText: '&nbsp;',
			topText: '&nbsp;',
		},
		{
			thisClassName: 'actor fixed',
			//topText: v => v.label('componentBatchIdString'),
			text: v => v.display('actorId'),
			bottomText: v => v.display('created'),
			topText: '&nbsp;',
		},
	]
});


export const PurchasesListView = CommonListBlockView.extend({
	modelCollectionKey: 'purchases',
	header: 'закупки',
	listChildView
});