import isRange from './is-range';
// import Single from '../single';

export default function isSingle (model) {
	return !isRange(model);

	// if(_.isSimple(model))
	// 	return true;

	// if((model instanceof Single) || !isRange(model))
	// 	return true;

	// return false;
}
