import { ButtonView } from './ButtonView';

export const FaIconButtonView = ButtonView.extend({
	template: '<% if(icon) { %><i class="<%=icon%>"></i><% } %><% if (text) {%><span><%= text %></span><% } %>',
	templateContext () {
		const faIcon = this.getOption('faIcon', true);
		let icon = this.getOption('icon', true);
		if (!icon && faIcon) {
			icon = 'fa fa-' + faIcon;
		}
		return {
			icon,
			glyph: this.getOption('glyph', true),
			text: this.getOption('text', true)
		};
	}
});
