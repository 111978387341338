import { getValueByPath, stringValue, displayNum } from 'utils';
import { moment } from 'vendors';
import { invokeValue } from 'utils/invoke';
import enums from 'helpers/enums';
// import refs from 'references';
import { modelSchemaApi } from './modelSchemaApi.js';

export const propertySchemaApi = {

	getSchema (propertySchema, modelSchema) {
		if (propertySchema == null || propertySchema === '') {
			return;
		}
		const type = typeof propertySchema;

		if (type === 'object') { return propertySchema; }

		if (typeof propertySchema === 'string' && modelSchema) {
			const property = propertySchema;
			return modelSchemaApi.getPropertySchema(propertySchema, modelSchema) || { property };
		}

	},

	getValue (propertySchema, model, modelSchema) {

		propertySchema = this.getSchema(propertySchema, modelSchema);
		if (!propertySchema) { return; }

		let value;

		if (propertySchema.getValue) {
			value = propertySchema.getValue(model, modelSchema);
		} else {
			value = getValueByPath(model, propertySchema.property);
		}

		return value;
	},

	hasValue (propertySchema, model, modelSchema) {
		const value = this.getValue(propertySchema, model, modelSchema);
		return value != null && value !== '';
	},

	displayValue (propertySchema, model, modelSchema) {

		propertySchema = this.getSchema(propertySchema, modelSchema);
		if (!propertySchema) { return ''; }
        // if (propertySchema.property === "forAgent.contractType") {
        //     debugger;
        // }
		let value = this.getValue(propertySchema, model, modelSchema);

		if (propertySchema.displayValue) {
			return stringValue(propertySchema.displayValue(value, model, modelSchema), propertySchema.displayIfEmpty);
		}

		const defaultEmptyValue = propertySchema.displayIfEmpty != null ? propertySchema.displayIfEmpty : '&mdash;';

		const isEmpty = propertySchema.isEmpty
			? propertySchema.isEmpty(value)
			: (value == null || value === '');

		if (isEmpty) {
			return stringValue(defaultEmptyValue);
		}

		if (propertySchema.valueType === 'enum') {
			const enumType = propertySchema.enumType || propertySchema.valueSubType;
			if (enumType) {
				value = enums.get(enumType, value);
			}
			return stringValue(value, defaultEmptyValue);
		}

		if (propertySchema.valueType === 'datetime') {
			if (value instanceof Date && isNaN(value.valueOf())) {
				return stringValue(defaultEmptyValue);
			}
			return moment(value).format('DD.MM.YYYY hh:mm');
		}

		if (propertySchema.valueType === 'boolean') {
			const src = propertySchema.sourceValues || { true: 'да', false: 'нет' };
			return stringValue(src[value]);
		}

		if (propertySchema.valueType === 'number') {
			const digits = propertySchema.displayDigits != null ? propertySchema.displayDigits : 1;
			return stringValue(displayNum(value, digits));
		}

		return value;
	},

	getLabel (propertySchema, model, modelSchema) {
		propertySchema = this.getSchema(propertySchema, modelSchema);
		if (!propertySchema) { return; }

		const args = [model, modelSchema];
		let label = invokeValue(propertySchema.label, propertySchema, args);
		if (!label) {
			label = invokeValue(propertySchema.name, propertySchema, args);
		}
		return label;
	},

	getEditHeader (propertySchema, model, modelSchema) {
		propertySchema = this.getSchema(propertySchema, modelSchema);
		if (!propertySchema) { return; }
		let header = invokeValue(propertySchema.editHeader, propertySchema, [model, modelSchema]);
		if (!header) {
			header = this.getLabel(propertySchema, model, modelSchema) + ': установите новое значение';
		}
		return header;
	}
};
