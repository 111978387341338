import 'mod/productionProducts';
import ListView from 'mod/productionProducts/views/cards-list';
import getRawFilters from 'mod/productionProducts/filters/get';
import mix from 'helpers/mix';
import FDC from 'mixins/view/flex-dynamic-column';

import ViewManagedCollection from 'mixins/view-managed-collection';
import ScrollHandlerMixin from 'mixins/view/scroll-handler';

import FetchableMixin from 'mixins/collection-view/fetchable';
import FetchOnScrollMixin from 'mixins/collection-view/fetch-on-scroll';
import Actionable from 'mixins/actionable';
import action from 'helpers/action';

import editValue from 'bus/edit';
import Add from 'mod/productionProducts/views/add';
// import busData from 'bus/data';
import modalError from 'helpers/modals/error';

const Base = mix(ListView).with(FDC, FetchableMixin, FetchOnScrollMixin, ViewManagedCollection, ScrollHandlerMixin, Actionable);

const View = Base.extend({
	id: 'products-search-list',
	className: 'products-list',
	entriesManagerOptions: {
		infoOptions: true,
		filtersOptions: {
			id: 'page:products:search',
			getRaw: getRawFilters,
			instantApply: true
		},
		actionsEnabled: true
	},
	onRender() {
		this.fetch();
	},
	actions: [
		action('add:type', 'добавить продукцию', { resourcesProduction: 'productsEdit' })
	],
	attributes() {
		return {
			'data-scrollable': 'products-search'
		};
	},
	actionAddType() {
		const collection = this.collection;
		editValue.do({
			header: 'Добавление продукции',
			controlView: Add,
			applyLabel: 'добавить',
			resetButton: false,
			cancelLabel: 'отставить'
		}).then(
			(data) => {
				collection.create(data, { preloader: true, wait: true }).then(
					() => { },
					(xhr) => modalError('неудалось добавить продукцию', xhr)
				);
				// return busData.productTypes().create(data, {preloader: true, wait:true}).then(
				// 	() => {},
				// 	(xhr) => modalError('неудалось добавить продукцию', xhr)
				// );
			},
			() => { }
		);
	}
});

export default View;
