import { _ } from 'vendors';
import BaseView from 'base/view';
import template from './layout.html';
import HeaderView from './header';
import MenuView from './menu';
import Yat from 'marionette.yat';
import { DropFilesBehavior } from 'components/files';
import { invokeValue } from '../../utils';
const AppLayout = BaseView.extend({
	id: 'app-layout',
	el: 'body',
	template,
	instantRender: true,
	stateApiEnabled: true,
	behaviors: [DropFilesBehavior],
	initialize (opts) {
		this.mergeOptions(opts, ['app']);
	},

	regions () {
		const stateApi = this.stateApi();


		const ContentRegion = Yat.Region.extend({ stateApi });

		const content = { el: '> section.page-content', replaceElement: false, regionClass: ContentRegion };
		const regions = {
			content,
			header: { el: '> section.page-header', replaceElement: true },
			menu: { el: '> section.menu-region', replaceElement: true }
		};
		return regions;
	},
	setPage (page, deniedContext) {
		this.page = (deniedContext && deniedContext.page) || page;
	},
	showContent (view, options) {
		if (options) {
			this.showChildView('content', view, options);
		} else {
			this.showChildView('content', view);
		}
	},
	getHeaderView () {
		if (this.headerView) { this.headerView.destroy(); }

		const options = { app: this.app, page: this.page };
		this.headerView = new HeaderView(options);

		return this.headerView;
	},
	showHeader (page) {
		const view = this.getHeaderView(page);
		this.showChildView('header', view);
	},
	toggleMenu () {
		if (this.getRegion('menu').currentView) { this.hideMenu(); } else { this.showMenu(); }
	},
	showMenu () {
		const options = {
			app: this.app,
			page: this.page
		};
		const view = new MenuView(options);
		this.showChildView('menu', view);
	},
	hideMenu () {
		const region = this.getRegion('menu');
		if (region.currentView) { region.empty(); }
	},
	childViewEvents: {
		// 'header:change':'headerChange',
		'toggle:menu': 'toggleMenu',
		'go:back': 'goBack'
	},
	goBack () {
		if (!this.page) return;
		// const { args, query } = this._currentActionContext || {};
		const goBackMethod = this.page.getGoBackMethod();
		if (_.isFunction(goBackMethod)) { goBackMethod(); }
	},
	showPage (page, actionContext, deniedContext) {
		if (!this.isRendered()) { throw new Error('Showing content in not rendered RootLayout'); }

		this.setPage(page, deniedContext);
		page._currentActionContext = actionContext;
		// this._currentActionContext = actionContext;
		// let layoutOpts = { rebuild:true };
		let content;
		const useNewLayout = invokeValue(page.shouldUseNewLayout, page, page)
		if (useNewLayout) {
			content = page.getNewLayoutView(actionContext);
		} else {
			const layoutOptions = page.getOption('layoutOptions', { invokeArgs: [page, actionContext]});
			content = page.getLayout(layoutOptions, { actionContext, page });
		}
		// const layoutOptions = {};
		// const content = page.getNewLayoutView 
		// 	? page.getNewLayoutView()
		// 	: page.getLayout(layoutOptions, { actionContext, page });


		this.showContent(content, page.getOption('layoutShowOptions'));

		this.showHeader(page);

		// this.showContent(view, actionContext, deniedContext);
	}

});

export default AppLayout;
