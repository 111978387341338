import BottomAction from 'components/ui-list/action';
import View from 'base/collection-view';
import childView from './contact-list-item';
import TextTagView from 'base/text-tag-view';
// import TextTagView from 'base/text-tag-view';

export default View.extend({
	tagName: 'ul',
	className: 'with-border',
	childView,
	customViews () {
		const views = [
			() => this.getOption('header')
				? TextTagView.header(this.getOption('header'), { collectionPlace: 'top' })
				: null,
			() => new BottomAction({
				tagName: 'button',
				className: '',
				text: 'добавить контакт',
				action: 'add:contact',
				actionContext: this.model,
				collectionPlace: 'bottom'
			})
		];
		return views;
	}
});
