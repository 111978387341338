import { HamburgerView } from 'base/view-hamburger';
import { headerButtons, SearchView } from './products-common';
import View from 'base/view';

const TabsButtons = HamburgerView.extend({
	className: 'tabs-buttons inline',
	getCustomViews () {

		const buttonsView = this;
		if (buttonsView._tabsViews) {
			buttonsView._tabsViews.length = 0;
		} else {
			buttonsView._tabsViews = [];
		}
		const buttons = this._getButtons();
		return buttons.map((btn, i) => this._createButtonView(btn, i));
	},
	_getButtons () {
		return this.getOption('buttons');
	},
	_createButtonView (btn, index) {
		const rootView = this.getOption('rootView');
		const buttonsView = this;

		let className = 'tab-btn';

		if (rootView.currentTab === btn.id) {
			className += ' active';
		}

		const view = new View({
			btnId: btn.id,
			className,
			template: `<button class="${btn.id}"><i></i><span>${btn.name}</span></button>`,
			events: {
				click (e) {
					if (this.$el.hasClass('active')) {
						return;
					}
					rootView.triggerMethod('header:tab', btn.id);
					console.log('--triggered--', btn.id);
					buttonsView._tabsViews.forEach(element => {
						if (element !== this) {
							element.$el.removeClass('active');
						} else {
							element.$el.addClass('active');
						}
					});
				}
			}
		});

		buttonsView._tabsViews.push(view);

		return view;
	}
});


export const ProductsHeader = HamburgerView.extend({
	className: 'products-header',
	getCustomViews () {
		const searchOnEnter = true;
		const filterModel = this.getOption('filterModel');
		const rootView = this.getOption('rootView');
		const searchView = new SearchView({ value: filterModel.get('name') });
		this.listenTo(searchView, 'user:input', (text, done) => {
			if (!searchOnEnter || done) {
				rootView.triggerMethod('search:by:name', text);
			}
		});

		const views = [
			{
				class: TabsButtons,
				buttons: headerButtons,
				rootView
			},
			searchView
		];


		return views;
	}

});
