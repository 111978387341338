import { _ } from 'vendors';
import View from 'base/view';
import { measures, getInputNumberValue, preventNonNumberTextInput } from '../../../utils';



export const BatchLineView = View.extend({
	className: 'batch-line-item ui2-molecule',
	template: `<div class="ui2-atom created">
        <div><%= moment(created).format('DD.MM.YY') %></div>
    </div>
    <div class="ui2-atom identifier">
        <div><span class="batch-identifier"><%= idString%></span></div>
    </div>
    <div class="ui2-atom amount">
    <i>на складе</i>
    <div><%= _.displayNum(baseAmount,4) %> <%= baseUnit %></div>
    </div>
    <div class="icon"><span class="fa fa-arrow-right"></span></div>
    <div class="user-input"><input name="newvalue" type="text"/><section class="refresh-timer"></section></div>
    <div class="ui2-atom newamount-delta">
    <i>изменение</i>
    <div data-role="value"><%= displayNewAmountDelta %></div>
    </div>
    <div class="ui2-atom amount">
    <i>у кон-тов</i>
    <div><%= _.displayNum(contragentsAmount,4) %> <%= baseUnit %></div>
    </div>
    `,

	refreshDelay: 2000,

	initialize () {
		this.product = this.getOption('product');
		this.onNewValueInput = _.debounce(this._onNewValueInput.bind(this), 400);
	},
	getNewAmountDelta (newamount) {
		const amount = this.model.get('baseAmount');
		const newAmount = newamount != null ? newamount : this.model.get('newAmount');
		if (newAmount == null) {
			return;
		}
		return newAmount - amount;
	},
	getDisplayNewAmountDelta (newamount) {
		const baseUnit = measures(this.product.get('baseUnitMeasureId'));
		const delta = this.getNewAmountDelta(newamount);
		let display = '&mdash;';
		if (delta) {
			display = _.displayNum(delta, 4) + ' ' + baseUnit;
			if (delta > 0) { display = '+' + display; }
		}
		return display;
	},
	templateContext () {
		const baseUnit = measures(this.product.get('baseUnitMeasureId'));
		return {
			baseUnit,
			displayNewAmountDelta: this.getDisplayNewAmountDelta()
		};
	},
	_onNewValueInput (event) {
		const valueHash = getInputNumberValue(event.target);
		this.model.set('newAmount', valueHash.numberValue);
		this.product.setNewAmount(this.model.id, valueHash.numberValue);
		this._updateNewAmountDelta();
        // console.log('-newamount-', this.model);
	},
	_updateModelNewAmount (newAmount) {
		this.model.set('newAmount', newAmount);
		this.product.setNewAmount(this.model.id, newAmount, this.model);
	},
	_updateNewAmountDelta (newamount) {
		let delta;
		if (!arguments.length) {
			delta = this.getDisplayNewAmountDelta();
		} else {
			delta = this.getDisplayNewAmountDelta(newamount);
		}
		this.$('.newamount-delta [data-role="value"]').html(delta);
	},
    // modelEvents: {
    //     'change:newAmount'() {
    //         this.$('.newamount-delta [data-role="value"]').html(this.getDisplayNewAmountDelta());
    //     }
    // },
	events: {
		'input input[name="newvalue"]' (event) {
			const valueHash = getInputNumberValue(event.target);
			this._updateNewAmountDelta(valueHash.numberValue);
			this._updateRefreshTimer(valueHash.numberValue);
            // return this.onNewValueInput(event);
		},
		'keydown input[name="newvalue"]': preventNonNumberTextInput,
		'focus input[name="newvalue"]' (e) { this.trigger('focus', this); },
		'blur input[name="newvalue"]' (e) { this.trigger('blur', this); }
	},
	_updateRefreshTimer (newValue) {
		if (this.refreshTimer) {
			clearInterval(this.refreshTimer);
		}

		const start = Date.now();
		const end = start + this.refreshDelay;
		this.refreshTimer = setInterval(() => {
			let stopped = '';
			let htmlValue = '';
			const now = Date.now();
			if (now > end) {
				clearInterval(this.refreshTimer);
				stopped = 'stopped';
			} else {
				const secondsLeft = (end - now) / 1000;
				htmlValue = _.displayNum(secondsLeft, 1);
			}
            // console.log('-seconds-', end - now, (end - now) / 1000, stopped, ':', htmlValue);
			this._updateRefreshTimerEl(htmlValue);
			if (stopped) {
				this._updateModelNewAmount(newValue);
			}
		}, 100);
	},
	_updateRefreshTimerEl (html) {
		this.$('.refresh-timer').html(html);
	},
	setFocus () {
		this.$('input[name="newvalue"]').focus();
	}

});
