import Model from 'base/model';
export const CreateCommentModel = Model.extend({
	constructor (attrs = {}) {
		this.parent = attrs.parent;
		delete attrs.parent;
		Model.apply(this, arguments);
	},
	url () {
		return this.parent.url() + '/comments';
	}
});
