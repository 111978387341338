import BasePage from 'pages/auth';
import { /* Layout, */ NewLayout } from './layout';
// import { NewLayout } from './new-layout';
import { ProductsCollection, productionSalesApiUrl, productionAllInfoApiUrl } from './models';
// import Collection from 'base/collection';
import Model from 'base/model';

const Page = BasePage.extend({
	moduleRights: { productProduction: 'view' },
	label: 'структура производства',
	route: 'full-proc-tree',
	Layout: NewLayout,
	// Layout,
	// children:[Production],
	icon: 'product-production',
	onBeforeStart () {
		if (!this.collection) {
			const col = new ProductsCollection();
			col.url = productionSalesApiUrl;
			this.addCollection(col);
			this.addStartPromise(col.fetchIfNot());
		}

		if (!this.model) {
			const model = new Model();
			model.url = productionAllInfoApiUrl;
			this.addModel(model);
			this.addStartPromise(model.fetchIfNot());
		}
		// model.fetch();
	}
});

export default Page;
