import Model from 'base/model';


export const productStoreBatchesUrlPattern = 'res/prod/products/:productId/store';
export const contragentProductStoreBatchesUrlPattern = 'contragents/:contragentId/products/:productId/store';

export const PartyModel = Model.extend({
	addAmount (value) {
		const initial = this.get('amount') || 0;
		this.set('amount', initial + value);
	},
	removeAmount (data) {
		return this.save(data, { method: 'post', url: this.url() + '/manual-remove', wait: true });
	},
	getBaseAmount (product) {
		// debugger;
		let amount = this.get('amount');
		let batchInStore = this.get('batchUnitsInStoreUnit');
		if (!batchInStore) {
			batchInStore = 1;
		}
		amount = amount * batchInStore;
		let baseInStore = product.get('baseUnitsInStoreUnit');
		if (!baseInStore) {
			baseInStore = 1;
		}
		amount = amount * baseInStore;
		return amount;
	}
});
