import { _ } from 'vendors';
import BaseView from 'base/view';
import template from './index.html';
import Selected from './selected';
import Month from './month';
import Months from './months-selection';
import Years from './years-selection';
import Values from 'components/values';
import mix from 'helpers/mix';
import ControlsMixin from 'components/controls/mixin';
import Predefined from './predefined';

export default mix(BaseView).with(ControlsMixin).extend({
	template,
	className: 'c-datetime',
	regions: {
		selected: { el: 'div.selected', replaceElement: true },
		predefined: { el: 'div.predefined', replaceElement: true },
		content: { el: 'div.content', replaceElement: true }
	},
	initialize () {
		this.initializeModel();
	},
	initializeModel () {
		if (this.model) return;

		const date = Date.info(this.getOption('value')).date;
		const startDate = Date.info(this.getOption('startTime')).date || Date.create({ minutes: 15 });

		const hash = this.getOption('modelType') === 'range'
			? this.getOption('value') || {}
			: date > startDate ? date : startDate;

		const values = this.getOption('predefinedValues');
		if (values) {
			this.predefinedCollection = Values.collection(values, {
				swapKeyAndValue: this.getOption('revertPredefinedValues'),
				modelType: this.getOption('modelType')
			});
		}

		this.model = Values.model(hash, {
			modelType: this.getOption('modelType')
		});
	},
	onRender () {
		this.showSelected();
		this.showPredefined();
		this.showMonth();
	},
	showSelected () {
		const view = new Selected({
			model: this.model,
			noTime: this.getOption('noTime'),
			startTime: this.getOption('startTime')
		});
		this.showChildView('selected', view);
	},
	showPredefined () {
		if (!this.predefinedCollection) return;
		const view = new Predefined({
			collection: this.predefinedCollection
		});
		this.showChildView('predefined', view);
	},
	showInContent (view) {
		this.showChildView('content', view);
	},
	showMonth () {
		const view = new Month({
			proxyTo: this,
			controlName: 'monthDay',
			model: this.monthModel,
			valueModel: this.model,
			startTime: this.getOption('startTime'),
			defaultTime: this.getOption('defaultTime')
		});
		this.monthModel = view.model;
		this.showInContent(view);

		if (this.monthListeningSettled) return;
		this.listenTo(this.monthModel, 'ready', this.showMonth);
		this.monthListeningSettled = true;
	},
	showYearsSelection () {
		const view = new Years({ model: this.monthModel });
		this.showInContent(view);
	},
	showMonthSelection () {
		const view = new Months({
			model: this.monthModel,
			valueModel: this.model
		});
		this.showInContent(view);
	},
	controlsEvents: {
		'monthDay:change' (value) {
			this.updateValue(value);
		}
	},
	childViewEvents: {
		'years:selection:click' () {
			this.showYearsSelection();
		},
		'months:selection:click' () {
			this.showMonthSelection();
		},
		'value:clear' () { this.clearProperty('value'); },
		'from:clear' () { this.clearProperty('from'); },
		'to:clear' () { this.clearProperty('to'); },
		'predefined:selected' (model) {
			this.updateValue(model.getValue());
		}
	},
	updateValue (value) {
		if (this.model.type === 'single') {
			this.model.set('value', value);
		} else if (this.model.type === 'range') {
			const update = {};
			if (!this.model.hasValue() || (!!this.model.get('from') && !!this.model.get('to'))) {
				update.from = value;
				update.to = undefined;
			} else {
				const ar = [this.model.get('from') || this.model.get('to'), value];

				update.from = _(ar).min();
				update.to = _(ar).max();

				update.from = new Date(update.from.valueOf());
				update.to = new Date(update.to.valueOf());

				update.from.setHours(0, 0, 0, 0);
				update.to.setHours(23, 59, 59, 999);
			}
			this.model.set(update);
		}
		this.model.trigger('refresh');

		if (this.model.hasValue()) {
			this.triggerChange(this.getValue());
		}
	},
	clearProperty (prop) {
		this.model.has(prop) && this.model.set(prop, undefined);

		if (this.model.type === 'single') {
			this.model.trigger('refresh');
			return;
		}

		if (prop === 'from') { this.dayClickCounter = false; } else if (prop === 'to') { this.dayClickCounter = true; }

		if (!this.model.hasValue()) { this.dayClickCounter = false; }

		this.model.trigger('refresh');

		if (this.model.hasValue()) { this.triggerChange(this.getValue()); }
	}

});
