module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 if (header) {
__p+='<header '+
((__t=( headerClass ))==null?'':__t)+
'></header>';
 } 
__p+='\n<section></section>\n';
 if (footer) {
__p+='<footer></footer>';
 } 
__p+='\n';
}
return __p;
};
