import CollectionView from 'base/collection-view';
import View from 'base/view';
import ControlMixin from 'components/controls/mixin';
import modalError from 'helpers/modals/error';
import { bus } from './bus';
import { GetInfoMixin } from './common';
import { ComponentsCollection } from './ComponentsCollection';
import { ComponentsListView } from './ComponentsListView';
import { ConfirmationView } from './ConfirmationView';
import { InputAmountView } from './InputAmountView';
import { ProductInfoView } from './ProductInfoView';



export const AcceptReadyProductLayout = ControlMixin(CollectionView).extend({
	className: 'accept-ready-product',

	...GetInfoMixin,

	customViews () {
		const separator = { className: 'view-separator', template: '' };

		const defs = this.getDefOptions();

		this.components = new ComponentsCollection();

		return [
			new ProductInfoView(defs),
			new View(separator),
			new InputAmountView(defs),
			new View(separator),
			new ComponentsListView({ ...defs, collection: this.components }),
			new View(separator),
			new ConfirmationView(defs)
		];
	},
	validate (model, options = {}) {
		console.error('# validate #');
		const { produceAmount, components = [] } = model;

		const errors = [];

		components.forEach(com => {
			console.error('# validate component #');
			const { /* awaitedAmount, */ parties, /* footprintless, */ optional /*, modifyOnlyByInteger */ } = com;

			// const componentType = optional
			// 	? 'optional'
			// 	: footprintless
			// 		? 'needed'
			// 		: 'important';

			const isOptional = optional === true;
			const isNeeded = !isOptional;

			const postedAmount = parties.reduce((sum, party) => {
				const postAmountPositive = party.postAmount > 0;
				const postAmountNegative = !postAmountPositive;

				if (party.baseAmount < party.postAmount) {
					errors.push({ type: 'party', id: party.id, message: 'у контрагента недостаточно продукции партии ' + party.idString });
					return sum;
				}

				if (postAmountNegative && isNeeded) {
					errors.push({ type: 'party', id: party.id, message: 'количество продукции в партии ' + party.idString + ' должно быть положительным' });
				}
				return sum + party.postAmount;
			}, 0);

			if (!(postedAmount > 0) && isNeeded) {
				errors.push({ type: 'product', id: com.productId, message: 'недостаточное количество компонента ' + com.productName });
			}
            // if (awaitedAmount > postedAmount && !com.optional) {
            //     errors.push({ type: 'product', id: com.productId, message: 'недостаточное количество компонента ' + com.productName });
            // }
		});

		if (!(produceAmount > 0)) {
			errors.push({ type: 'post', message: 'объем производимой продукции должен быть положительным числом' });
		}


		if (errors.length) {
			options.errors = errors;
			bus.notconfirmed();
			return false;
		}

		console.error('# validate done #');

		return true;
	},
	initialize () {

		this.value = {};
		this.listenTo(bus, 'confirmed', this.update);
	},
	onRender () {

		if (this._busunconfirmedlistener) return;
		this.listenTo(bus, 'unconfirmed:change', this.invalidate);
		this._busunconfirmedlistener = true;
	},
	invalidate () {
		this.value = {};
		this._triggerEvent('validate:error');
	},
	update () {
		const model = this.value = this.components.getPostModel();
		this.triggerChange(model);
	},
	onControlValidateError (model, { errors = [], validateAtRenderTime } = {}) {
		if (validateAtRenderTime || !errors.length) return;
		const message = errors.map(err => err.message).join('.<br>\r\n');
		modalError(message);
	}
});
