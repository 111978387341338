// import { FilterModel } from './FilterModel';
import { filters } from './filters';
import { ReportCollection } from './ReportCollection';
import { tableSchema } from './tableSchema';
// import { TableLayoutView } from 'components/schema-table';
// import { commonTableLayoutMixin } from '../table-layout-mixin';
import { CommonReportLayout } from '../common-report-layout';

const src = [
	'product',

	'totalBefore',
	'storeBefore',
	'contragentsBefore',
	// 'inProductsBefore',

	'incrementNotManual',
	'manualIncrement',

	'decrementSum',

	'usedForProduction',
	'defect',
	'manualDecrement',
	'sold',
	'returnedToManufacturer',
	'utilized',

	// 'decrementCalculated',

	// 'inProductsAfter',
	'contragentsAfter',
	'storeAfter',
	'totalAfter'

];


export const ProductionReportLayout = CommonReportLayout.extend({

	rawFilters: filters,

	__cells: [
		'product',

		'totalBefore',
		'storeBefore',
		'contragentsBefore',

		'appeared',
		'specificIncrement',
		'produced',
		'purchased',

		'disappeared',
		'specificDecrement',
		'spentOnProduction',
		'defect',
		'utilized',

		'contragentsAfter',
		// 'contragentsDelta'
		// 'storeDelta',
		'storeAfter',
		'totalAfter'
		// 'inProductsBefore', 'inProductsAfter', 'inProductsDelta'
	],
	cells: [
		...src
	],
	tableSchema,
	Collection: ReportCollection


	// rowOptions: {
	// 	baseClassName: 'table-report-entry',
	// 	thisClassName: 'product-before-after',
	// 	tagName: 'tr',
	// 	useWrapper: false,
	// 	useMolecule: false
	// },
	// listOptions: {
	// 	tagName: 'table',
	// 	baseClassName: 'strict-table'
	// },
	// cellOptions: {
	// 	tagName: 'td',
	// 	baseClassName: 'table-cell'
	// },
	// tableConfig: {
	// 	headersInside: true,
	// 	cellsWithoutLabels: true
	// }



});
