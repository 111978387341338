import merge from 'helpers/merge';
import Rights from './entity-rights';
import UiCardBlock from 'components/ui-card-block/block';


export default (model, opts = {}) => {
	const defs = {
		viewClass: UiCardBlock,
		viewOptions: {
			header: 'права',
			headerOptions: {
				className: 'bg-main-dark'
			},
			content: Rights,
			contentOptions () {
				// console.log('???', model.getRights());
				// console.log('???', model.get('rights'));
				return {
					model,
					collection: model.getRights(),
					shouldFetch: opts.shouldFetch
				};
			}
		},
		regionTemplate: { replaceElement: true }
	};

	return merge({}, defs, opts);
};
