// import CollectionView from 'base/collection-view';
import { PartiesView, PartiesListView, PartiesHeaders, AmountView } from './parties';
import { getStoreCollection } from './parties-collection';
import UiCardBlock from 'components/ui-card-block/block';
// import user from 'app/user';
import { buildTabsHeader } from './tabs';
import { BlockFooterTextAction, JustInputView } from 'components/ui2';

import { BaseBlockLayout } from '../base-block-layout';
import smartOpen from 'helpers/smart-open';

export const _UiStoreBlock = UiCardBlock.extend({
	className: 'double-width product-store-block ui2-block',
	header: { text: 'партии и количество', addClass: 'bg-main-dark' },
	cssClassModifiers: [],
	action: 'add:store:party',
	content: PartiesView,
	getCollection () {
		return getStoreCollection(this.model);
	},
	contentOptions () {
		return {
			model: this.model,
			collection: this.getCollection()
		};
	},
	actionArguments () {
		return [this.getCollection()];
	}
});



export const StoreBlockLayout = BaseBlockLayout.extend({
	getCollection () {
		return getStoreCollection(this.model);
	},
	canManuallyAdd () {
		return this.model.storeBatchCanBeManuallyAdded();
	},
	customViews () {
		const defs = {
			model: this.model, collection: this.getCollection()
		};



		// поиск по номеру партии...
		return [
			buildTabsHeader('root', this.ac, true),
			new JustInputView({
				...defs,
				placeholder: 'поиск по номеру партии...',
				onChange (text) {
					this.collection.trigger('id:contains', text);
				}
			}),
			new PartiesHeaders(defs),
			new PartiesListView({
				className: 'elastic-content',
				...defs
			}),
			new AmountView(defs),
			new BlockFooterTextAction({ text: 'показать все партии', clickAction: 'show:all:paries:click' }),
			// user.checkRights({ productsStore: 'acceptProducts' })
			this.canManuallyAdd()
				? new BlockFooterTextAction({ text: 'добавить партию', clickAction: 'add:party:click' })
				: null
		];
	},
	childViewEvents: {
		'add:party:click' () {
			this.model.executeAction('add:store:party', this.getCollection());
		},
		'show:all:paries:click' (event) {
			const url = `/prod/batches?productId.v=` + this.model.id;
			smartOpen(url, event);
		}

	}
});
