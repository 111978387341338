import { _ } from 'vendors';
import ModelProperties from 'components/ui-model-card/properties';
import h from 'components/ui-model-card/helpers';
import TypeModel from '../models/model';
import ControlMixin from 'components/controls/mixin';

function initModel (model, parent) {
	if (model) return;
	const parentId = (parent && parent.id) || undefined;
	this.model = new TypeModel({ parentId }, { parent });
}

const defs = { editButton: true, addClass: 'whole-btn', textAction: 'edit' };
const Base = ControlMixin(ModelProperties);
export default Base.extend({
	constructor (opts) {
		initModel.call(this, this.model || opts.model, this.parent || opts.parent);
		Base.apply(this, arguments);
		this.on('render', () => this.trigger('content:ready'));
	},
	className: 'card lined with-border create-product-type',
	properties: [
		h.ui('name', _.clone(defs))
	],
	getValue () {
		return this.model.toJSON();
	},
	modelEvents: {
		'change' () {
			this.triggerChange();
		}
	},
	validate (value) {
		return value && !!value.name;
	}
});
