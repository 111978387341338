import { _ } from 'vendors';
import Item from 'components/ui-list/item';
import Base from 'base/view';
import Collection from 'base/collection';
import CollectionView from 'base/collection-view';


export default Base.extend({
	constructor (opts) {
		this.collection = new Collection();
		Base.apply(this, arguments);
		this.mergeOptions(opts, ['actions', 'header']);

		this.updateCollection();

		this.createNestedHeader();
		this.createNestedActions();
		const refreshEvents = this.getOption('refreshOnModelEvents') || [];
		_.each(refreshEvents, eventName => {
			this.listenTo(this.model, eventName, this.updateCollection);
		});
	},
	template: Base.emptyTemplate,
	modelEvents: {
		change: 'updateCollection'
	},
	updateCollection () {
		// console.log('>>>> UPDATE ACTIONS COLLECTION <<<<');
		const allowed = this.actions || [];
		const actions = this.model.getActions({
			filter: (m) => allowed.indexOf(m.id) > -1
		});
		this.collection.reset(actions);
	},
	createNestedHeader () {
		if (!this.header) return;
		const name = this._cidedName('header');
		this.createNested(name, {
			viewClass: Base.extend({ tagName: 'header', className: this.header.addClass, template: _.template(this.header.text) }),
			regionTemplate: { replaceElement: true }
		});
	},
	createNestedActions () {
		const model = this.model;
		this.createNested('nestedActions', {
			viewClass: CollectionView,
			viewOptions: {
				collection: this.collection,
				tagName: 'ul',
				childView: Item.extend({
					tagName: 'li',
					addClass: 'as-button action',
					text: (m) => m.get('label'),
					onTextClick (action) {
						model.executeAction(action.id);
					}
				}),
				emptyView: Item.Empty.extend({
					model,
					text: this.getOption('emptyViewText', { force: false }) || 'нет доступных действий'
				})
			},
			regionTemplate: { replaceElement: true }
		});
	},
	onRender () {
		this._showOwn('header');
		this.showNestedActions();
	},
	_cidedName (name, prefix = '') {
		return prefix + _.camelCase(this.cid + ':' + name, !!prefix);
	},
	_showOwn (name) {
		const method = this._cidedName(name, 'show');
		if (_.isFunction(this[method])) { this[method](); }
	}
});
