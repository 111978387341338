import { _ } from 'vendors';
import View from 'base/view';
import UiCardBlock from 'components/ui-card-block/block';
import Tasks from './list';

const HeaderView = View.extend({
	className: 'bg-main-dark',
	tagName: 'header',
	template: _.template('<span><%= label %></span><button><%= alternate %></button>'),
	templateContext () {
		const mode = this.getOption('tasksMode');
		const label = mode === 'completed' ? 'завершённые задачи' : 'задачи';
		const alternate = mode === 'completed' ? 'задачи' : 'завершённые задачи';
		return {
			label,
			alternate
		};
	},
	events: {
		'click button' () {
			let mode = this.getOption('tasksMode');
			mode = mode === 'completed' ? 'active' : 'completed';
			this.trigger('switch:tasks', mode);
			// this.model.executeAction('show:completed:tasks');
		}
	}
});

export function buildTasksViewOptions(view, viewOptions = {}) {
	let header = { text: 'задачи', addClass: 'bg-main-dark' };
	let headerOptions;
	if (viewOptions.completedTasksEnabled) {
		// header = new HeaderView({ model: context.model });
		header = HeaderView;
		headerOptions = function () {
			return {
				tasksMode: this.tasksMode === 'completed' ? 'completed' : 'active',
				model: view.model
			};
		};
	}

	viewOptions = _.extend({
		header,
		headerOptions,
		content: Tasks,
		activeTasksOptions () {
			return {
				addClass: 'scrolled-y',
				collection: model.getTasks(),
				viewFilter: (v) => v.model.isNotClosed(),
				viewComparator: v => v.model.getDeadline()
			};
		},
		completedTasksOptions () {
			const col = this.model.getCompletedTasks();
			col.fetch();
			return {
				addClass: 'scrolled-y',
				collection: col,
				viewFilter: (v) => !v.model.isNotClosed(),
				viewComparator: v => -v.model.getClosedDate()
			};
		},
		contentOptions () {
			const options = this.tasksMode === 'completed'
				? this.getOption('completedTasksOptions')
				: this.getOption('activeTasksOptions');

			return options;
		},
		action: 'add:task',
		childViewEvents: {
			'switch:tasks' (type) {
				this.tasksMode = type;
				this.render();
			}
		}
	}, viewOptions);

	const model = view.model;
	viewOptions.class = UiCardBlock;

	return viewOptions;

}

export default function createNestedTasks (context, viewOptions = {}) {
	let header = { text: 'задачи', addClass: 'bg-main-dark' };
	let headerOptions;
	if (viewOptions.completedTasksEnabled) {
		// header = new HeaderView({ model: context.model });
		header = HeaderView;
		headerOptions = function () {
			return {
				tasksMode: this.tasksMode === 'completed' ? 'completed' : 'active',
				model: context
			};
		};
	}

	viewOptions = _.extend({
		header,
		headerOptions,
		content: Tasks,
		activeTasksOptions () {
			return {
				addClass: 'scrolled-y',
				collection: model.getTasks(),
				viewFilter: (v) => v.model.isNotClosed(),
				viewComparator: v => v.model.getDeadline()
			};
		},
		completedTasksOptions () {
			const col = this.model.getCompletedTasks();
			col.fetch();
			return {
				addClass: 'scrolled-y',
				collection: col,
				viewFilter: (v) => !v.model.isNotClosed(),
				viewComparator: v => -v.model.getClosedDate()
			};
		},
		contentOptions () {
			const options = this.tasksMode === 'completed'
				? this.getOption('completedTasksOptions')
				: this.getOption('activeTasksOptions');

			return options;
		},
		action: 'add:task',
		childViewEvents: {
			'switch:tasks' (type) {
				this.tasksMode = type;
				this.render();
			}
		}
	}, viewOptions);

	const model = context.model;
	context.createNested('tasks', {
		viewClass: UiCardBlock,
		viewOptions
	});
}
