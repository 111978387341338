import { _, $ } from 'vendors';
import View from 'base/view';
import EntriesManager from 'components/entries-manager';

import paths from 'helpers/paths';
import smartOpen from 'helpers/smart-open';

import user from 'app/user';

const Switcher = View.extend({
	className: 'card lined switcher state-switcher',
	template: _.template(`
	<div data-id="all" class="line whole-btn-bg <%= allActive %>"><div><i></i><span>все</span></div></div>
	<div data-id="active" class="line whole-btn-bg <%= activeActive %>"><div><i></i><span>действующие</span></div></div>
	<div data-id="completed" class="line whole-btn-bg <%= completedActive %>"><div><i></i><span>завершённые</span></div></div>
`),
	templateContext () {
		const type = this.getOption('type');
		return {
			allActive: type === 'all' ? ' active' : '',
			activeActive: type === 'active' ? ' active' : '',
			completedActive: type === 'completed' ? ' active' : ''
		};
	},
	events: {
		'click [data-id]:not(.active)' (e) {
			const $el = $(e.target).closest('[data-id]');
			const type = $el.data('id');
			smartOpen(paths.url('processes:' + type), e);
		}
	}
});

const UrlButonView = View.extend({
	className: 'card lined switcher state-switcher',
	template: _.template('<div class="line whole-btn-bg"><div><i></i><span><%= text %></span></div></div>'),
	events: {
		'click' (e) {
			const url = this.getOption('url');
			smartOpen(url, e);
		}
	},
	templateContext () {
		return {
			text: this.getOption('text')
		};
	}
});

// const CreateButton = View.extend({
// 	className: 'card lined switcher state-switcher',
// 	template: _.template('<div class="line whole-btn-bg"><div><i></i><span>запустить новый процесс</span></div></div>'),
// 	events: {
// 		'click' (e) {
// 			smartOpen(paths.url('processes:start'), e);
// 		}
// 	}
// });

export default EntriesManager.extend({
	initialize () {
		this.initInfo();
		this.initSwitcher();
		this.initFilters();
		this.initCreate();
	},
	initSwitcher () {
		this.createNested('switcher', {
			viewClass: Switcher,
			viewOptions: {
				type: this.getOption('type')
			}
		});
	},
	initCreate () {
		this.createNested('toBoard', {
			// condition: () => user.checkRights({ processes: 'startProcess' }),
			viewClass: UrlButonView,
			viewOptions: {
				text: 'Доска процессов',
				url: paths.url('processes:board')
			}
		});
		this.createNested('stateSwitcher', {
			condition: () => user.checkRights({ processes: 'startProcess' }),
			viewClass: UrlButonView,
			viewOptions: {
				text: 'Запустить новый процесс',
				url: paths.url('processes:start')
			}
		});
	}
});
