import { _, $ } from 'vendors';
import View from 'base/view';
import Model from 'base/model';
import Collection from 'base/collection';
import CollectionView from 'base/collection-view';
import DepartmentLinks from './department-links';

import BasePage from 'pages/auth';

import { DepartmentModel, storeApi } from '../models';

import Bb from 'backbone';

import { InlineSelectControl } from 'components/controls2/inline-select';
import { InlineDaterangeControl } from 'components/controls2/inline-daterange';

import ScrollHandlerMixin from 'mixins/view/scroll-handler';
import FetchableMixin from 'mixins/collection-view/fetchable';
import FetchOnScrollMixin from 'mixins/collection-view/fetch-on-scroll';
import { PageWithDivisionsLayout } from 'components/page-sub-division';
import { PageWithDivisions } from '../../../pages';
// import { values } from 'underscore';


export const stringComparator = (getters, v1, v2) => {
	const gettersType = typeof getters;

	if (gettersType === 'string') {
		const key = getters;
		getters = [v => v.model.get(key)];
	} else if (gettersType === 'function') {
		getters = [getters];
	}

	for (let x = 0; x < getters.length; x++) {
		const getter = getters[x];
		const val1 = getter(v1);
		const val2 = getter(v2);

		if (val1 < val2) {
			return -1;
		} else if (val1 > val2) {
			return 1;
		}
	}
	return 0;
};


export const PageLayout = View.extend({
	cssClassModifiers: [
		(m, v) => v.page.pageId,
		(m, v) => v._sidebarCollapsed ? 'sidebar-collapsed' : ''
	],
	template: '<section class="department-links-holder"></section><section class="information"></section>',
	regions: {
		nav: '.department-links-holder',
		info: '.information'
	},
	onRender () {
		this.showLinks();
		this.showContent();
	},
	showLinks () {
		const view = new DepartmentLinks({ model: this.model, currentPage: this.page.pageId });
		this.listenTo(view, 'collapsed', (collapsed) => this.triggerMethod('sidebar:collapsed', collapsed));
		this.showChildView('nav', view);
	},
	showContent () {
		const view = this.contentView = this.buildContentView();
		// view.listenTo(this, 'sidebar:collapsed', collapsed => view.trigger('sidebar:collapsed', collapsed));
		this.showChildView('info', view, { replaceElement: true });
	},
	buildContentView () {
		const ViewClass = this.getOption('contentView');
		let viewOptions = this.getOption('contentViewOptions');
		viewOptions = Object.assign({ model: this.model, addClass: 'information', tagName: 'section', ac: this.actionContext }, viewOptions);
		const view = new ViewClass(viewOptions);
		return view;
	},
	onSidebarCollapsed (collapsed) {
		this._sidebarCollapsed = collapsed;
		this.refreshCssClass();
	}
});

function buildSelectOption (item) {
	const isSelected = item.selected ? ' selected' : '';
	return `<option value="${item.value || ''}"${isSelected}>${item.name || '[без названия]'}</option>`;
}

function buildSelect (select) {
	const options = select.items.map(item => buildSelectOption(item)).join('');
	let labelHtml = '';
	if (select.label) {
		labelHtml = `<label>${select.label}</label>`;
	}
	const inputHtml = `<select name="${select.name}">${options}</select>`;
	return `<div class="${select.class || ''}">${labelHtml}${inputHtml}</div>`;
}

const htmlBuilders = {
	select: (filterModel, departmentModel) => {
		const values = filterModel.get('values');
		const filter = filterModel.attributes;
		const items = filter.itemsMap(values, departmentModel);
		const cssClass = filterModel.getCssClass();
		const hash = {
			name: filter.id,
			label: filter.name,
			class: cssClass,
			items
		};
		return buildSelect(hash);
	}
};

const LogTransferFiltersModel = Model.extend({
	initialize () {
		if (!this.attributes.values) { this.attributes.values = []; }
	},
	setValue (newvalue) {
		const valuesArray = newvalue ? [newvalue] : [];
		this.set('values', valuesArray);
	},
	getCssClass () {
		const css = ['filter-entry'];
		const cssClass = this.get('cssClass');
		if (cssClass) {
			css.push(cssClass);
		} else {
			const type = this.get('controlType');
			css.push(type);
		}
		return css.join(' ');
	},

	buildHtml (depModel) {
		const a = this.attributes;
		if (typeof (a.buildHtml) === 'function') {
			return a.buildHtml(this, depModel);
		} else if (htmlBuilders[a.controlType]) {
			return htmlBuilders[a.controlType](this, depModel);
		}
		return '';
	},

	urlParam () {
		const value = this.get('values') || [];

		if (!value.length) { return; }

		const vals = this._mapValues();

		const ready = vals.reduce((memo, val) => {
			Object.keys(val).forEach(key => {
				memo.push(key + '=' + val[key]);
			});
			return memo;
		}, []);

		return ready.join('&');

        // return this.id + '.v=' + value.join(',');
	},
	_mapValues (deepness) {
		const maped = [];
		const values = this.get('values');
		if (values && !values.length) { return maped; }

		for (let x = 0; x < values.length; x++) {
			if (deepness && x >= deepness) { return maped; }

			const newone = this._mapValue(values[x]);
			if (newone != null) { maped.push(newone); }
		}

		return maped;
	},
	_jsonValue (arg) {
		if (!arg) return arg;
		if (arg.toJSON) {
			return arg.toJSON();
		}
		return arg;
	},
	_mapValue (value) {
		if (value == null) return;
		const res = {};
		if ('value' in value) {
			if (value.value != null) { res[this.id + '.v'] = this._jsonValue(value.value); }
		} else {
			if (value.from) {
				res[this.id + '.f'] = this._jsonValue(value.from);
			}
			if (value.to) {
				res[this.id + '.t'] = this._jsonValue(value.to);
			}
		}
		return res;
	},
	getData () {
		const values = this._mapValues();
        // let values = this.get('values');
		if (values && !values.length) { return; }

		return values[0];
	}
});

const LogTransferFiltersCollection = Collection.extend({
	model: LogTransferFiltersModel,
	initialize () {
		this.on('change', this.updateDocumentSearch);
	},
	urlParams () {
		const urlparams = this.models.map(m => m.urlParam()).filter(f => !!f).join('&');
		return urlparams;
	},
	updateDocumentSearch () {
		const params = this.urlParams();
		console.log('- update document search -', params);
		this.trigger('new:url', params);
        // document.location.search = params;
	},
	getData () {
		return this.models.reduce((memo, model) => {
			Object.assign(memo, model.getData());
			return memo;
		}, {});
	}
});

const allTransferFilters = [
	{
		id: 'contragents',
		name: 'контрагент',
		controlType: 'select-inline',
        // buildHtml: htmlBuilders.select,
		itemsMap: (values = [], departmentModel) => {
			let items = departmentModel.get('contragents').map(c => ({ name: c.name, value: c.id, selected: values.indexOf(c.id) > -1 }));
			items = _.sortBy(items, 'name');
			items.unshift({ name: 'все', value: undefined });
			return items;
		}
	},
	{
		id: 'products',
		name: 'продукция',
		controlType: 'select-inline',
        // buildHtml: htmlBuilders.select,
		itemsMap: (values = [], departmentModel) => {
			let items = departmentModel.get('products').map(c => ({ name: c.fullName, value: c.id, selected: values.indexOf(c.id) > -1 }));
			items = _.sortBy(items, 'name');
			items.unshift({ name: 'все', value: undefined });
			return items;
		}
	},
	{
		id: 'period',
		name: 'Период',
		controlType: 'daterange-inline'
        // buildHtml(filterModel, departmentModel) {
        //     return '<button>период</button>';
        // }
	}
];

// const logTransferFilter = new LogTransferFiltersModel();

const convertToken = token => {
	switch (token) {
	case 'v':
		return 'value';
	case 'f':
		return 'from';
	case 't':
		return 'to';
	default:
		return token;
	}
};

const prodName = (short, full) => {
	if (short == null || short.trim() === '') { return full || '[без названия]'; }
	return short;
};

const LogTransferFilters = CollectionView.extend({
	className: 'filters-entries',
	initialize () {
		const filters = this.getRawFiltersModels();
		const filtersCollection = new LogTransferFiltersCollection(filters);
		this.filtersCollection = filtersCollection;
		this.listenTo(filtersCollection, 'new:url', params => this.triggerMethod('new:url', params));
        // console.log('*AC*', this.getOption('ac'), filters);
	},
	customViews () {
		return this.filtersCollection.map(filterModel => this.buildFilterView(filterModel));
	},
	buildFilterView (filter) {
		if (!filter.get('pinned')) { return; }
		const controlType = filter.get('controlType');

		if (this._controlViews[controlType]) {
			return this._controlViews[controlType](this, filter, { className: 'filter-entry' });
		}

		console.log('control type', controlType);
	},

    // template: '<%= filtersHtml %>',
	__initialize () {
		const filters = this.getRawFiltersModels();

		const filtersCollection = new LogTransferFiltersCollection(filters);
		this.filtersCollection = filtersCollection;
		this.filtersCollection.each(filter => {
			this.delegate('change', '[name=' + filter.id + ']', event => {
				const $el = $(event.target);
				let value = $el.val();
				if (!value || value === '') {
					value = [];
				} else {
					value = [value];
				}
				filter.set('values', value);
			});
		});
		this.listenTo(filtersCollection, 'new:url', params => this.triggerMethod('new:url', params));
	},

	getRequestValues () {
		const ac = this.getOption('ac');
		const hash = _.reduce(ac.qs || {}, (memo, value, key) => {
			const chunks = key.split('.');
			const id = chunks[0];

			const token = convertToken(chunks[1]);


			const existValue = memo[id];
			let updateValue;
			if (existValue) {
				updateValue = Array.isArray(existValue) ? existValue : [existValue];
			}

			if (Array.isArray(value)) {
				value = value.map(v => ({ [token]: v === '' ? undefined : v }));
				if (updateValue) {
					updateValue = value.reduce((memo, nv, index) => {
						const ev = updateValue[index];
						const mv = Object.assign({}, ev, nv);
						memo.push(mv);
						return memo;
					}, []);
				} else {
					updateValue = value;
				}
			} else {
				value = { [token]: value === '' ? undefined : value };
				if (updateValue) {
					Object.assign(updateValue[0], value);
				} else {
					updateValue = [value];
				}
			}

			if (updateValue.length === 1) {
				updateValue = updateValue[0];
			}
			memo[id] = updateValue;

			return memo;
		}, {});
		return hash;
	},

	getRawFiltersModels () {
		const ids = this.getOption('availableFilters');
		const hash = this.getRequestValues();
		const filters = allTransferFilters.reduce((memo, rawFilter) => {
			if (ids.indexOf(rawFilter.id) === -1) { return memo; }

			let rawValue = hash[rawFilter.id];
			if (rawValue) {
				if (!Array.isArray(rawValue)) {
					rawValue = [rawValue];
				}
			} else {
				rawValue = [];
			}

			const newfilter = Object.assign({}, rawFilter, {
				values: rawValue
			});

			this._updateFilterOnCreate(newfilter);

			memo.push(newfilter);
			return memo;
		}, []);
		return filters;
	},


	_filterUpdaters: {
		contragents: (view, fltr) => {
			const depmod = view.model;
			fltr.sourceValues = (depmod.get('contragents') || []).map(m => ({ value: m.id, label: m.name || '[без названия]' }));
			fltr.emptyLabel = 'все';
		},
		products: (view, fltr) => {
			const depmod = view.model;
			fltr.sourceValues = (depmod.get('products') || []).map(m => ({ value: m.id, label: prodName(m.shortName, m.fullName) }));
			fltr.emptyLabel = 'вся';
		}
	},

	_controlViewFltrOptions (fltr, opts) {
		const options = {
			controlName: fltr.id,
			label: fltr.get('name'),

			emptyLabel: fltr.get('emptyLabel'),
			emptyValue: fltr.get('emptyValue')

		};
		Object.assign(options, opts);
		return options;
	},
	_delegateControlFltrChange (control, fltr, convertValue) {
		this.listenTo(control, 'control:change', newvalue => {
			const val = convertValue ? convertValue(newvalue) : { value: newvalue };
			fltr.setValue(val);
		});
	},
	_controlViews: {
		'select-inline': (view, fltr, opts) => {
			const options = view._controlViewFltrOptions(fltr, opts);
			options.selectItems = fltr.get('sourceValues');
			options.value = ((fltr.get('values') || [])[0] || {}).value;
			const control = new InlineSelectControl(options);
			view._delegateControlFltrChange(control, fltr);
			return control;
		},
		'daterange-inline': (view, fltr, opts) => {
			const options = view._controlViewFltrOptions(fltr, opts);
			options.value = (fltr.get('values') || [])[0];
            // options.selectItems = fltr.get('sourceValues');
			const control = new InlineDaterangeControl(options);
			view._delegateControlFltrChange(control, fltr, val => val);
			return control;
		}
	},

	_updateFilterOnCreate (fltr) {
		const updater = this._filterUpdaters[fltr.id];
		if (!updater) return;
		updater(this, fltr);
	},

	__templateContext () {
		const depModel = this.model;
		const filtersHtmlArr = this.filtersCollection.map(filterModel => filterModel.buildHtml(depModel));
		const filtersHtml = filtersHtmlArr.join('');
		return {
			filtersHtml
		};
	}
});

export const LogTransferBlock = View.extend({
	className: 'logs-transfer-block',
	template: '<div class="filters-holder"></div><div class="entries-holder elastic-content"></div>',
	regions: {
		filters: '.filters-holder',
		entries: '.entries-holder'
	},
	onRender () {
		this.showFilters();
		this.showEntries();
	},
	showFilters () {
		const view = this.buildFiltersView();
		this.filters = view.filtersCollection;
		this.listenTo(view, 'new:url', params => this._onNewUrl(params));
		this.showChildView('filters', view, { replaceElement: false });
	},
	_onNewUrl (params) {
		if (params) {
			params = '?' + params;
		} else {
			params = '';
		}
		const ac = this.getOption('ac');
		const url = ac.page.url(ac.args) + params;
		Bb.history.navigate(url, { trigger: false });
		this.entries.fetch();
	},
	buildFiltersView () {
		const view = new LogTransferFilters({
			model: this.model,
			availableFilters: this.getOption('availableFilters') || [],
			ac: this.getOption('ac')
		});
		return view;
	},
	showEntries () {
		const view = this.buildEntriesView(this.filters);
		if (!view) return;
		this.entries = view;
		this.showChildView('entries', view, { replaceElement: true });
	},
	buildEntriesView (filters) { }
});



export const ProdDepPage = BasePage.extend({
	moduleRights: { productProduction: 'view' },

	setupModel (rd) {
		const model = new DepartmentModel({ id: rd.args.departmentId, pageId: this.pageId });
		this.addModel(model);
		this.addStartPromise(this.model.fetch());
	},

	onBeforeStart (rd) {
        // console.log('[this page]', this.pageId, this);
		this.setupModel(rd);
	}

});

export const ProdDepPage2 = PageWithDivisions.extend({
	moduleRights: { productProduction: 'view' },
	skipMenu: false,
	// Layout: PageWithDivisionsLayout,
	// layoutShowOptions: {
	// 	replaceElement: true
	// },
	// getDivisionRootPage () {
	// 	if (this.getOption('divisionRoot')) {
	// 		return this;
	// 	}
	// 	const parent = this.getParent();
	// 	if (!parent.getDivisionRootPage) {
	// 		return null;
	// 	}
	// 	return parent.getDivisionRootPage();
	// },
	setupModel (rd) {
		const id = rd.args.departmentId;
		const { model, promise } = storeApi.get(id);
		// const model = new DepartmentModel({ id: rd.args.departmentId, pageId: this.pageId });
		this.spreadUpModel(model);
		this.addModel(model);
		this.addStartPromise(promise);
	},
	onBeforeStart (rd) {
		// console.log('[this page]', this.pageId, this);
		this.setupModel(rd);
	}
});

const BaseFetchable = CollectionView.mixWith(FetchableMixin, FetchOnScrollMixin, ScrollHandlerMixin).extend({
	fetchNext () {
		const col = this.collection;
		if (col.fetchingInProcess) return;
		const res = col.getResultJSON();
		if (res.endOfData) return;

		const take = 50;
		const page = {
			'result.skip': (res.skiped || 0) + (res.returned || take),
			'result.take': take
		};
		return this.fetch(page, { remove: false });
	}
});


export const FetchableEntries = BaseFetchable.extend({

	scrollDirection: 'bottom',

	initialize () {
		this.initializeFetchableView();
		this.fetch();
	},

	initializeFetchableView () {
		this.depModel = this.getOption('departmentModel');
		this.filters = this.getOption('filters');
		this.collection = this.buildCollection();
	},

	getFetchData (data, opts) {
		let filtersData = this.filters.getData();
		filtersData = _.reduce(filtersData, (memo, value, key) => {
			if (value && Array.isArray(value)) { value = value.join(','); }
			memo[key] = value;
			return memo;
		}, {});
		const res = Object.assign({}, filtersData, data);
		return res;
	}


});
