import BaseView from 'base/view';
import template from './layout.html';
import Entries from './entries';
import Collection from 'mod/contragents/models/collection';

// import Views from 'mod/employees/views';
// import mix from 'helpers/mix';
// import FDC from 'mixins/view/flex-dynamic-column';
// import comparator from 'helpers/comparator';
// import EntriesManager from 'components/entries-manager';

export default BaseView.extend({
	id: 'contragents-search',
	className: 'contragents fdc-container',
	isInvulnerable: true,
	template,
	regions: {
		content: '.content-region'
	},
	initialize () {
		this.initCollection();
		this.createNested('content', {
			viewClass: Entries,
			regionTemplate: { replaceElement: true }
		});
	},
	initCollection () {
		if (this.collection) return;

		this.collection = new Collection();
	},
	onRender () {
		if (this._wasRendered) return;
		this.showContent();
		this._wasRendered = true;
	}

});
