import Model from './model';

const ResultModel = Model.extend({
	defaults: {
		skip: undefined,
		founded: undefined,
		returned: undefined,
		endOfData: undefined
	},
	clearSkip () {
		this.set('skip', 0);
	}
});

export default ResultModel;
