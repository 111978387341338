import View from 'base/view';
// import CollectionView from 'base/collection-view';
import Collection from 'base/collection';
import { ProductCard } from './ProductCard';
import { TreeCollection } from './TreeCollection';
import { setFilterFlags } from './common';

const treeProdsNestedsKeys = {
	fromSource: 'producedIds',
	fromProduction: 'componentIds'
};

// function _true (...args) {
// 	return _bool(true, ...args);
// }
// function _false (...args) {
// 	return _bool(false, ...args);
// }

// function _bool (value, ...args) {
// 	value = value === true;
// 	if (value) {
// 		console.log(value, ...args);
// 	}
// 	return value;
// }


export const TreeItem = View.extend({
	className: 'product-tree-entry',
	template: '<div></div><section></section>',
	regions: {
		prod: 'div',
		nested: 'section'
	},
	onRender () {
		this.showProd();
		this.showNested();
	},
	_onFilterPassed (passed) {
		this._filterPassed = passed;
		this.trigger('filter:passed', passed);
	},
	getProductName () {
		const { shortName, fullName } = this.model.attributes;
		return (shortName || '').trim() ? shortName : fullName;
	},
	showProd () {
		const filterModel = this.getOption('filterModel');
		const listenForFilterModel = (this.getNestedModels() || []).length === 0;
		const departmentModel = this.getOption('departmentModel');
		// const productModel = this.getOption('productModel');
		const view = new ProductCard({
			model: this.model,
			filterModel,
			listenForFilterModel,
			departmentModel
			// productModel
		});
		// this.listenTo(view, 'filter:passed', () => this._onFilterPassed);
		this.showChildView('prod', view, { replaceElement: true });
	},
	showNested () {
		const models = this.getNestedModels();
		if (!models.length) return;
		const filterModel = this.getOption('filterModel');
		const collection = new Collection(models);
		const view = new TreeCollection({
			className: 'nesteds',
			collection,
			childView: TreeItem,
			allModels: this.getOption('allModels'),
			direction: this.getOption('direction'),
			departmentModel: this.getOption('departmentModel'),
			parentTreeItem: this,
			filterModel
		});
		this.nestedChildViews = view.children;
		this.nestedChildrenView = view;

		this.showChildView('nested', view, { replaceElement: true });
	},
	getNestedModels () {
		const dir = this.getOption('direction');
		const nestedKey = treeProdsNestedsKeys[dir];
		const ids = this.model.get(nestedKey) || [];
        // console.log('**', dir, nestedKey, ids);
		const all = this.getOption('allModels');
		return all.models.filter(f => ids.indexOf(f.id) > -1);
	},
	// onBeforeFilter () {
	// 	this._filterPassed = false;
	// 	this._filterPassedForChild = false;
	// },
	// onFilter () {
	// 	if (!this.nestedChildrenView) { return; }
	// 	this.nestedChildrenView.filter();
	// },
	isFilterPassed (fromChild, fromParent) {

		const parentTreeItem = this.getOption('parentTreeItem');
		// const name = (this.getProductName() || '').toLowerCase();


		if (this._filterPassed) {
			return true; // _true(name, 'ALREADY CHECKED', 'SELF');
		}
		if (this._filterPassedForChild) {
			return true; // _true(name, 'ALREADY CHECKED', 'CHILDREN');
		}

		const filterModel = this.getOption('filterModel');
		if (filterModel.filterView(this)) {
			setFilterFlags(this, true);
			return true; // _true(name, 'meet FILTER');
		}


		const parentPassedByFilter = parentTreeItem?._filterPassed === true;
		const parentPassedByChildren = parentTreeItem?._filterPassedForChild === true;
		const parentPassed = parentPassedByFilter || parentPassedByChildren;

		if (parentPassedByFilter) {
			return true;
		}

		if (!fromChild && !fromParent) {

			if (parentTreeItem && !parentPassed) {
				return false;
			}

		}



		if (!fromChild && this.nestedChildViews) {
			const children = this.nestedChildViews._views;
			const haschild = children.some(childView => childView.isFilterPassed(undefined, this));
			setFilterFlags(this, haschild, true);
			return this._filterPassedForChild; // _bool(this._filterPassedForChild, name, ' has passed children?');
		}

		return false; // _false(name, 'NO CASE');
	},
	filter () {
		if (this.nestedChildrenView) {
			this.nestedChildrenView.filter();
		}
	}
});
