import Yat from 'marionette.yat';
const History = Yat.AppHistory.extend({
	_onForward (fragment, name, actionContext) {
		Yat.AppHistory.prototype._onForward.call(this, fragment, name, actionContext);
	},
	_onBackward (fragment, name, actionContext) {
		Yat.AppHistory.prototype._onBackward.call(this, fragment, name, actionContext);
	}
});


const history = new History();
// Console.warn('history:', history);

export default history;
