import CollectionView from 'base/collection-view';
import Collection from 'base/collection';
import { SwitcherButton } from './switcher-button-view';

const switchers = [
    // {
    //     id: 'now',
    //     text: 'сейчас',
    //     //reportTypes: ['store']
    //     //isActive: filter => !filter.hasValue(),
    // },
	{
		id: 'period',
		text: 'укажите период',
		reportTypes: ['products', 'purchase'],
		editFilter: 'period'
        // onClick(view) {
        //     let filter = view.filterer.get('period');
        //     filter && filter.editInModal();
        // }
        // isActive: filter => filter.hasValue(),
	},
	{
		id: 'dep',
		text: 'укажите отдел/участок',
		reportTypes: ['sources'],
		editFilter: 'byproddep'
        // onClick(view) {
        //     let filter = view.filterer.get('period');
        //     filter && filter.editInModal();
        // }
        // isActive: filter => filter.hasValue(),
	}
];

export const TypesSwitcherView = CollectionView.extend({
	className: 'wrapper',
	childView: SwitcherButton,
	initialize () {
		this.collection = new Collection(switchers);
		this.filterer = this.getOption('filterer');
		this.reportSchema = this.getOption('reportSchema');
        // this.filtererModel = this.getOption('filterer').get('period');
        // console.log('-filterer-', this.filtererModel);
	},
	childViewOptions () {
		return {
			filterer: this.filterer,
			reportSchema: this.reportSchema
            // filtererModel: this.filtererModel
		};
	},
	childViewEvents: {
		click (view, event) {
			const mod = view.model;
			if (mod.has('onClick')) {
				mod.get('onClick').call(mod, this);
			} else if (mod.has('editFilter')) {
				const fltr = this.filterer.get(mod.get('editFilter'));
				fltr.editInModal();
			}
            // if (view.model.id === 'now') {
            //     this.filtererModel.setValues([]);
            // } else {
            //     this.filtererModel.editInModal();
            // }
		}
	},
	viewFilter (v) {
		const types = v.model.get('reportTypes');
		if (!types) return true;
		return types.indexOf(this.reportSchema.name) > -1;
	}
});
