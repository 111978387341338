import { _ } from 'vendors';
import View from 'base/view';
export const ModelPropertyViewWithTemplate = View.extend({
	className () {
		let cls = (this.property || '').replace(/\./g, '-');
		if (this.addValueToClassName) {
			const value = this.getPropertyValue();
			cls += value ? ' ' + value : '';
		}
		return cls;
	},
	constructor (options = {}) {
		this._initializeProperty(options);
		View.apply(this, arguments);
		this.on('render', this._updateAttributes);
	},
	_updateAttributes () {
		let attrs = _.result(this, 'attributes') || {};
		const options = {
			title: this.getOption('title')
		};
		attrs = _.extend({}, attrs, options);
		this._setAttributes(attrs);
	},
	_initializeProperty (options) {
		this.mergeOptions(options, ['model', 'property', 'addValueToClassName', 'title']);
		if (!this.model || !this.property) return;
		this.listenTo(this.model, `change:${this.property}`, this.render);
	},
	getDisplayValue () {
		if (this.getOption('useDisplayValue')) {
			return this.getPropertyDisplay();
		} else {
			return this.getPropertyValue();
		}
	},
	getPropertyValue () {
		if (!this.model) return;
		return this.model.get(this.property);
	},
	getPropertyDisplay () {
		if (!this.model) return;
		return this.model.display(this.property);
	}
});

export const ModelPropertyView = ModelPropertyViewWithTemplate.extend({
	render () {
		if (this._isDestroyed) { return this; }

		this.triggerMethod('before:render', this);

		const value = this.getDisplayValue() || '';
		this.$el.html(value);

		this._isRendered = true;
		this.triggerMethod('render', this);

		return this;
	}
});
