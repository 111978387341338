import _ from 'underscore';

function pstoSetPair(context, pair){
	if(!_.isString(pair)) return;
	let keyvalue = pair.split('=');
	pstoSetKeyValue(context, keyvalue[0], keyvalue[1]);
}

function pstoSetKeyValue(context, key, value){
		
	if(key == null) return;
	key = decodeURIComponent(key);
	value != null && (value = decodeURIComponent(value));

	if(!(key in context))
		return (context[key] = value);

	!_.isArray(context[key]) && (context[key] = [context[key]]);

	context[key].push(value);

	return context[key];
}

function paramStringToObject(raw, opts = {emptyObject: true}){
	let result = {};
	if(!_.isString(raw)) return opts.emptyObject ? result : raw;

	let pairs = raw.split('&');
	_(pairs).each((pair) => pstoSetPair(result, pair));
	
	return result;
};
export default paramStringToObject
