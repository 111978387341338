import { _ } from 'vendors';
// import View from 'components/ui-card-block';
import Model from 'mod/contragents/models/model';

import ModelProperties from 'components/ui-model-card/properties';
import h from 'components/ui-model-card/helpers';
import BottomAction from 'components/ui-list/action';
import confirm from 'helpers/modals/confirm';

const defs = function (m, opts) {
	return _.extend({ editButton: m.mainInfoCanBeChanged(), addClass: 'whole-btn-bg', textAction: 'edit' }, opts);
};

export default ModelProperties.extend({
	className: 'card lined with-border',
	initialize () {
		this.initModel();
	},
	initModel () {
		if (this.model) return;
		this.model = new Model();
	},
	properties: (m) => [
		h.ui('name', defs(m)),
		h.ui('roles', defs(m)),
		h.ui('occupation', defs(m)),
		h.ui('note', defs(m))
	],
	action: (v) => ({
		viewClass: BottomAction,
		viewOptions: {
			text: 'далее',
			action: 'create',
			actionContext: v.model,
			actionCallback: (m) => confirm('Перейти на страницу контрагента?').then(
				() => m.executeAction('open:card'),
				() => m.clear()
			)
		}
	}),
	modelEvents: {
		'change:name' () {
			const name = this.model.get('name');
			this.trigger('change:name', name);
		}
	}
});
