import { _ } from 'vendors';
import { big, small } from '../../schema/template-utils';
import { measure } from '../../utils';

export const displayValueAndUnit = (value, unit, options = {}) => {
	const { digits = 4, valuePrefix = '' } = options;
	const bigValue = valuePrefix + _.displayNum(value, digits);
	const bigText = big(bigValue);
	const smallText = small(measure(unit) || '&mdash;');
	const res = bigText + smallText;
	return res;
};

export const displayThing = (value, view, options = {}) => {
	const { addSign } = options;
	let valuePrefix;
	const unit = view.model.get('product.unitMeasureId');

	switch (addSign) {
	case true:
		valuePrefix = value > 0 ? '+' : '';
		break;
	case false:
		value = value < 0 ? Math.abs(value) : value;
		break;
	}


	options = Object.assign({ valuePrefix }, options);
	return displayValueAndUnit(value, unit, options);
};



export const commonMixin = {

	_output (value, convertValue, options = {}) {
		const { display, showZero, ifEmpty = '&mdash;', emptyValue = 0 } = options;

		if (!display) { return value || emptyValue; }

		if (value === 0 && !showZero) { value = undefined; }

		return !value ? ifEmpty : convertValue(value, this, options); // displayThing(value, this, options);
	},

	getInBaseUnits (options = {}) {
		const { field, fieldsSum, fieldsSub, positive } = options;
		let value;
		if (fieldsSum || fieldsSub) {
			const fieldsArr = fieldsSum || fieldsSub;
			const sign = fieldsSum ? 1 : -1;
			const first = fieldsArr[0];
			let result = this.model.get(first) || 0;
            // console.log('-arr-', sign, first, result);
			for (let x = 1; x < fieldsArr.length; x++) {
				const add = this.model.get(fieldsArr[x]) || 0;
				result += add * sign;
			}

			value = result;
		} else {
			const key = field || this.fieldKey;
			value = this.model.get(key);
		}

		switch (positive) {
		case true:
			this.positive = value > 0 ? 'positive' : value < 0 ? 'negative' : '';
			break;
		case false:
			this.positive = value < 0 ? 'positive' : value > 0 ? 'negative' : '';
			break;
		}

		if (positive) {
            // console.log('??', this.fieldKey, value, this.positive);
            // console.log('-positive-', this.positive);
		}

		return this._output(value, displayThing, options);
        // if (!display)
        //     return value || 0;

        // if (value === 0 && !showZero)
        //      value = undefined;

        // return !value ? ifEmpty : displayThing(value, this, options);
	}
};
