import { BaseModel } from './base-models';
// import { isModel } from 'helpers/is-class';
// import nextNum from '../../helpers/next-num';
import action from 'helpers/action';
import busEdit from 'bus/edit';
import confirmModal from 'helpers/modals/confirm';

const actions = [
	action('rename', 'переименовать'),
	action('remove', 'удалить')
];


export default BaseModel.extend({
	actions,
	actionRename () {
		const model = this;
		busEdit.do({
			header: 'укажите название',
			value: model.get('name')
		}).then((name) => {
			model.set({ name });
		}, () => {});
	},
	actionRemove () {
		const list = this;
		const items = this.getItemsCollection();
		const board = this.getBoard();
		if (!items.length) {
			this.destroy();
			board.trigger('save:lists');
		} else {
			confirmModal('Вы собираетесь убрать с доски список и его содержимое').then(() => {
				board.removeItem(items.models);
				board.trigger('save:listsAndItems');
				list.destroy();
			});
		}
	},


	isNew: () => 1,
	getBoard () {
		return this.collection && this.collection.parent;
	},
	getItemsCollection () {
		if (this._items) return this._items;
		this._items = this.createItemsCollection();
		return this._items;
	},
	createItemsCollection () {
		const board = this.getBoard();
		if (!board) return;
		return board.getListItemsCollection(this);
	},
	addItem (entityModel, options) {
		// const arr = _.isArray(entityModel) ? entityModel : [entityModel];
		const listId = this.id;
		// let models = _.map(arr, entityModel => ({ listId, entityModel }));
		const board = this.getBoard();
		board.addModels(entityModel, listId, options);
		// board.addNewItemToList(models);
	}
	/* nestedEntities:{
		items: {
			class: ListItems,
			//data: [{ name: 1 }, {name: 2}]
		}
	} */
});
