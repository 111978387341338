import { _ } from 'vendors';
import View from './view';

const TagView = View.extend({
	template: _.template('<%= text%>'),
	templateContext () {
		return {
			text: this.getOption('text')
		};
	}
});


const createView = function (tagName, text, opts) {
	const options = _.extend({}, opts, { tagName, text });
	return new TagView(options);
};
createView.View = TagView;
createView.header = function (text, opts) {
	return createView('header', text, opts);
};
createView.li = function (text, opts) {
	return createView('li', text, opts);
};
export default createView;
