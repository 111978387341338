import { _ } from 'vendors';
// Instance
((proto) => {
	_.extend(proto, {
		isValid () {
			return !isNaN(this.valueOf());
		},
		getMonths () {
			const y = this.getFullYear();
			const m = y * 12;
			return m + this.getMonth() + 1;
		},
		getDays () {
			const val = this.valueOf();
			return Math.floor(val / Date.DAY);
		},
		addMilliseconds (num) {
			this.setMilliseconds(this.getMilliseconds() + num);
			return this;
		},
		addSeconds (num) {
			this.setSeconds(this.getSeconds() + num);
			return this;
		},
		addMinutes (num) {
			this.setMinutes(this.getMinutes() + num);
			return this;
		},
		clone (opts = {}) {
			const date = new Date(this.valueOf());
			if (opts.months) { date.addMonths(opts.months); }
			if (opts.days) { date.addDays(opts.days); }
			if (opts.hours) { date.addHours(opts.hours); }
			if (opts.minutes) { date.addMinutes(opts.minutes); }
			if (opts.seconds) { date.addSeconds(opts.seconds); }
			if (opts.milliseconds) { date.addMilliseconds(opts.milliseconds); }
			return date;
		},
		addHours (num, min = 0, sec = 0, ms = 0) {
			this.setHours(this.getHours() + num);
			min && this.addMinutes(min);
			sec && this.addSeconds(sec);
			ms && this.addMilliseconds(ms);
			return this;
		},
		addDays (num) {
			this.setDate(this.getDate() + num);
			return this;
		},
		addMonths (num) {
			this.setMonth(this.getMonth() + num);
			return this;
		}
	});
})(Date.prototype);

// STATIC
_.extend(Date, {
	NOW: 'now',
	PAST: 'past',
	FUTURE: 'future',
	SECOND: 1000,
	MINUTE: 60000,
	HOUR: 3600000,
	DAY: 86400000,
	isValid (arg) {
		return _.isDate(arg) && arg.isValid();
	},
	sameYear (d1, d2) {
		if (d2 == null) { d2 = new Date(); }

		if (!this.isValid(d1) || !this.isValid(d2)) { return false; }
		return d1.getFullYear() === d2.getFullYear();
	},
	nextYear (d1, d2) {
		const di = Date.info(d1, d2);
		return di.calendar.years === 1 && di.when === 'future';
	},
	prevYear (d1, d2) {
		const di = Date.info(d1, d2);
		return di.calendar.years === 1 && di.when === 'past';
	},
	sameMonth (d1, d2) {
		if (d2 == null) { d2 = new Date(); }

		return this.sameYear(d1, d2) && d1.getMonth() === d2.getMonth();
	},
	nextMonth (d1, d2) {
		const di = Date.info(d1, d2);
		return di.calendar.months === 1 && di.when === 'future';
	},
	prevMonth (d1, d2) {
		const di = Date.info(d1, d2);
		return di.calendar.months === 1 && di.when === 'past';
	},

	sameWeek (d1, d2) {
		const di = Date.info(d1, d2);
		return di.calendar.weeks === 0;
	},
	nextWeek (d1, d2) {
		const di = Date.info(d1, d2);
		return di.calendar.weeks === 1 && di.when === 'future';
	},
	prevWeek (d1, d2) {
		const di = Date.info(d1, d2);
		return di.calendar.weeks === 1 && di.when === 'past';
	},

	sameDay (d1, d2) {
		if (d2 == null) { d2 = new Date(); }

		return this.sameYear(d1, d2) && this.sameMonth(d1, d2) && d1.getDate() === d2.getDate();
	},
	nextDay (d1, d2) {
		const di = Date.info(d1, d2);
		return di.valid && di.when === 'future' && di.calendar.days === 1;
	},
	prevDay (d1, d2) {
		const di = Date.info(d1, d2);
		return di.valid && di.when === 'past' && di.calendar.days === 1;
	},
	isToday (d1) {
		return this.sameDay(d1);
	},
	isTomorrow (d1) {
		return Date.nextDay(d1, new Date());
	},
	info (d1, d2 = new Date()) {
		if (_.isString(d1)) d1 = new Date(d1);
		if (_.isString(d2)) d2 = new Date(d2);

		const result = {
			dateValid: this.isValid(d1),
			compareValid: this.isValid(d2)
		};
		result.valid = result.dateValid && result.compareValid;
		if (!result.valid) return result;

		_.extend(result, {
			date: d1,
			compare: d2,
			when: this.when(d1, d2),
			absolute: this.absoluteDifference(d1, d2),
			calendar: this.calendarDifference(d1, d2),
			sameYear: this.sameYear(d1, d2),
			sameMonth: this.sameMonth(d1, d2),
			sameDay: this.sameDay(d1, d2)
		});
		return result;
	},
	when (d1, d2 = new Date()) {
		if (this.isValid(d1)) { d1 = d1.valueOf(); }
		if (this.isValid(d2)) { d2 = d2.valueOf(); }
		if (!_.isNumber(d1)) { throw new Error('Date when first argument is wrong. accepts number or Date instance'); }
		if (!_.isNumber(d2)) { throw new Error('Date when compare argument is wrong. accepts number or Date instance'); }

		return d1 === d2
			? this.NOW
			: d1 < d2
				? this.PAST
				: this.FUTURE;
	},
	absoluteDifference (d1, d2 = new Date()) {
		if (!this.isValid(d1)) { throw new Error('Date absoluteDifference first argument is not a Date instance'); }

		if (!this.isValid(d2)) { throw new Error('Date absoluteDifference compare argument is not a Date instance'); }


		const dif = Math.abs(d1.valueOf() - d2.valueOf());
		const fn = Math.floor;
		const r = {
			ms: dif,
			seconds: fn(dif / this.SECOND),
			minutes: fn(dif / this.MINUTE),
			hours: fn(dif / this.HOUR),
			days: fn(dif / this.DAY)
		};
		return r;
	},
	calendarDifference (d1, d2) {
		if (!this.isValid(d1)) { throw new Error('Date calendarDifference first argument is not a Date instance'); }

		if (!this.isValid(d2)) { throw new Error('Date calendarDifference compare argument is not a Date instance'); }


		const y1 = d1.getFullYear();
		const y2 = d2.getFullYear();
		const years = y1 - y2;
		const m1 = d1.getMonths();
		const m2 = d2.getMonths();
		const months = m1 - m2;
		const da1 = d1.getDays();
		const da2 = d2.getDays();
		const days = da1 - da2;

		const dw1 = Date.toWeek(d1).getDays();
		const dw2 = Date.toWeek(d2).getDays();
		const weeks = Math.abs(Math.floor((dw1 - dw2) / 7));

		const r = {
			years: Math.abs(years),
			months: Math.abs(months),
			days: Math.abs(days),
			weeks
		};
		return r;
	},
	create (opts = {}) {
		const date = new Date();
		if (opts.minutes) { date.addMinutes(opts.minutes); }
		if (opts.hours) { date.addHours(opts.hours); }
		if (opts.days) { date.addDays(opts.days); }
		return date;
	},

	toWeek (_d) {
		const d = new Date(_d.valueOf());
		const wd = d.getDay() || 7;
		const add = (wd - 1) * -1;
		d.addDays(add);
		d.setHours(0, 0, 0, 0);
		return d;
	},
	toMonth (_d) {
		const d = new Date(_d.valueOf());
		d.setDate(1);
		d.setHours(0, 0, 0, 0);
		return d;
	},
	toDay (_d) {
		const d = new Date(_d.valueOf());
		d.setHours(0, 0, 0, 0);
		return d;
	}

});
