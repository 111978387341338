import childView from './check';
import CollectionView from 'base/collection-view';
import { _ } from 'vendors';

export default CollectionView.extend({
	childView,
	childViewOptions (m) {
		const res = {
			type: this.getOption('type'),
			name: this.getOption('name'),
			checked: (this.getOption('initialValues') || []).indexOf(m.getValue()) > -1
		};

		return res;
	},
	getValue () {
		let values = this.collection.map((m) => {
			const v = this.children.findByModel(m);
			return v && v.getValue();
		});
		values = _(values).filter((f) => f != null);
		return values;
	},
	childViewEvents: {
		'click' () {
			this.trigger('change');
		}
	}
});
