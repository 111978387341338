function updateHash (view, value, hash) {
	if (typeof value === 'function') {
		value = value.call(view, view);
	}
	if (value == null) {
		return value;
	}
	value = value.toString();
	if (value.indexOf(' ') > -1) {
		value.split(' ').forEach(chunk => {
			if (chunk.length) {
				hash[chunk] = 1;
			}
		});
	} else {
		hash[value] = 1;
	}
}

function addOptionKey (view, key, hash) {
	let value = view.getOption(key, true);
	if (!Array.isArray(value)) {
		value = [value];
	}	
	value.forEach((item) => {
		updateHash(view, item, hash);
	});
}

function addOptionKeys (view, keys, hash) {
	keys.forEach(key => addOptionKey(view, key, hash));
}

function addArrayValues (view, values, hash) {
	if (!values || !values.length) { return; }
	values.forEach(value => updateHash(view, value, hash));
}

function toText (value, name) {
	if (value == null || value === '' || value === false) {
		return undefined;
	} else if (value === true) {
		return name;
	} else {
		return value;
	}
}

export const classNameMixin = {
	className () { return this.buildClassName(true); },
	buildClassNameHash (fromClassName) {
		let hash = {};

		const optionKeys = ['baseClassName', 'thisClassName'];
		if (!fromClassName) {
			optionKeys.push('className');
		}
		addOptionKeys(this, optionKeys, hash);

		const classNames = this.getOption('classNames', true);
		addArrayValues(this, classNames, hash);

		const stateClassNames = this.getStateClassNames();
		addArrayValues(this, stateClassNames, hash);

		hash = this.extendClassNamesHash(hash, fromClassName);

		return hash;
	},
	extendClassNamesHash (hash, fromClassName) {
		if (this._buildCssClassKeysHash) {
			Object.assign(hash, this._buildCssClassKeysHash(fromClassName));
		}
		return hash;
	},
	getStateClassNames () {
		if (typeof this.state !== 'function') {
			return;
		}

		const classNames = this._getStateClassNamesArray();
			// this.getOption('stateClassNames', true) || [];
		return classNames.map(name => toText(this.state(name), name));
	},
	_getStateClassNames () {
		if (!this._stateClassNames) {
			this._stateClassNames = (this.getOption('stateClassNames', true) || []).reduce((memo, key) => {
				memo[key] = 1;
				return memo;
			}, {});
		}
		return this._stateClassNames;
	},
	_getStateClassNamesArray () {
		const stateClassNames = this._getStateClassNames();
		return Object.keys(stateClassNames);
	},
	addStateClassName (name) {
		const hash = this._getStateClassNames();
		hash[name] = 1;
	},
	removeStateClassName (name) {
		const hash = this._getStateClassNames();
		delete hash[name];
	},
	buildClassName (fromClassName) {
		const hash = this.buildClassNameHash(fromClassName);
		const keys = Object.keys(hash);
		let name = keys.join(' ');
		if (name === '') {
			name = undefined;
		}
		return name;
	},
	updateClassName () {
		const name = this.buildClassName();
		if (name) {
			this.el.className = name;
		} else {
			this.el.removeAttribute('class');
		}
	},
	initializeClassNameMixin () {
		if (this._classNameMixinInitialized) { return; }
		this._classNameMixinInitialized = true;
		this.on('after:initialize before:render', this.updateClassName);
	}
};
