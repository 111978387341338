import { _ } from 'vendors';
import BaseView from 'base/view';
import CollectionView from 'base/collection-view';
import ListItemView from './list-item-view';
import { DnDWithInteractJs, ActionsPopover } from 'behaviors';
import { ModelPropertyView } from 'components/views';
import PreventWheelMixin from 'mixins/view/prevent-wheel';
import mix from 'helpers/mix';
import busEdit from 'bus/edit';

const TransferSortBehavior = DnDWithInteractJs.extend({

	dropZoneOptions: {
		accept: '.board-list-item'
	},
	onDragDrop (view, options = {}) {
		const dropBefore = options.dropBefore;
		const model = view.model;
		const listId = this.view.model.id;
		const hash = { listId };
		let fix;
		if (dropBefore && dropBefore.model) {
			hash.order = (dropBefore.model.get('order') || 0) - 0.1;
			fix = true;
		}
		// let prevListId = model.get('listId');
		model.set(hash);
		if (fix) {
			this.view.collection.sortAndFix();
		}
		// model.trigger('change:listId', model, listId);
		this.view.board.trigger('save:items');
	}
});


const EmptyListView = BaseView.extend({
	className: 'empty-item',
	template: _.template('')
});

const ListItems = mix(CollectionView).with({}).extend({

	className: 'board-list-items',
	behaviors: [
		TransferSortBehavior
	],

	childView: ListItemView,
	childViewOptions () {
		const boardConfig = this.getOption('boardConfig') || {};
		const entityConfig = _.result(boardConfig, 'entityConfig');
		const options = {
			entityView: entityConfig.View,
			entityViewOptions: entityConfig.viewOptions,
			board: this.getOption('board')
		};
		return options;
	},
	viewComparator (v1, v2) {
		const o1 = v1.model.get('order');
		const o2 = v2.model.get('order');
		return o1 - o2;
	},
	emptyView: EmptyListView,
	initialize (options) {
		this.mergeOptions(options, ['board']);
		this.createCollection();
	},
	createCollection () {
		if (this.collection) return;
		this.collection = this.model.getItemsCollection();
		this.collection.each((model, index) => {
			const modelIndex = model.get('order');
			if (modelIndex !== index) {
				model.set('order', index);
				// modelIndex = index;
			}
		});
	}

});

export default mix(BaseView).with(PreventWheelMixin).extend({
	className: 'board-list',
	template: _.template(`
	<div class="board-list-content">
	<header>
		<b class="actions"><i></i></b>
		<span><%= name %></span>
		<b class="sortable-anchor"><i></i></b>
	</header>
	<section></section>
	<footer class="<%= footerClass %>"><%= footerText %></footer>
	</div>
	`),
	getScrollEl () {
		return this.$('section');
	},
	behaviors: [
		ActionsPopover.extend({
			reference: 'b.actions'
		})
	],
	regions: {
		items: 'section',
		name: 'header span'
	},
	onRender () {
		this.showItems();
		this.showHeader();
	},
	showHeader () {
		const view = new ModelPropertyView({ model: this.model, property: 'name', tagName: 'span' });
		this.showChildView('name', view, { replaceElement: true });
	},
	showItems () {
		const view = this.buildItemsView();
		this.showChildView('items', view);
	},
	buildItemsView () {
		const View = this.getOption('listItemsView') || ListItems;
		const board = this.getOption('board');
		const options = {
			board,
			model: this.model,
			boardConfig: this.getOption('boardConfig')
		};
		const view = new View(options);
		return view;
	},
	isAddEnabled () {
		const boardCfg = this.getOption('boardConfig') || {};
		return 'addItemControlOptions' in boardCfg || 'addItemToListAction' in boardCfg;
	},
	addNewItemDialogue ({ onResolve } = {}) {
		const boardCfg = this.getOption('boardConfig') || {};
		const editOptions = _.result(boardCfg, 'addItemControlOptions');

		return busEdit.do(editOptions).then((data) => {
			onResolve(data);
		});
	},
	addNewItemToList () {
		const promise = this.addNewItemDialogue({
			onResolve: data => this._addNewItem(data)
		});
		return promise;
	},
	modelEvents: {
		'change:name' () {
			const board = this.getOption('board');
			board.trigger('save:lists');
		}
	},
	events: {
		'click footer' () {
			if (!this.isAddEnabled()) return;
			const boardCfg = this.getOption('boardConfig') || {};
			if (_.isFunction(boardCfg.addItemToListAction)) {
				boardCfg.addItemToListAction().then(models => this._addNewItem(models), () => {});
			} else {
				this.addNewItemToList();
			}
		}
	},
	_addNewItem (data) {
		this.model.addItem(data, { save: true });
	},
	templateContext () {
		const enabled = this.isAddEnabled();
		const text = enabled ? '+ добавить сюда' : '';
		const footerClass = enabled ? 'as-button' : '';
		return {
			footerText: text,
			footerClass
		};
	}
});
