import _ from 'underscore';
import safeCall from '../functions/common/safe-call';
export default (Base) => {
	
	const CHILDREN_FIELD = '_children';

	let Mixin = Base.extend({
		constructor(...args) {
			Base.apply(this, args);
			this._initializeChildrenable(...args);
		},

		initializeChildrenable(){},

		/*
		
		Initialize section
		
		*/


		_initializeChildrenable(options = {}){
			this._initializeParrent(options);
			this._initPassDown();
			this._acceptPassedDown(options);
			this.initializeChildrenable(options);
			this._initializeChildren(options);
			this.triggerMethod('children:init');
		},
		
		_initializeParrent(opts){
			if(this.parent == null && opts.parent != null)
				this.parent = opts.parent;
		},
		_acceptPassedDown(opts){
			let parent = this.getParent();
			if(!parent) return;
			let hash = parent.getPassDown();
			_(hash).each((value, key) => this[key] = value);
			this.setPassDown(hash);
		},
		_initPassDown(){
			let value = this.getProperty('passDown');
			if(_.isArray(value)){
				let hash = {};
				_(value).each((key) => hash[key] = this[key]);
				this.setPassDown(hash);
			}
			else if(_.isObject(value)) {
				this.setPassDown(value);
			}
		},
		getPassDown(){
			return this._passDown;
		},
		setPassDown(hash){
			this._passDown || (this._passDown = {});
			_.extend(this._passDown, hash);
		},
		_initializeChildren(){
			this[CHILDREN_FIELD] = [];
			let _children = this.getProperty('children');
			_(_children).each((child) => this.createChild(child));
		},

		_normalizeChildContext(child){
			let childContext = {};

			if(_.isFunction(child) && child.Childrenable){
				_.extend(childContext, { Child: child });
			}else if(_.isFunction(child)){
				childContext = this._normalizeChildContext(child.call(this));
			}
			else if(_.isObject(child)){
				childContext = child;
			}
			return childContext;
		},		
		
		_initializeChild(childContext)
		{
			if(childContext == null || !_.isFunction(childContext.Child)) return;

			let Child = childContext.Child;
			let opts = this._normalizeChildOptions(childContext);
			return this.buildChild(Child, opts);
		},

		_normalizeChildOptions(options){
			let opts = _.extend({}, options);
			if(this.getOption('passToChildren') === true){
				_.extend(opts, this.options);
			}
			opts.parent = this;
			delete opts.Child;
			return this._buildChildOptions(opts);
		},		

		_buildChildOptions: function(def){
			return _.extend(def, this.getProperty('childOptions'));
		},

		/*
		
		public methods section
		
		*/		

		createChild(child){
			let childContext = this._normalizeChildContext(child);
			let initialized = this._initializeChild(childContext);
			if(initialized) 
				this.addChild(initialized);
		},
		addChild(child){
			if(!_.isObject(child)) return;
			let children = this.getChildren();
			children.push(child);			
		},

		buildChild(ChildClass, options){
			return new ChildClass(options);
		},		

		hasParent(){
			let parent = this.getParent();
			return _.isObject(parent);
		},

		getParent(){
			return this.getProperty('parent', {deep:false});
		},

		hasChildren(){
			let children = this.getChildren();
			return children.length > 0;
		},

		getChildren(opts = {}){
			let all = this[CHILDREN_FIELD];
			if(_.isFunction(opts.filter))
				return all.filter(opts.filter);
			else
				return all;
		},
		findChild(predicate, options = {}){
			//let isMe = this._tryFindChildPredicate(predicate,this);
			let isMe = safeCall(predicate, {args:[this], ifFail:false});
			if(isMe) return this;

			let result;
			let children = this.getChildren(options);
			let found = _(children).some((child) => !result && (result = child.findChild(predicate, options)));
			return result;
		},
		// _tryFindChildPredicate(predicate, child){
		// 	try {
		// 		return predicate(child);
		// 	}
		// 	catch(e){
		// 		return false;
		// 	}
		// },

	});

	Mixin.Childrenable = true;

	return Mixin;
}
