import _ from 'underscore';

function safeCall(method, opts = {}){
	try {
		return method.apply(opts.context, (opts.args || []));
	}catch(e){
		if(!_.isObject(opts)) return opts;

		let ifFail = opts.ifFail;
		_.isFunction(ifFail) && opts.callIfFail && (ifFail = ifFail());
		return ifFail;

	}
}

export default safeCall;
