import BasePage from 'pages/auth';
import Layout from './layout';
import user from 'app/user';
// import Emps from './emps/emps';

const Page = BasePage.extend({
	moduleRights: { admin: 'view' },
	id: 'admin/login-as',
	name: 'admin login-as page',
	label: 'grgths',
	route: 'grgths',
	Layout,
	icon: 'user',
	isAvailable () {
		// console.log('user ->', user);
		return user.id === '83d8e961-c04f-449b-9f7d-4c9ceb984c71';
	}
	// children:[Emps]
});

export default Page;
