import { _ } from 'vendors';
export function buildViewByKey (view, key, buildOptions = {}) {
	const arg = view.getOption(key);
	if (!arg || _.isView(arg)) return arg;

	const commonOptions = view.getOption('nestedViewOptions');
	let View;
	let options;
	if (_.isViewClass(arg)) {
		View = arg;
		const rawOptions = view.getOption(key + 'Options');
		options = _.extend({}, buildOptions.defaultOptions, commonOptions, rawOptions, buildOptions.options);
	} else if (typeof (arg) === 'object' && _.isViewClass(arg.class)) {
		View = arg.class;
		options = _.extend({}, buildOptions.defaultOptions, commonOptions, _.omit(arg, 'class'), buildOptions.options);
	} else if (_.isViewClass(buildOptions.View)) {
		View = buildOptions.View;
		const rawOptions = view.getOption(key + 'Options');
		options = _.extend({}, buildOptions.defaultOptions, commonOptions, rawOptions, buildOptions.options);
	} else {
		throw new Error('unable to build nested view');
	}
	return new View(options);
}
