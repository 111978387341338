// import { _ } from 'vendors';
import { buildFilters } from 'components/filters-v2';
import busData from 'bus/data';
const filters = {
	departmentId: {
		label: 'отдел',
		valueType: 'string'
	},
	period: {
		label: 'период',
		valueType: 'datetime',
		range: true
	},
	productId: {
		label: 'продукция',
		valueType: 'string',
		controlType: 'productsSelect'
	},
	actorId: {
		label: 'сотрудник',
		valueType: 'string',
		controlType: 'employeesSelect',
		display: v => busData.employeeName(v)
		// display: {
		// 	label: 'ответственный',
		// 	ifEmpty: ' - ',
		// 	transform: (v) => {
		// 		console.log('privaet');
		// 		return busData.employees(v).display('name');
		// 	}
		// }
	},
	type: {
		label: 'тип транзакции',
		valueType: 'enum',
		valueSubType: 'productBatchTransferTypes',
		multiple: true
	}
};

export const logFilters = (extend) => {
	return buildFilters(filters, { extend });
};
