// import busData from 'bus/data';
import { commonReportSchema } from '../common-report-schema';



// export const tableSchema = {
// 	created: {
// 		thisClassName: 'created fixed',
// 		onAfterInitialize () {
// 			this.value = new Date(this.model.get('created'));
// 		},
// 		text: v => v.value.toLocaleDateString(),
// 		smallText: v => v.value.toLocaleTimeString()
// 	},
// 	amount: {
// 		// class: Ui2AtomView,
// 		thisClassName: 'amount',
// 		text: v => {

// 			const {
// 				amount: sourceAmount,
// 				baseUnitMeasureId: sourceMeasureId
// 				// producedUnitMeasureId: sourceMeasureId,
// 				// baseUnitsInProducedUnit: destinationUnitsInSourceUnit
// 			} = v.model.attributes;

// 			const options = {
// 				sourceAmount,
// 				sourceMeasureId,
// 				destinationMeasureId: sourceMeasureId,
// 				destinationUnitsInSourceUnit: 1,
// 				display: true
// 			};

// 			return measuredAmount(options);
// 		}
// 		// topText: v => 'склад до: ' + v.model.get('amountInStoreBefore'),
// 		// bottomText: 'объём транзакции'
// 			// v => 'склад после: ' + v.model.get('amountInStoreAfter')
// 	},
// 	amountBefore: {
// 		thisClassName: 'amount amount-before',
// 		text: v => modelSimpleAmount(v.model, 'amountBefore', 'unitMeasureId'),
// 		bottomText: 'было'
// 	},
// 	amountAfter: {
// 		thisClassName: 'amount amount-after',
// 		text: v => modelSimpleAmount(v.model, 'amountAfter', 'unitMeasureId'),
// 		bottomText: 'стало'
// 	},
// 	amountDelta: {
// 		thisClassName: 'amount amount-delta',
// 		text: v => {
// 			const { amountAfter, amountBefore, unitMeasureId } = v.model.attributes;
// 			const delta = amountAfter - amountBefore;
// 			return simpleAmount(delta, unitMeasureId);
// 		},
// 		bottomText: 'изменение'
// 	},
// 	amountPurchased: {
// 		thisClassName: 'amount amount-purchased positive',
// 		text: v => modelSimpleAmount(v.model, 'amountPurchased', 'unitMeasureId'),
// 		bottomText: 'закуплено'
// 	},
// 	amountProduced: {
// 		thisClassName: 'amount amount-produced positive',
// 		text: v => modelSimpleAmount(v.model, 'amountProduced', 'unitMeasureId'),
// 		bottomText: 'произведено'
// 	},
// 	amountUsed: {
// 		thisClassName: 'amount amount-used negative',
// 		text: v => modelSimpleAmount(v.model, 'amountUsedForProduction', 'unitMeasureId'),
// 		bottomText: 'использовано'
// 	},
// 	amountTransfered: {
// 		thisClassName: 'amount amount-transfered negative',
// 		text: v => modelSimpleAmount(v.model, 'amountTransferedToContragent', 'unitMeasureId'),
// 		bottomText: 'передано кон-там'
// 	},
// 	amountTransferedBack: {
// 		thisClassName: 'amount amount-transfered-back positive',
// 		text: v => modelSimpleAmount(v.model, 'amountTransferedFromContragent', 'unitMeasureId'),
// 		bottomText: 'изъято у кон-тов'
// 	},
// 	amountFired: {
// 		thisClassName: 'amount amount-fired negative',
// 		text: v => modelSimpleAmount(v.model, 'amountFired', 'unitMeasureId'),
// 		bottomText: 'списано'
// 	},
// 	product: {
// 		thisClassName: 'product fixed',
// 		text: v => prodName(v.model.get('shortName'), v.model.get('fullName'))
// 	},
// 	batchName: {
// 		thisClassName: 'batch',
// 		text: v => v.model.get('batchIdString'),
// 		textHtml: (v, nov) => `<span class="batch-identifier">${v == null ? nov : v}</span>`
// 	},
// 	productAndBatchNames: {
// 		thisClassName: 'product fixed',
// 		smallText: v => v.model.get('productBatchIdString'),
// 		smallTextHtml: (v, nov) => `<span class="batch-identifier">${v == null ? nov : v}</span>`,
// 		text: v => v.model.get('productName')
// 	},
// 	actor: {
// 		thisClassName: 'actor',
// 		text: v => busData.employeeName(v.model.get('actorId'))
// 	}

// };

export const tableSchema = {
	...commonReportSchema
};
