import BasePage from 'pages/auth';
import Layout from './layout';
// import Emps from './emps/emps';

const Page = BasePage.extend({
	moduleRights: { admin: 'view, manageGroups' },
	id: 'admin/groups/create',
	name: 'create group page',
	label: 'Добавление группы',
	route: 'groups/create',
	Layout
});

export default Page;
