import { CyclesListView } from './cycles-list-view';
import { UiBlockView } from 'components/ui2/block';
import { getAsComponentCollection } from './asComponent-collection';

export const UsedInProductionBlockLayout = UiBlockView.extend({
	getCollection () {
		return getAsComponentCollection(this.model);
	},
	// canManuallyAdd () {
	// 	return this.model.concretesCanBeManuallyAdded();
	// },
	wholeColumn: true,
	doubleWidth: true,
	header: 'используется в производстве',
	// actionInFooter: 'add:concrete:product',
	content: v => ({
		class: CyclesListView,
		model: v.model,
		collection: v.getCollection()
	})

});

