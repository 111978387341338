import { _ } from 'vendors';
import View from 'base/view';
import Card from 'mod/departments/views/card-item';
import Children from './departments';
import collection from 'mod/departments/singleton';

const Department = View.extend({
	className: 'tree-item departments-item',
	template: _.template(''),
	initialize () {
		const model = this.model;
		this.createNested('entry', {
			viewClass: Card,
			regionTemplate: { replaceElement: true }
		});
		this.createNested('children', {
			viewClass: Children,
			viewOptions: {
				collection,
				childView: Department,
				cssClassModifiers: ['tree-item-children'],
				model
			},
			regionTemplate: { replaceElement: true }
		});
	},
	onRender () {
		this.showAllNested();
	}
});

export default Department;
