import template from './template.html';
import View from 'base/view';
import beh from 'behaviors';
// import modalError from 'helpers/modals/error';
import user from 'app/user';
import modals from 'helpers/modals';

const Layout = View.extend({
	className: 'card maxw600px margin-t',
	template,
	behaviors: [beh.FormToHash],
	onValuesApply (hash) {
		if (!hash.email) { modals.error('Укажите свой email'); } else { this.restorePass(hash); }
	},
	restorePass (data) {
		// var preloader = modals.preloader();
		user.post({
			url: 'passwordRecovery/begin',
			data,
			preloader: true
		}).then(
			() => {
				// preloader.destroy({force:true});
				modals.message('На указанный email отправлено письмо с дальнейшими инструкциями');
			},
			() => {
				// preloader.destroy({force:true});
				modals.error('Произошла непредвиденная ошибка. Перезагрузите страницу и попробуйте ещё раз');
			}
		);
	}
});

export default Layout;
