import { UiSchemaPropertiesView } from '../../../../components/ui2/schema-properties';
import { HamburgerView } from 'base/view-hamburger';

export const MainPropertiesListView = UiSchemaPropertiesView.extend({
	thisClassName: 'with-borders',
	properties: [
		//'productNameUrl', 'productBatchIdStringUrl', 'created', 'amountPostfix', 'actorId', 'departmentUrl', 'originProcessUrl', 'cycle'
		"idString",
		"product",
		"baseAmountPostfix",
		"origin",
		"originProcessUrl",
		"id",
		"componentsFootprint",
		"created",
		"modified",
		"creatorId",
		"lastTransfer",
	]
});



export const MainPropertiesBlockView = HamburgerView.extend({
	baseClassName: 'ui2-block batch-main',
	customViewOptions () {
		return {
			model: this.model
		};
	},

	customViews: [
		MainPropertiesListView,

	]
});
