import { _ } from 'vendors';
import '../api-config';
import model from './model';
import paths from 'helpers/paths';
import comparator from 'helpers/comparator';

import BaseCollection from 'base/collection';
import PagedCollection from 'mixins/paged-collection';
import mix from 'helpers/mix';


const Collection = mix(BaseCollection).with(PagedCollection);


export default Collection.extend({
	constructor (data, opts = {}) {
		this._sortOnChange = opts.sortOnChange !== false;
		if (this._sortOnChange) {
			this.on('change', this._onChange);
		}

		// this.comparator = opts.sorting === 'deadline desc' ? this._deadlineDesc : this._deadlineAsc;
		this.setOrder(opts.sorting);

		this.bad = opts.bad;
		this.parent = opts.parent;
		Collection.apply(this, arguments);
	},
	urlPattern: paths.api('tasks'),
	fetch (opts = {}) {
		this._abandonSort = true;
		const promise = Collection.prototype.fetch.call(this, _.extend({ url: this.fetchUrl() }, opts));
		promise.then(() => {
			this._abandonSort = false;
			this.sort();
		});
		return promise;
	},
	fetchUrl () {
		if (!this.parent) { return this.url(); }

		return this.parent.getApiUrl({ bad: this.bad });
	},
	model,

	_onChange () {
		if (this._sortOnChange && !this._abandonSort) {
			this.sort();
		}
	},
	_deadlineAsc (m1, m2) {
		return comparator([
			m1, m2, (m) => m.getDisplayDate()
		]);
	},
	_deadlineDesc (m1, m2) {
		return comparator([
			m2, m1, (m) => m.getDisplayDate()
		]);
	},
	setOrder (order, { sort } = {}) {
		const comparator = this.getComparator(order);
		this.comparator = comparator;
		if (sort) {
			this.sort();
		}
	},
	getComparator (order) {
		if (!order) {
			return this._deadlineAsc;
		}
		order = order[0];
		if (order[0] !== 'date') return this._deadlineAsc;

		if (order[1]) {
			return this._deadlineDesc;
		} else {
			return this._deadlineAsc;
		}
	}
});
