import { _ } from 'vendors';
import View from 'base/view';
import BaseModel from 'base/model';
import Actionable from '../../mixins/actionable';
import mix from 'helpers/mix';
import action from 'helpers/action';
import CollectionView from 'base/collection-view';

// import template from './header.html';
import PopoverBehavior from 'behaviors/popover';
// import user from 'app/user';
import ActionsLayout from 'components/actions/actions-layout';
import Notifications from './notifications';
import smartOpen from '../../helpers/smart-open';
import paths from 'helpers/paths';
import busData from 'bus/data';
import createTaskAction from 'mod/tasks/create-task-action';
// console.log('---*****------', Actionable);

const ActModel = mix(BaseModel).with(Actionable);

const user = () => busData.user();

const TaskButtonModel = ActModel.extend({
	actions: [
		action('request:transport', 'подать заявку на транспорт', { tasks: 'createTransportRequest' }),
		action('request:purchase', 'подать заявку на закупку', { tasks: 'createPurchaseRequest' }),
		action('create:task', 'поставить новую задачу')
	],
	actionCreateTask () {
		createTaskAction({
			success (model) {
				busData.trigger('task:created', model);
			}
		});
	},
	actionRequestTransport () {
		createTaskAction({
			taskType: 'request:transport',
			success (model) {
				busData.trigger('task:created', model);
			}
		});
	},
	actionRequestPurchase () {
		createTaskAction({
			taskType: 'request:purchase',
			success (model) {
				busData.trigger('task:created', model);
			}
		});
	}
});

// const UserActionsPopoverBehavior = PopoverBehavior.extend({
// 	viewport: 'viewport',
// 	reference: 'button.user',
// 	position: 'bottom-end',
// 	popoverViewCssClass: 'popover-actions',
// 	content () {
// 		return new ActionsLayout({ model: user() });
// 	}
// });

// const NewUserActionsPopoverBehavior = PopoverBehavior.extend({
// 	viewport: 'viewport',
// 	// reference:'button.user',
// 	position: 'bottom-end',
// 	popoverViewCssClass: 'popover-actions',
// 	content () {
// 		return new ActionsLayout({ model: user() });
// 	}
// });

const ActionsPopover = PopoverBehavior.extend({
	viewport: 'viewport',
	position: 'bottom-end',
	popoverViewCssClass: 'popover-actions',
	content () {
		const header = this.view.getOption('actionsPopoverHeader', true);
		//console.warn('-- acts popover --', header);
		return new ActionsLayout({ model: this.view.model, header });
	}
});

function getUserLogin(view) {
	const eid = view.model.get('employeeId');
	const emp = busData.employees(eid);
	if (emp) {
		return emp.get('userName');
	}
}


// const HeaderView = View.extend({
// 	template,
// 	tagName: 'section',
// 	className: 'page-header',
// 	behaviors: [UserActionsPopoverBehavior],
// 	// regions:{
// 	// 	'notifier':{el:'.notifications', replaceElement:true},
// 	// },
// 	ui: {
// 		header: '.header'
// 	},
// 	dynamicClassName () {
// 		const res = [];
// 		const page = this.getPage();
// 		if (!page || !page.getGoBackMethod()) { res.push('no-back'); }

// 		if (user().isAnonym()) {
// 			res.push('no-notifications');
// 			res.push('no-user');
// 		}

// 		return res.join(' ');
// 	},
// 	initialize (opts) {
// 		this.mergeOptions(opts, ['app', 'content', 'page']);
// 		if (this.page && this.page.model) { this.listenTo(this.page.model, 'change', this.render); }
// 		this.createNested('notifications', {
// 			viewClass: Notifications,
// 			viewOptions: {
// 				className: 'notifications'
// 			},
// 			regionTemplate: { el: '.notifications', exist: true, replaceElement: true }
// 		});
// 	},
// 	onRender () {
// 		this.showNotifications();
// 	},
// 	getPage () {
// 		return this.page;
// 	},
// 	triggers: {
// 		'click .app-menu': { event: 'toggle:menu', stopPropagation: false },
// 		'click .page-back': 'go:back'
// 	},
// 	events: {
// 		'click .processes' (e) {
// 			smartOpen(paths.url('processes:active'), e);
// 		},
// 		'click .tasks' (e) {
// 			smartOpen(paths.url('tasks'), e);
// 		}
// 	},
// 	getHeader () {
// 		return this.getPage().getHeader();
// 	},

// 	templateContext () {
// 		let tasks, processes;
// 		if (user().isEmployeed()) {
// 			// if (user.checkRights({ processes: 'view' })) {}
// 			tasks = '';
// 			processes = '';
// 		}
// 		return {
// 			page: this.getPage(),
// 			header: this.getHeader(),
// 			tasks,
// 			processes
// 		};
// 	}
// });

const HeadButton = View.extend({
	tagName: 'button',
	template: _.template('<i></i>'),
	events: {
		click (e) {
			if (this.$el.prop('disabled')) return;
			this.triggerMethod('click', e);
		}
	}
});

const HeadText = View.extend({
	template: _.template('<%= text %>'),
	className: 'header',
	initialize (opts) {
		this.mergeOptions(opts, ['text']);
	},
	setText (text) {
		this.text = text;
		this.render();
	},
	templateContext () {
		return {
			text: this.text || ''
		};
	}
});

const NewHeaderView = CollectionView.extend({
	tagName: 'section',
	className: 'page-header',
	viewComparator: false,
	initialize (opts) {
		this.mergeOptions(opts, ['app', 'content', 'page']);
		if (this.page && this.page.model) {
			this.listenTo(this.page.model, 'change', this.render);
		}
	},
	dynamicClassName () {
		const res = [];
		const page = this.getPage();
		if (!page || !page.getGoBackMethod()) {
			res.push('no-back');
		}

		if (user().isAnonym()) {
			res.push('no-notifications');
			res.push('no-user');
		}

		return res.join(' ');
	},
	getPage () {
		return this.page;
	},
	getHeader () {
		const page = this.getPage();
		const header = page && page.getHeader();
		return header;
	},
	customViews () {
		// console.log('C I D:', this.cid, this);
		// this.DEBUG = true;
		const emp = () => user().isEmployeed();
		return [
			() => new HeadButton({ className: 'app-menu', onClick: () => this.triggerMethod('toggle:menu') }),
			() => new HeadButton({ className: 'page-back', onClick: () => this.triggerMethod('go:back') }),
			() => new HeadText({ text: this.getHeader() }),
			() => emp() ? new HeadButton({ className: 'tasks', onClick: e => smartOpen(paths.url('tasks'), e), model: new TaskButtonModel(), behaviors: [ActionsPopover] }) : null,
			() => emp() ? new HeadButton({ className: 'processes', onClick: e => smartOpen(paths.url('processes:active'), e) }) : null,
			() => new Notifications({ className: 'notifications' }),
			// new HeadButton({ className: 'notifications no-badge' }),
			() => new HeadButton({ className: 'user', model: user(), behaviors: [ActionsPopover], actionsPopoverHeader: getUserLogin })
		];
	}
});

export default NewHeaderView;
