const CELL_SEPARATOR = ',';
const LINE_SEPARATOR = '\r\n';


export function buildCsv (data, options = {}) {
	const header = buildHeaderLine(options);
	const lines = data.map(model => buildDataLine(model, options));
	lines.unshift(header);
	return lines.join(LINE_SEPARATOR);
}

function buildLine (cells) {
	return cells.join(CELL_SEPARATOR);
}

function buildHeaderLine (options) {
	const { fields } = options;
	const cells = fields.map(field => buildHeaderCell(field, options));
	return buildLine(cells);
}

function buildHeaderCell (field, options) {
	const value = getHeaderValue(field, options);
	const csvValue = buildCell(value);
	return csvValue;
}

function getHeaderValue (field, options) {
	if (options.fieldHeader) {
		return options.fieldHeader(field, options);
	}
	return field;
}


function buildDataLine (model, options) {
	const fields = options.fields;
	const cells = fields.map(field => buildDataCell(field, model, options));
	return buildLine(cells);
}

function buildDataCell (field, model, options) {
	const value = getFieldValue(field, model, options);
	return buildCell(value);
}

function getFieldValue (field, model, options) {
	if (options.fieldValue) {
		return options.fieldValue(field, model, options);
	}
	return model[field];
}

function buildCell (value) {
	const text = value != null ? value.toString() : '';
	text.replace(/"/, '""');
	return `"${text}"`;
}
