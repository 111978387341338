import proto from 'components/values/get-prototype';
import enums from 'helpers/enums';
import busData from 'bus/data';
import bus from 'bus/models';

import { buildFilters as baseBuildFilters } from 'components/filters-v2/buildFilters';
import { tryGetName } from '../../../utils/guidNames';

export const commonFilters = {
	period: {
		label: 'период',
		valueType: 'datetime',
		range: true
	},
	productId: v => {
		const sourceValues = v.getSelectModels('products');
		return {
			label: 'продукция',
			valueType: 'string',
			controlType: 'select',
			sourceValues
		};
	},
	actorId: v => {
		const sourceValues = v.getSelectModels('employees');
		return {
			label: 'сотрудник',
			valueType: 'string',
			controlType: 'select',
			sourceValues
		};
	},
	contragentId: v => {
		const sourceValues = v.getSelectModels('contragents');
		return {
			label: 'контрагент',
			valueType: 'string',
			controlType: 'select',
			sourceValues
		};
	},
	departmentId: {
		hidden: true,
		valueType: 'string'
	}
};

export function buildFilters (filters, options) {
	return baseBuildFilters(filters, options);
}

export const batchesFilters = [
	{
		id: 'product:text',
		valueId: 'text',
		label: 'поиск по ключевому слову',
		hidden: true,
		valuePrototype: proto('text'),
		textSearch: true
	},
	{
		id: 'batch:text',
		valueId: 'batchIdString',
		label: 'партия',
		// hidden: true,
		valuePrototype: proto('text'),
		pinned: true
		// textSearch: true
	},
	// {
	// 	id: 'period',
	// 	valueId: 'period',
	// 	label: 'период',
	// 	pinned: true,
	// 	valuePrototype: {
	// 		valueType: 'datetime',
	// 		modelType: 'range'
	// 	},
	// 	range: true
	// },
	{
		id: 'productId',
		valueId: 'productId',
		label: 'продукция',
		pinned: true,
		// valueLabel (id) {
		// 	console.log('## id ?', id);
		// 	return bus.request('new:products:model', { id }).display('fullName');
		// },
		valueLabel (id) {
			return tryGetName(id);
		},

		valuePrototype: {
			controlType: 'select-api',
			modelType: 'single',
			multiple: false,
			sourceValues: () => {
				const col = bus.request('new:products:search');
				col.fetchIfNot();
				return col;
			}
		}
	},
	{
		id: 'product:typeId',
		valueId: 'productTypeId',
		label: 'тип',
		pinned: true,
		// values: [{value: 'client'}],
		valueLabel: (val) => busData.productTypeName(val),
		valuePrototype: () => ({
			multiple: false,
			sourceValues: () => busData.productTypes(),
			controlType: 'select',
			modelType: 'single'
		})
	},

	// {
	// 	id: 'product:origin',
	// 	valueId: 'productOrigin',
	// 	label: 'источник',
	// 	pinned: true,
	// 	// values: [{value: 'client'}],
	// 	valueLabel: (val) => enums.get('productOrigins', val),
	// 	valuePrototype: () => proto('enum', {
	// 		multiple: true,
	// 		sourceValues: () => enums.store.productOrigins
	// 	})
	// },

	{
		id: 'product:purpose',
		valueId: 'productPurpose',
		label: 'назначение продукции',
		pinned: true,
		// values: [{value: 'client'}],
		valueLabel: (val) => enums.get('productPurposes', val),
		valuePrototype: () => proto('enum', {
			multiple: true,
			sourceValues: () => enums.store.productPurposes
		})
	}
	// {
	// 	id: 'product:isArchived',
	// 	valueId: 'isArchived',
	// 	label: 'Архив',
	// 	pinned: true,
	// 	valueLabel: (val) => enums.get({ false: 'Действующая продукция', true: 'Архивная продукция' }, val || false),
	// 	valuePrototype: () => proto('enum', {
	// 		multiple: false,
	// 		sourceValues: () => ({ false: 'Действующая продукция', true: 'Архивная продукция' })
	// 	})
	// },
	// {
	// 	id: 'product:isDeleted',
	// 	valueId: 'isDeleted',
	// 	label: 'Удалённая продукция',
	// 	pinned: true,
	// 	rights: { admin: true, resourcesProduction: 'productsEdit' },
	// 	valueLabel: (val) => enums.get({ false: 'нет', true: 'да' }, val || false),
	// 	valuePrototype: () => proto('enum', {
	// 		multiple: false,
	// 		sourceValues: () => ({ false: 'не удалённая продукция', true: 'удалённая продукция' })
	// 	})
	// }
];

export function getRawFilters () {
	return batchesFilters.map(item => {
		return {
			...item
		};
	});

}
