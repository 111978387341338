import { _ } from 'vendors';
// import editValue from 'components/edit-value';
import unFlat from 'helpers/unflat';
import flat from 'helpers/flat';
import editValue from 'bus/edit';
import modalError from 'helpers/modals/error';
import isKnownCtor from 'helpers/is-known-ctor';

export default (Base) => Base.extend({
	editableProperties: true,
	patchProperty (property, value, opts = {}) {
		const resolve = opts.resolve || (() => {});
		const reject = opts.reject || ((m, xhr) => modalError('Не удалось сохранить изменения', xhr));
		const patchHash = unFlat({ [property]: value });
		console.log('patchHash', patchHash);
		return this.patch(patchHash, { wait: true, flat: opts.flatPatch, preloader: { overlay: opts.view } }).then(
			(data) => {
				if (value == null && !(property in flat(data))) {
					this.set(property, value, { flat: opts.flatPatch });
				}
				resolve(this, data);
			},
			(xhr) => reject(this, xhr)
		);
	},
	getPropertyEditConfig (key) {
		const prop = this.getPropertyConfig(key);

		const p = (key) => {
			if (!_.isFunction(prop[key]) || isKnownCtor(prop[key])) { return prop[key]; } else {
				return prop[key].call(this, this);
			}
		};
		const getOpts = p('editGetOptions');
		const eopts = p('edit');
		const opts = _.extend({
			header: this.getPropertyLabel(key) || key,
			label: this.getPropertyLabel(key) || key,
			valueType: p('type') || p('valueType') || 'text',
			modelType: 'single',
			value: this.get(key, getOpts),
			sourceValues: p('sourceValues'),
			allowedValues: p('allowedValues'),
			filterValuesMask: p('filterValuesMask'),
			controlType: p('controlType'),
			multiple: prop.multiple,
			controlOptions: p('controlOptions'),
			editOptions: p('editOptions')
		}, eopts);
		if (prop.notNull) {
			opts.editOptions || (opts.editOptions = {});
			opts.editOptions.resetButton = false;
		}



		return opts;
	},
	async editProperty (property, options = {}) {
		if (!this.canEditProperty(property)) return;
		const propCfg = this.getPropertyEditConfig(property);


		const opts = _.extend({}, propCfg, {
			editOptions: _.extend({
				ignoreModel: true,
				applyLabel: 'сохранить изменения',
				cancelButton: false,
				resetLabel: 'сбросить и сохранить'
			})
		});

		_.extend(opts.editOptions, propCfg.editOptions);
		opts.controlOptions = _.extend({}, opts.controlOptions, { doneOnBlur: false });

		// opts.controlOptions || (opts.controlOptions = {});
		// opts.controlOptions.doneOnBlur = false;

		const shouldOnlyApply = options.shouldOnlyApply != null ? options.shouldOnlyApply : this.isNew();

		opts.controlViewOptions = _.extend({}, opts.controlViewOptions, options.controlViewOptions);
		if (opts.controlOptions && opts.controlOptions.predefinedValues) {
			const getPredefined = opts.controlOptions.predefinedValues;
			if (_.isFunction(getPredefined)) {
				let result = getPredefined(this);
				if (result && result.then) {
					result = await result;
				}
				opts.controlOptions.predefinedValues = result;
			}
		}
		const applyValue = opts.applyValue;
		// console.log('--++> EDIT', opts, applyValue);
		if (_.isFunction(propCfg.beforeEdit)) {
			opts.value = propCfg.beforeEdit.call(this, opts.value);
		}

		editValue.do(opts).then((v) => {
			if (_.isFunction(propCfg.beforeChange)) {
				v = propCfg.beforeChange.call(this, v);
			}
			console.log('mod prop val:', property, v, shouldOnlyApply, applyValue);
			if (shouldOnlyApply) {
				if (applyValue) {
					applyValue(this, property, v);
				} else {
					this.set(property, v);
				}
				if (options.onResolve) {
					options.onResolve(v);
				}
			} else {
				this.patchProperty(property, v, options);
			}
		}, (rejectContext) => {
			if (rejectContext.reason === 'reset') {
				let value;
				if (_.isFunction(propCfg.beforeChange)) {
					value = propCfg.beforeChange.call(this, rejectContext.value);
				}
				if (this.isNew()) {
					this.set(property, value || null);
				} else {
					this.patchProperty(property, value || null);
				}
			}
		});
	},
	canEditProperty (property) {
		const rights = this.getPropertyRights(property);
		if (!_.size(rights)) return true;
	}
});
