import { _ } from 'vendors';
import paths from 'helpers/paths';
import Collection from 'base/collection';

export function getPointString (point) {
	let res = [];
	if (point.dropCargo) {
		res.push('отвозим ' + point.dropCargo);
	}
	if (point.takeCargo) {
		res.push('забираем ' + point.takeCargo);
	}
	const cargo = res.join(', ');
	res = [];
	if (point.contragent) {
		res.push(point.contragent.name);
	} else if (point.contact && point.contact.value) {
		res.push(point.contact.value);
	}
	if (cargo) {
		res.push(cargo);
	}
	return res.join(': ');
}
export function getPointsString (points, ensure) {
	points = ensureCollectionModels(points, ensure);
	if (!points || !points.length || (points.length === 1 && !_.size(points[0]))) return;

	return points.map(point => getPointString(point)).join('; ');
}

export function ensureCollectionModels (points, nullable = true) {
	if (nullable && points == null) {
		return [{}];
	}
	return points;
}


let counter = 0;
export function tramsformOldRequest (det) {
	if (det.points) {
		return det;
	} else {
		return {
			type: 'requestTransport',
			points: toNewPoints(det)
		};
	}
}
export function toNewPoints (old) {
	if (!old || old.points) return old.points;

	const isPalma = (addr) => {
		if (!addr) return false;
		const coords = addr.lat === 55.685706 && addr.lng === 37.606963;
		const text = addr.address === 'Россия, Москва, Нагорный проезд, 12к1';
		return text || coords;
	};

	const deb = {
		id: counter++
	};


	const first = {
		address: old.fromAddress
	};
	const second = {
		address: old.toAddress
	};
	if (old.fromAddress.contragent) {
		const firstContragent = _.pick(old.fromAddress.contragent, 'id', 'name');
		first.contragent = firstContragent;
	}
	if (old.toAddress.contragent) {
		const secondContragent = _.pick(old.toAddress.contragent, 'id', 'name');
		second.contragent = secondContragent;
	}

	const points = [first, second];
	const ownContact = old.contactName;

	if (isPalma(old.fromAddress)) {
		first.contragent = { name: 'PALMA' };
		first.takeCargo = old.cargo;
		second.dropCargo = old.cargo;
		if (ownContact) {
			second.contact = { value: ownContact };
		}
		deb.palma = 'FROM';
		points.shift();
		// return [second];
	} else if (isPalma(old.toAddress)) {
		second.contragent = { name: 'PALMA' };
		first.takeCargo = old.cargo;
		second.dropCargo = old.cargo;
		if (ownContact) {
			first.contact = { value: ownContact };
		}

		deb.palma = 'TO';
		points.pop();
	} else {
		first.takeCargo = old.cargo;
		second.dropCargo = old.cargo;
		deb.palma = false;
		// console.log('-----');
		// console.log(old);
		// console.log(isPalma(old.fromAddress), old.fromAddress);
		// console.log(isPalma(old.toAddress), old.toAddress);
		// console.log('-----');
	}
	// console.log('::', old);
	// console.log('::', points);
	// console.log(deb);

	return points;
}

export async function fetchContactValues (point) {
	if (!point || !point.contact) return;

	const values = point.contact.values;
	if (values) {
		return values;
	}
	const contragentId = point && point.contragent && point.contragent.id;
	const contactId = point.contact.id;
	if (!contactId || !contragentId) { return; }

	const url = [paths.api('contragents'), contragentId, 'contacts', contactId, 'values'].join('/');
	const col = new Collection();
	col.url = url;

	try {
		const data = await col.fetch();
		return data;
	} catch (e) {

	}
}
