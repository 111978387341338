// import { measuredAmount } from 'utils';
import { getProductName, simpleAmount } from './utils';
import enums from 'helpers/enums';
import modals from 'helpers/modals';

const variantsBottoms = {
	before: 'было',
	after: 'стало',
	delta: 'изменение'
};

const keyTops = {
	store: 'склад',
	contragents: 'контрагенты',
	inProducts: 'полуфабрикаты',
	expectedInProducts: 'ожидание полуф-ты'
};

const decrementKeys = {
	usedForProduction: 1,
	sold: 1,
	spentOnProduction: 1,
	returnedToManufacturer: 1,
	defect: 1,
	manualDecrement: 1,
	utilized: 1
	//	issuedToContragent: 1,
	//	takedFromContragent: 1,
};
const incrementKeys = {
	produced: 1,
	purchased: 1,
	manualIncrement: 1
};

const amountFields = {

	usedForProduction: {
		changeType: 'decrement',
		header: 'использовано в производство',
		headerText: 'использовано',
		headerSmallText: 'производство'
	},
	sold: {
		changeType: 'decrement',
		header: 'продано'
	},
	returnedToManufacturer: {
		changeType: 'decrement',
		header: 'возврат поставщику'
	},
	defect: {
		changeType: 'decrement',
		header: 'забраковано'
	},
	manualDecrement: {
		changeType: 'decrement',
		header: 'убавлено вручную'
	},
	utilized: {
		changeType: 'decrement',
		header: 'утилизировано'
	},

	produced: {
		changeType: 'increment',
		header: 'произведено'
	},
	purchased: {
		changeType: 'increment',
		header: 'закуплено'
	},
	manualIncrement: {
		changeType: 'increment',
		header: 'увеличено вручную'
	},

	issuedToContragent: {
		changeType: '',
		header: 'передано контрагенту'
	},
	takedFromContragent: {
		changeType: '',
		header: 'изъято у контрагента'
	}

};

function defaultTextFunc () {
	const value = this.getOption('value', true);
	const unitMeasureId = this.model.get('unitMeasureId');
	// console.error('#T', unitMeasureId, value);
	return simpleAmount(value, unitMeasureId);
}

function modelSimpleAmount (model, amountKey, unitKey, ifEmpty) {
	const amount = model.get(amountKey);
	const unit = model.get(unitKey);
	return simpleAmount(amount, unit, ifEmpty);
}
function deltaAmountValue (model, key) {
	const before = model.attributes[key + 'Before'];
	const after = model.attributes[key + 'After'];
	const delta = after - before;
	return delta;
}
function deltaAmount (model, key) {
	// const before = model.attributes[key + 'Before'];
	// const after = model.attributes[key + 'After'];
	// const delta = after - before;
	const delta = deltaAmountValue(model, key);
	const unitMeasureId = model.attributes.unitMeasureId;
	return simpleAmount(delta, unitMeasureId);
}

function schemaVariantBuilder (key, variant, tagTitle) {
	const Variant = variant[0].toUpperCase() + variant.substring(1);
	const modelKey = key + Variant;
	const value = variant !== 'delta'
		? v => v.model.get(modelKey)
		: v => deltaAmountValue(v.model, key);

	const text = variant !== 'delta'
		? v => modelSimpleAmount(v.model, modelKey, 'unitMeasureId')
		: v => deltaAmount(v.model, key);

	let onClick;
	if (key === 'expectedInProducts') {
		onClick = function () {
			console.log('#onClick');
			const ex = this.model.get('expectedExplain');
			if (ex == null) {
				return;
			}
			const val = ex[variant + 'Text'];
			if (!val) {
				return;
			}
			modals.show({
				header: 'история подсчёта',
				text: '<pre>' + val + '</pre>',
				type: 'full',
				show: {
					resolve: true,
					reject: false
				}
			});
		};
	}

	return {
		[modelKey]: {
			thisClassName: `amount ${key} ${variant}`,
			bottomText: variantsBottoms[variant],
			topText: keyTops[key],
			headerText: keyTops[key],
			headerSmallText: variantsBottoms[variant],
			value,
			text: defaultTextFunc,
			tagTitle,
			shouldTriggerClick: typeof onClick === 'function',
			onClick
		}
	};
}

function schemaBuilder (key, tagTitle) {
	const variants = ['before', 'after', 'delta'];
	return variants.reduce((memo, variant) => {
		Object.assign(memo, schemaVariantBuilder(key, variant, tagTitle));
		return memo;
	}, {});
}

function buildAmountSchemaItem (key) {
	const cfg = amountFields[key] || {};
	let changeClass = cfg.changeType;
	if (changeClass) {
		changeClass = ' ' + changeClass;
	}
	const defaultValue = v => v.model.get(key);
	const { value = defaultValue, headerText, headerSmallText } = cfg;
	return {
		[key]: {
			thisClassName: `amount ${key}${changeClass}`,
			value,
			text: defaultTextFunc,
			headerText: headerText || cfg.header,
			headerSmallText
		}
	};
}

function modelGet (model, key, defaultValue) {
	if (!model) { return defaultValue; }
	let value;
	if (model.get && model.attributes) {
		value = model.get(key);
	} else {
		value = model[key];
	}
	if (value == null) { return defaultValue; }
	return value;
}

function getSum (model, keys = [], exclude = []) {
	if (exclude.length) {
		keys = keys.filter(key => exclude.indexOf(key) === -1);
	}
	return keys.reduce((v, key) => {
		v += modelGet(model, key, 0);
		return v;
	}, 0);
}

function getDecrement (model, excludeKeys = []) {
	const keys = Object.keys(decrementKeys);
	return getSum(model, keys, excludeKeys);
}
function getIncrement (model, excludeKeys = []) {
	const keys = Object.keys(incrementKeys);
	return getSum(model, keys, excludeKeys);
}

export const commonReportSchema = {
	product: {
		noLabels: false,
		thisClassName: 'product fixed',
		text: v => getProductName(v.model),
		value: v => getProductName(v.model),
		headerText: 'продукция',
		topText: v => {
			if (!v.model) { return; }
			return enums.get('dtoProductInfoDeepTypes', v.model.get('deepness'));
		},
		events: {
			click () {
				console.log('#ID', this.model.id);
				console.log('#model', this.model.attributes);
			}
		}
		// smallText: v => v.model.id
	},
	...schemaBuilder('store', 'на складе'),
	...schemaBuilder('contragents'),
	...schemaBuilder('inProducts'),
	...schemaBuilder('expectedInProducts'),
/*
	totalBefore: {
		thisClassName: 'amount total before',
		bottomText: 'было',
		topText: 'всего',
		tagTitle: 'склад + контрагенты (+ полуфабрикаты)',
		text: v => {
			const { storeBefore, contragentsBefore, inProductsBefore = 0, unitMeasureId } = v.model.attributes;
			return simpleAmount(storeBefore + contragentsBefore + inProductsBefore, unitMeasureId);
		},
		headerText: 'всего',
		headerSmallText: 'было'
	},
	totalAfter: {
		thisClassName: 'amount total after',
		bottomText: 'стало',
		topText: 'всего',
		tagTitle: 'склад + контрагенты (+ полуфабрикаты)',
		text: v => {
			const { storeAfter, contragentsAfter, inProductsAfter = 0, unitMeasureId } = v.model.attributes;
			return simpleAmount(storeAfter + contragentsAfter + inProductsAfter, unitMeasureId);
		},

		headerText: 'всего',
		headerSmallText: 'стало'

	},
	baseIncrement: {
		thisClassName: 'amount store increment',
		bottomText: 'приход',
		text: v => modelSimpleAmount(v.model, 'baseIncrement', 'unitMeasureId'),
		headerText: 'приход'
		// topText: 'всего',
		// tagTitle: 'склад + контрагенты',
	},
	specificIncrement: {
		thisClassName: 'amount store increment',
		bottomText: 'приход',
		text: v => modelSimpleAmount(v.model, 'specificIncrement', 'unitMeasureId'),
		headerText: 'приход*',
		// topText: 'всего',
		tagTitle: 'приход без учета ручной корректировки'
	},
	customDecrement: {
		thisClassName: 'amount store decrement',
		bottomText: 'расход',
		text: (v, m = v.model.attributes) => {
			const before = (m.storeBefore + m.contragentsBefore + m.inProductsBefore);
			const after = (m.storeAfter + m.contragentsAfter + m.inProductsAfter);
			const increment = m.specificIncrement;
			return simpleAmount(before + increment - after, m.unitMeasureId);
			// console.log(m);
		},
		// simpleAmount(v.model.get('totalAfter') - v.model.get('totalBefore'), v.model.get('unitMeasureId')),
			// modelSimpleAmount(v.model, 'baseDecrement', 'unitMeasureId'),
		headerText: 'расход**',
		tagTitle: 'всего было + приход - всего стало'
	},
	baseDecrement: {
		thisClassName: 'amount store decrement',
		bottomText: 'расход',
		text: v => modelSimpleAmount(v.model, 'baseDecrement', 'unitMeasureId'),
		headerText: 'расход'
		// topText: 'всего',
		// tagTitle: 'склад + контрагенты',
	},
	specificDecrement: {
		thisClassName: 'amount store decrement',
		bottomText: 'расход',
		text: v => modelSimpleAmount(v.model, 'specificDecrement', 'unitMeasureId'),
		headerText: 'расход*'
		// topText: 'всего',
		// tagTitle: 'склад + контрагенты',
	},
	appeared: {
		thisClassName: 'amount appeared increment',
		bottomText: 'приход',
		text: v => modelSimpleAmount(v.model, 'appeared', 'unitMeasureId'),
		headerText: 'приход'

	},
	defect: {
		thisClassName: 'amount defect decrement',
		bottomText: 'брак',
		text: v => modelSimpleAmount(v.model, 'defect', 'unitMeasureId'),
		headerText: 'брак'

	},
	disappeared: {
		thisClassName: 'amount disappeared decrement',
		bottomText: 'расход',
		text: v => modelSimpleAmount(v.model, 'disappeared', 'unitMeasureId'),
		headerText: 'расход'
	},
	produced: {
		thisClassName: 'amount produced increment',
		bottomText: 'произведено',
		text: v => modelSimpleAmount(v.model, 'produced', 'unitMeasureId'),
		headerText: 'произведено'
	},
	purchased: {
		thisClassName: 'amount purchased increment',
		bottomText: 'поставлено',
		text: v => modelSimpleAmount(v.model, 'purchased', 'unitMeasureId'),
		headerText: 'поставлено'
	},
	spentOnProduction: {
		thisClassName: 'amount used decrement',
		headerSmallText: 'в&nbsp;производство',
		text: v => modelSimpleAmount(v.model, 'spentOnProduction', 'unitMeasureId'),
		headerText: 'пошло'
	},
	usedForProduction: {
		thisClassName: 'amount used decrement',
		bottomText: 'пошло в произв.',
		text: v => modelSimpleAmount(v.model, 'usedForProduction', 'unitMeasureId'),
		headerText: 'пошло в произв.'
	},
*/
	...buildAmountSchemaItem('usedForProduction'),
	...buildAmountSchemaItem('produced'),
	...buildAmountSchemaItem('purchased'),
	...buildAmountSchemaItem('sold'),
	// ...buildAmountSchemaItem('spentOnProduction'),
	...buildAmountSchemaItem('returnedToManufacturer'),
	...buildAmountSchemaItem('defect'),
	...buildAmountSchemaItem('issuedToContragent'),
	...buildAmountSchemaItem('takedFromContragent'),
	...buildAmountSchemaItem('manualIncrement'),
	...buildAmountSchemaItem('manualDecrement'),
	...buildAmountSchemaItem('utilized'),

	// calculated
	totalBefore: {
		thisClassName: 'amount total before',
		// bottomText: 'было',
		// topText: 'всего',
		// tagTitle: 'склад + контрагенты (+ полуфабрикаты)',
		value () {
			const { storeBefore, contragentsBefore, inProductsBefore = 0 } = this.model.attributes;
			const v = storeBefore + contragentsBefore + inProductsBefore;
			return v;
		},
		text: defaultTextFunc,
		headerText: 'всего',
		headerSmallText: 'было'
	},

	totalAfter: {
		thisClassName: 'amount total after',
		// bottomText: 'стало',
		// topText: 'всего',
		// tagTitle: 'склад + контрагенты (+ полуфабрикаты)',
		// text: v => {
		// 	const { storeAfter, contragentsAfter, inProductsAfter = 0, unitMeasureId } = v.model.attributes;
		// 	return simpleAmount(storeAfter + contragentsAfter + inProductsAfter, unitMeasureId);
		// },
		value () {
			const { storeAfter, contragentsAfter, inProductsAfter = 0 } = this.model.attributes;
			const v = storeAfter + contragentsAfter + inProductsAfter;
			return v;
		},
		text: defaultTextFunc,
		headerText: 'всего',
		headerSmallText: 'стало'

	},

	incrementNotManual: {
		thisClassName: 'amount increment',
		headerText: 'приход',
		headerSmallText: '(-ручной ввод)',
		value: v => getIncrement(v.model, ['manualIncrement']),
		text: defaultTextFunc
	},
	decrementSum: {
		thisClassName: 'amount decrement',
		headerText: 'расход',
		headerSmallText: '(сумма всех)',
		value: v => getDecrement(v.model),
		text: defaultTextFunc
	},
	decrementCalculated: {
		thisClassName: 'amount decrement',
		headerText: 'расход',
		headerSmallText: '(было + приход - стало)',
		value: v => {
			const val = v.schemaValue('totalBefore') + v.schemaValue('incrementNotManual') - v.schemaValue('totalAfter');
			// console.log('# decre', val);
			return val;
		},
		text: defaultTextFunc
	}

};
// console.error(commonReportSchema);
