import { PageWithDivisionsLayout } from 'components/page-sub-division/PageWithDivisionsLayout';
import { FilterModel } from './FilterModel';
import { ProductsContent } from './ProductsContent';
import { ProductsHeader } from './ProductsHeader';

export const ProductsLayout = PageWithDivisionsLayout.extend({
	initialize () {
		this.filterModel = new FilterModel();
	},
	rootId: 'production',
	header: {
		class: ProductsHeader
	},
	content: {
		class: ProductsContent,
		className: 'content'
	},
	currentTab: 'table',
	contentOptions () {
		return {
			filterModel: this.filterModel,
			model: this.model
		};
	},
	onBeforeRender () {
		this.model.initializeProductToProcessMap();
	},
	onSearchByName (text) {
		this.filterModel.set('name', text);
		this.filterModel.trigger('filter');
	},
	onHeaderTab (tabId) {
		this.currentTab = tabId;
		this.render();
	}
});
