import { _ } from 'vendors';
import Model from 'base/model';
import { Model as BbModel, Collection } from 'backbone';
import View from '../select/item-view';
import Select from 'components/controls/select';
import busData from 'bus/data';

const fetchedStores = new Map();

function ensureModel (CollectionModel) {
	return CollectionModel !== Model && CollectionModel !== BbModel;
}

function ensureFetchedStore (collection) {
	if (!ensureModel(collection.model)) return;
	let store = fetchedStores.get(collection.model);
	if (!store) {
		store = new Collection(null, { model: collection.model });
		fetchedStores.set(collection.model, store);
	}
	return store;
}

function updateFetchedStore (collection) {
	const store = ensureFetchedStore(collection);
	store && store.add(collection.models);
}

busData.reply('fetched-store', (Model, id) => {
	const store = fetchedStores.get(Model);
	if (!store) return;
	if (id == null) return store;
	return store.get(id);
});


export default Select.extend({
	addClass: 'control-select-api',
	_controlType: 'select-api',
	constructor (opts) {
		Select.apply(this, arguments);

		// let fetchAtStart = this.getOption('fetchAtStart') !== false;

		ensureFetchedStore(this.collection);


		this.listenTo(this.collection, 'sync', () => {
			updateFetchedStore(this.collection);
			this.trigger('refresh');
		});
		this.mergeOptions(opts, 'collectionViewOptions');
		if (!this.collection.length) {
			this.listenToOnce(this.collection, 'sync', () => {
				this.selector.selectInitialValues();
			});
		}

		if (this.getOption('fetchAtStart')) {
			this.collection.fetch().then(() => {
				this.trigger('refresh');
			});
		}

		// if(this.collection && (fetchAtStart !== false))
		// 	this.collection.fetch().then(() => {
		// 		this.trigger('refresh');
		// 	});
	},
	collectionViewOptions () {
		const opts = Select.prototype.collectionViewOptions.apply(this, arguments);
		const childView = this.getOption('collectionChildView');
		if (childView) {
			return _.extend(opts, { childView });
		}
		const childViewMixin = this.getOption('collectionChildViewMixin');
		if (childViewMixin) {
			return _.extend(opts, { childView: View.mixWith(childViewMixin) });
		}
		return opts;
	},

	getModelValue (m) {
		let value;
		if (this.getOption('shouldReturnModel')) {
			value = m;
		} else {
			value = Select.prototype.getModelValue.apply(this, arguments);
		}
		// console.log('#shouldReturnModel', this.getOption('shouldReturnModel'), value);

		return value;
	},
	showSearchAlways: true
});
