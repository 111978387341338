import PopoverBehavior from './popover';
import ActionsLayout from 'components/actions/actions-layout';

const ActionsPopoverBehavior = PopoverBehavior.extend({
	reference: 'button.options',
	position: 'right-start',
	popoverViewCssClass: 'popover-actions',
	showOn: 'click',
	placedAt: 'plate',
	content () {
		const placedAt = this.getOption('placedAt');
		// const header = this.view.getOption('actionsPopoverHeader', true);
		// console.warn('--- acts pop beh ---', header);
		// console.log('>>', placedAt);
		return new ActionsLayout({ model: this.view.model, placedAt });
	}
});

export default ActionsPopoverBehavior;
