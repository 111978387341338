const enumsStore = {};

const YesNo = {
	true: 'Да',
	false: 'Нет'
};

enumsStore.yesNo = YesNo;

export default enumsStore;
