module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<input type="'+
((__t=( type ))==null?'':__t)+
'" name="'+
((__t=( name ))==null?'':__t)+
'" value="'+
((__t=( value ))==null?'':__t)+
'" '+
((__t=( checked ))==null?'':__t)+
'/>\n<i></i>\n<span>'+
((__t=( label ))==null?'':__t)+
'</span>\n';
}
return __p;
};
