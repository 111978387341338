import CollectionView from './collection';
export default CollectionView.extend({
	viewFilter (v) {
		if (this.isCustomView(v)) return true;
		if (!v.model) return false;
		return v.model.isAvailable() && v.model.isPinned();
		// return this.isCustomView(v) || (v.model && v.model.isPinned());
	},
	isCustomView (v) {
		return v._isCustomView || CollectionView.prototype.isCustomView.call(this, v);
	}
});
