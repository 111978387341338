import { _ } from 'vendors';
import View from 'base/view';
import template from './layout.html';
import InputNumber from 'components/controls/input-number-spiner';
import mix from 'helpers/mix';
import ControlMixin from 'components/controls/mixin';

export default mix(View).with(ControlMixin).extend({
	className: 'c-time',
	template,
	regions: {
		hours: { el: '.hours', replaceElement: true },
		minutes: { el: '.minutes', replaceElement: true }
	},
	initialize () {
		this.initValue();
	},
	initValue () {
		const date = this.getOption('date');
		const startTime = this.getOption('startTime');
		let value = date || startTime || new Date();
		// let trigger = false;
		if (startTime && value < startTime) {
			// trigger = true;
			value = startTime;
		}
		this.value = new Date(value.valueOf());
		// if(trigger)
		// 	this.triggerChange();
	},
	onRender () {
		this.showInput('hours', (val) => this.tryNewTime(val));
		this.showInput('minutes', (val) => this.tryNewTime(null, val));
	},
	showInput (name, validate) {
		const options = _.extend(this.getInputOptions(name), {
			controlName: name,
			proxyTo: this,
			focusOnRender: false,
			doneOnBlur: false,
			validate
		});
		const view = new InputNumber(options);
		this.showChildView(name, view);
	},
	getHours () {
		const date = this.getValue();
		return date && date.getHours();
	},
	getMinutes () {
		const date = this.getValue();
		return date && date.getMinutes();
	},
	tryNewTime (hours, minutes) {
		if (!this.getOption('startTime')) return true;
		const di = Date.info(this.getOption('startTime'));
		if (!di.valid) return true;

		const date = new Date(this.getValue().valueOf());

		hours != null && date.setHours(hours);
		minutes != null && date.setMinutes(minutes);
		return date >= di.date;
	},
	setTime (hours, minutes) {
		if (hours == null && minutes == null) return;
		if (!this.tryNewTime(hours, minutes)) {
			return;
		}

		const date = this.getValue();
		if (hours != null) { date.setHours(hours); }
		if (minutes != null) { date.setMinutes(minutes); }

		this.triggerChange(date);
	},
	getInputOptions (name) {
		if (name === 'hours') {
			const value = this.getHours();
			return {
				min: 0,
				max: 23,
				maxLength: 2,
				value
			};
		} else if (name === 'minutes') {
			const value = this.getMinutes();
			return {
				min: 0,
				max: 59,
				maxLength: 2,
				value
			};
		}
	},
	controlsEvents: {
		'hours:change' (value) {
			this.setTime(value);
		},
		'minutes:change' (value) {
			this.setTime(null, value);
		}
	}
});
