export const guidNames = new Map();

function defaultNameGetter(model) {
	return model.get('name');
}

export function tryGetName(id) {
	return guidNames.get(id) || id;
}

function collectNames(collection, nameGetter) {
	nameGetter = nameGetter || defaultNameGetter;
	collection.models.forEach(model => {
		if (model.id == null) { return; }
		const name = nameGetter(model);
		if (name != null) {
			guidNames.set(model.id, name);
		}
	});
}

export function initializeCollectionGuidNames(collection, nameGetter) {

	collection.tryGetName = tryGetName;
	collectNames(collection, nameGetter);
	collection.on('sync', () => collectNames(collection, nameGetter));


}