import { BaseCollectionView } from 'base/base-collection-view';
import { ProductListItemView } from './product-list-item-view.js';
export const ProductsListView = BaseCollectionView.extend({
	baseClassName: 'elastic-content with-borders',
	childView: ProductListItemView,
	childViewOptions () {
		return {
			parent: this.model
		};
	},
	initialize () {
		this.collection.reset(this.model.get('concretes'));
	}
});
