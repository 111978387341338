import { ProductListItemView as Base } from 'mod/productionProducts/views';
import { Ui2AtomView } from '../../../../../../components/ui2/atom';
import { UiMoleculeView } from '../../../../../../components/ui2/molecule';

const CoreView = Ui2AtomView.extend({
	thisClassName: 'elastic',
	topText: v => v.model.display('typeId'),
	text: v => v.model.getName(),
	showSmall: true,
	showSmallText: true,
	smallText: v => {
		const n = v.model.getName();
		const f = v.model.get('fullName');
		return n !== f ? f : '';
	}
});

export const ProductListItemView = UiMoleculeView.extend({
	initialize () {
		this.on('all', x => console.log('eve', x));
	},
	proxyChildViewEvents: true,
	openCardButton: v => v.model.cardUrl
		? v.model.cardUrl()
		: false,
	deleteButton: true,
	content: CoreView,
	childViewEvents: {
		'delete:click' (child) {
			const parent = this.getOption('parent');
			if (!parent) { return; }
			parent.executeAction('remove:concrete:product', [this.model.id]);
			// console.warn('# chpok', arguments);
		}
	}
	// core: [CoreView]
});
// Base.extend({
// 	optionsButton: false,
// 	closeButton: true,
// 	cardButton: true,
// 	textAction: null
// });
