import { displayValueAndUnit, productFields } from '../common';

function getStatsProperty (view, key) {
	const stats = view.model.get('stats') || {};
	return stats[key];
}

const empty = '&mdash;';

export const fields = [
	...productFields,
	{
		key: 'stats.awaited',
		header: 'ожидается',
		cssClass: 'clean',
		value (view) {
			const items = getStatsProperty(view, 'awaited');
			if (!items) return empty;

			const text = items.reduce((txt, item) => {
				txt += displayValueAndUnit(item.amount, item.amountUnitMeasureId) + '<br/>';
				return txt;
			}, '');

			return text;
		}
	},
	{
		key: 'stats.accepted',
		header: 'получено в период',
		cssClass: 'clean',
		value (view) {
			const items = getStatsProperty(view, 'accepted');
			if (!items) return empty;

			const text = items.reduce((txt, item) => {
				txt += displayValueAndUnit(item.amount, item.amountUnitMeasureId) + '<br/>';
				return txt;
			}, '');

			return text;
		}
	}
];
