import BaseView from 'base/view';
import Entries from './entries';
// import Collection from 'mod/productionTypes/models/collection';

export default BaseView.extend({
	id: 'production-types-search',
	className: 'production-types fdc-container',
	isInvulnerable: true,
	template: BaseView.emptyTemplate,
	regions: {
		content: '.content-region'
	},
	initialize () {
		// this.initCollection();
		this.createNested('content', {
			viewClass: Entries,
			regionTemplate: { replaceElement: true }
		});
	},
	// initCollection(){
	// 	if(this.collection) return;
	// 	this.collection = new Collection();
	// },
	onRender () {
		if (this._wasRendered) return;
		this.showContent();
		this._wasRendered = true;
	}

});
