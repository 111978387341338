import { _ } from 'vendors';
const CustomViewsMixin = (Base) => Base.extend({

	constructor () {
		Base.apply(this, arguments);
	},
	initializeCustomViews () {
		if (this._customViewsInitialized) return;
		this._customViews = [];

		// REMASTERED
		// let opts = this.options;
		// // if (!this.model && opts.model) {
		// // 	this.model = opts.model;
		// // }
		// //let own = this.getProperty('customViews', { deep:false }) || [];
		// let own = _.result(this,'customViews') || [];
		// let add = _.result(opts,'customViews') || [];
		// this._customViews = [...own, ...add];
		// //[].concat(own, add);

		this._customViewsInitialized = true;
	},
	addCustomView (arg) {
		this.initializeCustomViews();
		this._customViews.push(arg);
	},
	_getCustomViews () {
		this.initializeCustomViews();
		const views = this.getOption('customViews', { args: [this.model, this] }) || [];
		views.push(...this._customViews);
		// let views = _.clone(this._customViews) || [];
		views.reverse();
		return views;
	},
	addChildView (view, index, triggerShowChildren = true) {
		if (_.isArray(view)) {
			_(view).each((v) => this.addChildView(v, index, false));
			if (this.isRendered() && triggerShowChildren) { this._showChildren(); }
			return;
		}

		if (!view || view._isDestroyed) {
			return view;
		}


		if (index == null || index >= this.children.length) {
			// Honestly, i don't know what this for, but it seems that this should be improved for multiple added views;
			// this._addedViews = [view];
			this._addedViews || (this._addedViews = []);
			this._addedViews.push(view);
		}

		this._addChild(view, index);

		if (this.isRendered() && triggerShowChildren) { this._showChildren(); }

		return view;
	},
	render () {
		if (this._isDestroyed) { return this; }

		this._destroyChildren();

		this.children._init();

		this.triggerMethod('before:render', this);

		this._addCustomViews();

		if (this.collection) {
			this._addChildModels(this.collection.models);
		}

		this._showChildren();

		this._isRendered = true;

		this.triggerMethod('render', this);
		return this;
	},
	_addCustomViews () {
		const customs = this._getCustomViews(); // this.customViews || [];
		// customs.reverse();
		// console.log('before ~~~', customs);
		// if (Array.isArray(customs[0])) {
		// 	debugger;
		// }
		_(customs).each((view) => {
			// let view;

			if (_.isFunction(view)) {
				view = view.call(this);
			}

			if (_.isView(view)) {
				if (this.DEBUG) {
					// console.log('~~', view.cid, view.className);
				}
				// view.customIndex = view.length - ind;
				this.addChildView(view, null, false);
			}

			if (Array.isArray(view)) {
				// console.log('~~', view);
				const index = view[1];
				view = view[0];
				view.customIndex = index;
				this.addChildView(view, index, false);
			}

			// else if (_.isFunction(custom)) {
			// 	let view = custom.call(this);
			// 	if(_.isView(view)) {
			// 		view.customIndex = customs.length - ind;
			// 		this.addChildView(view, null, false);
			// 	}
			// }
		});
	}
});

export default CustomViewsMixin;
