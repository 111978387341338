import { _, BackboneCollection } from 'vendors';
import { FilterValueModel } from './FilterValueModel';

function hashToKeyArray (obj, key, transformItem) {
	return _.map(obj, (item, id) => {
		if (transformItem) {
			item = transformItem(item);
		}
		item = Object.assign({ [key]: id }, item);
		return item;
	});
}

export const FilterValuesCollection = BackboneCollection.extend({
	model: FilterValueModel,
	getRequestData () {
		return this.reduce((data, model) => {
			Object.assign(data, model.getRequestData());
			return data;
		}, {});
	},
	setFilters (data) {
		if (data == null || typeof data !== 'object') { return; }
		if (!Array.isArray(data)) {
			data = hashToKeyArray(data, 'id');
		}
		this.set(data, { merge: true });
	},
	setValues (data) {
		if (data == null || typeof data !== 'object') { return; }
		if (!Array.isArray(data)) {
			data = hashToKeyArray(data, 'id', value => ({ value }));
		}
		_.each(data, item => {
			const model = this.get(item.id);
			if (!model) { return; }
			model.set(item);
		});
	}
});
