module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="control-region"></div>\n';
 if(showConfirm) {
__p+='\n<div class="edit-actions">\n	';
 if(applyButton) {
__p+='<button class="apply">'+
((__t=( applyLabel ))==null?'':__t)+
'</button>';
 } 
__p+='\n	';
 if(cancelButton) {
__p+='<button class="cancel">'+
((__t=( cancelLabel ))==null?'':__t)+
'</button>';
 } 
__p+='\n	';
 if(resetButton) {
__p+='<button class="'+
((__t=( resetCssClass))==null?'':__t)+
'">'+
((__t=( resetLabel ))==null?'':__t)+
'</button>';
 } 
__p+='\n</div>\n';
 } 
__p+='\n';
}
return __p;
};
