import { _ } from 'vendors';
import busNotify from 'bus/notify';
import Obj from 'base/object';
import busData from 'bus/data';
import { SystemEvent } from './models';

busNotify.message = function (...args) {
	return busNotify.request('message', ...args);
};
busNotify.warn = function (...args) {
	return busNotify.request('warn', ...args);
};
busNotify.error = function (...args) {
	return busNotify.request('error', ...args);
};
busNotify.wait = function (...args) {
	return busNotify.request('wait', ...args);
};


export default Obj.extend({
	initialize (opts) {
		this.mergeOptions(opts, ['stack']);
	},
	channelName: busNotify.channelName,
	initRadioOnInitialize: true,
	radioEvents: {
		'app:event': 'processEvent',
		'system:event': 'processSystemEvent'
	},
	radioRequests: {
		'notify' (type, data, opts) {
			return this.processSystemEvent(data, opts, type);
		},
		'message' (data, opts, type = 'message') {
			return this.processSystemEvent(data, opts, type);
		},
		'warn' (data, opts, type = 'warning') {
			return this.processSystemEvent(data, opts, type);
		},
		'error' (data, opts, type = 'error') {
			return this.processSystemEvent(data, opts, type);
		},
		'wait' (data, opts, type = 'wait') {
			return this.processSystemEvent(data, opts, type);
		}
	},
	addToStackEvents: ['taskChecking', 'taskChecked', 'comment', 'taskCreated', 'taskResponsibleChanged'],
	processEvent (event) {
		if (busData.user().isMe(event.actor.eId)) return;
		if (this.addToStackEvents.indexOf(event.event) > -1) {
			this.addToStack(event, { parse: true });
		}
	},
	processSystemEvent (arg, opts = {}, type) {
		// debugger;
		if (_.isString(arg)) {
			arg = { text: arg };
		}
		if (arguments.length === 3) {
			type && (arg.type = type);
		} else if (arguments.length === 2) {
			opts && opts.type && (arg.type = opts.type);
		}
		const Model = opts.modelClass || SystemEvent;
		const note = new Model(arg);
		if (opts.viewClass) {
			note.viewClass = opts.viewClass;
		}
		return this.addToStack(note, { merge: true });
	},
	addToStack (event, opts = {}) {
		if (event.id) {
			const exist = this.stack.get(event.id);
			if (exist) {
				exist.set(event.attributes);
				return exist;
			}
		}
		return this.stack.add(event, opts);

		// if (event.id && this.stack.has(event.id)) {
		// 	opts.silent = true;
		// 	triggerChange = true;
		// }
		// let added = this.stack.add(event, opts);
		// if (added && triggerChange) {
		// 	added.trigger('change', added);
		// }
		// return added;
	}
});
