import Model from 'base/model';
import _ from 'underscore';
export const ProductModel = Model.extend({
	initialize () {
		this._newAmounts = {};
	},
	setNewAmount (batchId, newValue, batchModel) {
		if (newValue === undefined) {
			delete this._newAmounts[batchId];
		} else {
			this._newAmounts[batchId] = newValue;
		}
		this.trigger('batch:newAmount', this, batchId, newValue, batchModel);
	},
	getNewAmountsCount () {
		return _.size(this._newAmounts);
	},
	getUnchangedCount () {
		const arr = this.get('batches') || [];
		const changed = this.getNewAmountsCount();
		const unchanged = arr.length - changed;
		return unchanged;
	},
	hasNewAmounts () {
		return this.getNewAmountsCount() > 0;
	},
	hasNewAmount (batchid) {
		return this._newAmounts[batchid] >= 0;
	},
	hasUnchangedAmounts () {
		return this.getUnchangedCount() > 0;
	}
});
