import createNestedTasks from 'mod/tasks/views/entity-tasks';
import { buildTasksViewOptions } from 'mod/tasks/views/entity-tasks';

import TasksList from 'mod/tasks/views/list';

export function tasksBlock (view) {
	const team = view.model.getEmployees();
	createNestedTasks(view, {
		completedTasksEnabled: true,
		modelEvents: {
			'new:state' () {
				this.render();
			}
		},
		actionOptions: {
			responsibleIdOptions: {
				prependSegments: [
					{
						id: 'team',
						name: 'участники',
						filter: (v) => {
							return team.some(m => m.get('employeeId') === v.model.id);
						},
						collection: undefined
					}
				]
			}
		}
	});
}

export function tasksContent(view) {
	const team = view.model.getEmployees();
	const options = {
		completedTasksEnabled: true,
		modelEvents: {
			'new:state' () {
				this.render();
			}
		},
		actionOptions: {
			responsibleIdOptions: {
				prependSegments: [
					{
						id: 'team',
						name: 'участники',
						filter: (v) => {
							return team.some(m => m.get('employeeId') === v.model.id);
						},
						collection: undefined
					}
				]
			}
		}
	}
	return buildTasksViewOptions(view, options);
}

export function getLastFewTasks(tasks) {
	return {
		class: TasksList,
		shouldCreateCollection: true,
		tasks,
		emptyView: null
	}
}