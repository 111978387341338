import { _ } from 'vendors';
import Obj from 'base/object';
import processor from './change-processor';
import CollectionClass from 'base/collection';

import mix from 'helpers/mix';

const InitializeGroups = {

	initializeGroups () {
		if (this._groupsInitialized) return;

		const groups = this.getRawGroups();
		this._groups = this._buildGroups(groups);

		this._groupsInitialized = true;
	},

	getRawGroups () {
		return this.getOption('groups', { args: [this.context] });
	},

	setGroupId (group, id) {
		if (group.id == null) { group.id = id; }
	},

	_buildGroups (groups) {
		let i = 0;
		const initializedGroups = {};

		_(groups).each((group, key) => {
			if (group == null) return;
			const normalized = this._buildGroup(group, key, i);
			initializedGroups[this.getGroupId(normalized)] = normalized;
			i++;
		});

		return initializedGroups;
	},

	_buildGroup (_group, key, initialIndex) {
		if (_group == null) return;

		const group = _.clone(_group);

		if (!('initialIndex' in group)) { group.initialIndex = initialIndex; }

		if (!('index' in group) && _.isNumber(key)) { group.index = key; }

		this.setGroupId(group, (_.isString(key) && key) || `group${initialIndex}`);

		if (!group.collection) { group.collection = this._createGroupCollection(group); }

		return group;
	},

	_createGroupCollection (group) {
		const Collection = this.getOption('CollectionClass');
		const options = this.getOption('collectionOptions', { args: [this.context] });
		// if (group._debug && group.mistery) {
		// 	Collection = Collection.extend({
		// 		initialize(){
		// 		}
		// 	});
		// }
		return this.createGroupCollection(Collection, group, options);
	},
	createGroupCollection (Collection, group, options) {
		return new Collection([], _.extend({}, options, { group }));
	}
};

const SourceEvents = {

	_initializeListeners () {
		if (this._listenersInitialized) return;

		const source = this.getCollection();


		this.listenTo(source, 'update', this._onSourceUpdate);
		this.listenTo(source, 'reset', this._onSourceReset);
		this.listenTo(source, 'change', this._onSourceModelChange);

		this._listenersInitialized = true;
	},

	_onSourceUpdate (col, opts = {}) {
		const { removed, added, merged } = (opts.changes || {});

		const groups = this.getGroups();
		const groupBy = _.bind(this.groupBy, this);

		const globalRemoved = processor.modelsToHash(removed);
		const addContext = processor.addModels(groups, groupBy, added);
		const removeContext = processor.mergeModels(groups, groupBy, merged, addContext);

		const result = processor.setModels(groups, addContext, removeContext, globalRemoved);

		if (!result.added.length && (added || []).length) { result.removed.push(...added); }


		this._postProcessChange(result);
	},

	_onSourceReset (col, removed) {
		this.reset(col, removed);
		/*
		this._onSourceUpdate(col, {
			changes: {
				added:[],
				merged:[],
				removed
			}
		});
		*/
	},

	_onSourceModelChange (model) {
		const groupId = this.groupBy(model);
		const groups = this.getGroups();
		const addContext = {};
		const removeContext = {};
		processor.merge(model, groupId, groups, addContext, removeContext);

		const result = processor.setModel(groups, addContext, removeContext);

		if (!result.added.length && !result.removed.length) { result.removed.push(model); }



		this._postProcessChange(result);
	}
};

const Grouping = {

	groupBy (m) {
		const name = this.getOption('notFoundGroup');
		if (!this.isInContext(m)) return name;
		return this.getGroupId(this.getGroupByModel(m)) || name;
	},

	// check if model should be grouped at all
	isInContext: () => true,

	getGroupByModel (model) {
		const groups = this.getGroups();
		let foundGroup;
		_(groups).some((group) => {
			const test = this._getTestModelGroupMethod(group);
			const result = test(model, group);
			if (result) { foundGroup = group; }
			return result;
		});
		return foundGroup;
	},

	getGroups () {
		if (!this._groups) {
			this.initializeGroups();
		}
		return this._groups;
	},

	_getTestModelGroupMethod (group) {
		if (_.isFunction(group.testModel)) { return group.testModel; } else if (_.isFunction(this.testModel)) { return this.testModel; } else { return this._defaultTestModel; }
	},

	_defaultTestModel: () => false,


	getGroupId (group) {
		return group && group.id;
	},

	group (col) {
		col || (col = this.getCollection());
		const groupBy = _.bind(this.groupBy, this);
		const grouping = col.groupBy(groupBy);
		return this._postProcessGrouping(grouping);
	},

	_postProcessGrouping (grouping) {
		const notFoundGroup = this.getOption('notFoundGroup');
		const outOfContext = grouping[notFoundGroup] || [];

		if (this.getOption('shouldRemoveNotFoundGroup')) {
			delete grouping[notFoundGroup];
		}

		this.postProcessOut(outOfContext);
		return this.postProcess(grouping, outOfContext);
	},
	_postProcessChange (result) {
		const outOfContext = _(result.removed).filter(f => result.added.indexOf(f) < 0);
		this.postProcessOut(outOfContext);
	},
	// accepts: grouping, outOfContext array
	// returns: grouping hash {key:[models]}
	postProcess: grouping => grouping,
	postProcessOut: out => out

};

const MergeOptions = [
	'context',
	'collection',
	'initializeGroups',
	'getRawGroups',
	'setGroupId',
	'createGroupCollection',

	'groupBy',
	'isInContext',
	'getGroupByModel',
	'getGroups',
	'getGroupId',
	'testModel',
	'postProcess',
	'postProcessOut',

	'getCollection'
];

export default mix(Obj).with(InitializeGroups, SourceEvents, Grouping).extend({
	constructor (opts) {
		this.mergeOptions(opts, MergeOptions);
		Obj.apply(this, arguments);
		if (this.getOption('shouldGroupOnInitialize')) { this.init(); }
	},

	// group name for out of context models
	notFoundGroup: 'undefined',

	// post process option. leaves or removes notfound group from grouping
	shouldRemoveNotFoundGroup: true,

	// if true then immediately initialize groups and populate them with source data
	// if not then stays clean, awaiting first init() invoke
	shouldGroupOnInitialize: true,

	// for group collection
	CollectionClass,

	getCollection () {
		return this.collection;
	},

	init () {
		this.reset();
		this._initializeListeners();
	},
	reset (col) {
		const groups = this.getGroups();
		const grouping = this.group(col);
		processor.resetModels(groups, grouping);
	}

});
