import { _ } from 'vendors';
import WheelDownToScrollRight from 'mixins/view/wheel-scroll';

function reApplyMaxWidth () {
	let value;
	if (isNotWideEnough.call(this)) { value = (getScrollWidth.call(this) + 1) + 'px'; }

	this.$el.css('max-width', value);
}

function isNotWideEnough () {
	const scrollWidth = getScrollWidth.call(this);
	const width = this.$el.outerWidth();
	return scrollWidth > width;
}

function getScrollWidth () {
	return this.$el.get(0).scrollWidth;
}


export default (Base) => {
	const Mixed = WheelDownToScrollRight(Base);
	return Mixed.extend({
		widthBreakpoint: 1030,
		scrollDirection: 'right',
		dynamicClassConfig: {
			events: ['dom:refresh', 'refresh']
		},
		constructor () {
			Mixed.apply(this, arguments);
			this.cssClassModifiers = [].concat(this.cssClassModifiers || []);
			this.cssClassModifiers.push(
				() => 'flex-dynamic-column'
			);
			const fixMaxWidth = _.bind(reApplyMaxWidth, this);
			const baseEvents = ['dom:refresh', 'render:children', 'refresh', 'render'];
			const events = [].concat(baseEvents, this.getOption('fixMaxWidthOn') || []);
			this.on(events.join(' '), fixMaxWidth);
		}
	});
};
