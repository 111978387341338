import { _, $ } from 'vendors';
import Bb from 'backbone';
import Model from './model';
import paths from 'helpers/paths';
import cfg from '../config';
import store from 'helpers/store';

paths.set('api', 'token', cfg.tokenUrl, { prefix: '', version: false });

const nativeAjax = $.ajax;

const tokenizedAjax = function (...args) {
	let options;

	if (args && args.length === 1 && _.isObject(args[0])) {
		options = args[0];
	}
	if (args && args.length === 2 && !_.isObject(args[0]) && _.isObject(args[1])) {
		options = args[1];
	}

	options && (options.headers = _.extend({}, options.headers, this.getAjaxHeaders()));

	return nativeAjax.apply($, args);
};

const tokenXhr = function (data, url = paths.api('token'), method = 'POST') {
	return nativeAjax({ url, data, method });
};

const Token = Model.extend({
	constructor () {
		Model.apply(this, arguments);
		this.reflectTokenChanges();
		this.on('all', (event, ...args) => this._tryInvokeWhenEvent(event, args));
	},

	tokenAttribute: 'access_token',
	refreshTokenAttribute: 'refresh_token',

	parse (data) {
		if (data == null) return data;
		if (data != null && _.isObject(data)) {
			data.issued = new Date(data.issued_json);
			if (isNaN(data.issued.valueOf())) { data.issued = new Date(); }
			data.expires = new Date(data.expired_json);
			if (isNaN(data.expires.valueOf())) { data.expires = new Date(data.issued.valueOf() + parseInt(data.expires_in, 10) * 1000); }

			this.fixTime(data);
		}


		return data;
	},

	fixTime (data) {
		let ttl = data.expires - data.issued;
		if (_.isNaN(ttl)) { ttl = 120000; }
		const now = new Date();
		const localExpires = new Date(now.valueOf() + ttl);
		const diff = Math.abs(now - data.issued);
		if (diff > 50000) {
			data.issued = now;
			data.expires = localExpires;
		}
	},

	hasToken () {
		return !!this.getToken();
	},
	getToken () {
		return this.get(this.tokenAttribute);
	},
	getRefreshToken () {
		return this.get(this.refreshTokenAttribute);
	},
	getRefreshData () {
		return {
			grant_type: 'refresh_token',
			refresh_token: this.getRefreshToken()
		};
	},
	isExpired () {
		const d = this.getExpires();
		if (!d || isNaN(d.valueOf())) return true;
		return (d.valueOf() - Date.now()) < 20000;
	},
	getExpires () {
		return this.get('expires');
	},
	getIssued () {
		return this.get('issued');
	},


	fetch (data) {
		if (this._fetching) return this._fetching;

		// delete this.fetchingResult;
		// this.triggerMethod('request',data);

		if (!this._lastFetchTimeCount) this._lastFetchTimeCount = 0;

		if (!this._lastFetchTime || this._lastFetchTimeCount < 4) {
			this._lastFetchTime = new Date();
			this._lastFetchTimeCount++;
		} else {
			if (new Date() - this._lastFetchTime < 15000) {
				throw new Error('Проверьте настройки времени на компьютере');
			} else {
				this._lastFetchTimeCount = 0;
			}
		}

		this._fetching = new Promise((resolve) => {
			tokenXhr(data).then(
				(json) => {
					const parsed = this.parse(_.extend({}, json));
					this.set(parsed, { flat: true });
					delete this._fetching;
					return resolve(json);
				},
				(xhr) => {
					delete this._fetching;
					this.clear();
					return resolve(xhr);
				});
		});
		return this._fetching;
		// .always((xhr) => this.triggerMethod('request:end', xhr));
	},
	login (username, password, clientId, scope) {
		return this.fetch({ grant_type: 'password', username, password, scope: `${scope} ${clientId}` });
	},
	refresh (opts = {}) {
		if (!this.isExpired() && !opts.force) { return Promise.resolve(); }
		return this.fetch(this.getRefreshData());
	},

	triggerMethod (event, ...args) {
		const method = _.camelCase('on:' + event);
		let result;
		if (_.isFunction(this[method])) { result = this[method](...args); }
		this.trigger(event, ...args);
		return result;
	},
	_tryInvokeWhenEvent (event, args) {
		const method = _.camelCase('when:' + event);
		if (_.isFunction(this[method])) { return this[method](...args); }
	},
	whenChange () {
		this.reflectTokenChanges();
	},
	reflectTokenChanges () {
		this.updateAjaxHeaders();
		this.replaceBackboneAjax();
		this.storeToken();
		this.trigger('changed');
	},
	storeToken () {
		if (this.hasToken()) { store.set('bearer:token', this.toJSON(), { days: 1 }); } else { store.expire('bearer:token'); }
	},


	getAjaxHeaders () {
		this.ajaxHeaders || (this.ajaxHeaders = {});
		return this.ajaxHeaders;
	},
	replaceBackboneAjax () {
		if (!this.hasToken()) { Bb.ajax = nativeAjax; } else { Bb.ajax = (...args) => this.ajax(...args); }
	},
	updateAjaxHeaders (token) {
		token || (token = this.getToken());
		const headers = this.getAjaxHeaders();
		if (token) {
			headers.Authorization = 'Bearer ' + token;
			headers.Accept = 'application/json';
		} else {
			delete headers.Authorization;
		}
	},
	getAuthorizeHeaderValue () {
		const token = this.getToken();
		if (!token) { return; }
		return 'Bearer ' + token;
	},
	ajax (...args) {
		return this.refresh().then(
			() => tokenizedAjax.apply(this, args),
			error => error
		);
		// return this.ensureToken(...args)
		// 	.then(() => tokenizedAjax.apply(this, args))
		// 	.catch((error) =>{
		// 		return error;
		// 	});
	},
	ensureToken () {
		if (!this.isExpired()) return Promise.resolve();

		return this.refresh();
	},


	init () {
		if (this.initPromise) return this.initPromise;

		if (!this.hasToken()) { return Promise.resolve(); }

		this.initPromise = this.refresh({ force: true }).then(
			() => {},
			() => { this.clear(); }
		);
		return this.initPromise;
	}

});

export default Token;
