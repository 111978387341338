import { _, $ } from 'vendors';
import Yat from 'marionette.yat';
import Bb from 'backbone';
import AppLayout from './layout/layout';

import bus from '../bus';
import cfg from '../config';

import getEventFields from 'helpers/get-event-fields';
import smartOpen from 'helpers/smart-open';
import AppError from 'base/error';
import PageErr from 'pages/error';

import Collection from 'base/collection';

import appData from 'modules/app-data/index';

import token from './token';
import user from './user';

import preloader from 'helpers/modals/preloader';
import mix from 'helpers/mix';

import sockets from 'components/sockets';

import './app-events-handler';

import notifier from 'helpers/notifier';

const IdentityBehavior = {
	initIdentity () {
		if (!cfg.release) {
			window.token = token;
			window.user = user;
		}

		this.addStartPromise(user.init());
		this.listenTo(user, 'change', this._onUserChanged);
	},
	_onUserChanged () {
		this.createLinksCollection();
		if (this.lastStartAttempt && !this._lastOneRestart) {
			this._lastOneRestart = this.lastStartAttempt.restart().then(
				(arg) => { delete this._lastOneRestart; return arg; },
				(arg) => { delete this._lastOneRestart; return arg; }
			);
		}
	}
};

const PagesBehavior = {
	initPages () {
		this.initClickHandler();
	},
	initClickHandler () {
		// var selector = 'a[href]:not([href=""]):not([href|="javascript:"]):not([href|="#"]):not([target]):not([data-skiproute="true"])';
		const selector = 'a[href]';

		$(document).on('click', selector, function (event) {
			const options = getEventFields(event);
			options.fromUrl = true;

			const $a = $(this);
			const href = $a.attr('href');

			const pat = /^(\w+:)?\/\//gi;
			if (pat.test(href) || href.startsWith('data:')) {
				return;
			}
			if (
				href.startsWith('mailto:') ||
				href.startsWith('tel:') ||
				href.startsWith('javascript:') ||
				$a.data('skiproute') ||
				(
					$a.attr('target') &&
					$a.attr('target') !== '_self'
				)
			) {
				return;
			}

			const maybeRoute = (cfg.pushState && /^[?/a-z0-9]+/i.test(href)) ||
							(!cfg.pushState && /^#[/a-z0-9]+/i.test(href));

			if (event.ctrlKey) {
				event.stopPropagation();
				return;
			}

			if (maybeRoute) {
				event.preventDefault();
				event.stopPropagation();
				smartOpen(href, options);
			}
		});
	},

	showLoginOrDenied (page, actionContext) {
		actionContext.executeOptions = {};
		if (Yat.identity.isAnonym()) {
			actionContext.executeOptions = { contentOptions: { header: 'Вы не авторизованы' } };
			this.executePage('acc/login', actionContext);
		} else { this.executePage('!NotAuthorized', actionContext); }
	},


	onPageError (page, error) {
		if (error.status == null) throw error;
	},
	onPageError400 (page, error, actionContext) {
		this.executePage('Error400', actionContext, error.responseJSON);
	},
	onPageError401 (page, error, actionContext) {
		this.showLoginOrDenied(page, actionContext);
	},
	onErrorToken (page, error, actionContext) {
		this.showLoginOrDenied(page, actionContext);
	},
	onPageError404 (page, error, actionContext) {
		actionContext.executeOptions = { headerOptions: { header: 'Страница не найдена' } };
		this.executePage('*NotFound', actionContext);
	},
	onPageError0 (page, error, actionContext) {
		actionContext.executeOptions = { headerOptions: { header: 'Страница не найдена' } };
		this.executePage('*NotFound', actionContext);
	},
	onPageErrorRedirect (page, error) {
		this.navigate(error.message);
	},



	onPageStartBegin (page, actionContext) {
		if (actionContext.routeType !== 'execute') { this.lastStartAttempt = actionContext; }
	},
	onPageStart (page, actionContext) {
		const deniedContext = this.lastStartAttempt && this.lastStartAttempt.page !== page && this.lastStartAttempt.error && this.lastStartAttempt.error.status !== 'redirect' && this.lastStartAttempt;
		this.currentStartedPage = page;
		this.showPage(page, actionContext, deniedContext);
		// this.triggerMethod('page:swap');
	},
	showPage (page, actionContext, deniedContext) {
		this.rootLayout.showPage(page, actionContext, deniedContext);
		this.triggerMethod('page:swap');
	},


	getLinksCollection ({ rebuild = false } = {}) {

		if (this._menuTree && !rebuild) return this._menuTree;

		const links = this.getLinks();

		if (!this._menuTree) {
			this._menuTree = new Collection(links);
		}

		if (rebuild) {
			this._menuTree.set(links);
		}

		return this._menuTree;
	},
	getChildren () {
		return Yat.App.prototype.getChildren.apply(this, arguments);
	},
	getLinks () {
		const children = this.getChildren();
		const links = _(children).chain().map((c) => {
			const links = c.getLinks();
			// console.log('==', c, links);
			return links;
		}).flatten().value();
		links.forEach(link => {
			link.isUrlParametrized = /:\w/.test(link.url);
		});
		return links;
	},
	createLinksCollection () {
		const links = this.getLinks();

		if (!this._menuTree) {
			this._menuTree = new Collection(links);
		} else {
			this._menuTree.set(links);
		}
	},



	navigate (url, opts = {}) {
		_.extend(opts, { trigger: true });
		Bb.history.navigate(url, opts);
	},
	openPage (url, opts = {}) {
		if (!opts.altKey && !opts.ctrlKey) {
			this.navigate(url);
		} else if (opts.altKey) {
			url = url.startsWith('/') || url.startsWith('#') ? url.substring(1) : url;
			const page = this.getPageByUrl(url);
			if (page && !this.isCurrentPage(page)) {
				page.openInModal(url, opts);
			}
		} else if (opts.ctrlKey) {
			!cfg.pushState && !url.startsWith('#') && (url = '#' + url);
			cfg.pushState && !url.startsWith('/') && (url = '/' + url);

			const win = window.open(url, '_blank');
			win.focus();
		}
	},
	executePage (fragment, ...args) {
		const context = this.getRouteContext(fragment);
		if (context) { context.callback(fragment, { routeType: 'execute', executeArgs: args }); } else { throw PageErr.NotFound('Route not found'); }
	},
	getRouteContext (fragment) {
		const routers = this.getRouters();
		if (!routers.length) { throw new AppError({ message: 'Router not found' }); }
		let context;
		_(routers).some((f) => {
			context = f.getContextByFragment(fragment);
			return !!context;
		});
		return context;
	},
	getRouters () {
		const children = this.getChildren() || [];
		return children.map((f) => f.router).filter((f) => !!f);
	},
	getPageByUrl (url) {
		const cntx = this.getRouteContext(url);

		return cntx && cntx.page;
	},
	isCurrentPage (page) {
		return !!this.lastStartAttempt && this.lastStartAttempt.page === page;
	},
	getCurrentPage () {
		return this.lastStartAttempt && this.lastStartAttempt.page;
	}
};

const AppRadioBehavior = {
	channel: bus.app,
	radioRequests: {
		user () {
			return user;
		},
		menu (opts = {}) {
			return this.getLinksCollection(opts);
		},
		open (url, opts = {}) {
			return this.openPage(url, opts);
		},
		navigate (...args) {
			this.navigate(...args);
		},
		'app:version' () {
			const version = this.getOption('version') || {};
			let str = 'v' + version.version;
			if (version.release !== true) { str += ', debug'; }
			return str;
		},
		'go:up' () {
			const page = this.getCurrentPage();
			if (!page) return;
			const link = page.getParentLink();
			if (!link) this.navigate('/');
			this.openPage(link.url);
		},
		'current:page' () {
			return this.currentStartedPage;
		}
	}
};

// AppRadioBehavior, PagesBehavior, IdentityBehavior
const BaseApp = mix(Yat.App).with(PagesBehavior, AppRadioBehavior, IdentityBehavior);
const App = BaseApp.extend({
	initialize () {
		this.initPages();

		this.initLayout();

		this.initIdentity();

		this.addStartPromise(appData.whenReady());

		if (!cfg.release) {
			window.app = this;
		}
	},
	initLayout () { this.rootLayout = new AppLayout({ app: this }); },
	onBeforeStart () {
		// token.init();
		// this.addStartPromise();
		// this.addStartPromise(user.init());

		// token.init();


		// var test = new Bb.Model();
		// test.url = 'http://api.palma-med.loc/auth/token-shmoken';
		// let xhr = test.fetch();

		// appData.fetch();
		// this.addStartPromise(appData.fetchIfNot());
		// this.addStartPromise(appData.whenReady());

	},
	onStart () {
		notifier();

		Bb.history.start({ trigger: true, pushState: cfg.pushState });

		user.whenAuth().then(
			() => sockets.start(),
			() => {}
		);
	},
	// getLinksCollection () {
	// 	debugger;
	// 	return BaseApp.prototype.getLinksCollection.apply(this, arguments);
	// },
	freezeWhileStarting: true,
	freezeUI () {
		this.freezeView = preloader();
	},
	unFreezeUI () {
		if (this.freezeView) { this.freezeView.destroy({ force: true }); }
	}
});

export default App;
