import { _ } from 'vendors';
import appData from 'mod/appData';

export function internalConvert(hash, Collection, Model, opts = {}) {

	const modules = appData.getModules();

	const items = _.map(modules, (value, module) => {

		if (!hash[module]) { 
			hash[module] = '';
		}

		const item = { id: module, value: hash[module], enum: value.rightsType };

		if (opts.asModels) {
			return new Model(item);
		} else {
			return item;
		}

	});


	if (opts.asCollection) { 
		return new Collection(items, { principalId: opts.principalId }); 
	} else { 
		return items; 
	}

}


