import {
	mainBlock, actionsBlock, stagesBlock, goalsBlock, checklistBlock, activityBlock, tasksBlock, teamBlock,
	secondInfoBlock
} from '../common-blocks';

import { createNestedProductsToBuy, createNestedDeliveryDetails, createNestedPaymentDetails } from 'mod/processes/buy';
import createNestedContragent from 'mod/contragents/views/nested/create';


export function initializeBuyLayout (view) {
	
	const contragent = view.model.getContragent({ fetch: true });
	mainBlock(view);
	createNestedContragent(view, contragent);
	createNestedPaymentDetails(view);
	createNestedDeliveryDetails(view);
	createNestedProductsToBuy(view);
	actionsBlock(view);
	stagesBlock(view);
	goalsBlock(view);
	checklistBlock(view);
	activityBlock(view);
	tasksBlock(view);
	teamBlock(view);
	secondInfoBlock(view);
}
