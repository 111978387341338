import { _ } from 'vendors';
import BaseView from 'base/view';
import ModelProperties from 'components/ui-model-card/properties';
import h from 'components/ui-model-card/helpers';
import Task from '../models/model';
// import busData from 'bus/data';
// import InputPlaceholdered from 'components/controls/input-placeholdered';
// import { AddressInput } from 'components/controls/contact-value';
import ControlMixin from 'components/controls/mixin';
import { PlaceholderedTextarea } from 'components/controls/textarea';
// import enums from 'helpers/enums';
// import editValue from 'bus/edit';
import { TabTriggersView } from 'components/content-tabs';
import ToDoBlock from './todo';

import RequestTransport from '../models/requestTransport-model';

// import EditTransportPoint from './transportRequest/editTransportPoint';
import EditTransportPoints from './transportRequest/editTransportPoints';


const defs = { editButton: true, addClass: 'whole-btn', textAction: 'edit' };

/*

const TypeButton = ControlMixin(BaseView).extend({
	template: _.template('<button><i></i><small><%= type %></small></button>'),
	cssClassModifiers:[
		(m,v) => v.value
	],
	initialize(){
		this.value = _.clone(this.getOption('value'));
	},
	templateContext(){
		return {
			type: enums.get('employeeTaskTypes', this.value),
		};
	},
	events:{
		'click'(){
			editValue.do({
				valueType: 'enum',
				multiple: false,
				controlType:'select',
				value: this.value,
				sourceValues: enums.store.employeeTaskTypes
			}).then(
				type => {
					this.value = type;
					this.triggerChange();
					this.render();
				},
				() => {}
			);
		}
	}
});

*/

const MainProps = ControlMixin(BaseView).extend({
	ignoreModel: true,
	className: 'main-tab-place',
	initialize () {
		this.taskModel = this.model;
		delete this.model;
		const reqModel = this.requestModel = this.getOption('requestTaskModel');
		this.value = reqModel.toJSON();
	},
	template: _.template(`
	<!-- <div class="flex-line">
		<div class="todo-place"></div>
		<div class="type-place"></div>
	</div> -->
	<div class="props-place card lined with-border"></div>
	`),
	regions: {
		todo: '.todo-place',
		type: '.type-place',
		props: '.props-place'
	},
	onRender () {
		const editView = new EditTransportPoints({ proxyTo: this, controlName: 'request', model: this.requestModel });
		this.showChildView('props', editView, { replaceElement: true });
	},
	controlsEvents: {
		'request:change' (data) {
			this.value.points = data;
			this.triggerChange();
		},
		'request:validate:error' () {
			this._triggerEvent('validate:error');
		}
	}
});


const AddProps = ControlMixin(BaseView).extend({
	template: _.template('<div class="dates-place"></div><div class="desc-place"></div><div class="checklist-place"></div>'),
	regions: {
		desc: '.desc-place',
		checklist: '.checklist-place',
		dates: '.dates-place'
	},
	onRender () {
		const dates = new ModelProperties({
			className: 'props-place card lined with-border',
			model: this.model,
			modelEvents: {
				'change:responsibleId' () {
					this.render();
				}
			},
			properties: [
				h.ui('dates.remindAt', _.extend({ condition: v => !v.model.get('responsibleId') || v.model.isMy() }, defs)),
				h.ui('dates.deadline', _.extend({}, defs))
			]
		});
		this.showChildView('dates', dates);


		const desc = new PlaceholderedTextarea({
			proxyTo: this,
			controlName: 'description',
			value: this.model.get('description'),
			label: 'описание задачи'
		});
		this.showChildView('desc', desc);

		const checklist = new ToDoBlock({
			model: this.model,
			header: 'Заметки',
			modelEvents: {
				'new:state' () {
					this.render();
				}
			},
			rights: {
				change: true,
				delete: true
			},
			canBeSwapped () {
				return true;
			}
		});
		this.showChildView('checklist', checklist);
	},
	controlsEvents: {
		'description:change' (value) {
			const model = this.model;
			model.set('description', value);
			this.triggerChange();
		}
	}
});

const JoinsProps = ModelProperties.extend({
	className: 'card lined with-border',
	properties: [
		h.ui('contragentId', _.extend({}, defs))
		// h.ui('processId', _.extend({}, defs) ),
	]
});


export default ControlMixin(BaseView).extend({
	initialize () {
		// let remindAt = (new Date()).addMinutes(10);
		if (!this.model) {
			// let myId = busData.user().getIdentityId();
			// responsibleId: myId,
			const hash = _.extend({ dates: { }, form: 'transportRequest' }, this.getOption('value')) || {};
			this.model = new Task(hash);
		}
		// this.listenTo(this.model, 'change:responsibleId', () => {
		// 	if (this.model.get('responsibleId') == null) {
		// 		this.model.set('responsibleId', busData.user().getIdentityId());
		// 	}
		// 	let rid = this.model.get('responsibleId');
		// 	if (busData.user().isMe(rid)) {
		// 		this.model.set('checkerId', null);
		// 	} else if(!this.model.get('checkerId')) {
		// 		this.model.set('checkerId', myId);
		// 	}
		// });
	},
	className: 'create-task',
	template: _.template('<div class="tabs"></div><div class="content"></div>'),
	regions: {
		content: '.content',
		tabs: '.tabs'
	},
	onTab (model) {
		this.showTab(model);
	},
	showTab (model) {
		const View = this.getOption(_.camelCase('tab:' + model.id));
		const options = this.getTabOptions(model);
		if (!View) return;

		const tabView = new View(options);
		this.showChildView('content', tabView);
	},
	getTabOptions (model) {
		const defOpts = {
			controlName: model.id,
			proxyTo: this,
			model: this.model
		};
		let options = this.getOption(_.camelCase('tab:' + model.id + ':options'), { args: [defOpts] });
		options = _.extend(defOpts, options);
		return options;
	},
	tabMain: MainProps,
	tabMainOptions () {
		if (!this.requestTaskModel) {
			this.requestTaskModel = new RequestTransport();
		}
		return {
			requestTaskModel: this.requestTaskModel
		};
	},
	tabAdd: AddProps,
	tabJoins: JoinsProps,
	onRender () {
		const tabsHash = {
			main: 'основное',
			add: 'дополнительно',
			joins: 'привязки'
		};
		if (this.getOption('parent')) {
			delete tabsHash.joins;
		}
		const tabs = new TabTriggersView({
			tabs: tabsHash,
			activeTab: 'main',
			triggerOn: this
		});
		this.showChildView('tabs', tabs);
		this.showTab(tabs.getCurrentTab());
	},
	controlsEvents: {
		'main:change' () {
			// this.model.set({
			// 	toDo: 'заявка на транспорт: ',
			// 	typeDetailsPost: value,
			// });
			// this.triggerChange();
			const changes = {
				typeDetailsPost: this.requestTaskModel.toJSON(),
				toDo: this.requestTaskModel.getTodoString()
			};
			this.model.set(changes);

			this.toggleLock();
		},
		'main:validate:error' () {
			this.model.set({
				typeDetailsPost: undefined,
				toDo: undefined
			});
			// this.model.set({
			// 	toDo: undefined,
			// 	typeDetailsPost: undefined,
			// });

			this.toggleLock();
		},
		'add:change' () {
			this.triggerChange();
		}
	},
	toggleLock () {
		const model = this.model;
		const valid = !!model.get('toDo') && !!model.get('typeDetailsPost'); // && !!model.get('dates.deadline') && !!model.get('responsibleId');
		if (valid) {
			this.trigger('switch:resolve', 'unlock');
		} else {
			this.trigger('switch:resolve', 'lock');
		}
		return valid;
	}

});
