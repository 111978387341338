import { _ } from 'vendors';

function removeAttribute (el, name, namespace) {
	if (namespace) {
		el.removeAttributeNS(namespace, name);
	} else {
		el.removeAttribute(name);
	}
}

function setAttribute (el, name, value, namespace) {
	if (namespace) {
		el.setAttributeNS(namespace, name, value);
	} else {
		el.setAttribute(name, value);
	}
}

function take (arg, index) {
	if (Array.isArray(arg)) {
		return arg[index];
	}
	return !index ? arg : undefined;
}

export const attributesMixin = {
	setElAttributes (hash, merge) {
		if (!hash) { return; }
		_.each(hash, (arg, key) => {
			const value = take(arg, 0);
			const namespace = take(arg, 1);
			if (value == null) {
				removeAttribute(this.el, key, namespace);
			} else {
				setAttribute(this.el, key, value, namespace);
			}
		});
	},
	setElAttribute (name, value, namespace) {
		const merge = true;
		if (namespace) {
			this.setElAttributes({ [name]: [value, namespace] }, merge);
		} else {
			this.setElAttributes({ [name]: value }, merge);
		}
	},
	setElTitle (value) {
		this.setElAttribute('title', value);
	},
	initializeTagTitle () {
		const title = this.getOption('tagTitle', true);
		if (title) {
			this.setElAttribute('title', title);
		}
	}
};
