import paths from './paths';
import token from 'app/token';
import 'mods/medias/api-config';

export function buildMediaUrl (mediaInfo, options = {}) {
	if (!mediaInfo) return '';
	let url;
	if (mediaInfo.entityMediaId) {
		url = paths.api('medias', mediaInfo.entityType || 'undef', mediaInfo.entityId || 'undef', mediaInfo.entityMediaId, options.option);
	}

	if (options.addToken) {
		url += '?tkn=' + token.getToken();
	}

	return url;
}
