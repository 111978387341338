// import user from './user';
import token from './token';
import busEvents from 'bus/app-events';

const handler = busEvents.request('Handler', {
	radioEvents: {
		'claimsChanged' () {
			token.refresh();
			// token.refresh().then(() => user.fetch());
		}
	}
});



export default handler;
