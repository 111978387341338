import { _ } from 'vendors';
import View from 'base/page-view';
import Deps from './departments';
import Dep from './department';
import collection from 'mod/departments/singleton';

export default View.extend({
	className: 'departments-tree',
	template: _.template('<div></div'),
	regions: {
		entries: { el: 'div', replaceElement: true }
	},
	onRender () {
		this.showEntries();
	},
	showEntries () {
		const view = new Deps({
			childView: Dep,
			cssClassModifiers: ['tree-container'],
			collection
		});
		this.showChildView('entries', view);
	}
});
