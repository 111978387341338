import { CommentEntryModel } from './comment-entry-model';
import Collection from 'base/collection';
import PagedCollection from 'base/paged-collection';
import busModels from 'bus/models';

export const BackendLogsCollection = PagedCollection.extend({
	model: CommentEntryModel,
	url () {
		return this.parent.url() + '/logs';
	}
});
busModels.reply('logs-v2', () => BackendLogsCollection);

export const EntriesLogsCollection = Collection.extend({
	model: CommentEntryModel
});
