// import { _ } from 'vendors';
// import BaseView from 'base/view';
// import CollectionView from 'base/collection-view';
// import Collection from 'base/collection';

// import { ReportLayout /*, getReportSchema, ReportCollection */ } from 'mods/reports';
// import viewManagedCollectionMixin from '../../../mixins/view-managed-collection';

// const entriesManagerOptions = {
// 	infoOptions: false,
// 	filtersOptions: {
// 		id: 'page:report:purchase', // + reportSchema.name,
// 		getRaw: () => [{ id: 'period', to: new Date(), from: new Date() }], // reportSchema.filters || [],
// 		instantApply: true
// 	},
// 	actionsEnabled: true
// };


// const ItemView = BaseView.extend({
// 	template: _.template('<%= productName %>'),
// 	templateContext () {
// 		const attrs = this.model.attributes;
// 		return {
// 			name: attrs.shortName || attrs.fullName
// 		};
// 	}
// });

// const ItemsView = CollectionView.extend({
// 	childView: ItemView,
// 	childViewOptions () {
// 		return {
// 			type: this.getOption('type')
// 		};
// 	},
// 	initialize () {
// 		const items = this.getOption('items');
// 		this.collection = new Collection(items);
// 	}
// });

// const ReportEntries = BaseView.extend({
// 	template: _.template('<div class="accepted"></div><div class="awaited"></div>'),
// 	regions: {
// 		awaited: '.awaited',
// 		accepted: '.accepted'
// 	},
// 	onRender () {
// 		this.showItems('accepted');
// 		this.showItems('awaited');
// 	},
// 	showItems (type) {
// 		const view = new ItemsView({
// 			type,
// 			items: this.model.get(type)
// 		});
// 		this.showChildView(type, view);
// 	},
// 	modelEvents: {
// 		change: 'render'
// 	}
// });


// const collectionMixin = {
// 	updateResult: function (data) {
// 		if (!data || _.isArray(data)) return;
// 		const hash = {
// 			skiped: data.skiped,
// 			founded: data.founded,
// 			returned: data.returned,
// 			endOfData: true
// 		};
// 		this.result.set(hash);
// 	},
// 	parse: function (data) {
// 		data.id = 1;
// 		return [data];
// 	}
// };

// export const __Layout = ReportLayout.extend({
//     // template: _.template('report will be here'),
// 	_getEntriesView () {
//         // let reportSchema = this.getOption('reportSchema');
//         // if (!reportSchema) {
//         //     throw new Error('reportSchema was not provided');
//         // }
//         // let entriesViewOptions = this.getOption('entriesViewOptions') || {};
//         // let view =  new EntriesView({

//         //     fetchNextDisabled: this.getOption('fetchNextDisabled'),
//         //     viewComparator: stringComparer,
//         //     collection: this.collection,
//         //     reportSchema,
//         //     //entriesManager: this.entriesManager,
//         //     //filtersInstance: this.filtersInstance,
//         //     entriesManagerOptions: {
//         //         infoOptions: true,
//         //         filtersOptions: {
//         //             id: 'page:report:' + reportSchema.name,
//         //             getRaw: () => reportSchema.filters || [],
//         //             instantApply: true,
//         //         },
//         //         actionsEnabled: true,
//         //     },
//         //     ...entriesViewOptions
//         // });

// 		// const view = new ThisEntries({ template: _.template('entries view') });
// 		// return view;

// 	},
// 	buildEntriesView (View, options) {
// 		const { collection /*, entriesManagerOptions, reportSchema */ } = options;

// 		Object.assign(collection, collectionMixin);

// 		options.childView = ReportEntries;

// 		console.log('--- >>>', View, options);
// 		return new View(options);
//         // delete options.collection;
//         // return new ThisEntries({
//         //     childView: ReportEntries,
//         //     collection, entriesManagerOptions, reportSchema
//         // });
// 	}
// });

import { ReportLayout } from 'mods/reports';
export const Layout = ReportLayout;
