import BaseView from 'base/view';
import template from './layout.html';
import mix from 'helpers/mix';
import ControlsMixin from 'components/controls/mixin';
import confirm from 'helpers/modals/confirm';
import modalError from 'helpers/modals/error';

const EditView = mix(BaseView).with(ControlsMixin).extend({
	_type: 'editValue',
	className: 'edit-value-control',
	template,
	regions: {
		control: { el: '.control-region', replaceElement: true }
	},
	destroyOnAction: true,
	initialize (opts = {}) {
		// if (opts.addClassName) {
		// 	(this.className || '') + ' ' + opts.addClassName;
		// }

		this.mergeOptions(opts, ['getValue']);
		this.prepareInitialValues();

		if (this.isNotMultiple() && !this.isConfirmButtons() && this.getOption('destroyOnChange') == null) {
			this.destroyOnChange = true;
		}

		this.promise = new Promise((resolve, reject) => {
			this.resolvePromise = resolve;
			this.rejectPromise = reject;
		});
	},
	_getPreloaderContext () {
		const modal = this.inModal;
		if (!modal) { return this; }

		if (modal.ui.box.length) { return modal.ui.box; } else if (modal.ui.contentWrapper.length) { return modal.ui.bocontentWrapperx; }

		return this;
	},
	onRender () {
		this.showControl();
		this.trigger('content:ready');
	},
	showControl () {
		const View = this.getOption('controlView');
		const options = this.getOption('controlViewOptions');
		const controlName = this.getOption('controlName');
		if (!options.controlName && controlName) {
			options.controlName = controlName;
		} else if (options.controlName) {
			this._childControlName = options.controlName;
		}

		options.proxyTo = this;
		// this.childControlName = options.controlName
		const view = this.control = new View(options);

		this.showChildView('control', view);
	},
	controlsEvents () {
		const name = this._childControlName || this.getOption('controlName') || 'control';
		const eventChange = `${name}:change`;
		const eventDone = `${name}:done`;
		const eventValidateError = `${name}:validate:error`;
		return {
			[eventChange] (...args) {
				this.enableApplyButton();
				this.value = args[0];
				this.triggerChange(...args);

				if (this.getOption('applyOnChange') || this.destroyOnChange) {
					this.triggerApply();
				}
			},
			[eventDone] (...args) {
				this.triggerChange(...args);
				if (this.getOption('applyOnChange') || this.destroyOnChange) {
					this.triggerApply();
				}
				// this.triggerApply();
			},
			[eventValidateError] () {
				this.disableApplyButton();
			}
		};
	},
	isConfirmButtons () {
		return !this.getOption('noConfirm');
	},
	onChildviewControlValidateError () {
		this.disableApplyButton();
	},
	enableApplyButton () {
		this.$('> .edit-actions button.apply').prop('disabled', false);
	},
	disableApplyButton () {
		this.$('> .edit-actions button.apply').prop('disabled', true);
	},
	templateContext () {
		return {
			applyLabel: this.getOption('applyLabel') || 'применить',
			cancelLabel: this.getOption('cancelLabel') || 'вернуть предыдущее значение',
			resetLabel: this.getOption('resetLabel') || 'сбросить значение',
			cancelButton: this.getOption('cancelButton') !== false,
			resetButton: this.getOption('resetButton') !== false,
			applyButton: this.getOption('applyButton') !== false,
			showConfirm: this.isConfirmButtons(),
			resetCssClass: ('reset ' + (this.getOption('resetButtonCssClass') || '')).trim()
		};
	},
	events: {
		'click .apply' (e) { e.stopPropagation(); this.triggerApply(); },
		'click .cancel' (e) { e.stopPropagation(); this.triggerCancel(); },
		'click .reset' (e) {
			e.stopPropagation();
			const confirmText = this.getOption('resetButtonConfirm');
			if (!confirmText) { this.triggerReset(); } else {
				confirm(confirmText).then(
					() => this.triggerReset(),
					() => {}
				);
			}
		}
	},
	broadcastAction (action, value) {
		this.triggerMethod('action', action);
		this._triggerEvent(action, value);
		this._triggerEvent('done', action, value);
	},
	triggerApply () {
		const value = this.getValue();
		console.log('APPLYING >', value);
		const beforeApply = this.getOption('beforeApply', { args: [value, { preloader: this._getPreloaderContext() }] });
		Promise.all([beforeApply]).then(
			() => {
				this.actionTaken = 'apply';
				this.resolvePromise(value);
				this.broadcastAction(this.actionTaken, value);
			},
			(xhr) => modalError('произошла ошибка', xhr)
		);
	},
	triggerCancel () {
		this.actionTaken = 'cancel';
		console.log('canceled ->', this.initialValue);
		this.rejectPromise({ reason: this.actionTaken, value: this.initialValue });
		this.broadcastAction(this.actionTaken, this.initialValue);
	},
	triggerReset () {
		this.actionTaken = 'reset';
		this.rejectPromise({ reason: this.actionTaken, value: this.resetValue });
		this.broadcastAction(this.actionTaken, this.resetValue);
	},
	onAction () {
		const shouldDestroy = this.actionTaken && !this.destroying && this.getOption('destroyOnAction');
		if (shouldDestroy) { this.destroy(); }
	},
	onBeforeDestroy () {
		this.destroying = true;
		if (!this.actionTaken) {
			if (this.getOption('actionOnDestroy') === 'apply') { this.triggerApply(); } else if (this.getOption('actionOnDestroy') === 'cancel') { this.triggerCancel(); } else if (this.getOption('actionOnDestroy') === 'reset') { this.triggerReset(); }
			// else {
			// 	//Console.warn('destroying without action');
			// }
		}
	},
	childViewTriggers: {
		refresh: 'refresh'
	}
});

export default EditView;
