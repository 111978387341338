import Yat from 'marionette.yat';
const cmn = Yat.Functions.common;

Yat.TemplateContext.add(
	{
		_lbl: (v) => cmn.getLabel(v),
		_val: (v) => cmn.getValue(v),
		_cidname: function (name) { return ` name="${this._cid(name)}" `; }
	}
);
