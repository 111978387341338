import { _ } from 'vendors';
import './api-config';
import Models from './models';
import editValue from 'components/edit-value';
import data from 'bus/data';

import modalError from 'helpers/modals/error';

import busEvents from 'bus/app-events';

busEvents.request('Handler', {
	radioEvents: {
		departmentChanged (ev) {
			const entity = this.getModelByEvent(ev);
			if (!entity) return;
			entity.set(ev.data);
		},
		departmentRightsChanged (ev) {
			const entity = this.getModelByEvent(ev);
			if (!entity) return;

			const newValue = _.extend({}, entity.get('rights'), ev.data.rights);
			entity.set('rights', newValue, { flat: false, parent: entity });
		},
		departmentEmployeesAdded (ev) {
			const entity = this.getModelByEvent(ev);
			if (!entity) return;
			entity.getEmployees().add(ev.data, { parent: entity });
		},
		departmentEmployeesRemoved (ev) {
			const entity = this.getModelByEvent(ev);
			if (!entity) return;
			entity.getEmployees().reset(ev.data, { parent: entity });
		},
		departmentAddedToDepartment (ev) {
			departments.add(ev.data);
			const entity = this.getModelByEvent(ev);
			entity && entity.trigger('change', entity);
		},
		departmentCreated (ev) {
			departments.add(ev.data);
		}

	},
	getModelByEvent (ev) {
		return ev.dataId && departments.get(ev.dataId);
	}
});


const Departments = Models.Collection.extend({
	tryAddDepartment (opts = {}) {
		let { header, parentId } = opts;
		header || (header = 'Укажите название отдела');

		editValue({
			header,
			valueType: 'text',
			modelType: 'single',
			editOptions: {
				resetButton: false,
				applyLabel: 'добавить',
				cancelLabel: 'не добавлять'
			}
		}).then((name) => {
			this.create({ name, parentId }, { wait: true }).then(
				() => {},
				xhr => modalError('Неудалось добавить отдел', xhr)
			);
			// let newModel = new Models.Model({ name, parentId });
			// newModel.save(null,{wait:true}).then(
			// 	() => {
			// 		collection.add(newModel);
			// 	},
			// 	() => {}
			// );
		}, () => {});
	}
});
const departments = new Departments();

data.departments = function (...args) {
	return data.request('departments', ...args);
};

data.departmentName = function (id) {
	const dep = data.request('departments', id);
	if (!dep) { return; }
	return dep.getName();
};

data.reply('departments', function (id) {
	if (!arguments.length) return departments;
	return departments.get(id);
});



export default departments;
