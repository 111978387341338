import { buildTabsHeader as baseBuildTabsHeader } from 'components/ui2';
// import smartOpen from 'helpers/smart-open';

export const tabs = [
	{ id: 'root', name: 'партии и количество' },
	{ id: 'logs', name: 'транзакции' }
];

// const rootPath = 'store';

// function buildUrl(id) {
// 	let add = '/store';
// 	if (id !== 'root') {
// 		add += '/' + id;
// 	}

// }

export function buildTabsHeader (startTab, ac, isRoot) {
	return baseBuildTabsHeader(startTab, tabs, ac, isRoot);

	// return new BlockTabsHeader({
	// 	startTab,
	// 	tabs,
	// 	//makeTabActiveOnClick: true,
	// 	onTabClick(tab, event) {
	// 		if (isRoot && tab.id === 'root') return;
	// 		let root = isRoot ? ac.page : ac.page.getParent();
	// 		let url;
	// 		if (!isRoot && tab.id === 'root')  {
	// 			url = root.url(ac.args);
	// 		} else {
	// 			let route = root.getRoute() + '/' + tab.id;
	// 			route = root._normalizeRoute(route);
	// 			url = root.parseUrl(route, ac.args);
	// 		}
	// 		smartOpen(url, event);
	// 		// console.log('GOING', url);
	// 		// console.log('GOING NESTED', url);
	// 	}
	// });
}
