import { Collection } from './base-models';
import List from './list-model';
import nextNum from 'helpers/next-num';

export default Collection.extend({
	model: List,
	comparator: 'order',
	addNewList () {
		const id = nextNum(this.pluck('id'));
		const order = nextNum(this.pluck('order'));
		const newList = {
			id,
			name: `Список №${id}`,
			order
		};
		return this.add(newList);
	}
});
