import { HamburgerView } from 'base/view-hamburger';
import { TableRowsListView } from './TableRowsListView';
import { TableRowView } from './TableRowView';
// import { ContragentsLogList } from './ContragentsLogList';
// import { ProductionTree } from './products-layouts/ProductionTree';
// import { ProductsTableLayout } from './products-layouts/TableLayout';

export const TableContentView = HamburgerView.extend({
	baseClassName: 'content',

	ListView: TableRowsListView,
	RowView: TableRowView,
	headerRowClassName: 'table-rows-list-header',
	getTableConfig () {
		return {};
	},
	getCustomViews () {
		return [
			this.buildHeadersView(),
			this.buildContentView()
		];
	},
	buildContentView () {
		const rootView = this.getOption('rootView');
		const layoutView = this.getOption('layoutView');
		const ListView = this.getOption('ListView');
		const listOptions = this.getOption('listOptions', true) || {};
		const options = {
			class: ListView,
			rootView,
			layoutView,
			model: this.model,
			filterModel: this.getOption('filterModel', true),

			thisClassName: this.getOption('listClassName'),

			rowClickUrl: this.getOption('rowClickUrl', false),
			childView: this.getOption('RowView'),
			Collection: this.getOption('Collection'),
			collection: this.getOption('collection'),
			tableSchema: this.getOption('tableSchema'),

			rowOptions: this.getOption('rowOptions', true),
			rowClassName: this.getOption('rowClassName'),
			rowUseWrapper: this.getOption('rowUseWrapper', true),

			cells: this.getOption('cells', false),
			cellOptions: this.getOption('cellOptions', true),

			viewFilter: this.getOption('viewFilter', false),
			viewComparator: this.getOption('viewComparator', false),
			disableFetch: this.getOption('disableFetch', true),

			...listOptions,

			tableConfig: this.getOption('tableConfig', true)
		};
		return options;
	},
	buildHeadersView () {
		if (!this.getOption('listHeaderEnabled', true)) {
			return;
		}
		const rootView = this.getOption('rootView');
		const headerRow = {
			class: this.getOption('RowView'),
			thisClassName: this.getOption('rowClassName'),
			rootView,
			filterModel: this.getOption('filterModel', true),
			tableSchema: this.getOption('tableSchema'),
			cells: this.getOption('cells', false),
			onlyHeaders: true
		};
		const options = {
			class: HamburgerView,
			thisClassName: this.getOption('headerRowClassName'),
			customViews: [headerRow]
		};
		return options;
	}
});
