import { _ } from 'vendors';

import { isString, isObject } from 'utils';

import busEvents from 'bus/app-events';


function getSocketEntityName(view) {
	const model = view?.model;
	if (model == null) { return; }
	return model.socketEntity || model.entity;
}

function initEntityEvents (view) {
	if (view._socketEntityEventsInitialized) return;

	const model = view.model;
	const entityName = getSocketEntityName(view);
	if (!entityName || !isString(entityName)) { return; }

	// if (!model || !(_.isString(model.socketEntity) || _.isString(model.entity))) return;

	const hash = view.getOption('socketEntityEvents');
	if (!isObject(hash)) return;

	if (!model.id) {
		view.listenToOnce(model, 'change:id', () => initEntityEvents(view));
		return;
	}

	if (view.isRendered() && view.isAttached()) {
		view._bindSocketEvents();
	}
	view.on('render attach', view._bindSocketEvents);
	view.on('before:render before:detach before:destroy', view._unbindSocketEvents);
	view._socketEntityEventsInitialized = true;
	// view.on('attach', view._bindSocketEvents);
	// view.on('before:detach', view._unbindSocketEvents);
	// view.on('before:destroy', view._unbindSocketEvents);
}


export default Base => Base.extend({
	constructor () {
		Base.apply(this, arguments);
		initEntityEvents(this);
	},
	_bindSocketEvents () {
		if (this._socketEventsBinded) return;

		const view = this;
		const hash = this.getOption('socketEntityEvents');
		const model = this.model;
		const entityName = getSocketEntityName(this); //this.model.socketEntity || this.model.entity;
		if (!isString(entityName)) {
			throw new Error('shocketEntity missing');
		}
		const prefix = _.colonedString(entityName.toLowerCase(), model.id);
		const events = this._socketEntityEvents = {};
		_.each(hash, (callback, _name) => {
			const names = _name.split(/\s/g);
			_.each(names, name => {
				const key = prefix + ':' + name;
				const eventHashKey = key in events ? _.uniqueId(key) : key;
				events[eventHashKey] = _.bind(callback, view);
				view.listenTo(busEvents, key, events[eventHashKey]);
			});
		});

		const socketEvents = view.getOption('socketEvents');
		_.each(socketEvents, (handler, _name) => {
			if (_.isString(handler) && _.isFunction(view[handler])) {
				handler = view[handler];
			}
			if (_.isFunction(handler)) {
				handler = handler.bind(view);
			} else {
				return;
			}
			view.listenTo(busEvents, _name, handler);
		});

		this._socketEventsBinded = true;
	},
	_unbindSocketEvents () {
		// let events = this._socketEntityEvents || {};
		this.stopListening(busEvents);
		// _(events).each((callback, key) => this.stopListening(busEvents, key));
		this._socketEntityEvents = {};
		this._socketEventsBinded = false;
	}
});
