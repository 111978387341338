import enums from 'helpers/enums';
// import user from 'app/user';
import prodFromChild from './prod-cycle/child-menu';
import storeChild from './store/child-menu';
import processesChild from './processes/child-menu';
import concreteChild from './concretes/child-menu';
import usedInProdChild from './used-in-prod/child-menu';

// import { Layout as ProductCycleLayout } from './prod-cycle/layout';
export const childrenMenu = [
	{
		id: 'root',
		label: 'Основное'
	},
	concreteChild,
	// {
	// 	id: 'prod-source',
	// 	label: 'Сырьё для',
	// 	filter: p => enums.has(p.model.get('purpose'), 'production')
	// },
	prodFromChild,
	usedInProdChild,

	{
		id: 'purchase',
		label: 'Закупка',
		filter: p => !p.model.get('isAbstractGroup') && enums.has(p.model.get('origin'), 'purchase')
	},
	{
		id: 'pricechanges',
		label: 'Изменение цены',
		filter: p => !p.model.get('isAbstractGroup') && enums.has(p.model.get('origin'), 'purchase')
	},

	storeChild,

	{
		id: 'activity',
		label: 'Журнал'
	},

	processesChild

];

