import '../api-config';
import BoardModel from 'components/board/board-model';
import busModels from 'bus/models';
import paths from 'helpers/paths';
import ProcessModel from './process';
import CardView from '../views/card-view';

const ProcessesBoard = BoardModel.extend({
	defaults: {
		parentEntityType: 'employee',
		modelType: 'process'
	},
	entityConfig: {
		Model: ProcessModel,
		View: CardView
	},
	defaultUrl: paths.api('processes:board')
});
export default ProcessesBoard;

let board;

busModels.reply('processes:board:instance', () => {
	if (!board) {
		board = new ProcessesBoard();
	}
	return board;
});
