import { GetInfoMixin, _meas } from './common';
import { _ } from 'vendors';
import UiListItem from 'components/ui-list/item';

export const ComponentInfoView = UiListItem.extend({
	...GetInfoMixin,
	addClass: 'component-holder',
	text: (m, v) => v.getName(),
	getName () {
		let name = this.model.get('name');
		if (this.model.get('isAbstractGroup')) {
			name = `[${name}]`;
		}
		// console.warn('# model name', name, this.model.attributes);
		return name;
	},
	rightItems () {
		const G = k => this.model.get(k);
		const D = v => _.displayNum(v, 4);

		const amount = D(G('contragentAmount'));
		const produceAmount = D(G('amount') / G('produceUnitsInBaseUnit'));
		const unit = _meas(G('baseUnitMeasureId'));

		const inputAmount = this.getInputedAmount() || 0;
		const awaitedAmount = this.getAwaitedAmount() || 0;

		const items = [
			{
				template: `<div class="numbers"><i>у кон-та</i><b>${unit}</b><span>${amount}</span></div>`
			},
			{
				template: `<div class="numbers"><i>исп-ся</i><b>${unit}</b><span>${produceAmount}</span></div>`
			},
			{
				template: `<div class="numbers"><i>введено</i><b>${unit}</b><span>${inputAmount}</span></div>`
			},
			{
				template: `<div class="numbers"><i>ожидается</i><b>${unit}</b><span>${awaitedAmount}</span></div>`
			}
		];
		return items;
	},
	rightButtons: [
		{ class: 'add-party', icon: true, title: 'добавить партию', 'data-trigger': 'add:party' }
	],

	initialize () {
		const parts = this.getOption('parties');
		this.listenTo(parts, 'recalced change remove', () => {
			this.model.set('awaitedAmount', this.getAwaitedAmount());
            // this.render
		});
	},

	getInputedAmount () {
		const parts = this.getOption('parties');
		return parts.getInputedAmount();
	},
	getAwaitedAmount () {
		const parts = this.getOption('parties');
		return parts.getAwaitedAmount();
	}
});
