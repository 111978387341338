// import CollectionView from 'base/collection-view';
import TransactionView from './transaction-view';
import TransactionsCollection from './transactions-collection';

import { FetchableEntries } from '../common';


export default FetchableEntries.extend({
	className: 'transactions-list entries-holder elastic-content',
	childView: TransactionView,
    // scrollDirection: 'bottom',

    // initialize() {
    //     this.depModel = this.getOption('departmentModel');
    //     this.filters = this.getOption('filters');
    //     this.collection = new TransactionsCollection();
    //     let url = this.depModel.url();
    //     this.collection.url = url + '/contragents-transactions';
    //     this.collection.fetch();
    // },

	buildCollection () {
		const collection = new TransactionsCollection();
		const url = this.depModel.url();
		collection.url = url + '/contragents-transactions';
		return collection;
	}

});
