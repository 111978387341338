const STORAGE_KEY = 'process.page.newlayout';

export function designSwitch() {
	const val = localStorage.getItem(STORAGE_KEY);
	const enabled = val === 'true';
	console.error("DESIGN", val, enabled);
	localStorage.setItem(STORAGE_KEY, !enabled);
	//debugger;
	document.location.reload();
}

export function isNewDesign() {
	const val = localStorage.getItem(STORAGE_KEY);
	const enabled = val === 'true';
	return enabled;
}


