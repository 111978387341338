import { invokeValue } from 'utils/invoke';
import { MnView } from 'vendors';

const baseSetOptions = MnView.prototype._setOptions;

export const optionsMixin = {
	getOption (key, options) {

		if (typeof options === 'boolean') {
			options = { invoke: options };
		} else if (typeof options !== 'object') {
			options = {};
		}

		let { invoke, invokeArgs = this, invokeContext = this, force = false } = options;

		if (invoke === undefined && (options.invokeArgs || options.invokeContext)) {
			invoke = true;
		}

		let value = this.options[key];
		if (value === undefined) {
			value = this[key];
		}

		if (invoke || force) {
			value = invokeValue(value, invokeContext, invokeArgs);
		}


		return value;
	},
	hasOption (key) {
		return this.options[key] !== undefined;
	},
	mergeOptionsKeys(keys, opts) {
		keys.forEach(key => {
			const value = this.getOption(key, opts);
			if (value !== undefined) {
				this[key] = value;
			}
		});
	}
	// _setOptions(arg1, arg2) {
	// 	const isArg1Array = Array.isArray(arg1);
	// 	if (arg1 && typeof arg1 === 'object' && !isArg1Array) {
	// 		return baseSetOptions.call(this, arg1, arg2);
	// 	} else if (isArg1Array) {
	// 		arg1.forEach(key => {
	// 			const value = this.getOption(key, arg2);
	// 			if (value !== undefined) {
	// 				this[key] = value;
	// 			}
	// 		});
	// 	}
	// },
};
