import { _ } from 'vendors';
import yatModals from './base-modals';
import error from './error';
import message from './message';
import preloader from './preloader';
import confirm, { confirmReason } from './confirm';
export default _.extend({
	confirm,
	confirmReason,
	error,
	message,
	preloader
}, yatModals);
