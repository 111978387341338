import merge from 'helpers/merge';
import Details from './list';
import UiCardBlock from 'components/ui-card-block/block';


export default (context, opts) => {
	const model = context.model;
	const defs = {
		viewClass: UiCardBlock,
		viewOptions: {
			header: 'реквизиты',
			headerOptions: {
				className: 'bg-main-dark'
			},
			content: Details,
			contentOptions () {
				return {
					model,
					collection: model.getDetails()
				};
			},
			action: 'add:details'
		},
		regionTemplate: { replaceElement: true }
	};

	const options = merge({}, defs, opts);
	return context.createNested('details', options);
};
