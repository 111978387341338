import Page from 'pages/anon';
import Layout from './layout';
import paths from 'helpers/paths';

paths.set('url', 'acc:login', 'acc/login');

const LoginPage = Page.extend({
	name: 'login page',
	label: 'Вход',
	route: 'login',
	Layout
});

export default LoginPage;
