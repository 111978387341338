
// import BaseView from 'pages/view';
// import template from './layout.html';
// import ModelProperties from 'components/ui-model-card/properties';
// import h from 'components/ui-model-card/helpers';
// import nestedRights from 'mod/rights/views/entity-nested-rights';
// import Departments from './departments';
// import Groups from './groups';
// import UiCardBlock from 'components/ui-card-block/block';
// import user from 'app/user';

// import { HamburgerView } from 'base/view-hamburger';
import ListView from './ListView';

// import Selector from 'helpers/selector';
// import { Selector } from 'helpers/selector2';
// import { SelectorView, ActionsView, TransferLayoutView } from '../common';
import { TransferLayoutView } from '../common';

export const CyclesLayoutView = TransferLayoutView.extend({
	ListView,
	thisClassName: 'active-cycles-page',
	resourceName: 'cycles',
	setupSelector (selector) {

	}
});
