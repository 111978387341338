import Model from 'base/model';
import CollectionView from 'base/collection-view';
import UiListItem from 'components/ui-list/item';
import busData from 'bus/data';
import user from 'app/user';
import smartOpen from 'helpers/smart-open';
import modalConfirm from 'helpers/modals/confirm';
import enums from 'helpers/enums';

const EmptyView = UiListItem.extend({
	text: 'не производится / отсутствуют производственные циклы'
});

const CycleView = UiListItem.extend({
	initialize (options) {
		this.mergeOptions(options, ['product']);
	},
	className: 'ui-list-item product-prod-cycle-item',
	cssClassModifiers () {
		return [
			this.model.isStarted() ? 'started' : '',
			this.model.get('state') === 'test' ? 'test' : ''
		];
	},
	text: m => m.get('name'),
	bottomText: m => busData.employeeName(m.get('responsibleId')),
	topText: m => enums.get('productionCycleStates', m.get('state')),
	leftButtons () {
		const isMy = this.model.isMy();
		const isStarted = this.model.isStarted();
		return [
			isMy && isStarted ? { icon: true, class: 'stop', title: 'остановить', 'data-trigger': 'stop:cycle' } : null,
			isMy && !isStarted ? { icon: true, class: 'start', title: 'запустить', 'data-trigger': 'start:cycle' } : null,
			{ icon: true, class: 'list-icon', title: 'перейти на страницу производственного цикла', 'data-trigger': 'show:cycle' }
		];
	},
	isMy () {
		return user.isMe(this.model.get('responsibleId'));
	},
	rightButtons () {
		const admin = this.model.amIAdmin();
		return [
			admin ? { icon: true, class: 'delete', title: 'удалить', 'data-trigger': 'delete:cycle' } : null
		];
	},
	onDeleteCycleClick (event) {
		modalConfirm('Подтвердите удаление').then(() => {
			this.model.destroy();
		});
	},
	onShowCycleClick (event) {
		const url = this.model.pageUrl({ productId: this.product.id });
		smartOpen(url, event);
		// console.log('CHPOK');
	},
	onStartCycleClick () {
		if (!this.isMy()) return;
		const url = this.model.url() + '/start';
		const saver = new Model();
		saver.save(null, { url, wait: true, preloader: this.$el, method: 'POST' }).then(() => this.model.set('isStarted', true));
	},
	onStopCycleClick () {
		if (!this.isMy()) return;
		const url = this.model.url() + '/stop';
		const saver = new Model();
		saver.save(null, { url, wait: true, preloader: this.$el, method: 'POST' }).then(() => this.model.set('isStarted', false));
	}
	// editButton: (m) => user.isMe(m.get('responsibleId')) && !m.get('isStarted'),
	// onShowListClick() {
	// 	this.
	// 	document.location.hash = this.model.id || _.uniqueId();
	// }
});

export const ProdCyclesView = CollectionView.extend({
	className: 'product-prod-cycles-list',
	childView: CycleView,
	emptyView: EmptyView,
	listType: 'active',
	listTypeFilters: {
		active: v => v.model.get('isArchived') !== true,
		archived: v => v.model.get('isArchived') === true
	},
	childViewOptions () {
		return {
			product: this.model
		};
	},
	viewFilter (v) {
		if (this.model.id !== v.model.get('productId')) { return false; }
		const listType = this.getOption('listType') || 'active';
		const listTypeFilter = this.listTypeFilters[listType];
		if (!listTypeFilter) {
			return true;
		}
		return listTypeFilter(v);
	},
	// childViewTriggers: {
	// 	'show:cycle:click':'show:cycle'
	// },
	initialize () {
		this.collection.fetch({ preloader: true });
	}
});
