import ModelProperties from 'components/ui-model-card/properties';
import h from 'components/ui-model-card/helpers';

export function createNestedPaymentDetails (view) {
	// const m = view.model;
	view.createNested('payment', {
		viewClass: ModelProperties.extend({ className: 'card lined with-border' }),
		viewOptions: {
			header: { text: 'оплата', addClass: '' },
			flatPatch: true,
			// cssClassModifiers:[
			// 	'main-info-block',
			// 	m => m.get('completeState'),
			// ],
			properties: [
				h.ui('buyDetails.payment.advancePercent', { editButton: true }),
				h.ui('buyDetails.payment.payFirstTill', { editButton: true }),
				h.ui('buyDetails.payment.payFullTill', { editButton: true }),
				h.ui('buyDetails.payment.totalPrice', { editButton: true }),
				h.ui('buyDetails.payment.donePriceAmount', { editButton: true })
				// h.ui('dates.deadline', {
				// 	editButton: (m) => m.isDeadlineCanBeChanged(),
				// 	cssClassModifiers: [(mm) => mm.isNotClosed() && mm.isInPast() ? 'past' : mm.isNotClosed() && mm.isNear() ? 'near' : '']
				// }),
				// h.ui('type', {editButton: (m) => m.isTypeCanBeChanged(), half:true } ),
				// h.ui('dates.strictDeadline', {editButton:(m) => m.isStrictCanBeChanged(), half:true } ),
				// h.ui('state', {
				// 	half: true,
				// 	cssClassModifiers: [(mm) => mm.get('result'), (mm) => mm.get('state')]
				// }),
				// h.ui('stateDate', { half: true }),
			]
		},
		regionTemplate: { replaceElement: true }
	});
}
