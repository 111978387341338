import View from 'base/view';
import { ClickableBehavior } from 'behaviors';
import smartOpen from 'helpers/smart-open';
import { cyclePageBaseUrl } from 'mods/resources/production/products/cfg';

import user from 'app/user';
export const CycleCard = View.extend({
	behaviors: [ClickableBehavior],
    // clickElementSelector: '.owner',
	cssClassModifiers: [
		m => user.isMe(m.get('responsible').id) ? 'my' : ''
            // busData.user().isMe(v.model.get('owner').id) ? 'my' : ''
	],
	className: 'ui2-block with-wrapper department-product-cycle clickable',
	template: `<div class="wrapper">
    <div class="ui2-molecule info">
				<div class="ui2-atom owner">
					<i>ответственный</i>
					<span><%= responsible.name %></span>
				</div>
				<div class="ui2-atom product">
					<i>продукт</i>
					<span><%= product.name %></span>
				</div>
    </div>
    <div class="ui2-molecule process">
				<div class="ui2-atom name">
					<i>название цикла</i>
					<span><%= name %></span>
				</div>
    </div>
</div>`,
	onClicked (event) {
		const options = { ctrlKey: !event.ctrlKey };
		const url = cyclePageBaseUrl(this.model.get('product').id) + this.model.id;
		smartOpen(url, options);
	}
});
