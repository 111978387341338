// import { BaseCollection } from 'base/base-collection';
import { ProductModel } from 'mod/productionProducts/models';
import { getNestedCollection } from 'utils/getNestedCollection';


// function buildCollection (model) {
// 	const models = [];
// 	const col = new BaseCollection(models, { model: ProductModel });
// 	col.parent = model;
// 	col.url = model.url() + '/concretes';
// 	return col;
// }

export function getConcretesCollection (model) {

	const url = model.url() + '/concretes';

	return getNestedCollection(model, { dataKey: 'concretes', Model: ProductModel, url });

	// if (!model.concretesCollection) {
	// 	model.concretesCollection = buildCollection(model);
	// }
	// return model.concretesCollection;

}
