import { HamburgerView } from 'base/view-hamburger';

import { ListItemView } from './ListItem';

export const CardItemView = HamburgerView.extend({

	constructor: function () {
		HamburgerView.apply(this, arguments);

		this.initializeClickableMixin();
	},

	url: v => v.model.id ? 'prod/builds/' + v.model.id : '',

	baseClassName: 'ui2-box-item',
	thisClassName: 'build-card ui2-list-item-margined',
	customViews: [
		ListItemView
	],
	customViewOptions () {
		const options = {
			model: this.model,
			collection: this.collection,
			modelSchema: this.getOption('modelSchema', true)
		};
		return options;
	}
});
