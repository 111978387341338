import { ListView } from './List';

import mix from 'helpers/mix';
import FDC from 'mixins/view/flex-dynamic-column';
import ViewManagedCollection from 'mixins/view-managed-collection';
import ScrollHandlerMixin from 'mixins/view/scroll-handler';

import FetchableMixin from 'mixins/collection-view/fetchable';
import FetchOnScrollMixin from 'mixins/collection-view/fetch-on-scroll';
import Actionable from 'mixins/actionable';

export const FdcFetchableListView = mix(ListView)
	.with(FDC, FetchableMixin, FetchOnScrollMixin, ViewManagedCollection, ScrollHandlerMixin, Actionable);
