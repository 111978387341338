import proto from 'components/values/get-prototype';
import enums from 'helpers/enums';
import busData from 'bus/data';

const filtersPrototypes = [
	{
		id: 'product:text',
		valueId: 'text',
		label: 'поиск по ключевому слову',
		hidden: true,
		valuePrototype: proto('text'),
		textSearch: true
	},


	{
		id: 'product:typeId',
		valueId: 'typeId',
		label: 'тип',
		pinned: true,
		// values: [{value: 'client'}],
		valueLabel: (val) => busData.productTypeName(val),
		valuePrototype: () => ({
			multiple: false,
			sourceValues: () => busData.productTypes(),
			controlType: 'select',
			modelType: 'single'
		})
	},
	{
		id: 'product:origin',
		valueId: 'origin',
		label: 'источник',
		pinned: true,
		// values: [{value: 'client'}],
		valueLabel: (val) => enums.get('productOrigins', val),
		valuePrototype: () => proto('enum', {
			multiple: true,
			sourceValues: () => enums.store.productOrigins
		})
	},
	{
		id: 'product:purpose',
		valueId: 'purpose',
		label: 'назначение',
		pinned: true,
		// values: [{value: 'client'}],
		valueLabel: (val) => enums.get('productPurposes', val),
		valuePrototype: () => proto('enum', {
			multiple: true,
			sourceValues: () => enums.store.productPurposes
		})
	},
	{
		id: 'product:isArchived',
		valueId: 'isArchived',
		label: 'Архив',
		pinned: true,
		valueLabel: (val) => enums.get({ false: 'Действующая продукция', true: 'Архивная продукция' }, val || false),
		valuePrototype: () => proto('enum', {
			multiple: false,
			sourceValues: () => ({ false: 'Действующая продукция', true: 'Архивная продукция' })
		})
	},
	{
		id: 'product:isDeleted',
		valueId: 'isDeleted',
		label: 'Удалённая продукция',
		pinned: true,
		rights: { admin: true, resourcesProduction: 'productsEdit' },
		valueLabel: (val) => enums.get({ false: 'нет', true: 'да' }, val || false),
		valuePrototype: () => proto('enum', {
			multiple: false,
			sourceValues: () => ({ false: 'не удалённая продукция', true: 'удалённая продукция' })
		})
	}
];

export default filtersPrototypes;
