import { BaseView } from 'base/base-view';
import { BaseHamburgerView } from 'base/base-hamburger-view';
import { BaseModel } from 'base/base-model';
import { getLastFewActivity, getLastFewGoals, getLastFewSubprocs, getLastFewTasks } from './common-blocks';


const Block = BaseHamburgerView.extend({
	className: 'ui2-block bg-white canonical dashboard-block',
	template: '<header><%= header %></header><section></section><footer><a href="<%= url %>">&rarr; посмотреть все</a></footer>',
	templateContext() {
		return {
			header: this.getOption('header'),
			url: this.getOption('rootUrl') + this.getOption('url')
		}
	},
	childViewContainer: '> section',
	getChildrenViews() {
		return [
			this.getOption('content', true)
		]
	}
});

// function getLastFewGoals(process) {
// 	const goals = process.get('goals');
// 	console.log('GOALS', goals, model.attributes);
// }

export const DashboardView = BaseHamburgerView.extend({
	className: 'ui2-blocks dashboard-items',
	template: '',
	initialize() {
		this.page = this.getOption('page');
		this.rootPage = this.page.getEntityPageRoot();
		const ac = this.getOption('ac');
		this.rootPageModel = this.rootPage.buildEntityPageModel(ac);
		this.process = this.model;
		this.model = new BaseModel();
		// this.on('all', e => console.warn('[dsbrd] => ', e));
		this.model.url = this.process.url() + '/dashboard';
		this.modelFetching = this.model.fetch();
		this.listenToOnce(this.model, 'sync', () => {
			this._fetched = true;
			this.render()
		});
	},

	childViewOptions() {
		return {
			rootUrl: this.rootPageModel.url + '/',
			model: this.model,
			modelFetching: this.modelFetching
		}
	},
	getChildrenViews() {
		if (!this._fetched) { return; }
		const process = this.process;
		const { comments, tasks, subprocesses, media } = this.model.attributes;
		console.error(comments, tasks, subprocesses, media);
		const views = [
			{ 
				class: Block, header: 'последние комментарии', url: 'activity', 
				content: v => getLastFewActivity({
					onRender() {
						// const promise = v.getOption('modelFetching');
						// promise.then(() => {
						// 	const entries = v.model.get('comments');
						// })
						if (!this.collection.__fakeSetUsed) {
							this.collection.fakeSet(comments);
							this.collection.__fakeSetUsed = true;
							console.error('UDACHA')
							this.render();
						}
						
					}
				}) 
			},
			{ class: Block, header: 'цели', url: 'goals', content: getLastFewGoals(process) },
			{ class: Block, header: 'последние задачи', url: 'tasks', content: getLastFewTasks(tasks) },
			{ class: Block, header: 'последние подпроцессы', url: 'sub-procs', content: getLastFewSubprocs(subprocesses, process) },
		];
		return views;
	}
});