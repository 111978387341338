import { MnObject } from 'vendors';
import { SchemaItemsCollection } from './SchemaItemsCollection';
export { modelWithSchemaMixin } from './modelWithSchemaMixin';
export { getSchema } from './getSchema';

export const Schema = MnObject.extend({
	constructor: function () {
		MnObject.apply(this, arguments);
		this.initializeBackendSchema();
	},
	initializeBackendSchema () {
		const schemaUrl = this.getOption('schemaUrl', true);
		if (!schemaUrl) {
			this.whenBackendSchemaFetched = Promise.resolve(this);
			return;
		}
		this.backendSchema = new SchemaItemsCollection([], { url: schemaUrl });
		this.listenTo(this.backendSchema, 'first:sync', () => this.triggerMethod('backend:fetch'));
		this.whenBackendSchemaFetched = this.backendSchema.whenFetched;
	},
	fetch () {
		if (this.backendSchema) {
			this.backendSchema.fetch();
		}
	}
});
