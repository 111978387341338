import { toUpperFirst } from "./to-case";

const defaultOptions = {
	splitPattern: /:/gm,
	ignoreEmpty: true
};

function transformString(chunks, options, internalIndex = 0) {
	const last = chunks[chunks.length - 1];
	options = last && typeof last === 'object' ? Object.assign({}, defaultOptions, options, chunks.pop()) : options;

	const { joinSeparator, ignoreEmpty } = options;

	chunks = chunks.map((chunk, index) => transformStringChunk(chunk, options, internalIndex + index));
	if (ignoreEmpty) {
		chunks = chunks.filter(f => !!f);
	}
	const result = chunks.join(joinSeparator);
	return result;
}

function transformStringChunk(chunk, options, internalIndex = 0) {
	if(chunk == null || chunk === '' || typeof chunk !== 'string') { return chunk; }
	const { splitPattern, transformChunk } = options;
	let chunks = chunk.split(splitPattern);
	if (transformChunk) {
		chunks = chunks.map((item, index) => transformChunk(item, options, internalIndex + index));
	}
	if (chunks.length === 1) {
		return chunks[0];
	} else {
		return transformString(chunks, options, internalIndex);
	}
}


const camelCaseOptions = {
	joinSeparator: '',
	transformChunk: (item, options, index) => {
		if (index) {
			item = toUpperFirst(item);
		}
		return item;
	}
}

export function toCamelCase(...chunks) {
	return transformString(chunks, camelCaseOptions)
}