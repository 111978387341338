import { HamburgerView } from 'base/views';
import { BaseHeaderView } from 'base/base-view';
import { BaseTextView, BaseView } from '../../../base/base-view';

export const UiBlockView = HamburgerView.extend({
	constructor: function () {
		HamburgerView.apply(this, arguments);
		this.initializeBlockView();
	},
	initializeBlockView () {
		if (this.getOption('wholeColumn', true)) {
			this.state('whole-column', true);
		}

		if (this.getOption('doubleWidth', true)) {
			this.state('double-width', true);
		}

		this.on('action:click', (action, ...args) => {
			if (this.model.executeAction) {
				this.model.executeAction(action, ...args);
			} else if (action !== 'action') {
				this.triggerMethod(action + ':click', ...args);
			}
		});

	},
	baseClassName: 'ui2-block',
	stateClassNames: [
		'whole-column',
		'double-width'
	],
	getCustomViews () {
		// const header = this.getHeaderView();
		// const contents = [
		// 	...this.getContentViews(),
		// 	...this.getActionViews()
		// ];
		// const footer = this.getFooterView();

		// const views = [];
		// if (header) {
		// 	views.push(header);
		// }

		const views = [
			this.getHeaderView(),
			...this.getContentViews(),
			...this.getActionViews(),
			this.getFooterView()
		];
		console.log('# views', views);
		return views;
	},
	getHeaderView () {
		const header = this.getOption('header', true);
		if (typeof header === 'string') {
			return {
				class: BaseHeaderView,
				thisClassName: 'just-text',
				text: header
			};
		}
		return header;
	},
	getContentViews () {
		let content = this.getOption('content', true);

		if (content) {
			if (!Array.isArray(content)) {
				content = [content];
			}
			return content;
		}

		return [{
			class: BaseView,
			template: '',
			thisClassName: 'elastic-content'
		}];

	},
	getActionViews () {
		if (this.getOption('actionInFooter', true)) {
			return [];
		}
		const actions = this.getOption('actions', true);
		if (!actions) { return []; }
		return this._normalizeActions(actions);
	},
	getFooterView () {
		const actioninFooter = this.getOption('actionInFooter', true);
		if (actioninFooter) {
			return this._normalizeActions(actioninFooter)[0];
		}

		const footer = this.getOption('footer', true);
		return footer;
		// if (!footer) { }

	},
	_normalizeActions (raw) {
		if (!raw) { return []; }
		if (!Array.isArray(raw)) {
			raw = [raw];
		}
		return raw.map((item, index) => this._normalizeAction(item, index));
	},
	_normalizeAction (action) {
		if (typeof action === 'string' && this.model.getAction) {
			const actionModel = this.model.getAction(action);
			if (actionModel) {
				return {
					class: BaseTextView,
					text: actionModel.label,
					thisClassName: 'ui2-line-action just-text',
					events: {
						click: event => this.triggerMethod('action:click', actionModel.id, event)
					}
				};
			}
			console.log(actionModel);
		}
	}
});
