import { HamburgerView } from 'base/view-hamburger';
import { setOptionsMixin } from './common';
import { PageAsideView } from './PageAsideView';

export const PageWithDivisionsLayout = HamburgerView.extend({
	tagName: 'section',
	className: 'page-content page-with-divisions',

	...setOptionsMixin(HamburgerView),

	beforeInitialize () {
		const ac = this.page._currentActionContext;
		this.req = {
			args: { ...ac.args },
			qs: { ...ac.qs }
		};
	},

	cssClassModifiers: [
		(m, v) => v.getRootId(),
		(m, v) => v.page.getOption('pageId')
	],
	getRootId () {
		const rootId = this.getOption('rootId');
		if (rootId) { return rootId; }
		const root = this.page.getDivisionRootPage();
		// console.log('~~~ root:', root);
		return root.getOption('pageId');
	},
	getCustomViews () {
		const arr = [
			this.getAsideView(),
			this._getContentView()
		];

		return arr;
	},
	getAsideViewOptions (opts) {
		return Object.assign({}, opts, this.getOption('asideOptions'));
	},
	getAsideView () {
		const defs = this.getDefaultChildViewOptions({}, { rootView: this });
		const options = this.getAsideViewOptions(defs);
		return new PageAsideView(options);
	},
	_getContentView () {
		const defs = this.getDefaultChildViewOptions({}, { rootView: this });
		const customViewOptions = {
			...defs,
			...(this.getOption('contentOptions') || {})
		};
		const view = {
			class: HamburgerView,
			className: 'page-with-divisions_content_container',
			customViewOptions,
			customViews: [
				this.getContentHeaderView(),
				this.getContentView()
			]
		};

		return view;
	},
	getContentView () {
		return this.getOption('content', true);
	},
	getContentHeaderView () {
		return this.getOption('header', true);
	}
});
