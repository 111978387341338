import View from 'base/view';
import { emptyViewMixin } from 'mixins/view/empty-view';

export const EmptyView = View.extend({

	...emptyViewMixin,

	template: '<%= text %>',
	templateContext () {
		return {
			text: this.getEmptyText()
		};
	}
});
