import BaseView from 'pages/view';
import ModelProperties from 'components/ui-model-card/properties';
import h from 'components/ui-model-card/helpers';
// import ModelActions  from 'components/ui-model-card/actions';
import ProcessModel from '../models/process';
// import TeamListView from './team-list';
// import UiCardBlock from 'components/ui-card-block/block';
import nestedEmployees from 'mod/employees/views/entity-nested-employees';
import ActionsLayout from 'components/actions/list';
import ToDoBlock from 'mod/tasks/views/todo';
import busData from 'bus/data';
import user from 'app/user';
import { BaseHamburgerView } from '../../../../base/base-hamburger-view';
import { BaseView as View } from 'base/base-view';
// import createNestedContragent from 'mod/contragents/views/nested/create';

const CreateProcessView = BaseView;

// not implemented
// function teamBlock (view, m) {
// 	view.createNested('employees',
// 		nestedEmployees(m,
// 			{
// 				type: 'processTeam',
// 				viewOptions: {
// 					className: 'nested-employees',
// 					modelEvents: {
// 						'new:state' () {
// 							this.render();
// 						}
// 					}
// 				}
// 			},
// 			{
// 				viewOptionsHeader: 'Участники процесса',
// 				addChildViewOptions: {
// 					bottomText: m => enums.get('processEmployeeRoles', m.get('joinedAs')),
// 					optionsButton: (em) => m.isEditable('major') && em.isNotMe(),
// 					cssClassModifiers: [
// 						'process-team',
// 						m => m.get('joinedAs')
// 					]
// 				},
// 				contentOptions: {
// 					searchDisabled: true
// 				}
// 			}
// 		)
// 	);
// }



export default CreateProcessView.extend({
	className: 'processes-create',
	template: BaseView.emptyTemplate,
	createModel() {
		const parentId = this.getOption('parentProcessId');
		if (this.model) {
			this.stopListening(this.model);			
		}
		this.model = new ProcessModel({ parentId });
		this.listenTo(this.model, {
			'change:type change:contragentId': () => this.render(),
			'replace:by:new': () => {
				setTimeout(() => {
					// console.clear();
					// console.warn('before re-rendered');
					this.createModel();
					this.render();
				}, 10);
			}
		});
		return this.model;
	},
	initialize () {
		// const m = this.model = new ProcessModel();
		// this.listenTo(m, 'change:type', () => this.render());
		// this.listenTo(m, 'change:contragentId', () => this.render());
		// console.log(user.checkRights({ productsPurchase: 'startProcesses' }));

		this.createModel();

		const canStartBuyProc = user.checkRights({ productsPurchase: 'startProcesses' });

		this.createNested('main', {
			viewClass: ModelProperties, //.extend({ className: 'card lined with-border' }),
			viewOptions: {
				className: 'card lined with-border',
				// header:{ text: 'основное', addClass:''},
				flatPatch: true,
				properties: [
					h.ui('name', { editButton: true }),
					h.ui('description', { editButton: true }),
					// h.ui('accessMode', { editButton: true } ),
					h.ui('ownerId', { editButton: true }),
					canStartBuyProc
						? h.ui('type', { editButton: true, condition: (...args) => { console.log('cond:', args); return true; } })
						: null
					// h.ui('dates.strictDeadline', {editButton:(m) => m.isStrictCanBeChanged(), half:true } ),
					// h.ui('state', {
					// 	half: true,
					// 	cssClassModifiers: [(mm) => mm.get('result'), (mm) => mm.get('state')]
					// }),
					// h.ui('stateDate', { half: true }),
				],
				action: 'create'
			},
			regionTemplate: { replaceElement: true }
		});

		this.createNested('buyContragent', {
			viewClass: ModelProperties, //.extend({ className: 'card lined with-border' }),
			viewOptions: {
				className: 'card lined with-border',
				header: { text: 'установите контрагента', addClass: '' },
				flatPatch: true,
				properties: [
					h.ui('contragentId', { editButton: true })
				]
				// action: 'create'
			},
			regionTemplate: { replaceElement: true },
			condition: () => {
				const m = this.model;
				return m.get('type') === 'buy';
			}
		});

		// createNestedContragent(this, undefined, );

		this.createNested('actions', {
			viewClass: ActionsLayout,
			viewOptions: {
				addClass: 'card lined',
				predicate: 'start:process',
				// actionValidators: {
				// 	'start:process': () => {
				// 		const m = this.model;
				// 		if (m.get('type') === 'buy') {
				// 			const id = m.get('contragent.id');
				// 			console.log('selected contragent:', id);
				// 			return id != null;
				// 		} else {
				// 			return true; //!!m.get('name');
				// 		}
				// 	}
				// }
			},
			regionTemplate: { replaceElement: true }
		});

		this.createNested('employees',
			nestedEmployees(() => this.model,
				{
					skipModels: true,
					type: 'processTeam',
					viewOptions: {
						shouldConfirmRemove: false,
						className: 'nested-employees',
						modelEvents: {
							'change:ownerId' (proc, ownerId) {
								const myId = busData.user().getIdentityId();
								if (!busData.user().isMe(ownerId)) {
									proc.addEmployees([{ employeeId: myId, joinedAs: 'admin' }]);
								} else {
									proc.removeEmployees([myId]);
								}
							}
						}
					}
				},
				{
					viewOptionsHeader: 'Участники процесса'
				}
			)
		);

		// this.createNested('todoPane', {
		// 	viewClass: ToDoBlock,
		// 	viewOptions: {
		// 		header: 'Заметки',
		// 		modelEvents: {
		// 			'new:state' () {
		// 				this.render();
		// 			}
		// 		},
		// 		rights: {
		// 			change: true,
		// 			delete: true
		// 		},
		// 		canBeSwapped () {
		// 			return true;
		// 		}
		// 	}
		// });

	},
	onRender () {
		this.showAllNested({ rebuild: true });
	}
});


const ColumnView = BaseHamburgerView.extend({
	className: 'column',
	getChildrenViews() {
		let content = this.getOption('columnContent', true);
		if (!Array.isArray(content)) {
			content = [content];
		}
		return content;
	}
});

const AllPropertiesView = BaseHamburgerView.extend({
	className: 'new-process-main-form',
	childrenViews: [
		{
			class: ColumnView,
			columnContent() {
				console.log('		:: getting column childrens')
				const canStartBuyProc = user.checkRights({ productsPurchase: 'startProcesses' });
				const view = {
					class: ModelProperties,
					className: 'card lined with-border',
					flatPatch: true,
					properties: [
						h.ui('name', { editButton: true }),
						h.ui('description', { editButton: true }),
						h.ui('ownerId', { editButton: true }),
						// canStartBuyProc
						// 	? h.ui('type', { editButton: true, condition: (...args) => true,  })
						// 	: null
					],
				}
				return view;
			}
		},
		// {
		// 	class: ColumnView,
		// 	columnContent() {

		// 		const data = nestedEmployees(
		// 			() => this.model,
		// 			{
		// 				skipModels: true,
		// 				type: 'processTeam',
		// 				viewOptions: {
		// 					shouldConfirmRemove: false,
		// 					className: 'nested-employees',
		// 					modelEvents: {
		// 						'change:ownerId' (proc, ownerId) {
		// 							const myId = busData.user().getIdentityId();
		// 							if (!busData.user().isMe(ownerId)) {
		// 								proc.addEmployees([{ employeeId: myId, joinedAs: 'admin' }]);
		// 							} else {
		// 								proc.removeEmployees([myId]);
		// 							}
		// 						}
		// 					}
		// 				}
		// 			},
		// 			{
		// 				viewOptionsHeader: 'Участники процесса'
		// 			}
		// 		);

		// 		let { viewClass, viewOptions } = data;

		// 		const view = Object.assign({ class: viewClass }, viewOptions);
				
		// 		return view;
		// 	}
		// }
	]
});

const SubmitView = View.extend({
	tagName: 'footer',
	className: 'submit',
	template: '<button>создать</button>',
	modelEvents: {
		change() {
			this.changeButtonState();
		}
	},
	initialize() {
		this.once('render', this.changeButtonState);
	},
	validateModel() {
		const { name } = this.model.attributes;
		if (!name) {
			return 'укажите название';
		}
	},
	changeButtonState() {
		const $b = this.$('button');
		const errors = this.validateModel();
		if (!errors) {
			$b.prop('disabled', false);
		} else {
			$b.prop('disabled', true);
		}
	},
	events: {
		click(e) {
			const errors = this.validateModel();
			if (errors) { return; }
			this.trigger('click:create');
		}
	}
})

export const CreateProcessView2 = BaseHamburgerView.extend({
	className: 'new-process-dialogue',
	initialize(options) {
		this.mergeOptions(options, ['resolveAction']);
		const parentId = this.getOption('parentProcessId');
		this.model = new ProcessModel({ parentId });
	},	
	childrenViews: [
		AllPropertiesView,
		SubmitView
	],
	childViewEvents: {
		async 'click:create'() {
			if (this.resolveAction) {
				try {
					await this.resolveAction.call(this, this.model.attributes);
					this.destroy();
				} catch(exc) {
					console.error(exc);
				}
			}
		}
	}

});
