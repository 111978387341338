// import { Collection as BaseCollection } from 'core/collection';
import { BaseCollection } from 'base/base-collection';


function buildCollection (model, _options) {

	let { data, dataKey, Collection, Model, options, url, fetch } = _options;

	if (!data) {
		data = (dataKey ? model.get(dataKey) : undefined) || [];
	}

	if (!options && Model) {
		options = { model: Model };
	}

	if (!Collection) {
		Collection = BaseCollection;
	}

	const collection = new Collection(data, options);
	collection.parent = model;

	if (url) {
		collection.url = url;
	}

	if (fetch) {
		collection.fetch();
	}

	return collection;
}

function getPropertyKey (options) {

	const { dataKey, propertyKey } = options;
	if (propertyKey) {
		return propertyKey;
	}
	return dataKey + 'Collection';
}

export function getNestedCollection (model, options = {}) {

	const propertyKey = getPropertyKey(options);

	if (!model[propertyKey]) {
		model[propertyKey] = buildCollection(model, options);
	}

	return model[propertyKey];

}
