
const key = '70e89fe7-0a9a-445e-ab66-e10e96ee0c99';
const url = `//api-maps.yandex.ru/2.1/?apikey=${key}&lang=ru_RU`;
let resolved = false;

export * from './views';

export function loadScript () {
	return new Promise(resolve => {
		if (resolved) {
			resolve();
			return;
		}

		const script = document.createElement('script');
		script.src = url;
		script.onload = () => {
			resolved = true;
			resolve();
		};
		document.body.prepend(script);
	});
}
