
import { ReportPage } from './cmn';

const Page = ReportPage.extend({
	label: 'Отчет по складу',
	reportType: 'store',
	reportLayoutOptions: {
		noTopMenu: true
	}
});

export default Page;
