import { ActivityListItems, ActivityListItems2 } from './activity-list-items';
import { SmartLogsCollection, SmartLogsCollection2 } from '../models/logs-collection';
import { FiltersModel } from './filters-models';

export function getLastFewActivityView(opts = {}) {
	const collection = new SmartLogsCollection();
	
	return {
		class: ActivityListItems,
		filtersModel: new FiltersModel(),
		collection,
		// logConfig: this.getOption('logConfig'),
		// backendCollection: this.model.entity('logs'),
		...opts,
	}
}