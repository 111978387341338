
import { productionApiUrl } from '../full-proc-tree/models';
import Model from 'base/model';
import modals from 'helpers/modals';
import { ShowOrCreateProcessesView } from './cycle-processes';

const emptyCb = () => {};

function getCycleProcessesUrl (prodId, cycleId) {
	return productionApiUrl + '/' + prodId + '/cycles/' + cycleId + '/processes';
}


export function StartProcessWizzard (productModel, cycleModel) {
	const info = new Model({
		cycleId: cycleModel.id
	});
	info.url = () => getCycleProcessesUrl(productModel.id, cycleModel.id);

	info.fetch().then(data => ShowProcesses(productModel, cycleModel, data, info), emptyCb);
}

function ShowProcesses (product, cycle, processes, apiModel) {
	const view = new ShowOrCreateProcessesView({ items: processes, apiModel });
	modals.show(view, { type: 'full', header: 'действующие процессы' });
}
