import View from './view';
import { _ } from 'vendors';
import { invokeValue } from 'utils';
import { BackboneView } from './vendors';
import { stateMixin } from 'mixins/view/state';
// function invoke (arg, context, ...args) {
// 	if (typeof (arg) !== 'function') { return arg; }
// 	isKnownCtor()
// }

function isArray (arg) {
	return arg && typeof (arg) === 'object' && Array.isArray(arg);
}

function buildView (arg, options) {
	if (typeof arg === 'function') {
		console.warn('#ACHTUNG#', 'NOT INVOKED VIEW');
	}
	const viewOptions = _.extend({}, options, _.omit(arg, 'class'));
	const Ctor = arg.class;
	return new Ctor(viewOptions);
}


export const HamburgerView = View.extend({
	childViewEventPrefix: false,

	...stateMixin,

	constructor: function () {
		this._customViews = [];
		View.apply(this, arguments);
		this.on('destroy before:render', () => this.removeCustomViews());
	},

	template: '',

	onRender () {
		this.renderCustomViews();
	},

	renderCustomViews () {
		const contexts = this.buildCustomViewsContexts();
		contexts.forEach(context => {
			this.attachCustomView(context);
		});
	},

	buildCustomViewsContexts () {
		const contexts = this.getCustomViews();

		if (!isArray(contexts) || !contexts.length) {
			return [];
		}

		return contexts.reduce((all, arg) => {
			// console.log('# HAMBU BUILD: ', arg, typeof arg);
			// const argType = typeof arg;
			arg = invokeValue(arg, this, this);
			if (arg === BackboneView || (arg && arg.prototype instanceof BackboneView)) {
				arg = {
					class: arg
				};
			}
			// if (argType === 'function') {
			// 	// console.log('# HAMBU ->', arg);
			// }
			const context = this.buildCustomViewContext(arg);
			// if (argType === 'function') {
			// 	// console.log('# CNTX:', context);
			// }

			if (context) {
				all.push(context);
			}
			return all;
		}, []);
	},

	getCustomViews () {
		return this.getOption('customViews', { args: [this] });
	},
	getCustomViewOptions () {
		return this.getOption('customViewOptions', { args: [this] });
	},
	getCustomViewsContainer () {
		const selector = this.getOption('customViewsContainer');
		if (typeof selector !== 'string') return;
		return this.$(selector).get(0);
	},
	buildCustomViewContext (arg) {
		let options;
		if (!arg) return;

		if (isArray(arg)) {
			options = arg[1];
			arg = arg[0];
		}

		if (!options) options = {};

		const context = {};
		if (arg instanceof BackboneView) {
			context.view = arg;
		} else {
			context.view = buildView(arg, this.getCustomViewOptions());
		}
		if (context.view) {
			this._proxyChildViewEvents(context.view);
			this.setupCustomView(context.view);
		}

		const insertPosition = options.insertPosition;

		if (!options.el && !options.selector) {
			options.el = this.getCustomViewsContainer();
		}

		if (options.selector) {
			context.el = this.$(options.selector).get(0);
			context.insertPosition = insertPosition || 'afterbegin';
		} else if (options.el) {
			context.el = options.el;
			context.insertPosition = insertPosition || 'beforeend';
		} else {
			context.el = this.el;
			context.insertPosition = insertPosition || 'beforeend';
		}

		return context;
	},

	setupCustomView (view) { },
	attachCustomView (context) {
		if (!context || !context.view) return;
		const view = context.view;
		this._customViews.push(view);
		const proxyEvents = this.getOption('proxyChildEvents') === true || !!view.getOption('shouldProxyEvents');
		if (proxyEvents) {
			const viewName = view.getOption('viewName') || 'childView';
			this.listenTo(view, 'all', (event, ...args) => {
				const childEvent = viewName !== 'childView' ? viewName + ':' + event : event;
				if (this._childViewEvents) {
					const cve = this._childViewEvents;
					if (cve[childEvent]) {
						cve[childEvent].apply(this, args);
					}
				}
				if (this._childViewTriggers) {
					const cvt = this._childViewTriggers;
					if (cvt[childEvent]) {
						this.triggerMethod(cvt[childEvent], ...args);
					}
				}
			});
		}

		view.triggerMethod('before:attach');
		context.el.insertAdjacentElement(context.insertPosition, view.el);
		view.triggerMethod('attach');
		view.render();
	},

	removeCustomViews () {
		this._customViews.forEach(view => {
			this.stopListening(view);
			view.destroy();
            // console.log(' >> context', context);
            // context && context.view.destroy();
		});
		this._customViews.length = 0;
	}

});
