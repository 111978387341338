import CollectionView from 'base/collection-view';
import Collection from 'base/collection';

import { ProcessCard } from './ProcessCard';
import { EmptyBlockView } from '../emptyBlock';

export const ProcessesList = CollectionView.extend({
	childView: ProcessCard,
	emptyView: EmptyBlockView,
	initialize () {
		const models = this.model.get('productionProcesses') || [];
		this.collection = new Collection(models);
	}
});
