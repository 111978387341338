import Single from '../single';
import Range from '../range';
import getModelType from './get-model-type';
import ValueError from './value-error';

const createModel = function (data, opts = {}) {
	const type = opts.modelType || getModelType(data);
	if (type === 'range') { return createModel.range(data); } else if (type === 'single') { return createModel.single(data); } else { throw new ValueError('CreateModel', 'Unable to detect model type', data); }
};
createModel.single = function (data) {
	return new Single(data, { parse: true });
};
createModel.range = function (data) {
	return new Range(data);
};
export default createModel;
