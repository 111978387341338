import Page from 'pages/auth';
import Layout from './layout';
import paths from 'helpers/paths';

paths.set('url', 'acc:change:pass', 'acc/changePass');

const LoginPage = Page.extend({
	name: 'change pass page',
	label: 'Изменение пароля',
	route: 'changePass',
	Layout,
	icon: 'identity-changepass',
	employeeNeeded: false
});

export default LoginPage;
