import '../api-config';
import BaseModel from 'base/card-model';
// import BbStore from 'base/bb-store';
import paths from 'helpers/paths';
import action from 'helpers/action';
import mix from 'helpers/mix';
import busData from 'bus/data';



const PropertiesMixin = {
	properties: {
		employeeId: {
			nested: v => v && busData.employees(v),
			display: {
				transform: id => id && busData.employeeName(id),
				ifEmpty: 'не закреплён за сотрудником'
			}
		}
	}
};

const Model = mix(BaseModel).with(PropertiesMixin).extend({
	cardUrlRoot: paths.url('users'),
	urlRoot: paths.api('users'),
	isOnline () {
		const la = Date.info(this.get('lastAction'));
		return la.valid && la.absolute.minutes < 5;
	},
	getName () {
		return this.display('name');
	},
	actions: [
		action('add:to:admins', 'добавить в администраторы', { admin: 'manageAdmins' })
	],
	actionAddToAdmins (action, options = {}) {
		options.admins.create(this.toJSON(), { wait: true });
	}
});

// const StoreModel = BbStore(Model);

export default Model;
