// import cfg from 'app-config';
import enums from 'helpers/enums';

import { BackboneModel as BbModel } from 'vendors';

const trueWords = ['true', 'on', 'да', 'yes'];

export const SchemaItemModel = BbModel.extend({
	defaults: {
		order: 0
	},
	fromString (stringValue) {

		if (stringValue == null) return stringValue;
		if (stringValue === '') return null;

		let value;

		const valueType = this.get('valueType');
		if (valueType === 'number') {

			if (typeof (stringValue) === 'number') {
				return stringValue;
			}
			stringValue = stringValue.replace(',', '.');
			value = parseFloat(stringValue, 10);
			return isNaN(value) ? null : value;

		} else if (valueType === 'boolean') {

			if (typeof (stringValue) === 'boolean') {
				return stringValue;
			}
			return trueWords.indexOf(stringValue.toLowerCase()) > -1;

		} else if (valueType === 'datetime') {

			if (stringValue instanceof Date) {
				return stringValue;
			}
			value = new Date(stringValue);
			return isNaN(value.valueOf()) ? undefined : value;
		} else {
			return stringValue;
		}
	},
	toValue (value) {
		const valueType = this.get('valueType');
		if (valueType === 'enum') {
			let enumType = this.get('valueSubtype');
			enumType = enumType[0].toLowerCase() + enumType.substring(1);
			return enums.get(enumType, value);
		} else {
			return value;
		}
	}
});

