import { _ } from 'vendors';
import Bb from 'backbone';
import CollectionView from 'base/collection-view';
import View from 'base/view';
import busData from 'bus/data';
import smartOpen from 'helpers/smart-open';
import { ProductsCollection /*, productionSalesApiUrl, productionAllInfoApiUrl */ } from './models';
import { StartProcessWizzard } from '../start-proc';
import { componentComparator } from 'mod/productionProducts/models/prod-cycle/prodCycleProd-model';

const _measure = v => busData.measures(v, 'short');

const openCardButton = '<span class="open-card-button" title="открыть карточку в новой вкладке"><i></i></span>';
const openCardMixin = {
	initializeOpenCardMixin () {
		this.delegate('click', '.open-card-button', this.openCardClickHandler.bind(this));
	},
	openCardClickHandler (event) {
		const options = { ctrlKey: !event.ctrlKey };
		const url = this.model.getCardUrl();
		smartOpen(url, options);
	}
};

const LayoutEmptyView = View.extend({
	template: 'производственных циклов нет'
});

const CyclesEmptyView = View.extend({
	className: 'emptyview',
	requestApiTemplate: _.template('<div class="request-api" title="подгрузить данные"><i></i></div>'),
	purchaseTemplate: _.template('<div class="origin-purchase"><i></i></div>'),
	nocycleTemplate: _.template('<div class="no-production-cycles"><i></i><p>циклы отсутствуют.<br/>необходимо завести</p></div>'),
	getTemplate () {
		// console.log(this.model.isForeign());
		if (this.model.isForeign()) {
			return this.purchaseTemplate;
		} else {
			if (this.model.hasNoCycles()) {
				return this.nocycleTemplate;
			} else {
				return this.requestApiTemplate;
			}
		}
	},
	events: {
		'click .request-api' () {
			this.$el.addClass('fetching');
			this.model.fetchCycles();
		}
	}
});

// no-products-in-cycle
const ProductsEmptyView = View.extend({
	className: 'cycle-components-item',
	template: '<div class="no-products-in-cycle"><div>в цикле отсутствует продукция.<br/>необходимо завести</div></div>'
});


const ProductView = View.extend({
	initialize (options) {
		this.mergeOptions(options, ['parentProduct']);
	},
	className: 'production-tree',
	template: `
		<div class="product">
		</div>
		<div class="product-cycles">
		</div>
	`,
	regions: {
		product: '.product',
		cycles: '.product-cycles'
	},
	getProductModel () {
		return this.model;
	},
	getComponentModel () {

	},
	onRender () {
		const product = new ProductInfoView({ model: this.getProductModel(), component: this.getComponentModel(), parentProduct: this.parentProduct });
		this.showChildView('product', product);
		const cycles = new ProductCyclesView({ model: this.model });
		this.showChildView('cycles', cycles, { replaceElement: true });
	}
});

const ComponentProductView = ProductView.extend({
	className: 'cycle-components-item',
	getProductModel () {
		return this.model.getProductModel();
	},
	getComponentModel () {
		return this.model;
	}
});


const CycleProductsView = CollectionView.extend({
	className: 'cycle-components-list',
	emptyView: ProductsEmptyView,
	childView: ComponentProductView,
	initialize (options) {
		this.collection = this.model.getComponentsCollection();
		this.mergeOptions(options, ['parentProduct']);
	},
	childViewOptions () {
		return {
			parentProduct: this.parentProduct
		};
	},
	viewComparator (v1, v2) {
		return componentComparator(v1.model, v2.model);
	}
});

const ProductCycleInfoView = View.extend({
	className: 'product-cycle-info',
	initialize (options) {
		this.mergeOptions(options, ['parentProduct']);
		this.initializeOpenCardMixin();
	},
	// tagName: 'header',
	template: `
		<div class="line-1">${openCardButton}<span><%= name %></span><span class="responsible"><%= responsible %></span><%= launchButtonHtml %></div>
		<div class="line-2"><span title="можно произвести такое количество <%= units %> <%= parentName %>"><%= possible %><b>можно</b></span></div>
	`,
	getTemplateUnits () {
		const p = this.parentProduct;
		const prodUnit = p && p.get('unit');
		const prodMeas = p && _measure(prodUnit);
		return prodMeas || '';
		// let c = this.component;
		// let cycle = this.model;
		// let p = this.parentProduct;

		// let cycleUnit = cycle && cycle.get('productionMeasureId');
		// let cycleMeas = cycle && _measure(cycleUnit);
		// let baseInCycle = cycle && cycle.get('baseUnitsInProductionUnit');
		// let prodUnit = p && p.get('unit');
		// let prodMeas = p && _measure(prodUnit);
		// let units = '';

		// if (cycleUnit || prodUnit) {
		// 	if (cycleUnit) {
		// 		units += cycleMeas;
		// 		if (baseInCycle > 1) {
		// 			units += ' (' + baseInCycle + ' ' + prodMeas + ')';
		// 		}
		// 	}
		// 	if (!units) {
		// 		units += prodMeas;
		// 	}
		// } else {
		// 	//units = '1';
		// }
		// return units;
	},
	templateContext () {
		const launchButtonHtml = '<button class="start-proc" >производство</button>';

		const units = this.getTemplateUnits();

		return {
			responsible: busData.employeeName(this.model.get('responsibleId')),
			possible: this.model.displayPossibleAmount(this.parentProduct),
			parentName: this.parentProduct.get('name'),
			launchButtonHtml,
			units
		};
	},
	events: {
		'click .start-proc' () {
			StartProcessWizzard(this.parentProduct, this.model);
		}
	},
	...openCardMixin
});

const ProductCycleView = View.extend({
	initialize (options) {
		this.mergeOptions(options, ['parentProduct']);
	},
	className: 'product-cycle-item',
	template: '<header></header><div class="cycle-products"></div>',
	regions: {
		info: 'header',
		products: '.cycle-products'
	},
	onRender () {
		const products = new CycleProductsView({ model: this.model, parentProduct: this.parentProduct });
		this.showChildView('products', products);

		const info = new ProductCycleInfoView({ model: this.model, parentProduct: this.parentProduct });
		this.showChildView('info', info, { replaceElement: false });
	}

});

const ProductCyclesView = CollectionView.extend({
	className: 'product-cycles-list',
	initialize () {
		this.collection = this.model.getCyclesCollection();
		// this.listenTo(this.model, 'after:fetch:cycles', this.render);
	},
	childView: ProductCycleView,
	emptyView: CyclesEmptyView,
	emptyViewOptions () {
		return {
			model: this.model
		};
	},
	childViewOptions () {
		return {
			parentProduct: this.model
		};
	}
});

const ProductInfoView = View.extend({
	initialize (options) {
		this.mergeOptions(options, ['component', 'parentProduct']);
		this.initializeOpenCardMixin();
		// if (this.component && this.component.attributes.product.name === '*Кольцо Абуцел-К на складе') {
		// 	console.log('# PROD INFO', this.model);
		// }
	},
	className: 'product-info',
	template: `
		<div class="product-name-line">${openCardButton}<span><%= name %></span></div>
		<div class="amounts">
			<%if (isc) {%><span class="possible" title="хватит на такое количество <%= parentName %>"><%= possibleAmount %><b>можно</b></span><% } %>
			<span class="in-store" title="сейчас на складе"><%= inStore %><b>есть</b></span>
			<%if (isc) {%><span class="for-one" title="нужно на производство <%= units %> <%= parentName %>"><%= produceAmount %><b>надо</b></span><% } %>
		</div>
	`,
	getTemplateUnits () {
		const c = this.component;
		const cycle = c && c.collection.parent;
		const p = this.parentProduct;

		const cycleUnit = cycle && cycle.get('productionMeasureId');
		const cycleMeas = cycle && _measure(cycleUnit);
		const baseInCycle = cycle && cycle.get('baseUnitsInProductionUnit');
		const prodUnit = p && p.get('unit');
		const prodMeas = p && _measure(prodUnit);
		let units = '';

		if (cycleUnit || prodUnit) {
			if (cycleUnit) {
				units += '1 ' + cycleMeas;
				if (baseInCycle > 1) {
					units += ' (' + baseInCycle + ' ' + prodMeas + ')';
				}
			}
			if (!units) {
				units += '1 ' + prodMeas;
			}
		} else {
			units = '1';
		}
		return units;
	},
	templateContext () {
		const c = this.component;
		const p = this.parentProduct;
		const parentName = p ? p.getName() : '';


		const isc = !!c;
		const produceAmount = c ? c.displayProduceAmount() : '';
		const possibleAmount = c ? c.displayPossibleAmount(p) : '&ndash;';
		const units = this.getTemplateUnits();

		return {
			isc,
			inStore: this.model.displayStoreAmount(true, 0, { round: 'floor' }),
			produceAmount,
			possibleAmount,
			parentName,
			units
		};
	},
	...openCardMixin
});


// function containsText (arg, text, lookupkeys) {
// 	if (arg == null) {
// 		return false;
// 	}
// 	if (typeof (arg) === 'string') {
// 		return arg.toLowerCase().indexOf(text.toLowerCase()) > -1;
// 	}
// 	if (typeof (arg) === 'object') {
// 		return Object.keys(arg).some(key => containsText(arg[key], text));
// 	}
// 	return false;
// }

function normtxt (txt, force) {
	if (!txt) return force ? '' : txt;
	return txt.toLowerCase().trim();
}
function smartProductLookup (product, search) {
	const {
		text, textInProduct, textInCycle, textInComponent, employeeId,
		notStrictText, cycleLookup, componentLookup
	} = search;


	if (employeeId != null) {
		if (!product.cycles || !product.cycles.length) return false;
	}

	let prodTextFound;
	if (text && textInProduct) {
		prodTextFound = normtxt(product.name, true).indexOf(text) > -1;
		if (!notStrictText && !prodTextFound) {
			return false;
		}
	}
	let cycleFound;

	if (cycleLookup && product.cycles) {
		cycleFound = product.cycles.some(cycle => {
			if (employeeId != null && cycle.responsibleId !== employeeId) return false;

			let cycleTextFound;
			if (text && textInCycle) {
				cycleTextFound = normtxt(cycle.name, true).indexOf(text) > -1 ||
				normtxt(busData.employeeName(cycle.responsibleId), true).indexOf(text) > -1;
				if (!notStrictText && !cycleTextFound) return false;
			}

			let compFound;
			if (componentLookup && cycle.components) {
				compFound = cycle.components.some(comp => {
					let compTextFound;
					if (text && textInComponent) {
						compTextFound = normtxt((comp.product || {}).name, true).indexOf(text) > -1;
						if (!notStrictText && !compTextFound) return false;
					}
					// сложная логика для более простого добавления других атрибутов для поиска
					return compTextFound === true;
				});
			}
			return cycleTextFound === true || compFound === true;
		});
	}
	return prodTextFound === true || cycleFound === true;
}


export const TreeLayout = CollectionView.extend({
	className: 'production-trees',
	emptyView: LayoutEmptyView,
	childView: ProductView,
	changeFilter (search = {}) {
		const shouldFilter = search.text || search.employeeId;

		if (shouldFilter) {
			this.setFilter(v => {
				// return containsText(v.model.attributes, text);
				return smartProductLookup(v.model.attributes, search);
			});
		} else {
			this.setFilter(null);
		}
	}
});



const InputWithCheckboxes = View.extend({
	className: 'input-with-checkboxes',
	template: `
		<div class="input-holder"><input type="text" name="text" value="<%= text %>" placeholder="введите текст для поиска..." /></div>
		<div class="checkboxes-holder">
			<label><input type="checkbox" <%= chckbx('inProduct') %>><span> в продукции</span></label>
			<label><input type="checkbox" <%= chckbx('inCycle') %>><span> в цикле</span></label>
			<label><input type="checkbox" <%= chckbx('inComponent') %>><span> в компоненте</span></label>
		</div>
	`,
	events: {
		'keyup input' (e) {
			if (e.keyCode === 13) {
				this.done();
			} else {
				this.change();
			}
		},
		'change input' (e) {
			this.change();
		},
		'click button' (e) {
			this.done();
		}
	},
	checksDefaults: {
		inProduct: true,
		inCycle: true
	},

	templateContext () {
		// let checks = Object.assign({}, this.checksDefaults, );
		const fromOpts = _.reduce(['inProduct', 'inCycle', 'inComponent'], (h, k) => {
			const v = this.getOption(k);
			if (v != null) {
				h[k] = v;
			}
			return h;
		}, {});

		const checks = {
			...this.checksDefaults,
			...fromOpts
		};


		// console.log(' -- -- checks', checks, fromOpts);

		return {
			text: this.getOption('text'),
			chckbx: (key) => {
				let out = `name="${key}"`;
				if (checks[key]) {
					out += ' checked="checked"';
				}
				return out;
			}
		};
	},
	_change (event) {
		const newvalue = {};
		this.$('input').each((ind, el) => {
			if (el.type === 'checkbox') {
				newvalue[el.name] = el.checked;
			} else {
				newvalue[el.name] = el.value;
			}
		});
		this.trigger(event, newvalue);
	},

	change () {
		this._change('text:change');
	},
	done () {
		this._change('text:done');
	}

});

const parseQsVal = (val) => {
	const jval = JSON.parse(val || 'null');
	return jval != null ? jval : undefined;
};

const TopView = View.extend({
	className: 'production-manage-section',
	template: `
		<div class="text"></div>
		<div class="employee"></div>
		<button>ok</button>`,
	regions: {
		text: '.text',
		employee: '.employee'
	},
	initialize () {
		this.value = {};
	},
	initialDone () {
		this.textView.done();
	},
	onRender () {
		this.showInputView();
	},
	buildInputView () {
		const ac = this.getOption('ac');

		// let text =



		const user = busData.user();
		const eid = user && user.get('employeeId');
		const text = 'text' in ac.qs ? ac.qs.text : normtxt(busData.employeeName(eid), true);
		const inProduct = parseQsVal(ac.qs.prod); // === 'true' ? 'true' : 'false';
		// console.log(' ++ inProd: ', inProduct, typeof inProduct)
		const inCycle = parseQsVal(ac.qs.cycle);
		const inComponent = parseQsVal(ac.qs.comp);

		const view = new InputWithCheckboxes({ text, inProduct, inCycle, inComponent });

		return view;
	},
	showInputView () {
		const view = this.buildInputView();
		this.textView = view;
		this.showChildView('text', view);
	},
	childViewEvents: {
		'text:change' (text) {
			this.value.text = text;
		},
		'text:done' (text) {
			this.value.text = text;
			this.done();
		}
	},
	done () {
		const search = this.buildSearchContext();
		this.trigger('done', search);
		this.updateDocumentUrl();
	},
	urlMapper: {
		inProduct: 'prod',
		inCycle: 'cycle',
		inComponent: 'comp'
	},
	toUrlParams (val) {
		let text = Object.keys(val).map(key => `${this.urlMapper[key] || key}=${encodeURIComponent(val[key])}`).join('&');
		if (text) {
			text = '?' + text;
		}
		return text;
	},
	updateDocumentUrl () {
		let url = this.toUrlParams(this.value.text) || '';
		const ac = this.getOption('ac');
		url = ac.page.url() + url;
		Bb.history.navigate(url, { trigger: false });
	},
	buildSearchContext () {
		const search = this.value;
		const employeeId = search.employeeId;
		const srchTxt = search.text || { inProduct: false, inCycle: false, inComponent: false };
		let { text, inProduct, inCycle, inComponent } = srchTxt;

		if (text) {
			text = normtxt(text);
		}

		const notStrictText = (inProduct + inCycle + inComponent) > 1;
		const componentLookup = (text && inComponent);
		const cycleLookup = (text && inCycle) || !!employeeId || componentLookup;

		if (text || employeeId) {
			return {
				text,
				textInProduct: inProduct,
				textInCycle: inCycle,
				textInComponent: inComponent,
				employeeId,
				notStrictText,
				cycleLookup,
				componentLookup
			};
		}
	},
	events: {
		'click button' (e) {
			this.done();
		}
	}
});


// DEPRECATED
export const Layout = View.extend({
	template: '<div class="top"></div><div class="tree"></div>',
	regions: {
		top: '.top',
		tree: '.tree'
	},
	onRender () {
		const topView = new TopView({ pageUrl: 'prod' });
		this.showChildView('top', topView);

		const treeView = new TreeLayout({
			className: 'production-trees',
			emptyView: LayoutEmptyView,
			childView: ProductView,
			collection: this.collection
		});

		treeView.listenTo(topView, 'done', text => {
			treeView.changeFilter(text);
		});

		this.showChildView('tree', treeView);
	}
});


export const NewLayout = View.extend({
	template: '<div class="top"></div><div class="tree"></div>',
	regions: {
		top: '.top',
		tree: '.tree'
	},
	onRender () {
		const topView = new TopView({ ac: this.actionContext });
		this.showChildView('top', topView);

		this.processModel();


		const treeView = new TreeLayout({
			className: 'production-trees',
			emptyView: LayoutEmptyView,
			childView: ProductView,
			collection: this.collection
		});

		treeView.listenTo(topView, 'done', text => treeView.changeFilter(text));

		this.showChildView('tree', treeView);

		topView.initialDone();
	},
	processTreeModel () {
		const { cycles, products } = this.model.attributes;
		// let dProducts = new Map();

		// const nestedProducts = new Set();
		const prodCycles = new Map();
		const prodComponents = new Map();
		cycles.forEach(cycle => {
			let prodCycle = prodCycles.get(cycle.productId);
			if (!prodCycle) {
				prodCycle = [];
				prodCycles.set(cycle.productId, prodCycle);
			}
			prodCycle.push(cycle);

			(cycle.components || []).forEach(com => {
				// nestedProducts.add(com.id);
				let prodCom = prodComponents.get(com.id);
				if (!prodCom) {
					prodCom = [];
					prodComponents.set(com.id, prodCom);
				}
				prodCom.push(com);
			});
		});


		const processed = products.reduce((prods, prod) => {
			const cycles = prodCycles.get(prod.id);
			const prodCom = prodComponents.get(prod.id);
			if (!prodCom) {
				prod.cycles = cycles || [];
				prods.push(prod);
			} else {
				prodCom.forEach(com => {
					com.product.cycles = cycles;
				});
			}
			return prods;
		}, []);

		this.collection = new ProductsCollection(processed);
		// console.log('products', processed);
	},
	processModel () {
		const { cycles, products } = this.model.attributes;
		const dProdCycles = new Map();
		for (let x = 0, length = cycles.length; x < length; x++) {
			const cycle = cycles[x];
			let prodCycles = dProdCycles.get(cycle.productId);
			if (!prodCycles) {
				prodCycles = [];
				dProdCycles.set(cycle.productId, prodCycles);
			}
			prodCycles.push(cycle);
		}
		const filtered = products.filter(f => {
			const cycles = dProdCycles.get(f.id);
			f.cycles = cycles;
			if (cycles) return true;

			return f.origin === 'production';


			// return !!f.cycles;
		});
		this.collection = new ProductsCollection(filtered);
	},
	__processModel () {
		const { /* cycles, */ products } = this.model.attributes;

		// const dProds = products.reduce((st, item) => {
		// 	st[item.id] = item;
		// 	return st;
		// }, {});


		const components = [];
		const dProdCycles = {};
		// const dCycles = cycles.reduce((st, item) => {
		// 	st[item.id] = item;

		// 	if (!dProdCycles[item.productId]) {
		// 		dProdCycles[item.productId] = [];
		// 	}
		// 	dProdCycles[item.productId].push(item);

		// 	item.components.forEach(com => {
		// 		if (com.product.cycles) return;
		// 		if (com.product.origin !== 'production') return;
		// 		components.push(com);
		// 	});

		// 	const prod = dProds[item.productId];
		// 	if (!prod) {
		// 		console.log('not found product for ', item);
		// 		return;
		// 	}
		// 	if (!prod.cycles) {
		// 		prod.cycles = [];
		// 	}
		// 	prod.cycles.push(item);

		// 	return st;
		// }, {});

		components.forEach(com => {
			com.product.cycles = dProdCycles[com.id];
		});

		// dProdCycles = cycles.reduce((res, cyc) => {
		// 	if (!res[cyc.productId]) {
		// 		res[cyc.productId] = [];
		// 	}
		// 	res[cyc.productId].push(cyc);
		// 	return res;
		// }, {});

		// let dComps = {};

		// cycles.forEach(cycle => {
		// 	let prod = dProds[cycle.productId];
		// 	if (!prod) {
		// 		console.log('not found product for ', cycle);
		// 		return;
		// 	}
		// 	if (!prod.cycles) {
		// 		prod.cycles = [];
		// 	}
		// 	prod.cycles.push(cycle);
		// 	cycle.components.forEach(com => {
		// 		if (com.product.origin !== 'production') return;
		// 		if (com.product.cycles) return;

		// 	});
		// });
		this.collection = new ProductsCollection(products);
		// console.log('products', products);
	}
});
