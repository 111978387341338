import { _ } from 'vendors';
import Object from 'base/object';

const Bus = Object.extend({
	// clear() {
	// 	this.products = {};
	// 	this.batches = {};
	// },
	setProductBatch (productId, batchId, batch) {
		if (!this.value.productBatches) {
			this.value.productBatches = {};
		}
		const store = this.value.productBatches;
		store[productId] = batchId;
		this.productBatchesValid = _.every(store, (bid, pid) => !!bid && !!pid);
		// this.products[productId] = batchId;
		// this.batches[productId] = batch;
	}
});
export const bus = new Bus();
