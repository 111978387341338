import { HamburgerView } from 'base/view-hamburger';
// import { ListView } from './list-view';

// import Selector from 'helpers/selector';
import { Selector } from 'helpers/selector2';
import { SelectorView } from './SelectorView';
import { ActionsView } from './ActionsView';
import { buildApiUrl } from './utils';
import { BackboneModel } from 'vendors';
import busEdit from 'bus/edit';
// import { SelectorView, ActionsView } from '../common';


export const TransferLayoutView = HamburgerView.extend({
	baseClassName: 'staff-emp ui2-flex-column',

	initialize () {
		this.selector = this.buildSelector(Selector);
		this.setupSelector(this.selector);
		console.log('# LAYOUT', this);
	},
	setupSelector (selector) { },
	buildSelector (Selector) {
		return new Selector({
			multiple: true
		});
	},
	getTabsButtons () {
		const buttons = this.getOption('tabsButtons', true) || [];
		buttons.unshift({
			id: 'all',
			name: 'все'
		});
		buttons.push({
			id: 'selected',
			name: 'отобранные'
		});
		return buttons;
	},
	customViews () {
		return [
			{
				class: SelectorView,
				tabsButtons: this.getTabsButtons(),
				// [
				// 	{ id: 'all', name: 'все' },
				// 	{ id: 'selected', name: 'отобранные' }
				// ],
				selector: this.selector,
				onTabClick: model => this.setListFilter(model)
			},
			{
				class: this.ListView,
				model: this.model,
				selector: this.selector,
				viewName: 'listView'
			},
			{
				class: ActionsView,
				selector: this.selector,
				viewName: 'actionsView'
			}
		];
	},
	setupCustomView (view) {
		const viewName = view.getOption('viewName');
		switch (viewName) {
		case 'actionsView':
			this.listenTo(view, 'transfer:click', () => this.triggerMethod('transfer:click'));
			break;
		case 'listView':
			this.listView = view;
			break;
		}
	},
	getFilterOptions () {

	},
	_getFilterOptions () {
		const selector = this.selector;
		const options = Object.assign({ selector, employee: this.model }, this.getFilterOptions());
		return options;
	},
	setListFilter (model) {
		const filter = model.get('filter') || model.id;
		const selector = this.selector;
		selector.filter = this._createListFilter(filter);
		selector.trigger('filter:change');
	},
	_createListFilter (filter) {
		const selector = this.selector;

		if (filter === 'all') {

			return null;

		} else if (filter === 'selected') {

			return v => selector.isSelected(v);

		} else if (typeof filter === 'function') {

			const fltrOptions = this._getFilterOptions();
			console.error('### === ', fltrOptions);
			return v => filter(v, fltrOptions);

		} else {

			return true;

		}

	},
	childViewEvents: {
		'transfer:click' () {
			console.log('OP-PA!');
		}
	},
	onTransferClick () {
		this.takeTransferAction();
	},
	async takeTransferAction () {
		const attrs = await this.getTransferChangePayloadAsync();
		console.log('attrs!', attrs);
		const add = this.getOption('resourceName') + '/transfer';
		const model = new BackboneModel();
		model.url = buildApiUrl(this.model.id, add);
		await model.save(null, { attrs, method: 'POST' });
		await this.listView.collection.fetch({ remove: true, merge: true });
	},
	async getTransferChangePayloadAsync () {
		const options = {
			controlType: 'employeesSelect',
			applyLabel: 'выбрать',
			resetButton: false,
			cancelButton: true,
			cancelLabel: 'отставить'
			// controlOptions: {
			// 	rejectHard: false
			// }
		};
		const transferTo = await busEdit.do(options);
		const idList = this.selector.value(m => m.id);
		return { transferTo, idList };
	}
});
