import BasePage from 'pages/auth';
import BuildChild from './build';
import { EntriesView } from './EntriesView';
// import Layout from './layout';
// import Collection from 'base/collection';
// import { departmentsApiUrl } from './models';
// import DepartmentPage from './department';

const Page = BasePage.extend({
	moduleRights: { productProduction: 'view' },
	label: 'журнал производства',
	route: 'builds',
	faIcon: 'list-alt',
	Layout: EntriesView,
	wrapLayout: false,
	// onBeforeStart () {
	// 	if (!this.collection) {
	// 		const col = new Collection();
	// 		col.url = departmentsApiUrl;
	// 		this.addCollection(col);
	// 		this.addStartPromise(col.fetchIfNot());
	// 	}
	// },
	children: [BuildChild]
});

export default Page;
