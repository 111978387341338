import { $ } from 'vendors';
// import ModelProperties from 'components/ui-model-card/properties';
// import h from 'components/ui-model-card/helpers';
import ControlsMixin from 'components/controls/mixin';
import CollectionView from 'base/collection-view';
import View from 'base/view';
import busData from 'bus/data';
import Collection from 'base/collection';
import { Object } from 'backbone.marionette';
import Model from 'base/model';
import _ from 'underscore';

const bus = new Object();

const measure = v => busData.measures(v, 'short');

function getAmountText (amount, unit, priceUnit, priceUnitsInPurchaseUnit) {
	let amountText = _.displayNum(amount, 4) + ' ' + priceUnit;
	if (unit !== priceUnit) {
		amountText += ` (${_.displayNum(amount * priceUnitsInPurchaseUnit, 4)} ${unit})`;
	}
	return amountText;
}


const AcceptModel = Model.extend({
	initialize () {
		this.on('change', () => {
			this.trigger('refresh');
			bus.trigger('change');
		});
	},
	setParties (col) {
		this.parties = col;
		this.listenTo(col, 'change add remove', this._onPartiesChange);
        // this.on('change:remainsAmount', this._onExpectedAmountChange)
	},
	_onPartiesChange () {
		const sum = this.parties.reduce((memo, model) => {
			const add = (model.validate() && model.get('amount')) || 0;
			memo += add;
			return memo;
		}, 0);
        // bus.trigger('change:entered:amount', sum);
		this.set({
			enteredAmount: sum
            // remainsAmount: this.get('expectedAmount') - sum,
            // acceptType: undefined,
		});
        // this.trigger('refresh');
        // bus.trigger('change', sum);
	}
});

const PartyModel = Model.extend({
	validate () {
		const { partyName, amount } = this.attributes;
		return partyName && partyName.length > 0 && amount && amount > 0;
	}
});

const ProductInfoView = View.extend({
	tagName: 'ul',
	className: 'info-by-lines',
	template: `
        <!--<li class="centered"><div><dt>ожидается:</dt><dd><%= _.displayNum(amount,4) %> <%= unit %></dd></div></li>-->
        <li class="centered"><div><span>укажите партию и количество:</span></div></li>
    `,
	templateContext () {
		return {
			unit: measure(this.model.get('unitMeasureId'))
		};
	}
    // initialize() {
    //     console.log('product info view', this.model.attributes);
    // }
});



const AcceptInfoView = View.extend({
	tagName: 'ul',
	className: 'info-by-lines bottom-border',
	template: `
        <li class="centered">
            <dl class="three">
                <span class="cell">
                    <dt>всего к поставке:</dt>
                    <dd><%= amountText %></dd>
                </span>            
                <span class="cell">
                    <dt>уже принято:</dt>
                    <dd><%= acceptedAmountText %></dd>
                </span>
                <span class="cell">
                    <dt>ожидается:</dt>
                    <dd><%= expectedAmountText %></dd>
                </span>
            </dl>
        </li>
        <li class="centered two">
            <div class="cell with-popup-disclaimer">
                <label class="radio-label"><input type="radio" name="acceptType" value="final" <%= acceptType === 'final' ? "checked" : '' %>><span> финальная поставка</span></label>
                <div class="popup-disclaimer">
                    <small>
                        Означает, что по этому процессу закупки вы получили закупаемую продукцию в полном объеме.<br>
                        Можно указать принимаемое количество больше или меньше ожидаемого, в таком случаи ожидаемое количество будет скорректированно автоматически.
                    </small>
                </div>
            </div>
            <div class="cell with-popup-disclaimer">
                <label class="radio-label"><input type="radio" name="acceptType" value="partial" <%= acceptType === 'partial' ? "checked" : '' %>><span> частичная поставка</span></label>
                <div class="popup-disclaimer">
                    <small>
                        Означает, что по этому процессу закупки вы получили закупаемую продукцию не в полном объеме и ожидается последующая паставка/поставки в рамках этого процесса закупки.<br>
                        Можно указать принимаемое количество равным ожидаемому.
                    </small>
                </div>
            </div>
        </li>
    `,
	events: {
		'click input[name=acceptType]' (e) {
			this.model.set('acceptType', $(e.target).closest('input').val());
			this.model.trigger('refresh');
		}
	},
	modelEvents: {
		refresh: 'render'
	},

	templateContext () {
        // let amountText =  _.displayNum(amount, 4) + ' ' + priceUnit;
        // if (unit != priceUnit) {
            //     amountText += ` (${_.displayNum(amount * priceUnitsInPurchaseUnit, 4)} ${unit})`;
            // }
            // let acceptedAmountText = _.displayNum(acceptedAmount, 4) + ' ' + priceUnit;
            // let expectedAmountText = _.displayNum(expectedAmount, 4) + ' ' + priceUnit;


		const { unit, priceUnit, amount, acceptedAmount, expectedAmount, priceUnitsInPurchaseUnit } = this.model.attributes;
		const amountText = getAmountText(amount, unit, priceUnit, priceUnitsInPurchaseUnit);
		const acceptedAmountText = getAmountText(acceptedAmount, unit, priceUnit, priceUnitsInPurchaseUnit);
		const expectedAmountText = getAmountText(expectedAmount, unit, priceUnit, priceUnitsInPurchaseUnit);


		return {
			amountText,
			acceptedAmountText,
			expectedAmountText
		};
	}
});

const ResultInfoView = View.extend({
	tagName: 'ul',
	className: 'info-by-lines',
	template: `
    <li class="centered"><div><span>изменения после принятия продукции:</span></div></li>
    <li class="centered">
        <dl class="three">
            <span class="cell">
                <dt>всего к поставке:</dt>
                <dd><%= amountText %></dd>
            </span>            
            <span class="cell">
                <dt>уже принято:</dt>
                <dd><%= acceptedAmountText %></dd>
            </span>
            <span class="cell">
                <dt>ожидается:</dt>
                <dd><%= expectedAmountText %></dd>
            </span>
        </dl>
    </li>

    `,
	modelEvents: {
		refresh: 'render'
	},
	templateContext () {
        // let { unit, amount, acceptedAmount, enteredAmount, expectedAmount, acceptType } = this.model.attributes;
		// const d = v => _.displayNum(v, 4);
		// const du = v => d(v) + ' ' + unit;
		const ct = (t, c) => c ? `<span class="color-${c}">${t}</span>` : t;
		// const duc = (v, c) => ct(du(v), c);
        // let amountText, acceptedAmountText, expectedAmountText;
        // acceptedAmountText = du(acceptedAmount + enteredAmount);

        // if (acceptType === 'final') {
        //     expectedAmountText = "поставка завершена";
        //     if (enteredAmount === expectedAmount) {
        //         amountText = du(amount);
        //     } else if (enteredAmount < expectedAmount) {
        //         amountText = duc(acceptedAmount + enteredAmount, 'red');
        //     } else if (enteredAmount > expectedAmount) {
        //         amountText = duc(acceptedAmount + enteredAmount, 'red');
        //     }
        // } else if (acceptType === 'partial') {
        //     expectedAmountText = ct("следующая поставка","red");
        //     if (enteredAmount === expectedAmount) {
        //         amountText = duc(amount, "red");
        //     } else if (enteredAmount < expectedAmount) {
        //         amountText = du(amount);
        //     } else if (enteredAmount > expectedAmount) {
        //         amountText = duc(acceptedAmount + enteredAmount, 'red');
        //     }

        // } else {
        //     amountText = acceptedAmountText = expectedAmountText = ct("< укажите тип поставки >", "red");
        // }


		let { unit, priceUnit, amount, acceptedAmount, expectedAmount, enteredAmount, priceUnitsInPurchaseUnit, acceptType } = this.model.attributes;

		let amountTextAlt, amountColor,
			expectedAmountTextAlt, expectedAmountColor,
			acceptedAmountTextAlt, acceptedAmountColor;

		acceptedAmount += enteredAmount;

		if (acceptType === 'final') {
			if (enteredAmount !== expectedAmount) {
				amount = acceptedAmount;
				amountColor = 'red';
			}
			expectedAmountTextAlt = 'поставка завершена';
		} else if (acceptType === 'partial') {
            // expectedAmountText = ct("следующая поставка","red");
			expectedAmountColor = 'red';
			expectedAmountTextAlt = 'следующая поставка';
			if (enteredAmount === expectedAmount) {
				// TODO: что в этом случаи?
			} else if (enteredAmount < expectedAmount) {
				// TODO: а в этом что?
			} else if (enteredAmount > expectedAmount) {
				amount = acceptedAmount + enteredAmount;
				amountColor = 'red';
			}
		} else {
			amountTextAlt = acceptedAmountTextAlt = expectedAmountTextAlt = ct('< укажите тип поставки >', 'red');
		}

		const amountText = ct(amountTextAlt || getAmountText(amount, unit, priceUnit, priceUnitsInPurchaseUnit), amountColor);
		const acceptedAmountText = ct(acceptedAmountTextAlt || getAmountText(acceptedAmount, unit, priceUnit, priceUnitsInPurchaseUnit), acceptedAmountColor);
		const expectedAmountText = ct(expectedAmountTextAlt || getAmountText(expectedAmount, unit, priceUnit, priceUnitsInPurchaseUnit), expectedAmountColor);

		return {
			amountText,
			acceptedAmountText,
			expectedAmountText
		};


        // return {
        //     amountText,
        //     acceptedAmountText,
        //     expectedAmountText
        // }
	}
});

const EditWithLabel = View.extend({
	className: 'edit-box',
	template: '<label><%= label %></label><div><input class="form-control" type="<%= inputType %>"></div>',
	cssClassModifiers: [
		(m, v) => v.error ? 'has-error' : null
	],
	templateContext () {
		return {
			label: this.getOption('label'),
			inputType: this.getOption('controlType')
		};
	},
	events: {
		'input input' () {
			const val = this.getControlValue();
			this.setModelValue(val);
		}
	},
	initialize () {
		this.listenTo(bus, 'change', this._onBusChange);
	},
	onBeforeRender () {
		this._onBusChange();
	},
	_onBusChange () {
		this.error = !this.validate();
		this.refreshCssClass();
	},
	_normalizeValue (value) {
		if (value === '' || value == null) {
			return;
		}
		const isNumber = this.getOption('controlType') === 'number';
		if (isNumber) {
			value = parseFloat(value.replace(',', '.'), 10);
			if (isNaN(value)) { return; }
		}
		return value;
	},
	getControlValue () {
		const value = this.$('input').val();
		return this._normalizeValue(value);
	},
	setModelValue (value) {
		value = this._validate(value) ? value : undefined;
		this.model.set(this.getOption('property'), value);
	},
	_validate (value) {
		const isNumber = this.getOption('controlType') === 'number';
		if (isNumber) {
			return typeof (value) === 'number' && value > 0;
		} else {
			return value && value.trim().length > 0;
		}
	},
	validate () {
		const value = this.getControlValue();
		return this._validate(value);
	}
});

const DestroyView = View.extend({
	tagName: 'button',
	className: 'remove-button',
	template: '<i></i>',
	triggers: {
		click: 'remove:party'
	}
});

const PartyView = CollectionView.extend({
	className: 'party-item',
	tagName: 'li',
	initialize () {
		const prod = this.product = this.getOption('product');
		this.unitId = prod.get('priceUnitMeasureId') || prod.get('unitMeasureId');
	},
	customViews () {
		const index = this.model.collection.indexOf(this.model);
		return [
			new EditWithLabel({
				className: 'edit-box edit-party-box',
				label: 'номер партии',
				controlType: 'text',
				model: this.model,
				property: 'partyName'
			}),
			new EditWithLabel({
				label: 'количество ' + measure(this.unitId),
				className: 'edit-box edit-number-box',
				controlType: 'number',
				model: this.model,
				property: 'amount'
			}),
			index > 0 ? new DestroyView() : new View({ tagName: 'i', template: '' })
		];
	},
	childViewEvents: {
		'remove:party' () {
			bus.trigger('remove:party', this.model);
		}
	}
});

const PartiesView = CollectionView.extend({
	tagName: 'ul',
	className: 'overflow-auto parties-list',
	initialize () {
		this.collection = new Collection([{}], { model: PartyModel });
		this.model.setParties(this.collection);
		this.listenTo(bus, 'add:party', () => this.collection.add({}));
		this.listenTo(bus, 'remove:party', (model) => this.collection.remove(model));
	},
	childView: PartyView,
	childViewOptions () {
		return {
			product: this.getOption('product')
		};
	}
});

const AddPartyView = View.extend({
	className: 'add-party-box',
    // tagName: 'ul',
	template: '<div><button class="add-column"> + добавить партию</button></div>',
	events: {
		'click button' () {
			bus.trigger('add:party');
		}
	}
});

export const AcceptProductView = CollectionView.mixWith(ControlsMixin).extend({
	className: 'accept-process-product-dialogue flex-column-auto',
	customViews () {
		const product = this.getOption('product');
		return [
			new AcceptInfoView({ model: this.model }),
			new ProductInfoView({ model: product }),
			new PartiesView({ product, model: this.model }),
			new AddPartyView(),
			new ResultInfoView({ model: this.model })
		];
	},
	initialize () {
		const product = this.getOption('product');
		const { acceptedAmount = 0, amount = 0 } = product.attributes;

		const expected = amount - acceptedAmount;
		const unitId = product.get('unitMeasureId');
		const priceUnitId = product.get('priceUnitMeasureId') || unitId;
		this.model = new AcceptModel({
			unitId,
			unit: measure(unitId),
			priceUnitId,
			priceUnit: measure(priceUnitId),
			priceUnitsInPurchaseUnit: product.get('priceUnitsInPurchaseUnit'),
			expectedAmount: expected,
			enteredAmount: undefined,
			acceptedAmount,
			amount,
			acceptType: undefined
		});
		this.listenTo(bus, 'change', () => {
			this.triggerChange();
		});
	},
	validate () {
        // console.log('-= validat =-');
		const { acceptType, enteredAmount } = this.model.attributes;
		const firstChannce = acceptType && enteredAmount > 0 && this.model.parties.length > 0;
		if (!firstChannce) {
			this.trigger('switch:resolve', 'lock');
			console.log('first chance', firstChannce);
			return false;
		}
        // if (!acceptType || !(enteredAmount > 0) || !this.model.parties.length) return false;
		const secondChance = this.model.parties.every(party => party.validate());
        // console.log('second chance', secondChance);
		if (secondChance) {
			this.trigger('switch:resolve', 'unlock');
		} else {
			this.trigger('switch:resolve', 'lock');
		}
		return secondChance;
	},
	onResolve () {
		console.log('resolved');
	}
});
