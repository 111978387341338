import { EntityPageLayout, ButtonsGroup, PageButtonsGroup, ActionsButtonsGroup } from "components/entity-page-layout";
import { actionsApi } from "apis/actions";
import smartOpen from 'helpers/smart-open';
import { designSwitch } from "./designSwitch";

export default EntityPageLayout.extend({
	thisClassName: 'process-entity',
	asideOptions() {
		return {
			buttonGroups: this.getAsideButtonGroups()
		}
	},
	getAsideButtonGroups() {

		const isRoot = this.getOption('isRoot');

		const id = {
			class: ButtonsGroup,
			thisClassName: 'white bold',
			buttons:  v => [
				{ 
					text: 'скопировать ссылку #' + v.model.get('iId'), icon: 'fa fa-copy', title: 'скопировайт', tagTitle: 'скопировать ссылку',
					action() {
						const url = document.location.toString();

						const el = document.createElement("input");
						el.value = url;
						document.body.append(el);
						el.select();
						try {
							document.execCommand('copy');
						} catch (error) {
								console.error(error);
						} finally {
								el.remove();
						}
				
					} 
				}
			]
		};

		const parent = this.model.get('parent');

		const parentView = parent ? {
			class: ButtonsGroup,
			thisClassName: 'white bold',
			buttons: [
				{
					text: parent.name,
					action(e) {
						smartOpen('procs/' + (parent.iId || parent.id), e);
					}
				}
			]
		} : undefined;

		const pages = {
			class: PageButtonsGroup,
			thisClassName: 'pages',
		};

		// let pageActions = actionsApi.getActions(this.page.model, { placedAt: 'page' });
		const allowed = [
			'upload:files', 'show:tasksBoard', 
			// 'add:to:board', 'remove:from:board', 
			'complete:process', 'cancel:process', 'restart:process'
		];
		
		let pageActions = this.page.model.getActions({
			filter: (m) => allowed.indexOf(m.id) > -1
		});

		

		const actions = pageActions.length ? {
			class: ActionsButtonsGroup,
			thisClassName: 'actions',
			actions: pageActions
		} : undefined;


		const oldlayout = isRoot ? {
			class: ButtonsGroup,
			thisClassName: 'white bold',
			buttons: [
				{
					text: 'переключиться на старую версию',
					action: () => {
						designSwitch()
					}
				}
			]
		} : undefined;

		const views = [
			id,
			parentView,
			pages,
			actions,
			oldlayout
		];

		console.error('::buttons::', isRoot);

		return views;
	},
	initialize() {
		this.listenTo(this.model, 'change:completeState', this.render);
	}
});