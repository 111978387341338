import paths from 'helpers/paths';


paths.set('api', 'token', 'auth/token', { prefix: '', version: false });


/*
Yat.identity.tokenExpireOffset = 1200000; // 20 mins
Yat.identity.tokenUrl = paths.api('token');



Yat.identity.parseToken = function (token) {
  if (token == null) return token;
  if (token != null && _.isObject(token)) {
    token.expires = new Date(token.expiresJson);
    if (isNaN(token.expires.valueOf())) { token.expires = new Date((new Date(token.issuedJson)).valueOf() + parseInt(token.expires_in, 10) * 1000); }
    if (isNaN(token.expires.valueOf())) { token.expires = new Date(Date.now() + (token.expires_in * 1000)); }
  }
  return token;
};
*/
