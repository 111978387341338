import { _ } from 'vendors';
import mixin from 'components/controls/mixin';
import View from 'base/view';
const Control = mixin(View);
const Textarea = Control.extend({
	tagName: 'textarea',
	template: _.template('<%= text %>'),
	className: 'bigtext',
	initialize () {
		this.prepareInitialValues();
	},
	events: {
		'keypress' (e) {
			if (e.keyCode === 10 && e.ctrlKey) {
				this.updateValue();
				this.triggerDone();
			}
		},
		'input' () {
			this.updateValue();
		},
		'blur' () {
			this.updateValue();
		}
	},
	updateValue () {
		const value = this.$el.val();
		if (this.value !== value) {
			this.value = value;
			this.triggerChange(value);
		}
	},
	templateContext () {
		return {
			text: this.getValue()
		};
	}

});

export const PlaceholderedTextarea = Control.extend({
	className: 'placeholdered',
	template: _.template('<textarea/>'),
	regions: {
		textarea: { el: 'textarea', replaceElement: true }
	},
	showInput () {
		const opts = _.extend({}, this.options, {
			proxyTo: this,
			controlName: 'control'
		});
		const view = new Textarea(opts);
		this.showChildView('textarea', view);
	},
	onRender () {
		this.showInput();
		this.trigger('content:ready');
	},
	attributes () {
		const attrs = _.extend({}, this.getOption('attrs'), {
			'data-placeholder': this.getOption('label')
		});
		return attrs;
	},
	controlsEvents: {
		'control:change' (...args) {
			this.triggerChange(...args);
		},
		'control:done' (...args) {
			this.triggerDone(...args);
		},
		'control:validate:error' (...args) {
			this._triggerEvent('validate:error', ...args);
		}
	}
});

export default Textarea;
