import { _ } from 'vendors';

function validateProperty (value, opts) {
	opts.errors || (opts.errors = []);
	opts.checked || (opts.checked = []);
	const propertyErrors = [];
	const { rule, errors, property } = opts;

	const isEmpty = value == null || value === '';

	if (isEmpty && rule.required) {
		propertyErrors.push(rule.requiredMessage || 'required');
	} else if (_.isFunction(rule.validate)) {
		const result = rule.validate.call(this, value, opts);
		if (_.isArray(result)) { propertyErrors.push(...result); }
	}


	opts.checked.push(property);
	errors.push(...propertyErrors);

	if (propertyErrors.length) { return propertyErrors; }
}

export default function validateModel (model, attrs, opts = {}) {
	if (attrs == null) { attrs = model.toJSON(); } else if (_.isString(attrs)) { attrs = { [attrs]: model.get(attrs) }; }

	if (!_.isObject(attrs)) return;

	opts.errors || (opts.errors = []);
	opts.checked || (opts.checked = []);
	opts.model = model;
	_(attrs).each((value, property) => {
		const cfg = model.getPropertyConfig(property) || {};
		const rule = cfg.validation || {};
		if (!rule) { return; }

		validateProperty.call(model, value, _.extend({ property, attrs, rule, cfg }, opts));
	});

	_(model.properties).each((cfg, property) => {
		if (opts.checked.indexOf(property) > -1) return;
		if (!(cfg.validation && cfg.validation.required)) return;

		validateProperty.call(model, model.get(property), _.extend({ property, attrs, rule: cfg.validation, cfg }, opts));
	});

	if (opts.errors.length) { return opts.errors; }
}
