function upperFirst (txt) {
	if (!txt) return txt;
	return txt.substr(0, 1).toUpperCase() + txt.substr(1);
}

export function buildTabsPageChildren (tabs, MainLayout, defaultOptions) {
	if (!tabs) return [];
	return tabs.reduce((children, tab) => {

		if (tab.id === 'root') return children;

		const options = Object.assign({}, defaultOptions, tab.pageChildOptions);

		const child = {
			id: tab.id,
			label: upperFirst(tab.name),
			Layout: MainLayout,
			...options
            // filter: () => user.checkRights({ productsStore: 'view' }),
		};

		// console.log(' -- -- add child -- -- ', child, options);

		children.push(child);

		return children;
	}, []);
}
