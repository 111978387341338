import CollectionView from 'base/collection-view';
import Collection from 'base/collection';

import { HeaderTab } from './header-tab';
import { TabModel } from './tab-model';

import smartOpen from 'helpers/smart-open';

export const BlockTabsHeader = CollectionView.extend({
	tagName: 'header',
	className: 'tabs bg-main-dark',
	initialize () {
		this.initializeCollection();
		this.initializeActiveTab();
	},
	initializeCollection () {
		this.collection = new Collection(this.getOption('tabs'), { model: TabModel });
	},
	initializeActiveTab () {
		const start = this.getOption('startTab');
		if (start) {
			const model = this.collection.get(start);
			if (model) {
				model.makeActive();
			}
		}
	},
	childView: HeaderTab,
	makeTabActiveOnClick: false,
	childViewEvents: {
		'tab:click' (view, event) {
			if (this.getOption('makeTabActiveOnClick')) {
				view.model.makeActive();
			}
			this.triggerMethod('tab:click', view.model, event);
		}
	}
});

export function buildTabsHeader (startTab, tabs, ac, isRoot) {
	return new BlockTabsHeader({
		startTab,
		tabs,
		// makeTabActiveOnClick: true,
		onTabClick (tab, event) {
			if (isRoot && tab.id === 'root') return;
			const root = isRoot ? ac.page : ac.page.getParent();
			let url;
			if (!isRoot && tab.id === 'root') {
				url = root.url(ac.args);
			} else {
				let route = root.getRoute() + '/' + tab.id;
				route = root._normalizeRoute(route);
				url = root.parseUrl(route, ac.args);
			}
			smartOpen(url, event);
		}
	});
}
