import 'mods/processes/common/models/processes-board';
import BasePage from 'pages/auth';
// import Layout from './layout';
import NewLayout from './new-layout';
import Model from 'mod/commonProcesses/models/process';
import busModels from 'bus/models';
import { optionsMixin } from 'mixins/options';
// import ProcessTasksBoard from './tasksBoard';
// import PageError from 'pages/error';
// import busApp from 'bus/app';
// import paths from 'helpers/paths';

export const BaseProcessPage = BasePage.extend({
	...optionsMixin,
	initialize() {
		// this.on('all', e => console.log('-> pe	:', e, this.cid, this.model?.id ));
	},
	id: 'commonprocess:card',
	label () { return this.model && this.model.display('name'); },
	// route: ':id',
	// skipMenu: true,
	Layout: NewLayout,
	// layoutOptions(page, ac) {
	// 	if (!arguments.length){ return; }
	// 	//console.error(arguments);
	// 	const entityPageModel = this.getEntityPageModel(ac);
	// 	console.log(entityPageModel);
	// 	return { entityPageModel };
	// },
	shouldUseNewLayout: true,

	getHeader () {
		return this.getOption('header', true) || this.getOption('label', true);
	},

	getLayoutOptions(ac) {
		const root = this.getEntityPageRoot();
		const isRoot = this === root;
		const entityPageModel = this.getEntityPageModel(ac);
		const content = this.getOption('content', false);
		const opts = Object.assign({ entityPageModel, isRoot, page: this, content, model: this.model, ac }, this.getOption('layoutOptions', { invokeArgs: [this, ac] }));

		return opts;
	},
	// children: [ProcessTasksBoard],
	socketGroups: (m) => 'commonprocess:' + m.id.toString().toLowerCase(),

	onBeforeStart (ac) {
		this.initializeModel(ac.args.id);
		const board = busModels.request('processes:board:instance');
		this.addStartPromise(board.getModelIds({ fetchIfNot: true }));
	},

	initializeModel (id) {
		
		if (this.model?.id !== id) {
			if (this.model) {
				this.stopListening(this.model);
			}
			this.model = new Model({ id })			
		}

		const root = this.getEntityPageRoot();
		
		const model = root.model = this.model; // || new Model({ id });

		this.addStartPromise(model.fetchIfNot());
		

		// this.addStartPromise(new Promise((resolve, reject) => {
		// 	model.fetchIfNot().then(
		// 		() => { resolve(); },
		// 		(xhr) => { reject(xhr); }
		// 	);
		// }));
	},

	getEntityPageModel(ac) {
		const root = this.getEntityPageRoot();
		if (!root) { return {}; }
		return root.buildEntityPageModel(ac);
	},

	getEntityPageRoot() {
		if (this.getOption('entityPageRoot')) {
			return this;
		}
		const parent = this.getParent();
		if (!parent || !parent.getEntityPageRoot) { return; }
		return parent.getEntityPageRoot();
	},

	buildPageModel(ac) {
		const menuName = this.getOption('menuName');
		return {
			id: this.id,
			label: this.getLabel(ac),
			menuName,
			url: this.url(ac.args),
			order: this.getOption('order'),
			isAvailableForSubmenu: this.getOption('isAvailableForSubmenu', true)
		}
	},

	buildEntityPageModel(ac) {
		const model = this.buildPageModel(ac);
		model.root = true;
		let children = this.getStartableChildren() || [];
		
		model.children = children.map(child => {
			if (child.buildPageModel) {
				return child.buildPageModel(ac);
			} else {
				return this.buildPageModel.call(child, ac);
			}
		});
		return model;
	},
	isAvailableForSubmenu: true,

	modelIn(...inArgs) {
		const model = this.getGroupModel();
		if (!model) { return false; }
		const res = inArgs.indexOf(model.get('type')) > -1;
		return res;
	},

	getGroupModel() {
		if (this.model) {
			return this.model;
		}
		return this.getEntityPageRoot()?.model;
	},

	getNewLayoutView(ac) {
		const Ctor = this.getOption('Layout', true);
		const options = this.getLayoutOptions(ac);
		return new Ctor(options);
	}

});

