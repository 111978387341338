import Base from 'base/collection-view';
import Item from './list-item';
import Empty from 'components/empty-view';

export default Base.extend({
	childView: Item,
	emptyView: Empty,
	childViewOptions () {
		const tagName = this.getOption('tagName') === 'ul' ? 'li' : undefined;
		return {
			tagName
		};
	}
});
