import View from 'base/view';
import template from './check.html';

export default View.extend({
	tagName: 'label',
	className: 'radio-check',
	template,
	ui: {
		input: 'input'
	},
	getValue () {
		if (this.ui.input.prop('checked')) { return this.ui.input.val(); }
	},
	events: {
		'click' (e) {
			e.stopPropagation(e);
		},
		'change input[type]' (e) {
			e.stopPropagation();
			// e.preventDefault();

			this.trigger('click', this.getValue());
		}
	},
	getPropertyValue (name) {
		return (this.model && this.model.get(name)) || this.getOption(name);
	},
	templateContext () {
		const checked = this.getOption('checked') ? ' checked="checked"' : '';
		return {
			type: this.getPropertyValue('type'),
			name: this.getPropertyValue('name'),
			label: this.getPropertyValue('label'),
			value: this.getPropertyValue('value'),
			checked
		};
	}
});
