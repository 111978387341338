import { Behavior } from './behavior';
import interact from 'interactjs';
import { _, $ } from 'vendors';

function trycall (method, context, ...args) {
	if (!_.isFunction(method)) return;
	return method.apply(context, args);
}

function renderedPromise (view) {
	return new Promise((resolve) => {
		if (view.isRendered()) {
			resolve();
		} else {
			view.once('render', () => resolve());
		}
	});
}

export default Behavior.extend({
	ghostDomContextSelector: '.board',
	readyForDropClass: 'ready-for-drop',
	dropAvailableClass: 'drop-available',

	// #region initialization
	constructor () {
		Behavior.apply(this, arguments);
		this.once('initialize', this._initializeIJS);
	},

	_initializeIJS () {
		this.setupDropZone();
		this.setupDragItems();
		this.listenTo(this.view, 'before:render', this.clearGhostContext());
	},

	getDropZoneOptions () {
		const beh = this;
		const customOptions = this.getOption('dropZoneOptions') || {};
		const options = {
			ondrop (event) {
				beh._toggleReadyForDropClass(false);
				beh._toggleDropAvailableClass(false);
				const el = event.relatedTarget;
				$(el).trigger('dropped:into', [beh]);
				trycall(customOptions.ondrop, beh, event);
			},
			ondragenter (event) {
				if (!beh.el.contains(event.relatedTarget)) {
					beh._toggleReadyForDropClass(true);
				}
				trycall(customOptions.ondragenter, beh, event);
			},
			ondragleave (event) {
				beh._toggleReadyForDropClass(false);
				trycall(customOptions.ondragleave, beh, event);
			},
			ondropactivate (event) {
				beh._toggleDropAvailableClass(true);
				trycall(customOptions.ondropactivate, beh, event);
			},
			ondropdeactivate (event) {
				beh._toggleDropAvailableClass(false);
				trycall(customOptions.ondropdeactivate, beh, event);
			}
		};
		_.defaults(options, customOptions);
		return options;
	},

	setupDropZone () {
		const options = this.getDropZoneOptions();
		interact(this.el).dropzone(options);
	},

	setupDragItems () {
		this.listenTo(this.view, 'add:child', this.setupChildView);
	},
	getDraggableOptions (view) {
		const container = this.getGhostContext().get(0);
		const autoScroll = {
			container
		};

		const defs = {
			autoScroll,
			onstart: event => {
				this.beginDrag(event, view);
				// container.style.overflow = 'hidden';
				event.interaction.scroll = {
					x: container.scrollLeft,
					y: container.scrollTop,
					xEnd: container.scrollWidth,
					yEnd: container.scrollHeight
				};
				this._startHandleScroll(view, container, event.interaction);
			},
			onmove: event => this.moveDrag(event, view),
			onend: event => {
				// container.style.overflow = 'auto';
				this._stopHandleScroll(view, container);
				this.endDrag(event, view);
			}
		};
		const options = _.defaults(this.getOption('draggableOptions') || {}, defs);
		return options;
	},

	getChildViewDropZoneOptions (view) {
		const beh = this;
		const customOptions = this.getOption('childViewDropZoneOptions') || {};
		const options = {
			ondrop (event) {
				beh._toggleReadyForDropClass(false, view.$el);
				beh._toggleDropAvailableClass(false, view.$el);
				const el = event.relatedTarget;
				$(el).trigger('dropped:before', [beh, view]);
				trycall(customOptions.ondrop, beh, event);
			},
			ondragenter (event) {
				beh._toggleReadyForDropClass(true, view.$el);
				trycall(customOptions.ondragenter, beh, event);
			},
			ondragleave (event) {
				beh._toggleReadyForDropClass(false, view.$el);
				trycall(customOptions.ondragleave, beh, event);
			},
			ondropactivate (event) {
				beh._toggleDropAvailableClass(true, view.$el);
				trycall(customOptions.ondropactivate, beh, event);
			},
			ondropdeactivate (event) {
				beh._toggleDropAvailableClass(false, view.$el);
				trycall(customOptions.ondropdeactivate, beh, event);
			}
		};
		_.defaults(options, customOptions);
		return options;
	},


	// #endregion

	// #region setup childView
	setupChildView (parent, view) {
		if (!view._isModelView) return;
		interact.dynamicDrop(true);

		this.setupChildViewDraggable(view);
		this.setupChildViewDroppable(view);
		this.setupChildViewDragAndDropHandlers(view);
	},
	setupChildViewDroppable (view) {
		const options = this.getChildViewDropZoneOptions(view);
		interact(view.el).dropzone(options);
	},
	setupChildViewDraggable (view) {
		const behView = this.view;
		const setup = () => {
			const options = this.getDraggableOptions(view);
			interact(view.el).draggable(options).styleCursor(false);
		};
		const behPromise = renderedPromise(behView);
		const childPromise = renderedPromise(view);
		Promise.all([behPromise, childPromise]).then(() => setup());
	},
	setupChildViewDragAndDropHandlers (view) {
		view.delegate('dropped:into', (event, beh) => beh.triggerMethod('drag:drop', view));
		view.delegate('dropped:before', (event, beh, beforeThisView) => beh.triggerMethod('drag:drop', view, { dropBefore: beforeThisView }));
	},
	// #endregion

	// #region draggable lifecycle
	beginDrag (event, view) {
		this.triggerMethod('drag:begin', event, view);
		this.takeInitialPosition(event, view);
		view.$el.addClass('swapping');
		view.ghost = this.createGhost(view);
	},
	endDrag (event, view) {
		this.triggerMethod('drag:end', event, view);
		view.$el.removeClass('swapping');
		view.ghost.remove();
	},
	moveDrag (event, view) {
		this.triggerMethod('drag:move', event, view);
		const el = this.getDragElement(view);
		const position = this._calculateNewPosition(event, view);
		this.updateElPosition(el, position);
	},
	getDragElement (view) {
		return view.ghost.get(0);
	},
	_calculateNewPosition (event) {
		event.interaction.x += event.dx;
		event.interaction.y += event.dy;
		return event.interaction;
	},
	updateElPosition (el, position) {
		el.style.left = position.x + 'px';
		el.style.top = position.y + 'px';
	},

	_startHandleScroll (view, container, interaction) {
		this._stopHandleScroll();
		const handler = () => {
			const dx = container.scrollLeft - interaction.scroll.x;
			const dy = container.scrollTop - interaction.scroll.y;
			interaction.scroll.x = container.scrollLeft;
			interaction.scroll.y = container.scrollTop;
			interaction.x += dx;
			interaction.y += dy;
			this.updateElPosition(view.el, interaction);
		};
		this._dragSrcollHandler = {
			el: container,
			handler
		};
		$(container).on('scroll', handler);
	},
	_stopHandleScroll () {
		if (!this._dragSrcollHandler) return;
		const { el, handler } = this._dragSrcollHandler;
		$(el).off('scroll', handler);
		delete this._dragSrcollHandler;
	},

	// #endregion

	// #region dropable lifecycle
	_toggleReadyForDropClass (add, $el) {
		const cls = this.getOption('readyForDropClass');
		this._updateHelperClass(cls, !!add, $el);
	},
	_toggleDropAvailableClass (add, $el) {
		const cls = this.getOption('dropAvailableClass');
		this._updateHelperClass(cls, !!add, $el);
	},
	_updateHelperClass (cls, add, $el) {
		!$el && ($el = this.$el);
		if (add) {
			$el.addClass(cls);
		} else {
			$el.removeClass(cls);
		}
	},


	// #endregion

	// #region helpers
	clearGhostContext () {
		delete this.ghostContext;
	},

	getGhostContext () {
		if (!this.view.isRendered()) return;
		if (!this.ghostContext) {
			const ghostDomContextSelector = this.getOption('ghostDomContextSelector') || 'body';
			this.ghostContext = $(ghostDomContextSelector);
		}
		return this.ghostContext;
	},


	createGhost (view) {
		const ghost = view.$el.clone();
		ghost.css({
			position: 'absolute',
			width: view.$el.outerWidth() + 'px',
			height: view.$el.outerHeight() + 'px'
		});
		ghost.addClass('ghost');
		ghost.appendTo(this.getGhostContext());
		return ghost;
	},

	getElementOffset (event, view) {
		const addLeft = view.$el.outerWidth() / -2;
		const addTop = view.$el.outerHeight() / -2;

		const context = this.getGhostContext();	// for absolute position
		const offset = context.offset();
		const y = context[0].scrollTop - offset.top + addTop + event.page.y;
		const x = context[0].scrollLeft - offset.left + addLeft + event.page.x;

		// let y = event.page.y + addTop; // for fixed position
		// let x = event.page.x + addLeft;
		return { x, y };
	},

	takeInitialPosition (event, view) {
		let x = parseInt(event.target.getAttribute('data-x'), 10) || 0;
		let y = parseInt(event.target.getAttribute('data-y'), 10) || 0;

		if (!x && !y) {
			const off = this.getElementOffset(event, view);
			x = off.x;
			y = off.y;
		}
		// console.log({x,y}, event);
		// event.interaction
		event.interaction.x = x;
		event.interaction.y = y;
	}
	// #endregion


});
