import { FilterModel } from './FilterModel';
import { rawFilters } from './filters';
import { LogCollection } from './LogCollection';
import { tableSchema } from './tableSchema';
import { TableLayoutView } from 'components/schema-table';
import { commonTableLayoutMixin } from '../table-layout-mixin';
// import { FilterModel } from './FilterModel';
// import { ProductsContent } from './ProductsContent';
// import { ProductsHeader } from './ProductsHeader';

export const ProductionLogLayout = TableLayoutView.extend({
	...commonTableLayoutMixin,
	FilterModel,
	rawFilters,
	rowClassName: 'production-log-entry',
	excludeRawFilters: ['contragentId'],
	// initializeFilterModel () {
	// 	this.filterModel = new FilterModel(productionLogFilters);
	// 	const initial = this.getFilterModelInitialValues();
	// 	this.filterModel.setValues(initial);
	// },

	rowClickUrl: v => '/prod/builds/' + v.model.id,
	cells: ['created', 'productAndBatchNames', 'amount', 'actor'],
	tableSchema,
	Collection: LogCollection

});
