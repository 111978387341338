import BasePage from 'pages/auth';
import Types from './types';
import Products from './products';
import Measures from './measures';

const Page = BasePage.extend({
	moduleRights: { resources: 'view', resourcesProduction: 'view' },
	label: 'Продукция',
	route: 'prod',
	children: [Products, Types, Measures],
	icon: 'production'
});

export default Page;
