import { _, $ } from 'vendors';
import View from 'base/view';
import { md } from '../markdown-it';
import helpView from './help';
import busViews from 'bus/views';
import SelectControl from 'components/controls/select';
import enums from 'helpers/enums';
import { storeUnsendedMixin } from '../utils';

const PreviewView = View.extend({
	className: 'preview-view',
	template: _.template('<article><%= text %></article>'),
	templateContext () {
		return {
			text: this.getText()
		};
	},
	getText () {
		const value = this.getValue();
		return md.render(value.text);
	},
	getValue () {
		return this.getOption('value') || '';
	}
});

const EditView = View.extend({
	className: 'edit-view',
	template: _.template('<textarea <%= disabled %>><%= value %></textarea>'),
	templateContext () {
		const disabled = this.getOption('textDisabled') ? 'disabled' : '';
		return {
			value: this.getValue() || '',
			disabled
		};
	},
	getValue () {
		return (this.getOption('value') || {}).text || '';
	}
});

const MarksView = View.extend({
	initialize (options) {
		this.mergeOptions(options, ['value']);
	},
	template: _.template('<div class="marks"></div>'),
	regions: {
		marks: '.marks'
	},
	onRender () {
		this.showMarksControl();
	},
	showMarksControl () {
		const options = {
			multiple: true,
			valueType: 'enum',
			sourceValues: enums.store.appLogMarks,
			value: this.value.marks,
			proxyTo: this
		};
		const view = new SelectControl(options);
		this.showChildView('marks', view);
	},
	controlsEvents: {
		'control:change' (markers) {
			this.triggerMethod('marks:change', markers);
		}
	}
});


const editTemplate =
`<div class="tab-triggers">
	<button data-type="edit" class="tab edit-tab active"><i></i><span>редактирование</span></button>
	<button data-type="preview" class="tab preview-tab"><i></i><span>предпросмотр</span></button>
	<button data-type="marks" class="tab marks-tab"><i></i><span>маркеры</span></button>
	<button data-type="help" class="tab help-tab"><i></i><span>подсказки</span></button>
</div>
<div class="tab-content"></div>
<div class="action-buttons">
	<button class="apply"><i></i><span>отправить</span></button>
	<button class="cancel"><i></i><span>не отправлять</span></button>
	<button class="cancel-force"><i></i><span>отмена</span></button>
</div>
`;

const EditCommentEntry = View.extend({
	className: 'extended-comment-edit',
	template: _.template(editTemplate),
	activeTab: 'edit',
	regions: {
		tab: '.tab-content'
	},
	editView: EditView,
	previewView: PreviewView,
	marksView: MarksView,
	marksViewOptions () {
		const value = this.value;
		return {
			onMarksChange (marks) {
				value.marks = marks;
			}
		};
	},
	helpView,
	initialize (options) {
		this.mergeOptions(options, ['textarea']);
		this.initializeValue();
	},
	getValueFromModel () {
		const keys = ['marks', 'customCreated'];
		if (this.model.isTextEditable()) {
			this._isTextEditable = true;
			keys.unshift('text');
		} else {
			this._isTextEditable = false;
		}
		return _.pick(this.model.attributes, ...keys);
	},
	initializeValue () {
		if (this.textarea) {
			this.value = { text: this.textarea.val() };
		} else if (this.model) {
			this._isModel = true;
			this.value = this.getValueFromModel();
		} else {
			this.value = {};
		}
		// console.log('***', this.value);
	},
	onRender () {
		this.triggerMethod('ready');
	},
	onReady () {
		this.showTabView(this.activeTab);
		this.trigger('refresh');
	},
	showTabView (name) {
		const view = this.buildTabView(name);
		this.showChildView('tab', view);
		this.activeTab = name;
	},
	buildTabView (name) {
		const View = this.getOption(name + 'View', { args: [this.model, this] });
		let options = this.getOption(name + 'ViewOptions', { args: [this.model, this] });
		options = this.buildTabOptions(name, options);
		return new View(options);
	},
	buildTabOptions (name, options) {
		const value = this.getValue();
		const textDisabled = this._isTextEditable === false;
		return _.extend({}, options, { value, textDisabled });
	},
	getValue () {
		return this.value;
	},
	events: {
		'click .tab-triggers .tab' (e) {
			const $b = $(e.target).closest('.tab');
			const tabName = $b.data('type');
			if (tabName === this.activeTab) return;
			this.$('.tab-triggers .tab').removeClass('active');
			$b.addClass('active');
			this.showTabView(tabName);
		},
		'input textarea' (e) {
			const value = e.target.value;
			this.value.text = value;
			this._storeUnsended(value);
		},
		'click .action-buttons .apply' () {
			const val = this.getValue();
			
			this._storeUnsended();
			this.trigger('resolve', val);
		},
		'click .action-buttons .cancel' () {
			this.trigger('reject', { reason: 'rejectSoft', value: this.getValue() });
		},
		'click .action-buttons .cancel-force' () {
			this.trigger('reject', { reason: 'rejectHard' });
		},
		'keyup textarea' (e) {
			if (!(e.ctrlKey && e.keyCode === 13)) {
				return;
			}
			this.trigger('resolve', this.getValue());
		}
	},
	// onBeforeDestroy() {
	// 	console.error('on before destroy');
	// 	this.trigger('reject', { reason: 'rejectSoft', value: this.getValue() });
	// },
	...storeUnsendedMixin,
});

busViews.reply('EditCommentEntry', () => EditCommentEntry);
export default EditCommentEntry;
