import CollectionView from 'base/collection-view';
import Collection from 'base/collection';

import { CycleCard } from './CycleCard';
import { EmptyBlockView } from '../emptyBlock';

export const CyclesList = CollectionView.extend({
	childView: CycleCard,
	emptyView: EmptyBlockView,
	initialize () {
		const models = this.model.get('cycles') || [];
		this.collection = new Collection(models);
	}
});
