import { _, moment } from 'vendors';
import Model from 'base/model';
import ValuesFactory from 'components/values/factory/collection';
import busData from 'bus/data';
import editValue from 'bus/edit';

const currentYearGlobal = (new Date()).getFullYear();

const customFormatDate = date => {
	if (!date) return;
	if (typeof date === 'string') {
		date = new Date(date);
	}
	const cur = date.getFullYear() === currentYearGlobal;
	return moment(date).format(cur ? 'DD.MM' : 'DD.MM.YY');
};


function invoke (value, context) {
	if (typeof value === 'function') {
		return value.call(context, context);
	}
	return value;
}

function isContext (obj) {
	return obj && typeof obj === 'object' && 'values' in obj;
}

function isBoolean (obj) {
	return obj === true || obj === false;
}

function normalizeDependsContext (obj, { isAny = true, toArray = false } = {}) {
	if (!toArray && obj == null) return null;

	const empty = toArray ? [] : {};
	if (isContext(obj)) {
		return obj;
	}

	if (isBoolean(obj)) {
		return {
			any: obj,
			values: empty
		};
	}
	if (toArray) {
		if (obj == null) {
			obj = [];
		}
		if (!Array.isArray(obj)) {
			obj = [obj];
		}
	}
	return {
		any: isAny,
		values: obj
	};
	// if (Array.isArray(obj))
	// {
	// }

	// if (!obj || typeof obj !== 'object') {
	// 	if (obj === true) {
	// 		return {
	// 			any: true,
	// 			values: {}
	// 		};
	// 	} else if(obj === false) {
	// 		return {
	// 			any: false,
	// 			values: {}
	// 		};
	// 	}
	// 	return undefined;
	// } else if (Array.isArray(obj)) {
	// 	return {
	// 		any: isAny,
	// 		values: obj
	// 	};
	// }
	// if ('values' in obj) {
	// 	return obj;
	// }
	// return {
	// 	values: obj
	// };
}

function buildDependedsListeners (model) {
	if (!model.collection) {
		console.warn('DEPENDED LISTENERS SKIPED due to empty collection property');
		return;
	}

	const depends = model.getDependancy();

	if (!depends) return;

	const change = () => {
		// console.log('> clear values due to depended model change', model);
		model.values.reset();
		model.trigger('change', model);
	};

	const events = Object.keys(depends.values).reduce((hash, key) => {
		hash['changed:' + key] = change;
		return hash;
	}, {});
	if (!_.size(events)) return undefined;
	return events;
}

function checkDependencyContext (model, context) {
	const isAny = !!context.any;
	const values = context.values;
	const collection = model.collection;

	const result = Object.keys(values).reduce((valid, dependId) => {
		// debugger;
		// if (!valid) return valid;

		const dependedModel = collection.get(dependId);
		if (!dependedModel) return isAny ? valid : false;

		const hasValue = dependedModel.hasValue();
		const dependedContext = normalizeDependsContext(values[dependId], { toArray: true });
		// debugger;
		if (!_.size(dependedContext.values)) {
			const validValue = dependedContext.any ? hasValue : !hasValue;
			return isAny ? (valid || validValue) : valid && validValue;
		}

		const dependedValues = dependedModel.getValues() || [];
		const method = dependedContext.isAny ? 'some' : 'every';
		const result = dependedContext.values[method](v => dependedValues.indexOf(v) > -1);

		return isAny ? valid || result : valid && result;
	}, false);
	return result;
}

function rangeUndefinedLabel (val) {
	return [val.from, val.to].filter(f => !!f).join(' &ndash; ');
}

function rangeDateTimeLabel (val) {
	return [customFormatDate(val.from), customFormatDate(val.to)].filter(f => !!f).join(' &ndash; ');
}

export default Model.extend({
	initialize () {
		this.on('change', () => {
			this.nestedModel = invoke(this.get('nestedModel'), this);
			if (!this.collection) {
				console.warn('changed event not triggered due to empty collection property');
				return;
			}
			this.collection.trigger('changed:' + this.id, this);
			// this.nestedModel = this.get('nestedModel');
			// this.nestedModel && this.nestedModel();
		});
		const valueProto = this.getValuePrototype();
		this.valueType = valueProto.valueType;
		this.values = ValuesFactory(this.get('values'), this.getValuePrototype());
		this.listenOnDepended();
	},
	listenOnDepended () {
		const dependedEvents = buildDependedsListeners(this);
		if (!dependedEvents) return;
		this.listenTo(this.collection, dependedEvents);
	},
	isHidden () {
		return this.get('hidden') === true;
	},
	getDependancy () {
		return normalizeDependsContext(
			invoke(this.get('dependsOn'), this)
		);
	},
	checkDependancy () {
		const depends = this.getDependancy();
		if (!depends) return true;
		const result = checkDependencyContext(this, depends);
		return result;
	},
	isAvailable () {
		if (this.get('available') === false) {
			return false;
		}
		const rights = this.get('rights');
		const rightsValid = (typeof rights !== 'object') || busData.user().checkRights(rights);
		if (!rightsValid) return false;

		const dependancyValid = this.checkDependancy();
		return dependancyValid;
	},
	isPinned () {
		return ((this.hasValue() && this.canBePinned()) || (this.get('pinned') === true)) && !this.isHidden();
	},
	canBePinned () {
		return this.get('pinned') !== false;
	},
	getOrder () {
		return this.get('order') || 0;
	},
	isMultiple () {
		return this.get('multiple') === true;
	},
	getValues () {
		const models = this.values.getValues();
		const values = _(models).map((m) => m.getValue());
		return values;
	},
	getValue () {
		const values = this.getValues();
		return this.isMultiple() ? values : values[0];
	},
	getValueLabel (value) {
		const valueLabel = this.get('valueLabel');

		if (_.isFunction(valueLabel)) {

			return valueLabel(value, this);

		} else {

			if (value && typeof value === 'object') {

				if (!_.keys(value)) {
					return this.getEmptyLabel();
				}

				if (value.from || value.to) {
					return this.getRangeValueLabel(value);
				}
				return value;
				// const currentYear = (new Date()).getFullYear();

				// const format = date => {
				// 	if (!date) return;
				// 	const cur = date.getFullYear() === currentYear;
				// 	return moment(date).format(cur ? 'DD.MM' : 'DD.MM.YY');
				// };

				// if (value.from instanceof Date || value.to instanceof Date) {
				// 	const res = [];
				// 	if (value.from) {
				// 		res.push(format(value.from));
				// 		if (!value.to) {
				// 			res.unshift('от ');
				// 		}
				// 	}
				// 	if (value.to) {
				// 		if (res.length) {
				// 			res.push(' &ndash; ');
				// 		} else {
				// 			res.push('до ');
				// 		}
				// 		res.push(format(value.to));
				// 	}

				// 	if (res.length) {
				// 		return res.join('');
				// 	} else {
				// 		return this.getEmptyLabel();
				// 	}
				// 	// return 'период даты...';
				// }
			}
			return value;
		}
	},
	getRangeValueLabel (val) {
		const valueType = this.valueType || this.values.valueType;
		if (valueType === 'datetime') {
			return rangeDateTimeLabel(val);
		} else {
			return rangeUndefinedLabel(val);
		}
	},
	hasValue () {
		const value = this.getValue();
		const multiple = this.isMultiple();
		return multiple ? value.length > 0 : (value != null && value !== '');
	},
	getEmptyLabel () {
		return this.get('emptyLabel') || '&mdash;';
	},
	getDisplayValue () {
		const values = this.getValues();
		if (values == null || values.length === 0) {
			return this.getEmptyLabel();
		} else {
			const label = _(values).map((value) => this.getValueLabel(value)).join(', ');
			return label || this.getEmptyLabel();
		}
	},
	getValuePrototype () {
		let proto = this.get('valuePrototype');
		_.isFunction(proto) && (proto = proto());
		return proto || {};
	},
	getEditConfig (opts = {}) {
		const proto = this.getValuePrototype();
		const cfg = _.extend({}, proto);
		cfg.header = this.get('label');
		cfg.returnType = 'model';
		if (!opts.skipValues) {
			cfg.value = this.getValue();
		}
		// cfg.value = this.get('values');
		return cfg;
	},
	setValues (values, opts = {}) {
		if (!_.isArray(values)) {
			values = values == null ? [] : [values];
		}


		this.values.reset(values, { parse: true });
		this.set('values', this.getValue(), opts);
		console.error('MODEL:', this.values, this);
	},
	toFilter () {
		if (!this.hasValue()) { return; }

		const key = this.get('valueId');

		if (key == null) return;

		const result = { [key]: this.getValue() };

		return result;
	},
	getUrlKey () {
		return this.get('urlKey') || this.get('valueId');
	},
	toUrl () {
		if (!this.hasValue()) { return; }
		const key = this.getUrlKey();
		if (key == null) return;
		const result = this.values.toUrl(key);

		return result;
	},
	editInModal () {
		const cfg = this.getEditConfig();
		cfg.return = 'control';
		const ev = editValue.do(cfg);
		// this.listenTo(ev, 'control:change', (values) => this.model.setValues(values));
		return ev.promise.then(
			(values) => this.setValues(values),
			(result) => this.setValues(result.value)
		);
	}
});
