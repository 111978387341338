import { _ } from 'vendors';
import View from 'components/ui-list/item';

const ActionView = View.extend({
	className: 'ui-list-action',
	text () {
		let lbl = this.model.get('label');
		if (_.isFunction(lbl)) {
			const cntx = this.getOption('triggerOn');
			lbl = lbl.call(cntx, cntx);
			return lbl;
		} else {
			return lbl;
		}
	}
});

export default ActionView;
