import { _, $ } from 'vendors';
import View from 'base/view';
// import UiCardBlock from 'components/ui-card-block/block';
// import Processes from './list';
// import Ui2Column from 'components/ui2/column';
import { HamburgerView } from 'base/view-hamburger';
import { BaseHamburgerView } from 'base/base-hamburger-view';
// import UiCardBlock from 'components/ui-card-block/block';
import CollectionView from 'base/collection-view';

import childView from 'mod/processes/common/views/list-item-view';
import busModels from 'bus/models';


import mix from 'helpers/mix';
// import FDC from 'mixins/view/flex-dynamic-column';

// import ViewManagedCollection from 'mixins/view-managed-collection';
import ScrollHandlerMixin from 'mixins/view/scroll-handler';
import FetchableMixin from 'mixins/collection-view/fetchable';
import FetchOnScrollMixin from 'mixins/collection-view/fetch-on-scroll';

// let BaseView = mix(CollectionView).with(FDC, FetchableMixin, FetchOnScrollMixin, ViewManagedCollection, ScrollHandlerMixin);
const BaseView = mix(CollectionView).with(FetchableMixin, FetchOnScrollMixin, ScrollHandlerMixin);



// const emptyObject = {};

export const ProcessesListView = BaseView.extend({
	scrollDirection: 'bottom',
	initialize () {
		const Collection = busModels.request('processes:paged');
		this.collection = new Collection([], { state: this.getOption('processesMode') });
		this.fetch();
		// let options = this.getFetchOptions();
        // this.collection.fetch(options);

		console.log(' ++++ ++++ this', this);
		// { 'productid.v': this.model.id, 'noactor.v': true }
	},
	getFetchData (page) {
		return _.extend({}, this.getOption('fetchData'), page);
	},
	// getFetchOptions() {
	// 	let fetchOptions = this.getOption('fetchOptions', { args: [this] }) || emptyObject;
	// 	let fetchData = this.getFetchData() || fetchOptions.data || emptyObject;
	// 	return {
	// 		...fetchOptions,
	// 		data: {
	// 			...fetchData
	// 		},
	// 		...emptyObject
	// 	}
	// },
	fetchNext () {
		const col = this.getCollection();
		if (col.fetchingInProcess) return;
		const res = col.getResultJSON();
		if (res.endOfData) return;
		const take = 50;
		const page = {
			'result.skip': (res.skiped || 0) + (res.returned || take),
			'result.take': take
		};
		return this.fetch(page, { remove: false });
	},
	childView
});


const HeaderView = View.extend({
	className: 'ui2-block_header tabs bg-main-dark',
	tagName: 'header',
	template: _.template('<span class="header-tab<%= activeActive %>"><%= activeLabel %></span><span  class="header-tab<%= completedActive %>"><%= completedLabel %></span>'),
	templateContext () {
		const mode = this.getOption('processesMode');
		const isCompleted = mode === 'completed';
		// let label = mode === 'completed' ? 'завершённые процессы' : 'активные процессы';
		// let alternate = mode === 'completed' ?  'активные процессы' : 'завершённые процессы';
		const activeLabel = 'активные процессы';
		const activeActive = isCompleted ? '' : ' active';
		const completedLabel = 'завершённые процессы';
		const completedActive = isCompleted ? ' active' : '';
		const ctx = {
			// label,
			// alternate,
			activeLabel,
			activeActive,
			completedLabel,
			completedActive
		};
		console.log('== TMPL ==', ctx, mode, isCompleted);
		return ctx;
	},
	events: {
		'click .header-tab:not(.active)' (event) {
			console.log('== CLICK TAB ==');
			const isActive = $(event.target).closest('.header-tab').hasClass('active');
			if (isActive) return;

			this.triggerMethod('switch:processes');

			// let mode = this.getOption('processesMode');
			// mode = mode === 'completed' ? 'active' : 'completed';
			// this.trigger('switch:processes', mode);
			// this.model.executeAction('show:completed:tasks');
		}
	}
});

export default function createNestedProcesses (context, viewOptions = {}) {
	/*
	let header = { text: 'Процессы', addClass:'bg-main-dark'};
	let headerOptions = undefined;
	if (viewOptions.completedProcessesEnabled) {

		//header = new HeaderView({ model: context.model });
		header = HeaderView;
		headerOptions = function(){
			return {
				processesMode: this.processesMode === 'completed' ? 'completed' : 'active',
				model: context,
			};
		};

	}
	viewOptions = _.extend({
		header,
		headerOptions,
		content: Processes,
		activeProcessesOptions(){
			let col = this.model.getProcesses();
			col.fetch();
			return {
				addClass: 'scrolled-y',
				collection: col,
				viewFilter: (v) => v.model.isNotClosed(),
				//viewComparator: v => v.model.getDeadline()
			};
		},
		completedProcessesOptions(){
			let col = this.model.getCompletedProcesses();
			col.fetch();
			return {
				addClass: 'scrolled-y',
				collection: col,
				viewFilter: (v) => !v.model.isNotClosed(),
				//viewComparator: v => -v.model.getClosedDate()
			};
		},
		contentOptions(){
			let options = this.tasksMode === 'completed'
				? this.getOption('completedProcessesOptions', { args: [this.model, this] })
				: this.getOption('activeProcessesOptions', { args: [this.model, this] });

			return options;
		},
		//action: 'add:task',
		childViewEvents:{
			'switch:processes'(type){
				this.processesMode = type;
				this.render();
			},
		},
	}, viewOptions);

	*/

	const model = context.model;
	viewOptions = _.extend({
		initialize() {
			this.on('all', c => console.log('[p]', c));
		},
		className: 'ui2-block double-column whole-column',
		model,
		childViewEvents: {
			'switch:processes' () {
				let currentState = this.processesMode || this.getOption('processesMode');
				const newstate = currentState === 'completed' ? 'active' : 'completed';
				this.processesMode = newstate;
				// console.log(' == SWITCHING MODE == ', currentState, newstate);
				this.render();
			}
		},
		childrenViews: [
			{
				class: HeaderView,
				shouldProxyEvents: true,
				model
				// onSwitchProcesses() {
				// 	console.log(' -- === ', m, v, ' === -- ');
				// }
			},
			{
				class: ProcessesListView,
				className: 'elastic-content'
			}
		],
		childViewOptions () {
			const processesMode = this.processesMode || this.getOption('processesMode');
			console.log('== BLD CHILD ==', processesMode);
			return {
				processesMode,
				fetchOptions: this.getOption('fetchOptions'),
				fetchData: this.getOption('fetchData')
			};
		}
	}, viewOptions);
	context.createNested('processes', {
		viewClass: BaseHamburgerView,
		//HamburgerView,
		// viewClass: UiCardBlock,
		viewOptions
	});
}
