import View from 'base/view';
import Model from 'base/model';


const BaseEntryView = View.extend({
	className: 'report-entry-item',

	initialize () {
        // console.log('--entry-view--', this);
		this.period = this.getOption('period');
		this.reportSchema = this.getOption('reportSchema');
		this.initHeaderModel();
	},

	initHeaderModel () {},

	getTemplate () {
		if (this.isNotHeader()) { return this.reportSchema.template; } else { return this.reportSchema.headerTemplate; }
	},

	tagTitle () {
		if (!this.isNotHeader()) return;
		const hash = {};

		if (this.model.get('hasBatches') === false) {
			hash['ранее не использовался'] = true;
		}

		return Object.keys(hash).join(', ');
	},

	dynamicClassName () {
		if (!this.isNotHeader()) return '';
		const hash = {};

		if (this.model.get('hasBatches') === false) {
			hash['no-batches'] = true;
		}

		return Object.keys(hash).join(' ');
	},

	isNotHeader () {
		return this.getOption('header') !== true;
	},

	hasPeriod () {
		return this.period.hasValue() === true;
	},

	getModelSchema () {
		return this.reportSchema.modelSchema;
	},

	display (key) {
		const schema = this.getModelSchema();
		return schema._display(key, this.model);
	},


	templateContext () {
		const context = this.reportSchema.buildTemplateContext(this);
		if (!this.model.get('stats') && !context.stats) {
			context.stats = {};
		}
        // context.display = this.display.bind(this);
		return context;
        // let context = {};

        // let schema = this.reportSchema.sortedSchema;
        // context.css = {};
        // context.vals = {};
        // schema.forEach(s => {
        //     if (s.addValueCssClass) {
        //         context.css[s.key] = this.reportSchema.invoke(s.addValueCssClass, this);
        //     }
        //     if (s.value) {
        //         context.vals[s.key] = this.reportSchema.invoke(s.value, this);
        //     }
        // });
        // return context;
	}
});

export const EntryView = BaseEntryView.extend({


	// events: {
	// 	'click' () {
	// 		// let url = //busData.request('product:page:url') + '/' + this.model.id;
	// 		// smartOpen(url, { ctrlKey: true });
	// 	}
	// }

});


export const EntryHeaderView = BaseEntryView.extend({
	addClass: 'header',

	initHeaderModel () {
		this.model = new Model();
		this.updateHeaderModel();
	},

	getHeaderHash () {
		return this.reportSchema.headerHash;
	},
	updateHeaderModel () {
		const hash = this.getHeaderHash();
            // this.hasPeriod() ? periodHeaderHash : momentHeaderHash;
		this.model.clear({ silent: true });
		this.model.set(hash);
	}



});
