import { _, $ } from 'vendors';
import './prepare-jquery';
import 'signalr';
import token from 'app/token';
import paths from 'helpers/paths';
import BaseObject from 'base/object';
import busEvents from 'bus/app-events';
import busSockets from 'bus/sockets';
import busNotify from 'bus/notify';
import startPromise from './start-promise';
import cfg from 'root/config';

const SocketHandler = BaseObject.extend({
	initRadioOnInitialize: true,
	channelName: busSockets.channelName,
	radioRequests: {
		'join:group' (...names) {
			_(names).each((name) => {
				return name && this._serverInvoke('joinGroup', name);
			});
		},
		'leave:group' (...names) {
			_(names).each((name) => name && this._serverInvoke('leaveGroup', name));
		}
	},
	initialize () {
		this.initConnection();
		this.initProxy();
		this.listenTo(token, 'change:token', this.onTokenChange);
	},
	initConnection () {
		this.connection = $.hubConnection('/_aws', { useDefaultPath: false });
		if (!cfg.release) {
			// Console.warn('WS conn:', this.connection);
			global.WsConn = this.connection;
		}
		this.connection.qs = { token: token.getToken() };
		this.connection.url = paths.api('socketsHub');
		this.connection.error((...args) => this.triggerMethod('error', ...args));
	},
	isConnected () {
		return this.connection.state === $.signalR.connectionState.connected;
	},
	isDisconnected () {
		return this.connection.state === $.signalR.connectionState.disconnected;
	},
	initProxy () {
		const hub = this.hubProxy = this.connection.createHubProxy(this.getOption('hubName'));
		if (!cfg.release) {
			// Console.warn('WS hub:', hub);
			global.WsHub = hub;
		}

		hub.on('appEvent', (...args) => this.triggerAppEvent(...args));
		hub.on('appLog', (...args) => this.triggerAppLog(...args));
		this.connection.connectionSlow((...args) => this._triggerConnectionEvent('connection:slow', args));
		this.connection.reconnecting((...args) => {
			this._reconnection = new Promise((resolve, reject) => {
				this.once('reconnected', () => {
					resolve();
					delete this._reconnection;
				});
				this.once('disconnected', () => {
					reject();
					delete this._reconnection;
				});
			});
			this._triggerConnectionEvent('reconnecting', args);
		});
		this.connection.reconnected((...args) => this._triggerConnectionEvent('reconnected', args));
		this.connection.disconnected((...args) => this._triggerConnectionEvent('disconnected', args));
	},
	_triggerConnectionEvent (event, args) {
		this.triggerMethod(event, ...args);
	},
	onTokenChange () {
		// $.connection.hub.qs.token = token.getToken();
		this.connection.qs.token = token.getToken();
	},
	onReconnecting () {
		busNotify.warn({
			id: 'socket',
			text: 'Утеряно соединение с сервером.<br/> Пробуем восстановить...',
			viewed: false,
			viewedDelay: 0
		});
	},
	onReconnected () {
		busNotify.message({
			id: 'socket',
			text: 'Соединение с сервером восстановлено',
			viewedDelay: 3,
			viewed: false,
			store: false
		});
	},
	onDisconnected () {
		busNotify.error({
			id: 'socket',
			text: 'Утеряно соединение с сервером.<br/> Для корректной работы перезагрузите страницу',
			viewed: false,
			viewedDelay: 0,
			store: false
		});
	},
	start () {
		return this.connect();
		// this.connection.start({ withCredentials: false })
		// 	.then(
		// 		(...args) => this.triggerMethod('start:success',...args),
		// 		(...args) => this.triggerMethod('start:error',...args)
		// 	);
	},
	connect () {
		return this.connection.start({ withCredentials: false })
			.then(
				(...args) => {
					this._triggerConnectionEvent('start', args);
					return args[0];
					// this.triggerMethod('start:success',...args)
				},
				(...args) => {
					this._triggerConnectionEvent('start:error', args);
					// this.triggerMethod('start:error',...args)
					return args[0];
				}
			);
	},
	onStart () {
		this.addConnectionIdToHeader();
		startPromise.resolve();
		delete this._reconnection;
		Console.warn('socket started');
	},
	onStartError () {
		Console.warn('socket failed to start');
	},
	triggerAppLog (appLog) {
		// Console.log('APP:LOG -> ', ...arguments);
		_(appLog.broadcastToGroups).each((name) => busEvents.trigger(name.toLowerCase() + ':log', appLog));
	},
	triggerAppEvent (appEvent) {
		// Console.log('APP:EVENT', appEvent);
		const radio = this.getChannel();
		radio.trigger('app:event', appEvent);
		busNotify.trigger('app:event', appEvent);
		busEvents.trigger(appEvent.event, appEvent);

		if (appEvent.dataName) {
			const entityEvent = _.colonedString(appEvent.dataName.toLowerCase(), appEvent.dataId, appEvent.event);
			busEvents.trigger(entityEvent, appEvent);

			/*
			let typeEvent = _.colonedString(appEvent.event);
			//console.log('triggering __ ', typeEvent);
			busEvents.trigger(typeEvent, appEvent);
			*/
			const typeEvent = _.colonedString(appEvent.event);
			const arrTypeEvents = typeEvent.split(':');
			let chunk = '';
			while (arrTypeEvents.length) {
				chunk += arrTypeEvents.shift();
				const arg = arrTypeEvents.join(':');
				busEvents.trigger(chunk, appEvent, arg);
				chunk += ':';
			}
		}
		// _(appEvent.broadcastToGroups).each((name) => {
		// 	let eventName = _.colonedString(name);
		// 	//console.log('== E', eventName);
		// 	busEvents.trigger(eventName, appEvent);
		// });
	},
	addConnectionIdToHeader () {
		$.ajaxSetup({
			headers: {
				wscid: this.hubProxy.connection.id
			}
		});
	},

	ensureIsAlive () {
		if (this.isConnected()) { return Promise.resolve(); } else if (this._reconnection) { return this._reconnection; } else {
			this._reconnection = this.connect();
			return this._reconnection;
		}
	},
	_serverInvoke (name, ...args) {
		this.ensureIsAlive().then(
			() => this.hubProxy.invoke(name, ...args)
		);
	}

});

export default SocketHandler;
