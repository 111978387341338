import editValue from 'bus/edit';
import busModels from 'bus/models';
import EditView from './edit';
// import Model from './model';
import modalError from 'helpers/modals/error';
import confirm from 'helpers/modals/confirm';

export default function editDetailsAction (_model, parent) {
	const isnew = !_model;
	const Model = busModels.getModel('BankDetails');
	const model = _model ? _model.clone() : new Model();
	model.unset('id');

	editValue.do({
		header: isnew ? 'добавление реквизитов' : 'изменение реквизитов',
		value: model,
		applyLabel: isnew ? 'добавить' : 'сохранить',
		resetButton: !isnew,
		cancelLabel: 'отставить',
		resetLabel: 'удалить',
		resetButtonCssClass: 'delete-button',
		editOptions: {
			controlView: EditView
		}
	}).then(
		(data) => {
			if (isnew) {
				parent.getDetails().create(data, { wait: true }).then(
					() => {},
					(xhr) => modalError('Не удалось добавить реквизиты', xhr)
				);
			} else {
				_model.save(data, { patch: true, wait: true }).then(
					() => {},
					(xhr) => modalError('Не уалось сохранить изменения', xhr)
				);
			}
		},
		(reject) => reject.reason === 'reset' && _model && confirm('Подтвердите удаление реквизитов').then(() => _model.destroy({ wait: true }), () => {})
	);

	/*
	let preloader = { overlay: opts.view };
	let content = new EditDetails({
		parent: this,
		destroyAfterPromise: true,
		promiseButtonsOptions:{

			// resolve:false,
			// rejectLabel: 'закрыть',
		}
	});
	modals.show({
		header:'Добавление реквизитов',
		content,
		type:'full',
	});
	content.promise.then(
		(detail) => {
			var details = this.getDetails();
			details.create(detail,{ wait:true, preloader }).then(
				() => {},
				(xhr) => modalError('Произошла ошибка',xhr)
			);
		},
		() => {}
	);
	*/
}
