import BasePage from 'pages/auth';
// import Create from './create';
import Layout from './layout';
import Model from 'mod/groups/models/model';
// import Emps from './emps/emps';

const Page = BasePage.extend({
	moduleRights: { admin: 'view, manageGroups' },
	id: 'admin/groups/#id',
	name: 'groups card management page',
	label () { return 'Группа: ' + ((this.model && this.model.display('name')) || ''); },
	route: ':groupId',
	skipMenu: true,
	Layout,
	wrapLayout: true,
	socketGroups: (m) => 'employeegroup:' + m.id.toString().toLowerCase(),
	onBeforeStart (rd) {
		const model = new Model({ id: rd.args.groupId });
		this.addModel(model);
	}
});

export default Page;
