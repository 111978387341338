import View from 'base/view';

import ProductsBlock from './products-block';
import ProcessesBlock from './processes-block';
import CyclesBlock from './cycles-block';
import ContragentsBlock from './contragents-block';

// import DepartmentLinks from './department-links';
import { PageLayout } from './common';

const containerHtml = name => `<div class="${name}"></div>`;
const regionHash = name => '.' + name;

const regionsHash = names => names.reduce((memo, name) => {
	memo[name] = regionHash(name);
	return memo;
}, {});

const html = names => names.reduce((memo, name) => {
	memo += containerHtml(name) + '\r\n';
	return memo;
}, '');

const names = [
	'products', 'processes', 'cycles', 'contragents', 'employees'
];

// const template = `<section class="department-links-holder"></section><section class="information">${html(names)}</section>`;

const _regions = regionsHash(names);
// _regions.links = '.department-links-holder';

const defaultShowOptions = { replaceElement: false };

const Layout = View.extend({
    // className: 'production-department',
	template: html(names),
	regions: _regions,
	onRender () {
		this.model.initializeProductToProcessMap();
		this.showProducts();
		this.showProcesses();
		this.showCycles();
		this.showContragents();
		this.showEmployees();
        // this.showChildView('links', new DepartmentLinks({ model: this.model }));
	},
	show (view, showOptions) {
		showOptions = showOptions || defaultShowOptions;
		this.showChildView(view.regionName, view, showOptions);
	},
	showProducts () {
		const view = new ProductsBlock({ model: this.model, regionName: 'products' });
		this.show(view, { replaceElement: true });
	},
	showProcesses () {
		const view = new ProcessesBlock({ model: this.model, collapsed: true, regionName: 'processes' });
		this.show(view, { replaceElement: true });
	},
	showCycles () {
		const view = new CyclesBlock({ model: this.model, collapsed: true, regionName: 'cycles' });
		this.show(view, { replaceElement: true });
	},
	showContragents () {
		const view = new ContragentsBlock({ model: this.model, collapsed: true, regionName: 'contragents' });
		this.show(view, { replaceElement: true });
	},
	showEmployees () {}

});

export default PageLayout.extend({
	className: 'production-department',
	contentView: Layout
});
