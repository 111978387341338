import { _ } from 'vendors';
import action from 'helpers/action';
// import EmployeeModel from 'mod/employees/models/model';
import Collection from '../models/in-entity-collection';
import editValue from 'components/edit-value';
// import EmployeeInGroupModel from '../models/model-in-entity';
import confirm from 'helpers/modals/confirm';
import user from 'app/user';



function initializeActions (config) {
	this._actions || (this._actions = []);

	if (config) {
		const actions = _.isFunction(config.actions) ? config.actions.call(this, this, config) : config.actions;
		if ('actions' in config) {
			if (_.isArray(actions)) { this._actions.push(...actions); }
		} else {
			// DEPRECATED
			if (this.employeesActionsConfig) {
				const addEmps = action('add:employees', 'добавить сотрудников', this.employeesActionsConfig.add.rights, { places: 'page' });
				const removeEmps = action('remove:employee', 'исключить сотрудника', this.employeesActionsConfig.remove.rights, { hidden: true });
				this._actions.push(addEmps, removeEmps);
			}
		}
	}
}

// function initializeCollection () {
// 	this.registerNestedCollection({
// 		name: 'employees',
// 		Collection
// 	});
// }

export default (Base) => {
	const Ctor = Base;
	// Base.NestedCollections ? Base : NestedCollections(Base);

	return Ctor.extend({
		constructor () {
			// this.initEntityWithEmployeesHandlers();

			Base.apply(this, arguments);
			this.registerNestedEntity('employees', Collection);
			// initializeCollection.call(this);
			initializeActions.call(this, this.employeesActionsConfig);
		},
		getEmployees () {
			return this.entity('employees');
		},
		// initEntityWithEmployeesHandlers(){
		// 	this.on('change:employees', this.refreshEmployeesCollection);
		// },


		/*
		getEmployees(opts = {}){
			let raw = this.get('employees') || [];
			let models = _(raw).map((hash) => new EmployeeInGroupModel(hash, {
				parent: this,
			}));
			if(opts.asCollection)
				return new Collection(models);
			else
				return models;
		},
		refreshEmployeesCollection(){
			let raw = this.getEmployees();
			this.employees && this.employees.reset(raw);
		},
		getEmployeesCollection(){

			if(!this.employees){
				this.employees = new Collection();
				this.employees.populated = true;
				this.listenTo(this.employees, 'change', () => {
					this.set('employees', this.employees.toJSON());
				});
			}

			this.refreshEmployeesCollection();

			return this.employees;
		},
		*/


		// helpers
		employeeIdAttribute: 'eId',
		employeesAttribute: 'employees',
		forceApiEmployees: true,

		hasEmployee (id) {
			const emp = this.getEmployee(id);
			return emp != null;
		},
		getEmployee (id) {
			if (id == null) return;
			const col = this.getEmployees();
			return col.find((e) => e.get(this.employeeIdAttribute) === id);
		},
		getEmployeeRole (id, opts = {}) {
			const emp = this.getEmployee(id);
			return emp && emp.get('role', opts);
		},
		getEmployeesIds () {
			const emps = this.getEmployees();
			return emps.map((e) => e.get(this.employeeIdAttribute));
		},

		removeEmployeeFromCollection (employeeId) {
			const emps = this.getEmployees();
			const emp = this.getEmployee(employeeId);
			if (!emp) return;
			emps.remove(emp);
		},



		// employee actions
		actionAddEmployees () {
			editValue({
				controlType: 'employeesSelect',
				header: 'Выберите сотрудников',
				multiple: true,
				excludeValues: this.getEmployeesIds()
			}).then((values) => {
				// console.log('~~~ ADDED', values);
				this.addEmployees(values);
			});
		},
		actionRemoveEmployee (action, opts = {}) {
			const { model, shouldConfirm = true } = opts;
			if (!model) { throw new Error('`remove:employee` error. employee not passed'); }

			if (shouldConfirm) {
				confirm('Вы собираетесь исключить сотрудника').then(
					() => this.removeEmployees(model),
					() => {}
				);
			} else {
				this.removeEmployees(model);
			}
		},
		removeEmployees (models) {
			console.log('remo', models);
			if (models == null) return;
			!_.isArray(models) && (models = [models]);

			const ids = _(models).map((m) => _.isObject(m) ? m.get(this.employeeIdAttribute) : m);
			console.log('remo ids', ids);
			const shouldApi = this.forceApiEmployees || !this.isNew();

			if (shouldApi) {
				this.send({
					method: 'delete',
					data: ids,
					url: 'employees'
				}).then((emps) => {
					this.set(this.employeesAttribute, emps);
				});
			} else {
				const emps = this.getEmployees();
				if (!Array.isArray(models)) {
					models = [models];
				}
				models.forEach(emp => {
					if (typeof emp === 'object') {
						emps.remove(models);
					} else {
						const model = emps.find({ employeeId: emp });
						emps.remove(model);
					}
				});
			}
		},
		addEmployees (models) {
			if (!models) return;
			const shouldApi = this.forceApiEmployees || !this.isNew();
			if (shouldApi) {
				this.send({
					method: 'post',
					data: models,
					url: 'employees'
				}).then((emps) => {
					if (this.addEmployeesToCollection) {
						this.addEmployeesToCollection(emps);
					} else {
						this.set(this.employeesAttribute, emps, { parent: this });
					}
				});
			} else {
				const col = this.getEmployees();
				const nestedEmployeeMap = this.nestedEmployeeMap || (m => typeof m === 'object' ? m : { [this.employeeIdAttribute]: m });
				models = _.map(models, nestedEmployeeMap);
				const res = col.add(models);
				return res;
			}
		},
		employeesCanBeAdded () {
			if (this.employeesActionsConfig && this.employeesActionsConfig.remove) {
				return user.checkRights(this.employeesActionsConfig.add.rights, { context: this });
			}
		},
		employeesCanBeRemoved (emps) {
			// console.log('CAN BE REMOVED', arguments, this);
			let hasRights;
			if (this.employeesActionsConfig && this.employeesActionsConfig.remove) {
				hasRights = user.checkRights(this.employeesActionsConfig.remove.rights, { context: this });
			}
			return hasRights;
		}

	});
};
