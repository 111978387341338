import { _, $ } from 'vendors';
import View from 'base/view';
import template from './layout.html';
import Input from 'components/controls/input';

import mix from 'helpers/mix';
import ControlsMixin from 'components/controls/mixin';


export default mix(View).with(ControlsMixin).extend({
	initialize (opts) {
		this.mergeOptions(opts, ['validate']);
	},
	className: 'c-input-number',
	template,
	regions: {
		input: { el: 'input', replaceElement: true }
	},
	events: {
		'mousedown i:first-child' () { this.addingValue(1); },
		'mousedown i:last-child' () { this.addingValue(-1); }
	},
	addValue (dir) {
		const value = this.input.getValue();
		const val = value != null && (value + dir);
		if (this.validate(val)) { this.input.setValue(val); }
	},
	addingValue (dir) {
		this.addValue(dir);
		this.timeout = setTimeout(() => {
			this.interval = setInterval(() => this.addValue(dir), 20);
		}, 200);
		$(document).one('mouseup', () => {
			clearTimeout(this.timeout);
			clearInterval(this.interval);
		});
	},
	onRender () {
		const opts = _.extend({}, this.options, {
			proxyTo: this,
			type: 'number'
		});
		const view = this.input = new Input(opts);
		this.showChildView('input', view);
	},
	controlsEvents () {
		const control = this.getOption('controlName') || 'control';
		const controlChange = `${control}:change`;
		const controlDone = `${control}:done`;
		return {
			[controlChange] (val) {
				this.triggerChange(val);
			},
			[controlDone] (val) {
				this.triggerDone(val);
			}
		};
	},
	validate: () => true
});
