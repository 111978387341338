import { moment } from 'vendors';
import { propertySchemaApi } from '../../../../apis/schema';
import { displayUnit } from '../../../../apis/units';
import { addPostfix, displayParty, postfixFunc,  } from '../../../../utils';
import { openCardButton } from '../../../../utils/openCardButton';
import { employeeName } from '../../../staff/emps';



export const buildSchema = {
	name: {
		label: 'название',
		displayValue (v, m, s) {
			const created = propertySchemaApi.displayValue('createdShort', m, s);
			const amount = propertySchemaApi.displayValue('amountPostfix', m, s);
			const product = propertySchemaApi.displayValue('productName', m, s);
			const batch = propertySchemaApi.getValue('productBatchIdString', m, s);
			return [created, amount, batch, product].join(' - ');
		}
	},
	amount: {
		label: 'произведённое количество',
		valueType: 'number'
	},
	amountPostfix: {
		label: 'произведённое количество',
		displayValue (v, m, s) {
			v = propertySchemaApi.displayValue('amount', m, s);
			const unit = propertySchemaApi.getValue('baseUnitMeasureId', m, s);
			const dunit = displayUnit(unit);
			return addPostfix(v, postfixFunc(dunit));
		}
	},
	productBatchIdString: {
		label: 'номер партии',
		displayValue (v, m, s) {
			
			//// const url = cardUrls.batch + id;
			return displayParty(v, '');
		}
	},
	productBatchIdStringUrl: {
		label: 'номер партии',
		displayValue (v, m, s) {
			v = propertySchemaApi.displayValue('productBatchIdString', m, s);
			const id = propertySchemaApi.getValue('productBatchId', m, s);
			//// const url = cardUrls.batch + id;
			return openCardButton({ id, type: 'batch' }, v);
		}
	},
	createdFromNow: {
		label: 'дата производства',
		displayValue (v, m, s) {
			v = propertySchemaApi.getValue('created', m, s);
			return moment(v).fromNow();
		}
	},
	created: {
		label: 'дата производства',
		valueType: 'datetime'
	},
	createdShort: {
		label: 'дата производства',
		valueType: 'datetime',
		displayValue (v, m, s) {
			const created = propertySchemaApi.getValue('created', m, s);
			return moment(created).format('DD.MM.YY');
		}
	},
	actorId: {
		label: 'сотрудник',
		displayValue (v, m, s) {
			return employeeName(v);
		}
	},
	productName: {
		label: 'название продукции',
		// displayValue(v,m,s) {
		// 	const id = v.id;
		// 	//const url = cardUrls.product + id;
		// 	return openCardButton({ id, type: 'product' }, v);
		// }
	},
	productNameUrl: {
		label: 'название продукции',
		displayValue(v,m,s) {
			v = propertySchemaApi.displayValue('productName', m, s);
			const id = propertySchemaApi.getValue('productId', m, s);
			//const url = cardUrls.product + id;
			return openCardButton({ id, type: 'product' }, v);
		}
	},	
	departmentUrl: {
		label: 'участок',
		displayValue (v, m, s) {
			v = propertySchemaApi.displayValue('department', m, s);
			if (v == null) {
				return;
			}
			const id = v.id;
			//const url = cardUrls.productionDepartment + id;
			return openCardButton({ id, type: 'productionDepartment' }, v.name);
		}
	},
	originProcessUrl: {
		label: 'процесс производства',
		displayValue (v, m, s) {
			v = propertySchemaApi.displayValue('originProcess', m, s);
			if (v == null) {
				return;
			}
			const id = v.iId || v.id;
			//const url = cardUrls.process + id;
			return openCardButton({ id, type: 'process' }, v.name);
		}
	},
	cycle: {
		label: 'цикл производства',
		displayValue(v,m,s) {
			return v?.name;
		}
	},
	cycleUrl: {
		label: 'цикл производства',
		displayValue (v, m, s) {
			if (v == null) {
				return;
			}
			const id = v.id;
			//const url = cardUrls.cycle + id;
			return openCardButton({ id, type: 'cycle' }, v.name);
		}
	},
};
