import { _ } from 'vendors';

import Model from 'base/model';
import paths from 'helpers/paths';
import SingleFetch from 'mixins/single-fetch';
import mix from 'helpers/mix';
import busData from 'bus/data';

import './currencies';

/*

import emps from 'mod/employees/singleton';
import deps from 'mod/departments/singleton';
import groups from 'mod/groups/singleton';
import productTypes from 'mod/productionTypes/singleton';
import measures from 'mod/productionMeasures/singleton';

*/

import enumsStore from 'helpers/enums/store';
// import EnumsStore from 'helpers/enums/enums-store';


paths.set('api', 'appdata', 'config/all', { prefix: '' });

const AppData = mix(Model).with(SingleFetch).extend({
	name: 'app:data',
	url: paths.api('appdata'),
	whenReady () {
		if (!this.readyPromise) {
			this.readyPromise = new Promise((resolve, reject) => {
				this.fetchIfNot().then(
					() => {
						const emps = busData.request('employees');
						emps.reset(this.get('employees'));
						emps.populated = true;

						const deps = busData.request('departments');
						deps.reset(this.get('departments'));
						deps.populated = true;

						const groups = busData.request('groups');
						groups.reset(this.get('groups'));
						groups.populated = true;

						const productTypes = busData.request('productTypes');
						productTypes.reset(this.get('productTypes'));
						productTypes.populated = true;

						const measures = busData.request('measures');
						measures.reset(this.get('measures'));
						measures.populated = true;


						_.extend(enumsStore, this.get('enums'));
						return resolve();
					},
					() => {
						return reject();
					}
				);
			});
		}
		return this.readyPromise;
	},
	getEmployees () {
		return this.get('employees');
	},
	getGroups () {
		return this.get('groups');
	},
	getDepartments () {
		return this.get('departments');
	},
	getModules () {
		return this.get('modules');
	},
	getModuleName (id) {
		const modules = this.getModules() || {};
		return (modules[id] || {}).label || id;
	},
	getModuleEnum (id) {
		const modules = this.getModules() || {};
		return (modules[id] || {}).rightsType;
	}
});

const appdata = new AppData();

export default appdata;
