
import busData from 'bus/data';
import Collection from 'base/collection';
// import { ProdCycleProdModel } from './prodCycleProd-model';
// import { componentComparator } from 'mod/productionProducts/models/prod-cycle/prodCycleProd-model';

export const ProdCycleProcsCollection = Collection.extend({
	hasMy () {
		const user = busData.user();
		return this.models.some(m => user.isMe(m.get('ownerId')));
	}
});


function buildCycleProcessCollection (model) {
	const models = model.get('processes') || [];
	const col = new ProdCycleProcsCollection(models, { parent: model });
	return col;
}

export function getCycleProcessCollection (model) {
	if (!model.procsCollection) {
		model.procsCollection = buildCycleProcessCollection(model);
	}
	return model.procsCollection;
}



// function deprecated() {
// let chars = 'abcdefghijk';
// for(let x = 1; x < 50; x++) {
// 	let amount = Math.random() * 100 >> 0;
// 	let id = chars[(Math.random() * (chars.length - 1) >> 0)] +
// 	chars[(Math.random() * (chars.length - 1) >> 0)] +
// 	chars[(Math.random() * (chars.length - 1) >> 0)] + '-' +
// 	chars[(Math.random() * (chars.length - 1) >> 0)] +
// 	chars[(Math.random() * (chars.length - 1) >> 0)] + '-' + x;
// 	models.push({ id, amount });
// }
// }
