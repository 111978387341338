import { _ } from 'vendors';
const propExpr = /^change:(\w+)$/i;

export default Base => Base.extend({
	constructor (data, fields) {
		if (fields && fields.enablePropertyChangeEvents) {
			this.enablePropertyChangeEvents = fields.enablePropertyChangeEvents;
		}
		if (fields && fields.propertyChangeEventBehavior) {
			this.enablePropertyChangeEvents = fields.propertyChangeEventBehavior;
		}
		Base.apply(this, arguments);
		if (this.enablePropertyChangeEvents) {
			this.__setupPropertyChangeListener();
		}
	},

	enablePropertyChangeEvents: false,
	propertyChangeEventBehavior: 'invoke', // 'invoke' || 'trigger';
	__setupPropertyChangeListener () {
		if (this.__propertyListenersSettled) return;

		this.on('all', (event, ...args) => {
			if (!propExpr.test(event)) return;

			const property = event.replace(propExpr, '$1');
			if (this.propertyChangeEventBehavior === 'trigger') {
				this.trigger('propertychange', property, ...args);
			} else if (this.propertyChangeEventBehavior === 'invoke') {
				const method = _.camelCase('on:change:' + property);
				if (typeof this[method] === 'function') {
					this[method](...args);
				}
			}
		});
		this.__propertyListenersSettled = true;
	}
});
