

export default (Base) => Base.extend({
	waitText: 'подождите пожалуйста...',
	emptyText: '... пусто ...',
	searchText: 'ничего не нашлось',
	text: '... пусто ...',
	getText () {
		const dataState = this.getOption('dataState');
		const searched = this.getOption('searchApplied');
		if (dataState === 'fetching') {
			return this.getOption('waitText', { args: [this.model, this] });
		} else if (dataState === 'fetched' && !searched) {
			return this.getOption('emptyText', { args: [this.model, this] });
		} else if (searched) {
			return this.getOption('searchText', { args: [this.model, this] });
		} else {
			return this.getOption('text', { args: [this.model, this] });
		}
	},
	templateContext () {
		return {
			text: this.getText()
		};
	}
});
