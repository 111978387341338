import View from 'components/ui-list/item';
import Block from 'components/ui-card-block/block';
import CollectionView from 'base/collection-view';
import Collection from 'base/collection';
import smartOpen from 'helpers/smart-open';

const Page = View.extend({
	className: 'ui-list-item whole-btn subpage-item',
	tagName: 'li',
	text: m => m.get('name'),
	dynamicClassName () {
		const hash = {};
		if (this.model.get('active')) {
			hash.active = true;
		}
		return Object.keys(hash).join(' ');
	},
	events: {
		click (e) {
			if (this.model.get('active')) {
				e.preventDefault();
				e.stopPropagation();
				return;
			}
			this.trigger('click', this.model, e);
		}
	}
});

const Pages = CollectionView.extend({
	className: 'sub-pages ui-list',
	tagName: 'ul',
	childView: Page,
	initialize () {
		this.collection = new Collection();
	},
	onBeforeRender () {
		this.collection.reset();
		this.collection.set(this.getPages());
		const current = this.collection.find({ url: this.getOption('currentSubpage') });
		if (current) {
			current.set('active', true);
		}
	},
	getPages () {
		const pages = this.getOption('subpages');
        // console.log('-pages-', pages);
		return pages;
	},
	childViewEvents: {
		click (model, event) {
			const parent = this.model;
			const url = this.getOption('url');
			const base = url.replace(/\{([a-z0-9]+)\}/gmi, (txt, attr) => {
				return parent.get(attr);
			});
			smartOpen(base + model.get('url'), event);
		}
	}
});

export default Block.extend({
	header: { text: 'подразделы', addClass: 'bg-main-dark' },
	content: Pages,
	contentModel () {
		return this.model;
	},
	contentOptions () {
		return {
			url: this.getOption('url'),
			subpages: this.getOption('subpages'),
			currentSubpage: this.getOption('currentSubpage')
		};
	}
});
