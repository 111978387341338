import { HamburgerView } from 'base/view-hamburger';
import { BaseView } from 'base/base-view';

import { BaseCollectionView } from 'base/base-collection-view';
import { BaseCollection } from 'base/base-collection';

// const TriggerModel =
const TriggersCollection = BaseCollection.extend({

});

const TabTriggerButtonView = BaseView.extend({
	baseClassName: 'tab-btn',
	modelEvents: {
		change: 'render'
	},
	classNames: [
		v => v.ifActive('active', '')
	],
	isActive () {
		return this.model.get('active') === true;
	},
	ifActive (whenTrue, whenFalse) {
		return this.isActive() ? whenTrue : whenFalse;
	},
	template: '<button class="<%= id %>"><i></i><span><%= name %></span></button>',
	events: {
		'click button' (e) {
			if (this.isActive()) { return; }
			this.trigger('tab:click', this.model);
		}
	}
});

export const InlineTabsTriggers = BaseCollectionView.extend({
	childView: TabTriggerButtonView,
	baseClassName: 'tabs-buttons',
	thisClassName: 'inline',
	initialize () {
		this.createCollection();
		this._fixActiveTab();
	},
	createCollection () {
		if (!this.collection) {
			const buttons = this.getOption('buttons', true);
			this.collection = new TriggersCollection(buttons);
		}
	},
	_fixActiveTab () {
		let found = this.collection.find({ active: true });
		if (found) { return; }
		found = this.collection.find({ default: true });
		if (!found) {
			found = this.collection.first();
		}
		if (!found) { return; }
		this._makeActive(found);
	},
	_makeActive (model) {
		const active = this.collection.find({ active: true });
		if (active) {
			active.set('active', false);
		}
		model.set('active', true);
	},
	childViewEvents: {
		'tab:click' (model) {
			this._makeActive(model);
			this.triggerMethod('tab:click', model);
		}
	}
});
