import { actionsBlock } from './actionsBlock';
import { activityBlock } from './activityBlock';
import { checklistBlock } from './checklistBlock';
import { mainBlock } from './mainBlock';
import { goalsBlock } from './goalsBlock';
import { secondInfoBlock } from './secondInfoBlock';
import { tasksBlock } from './tasksBlock';
import { teamBlock } from './teamBlock';
import { stagesBlock } from './stagesBlock';

export function initializeCommonLayout (view) {
	
	mainBlock(view);

	actionsBlock(view);
	stagesBlock(view);
	goalsBlock(view);
	checklistBlock(view);
	activityBlock(view);
	tasksBlock(view);
	teamBlock(view);
	secondInfoBlock(view);
}
