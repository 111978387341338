import { _ } from 'vendors';
import childView from './month';
import CollectionView from 'base/collection-view';
import Collection from 'base/collection';

export default CollectionView.extend({
	className: 'c-dt-months',
	childView,
	initialize () {
		this.collection = new Collection();
		this.resetCollection();
	},
	resetCollection () {
		const a = []; a[11] = 0;
		const models = _(a).map((t, id) => ({ id }));
		this.collection.reset(models);
	},
	childViewEvents () {
		return {
			'click' (view) {
				this.model.trigger('month:selected', view.model.id);
			}
		};
	}
});
