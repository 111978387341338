import { _ } from 'vendors';
import CollectionView from 'base/collection-view';
import UiListItem from 'components/ui-list/item';
import View from 'base/view';
import comparator from 'helpers/comparator';
import busData from 'bus/data';
import user from 'app/user';
import editValue from 'bus/edit';
import modalError from '../../../../../../helpers/modals/error';
import { openCardButton } from '../../../../../../utils/openCardButton';
import { displayParty } from '../../../../../../utils';
// import './parties.less';

const PartyView = UiListItem.extend({
	renderOnModelChange: true,
	initialize (options) {
		this.mergeOptions(options, ['product']);
		// this.on('render', () => console.log('OPLYA OPLYA OPLYA'));
		// this.model.on('change', () => console.log('WOW WOW WOW'));
	},
	getProduct () {
		return this.model.collection.parent;
	},
	getBaseMeasure (options = {}) {
		const product = this.getProduct();
		const measure = product.get('unitMeasureId');

		if (options.display) {
			return busData.measures(measure, 'short');
		}
		return measure;
	},
	getStoreMeasure (options = {}) {
		const product = this.getProduct();
		const measure = product.get('unitMeasureId');
		const storeMeasure = product.get('storeUnitMeasureId') || measure;
		if (options.display) {
			if (measure !== storeMeasure) { return busData.measures(storeMeasure, 'short'); }
		} else {
			return storeMeasure;
		}
	},
	getStoreAmount (options = {}) {
		const product = this.getProduct();
		const measure = product.get('unitMeasureId');
		const storeMeasure = product.get('storeUnitMeasureId');
		const bis = product.get('baseUnitsInStoreUnit') || 1;
		const amount = this.model.get('baseAmount') / bis;

		if (options.display) {
			if (measure !== storeMeasure) { return _.displayNum(amount, 4); }
		} else {
			return amount;
		}
	},
	tagName: 'li',
	className: 'ui-list-item party-item',
	// renderOnModelChange: true,
	// className: 'party-item',
	// template: '<%= id %> | <%= amount %>',
	text: m => openCardButton({ id: m.id, type: 'batch' }, displayParty(m.get('idString'))),
	// topText: 'номер партии',
	rightItems () {
		// const m = this.model;
		// console.log('#--==', m , this)
		// debugger;
		return [
			{
				template: '<div><i></i><b><% if (!isSameUnit) { %><%= storeAmount %> <%= storeUnit %><% } %></b><span><%= baseAmount %> <%= baseUnit %></span></div>',
				templateContext: {
					// amount: this.model.get('amount'),
					// unit: busData.measures(m.get('batchUnitMeasureId'), 'short'),
					// baseAmount: this.model.getBaseAmount(this.product),
					// baseUnit: busData.measures(this.product.get('unitMeasureId'), 'short')
					// busData.measures(m.get('batchUnitMeasureId'), 'short'),
					baseAmount: _.displayNum(this.model.get('baseAmount'), 4),
					baseUnit: this.getBaseMeasure({ display: true }),
					storeAmount: this.getStoreAmount({ display: true }),
					storeUnit: this.getStoreMeasure({ display: true }),
					isSameUnit: this.getStoreMeasure() === this.getBaseMeasure()
				}
			}
		];
	},
	rightButtons () {
		return [
			this.canManualyAdd() ? { icon: true, class: 'increase', title: 'добавить' } : null,
			this.canManualyRemove() ? { icon: true, class: 'decrease', title: 'списать' } : null
			// { icon: true, class: 'correct' },
		];
	},
	addEvents: {
		'click > button.increase' (ev) {
			ev.stopPropagation();
			this.triggerMethod('increase:click');
		},
		'click > button.decrease' (ev) {
			ev.stopPropagation();
			this.triggerMethod('decrease:click');
		}
	},
	onIncreaseClick () {
		this.product.executeAction('add:store:party', this.model.collection, this.model);
	},
	onDecreaseClick () {
		this.product.executeAction('withdraw:store:party', this.model.collection, this.model);
	},
	canManualyAdd () {
		return this.product.storeBatchCanBeManuallyAdded();
	},
	canManualyRemove () {
		return this.product.storeBatchCanBeManuallyRemoved();
	}
});

const EmptyView = UiListItem.extend({
	text () {
		return this.hasFilter() ? 'ничего не найдено' : 'склад пуст';
	},
	hasFilter () {
		return this.getOption('wasFiltered');
	}

});

export const PartiesListView = CollectionView.extend({
	initialize () {
		this.setViewComparator('id');
		this.listenTo(this.collection, {
			'change:comparator': key => this.setViewComparator(key),
			'id:contains': text => { this._idContains = text; this.filter(); }
		});
		this.collection.fetch();
	},
	tagName: 'ul',
	className: 'parties-list',
	childView: PartyView,
	emptyView: EmptyView,

	emptyViewOptions () {
		return {
			wasFiltered: !!this._idContains
		};
	},
	viewFilter (v) {
		const m = v.model;
		const val = (this._idContains || '').toLowerCase();
		if (!val) {
			return m.get('baseAmount') > 0;
			// return true;
		}
		return m.get('idString').toLowerCase().indexOf(val) > -1;
	},
	setViewComparator (key) {
		if (this[key + 'Comparator']) {
			this.viewComparator = this[key + 'Comparator'];
			this.sort();
		}
	},
	idComparator (v1, v2) {
		return comparator(v1, v2, m => m.id);
	},
	amountComparator (v1, v2) {
		return comparator(v1, v2, m => m.get('amount') || Number.MAX_SAFE_INTEGER);
	},
	childViewOptions () {
		return {
			product: this.model
		};
	}
});


export const AmountView = UiListItem.extend({
	className: 'ui-list-item party-item total-amount',
	// tagName: 'footer',
	text: 'итого',
	rightItems () {
		const isAdmin = user.isAdmin();
		return [
			{
				template: '<div><i></i><b><%= storeAmount %> <%= storeUnit %></b><span><%= baseAmount %> <%= baseUnit%></span></div>',
				templateContext: {
					baseAmount: this.getTotalAmount({ display: true }),
					baseUnit: this.getBaseMeasure({ display: true }),
					storeAmount: this.getTotalStoreAmount({ display: true }),
					storeUnit: this.getStoreMeasure({ display: true })
				}
			},
			isAdmin ? { // eslint-disable-line
				template: '<button class="transparent" data-trigger="fix:current:store:amount" title="исправить значение"><%= currentStore %> <%= measure %></button>',
				// template: '<div><button class="btn-transparent" data-trigger="fix:current:store:amount"><%= currentStore %> <%= measure %></button></div>',
				templateContext: {
					currentStore: this.model.get('storeCurrentAmount'),
					measure: this.getBaseMeasure({ display: true })
				}
			} : null
		];
	},
	getTotalAmount (options = {}) {
		let amount = this.collection.reduce((total, m) => total + m.get('baseAmount'), 0);
		if (options.display) {
			amount = _.displayNum(amount, 4);
		}
		return amount;
	},
	getTotalStoreAmount (options = {}) {
		const total = this.getTotalAmount();
		const bis = this.model.get('baseUnitsInStoreUnit') || 1;
		const amount = total / bis;
		const measure = this.model.get('unitMeasureId');
		const storeMeasure = this.model.get('storeUnitMeasureId') || measure;
		if (options.display) {
			if (measure !== storeMeasure) { return _.displayNum(amount, 4); }
		} else {
			return amount;
		}
	},
	getBaseMeasure (options = {}) {
		let measure = this.model.get('unitMeasureId');
		if (options.display) {
			measure = busData.measures(measure, 'short');
		}
		return measure;
	},
	getStoreMeasure (options = {}) {
		const measure = this.model.get('unitMeasureId');
		const storeMeasure = this.model.get('storeUnitMeasureId') || measure;
		if (options.display) {
			if (measure !== storeMeasure) { return busData.measures(storeMeasure, 'short'); }
		} else {
			return storeMeasure;
		}
	},
	collectionEvents: {
		update: 'render',
		change: 'render'
	},
	onFixCurrentStoreAmountClick () {
		editValue.do({
			header: 'укажите новое количество в учётных единицах',
			valueType: 'number',
			value: this.getTotalAmount()
		}).then(amount => {
			const parsed = parseFloat(amount, 10);
			if (isNaN(parsed) || amount < 0) {
				modalError('неудалось исправить значение - некорректный ввод');
			}
			return this.model.save(null, { wait: true, attrs: { storeCurrentAmount: amount }, method: 'PATCH' });
		},
		() => {}
		).then(() => {}, xhr => modalError('неудалось исправить значение', xhr));
	}
});

export const PartiesHeaders = View.extend({
	className: 'list-header',
	template: '<label class="by-id">номер партии</label><label class="by-amount">количество</label>',
	events: {
		'click label.by-id' () { this.collection.trigger('change:comparator', 'id'); },
		'click label.by-amount' () { this.collection.trigger('change:comparator', 'amount'); }
	}
});

export const PartiesView = View.extend({
	tagName: 'section',
	className: 'content',
	template: `
		<div class="helpers"></div>
		<section class="list-header"><label>номер партии</label><label>количество</label></section>
		<div class="items"></div>
		<div class="full-amount"></div>
	`,
	regions: {
		helpers: '.helpers',
		items: '.items',
		amount: '.full-amount'
	},
	onRender () {
		const defs = { model: this.model, collection: this.collection };
		// TODO: проверить кусок
		// const helpers = new HelpersView(defs);
		// this.showChildView('helpers', helpers, { replaceElement: true });

		const items = new PartiesListView(defs);
		this.showChildView('items', items, { replaceElement: true });

		const amount = new AmountView(defs);
		this.showChildView('amount', amount, { replaceElement: true });
	}
});
