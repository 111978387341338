import Object from 'base/object';

const Bus = Object.extend({
	initialize () {

	},
	notconfirmed () {
		this.trigger('unconfirmed:change');
	},
	newamount (amount) {
		if (amount != null) {
			amount = parseFloat(amount, 10);
			if (isNaN(amount) || amount < 0) {
				amount = 0;
			}
		}
		this.trigger('new:amount', amount);
		this.notconfirmed();
	},
	confirm () {
		this.trigger('confirmed');
	}
});

export const bus = new Bus();
