// import { UiMoleculeView } from 'components/ui2/molecule';
import { Ui2AtomView } from 'components/ui2/atom';
import { CommonListItemView } from '../common/ListItemView';

const relationTypes = {
	none: 'не определить',
	both: 'проверяющий и ответственный',
	responsible: 'ответственный',
	checker: 'проверяющий'
};

function buildRelationType (ids) {
	const { id, responsibleId, checkerId } = ids;
	if (!id) {
		return 'none';
	} else if (id === responsibleId && id === checkerId) {
		return 'both';
	} else if (id === responsibleId) {
		return 'responsible';
	} else if (id === checkerId) {
		return 'checker';
	}
}

function extractIds (id = '', attrs) {
	const { responsibleId = '', checkerId = '' } = attrs;
	return {
		id: id.toLowerCase(),
		responsibleId: responsibleId.toLowerCase(),
		checkerId: checkerId.toLowerCase()
	};
}

export default CommonListItemView.extend({
	openCardButton: v => `tasks/${v.model.id}`,
	classNames: [
		v => v.getRelationType()
	],
	content: {
		text: v => v.model.get('toDo')
	},
	// leftButtons: [
	// 	'open-card'
	// ],
	leftItems: [
		v => ({
			class: Ui2AtomView,
			thisClassName: 'fixed',
			text: v.getRelationText()
		})
	],
	getRelationType () {
		if (this._relationId != null) {
			return this._relationId;
		}
		const ids = extractIds(this.getOption('employeeId'), this.model.attributes);
		const relation = buildRelationType(ids);
		this._relationId = relation;
		return relation;
	},
	getRelationText () {
		const type = this.getRelationType();
		return relationTypes[type] || '&nbsp;';
	}

});
