import { _ } from 'vendors';
import CollectionView from 'base/collection-view';
import UiListItem from 'components/ui-list/item';
import { getCollection } from './prodCycleProd-collection';
// import bus from 'bus/models';
import busData from 'bus/data';
import user from 'app/user';
import { BlockTextHeader, BlockFooterTextAction } from 'components/ui2';
import View from 'base/view';
import smartOpen from 'helpers/smart-open';

const EmptyView = UiListItem.extend({
	text: 'сырьё не указано'
});

const ProductView = UiListItem.extend({
	initialize (options) {
		this.mergeOptions(options, ['cycle', 'product']);
	},
	// itemMainTag: 'div',
	className: 'ui-list-item product-cycle-source-item', // with-second-line
	text: (m, v) => (m && m.getProductName()) || (`
		<span class="first-line">${m.getProductName()}</span>
		<dl class="second-line dl-in-a-row">
			<div>
				<dt>нужно для производства:</dt>
				<dd>${v.getProduceNeedText()}</dd>
			</div>
			<div>
				<dt>хватит на:</dt>
				<dd>${v.getCanProduceAmountText()}</dd>
			</div>			
		</dl>
	`),
	getProduceNeedText () {
		const m = this.model;
		return m.get('amount') + ' ' + busData.measures(m.get('produceUnitMeasureId'), 'short');
	},
		// (m.get('name') || bus.request('new:products:model', { id: m.id }).getLabel()),
	// smallText:'huba-buba',
	topText: m => m.getComponentTypeName(),
	cssClassModifiers: [
		m => m.getComponentType() // m.get('optional') && 'optional' || '',
	],
	getStoredAmountInBaseUnits () {
		const instore = this.model.get('storeAmount') || 0;
		if (instore === 0) {
			return 0;
		}
		return instore;
		// let baseUnitsInStoreUnit = this.model.get('baseUnitsInStoreUnit');
		// return instore * baseUnitsInStoreUnit;
	},
	getCanProduceAmountText () {
		return _.displayNum(this.getPossibleAmount(), 4) + ' ' + this.getProductUnit('short');
	},
	getStoredAmountInProduceUnits () {
		const baseAmount = this.getStoredAmountInBaseUnits() || 0;
		const koef = this.model.get('produceUnitsInBaseUnit') || 1;
		return baseAmount * koef;
	},
	getPossibleAmount () {
		// let available = this.getStoredAmountInBaseUnits();
		const available = this.getStoredAmountInProduceUnits();
		if (available === 0) {
			return 0;
		}
		// let one = this.model.get('produceUnitsInBaseUnit');
		const one = this.model.get('amount');
		return Math.floor(available / one);
	},
	getProductUnit (kind) {
		const m = this.product.get('unitMeasureId');
		// console.log('log product', this.product);
		return busData.measures(m, kind);
	},
	leftButtons () {
		return [
			{ icon: true, class: 'open-card', 'data-trigger': 'show:card', title: 'открыть страницу продукции в новой вкладке' }
		];
	},
	rightItems () {
		const m = this.model;
		const v = this;
		return [
			{
				template: '<div class="needed"><i>нужно для производства:</i><span><%= amount%> <%= unit %></span></div>',
				templateContext: {
					amount: _.displayNum(m.get('amount'), 4),
					unit: busData.measures(m.get('produceUnitMeasureId'), 'short')
				}
			},
			{
				template: '<div class="enough"><i>хватит на:</i><span><%= amount%> <%= unit %></span></div>',
				templateContext: {
					amount: _.displayNum(v.getPossibleAmount(), 4),
					unit: v.getProductUnit('short')
				}
			}

		];
	},
	editButton () {
		return this.cycle.canBeEdited();
	},
	onEditClick () {
		this.product.executeAction('edit:produce:cycle:product', this.model, this.cycle);
	},
	onShowCardClick (model, view, event) {
		const options = {
			ctrlKey: !event.ctrlKey
		};
		const url = this.product.cardUrlRoot + '/' + this.model.id;
		smartOpen(url, options);
	}
	// leftButtons() {
	// 	return [
	// 		{ icon: true, class: 'list-icon', title: 'показать список сырья', 'data-trigger':'show:cycle' }
	// 	];
	// },
	// onShowListClick() {
	// 	this.
	// 	document.location.hash = this.model.id || _.uniqueId();
	// }
});

// const ProductView = UiListItem.extend({
// 	//template: 'private',
// 	initialize(options) {
// 		this.mergeOptions(options, ['cycle', 'product']);
// 	},
// })

export const ProdCycleProductsView = CollectionView.extend({
	emptyView: EmptyView,
	childView: ProductView,
	initialize (options) {
		this.mergeOptions(options, ['product', 'cycle']);
		this.collection = getCollection(this.model);
		this.collection.fetch({ preloader: true });
	},
	childViewOptions () {
		return {
			cycle: this.model,
			product: this.product
		};
	},
	viewComparator (a, b) {
		return this.collection.compare(a.model, b.model);
	},
	collectionEvents: {
		'change:order' () {
			this.sort();
		}
	}
});


export const ProdCycleBoxView = CollectionView.extend({
	initialize (options) {
		this.mergeOptions(options, ['cycle', 'product']);
	},
	customViews () {
		const cycle = this.cycle;
		const product = this.product;
		const defs = { model: cycle, product };
		return [
			new BlockTextHeader('Производственный цикл: ' + cycle.get('name')),
			new ProdCycleProductsView(defs),
			(user.checkRights({ productProduction: 'editProduction' }) && cycle.canBeEdited())
				? new BlockFooterTextAction({ tagName: 'div', template: 'добавить сырье', clickAction: 'add:cycle:prod:click', cycle, product })
				: null,
			new BlockFooterTextAction({ template: 'назад к списку', clickAction: 'show:list:click' })
		];
	}
});


export const ProdCycleLayoutView = CollectionView.extend({
	customViews () {
		return [
			new View({ template: 'hello' })
		];
	}
});
