import BaseModel from 'base/model';
// import Collection from 'base/collection';
import busModels from 'bus/models';
// import enums from 'helpers/enums';
// import busData from 'bus/data';
import EditableProperties from 'mixins/editable-properties';
import { fetchContactValues, ensureCollectionModels, getPointsString } from './utils';
// import paths from 'helpers/paths';

export const TransportPointModel = BaseModel.mixWith(EditableProperties).extend({
	properties: {
		address: {
			controlType: 'advanced-address',
			controlOptions: {
				predefinedValues (model) {
					const contragentId = model && model.get('contragent.id');
					if (!contragentId) return;

					return busModels.request('contragent:addresses', contragentId).then(data => {
						return Promise.resolve(data.map(item => ({ key: item.label, value: item.value })));
					});
				}
			},
			display: {
				label: 'адрес',
				transform: v => {
					if (!v) return;
					return v.label || v.address;
				}
			}
		},
		contragent: {
			controlType: 'select-api',
			sourceValues: () => busModels.request('new:contragents:search', { fetch: true }),
			edit: {
				beforeChange (mod) {
					const json = mod == null ? null : mod.toJSON();
					return json;
					// this.set('contragent', json, { silent: true });
					// return mod && mod.id || null;
				}
			},
			editOptions: {
				addClass: 'long-content'
			},
			controlOptions: {
				multiple: false,
				fetchAtStart: true,
				apiSearch: true,
				shouldReturnModel: true
			},
			display: {
				label: 'контрагент',
				// ifEmpty: 'не установолен',
				transform () {
					return this.get('contragent.name');
				}
			}
		},
		contact: {
			valueType: 'text',
			controlType: 'contact-idname',
			controlOptions: {
				predefinedValues (model) {
					const contragentId = model && model.get('contragent.id');
					if (!contragentId) return;

					return busModels.request('contragent:contactNames', contragentId).then(data => {
						return Promise.resolve(data.map(item => ({ key: item.value, value: item })));
					});
				}
			},
			display: {
				label: 'контактное лицо',
				transform: v => v && v.value
			}
		},
		takeCargo: {
			display: {
				label: 'получить груз'
			}
		},
		dropCargo: {
			display: {
				label: 'отдать груз'
			}
		},
		comment: {
			valueType: 'bigtext',
			display: {
				label: 'комментарий'
			}
		},
		workHours: {
			valueType: 'text',
			display: {
				label: 'в какое время доставить/забрать'
			}
		},
		notifyFirst: {
			valueType: 'text',
			display: {
				label: 'требуется предупредить'
			}
		}
	},
	validate () {
		const cargo = this.get('dropCargo') || this.get('takeCargo');
		const address = this.get('address.address');
		const contact = this.get('contact.value');
		return cargo && address && contact;
	},
	async fetchContactValues () {
		const data = await fetchContactValues(this.attributes);
		if (data != null) {
			this.set('contact.values', data, { silent: true });
		}
		return data;

		/*
		let values = this.get('contact.values');
		if (values) {
			return values;
		}
		let contragentId = this.get('contragent.id');
		let contactId = this.get('contact.id');
		if (!contactId || !contragentId)
			return [];

		let url = [paths.api('contragents'), contragentId, 'contacts', contactId, 'values'].join('/');
		var col = new Collection();
		col.url = url;

		try {
			let data = await col.fetch();
			this.set('contact.values', data, { silent: true });
			return data;
		} catch (e) {
			return [];
		}
		*/
	}
});


// toAddress: {
// 	address: 'Россия, Москва, Нагорный проезд, 12к1',
// 	lat: 55.685706,
// 	lng: 37.606963
// }



export default BaseModel.mixWith(EditableProperties).extend({
	defaults () {
		return {
			type: 'transportRequest'
		};
	},
	getRawPoints (nullable) {
		const points = this.get('points');
		return ensureCollectionModels(points, nullable);
	},
	getTodoString () {
		const points = this.getRawPoints();
		return getPointsString(points);
	}
});
