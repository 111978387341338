// import BaseView from 'base/view';
import View from 'components/ui-card/item';
import uiList from 'components/ui-list/item';

const nestedViewClass = uiList.extend({
	renderOnModelChange: false,
	cssClassModifiers: [
		m => m.getClickAction() ? 'whole-btn' : ''
	],
	ui: {
		topText: '> div > i',
		text: '> div > span',
		smallText: '> div > small',
		bottomText: '> div > b'
	},
	modelEvents: {
		change: 'refreshHtmlParts'
	},
	icon: true,
	text: m => m.getText(),
	smallText: m => m.getSmallText(),
	bottomText: m => m.getBottomText(),
	topText: m => m.getTopText(),
	closeButton: true,
	onCloseClick (m, v, e) {
		e.stopPropagation();
		v._finalize();
	},
	_finalize () {
		const m = this.model;
		if (!m.isViewed()) {
			if (m.shouldBeStored()) { m.trigger('trigger:viewed'); } else { m.trigger('trigger:destroy'); }
		} else {
			m.trigger('trigger:destroy');
		}
	},
	onBeforeDestroy () {
		this.__obd_done = true;
		clearInterval(this.refreshTimer);
		clearTimeout(this.timer);
	},
	// _tryRender(){
	// 	this.render();
	// },
	_setDelayedFinalize (delay) {
		if (this.timer) {
			clearTimeout(this.timer);
		}
		if (delay) {
			this.timer = setTimeout(() => {
				this._finalize();
			}, delay);
		}
	},
	_setDelayedRefreshParts () {
		const refresher = () => {
			if (!this.isDestroyed()) {
				this.refreshHtmlParts();
				setTimeout(refresher, 5000);
			}
		};
		setTimeout(refresher, 5000);

		// if (this.refreshTimer) return;

		// this.refreshTimer = setInterval(() => {
		// 	if (this.isDestroyed()) {
		// 		clearInterval(this.refreshTimer);
		// 		return;
		// 	}
		// 	this.refreshHtmlParts();
		// }, 10000);
	},
	onRender () {
		/*
		let m = this.model;
		let delay = !m.isViewed() ? m.getViewedDelay() : m.getDestroyDelay();
		this._setDelayedFinalize(delay);
		this._setDelayedRefreshParts();
		*/
	},
	refreshHtmlParts () {
		this.ui.topText.html(this.getTopText());
		this.ui.text.html(this.getText());
		this.ui.smallText.html(this.getSmallText());
		this.ui.bottomText.html(this.getBottomText());
	}
});

export default View.extend({
	// addClass:'whole-btn',
	renderOnModelChange: false,
	className: 'card lined fast-notify',
	cssClassModifiers: [
		m => m.isViewed() ? 'viewed' : '',
		m => m.getType(),
		m => m.getName(),
		m => m.getEventType()
	],
	nestedViewClass,
	cardLines: [
		{
			name: 'itemLine'
		}
	],
	modelEvents: {
		'trigger:viewed' () {
			// this.trigger('timer', this);
			this.animateAndSetViewed();
		},
		'trigger:destroy' () {
			this.animateDestroy();
		}
	},
	_disappear (callback) {
		this.$el.animate({
			height: 'toggle',
			width: 'toggle'
		}, 300, callback);
	},
	animateAndSetViewed () {
		this._disappear(() => { this.model.setViewed(); this.$el.attr('style', ''); });
	},
	animateDestroy () {
		this._disappear(() => this.model.destroy());
	},

	clickAction: m => m.getClickAction()


});

/*
export default BaseView.extend({
	className: 'fast-notify ver-2',
	renderOnModelChange: false,
	cssClassModifiers:[
		m => m.isViewed() ? 'viewed' : '',
		m => m.getType(),
		m => m.getName(),
		m => m.getEventType(),
	],
	template: _.template(`
		<div class="icon"><i></i></div>
		<div class="body">
			<div class="top-system"></div>
			<div class="text"></div>
			<div class="small"></div>
			<div class="bottom-system"></div>
		</div>
		<div class="button"><button><i></i></button></div>
	`),
	initialize(){
		this.on('all', c => //console.log(c));
	},
	onRender(){
		//console.log('render', this.cid);
	}
});
*/

