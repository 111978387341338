import { _ } from 'vendors';
import { invokeValue } from 'utils';

export function buildFilters (filters, options) {

	const { include = [], exclude = [], extend = {}, invokeContext, invokeArgs } = (options || {});

	return _.reduce(filters, (memo, filterValue, filterKey) => {

		filterValue = invokeValue(filterValue, invokeContext, invokeArgs);

		if (include.length && include.indexOf(filterKey) === -1) {
			return memo;
		}

		if (exclude.length && exclude.indexOf(filterKey) > -1) {
			return memo;
		}


		const extValue = extend[filterKey];

		memo[filterKey] = Object.assign({}, filterValue, extValue);


		return memo;
	}, {});
}
