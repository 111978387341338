import { _ } from 'vendors';
const processor = {

	_mergeResult (src, forMerge) {
		src.added.push(...forMerge.added);
		src.removed.push(...forMerge.removed);
	},
	_normalizeResult (result) {
		result.added = _.uniq(result.added);
		result.removed = _.uniq(result.removed);
		return result;
	},
	addModels (groups, groupBy, added = [], addContext = {}) {
		_(added).each((model) => {
			const groupId = groupBy(model);
			processor.add(model, groupId, groups, addContext);
		});
		return addContext;
	},
	mergeModels (groups, groupBy, merged = [], addContext = {}, removeContext = {}) {
		_(merged).each((model) => {
			const groupId = groupBy(model);
			processor.merge(model, groupId, groups, addContext, removeContext);
		});
		return removeContext;
	},
	setModels (groups, addContext, removeContext, globalRemoved) {
		const result = {
			added: [],
			removed: []
		};

		if (!_.size(removeContext) && !_.size(addContext) && !_.size(globalRemoved)) { return result; }

		_(groups).each((group, groupId) => {
			const groupResult = processor.setGroupModels(groupId, group.collection, addContext, removeContext, globalRemoved);
			processor._mergeResult(result, groupResult);
		});

		return processor._normalizeResult(result);
	},
	setModel (groups, addContext, removeContext) {
		const result = {
			added: [],
			removed: []
		};

		if (!_.size(removeContext) && !_.size(addContext)) { return result; }

		_(groups).each((group, groupId) => {
			if ((addContext[groupId] || []).length) {
				result.added.push(...addContext[groupId]);
				group.collection.add(addContext[groupId]);
			}
			_(removeContext).each((models, gid) => {
				if (gid !== groupId && models && models.length) {
					result.removed.push(...models);
					group.collection.remove(models);
				}
			});
		});
		return processor._normalizeResult(result);
	},
	setGroupModels (groupId, collection, addContext, removeContext, globalRemoved) {
		const result = {
			added: [],
			removed: []
		};

		const shouldBeRemoved = processor.removeGroupHash(groupId, removeContext, globalRemoved);

		const newSet = collection.filter((m) => {
			const notRemoved = !(m.cid in shouldBeRemoved);
			if (!notRemoved) { result.removed.push(m); }
			return notRemoved;
		});
		const added = addContext[groupId] || [];
		result.added.push(...added);
		newSet.push(...added);
		collection.set(newSet);

		return result;
	},
	resetModels (groups, grouping) {
		_(groups).each((group, groupId) => {
			const models = grouping[groupId] || [];
			processor.resetGroupModels(group, models);
		});
	},
	resetGroupModels (group, models = []) {
		group.collection.reset(models);
	},
	add (model, groupId, groups, context) {
		if (!(groupId in groups)) { return; }
		context[groupId] || (context[groupId] = []);
		context[groupId].push(model);
	},
	remove (model, groupId, context) {
		context[groupId] || (context[groupId] = []);
		context[groupId].push(model);
	},
	merge (model, groupId, groups, addContext = {}, removeContext = {}) {
		processor.remove(model, groupId, removeContext);
		processor.add(model, groupId, groups, addContext);
	},
	removeGroupHash (groupId, removeContext, globalRemoved) {
		const hash = {};
		_(removeContext).each((models, id) => {
			if (groupId !== id) { processor.modelsToHash(models, hash); }
		});
		return _.extend(hash, globalRemoved);
	},
	modelsToHash (models = [], hash = {}) {
		return _.reduce(models, (hash, item) => {
			hash[item.cid] = item;
			return hash;
		}, hash);
	}
};
export default processor;
