import { _ } from 'vendors';
import View from 'base/view';

export default View.extend({
	className () {
		const current = (this.model.id === (new Date()).getFullYear());
		return current ? 'current' : undefined;
	},
	template: _.template('<%= id %>'),
	triggers: { click: 'click' }
});
