import BasePage from 'pages/auth';
import Layout from './layout';
import Model from 'mod/tasks/models/model';


const Page = BasePage.extend({
	id: 'tasks:card',
	label () { return 'задача: ' + ((this.model && this.model.display('toDo')) || ''); },
	route: ':id',
	skipMenu: true,
	Layout,
	wrapLayout: true,
	socketGroups: (m) => 'employeetask:' + m.id.toString().toLowerCase(),
	onBeforeStart (ac) {
		this.initializeModel(ac.args.id);
	},
	initializeModel (id) {
		if (this.model && this.model.id !== id) {
			this.stopListening(this.model);
			delete this.model;
		}
		const model = this.model || new Model({ id });
		this.addModel(model);
		this.addStartPromise(model.fetch());
	}
});

export default Page;
