import { _ } from 'vendors';
import Model from 'base/model';
import Collection from 'base/collection';
import paths from 'helpers/paths';
import busData from 'bus/data';
import { productPageCardUrl } from 'app/constants';

export const productionApiUrl = paths.urls.api('production');

// const productionTreesApiUrl = productionApiUrl + '/trees';
const productionCyslesApiUrl = id => productionApiUrl + '/' + id + '/cycles';

export const productionSalesApiUrl = productionApiUrl + '/sales';
export const productionAllInfoApiUrl = productionApiUrl + '/full';

const measure = v => busData.measures(v, 'short');

const chunks = (...args) => args.join('/');

function updateCollection (model, key, options) {
	updateThing(model, key, [], 'reset', options);
}

// function updateModel (model, key, options) {
// 	updateThing(model, key, {}, 'set', options);
// }

function updateThing (model, key, defaultValue, method, options) {
	const thing = model[key];
	if (!thing) return;
	const data = model.get(key) || defaultValue;
	thing[method].call(thing, data, options); // eslint-disable-line
}

function ensureCollection (model, key, CollectionClass, options) {
	// let col = model[key];
	// if (col) return col;
	// let models = model.get(key) || [];
	// col = model[key] = new CollectionClass(models, options);
	// col.parent = this;
	// return col;
	return ensureThing(model, key, [], CollectionClass, options);
}

function ensureModel (model, key, ModelClass, options) {
	return ensureThing(model, key, {}, ModelClass, options);
}

function ensureThing (model, key, defaultValue, Class, options) {
	let newone = model[key];
	if (newone) return newone;
	const data = model.get(key) || defaultValue;
	newone = model[key] = new Class(data, options);
	newone.parent = model;
	return newone;
}



const BaseModel = Model.extend({
	constructor () {
		this.__type = this.type;
		Model.apply(this, arguments);
	},
	getName () {
		throw new Error('getName Not Implemented');
	},
	getProductBaseUnit () {
		throw new Error('getProductBaseUnit Not Implemented');
	}
});


export const ComponentModel = BaseModel.extend({
	type: 'ComponentModel',
	getName () {
		const prod = this.getProductModel();
		return prod.getName();
	},
	getProductBaseUnit (parent) {
		const prod = this.getProductModel();
		return prod.getProductBaseUnit();
	},
	getProductModel (options) {
		// if (!this.product) {
		// 	this.product = new ProductModel();
		// 	this.product.parent = this;
		// }
		// let product = this.get('product') || {};
		// this.product.set(product, options);
		// return this.product;
		return ensureModel(this, 'product', ProductModel, options);
	},
	getCyclesCollection () {
		return this.getProductModel().getCyclesCollection();
	},
	isForeign () {
		return this.getProductModel().isForeign();
	},
	hasNoCycles () {
		return this.getProductModel().hasNoCycles();
	},
	fetchCycles () {
		return this.getProductModel().fetchCycles();
	},
	getProduceAmount (inBase) {
		const amount = this.get('amount');
		if (!amount || !inBase) return amount || 0;
		const koef = this.get('unitsInBaseUnit');
		const res = amount / koef;
		return res;
	},
	displayProduceAmount (digits, options) {
		digits == null && (digits = 4);
		const amount = this.getProduceAmount();
		const unit = this.get('unit');
		const unitText = measure(unit);
		return _.displayNum(amount, digits, options) + ' ' + unitText;
	},
	getPossibleAmount () {
		const product = this.getProductModel();
		const inStore = product.getStoreAmount(true);
		const produceAmount = this.getProduceAmount(true);
		let res = inStore / produceAmount;

		try {
			const cycle = this.collection.parent;
			res = res * cycle.get('baseUnitsInProductionUnit');
		} catch {}


		// console.log('## ', res, this);
		return res;
	},
	displayPossibleAmount (parent) {
		const unit = parent.getProductBaseUnit();
		let amount = this.getPossibleAmount();
		amount = Math.floor(amount);
		return _.displayNum(amount, 0) + ' ' + measure(unit);
		// throw new Error('Not implemented displayPossibleAmount')
		// digits == null && (digits = 4);
		// if (!unit) return;
		// let amount = this.getPossibleAmount();
		// let unitText = measure(unit);
		// return _.displayNum(amount, digits, options) + ' ' + unitText;
	}
});

export const ComponentsCollection = Collection.extend({
	model: ComponentModel
});

export const ProductCycleModel = BaseModel.extend({
	type: 'ProductCycleModel',
	getProductBaseUnit (parent) {
		if (!parent) {
			throw new Error('getProductBaseUnit Not Implemented, parent is null');
		}
		return parent.getProductBaseUnit();
	},
	getComponentsCollection () {
		// if (!this.components) {
		// 	this.components = new ComponentsCollection();
		// 	this.components.parent = this;
		// }
		// let components = this.get('components') || [];
		// this.components.reset(components);
		// return this.components;
		return ensureCollection(this, 'components', ComponentsCollection);
	},
	getPossibleAmount () {
		const col = this.getComponentsCollection();
		const amounts = col.map(model => model.getPossibleAmount());
		const minamount = _.min(amounts);
		return minamount; // * this.get('baseUnitsInProductionUnit'); - already multiplied
	},
	displayPossibleAmount (parent) {
		const amount = this.getPossibleAmount();
		const unit = this.getProductBaseUnit(parent);
			// parent.getProductBaseUnit();
			// parent.get('unit');
		const unitText = measure(unit);
		return _.displayNum(amount, 0) + ' ' + unitText;
	},
	getCardUrl () {
		const baseUrl = this.collection.parent.getCardUrl();
		return chunks(baseUrl, 'prod-from', this.id);
	}
});

export const ProductCyclesCollection = Collection.extend({
	model: ProductCycleModel
});



export const ProductModel = BaseModel.extend({
	type: 'ProductModel',
	getName () {
		return this.get('name');
	},
	getProductBaseUnit (parent) {
		return this.get('unit');
	},
	getCardUrl () {
		return chunks(productPageCardUrl, this.id); // busData.request('product:page:url'), this.id);
	},
	getCyclesCollection () {
		return ensureCollection(this, 'cycles', ProductCyclesCollection);
	},
	isForeign () {
		const res = this.get('origin') === 'purchase';
		return res;
	},
	hasNoCycles () {
		if (this.isForeign()) return true;

		const col = this.getCyclesCollection();
		if (col.length > 0) return false;
		if (!this.parent) return true;
		return this._cyclesFetched;
		// if (!this.parent && !col.length) {
		// 	return true;
		// }
		// if (this.parent && )
		// return false;
	},
	fetchCycles () {
		return this.fetch({ url: productionCyslesApiUrl(this.id) }).then((data) => {
			this._cyclesFetched = true;
			this.trigger('after:fetch:cycles', this);
			updateCollection(this, 'cycles');
		});
	},
	getStoreAmount (inBase) {
		const amount = this.get('storeAmount');
		return amount || 0;
		// if (!amount || !inBase) return amount || 0;
		// let koef = this.get('unitsInStoreUnit');
		// let res = amount * koef;
		// return res;
	},
	displayStoreAmount (inBase, digits, options) {
		digits == null && (digits = 4);
		const amount = this.getStoreAmount(inBase);

		const unit = this.get('unit'); // inBase ? this.get('unit') : this.get('storeUnit');
		const unitText = busData.measures(unit, 'short');

		return _.displayNum(amount, digits, options) + ' ' + unitText;
	}
});

export const ProductsCollection = Collection.extend({
	model: ProductModel
});

