import { _ } from 'vendors';
import Collection from '../models/collection';
import action from 'helpers/action';
import createAction from '../create-task-action';
// import CreateTask from '../views/create';
// import modals from 'helpers/modals';
// import modalError from 'helpers/modals/error';

export default (Base) => Base.extend({

	constructor (opts = {}) {
		this.registerNestedEntity('tasks', { class: Collection });
		if (this.completedTasksEnabled) {
			const fn = this.getCompletedTasksDefinition ? this.getCompletedTasksDefinition.bind(this) : arg => arg;
			this.registerNestedEntity('completedTasks', fn(Collection));
		}
		Base.apply(this, arguments);


		const actions = [].concat((this.actions || []), (opts.actions || []));

		const rule = () => {
			if (_.isFunction(this.getAddTaskRule)) {
				return this.getAddTaskRule();
			}
		};

		const rights = () => {
			if (_.isFunction(this.getAddTaskRights)) {
				return this.getAddTaskRights();
			}
		};

		actions.push(action('add:task', 'поставить задачу', rights(), { rule: rule() }));

		this.actions = actions;
	},
	getTasks () {
		return this.entity('tasks');
	},
	getCompletedTasks () {
		return this.entity('completedTasks');
	},
	actionAddTask (action, opts) {
		const options = _.extend({ parent: this }, opts);
		createAction(options);
	}
});
