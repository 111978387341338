import CollectionView from 'base/collection-view';
import childView from './item';
import comparator from 'helpers/comparator';
export default CollectionView.extend({
	childView,
	viewComparator (v1, v2) {
		return comparator([v2, v1, (m) => m.getOrder()]);
	},
	collectionEvents: {
		change () {
			this.sort();
		}
	}
});
