export function takeFilters (arr, excludeIds, takeIds) {
	if (excludeIds == null && takeIds == null) { return arr; }

	if (takeIds && takeIds.length) {
		arr = arr.filter(f => takeIds.indexOf(f.id) > -1);
	}

	if (excludeIds && excludeIds.length) {
		arr = arr.filter(f => excludeIds.indexOf(f.id) < 0);
	}

	return arr;
}
