import '../api-config';
import BoardModel from 'components/board/board-model';
import paths from 'helpers/paths';
import ItemModel from 'mods/tasks/models/model';
import CardView from 'mods/tasks//views/card-item';

const ProcessTasksBoard = BoardModel.extend({
	defaults: {
		parentEntityType: 'process',
		modelType: 'task'
	},
	shouldAddDefaultList: true,
	modelsAreLinked: true,
	entityConfig: {
		Model: ItemModel,
		View: CardView
	},
	defaultUrl () {
		return paths.api('processes', this.get('parentEntityId'), 'tasksBoard');
	}
});
export default ProcessTasksBoard;

