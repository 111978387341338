
import proto from 'components/values/get-prototype';
import enums from 'helpers/enums';
import busData from 'bus/data';

// import busModels from 'bus/models';

import { buildProductSelectFilter } from 'mod/productionProducts/filters/select-filter';
import { buildContragentSelectFilter } from 'mod/contragents/filters/select-filter';
// import { buildEmployeeSelectFilter } from 'mod/staff/emps/filters/select-filter';

import { periodFilter } from './filters';

export const productFilters = [
	{
		id: 'product:text',
		valueId: 'text',
		label: 'поиск по ключевому слову',
		hidden: true,
		valuePrototype: proto('text'),
		textSearch: true
	},


	{
		id: 'product:typeId',
		valueId: 'typeId',
		label: 'тип',
		pinned: true,
		// values: [{value: 'client'}],
		valueLabel: (val) => busData.productTypeName(val),
		valuePrototype: () => ({
			multiple: false,
			sourceValues: () => busData.productTypes(),
			controlType: 'select',
			modelType: 'single'
		})
	},
	{
		id: 'product:origin',
		valueId: 'origin',
		label: 'источник',
		pinned: true,
		// values: [{value: 'client'}],
		valueLabel: (val) => enums.get('productOrigins', val),
		valuePrototype: () => proto('enum', {
			multiple: true,
			sourceValues: () => enums.store.productOrigins
		})
	},
	{
		id: 'product:purpose',
		valueId: 'purpose',
		label: 'назначение',
		pinned: true,
		// values: [{value: 'client'}],
		valueLabel: (val) => enums.get('productPurposes', val),
		valuePrototype: () => proto('enum', {
			multiple: true,
			sourceValues: () => enums.store.productPurposes
		})
	},
	{
		id: 'product:hasBatches',
		valueId: 'hasBatches',
		label: 'Используется',
		pinned: true,
		// values: [{value: 'client'}],
		values: true,
		valueLabel: (val) => enums.get('yesNo', val),
		valuePrototype: () => proto('enum', {
			multiple: false,
			sourceValues: () => enums.store.yesNo
		})
	},

	buildProductSelectFilter({
		id: 'byproduct',
		valueId: 'byproduct',
		label: 'относится к продукции',
		pinned: true
	}),
	buildContragentSelectFilter({
		id: 'bycontragent',
		valueId: 'bycontragent',
		label: 'относится к контрагенту',
		pinned: true
	}),

	{
		id: 'byemployee',
		valueId: 'byemployee',
		label: 'относится к сотруднику',
		pinned: true,
		valuePrototype: {
			modelType: 'single',
			multiple: false,
			controlType: 'employeesSelect',
			sourceValues: () => busData.employees()
		},
		valueLabel (id) {
			return busData.employees(id).display('name');
		}
	}


	// {
	// 	id:'byproduct',
	// 	valueId:'byproduct',
	// 	label: 'относятся к продукции',
	// 	pinned: true,
	// 	nestedModel() {
	// 		if (this._nestedModelInitialized) {
	// 			return;
	// 		}
	// 		let id = this.getValue();
	// 		console.log('>>>> NESTED UD', id);
	// 		if (id) {
	// 			let model = busModels.request('new:products:model', { id });
	// 			model.fetchIfNot();
	// 			this.listenToOnce(model, 'change', () => this.trigger('change', this));
	// 			this._nestedModelInitialized = model;
	// 		}
	// 	},
	// 	valueLabel(val){
	// 		return busModels.request('new:products:model', { id: val }).display('fullName');
	// 	},
	// 	valuePrototype: {
	// 		controlType: 'select-api',
	// 		modelType: 'single',
	// 		multiple: false,
	// 		sourceValues: () => {
	// 			let col = busModels.request('new:products:search');
	// 			col.fetchIfNot();
	// 			return col;
	// 		},

	// 	}
	// },

	// {
	// 	id:'product:isArchived',
	// 	valueId:'isArchived',
	// 	label: 'Архив',
	// 	pinned: true,
	// 	valueLabel: (val) => enums.get({ false: 'Действующая продукция', true: 'Архивная продукция'}, val || false),
	// 	valuePrototype: () =>proto('enum',{
	// 		multiple:false,
	// 		sourceValues:() => ({ false: 'Действующая продукция', true: 'Архивная продукция'}),
	// 	})
	// },
	// {
	// 	id:'product:isDeleted',
	// 	valueId:'isDeleted',
	// 	label: 'Удалённая продукция',
	// 	pinned: true,
	// 	rights: { admin: true, resourcesProduction: 'productsEdit' },
	// 	valueLabel: (val) => enums.get({ false: 'нет', true: 'да'}, val || false),
	// 	valuePrototype: () =>proto('enum',{
	// 		multiple:false,
	// 		sourceValues:() => ({ false: 'не удалённая продукция', true: 'удалённая продукция'}),
	// 	})
	// },
];



export const productFiltersWithPeriod = [
	...productFilters,
	periodFilter
    // {
    //     id:'period',
    //     valueId: 'period',
    //     label: 'период',
    //     pinned: true,
    //     valuePrototype: () => ({
    //         multiple:false,
    //         //sourceValues:() => busData.productTypes(),
    //         //controlType:'select',
    //         modelType:'range',
    //         valueType: 'date'
    //     }),
    // }
];
