import Model from 'base/model';

export const TabModel = Model.extend({
	makeActive () {
		const current = this.collection.find({ active: true });
		if (current) {
			current.makeInactive();
		}
		this.set('active', true);
	},
	makeInactive () {
		this.set('active', false);
	}
});
