import { _ } from 'vendors';
import View from 'base/view';
import CollectionView from 'base/collection-view';
import ListView from './list-view';

import BoardModel from './board-model';

import { SwappableBehavior } from 'behaviors/sortable';


import FDC from 'mixins/view/flex-dynamic-column';
import mix from 'helpers/mix';



const SwapBoardLists = SwappableBehavior.extend({
	selector: '.sortable-anchor',
	property: 'order',
	swapOptions: { silent: true },
	onInteractionStart (view) {
		view.$el.addClass('swapping');
	},
	onInteractionEnd (view, models) {
		view.$el.removeClass('swapping');
		if (models && models.length) {
			this.view.collection.sort();
			this.view.model.set('lists', this.view.collection.toJSON(), { changeInitiator: this });
			this.view.model.trigger('save:lists');
		}
	},
	canBeSwapped (enteredView) {
		return enteredView instanceof ListView;
	}
});

const AddNew = View.extend({
	className: 'board-list',
	template: _.template('<button class="add-column"> + добавить колонку</button>'),
	triggers: {
		'click .add-column': 'show:add:dialogue'
	}

});

export default mix(CollectionView).with(FDC).extend({
	cssClassModifiers: ['board'],
	childView: ListView,
	behaviors: [SwapBoardLists], // [OrdererBehavior],
	initialize () {
		this.initModels();
	},

	viewComparator (v1, v2) {
		const o1 = v1.model ? v1.model.get('order') : Infinity;
		const o2 = v2.model ? v2.model.get('order') : Infinity;
		// console.log('order!', v1.model && v1.model.get('name'), v1.model && v1.model.get('order'));
		return o1 - o2;
	},
	initModels () {
		const config = this.getOption('boardConfig') || {};
		const entityConfig = _.result(config, 'entityConfig');
		if (!this.model) {
			this.model = new BoardModel({}, { entityConfig });
		}
		if (this.getOption('fetchOnInitialize')) {
			this.model.fetch();
		}
		if (!this.collection) {
			this.collection = this.model.entity('lists');
		}
	},
	childViewOptions () {
		return {
			board: this.model,
			boardConfig: this.getOption('boardConfig')
		};
	},
	onRender () {
		this.addButtonView();
	},
	addButtonView () {
		const view = new AddNew({ model: this.model, collection: this.collection });
		this.addChildView(view, Infinity);
	},
	childViewEvents: {
		'show:add:dialogue' () {
			this.model.addNewList({ save: true });
		}
	}

});
