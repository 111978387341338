import View from 'base/view';
import template from './template.html';
import Values from 'components/values';
import editValue from 'bus/edit'; // 'components/edit-value';

import mix from 'helpers/mix';
import BaseControlMixin from 'components/controls/mixin';

export default mix(View).with(BaseControlMixin).extend({
	template,
	ui: {
		label: '> span'
	},
	initialize () {
		this.prepareInitialValues();
		this.initModel();
		this.initCollection();
	},
	initModel () {
		if (this.model) return;
		this.model = Values.model(this.initialValue);
	},
	initCollection () {
		if (this.collection) return;
		const src = this.getOption('sourceValues');
		if (src == null) { throw new Error('SelectControl: there is no data provided'); }
		const col = Values.collection(src, {});
		this.collection = col;
	},
	getLabel () {
		return this.model.display('value');
	},
	attributes () {
		return {
			'data-role': 'inline-select-trigger'
		};
	},
	events: {
		click () {
			editValue.do({
				proxyTo: this,
				header: this.getOption('header'),
				controlName: 'select',
				controlType: 'select',
				valueType: this.getOption('valueType'),
				multiple: this.getOption('multiple'),
				value: this.model.getValue(),
				noConfirm: this.getOption('noConfirm'),
				resetValue: this.getOption('resetValue'),
				sourceValues: this.collection
			}).then(
				value => this.model.setValue(value),
				value => this.model.setValue(value)
			);
		}
	},
	controlsEvents: {
		'select:change' (value) {
			this.model.setValue(value);
			this.triggerChange(this.model.getValue());
		}
	},
	modelEvents: {
		change: 'render'
	},
	templateContext () {
		return {
			label: this.getLabel()
		};
	}
});
