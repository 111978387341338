import { _ } from 'vendors';
import Yat from 'marionette.yat';
import ActionsPopover from './actions-popover';
import InfoPopover from './popover-info';
import Popover from './popover';
import FoldableSibblings from './foldable-sibblings';
import DnDWithInteractJs from './dnd-with-interactjs';
export { ClickableBehavior } from './clickable';

export { Behavior } from './behavior';

const behaviors = {
	ActionsPopover,
	InfoPopover,
	Popover,
	FoldableSibblings,
	DnDWithInteractJs
};
const beh = _.extend({}, Yat.Behaviors, behaviors);

export {
	ActionsPopover,
	InfoPopover,
	Popover,
	FoldableSibblings,
	DnDWithInteractJs
};

export default beh;
