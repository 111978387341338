import Views from 'mod/contragents/views';
import getRawFilters from 'mod/contragents/filters/get';
import mix from 'helpers/mix';
import FDC from 'mixins/view/flex-dynamic-column';
// import comparator from 'helpers/comparator';
import ViewManagedCollection from 'mixins/view-managed-collection';
import ScrollHandlerMixin from 'mixins/view/scroll-handler';
// import ViewLoadOnScroll from 'mixins/ncv-load-on-scroll.js';

import FetchableMixin from 'mixins/collection-view/fetchable';
import FetchOnScrollMixin from 'mixins/collection-view/fetch-on-scroll';


// FDC, FetchableMixin, FetchOnScrollMixin, ViewManagedCollection, ViewScrollHandler
const View = mix(Views.List).with(FDC, FetchableMixin, FetchOnScrollMixin, ViewManagedCollection, ScrollHandlerMixin).extend({
	id: 'contragents-search-list',
	className: 'contragents-list',
	entriesManagerOptions: {
		infoOptions: true,
		filtersOptions: {
			getRaw: getRawFilters,
			instantApply: true
		}
	},
	onRender () {
		this.fetch();
	},
	attributes () {
		return {
			'data-scrollable': 'contragents-search'
		};
	}
});

export default View;
