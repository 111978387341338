// import { _ } from 'vendors';
import BaseView from 'base/view';
import CardsLayout from './cards-layout';
import appBus from 'bus/app';
import comparator from 'helpers/comparator';

const View = BaseView.extend({
	template: '<div></div>',
	regions: {
		cards: { el: 'div', replaceElement: true }
	},
	onRender () {
		this.showCards();
	},
	showCards () {

		const page = this.getOption('page');
		const link = page.getLink() || {};
		const col = appBus.request('menu');
		const view = new CardsLayout({
			page,
			className: 'pages-cards',
			collection: col,
			viewFilter: (v) => v.model.get('parentId') === link.id,
			viewComparator: (v1, v2) => comparator([v1, v2, (m) => m.get('order')], [v1, v2, (m, v) => v.cid])
		});

		this.showChildView('cards', view);
	}


});

export default View;
