import { ViewWithMenu } from 'pages/views';
// import { childrenMenu } from './children.js';

export default ViewWithMenu.extend({
	className: 'product-page-view',
	initialize (options) {
		this.mergeOptions(options, ['menuItems']);
	}
	// menuItems: childrenMenu,
});
