import BasePage from 'pages/auth';
import PageErr from 'pages/error';
import emps from 'mod/employees/singleton';

import { PageWithDivisions } from '../../../pages';
// import { DepartmentModel, storeApi } from '../models';
import Model from 'mod/employees/models/model';

export const StaffPageBase = PageWithDivisions.extend({
	// moduleRights: { productProduction: 'view' },
	skipMenu: false,
	socketGroups: (m) => 'employee:' + m.id,
	// Layout: PageWithDivisionsLayout,
	// layoutShowOptions: {
	// 	replaceElement: true
	// },
	// getDivisionRootPage () {
	// 	if (this.getOption('divisionRoot')) {
	// 		return this;
	// 	}
	// 	const parent = this.getParent();
	// 	if (!parent.getDivisionRootPage) {
	// 		return null;
	// 	}
	// 	return parent.getDivisionRootPage();
	// },
	setupModel (rd) {
		const id = rd.args.id;
		const model = new Model({ id });
		model.fetch();
		// const { model, promise } = storeApi.get(id);
		// const model = new DepartmentModel({ id: rd.args.departmentId, pageId: this.pageId });
		this.spreadUpModel(model);
		this.addModel(model);
		// this.addStartPromise(promise);
	},
	onBeforeStart (rd) {
		// console.log('[this page]', this.pageId, this);
		this.setupModel(rd);
	},
	preventStart: function (ac) {
		const prev = BasePage.prototype.preventStart.call(this, ac);
		if (prev) return prev;

		if (!ac) { return; }

		const emp = emps.get(ac.args.id);
		if (!emp) { return PageErr.NotFound('Сотрудник не найден', this.url()); }
	}
});
