import Model from 'base/model';
import EditableProperty from 'mixins/editable-properties';
import mix from 'helpers/mix';
import ValidateMixin from 'mixins/model/validate';
import Actionable from 'mixins/actionable';
import action from 'helpers/action';
import editAction from './edit-action';
import busModels from 'bus/models';
// import modals from 'helpers/modals';
// import Edit from './edit';

const requisitsTypes = {
	true: 'основные',
	false: 'второстепенные'
};

const PropertiesMixin = {
	properties: {
		shortName: {
			validation: {
				required: true,
				requiredMessage: 'Заполните название'
			},
			display: {
				label: 'название'
			}
		},
		legalAddress: {
			display: {
				label: 'юридический адрес'
			}
		},
		mailAddress: {
			display: {
				label: 'почтовый адрес'
			}
		},
		inn: {
			display: {
				label: 'ИНН'
			}
		},
		kpp: {
			display: {
				label: 'КПП'
			}
		},
		okpo: {
			display: {
				label: 'ОКПО'
			}
		},
		bik: {
			display: {
				label: 'БИК'
			}
		},
		account: {
			display: {
				label: 'расчетный счет'
			}
		},
		correspondentAccount: {
			display: {
				label: 'кор. счет'
			}
		},
		bankName: {
			display: {
				label: 'название банка'
			}
		},
		phone: {
			display: {
				label: 'контактный телефон'
			}
		},
		email: {
			display: {
				label: 'контактный email'
			}
		},
		person: {
			display: {
				label: 'контактное лицо'
			}
		},
		isPrimary: {
			type: 'boolean',
			sourceValues: requisitsTypes,
			display: {
				label: 'основные реквизиты компании',
				transform: (v) => requisitsTypes[v || false]
			}
		},
		note: {
			type: 'bigtext',
			display: {
				label: 'примечание',
				preserveWhiteSpace: true
			}
		}
	}
};

const BankDetails = mix(Model).with(Actionable, EditableProperty, PropertiesMixin, ValidateMixin).extend({
	actions: [
		action('edit', 'редактировать', null, { hidden: true }),
		action('delete', 'удалить', null, { hidden: true })
	],

	actionEdit () {
		editAction(this);

		// let preloader = { overlay: opts.view };
		// let content = new Edit({
		// 	model: this,
		// 	parent: this.collection.parent,
		// 	destroyAfterPromise: true,
		// 	promiseButtonsOptions:{
		// 		resolveLabel: 'закрыть',
		// 		rejectLabel:'Отменить изменения',
		// 		reset:true,
		// 		resetLabel: 'удалить',
		// 		resetClass: 'delete'
		// 		// rejectLabel: 'закрыть',
		// 	}
		// });
		// modals.show({
		// 	header:'Изменение реквизитов',
		// 	content,
		// 	type:'full',
		// });
		// content.promise.then(
		// 	() => {},
		// 	(reject) => {
		// 		if (reject.reason === 'cancel')
		// 			this.save(reject.data,{wait:true, patch:true, preloader});
		// 		else
		// 			this.destroy({wait:true, preloader:true});
		// 	}
		// );
	}

});

busModels.setModel('BankDetails', BankDetails);

export default BankDetails;
