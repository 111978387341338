export const commonCollectionMixin = {
	initializeCollection () {
		if (!this.collection) {
			const Collection = this.getOption('Collection', true);
			this.collection = new Collection();
		}
		const layout = this.getOption('layoutView');
		if (layout) {
			layout.triggerMethod('data:collection:initialized', this.collection);
		}
	}
};
