import BasePage from 'pages/auth';
import Layout from './list/layout';
import Create from './create';
import Card from './group';
// import List from './list';
// import Layout from './layout';
// import Emps from './emps/emps';

const Page = BasePage.extend({
	moduleRights: { admin: 'view, manageGroups' },
	id: 'admin/groups',
	name: 'groups management page',
	label: 'Управление группами',
	route: 'groups',
	Layout,
	icon: 'groups',
	socketGroups: () => 'employeegroups',
	children: [Create, Card]
});

export default Page;
