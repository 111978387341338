import Collection from 'base/collection';
import { ProdCycleModel, productProdCycleUrlPatternFull } from './prodCycles-model';
// import paths from 'helpers/paths';

export const ProdCyclesCollection = Collection.extend({
	urlPattern: productProdCycleUrlPatternFull,
	model: ProdCycleModel,
	addCycle (data) {
		// if (!data.id) {
		// 	data.id = _.uniqueId();
		// }
		return this.create(data, { wait: true, preloader: true });
	}
});


export function buildCollection (model) {
	const models = [];
	// let chars = 'abcdefghijk';
	// for(let x = 1; x < 50; x++) {
	// 	let amount = Math.random() * 100 >> 0;
	// 	let id = chars[(Math.random() * (chars.length - 1) >> 0)] +
	// 	chars[(Math.random() * (chars.length - 1) >> 0)] +
	// 	chars[(Math.random() * (chars.length - 1) >> 0)] + '-' +
	// 	chars[(Math.random() * (chars.length - 1) >> 0)] +
	// 	chars[(Math.random() * (chars.length - 1) >> 0)] + '-' + x;
	// 	models.push({ id, amount });
	// }

	const col = new ProdCyclesCollection(models, { parent: model });
	col.productId = model.id;
	return col;
}

export function getCollection (model) {
	if (!model.prodCyclesCollection) {
		model.prodCyclesCollection = buildCollection(model);
	}
	return model.prodCyclesCollection;
}
