import { _ } from 'vendors';
import CollectionView from 'base/collection-view';
import { getCollection } from './prodCycles-collection';
import { getCollection as getCycleProdCollection } from './prodCycleProd-collection';
import { BlockTextHeader, BlockFooterTextAction } from 'components/ui2';
import { ProdCyclesView } from './prod-cycles';
import { ProdCycleProductsView } from './prod-cycle';
import user from 'app/user';



export const UiProdFromBlock = CollectionView.extend({
	initialize () {
		if (document.location.hash) {
			this.cycleId = document.location.hash.substring(1);
		}
	},
	className: 'double-width product-prod-cycles-block ui2-block max-whole-column',
	getCollection () {
		return getCollection(this.model);
	},
	getCycle (id) {
		const col = this.getCollection();
		const res = col.get(id);
		return res;
	},
	customViews () {
		if (this.cycleId) {
			return this.getCycleCustomViews(this.cycleId);
		} else {
			return this.getListCustomViews();
		}
	},
	getCycleCustomViews (cycleId) {
		const col = this.getCollection();
		if (!col.isFetched()) {
			this.listenToOnce(col, 'sync', this.render);
			col.fetch({ preloader: true });
			return [];
		}

		const cycle = this.getCycle(cycleId);
		const product = this.model;
		const defs = { model: cycle, product };
		return [
			new BlockTextHeader('Производственный цикл: ' + cycle.get('name')),
			new ProdCycleProductsView(defs),
			(user.checkRights({ productProduction: 'editProduction' }) && cycle.canBeEdited())
				? new BlockFooterTextAction({ tagName: 'div', template: 'добавить сырье', clickAction: 'add:cycle:prod:click', cycle, product })
				: null,
			new BlockFooterTextAction({ template: 'назад к списку', clickAction: 'show:list:click' })
		];
	},
	getListCustomViews () {
		const defs = { model: this.model, collection: this.getCollection() };
		return [
			new BlockTextHeader('Производственные циклы'),
			new ProdCyclesView(defs),
			user.checkRights({ productProduction: 'editProduction' })
				? new BlockFooterTextAction({ template: 'создать производственный цикл', clickAction: 'add:prod:cycle:click' })
				: null
		];
	},
	childViewEvents: {
		'add:cycle:prod:click' (childView) {
			const cycle = childView.getOption('cycle');
			const collection = getCycleProdCollection(cycle);
			this.model.executeAction('add:produce:cycle:product', collection, cycle, this.model);
		},
		'add:prod:cycle:click' () {
			this.model.executeAction('add:produce:cycle', this.getCollection());
		},
		'show:list:click' () {
			this.setCycleId();
		},
		'show:cycle' (cycle) {
			const id = cycle.id || _.uniqueId();
			this.setCycleId(id);
		}
	},
	setCycleId (id, { shouldRender = true, shouldChangeHash = true } = {}) {
		this.cycleId = id;
		if (shouldChangeHash) {
			document.location.hash = this.cycleId || '';
		}
		if (shouldRender) {
			this.render();
		}
	}
});

