import CollectionView from 'base/collection-view';
import Collection from 'base/collection';
import { ExtPurchaseItems } from './purchaseItems';
import CardBlock from 'components/ui-card-block/block';
import { ButtonView } from './ButtonView';
import { Purchases } from './purchaseProcs';
import busData from 'bus/data';
// import './purchase-block.less';

const tabs = [
	{ id: 'unresolved', name: 'не распределённое', active: true },
	{ id: 'purchases', name: 'процессы закупки', isMy: true }
];

const HeaderView = CollectionView.extend({
	tagName: 'header',
	className: 'purchase-request-header',
	childView: ButtonView,
	initialize () {
		this.collection = new Collection(tabs);
		const model = this.collection.find({ active: true });
		this.once('render', () => this._setActive(model));
		// this.makeActive(model);
	},
	viewFilter (v) {
		const isMy = v.model.get('isMy');
		return !isMy || (isMy && this.model.isMy()) || busData.user().isAdmin();
	},

	childViewEvents: {
		'tab:click' (view) {
			this.makeActive(view.model);
		}
	},
	makeActive (newActive) {
		if (this.currentActive === newActive) {
			return;
		}
		if (this.currentActive) {
			this.currentActive.set('active', false);
		}
		this._setActive(newActive);
	},
	_setActive (model) {
		this.currentActive = model;
		if (!model) { return; }
		model.set('active', true);
		this.trigger('active:tab', model.id);
	}
});



export const PurchaseBlock = CardBlock.extend({
	header: HeaderView,
	headerOptions () {
		return {
			model: this.model,
			foo: 'bar'
		};
	},
	cssClassModifiers: ['card lined with-border', 'purchaserequest-container', 'minus-header-and-footer', 'double-width', 'shadowless-top', 'transparent-header'],


	unresolved: ExtPurchaseItems,

	purchases: Purchases,

	childViewEvents: {
		'active:tab' (id) {
			this.showTabView(id);
		}
	},
	showTabView (id) {
		this._buildContent(id);
		const [content, options] = this._toShow[id];
		this.showChildView('content', content, options);
	},
	childContentOptions () {
		return {
			model: this.model
		};
	}
});


export function createPurchaseRequestBlockContext (model) {
	return {
		viewClass: PurchaseBlock,
		viewOptions: {
			model
		}
	};
}
