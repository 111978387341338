import proto from 'components/values/get-prototype';
import enums from 'helpers/enums';
// import bus from 'bus/models';
import { buildProductSelectFilter } from 'mod/productionProducts/filters/select-filter';

const activeArchive = {
	true: 'контрагентов в архиве',
	false: 'действующих контрагентов'
};

const filtersPrototypes = [
	{
		id: 'contragent:text',
		valueId: 'text',
		label: 'поиск по ключевому слову',
		hidden: true,
		valuePrototype: proto('text'),
		textSearch: true
	},
	buildProductSelectFilter({
		id: 'joinedProduct:id',
		valueId: 'joinedProduct',
		label: 'поставляет продукцию',
		pinned: true
	}),
	// {
	// 	id: 'joinedProduct:id',
	// 	valueId: 'joinedProduct',
	// 	label: 'поставляет продукцию',
	// 	pinned: true,
	// 	nestedModel() {
	// 		if (this._nestedModelInitialized) {
	// 			return;
	// 		}
	// 		let id = this.getValue();
	// 		if (id) {
	// 			let model = bus.request('new:products:model', { id });
	// 			//model.fetchIfNot().then(ok => console.log('fetchIfNot OK',ok), err => console.log('fetchIfNot ERR', err));
	// 			this.listenToOnce(model, 'change', () => this.trigger('change', this));
	// 			this._nestedModelInitialized = model;
	// 		}
	// 	},
	// 	valueLabel(id){
	// 		return bus.request('new:products:model', { id }).display('fullName');
	// 	},
	// 	valuePrototype: {
	// 		controlType: 'select-api',
	// 		modelType: 'single',
	// 		multiple: false,
	// 		sourceValues: () => {
	// 			let col = bus.request('new:products:search');
	// 			col.fetchIfNot();
	// 			return col;
	// 		},
	// 	}
	// },
	{
		id: 'contragent:roles',
		valueId: 'roles',
		label: 'роль',
		pinned: true,
		// values: [{value: 'client'}],
		valueLabel: (val) => enums.get('contragentRoles', val),
		valuePrototype: () => proto('enum', {
			multiple: true,
			sourceValues: () => enums.store.contragentRoles
		})
	},
	{
		id: 'contragent:archive',
		valueId: 'archive',
		label: 'показывать только',
		pinned: true,
		values: [{ value: false }],
		valueLabel: (val) => activeArchive[val],
		valuePrototype: {
			controlType: 'select',
			modelType: 'single',
			valueType: 'boolean',
			multiple: false,
			sourceValues: activeArchive
		}
	}

];

export default filtersPrototypes;
