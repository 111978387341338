import BaseLayout from '../base-layout';

import { ConcretesBlockLayout } from './block-layout';

export const ConcretesLayout = BaseLayout.extend({
	concretesContents () {
		return [
			new ConcretesBlockLayout({ model: this.model, ac: this.actionContext })
		];
	}
});
