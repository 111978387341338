module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<b class="'+
((__t=( cssClass ))==null?'':__t)+
'"><big>'+
((__t=( top ))==null?'':__t)+
'</big>';
 if(bottom) {
__p+='<small>'+
((__t=( bottom ))==null?'':__t)+
'</small>';
 } 
__p+='</b>\n';
}
return __p;
};
