import PageLayout from '../pageLayout';
import createNestedTasks from 'mod/tasks/views/entity-tasks-2';

export default PageLayout.extend({
	currentSubpage: '/tasks',
	initializeNesteds () {
		createNestedTasks(this, {
			addClass: 'double-column minus-header-and-footer',
			completedTasksEnabled: true,
			modelEvents: {
				'new:state' () {
					this.render();
				}
			}
		});
	}
});
