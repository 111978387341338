import { _ } from 'vendors';
const defCssCfg = {
	beforeRender: true,
	modelChange: true,
	refresh: true
};

const globalModifiers = [
	(m, v) => _.result(v, 'isInModal') ? 'in-modal' : ''
];

const emptyOptions = {};

const isValuable = value => !(value == null || value === '');

// const valueNormalizer = (value, options) => {
// 	if (!isValuable(value)) { return; }

// 	const type = typeof (value);

// 	if (type === 'function') {
// 		const { invokeContext, invokeArgs = [] } = options || emptyOptions;
// 		return value.apply(invokeContext, invokeArgs);
// 	} else {
// 		let isString;

// 		if (value instanceof String) {
// 			value = value.valueOf();
// 			isString = true;
// 		}

// 		if (type === 'string' || isString) {
// 			if (value.indexOf(',') > -1) {
// 				return value.split(/\s*,\s*/gmi);
// 			} else if (value.indexOf(' ') > -1) {
// 				return value.split(/\s+/gmi);
// 			}
// 		}
// 	}

// 	return value;
// };

// const valueReducer = (hash, options, classValue) => {
// 	classValue = valueNormalizer(classValue, options);

// 	if (classValue == null) { return hash; }

// 	if (Array.isArray(classValue)) {
// 		for (let x = 0; x < classValue.length; x++) {
// 			const x_val = classValue[x];
// 			if (isValuable(x_val)) { hash[x_val] = true; }
// 		}
// 	} else {
// 		hash[classValue] = true;
// 	}

// 	return hash;
// };

const arrayReducer = (hash = {}, options, arr) => {
	for (let x = 0; x < arr.length; x++) { itemReducer(hash, options, arr[x]); }
	return hash;
};

const itemReducer = (hash = {}, options, item) => {
	// let value = valueNormalizer(item, options);

	if (!isValuable(item)) { return hash; }

	const type = typeof (item);

	if (type === 'function') {
		const { invokeContext, invokeArgs = [] } = options || emptyOptions;
		item = item.apply(invokeContext, invokeArgs);
		return itemReducer(hash, options, item);
	}

	if (Array.isArray(item)) {
		return arrayReducer(hash, options, item);
	}

	const text = item.toString().trim();
	if (text === '') return hash;

	const textArray = textToArray(text);
	if (textArray) {
		for (let x = 0; x < textArray.length; x++) {
			const itemText = textArray[x].trim();
			if (itemText.length) { hash[itemText] = true; }
		}
	} else {
		hash[text] = true;
	}
	return hash;
};

const textToArray = (value) => {
	if (value.indexOf(',') > -1) {
		return value.split(/\s*,\s*/gmi);
	} else if (value.indexOf(' ') > -1) {
		return value.split(/\s+/gmi);
	}
};

function addBaseClass (baseClass, view) {
	let arr = view._baseCssClassModifiers;
	if (!arr) {
		arr = view._baseCssClassModifiers = [];
	}
	arr.push(baseClass);
}

export default (Base) => Base.extend({
	constructor () {
		// this.classNameModifiers = [];

		Base.apply(this, arguments);
		this._setupDynamicClass();
	},
	_setupDynamicClass () {
		if (this._dynamicClassInitialized) return;

		const cfg = this._getDynamicCfg();
		if (!cfg) return;

		const events = this._getDynamicClassViewEvents(cfg);

		const method = this.updateClassName
			? this.updateClassName.bind(this)
			: this.refreshCssClass.bind(this);

		_(events).each((eventName) => {
			this.on(eventName, method);
		});
		if (cfg.modelChange && this.model) {
			this.listenTo(this.model, 'change', method);
		}

		this._dynamicClassInitialized = true;
	},
	_getDynamicCfg () {
		const cfg = _.extend({}, defCssCfg, this.getOption('dynamicClassConfig'));
		if (!cfg || _.size(cfg) === 0) return;
		return cfg;
	},
	_getDynamicClassViewEvents (cfg) {
		let events = [].concat(cfg.events || []);
		if (cfg.refresh) events.push('refresh');
		if (cfg.beforeRender) events.push('before:render');
		events = _(events).uniq();
		return events;
	},

	_old_refreshCssClass () {
		const c1 = this.getOption('className');
		const c2 = this.getOption('dynamicClassName');
		const c3 = this.getOption('addClass');
		const optsModifiers = this.getOption('cssClassModifiers', { deep: false });
		const propsModifiers = this.getProperty('cssClassModifiers', { deep: false });
		const modifiers = [].concat(optsModifiers || [], propsModifiers || [], globalModifiers);
		const classes = _(modifiers).map((cls) => {
			return _.isString(cls)
				? cls
				: _.isFunction(cls)
					? cls.call(this, this.model, this)
					: null;
		});
		classes.unshift(c1, c2, c3);
		const ready = _(classes).filter((f) => f != null && f !== '');
		const className = _.uniq(ready).join(' ');

		if (className.trim() === '') {
			this.$el.removeAttr('class');
		} else {
			this.$el.attr({
				class: className
			});
		}
	},

	addBaseCssClass (arg) {
		addBaseClass(arg, this);
	},

	refreshCssClass () {
		const keys = this._buildCssClassKeys();
		this._updateCssClass(keys.join(' '));
	},


	_buildCssClassKeysHash (fromClassName) {
		const c0 = this.getOption('baseClassName');
		let c1;
		if (!fromClassName) {
			c1 = this.getOption('className');
		}
		const c2 = this.getOption('dynamicClassName');
		const c3 = this.getOption('addClass');
		const optsModifiers = this.getOption('cssClassModifiers', { deep: false });
		const propsModifiers = this.getProperty('cssClassModifiers', { deep: false });
		const classModifiers = [c0, c1, c2, c3, optsModifiers, propsModifiers, globalModifiers, this._baseCssClassModifiers];

		const options = {
			invokeContext: this,
			invokeArgs: [this.model, this]
		};

		const hash = itemReducer({}, options, classModifiers);
		return hash;
		// return Object.keys(hash);
	},
	_buildCssClassKeys () {
		const hash = this._buildCssClassKeysHash();
		return Object.keys(hash);
	},
	_updateCssClass (arg) {
		if (arg == null || arg === '') {
			this.$el.removeAttr('class');
		} else {
			this.$el.attr({ class: arg });
		}
	}
});
