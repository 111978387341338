// import BasePage from 'pages/auth';
// import Layout from './layout';
// import { DepartmentModel } from '../../models';

import { PageLayout, ProdDepPage } from '../common';
import ContentLayout from './layout';



const Layout = PageLayout.extend({
	className: 'production-department',
	contentView: ContentLayout
});

const Page = ProdDepPage.extend({
	// moduleRights: { productProduction: 'view' },
	label () {
		return 'Производство отдела ' + ((this.model && this.model.display('name')) || '') + ' ';
	},
	route: 'production',
	icon: 'product-production',
	pageId: 'production',
	Layout
    // onBeforeStart(rd){
	// 	let model = new DepartmentModel({id : rd.args.departmentId});
	// 	this.addModel(model);
	// 	this.addStartPromise(this.model.fetch());
	// },
});

export default Page;
