import { _ } from 'vendors';
import '../api-config';
import BaseModel from 'base/card-model';
import Collection from 'base/collection';
// import BbStore from 'base/bb-store';
import paths from 'helpers/paths';
import mix from 'helpers/mix';

import EditableProperties from 'mixins/editable-properties';
import PropertiesMixin from './model-properties';
import action from 'helpers/action';
import editValue from 'bus/edit';
import modalError from 'helpers/modals/error';
import confirm from 'helpers/modals/confirm';

import NestedEntitiesV2 from 'mixins/model/nested-entities-v2';


import EntityWithTasks2 from 'mod/tasks/mixins/entity-with-tasks-2';

import EntityWithProcesses from 'mod/processes/mixins/entity-with-processes';

// import EntityWithLogs from 'components/logs/entity-with-logs';
import EntityWithDetailsMixin2 from 'components/bank-details/entity-mixin-2';
// import EntityWithCommentsMixin from 'components/comments/entity-mixin';
import EntityWithContactsMixin2 from 'components/contacts/mixins/entity-with-contacts-2';
import busModels from 'bus/models';
import { BackendLogsCollection } from 'mods/logs/models/backend-logs-collection';

import { ProductContragentCollection } from 'mod/productionProducts/models/product-contragent';

import { AddJoined as AddJoinedProduct } from 'mod/productionProducts/views/joined-contragents';

const Shim = {
	// getLogs() { },
	// getContacts() {},
	// getDetails() {},
	// getTasks() {},
};

const Base = mix(BaseModel).with(
	PropertiesMixin,
	EditableProperties
	, NestedEntitiesV2

	, EntityWithDetailsMixin2
	, EntityWithTasks2
	, EntityWithContactsMixin2
	, EntityWithProcesses
	//,
	// NestedCollections,
	// EntityWithContactsMixin,
	// EntityWithTasks,
	// EntityWithDetailsMixin,
	// EntityWithLogs
	//, EntityWithCommentsMixin
	, Shim
);

const Model = Base.extend({
	cardUrlRoot: paths.url('contragents'),
	urlRoot: paths.api('contragents'),
	taskEntity: 'contragent',
	socketEntity: 'contragent',
	completedTasksEnabled: true,
	nestedEntities: {
		logs: BackendLogsCollection,
		joinedProducts: ProductContragentCollection.Product
	},
	getJoinedProducts () {
		return this.entity('joinedProducts');
	},
	// nestedCollections:{
	// 	logs:() => ({
	// 		Collection: busModels.getModel('logs'),
	// 	})
	// },

	// isCommentable: () => true,

	getCompletedTasksDefinition (Collection) {
		const model = this;
		return Collection.extend({
			fetchUrl () {
				const url = _.result(model, 'url');
				if (url == null) return;
				// _.isFunction(model.url) && (url = model.url());
				return url + '/completedTasks';
			}
		});
	},

	mainInfoCanBeChanged () {
		return true;
	},

	isArchived () {
		return this.get('isArchived');
	},

	actions: [
		action('upload:files', 'прикрепить файлы', null, {
			order: -100,
			places: 'page'
			// rule: (m) => m.isMy()
		}),
		action('add:person', 'добавить контактное лицо'),
		action('create', 'создать контрагента', null, { hidden: true }),
		action('move:to:archive', 'отправить в архив', null, { order: 1000, rule: (m) => !m.isArchived() }),
		action('move:to:active', 'вернуть из архива', null, { order: 1000, rule: (m) => m.isArchived() }),
		action('add:joined:product', 'прикрепить продукцию', { resourcesProduction: 'productsEdit' })

	],

	actionUploadFiles () {
		this.trigger('open:selectFilesToUpload');
	},
	actionAddPerson () {
		editValue.do({
			header: 'Добавление контактного лица',
			controlType: 'entityPerson',
			applyLabel: 'добавить',
			cancelLabel: 'закрыть без добавления',
			resetButton: false,
			controlOptions: {
				parent: this
			}
		}).then(
			(data) => {
				const persons = this.getContacts();
				persons.create(data).then(
					() => {
						this.set('contacts', persons.toJSON(), { silent: true });
					},
					(xhr) => modalError('Контактное лицо не добавлено, произошла ошибка', xhr)
				);
			},
			() => {}
		);
	},

	actionCreate (action, opts = {}) {
		confirm('Вы собираетесь добавить нового контрагента').then(
			() => {
				this.save(null, { wait: true }).then(
					() => {
						if (_.isFunction(opts.callback)) { opts.callback(this); }
					},
					(xhr) => modalError('Не удалось добавтиь контрагента', xhr)
				);
			},
			() => {}
		);
	},

	actionMoveToArchive () {
		this.send({
			url: 'archive',
			method: 'PUT',
			preloader: true
		}).then(
			() => this.set('isArchived', true),
			(xhr) => modalError('неудалось отпарвить контрагента в архив', xhr)
		);
	},
	actionMoveToActive () {
		this.send({
			url: 'archive',
			method: 'DELETE',
			preloader: true
		}).then(
			() => this.set('isArchived', false),
			(xhr) => modalError('неудалось достать контрагента из архива', xhr)
		);
	},
	actionAddJoinedProduct () {
		const collection = this.getJoinedProducts();
		editValue.do({
			header: 'Прикрепление продукции',
			controlView: AddJoinedProduct,
			controlViewOptions: {
				type: 'product',
				parentModel: this,
				parentModelType: 'contragent'
			},
			applyLabel: 'добавить',
			resetButton: false,
			cancelLabel: 'отставить'
		}).then(
			(data) => {
				collection.create(data, { preloader: true, wait: true }).then(
					() => {},
					(xhr) => modalError('неудалось прикрепить продукцию', xhr)
				);
			},
			() => {}
		);
	}

});

busModels.reply('Contragent', () => Model);
busModels.reply('new:contragents:model', (data) => {
	const store = busModels.request('contragents:store');
	const exist = store.get(data);
	// console.log('cnt lookup', data, exist, store);
	if (exist) return exist;

	const model = new Model(data);
	if (data && _.size(data) === 1 && data.id) {
		model.fetchIfNot();
		store.add(model);
	}
	return model;
});


busModels.reply('contragent:contactNames', cId => {
	const url = paths.api('contragents') + '/' + cId + '/contactsNames';
	const col = new Collection();
	col.url = url;
	return col.fetch().then(data => {
		return Promise.resolve(data);
	}).catch(() => Promise.resolve([]));
});

busModels.reply('contragent:addresses', cId => {
	const url = paths.api('contragents') + '/' + cId + '/addresses';
	const col = new Collection();
	col.url = url;
	return col.fetch().then(data => {
		const parsed = data.map(item => {
			if (item.value && item.value.rawCoords) {
				try {
					item.value.coords = JSON.parse(item.value.rawCoords);
					delete item.value.rawCoords;
				} catch (e) { /** */ }
			}
			return item;
		});
		// console.log('PARSED', parsed);
		return Promise.resolve(parsed);
	}).catch(() => Promise.resolve([]));
});



export default Model;
/*
const StoreModel = BbStore(Model);

busModels.reply('contragent',() =>  StoreModel);
busModels.reply('new:contragents:model', (data) => {
	return new StoreModel(data);
});
export default StoreModel;
*/
