// import 'mod/productionTypes';
import { productTypesCollection } from 'mod/productionTypes/singleton';
import BasePage from 'pages/auth';
import Layout from './layout';
import busData from 'bus/data';
import TypePage from './type';

const Page = BasePage.extend({

	moduleRights: { resources: 'view', resourcesProduction: 'view, typesView' },
	label: 'Типы продукции',
	route: 'types',
	children: [TypePage],
	icon: 'production-types',
	onBeforeStart () {
		if (!this.collection) {
			// const productTypes = busData.productTypes();
			// this.addCollection(productTypes);
			this.addCollection(productTypesCollection);
			
		}
	},
	Layout
});

export default Page;
