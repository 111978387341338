import { propertySchemaApi } from './propertySchemaApi';

export const viewSchemaMixin = {
	getModelSchema () {
		const schema = this.getOption('modelSchema', true);
		if (schema) { return schema; }
		if (this.model && this.model.getSchema) {
			return this.model.getSchema();
		}
	},
	display (key) {
		const schema = this.getModelSchema();
		const value = propertySchemaApi.displayValue(key, this.model, schema);
		return value;
	},
	label (key) {
		const schema = this.getModelSchema();
		const value = propertySchemaApi.getLabel(key, this.model, schema);
		return value;

	}
};
