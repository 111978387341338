import { _ } from 'vendors';
import Bb from 'backbone';
import modals from 'helpers/modals';
import byPath from 'helpers/by-path';
import paths from 'helpers/paths';

const bbSync = Bb.sync;
// const backboneUrl = Bb.Model.prototype.url;

const syncPostProcess = function (context, type, method, callback, args = []) {
	context.triggerMethod(`api:${type}`, ...args);
	context.triggerMethod(`api:${type}:${method}`, ...args);
	context.triggerMethod('api:end', type, ...args);
	_.isFunction(callback) && callback.apply(context, args);
};

export default (Base) => Base.extend({

	constructor () {
		Base.apply(this, arguments);
	},
	triggerMethod (event, ...args) {
		const method = _.camelCase('on:' + event);
		let result;
		if (_.isFunction(this[method])) { result = this[method](...args); }
		this.trigger(event, ...args);
		return result;
	},
	sync (method, model, options = {}) {
		if (options.preloader) {
			this._createSyncPreloader(options.preloader, options);
		}


		const _success = options.success;
		options.success = (...args) => syncPostProcess(this, 'success', options.methodName || method, _success, args);
		const _error = options.error;
		options.error = (...args) => syncPostProcess(this, 'error', options.methodName || method, _error, args);


		try {
			const result = bbSync.apply(this, arguments);
			return result;
		} catch (err) {
			syncPostProcess(this, 'error', options.methodName || method, _error, [err]);
			return Promise.reject(err);
		}
	},
	_createSyncPreloader (preloader) {
		if (!preloader) return;
		else if (_.isFunction(preloader)) { return this._createSyncPreloader(preloader()); }

		let preloaderOptions;

		if (_.isView(preloader)) {
			preloaderOptions = {
				overlay: preloader
			};
		} else if (preloader.jquery) {
			preloaderOptions = {
				overlay: preloader
			};
		} else if (_.isObject(preloader)) {
			preloaderOptions = _.extend({}, preloader);
		}

		const instance = modals.preloader(preloaderOptions);


		!!instance && this.once('api:end', () => instance.destroy({ force: true }));
	},
	buildUrl (key, { ownProperties } = {}) {
		const src = _.result(this, key);
		if (!src) return;

		const url = src.replace(/:([a-z0-9_.]+)/gmi, (found, field) => {
			if (ownProperties) {
				return this[field];
			} else {
				const result = byPath.get(this, field);
				if (result !== undefined) {
					return result;
				}
				return this[field];
			}

			// if (byPath.has(this, field)) {
			// 	let result = byPath.get(this, field);
			// 	return result;
			// }
		});

		return url;
	},
	_normalizeUrl (url) {
		if (!url) {
			return url;
		}
		if (url.startsWith('http') || url.startsWith('//')) {
			return url;
		}
		return paths.urls.api(url);
	},
	url (opts) {
		const url = this.buildUrl('urlPattern', opts);
		if (url) { return this._normalizeUrl(url); }

		if (this instanceof Bb.Collection) { return; }

		let baseUrl = this.collection && _.result(this.collection, 'url');
		!baseUrl && (baseUrl = _.result(this, 'urlRoot'));
		!baseUrl && (baseUrl = this.buildUrl('urlRootPattern', opts));

		if (baseUrl == null) { return; }



		return this._normalizeUrl(this.isNew() ? baseUrl : baseUrl + '/' + this.id);


		/*
		if(this.collection && _.isFunction(this.collection.url)){
			let collectionUrl = this.collection.url();
			if(collectionUrl)
				return this.id ? collectionUrl + '/' + this.id : collectionUrl;
		}



		return backboneUrl.call(this, arguments);
		*/
	}
});
