import { moment } from 'vendors';
import { propertySchemaApi } from '../../../../apis/schema';
import { displayUnit } from '../../../../apis/units';
import { addPostfix, displayParty, postfixFunc } from '../../../../utils';
import { openCardButton } from '../../../../utils/openCardButton';
import { employeeName } from '../../../staff/emps';

export const buildComponentSchema = {
	name: {
		label: 'название',
		displayValue (v, m, s) {
			const product = propertySchemaApi.displayValue('componentName', m, s);
			const batch = propertySchemaApi.getValue('componentBatchIdString', m, s);
			return [batch, product].join(' - ');
		}
	},
	amount: {
		label: 'израсходовано',
		valueType: 'number'
	},
	amountPostfix: {
		label: 'израсходовано',
		displayValue (v, m, s) {
			v = propertySchemaApi.displayValue('amount', m, s);
			const unit = propertySchemaApi.getValue('baseUnitMeasureId', m, s);
			const dunit = displayUnit(unit);
			return addPostfix(v, postfixFunc(dunit));
		}
	},
	componentName: {
		label: 'название компонента',
		displayValue(v,m,s) {
			const id = propertySchemaApi.getValue('componentId', m, s);
			return openCardButton({ id, type: 'product' }, v);
		}
	},
	componentBatchIdString: {
		label: 'номер партии',
		displayValue (v, m, s) {
			const id = propertySchemaApi.getValue('componentBatchId', m, s);
			return openCardButton({ id, type: 'batch' }, displayParty(v, ''));
		}
	},
	createdFromNow: {
		label: 'дата производства',
		displayValue (v, m, s) {
			v = propertySchemaApi.getValue('created', m, s);
			return moment(v).fromNow();
		}
	},
	created: {
		label: 'дата производства',
		valueType: 'datetime'
	},
	createdShort: {
		label: 'дата производства',
		valueType: 'datetime',
		displayValue (v, m, s) {
			const created = propertySchemaApi.getValue('created', m, s);
			return moment(created).format('DD.MM.YY');
		}
	},
	actorId: {
		label: 'сотрудник',
		displayValue (v, m, s) {
			return employeeName(v);
		}
	},
	productName: {
		label: 'название продукции',
		displayValue(v,m,s) {
			const id = propertySchemaApi.getValue('productId', m, s);
			return openCardButton({ id, type: 'product' }, v);
		}
	}
};
