import { _ } from 'vendors';
export default (Base) => Base.extend({
	constructor () {
		Base.apply(this, arguments);
		if (this.getOption('readyOnRender')) {
			this.once('render', () => this.trigger('content:ready'));
		}
		this.on('content:ready', () => this.validateOnRender());
	},
	validateOnRender (opts = {}) {
		const value = this.getValue();
		const valid = this.isValueValid(value, opts);
		if (!valid) { this._triggerEvent('validate:error', value, { ...opts, validateAtRenderTime: true }); }
	},
	isValueValid (value, opts = {}) {
		if (opts.skipValidate) return true;

		const hasValidator = _.isFunction(this.getOption('validate', { force: false }));
		if (hasValidator) {
			const valid = !hasValidator || (hasValidator && this.getOption('validate', { args: [value, opts] }));
			return valid;
		}
		return true;
	},
	_triggerEvent (eventName, value, options, ...args) {
		this.triggerMethod(`control:${eventName}`, value, options, ...args);
		const controlName = this.getOption('controlName');
		if (controlName && this.getOption('triggerNamedEvent')) { this.triggerMethod(`control:${controlName}:${eventName}`, value, options, ...args); }
		this.proxyUp(eventName, value, options, ...args);
	},
	triggerChange (value, opts = {}) {
		if (value == null) value = this.getValue();

		if (!this.isValueValid(value, opts)) { this._triggerEvent('validate:error', value, opts); } else { this._triggerEvent('change', value, opts); }
	},
	triggerDone (value, opts = {}) {
		if (value == null) value = this.getValue();
		if (!this.isValueValid(value, opts)) { this._triggerEvent('validate:error', value, opts); } else { this._triggerEvent('done', value, opts); }
	},
	proxyUp (type, ...args) {
		const proxyTo = this.getOption('proxyTo');
		const controlsEvents = proxyTo && proxyTo.getOption('controlsEvents');
		const name = this.getOption('controlName') || 'control';
		const handler = `${name}:${type}`;

		const parentMethod = controlsEvents && controlsEvents[handler];
		if (_.isFunction(parentMethod)) {
			parentMethod.apply(proxyTo, args);
		}
	},
	transformValueIn (value) {
		if (value == null) return value;
		if (this.getOption('valueType') === 'enum') {
			if (this.getOption('multiple')) { return value.split(/\s*,\s*/gmi); } else { return value; }
		}
		return value;
	},
	transformValueOut (value) {
		if (this.getOption('valueType') === 'enum') {
			if (_.isArray(value)) {
				return value.join(', ');
			} else { return value; }
		}
		return value;
	},
	prepareInitialValues () {
		let initValue = this.transformValueIn(this.getOption('value', { deep: false }));
		if (_.isModel(initValue)) { initValue = initValue.toJSON(); } else if (_.isObject(initValue) && !_.isArray(initValue)) { initValue = _.extend({}, initValue); }
		this.initialValue = initValue;

		let resetValue = this.transformValueIn(this.getOption('resetValue', { deep: false }));
		if (_.isModel(resetValue)) { resetValue = resetValue.toJSON(); } else if (_.isObject(resetValue)) { resetValue = _.extend({}, resetValue); }
		this.resetValue = resetValue;
	},
	getValue () {
		const ignoreModel = this.getOption('ignoreModel');
		const modelValue = !ignoreModel && this.model;
		const context = (!ignoreModel && this.model) || this.control;
		let contextValue;
		if (context && context.getValue) {
			contextValue = context.getValue();
		}
		const thisValue = this.getProperty('value', { deep: false });
		const thisInitValue = this.getProperty('initialValue', { deep: false });
		const value = contextValue !== undefined
			? contextValue
			: modelValue || (thisValue != null
				? thisValue
				: thisInitValue);

		// let lbl = contextValue != null
		// 	? 'contextValue'
		// 	: modelValue ? 'modelValue'
		// 		: thisValue != null
		// 			? 'thisValue'
		// 			: 'thisInitValue';

		// console.log('controlmixin.getValue', value, lbl);
		// console.log(context);

		return value;
	},
	isMultiple () {
		return !!this.getOption('multiple');
	},
	isNotMultiple () {
		return !this.isMultiple();
	},
	triggerContentReady () {
		this.trigger('content:ready');
	}
});
