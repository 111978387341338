import { _ } from 'vendors';
export default (Base) => Base.extend({
	fineId: undefined,

	getFineId () {
		if (!this.fineId) return this.id;
		const fine = this.get(this.fineId);
		return fine || this.id;
	},

	fetch (options = {}) {
		if (this.fineId) { options.url = this.fineUrl(); }
		return Base.prototype.fetch.call(this, options);
	},

	baseUrl () {
		const base =
        _.result(this, 'urlRoot') ||
		_.result(this.collection, 'url');
		return base;
	},

	fineUrl () {
		const id = this.getFineId();
		const base = this.baseUrl();
		return base + '/' + id;
	}
});
