import { InlineTabsTriggers } from 'components/ui2/nav-tabs';
import { HamburgerView } from 'base/view-hamburger';

export const SelectorView = HamburgerView.extend({
	thisClassName: 'ui2-list-item-margined',
	customViews () {
		const views = [
			{
				class: InlineTabsTriggers,
				buttons: this.getOption('tabsButtons'),
				onTabClick: model => this.triggerMethod('tab:click', model)
			}
		];
		return views;
	}
});
