import { productFilters } from '../common';
import { periodFilter } from '../common/filters';
import enums from 'helpers/enums';


const excludeFilters = {
	bycontragent: 1,
	byproduct: 1
};

const datasetEnum = {
	// none: 'все',
	awaiting: 'ожидается',
	accepted: 'получено'
};

const prodfltrs = productFilters.map(filter => ({ ...filter })).filter(f => !(f.id in excludeFilters));



export const filters = [
	...prodfltrs,
	{
		id: 'dataset',
		valueId: 'dataset',
		label: 'что показать',
		pinned: true,
		valuePrototype: () => ({
			multiple: true,
			sourceValues: datasetEnum,
			modelType: 'single',
			valueType: 'enum'
		}),
		valueLabel: (val) => enums.get(datasetEnum, val)
	},
	{
		...periodFilter,
		values: [{ from: getMondayWeekAgo(), to: new Date() }]
	}


];


function getMondayWeekAgo() {
	const now = new Date();
	now.setDate(now.getDate() - ((now.getDay() || 7) - 1) - 7);
	return now;
}
