import { _ } from 'vendors';
import childView from './day';
import CollectionView from 'base/collection-view';
import mix from 'helpers/mix';
import ControlsMixin from 'components/controls/mixin';
import Single from 'components/values/single';
import comparator from 'helpers/comparator';

export default mix(CollectionView).with(ControlsMixin).extend({
	className: 'c-dt-month-days',
	childView,
	isSingle () {
		return this.model.valueModel instanceof Single;
	},
	childViewEvents: {
		'click' (view) {
			const date = view.model.getValue();
			const value = new Date(date.valueOf());
			if (this.isSingle()) {
				if (Date.sameDay(value)) {
					const time = new Date();
					value.setHours(time.getHours(), time.getMinutes() + 10);
				} else {
					let timeArray = (this.model.get('defaultTime') || '11:00').split(':');
					timeArray = _(timeArray).map((t) => parseInt(t, 10));
					value.setHours(...timeArray);
				}
			}


			this.triggerChange(value);
		}
	},
	validate (v) {
		return !this.model.get('startTime') || v > this.model.get('startTime');
	},
	viewComparator (v1, v2) {
		return comparator(v1, v2, m => m.get('value').valueOf());
	}
	// childViewTriggers:{
	// 	'click':'click'
	// },
});
