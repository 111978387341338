import Collection from 'base/collection';
import model from './model';
export default Collection.extend({
	model,
	initialize (data, opts = {}) {
		this._initializeParent(opts.parent);
	},
	_initializeParent (parent) {
		this.parent = parent;
		if (this.parent == null) {
			throw Error('Initialize EntityContacts collection error, parent not provided');
		}
		this._onParentIdChange();
		this.listenTo(this.parent, 'change:id', this._onParentIdChange);
	},
	_onParentIdChange () {
		this.parentId = this.parent.id;
		this.url = this.parent.url() + '/details';
	}
});
