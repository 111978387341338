import { $ } from 'vendors';
import 'mods/medias/api-config';
import paths from 'helpers/paths';
import Model from 'base/model';
export function registerAuthHref () {
	$('body').on('click', '[auth-href]', (event) => {
		const $a = $(event.target).closest('[auth-href]');
		const forceDownload = $a.data('download');
		const href = $a.attr('auth-href');
		const openType = forceDownload || event.ctrlKey ? '/download' : '/open';
		const filename = $a.attr('filename');
		const model = new Model();
		model.url = href + openType;
		model.fetch().then(data => {
			const url = paths.api('medias', 'byhash', data);
			if (openType === '/download') {
				const $a = $(`<a href="${url}" target="_blank" download="${filename}" style="display:none"></a>`);
				$a.appendTo($('body'));
				$a.get(0).click();
				$a.remove();
			} else if (openType === '/open') {
				window.open(url, '_blank');
			}
		});
	});
}
