import BaseView from 'pages/view';
import template from './layout.html';
import UiBlock from 'components/ui-card-block/block';

import AdminsCollection from 'mod/users/models/collection-admins';
import UsersCollection from 'mod/users/models/collection';

import AdminsList from './admins-list';
import UsersList from './users-list';


export default BaseView.extend({
	template,
	regions: {
		admins: { el: '.admins', replaceElement: true },
		users: { el: '.users', replaceElement: true }
	},
	initialize () {
		const admins = new AdminsCollection();


		const users = new UsersCollection();
		users.fetch();


		this.createNested('admins', {
			viewClass: UiBlock.extend({
				header: 'администраторы',
				headerOptions: {
					className: 'bg-main-dark'
				},
				content: AdminsList,
				contentOptions: {
					collection: admins
				}
			})
		});
		admins.fetch().then(() => {
			this.createNested('users', {
				viewClass: UiBlock.extend({
					header: 'пользователи',
					headerOptions: {
						className: 'bg-main-dark'
					},
					content: UsersList,
					contentOptions: {
						className: 'plain',
						collection: users,
						admins
					}
				}),
				viewOptions: {
					cssClassModifiers: ['h100p']
				}
			});
			this.triggerMethod('ready');
		});
	},
	onReady () {
		this.showAdmins();
		this.showUsers();
	}
});
