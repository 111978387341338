module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="card">\n	<header>укажите название группы</header>\n	<div class="margin-b w100p" data-placeholder="название"><input '+
((__t=( _cidname('name')))==null?'':__t)+
' value="'+
((__t=( _m.value))==null?'':_.escape(__t))+
'" class="w100p" /></div>\n	<button class="apply">добавить</button>\n</div>\n';
}
return __p;
};
