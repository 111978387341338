import bus from 'bus/models';
const busModelKey = 'new:contragents:model';
const busSearchKey = 'new:contragents:search';
const defaultFilter = {
	nestedModel () {
		if (this._nestedModelInitialized) {
			return;
		}
		const id = this.getValue();
		if (id) {
			const model = bus.request(busModelKey, { id });
            // let propsLength = _.size(model.attributes);
            // if (propsLength === 1 && model.url) {
            //     model.fetchIfNot().then(ok => {
            //         console.log('== fetchIfNot OK ==')
            //         this.trigger('change', this);
            //     }, err => console.log('fetchIfNot ERR', err));
            // }
            // console.log('CONSOLE NESTED: ', _.size(model.attributes), model.collection, model.url());
			this.listenToOnce(model, 'change', () => {
				console.log('** ONCE CHANGED! **');
				this.trigger('change', this);
			});
			this._nestedModelInitialized = model;
		}
	},
	valueLabel (id) {
		const model = bus.request(busModelKey, { id });
		console.log('-- SHOWING LABLE -- ', model.attributes);
		return model.display('name');
	}
};
const defaultValuePrototype = {
	controlType: 'select-api',
	modelType: 'single',
	multiple: false,
	sourceValues: () => {
		const col = bus.request(busSearchKey);
		col.fetchIfNot();
		return col;
	}
};
export function buildContragentSelectFilter (userData = {}) {
	const userValuePrototype = userData.valuePrototype;
	const filter = {
		...defaultFilter,
		...userData,
		valuePrototype: {
			...defaultValuePrototype,
			...userValuePrototype
		}
	};
	return filter;
}
