import { _ } from 'vendors';
import comparator from 'helpers/comparator';

const ifNull = function (arg, val) {
	return arg == null ? val : arg;
};
/*
const defaultCustomComparator = function(v1,v2){
	return comparator(
		[v1,v2, (m,v) => (v.getOption('collectionPlace') || 'bottom') === 'bottom' ? 1 : -1 ],
		[v1,v2, (m,v) => ifNull(v.getOption('customOrder'),-1) ]
		//[v1,v2, (m,v) => v.cid ]
	);
};
*/

function getCustomResult (v1, v2, context) {
	const nc1 = context.isCustomView(v1);
	const nc2 = context.isCustomView(v2);
	if (!nc1 && !nc2) { return true; } else if (nc1 && nc2) { return false; } else {
		return nc1 ? -1 : 1;
	}
}

function getViewPlace (v, place) {
	const res = (v.getOption('collectionPlace') || place) === 'bottom' ? 1 : -1;
	return res;
}

/*
function redefinedComparator(v1,v2) {

	let place = this.getOption('customPlace') || 'bottom';
	let result = getCustomResult(v1, v2, this);
	let userComparator = _.bind(this._passedComparator || this._viewComparator, this);

	if (result === true) {

		if(userComparator.length === 1){
			let r1 = userComparator(v1);
			let r2 = userComparator(v2);
			let compareResult = r1 === r2 ? 0
				: r1 < r2 ? -1
					: 1;
			return compareResult;

		} else {

			return userComparator(v1,v2);

		}

	} else if (!result) {
		return comparator(
			[v1,v2, (m, v) => getViewPlace(v, place) ],
			[v1,v2, (m, v) => ifNull(v.getOption('customOrder'),-1) ]
		);
	} else {
		let v = result === -1 ? v1 : v2;
		let coef = result * -1;
		return getViewPlace(v, place) * coef;
	}
}

function redefineComparator(opts = {}){
	let custom = opts.viewComparator || this.viewComparator;
	if(_.isString(custom)){
		let key = custom;
		custom = (v) => v.model && v.model.get(key);
	}
	_.isFunction(custom) && (this._passedComparator = custom);
	opts.viewComparator = redefinedComparator;
}
*/


function redefineComparatorNew (opts = {}) {
	let supplied = opts.viewComparator || this.viewComparator;
	if (supplied == null) supplied = this._viewComparator;

	this._suppliedComparator = supplied;

	const len = _.isFunction(supplied)
		? supplied.length
		: supplied === false
			? 0
			: 1;

	const _this = this;

	if (len === 1) {
		opts.viewComparator = function (v) {
			return newComparator.call(_this, v);
		};
	} else {
		opts.viewComparator = function (v1, v2) {
			return newComparator.call(_this, v1, v2);
		};
	}
}

function newComparator () {
	const len = arguments.length;

	const place = this.getOption('customPlace') || 'bottom';

	if (len === 0) {
		return 0;
	} else if (len === 1) {
		const v = arguments[0];
		if (this.isCustomView(v)) {
			const viewPlace = getViewPlace(v, place);
			if (viewPlace === 1) {
				return (this.collection && this.collection.length) || this.children._views.indexOf(v);
			} else {
				return -1;
			}
		} else {
			return this._suppliedComparator.apply(this, arguments);
		}
	} else {
		const v1 = arguments[0];
		const v2 = arguments[1];
		const result = getCustomResult(v1, v2, this);

		if (result === true) {
			return this._suppliedComparator.apply(this, arguments);
		} else if (!result) {
			return comparator(
				[v1, v2, (m, v) => getViewPlace(v, place)],
				[v1, v2, (m, v) => ifNull(v.getOption('customOrder'), -1)]
			);
		} else {
			const v = result === -1 ? v1 : v2;
			const coef = result * -1;
			return getViewPlace(v, place) * coef;
		}
	}
}

const FixedComparatorMixin = (Base) => {
	return Base.extend({
		constructor (opts) {
			redefineComparatorNew.call(this, opts);
			Base.apply(this, arguments);
		}
	});
};

export default FixedComparatorMixin;
