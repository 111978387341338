export const modelSchemaApi = {
	getPropertySchema (key, modelSchema) {
		if (!modelSchema) { return; }
		const property = modelSchema[key];
		if (property && !property.property) {
			property.property = key;
		}
		return property;
	}
};
