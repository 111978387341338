import ModelProperties from 'components/ui-model-card/properties';
import h from 'components/ui-model-card/helpers';

export function secondInfoBlock (view) {
	const m = view.model;
	view.createNested('main2', {
		viewClass: ModelProperties.extend({ className: 'card lined with-border' }),
		viewOptions: {
			flatPatch: true,
			properties: [
				h.ui('ownerId', { editButton: m.isCanBeChanged('ownerId'), half: true }),
				h.ui('creatorId', { editButton: false, half: true }),

				h.ui('modified', { editButton: false, half: true }),
				h.ui('created', { editButton: false, half: true })
			]
		},
		regionTemplate: { replaceElement: true }
	});
}
