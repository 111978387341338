import { _ } from 'vendors';
import SingleCollection from '../single-collection';
import RangeCollection from '../range-collection';
import ValueError from './value-error';
import checkData from './check-data';
import objectToArray from './object-to-array';


const createCollection = function (raw, opts = {}) {
	const options = _.extend({}, opts);
	options.type || (options.type = options.modelType);
	if (_.isCollection(raw)) return raw;

	if (options.checkData == null) { options.checkData = true; }

	let data;
	if (_.isArray(raw)) { data = raw; } else if (_.isObject(raw)) { data = objectToArray(raw, options); }


	const checkError = checkData(data, options);
	if (checkError) {
		throw new ValueError('CreateValuesCollection: checkData error.', checkError, data);
	}

	if (!options.type) { throw new ValueError('CreateValuesCollection: create error.', '`modelType` is not provided and can not be detected', data); }

	if (options.type === 'single') { return new SingleCollection(data, options); } else if (options.type === 'range') { return new RangeCollection(data, options); }
};

createCollection.single = function (data, _opts) {
	const opts = _.extend({ checkData: false }, _opts, { type: 'single' });
	return createCollection(data, opts);
};
createCollection.range = function (data, _opts) {
	const opts = _.extend({ checkData: false }, _opts, { type: 'range' });
	return createCollection(data, opts);
};

export default createCollection;
