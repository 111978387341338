import View from 'base/view';
import mix from 'helpers/mix';
import ControlsMixin from 'components/controls/mixin';
import template from './template.html';
export default mix(View).with(ControlsMixin).extend({
	className: 'boolean-select',
	template,
	initialize (opts) {
		this.mergeOptions(opts, ['sourceValues', 'value']);
		this.initialValue = this.value;
	},
	templateContext () {
		return {
			name: this.cid,
			leftValue: true,
			leftLabel: this.sourceValues.true,
			rightValue: false,
			rightLabel: this.sourceValues.false,
			leftChecked: this.value ? ' checked="checked"' : '',
			rightChecked: !this.value ? ' checked="checked"' : ''
		};
	},
	events: {
		'click' (e) {
			e.stopPropagation();
		},
		'click input' (e) {
			this.value = e.target.value === 'true';

			this.triggerChange(this.value);
		}
	}
});
