import InfoView from './info';
import FiltersView from './filters';
import ActionsLayout from 'components/actions/list';
import { HamburgerView } from '../../base/view-hamburger';
// import { _ } from 'vendors';

export const EntriesManagerView = HamburgerView.extend({

	baseClassName: 'entries-manager',

	getCollection () {
		return this.collection;
	},

	getCustomViews () {
		const info = this.getInfoView();
		const filters = this.getFiltersView();
		const actions = this.getActionsView();
		return [info, filters, actions];
	},

	getInfoView () {
		let options = this.getOption('infoViewOptions', true);
		if (options === true) {
			options = {};
		}
		if (!options) return;
		return {
			class: InfoView,
			...options,
			model: this.getCollection().result
		};
	},

	getFiltersView () {
		const filtersInstance = this.getOption('filtersInstance', true);
		const options = this.getOption('filtersViewOptions', true) || {};
		const instance = filtersInstance || (options && options.instance);
		if (!instance) return;
		return {
			class: FiltersView,
			...options,
			instance,
			collection: instance.getCollection(),
			textShouldNotApply: true
		};

	},

	getActionsView () {
		const actionsEnabled = this.getOption('actionsEnabled', true);
		const actionsContext = this.getOption('actionsContext', true);

		const hasActions = !!actionsEnabled || !!actionsContext;

		if (!hasActions) return;

		return {
			class: ActionsLayout,
			addClass: 'card lined',
			context: actionsContext
		};

	}



});
