import Bb from 'backbone';
import Mn from 'backbone.marionette';
import _ from 'underscore';

let getCompareABModel = (arg) => {
	if (arg instanceof Bb.Model)
		return arg;
	else if (arg instanceof Mn.View)
		return arg.model;
	else
		return;
}
let getCompareABView = (arg) => {
	if (arg instanceof Bb.View)
		return arg;
	else
		return;
}

let compareAB = (a, b, func) => {
	if (_.isFunction(func)) {
		a = func.call(a, getCompareABModel(a), getCompareABView(a));
		b = func.call(b, getCompareABModel(b), getCompareABView(b));
		return a < b ? -1
			: a > b ? 1
				: 0;
	}else if(_.isArray(func)){
		let result = 0;
		_(func).every((f) => {
			if(!_.isFunction(f)) return true;
			result = compareAB(a,b,f);
			return result === 0;
		});
		return result;
	}
};

export default compareAB;
