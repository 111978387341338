import { _ } from 'vendors';

const defaultSchema = {
	addKeyToClass: true,
	addValueToClass: false,
	tagName: 'div'
};

function addstring (val, text, separator) {
	if (!val || val === '') return text;
	text = text || '';
	if (text.length) {
		text += separator;
	}
	text += val;
	return text;
}

function getAttrs (opts) {
	const attrs = _.extend({}, opts.attrs);

	const contextKey = opts.contextKey || opts.key;

	attrs.class = addstring(opts.cssClass, attrs.class, ' ');

	if (opts.addKeyToClass && opts.key) {
		const csskey = opts.key.replace(/\./gm, '-');
		attrs.class = addstring(csskey, attrs.class, ' ');
	}

	if (opts.addValueToClass) {
		attrs.class = addstring('<%=' + contextKey + '%>', attrs.class, ' ');
	}

	if (opts.addValueToTitle) {
		attrs.title = addstring('<%=' + contextKey + '%>', attrs.title, ' ');
	}

	if (opts.isHeaderModel && opts.headerShort) {
		attrs.title = addstring(opts.header, attrs.title, ' ');
	}

	if (!opts.isHeaderModel && opts.title) {
		attrs.title = addstring(opts.title, attrs.title, ' ');
	}

	if (!opts.isHeaderModel && opts.addValueCssClass) {
		attrs.class = addstring(`<%= css["${opts.key}"] %>`, attrs.class, ' ');
	}
    // console.log('-attr-', attrs, opts);
	return attrs;
}

function getAttrsText (hash) {
	let text = _.map(hash, (value, key) => {
		if (value == null) return;

		if (key === 'class' && value) {
            // console.log('-before-', value);
            // value = value.split(/\s/gm).map(item => {
            //     if (item.indexOf('<%') >= 0)
            //         return item;
            //     else if (item.indexOf('.') >= 0) {
            //         item = item.replace(/\./gmi,'-');
            //         return item;
            //     }
            //     return item;
            // }).join(' ');
            // console.log('-after-', value);
		}

		return `${key.toLowerCase()}="${value}"`;
	}).filter(f => !!f).join(' ');

	if (text.length) { text = ' ' + text; }

	return text;
}

function getCellContent (opts) {
	if (opts.isHeaderModel) {
		return opts.headerShort || opts.header;
	}

	if ('content' in opts) { return opts.content; } else {
		if (opts.contextKey) {
			return `<%= ${opts.contextKey} %>`;
		} else {
			return `<%= ${opts.key} %>`;
		}
	}
}


export function cell (options, isHeaderModel) {
	options = _.extend({ isHeaderModel }, defaultSchema, options);
	const attrsObj = getAttrs(options);
	const attrs = getAttrsText(attrsObj);
	const content = getCellContent(options);
	return `<${options.tagName}${attrs}>${content}</${options.tagName}>`;
}

export function big (content, options) {
	return cell(_.extend({ content, tagName: 'big' }, options));
}

export function small (content, options) {
	return cell(_.extend({ content, tagName: 'small' }, options));
}
