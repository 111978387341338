import DepartmentListItem from 'mod/departments/views/list-item';
import CollectionView from 'base/collection-view';
import deps from 'mod/departments/singleton';


const childView = DepartmentListItem.extend({
	tagName: 'li',
	smallText () {
		const emp = this.getOption('employee');
		return this.model.getEmployeeRole((emp || {}).id, { display: true });
	},
	topText () {},
	closeButton: (m) => m.employeesCanBeRemoved(), // m.employeeCanBeRemoved(); },
	onCloseClick () {
		const model = (this.getOption('employee') || {}).id;
		this.model.executeAction('remove:employee', { model });
	}
});
export default CollectionView.extend({
	initialize () {
		this.listenTo(deps, 'change update remove', () => {
			this.model.getDepartmentsCollection();
		});
	},
	tagName: 'ul',
	childView,
	emptyView: childView.Empty,
	childViewOptions () {
		return {
			employee: this.model
		};
	}
});
