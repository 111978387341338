import { commonFilters } from '../common-filters';
export const rawFilters = {
	...commonFilters,
	transactionType: v => {
		return {
			label: 'тип операции',
			valueType: 'string',
			controlType: 'select',
			sourceValues: [
				{
					label: 'выдача',
					value: 'issueForProduction'
				},
				{
					label: 'возврат на склад',
					value: 'revokeFromProduction'
				}
			]
		};
	}
};
