import Base from 'components/ui-list/item';
import ActionsPopover from 'behaviors/actions-popover';
export default Base.extend({
	renderOnModelChange: true,
	behaviors: [ActionsPopover.extend({ placedAt: 'plate' })],
	// topText(){
	// 	let leads = this.model.getLeads();
	// 	return _(leads).map((l) => l.display('name')).join(', ');
	// },
	smallText () {
		const emps = this.model.getEmployees();
		return emps.map((e) => e.display('name')).join(', ');
	},
	bottomText () {
		return this.model
			.getRights()
			.getFilledNames()
			.join(', ');
	},
	optionsButton: true,
	textAction: 'open:card'
});
