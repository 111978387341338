import {
	Object as OldObject, MnObject as NewObject,
	View as MnView, NextCollectionView as MnCollectionView
} from 'backbone.marionette';

const MnObject = OldObject || NewObject;

export {
	MnObject,
	MnView,
	MnCollectionView
};
