import { ReportPage } from './cmn';

const Page = ReportPage.extend({
	label: 'Отчет по сырью',
	reportType: 'sources',
	reportLayoutOptions: {
		fetchNextDisabled: true
	}
});

export default Page;
