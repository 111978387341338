import _ from 'underscore';
import isKnownCtor from './is-known-ctor';

export default function betterResult (obj = {}, key, opts = {}) {
	const { context, args, checkAlso, force } = opts;
	const defaultValue = opts.default;

	if (!_.isString(key) || key === '') { return; }

	let value = obj[key];

	if (value != null && (!_.isFunction(value) || isKnownCtor(value))) {
		return value;
	}

	if (force !== false && _.isFunction(value)) {
		value = value.apply(context || obj, args);
	}

	// let result = force !== false && _.isFunction(value) ? value.apply(context || obj, args) : value;

	if (value == null && _.isObject(checkAlso)) {
		const alsoOptions = _.omit(opts, 'checkAlso');
		value = betterResult(checkAlso, key, alsoOptions);
	}

	if (value == null && defaultValue != null) {
		value = defaultValue;
	}

	return value;
}
