import { _ } from 'vendors';
// import dDefs from 'modules/display-defaults';
import enums from 'helpers/enums';


function toBool (v) {
	if (v == null) return;
	if (_.isBoolean(v)) return v;
	if (!v) return false;
	v = v.toString().toLowerCase();
	return ['0', 'false'].indexOf(v) > -1;
}

const ContactModelProperties = {
	properties: {
		middleNamePerson: {
			display: {
				label: 'отчество'
			}
		},
		firstNamePerson: {
			display: {
				label: 'имя'
			}
		},
		lastNamePerson: {
			display: {
				label: 'фамилия'
			}
		},
		name: {
			display: {
				transform () {
					const cName = this.get('contactName');
					const pName = this.getPersonName();
					return pName || cName || '&mdash;';
				}
			}
		},
		contragentFuncs: {
			display: {
				label: 'функции',
				transform (v) {
					return enums.get('contragentContactFunctions', v);
				}
			}
		},
		funcs: {
			display: {
				label: 'функции',
				transform (v) {
					if (this.has('contragentFuncs')) {
						if (v == null) { v = this.get('contragentFuncs'); }
						return enums.get('contragentContactFunctions', v);
					}
				}
			}
		},
		primary: {
			display: {
				label: 'основной контакт',
				transform (v) {
					v = toBool(v);
					return v ? 'да' : 'нет';
				}
			}
		}
	}
};
export default ContactModelProperties;
