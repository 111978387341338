import CollapasableBlock from './collapasable-block';
import CollectionView from 'base/collection-view';
import Collection from 'base/collection';
import View from 'base/view';
import smartOpen from 'helpers/smart-open';
// import { ClickableBehavior, stringComparator } from './common';
import { ClickableBehavior } from 'behaviors';
const links = [
	{
		id: 'root',
		type: 'root',
		name: 'отдел',
		url (attrs) {
			return `/prod/deps/${attrs.departmentId}`;
		}
	},
	{
		id: 'contragents',
		type: 'subdivision',
		name: 'журнал выдачи продукции контрагентам',
		url (attrs) {
			return `/prod/deps/${attrs.departmentId}/contragents-transactions`;
		}
	},
	{
		id: 'production',
		type: 'subdivision',
		name: 'производство',
		url (attrs) {
			return `/prod/deps/${attrs.departmentId}/production`;
		}
	},
	{
		id: 'source-report-link',
		type: 'link',
		name: 'отчет по сырью',
		target: 'new',
		url (attrs) {
			return `/reports/sources?byproddep.v=${attrs.departmentId}`;
		}
	}
];

function createLinkModel (link, department) {
	const model = {
		departmentId: department.id,
		...link
	};
	return model;
}

const LinkView = View.extend({
	className: 'ui2-molecule link-item',
	template: '<div><%= name %></div>',
	behaviors: [ClickableBehavior],
	cssClassModifiers: [
		(m, v) => v.isCurrentLink() ? 'active' : ''
	],
	getClickOptions (event) {
		const newTab = this.model.get('target') === 'new';
		const ctrlKey = newTab ? !event.ctrlKey : event.ctrlKey;
		const options = { ctrlKey };
		return options;
	},
	getUrl () {
		const m = this.model;
		let url = m.get('url');
		if (typeof url === 'function') {
			url = url(m.attributes, m);
		}
		return url;
	},
	onClicked (event) {
		const url = this.getUrl();
		const clickOptions = this.getClickOptions(event);
		smartOpen(url, clickOptions);
	},
	isCurrentLink () {
		// const dep = this.getOption('departmentModel');
		// const pageId = dep.get('pageId');
		const fullUrl = this.getUrl();
		const thispath = document.location.pathname;
		const url = fullUrl.split('?')[0];
		const isRoot = this.model.get('type') === 'root';
		if (thispath === url) { return true; }

		if (!isRoot && thispath.startsWith(url)) { return true; }

		return false;
        // console.log('>>> urls:', dep.get('pageId'), url, thispath);
	}
});

const LinksView = CollectionView.extend({
	className: 'links-list',
	initialize () {
		const models = links.map(lnk => createLinkModel(lnk, this.model));
		this.collection = new Collection(models);
	},
	childView: LinkView,
	childViewOptions () {
		return {
			departmentModel: this.model
		};
	}
});



export default CollapasableBlock.extend({
	addClas: 'links-block',
	header: 'Ссылки и подразделы',
	buildContentView () {
		return new LinksView({ model: this.model });
	}
});
