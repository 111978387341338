import { _ } from 'vendors';
import View from 'base/view';
import CollectionView from 'base/collection-view';
import Collection from 'base/collection';
import { BuyProcessProductModel } from './BuyProcessProductModel';
// import UiListItem from 'components/ui-list/item';

import busData from 'bus/data';
import { ActionsPopover } from 'behaviors';

const measure = v => busData.measures(v, 'short');



// const formatPrice = pr => {
// 	try {
// 		return _.displayNum(pr, 4);
// 		// pr.toLocaleString('ru');
// 	} catch (e) {
// 		return pr;
// 	}
// };

const ProductView = View.extend({
	behaviors: [ActionsPopover],
	className: 'ui-list-item buy-product-item',
	tagTitle: v => v.model.get('fullName') || v.model.get('name'),
	cssClassModifiers: [
		(m) => m.get('isFullyAccepted') ? 'completely-accepted' : undefined
	],
	template: `
		<button class="options"><i></i></button>
		<div class="name">
			<b><%= onePriceText%></b>
			<span><%= name %></span>
		</div>
		<div class="amount">
			<i><%= fullAmountText %></i>
			<b><%= fullPriceText %></b>
			<span><%= remainsAmountText %></span>
		</div>
	`,
	modelEvents: {
		refresh: 'render',
		change: 'render'
	},
	templateContext () {
		const { name, fullName, amount, acceptedAmount, unitMeasureId, priceUnitMeasureId, isFullyAccepted, priceUnitsInPurchaseUnit } = this.model.attributes;


		const remainsAmount = amount - acceptedAmount;

		const workAmount = !isFullyAccepted && acceptedAmount ? amount : remainsAmount;

		const priceUnit = measure(priceUnitMeasureId || unitMeasureId);

		const remainsAmountText = _.displayNum(isFullyAccepted ? amount : remainsAmount, 4) + ' ' + priceUnit;

		let fullAmountText = '';

		const baseUnit = measure(unitMeasureId);
		let baseAmountText = '';
		if (priceUnitsInPurchaseUnit != null && priceUnitsInPurchaseUnit !== 1) {
			baseAmountText = _.displayNum(workAmount * priceUnitsInPurchaseUnit, 4) + ' ' + baseUnit;
		}
		// priceUnitsInPurchaseUnit;

		if (!isFullyAccepted && acceptedAmount) {
			fullAmountText = _.displayNum(amount, 4) + ' ' + priceUnit;
			if (baseAmountText && baseAmountText !== '') { fullAmountText += ' / ' + baseAmountText; }
		} else {
			fullAmountText = baseAmountText;
		}

		const inf = this.model.getPriceInfo();
		const fullPriceText = inf.price ? _.displayNum(inf.price, 4) + ' ' + inf.currencySign : '';
		// let priceUnit = inf.priceUnitMeasure || inf.unitMeasure;
		const onePriceText = inf.pricePerPriceUnit ? `1 ${priceUnit} = ${_.displayNum(inf.pricePerPriceUnit, 4)} ${inf.currencySign}` : '';
		// console.log(inf);
		return {
			name: name || fullName,
			baseAmountText,
			remainsAmountText,
			fullAmountText,
			fullPriceText,
			onePriceText
		};
	}
});

// const __ProductView = UiListItem.extend({
// 	behaviors: [ActionsPopover],
// 	// text: m => m.get('fullName') + ': ' + m.get('amount') + ' ' + m.display('unitMeasureId'),
// 	tagTitle: m => m.get('fullName') || m.get('name'),
// 	text: m => m.get('name') || m.get('fullName'),
// 	rightItems () {
// 		const m = this.model;
// 		return [
// 			// {
// 			// 	template: '<div><b><%= units %></b><span><%= amount%></span></div>',
// 			// 	templateContext: {
// 			// 		units: m.display('unitMeasureId')
// 			// 	}
// 			// }
// 		];
// 	},
// 	smallText () {
// 		// let m = this.model;
// 		// let units = m.display('unitMeasureId');
// 		// let amount = m.get('amount');
// 		// let amountInPurchaseUnit = m.get('priceUnitsInPurchaseUnit') || 1;
// 		// let unitPrice = this.model.get('pricePerUnit');
// 		// unitPrice = unitPrice * amountInPurchaseUnit;
// 		// let price = unitPrice * this.model.get('amount');

// // -----------------------

// 		// let fm = formatPrice;
// 		// let inf = this.model.getPriceInfo();
// 		// return `<span class="small-items">
// 		// 	<span class="small-item amount">
// 		// 		<label>кол-во</label>
// 		// 		${inf.amount} <em>${inf.unitMeasure}</em>
// 		// 	</span>
// 		// 	<span class="small-item unit-price">
// 		// 		<label>за ${busData.measures(inf.unitMeasureId, 'short')}</label>
// 		// 		${fm(inf.pricePerPriceUnit)} <em>${inf.currencySign}</em>
// 		// 	</span>
// 		// 	<span class="small-item total-price">
// 		// 		<label>сумма</label>
// 		// 		${fm(inf.price)} <em>${inf.currencySign}</em>
// 		// 	</span>
// 		// 	</span>`;

// // -----------------------

// 		// let txt = 'цена за всё: <strong>' + price + '</strong> р.<br>';
// 		// txt += 'цена за единицу: ' + this.model.get('pricePerUnit') + ' р.';
// 		// return txt;
// 	}
// });



export const BuyProcessProductsView = CollectionView.extend({
	className: 'products-items',

	initialize () {
		const proc = this.process = this.getOption('process');
		// this.listenTo(this.process, 'buyproducts:refresh', () => {
		// 	console.log('REFRESH')
		// 	this.collection.reset(this.getFreshModels());
		// 	console.log('RESET WAS DONE')
		// 	this.process.trigger('items:patched');
		// });
		// let items = this.getFreshModels();
		if (!proc.productsToBuyCollection) {
			proc.productsToBuyCollection = new Collection(proc.get('productsToBuy'), { model: BuyProcessProductModel });
			proc.trigger('productsToBuy:initialized');
		}
		this.collection = proc.productsToBuyCollection;
			// new Collection(items, { model: BuyProcessProductModel });
	},
	// getFreshModels() {
	// 	let items = this.process.get('productsToBuy') || [];
	// 	return items;
	// },
	childView: ProductView,
	childViewOptions (childModel) {
		const proc = this.process;
		childModel.process = proc;
		// const col = this.collection;
		// let editModel = childModel;
		childModel.id && (childModel.url = this.process.url() + '/buyproducts/' + (childModel.id || ''));
		return {
			// editButton: this.process.amIAdmin(),
			optionsButton: true
			// onEditClick() {
			// 	let content = new EditProductView({ model: this.model, changeBehavior: 'apply' });
			// 	busEdit.doNext({
			// 		header: 'Изменение ' + this.model.get('fullName'),
			// 		labels: {
			// 			resolve: 'Сохранить изменения'
			// 		},
			// 		content: new EditWraper({
			// 			content
			// 		}),
			// 		resolve() {
			// 			//return Promise.reject();
			// 			let json = childModel.toJSON();
			// 			let attrs = _.pick(json, 'amount', 'unitMeasureId', 'pricePerUnit', 'nds', 'priceUnitMeasureId', 'priceUnitsInPurchaseUnit', 'priceCurrencyId' );
			// 			let colJson = col.toJSON();
			// 			//console.log('##', attrs, json, colJson);
			// 			return childModel.save(null, { attrs, method: 'PATCH', wait: true }).then(() => {
			// 				proc.set('productsToBuy', colJson);
			// 				console.log('### ?? patched', colJson);
			// 				proc.trigger('items:patched');
			// 			});
			// 		}
			// 	}).then(value => {
			// 		console.log('resolved', value);
			// 	}).catch(value => {
			// 		console.log('catched', value);
			// 	});
			// }
		};
	}
});

const ProductViewForClipboard = View.extend({
	tagName: 'tr',
	template: _.template('<td><span><%= name %></span></td><td><span><%= amount %></span><span><%= units %></span></td>'),
	attributes: {
		style: 'border: 1px solid #777'
	},
	templateContext () {
		const m = this.model;
		const units = m.display('unitMeasureId');
		const amount = m.get('amount');
		// let unitPrice = this.model.get('pricePerUnit');
		// let price = unitPrice * this.model.get('amount');
		// let fm = pr => {
		// 	try {
		// 		return pr.toLocaleString('ru');
		// 	} catch (e) {
		// 		return pr;
		// 	}
		// };
		const res = {
			name: this.model.get('fullName') || this.model.get('shortName'),
			amount,
			units
		};
		return res;
	}
});

export const BuyProcessProductsViewForClipboard = BuyProcessProductsView.extend({
	className: '',
	tagName: 'table',
	attributes: {
		cellpadding: '5px',
		cellspacing: '0',
		style: 'border: 1px solid #777; border-collapse: collapse'
	},
	childView: ProductViewForClipboard,
	childViewOptions: {}
});


export const BuyProcessProductsFooterView = View.extend({
	tagName: 'footer',
	template: _.template('<div><span>итого:</span></div><div><span><%= total %></span></div>'),
	initialize () {
		const proc = this.getProcess();
		this.collection = proc.productsToBuyCollection;
		this.listenTo(proc, 'items:patched', this.onItemsPatched);
	},
	collectionEvents: {
		'update remove change': 'onItemsPatched'
	},
	onItemsPatched () {
		this.render();
	},
	getProcess () {
		return this.getOption('process');
	},
	getProducts () {
		return this.collection.toJSON();
		// let proc = this.getProcess();
		// let items = proc.get('productsToBuy') || [];
		// return items;
	},
	getTotalPrice () {
		const prods = this.getProducts();
		const prices = prods.map(m => new BuyProcessProductModel(m)).map(m => m.getPriceInfo());
		const groups = _.groupBy(prices, h => h.currency.id);
		if (_.size(groups) > 1) {
			return;
		}
		let total;
		_.every(groups, (group, groupid) => {
			const groupSum = _.reduce(group, (memo, item) => {
				memo += item.price;
				return memo;
			}, 0);
			total = [groupSum, groupid];
		});
		// let total = _.reduce(groups, (sum, group, groupId) => {
		// 	let groupSum =  _.reduce(group, (memo, item) => memo += item.price, 0);
		// 	console.log('### groupSum', groupSum, groupId, groups);
		// 	sum += busData.toRubles(groupSum, groupId);
		// 	return sum;
		// }, 0);
		return total;
		// let price = prods.reduce((memo, item) => memo += item.amount * item.pricePerUnit, 0);
		// return price;
	},
	templateContext () {
		const total = this.getTotalPrice();
		if (Array.isArray(total) && !isNaN(total[0])) {
			const [amount, cur] = total;
			return {
				total: _.displayNum(amount, 4) + busData.currencies(cur, 'sign')
			};
		}
		return {
			total: '&mdash;'
		};
		// if (isNaN(total)) {
		// 	total = '&mdash;';
		// } else {
		// 	total = total.toLocaleString('ru') + ' р.';
		// }
		// return {
		// 	total,
		// };
	}
	// modelEvents: {
	// 	'change:items'() {
	// 		this.render();
	// 	}
	// }
});
