import control from 'components/controls/mixin';
import View from 'base/view';
import template from './attach-user.html';


import EmailInput from 'components/controls/contact-value/email';
import selectDetachedUsers from 'mod/users/helpers/select-detached';

export default control(View).extend({
	className: 'attach-user-to-employee',
	template,
	initialize () {
		this.value = {};

		this.createNested('email', {
			viewClass: EmailInput,
			viewOptions: {
				proxyTo: this,
				controlName: 'email',
				type: 'email',
				doneOnEnter: false,
				focusOnRender: false,
				doneOnBlur: false,
				label: 'email нового пользователя'
			},
			regionTemplate: { el: '.invite-new-region', replaceElement: true, exist: true }
		});
	},
	onRender () {
		this.showEmail();
		this.triggerContentReady();
	},
	controlsEvents: {
		'email:change' (email) {
			this.value.email = email;
			this.value.id = undefined;
			this.triggerChange();
		}
	},
	events: {
		'click .select-one button' () {
			selectDetachedUsers().then(
				(id) => {
					this.value.id = id;
					this.value.email = undefined;
					this.triggerDone();
				}
			);
		}
	},
	validate () {
		return this.value.id || this.value.email;
	}
});
