import bus from 'bus';
import busEdit from 'bus/edit';
import busModels from 'bus/models';
import modals from 'helpers/modals';

export function getTaskChannel (id) {
	return bus.channel('purchaserequest:' + id);
}

// export function addProduct(model, context = {}) {
// 	let { productIds, contragent } = context;

// }

export function removeProc () {

}

function buildProductsSourceValues (items) {
	return items.reduce((memo, item) => {
		if (item.tempProcessId) return memo;
		const added = {
			id: item.productionId || item.newproduct,
			isNew: !!item.newproduct,
			value: item.productionId || item.newproduct,
			name: item.name || (item.newproduct + ' [отсутствует в каталоге]')
		};
		memo.push(added);
		return memo;
	}, []);
}

export function buildProductsContext (items, contragentId) {
	const context = {
		productsById: {},
		productsHash: {}
	};

	context.products = items.reduce((memo, item) => {
		if (item.tempProcessId) return memo;
		const added = {
			id: item.productionId || item.newproduct,
			isNew: !!item.newproduct,
			value: item.productionId || item.newproduct,
			name: item.name || (item.newproduct + ' [отсутствует в каталоге]'),
			amount: item.amount,
			unitMeasureId: item.unitMeasureId
		};


		if (contragentId && item.contragentsData && item.contragentsData.length) {
			const info = item.contragentsData.find(det => det.contragent && det.contragent.id === contragentId);
			info && info.details && (added.details = info.details);
		} else if (contragentId == null && item.contragentsData) {
			added.contragentsData = item.contragentsData;
		}

		memo.push(added);
		context.productsById[added.id] = item;
		context.productsHash[added.id] = added;

		return memo;
	}, []);

	return context;
}

export function selectProducts ({ products, productIds } = {}) {
	return busEdit.do({
		header: 'укажите продукцию',
		controlType: 'select',
		sourceValues: products,
		multiple: true
	});
}

export function selectContragent () {
	return busEdit.do({
		header: 'укажите контрагента',
		controlType: 'select-api',
		sourceValues: () => busModels.request('new:contragents:search', { fetch: true }),
		controlViewOptions: {
			shouldReturnModel: true
		}
	});
}

function mapOnAdd (id, productsById, productsHash, tempId) {
	console.log('-0-', id, productsById);
	productsById[id].tempProcessId = tempId;
	const hashValue = productsHash[id];
	return {
		productId: !hashValue.isNew ? id : null,
		product: hashValue
	};
}

function convertToBuy (productIds, productsById, productsHash, tempId) {
	return productIds.map(id => mapOnAdd(id, productsById, productsHash, tempId));
}

export async function addProducts (opts = {}) {
	let { model, /* productModel, */ productId, contragent } = opts;
	// console.log('# productModel', productModel && productModel.attributes, opts);
	const items = model.get('typeDetails.items');

	const productSourceValues = buildProductsSourceValues(items);



	let productIds;
	if (productId) {
		productIds = [productId];
	} else {
		productIds = await selectProducts({ products: productSourceValues });
	}

	// let askAboutNew = false;

	if (!contragent) {
		contragent = await selectContragent();
		if (contragent && contragent.toJSON) {
			contragent = contragent && contragent.toJSON();
		}
	}
	// else {
	// 	askAboutNew = true;
	// }

	const contragentId = contragent ? contragent.id : null;

	console.log('COOL');

	let procs = model.attributes.typeDetails.processes;
	if (!procs) {
		procs = model.attributes.typeDetails.processes = [];
	}
	let useExist = false;
	const exist = procs.find(i => (i.contragentId === contragentId) || (contragentId == null && i.contragentId == null));
	if (exist) {
		useExist = await (modals.confirm('Добавить к имеющемуся процессу ?', 'добавить к имеющемуся', 'создать новый').then(() => true).catch(() => false));
		console.log('= =', useExist);
	}

	const { /* products, */ productsById, productsHash } = buildProductsContext(items, contragentId);

	// console.log('/*/*/*', productsById, productsHash);

	if (!useExist) {
		const tempId = Date.now();
		const raw = {
			tempId,
			contragentId: contragent ? contragent.id : null,
			contragentName: contragent ? contragent.name : '[контрагент пока не известен]',
			productsToBuy: convertToBuy(productIds, productsById, productsHash, tempId)
			// productIds.map(id => mapOnAdd(id, productsById, productsHash, tempId))
		};
		procs.push(raw);
	} else {
		exist.productsToBuy.push(...convertToBuy(productIds, productsById, productsHash, exist.tempId));
	}

	model.save(null, {
		method: 'PATCH',
		wait: true,
		attrs: { typeDetails: model.get('typeDetails') }
	}).then(() => {
		model.trigger('change:typeDetails.processes');
		model.trigger('change:typeDetails.items');
	});
}
