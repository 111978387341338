import { Ui2BaseButtonView } from 'components/ui2/buttons';

export const SwitcherButton = Ui2BaseButtonView.extend({
	template: '<%= text %>',
	initialize () {
		this.filterer = this.getOption('filterer');
        // this.filtererModel = this.getOption('filtererModel');
        // this.listenTo(this.filtererModel, 'change', this.render);
	},
	getText () {
		return this.model.get('text');
	},
	isActive () {
        // let fmod = this.filtererModel;
		let isActive = this.model.get('isActive');
		if (!isActive) return isActive;

		if (typeof isActive === 'function') {
			isActive = isActive.call(this.model, this.model, this);
		}

		return isActive;
        // if (typeof isActive === 'function') {
        //     return isActive.call(this.model, fmod);
        // }
        // return isActive;
	},
	templateContext () {
		return {
			text: this.getText()
		};
	}
});
