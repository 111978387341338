import BasePage from 'pages/auth';
import Layout from './layout';
import { requestTypes } from 'mod/configs/models/requestResponsible';
import Request from './request';
// import Create from './create';
// import Card from './group';

// import List from './list';
// import Layout from './layout';
// import Emps from './emps/emps';

const Page = BasePage.extend({
	moduleRights: { admin: 'view' },
	id: 'admin/requests',
	name: 'requests management page',
	label: 'Управление заявками',
	route: 'requests',
	initialize () {
		this.collection = requestTypes;
	},
	onBeforeStart () {
		return this.collection.fetchIfNot();
	},
	Layout,
	icon: 'requests',
	// socketGroups:() => 'employeegroups',
	children: [Request]
});

export default Page;
