import { _ } from 'vendors';
import BaseView from 'base/view';
import template from 'base/empty-template';
import Controls from './controls';
import MonthModel from './month-model';
import Weekdays from './weekdays';
import Days from './days';
import mix from 'helpers/mix';
import ControlsMixin from 'components/controls/mixin';

export default mix(BaseView).with(ControlsMixin).extend({
	tagName: 'section',
	className: 'c-dt-month',
	template,
	triggers: {
		'click .c-dt-c-month': 'months:selection:click',
		'click .c-dt-c-year': 'years:selection:click'
	},
	initialize () {
		this.valueModel = this.getOption('valueModel');
		this.initModel();
		this.initCollection();
		this.initNested();
	},
	onRender () {
		this.showAllNested();
	},
	// childViewTriggers:{
	// 	'click':'day:selected'
	// },

	initModel () {
		if (this.model) return;
		const rawval = this.valueModel && this.valueModel.get('value');

		const now = (_.isDate(rawval) && !isNaN(rawval.valueOf()) && rawval) || new Date();

		// var now = this.valueModel.get('value') || new Date();

		const year = this.getOption('year') || now.getFullYear();
		const month = this.getOption('month') || now.getMonth();
		this.model = new MonthModel({ year, month, startTime: this.getOption('startTime'), defaultTime: this.getOption('defaultTime') }, { valueModel: this.valueModel });
	},

	initCollection () {
		this.collection = this.model.getDaysCollection();
	},

	initNested () {
		this.createNested('controls', {
			viewClass: Controls,
			regionTemplate: {
				el: '.controls',
				replaceElement: true
			}
		});


		this.createNested('weekdays', {
			viewClass: Weekdays,
			regionTemplate: {
				el: '.weekdays',
				replaceElement: true
			}
		});

		this.createNested('days', {
			viewClass: Days,
			viewOptions: {
				proxyTo: this,
				controlName: 'days'
			},
			regionTemplate: {
				el: '.days',
				replaceElement: true
			}
		});
	},
	controlsEvents: {
		'days:change' (value) {
			this.triggerChange(value);
		}
	}
});
