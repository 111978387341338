import BasePage from 'pages/auth';
// import employees from 'mod/employees/singleton';
import Layout from './layout';
import Employee from './emp';
import action from 'helpers/action';
import Actionable from 'mixins/actionable';
import create from './create/modal';
import data from 'bus/data';
import EmployeeModel from 'mod/employees/models/model';
import modals from 'helpers/modals';

const Page = Actionable(BasePage).extend({
	id: 'staff/emps',
	name: 'staff employees page',
	label: 'Сотрудники',
	route: 'emps',
	icon: 'staff-emps',
	onBeforeStart () {
		// let col = new Bb.Collection();
		// col.url = 'http://api.palma-med.loc/v2/asd/qwe/asd/zxc';
		// this.addStartPromise(col.fetch());
		this.addCollection(data.employees());
	},
	children: [Employee],
	actions: [
		action('add:employee', 'добавить сотрудника', { employees: 'invite' })
	],
	Layout,
	socketGroups: () => 'employees',
	actionAddEmployee () {
		create().then((value) => {
			// var url = _.result(data.employees(), 'url');
			const model = new EmployeeModel();
			model.save(value, { preloader: true, wait: true }).then(
				() => {
					data.employees().add(model);
					return modals.confirm('Перейти на страницу сотрудника?').then(
						() => model.executeAction('open:card'),
						() => {}
					);
				},
				() => modals.error('Возникла ошибка, не удалось добавить сотрудника')
			);
		}).catch(() => {});
	}
	// children:[Login, ForgotPass, ChangePass, ResetPass]
});

export default Page;
