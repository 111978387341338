import ModelProperties from 'components/ui-model-card/properties';
import h from 'components/ui-model-card/helpers';
import UiCardBlock from 'components/ui-card-block/block';
import UiItem from 'components/ui-list/item';
import { BaseHamburgerView } from '../../../../base/base-hamburger-view';
import { designSwitch, isNewDesign } from '../designSwitch';

const Switch = BaseHamburgerView.extend({
	className: 'card lined',
	childrenViews: [
		v => ({
			class: UiItem,
			thisClassName: 'whole-btn',
			text: 'переключиться на новую версию',
			triggers: {
				'click':'click'
			}
		})
	],
	childViewEvents:{
		click() {
			designSwitch();
		}
	}
})

export function mainBlock (view) {
	const m = view.model;
	if (m.get('type') === 'process') {
		view.createNested('specialAction', {
			viewClass: Switch,
			viewOptions: {
				parentView: view
			}
		});
	}

	view.createNested('main', {
		viewClass: ModelProperties.extend({ className: 'card lined with-border' }),
		viewOptions: {
			// header:{ text: 'id: #' + m.id, addClass:''},
			flatPatch: true,
			cssClassModifiers: [
				'main-info-block',
				m => m.get('completeState')
			],
			properties: [
				h.ui('iId', { editButton: false }),
				h.ui('name', { editButton: m.isCanBeChanged('name') }),
				h.ui('deadline', { editButton: m.isCanBeChanged('deadline') }),
				h.ui('description', { editButton: m.isCanBeChanged('description') }),
				h.ui('completeState', { editButton: false })
			]
		},
		regionTemplate: { replaceElement: true }
	});
}

export function mainContent(view) {
	const m = view.model;
	return {
		class: ModelProperties,
		className: 'card lined with-border',
		flatPatch: true,
		cssClassModifiers: [
			'main-info-block',
			m => m.get('completeState')
		],
		properties: [
			
			h.ui('name', { editButton: m.isCanBeChanged('name') }),
			h.ui('completeState', { editButton: false }),
			h.ui('description', { editButton: m.isCanBeChanged('description') }),
			h.ui('deadline', { editButton: m.isCanBeChanged('deadline') }),
			h.ui('ownerId', { editButton: m.isCanBeChanged('ownerId'), half: false }),
			h.ui('creatorId', { editButton: false, half: false }),
			h.ui('modified', { editButton: false, half: false }),
			h.ui('created', { editButton: false, half: false })
		]
	}
}