import { _ } from 'vendors';
export default function (values = [], defaultValue = 1) {
	if (!values || !values.length) return defaultValue;

	let nextNum = values.length === 1
		? values[0]
		: _.max(values);

	if (nextNum == null || nextNum < defaultValue) {
		return defaultValue;
	} else {
		return ++nextNum;
	}
}
