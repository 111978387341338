import pkg from '../../package.json';

const appVersion = {
	version: pkg.version,
	release: process.env.NODE_ENV === 'production'
};

window.pmapp = appVersion;

export default appVersion;
