import { _ } from 'vendors';
import Obj from 'base/object';
import Radio from './radio';

const bus = Radio.channel('socket:app:event');
const Handler = Obj.extend({
	channelName: 'socket:app:event',
	getContext () { return this.getOption('context'); },
	initialize () {

	}
});

function rebindContext (hash, context) {
	if (!(_.isObject(hash) && !_.isFunction(hash))) return;
	_(hash).each((method, name) => {
		if (!_.isFunction(method)) return;
		hash[name] = _.bind(method, context);
	});
}

bus.reply('Handler', function (opts = {}, context) {
	if (_.isObject(context)) {
		rebindContext(opts.radioEvents, context);
		rebindContext(opts.radioRequests, context);
	}

	const NewHandler = Handler.extend(opts);
	return new NewHandler();
});

export default bus;
