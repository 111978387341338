export function plural (number, one, few, many, withNum) {
    // let b = this;
	few = few || one;
	many = many || few;

	if (isNaN(number)) { return ''; }

	let n = parseInt(number, 10) % 100;
	let detected = one;

	if (n > 4 && n < 21) { detected = many; } else {
		n = n % 10;
		if (n > 4 || n === 0) {
			detected = many;
		} else if (n === 1) { detected = one; } else { detected = few; }
	}
	const result = withNum === true ? number.toString() + ' ' + detected : detected;
	return result;
}

export function padNumber (number, length = 2, char = '0', ifNotNumber = '') {
	if (number == null || isNaN(number)) {
		return ifNotNumber;
	}
	const str = number.toString();
	return str.padStart(length, char);
}

export function displayNum (value, digits, options = {}) {

	if (digits && typeof digits === 'object') {
		options = digits;
		digits = undefined;
	}

	digits = digits || options.digits || 0;

	const empty = options.empty || '';

	value = parseFloat(value, 10);

	if (isNaN(value)) {
		return empty;
	}

	const power = Math.pow(10, digits);

	if (power > 0) {
		const method = options.round || 'round';
		value = Math[method](value * power) / power;
	}

	if (value.toLocaleString) {
		return value.toLocaleString('ru', { maximumFractionDigits: digits || 0 });
	} else {
		return russianNumber(value);
	}

}

function russianNumber (value) {
	if (value == null || isNaN(value)) return;

	let [stringInteger, stringFractional] = value.toString().split('.');

	const integerCharArray = Array.from(stringInteger);
	integerCharArray.reverse();


	stringInteger = integerCharArray.reduce((memo, char, index) => {
		if (index > 0 && (index % 3) === 0) {
			char = char + ' ';
		}
		memo = char + memo;
		return memo;
	}, '');

	if (stringFractional) {
		stringFractional = ',' + stringFractional;
	}

	return integerCharArray + stringFractional;
}

export function displayDistance (value, options) {
	if (value == null || isNaN(value)) return;
	value = Math.floor(value / 50) * 50;
	return displayNum(value, options);
}


export function formatNum (value, options) {
	options || (options = {});
	if (value == null) return options.empty || '';
	const nvalue = parseFloat(value, 10);
	if (isNaN(nvalue)) return options.empty || '';

	const locale = 'ru';
	const numoptions = {};
	if (options.maxFraction != null) { numoptions.maximumFractionDigits = options.maxFraction; }
	return nvalue.toLocaleString(locale, numoptions);
}
