import { _, moment } from 'vendors';
import { getProductName } from './utils';
import busData from 'bus/data';
import { buildFilters } from './common-filters';
import modals from 'marionette.yat/singletons/modals/modals';
import { buildCsv, downloadCsv } from '../../../../../helpers/csv';
import { tableSchemaValue } from '../../../../../components/schema-table';

export const commonTableLayoutMixin = {
	excludeFilters: ['departmentId'],
	configButton: true,
	printButton: true,
	viewComparator: v => v.model.get('name'),
	getFilterModelInitialValues () {
		const source = this.req?.args || {};
		const values = _.pick(source, 'departmentId', 'productId', 'contragentId');
		console.error('? initial values ->', values);
		return values;
	},
	selectModelMappings: {
		employees: item => ({
			label: busData.employeeName(item.eId),
			value: item.eId
		}),
		contragents: item => ({
			value: item.id,
			label: item.name || '[без названия]'
		}),
		products: item => ({
			value: item.id,
			label: getProductName(item)
		})
	},
	getSelectModels (key) {
		const items = this.model.get(key) || [];
		const itemsMap = this.selectModelMappings[key];
		const models = items.map(itemsMap);
		const sorted = _.sortBy(models, 'label');
		return sorted;
	},
	getFilterModelData () {
		const filters = this.getOption('rawFilters', true);
		const exclude = this.getOption('excludeRawFilters', true);
		const include = this.getOption('includeRawFilters', true);
		if (filters) {
			return buildFilters(filters, { exclude, include, invokeContext: this, invokeArgs: this });
		}
	},
	onBeforeRender () {
		this.model.initializeProductToProcessMap();
	},
	onCsvButtonClick () {
		const schema = this.getOption('tableSchema');
		const fields = this.getOption('cells', true) || [];
		const options = {
			fields,
			fieldHeader (field) {
				const schemaField = schema[field];
				return [schemaField.headerText, schemaField.headerSmallText].filter(f => !!f).join(' ') || field;
			},
			fieldValue (field, model) {
				const schemaField = schema[field];
				try {
					const fakeView = {
						model,
						schemaValue (key) {
							return tableSchemaValue(this, key, schema, model);
						}

					};
					return schemaField.value.call(fakeView, fakeView);
				} catch (exc) {

					console.error(field, schemaField);
					console.error(exc);
				}
			}
		};
		const data = this.dataCollection.models;
		const csv = buildCsv(data, options);

		const period = this.filterModel.getValueModel('period').getValue();
		const chunks = ['report'];
		if (period.from) {
			chunks.push(moment(period.from).format('YYYYMMDD'));
		}
		if (period.to) {
			chunks.push(moment(period.to).format('YYYYMMDD'));
		}
		const filename = chunks.join('-');

		downloadCsv(csv, filename);

		// const options = {
		// 	fields: ['id', 'name', 'value']
		// };
		// const data = [
		// 	{ id: 1, name: 'foo', value: 123 },
		// 	{ id: 2, name: 'asd', value: 345 },
		// 	{ id: 3, name: 'qwe', value: 234 },
		// 	{ id: 4, name: 'zxc', value: 678 }
		// ];
		// const csv = buildCsv(data, options);
		// const filename = 'test';
		// downloadCsv(csv, filename);
	},
	onPrintButtonClick () {
		const options = this.getContentView();
		options.disableFetch = true;
		// options.listHeaderEnabled = true;
		const View = options.class;
		console.error(options);
		const view = new View(options);
		modals.show(view, {
			type: 'full',
			css: { wrapper: 'yat-modal-wrapper printable-table full-screen' },
			modifiers: {
				'after:render': { centering: () => {} },
				refresh: { centering: () => {} }
			}
		});
	},
	onDataCollectionInitialized (col) {
		this.dataCollection = col;
	}
};
