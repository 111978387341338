import { View, HamburgerView } from 'base/views';

import { Ui2AtomView } from '../ui2/atom';
import modals from 'helpers/modals';
import { FiltersView } from '../filters-v2';

export const TableContentHeaderView = HamburgerView.extend({
	baseClassName: 'header list-manager',

	customViews () {
		const views = [
			this.getFilterButtonView(),
			...this.getAdditionalViews(),
			this.getInfoView(),
			this.getTableConfigView(),
			this.getPrintButtonView(),
			this.getCsvButtonView()
		];
		return views;
	},
	getInfoView () {
		if (!this.collection.result) { return; }
		return {
			class: Ui2AtomView,
			model: this.collection.result,
			text: v => v.model.get('founded') || '',
			renderOnModelChange: true
		};
	},
	getFilterButtonView () {
		if (this.getOption('noFilterButton', true)) {
			return;
		}
		const filterModel = this.getOption('filterModel');
		if (!filterModel) { return; }
		const excludeFilters = this.getOption('excludeFilters');
		return {
			class: View,
			tagName: 'button',
			template: '<i class="fa fa-filter"></i>',
			triggers: {
				click: 'click'
			},
			onClick () {
				const view = new FiltersView({
					model: filterModel,
					excludeFilters
				});
				modals.show({
					header: 'фильтры',
					asPromise: true,
					content: view,
					type: 'full',
					labels: { resolve: 'закрыть' },
					show: {
						resolve: true
					}
				}).promise.then(() => {
					filterModel.trigger('apply');
				}, () => {});
			}
		};
	},
	getAdditionalViews () {
		let res = this.getOption('content', true);
		if (!Array.isArray(res)) {
			res = [res];
		}

		return res;
	},
	getTableConfigView () {
		const layoutView = this.getOption('layoutView');
		if (!this.getOption('configButton', true)) {
			return;
		}
		return {
			class: View,
			tagName: 'button',
			template: '<i class="fa fa-cog"></i>',
			triggers: {
				click: 'click'
			},
			onClick () {
				layoutView.triggerMethod('config:button:click');
			}
		};
	},
	getPrintButtonView () {
		if (!this.getOption('printButton', true)) {
			return;
		}
		const layoutView = this.getOption('layoutView');
		return {
			class: View,
			tagName: 'button',
			template: '<i class="fa fa-print"></i>',
			triggers: {
				click: 'click'
			},
			onClick () {
				layoutView.triggerMethod('print:button:click');
			}

		};
	},
	getCsvButtonView () {
		if (!this.getOption('csvButton', true)) {
			return;
		}
		const layoutView = this.getOption('layoutView');
		return {
			class: View,
			tagName: 'button',
			template: '<i class="fa fa-file-excel-o"></i>',
			triggers: {
				click: 'click'
			},
			onClick () {
				layoutView.triggerMethod('csv:button:click');
			}

		};
	}

});
