import BaseCollection from 'base/collection';
import model from './model';
import busModels from 'bus/models';
import comparator from 'helpers/comparator';
const Collection = BaseCollection.extend({
	model,
	comparator (m1, m2) {
		return comparator([m2, m1, (m) => m.get('created', { convert: 'date' })]);
	}
});

busModels.reply('logs', () => Collection);

export default Collection;
