import { _ } from 'vendors';
import { Model, Collection } from 'backbone';

/*
  "skiped": 0,
  "taked": 50,
  "founded": 2,
  "returned": 2,
  "endOfData": true,

*/

const baseFetch = Model.prototype.fetch;


function add (arr, key, value) {
	let line;
	if (value == null || value === '') {
		line = key + '=';
	} else {
		line = key + '=' + encodeURIComponent(value);
	}
	arr.push(line);
}

export const PagedData = Model.extend({
	constructor (data, options) {
		this.beforeInitialize(data, options);
		Model.apply(this, arguments);
		this.on('sync', (model, data, options) => {
			console.log('SSSS', options);
			this.trigger('items:fetch', this.items.models, options);
		});
		this.on('filters:changing', _.debounce(this._filtersChange.bind(this), 300));
	},
	parse (data) {
		const items = data.items;
		delete data.items;
		this._setCollectionItems(items);
		return data;
	},
	beforeInitialize (data, options = {}) {
		this.filters = {};
		this._initializeOptions(options);
		this._initializeCollection();
		this._intializeUrl();
	},
	setFilter (key, value) {
		if (key && typeof key === 'object') {
			Object.assign(this.filters, key);
		} else {
			this.filters[key] = value;
		}
		this.trigger('filters:changing');
	},
	addFilterValue (key, addValue) {
		if (key in this.filters) {
			let value = this.filters[key];
			if (!Array.isArray(value)) {
				value = this.filters[key] = [value];
			}
			value.push(addValue);
			this.trigger('filters:changing');
		} else {
			this.setFilter(key, addValue);
		}
	},
	_filtersChange () {
		this.trigger('filters:change');
		this.fetch({ remove: true });
	},
	getUrlQuery ({ move } = {}) {
		const query = [];
		if (move != null) {
			let { skiped, taked } = this.attributes;
			skiped = skiped + (move ? taked : -taked);
			if (skiped < 0) skiped = 0;
			query.push('page.skip=' + skiped);
			query.push('page.take=' + taked);
		}
		query.push(...this.getFiltersForGet());
		return query.join('&');
	},
	url (options) {
		let url = this._url;
		if (url && typeof url === 'function') {
			url = url.call(this);
		}
		const query = this.getUrlQuery(options);
		if (query) {
			url += '?' + query;
		}
		return url;
	},
	_intializeUrl () {
		this._url = this.getOption('url') || this.items.url;
	},
	_getItemsSetOptions () {
		return { };
	},
	_setCollectionItems (items) {
		this.items.set(items, this._getItemsSetOptions());
	},
	_initializeOptions (options) {
		options = options || {};
		this.options = { ...options };
	},
	_initializeCollection () {
		const CollectionClass = this.getOption('collectionClass') || Collection;
		this.items = new CollectionClass(null, this.getOption('collectionOptions'));
	},
	getOption (key, { invoke } = {}) {
		let value = this.options[key];
		if (value === undefined) {
			value = this[key];
		}
		if (invoke && typeof value === 'function') {
			value = value.call(this);
		}
		return value;
	},
	getFiltersForGet () {
		const pairs = [];
		_.each(this.filters, (value, key) => {
			if (Array.isArray(value)) {
				_.each(value, arrValue => add(pairs, key, arrValue));
			} else {
				add(pairs, key, value);
			}
		});
		return pairs;
	},
	getFiltersForPost () {
		return {
			...this.filters
		};
	},
	getMethod () {
		return this.getOption('method');
	},
	getBodyData () {
		const method = this.getMethod() || 'get';
		if (method === 'get') return;
		return this.getFiltersForPost();
	},
	fetch (options = {}) {
		const method = this.getMethod();
		if (method && !options.method) {
			options.method = method;
		}
		options.wait = true;
		const data = this.getBodyData();
		if (data && !options.data) {
			options.data = data;
		}
		if (options.move) {
			options.url = this.url(options);
		}
		return baseFetch.call(this, options);
	}
});
