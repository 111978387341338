import { _ } from 'vendors';
import BasePage from 'base/page';
import user from 'app/user';
import Layout from './layout';
import preloader from 'helpers/modals/preloader';
import modals from 'helpers/modals';
import DynamicWrapper from 'mixins/view/flex-dynamic-column-wrapper';
import busApp from 'bus/app';
import busSockets from 'bus/sockets';
import socketsStarts from 'components/sockets/start-promise';
import { simpleInvoke } from '../../helpers/invoke';


const baseStart = BasePage.prototype.start;

const DefaultPage = BasePage.extend({
	constructor () {
		BasePage.apply(this, arguments);
		this._initSocketGroups();
		// this.on('all', c => console.log('#pg: ', c));
	},

	_initSocketGroups () {
		const groups = this.getOption('socketGroups', { force: false });
		if (!groups) return;

		const _groups = _.isFunction(groups) ? groups : () => groups;
		const getGroups = () => {
			let names = _groups.call(this, this.model);
			if (!names) return [];
			!_.isArray(names) && (names = [names]);
			return names;
		};

		this.on('start', () => {
			socketsStarts.promise.then(() => {
				if (!this.isStarted()) return;
				const groupNames = getGroups();
				if (groupNames.length) {
					busSockets.joinGroup(...groupNames);
					this.once('stop', () => busSockets.leaveGroup(...groupNames));
				}
			});
		});
	},


	Layout,
	relativeRoute: true,
	freezeWhileStarting: true,

	freezeUI () {
		this.freezeView = preloader();
	},
	unFreezeUI () {
		if (this.freezeView) { this.freezeView.destroy({ force: true }); }
	},
	start (...args) {
		let promise;
		if (this.isStarted()) { 
			promise = this.stop().then(() => baseStart.apply(this, args)); 
		} else { 
			promise = baseStart.apply(this, args); 
		}
		return promise;
	},
	onStartBegin () {
		const currentPage = busApp.currentPage();
		if (currentPage && currentPage !== this) { currentPage.stop(); }
	},
	openInModal (url) {
		if (this.getOption('noModal')) return;

		const actionContext = this.router.createActionContext(this, url, { routeType: 'execute' });
		if (!actionContext) return;

		this.customStart({ silent: true, beforeStart: true }, actionContext).then(() => {
			const layout = this.getLayout();
			modals.show(layout, { type: 'full' });
		}, () => {

		});
	},
	buildLayoutOptions (rawOptions) {
		if (this.id) {
			rawOptions.id = this.id;
			rawOptions.storeState = true;
		}
		return rawOptions;
	},

	matchUrl (url) {
		if (url == null || !_.isString(url) || !this._routesContexts) return false;
		const res = _(this._routesContexts).some((cntx) => cntx.pattern.test(url));
		return res;
	},
	getGoBackMethod (req) {
		if (arguments.length === 0) {
			req = _.pick(this._currentActionContext || {}, 'args', 'query');
		}
		const parentLink = this.getParentLink();
		if (parentLink?.url) {
			const urlParams = Object.assign({}, req.args, req.query);
			parentLink.url = this.parseUrl(parentLink.url, urlParams);
		}

		// console.log('parentLink :::: ', parentLink);

		const canGoBack = this.history.canGoBack();

		if (canGoBack) {
			return () => this.history.goBack();
		} else if (parentLink) {
			return () => this.history.goSilent(parentLink.url, { trigger: true, updateCurrent: true });
		}
	},
	isAvailable () {
		if (this.moduleRights == null) return true;
		return user.checkRights(this.moduleRights);
	},
	getWrapLayout() {
		return this.getOption('wrapLayout');
	},
	getLayout (opts, merge) {
		const view = BasePage.prototype.getLayout.apply(this, arguments);

		_.extend(view, merge);
		const wrap = this.getWrapLayout();
		if (wrap) {
			if (_.isFunction(wrap)) {
				return new wrap({ content: view }); // eslint-disable-line
			} else if (wrap === true) {
				return new DynamicWrapper({ content: view });
			}
			return view;
		}
		view.triggerMethod('after:initialize');
		return view;
	},
	getHeader () {
		return this.getOption('header', { args: [this.model, this] }) || this.getOption('label', { args: [this.model, this] });
	},
	getLabel () {
		return this.getOption('header', { args: [this.model, this] }) || this.getOption('label', { args: [this.model, this] });
	},
	buildLink (level, index) {
		const hash = BasePage.prototype.buildLink.call(this, level, index);
		if (!hash) return;
		hash.icon = simpleInvoke(this.icon, this, this);
		hash.faIcon = simpleInvoke(this.faIcon, this, this);
		const label = this.getOption('menuName', { args: [this.model, this] });
		if (label) {
			hash.label = label;
		}
		return hash;
	}
});


export default DefaultPage;
