import Base from 'components/ui-list/item';
import ActionsPopover from 'behaviors/actions-popover';


export default Base.extend({
	behaviors: [ActionsPopover],
	cssClassModifiers: [
		'production-type'
	],
	text () {
		return this.model.display('name');
	},
	smallText: m => m.getFullName(),
	textAction: 'open:card',
	optionsButton: true
	// infoButton:true
});
