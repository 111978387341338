import './promise';
import { $ } from 'vendors';
$(() => {
	$.fn.promiseAnimate = function (opts, delay) {
		return new Promise((resolve) => {
			try {
				this.animate(opts, delay, resolve);
			} catch (e) {
				resolve();
			}
		});
	};
});
