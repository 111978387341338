import 'mod/productionMeasures';
import ListView from 'mod/productionMeasures/views/cards-list';
import getRawFilters from 'mod/productionMeasures/filters/get';
import mix from 'helpers/mix';
import FDC from 'mixins/view/flex-dynamic-column';

import ViewManagedCollection from 'mixins/view-managed-collection';


import Actionable from 'mixins/actionable';
import action from 'helpers/action';


import openAdd from 'mod/productionMeasures/views/open-add';

const Base = mix(ListView).with(FDC, ViewManagedCollection, Actionable);
const View = Base.extend({
	id: 'measures-search-list',
	className: 'measures-list',
	entriesManagerOptions: {
		infoOptions: true,
		filtersOptions: {
			getRaw: getRawFilters,
			instantApply: true
		},
		actionsEnabled: true
	},
	actions: [
		action('add:measure', 'добавить единицу измерения', { resourcesProduction: 'measuresEdit' })
	],
	attributes () {
		return {
			'data-scrollable': 'measures-search'
		};
	},
	actionAddMeasure () {
		return openAdd();
	}
});

export default View;
