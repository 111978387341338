import { _ } from 'vendors';
import BasePage from 'pages/auth';
// import Layout from './layout';
import { BoardView } from 'components/board';

import TaskModel from 'mods/tasks/models/model';
import CardView from 'mods/tasks/views/card-item';
import Model from 'mod/commonProcesses/models/process';

// import busModels from 'bus/models';
// import paths from 'helpers/paths';

import createTaskAction from 'mods/tasks/create-task-action';

const Page = BasePage.extend({
	label: 'Доска задач процесса',
	route: 'tasksBoard',
	// children:[Production],
	icon: 'board',
	Layout: BoardView,
	onBeforeStart (ac) {
		this.initializeModel(ac.args.id);
		// let board = busModels.request('processes:board:instance');
		// this.addStartPromise(board.getModelIds({ fetchIfNot: true }));
	},
	layoutOptions () {
		const processModel = this.processModel;
		const apiUrl = _.result(this.model, 'url') + '/tasksBoard';
		const options = {
			// model: this.model.getTasksBoardModel(),
			boardConfig: {
				boardType: 'processTasks',
				apiUrl,
				entityConfig: {
					Model: TaskModel,
					View: CardView
				},
				addItemToListAction () {
					return createTaskAction({
						parent: processModel
					});
				}
				// addItemControl: SelectApi,
				/* addItemControlOptions: () => ({
					header: 'добавление задачи на доску',
					controlType:'select-api',
					editOptions: {
						addClass: 'long-content'
					},
					controlOptions: {
						multiple: true,
						fetchAtStart: true,
						apiSearch: true,
						shouldReturnModel: true,
						collection: busModels.request('new:tasks:search', { fetch: true })
					},
				}) */
			},
			className: 'board-processes'
		};
		return options;
	},
	initializeModel (id) {
		if (!this.processModel || this.processModel.id !== id) {
			// console.log('new process');
			this.processModel = new Model({ id });
			delete this.model;
		}
		if (!this.model) {
			// console.log('new board');
			const model = this.processModel.getTasksBoard();
			this.addModel(model);
		}
		this.addStartPromise(this.model.fetch());
	},
	hidden: true,
	wrapLayout: true
});

export default Page;
