import { $ } from 'vendors';
import View from 'base/view';
import template from './state-switcher.html';
export default View.extend({
	className: 'card lined switcher state-switcher',
	template,
	cssClassModifiers: [
		(m) => m.get('state')
	],
	events: {
		'click .line' (e) {
			const $line = $(e.target).closest('.line');
			const state = $line.data('id');
			const view = this.model.get('view');
			const date = 	this.model._getNewViewDate(view, state);
			this.model.set({
				state,
				date
			});
		}
	}
});
