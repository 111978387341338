import user from 'app/user';
import { StoreLayout } from './layout';
export default 	{
	id: 'store',
	label: 'Склад',
	filter: (p) => !p.model.get('isAbstractGroup') && user.checkRights({ productsStore: 'view' }),
	Layout: StoreLayout,
	children: [
		{
			id: 'logs',
			label: 'История',
			filter: () => user.checkRights({ productsStore: 'view' }),
			Layout: StoreLayout
		}
	]
};
