import { HamburgerView } from 'base/view-hamburger';
import { ProcessesList } from './ProcessesList';
// import { ProductionTree } from './products-layouts/ProductionTree';
// import { ProductsTableLayout } from './products-layouts/TableLayout';

export const ProcessesContent = HamburgerView.extend({
	getCustomViews () {
		return [
			this.buildContentView()
		];
	},
	buildContentView () {
		const rootView = this.getOption('rootView');
		const view = (ext) => ({
			rootView,
			model: this.model,
			filterModel: this.getOption('filterModel'),
			...ext
		});
		return view({ class: ProcessesList });

	}
});
