import viewBus from 'bus/views';
import dataBus from 'bus/data';
// import emps from 'mod/employees/singleton';
import SelectSegmented from 'components/controls/select-segmented';
// import busData from 'bus/data';

const busData = dataBus;

export default SelectSegmented.extend({
	className: 'c-select-employees',
	excludeSegments: () => (busData.user().isEmployeer() ? '' : 'my,') + 'virtual, fired, all',
	segments: [
		{
			id: 'my',
			name: 'мои подчинённые',
			filter: v => v.model.isMyEmployee()
		},
		{
			id: 'actual',
			name: 'действующие',
			filter: v => v.model.isActive()
		},
		{
			id: 'virtual',
			name: 'виртуальные',
			filter: v => v.model.isVirtual()
		},
		{
			id: 'fired',
			name: 'уволенные',
			filter: v => v.model.isFired()
		}
	],
	getSelectOptions () {
		const hash = {
			proxyTo: this,
			multiple: this.getOption('multiple'),
			excludeValues: this.getOption('excludeValues'),
			sourceValues: dataBus.employees(),
			childView: viewBus.getView('employee:list:item', { textAction: false }),
			viewComparator (v1) {
				return v1.model.display('name');
			},
			value: this.getOption('value'),
			shouldSearchOnType: this.getOption('shouldSearchOnType') !== false
		};

		return hash;
	},
	controlsEvents: {
		'control:change' (value) {
			this.value = value;
			this.triggerChange(value);
		}
	},

	getValue () {
		return this.value;
	}
});
