import { _ } from 'vendors';
import flat from 'helpers/flat';

const addProperty = function (path, value, res) {
	path = ('.' + path).replace(/\./gmi, '/');
	value = value == null ? null : value;
	const op = 'replace';
	const prop = { op, path, value };
	res.push(prop);
};

export default function toJsonPatch (hash) {
	if (hash == null || !_.size(hash)) return {};
	hash = flat(hash);

	const res = [];

	_(hash).each((value, path) => addProperty(path, value, res));

	return res;
}
