import { $ } from 'vendors';
import getEventFields from './get-event-fields';
import appBus from 'bus/app';

export default function (url, arg) {
	let opts = arg;
	if (arg instanceof Event || arg instanceof $.Event) { opts = getEventFields(arg); }

	return appBus.request('open', url, opts);
}
