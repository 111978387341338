import { _ } from 'vendors';
import View from 'base/view';
import { CreateCommentModel } from '../models/comment-model';
import modalError from 'helpers/modals/error';


import modals from 'helpers/modals';
import ExtendedEdit from './extended-edit/edit';
import MarkdownHelp from './extended-edit/help';
import { pasteImageHandler } from 'components/files';
import { storeUnsended, storeUnsendedMixin } from './utils';
// import { show } from 'helpers/floating-modal';



const InlineMessageEdit = View.extend({
	constructor () {
		this.detectResize = _.throttle(this._detectResize, 50);
		View.apply(this, arguments);
	},
	className: 'message-edit',
	template: _.template(''),
	tagName: 'textarea',
	attributes: {
		autofocus: true,
		placeholder: 'Введите текст комментария...\nбыстрая отправка: Cntrl + Enter'
	},
	events: {
		input: '_onInput',
		'keydown'(e) {
			if (e.key == 'Tab') {
				e.preventDefault();
				const el = e.target;
				var start = el.selectionStart;
				var end = el.selectionEnd;
		
				// set textarea value to: text before caret + tab + text after caret
				el.value = el.value.substring(0, start) + "\t" + el.value.substring(end);
		
				// put caret at right position again
				el.selectionStart = el.selectionEnd = start + 1;
			}
		},
		'keyup' (e) {
			if (!(e.ctrlKey && e.keyCode === 13)) {
				return;
			}
			this.trigger('cntrl:enter', this.el.value);
		},
		'paste' (event) {
			pasteImageHandler(event, imageBlobs => this.trigger('images:pasted', this.el.value, imageBlobs));
		}
	},
	onAttach () {
		if (this.getOption('focus')) {
			this.$el.focus();
		}
	},
	_onInput() {
		this._storeUnsended(this.el.value);
		this.detectResize.apply(this, arguments);
	},
	_detectResize () {
		if (!this.el.value) {
			delete this._lastScrollHeight;
			delete this._resizeDisabled;
			this.$el.removeClass('autoresize-disabled');
			this.$el.css('height', '');
			return;
		}
		const outerH = this.el.offsetHeight;
		const innerH = this.el.clientHeight;
		const scrollH = this.el.scrollHeight;
		const height = scrollH + outerH - innerH;
		const lastH = this._lastScrollHeight == null ? innerH : this._lastScrollHeight;
		this._lastScrollHeight = scrollH;

		if (lastH === scrollH) {
			return;
		}

		let lastFix = false;

		if (height > 400 && !this._resizeDisabled) {
			this.$el.addClass('autoresize-disabled');
			this._resizeDisabled = true;
			lastFix = true;
		} else if (height <= 400 && this._resizeDisabled === true) {
			this.$el.removeClass('autoresize-disabled');
			this._resizeDisabled = true;
			lastFix = true;
		}

		if (!this._resizeDisabled || lastFix) {
			this.$el.css('height', scrollH + 'px');
			this.el.scrollTop = 0;
		}
	},
	initialize() {
		this.$el.val(this._restoreUnsended());
	},
	...storeUnsendedMixin,
});


const templateString =
`<div class="helpers">
	<button class="to-compose-mode"><i></i></button>
	<button class="markdown-help" title="Подсказки по разметке"><i></i></button>
</div>
<div class="message"></div>
<div class="send-message"><button class="send" title="Отправить сообщение"><i></i></button></div>
`;

export const EnterTextLayout = View.extend({
	tagName: 'footer',
	className: 'fast-comment-layout',
	template: _.template(templateString),
	regions: {
		message: '.message'
	},
	onRender () {
		this.triggerMethod('ready');
	},
	onReady () {
		this.showTextarea();
	},
	modelEvents: {
		'change:completeState': 'render',
		'change:team': 'render'
	},
	getInputCommonOptions() {
		return {
			unsendedCacheId: this.getOption('unsendedCacheId', true),
		}
	},
	showTextarea (opts) {
		const enabled = this.getOption('isEnabled', { args: [this.model] });
		if (enabled === false) return;
		const options = Object.assign(this.getInputCommonOptions(), opts)
		const view = new InlineMessageEdit(options);
		this.showChildView('message', view);
	},
	getLogs () {
		return this.collection;
	},
	sendComment (data) {
		if (data == null) return;
		if (_.isString(data)) {
			data = { text: data };
		}
		const text = data.text;
		if (text == null || text === '') return;
		const attrs = _.extend({ parent: this.model }, data);
		this._send(attrs);
	},
	sendImages (text, imageBlobs) {
		const fileSvc = this.getOption('fileSvc');
		if (fileSvc && imageBlobs) {
			fileSvc.addBlobs(imageBlobs);
		}
		// console.log(imageBlobs);
		// let data = { text };
		// let attrs = _.extend({ parent: this.model }, data);
		// this._send(attrs);

		// let formData = new FormData();
		// formData.append('text', text);
		// _.each(imageBlobs, (blob, index) => {
		// 	formData.append(`images[${index}]`, blob, _.uniqueId('uploadedImage'));
		// });
		// this._send(attrs, {
		// 	//contentType: 'multipart/form-data',
		// 	addToUrl: '/multipart',
		// 	data: formData,
		// 	cache: false,
		// 	contentType: false,
		// 	processData: false,
		// });
	},
	_send (attrs, sendOptions = {}) {
		if (this._sending) return;

		const model = new CreateCommentModel(attrs);

		_.defaults(sendOptions, { preloader: true, wait: true });
		if (sendOptions.addToUrl) {
			const url = _.result(model, 'url');
			sendOptions.url = url + sendOptions.addToUrl;
			delete sendOptions.addToUrl;
		}
		this._sending = true;

		model.save(null, sendOptions).then((data) => {
			delete this._sending;
			this._storeUnsended();
			this.showTextarea({ focus: true });
			const logs = this.getLogs();
			logs.addEntry(data, { collection: logs.getBackend() });
		}, xhr => {
			delete this._sending;
			modalError('Неудалось добавить комментарий', xhr);
		});
	},

	childViewEvents: {
		'cntrl:enter': 'sendComment',
		'images:pasted': 'sendImages'
	},
	events: {
		'click .send' () {
			const text = this.$('textarea').val();
			this.sendComment(text);
		},
		'click .markdown-help' () {
			// show(new MarkdownHelp);

			modals.show({
				header: 'подсказки',
				content: new MarkdownHelp(),
				type: 'message'
			});
		},
		'click .to-compose-mode' () {
			const editView = new ExtendedEdit({ 
				textarea: this.$('textarea'),
				...this.getInputCommonOptions()
			});
			modals.show({
				asPromise: true,
				returnPromise: true,
				header: 'добавление комментария',
				content: editView,
				type: 'message-flex',
				show: {
					actions: false
				}

			}).then(
				(data) => {
					this.sendComment(data);
				},
				(data) => {
					const hardReject = data?.reason === 'rejectHard';
					let storeValue; // = this.$('textarea').val();
					if (!hardReject) {
						const val = (data ? data.value : editView.getValue()) || {};
						storeValue = val.text;
						this.$('textarea').val(storeValue);
					} else {
						storeValue = this.$('textarea').val();
					}
					this._storeUnsended(storeValue);
				}
			);
		}
	},
	...storeUnsendedMixin,

});

