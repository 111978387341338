import { FetchOnScrollView } from 'base/views';
import { stateMixin } from 'mixins/view/state';
import { classNameMixin } from '../../mixins/view/className';
import { commonCollectionMixin } from './common';
import { EmptyView } from './EmptyView';
import { TableRowView } from './TableRowView';
import { MnCollectionView } from 'vendors';

const addChildView = MnCollectionView.prototype.addChildView;

export const TableRowsListView = FetchOnScrollView.extend({
	...classNameMixin,
	...stateMixin,
	...commonCollectionMixin,

	baseClassName: 'table-rows-list',
	childView: TableRowView,
	emptyView: EmptyView,

	initialize () {
		this._initialize();
		// this.updateClassName();
		console.error('#RowList', this.options);
	},
	_initialize () {
		this.initializeCollection();
		this.initializeCollectionState();
		this.initializeFilterModel();
		this.once('before:render', () => this.fetch());

		this._initHeaders();

		// this.on('render:children', () => this._onRenderChildren());
	},
	_initHeaders () {
		const { headersInside } = (this.getOption('tableConfig') || {});
		if (!headersInside) { return; }
		const event = this.isEmpty() ? 'render:children' : 'render';

		this.once(event, () => {
			this._addInsideHeaders();
		});

	},
	fetch () {
		// debugger;
		if (this.getOption('disableFetch') === true) {
			return;
		}
		return FetchOnScrollView.prototype.fetch.apply(this, arguments);
	},
	initializeCollectionState () {
		console.error('#init state');
		this.state('emptyState', 'waiting');
		this.listenTo(this.collection, 'sync', () => {
			if (this.collection.length) {
				this.state('emptyState', false);
			} else {
				this.state('emptyState', 'notFound');
			}
		});
		this.on('filter', () => {
			if (this.children._views.length) {
				this.state('emptyState', false);
			} else {
				this.state('emptyState', 'empty');
			}
		});
	},
	initializeFilterModel () {
		if (this.filterModel) { return; }
		this.filterModel = this.getOption('filterModel', true);
		if (this.filterModel) {
			this.listenTo(this.filterModel, 'apply', this.fetch);
		}
	},
	getRequestData () {
		if (!this.filterModel) { return; }
		const data = this.filterModel.getRequestData();
		return data;
	},
	childViewOptions () {
		const rowOptions = this.getOption('rowOptions', true) || {};
		const rowClickUrl = this.getOption('rowClickUrl', false);
		return {
			rowClickUrl,

			cells: this.getOption('cells', false),
			cellOptions: this.getOption('cellOptions', true),

			tableSchema: this.getOption('tableSchema'),
			thisClassName: this.getOption('rowClassName'),
			tableConfig: this.getOption('tableConfig', true),
			useWrapper: this.getOption('rowUseWrapper', true),
			...rowOptions
		};
	},
	emptyViewOptions () {
		return {
			emptyState: this.state('emptyState')
		};
	},

	_addInsideHeaders () {
		console.log('#ADDING-HEADERS#');
		if (this.headersAlreadyRendered) { return; }
		this.headersAlreadyRendered = true;

		const HeadersView = this.childView;
		const options = this.childViewOptions();
		options.onlyHeaders = true;
		// options.baseClassName = 'inside-headers';
		// options.thisClassName = 'clean';
		options.className = 'table-headers';
		const view = new HeadersView(options);

		addChildView.call(this, view, 0);

	}
});
