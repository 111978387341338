import Collection from 'base/collection';
import CollectionView from 'base/collection-view';
// import View from 'base/view';
import { setOptionsMixin } from './common';
import { Ui2AtomView } from '../ui2/atom';
import { HamburgerView } from 'base/view-hamburger';
import smartOpen from '../../helpers/smart-open';
import paths from 'helpers/paths';

const LinkView = HamburgerView.extend({

	...setOptionsMixin(HamburgerView),

	className: 'ui2-molecule division-link clickable',
	cssClassModifiers: [
		(m, v) => v.isCurrent() ? 'current' : ''
	],
	customViews: [
		{
			class: Ui2AtomView,
			text: v => v.model.get('label')
		}
	],
	customViewOptions () {
		return {
			model: this.model,
			page: this.page
		};
	},
	isCurrent () {
		return this.model.get('isCurrent') === true;
	},
	getUrl () {
		const urlPattern = this.model.get('url');
		const args = {};
		return paths.urls.page(urlPattern, args);
	},
	events: {
		click (event) {
			let url = this.model.get('url');
			url = paths.urls.page(url, this.req.args);
			console.log('::', url, this.req);
			if (this.isCurrent()) { return; }
			smartOpen(url, event);
		}
	}
});

export const DivisionsView = CollectionView.extend({

	...setOptionsMixin(CollectionView),

	className: 'block white divisions',
	initialize () {

		const root = this.page.getDivisionRootPage();
		if (!root) { return; }

		const children = root.getChildren();

		children.unshift(root);

		const models = children.reduce((links, child) => {
			const model = child.getLink();
			if (!model) return links;

			model.isCurrent = child === this.page;
			links.push(model);

			return links;
		}, []);
		this.collection = new Collection(models);

	},
	childView: LinkView,
	childViewOptions () {
		return this.getDefaultChildViewOptions({ noData: true });
	}
});
