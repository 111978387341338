
// import { HamburgerView } from '../HamburgerView';
import { isView } from 'utils/is';
import { Ui2AtomView, Ui2AtomView as UiAtomView, Ui2SchemaAtomView } from '../atom';
// import { GlyphButtonView } from '../Button';
// import { CoreBbView } from '../../../core/coreBbView';
import { invokeValue } from 'utils';
import { HamburgerView } from 'base/views';
import { FaIconButtonView } from '../buttons';
import { DumbView } from '../../../base/dumb-view';
import smartOpen from 'helpers/smart-open';
import { viewSchemaMixin } from 'apis/schema';


const openCardButton = {
	class: FaIconButtonView,
	buttonName: 'openCard',
	// url: v.getOption('openCardButton', true) || v.getOption('openCardButtonLeft', true),
	faIcon: 'eye',
	onClick (view, e) {
		const ctrlKey = e.ctrlKey !== true;
		const url = this.getOption('url', true);
		if (url) {
			if (typeof url === 'string') {
				smartOpen(url, { ctrlKey });
			} else {
				console.warn('provided url was not a string', url);
			}
		}
	}
};

const extras = {
	buttons: {
		left: [
			{
				check: view => view.getOption('optionsButton', true),
				options: textToButton('options:ellipsis-v')
			},
			{
				check: view => view.getOption('selectButton', true),
				options: textToButton('select')
			},
			{
				check: view => {
					const url = view.getOption('openCardButton', true) || view.getOption('openCardButtonLeft', true);
					return !!url;
				},
				options: v => ({
					url: v.getOption('openCardButton', true) || v.getOption('openCardButtonLeft', true),
					...openCardButton
				})
			}
		],
		right: [
			{
				check: view => view.getOption('editButton', true),
				options: textToButton('edit:edit')
			},
			{
				check: view => view.getOption('openCardButtonRight', true),
				options: v => ({
					url: v.getOption('openCardButtonRight', true),
					...openCardButton
				})
			},
			{
				check: view => view.getOption('removeButton', true),
				options: textToButton('remove:remove')
			},
			{
				check: view => view.getOption('deleteButton', true),
				options: textToButton('delete:trash')
			}
		]
	},
	icons: {
		left: undefined,
		right: undefined
	},
	items: {
		left: undefined,
		right: undefined
	}
};

function textToButton (text) {

	const chunks = text.split(/:/gmi);

	// if (chunks.length === 1) {
	// 	chunks.push(chunks[0]);
	// }
	const [buttonName, faIcon] = chunks;
	const icon = faIcon ? undefined : buttonName;
	const btn = {
		class: FaIconButtonView,
		buttonName,
		thisClassName: buttonName,
		icon,
		faIcon
		// glyph
		// clickEvent: buttonName + ':click'
	};
	// console.error('->', btn);
	return btn;
}


function tryExtendBuildOptions (item, ViewClass, extension) {

	if (
		item && !isView(item) &&
		typeof item === 'object' &&
		'class' in item === false
	) {

		item = Object.assign({ class: ViewClass }, extension, item);

	}
	return item;

}

export const UiMoleculeView = HamburgerView.extend({
	baseClassName: 'ui2-molecule',
	customViewOptions () {
		return {
			model: this.model,
			collection: this.collection
		};
	},
	getCustomViews () {


		return [
			...this.getLeftChildren(),
			...this.getCoreChildren(),
			...this.getRightChildren()
		];

	},
	getCoreChildren () {
		const DefaultView = this.getOption('coreChildView', true) ||
			this.getOption('childView', true) ||
			UiAtomView;

		return this._getAndRemapChildrenItems('content', item => tryExtendBuildOptions(item, DefaultView));

	},
	_getAndRemapChildrenItems (key, remaper) {

		let views = this.getOption(key, true);
		if (views == null) {

			return [];

		}
		if (!Array.isArray(views)) {

			views = [views];

		}
		return remaper ? views.map(remaper) : views;

	},
	getLeftChildren () {

		return this._getSideChildren('left');

	},
	getRightChildren () {

		return this._getSideChildren('right');

	},
	_getSideChildren (side) {

		const icons = this._getSideIcons(side);
		const buttons = this._getSideButtons(side);
		const items = this._getSideItems(side);

		if (side === 'left') {

			return [
				...icons,
				...buttons,
				...items
			];

		} else {

			return [
				...items,
				...buttons,
				...icons
			];

		}

	},
	_getSideIcons (side) {

		const DefaultView = this.getOption(side + 'IconChildView', true) ||
			this.getOption('iconChildView', true) ||
			FaIconView;

		const remaper = item => {

			if (item == null) { return; }
			let extension;
			if (typeof item === 'string') {

				extension = { icon: item };

			} else if (typeof item !== 'object') {

				return;

			}
			return tryExtendBuildOptions(item, DefaultView, extension);

		};

		let icons = this._getAndRemapChildrenItems(side + 'Icons', remaper);
		icons = this._addExtraItems(side, icons, extras.icons[side]);
		return icons;

	},
	_getSideButtons (side) {
		const DefaultView = this.getOption(side + 'ButtonChildView', true) ||
			this.getOption('buttonChildView', true) ||
			FaIconButtonView;
		const remaper = item => {

			// let extension;
			if (typeof item === 'string') {

				item = textToButton(item);
				// { icon: item };

			}
			return tryExtendBuildOptions(item, DefaultView);

		};

		let buttons = this._getAndRemapChildrenItems(side + 'Buttons', remaper);
		buttons = this._addExtraItems(side, buttons, extras.buttons[side]);
		return buttons;

	},
	_getSideItems (side) {
		const DefaultView = this.getOption(side + 'ChildView', true) ||
			this.getOption('childView', true) ||
			Ui2AtomView;
		const remaper = item => {
			if (item == null) { return; }
			return tryExtendBuildOptions(item, DefaultView);
		};
		let items = this._getAndRemapChildrenItems(side + 'Items', remaper);

		items = this._addExtraItems(side, items, extras.items[side]);

		return items;

	},
	_addExtraItems (side, items, extra) {

		if (!Array.isArray(extra)) {

			return items;

		}
		const add = this._buildExtraItemsArray(extra);
		if (!add.length) return items;
		if (side === 'left') {

			items.unshift(...add);

		} else {

			items.push(...add);

		}
		return items;

	},
	_buildExtraItemsArray (itemsArray) {

		return itemsArray.reduce((arr, btn) => {

			const checkResult = btn.check(this);
			if (checkResult) {

				const view = invokeValue(btn.options, this, [this, checkResult]);
				if (view) {

					arr.push(view);

				}

			}
			return arr;

		}, []);

	}

});


const FaIconView = DumbView.extend({
	tagName: 'span',
	className: 'icon',
	html: v => `<i class="glyphicon glyphicon-${v.getOption('icon')}"></i>`
});


export const UiSchemaMoleculeView = UiMoleculeView.extend({
	...viewSchemaMixin,
	customViewOptions () {
		const options = {
			model: this.model,
			collection: this.collection,
			modelSchema: this.getOption('modelSchema', true)
		};
		return options;
	},
	childView: Ui2SchemaAtomView
});
