
import View from 'base/view';
import { ProductsCollection } from './models/products-collection';
import { ProductLinesView } from './product-lines-view';
import { FilterModel } from './models/filter';
import { ActionsItemsView } from './actions-items-view';
import { FilterContainerView } from './filter-container-view';

import preloader from 'helpers/modals/preloader';

export default View.extend({
	className: 'products-edit-amounts',
	template: `<div class="filters-container"></div><div class="production-container"><div class="please-wait"><i class="preloader"></i> подождите, пожалуйста... </div></div>
    <div class="actions-container"></div>`,
	regions: {
		production: '.production-container',
		actions: '.actions-container',
		filter: '.filters-container'
	},
	initialize () {
		this.filterModel = new FilterModel();
		this.collection = new ProductsCollection();
		this.collection.on('fetch:complete', e => console.log('-col fetched-'));
	},
	onRender () {
        // let freezer = preloader();
		this.collection.fetchIfNot().then(() => {
			this.showViews();
            // freezer.destroy();
		});
	},
	showViews () {
		const options = {
			collection: this.collection,
			filterModel: this.filterModel
		};

		const prods = new ProductLinesView(options);
		this.showChildView('production', prods);

		const actions = new ActionsItemsView(options);
		this.showChildView('actions', actions);

		const filter = new FilterContainerView(options);
		this.showChildView('filter', filter);
	},
	childViewEvents: {
		'after:save' () {
			const freezer = preloader();
			this.collection.fetch().then(() => {
				freezer.destroy({ force: true });
				this.render();
			}).catch(() => {
				freezer.destroy({ force: true });
			});
		}
	}
});
