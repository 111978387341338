import BaseView from 'base/view';
import template from './layout.html';
import DateView from './date';
export default BaseView.extend({
	tagName: 'section',
	className: 'c-dt-selected',
	template,
	regions: {
		value: { el: '.value', replaceElement: true },
		from: { el: '.from', replaceElement: false },
		to: { el: '.to', replaceElement: false }
	},
	onRender () {
		if (this.model.type === 'single') {
			this.showDate('value');
		} else if (this.model.type === 'range') {
			this.showDate('from');
			this.showDate('to');
		}
	},
	showDate (name) {
		const date = this.model.get(name);
		if (date == null) return;
		const view = new DateView({
			name,
			cssClassModifiers: [
				name === 'value' ? 'single-value' : undefined
			],
			controlName: name,
			proxyTo: this,
			date,
			ignoreModel: true,
			model: this.model,
			noTime: this.getOption('noTime'),
			startTime: this.getOption('startTime')
		});
		this.showChildView(name, view);
	},
	modelEvents: {
		refresh: 'render'
	},
	controlsEvents: {
		'value:change' (date) {
			this.model.set('value', date);
			this.trigger('time:changed');
		},
		'value:from' (date) {
			this.model.set('from', date);
			this.trigger('time:changed');
		},
		'value:to' (date) {
			this.model.set('to', date);
			this.trigger('time:changed');
		}
	},
	childViewTriggers: {
		// 'change':'time:changed',
		'value:clear': 'value:clear',
		'from:clear': 'from:clear',
		'to:clear': 'to:clear'
	},
	childViewEvents: {
		clear: 'render'
	},
	templateContext () {
		return {
			isSingle: this.model.type === 'single',
			isRange: this.model.type === 'range'
		};
	}
});
