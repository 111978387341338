import PagedCollection from 'mixins/paged-collection';
import BaseCollection from 'base/collection';
import { BatchModel } from './BatchModel';
import { apiBatchesUrl } from '../constants';

export const FetchableBatchesCollection = PagedCollection(BaseCollection).extend({
	model: BatchModel,
	urlPattern: apiBatchesUrl,
	fetchMethod: 'POST'
});
