// import user from 'app/user';
import enums from 'helpers/enums';

import { UsedInProductionLayout } from './layout';
export default 	{
	id: 'used-in-prod',
	label: 'Используется в производстве',
	filter: p => enums.has(p.model.get('purpose'), 'production'),
	Layout: UsedInProductionLayout
	// children: [
	// 	{
	// 		id: 'logs',
	// 		label: 'История',
	// 		filter: () => user.checkRights({ productsStore: 'view' }),
	// 		Layout: StoreLayout
	// 	}
	// ]
};
