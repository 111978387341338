import { _ } from 'vendors';
import { Model } from 'base/vendors';
import enums from 'helpers/enums';

const emptyArray = [];

const getAttrs = model => model instanceof Model ? model.attributes : model;

export const FilterModel = Model.extend({
	defaults: {
		modelsSet: 'all'
	},
	modelsSets: {
		all: {
			label: 'все',
			select: undefined,
			count: m => (m.attributes.batches || emptyArray).length
		},
		nochanges: {
			label: 'без изменений',
			batchFilter: (attrs, product) => !product.hasNewAmount(attrs.id),
			select: m => m.hasUnchangedAmounts(),
			count: m => m.getUnchangedCount()
		},
		changes: {
			label: 'с изменениями',
			batchFilter: (attrs, product) => product.hasNewAmount(attrs.id),
			select: m => m.hasNewAmounts(),
			count: m => m.getNewAmountsCount()
		}
	},
	fields: {
		productName: {
			transformFilterValue: v => v != null ? v.toLowerCase() : v,
			filter: (filterValue, product) => {
				if (product == null) { return true; }
				product = getAttrs(product);
				const productName = (product.name || '').toLowerCase();
                // console.log('>', productName, filterValue, productName.indexOf(filterValue));
				return productName.indexOf(filterValue) > -1;
			}
		},
		batchName: {
			transformFilterValue: v => v != null ? v.toLowerCase() : v,
			filter: (filterValue, product, batch) => {
				if (batch == null) { return true; }

				batch = getAttrs(batch);
				const idString = (batch.idString || '').toLowerCase();
				return idString.indexOf(filterValue) > -1;
			}
		},
		origin: {
			filter: (filterValue, product) => {
				const attrs = getAttrs(product);
				return enums.has(attrs.origin || '', filterValue);
			}
		},
		purpose: {
			filter: (filterValue, product) => {
				const attrs = getAttrs(product);
				return enums.has(attrs.purpose || '', filterValue);
			}
		}
	},
	_filterFields (product, batch) {
        // console.log('-bff2-', batch);
		return _.every(this.fields, (field, fieldName) => {
			let filterValue = this.get(fieldName);

			if (field.transformFilterValue) { filterValue = field.transformFilterValue(filterValue); }

			if (filterValue === undefined) { return true; }

            // console.log('.', fieldName, filterValue);
			return field.filter(filterValue, product, batch);
		});
	},
	productFilterFields (product) {
		const attrs = getAttrs(product);
		const prodFields = this._filterFields(product);
		if (!prodFields) { return false; }
		const batchFields = attrs.batches.some(batch => this._filterFields(product, batch));
		return batchFields;
        // return this._filterFields(product) && attrs.batches.some(batch => this._filterFields(product, batch));
	},
	batchFilterFields (batch, product) {
        // console.log('-bff1-', batch);
		return this._filterFields(product, batch);
	},
	getModelsSetCount (modelsSet, col) {
		const cfg = this.modelsSets[modelsSet];
		if (!cfg) return 0;
		let selected;
		if (!cfg.select) { selected = col.models; } else { selected = col.models.filter(cfg.select); }


		const batchesCount = _.reduce(selected, (v, m) => v + cfg.count(m), 0);

		return batchesCount;
        // selected.length;
	},
	filter (m) {
		const cfg = this.modelsSets[this.get('modelsSet')];
		if (cfg && cfg.select && !cfg.select(m)) { return false; }
		if (!this.productFilterFields(m)) { return false; }
		return true;
	},
	filterBatch (batch, product) {
		const battrs = getAttrs(batch);
            // batch instanceof Model ? batch.attributes : batch;
		const cfg = this.modelsSets[this.get('modelsSet')];
		if (cfg && cfg.batchFilter && !cfg.batchFilter(battrs, product)) {
			return false;
		}
        // console.log('-fb-', batch);
		if (!this.batchFilterFields(batch, product)) { return false; }

		return true;
	}
});
