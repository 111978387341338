import { moment } from 'vendors';
import View from 'base/view';
import template from './date.html';
import Time from 'components/controls/time';
import mix from 'helpers/mix';
import ControlMixin from 'components/controls/mixin';
export default mix(View).with(ControlMixin).extend({
	className: 'c-dt-selected-date',
	template,
	regions: {
		time: { el: '.time', replaceElement: true }
	},
	initialize () {
		const value = new Date(this.getOption('date').valueOf());
		this.value = value;
	},
	onRender () {
		this.showTime();
	},
	showTime () {
		if (this.getOption('noTime')) return;
		const view = new Time({
			proxyTo: this,
			controlName: 'time',
			date: this.getValue(),
			startTime: this.getOption('startTime')
		});
		this.showChildView('time', view);
	},
	getDateLabel () {
		if (this.model.type !== 'range') return;
		return this.getOption('name') === 'from' ? 'с' : 'по';
	},
	templateContext () {
		return {
			label: this.getDateLabel(),
			date: this.getOption('date') && moment(this.getOption('date')).format('DD MMM, YYYY')
		};
	},
	controlsEvents: {
		'time:change' (date) {
			const value = this.getValue();
			value.setHours(date.getHours(), date.getMinutes(), 0, 0);
			this.triggerChange(value);
		}
	},
	// triggers(){
	// 	return {'click button.clear': `${this.getOption('name')}:clear`};
	// },
	events: {
		'click button.clear' () {
			this.trigger(`${this.getOption('name')}:clear`);
			this.trigger('clear');
		}
	}
	// childViewTriggers:{
	// 	'change':'change'
	// },
});
