import { _ } from 'vendors';
import BaseView from 'base/view';
import ModelProperties from 'components/ui-model-card/properties';
import h from 'components/ui-model-card/helpers';
import Task from '../models/model';
import busData from 'bus/data';
import InputPlaceholdered from 'components/controls/input-placeholdered';
import ControlMixin from 'components/controls/mixin';
import { PlaceholderedTextarea } from 'components/controls/textarea';
import enums from 'helpers/enums';
import editValue from 'bus/edit';
import { TabTriggersView } from 'components/content-tabs';
import ToDoBlock from './todo';
// import user from 'app/user';
// import modalError from 'helpers/modals/error';

// function initModel (model, parent) {
// 	if (model) return;

// 	this.model = new Task(null, { parent });
// 	this.model.set({
// 		responsibleId: busData.user().getIdentityId(),
// 		'dates.deadline': Date.create({ minutes: 15 })
// 	});
// 	// console.log('~~~', this.model.toJSON())
// }

const defs = { editButton: true, addClass: 'whole-btn', textAction: 'edit' };

// const deprecated = ModelProperties.extend({
// 	constructor(opts){
// 		initModel.call(this, this.model || opts.model, this.parent || opts.parent);
// 		ModelProperties.apply(this, arguments);
// 		this.on('render', this.validate);

// 	},
// 	className:'card lined with-border create-task',
// 	//header:{ text: 'основное', addClass:'bg-main-dark'},
// 	getResponsibleIdOptions() {
// 		return this.getOption('responsibleIdOptions');
// 	},
// 	properties:[
// 		h.ui('toDo', _.clone(defs) ),
// 		h.ui('description', _.clone(defs) ),
// 		h.hui('dates.deadline', _.clone(defs) ),
// 		h.hui('type', _.clone(defs) ),
// 		(m,v) => h.hui('responsibleId', _.extend({}, defs, { editOptions: v.getResponsibleIdOptions() }) ),
// 		h.hui('checkerId', _.clone(defs) ),
// 		h.ui('dates.strictDeadline', _.clone(defs) ),
// 	],
// 	modelEvents:{
// 		'change':'onModelChange',
// 		'change:type':'onModelChangeType'
// 	},
// 	// onRender(){
// 	// 	//this.validate();
// 	// },
// 	onModelChange(){
// 		this.validate();
// 	},
// 	onModelChangeType(){
// 		var type = this.model.display('type');
// 		if(!this.model.get('toDo'))
// 			this.model.set('toDo',type);
// 	},
// 	validate(){
// 		if((this.model.get('toDo') || '').length
// 		&& _.isDate(this.model.get('dates.deadline'))) {

// 			this.trigger('switch:resolve','unlock');
// 		} else {
// 			this.trigger('switch:resolve','lock');
// 		}
// 	}
// });

const TypeButton = ControlMixin(BaseView).extend({
	template: _.template('<button><i></i><small><%= type %></small></button>'),
	cssClassModifiers: [
		(m, v) => v.value
	],
	initialize () {
		this.value = _.clone(this.getOption('value'));
	},
	templateContext () {
		return {
			type: enums.get('employeeTaskTypes', this.value)
		};
	},
	events: {
		'click' () {
			editValue.do({
				valueType: 'enum',
				multiple: false,
				controlType: 'select',
				value: this.value,
				sourceValues: enums.store.employeeTaskTypes
			}).then(
				type => {
					this.value = type;
					this.triggerChange();
					this.render();
				},
				() => {}
			);
		}
	}
});

const MainProps = ControlMixin(BaseView).extend({
	className: 'main-tab-place',
	initialize () {
		this.value = _.clone(this.getOption('value'));
	},
	template: _.template(`
	<div class="flex-line">
		<div class="todo-place"></div>
		<div class="type-place"></div>
	</div>
	<div class="props-place card lined with-border"></div>
	`),
	regions: {
		todo: '.todo-place',
		type: '.type-place',
		props: '.props-place'
	},
	onRender () {
		const model = this.model;
		const toDo = new InputPlaceholdered({
			label: 'что надо сделать?',
			proxyTo: this,
			controlName: 'toDo',
			value: model.get('toDo')
		});
		this.showChildView('todo', toDo);

		const type = new TypeButton({
			proxyTo: this,
			controlName: 'type',
			value: model.get('type') || 'common'
		});
		this.showChildView('type', type);

		const props = new ModelProperties({
			className: 'props-place card lined with-border',
			model,
			modelEvents: {
				'change:responsibleId': 'render',
				'change:departmentId': 'render'
			},
			properties: [
				(m, v) => h.ui('responsibleId', _.extend({ half: true }, defs, { editOptions: v.getOption('responsibleIdOptions') })),
				h.ui('checkerId', _.extend({ half: true }, defs)),
				h.ui('departmentId', { ...defs })
				// h.ui('dates.remindAt', _.extend({ condition: v => !v.model.get('responsibleId') || v.model.isMy() }, defs) ),
				// h.ui('dates.deadline', _.extend({}, defs) ),
			]
		});

		this.showChildView('props', props, { replaceElement: true });
	},
	controlsEvents: {
		'toDo:change' (value) {
			const model = this.model;
			model.set('toDo', value);
			this.triggerChange();
		},
		'type:change' (value) {
			const model = this.model;
			model.set('type', value);
			this.triggerChange();
		}
	}
});


const AddProps = ControlMixin(BaseView).extend({
	template: _.template('<div class="dates-place"></div><div class="desc-place"></div><div class="checklist-place"></div>'),
	regions: {
		desc: '.desc-place',
		checklist: '.checklist-place',
		dates: '.dates-place'
	},
	onRender () {
		const dates = new ModelProperties({
			className: 'props-place card lined with-border',
			model: this.model,
			modelEvents: {
				'change:responsibleId' () {
					this.render();
				}
			},
			properties: [
				h.ui('dates.remindAt', _.extend({ condition: v => !v.model.get('responsibleId') || v.model.isMy() }, defs)),
				h.ui('dates.deadline', _.extend({}, defs))
			]
		});
		this.showChildView('dates', dates);


		const desc = new PlaceholderedTextarea({
			proxyTo: this,
			controlName: 'description',
			value: this.model.get('description'),
			label: 'описание задачи'
		});
		this.showChildView('desc', desc);

		const checklist = new ToDoBlock({
			model: this.model,
			header: 'Заметки',
			modelEvents: {
				'new:state' () {
					this.render();
				}
			},
			rights: {
				change: true,
				delete: true
			},
			canBeSwapped () {
				return true;
			}
		});
		this.showChildView('checklist', checklist);
	},
	controlsEvents: {
		'description:change' (value) {
			const model = this.model;
			model.set('description', value);
			this.triggerChange();
		}
	}
});

const JoinsProps = ModelProperties.extend({
	className: 'card lined with-border',
	properties: [
		h.ui('contragentId', _.extend({}, defs)),
		h.ui('processId', _.extend({}, defs))
	]
});


export default ControlMixin(BaseView).extend({
	readyOnRender: true,
	initialize () {
		// let remindAt = (new Date()).addMinutes(10);
		const myId = busData.user().getIdentityId();

		const hash = _.extend({ responsibleId: myId, dates: { } }, this.getOption('value')) || {};
		this.model = new Task(hash);

		this.listenTo(this.model, 'change:departmentId', () => {
			const { departmentId, responsibleId, checkerId } = this.model.attributes;
			let changeHash;
			if (departmentId != null) {
				changeHash = changeHash || {};
				changeHash.responsibleId = null;
			} else if (responsibleId == null) {
				changeHash = changeHash || {};
				changeHash.responsibleId = myId;
				if (checkerId === myId) {
					changeHash.checkerId = null;
				}
			}
			if (changeHash) {
				this.model.set(changeHash);
			}
		});

		this.listenTo(this.model, 'change:responsibleId', () => {
			const { responsibleId, departmentId, checkerId } = this.model.attributes;

			let changeHash;

			if (responsibleId == null && departmentId == null) {
				changeHash = {
					responsibleId: myId
				};
				// this.model.set('responsibleId', myId);
			}

			if (responsibleId != null && departmentId != null) {
				changeHash = {
					departmentId: null
				};
			}


			const newResponsible = (changeHash || {}).responsibleId || responsibleId;

			if (busData.user().isMe(newResponsible)) {
				changeHash = changeHash || {};
				changeHash.checkerId = null;
			} else if (!checkerId) {
				changeHash = changeHash || {};
				changeHash.checkerId = myId;
			}

			// let rid = this.model.get('responsibleId');
			// if (busData.user().isMe(rid)) {
			// 	this.model.set('checkerId', null);
			// } else if(!this.model.get('checkerId')) {
			// 	this.model.set('checkerId', myId);
			// }

			if (changeHash) {
				this.model.set(changeHash);
			}
		});
	},
	className: 'create-task',
	template: _.template('<div class="tabs"></div><div class="content"></div>'),
	regions: {
		content: '.content',
		tabs: '.tabs'
	},
	onTab (model) {
		this.showTab(model);
	},
	showTab (model) {
		const View = this.getOption(_.camelCase('tab:' + model.id));
		const options = this.getTabOptions(model);
		if (!View) return;
		const tabView = new View(options);
		this.showChildView('content', tabView);
	},
	getTabOptions (model) {
		const defOpts = {
			controlName: model.id,
			proxyTo: this,
			model: this.model
		};
		let options = this.getOption(_.camelCase('tab:' + model.id + ':options'), { args: [defOpts] });
		options = _.extend(defOpts, options);
		return options;
	},
	tabMain: MainProps,
	tabAdd: AddProps,
	tabJoins: JoinsProps,
	onRender () {
		const tabsHash = {
			main: 'основное',
			add: 'дополнительно',
			joins: 'привязки'
		};
		if (this.getOption('parent')) {
			delete tabsHash.joins;
		}
		const tabs = new TabTriggersView({
			tabs: tabsHash,
			activeTab: 'main',
			triggerOn: this
		});
		this.showChildView('tabs', tabs);
		this.showTab(tabs.getCurrentTab());
	},
	controlsEvents: {
		'main:change' (value) {
			_.extend(this.value, value);
			this.triggerChange();
		},
		'add:change' (value) {
			_.extend(this.value, value);
			// this.value.description = value;
			this.triggerChange();
		}
	},
	validate () {
		const model = this.model;
		const valid = !!model.get('toDo'); // && !!model.get('dates.deadline') && !!model.get('responsibleId');
		if (valid) {
			console.log('unlock');
			this.trigger('switch:resolve', 'unlock');
		} else {
			console.log('lock');
			this.trigger('switch:resolve', 'lock');
		}
		return valid;
	}
});
