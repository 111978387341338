import enums from 'helpers/enums';

const PropertiesMixin = {
	properties: {
		id: {
			display: {
				label: 'id',
				transform (v) {
					return v + ', ' + this.cid;
				}
			}
		},
		name: {
			display: {
				label: 'название'
			}
		},
		note: {
			type: 'bigtext',
			display: {
				label: 'описание',
				preserveWhiteSpace: true
			}
		},
		occupation: {
			display: {
				label: 'род деятельности'
			}
		},
		roles: {
			type: 'enum',
			sourceValues: () => enums.store.contragentRoles,
			multiple: true,
			display: {
				label: 'роль',
				transform: (v) => enums.get('contragentRoles', v)
			}
		}
	}
};
export default PropertiesMixin;
