import BasePage from 'pages/auth';
import Layout from './layout';
import Model from 'mod/productionProducts/models/model';
import { buildPageChildren } from './utils';
import { childrenMenu } from './children';

const Page = BasePage.extend({
	label: (p) => p.display && p.display('fullName'),
	route: ':productId',
	skipMenu: true,
	Layout,
	layoutOptions: {
		menuItems: childrenMenu
	},
	wrapLayout: true,
	children: () => buildPageChildren(null),
	isChildRoot: true,
	socketGroups: (m) => 'product:' + m.id,
	onBeforeStart (ac) {
		const model = new Model({ id: ac.args.productId });
		this.addModel(model, { fetch: true });
	}
});

export default Page;
