import { _ } from 'vendors';
import View from 'base/view';
import collection from './stack';
import instances from './instances';

export default View.extend({
	tagName: 'button',
	template: _.template('<i></i><b></b>'),
	ui: {
		counter: 'b'
	},
	collection,
	initialize () {
		this.listenTo(collection, 'all', this.updateCounter);
	},
	onRender () {
		this.updateCounter();
	},
	updateCounter () {
		if (!this.collection.length) { this.$el.addClass('no-badge'); } else { this.$el.removeClass('no-badge'); }

		this.ui.counter.html(this.collection.length || '');
	},
	events: {
		click: 'toggleNotifies'
	},
	toggleNotifies () {
		const view = instances.notifies;
		if (view.viewFilter) {
			view.viewFilter = null;
			view.collection.invoke('set', { viewed: true });
			view.sort();
			// view.collection.sort();
		} else {
			view.viewFilter = view.notViewedFilter;
			view.sort();
			// view.collection.sort();
		}
	}
});
