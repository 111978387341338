import { PointLine } from './transportPoint';
import { BasePointsView } from './mixins';


export default BasePointsView.extend({
	className: 'transportpoints',
	childView: PointLine,
	_reinitCollection () {
		const points = this.model.get('typeDetails.points') || [];
		this.collection.reset(points);
	}
});
