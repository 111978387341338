import View from 'base/view';
import { ClickableBehavior } from 'behaviors';
import smartOpen from 'helpers/smart-open';
import { contragentPageBaseUrl } from 'mods/contragents';
import enums from 'helpers/enums';

// import user from 'app/user';
export const ContragentCard = View.extend({
	behaviors: [ClickableBehavior],
    // clickElementSelector: '.owner',
	// cssClassModifiers: [
	// 	m => user.isMe(m.get('responsible').id) ? 'my' : ''
  //           // busData.user().isMe(v.model.get('owner').id) ? 'my' : ''
	// ],
	className: 'ui2-block with-wrapper department-contragent clickable',
	template: `<div class="wrapper">
    <div class="ui2-molecule contragent">
				<div class="ui2-atom name">
					<i><%= roles %></i>
					<b><%= occupation %></b>
					<span><%= name %></span>
				</div>
    </div>
</div>`,
	templateContext () {
		const { roles, name, occupation } = this.model.attributes;
		return {
			roles: enums.get('contragentRoles', roles),
			name: name || '<small>[без названия]</small>',
			occupation: occupation || ''
		};
	},
	onClicked (event) {
		const options = { ctrlKey: !event.ctrlKey };
		const url = contragentPageBaseUrl + this.model.id;
		smartOpen(url, options);
	}
});
