import { _, $ } from 'vendors';
import Yat from 'marionette.yat';
import View from 'base/view';
import template from './item.html';
import Empty from './empty';

const ItemView = View.extend({
	className: 'ui-list-item',
	dynamicClassName () {
		const res = [];
		if (this.getOption('textAction')) {
			res.push('whole-btn');
		}
		if (this.getOption('preserveWhiteSpace')) {
			res.push('text-preserve-white-space');
		}

		const enabled = this.getOption('isEnabled');
		if (enabled === false) {
			res.push('disabled');
		}
		return res.join(' ');
	},
	template,
	defaultEvents () {
		const events = {
			'mousedown >*:not(button)' (ev) {
				if (ev.shiftKey) { ev.preventDefault(); }
			},
			'mousedown > button.selection' (ev) {
				ev.stopPropagation();
				if (ev.shiftKey) { ev.preventDefault(); }
			},
			'click >*:not(button)' (ev) {
				// ev.stopPropagation();
				this.triggerMethod('text:click', this.model, this, ev);
			},
			'click > button.close' (ev) {
				ev.stopPropagation();
				this.triggerMethod('close:click', this.model, this, ev);
			},
			'click > button.selection' (ev) {
				ev.stopPropagation();
				this.triggerMethod('selection:click', this.model, this, ev);
			},
			'click > button.plus' (ev) {
				ev.stopPropagation();
				this.triggerMethod('plus:click', this.model, this, ev);
			},
			'click > button.options' (ev) {
				ev.stopPropagation();
				this.triggerMethod('options:click', this.model, this, ev);
			},
			'click > button.info' (ev) {
				// ev.stopPropagation();
				this.triggerMethod('info:click', this.model, this, ev);
			},
			'click > button.edit' (ev) {
				ev.stopPropagation();
				this.triggerMethod('edit:click', this.model, this, ev);
			},
			'click > button[data-trigger]' (ev) {
				ev.stopPropagation();
				const $btn = $(ev.target).closest('button');
				const event = $btn.data('trigger');
				this.triggerMethod(event + ':click', this.model, this, ev);
			}
		};
		return events;
	},
	events () {
		const addEvents = this.getOption('addEvents');
		const defaultEvents = this.defaultEvents();
		return _.extend(defaultEvents, addEvents);
	},
	modelEvents () {
		if (!this.model) return;
		return {
			change: 'render'
		};
	},

	onTextClick () {
		const action = this.getOption('textAction', { args: [this.model, this] });
		const model = this.model;
		if (_.isString(action) && _.isFunction(model.executeAction)) { model.executeAction(action); }
	},

	getTopText () { return this.getOption('topText', { args: [this.model, this] }) || (this.model && this.model.get('topText')) || ''; },
	getBottomText () { return this.getOption('bottomText', { args: [this.model, this] }) || (this.model && this.model.get('bottomText')) || ''; },
	getSmallText () { return this.getOption('smallText', { args: [this.model, this] }) || (this.model && this.model.get('smallText')) || ''; },
	getText () {
		let result = this.getOption('text', { args: [this.model, this] });

		if (result == null && this.model) { result = Yat.Functions.common.getLabel(this.model); }

		return result;
	},
	getLeftButtons () {
		const btns = [].concat(this.getOption('leftButtons', { args: [this.model, this] }) || []);

		if (this.getOption('selectButton', { args: [this.model, this] })) { btns.unshift({ label: '<i></i>', class: 'selection' }); }
		if (this.getOption('optionsButton', { args: [this.model, this] })) { btns.unshift({ label: '<i></i>', class: 'options' }); }

		return this._createBtns(btns);
	},
	_getItems (name) {
		const items = this.getOption(name, { args: [this.model, this] }) || [];
		const json = this.model ? this.model.toJSON() : {};
		const html = _(items).map((item) => {
			let compiled = '';

			if (item == null) return compiled;

			if (_.isFunction(item)) {
				item = item.apply(this, this.model, this);
			}
			if (typeof item.template === 'string') {
				item.template = _.template(item.template);
			}
			if (
				_.isFunction(item.template) &&
				(!_.isFunction(item.condition) || item.condition(this.model))
			) {
				let tmpl;
				if (item.templateContext) {
					if (_.isFunction(item.templateContext)) {
						tmpl = item.templateContext.apply(this, [this.model, this]) || {};
					} else if (_.isObject(item.templateContext)) {
						tmpl = item.templateContext;
					}
				}
				const context = _.extend({}, json, tmpl);

				compiled = item.template(context);
			}
			return compiled;
		}).join('');
		return html;
	},
	getLeftItems () {
		return this._getItems('leftItems');
	},
	getRightItems () {
		return this._getItems('rightItems');
	},
	getRightButtons () {
		const btns = [].concat(this.getOption('rightButtons', { args: [this.model, this] }) || []);
		if (this.getOption('infoButton', { args: [this.model, this] })) { btns.push({ label: '<i></i>', class: 'info not-action' }); }
		if (this.getOption('editButton', { args: [this.model, this] })) { btns.push({ label: '<i></i>', class: 'edit' }); }
		if (this.getOption('plusButton', { args: [this.model, this] })) { btns.push({ label: '<i></i>', class: 'plus' }); }
		if (this.getOption('closeButton', { args: [this.model, this] })) { btns.push({ label: '<i></i>', class: 'close danger' }); }
		// console.log('***', btns, this.editButton, this.kookoo);
		return this._createBtns(btns);
	},
	templateContext () {
		return {
			_icon: this.getOption('icon') ? '<i><i></i></i>' : '',
			_top: this.getTopText(this.model, this),
			_bottom: this.getBottomText(this.model, this),
			_small: this.getSmallText(this.model, this),
			_text: this.getText(this.model, this),
			_leftBtns: this.getLeftButtons(this.model, this),
			_leftItems: this.getLeftItems(this.model, this),
			_rightItems: this.getRightItems(this.model, this),
			_rightBtns: this.getRightButtons(this.model, this),
			_itemMainTag: this.getOption('itemMainTag') || 'span',
			...(this.getOption('extendTemplateContext') || {})
		};
	},
	_btnTemplate: _.template('<button<%= attrs %>><%= label %></button>'),
	_createBtn (opts) {
		if (_.isFunction(opts)) {
			return this._createBtn(opts.call(this, this.model, this));
		}

		if (!opts) return '';

		if (_.isString(opts)) {
			opts = { class: opts };
		}

		opts = _.clone(opts);
		let label = opts.label;
		delete opts.label;
		if (opts.icon) {
			label = '<i></i>';
			delete opts.icon;
		}
		let attrs = _(opts).map((value, name) => `${name}="${_.escape(value)}"`).join(' ');
		if (attrs) attrs = ' ' + attrs;
		return this._btnTemplate({ label, attrs });
	},
	_createBtns (btns) {
		return _(btns).map((btn) => this._createBtn(btn)).join('');
	}
});

ItemView.Empty = Empty;

export default ItemView;
