import { _ } from 'vendors';
// import template from './menu.html';
import View from 'base/view';
import CollView from 'base/collection-view';
import cfg from '../config';
import appBus from 'bus/app';
import comparator from 'helpers/comparator';
import Collection from 'base/collection';
import Bb from 'backbone';
// import { simpleInvoke } from '../../helpers/invoke';

// const LinkView = View.extend({
// 	tagName: "a",
// 	template: _.template("<%= label %>"),
// 	attributes(){
// 		let m = this.model.toJSON();
// 		return {
// 			href: cfg.get('pageUrl', m.url),
// 			target: m.target || "_self"
// 		}
// 	},
// });

const LiLinkView = View.extend({
	className: 'item',
	tagName: 'li',
	template: _.template('<a href="<%- url %>" target=<%- target %>><%- label %></a><%if(hasChildren){%><ul></ul><%}%>'),
	cssClassModifiers: [
		(m) => m.get('active') === true ? 'active' : ''
	],
	regions: { children: { el: 'ul', replaceElement: true } },
	initialize () {
		// console.log(this.model);
		this._checkUrl();
		this.listenTo(Bb.history, 'route', this.onRouteChange);
	},
	onRouteChange (context, path) {
		if (typeof path !== 'string') {
			console.warn('path is not string', path);
			return;
		}
		if (path.endsWith('(/)')) path = path.substring(0, path.length - 3) + '/';
		const active = this.model.get('active');
		const url = this.model.get('url');
		if (active && url !== path) {
			this.model.set('active', false);
		} else if (!active && url === path) {
			this.model.set('active', true);
		}
	},
	_checkUrl () {
		const url = document.location.href.substring(document.location.origin.length);
		if (this.model.get('url') === url) { 
			this.model.set('active', true); 
		} else if (this.model.get('active')) {
			this.model.set('active', false);
		}
	},
	onRender () { this.showChildren(); },
	hasChildren () {

		if (!this.getOption('treeView')) {
			return false;
		}
		const hasChildren = this.collection.filter((f) => {
			if (f.get('parentId') !== this.model.id) {
				return false;
			}
			if (f.get('isUrlParametrized')) {
				return false;
			}
			return true;
		}).length > 0;
		return hasChildren;
	},
	showChildren () {
		if (!this.hasChildren()) return;
		const children = this.collection;
		const view = new UlLinksView({
			treeView: this.getProperty('treeView'),
			collection: children,
			viewFilter: (v) => v.model.get('parentId') === this.model.id,
			actionContext: this.getOption('actionContext')
		});
		this.showChildView('children', view);
	},

	templateContext () {
		let url = cfg.get('pageUrl', this.model.get('url'));
		if (this.model.get('url') == null) { url = 'javascript:'; }
		let label = this.model.get('label');
		if (_.isFunction(label)) {
			label = label();
		}
		return {
			hasChildren: this.hasChildren(),
			url,
			target: this.model.get('target'),
			label
		};
	},
	events: {
		'click' (e) {
			if (this.model.get('url') !== undefined) {
				return true;
			}
			e.preventDefault();
			e.stopPropagation();
			const ac = this.getOption('actionContext');
			const json = this.model.toJSON();
			ac && ac.executeAction && ac.executeAction(json, { placedAt: 'page' });
		}
	}
});

const UlLinksView = CollView.extend({
	className: 'division-links',
	tagName: 'ul',
	childView: LiLinkView,
	onAttach () {
		this.exposeNotEmpty();
	},
	exposeNotEmpty () {
		if (!this.getOption('addRegionClass')) return;
		const add = this.collection && this.collection.length > 0;
		const addClass = 'not-empty';
		if (add) { this.$el.parent().addClass(addClass); } else { this.$el.parent().removeClass(addClass); }
	},
	childViewOptions () {
		return {
			itemType: this.getOption('itemType'),
			collection: this.collection,
			treeView: this.getOption('treeView'),
			actionContext: this.getOption('actionContext')
		};
	},
	viewComparator: (v1, v2) => comparator(v1, v2, (m) => m.get('order') || 0)
});

const Division = View.extend({
	className: 'division',
	template: _.template('<header></header><section></section>'),
	regions: {
		header: 'header',
		content: 'section'
	},
	modelEvents: {
		'change' () {
			if (!this.model.getActions) return;
			this.collection.reset(this.model.getActions({ placedAt: 'page' }));
		}
	},
	onRender () {
		this.showHeader();
		this.showContent();
	},
	showHeader () {
		const view = new View({ template: _.template(this.getOption('label')) });
		this.showChildView('header', view);
	},
	showContent () {
		const actionContext = this.getOption('actionContext') || this.model.get('actionContext');
		const addRegionClass = this.getOption('addRegionClass');
		const name = this.getOption('regionName');
		const treeView = this.getOption('treeView');
		const collection = this.collection;
		const viewFilter = this.getOption('viewFilter', { force: false });

		const view = new UlLinksView({ name, addRegionClass, treeView, collection, viewFilter, actionContext, itemType: this.model.get('itemType') });
		this.showChildView('content', view);
	}
});

const divisionNames = {
	tree: 'карта сайта',
	actions: 'действия',
	pageactions: 'действия',
	children: 'подразделы',
	sibblings: 'смотрите также'
};



// const OldMenu = View.extend({

// 	className: 'menu-region on',
// 	tagName: 'section',
// 	template,

// 	initialize (opts) {
// 		this.mergeOptions(opts, ['app', 'page']);
// 		this.initApp();
// 	},
// 	initApp () {
// 		if (!this.app) return;
// 		this.listenTo(this.app, 'page:swap', this.onPageSwapped);
// 	},
// 	onPageSwapped () {
// 		this.destroy();
// 	},

// 	attributes: {
// 		'data-show': 'page'
// 	},
// 	regions: {
// 		tree: '.tree-region',
// 		actions: '.model-actions',
// 		pageactions: '.page-actions',
// 		children: '.page-children',
// 		sibblings: '.page-sibblings'
// 	},

// 	events: {
// 		'click .menu-switcher button' (e) {
// 			const $btn = $(e.target).closest('button');
// 			this.$el.attr('data-show', $btn.get(0).className);
// 		}
// 	},
// 	ui: {
// 		version: '.version'
// 	},
// 	onRender () {
// 		this.showTree();
// 		this.showModelActions();
// 		this.showPageActions();
// 		this.showPageChildren();
// 		this.showPageSibblings();
// 		this.showFavorites();

// 		this.ui.version.html(appBus.request('app:version'));
// 		this.$('.version').html(appBus.request('app:version'));
// 	},

// 	getLinks (opts = {}) {
// 		const col = appBus.request('menu', opts);
// 		return col;
// 	},

// 	getModelActions () {
// 		const page = this.page;
// 		const model = page && page.model;
// 		const col = model && model.getActions && model.getActions({ placedAt: 'page' });
// 		return new Collection(col || []);
// 	},

// 	getPageActions () {
// 		const page = this.page;
// 		// let model = page && page.model;
// 		const col = page.getActions && page.getActions({ placedAt: 'page' });
// 		return new Collection(col || []);
// 	},
// 	getChildrenLinksFilter () {
// 		const page = this.page;
// 		const link = page.getLink();
// 		return (view) => !!link && view.model.get('parentId') === link.id;
// 	},
// 	getSibblingsFilter () {
// 		const page = this.page;
// 		const link = page.getLink();
// 		return (view) => !!link && view.model.get('parentId') === link.parentId && view.model.id != link.id;
// 	},
// 	showFavorites () {},
// 	showTree () { 			this._showLinksList('tree', true, null, (view) => { return view.model.get('parentId') === 'root:page'; }); },
// 	showModelActions () { 	this._showLinksList('actions', false, this.getModelActions(), null, this.page && this.page.model); },
// 	showPageActions () { 	this._showLinksList('pageactions', false, this.getPageActions()); },
// 	showPageChildren () { 	this._showLinksList('children', false, null, this.getChildrenLinksFilter()); },
// 	showPageSibblings () { 	this._showLinksList('sibblings', false, null, this.getSibblingsFilter()); },
// 	_showLinksList (regionName, treeView, collection, viewFilter, model) {
// 		collection || (collection = this.getLinks());
// 		if (collection == null || !collection.length) return;

// 		if (viewFilter) {
// 			const data = collection.filter((model) => viewFilter({ model }));
// 			if (data.length === 0) return;
// 		}

// 		const actionContext = this.page.model || this.page;

// 		const addRegionClass = regionName !== 'tree';

// 		const view = new Division({
// 			label: divisionNames[regionName],
// 			regionName,
// 			addRegionClass,
// 			treeView,
// 			collection,
// 			viewFilter,
// 			actionContext,
// 			model
// 		});
// 		this.showChildView(regionName, view, { replaceElement: false });
// 	}
// });


const AppVersion = View.extend({
	// className: 'version',
	template: '<%= version %>',
	templateContext () {
		return {
			version: appBus.request('app:version')
		};
	}
});



const TabTriggerView = View.extend({
	tagName: 'button',
	template: '<i></i>',
	cssClassModifiers: [
		m => m.get('active') ? 'active' : ''
	],
	className () {
		return this.model.id;
	},
	events: {
		'click' () {
			if (this.model.get('active')) {
				return;
			}
			this.trigger('tab:click', this.model);
		}
	}
});

const TabsTriggersView = CollView.extend({
	childView: TabTriggerView,
	initialize () {
		this.collection = new Collection([
			{
				id: 'page'
			},
			{
				id: 'menu'
			},
			{
				id: 'favorites'
			}
		]);
	},
	childViewTriggers: {
		'tab:click': 'tab:click'
	}
});

const TabContentView = CollView.extend({
	childView: Division,
	cssClassModifiers: [
		(m, v) => 'tab-content-inner tab-content-' + (v.tab ? v.tab.id : 'empty')
	],
	getChildOptions (regionName) {
		return {
			label: divisionNames[regionName],
			regionName
		};
	},


	getModelActions () {
		const page = this.page;
		const model = page && page.model;
		const col = model && model.getActions && model.getActions({ placedAt: 'page' });
		return new Collection(col || []);
	},
	getModel () {
		return this.page && this.page.model;
	},
	getPageActions () {
		const page = this.page;
		const col = page.getActions && page.getActions({ placedAt: 'page' });
		return new Collection(col || []);
	},

	getPageLink () {
		return this.page && this.page.getLink();
	},
	buildChildModel (id, tab, hash) {
		const divisionOptions = Object.assign({}, tab.get('divisionOptions'), hash);
		const model = { id, tabId: tab.id, divisionOptions };
		return model;
	},
	buildChildrenModels (tab) {
		let models = [];
		switch (tab.id) {
		case 'menu':
			models = [
				this.buildChildModel('tree', tab, {
					treeView: true,
					collection: appBus.request('menu', {}),
					viewFilter: view => view.model.get('parentId') === 'root:page'
				})
			];
			break;
		case 'page':
			models = [
				this.buildChildModel('actions', tab, {
					treeView: false,
					itemType: 'action',
					actionContext: this.getModel(),
					collection: this.getModelActions()
				}),
				this.buildChildModel('pageactions', tab, {
					treeView: false,
					itemType: 'action',
					actionContext: this.page,
					collection: this.getPageActions()
				}),
				this.buildChildModel('children', tab, {
					treeView: false,
					collection: appBus.request('menu', {}),
					viewFilter: (view) => {
						const link = this.getPageLink();
						return !!link && view.model.get('parentId') === link.id;
					}
				}),
				this.buildChildModel('sibblings', tab, {
					treeView: false,
					collection: appBus.request('menu', {}),
					viewFilter: (view) => {
						const link = this.getPageLink();
						return !!link && view.model.get('parentId') === link.parentId && view.model.id !== link.id;
					}
				})
			];
			break;
		}

		return models.filter(m => {
			if (!(m && m.divisionOptions && m.divisionOptions.collection && m.divisionOptions.collection.length)) {
				return false;
			}
			if (m.divisionOptions.viewFilter) {
				return m.divisionOptions.collection.models.filter(model => m.divisionOptions.viewFilter({ model })).length > 0;
			}
			return true;
		});
	},
	initialize (options) {
		this.page = options.page;
		const tab = this.getOption('tab');
		if (tab == null) return;
		this.tab = tab;
		const models = this.buildChildrenModels(tab);
		this.collection = new Collection(models);
	},
	childViewOptions (child) {
		const divisionOptions = child.get('divisionOptions');

		const options = Object.assign({}, divisionOptions, this.getChildOptions(child.id));

		return options;
	}
});

const NewMenu = View.extend({
	className: 'menu-region on',
	template: '<div class="tabs-triggers"></div><div class="tab-content"></div><div class="app-version"></div>',
	regions: {
		tabs: '.tabs-triggers',
		content: '.tab-content',
		version: '.app-version'
	},
	initialize (opts) {
		this.mergeOptions(opts, ['app', 'page']);
		this.initApp();
	},
	initApp () {
		if (!this.app) return;
		this.listenTo(this.app, 'page:swap', this.onPageSwapped);
	},
	onPageSwapped () {
		this.destroy();
	},
	onRender () {
		const tabs = new TabsTriggersView({ className: 'menu-switcher' });
		tabs && this.showChildView('tabs', tabs, { replaceElement: true });
		const tab = tabs.collection.first();

		this.showTab(tab);

		const version = new AppVersion();
		version && this.showChildView('version', version);
	},
	childViewEvents: {
		'tab:click' (tab) {
			this.showTab(tab);
		}
	},
	showTab (tab) {
		if (!tab) return;
		const active = tab.collection.findWhere({ active: true });
		if (active) {
			active.set('active', false);
		}
		tab.set('active', true);
		const content = new TabContentView({ tab, page: this.page });
		content && this.showChildView('content', content);
	}

});


export default NewMenu;
