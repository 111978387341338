import { _ } from 'vendors';

import TasksCards from 'mod/tasks/views/cards';
import getRawFilters from 'mod/tasks/filters/get';
import entriesManager from './manager';

// import TasksManager from './manager';

import mix from 'helpers/mix';
import FDC from 'mixins/view/flex-dynamic-column';

import ViewManagedCollection from 'mixins/view-managed-collection';
import ScrollHandlerMixin from 'mixins/view/scroll-handler';
import FetchableMixin from 'mixins/collection-view/fetchable';
import FetchOnScrollMixin from 'mixins/collection-view/fetch-on-scroll';

const BaseView = mix(TasksCards).with(FDC, FetchableMixin, FetchOnScrollMixin, ViewManagedCollection, ScrollHandlerMixin);

const View = BaseView.extend({
	tagName: 'div',
	initialize () {
		this.afterInitialize();
	},
	fixMaxWidthOn: ['childview:childview:render:children'],
	afterInitialize () {},
	id: 'tasks-list',
	className: 'tasks-list',
	cssClassModifiers: [
		m => m.get('view') === 'listtable' ? 'fixed-manager' : ''
	],
	entriesManager,
	entriesManagerOptions: (v) => ({
		model: v.model,
		infoOptions: true,
		filtersOptions: {
			getRaw: getRawFilters,
			instantApply: true
		}
	}),
	onRender () {
		this.fetch();
	},

	modelIsVisible (m) {
		const isObserver = this.model.get('type') === 'observer' || this.model.get('type') === 'all';
		if (isObserver) { return true; }
		const notActive = this.model.get('state') === 'completed';
		const res = m.isVisible(notActive);
		return res;
	},
	modelIsBad (m) {
		const dl = m.getDate('remindAt');
		// m.getDeadline();
		const res = m.isNotClosed() && dl && dl < Date.now();
		return res;
	},
	modelInRange (m) {
		if (!_.isFunction(this.getDateRange)) return true;
		const range = this.getDateRange();
		if (!range) return true;
		const date = this.getModelDate(m);
		// m.getDisplayDate();
		return date && date >= range.from && date <= range.to;
	},
	modelCondition (m) {
		const type = this.model.get('type');
		switch (type) {
		case 'responsible':
			return m.isMy() || m.hasNoResponsible();
		case 'checker':
			return m.amIChecker(); // && m.isOnCheck();
		case 'creator':
			return m.amICreator();
		case 'observer':
		case 'all':
			return true;
		}
		return true;
	},
	modelInView (m, skipBad) {
		if (!m) return false;
		const notBad = (skipBad || !this.modelIsBad(m));
		const inView = this.modelIsVisible(m);
		const condition = this.modelCondition(m);
		const inRange = this.modelInRange(m);
		const res = notBad && inView && condition && inRange;
		// console.log('-- m --', res, m.attributes);
		// console.log(notBad, inView, condition, inRange);
		return res;
	},


	attributes () {
		return {
			'data-scrollable': 'tasks-list'
		};
	}
});

export default View;
