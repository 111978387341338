import BaseView from 'base/view';
import View from 'components/ui-card-block/block';
import Nested from './view';

const template = () => '<span><span class="empty-ui-list-item-text">без контрагента</span></span>';
const EmptyContragent = BaseView.extend({ template });


export default View.extend({
	header: { text: 'Контрагент', className: 'bg-main-dark' },
	getContragentModel () {
		const actionsModel = this.getActionsModel();
		return actionsModel.getContragent();
	},
	content () {
		const model = this.getContragentModel();
		if (!model) {
			return EmptyContragent;
		} else {
			return Nested;
		}
	},
	contentOptions () {
		const model = this.getContragentModel();
		return {
			model,
			collection: this.collection
		};
	},
	action: 'change:contragent:junction'
});
