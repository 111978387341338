export function splitComaString (text) {
	return text.split(/\s*,\s*/);
}

export function textArrayToHash (arr) {
	return arr.reduce((hash, value) => {
		hash[value] = 1;
		return hash;
	}, {});
}

export function getStringHash (text) {
	const arr = splitComaString(text);
	return textArrayToHash(arr);
}

export function textsHaveIntersections (text1, text2) {
	const h1 = getStringHash(text1);
	const h2 = getStringHash(text2);
	return Object.keys(h1).some(key => key in h2);
}

export function toFirstLetters (text, toUpper = true) {
	if (!text) { return text; }
	return text
		.split(/\s+/)
		.map(c => {
			let first = (c[0] || '');
			if (toUpper) {
				first = first.toUpperCase();
			}
			return first;
		})
		.filter(f => !!f)
		.join('');
}

export function stringValue (arg, defaultValue = '') {
	if (arg == null || arg === '') { return defaultValue; }
	return arg;
}

export function addPostfix (arg, postfix) {
	if (postfix == null || arg == null || arg === '' || arg === '&nbsp;' || arg === '&mdash;' || arg === '&ndash;') { return arg; }
	return arg + postfix;
}

export function addPrefix (arg, prefix) {
	if (prefix == null || arg == null || arg === '') { return arg; }
	return prefix + arg;
}

export function wrapValue (arg, prefix, postfix) {

	if ((prefix == null && postfix == null) || arg == null || arg === '') { return arg; }

	if (prefix != null) {
		arg = prefix + arg;
	}

	if (postfix != null) {
		arg = arg + postfix;
	}

	return arg;
}


function _xxxfix (val, type) {
	if (!val) { return ''; }
	return `<span class="value-${type}">${val}</span>`;
}

export function postfixFunc (val) {
	return _xxxfix(val, 'postfix');
}

export function prefixFunc (val) {
	return _xxxfix(val, 'prefix');
}


export const displayParty = (v, nov) => `<span class="batch-identifier">${v == null ? nov : v}</span>`;
