import MnObject from 'base/object';

import { bindEvents } from 'backbone.marionette';

export const BusService = MnObject.extend({
	constructor () {
		MnObject.apply(this, arguments);
		this.on('before:destroy', this.stop.bind(this));
	},
	start () {
		this.stop();
		const svc = this._service = this.getService();
		const events = this.getOption('events');
		bindEvents(this, svc, events);
	},
	getService () {
		return this.getOption('service');
	},
	stop () {
		if (this._service) {
			this.stopListening(this._service);
			delete this._service;
		}
	}
});
