import CollectionView from 'base/collection-view';
import Collection from 'base/collection';

import { ContragentCard } from './ContragentCard';
import { EmptyBlockView } from '../emptyBlock';

export const ContragentsList = CollectionView.extend({
	childView: ContragentCard,
	emptyView: EmptyBlockView,
	initialize () {
		const models = this.model.get('contragents') || [];
		this.collection = new Collection(models);
	}
});
