import View from 'base/view';
import template from './employee.html';
import PlaceholderedInput from 'components/controls/input-placeholdered';
import Email from 'components/controls/contact-value/email';
import ControlMixin from 'components/controls/mixin';
import selectDetachedUsers from 'mod/users/helpers/select-detached';
import Users from 'mod/users/models/collection';

function getInputOptions (proxyTo, controlName, label, type, name, selector, View) {
	return {
		skipModels: true,
		viewClass: View || PlaceholderedInput,
		viewOptions: {
			controlName,
			proxyTo,
			valueType: 'string',
			label,
			type,
			inputAttrs: {
				name
			}
		},
		regionTemplate: { el: selector, replaceElement: true }
	};
}

export default ControlMixin(View).extend({
	template,
	ignoreModel: true,
	initialize () {
		this.value = {
			name: {}
		};
		this.createNested('name-last', getInputOptions(this, 'name-last', 'фамилия', 'text', 'name.last', '.name-last'));
		this.createNested('name-first', getInputOptions(this, 'name-first', 'имя', 'text', 'name.first', '.name-first'));
		this.createNested('name-middle', getInputOptions(this, 'name-middle', 'отчество', 'text', 'name.middle', '.name-middle'));
		this.createNested('email', getInputOptions(this, 'email', 'email для отправки приглашения', 'email', 'email', '.email', Email));
	},
	onRender () {
		this.showAllNested();
		if (!this.ui.email.length) { this.ui.email = this.$('input[type=email]'); }
	},
	ui: {
		email: 'input[type=email]',
		selectUser: 'button.select-user'
	},
	validate () {
		const emp = this.getValue();
		const len = (emp.name.first || '') + (emp.name.last || '') + (emp.name.middle || '');
		let invalid = false;

		if (!len) { invalid = true; }

		if (!emp.email && this.ui.email.val()) { invalid = true; }

		// if(!emp.email && emp)
		// if(!invalid)
		this.triggerValid(invalid);

		return invalid;
	},
	triggerValid (invalid) {
		const type = invalid ? 'lock' : 'unlock';
		// if(invalid){
		// 	this.inModal && (this.inModal.resolve = this.getValue());
		// }else{
		// 	this.inModal && (this.inModal.resolve = undefined);
		// }
		this.trigger('switch:resolve', type, this.getValue());
	},
	triggerUserChange (user) {
		const val = this.getValue();
		const email = user ? user.get('email') : undefined;
		const userId = user ? user.id : undefined;
		this.ui.email.val(email);
		val.email = email;
		val.userId = userId;
		this.validate();
		this.triggerMethod('user:change', user);
	},
	controlsEvents: {
		'name-last:change' (val) {
			this.value.name.last = val;
			this.triggerChange();
		},
		'name-first:change' (val) {
			this.value.name.first = val;
			this.triggerChange();
		},
		'name-middle:change' (val) {
			this.value.name.middle = val;
			this.triggerChange();
		},
		'email:change' (val) {
			this.value.email = val;
			this.triggerChange();
		},
		'email:validate:error' () {
			const emp = this.getValue();
			emp.email = '';
			this.validate();
		}
	},
	events: {
		'click @ui.selectUser' () {
			const collection = new Users();
			selectDetachedUsers({ collection }).then((id) => {
				this.triggerUserChange(collection.get(id));
			});
		}
	}
});
