import { _ } from 'vendors';
export default Base => Base.extend({
	getUrl () {
		return _.result(this, 'url');
	},
	apiSend (url, options = {}) {
		const selfurl = this.getUrl();
		if (selfurl) {
			url = selfurl + (url ? '/' + url : '');
		}
		if (url) {
			options.url = url;
		}

		if (!options.method) {
			options.method = 'GET';
		}

		const method = options.method;

		if (!options.contentType) {
			options.contentType = 'application/json';
		}

		// if (method && method.toUpperCase() !== 'GET') {

		// }

		return this.sync(method, this, options);
	},
	apiPost (url, data, options = {}) {
		options.method = 'POST';
		if (data) {
			data = JSON.stringify(data);
		}
		options.data = data;

		return this.apiSend(url, options);
	},
	apiGet (url, options = {}) {
		options.method = 'GET';
		return this.apiSend(url, options);
	},
	apiDelete (url) {
		return this.apiSend(url, { method: 'DELETE' });
	}

});
