// import { Ui2AtomView } from '../ui2/atom';
import { UiMoleculeView } from '../ui2/molecule';
import editValue from 'bus/edit';

export const FilterItemView = UiMoleculeView.extend({
	proxyChildEvents: true,
	thisClassName: 'filter-item clickable',
	// initialize () {
	// 	this.on('all', e => console.log(e));
	// },
	renderOnModelChange: true,
	content: {
		topText: v => v.model.getLabel(),
		text: v => v.model.displayValue()
	},
	removeButton: true,
	events: {
		click () {
			this.editInModal();
		}
	},
	getEditOptions () {
		const schema = this.model.getSchema();
		const options = {
			...schema
		};
		if (schema.range) {
			options.modelType = 'range';
		}
		if (schema.valueType === 'datetime') {
			options.valueType = 'date';
		}
		return options;
	},
	editInModal () {
		const m = this.model;
		editValue.do({
			header: m.getLabel(),
			value: m.getValue(),
			applyLabel: 'ОК',
			resetButton: true,
			cancelLabel: 'отставить',
			resetLabel: 'сбросить',
			resetButtonCssClass: 'remove-button',
			...this.getEditOptions()
		}).then((value) => {
			console.log('value', value);
			m.setValue(value);
		}, (res) => {
			if (res.reason === 'reset') {
				m.setValue(undefined);
			}
		});
	},
	childViewEvents: {
		'remove:click' (btn, event) {
			event.preventDefault();
			event.stopPropagation();
			this.model.setValue(undefined);
		}
	}
});
