import { BaseCollectionView } from 'base/base-collection-view';
// import { ProcessesCollection } from '../models/collection';
// import childView from './ItemView';
import { buildApiUrl } from './utils';
import { CommonCollection } from './CommonCollection';

export const ListView = BaseCollectionView.extend({

	Collection: CommonCollection,
	// collectionAddUrl: 'processes/active',
	baseClassName: 'list-entries elastic',
	initialize () {
		this.selector = this.getOption('selector', true);
		this.createCollection();
		this.listenTo(this.selector, 'filter:change', () => {
			this.setFilter(this.selector.filter);
		});
	},

	_getCollectionUrl () {
		const empId = this.model.id;
		return buildApiUrl(empId, this.collectionAddUrl);
		// return _.result(this.model, 'url') + '/' + this.collectionAddUrl;
	},

	createCollection () {
		this.collection = new this.Collection();
		this.collection.url = this._getCollectionUrl();
		this.once('render', () => this.collection.fetch());
	},
	childViewOptions () {
		return {
			selector: this.selector,
			employeeId: this.model.id
		};
	},

	_isFilteredOut (view, index) {
		if (!this.viewFilter) {
			return false;
		} else {
			return !this.viewFilter(view, index);
		}
	},
	childViewEvents: {
		'select:click': '_select'
	},
	_select (childView, options = {}) {
		console.warn('# _select');
		const prevChildView = this._lastClickedView;
		this._lastClickedView = childView;

		const multiple = this.selector.isMultiple();

		const { shiftKey } = options;

		if (!multiple || !shiftKey || !prevChildView) {
			this.selector.select(childView, options);
		} else {

			const cnt = this.children;

			const lastIndex = cnt.findIndexByView(prevChildView);
			const thisIndex = cnt.findIndexByView(childView);

			const left = lastIndex > thisIndex ? thisIndex : lastIndex + 1;
			const right = lastIndex > thisIndex ? lastIndex - 1 : thisIndex;

			for (let i = left; i <= right; i++) {
				const view = cnt.findByIndex(i);
				if (view && !this._isFilteredOut(view, i)) {
					this.selector.select(view, options);
				}
			}

		}
	}

});
