import Collection from 'base/collection';

export const ContragentBatchesCollection = Collection.extend({
	getBatches (id) {
		this.ensureConcretes();
		const model = this.get(id);
		if (!model) return [];
		return model.get('batches') || [];
	},
	ensureConcretes () {
		if (this._concretesExtracted) { return; }

		const add = [];

		this.models.forEach(model => {
			if (!model.get('isAbstractGroup')) { return; }

			const concretes = model.get('concretes') || [];
			concretes.forEach(concrete => {
				concrete.batches = concrete.batches.map(
					batch => ({
						concreteName: concrete.name,
						...batch
					})
				);
			});
			add.push(...concretes);
		});

		this.add(add);

		this._concretesExtracted = true;

	}
});

ContragentBatchesCollection.create = function (process) {
	const contragentBatches = new this();
	contragentBatches.url = process.url() + '/production-components/contragent-batches';
	return contragentBatches;
};
