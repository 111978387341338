import { _ } from 'vendors';
import BasePage from 'pages/auth';
import Layout from './layout';
import Model from 'mod/productionProducts/models/model';
import { childrenMenu } from './children';

function buildChildPage (parent, item) {
	if (parent) {
		const parentIds = parent.ids || [];
		item.ids = [...parentIds, item.id];
	} else {
		item.ids = [item.id];
	}
	const layoutOptions = item.layoutOptions || {};

	return BasePage.extend({
		label: (p) => p.display && p.display('fullName'),
		route: item.id,
		skipMenu: true,
		Layout: item.Layout || Layout,
		layoutOptions: {
			menuItems: childrenMenu,
			...layoutOptions
		},
		wrapLayout: true,
		childId: item.id,
		childIds: item.ids,
		children: buildPageChildren(item),
		getChildRootPage () {
			let page = this;
			while (page) {
				const isroot = page.getOption('isChildRoot');
				if (isroot) {
					return page;
				}
				const parent = page.getParent();
				if (!parent) {
					return page;
				}
				page = parent;
			}
		},
		socketGroups: (m) => 'product:' + m.id,
		onBeforeStart (ac) {
			const model = new Model({ id: ac.args.productId });
			this.addModel(model, { fetch: true });
			if (item.beforeStart) {
				item.beforeStart.call(this, ac);
			}
		}
	});
}

export function buildPageChildren (parent) {
	const children = parent ? parent.children : childrenMenu;
	if (!children) return;
	const items = _.filter(children, i => i.id !== 'root');
	return _.map(items, buildChildPage.bind(null, parent));
}
