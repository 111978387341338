import model from './model';
import collection from './collection';

const factory = {
	model,
	collection
};



export default factory;
