import BaseLayout from '../base-layout';
import { StoreBlockLayout } from './store-layout';
import { HistoryBlockLayout } from './history-layout';
export const StoreLayout = BaseLayout.extend({
	storeContents () {
		return [
			new StoreBlockLayout({ model: this.model, ac: this.actionContext })
		];
	},
	logsContents () {
		return [
			new HistoryBlockLayout({ model: this.model, ac: this.actionContext })
		];
	}
});
