import { _ } from 'vendors';

import Yat from 'marionette.yat';
import ExtendedFetch from 'mixins/fetch-ext';
// import user from '../app/user';
import BackboneExtend from 'mixins/backbone-ext';
import mix from 'helpers/mix';
import Model from 'base/model';
import ResultModel from './collection-result-model';

const Base = mix(Yat.Collection).with(ExtendedFetch, BackboneExtend);
export default Base.extend({
	model: Model,
	reinitOnSvcChange: false,
	constructor (data, opts = {}) {
		this.cid = _.uniqueId('col');
		if (opts.populated) { this.populated = true; }

		if (opts.parent) {
			this.parent = opts.parent;
		}

		// if(opts.entity)
		// 	this.entity = opts.entity;

		this.result = new ResultModel();

		Base.apply(this, arguments);
		// if(this.reinitOnSvcChange){
		// 	this.listenTo(user, 'change:service', () => this.reinit());
		// }
	},
	// reinit(data){
	// 	this.reset(data,{reinit:true});
	// },
	updateResult (data) {
		this.result.set('returned', data.length);
	},
	isEndOfData () {
		return this.result.get('endOfData') === true;
	},
	getResultJSON () {
		return this.result.toJSON();
	},
	create (model, options = {}) {
		if (options.promise === false) {
			return Base.prototype.create.call(this, model, options);
		}

		const wait = options.wait !== false;
		const collection = this;
		model = this._prepareModel(model, options);
		options.model = model;

		return new Promise((resolve, reject) => {
			if (!model) return reject(false);
			if (!wait) {
				this.add(model, options);
			}
			const success = options.success;
			options.success = function (m, resp, callbackOpts) {
				if (wait) collection.add(m, callbackOpts);
				if (success) success.call(callbackOpts.context, m, resp, callbackOpts);
			};
			model.save(null, options).then(
				() => resolve(model),
				(xhr) => reject(xhr, model)
			);
		});
	}

});

