import BasePage from 'pages/auth';
import Layout from './layout';
import Model from 'mod/configs/models/model';
// import Create from './create';
// import List from './list';
// import Card from './group';
// import Layout from './layout';
// import Emps from './emps/emps';

const Page = BasePage.extend({
	moduleRights: { admin: 'view, manageSystemConfig' },
	id: 'admin/configs/smtp',
	name: 'smtp config management page',
	label: 'Управление конфигурацией smtp',
	route: 'smtp',
	icon: 'config smtp',
	onBeforeStart () {
		this.addModel(new Model({ id: 'smtpserver' }), { fetch: true });
	},
	Layout
});

export default Page;
