import { _ } from 'vendors';
import CollectionView from 'base/collection-view';
import ItemView from './item-view';
import itemMixin from './item-view-mixin';
export default CollectionView.extend({
	constructor (opts = {}) {
		const childView = opts.childView || ItemView;
		opts.childView = childView.SelectItemMixin ? childView : itemMixin(childView);

		CollectionView.apply(this, arguments);
	},
	initialize (opts) {
		this.mergeOptions(opts, ['selector']);
		this.listenTo(this.selector, 'select unselect', this._onSingleChange);
		this.listenTo(this.selector, 'changes', this._onMassChanges);

		// let childView = this.getOption('childView', {deep:false}) || ItemView;
		// this.chidlView = childView.SelectItemMixin ? childView : itemMixin(childView);
	},
	_onSingleChange (key, item) {
		item.model.trigger('change');
	},
	_onMassChanges (changes = {}) {
		_(changes.unselected).each((i) => i.model.trigger('change'));
		_(changes.selected).each((i) => i.model.trigger('change'));
	},
	// childView,
	childViewEvents: {
		'text:click' (model, view, e) {
			e.stopPropagation();
			this.itemClicked.apply(this, arguments);
		},
		'selection:click' (model, view, e) {
			e.stopPropagation();
			this.itemClicked.apply(this, arguments);
		}
	},
	buildChildView (model, View, options) {
		const mix = this.getOption('childViewMixin');
		options = Object.assign({ model, selector: this.selector }, options, mix);
		return new View(options);
	},
	// childViewOptions(){
	// 	return {
	// 		selector: this.selector,
	// 	};
	// },
	itemClicked (model, view, event) {
		this.selector.clicked(view, { event });
		this.trigger('change');
	}
});
