import Single from 'components/values/single';
export default Single.extend({
	initialize (data, opts = {}) {
		this.value = opts.value;
		this.month = opts.month;
	},
	getDay () { return this.get('value').getDay() || 7; },
	getDate () { return this.get('value').getDate(); },
	getMonth () { return this.get('value').getMonth(); },
	getYear () { return this.get('value').getFullYear(); },
	sameDay (date) {
		if (date == null) return false;
		if (typeof date === 'string') {
			date = new Date(date);
			if (isNaN(date.valueOf())) { return false; }
		}

		return date.getMonth() === this.getMonth() &&
		date.getFullYear() === this.getYear() &&
		date.getDate() === this.getDate();
	},
	more (date, check) {
		if (date == null) return true;
		check || (check = this.get('value'));
		return check >= date;
	},
	less (date, check) {
		if (date == null) return true;
		check || (check = this.get('value'));
		return check <= date;
	},
	inRange (from, to, date) {
		if (from == null && to == null) { return false; } else if (from != null && to != null) { return this.more(from, date) && this.less(to || from, date); } else {
			return this.sameDay(from || to);
		}
	},
	isCurrent () {
		return this.sameDay(new Date());
	},
	isSelected (date) {
		if (this.value.type === 'single') { return this.sameDay(this.value.get('value')); } else if (this.value.type === 'range') {
			return this.inRange(this.value.get('from'), this.value.get('to'), date);
		}
	},
	isOutOfMonth () {
		return this.getMonth() !== this.month.get('month');
	},
	isHoliday () {
		return this.getDay() > 5;
	},
	isLastSelected () {
		if (this.value.type === 'single') { return this.sameDay(this.value.get('value')); } else if (this.value.type === 'range') {
			return this.sameDay(this.value.get('to'));
		}
	}
});
