import BaseView from 'base/view';
import template from './controls.html';
export default BaseView.extend({
	className: 'c-dt-controls',
	template,
	triggers: {
		'click button:first-child': 'go:prev',
		'click button:last-child': 'go:next'
	},
	ui: {
		years: '.c-dt-c-years'
	},
	collectionEvents: {
		'update' () {
			this.ui.years.html(this.getLabel());
		}
	},
	getLabel () {
		return [
			this.collection.first().id,
			this.collection.last().id
		].join(' &mdash; ');
	},
	templateContext () {
		return {
			years: this.getLabel()
		};
	}
});
