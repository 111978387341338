import BasePage from 'pages/auth';
import PageErr from 'pages/error';
import Layout from './layout';
import Model from 'mod/employees/models/model';
import emps from 'mod/employees/singleton';
import { StaffPageBase } from './common';
import { MainLayoutView } from './main-layout';
import { RightsLayoutView } from './rights-layout';
import { ProcessesLayoutView, TasksLayoutView, CyclesLayoutView } from './transfer-layouts';

const _Page = BasePage.extend({
	id: 'staff/emps/card',
	name: 'staff employee page',
	label () { return 'Сотрудник: ' + ((this.model && this.model.display('name')) || ''); },
	route: ':id',
	skipMenu: true,
	Layout,
	wrapLayout: false,
	socketGroups: (m) => 'employee:' + m.id,
	preventStart: function (ac) {
		const prev = BasePage.prototype.preventStart.call(this, ac);
		if (prev) return prev;

		if (!ac) { return; }

		const emp = emps.get(ac.args.id);
		if (!emp) { return PageErr.NotFound('Сотрудник не найден', this.url()); }
	},
	onBeforeStart (ac) {
		const model = new Model({ id: ac.args.id });
		this.addModel(model, { fetch: true });
	}
});


const Page = StaffPageBase.extend({
	route: ':id',
	divisionRoot: true,
	layoutOptions: {
		content: MainLayoutView
	},
	children: [
		StaffPageBase.extend({
			route: 'rights',
			label: 'права',
			layoutOptions: {
				content: RightsLayoutView
			},
			moduleRights: { admin: 'view' }
		}),
		StaffPageBase.extend({
			route: 'tasks',
			label: 'задачи',
			moduleRights: { employees: 'transferTasks' },
			layoutOptions: {
				content: TasksLayoutView
			}
		}),
		StaffPageBase.extend({
			route: 'processes',
			label: 'процессы',
			moduleRights: { employees: 'transferProcesses' },
			layoutOptions: {
				content: ProcessesLayoutView
			}
		}),
		StaffPageBase.extend({
			route: 'prod-cycles',
			label: 'производственные циклы',
			moduleRights: { employees: 'transferProductionCycles' },
			layoutOptions: {
				content: CyclesLayoutView
			}
		})
	],

	label () {
		const m = this.model;
		if (m == null) {
			return 'сотрудник';
		}
		return m.getName();
	},
	menuName: (m, p) => {
		// console.warn('#staff args', m, p);
		if (m == null) {
			return 'сотрудник';
		}
		return m.getName();
	}
});

export default Page;
