import BasePage from 'pages/auth';
import Smtp from './smtp';
// import List from './list';
// import Card from './group';
// import Layout from './layout';
// import Emps from './emps/emps';

const Page = BasePage.extend({
	moduleRights: { admin: 'view, manageSystemConfig' },
	id: 'admin/configs',
	name: 'configs management page',
	label: 'Управление конфигурациями системы',
	route: 'configs',
	icon: 'config',
	children: [Smtp]
});

export default Page;
