import { ActivityListUiBlock, getLastFewActivityView } from 'mods/logs/views';
// import { getLastFewActivityView } from '../../../../modules/logs/views';

export function activityBlock (view) {
	view.createNested('activity', {
		viewClass: ActivityListUiBlock,
		viewOptions: {
			model: view.model,
			fileSvc: view.fileSvc,
			logConfig: {
				entities: ['task']
			},
			isCommentingEnabled: m => m.isCommentable()
		}
	});
}

export function activityContent(view) {
	return {
		class: ActivityListUiBlock,
		model: view.model,
		fileSvc: view.fileSvc,
		logConfig: {
			entities: ['task']
		},
		isCommentingEnabled: m => m.isCommentable()
	}
}

export function getLastFewActivity(opts = {}) {	
	return getLastFewActivityView({
		logConfig: {
			entities: ['task']
		},
		...opts
	});
}