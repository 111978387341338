import { isEmpty, measuredAmount } from 'utils';
import { _ } from 'vendors';


export function takeFirst (...args) {
	return _.find(args, v => !isEmpty(v));
}

export function getProductName (product) {
	if (arguments.length === 2) {
		return takeFirst(arguments[0], arguments[1]);
	} else {
		const hash = product.attributes ? product.attributes : product;
		const { shortName, fullName } = hash;
		return takeFirst(shortName, fullName);
	}
}

export function simpleAmount (sourceAmount, sourceMeasureId, ifEmpty = '&mdash;') {
	const options = {
		sourceAmount,
		sourceMeasureId,
		destinationMeasureId: sourceMeasureId,
		destinationUnitsInSourceUnit: 1,
		display: true,
		ifEmpty
	};
	return measuredAmount(options);
}
