import Collection from 'base/collection';
// import Model from 'base/model';
import { PartyModel, productStoreBatchesUrlPattern, contragentProductStoreBatchesUrlPattern } from './parties-model';
import paths from 'helpers/paths';
import modalError from 'helpers/modals/error';
import busModels from 'bus/models';

export const StoreCollection = Collection.extend({
	urlPattern: paths.urls.api(productStoreBatchesUrlPattern),
	model: PartyModel,
	addParty (data) {
		if (!data) return;
		return this.create(data, { merge: true, preloader: true, wait: true, url: this.url() + '/manual-add' })
			.then(() => {}, xhr => modalError('Не удалось добавить партию', xhr));
		// let exist = this.get(data.partyId);
		// if (exist) {
		// 	return this._extendParty(exist, data);
		// } else {
		// 	return this._createParty(data);
		// }
	},
	removeFromParty (data) {
		console.log('### removeFromParty >>', data, this);
	},
	_createParty (data) {
		const model = {
			id: data.partyId,
			amount: data.amount
		};
		this.add(model);
	},
	_extendParty (exist, data) {
		exist.addAmount(data.amount);
	}
});

busModels.reply('api:product:batches', (opts) => {
	console.log('#>', opts);
	const col = new Collection();
	col.urlPattern = paths.urls.api(productStoreBatchesUrlPattern);
	col.productId = opts.productId;
	if (opts.fetch) {
		col.fetch(opts.fetchOptions);
	}
	return col;
});
busModels.reply('api:contragent:product:batches', (opts) => {
	const col = new Collection();
	col.urlPattern = paths.urls.api(contragentProductStoreBatchesUrlPattern);
	col.productId = opts.productId;
	col.contragentId = opts.contragentId;
	if (opts.fetch) {
		col.fetch(opts.fetchOptions);
	}
	return col;
});

export function buildStoreCollection (model) {
	const models = [];
	const col = new StoreCollection(models, { parent: model });
	col.productId = model.id;
	return col;
}

export function getStoreCollection (model) {
	if (!model.storeCollection) {
		model.storeCollection = buildStoreCollection(model);
	}
	return model.storeCollection;
}

/*

function generate() {
	let chars = 'abcdefghijk';
	for(let x = 1; x < 50; x++) {
		let amount = Math.random() * 100 >> 0;
		let id = chars[(Math.random() * (chars.length - 1) >> 0)] +
		chars[(Math.random() * (chars.length - 1) >> 0)] +
		chars[(Math.random() * (chars.length - 1) >> 0)] + '-' +
		chars[(Math.random() * (chars.length - 1) >> 0)] +
		chars[(Math.random() * (chars.length - 1) >> 0)] + '-' + x;
		models.push({ id, amount });
	}
}

*/
