import List from './list';
import CardView from './card-item';
import RowView from './row-item';
import comparator from 'helpers/comparator';

export default List.extend({
	constructor () {
		List.apply(this, arguments);
		this.listenTo(this.collection, 'change:dates', this.sort);
	},
	childView: CardView,
	childViewOptions() {
		return {
			showAsRow: this.getOption('showTableRows', true)
		}
	},
	// () {
	// 	if (this.getOption('showTableRows', true)) {
	// 		return RowView;
	// 	}
	// 	return CardView;
	// },

	newInTop: true,
	comparatorKey: 'remindAt',

	getModelDate (m) {
		if (m.isClosed()) { return m.getDate('closed'); } else {
			const key = this.getOption('comparatorKey');
			return m.getDate(key);
		}
	},
	viewComparator (v1, v2) {
		const newInTop = !!this.getOption('newInTop');
		const comparatorKey = this.getOption('comparatorKey');
		if (newInTop) { return comparator([v2, v1, m => m.getDate(comparatorKey)]); } else { return comparator([v1, v2, m => m.getDate(comparatorKey)]); }
	}
});
