export const commonMixin = {
	getDefaultChildViewOptions () {
		return {
			propertySchema: this.getOption('propertySchema', true),
			modelSchema: this.getOption('modelSchema', true),
			model: this.model,
			collection: this.collection
		};

	},
	customViewOptions () {
		const defs = this.getDefaultChildViewOptions();
		return defs;
	}
};
