import viewClass from './block';
export default function create (context, model, condition) {
	// if(!_.isModel(model)) return;
	context.createNested('contragent', {
		skipModels: true,
		viewClass,
		condition,
		viewOptions: {
			model,
			collection: model && model.getContacts(),
			actionsModel: context.model,
			refreshOnActionsModelEvents: ['change:contragentId']
		}
	});
}
