import { _ } from 'vendors';
import '../api-config';
import Collection from 'base/collection';
import paths from 'helpers/paths';
import Model from 'base/card-model';
import mix from 'helpers/mix';
import EditableProperties from 'mixins/editable-properties';

import EntityWithLogs2 from 'mods/logs/entity-with-logs-2-mixin';
import busData from 'bus/data';
import enums from 'helpers/enums';
import action from 'helpers/action';
import BbStore from 'base/bb-store';
import bus from 'bus/models';
import { JoinedProductsCollection } from './joined-products';

import editValue from 'bus/edit';

import { AddJoined } from '../views/joined-products';
import { AddJoined as AddJoinedContragent } from '../views/joined-contragents';
import modalConfirm from 'helpers/modals/confirm';
import modalError from 'helpers/modals/error';
import { ProductContragentCollection } from './product-contragent';
import NestedEntitiesV2mixin from 'mixins/model/nested-entities-v2';
import { AddStoreParty, AddToStoreModel, RemoveFromParty } from '../views/store-addParty';
import { AddProduceCycle, AddProduceCycleProduct /*, EditProduceCycleProduct */ } from '../views/prod-cycle';
import { productApiSearchUrl, productPageCardUrl } from './constants';
import { selectContragentAsync } from '../../../../contragents/utils';
import { buildProductProductionApiAddProcessUrl } from '../../../../product-production';
// import { searchProductsCollection } from './collection';

// import busData from 'bus/data';

// import busModels from 'bus/models';

const YesNo = {
	true: 'Да',
	false: 'Нет'
};

const AbstractProduct = {
	true: 'Абстрактная группа продукции',
	false: 'Обычная продукция'
};

const NotNeedNegotiateOnPurchases = {
	true: 'Не требует согласования при закупке',
	false: 'Требует согласования при закупке'
}

const ModelProperties = {
	properties: {
		fullName: {
			display: {
				label: 'Полное название',
				title: 'Официальное полное название',
				ifEmpty: '&mdash;'
				// transform: (v,o,m) => v || (m && m.get('fullName'))
			}
		},
		shortName: {
			display: {
				label: 'короткое название',
				title: 'Официальное короткое название',
				ifEmpty: '&mdash;'
				// transform: (v,o,m) => v || (m && m.get('fullName'))
			}
		},
		innerName: {
			display: {
				label: 'Неформальное название',
				title: 'Неофициальное название для использования внутри компании',
				ifEmpty: '&mdash;'
				// transform: (v,o,m) => v || (m && m.get('fullName'))
			}
		},
		entityName: {
			display: {
				label: 'Системное название',
				title: 'Не может быть изменено, используется самой системой',
				ifEmpty: '&mdash;'
				// transform: (v,o,m) => v || (m && m.get('fullName'))
			}
		},
		typeId: {
			sourceValues: () => busData.productTypes(),
			controlType: 'select',
			display: {
				label: 'тип',
				transform: (v) => busData.productTypeName(v),
				ifEmpty: '&mdash;'
			}
		},
		unitMeasureId: {
			sourceValues: () => busData.measures(),
			controlType: 'select',
			display: {
				label: 'учётная единица измерения',
				transform: (v) => busData.measures(v, 'short')
			}
		},
		purchaseUnitMeasureId: {
			sourceValues: () => busData.measures(),
			controlType: 'select',
			display: {
				label: 'единица измерения закупки',
				transform: (v) => busData.measures(v, 'short')
			}
		},
		storeUnitMeasureId: {
			sourceValues: () => busData.measures(),
			controlType: 'select',
			display: {
				label: 'складская единица измерения',
				transform: (v) => busData.measures(v, 'short')
			}
		},
		baseUnitsInStoreUnit: {
			display: {
				label: 'количество учётных единиц в одной складской'
				// transform: (v) => busData.measures(v, 'one')
			},
			type: 'number'
		},
		origin: {
			sourceValues: () => enums.store.productOrigins,
			type: 'enum',
			multiple: true,
			display: {
				label: 'источник',
				transform: (v) => enums.get('productOrigins', v),
				ifEmpty: '&mdash;'
			}
		},
		purpose: {
			sourceValues: () => enums.store.productPurposes,
			type: 'enum',
			multiple: true,
			display: {
				label: 'назначение',
				transform: (v) => enums.get('productPurposes', v),
				ifEmpty: '&mdash;'
			}
		},
		note: {
			type: 'bigtext',
			display: {
				label: 'описание',
				preserveWhiteSpace: true
			}
		},
		storeNormalRemainder: {
			type: 'number',
			display: {
				label: 'неснижаемый остаток в учетных единицах'
			}
		},
		storeAverageDaysToPurchase: {
			type: 'number',
			display: {
				label: 'среднее количество дней закупки'
			}
		},
		storeAverageConsumptionPerDay: {
			type: 'number',
			display: {
				label: 'средний расход в день в учетных единицах'
			}
		},
		isArchived: {
			type: 'boolean',
			sourceValues: YesNo,
			display: {
				label: 'продукция в архиве',
				transform: (v) => YesNo[v || false]
			}
		},
		isDeleted: {
			type: 'boolean',
			sourceValues: YesNo,
			display: {
				label: 'продукция удалена',
				transform: (v) => YesNo[v || false]
			}
		},
		isAbstractGroup: {
			type: 'boolean',
			sourceValues: AbstractProduct,
			display: {
				label: 'абстрактная группа',
				transform: (v) => AbstractProduct[v || false]
			}
		},
		notNeedNegotiateOnPurchase: {
			type: 'boolean',
			sourceValues: NotNeedNegotiateOnPurchases,
			display: {
				label: 'согласование при закупке',
				transform: (v) => NotNeedNegotiateOnPurchases[v || false]
			}
		},

	}
};

const ModelActions = {
	actions: [
		// action('add:produced:from','добавить составляющую продукцию', { resourcesProduction: 'productsEdit' }),
		action('add:joined:contragent', 'прикрепить контрагента', { resourcesProduction: 'productsEdit' }),
		action('add:concrete:product', 'добавить продукцию в группу', { resourcesProduction: 'manageAbstractGroupProducts' }, { rule () { return this.get('isAbstractGroup') === true; } }),
		action('remove:concrete:product', 'убрать продукцию из группы', { resourcesProduction: 'manageAbstractGroupProducts' }, { rule () { return this.get('isAbstractGroup') === true; }, hidden: true }),
		action('archive', 'отправить в архив', { resourcesProduction: 'productsEdit' }, { rule () { return !this.get('isArchived'); } }),
		action('unarchive', 'вернуть из архива', { resourcesProduction: 'productsEdit' }, { rule () { return this.get('isArchived'); } }),
		action('delete', 'удалить', { admin: true, resourcesProduction: 'productsEdit' }, { rule () { return !this.get('isDeleted'); } }),
		action('undelete', 'отменить удаление', { admin: true, resourcesProduction: 'productsEdit' }, { rule () { return this.get('isDeleted'); } }),
		action('add:store:party', 'добавить партию', { productsStore: 'acceptProducts' }, { hidden: true }),
		action('withdraw:store:party', 'списать продукцию со склада', { productsStore: 'acceptProducts' }, { hidden: true }),
		action('add:produce:cycle', 'добавить цикл производства', { productProduction: 'editProduction' }, { hidden: true }),
		action('add:produce:cycle:product', 'добавить сырье в цикл производства', { productProduction: 'editProduction' }, { hidden: true }),
		action('add:produce:cycle:process', 'добавить производственный процесс', { productProduction: 'editProduction' }, { hidden: true }),
		action('edit:produce:cycle:product', 'редактировать сырье в цикле производства', { productProduction: 'editProduction' }, { hidden: true })
		// action('withdraw:store:party', 'изъять партию', { productsStore: 'Products' }, {  }),
		// action('edit:produced:from','изменение составляющей продукции', { resourcesProduction: 'productsEdit' }),
	],
	actionAddProducedFrom () {
		const collection = this.getJoinedProducts();
		editValue.do({
			header: 'Добавление состовляющей продукции',
			controlView: AddJoined,
			applyLabel: 'добавить',
			resetButton: false,
			cancelLabel: 'отставить'
		}).then(
			(data) => {
				collection.create(data, { preloader: true, wait: true }).then(
					() => {},
					(xhr) => modalError('неудалось добавить продукцию', xhr)
				);
			},
			() => {}
		);
	},
	actionAddJoinedContragent () {
		const collection = this.getJoinedContragents();
		editValue.do({
			header: 'Прикрепление контрагента',
			controlView: AddJoinedContragent,
			controlViewOptions: {
				type: 'contragent',
				parentModel: this,
				parentModelType: 'product'
			},
			applyLabel: 'добавить',
			resetButton: false,
			cancelLabel: 'отставить'
		}).then(
			(data) => {
				collection.create(data, { preloader: true, wait: true }).then(
					() => {},
					(xhr) => modalError('неудалось прикрепить контрагента', xhr)
				);
			},
			() => {}
		);
	},
	actionDelete () {
		modalConfirm('Подтвердите удаление').then(() => {
			return this.apiDelete().then(() => {
				this.set({
					isDeleted: true,
					id: null
				});
				this.id = undefined;
				setTimeout(() => this.destroy(), 2000);
			});
		});
	},
	actionUndelete () {
		modalConfirm('Подтвердите отмену удаления продукции').then(() => {
			return this.apiPost('undelete').then(data => this.set(data));
		});
	},
	actionArchive () {
		modalConfirm('Подтвердите отправку в архив').then(() => {
			return this.apiPost('archive').then(data => this.set(data));
		});
	},
	actionUnarchive () {
		modalConfirm('Подтвердите возвращение из архива').then(() => {
			return this.apiPost('unarchive').then(data => this.set(data));
		});
	},
	actionAddStoreParty (action, collection, partyModel) {
		const userIsAdmin = busData.user().isAdmin();
		if (this.get('origin') === 'production' && !userIsAdmin) {
			modalError('Нельзя добавить партию продукции в ручном режиме если источник продукции производство');
			return;
		}
		// console.log('arguments:', arguments);
		const unitMeasureId = this.get('unitMeasureId');
		const storeUnitMeasureId = this.get('storeUnitMeasureId');
		const baseUnitsInStoreUnit = this.get('baseUnitsInStoreUnit');

		if (!unitMeasureId || !storeUnitMeasureId || !(baseUnitsInStoreUnit > 0)) {
			modalError('Нельзя добавить партию продукции если не установлены учётная и складская единицы измерения и отношение одной к другой');
			return;
		}
		// let batchUnitsInStoreUnit = storeUnitMeasureId === unitMeasureId ? 1 : this.get('baseUnitsInStoreUnit');
		if (!collection) {
			console.warn('No collection defined');
		}

		const idString = (partyModel && partyModel.get('idString')) || undefined;
		const addModel = new AddToStoreModel({
			idString,
			transferMeasureId: storeUnitMeasureId,
			baseUnitsInTransferUnit: baseUnitsInStoreUnit,
			unitMeasureId,
			storeUnitMeasureId,
			baseUnitsInStoreUnit,
			productName: this.get('fullName')
		});

		// addModel.baseUnit = unitMeasureId;
		// addModel.storeUnit = storeUnitMeasureId;
		// addModel.baseUnitsInStoreUnit = baseUnitsInStoreUnit;
		// addModel.productName =

		editValue.do({
			header: 'Добавление партии',
			controlView: AddStoreParty,
			controlViewOptions: {
				model: addModel
			},
			applyLabel: 'добавить',
			resetButton: false,
			cancelLabel: 'отставить'
		}).then(
			(data) => {
				return collection.addParty(data);
			},
			(xhr) => {}
		).then(() => {}, xhr => {
			modalError('неудалось добавить', xhr);
		});
	},
	actionWithdrawStoreParty (action, collection, partyModel) {
		const userIsAdmin = busData.user().isAdmin();
		if (this.get('origin') === 'production' && !userIsAdmin) {
			modalError('Нельзя списать продукцию из партии в ручном режиме если источник продукции производство');
			return;
		}
		if (!partyModel) {
			modalError('Нельзя списать продукцию в ручном режиме если не указана партия продукции');
			return;
		}
		// console.log('arguments:', arguments);
		const unitMeasureId = this.get('unitMeasureId');
		const storeUnitMeasureId = this.get('storeUnitMeasureId');
		const baseUnitsInStoreUnit = this.get('baseUnitsInStoreUnit');

		if (!unitMeasureId || !storeUnitMeasureId || !(baseUnitsInStoreUnit > 0)) {
			modalError('Нельзя списать продукцию если не установлены учётная и складская единицы измерения и отношение одной к другой');
			return;
		}
		// let batchUnitsInStoreUnit = storeUnitMeasureId === unitMeasureId ? 1 : this.get('baseUnitsInStoreUnit');
		if (!collection) {
			console.warn('No collection defined');
		}
		const idString = (partyModel && partyModel.get('idString')) || undefined;
		editValue.do({
			header: 'Списание продукции',
			controlView: RemoveFromParty,
			controlViewOptions: {
				maxValue: partyModel.get('amount'),
				model: new AddToStoreModel({
					isWithdraw: true,
					idString,
					transferMeasureId: storeUnitMeasureId,
					baseUnitsInTransferUnit: baseUnitsInStoreUnit,
					unitMeasureId,
					storeUnitMeasureId,
					baseUnitsInStoreUnit,
					productName: this.get('fullName')
				})
			},
			applyLabel: 'списать',
			resetButton: false,
			cancelLabel: 'отставить'
		}).then(
			(data) => {
				return partyModel.removeAmount(data);
				// console.log('DATA', data, partyModel.get('amount'));
				// collection.removeFromParty(data);
				// collection.create(data, {preloader: true, wait:true}).then(
				// 	() => {},
				// 	(xhr) => modalError('неудалось добавить продукцию', xhr)
				// );
			},
			(xhr) => {}
		).then(() => {}, xhr => {
			modalError('неудалось списать', xhr);
		});
	},
	actionAddProduceCycle (action, collection) {
		// console.log('arguments:', arguments);
		if (!collection) {
			console.warn('No collection defined');
		}

		// let productId = this.id;
		// let collection = this.getJoinedProducts();

		editValue.do({
			header: 'Создание производственного цикла',
			controlView: AddProduceCycle,
			controlViewOptions: {
				product: this
				// model: new AddToStoreModel({ partyId: partyModel && partyModel.id || undefined })
			},
			applyLabel: 'добавить',
			resetButton: false,
			cancelLabel: 'отставить'
		}).then(
			(data) => {
				return collection.addCycle(data);
				// collection.create(data, {preloader: true, wait:true}).then(
				// 	() => {},
				// 	(xhr) => modalError('неудалось добавить продукцию', xhr)
				// );
			},
			() => {}
		).then(() => {}, xhr => {
			modalError('неудалось добавить цикл', xhr);
		});
	},
	actionAddProduceCycleProduct (action, collection, cycle) {
		if (!collection) {
			console.warn('No collection defined');
		}

		// let productId = this.id;
		// let collection = this.getJoinedProducts();

		editValue.do({
			header: 'Добавление сырья',
			controlView: AddProduceCycleProduct,
			controlViewOptions: {
				product: this,
				cycle,
				cycleProducts: collection
				// model: new AddToStoreModel({ partyId: partyModel && partyModel.id || undefined })
			},
			applyLabel: 'добавить',
			resetButton: false,
			cancelLabel: 'отставить'
		}).then(
			(data) => {
				collection.addProduct(data);
				// collection.create(data, {preloader: true, wait:true}).then(
				// 	() => {},
				// 	(xhr) => modalError('неудалось добавить продукцию', xhr)
				// );
			},
			() => {}
		);
	},
	async actionAddProduceCycleProcess (action, collection, cycle) {

		if (!collection) {
			console.warn('No collection defined');
		}

		const contragent = await selectContragentAsync();
		console.warn('# contragent # >>', contragent);
		if (!contragent) {
			return;
		}

		const urlPattern = buildProductProductionApiAddProcessUrl(this.id, cycle.id);

		const body = {
			productId: this.id,
			cycleId: cycle.id,
			contragentId: contragent.id
		};

		const saver = new Model();
		saver.urlPattern = urlPattern;

		const result = await saver.save(null, { attrs: body, method: 'POST' });
		console.warn('# result # >>', result);

		if (result) {
			collection.add(result);
			console.warn('# added # >>', collection);
		}

		// {productId:guid}/cycles/{cycleId:guid}/processes
		// editValue.do({
		// 	header: 'Добавление сырья',
		// 	controlView: AddProduceCycleProduct,
		// 	controlViewOptions: {
		// 		product: this,
		// 		cycle,
		// 		cycleProducts: collection
		// 		// model: new AddToStoreModel({ partyId: partyModel && partyModel.id || undefined })
		// 	},
		// 	applyLabel: 'добавить',
		// 	resetButton: false,
		// 	cancelLabel: 'отставить'
		// }).then(
		// 	(data) => {
		// 		collection.addProduct(data);
		// 	},
		// 	() => {}
		// );
	},
	actionEditProduceCycleProduct (action, model, cycle) {
		const hash = model.omit('id');
		hash.productId = model.id;

		editValue.do({
			header: 'Изменение сырья',
			controlView: AddProduceCycleProduct,
			controlViewOptions: {
				mode: 'edit',
				product: this,
				cycle,
				hash
				// model: new AddToStoreModel({ partyId: partyModel && partyModel.id || undefined })
			},
			applyLabel: 'сохранить',
			resetButton: true,
			resetLabel: 'удалить',
			cancelLabel: 'отставить'

		}).then(
			(data) => {
				// data = _.pick(data, 'productId');
				return model.save(data, { wait: true, preloader: true, method: 'PATCH' });
				// collection.addProduct(data);
				// collection.create(data, {preloader: true, wait:true}).then(
				// 	() => {},
				// 	(xhr) => modalError('неудалось добавить продукцию', xhr)
				// );
			},
			({ reason } = {}) => {
				if (reason === 'reset') {
					modalConfirm('подтвердите удаление').then(() => {
						return model.destroy({ wait: true, preloader: true });
					}).then(
						() => {},
						xhr => modalError('неудалось удалить компонент цикла', xhr)
					);
				}
				// console.log('RESET', rest);
			}
		).then(
			() => {},
			xhr => modalError('неудалось отредактировать компонент цикла', xhr)
		);
	}

};


const concretesMixin = {
	actionAddConcreteProduct () {
		const opts = {
			header: 'выберите продукцию',
			controlType: 'select-api',
			modelType: 'single',
			multiple: false,
			sourceValues: () => {
				const collection = new Collection([], { model: ProductModel });
				collection.urlPattern = productApiSearchUrl;
				return collection;
			},
			controlOptions: {
				fetchAtStart: false,
				apiSearch: true,
				addRequestData: {
					abstract: false,
					unitMeasureId: this.get('unitMeasureId')
				}
				// collection: searchProductsCollection
			}
		};
		return editValue.do(opts).then((value) => {
			const data = [value];
			const jsonData = JSON.stringify(data);
			const url = this.url() + '/concretes';
			const saver = new Model();
			saver.url = url;
			saver.save(null, {
				wait: true,
				data: jsonData,
				method: 'POST',
				contentType: 'application/json'
			}).then((data) => {
				this.setConcretes(data);
				console.warn('#val', value, data);
			});
		});
	},
	actionRemoveConcreteProduct (action, data) {

		if (data == null) { return; }
		const array = Array.isArray(data);

		modalConfirm('Подтвердите удаление').then(() => {
			const jsonData = array ? JSON.stringify(data) : null;
			const url = this.url() + '/concretes' + (!array ? '/' + data : '');
			const saver = new Model();
			saver.url = url;
			saver.save(null, {
				wait: true,
				data: jsonData,
				method: 'DELETE',
				contentType: 'application/json'
			}).then(() => {
				this.removeConcretes(array ? data : [data]);
			});
		});

	},
	setConcretes (data) {
		this.set('concretes', data);
		if (this.concretesCollection) {
			this.concretesCollection.reset(data);
		}
	},
	removeConcretes (ids) {
		const data = (this.get('concretes') || []).filter(item => ids.indexOf(item.id) === -1);
		this.setConcretes(data);
	}
};

const Base = mix(Model).with(EditableProperties, ModelProperties, ModelActions, NestedEntitiesV2mixin, EntityWithLogs2);

busData.reply('product:page:url', paths.url('res:production:products'));

export const ProductModel = Base.extend({

	...concretesMixin,
	entityName: 'product',
	cardUrlRoot: productPageCardUrl,
	urlRootPattern: paths.api('res:production:products'),
	isCommentable: () => false,
	mainInfoCanBeChanged () {
		return busData.user().checkRights({ resourcesProduction: 'productsEdit' });
	},
	concretesCanBeManuallyAdded () {
		const user = busData.user();
		// if (user.isAdmin()) return true;
		return user.checkRights({ resourcesProduction: 'manageAbstractGroupProducts' });
	},
	storeBatchCanBeManuallyAdded () {
		const user = busData.user();
		if (user.isAdmin()) return true;
		if (this.get('origin') === 'production') return false;
		return user.checkRights({ productsStore: 'acceptProducts' });
	},
	storeBatchCanBeManuallyRemoved () {
		const user = busData.user();
		if (user.isAdmin()) return true;
		return user.checkRights({ productsStore: 'editProducts' });
	},
	/*
	nestedCollections:[{
		name:'joinedProducts',
		Collection: JoinedProductsCollection,
	}, {
		name: 'joinedContragents',
		Collection: ProductContragentCollection.Contragent
	}],
	*/
	nestedEntities: {
		joinedProducts: JoinedProductsCollection,
		joinedContragents: ProductContragentCollection.Contragent
	},
	getJoinedProducts () {
		return this.entity('joinedProducts');
	},
	getJoinedContragents () {
		return this.entity('joinedContragents');
	},
	getName () {
		const { shortName, fullName } = this.attributes;
		return !shortName
			? (fullName || '')
			: shortName;
	},
	getLabel () {
		return this.display('fullName');
	},
	getStoreModel () {
		if (!this._storeModel) {
			const storeHash = _.extend({ id: this.id }, this.get('store'));
			this._storeModel = new ProductStore(storeHash);
		}
		return this._storeModel;
	}
});

const StoredModel = BbStore(ProductModel);
bus.reply('AppProduct', () => ProductModel);
bus.reply('products:model', () => {
	return StoredModel;
});
bus.reply('open:product:page', (id, opts) => {
	const model = new ProductModel({ id });
	return model.actionOpenCard(null, opts);
});
bus.reply('new:products:model', (data) => {
	const store = busData.request('products:store');
	const exist = store.get(data);
	// console.log('cnt lookup', data, exist, store);
	if (exist) return exist;
	const model = new StoredModel(data);
	model.once('change', () => console.log('CHANGE!', model.getLabel()));
	model.fetchIfNot();
	store.add(model);
	return model;
});


const StoreProperties = {
	properties: {
		currentAmount: {
			type: 'number',
			display: {
				label: 'количество на складе',
				ifEmpty: '&mdash;'
			}
		},
		normalRemainder: {
			type: 'number',
			display: {
				label: 'нормальный остаток',
				ifEmpty: '&mdash;'
			}
		},
		averageMonthConsumption: {
			type: 'number',
			display: {
				label: 'среднее месячное потребление',
				ifEmpty: '&mdash;'
			}
		},
		unitsInStoreUnit: {
			type: 'number',
			display: {
				label: 'количество учётных единиц в одной складской',
				ifEmpty: '&mdash;'
			}
		},
		storeUnitToUnitLoose: {
			type: 'number',
			display: {
				label: '% потери при переводе между учётной и складской ед. изм.',
				ifEmpty: '&mdash;'
			}
		},
		unitMeasureId: {
			sourceValues: () => busData.measures(),
			controlType: 'select',
			display: {
				label: 'складская единица измерения',
				transform: (v) => busData.measures(v, 'one'),
				ifEmpty: '&mdash;'
			}
		}
	}
};
const BaseProductStore = mix(Model).with(EditableProperties, StoreProperties, ModelActions);
const ProductStore = BaseProductStore.extend({
	urlRootPattern: paths.api('res:production:store'),
	isCommentable: () => false
});



export default StoredModel;
