import { _, $ } from 'vendors';
import Bb from 'backbone';
import Collection from './models/collection';
// import ResultModel from './models/result-model';
import Obj from 'base/object';
import isAnyPropertyContains from 'helpers/is-any-property-contains';
import compare from './compare';
import unFlat from 'helpers/unflat';
import store from 'helpers/store';

import { invokeValue } from 'utils';

export default Obj.extend({
	bindToQueryString: true,
	initialize (opts = {}) {

		this.mergeOptions(opts, ['id', 'rawData', 'view', 'entries']);
		let data;
		if (!this.rawData && 'getRaw' in this.options) {
			data = this.getOption('getRaw', true);
		} else {
			data = invokeValue(this.rawData, this, this);
		}
		this._rawData = data || [];
		// console.warn('= filters raw data =', this._rawData, this.options);
		// if (_.isFunction(opts.getRaw)) {
		// 	this._rawData = opts.getRaw();
		// } else {
		// 	this._rawData = opts.rawData || [];
		// }

		this.initCollection(this.getOption('collection'));
		this._takeStoredValues();
		this._takeQueryStringValues();
		this.initEntries();
	},
	getCollection () {
		if (this.collection) return this.collection;
		return this.initCollection();
	},
	get (arg) {
		return this.getCollection().get(arg);
	},
	initCollection (collection) {
		this.collection = collection || new Collection(this._rawData);

		this.initCollectionHandlers();

		return this.collection;
	},
	initCollectionHandlers () {
		const col = this.collection;
		this.listenTo(col, 'change', this.triggerFilterChange);
	},
	initEntries () {

		if (this.view) {
			console.warn('~~has view~~');
			this.originalFilter = this.view.viewFilter;
			this.originalComparator = this.view.viewComparator;
		}

		if (!this._applyListenersInitialized) {
			console.warn('~~ NO _applyListenersInitialized ~~');
			const method = this.entries.noApi ? this.applyNoApi : this.applyApi;
			this._applyMethod = method;
			this.on('apply', method);
			this._applyListenersInitialized = true;
		}

		if (this.entries.noApi) {
			console.warn('~~ NO API ~~');
			this.view._fixIsAttachedOnFirstRender = true;
			this.applyNoApi();
			this.listenTo(this.view, 'render:children', this.updateNoApiResult);
		} else {
			console.warn('~~ BACKEND API ~~');
			if (this._entriesListenersInitialized) { return; }
			this.listenTo(this.entries, 'change', (model, ...args) => {

				if (!model || !_.size(model.changed)) return;
				const data = this.toFilter();
				const result = this._dataFilter(model, data);
				if (!result && model.collection) {
					model.collection.remove(model);
				}
			});
			this._entriesListenersInitialized = true;
		}
	},
	triggerFilterChange () {
		this.triggerMethod('filter:change');
		if (this.getOption('instantApply')) {
			console.warn('instant apply');
			this.triggerApply();
		}
	},
	triggerApply () {
		this.triggerMethod('apply');
	},
	toUrl () {
		return this.collection.toUrl();
	},
	toFilter () {
		return this.collection.toFilter();
	},
	applyApi () {
		this._updateQueryString();
		this._storeValues();
		if (this.view) {
			if (!this.view.getOption('modernFetch', true)) {
				// const data = this.toFilter();
				// console.error('[data]', data);
				// this.view.fetch(data);
			// } else {
				this.view.fetch();
			}
		}
	},
	applyNoApi () {
		this._updateQueryString();
		this._storeValues();
		const data = this.toFilter();
		if (_.size(data) && this.view) {
			this.view.setFilter((v) => this.noApiFilter(v, data));
		} else {
			this.view.setFilter(this.originalFilter);
		}
		// this.view.sort();
	},
	noApiFilter (v, data) {
		if (typeof this.originalFilter === 'function') {
			const result = this.originalFilter(v);
			if (!result) return false;
		}
		
		if (!v.model) return true;
		
		console.log('-noapi-filter-');
		return this._dataFilter(v.model, data);
	},
	_dataFilter (model, data = {}) {
		// console.log('----');
		const result = this.collection.every(filterModel => {
			
			const valueId = filterModel.get('valueId');
			const values = data[valueId];
			if (valueId !== 'text' && (values === undefined || filterModel.get('noLocalFilter'))) return true;
			if (valueId === 'text') {

				if (values == null || values === '') { return true; }

				const textResult = isAnyPropertyContains(model, values);
					// (values && isAnyPropertyContains(model, values)) || true;
				console.warn('text', textResult);
				return textResult;
			}
			// debugger;
			const filter = filterModel.get('filter');
			if (_.isFunction(filter)) {
				return filter.call(filterModel, model, values);
			}
			if (model.has(valueId)) {
				const modelValue = model.get(valueId);
				const cfg = model.getPropertyConfig(valueId);
				const res = compare(modelValue, values, cfg);
				// console.log(valueId, modelValue, values, res);
				return res;
			} else if (model[valueId] != null) {
				const modelValue = _.result(model, valueId);
				const res = compare(modelValue, values);
				// console.log(valueId, modelValue, values, res);
				return res;
			} else {
				return false;
			}
		});
		//console.warn('# match result', true);
		return result;
		/*
		return _(data).every((values, field) => {

			if(values == null || (_.isArray(values) && values.length === 0))
				return true;

			if(field === 'text')
				return isAnyPropertyContains(model, values);
			else if(model.has(field)) {
				let modelValue = model.get(field);
				let cfg = model.getPropertyConfig(field);
				return compare(modelValue, values, cfg);
			}
			else if (model[field]){
				var modelValue = _.result(model, field);
				return compare(modelValue, values);
			} else {
				return false;
			}

		});
		*/
	},
	updateNoApiResult () {
		const children = this.view.getRenderedViews();
		this.entries.result.set('founded', children.length);
		if (this.view._fixIsAttachedOnFirstRender) {
			delete this.view._fixIsAttachedOnFirstRender;
		}
	},
	setView (view) {
		if (this.view) {
			this.stopListening(this.view);
			this.off('apply', this._applyMethod);
			delete this.view;
		}
		this.view = view;
		this.initEntries();
	},
	_takeQueryStringValues () {
		if (!this.getOption('bindToQueryString')) return;

		const search = Bb.history.location.search.substring(1);
		if (!search) return;

		const search2 = search.replace(/&/g, '","').replace(/=/g, '":"');

		const raw = JSON.parse('{"' + search2 + '"}', (key, value) => key === '' ? value : decodeURIComponent(value));
		const result = unFlat(raw);

		_.each(result, (value, _key) => {
			if (/\[\d+\]/.test(_key)) {
				const pattern = /([^[]+)\[(\d+)\]/;
				const key = (_key || '').replace(pattern, '$1');
				const index = parseInt((_key || '').replace(pattern, '$2'), 10);
				if (key in result) {
					result[key] = [result[key]];
				} else {
					result[key] = [];
				}
				result[key][index] = value;
				delete result[_key];
			}
		});

		_.each(result, (arr, key) => {
			if (!_.isArray(arr)) return;
			result[key] = arr.filter(f => !!f);
		});

		this._applyQueryStringObject(result);
	},
	_applyQueryStringObject (hash) {
		this.collection.applyQueryStringObject(hash);
	},
	_updateQueryString () {
		if (!this.getOption('bindToQueryString')) return;

		let fragment = Bb.history.getFragment(Bb.history.location.pathname);
		const currentSearch = Bb.history.location.search || '';
		const search = $.param(this.toUrl() || '');
		if (currentSearch.substring(1) !== search) {
			if (search) { fragment += '?' + search; }
			Bb.history.navigate(fragment, { trigger: false });
		}
	},
	_takeStoredValues () {
		if (!this.id) return;
		const stored = store.get('filters:' + this.id);
		if (stored) {
			this.collection.applyObject(stored);
		}
	},
	_storeValues () {
		if (!this.id) return;
		const data = this.toFilter();
		store.set('filters:' + this.id, data);
	},
	destroy () {
		this._isDestroyed = true;
		this.stopListening();
		this.off();
		delete this.rawData;
		delete this._rawData;
		delete this.view;
		delete this.entries;
		delete this.options;
	}
});
