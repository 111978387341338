import View from 'base/view';

const isActiveModifier = (m, v) => v.getOption('isActive') ? 'active' : undefined;
const isDisabledModifier = (m, v) => v.getOption('isDisabled') ? 'disabled' : undefined;

export const Ui2BaseButtonView = View.extend({
	constructor: function () {
		View.apply(this, arguments);
		this.addBaseCssClass(isActiveModifier);
		this.addBaseCssClass(isDisabledModifier);
		this.delegate('click', this._onClickDelegate.bind(this));
	},
	baseClassName: 'ui2-button',
	tagName: 'button',
	clickTriggerEvent: 'click',
	isActive () { },
	isDisabled () { },
	_onClickDelegate (event) {
		if (this.getOption('isDisabled')) {
			event.preventDefault();
			event.stopPropagation();
			return;
		}
		const triggerEvent = this.getOption('clickTriggerEvent');
		this.triggerMethod(triggerEvent, this, event);
	}
});
