import CardView from 'components/cards/card-view';

const View = CardView.extend({
	className: 'card page-link-card',
	triggers: { click: 'click' },
	attributes () {
		return {
			'data-t-url': this.model.get('url')
		};
	}
});

export default View;
