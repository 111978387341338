import busData from 'bus/data';


export const _meas = (v, which = 'short') => busData.measures(v, which);

export const GetInfoMixin = {
	getDefOptions () {
		return {
			process: this.getProcess(),
			batches: this.getBatches()
		};
	},
	getProcess () {
		return this.getOption('process');
	},
	getBatches () {
		return this.getOption('batches');
	},
	getRawCycle () {
		return this.getProcess().get('productionCycle');
	},
	getRawProduct () {
		return this.getProcess().get('product');
	}
};

export const recalcComponentValue = function (com, parentAmount) {
	const numValue = parseFloat(parentAmount, 10);
	if (parentAmount == null || isNaN(numValue) || numValue < 0) return;
	const amountPerOne = com.get('amount');
	const totalProduceAmount = numValue * amountPerOne;
	let totalAmountInBaseUnits = totalProduceAmount / com.get('produceUnitsInBaseUnit');
	if (com.get('modifyOnlyByInteger')) {
		totalAmountInBaseUnits = Math.ceil(totalAmountInBaseUnits);
	}
	return totalAmountInBaseUnits;
};
