import View from 'base/view';
import Model from 'base/model';
import Collection from 'base/collection';
import CollectionView from 'base/collection-view';
import user from 'app/user';

import modals from 'helpers/modals';

import { ClickableBehavior } from 'behaviors';
import smartOpen from 'helpers/smart-open';
import { productPageBaseUrl } from 'mods/resources/production/products/cfg';
import { _ } from 'vendors';

import modalError from 'helpers/modals/error';



import { ProcessCardView } from '../ProcessCardView';
import { getProductName, simpleAmount } from '../../utils';

const ActionButtonModel = Model.extend({
	invoke (key, ...args) {
		let value = this.get(key);
		if (typeof value === 'function') {
			value = value.call(this, ...args);
		}
		return value;
	}
});
const ActionButtonView = View.extend({
	tagName: 'button',
	className: 'product-action-button',
	template: '<%= text %>',
	cssClassModifiers: [
		(m) => m.get('actionId')
	],
	events: {
		click (event) {
			event.stopPropagation();
			event.preventDefault();
			const action = this.model.get('action');
			if (typeof action !== 'function') {
				console.warn('missing action for ', this.model.get('actionId'), this);
				return;
			}
			const data = this.getOption('departmentModel');
			const product = this.getOption('productModel');
			action.call(this.model, product, data);
		}
	}
});

function detectProcess (processes, product, department) {
	if (processes.length === 1) {
		return processes;
	}

	const myprocs = processes.filter(proc => {
		if (user.isMe(proc.owner.id)) {
			return true;
		}
		return proc.team.some(emp => user.isMe(emp.id));
	});

	return myprocs.length ? myprocs : processes;

}

function showProcess (prodProcs, product, department) {
	const foundProcesses = detectProcess(prodProcs, product, department);

	if (foundProcesses.length > 1) {
		console.log('>> SHOW MODAL', foundProcesses);

		const content = new CollectionView({
			collection: new Collection(foundProcesses),
			childView: ProcessCardView
		});

		modals.show({
			header: 'Выберите подходящий процесс',
			content,
			type: 'short'
		});
	} else {
		const proc = foundProcesses[0];
		smartOpen('/procs/' + proc.id, { ctrlKey: true });
	}
}

const productActionDefaults = {
	acceptAtStore: {
		isAvailable (product, department) {
			// if (product.id in department.productProcesses) {
			// }
			if (product.get('deepness') !== 'input') {
				return false;
			}
			return product.id in department.productMoveProcessesOutput;
		},
		defaults: {
			text: 'принять на склад',
			action (product, department) {
				const prodProcs = department.productMoveProcessesOutput[product.id];
				if (!prodProcs) {
					modalError('В системе пока нет производственного процеса, его необходимо создать');
					return;
				}
				showProcess(prodProcs, product, department);

			}
		}
	},
	produce: {
		isAvailable (product, department) {
			// if (product.id in department.productProcesses) {
			// }
			return product.get('deepness') !== 'input';
		},
		defaults: {
			text: 'произвести',
			// detectProcess (processes, product, department) {
			// 	if (processes.length === 1) {
			// 		return processes;
			// 	}

			// 	const myprocs = processes.filter(proc => {
			// 		if (user.isMe(proc.owner.id)) {
			// 			return true;
			// 		}
			// 		return proc.team.some(emp => user.isMe(emp.id));
			// 	});

			// 	return myprocs.length ? myprocs : processes;

			// },
			action (product, department) {
				const prodProcs = department.productProcesses[product.id];
				if (!prodProcs) {
					modalError('В системе пока нет производственного процеса, его необходимо создать');
					return;
				}
				showProcess(prodProcs, product, department);
			}
		}
	},
	moveToStore: {
		isAvailable (product, department) {
			// if (product.id in department.productProcesses) {
			// }
			if (product.get('deepness') !== 'output') {
				return false;
			}
			return product.id in department.productMoveProcesses;
		},
		defaults: {
			text: 'сдать на склад',
			action (product, department) {
				const prodProcs = department.productMoveProcesses[product.id];
				if (!prodProcs) {
					modalError('В системе пока нет производственного процеса, его необходимо создать');
					return;
				}
				showProcess(prodProcs, product, department);

			}
		}
	}

};

const ProductActionsView = CollectionView.extend({
	initialize () {
		this.initializeCollection();
	},
	initializeCollection () {
		const models = this.getActionsModels();
		this.collection = new Collection(models, { model: ActionButtonModel });
	},
	getActionsModels () {
		const department = this.getOption('departmentModel');
		const actions = _.reduce(productActionDefaults, (actions, actionObj, actionId) => {
			const model = Object.assign({}, actionObj.defaults, { actionId });
			if (!actionObj.isAvailable || actionObj.isAvailable(this.model, department)) {
				actions.push(model);
			}
			return actions;
		}, []);


		return actions;
	},
	childView: ActionButtonView,
	childViewOptions () {
		return {
			productModel: this.model,
			departmentModel: this.getOption('departmentModel')
		};
	}
});



export const ProductCard = View.extend({
	className: 'ui2-block with-wrapper production-department-product-card clickable',
	template: `<div class="wrapper">
<div class="ui2-molecule info">
    <div class="name"><%= name %></div>
</div>
<div class="ui2-molecule amounts">
    <div class="ui2-atom">
        <b>на складе</b>
        <span><%= storeAmountDisplay %></span>
    </div>
    <div class="ui2-atom">
        <b>у конт-тов</b>
        <span><%= contragentAmountDisplay %></span>
    </div>
    <div class="ui2-atom">
        <b>всего</b>
        <span><%= totalAmountDisplay %></span>
    </div>
</div>
<div class="ui2-molecule actions"></div>
</div>`,
	behaviors: [ClickableBehavior],
	clickElementSelector: '.wrapper',
	regions: {
		actions: '.actions'
	},
	cssClassModifiers: [
		(m, v) => m.get('deepness')
	],
	initialize () {

		if (!this.getOption('listenForFilterModel')) { return; }
		this.filterModel = this.getOption('filterModel');
		this.listenTo(this.filterModel, 'filter', () => {
			const passed = this.filterModel.filterView(this);
			this.trigger('filter:pass', passed);
		});
	},
	getName () {
		// const { shortName, fullName } = this.model.attributes;
		return getProductName(this.model);
		// return (shortName || '').trim() ? shortName : fullName;
	},
	templateContext () {
		const { contragentAmount, storeAmount, unitMeasureId } = this.model.attributes;
		const name = this.getName();
		const ifEmpty = '&mdash;';
		const contragentAmountDisplay = simpleAmount(contragentAmount, unitMeasureId, ifEmpty);
		const storeAmountDisplay = simpleAmount(storeAmount, unitMeasureId, ifEmpty);
		const totalAmountDisplay = simpleAmount(storeAmount + contragentAmount, unitMeasureId, ifEmpty);
		return {
			name,
			contragentAmountDisplay,
			storeAmountDisplay,
			totalAmountDisplay
		};
	},
	onClicked (event) {
		const options = { ctrlKey: !event.ctrlKey };

		const url = productPageBaseUrl + this.model.id;
		smartOpen(url, options);
	},
	onRender () {
		this.showActions();
	},
	showActions () {
		const view = this.buildActionsView();
		if (!view) { return; }
		this.showChildView('actions', view);
	},
	buildActionsView () {
		const departmentModel = this.getOption('departmentModel');
		return new ProductActionsView({ model: this.model, departmentModel });

	}
});
