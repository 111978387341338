import _ from 'underscore';
import $ from 'jquery';
import config from './config';
import Bb from 'backbone';
import Mn from 'backbone.marionette';
import YatView from './../../YatView.js';
import mix from './../../helpers/mix';
import OptionProperty from './../../mixins/get-option-property';
let template = _.template(
`<% if(show.bg) {%><div <%= css('bg') %> data-role="modal-bg"></div><% } %>
<div <%= css('contentWrapper') %> data-role="modal-content-wrapper">
	<div <%= css('box') %> data-role="modal-box">
		<% if(show.close) {%><button  <%= css('close') %> data-role="modal-close"><%= label('close') %></button><% } %>
		<% if(show.header) {%><header <%= css('header') %> data-role="modal-header"><%= header %></header><% } %>
		<div <%= css('content') %> data-role="modal-content"><%= text %></div>
		<% if(show.actions) {%>
		<div <%= css('actions') %> data-role="modal-actions">
			<% if(show.resolve) {%><button <%= css('resolve') %> data-role="modal-resolve"><%= label('resolve') %></button><% } %>
			<% if(show.reject) {%><button <%= css('reject') %> data-role="modal-reject"><%= label('reject') %></button><% } %>
		</div>
		<% } %>
	</div>
</div>
`);


const ModalDestroyBehavior = {
	destroy(opts = {}){
		
		if(!this.canBeClosed() && opts.force !== true) return;

		this._clearRefernceOnContent();

		return YatView.prototype.destroy.apply(this, arguments);

	},
	canBeClosed(){
		return this.getConfigValue('options','preventClose') !== true;
	},
	onBeforeDestroy(){ 
		if(this.shouldRemoveBodyCssClass){
			$('body').removeClass(this.shouldRemoveBodyCssClass);
		}
		this.trigger('reject', this.getProperty('reject'), true);
	},
	_onContentDestroy(){
		this.contentDestroyed = true;
		this.destroy({force:true});
	},
};

const ModalRenderMixin = {
	
	// instantRender: true,
	// renderOnReady: true,

	template: template,

	attributes:{
		'data-role':'modal-wrapper'
	},

	regions:{
		'content':'[data-role="modal-content"]'
	},

	onBeforeRender(){

		let cfg = this.getConfig();
		cfg.css.wrapper && this.$el.addClass(cfg.css.wrapper);
		
		this.$el.appendTo($('body'));
		let addBodyClass = this.getOption('addBodyCssClass');
		if(addBodyClass && !$('body').hasClass(addBodyClass)){
			this.shouldRemoveBodyCssClass = addBodyClass;
			$('body').addClass(addBodyClass);
		}
	},
	onRender(){

		this.showContent();		
		this.triggerMethod('after:render');
		//this.applyModifiers('after:render');
	},
	showContent(){
		this.content = this.getOption('content');

		if(!(this.content instanceof Bb.View)) return;
		
		this.initResolveBehavior();

		this._setReferenceOnContent();
		
		this.listenToOnce(this.content,'destroy', this._onContentDestroy);

		this.showChildView('content', this.content);
		
	},
	initResolveBehavior(){
		if(!this.getConfigValue('show','resolve'))
			return;

		if(!this.getOption('blockResolve'))
			return;

		this.ui.resolve.prop('disabled', true);
		this.listenTo(this.content,'switch:resolve',(how) => {
			//console.log('wow, switching resolve', how);
			if(how == 'unlock')
				this.ui.resolve.prop('disabled', false);
			else if(how == 'lock')
				this.ui.resolve.prop('disabled', true);
		});

	},
	templateContext(){
		let cfg = this.getConfig();
		return {
			css(name){
				return cfg.css[name] ? ` class="${cfg.css[name]}"` : '';
			},
			label(name){
				return cfg.labels[name] || '';
			},
			show : cfg.show,
			text: this.getOption('text'),
			header: this.getOption('header'),
		}
	}	

}

const ModalClickBehavior = {
	ui:{
		'bg': '[data-role="modal-bg"]',
		'contentWrapper': '[data-role="modal-content-wrapper"]',		
		'text':'[data-role="modal-content"]',
		'header':'[data-role="modal-header"]',
		'close':'[data-role="modal-close"]',
		'resolve':'[data-role="modal-resolve"]',
		'reject':'[data-role="modal-reject"]',
		'box':'[data-role="modal-box"]',
	},

	triggers:{
		'click @ui.close':{ event: 'click:close', stopPropagation: true },
		'click @ui.reject':{ event: 'click:reject', stopPropagation: true },
		'click @ui.resolve':{ event: 'click:resolve', stopPropagation: true },
		// 'click @ui.text':{ event: 'click:content', stopPropagation: true },
		// 'click @ui.contentWrapper': { event: 'click:content:wrapper', stopPropagation: true },
		// 'click @ui.box':{ event: 'click:box', stopPropagation: true },
		'click @ui.bg':{ event: 'click:bg', stopPropagation: true },
		//'click': { event: 'click:wrapper', stopPropagation: true },
	},
	events:{
		'click @ui.contentWrapper'(e){
			this._insideClickEvent = e.originalEvent;
		},
		'click'(e){

			if(this._insideClickEvent == e.originalEvent){

				return;
			}

			this.trigger('click:wrapper');
		},
	},
	onClickClose(){ 
		console.error('close click');
		this.destroy(); 
	},

	onClickResolve(){ 
		const val = this.getProperty('resolve');
		console.error('onClickResolve', val);
		this.trigger('resolve', val);
	},
	onClickReject(){ 
		const val = this.getProperty('reject');
		console.error('onClickReject', val);
		
		this.trigger('reject', val);
	},
	onClickContentWrapper(){  },
	onClickBox(){  },
	onClickBg(){ 
		console.log('onClickBg');
		this.clickedOutsideOfModal(); 
	},
	onClickWrapper(){ 
		console.log('onClickWrapper');
		this.clickedOutsideOfModal(); 
	},

	clickedOutsideOfModal(){
		console.error('outside click');
		
		//console.log('click outside', arguments);
		if(this.getConfigValue('options','closeOnClickOutside') !== true) {
			return;
		}
		this.destroy();

	},
	
};

const ModalView = mix(YatView).with(OptionProperty, ModalDestroyBehavior, ModalRenderMixin, ModalClickBehavior).extend({

	initialize(options){
		this.mergeOptions(options, ['header', 'text']);	
		this._initModifiers();
		this._initPromise();
		//setTimeout(() => this.triggerRender(),20);
	},
	triggerRender(){
		this.render();
	},
	_initPromise(){
		let asPromise = this.getConfigValue('options','asPromise') == true;		
		if(asPromise){
			this.promise = new Promise((resolve, reject) => {
				this.once('resolve',(arg) => {
					// console.warn('resolving:', arg);
					resolve(arg)
				});
				this.once('reject',(arg) => {
					// console.warn('rejecting:', arg);
					reject(arg);
				});
			});
		}
		this.once('resolve reject',(arg, destroying) => {
			this.preventClose = false;
			// console.warn('resolve-reject`ing:', arg, destroying);
			if(this.getConfigValue('options','closeOnPromise') && !destroying){
				this.destroy();
			}			
		});
	},
	_initModifiers(){
		let eventsModifiers = this.getConfigValue('modifiers');
		_(eventsModifiers).each((modifiers, eventName) => {
			this.on(eventName, () => {
				_(modifiers).each((mod) => _.isFunction(mod) && mod.call(this));		
			});
		});
	},

	_setReferenceOnContent(){
		if(!(this.content instanceof Bb.View)) return;
		this.content.inModal = this;
		this.content.triggerMethod('before:modal:in', this);
		if (this.content.isRendered()) {
			this.contentInvulnerable = true;
			this.content.triggerMethod('modal:in');
		}
		else
			this.content.once('render',() => this.content.triggerMethod('modal:in'));

	},
	_clearRefernceOnContent(){

		if(!(this.content instanceof Bb.View)) return;
		if(this.contentDestroyed) return;

		delete this.content.inModal;
		this.content.triggerMethod('modal:out');
		if(this.contentInvulnerable)
			this.detachChildView('content');

	},

	_getModalOptions(){
		let h = {};
		if(this.getOption('closeOnClickOutside') != null)
			h.closeOnClickOutside = this.getOption('closeOnClickOutside');
		if(this.getOption('closeOnPromise') != null)
			h.closeOnPromise = this.getOption('closeOnPromise');
		if(this.getOption('preventClose') != null)
			h.preventClose = this.getOption('preventClose');
		if(this.getOption('asPromise') != null)
			h.asPromise = this.getOption('asPromise');

		return h;
	},
	getConfigValue(section, name){
		let cfg = this.getConfig() || {};
		if(section == null) return;
		if(name == null)
			return cfg[section];

		return (cfg[section] || {})[name];
	},
	getConfig(key){		
		if(this.config) return this.config;

		let typeName = this.getOption('type') || 'simple';
		let type = _.extend({}, config.get('types.' + typeName) || {});

		type.show = _.extend({}, config.get('dafaultShow'), type.show, this.getOption('show'));
		type.labels = _.extend({}, config.get('defaultLabels'), type.labels, this.getOption('labels'));
		type.css= _.extend({}, config.get('defaultCss'), type.css, this.getOption('css'));
		type.modifiers = _.extend({}, config.get('defaultModifiers'), type.modifiers, this.getOption('modifiers'));
		type.options = _.extend({}, config.get('defaultOptions'), type.options, this._getModalOptions());

		if(type.show.header == null && this.getOption('header'))
			type.show.header = true;
		
		if(type.show.resolve == null && (this.getOption('resolve') || type.options.asPromise))
			type.show.resolve = true;
		if(type.show.reject == null && this.getOption('reject'))
			type.show.reject = true;

		if(type.show.actions == null && (type.show.resolve || type.show.reject))
			type.show.actions = true;

		//Console.log('modal type', type);

		return this.config = type;
	},
	childViewTriggers:{
		'refresh':'refresh',
		'resolve':'resolve',
		'reject':'reject'
	}
});

export default ModalView;
