import BasePage from 'pages/auth';
// import Create from './create';
import Layout from './layout';
import deps from 'mod/departments/singleton';
import Dep from './dep';
import mix from 'helpers/mix';
import Actionable from 'mixins/actionable';
import action from 'helpers/action';

const Page = mix(BasePage).with(Actionable).extend({
	moduleRights: { departments: 'view' },
	id: 'staff/deps',
	name: 'departments list',
	label: 'Отделы',
	route: 'deps',
	Layout,
	children: [Dep],
	icon: 'staff-deps',
	socketGroups: () => 'departments',
	actions: [
		action('add:department', 'добавить отдел', { departments: 'createDelete' })
	],
	actionAddDepartment () {
		deps.tryAddDepartment();
	}
});

export default Page;
