import { moment } from 'vendors';
import Base from './base';
import between from '../between';
export default Base.extend({
	groups () {
		const isActive = this.model.get('state') === 'active';
		const range = this.getDateRange();
		const start = Date.toWeek(range.from);
		const groups = [];
		const now = new Date();
		for (let x = 0; x < 7; x++) {
			let from = Date.toDay(start);
			from.addDays(x);
			const to = Date.toDay(from);
			to.setHours(23, 59, 59, 999);

			if (isActive && from < now) { from = now; }

			this.createGroup(to, {
				date: from,
				header: moment(from).format('dddd, Do MMM'),
				testModel: (m) => between(this.getModelDate(m), from, to),
				isInPast: to <= now,
				_debug: x === 0
			}, groups);
		}
		return groups;
	}
});
