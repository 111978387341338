import { _ } from 'vendors';
import CollectionView from './collection-view';
import BaseView from './view';
import SearchControl from 'components/controls/search';
import isAnyPropertyContains from 'helpers/is-any-property-contains';

export default BaseView.extend({
	constructor () {
		BaseView.apply(this, arguments);
		const api = this.getOption('apiSearch');
		this.search = api ? this._apiSearch : this._localSearch;
	},
	regions: {
		segments: { el: '.search-segments', replaceElement: true },
		search: { el: '.search-region', replaceElement: true },
		entries: { el: '.entries-region', replaceElement: true }
	},
	template: _.template('<div class="search-segments"></div><div class="search-region"></div><div class="entries-region"></div>'),
	onRender () {
		this.showSegments();
		this.showEntries();
		this.showSearch();
	},
	showSegments () {
		const segmentation = this.getOption('segmentation');
		if (!segmentation) {
			// todo: implement no segmentation logic
		}
	},
	showEntries () {
		const view = this.entriesView = this.buildEntriesView();
		this.showChildView('entries', view);
		this.triggerMethod('search:ready');
	},
	buildEntriesView () {
		const View = this.getOption('collectionView') || CollectionView;
		const options = this.buildEntriesOptions();
		const entries = new View(options);
		return entries;
	},
	buildEntriesOptions () {
		const hash = {
			collection: this.getOption('collection', { force: false }),
			childView: this.getOption('childView', { force: false }),
			emptyView: this.getOption('emptyView', { force: false }),
			tagName: this.getOption('collectionTagName', { force: false }),
			className: this.getOption('collectionClassName', { force: false }),
			viewComparator: this.getOption('viewComparator', { force: false }),
			viewFilter: this.getOption('viewFilter', { force: false }),
			childViewOptions: this.getOption('childViewOptions', { force: false })
		};

		const colViewOpts = this.getOption('collectionViewOptions');

		const options = _.extend({}, hash, colViewOpts);

		_(options).each((v, n) => v == null && (delete options[n]));

		return options;
	},
	showSearch () {
		const col = this.getOption('collection');
		if (this.getOption('searchDisabled')) {
			return;
		}
		if (this.getOption('showSearchAlways') !== true && (!col || col.length < 10)) {
			if (this.isRendered() && this.searchView) {
				const region = this.getRegion('search');
				region.empty();
			}
		} else {
			const view = this.searchView = new SearchControl({
				controlName: 'search',
				proxyTo: this,
				applyOnChange: this.getOption('shouldSearchOnType')
			});
			this.showChildView('search', view);
		}
	},
	collectionEvents: {
		'change add remove update': 'showSearch'
	},
	controlsEvents: {
		'search:change' (txt) {
			this.search(txt);
		}
	},
	search: function () {},
	_localSearch (txt) {
		const cntx = this.entriesView;
		const oldFV = cntx.viewFilter;
		const FV = _.isFunction(oldFV) ? oldFV : () => true;

		cntx.viewFilter = function (v) {
			const res = FV.apply(cntx, arguments);
			if (!res) return false;
			return v.model && isAnyPropertyContains(v.model, txt);
		};
		cntx.searchApplied = true;
		cntx.sort();
		cntx.viewFilter = oldFV;
	},
	_apiSearch (txt) {

		const data = Object.assign({ text: txt }, this.getOption('addRequestData', true));
		const serializedData = Object.keys(data).reduce((items, key) => {
			let value = data[key];
			if (value) {
				value = encodeURIComponent(value);
			}
			items.push(`${key}=${value}`);
			return items;
		}, []).join('&');

		this.collection.fetch({ data: serializedData });
	},
	setViewFilter (filter, opts = {}) {
		this.entriesView && (this.entriesView.viewFilter = filter);
		!opts.silent && this.entriesView.sort();
	},
	sort () {
		this.entriesView && this.entriesView.sort();
	}

});
