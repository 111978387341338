import { _ } from 'vendors';
import View from 'base/view';
import CollectionView from 'base/collection-view';

const childView = View.extend({
	tagName () {
		return this.isTextField() ? 'input' : 'button';
	},
	className: 'item',
	isTextField () {
		return !!this.model.get('textField');
	},
	cssClassModifiers: [
		m => m.id, m => m.get('label') ? 'labeled' : '',
		m => m.get('active') ? 'active' : '',
		(m, v) => v.isTextField() ? 'search-text' : ''
	],
	getTemplate () {
		if (this.isTextField()) {
			return _.template('');
		}
		return _.template('<i><%= label %></i>');
	},
	templateContext () {
		return {
			label: this.model.get('label')
		};
	},
	onRender () {
		this.$el.attr('title', this.model.get('title') || '');
	},
	events: {
		'click' () {
			if (this.isTextField() || this.model.get('active')) return;
			this.model.makeActive();
		},
		'keyup' (e) {
			if (!this.isTextField()) return;
			if (e.keyCode === 13) {
				this.model.set('value', this.el.value);
				this.model.collection.parent.trigger('search-text');
				console.log('OK, man');
			}
		}
	}
});

export const ActivityListFilters = CollectionView.extend({
	tagName: 'header',
	className: 'activity-list-filters',
	initialize () {
		this.collection = this.model.entity('items');
	},
	childView
});
