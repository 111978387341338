import { _ } from 'vendors';
import { bus } from './bus';
import UiListItem from 'components/ui-list/item';

export const ConfirmationView = UiListItem.extend({
	text: 'все верно',
	addClass: 'all-confirmed',
	rightItems: [
		{
			template: '<div class="checkbox-holder"><input type="checkbox"></div>'
		}
	],
	ui: {
		inp: 'input'
	},
	events: {
		'click' (e) {
			if (e.target === this.ui.inp.get(0)) {
				e.preventDefault();
				_.defer(() => this.toggleInput());
			} else {
				this.toggleInput();
			}
		}
	},
	initialize () {
		this.listenTo(bus, 'unconfirmed:change', () => {
			if (!this.isRendered()) return;
			this.toggleInput(false);
		});
	},
	toggleInput (value) {
		if (value == null) {
			value = !this.ui.inp.prop('checked');
		}
		this.ui.inp.prop('checked', value);
		if (value) {
			bus.confirm();
		}
	}
});
