import { _ } from 'vendors';
import { View } from 'base/views';
import { stateMixin } from '../../../mixins/view/state';

const ClassOptions = ['initializeClickHandler', 'clickAction', 'canBeEnabled', 'canBeDisabled'];



export const ButtonView = View.extend({

	constructor: function (options) {

		this.mergeOptions(options, ClassOptions);

		View.apply(this, arguments);

		this.buttonName = this.getOption('buttonName', true);

		_.result(this, 'initializeClickHandler');

		this.on('before:render', this._updateDisabledState);
		// this.on('all', e => console.error('==> btn', e));
	},
	...stateMixin,
	tagName: 'button',
	template: '<%= html %>',
	stateful: true,
	stateClassNames: ['waiting'],

	initializeClickHandler () {
		this.delegate('click', this._clickHandler.bind(this));
	},

	_updateDisabledState () {
		if (this.isRendered()) {
			const isDisabled = this.state('disabled');
			const shouldBeEnabled = this._canBe('Enabled', true);
			if (isDisabled && shouldBeEnabled) {
				this._enable();
			} else if (!isDisabled && !shouldBeEnabled) {
				this._disable();
			}
		} else {
            // first render
			if (this.getOption('disabled', true) || !this._canBe('Enabled', true)) {
				this.disable();
			}

		}
	},

	templateContext () {
		return {
			html: this.buildHtml()
		};
	},

	buildHtml () {
		return this.getOption('text', true);
	},

	_isActive () {
		if (this.hasOption('isActive')) {
			return this.getOption('isActive', true);
		}
		if (this.state('disabled') || this.state('waiting')) {
			return false;
		}
		return true;
	},

	_canBe (type, ifUndefined) {
		const property = 'canBe' + type;
		if (property in this) {
			const result = _.result(this, property) !== false;
			return result;
		} else {
			return ifUndefined;
		}
	},
	_getClickEventName (prefix = '', postfix = '') {
		if (!this._clickEventName) {
			this._clickEventName = this.buttonName ? this.buttonName + ':click' : 'click';
		}
		if (prefix) { prefix = prefix + ':'; }
		if (postfix) { postfix = ':' + postfix; }
		return prefix + this._clickEventName + postfix;
	},
	triggerClickEvent (eventAdd, ...args) {
		const [prefix, postfix] = eventAdd.split(':');
		const eventName = this._getClickEventName(prefix, postfix);
		// console.error('#', eventName);
		this._triggerClickEvent(eventName, args);
        // this.triggerMethod(eventName, ...args);
		if (this.buttonName) {
			const baseEvent = [prefix, 'click', postfix].filter(f => !!f).join(':');
			// console.error('#', baseEvent);
			this._triggerClickEvent(baseEvent, args);
		}
	},
	_triggerClickEvent (eventName, args) {
		if (eventName === 'click' && this._onClickTriggered) { return; }
		this.triggerMethod(eventName, ...args);
	},
	_clickHandler (event) {

		if (!this._isActive()) { return; }


		this.state('waiting', true);


		this.triggerClickEvent('before:', this, event);

		const result = this._takeAction(event);

		if (result && typeof result.then === 'function') {
			result.then((arg) => {
				if (this.isDestroyed()) { return; }
				this.state('waiting', false);
				this._afterClick(event, arg);
			}, (exc) => {
				if (this.isDestroyed()) { return; }
				this.state('waiting', false);
				this._afterClick(event, exc, true);
			});
		} else {
			this.state('waiting', false);
			const failed = result === false;
			this._afterClick(event, result, failed);
		}

	},

	_takeAction (event) {
		let res;
		if (this.clickAction) {
			res = this.clickAction(event);
                // this.getOption('clickAction', { invoke: true, invokeArgs: [this, event], invokeContext: this });
		} else if (this.hasOption('onClick')) {
			this._onClickTriggered = true;
			res = this.triggerMethod('click', this, event);
		}
		return res;
	},

	_afterClick (event, result, failed) {
		console.log('----- after click');
		const add = failed ? ':failed' : '';
        // if (failed) {
        //     this.triggerClickEvent(':failed', this, event, result);
        // }
		this.triggerClickEvent(add, this, event, result);
        // let eventName = this._getTriggerClickEvent(failed);
        // if (!eventName) { return; }
        // this.triggerMethod(eventName, this, event, result);
	},

	disable () {
		if (_.result(this, 'canBeDisabled') === false) { return; }
		this._disable();
	},
	_disable () {
		this.state('disabled', true);
		this.$el.prop('disabled', true);
	},

	enable () {
		if (_.result(this, 'canBeEnabled') === false) { return; }
		this._enable();
	},
	_enable () {
		this.state('disabled', false);
		this.$el.prop('disabled', false);
	}


});
