import { _ } from 'vendors';
import { commonMixin, displayThing } from '../common';

export const mixin = {

	...commonMixin,

	getDaysToZero (options = {}) {
		const { auto } = options;
		const addAuto = auto ? 'Auto' : '';

		let result = 0;

		const amount = this.model.get('stats.amount');

		if (amount) {
			const consumption = this.model.get('stats.avgConsumptionPerDay' + addAuto);
			if (consumption) {
				result = amount / consumption;
			}
		}

		return this._output(result, v => `<big>${_.displayNum(v, 1)}</big><small>д</small>`, options);

        // if (!display)
        //     return result;

        // return `<big>${_.displayNum(result, 1)}</big><small>д</small>`;
	},
	getAvgDaysToPurchase (options = {}) {
		const { auto } = options;
		const addAuto = auto ? 'Auto' : '';
		const val = this.model.get('stats.avgDaysToPurchase' + addAuto);
		return this._output(val, v => `<big>${_.displayNum(v, 1)}</big><small>д</small>`, options);
	},

	getAwaitingAmount (options = {}) {
		const { display /*, ifEmpty = '&mdash;' */ } = options;

		const value = this.model.get('stats.awaitingAmount');
		if (!display) { return value || 0; }

		if (!value) { return display ? '&mdash;' : 0; }

		return displayThing(value, this);
	}

};
