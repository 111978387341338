import { buildTabsHeader as baseBuildTabsHeader, buildTabsPageChildren as baseBuildTabsPageChildren } from 'components/ui2';

// import smartOpen from 'helpers/smart-open';

export const tabs = [
	{ id: 'root', name: 'компоненты' },
	{ id: 'procs', name: 'процессы' }
];

export function buildTabsHeader (startTab, ac, isRoot) {
	return baseBuildTabsHeader(startTab, tabs, ac, isRoot);
}

export function buildTabsPageChildren (MainLayout, defaultOptions) {
	return baseBuildTabsPageChildren(tabs, MainLayout, defaultOptions);
}
