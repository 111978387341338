import { View } from 'base/vendors';
import { _ } from 'vendors';
// import { classNameMixin } from 'mixins/view/className';
import { commonViewMixin } from 'mixins/view/common-view';
import { optionsMixin } from 'mixins/options';
import { viewSchemaMixin } from 'apis/schema';

export const Ui2AtomView = View.extend({

	constructor: function () {

		View.apply(this, arguments);
		if (this.getOption('renderOnModelChange') && this.model) {
			this.listenTo(this.model, 'change', this.render);
		}
		this.initializeTagTitle();
		this.initializeClassNameMixin();
	},

	...commonViewMixin,
	...optionsMixin,

	baseClassName: 'ui2-atom',
	template: _.template(`<% if (showTop) {%><i><%= topText %></i><% } %>
<% if (showBottom) {%><b><%= bottomText %></b><% } %>
<span><%= text %></span>
<% if (showSmall) {%><small><%= smallText %></small><% } %>
`),
	templateContext () {
		const showTop = !!this.getOption('showTop');
		const showBottom = !!this.getOption('showBottom');
		const showSmall = !!this.getOption('showSmall');
		const ifEmptyText = this.getOption('ifEmptyText') || '';
		const ifEmptyTopText = this.getOption('ifEmptyTopText') || '';
		const ifEmptyBottomText = this.getOption('ifEmptyBottomText') || '';
		const ifEmptySmallText = this.getOption('ifEmptySmallText') || '';
		return {

			showTop,
			showBottom,
			showSmall,

			text: this.getTextHtml(ifEmptyText),
			topText: showTop ? this.getTopTextHtml(ifEmptyTopText) : null,
			bottomText: showBottom ? this.getBottomTextHtml(ifEmptyBottomText) : null,
			smallText: showSmall ? this.getSmallTextHtml(ifEmptySmallText) : null
		};
	},

	showTop: true,
	showBottom: true,
	showSmall: false,

	getTextValue (key, ifEmpty) {
		let value = this.getOption(key, { force: false });
		if (typeof value === 'function') {
			value = value.call(this, this);
		}
		if (value == null || value === '') { return ifEmpty; }
		return value;
	},
	getHtmlValue (key, ifEmpty) {
		let value = this.getTextValue(key);
		const htmlKey = key + 'Html';
		const toHtml = this.options[htmlKey] || this[htmlKey];
		if (typeof toHtml === 'function') {
			value = toHtml.call(this, value, ifEmpty, this);
		}
		return this.getHtml(value, ifEmpty);
	},
	getHtml (value, ifEmpty) {
		if (value == null || value === '') { return ifEmpty || ''; }
		const toHtml = this.options.toHtml || this.toHtml;
		if (typeof toHtml === 'function') {
			value = toHtml.call(this, value, ifEmpty, this);
		}
		return value;
	},

	getBottomTextHtml (ifEmpty) { return this.getHtmlValue('bottomText', ifEmpty); },
	getTextHtml (ifEmpty) { return this.getHtmlValue('text', ifEmpty); },
	getSmallTextHtml (ifEmpty) { return this.getHtmlValue('smallText', ifEmpty); },
	getTopTextHtml (ifEmpty) { return this.getHtmlValue('topText', ifEmpty); },

	update () {
		const context = this.templateContext();
		_.each(this.selectors, (selector, key) => {
			const value = context[key];
			if (value === null) { return; }
			this.updateValue(key, value, selector);
		});
	},
	updateValue (key, html, selector) {
		if (selector == null) {
			selector = this.selectors[key];
		}
		this._updateElementHtml(selector, html);
	},
	_updateElementHtml (selector, html) {
		this.$(selector).html(html);
	}
});


export const Ui2SchemaAtomView = Ui2AtomView.extend({
	...viewSchemaMixin
});
