import BasePage from 'pages/anon';
import Layout from './layout';
import paths from 'helpers/paths';
import confirm from './confirm';

paths.set('url', 'acc:forgot:pass', 'acc/forgotPass');

const Page = BasePage.extend({
	name: 'forgotPassword page',
	label: 'Восстановление пароля',
	route: 'forgotPass',
	Layout,
	children: [confirm]
});

export default Page;
