import UiCardBlock from 'components/ui-card-block/block';
// import View from 'base/view';
import { FiltersModel } from './filters-models';
import { EnterTextLayout } from './enter-text-layout';
import { ActivityListFilters } from './activity-list-filters';
import { ActivityListItems, ActivityListItems2 } from './activity-list-items';
import { SmartLogsCollection, SmartLogsCollection2 } from '../models/logs-collection';
import user from 'app/user';
import { getModelUnsendedKey } from './utils';
// import './entity-activity.less';

const BaseView = UiCardBlock; // View; //

export const ActivityListUiBlock = BaseView.extend({
	className: 'entity-activity',
	cssClassModifiers: [],
	footer: EnterTextLayout,
	header: ActivityListFilters,
	content: ActivityListItems,
	// content: ActivityListItems2,

	initialize () {

		const col = this.logs = new SmartLogsCollection();
		col.setBackend(this.model.entity('logs'));

		this.unsendedCacheId = getModelUnsendedKey(this.model);
		// console.warn('comments collection:', this.unsendedCacheId, col);


		// this.logs = new SmartLogsCollection2();

		// col.fetchBackend();
	},
	getFiltersModel () {
		if (!this._filtersModel) {
			this._filtersModel = new FiltersModel();
			const activateFilter = this.getOption('activeFilterId');
			if (activateFilter) {
				this._filtersModel.makeActive(activateFilter);
			}
		}
		return this._filtersModel;
	},
	contentOptions () {
		return {
			model: this.model,
			filtersModel: this.getFiltersModel(),
			collection: this.logs,
			logConfig: this.getOption('logConfig'),
			backendCollection: this.model.entity('logs'),
			
		};
	},
	headerOptions () {
		const filters = this.getFiltersModel();
		return { model: filters, collection: this.logs };
	},
	footerOptions () {
		return {
			model: this.model,
			collection: this.logs,
			isEnabled: this.getOption('isCommentingEnabled', { force: false }),
			fileSvc: this.getOption('fileSvc'),
			unsendedCacheId: this.unsendedCacheId
		};
	}
});
