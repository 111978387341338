import { productFields } from '../common';

export const fields = [
	...productFields,
	{
		key: 'stats.amount',
		header: 'на складе',
		value: v => v.getInBaseUnits({ field: 'stats.amount', display: true })
	},
	{
		key: 'stats.contragentAmount',
		header: 'у контрагентов',
		value: v => v.getInBaseUnits({ field: 'stats.contragentAmount', display: true })
	},
	{
		key: 'stats.normalReminder',
		header: 'допустимый остаток',
		title: 'неснижаемый остаток',
		value: v => v.getInBaseUnits({ field: 'stats.normalReminder', display: true })
	},

	{
		key: 'stats.daysToEmpty',
		header: 'дней до 0 (руч.)',
		title: 'количество дней до опустошения, ручной ввод',
		value: v => v.getDaysToZero({ display: true })
	},

	{
		key: 'stats.avgDaysToPurchase',
		header: 'ср. закупка в днях (руч.)',
		title: 'длительность средней закупки в днях, ручной ввод',
		value: v => v.getAvgDaysToPurchase({ display: true })
	},

	{
		key: 'stats.daysToEmptyAuto',
		header: 'дней до 0 (авто)',
		title: 'количество дней до опустошения, автоматический подсчет',
		value: v => v.getDaysToZero({ display: true, auto: true })
	},

	{
		key: 'stats.avgDaysToPurchaseAuto',
		header: 'ср. закупка в днях (авто)',
		title: 'длительность средней закупки в днях, автоматический подсчет',
		value: v => v.getAvgDaysToPurchase({ display: true, auto: true })
	},


	{
		key: 'stats.awaitingAmount',
		header: 'ожидается',
		value: v => v.getInBaseUnits({ field: 'stats.awaitingAmount', display: true })
	}
];
