import $ from 'jquery';
import _ from 'underscore';

let shouldPreventMove = false;
let fakeListener = false;

// let logDiv;
// function mobileLog (...args) {
// 	if (!logDiv) {
// 		logDiv = $('<div/>').css({
// 			position: 'absolute',
// 			'z-index': 1000,
// 			top: '0px',
// 			left: '0px',
// 			padding: '3px',
// 			'background-color': 'white'
// 		}).appendTo($('body'));
// 	}
// 	logDiv.text(args.join());
// }

export const preventCallback = event => {
	if (shouldPreventMove) {
		event.preventDefault();
	}
};

// for canceling click after drag back to the clicked el
function cancelMouseClick (e) {
	e.stopPropagation(); // Stop the click from being propagated.
	window.removeEventListener('click', cancelMouseClick, true); // cleanup
}

// for preventing touchmove after hold
export function setupTouchMovePrevent () {
	if (fakeListener) return;

	document.addEventListener('touchmove', preventCallback, { passive: false });

	fakeListener = true;
}

const baseManipulatorEnter = (event, initialEl, rest, onMove) => {
	const touches = !!event.touches;
	let target = event.target;
	if (touches) {
		const touch = event.touches[0];
		target = document.elementFromPoint(touch.pageX, touch.pageY);
	}
	if (initialEl === target || initialEl.contains(target)) return;
	if (_.isFunction(onMove)) {
		onMove();
	}
	if (_.isFunction(rest)) {
		rest = rest();
	}
	$(target).trigger('manipulator:enter', rest || []);
};

export function delayedTouchstart (touchstart, delay = 400) {
	return function (event) {
		if (event.touches.length !== 1) { return; }

		const el = event.target;


		const heldItem = function () {
			el.removeEventListener('touchend', onReleasedItem);
			el.removeEventListener('touchmove', onReleasedItem);


			// preventing defalut touchmove
			// using fakeListener from setupTouchMovePrevent
			// and reverting value back after touch end
			shouldPreventMove = true;
			const returnPreventMoveValue = () => {
				el.removeEventListener('touchend', returnPreventMoveValue);
				shouldPreventMove = false;
			};


			el.addEventListener('touchend', returnPreventMoveValue);

			touchstart(event);
		};

		const onReleasedItem = function () {
			el.removeEventListener('touchend', onReleasedItem);
			el.removeEventListener('touchmove', onReleasedItem);
			clearTimeout(timer);
		};

		const timer = setTimeout(heldItem, delay);
		el.addEventListener('touchend', onReleasedItem);
		el.addEventListener('touchmove', onReleasedItem);
	};
}


export function setupManipulatorEnter ({ $el, onManipulatorEnter, onStart, onEnd, onMove, disableTouchScroll, selector, preventStart, elementEnterArguments } = {}) {
	if (!fakeListener) {
		document.addEventListener('touchmove', preventCallback, { passive: false });
		fakeListener = true;
	}

	const el = $el.get(0);
	const manipulatorEnter = _.partial(baseManipulatorEnter, _, el, elementEnterArguments, onMove);
	const $doc = $(document);
	const isOnStart = _.isFunction(onStart);
	const isOnEnd = _.isFunction(onEnd);
	const isDisableTouchScroll = _.isFunction(disableTouchScroll);
	const isPreventStart = _.isFunction(preventStart);

	const touchStart = (ev) => {
		// mobileLog(ev);
		if (selector) {
			const selected = $el.find(selector);
			if (!selected.length || !selected.get(0).contains(ev.target)) return;
		}

		isOnStart && onStart();
		isDisableTouchScroll && disableTouchScroll();

		const touchEnd = (event) => {
			document.removeEventListener('touchmove', manipulatorEnter);
			document.removeEventListener('touchend', touchEnd);
			isOnEnd && onEnd(event);
		};

		document.addEventListener('touchmove', manipulatorEnter, { passive: false });
		document.addEventListener('touchend', touchEnd, { passive: false });
	};

	// const touchEl = $el.find(selector).get(0);
	// mobileLog(new Date(), touchEl != null, selector, $el.html());
	el.addEventListener('touchstart', delayedTouchstart(touchStart), { passive: false });

	$el.on('mousedown', selector, (original) => {
		if (isPreventStart && preventStart()) {
			original.preventDefault();
			return;
		}

		const stopHandlers = (event) => {
			original.preventDefault();
			event.preventDefault();
			$doc.off('mouseenter', '*', manipulatorEnter);
			$el.off('mousemove', mouseStart);
			isOnEnd && onEnd(event);
			return false;
		};

		const mouseStart = () => {
			$el.one('mouseup', () => {
				window.addEventListener('click', cancelMouseClick, true);
			});
			onStart && onStart();
			$doc.on('mouseenter', '*', manipulatorEnter);
		};
		$doc.one('mouseup', stopHandlers);
		$el.one('mousemove', mouseStart);
	});
	$el.on('manipulator:enter', (event, ...rest) => {
		// console.log('::', event, rest);
		event.stopPropagation();
		onManipulatorEnter(event, ...rest);
	});
}
