import { CardItemView, getRawFilters, FetchableBatchesCollection } from 'mods/product-production/batches';
import { ManagedCollectionView } from '../../../components/managed-collection';



export const EntriesView = ManagedCollectionView.extend({

	initialize () {
		this.collection = new FetchableBatchesCollection();
	},

	id: 'production-batches',
	className: 'production-batches',

	infoViewOptions: true,

	filtersOptions: {
		id: 'page:batches:search',
		rawData: getRawFilters,
		instantApply: true
	},

	childView: CardItemView

	// entriesManagerOptions: {
	// 	// actionsEnabled: true
	// },

	// onRender () {
	// 	this.fetch();
	// }
	// actions: [
	// 	action('add:type', 'добавить продукцию', { resourcesProduction: 'productsEdit' })
	// ],
	// attributes () {
	// 	return {
	// 		'data-scrollable': 'production-builds-search'
	// 	};
	// }
	// actionAddType () {
	// 	const collection = this.collection;
	// 	editValue.do({
	// 		header: 'Добавление продукции',
	// 		controlView: Add,
	// 		applyLabel: 'добавить',
	// 		resetButton: false,
	// 		cancelLabel: 'отставить'
	// 	}).then(
	// 		(data) => {
	// 			collection.create(data, { preloader: true, wait: true }).then(
	// 				() => { },
	// 				(xhr) => modalError('неудалось добавить продукцию', xhr)
	// 			);
	// 			// return busData.productTypes().create(data, {preloader: true, wait:true}).then(
	// 			// 	() => {},
	// 			// 	(xhr) => modalError('неудалось добавить продукцию', xhr)
	// 			// );
	// 		},
	// 		() => { }
	// 	);
	// }
});

