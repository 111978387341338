import PageLayout from '../pageLayout';
import createNestedProcesses from 'mod/processes/common/views/entity-processes';

export default PageLayout.extend({
	currentSubpage: '/processes',
	initializeNesteds () {

		createNestedProcesses(this, {
			addClass: 'double-column whole-column',

			processesMode: 'active',
			fetchData: { 'contragentid.v': this.model.id, noactor: true }

		});
	}
});
