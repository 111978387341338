import Layout from './layout';
import modals from 'helpers/modals';
import Model from 'mod/employees/models/model';
export default function create () {
	return modals.show({
		header: 'добавление сотрудника',
		blockResolve: true,
		content: new Layout({
			model: new Model()
		}),
		asPromise: true,
		returnPromise: true,
		type: 'full',
		show: {
			resolve: true,
			reject: true
		},
		labels: {
			resolve: 'далее',
			reject: 'отставить'
		}
	});
}
