import BaseModel from 'base/model';
import enums from 'helpers/enums';
import mix from 'helpers/mix';
import { _ } from 'vendors';
// import Actionable from 'mixins/actionable';
// import dDefs from 'modules/display-defaults';


const ValuePropertiesMixin = {
	properties: {
		type: {
			display: {
				transform: (v) => enums.get('contactValues', v)
			}
		},
		value: {
			display: {
				transform (value) {
					if (!this.isPhone()) return value;
					return _.displayPhone(value);
				}
			}
		}
	}
};

const BaseValueModel = mix(BaseModel).with(ValuePropertiesMixin);
const ValueModel = BaseValueModel.extend({

	// getLabel(){
	// 	return this.display('value');
	// },
	getTypeOrder () {
		if (this.isPhone()) { return -2; } else if (this.isEmail()) { return -1; } else { return 0; }
	},
	isPhone () {
		const type = this.get('type') || '';
		return /phone/gi.test(type);
	},
	isEmail () {
		const type = this.get('type') || '';
		return /email/gi.test(type);
	},
	toHtml () {
		const value = this.get('value');
		if (!value) return '';

		const urlPrefix = this.isPhone()
			? 'tel:'
			: this.isEmail()
				? 'mailto:'
				: '';
		const urlValue = this.get('flatValue') || this.get('value');
		const label = this.display('value');
		const html = `<a href="${urlPrefix}${urlValue}">${label}</a>`;
		return html;
	}
});

export default ValueModel;
