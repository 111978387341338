import getRawFilters from 'mod/commonProcesses/filters/get';
import mix from 'helpers/mix';

import ListView from 'mod/commonProcesses/views/list-view';

import FDC from 'mixins/view/flex-dynamic-column';
import FetchableMixin from 'mixins/collection-view/fetchable';
import FetchOnScrollMixin from 'mixins/collection-view/fetch-on-scroll';
import ViewManagedCollection from 'mixins/view-managed-collection';
import ScrollHandlerMixin from 'mixins/view/scroll-handler';
import entriesManager from './manager';

// FDC, FetchableMixin, FetchOnScrollMixin, ViewManagedCollection, ViewScrollHandler
const View = mix(ListView).with(FDC, FetchableMixin, FetchOnScrollMixin, ViewManagedCollection, ScrollHandlerMixin).extend({
	id: 'contragents-search-list',
	className: 'contragents-list',
	entriesManager,
	entriesManagerOptions () {
		return {
			type: this.getOption('type'),
			infoOptions: true,
			filtersOptions: {
				getRaw: getRawFilters,
				instantApply: true
			}
		};
	},
	onRender () {
		this.fetch();
	},
	attributes () {
		return {
			'data-scrollable': 'contragents-search'
		};
	}
});

export default View;
