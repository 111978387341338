import { _, moment } from 'vendors';
import Base from './base';
import between from '../between';



export default Base.extend({
	groups () {
		const range = this.getDateRange();
		const now = new Date();
		const day = Date.toDay(range.from);
		const dm = moment(day);
		const p = [
			{
				header: 'утром',
				from: (d) => d.clone(),
				to: (d) => d.clone().addHours(11, 59, 59, 999)
			},
			{
				header: 'днём',
				from: (d) => d.clone().addHours(12),
				to: (d) => d.clone().addHours(5, 59, 59, 999)
			},
			{
				header: 'вечером',
				from: (d) => d.clone().addHours(18),
				to: (d) => d.clone().addHours(5, 59, 59, 999)
			}
		];
		const result = [];

		_(p).each((item) => {
			const from = item.from(day);
			const to = item.to(from);
			this.createGroup(
				to,
				{
					header: item.header + dm.format('[, ]Do MMM'),
					testModel: (m) => between(m.getDisplayDate(), from, to),
					isInPast: to <= now
				},
				result
			);
		});

		return result;
	}

});
