import editValue from 'bus/edit';
import { displayNum } from 'utils';
import busData from 'bus/data';
import UiListItem from 'components/ui-list/item';
export const _meas = v => busData.measures(v, 'short');

const NoConcretesView = UiListItem.extend({
	text: 'продукция отсутствует'
});

const baseUnit = m => _meas(m.get('baseUnitMeasureId'));
export const displayAmount = (m, key) => displayNum(m.get(key), 4) + ' ' + baseUnit(m);

export async function selectConcreteProductAsync (options = {}) {

	let { sourceValues, text, addText = '', smallText } = options;

	if (!text) {

		if (typeof addText !== 'function') {
			const value = addText;
			addText = () => value;
		}

		text = m => m.get('name') + addText(m);

	}
	const result = await editValue.do({
		header: 'Выберите конкретную продукцию',
		controlType: 'select',
		modelType: 'single',
		controlOptions: {
			searchDisabled: true,
			shouldReturnModel: true,
			emptyView: NoConcretesView,
			collectionChildViewMixin: {
				text, // : m => m.get('name'), // m.get('idString') + ' &mdash; ' + m.get('baseAmount') + ' ' + unit
				smallText
				// bottomText: m => `на складе: ${displayAmount(m, 'storeAmount')}, у кон-та: ${displayAmount(m, 'contragentAmount')}`
			}
		},
		sourceValues
	});

	return result;
}
