import { FilterModel } from '../ReportFilterModel';

import { TableLayoutView } from 'components/schema-table';
import { commonTableLayoutMixin } from '../table-layout-mixin';

import { FilterItemView } from '../../../../../../components/filters-v2/FilterItemView';
import { SelectInitialPeriodView } from './SelectInitialPeriodView';

const protoGetContentView = TableLayoutView.prototype.getContentView;
const protoGetContentHeaderView = TableLayoutView.prototype.getContentHeaderView;

const mixin = {
	initializeFilterModel () {
		const filters = this.getOption('rawFilters', false);
		this.filterModel = new FilterModel(filters);
		const initial = this.getFilterModelInitialValues();
		this.filterModel.setValues(initial);
		this.listenTo(this.filterModel, 'period:changed', () => {
			this.render();
		});
	},
	contentHeader: {
		noFilterButton: true,
		csvButton: true,
		content: [
			v => {
				const fm = v.getOption('filterModel');
				return {
					class: FilterItemView,
					model: fm.getValueModel('period'),
					removeButton: false,
					modelEvents: {
						change () {
							fm.trigger('apply');
							this.render();
						}
					}
				};
			}
		]
	},
	getContentHeaderView () {
		const hasPeriod = this.filterModel.hasValue('period', 'from', 'to');
		if (hasPeriod) {
			return protoGetContentHeaderView.apply(this, arguments);
		}

	},

	getContentView () {
		const hasPeriod = this.filterModel.hasValue('period', 'from', 'to');
		if (hasPeriod) {
			return protoGetContentView.apply(this, arguments);
		}
		return {
			class: SelectInitialPeriodView,
			filterModel: this.filterModel
		};
	}
};

export const CommonReportLayout = TableLayoutView.extend({

	...commonTableLayoutMixin,
	...mixin,
	// rowClassName: 'product-before-after-report-entry',
	rowOptions: {
		baseClassName: 'table-report-entry',
		thisClassName: 'product-before-after',
		classNames: [
			v => (v.model && v.model.get('deepness')) || ''
		],
		tagName: 'tr',
		useWrapper: false,
		useMolecule: false
	},
	listOptions: v => ({
		tagName: 'table',
		baseClassName: 'strict-table',
		layoutView: v
	}),
	cellOptions: {
		tagName: 'td',
		baseClassName: 'table-cell'
	},
	tableConfig: {
		headersInside: true,
		cellsWithoutLabels: true
	}


});


export const CommonLogLayout = TableLayoutView.extend({

	...commonTableLayoutMixin,
	...mixin,

	rowClassName: 'product-before-after-report-entry'


});
