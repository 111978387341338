import 'mods/processes/common/models/processes-board';
import BasePage from 'pages/auth';
import busModels from 'bus/models';

import Layout from './layout';
const Page = BasePage.extend({
	label: 'Завершенные процессы',
	route: 'completed',
	// children:[Production],
	icon: 'processes-closed',
	Layout,
	onBeforeStart () {
		const board = busModels.request('processes:board:instance');
		this.addStartPromise(board.getModelIds({ fetchIfNot: true }));
	}
});

export default Page;
