import PagesBlock from 'components/pages-block';
export default PagesBlock.extend({
	url: 'contragents/{id}',
	subpages: [
		{
			url: '',
			name: 'Основной раздел'
		},
		{
			url: '/processes',
			name: 'Процессы'
		},
		{
			url: '/tasks',
			name: 'Задачи'
		},
		{
			url: '/buy-products',
			name: 'Закупаемая продукция'
		}
	]
});
