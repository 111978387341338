import { _ } from 'vendors';
export default (Base) => Base.extend({
	constructor () {
		if (!this.getCollection) { this.getCollection = () => this.collection; }
		Base.apply(this, arguments);
	},

	showPreloaderOnFetch: true,

	fetch (fetchData, opts) {
		const options = this._buildFetchOptions(fetchData, opts);
		const collection = this.getCollection();

		const promise = collection.fetch(options);
		return promise;
	},
	_buildFetchOptions (fetchData, opts = {}) {
		const data = this.getFetchData(fetchData, opts);
		if (data) {
			opts.data = _.clone(data);
		}

		if (this.getOption('showPreloaderOnFetch')) {
			opts.preloader = this;
		}

		const options = this.getFetchOptions(data, opts);
		return _.extend(opts, this.getOption('fetchOptions', { args: [this] }), options);
	},
	getFetchData: (data) => data,
	getFetchOptions () {}
});
