import { _ } from 'vendors';
const compiledTemplates = {};

export const stringTemplateMixin = {
	getTemplate () {
		let tmpl = this.template;
		const type = typeof this.template;
		if (type === 'function') {
			return tmpl;
		}

		if (type === 'string') {
			let compiled = compiledTemplates[tmpl];
			if (!compiled) {
				compiled = _.template(tmpl);
				compiledTemplates[tmpl] = compiled;
				// tmpl = compiled;
			}
			if (compiled) {
				this.template = compiled;
				tmpl = compiled;
			}
		}

		return tmpl;
	}
};
