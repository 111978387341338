import childView from './ListItemView';
import { ListView } from '../common';

export default ListView.extend({

	collectionAddUrl: 'cycles/active',
	childView


});
