import { _ } from 'vendors';
import template from './template.html';
import View from 'base/view';
// import modalError from 'helpers/modals/error';
import beh from 'behaviors';

import validate from 'helpers/validate';
import modals from 'helpers/modals';
import user from 'app/user';
import bus from 'bus/app';
// import paths from 'helpers/paths';

const rule = {
	currentPassword: {
		required: {
			message: 'укажите свой текущий пароль'
		}
	},
	password: {
		required: {
			message: 'укажите новый пароль'
		},
		validators: [
			{
				func: (v, hash) => v === hash.passwordConfirm,
				message: 'пароль и подтверждение пароля не совпадают'
			}
		]
	}
};


const Layout = View.extend({
	className: 'card maxw600px margin-t',
	template,
	behaviors: [beh.FormToHash],
	onValuesApply (hash) {
		const errors = validate(hash, rule);
		if (errors) {
			const messages = _(errors).pluck('message').join('<br/>');
			modals.error(messages);
		} else { this.setNewPass(hash); }
	},
	setNewPass (data) {
		user.post({
			url: 'changePass',
			data,
			preloader: true
		}).then(
			() => {
				modals.message('Отлично, пароль изменён');
				bus.navigate('');
			},
			() => modals.error('Что-то пошло не так, перепроверьте всё и попробуйте ещё раз')
		);
	}
});

export default Layout;
