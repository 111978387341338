import { commonMixin } from '../common';

export const mixin = {

	...commonMixin

    // getDaysToZero(options = {}) {
    //     let { auto } = options;
    //     let addAuto = auto ? 'Auto' : '';

    //     let result = 0;

    //     let amount = this.model.get('stats.amount');

    //     if (amount) {
    //         let consumption = this.model.get('avgConsumptionPerDay' + addAuto);
    //         if (consumption) {
    //             result = amount / consumption;
    //         }
    //     }

    //     return this._output(result, v => `<big>${_.displayNum(v, 1)}</big><small>д</small>`, options);

    //     // if (!display)
    //     //     return result;

    //     // return `<big>${_.displayNum(result, 1)}</big><small>д</small>`;
    // },
    // getAvgDaysToPurchase(options = {}) {
    //     let { auto } = options;
    //     let addAuto = auto ? 'Auto' : '';
    //     let val = this.model.get('avgDaysToPurchase' + addAuto);
    //     return this._output(val, v => `<big>${_.displayNum(v, 1)}</big><small>д</small>`, options);
    // },

    // getAwaitingAmount(options = {}) {
    //     let { display, ifEmpty = '&mdash;' } = options;

    //     let value = this.model.get('stats.awaitingAmount');
    //     if (!display)
    //         return value || 0;

    //     if (!value)
    //         return display ? '&mdash;' : 0;

    //     return displayThing(value, this);
    // },

};
