import { _ } from 'vendors';
// import Model from 'base/model';
// import Collection from 'base/collection';
import CollectionView from 'base/collection-view';
import View from 'base/view';

// import PopoverBehavior from 'behaviors/popover';
// import { BasePointsView } from './mixins';
import ModelProperties, { editPropertyClickHandler } from 'components/ui-model-card/properties';
import PropertyView from 'components/ui-model-card/property';
import h from 'components/ui-model-card/helpers';
import { /* getTaskChannel, */ addProducts } from './utils';

// import enums from 'helpers/enums';
// import busData from 'bus/data';
// import { commonPriceInfos, BaseProductContragentModel } from 'mod/productionProducts/models/product-contragent';
// import { toLocaleStringTransform } from 'utils';

import { BaseProductContragentModel } from 'mod/productionProducts/models/product-contragent';

// import { BuyProcessProductModel } from 'mod/processes/buy/BuyProcessProductModel';

// const _DetailedJoinInfo = View.extend({
// 	className: 'card',
// 	template: _.template(`
// 		<div class="ui-list-item"><span><%= details.price %> р.</span></div>
// 		<div class="ui-list-item"><span><%= _v.getMeassureUnit() %></span></div>
// 	`),
// 	getMeassureUnit() {
// 		return 'tryam'
// 	},
// 	initialize() {
// 		console.log('~+', this);
// 	}
// });

// const DetailedJoinInfo = ModelProperties.extend({
// 	isInsideCollection () {
// 		return this.getOption('inCollectionView');
// 	},
// 	className: 'props-place card lined with-border transportpoint-props',
// 	properties: () => ([
// 		h.ui('price', { half: true }),
// 		h.ui('unitMeasureId', { half: true }),
// 		h.ui('priceInfo', { half: true }),
// 		h.ui('purchaseUnitToStoreUnit', { half: true })
// 	])
// });

// const JoinedInfoModel = Model.extend({
// 	properties: {
// 		price: {
// 			modelType: 'single',
// 			multiple: false,
// 			valueType: 'number',
// 			display: {
// 				label: 'стоимость за 1 единицу',
// 				ifEmpty: '&mdash;',
// 				transform: v => (v && v.toLocaleString('ru')) || undefined
// 			}
// 		},
// 		priceInfo: {
// 			multiple: true,
// 			valueType: 'enum',
// 			sourceValues: () => commonPriceInfos,
// 			controlType: 'select',
// 			display: {
// 				label: 'уточнения по цене',
// 				transform: (v) => enums.get(commonPriceInfos, v)
// 			}
// 		},
// 		unitMeasureId: {
// 			sourceValues: () => busData.measures(),
// 			controlType: 'select',
// 			display: {
// 				label: 'единица измерения',
// 				transform: (v) => busData.measures(v, 'one')
// 			}
// 		},
// 		purchaseUnitToStoreUnit: {
// 			modelType: 'single',
// 			multiple: false,
// 			valueType: 'number',
// 			display: {
// 				label: 'сколько складских единиц в одной закупочной единице',
// 				ifEmpty: '&mdash;',
// 				transform: toLocaleStringTransform
// 			}
// 		}
// 	}
// });

// const JoinInfoBehavior = PopoverBehavior.extend({
// 	popoverViewCssClass: 'popover-common max-width-400px',
// 	// showOn:'click',
// 	content () {
// 		return new DetailedJoinInfo({
// 			model: new JoinedInfoModel(this.view.model.get('details'))
// 		});
// 	}
// });



const defs = { destroyOnChange: false, className: 'card-line' }; // { addClass:'whole-btn', textAction:'edit' };

export const PurchaseItemMain = ModelProperties.extend({
	getTaskModel () {
		return this.getOption('taskModel');
	},
	isMyTask () {
		return this.getTaskModel().isMy();
	},
	cssClassModifiers: [
		m => !m.get('productionId') ? 'bg-light-green' : ''
	],
	isInsideCollection () {
		return this.getOption('inCollectionView');
	},
	className: 'props-place card-line',
	tagTitle () {
		return this.model.get('fullName');
	},
	properties: (m, v) => ([
		m.get('productionId')
			? h.ui('productionId', { ...defs })
			: h.ui('newproduct', { ...defs }),
		h.ui('amount', { ...defs, half: true, editButton: v.isMyTask() }),
		h.ui('unitMeasureId', { ...defs, half: true, editButton: v.isMyTask() })
		// h.ui('contragentId', { ...defs }),
	]),

	onEditPropertyAmount () {
		// console.log('++> ', this.model.toJSON());
		// console.log(this.model);
		this.model.collection.trigger('edited', this.model);
	}
});

const ContragentJoinInfo = View.extend({
	className: 'join-info item',
	tagTitle: 'закупить у этого контрагента',
	modelTemplate: _.template(`
		<span class="name">
			<i>название</i>
			<span><%= contragent.name %></span>
		</span>
		<span class="info-entry">
			<span><%= unitPrice %> <%= currencySign %></span>
			<b>за <%= unit %></b>
		</span>
		<span class="info-entry">
			<% if (hasTwoMeassures) {%>
			<span><%= priceUnitPrice %> <%= currencySign %></span>
			<b>за <%= priceUnit %></b>
			<% } %>
		</span>
		<span class="info-entry">
			<% if (hasTwoMeassures) {%>
				<span><%= priceUnitsInPurchaseUnit %> <%= priceUnitShort %></span>
				<b>в 1 <%= unitShort %></b>
			<% } %>
		</span>
	`),
	templateContext () {
		if (!this.model) return {};
		const info = this.model.getPriceInfo();
		// console.log("#", info);
		return {
			hasTwoMeassures: info.hasTwoMeassures,
			currencySign: info.currencySign,
			unitPrice: _.displayNum(info.pricePerUnit, 4),
			unit: info.unitMeasure || 'единицу',
			unitShort: info.unitMeasureShort,
			priceUnitPrice: _.displayNum(info.pricePerPriceUnit, 4),
			priceUnit: info.priceUnitMeasure,
			priceUnitShort: info.priceUnitMeasureShort,
			priceUnitsInPurchaseUnit: info.priceUnitsInPurchaseUnit
		};
	},
	noModelTemplate: _.template(`
	<span>
		<span>выбрать контрагента из списка</span>
	</span>
	`),
	initialize (options) {
		this.mergeOptions(options, ['taskModel', 'parentModel', 'modelTemplate']);
		// this.taskModel = this.getOption('taskModel');
		// this.bus = getTaskChannel(this.taskModel.id);
	},
	getTemplate () {
		if (this.model) {
			return this.modelTemplate;
		} else {
			return this.noModelTemplate;
		}
	},
	getProductId () {
		if (this.model) {
			return this.model.get('product.id');
		} else {
			// let parentModel = this.getOption('parentModel');
			return this.parentModel.get('productionId') || this.parentModel.get('newproduct');
		}
	},
	getContragent () {
		return (this.model && this.model.get('contragent')) || undefined;
	},
	addProductOptions () {
		return {
			model: this.taskModel,
			productModel: this.model,
			productId: this.getProductId(),
			contragent: this.getContragent()
		};
	},
	events: {
		'click' () {
			addProducts(this.addProductOptions());
		}
	}
});

// const NoContragents = ContragentJoinInfo.extend({
// 	template: _.template('создать процесс закупки'),
// 	addProductOptions() {
// 		//console.log('???? }', , this);
// 		let model = this.getOption('parentModel');

// 		return { model: this.taskModel, productId: model.get('productionId') || model.get('newproduct') };
// 	},
// });

// const ContragentsList = CollectionView.extend({
// 	className: 'joined-contragents',
// 	childView: ContragentJoinInfo,
// 	emptyView: ContragentJoinInfo, // NoContragents,
// 	emptyViewOptions () {
// 		return _.omit(this.childViewOptions(), 'behaviors');
// 	},
// 	childViewOptions () {
// 		return {
// 			behaviors: [JoinInfoBehavior],
// 			taskModel: this.getOption('taskModel'),
// 			parentModel: this.model
// 		};
// 	},
// 	initialize () {
// 		this.collection = new Collection(null, { model: BaseProductContragentModel });
// 		this._reinitCollection();
// 	},
// 	_reinitCollection () {
// 		const data = this.model.get('contragentsData') || [];
// 		const items = data.map(item => {
// 			return {
// 				id: item.contragent.id,
// 				...item
// 			};
// 		});
// 		this.collection.reset(items);
// 	}
// });

export const PurchaseItem = CollectionView.extend({
	className: 'purchaseitem-item card lined',
	initialize (opts) {
		this.mergeOptions(opts, ['productsMeasures']);
	},
	getTaskModel () {
		return this.getOption('taskModel');
	},
	isMyTask () {
		return this.getTaskModel().isMy();
	},
	getProperties () {
		const m = this.model;
		const model = m;
		const propertyModel = model;
		const changeBehavior = 'patch';
		const v = this;
		const umid = model.get('unitMeasureId');
		const pid = m.get('productionId');
		let bottomText;
		if (pid) {
			const property = this.productsMeasures.getPropertyName(pid, umid);
			if (!property) {
				bottomText = '<em style="color: red">не совпадает</em>';
			} else if (property === 'unitMeasureId') {
				bottomText = '<em>закупочная ед. изм.</em>';
			}
		}
		return [
			pid
				? h.ui('productionId', { ...defs, model })
				: h.ui('newproduct', { ...defs, model }),
			h.ui('amount', { ...defs, half: true, editButton: v.isMyTask(), model, propertyModel, changeBehavior }),
			h.ui('unitMeasureId', { ...defs, half: true, editButton: v.isMyTask(), model, propertyModel, changeBehavior, bottomText })
			// h.ui('contragentId', { ...defs }),
		];
	},
	getPropertiesViews () {
		const props = this.getProperties();
		return props.map(prop => prop ? new PropertyView(prop) : null);
	},
	onEditProperty () {
		this.model.collection.trigger('edited', this.model);
	},
	_buildContragentView (model) {
		const options = {
			model,
			taskModel: this.getOption('taskModel'),
			parentModel: this.model
		};
		if (model) {
			// options.behaviors = [JoinInfoBehavior];
		}
		return new ContragentJoinInfo(options);
	},
	getContragentsViews () {
		// let views = [];

		if (!this.isMyTask()) { return []; }

		const data = this.model.get('contragentsData') || [];
		const items = data.map(item => {
			const hash = {
				id: item.contragent.id,
				...item
			};

			const model = new BaseProductContragentModel(hash);
			return this._buildContragentView(model);
			// new ContragentJoinInfo({
			// 	model,
			// 	behaviors: [JoinInfoBehavior],
			// 	taskModel: this.getOption('taskModel'),
			// 	parentModel: this.model,
			// });
		});
		items.push(this._buildContragentView());
		return [
			new CollectionView({
				className: 'joined-contragents',
				customViews: items
			})
		];
		// return items;
		// return views;
	},

	customViews () {
		// let options = { model: this.model, taskModel: this.getOption('taskModel') };
		// let taskModel = this.getOption('taskModel');
		const properties = this.getPropertiesViews();
		const views = this.getContragentsViews();
		// console.log('## =', properties);
		return [
			// new PurchaseItemMain(options),
			...properties,
			...views
			// taskModel.isMy() ? new ContragentsList(options) : null
			// new PointItemLabels({ model: this.model }),
		];
	},
	childViewEvents: {
		'edit:property:click': editPropertyClickHandler
	}
});

export const ItemLine = View.extend({
	// behaviors: [PointInfoBehavior],
	className: 'purchaseitem-lineitem',
	template: _.template('<div data-region="item"></div>'),
	regions: {
		item: '> [data-region="item"]'
	},
	initialize (opts) {
		this.mergeOptions(opts, ['productsMeasures']);
	},
	onRender () {
		this.showChildView('item', new PurchaseItem({ model: this.model, taskModel: this.getOption('taskModel'), productsMeasures: this.productsMeasures }), { replaceElement: true });
	},
	templateContext () {
		return {
			index: this.model.collection.indexOf(this.model) + 1
		};
	}
});
