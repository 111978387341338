import UiCardBlock from 'components/ui-card-block/block';
import UiItem from 'components/ui-list/item';

// import View from 'base/view';
import CollectionView from 'base/collection-view';
import Collection from 'base/collection';

const StageItemView = UiItem.extend({
	text: m => m.get('name') + ':' + m.get('stageOrder')
});

export function stagesBlock (_this) {
	if (!_this.model.hasStages()) return;

	const items = _this.model.get('stages') || [];
	const collection = new Collection(items);
	collection.comparator = 'stageOrder';
	collection.sort();
	const block = {
		viewClass: UiCardBlock,
		viewOptions: {
			header: { text: 'стадии' },
			content: CollectionView,
			contentOptions: {
				className: 'ui-list',
				collection,
				childView: StageItemView
			}
		}
	};
	_this.createNested('stagesBlock', block);
}
