import { _ } from 'vendors';
import Users from '../models/collection';
import editValue from 'bus/edit';


export default function selectDetached (opts = {}) {
	const collection = opts.collection || new Users();
	collection.url = _.result(collection, 'url') + '/detached';
	return editValue.do({
		header: 'Выберите пользователя',
		controlType: 'select-api',
		modelType: 'single',
		multiple: false,
		collection
	});
}
