import { _ } from 'vendors';
export default (Base) => Base.extend({
	constructor () {
		Base.apply(this, arguments);

		this._scrollHandler = _.bind(this._scrollHandler, this);
		this._setScrollHandlers();

		this.on('edges:clear', this._clearEdges);
	},

	getScrollPosition () {
		const left = this.$el.scrollLeft();
		const top = this.$el.scrollTop();
		return {
			left,
			top,
			right: left + this.el.clientWidth,
			bottom: top + this.el.clientHeight
		};
	},

	getScrollEdges () {
		// длинна прокрутки контейнера
		return {
			left: this.$el.get(0).scrollWidth,
			top: this.$el.get(0).scrollHeight
		};
	},

	getScrollVariants () {
		const edges = this.getScrollEdges();
		const width = this.$el.outerWidth();
		const height = this.$el.outerHeight();
		const result = {
			// if true - has no scroll, if false - has scroll
			left: edges.left <= Math.round(width),
			top: edges.top <= Math.round(height)
		};
		return result;
	},

	_setScrollHandlers () {
		this.on('attach', this._startHandleScroll);
		this.on('detach', this._stopHandleScroll);
	},

	_scrollHandler () {
		const scroll = this._lastScrollPosition = this.getScrollPosition();
		const edges = this._checkElementEdge(scroll);
		this._tryTriggerEdges(edges);
	},

	_startHandleScroll () {
		this.$el.on('scroll', this._scrollHandler);
	},

	_stopHandleScroll () {
		this.$el.off('scroll', this._scrollHandler);
	},

	_getElementEdges () {
		return {
			left: 0,
			top: 0,
			right: this.el.scrollWidth,
			bottom: this.el.scrollHeight
		};
	},

	_clearEdges () {
		delete this._elementWidth;
		delete this._elementHeight;
		delete this._leftEdgeTriggered;
		delete this._rightEdgeTriggered;
		delete this._topEdgeTriggered;
		delete this._bottomEdgeTriggered;
	},

	_checkElementEdge (position) {
		const edges = this._getElementEdges();
		const left = (Math.abs(position.left - edges.left) - this.el.clientWidth) < 0;
		const right = (Math.abs(position.right - edges.right) - this.el.clientWidth) < 0;
		const top = (Math.abs(position.top - edges.top) - this.el.clientHeight) < 0;
		const bottom = (Math.abs(position.bottom - edges.bottom) - this.el.clientHeight) < 0;
		return { left, right, top, bottom };
	},

	getAllowedEdges () {
		if (!this._allowedEdges) {
			let allowedEdges = this.getOption('allowedScrollEdges');
			if (!_.isArray(allowedEdges)) {
				allowedEdges = ['bottom', 'right'];
			}
			this._allowedEdges = allowedEdges;
		}
		return this._allowedEdges;
	},

	_tryTriggerEdges (edges) {
		const allowed = this.getAllowedEdges();
		_.each(edges, (should, edgeName) => {
			if (!should || allowed.indexOf(edgeName) === -1) return;
			this._tryTriggerEdge(edgeName);
			// let field = `_${edgeName}EdgeTriggered`;
			// console.log('#1', this[field]);
			// if (this[field]) return;
			// console.log('#2', edgeName, field);
			// this.triggerMethod('scrolled:to:' + edgeName);
			// this[field] = true;
		});
		/*
		let shouldTrigger = {};
		let elementEdges = this._getElementEdges();
		//console.log(elementEdges);
		if(!edges.left)
			shouldTrigger.right = elementEdges.left;

		if(!edges.top)
			shouldTrigger.bottom = elementEdges.top;

		if(!_.size(shouldTrigger)) return;

		_(shouldTrigger).each((offset, edge) => {
			this._triggerEdge(edge, offset);
		});
		*/
	},

	_tryTriggerEdge (edge) {
		const field = `_${edge}EdgeTriggered`;

		if (this[field]) return;
		this[field] = true;
		// console.warn('scrolled:to:' + edge);
		this.triggerMethod('scrolled:to:' + edge);
	}
	// _triggerEdge(edge, offset){
	// 	console.log('#3', edge, offset);
	// 	let field = '_' + _.camelCase(edge + ':edge:triggered');

	// 	if (this[field] != null && this[field] >= offset) {
	// 		return;
	// 	}

	// 	this.triggerMethod('scrolled:to:' + edge);
	// 	this[field] = offset;
	// },

});
