import { HamburgerView } from 'base/view-hamburger';
import { ComponentsListBlockView } from './ComponentsListBlockView';

import { MainPropertiesBlockView } from './MainPropertiesBlockView';

export const Layout = HamburgerView.extend({
	baseClassName: 'ui2-blocks',
	customViewOptions () {
		return {
			model: this.model
		};
	},
	customViews: [
		MainPropertiesBlockView,
		ComponentsListBlockView
	]
});
