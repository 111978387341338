import busEvents from 'bus/app-events';

export default Base => Base.extend({
	constructor () {
		Base.apply(this, arguments);
		this._initSocketsHandler();
	},
	_initSocketsHandler () {
		if (this._socketsHandler) return;
		this.once('render', this._createSocketsHandler);
		this.once('before:destroy', this._removeSocketsHandler);
	},
	_createSocketsHandler () {
		if (this._socketsHandler) return;
		const handler = this.getOption('socketsHandler', { args: [this] });
		this._socketsHandler = busEvents.request('Handler', handler, this);
	},
	_removeSocketsHandler () {
		if (!this._socketsHandler) return;
		this._socketsHandler.destroy();
		delete this._socketsHandler;
	}
});
