// import { _, $ } from 'vendors';
import CollectionView from 'base/collection-view';

import PreventWheelMixin from 'mixins/view/prevent-wheel';

import editValue from 'bus/edit';
import modalError from 'helpers/modals/error';
import { Model } from 'base/models';
import { bus } from './bus';
import { SelectBatchIdView } from './SelectBatchIdView';
import { AcceptReadyProductInModal } from '../accept-ready-product';

import { BlockHeaderWithRightButtons, BlockFooterTextAction } from 'components/ui2';
import { ComponentsListView } from './ComponentsListView';

export const ContragentView = CollectionView.mixWith(PreventWheelMixin).extend({
	getScrollEl () {
		return this.$('.contragent-components-list');
	},
	className: 'double-width contragent-components-block ui2-block whole-column',
	customViews () {
		const agent = this.model.get('contragent');
		if (!agent) { return []; }
		return [
			new BlockHeaderWithRightButtons({
				text: 'ресурсы контрагента ' + agent.name,
				buttons: [
					// { id: 'withdraw:resources', text: 'выдать' },
					// { id: 'accept::resources', text: 'забрать' },
					// { id: 'accept::resources', text: 'списать брак' },
					// { id: 'accept:production', text: 'принять продукцию' },
				]
			}),
			new ComponentsListView({
				process: this.model
			}),
			new BlockFooterTextAction({
				text: 'принять готовую продукцию',
				clickAction: 'accept:production'
			})
		];
	},
	childViewEvents: {
		'accept:production' () {
			AcceptReadyProductInModal(this.model)
				.then(
					data => this.selectBatchIdString(data),
					xhr => modalError('не удалось принять готовую прдукцию', xhr)
				);
		}
	},
	selectBatchIdString (value) {
		editValue.do({
			header: 'Установите номер партии',
			applyLabel: 'добавить продукцию на склад',
			modalOptions: {
				modifiers: {
					'after:render': null,
					refresh: null
				}
				// css: {
				// 	wrapper: 'flex-next-modal-wrapper'
				// }
			},
			resetButton: false,
			cancelButton: false,
			controlView: SelectBatchIdView,
			controlViewOptions: {
				process: this.model,
				value,
				readyOnRender: true
			}
		}).then(attrs => {
			const sender = new Model();
			sender.url = this.model.url() + '/production/accept-product-batch';
			sender.save(null, { wait: true, method: 'post', attrs })
				.then((data) => {
					console.log('# OK', data);
					bus.trigger('refresh:contragent:store', data);
				}, xhr => modalError('Не удалось внести партию продукции', xhr));
		});
	}
});
