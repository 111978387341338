// import { TableRowView } from '../schema-table';
import { TableRowView } from '../schema-table';
import { tableSchema } from './tableSchema';

export const LogTableRowView = TableRowView.extend({
	thisClassName: 'transaction-log-entry',

	classNames: [
		v => v.ifHeader('', vv => 'dst-' + vv.model.get('destinationParticipantType')),
		v => v.ifHeader('', vv => 'src-' + vv.model.get('sourceParticipantType')),
		v => v.ifHeader('', vv => vv.model.get('type')),
		v => v.ifHeader('', vv => vv.getStoreChangeType())
	],

	useWrapper: true,

	tableSchema,

	getStoreChangeType () {
		const { amountInStoreBefore, amountInStoreAfter } = this.model.attributes;
		return amountInStoreAfter > amountInStoreBefore
			? 'increment'
			: amountInStoreAfter < amountInStoreBefore
				? 'decrement'
				: '';
	}

});
