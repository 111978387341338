import { _ } from 'vendors';
import NotFoundPage from './not-found';
import Error400 from './error-400';
import Page from './auth';
import Acc from './acc';

import Contragents from './contragents';
import Staff from './staff';
import Tasks from './tasks';
import Admin from './Admin';
import Resources from './resources';
import Store from './store';
import Processes from './processes';
import Purchase from './purchase';
import ProductProduction from './product-production';
import Reports from './reports';
// import Deals from './deals/deals';


import history from 'helpers/history';

const Root = Page.extend({
	id: 'root:page',
	name: 'Root Page',
	label: 'Palma Med Corp.',
	route: '(/)',
	children: [Tasks, Processes, ProductProduction, Purchase,
		Store,
		Contragents, Reports, Staff, Resources, Admin, Acc, Error400, NotFoundPage],
	root: true,
	history,
	employeeNeeded: false,
	passDown () {
		const pass = {
			root: this,
			app: this.getParent(),
			history,
			router: this.router,
			proxyEventsTo: this.getParent()
		};
		_.extend(this, pass);
		return pass;
	}

});

export default Root;
