import { _ } from 'vendors';
import BaseModel from 'base/model';
import Model from 'base/card-model';
import mix from 'helpers/mix';
import action from 'helpers/action';
import EditableProperties from 'mixins/editable-properties';

import editValue from 'bus/edit';

import enums from 'helpers/enums';
import busData from 'bus/data';
import NestedEntitiesV2mixin from 'mixins/model/nested-entities-v2';
import paths from 'helpers/paths';
// import editValue from 'bus/edit';


import EntityWithEmployees from 'mod/employees/mixins/entity-with-employees';
import EntityWithTasks from 'mod/tasks/mixins/entity-with-tasks-2';
import EntityWithChecklist from 'mod/tasks/mixins/entity-with-checklist';
import EntityWithLogs2 from 'mods/logs/entity-with-logs-2-mixin';
// import EntityWithLogs from 'components/logs/entity-with-logs';

import { TeamMembersCollection } from './team-members';

import '../api-config';

import modalError from 'helpers/modals/error';
import modalMessage from 'helpers/modals/message';
import modalConfirm from 'helpers/modals/confirm';

import { dateTransform } from 'helpers/date/smart';

// import BbStore from 'base/bb-store';
import busModels from 'bus/models';

import provideComment from 'helpers/provide-comment';

import BooleanSelect from 'components/controls/boolean-select';
import ProcessTasksBoard from './process-tasks-board';
import smartOpen from 'helpers/smart-open';
import { BuyProcessProperties } from 'mod/processes/buy/mixin.BuyProcessModel';

const PropertiesMixin = {
	properties: {

		...BuyProcessProperties,

		type: {
			type: 'enum',
			sourceValues: () => enums.store.appProcessTypes,
			allowedValues: ['process', 'buy'],
			display: {
				label: 'тип процесса',
				ifEmpty: 'Обычный процесс',
				transform: (v) => enums.get('appProcessTypes', v)
			}
		},

		iId: {
			display: {
				label: 'id #',
			}
		},

		name: {
			display: {
				label: 'название процесса',
				ifEmpty: 'не установлено'
			}
		},
		description: {
			type: 'bigtext',
			display: {
				label: 'описание',
				ifEmpty: 'не установлено'
			}
		},
		accessMode: {
			type: 'enum',
			multiple: true,
			sourceValues: () => enums.store.processAccessModes,
			display: {
				label: 'режим доступа',
				transform: (v) => enums.get('processAccessModes', v)
			}
		},
		ownerId: {
			notNull: true,
			nested: (eId) => busData.employees(eId),
			sourceValues: () => busData.employees(),
			controlType: 'employeesSelect',
			display: {
				label: 'ответственный',
				ifEmpty: 'я',
				transform: (v) => busData.employeeNameOrMe(v, 'я', 'я')// m.whoResponsible()
			}
		},
		creatorId: {
			notNull: true,
			nested: (eId) => busData.employees(eId),
			sourceValues: () => busData.employees(),
			controlType: 'employeesSelect',
			display: {
				label: 'инициатор',
				ifEmpty: 'я',
				transform: (v) => busData.employeeNameOrMe(v, 'я', 'я')// m.whoResponsible()
			}
		},
		deadline: {
			display: {
				label: 'крайний срок',
				transform: dateTransform,
				alternative: (v) => dateTransform(v, { format: 'fromNow' })
			}
		},
		modified: {
			display: {
				label: 'последнее изменение',
				transform: dateTransform,
				alternative: (v) => dateTransform(v, { format: 'fromNow' })
			}
		},
		created: {
			display: {
				label: 'дата создания',
				transform: dateTransform,
				alternative: (v) => dateTransform(v, { format: 'fromNow' })
			}
		},
		completeState: {
			type: 'enum',
			sourceValues: () => enums.store.appProcessCompleteStates,
			display: {
				label: 'статус',
				transform: (v) => enums.get('appProcessCompleteStates', v)
			}
		}
		/*
		state:{
			display:{
				label: 'состояние',
				transform: (v,o,m) => {
					switch(m.get('result')){
					case 'succeeded': return 'завершена';
					case 'failed': return 'провалена';
					case 'canceled': return 'отменена';
					default: return enums.get('employeeTaskStates',v);
					}
				}
			}
		},
		'dates.deadline':{
			type:'datetime',
			controlOptions(){
				return {
					defaultTime:'12:00',
					//startTime: Date.create({minutes:2}),
					predefinedValues:{
						'через 15 минут': Date.create({minutes:15}),
						'через час': Date.create({hours:1}),
						'завтра': Date.create({days:1}),
						'послезавтра': Date.create({days:2}),
					},
					revertPredefinedValues: true,
				};
			},
			display:{
				label: 'когда надо сделать?',
				transform: (v,o) => dateTransform(v, _.extend(o,{smartDateOptions:{time:true}})), //(v) => smartDate.main(v,{time:true})
				alternative: (v) => dateTransform(v,{format:'fromNow',smartDateOptions:{time:true}}),
			}
		},
		'dates.strictDeadline':{
			type:'boolean',
			sourceValues: deadlineStrictnes,
			display:{
				label:'тип крайнего срока',
				transform:(v) => deadlineStrictnes[v || false]
			}
		},
		'modified':{
			display:{
				label:'последнее изменение',
				transform: dateTransform,
				alternative: (v) => dateTransform(v,{format:'fromNow'}),
			}
		},
		'created':{
			display:{
				label:'когда поставлена',
				transform: dateTransform,
				alternative: (v) => dateTransform(v,{format:'fromNow'}),
			}
		},
		'dates.onCheck':{
			display:{
				label:'когда отправлена на проверку',
				transform: dateTransform
			}
		},
		'dates.started':{
			display:{
				label:'взята в работу',
				transform: dateTransform,
				alternative: (v) => dateTransform(v,{format:'fromNow'}),
			}
		},
		'dates.closed':{
			display:{
				transform: dateTransform,
				alternative: (v) => dateTransform(v,{format:'fromNow'}),
			}
		},
		'stateDate':{
			display:{
				label(){
					switch(this.get('result')){
					case 'succeeded': return 'завершена';
					case 'failed': return 'провалена';
					case 'canceled': return 'отменена';
					default: return 'в работе с';
					}
				},
				alternative(){
					if(this.isClosed()){
						return this.display('dates.closed', {alternative: true});
					} else {
						return this.display('dates.started',{alternative: true}) || this.display('created',{alternative: true});
					}
				},
				transform(){
					if(this.isClosed()){
						return this.display('dates.closed');
					} else {
						return this.display('dates.started') || this.display('created');
					}
				}
			}
		},

		checkerId: {
			nested: (eId) => busData.employees(eId),
			sourceValues: () => busData.employees(),
			controlType:'employeesSelect',
			display:{
				label:'проверяющий',
				ifEmpty:'не требует проверки',
				transform: (v) => employeeName(v, 'не требует проверки', 'я проверяю')
			}
		},
		creatorId: {
			notNull:true,
			nested: (eId) => busData.employees(eId),
			display:{
				label:'поставлена',
				transform: (v) => employeeName(v, 'неизвестно кем', 'мной')
			}
		},
		contragent: {
			display:{
				transform(){
					let c = this.getContragent();
					return c && c.display('name');
				}
			}
		}
		*/
	}
};


const Base = mix(Model).with(EditableProperties, PropertiesMixin, NestedEntitiesV2mixin, EntityWithEmployees, EntityWithTasks, EntityWithChecklist, EntityWithLogs2);
const Process = Base.extend({
	constructor () {
		Base.apply(this, arguments);
		this.on('change:completeState', () => this.trigger('new:state', this));
	},
	getFineId() {
		const iid = this.get('iId');
		if (iid != null) { return iid; }
		return this.id;
	},
	isBuy () {
		return this.get('type') === 'buy';
	},
	hasStages () {
		const items = this.get('stages') || [];
		return (items.length > 0);
	},
	hasGoals () {
		const items = this.get('goals') || [];
		return (items.length > 0);
	},
	taskEntity: 'process',
	socketEntity: 'CommonProcess',
	cardUrlRoot: paths.url('processes'),
	urlRoot: paths.api('processes'),
	completedTasksEnabled: true,
	getCompletedTasksDefinition (Collection) {
		const model = this;
		return Collection.extend({
			fetchUrl () {
				let url = model.url;
				_.isFunction(model.url) && (url = model.url());
				return url + '/completedTasks';
			}
		});
	},


	employeesActionsConfig: {
		add: {
			rights (proc) {
				if (proc.isNew()) {
					return { processes: 'startProcess' };
				} else {
					return proc.amIAdmin() && proc.isNotClosed();
				}
			}
		},
		remove: {
			rights (proc) {
				if (proc.isNew()) {
					return { processes: 'startProcess' };
				} else {
					return proc.amIAdmin() && proc.isNotClosed();
				}
			}
		},
		changeRole: {
			rights (proc) {
				console.error('oplya change role', arguments, this, proc);
				if (proc.isNew()) {
					return { processes: 'startProcess' };
				} else {
					return proc.amIAdmin();
				}
			}
		}
	},
	employeesCanBeRemoved (emps) {
		// console.log('CAN BE REMOVED', arguments, this);
		if (emps == null || emps.length === 0) {
			return false;
		}
		const ownerId = this.get('ownerId');
		const canDo = this.amIAdmin() && this.isNotClosed();
		if (!canDo) {
			return false;
		}
		const ok = emps.every(emp => {
			let empId;
			if (typeof emp === 'string') {
				empId = emp;
			} else if (emp && typeof emp === 'object' && emp.get) {
				empId = emp.get('employeeId');
			}
			return empId !== ownerId
		});
		return ok;
		// let hasRights;
		// if (this.employeesActionsConfig && this.employeesActionsConfig.remove) {
		// 	hasRights = user.checkRights(this.employeesActionsConfig.remove.rights, { context: this });
		// }
		// return hasRights;
	},

	getMe ()	{
		const employeeId = busData.user().getIdentityId();
		if (employeeId) {
			return this.getEmployee(employeeId);
		}
	},
	checkEmployeeRights (shouldHas = '', employee) {
		if (!shouldHas) return true;

		const splitpat = /\s*,\s*/gi;
		const rights = this.getEmployeeRights(employee) || '';
		const rightsArr = rights.split(splitpat);
		const shouldHasArr = shouldHas.split(splitpat);
		return _.every(shouldHasArr, expect => rightsArr.indexOf(expect) > -1);
	},
	getEmployeeRights (id) {
		const emp = this.getEmployee(id);
		return emp ? emp.get('joinedAs') : '';
	},
	getEmployee (employeeId) {
		const emps = this.getEmployees();
		if (employeeId == null) {
			employeeId = this.getMe();
		}
		if (_.isObject(employeeId) && employeeId instanceof BaseModel) {
			return emps.get(employeeId);
		}
		return emps.findWhere({ employeeId });
	},
	getEmployees () {
		return this.entity('team');
	},

	getTasksBoard () {
		if (!this.tasksBoard) {
			this.tasksBoard = new ProcessTasksBoard({ parentEntityId: this.id });
			this.tasksBoard.defaultUrl = _.result(this, 'url') + '/tasksBoard';
		}
		return this.tasksBoard;
	},

	forceApiEmployees: false,
	employeeIdAttribute: 'employeeId',
	employeesAttribute: 'team',


	getContragent () {
		if (!this.isBuy()) return;
		if (this.get('contragent') == null) return;
		return this.entity('contragent');
	},

	isNotClosed () {
		return this.get('completeState') === 'notCompleted';
	},
	isGeneralCanBeEdited () {
		const potential = this.amIAdmin();
		return potential && this.isNotClosed();
	},
	isEmployeeOwner(id) {
		return this.get('ownerId') === id;
	},
	amIGeneralEditor () {
		return this.amIAdmin();
	},
	isEditable (which = 'minor') {
		return this.isNotClosed() && this.amIEditor(which);
	},
	isMy () {
		return busData.user().isMe(this.get('ownerId'));
	},
	amIIn () {
		return !!this.getMe();
	},
	amIViewer (which = 'minor') {
		if (which === 'major') {
			return this.amIMajorViewer();
		} else if (which === 'minor') {
			return this.amIMinorViewer();
		}
	},
	amIAdmin () {
		const res = this.checkEmployeeRights('admin') || this.isMy() || busData.user().isAdmin();
		return res;
	},
	amIEditor (which = 'minor') {
		// if (this.amIAdmin()) {
		// 	return true;
		// }
		if (which === 'major') {
			return this.amIMajorEditor();
		} else if (which === 'minor') {
			return this.amIMinorEditor();
		}
	},
	amIMajorViewer () {
		return this.checkEmployeeRights('viewMajor') || this.amIEditor('minor');
	},
	amIMinorViewer () {
		return this.checkEmployeeRights('viewMinor') || this.amIMajorViewer();
	},
	amIMinorEditor () {
		return this.checkEmployeeRights('editMinor') || this.amIMajorEditor();
	},
	amIMajorEditor () {
		return this.checkEmployeeRights('editMajor') || this.amIAdmin();
	},
	isCanBeChanged (property) {
		const properties = {
			// ownerId: m => m.isMy() && m.isNotClosed(),
			name: m => m.amIAdmin(),
			description: m => m.amIAdmin()
		};

		const rights = properties[property];
		const result = rights ? rights.call(this, this) : false;

		return result;
	},
	isCompletable () {
		const baseCompletable = this.isNotClosed() && this.amIAdmin();
		if (this.isBuy()) {
			return this.productsToBuyCollection && baseCompletable && this.productsToBuyCollection.every(model => model.get('isFullyAccepted'));
		}
		return baseCompletable;
	},
	isCancelable () {
		return this.isNotClosed() && this.amIAdmin();
	},
	isRestartable () {
		return !this.isNotClosed() && this.amIAdmin();
	},
	isCommentable () {
		return this.isNotClosed() && this.amIMajorViewer();
	},
	getProcessesBoard () {
		return busModels.request('processes:board:instance');
	},
	isOnBoard () {
		const board = this.getProcessesBoard();
		return board.isIn(this.id);
	},
	isNotOnBoard () {
		const board = this.getProcessesBoard();
		return board.isNotIn(this.id);
	},

	actions: [
		action('upload:files', 'прикрепить файлы', null, {
			order: -100,
			rule: (m) => m.amIMinorViewer(),
			places: 'page'
		}),
		action('add:goal', 'добавить цель', null, {
			rule: (m) => m.amIAdmin() && m.isNotClosed(),
			places: 'page'
		}),
		action('show:tasksBoard', 'перейти на доску задач процесса', null, {
		}),
		action('add:to:board', 'добавить процесс на доску процессов', null, {
			places: 'page',
			rule: m => m.isNotOnBoard()
		}),
		action('remove:from:board', 'убрать с доски', null, {
			places: 'page',
			rule: m => m.isOnBoard()
		}),

		action('start:process', 'запустить процесс', { processes: 'startProcess' }, {
			places: 'page'
		}),
		action('complete:process', 'завершить процесс', null, {
			rule: (m) => m.isCompletable()
		}),
		action('cancel:process', 'отменить процесс', null, {
			rule: (m) => m.isCancelable()
		}),
		action('restart:process', 'возобновить процесс', null, {
			rule: (m) => m.isRestartable()
		}),
		// action('add:employees','добавить участников', { processes:'startProcess' },{
		// 	rule: m => m.isMy() && m.isNotClosed(),
		// 	places:'page'
		// }),
		action('add:check:item', 'добавить пункт', null, {
			places: 'page',
			order: -89,
			rule: (m) => m.amIAdmin() || m.isNew()
		})

	],

	getAddTaskRule () {
		return () => this.isNotClosed() && this.amIMinorEditor();
	},

	actionAddGoal () {
		const options = {
			header: 'Новая цель',
			controlOptions: {
				multiple: false,
				valueType: 'text'
			}
		};
		editValue.do(options).then((name) => {
			this.apiPost('goals', { name }).then(goal => {
				this.trigger('new:goal', goal);
			});
		}, () => {});
	},

	actionUploadFiles () {
		this.trigger('open:selectFilesToUpload');
	},
	actionShowTasksBoard (e) {
		const url = this.cardUrl() + '/tasksBoard';
		smartOpen(url, e);
	},

	actionAddToBoard () {
		const board = this.getProcessesBoard();
		board.addModels(this, null, { save: true });
		this.trigger('change:actions');
	},

	actionRemoveFromBoard () {
		const board = this.getProcessesBoard();

		board.removeModels(this, { save: true });
		this.trigger('change:actions');
	},

	actionStartProcess () {
		const { name, type } = this.attributes;
		const contragentId = this.get('contragent.id');

		const error = type === 'buy' && !contragentId ? 'Выберите контрагента' 
		: type !== 'buy' && !name ? 'Укажите название процесса'
		: undefined;
		
		
		if (error) {
			modalError(error);
			return;
		}
		this.save().then(
			() => {
				modalConfirm('Процесс запущен.<br/>Перейти на страницу процесса?', 'перейти').then(
					() => this.actionOpenCard(),
					() => {
						this.clear({ silent: true });
						this.trigger('replace:by:new', this);
					}
				);
			},
			err => modalError(err)
		);
	},

	actionRestartProcess () {
		provideComment({
			context: this,
			header: 'возобновление процесса',
			commentHeader: 'укажите причину',
			onConfirm: data => this.send({
				url: 'restart',
				method: 'POST',
				data
			}),
			onSuccess: newData => {
				this.set(newData);
				modalMessage('процесс возобновлён');
			},
			onError: error => {
				modalError('Произошла ошибка', error);
			}
		});
	},
	actionCompleteProcess () {
		provideComment({
			context: this,
			header: 'завершение процесса',
			commentHeader: 'комментарий',
			controlView: BooleanSelect,
			controlProperty: 'success',
			validate: value => {
				if (!value) return false;
				return 'success' in value;
			},
			controlViewOptions: {
				sourceValues: {
					true: 'успех',
					false: 'провал'
				}
			},
			onConfirm: data => this.send({
				url: 'complete',
				method: 'POST',
				data
			}),
			onSuccess: newData => {
				this.set(newData);
				modalMessage('процесс завершён');
			},
			onError: error => {
				modalError('Произошла ошибка', error);
			}
		});
	},
	actionCancelProcess () {
		provideComment({
			context: this,
			header: 'отмена процесса',
			commentHeader: 'укажите причину',
			onConfirm: data => this.send({
				url: 'cancel',
				method: 'POST',
				data
			}),
			onSuccess: newData => {
				this.set(newData);
				modalMessage('процесс отменён');
			},
			onError: error => {
				modalError('Произошла ошибка', error);
			}
		});
	},



	nestedEntities: {
		team: TeamMembersCollection,
		contragent: () => busModels.getModel('Contragent')
	}

});

busModels.reply('CommonProcess', () => Process);
busModels.reply('Process', () => Process);

export default Process;
/*
const StoreModel = BbStore(Process);

busModels.reply('process',() =>  StoreModel);

export default StoreModel;
*/
