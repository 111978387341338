import _measures from './singleton';

export function shortMeasure (id) {
	return measures(id, 'short');
}

export function measures (id, opts) {
	opts || (opts = {});

	if (arguments.length === 0) return _measures;

	if (id == null) return null;

	const item = _measures.get(id);

	if (item && typeof opts === 'string') {
		return item.display(opts);
	}

	if (opts && opts.transform) {
		return opts.transform(item, id);
	}

	// if (!_.size(opts) || !item) return item;

	// if (opts.transform) {
	// 	return opts.transform(item);
	// }

	return item;
}

