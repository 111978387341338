import BasePage from 'pages/auth';
import Layout from './layout';
import Collection from 'base/collection';
import { departmentsApiUrl } from './models';
import DepartmentPage from './department';

const Page = BasePage.extend({
	moduleRights: { productProduction: 'view' },
	label: 'участки производства',
	route: 'deps',
	icon: 'staff-deps',
	Layout,
	onBeforeStart () {
		if (!this.collection) {
			const col = new Collection();
			col.url = departmentsApiUrl;
			this.addCollection(col);
			this.addStartPromise(col.fetchIfNot());
		}
	},
	children: [DepartmentPage]
});

export default Page;
