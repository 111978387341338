import { _ } from 'vendors';
import { Model, Collection, BackboneView } from 'base/vendors';

export function isClass (arg, Base) {
	return _.isFunction(arg) && (arg === Base || arg.prototype instanceof Base);
}

export function isModelClass (arg) {
	return isClass(arg, Model);
}
export function isCollectionClass (arg) {
	return isClass(arg, Collection);
}

export function isViewClass (arg) {
	return isClass(arg, BackboneView);
}

export function isCollection (arg) {
	return arg instanceof Collection;
}

export function isModel (arg) {
	return arg instanceof Model;
}

export function isView (arg) {
	return arg instanceof BackboneView;
}
