import { _ } from 'vendors';
import Yat from 'marionette.yat';
export default _.extend({}, Yat.modals, {
	show (...args) {
		const options = this.getOptions(...args);
		// console.log('>>> modal ::', options);
		if (options.addBodyCssClass !== false) {
			options.addBodyCssClass = 'blur-not-last';
		}
		return Yat.modals.show(options);
	}
});
