import { _ } from 'vendors';
import BaseModel from 'base/model';
import Actionable from 'mixins/actionable';

import ContactValuesCollection from './contact-values-collection';
import mix from 'helpers/mix';
import ContactModelProperties from './model-properties';
import action from 'helpers/action';
import editValue from 'bus/edit';
import error from 'helpers/modals/error';
import busModels from 'bus/models';
const BaseContactModel = mix(BaseModel).with(Actionable, ContactModelProperties);

const ContactValuesMixin = {
	// initializeContactValues(){
	// 	this.initContactValuesCollection();
	// 	this.initContactValuesListener();
	// },
	initializeContactValues () {
		if (this._valuesInitialized) return;

		const raw = this.getRawContactValues();
		this.contactValues = new ContactValuesCollection(raw, { parent: this });
		this.listenTo(this.contactValues, 'edited added', this._onContactChanged);
		this.on('change:values', this.updateContactValues);

		this._valuesInitialized = true;
	},
	_onContactChanged () {
		const contactValues = this.getContactValues();

		this.set('values', contactValues.toJSON());
	},
	getContactValues () {
		return this.contactValues;
	},
	getRawContactValues () {
		return (this.get('values') || []).slice(0);
	},
	updateContactValues () {
		const raw = this.getRawContactValues();
		const values = this.getContactValues();
		values.reset(raw, { parse: true });
		values.sort();
	},
	getFirstContactValue () {
		const values = this.getContactValues();
		return values.first();
	}

};


const ContactModel = mix(BaseContactModel).with(ContactValuesMixin).extend({
	constructor () {
		BaseContactModel.apply(this, arguments);

		// in ContactValuesMixin
		this.initializeContactValues();
	},
	initialize (data, opts = {}) {
		this._initializeParent(opts.parent);
	},
	_initializeParent (parent) {
		if (this.collection) return;
		this.parent = parent;
		if (this.parent == null) {
			throw Error('Initialize EntityContact model error, parent not provided');
		}
		this._onParentIdChange();
		this.listenTo(this.parent, 'change:id', this._onParentIdChange);
	},
	_onParentIdChange () {
		this.parentId = this.parent.id;
		this.url = this.parent.url() + '/contacts';
	},

	isPrimary () {
		return this.get('primary');
	},
	getOrder () {
		return this.get('order') || 0;
	},
	getPersonName () {
		const model = this;
		const contactName = model.get('contactName');
		if (contactName != null) return '';
		return [
			model.get('person.name.last'),
			model.get('person.name.first'),
			model.get('person.name.middle')
		].filter((v) => !!v).join(' ');
	},
	getFuncsProperty () {
		return 'contragentFuncs';
	},
	getFuncs (opts) {
		const property = this.getFuncsProperty();
		return this.get(property, opts);
	},
	setFuncs (values) {
		if (_.isArray(values)) { values = values.join(', '); }

		const property = this.getFuncsProperty();
		const hash = { [property]: values };
		this.set(hash);
		return hash;
	},
	canBeEdited () {
		return true;
	},
	hasAnyContactValue () {
		const values = this.getContactValues();
		return values.hasAnyContactValue();
	},
	actions: [
		action('add:contact', 'добавить контакт'),
		action('edit', 'внести изменения')
	],

	actionAddContact () {
		const isNew = this.isNew();
		editValue.do({
			header: 'Добавление контакта',
			controlType: 'contactValue',
			applyLabel: 'добавить контакт',
			cancelLabel: 'не добавлять',
			resetButton: false
		}).then((data) => {
			const values = this.getContactValues();
			if (isNew) {
				const contactValue = values.add(data);
				contactValue.trigger('added', contactValue);
			} else {
				return values.create(data, { wait: true }).then(
					(contactValue) => contactValue.trigger('added', contactValue),
					(xhr) => error('Возникла ошибка', xhr)
				);
			}
		});
	},

	actionEdit () {
		editValue.do({
			header: 'Изменение контактного лица',
			controlType: 'entityPerson',
			applyLabel: 'сохранить изменения',
			cancelLabel: 'закрыть без сохранения',
			resetLabel: 'удалить контактное лицо',
			resetButtonCssClass: 'delete-button',
			resetButtonConfirm: 'Вы собираетесь удалить контактное лицо',
			value: this
			// controlOptions:{
			// 	//parent: this.collection.parent
			// }
		}).then(
			(data) => {
				if (!this.isNew()) {
					this.save(data, { wait: true }).then(
						() => {},
						(xhr) => error('Не удалось сохранить изменения.', xhr)
					);
				}
			},
			(reject) => {
				if (reject.reason === 'reset') {
					this.destroy({ wait: true }).then(
						() => {},
						(xhr) => error('не удалось удалить контактное лицо', xhr)
					);
				}
			}
		);
	}

	// getLabel(){
	// 	return this.get('person.name.full');
	// },
	// getFunction(opts){
	// 	if(this.has('clientFuncs'))
	// 		return this.get('clientFuncs', opts);
	// 	else if(this.has('ownerFuncs'))
	// 		return this.get('ownerFuncs', opts);
	// },

});

busModels.reply('Contact', () => ContactModel);

export default ContactModel;
