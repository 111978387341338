import Model from 'base/model';
import Collection from 'base/collection';
import mix from 'helpers/mix';
import NestedEntitiesMixinV2 from 'mixins/model/nested-entities-v2';

const FilterItem = Model.extend({
	getValue () { return this.get('value'); },
	makeActive () {
		this.trigger('make:active', this);
	}
});
const FiltersCollection = Collection.extend({
	constructor () {
		Collection.apply(this, arguments);
		this.on('make:active', this.makeActive);
	},
	model: FilterItem,
	getActive () {
		return this.findWhere({ active: true });
	},
	makeActive (model) {
		const current = this.getActive();
		if (current && current !== model) {
			current.set('active', false);
		}
		model.set('active', true);
		this.parent.trigger('change:active', model);
	}
});


const Base = mix(Model).with(NestedEntitiesMixinV2);
export const FiltersModel = Base.extend({
	makeActive (id) {
		const col = this.entity('items');
		const model = this.entity('items').get(id);
		if (!model) return;
		col.makeActive(model);
	},
	getActive () {
		return this.entity('items').getActive();
	},
	getSearchText () {
		const text = this.entity('items').get('text');
		return text.getValue();
	},
	defaults () {
		return {
			items: [
				{ id: 'comment', types: ['comment'], title: 'Только записи с комментариями', active: true },
				{ id: 'file', types: ['fileUploaded'], title: 'Добавленные файлы' },
				{ id: 'all', label: 'все', title: 'Все записи' },
				{ id: 'text', label: '', title: 'Поиск комментария', textField: true }
			]
		};
	},
	nestedEntities: {
		items: FiltersCollection
	}
});
