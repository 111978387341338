module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 if(header){ 
__p+='<header>'+
((__t=( header ))==null?'':_.escape(__t))+
'</header>';
 } 
__p+='\n<div class="form-container vertical default">\n	<div data-placeholder="логин"><input type="email" name="username" value="'+
((__t=( username ))==null?'':__t)+
'"/></div>\n	<div data-placeholder="пароль"><input type="password" name="password" /></div>\n	<button class="apply">войти</button>\n	<div class="help-links small horizontal">\n		<a href="'+
((__t=( _url('acc:forgot:pass') ))==null?'':__t)+
'">забыли пароль?</a>\n	</div>\n</div>\n\n';
}
return __p;
};
