import PersonsList from 'components/contacts/views/persons-list';
import Contragent from '../list-item';
export default PersonsList.extend({
	initialize () {
		// this.customViews.push
		this.addCustomView(
			() => new Contragent({ tagName: 'li', model: this.model, collectionPlace: 'top' })
		);
	}
});
