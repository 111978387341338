import 'mod/productionTypes';
import ListView from 'mod/productionTypes/views/cards-list';
import getRawFilters from 'mod/productionTypes/filters/get';
import mix from 'helpers/mix';
import FDC from 'mixins/view/flex-dynamic-column';

import ViewManagedCollection from 'mixins/view-managed-collection';

// import ScrollHandlerMixin from 'mixins/view/scroll-handler';
// import FetchableMixin from 'mixins/collection-view/fetchable';
// import FetchOnScrollMixin from 'mixins/collection-view/fetch-on-scroll';


import Actionable from 'mixins/actionable';
import action from 'helpers/action';


import openAdd from 'mod/productionTypes/views/open-add';

// const Base = mix(ListView).with(FDC, FetchableMixin, FetchOnScrollMixin, ViewManagedCollection, ScrollHandlerMixin, Actionable);
const Base = mix(ListView).with(FDC, ViewManagedCollection, Actionable);
const View = Base.extend({
	id: 'productionTypes-search-list',
	className: 'productionTypes-list',
	entriesManagerOptions: {
		infoOptions: true,
		filtersOptions: {
			getRaw: getRawFilters,
			instantApply: true
		},
		actionsEnabled: true
	},
	actions: [
		action('add:type', 'добавить новый тип', { resourcesProduction: 'typesEdit' })
	],
	attributes () {
		return {
			'data-scrollable': 'productionTypes-search'
		};
	},
	actionAddType () {
		return openAdd();
	}
});

export default View;
