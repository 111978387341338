import { _ } from 'vendors';
import Models from './models';
import data from 'bus/data';
// import SingleFetch from 'mixins/single-fetch';


import busEvents from 'bus/app-events';

busEvents.request('Handler', {
	radioEvents: {
		employeeChanged (ev) {
			const entity = this.getModelByEvent(ev);
			if (!entity) return;
			entity.set(ev.data);
		},
		employeeLeaveDepartments (ev) {
			const entity = this.getModelByEvent(ev);
			if (!entity) return;
			_(ev.data).each((d) => {
				const dep = this.getDepartment(d.id);
				if (!dep) return;
				dep.removeEmployeeFromCollection(entity.id);
			});
		},
		employeeLeaveGroups (ev) {
			const entity = this.getModelByEvent(ev);
			if (!entity) return;
			_(ev.data).each((d) => {
				const grp = this.getGroup(d.id);
				if (!grp) return;
				grp.removeEmployeeFromCollection(entity.id);
			});
		}

		// departmentRightsChanged(ev){
		// 	let entity = this.getModelByEvent(ev);
		// 	if(!entity) return;

		// 	let newValue = _.extend({}, entity.get('rights'), ev.data.rights);
		// 	entity.set('rights', newValue, {flat: false, parent: entity});
		// },
		// departmentEmployeesAdded(ev){
		// 	let entity = this.getModelByEvent(ev);
		// 	if(!entity) return;
		// 	entity.getEmployees().add(ev.data, { parent: entity });
		// },
		// departmentEmployeesRemoved(ev){
		// 	let entity = this.getModelByEvent(ev);
		// 	if(!entity) return;
		// 	entity.getEmployees().reset(ev.data, { parent: entity });
		// },
		// departmentAddedToDepartment(ev){
		// 	departments.add(ev.data);
		// 	let entity = this.getModelByEvent(ev);
		// 	entity && entity.trigger('change',entity);

		// },
		// departmentCreated(ev){
		// 	departments.add(ev.data);
		// }
	},
	getModelByEvent (ev) {
		return ev.dataId && employees.get(ev.dataId);
	},
	getDepartment (id) {
		if (!id) return;
		return data.departments(id);
	},
	getGroup (id) {
		if (!id) return;
		return data.groups(id);
	}

});



const Employees = Models.Collection.extend({
	noApi: true
});
const employees = new Employees();


data.employees = function (...args) {
	return data.request('employees', ...args);
};

data.employeeName = function (id) {
	if (id == null || id === '') { return; }
	return data.employees(id, { transform: (e) => e && e.display('name') });
};

data.employeeNameOrMe = function (id, nobody, me) {
	if (id == null) { return nobody; }

	if (data.user().isMe(id)) { return me; }

	const emp = data.employees(id);
	if (emp == null) { return nobody; } else { return emp.display('name'); }
};

data.reply('employees', (id, opts = {}) => {
	if (id == null) return employees;
	const employee = employees.get(id);
	if (!_.size(opts) || !employee) return employee;

	if (opts.transform) { return opts.transform(employee); }

	return employee;
});

export default employees;
