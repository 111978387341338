import Base from 'components/ui-list/item';
import ActionsPopover from 'behaviors/actions-popover';

function employee (m, key) {
	const nested = m.get(key, { nested: true });
	return nested && nested.display && nested.display('name');
}

export default Base.extend({
	behaviors: [ActionsPopover.extend({ placedAt: 'plate' })],
	cssClassModifiers: [
		'process',
		m => m && 'process-' + m.get('type'),
		m => m && m.get('completeState')
	],
	text () {
		return this.model.display('name');
	},
	topText () {
		const owner = employee(this.model, 'ownerId');
		// let creator = employee(this.model, 'creatorId');
		return `<span>${owner}</span>`;
	},
	bottomText () {
		return this.model.display('modified');
	},
	textAction: 'open:card',
	optionsButton: true,
	infoButton: true
});
