import { _ } from 'vendors';
import Bb from 'backbone';
import BasePage from 'pages/auth';
import Layout from './layout';
import ManagerModel from './manager/model';
import BaseCollection from 'mod/tasks/models/collection';
import Task from './task';
import busData from 'bus/data';
const Collection = BaseCollection.extend({

});


const Page = BasePage.extend({
	// moduleRights: { tasks: 'view' },
	order: -1000,
	label: (m) => 'Задачи ' + ((m && m.display('date')) || ''),
	route: ['tasks', 'tasks/:type/:state/list', 'tasks/:type/:state/:view/:value'],
	icon: 'tasks',

	onBeforeStart (ac) {
		console.error('TASK BEFORE START');
		const handleCreatedTask = task => {
			this.collection.add(task);
		};
		this.listenTo(busData, 'task:created', handleCreatedTask);
		this.once('before:stop', () => this.stopListening(busData, 'task:created', handleCreatedTask));

		if (!this.model) {
			this.addModel(new ManagerModel());
		}
		if (!this.collection) {
			this.addCollection(new Collection([], { parent: this.model }));
		}


		if (!this.badEntries) {
			console.log('плохих нет, делаем плохих');
			this.badEntries = new Collection([], {
				sorting: [['date', 'desc']],
				sortOnChange: false,
				parent: this.model,
				bad: true
			});
		} else {
			// console.log('отключил из-за тормозов');
			// отключил из-за тормозов.
			// this.badEntries.fetch();
		}


		const replaceUrl = this.synchronizeModel(ac);
		this.updateCollectionComparator();
		if (replaceUrl) {
			this.setNewPageUrl();
		}

		this.listenTo(this.model, 'change', () => {
			this.updateCollectionComparator();
			this.setNewPageUrl();
		});
	},
	synchronizeModel (ac = {}) {
		if (!_.size(ac.args)) {
			return true;
		}

		let replaceUrl = false;

		const di = Date.info(ac.args.value);
		const value = ac.args.view == null
			? undefined
			: di.date;

		const update = {
			type: ac.args.type,
			state: ac.args.state,
			view: ac.args.view || 'list',
			date: value
		};

		if (value && !this.model.isValidDate(value, update.view)) {
			update.date = this.model._getNewViewDate(update.view);
			replaceUrl = true;
		}
		this.model.set(update, { silent: true });

		return replaceUrl;
	},
	onBeforeStop () {
		this.stopListening(this.model, 'change');
	},
	setNewPageUrl () {
		Bb.history.navigate(this.model.getPageUrl(), { trigger: false });
	},
	updateCollectionComparator () {
		const isDesc = this.model.get('state') === 'completed';
		this.collection.setOrder([['date', isDesc]]);
	},
	Layout,
	// wrapLayout: true,
	children: [Task],
	socketGroups: () => 'employeetasks'
});

export default Page;
