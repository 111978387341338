import PageLayout from '../pageLayout';

import UiCardBlock from 'components/ui-card-block/block';
import JoinedProducts from '../joined-products';

export default PageLayout.extend({
	currentSubpage: '/buy-products',
	initializeNesteds () {
		const m = this.model;
		this.createNested('joinedContragents', {
			viewClass: UiCardBlock,
			viewOptions: {
				addClass: 'double-column minus-header-and-footer',
				header: { text: 'продукция', addClass: 'bg-main-dark' },
				content: JoinedProducts,
				contentOptions () {
					return {
						tagName: 'ul',
						addClass: 'scrolled-y',
						collection: m.getJoinedProducts(),
						childViewOptions: {
							tagName: 'li',
							type: 'product'
						}
					};
				},
				action: 'add:joined:product'
			},
			regionTemplate: { replaceElement: true }
		});
	}
});
