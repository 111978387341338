import { _ } from 'vendors';
import { BaseModel } from './base-models';
import Collection from 'base/collection';
import Lists from './lists-collection';
import Items from './board-items-collection';
import nextNum from '../../helpers/next-num';
import paths from 'helpers/paths';

paths.set('api', 'boards', 'boards');


export default BaseModel.extend({
	urlRoot: paths.api('boards'),
	constructor (attrs, options = {}) {
		BaseModel.apply(this, arguments);

		if (options.entityConfig != null) { this.entityConfig = options.entityConfig; }

		this.on('save:lists', this.patchListsAfterChange);
		this.on('save:items', this.patchItemsAfterChange);
		this.on('save:listsAndItems', this.patchListAndItemsAfterChange);
	},
	getModelIds ({ fetch = false, fetchIfNot = false } = {}) {
		if (fetch || (fetchIfNot && !this.isFetched())) {
			return this.fetchItems().then(() => this.getModelIds());
		} else {
			const items = this.entity('items');
			return items.pluck('id');
		}
	},
	isIn (id) {
		// debugger;
		const ids = this.getModelIds();
		return ids.indexOf(id) > -1;
	},
	isNotIn (id) {
		return !this.isIn(id);
	},
	parse (data) {
		let models;
		// let saveNeeded;
		let fullResponse;
		let realModelsIds;
		if (data.models) {
			fullResponse = true;
			models = data.models;
			const modelCol = this.getModelsCollection();
			modelCol.set(data.models);
			realModelsIds = modelCol.pluck('id');
			delete data.models;
		}
		let listId;
		if (this.shouldAddDefaultList && fullResponse && (data.lists != null && !data.lists.length)) {
			data.lists = [{ id: 1, name: 'Список', order: 0 }];
			// saveNeeded = true;
		}

		if (data.lists && !listId) {
			listId = data.lists[0] && data.lists[0].id;
		}


		if (this.modelsAreLinked && models && data.items) {
			!data.items && (data.items = []);
			const existIds = _.pluck(data.items || [], 'id');
			let order = 0;
			const modelsIds = {};
			_.each(models, model => {
				modelsIds[model.id] = 1;
				if (existIds.indexOf(model.id.toString()) === -1) {
					data.items.push({ id: model.id, listId, order });
					order++;
				}
			});
			data.items = _.reduce(data.items, (memo, item) => {
				if (modelsIds[item.id]) {
					memo.push(item);
				}
				return memo;
			}, []);

			// if (order > 0) {
			// 	saveNeeded = true;
			// }
		} else if (!this.modelsAreLinked && models && data.items) {
			data.items = data.items.filter(f => realModelsIds.indexOf(f.id) > -1);
		}

		if (data.items && data.lists) {
			const listIds = _.pluck(data.lists || [], 'id');
			_.each(data.items, item => {
				if (item.listId == null || listIds.indexOf(item.listId) === -1) {
					item.listId = listId;
				}
			});
		}

		// if(saveNeeded)
		// 	this.once('sync', () => this.trigger('save:listsAndItems'));

		return data;
	},
	fetch (options = {}) {
		if (this.isNew() && !options.url) {
			options.url = _.result(this, 'defaultUrl');
		}
		return BaseModel.prototype.fetch.call(this, options);
	},
	fetchItems () {
		const key = this.isNew() ? 'defaultUrl' : 'url';
		const baseUrl = _.result(this, key);
		const url = baseUrl + '/items';
		return this.fetch({ url });
	},
	// isNew: () => 1,
	nestedEntities: {
		lists: {
			class: Lists
		},
		items: {
			class: Items
		}
	},
	getListItemsCollection (list) {
		const items = this.entity('items');
		return items.getListItemsCollection(list);
	},
	getItemModelClass () {
		const items = this.entity('items');
		return items.model;
	},
	toItemModel ({ listId, entityModel } = {}) {
		if (listId == null) {
			const list = this.entity('lists').first();
			listId = list ? list.id : undefined;
		}
		const modelsCollection = this.getModelsCollection();
		modelsCollection.add(entityModel);
		const items = this.getListItemsCollection(listId);
		const Item = this.getItemModelClass();
		const id = entityModel.id;
		const order = items ? nextNum(items.pluck('order'), 0) : 0;
		return new Item({ id, order, listId });
	},
	addNewItemToList (contexts) {
		const items = this.entity('items');
		const newItems = _.map(contexts, context => this.toItemModel(context));
		items.add(newItems);
	},
	addModels (models, listId, options = {}) {
		if (models == null) return;
		!_.isArray(models) && (models = [models]);
		const contexts = _.map(models, entityModel => ({ entityModel, listId }));
		const items = this.entity('items');
		const newItems = _.map(contexts, context => this.toItemModel(context));
		items.add(newItems);
		if (options.save) {
			this.trigger('save:items');
		}
	},
	removeModels (models, options = {}) {
		if (models == null) return;
		!_.isArray(models) && (models = [models]);
		const ids = _.pluck(models, 'id');
		const items = this.entity('items');
		items.remove(ids);
		if (options.save) {
			this.trigger('save:items');
		}
	},
	removeItem (models, options = {}) {
		const items = this.entity('items');
		const removed = items.remove(models);
		if (options.save) {
			this.trigger('save:items');
		}
		return removed;
	},
	addNewList (options = {}) {
		const lists = this.entity('lists');
		const newList = lists.addNewList();
		if (options.save) {
			this.trigger('save:lists');
		}
		return newList;
	},
	createModelsCollection (models) {
		const model = this.entityConfig.Model;
		const collection = new Collection(models, { model });
		return collection;
	},
	getModelsCollection () {
		if (!this.modelsCollection) {
			this.modelsCollection = this.createModelsCollection();
		}
		return this.modelsCollection;
	},
	_patchProp (...keys) {
		const attrs = {};
		_.each(keys, key => {
			const values = this.entity(key);
			attrs[key] = values;
		});
		return this.save(null, { attrs, preloader: true, method: 'patch' });
	},
	patchListsAfterChange (instance, changes, opts) {
		if (opts && opts.xhr) {
			return;
		}
		return this._patchProp('lists');
	},
	patchItemsAfterChange (instance, changes, opts) {
		if (opts && (opts.xhr || opts.changeInitiator === this)) {
			return;
		}
		return this._patchProp('items');
	},
	patchListAndItemsAfterChange () {
		return this._patchProp('items', 'lists');
	}
});
