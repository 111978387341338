module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+=''+
((__t=( _leftBtns ))==null?'':__t)+
'\n'+
((__t=( _icon ))==null?'':__t)+
'\n'+
((__t=( _leftItems ))==null?'':__t)+
'\n<div class="ui-list-item-main">\n	';
if(_top){
__p+='<i>'+
((__t=( _top ))==null?'':__t)+
'</i>';
}
__p+='\n	';
if(_bottom){
__p+='<b>'+
((__t=( _bottom ))==null?'':__t)+
'</b>';
}
__p+='\n	<'+
((__t=( _itemMainTag))==null?'':__t)+
'>'+
((__t=( _text ))==null?'':__t)+
'</'+
((__t=( _itemMainTag))==null?'':__t)+
'>\n	';
if(_small){
__p+='<small>'+
((__t=( _small ))==null?'':__t)+
'</small>';
}
__p+='\n</div>\n'+
((__t=( _rightItems ))==null?'':__t)+
'\n'+
((__t=( _rightBtns ))==null?'':__t)+
'\n';
}
return __p;
};
