import { BackendLogsCollection } from 'mods/logs/models/backend-logs-collection';
export default Base => Base.extend({
	constructor () {
		Base.apply(this, arguments);
		this.registerNestedEntity('logs', BackendLogsCollection);
	},
	getLogs () {
		return this.entity('logs');
	}
});
