export default (Base) => Base.extend({
	constructor () {
		Base.apply(this, arguments);
		this.initWheelBehavior();
	},
	initWheelBehavior () {
		this.$el.on('wheel', (e) => {
			if (this.isScrollable(e.target)) { e.stopPropagation(); }
		});
		this.on('before:destroy', () => this.$el.off('wheel'));
	},
	getScrollEl () {
		const ul = this.$('ul');
		return ul.length && ul;
	},
	isScrollable (target) {
		const scroll = this.getScrollEl();
		if (!scroll) return false;
		const res = scroll.outerHeight() < scroll.get(0).scrollHeight;
		if (res && scroll[0].contains(target)) { return res; }
	}
});
