import '../api-config';
import BaseModel from 'base/model';
import BbStore from 'base/bb-store';
import paths from 'helpers/paths';
import mix from 'helpers/mix';
import EditableProperties from 'mixins/editable-properties';
const Model = mix(BaseModel).with(EditableProperties).extend({
	urlRoot: paths.api('admin:configs')
});
const StoreModel = BbStore(Model);
export default StoreModel;
