import { TableContentView } from '../schema-table';
import { LogListView } from './LogListView';
import { LogTableRowView } from './LogTableRowView';
// import { ContragentsLogList } from './ContragentsLogList';
// import { ProductionTree } from './products-layouts/ProductionTree';
// import { ProductsTableLayout } from './products-layouts/TableLayout';

export const LogContentView = TableContentView.extend({
	baseClassName: 'content',

	ListView: LogListView,
	RowView: LogTableRowView

});
