
import ListView from './ListView';
import { TransferLayoutView } from '../common';

export const TasksLayoutView = TransferLayoutView.extend({
	ListView,
	thisClassName: 'active-processes-page',
	resourceName: 'tasks',
	tabsButtons: [
		{
			id: 'responsible',
			name: 'ответственный',
			filter: (view, options) => options.isResponsible(view.model)
		},
		{
			id: 'checker',
			name: 'проверяющий',
			filter: (view, options) => options.isChecker(view.model)
		}
	],
	getFilterOptions () {
		const id = this._getEmployeeId();
		return {
			isChecker: model => (model.get('checkerId') || '').toLowerCase() === id,
			isResponsible: model => (model.get('responsibleId') || '').toLowerCase() === id
		};
	},
	_getEmployeeId () {
		if (!this._employeeId) {
			this._employeeId = this.model.id.toLowerCase();
		}
		return this._employeeId;
	},
	setupSelector (selector) {

	}

});
