module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<label>\n	<input type="radio" name="'+
((__t=( name ))==null?'':__t)+
'" value="'+
((__t=( leftValue))==null?'':__t)+
'" '+
((__t=(leftChecked))==null?'':__t)+
'>\n	<span>'+
((__t=( leftLabel))==null?'':__t)+
'</span>\n</label>\n<label>\n	<input type="radio" name="'+
((__t=( name ))==null?'':__t)+
'" value="'+
((__t=( rightValue))==null?'':__t)+
'" '+
((__t=(rightChecked))==null?'':__t)+
'>\n	<span>'+
((__t=( rightLabel))==null?'':__t)+
'</span>\n</label>		\n';
}
return __p;
};
