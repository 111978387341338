import _ from 'underscore';
import $ from 'jquery';
import Mn from 'backbone.marionette';
import Bb from 'backbone';
import mix from './helpers/mix';
import GetOptionProperty from './mixins/get-option-property';

const HistoryApi = mix(Mn.Object).with(GetOptionProperty).extend({
	initialize(){
		this.start();
	},
	current: undefined,
	entries: [],
	
	//check if we are in the begining of history
	canGoBack(){
		return !!this.entries.length;
	},
	
	_getBackContext(){
		let back = this.entries.pop();
		if(back == null) return;
		if(this.current){
			if(this.current.page && this.current.page == back.page)
				return this._getBackContext();
		}
		return back;
	},
	//call this when you need to return to previous item
	goBack(){
		let back = this._getBackContext();
		// this.entries.pop();
		if(back == null) return;
		let trigger = _.isString(back);
		
		Bb.history.navigate(back.fragment != null ? back.fragment : back, { trigger });

		if(!trigger && _.isFunction(back.callback))
			back.callback(back.fragment, {routeType:'back:route'});
	},
	goSilent(url, opts = {trigger: true}){
		this.silentRoute = opts;
		Bb.history.navigate(url, opts);
	},
	//this will set up new entry, read as "move forward"
	//its internal method, you never call it by itself
	_onForward(fragment, name, actionContext){
		
		if(this.silentRoute){
			if(this.silentRoute.updateCurrent)
				this.current = actionContext || fragment;		

			this.silentRoute = false;
			return;
		}
		if(this.current)
			this.entries.push(this.current);
		this.current = actionContext || fragment;
		this.trigger('change', this);
	},
	//this will move us to previous item, read as "move backward"
	//its internal method, you never call it by itself
	_onBackward(fragment, name, actionContext){
		this.current = actionContext || fragment;
		this.trigger('change', this);
	},
	//we have to listen Backbone history, because accasionally there can be a lot of routers. (realy, why we need more than one?)
	start(){
		this.listenTo(Bb.history, 'route', this._onForward);
		this.listenTo(Bb.history, 'back:route', this._onBackward);
	},
});

export default HistoryApi;
