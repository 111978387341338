import { _, moment } from 'vendors';
import '../api-config';
import BaseModel from 'base/card-model';
import BbStore from 'base/bb-store';
import paths from 'helpers/paths';
import mix from 'helpers/mix';
import EntityWithRights from 'mod/rights/mixins/entity-with-rights';
import EditableProperties from 'mixins/editable-properties';
import phoneHtml from 'helpers/phone-html';
import emailHtml from 'helpers/email-html';
import _enums from 'helpers/enums';
// import deps from 'mod/departments/singleton';
// import groups from 'mod/groups/singleton';
// import Users from 'mod/users/models/collection';
import Collection from 'base/collection';
import action from 'helpers/action';
import editValue from 'bus/edit';
// import dataRadio from 'bus/data';
import confirm from 'helpers/modals/confirm';
// import user from 'app/user';
import busData from 'bus/data';
import modalError from 'helpers/modals/error';

import AttachUser from 'mod/employees/views/attach-user';
import NestedEntitiesV2mixin from 'mixins/model/nested-entities-v2';

const dataRadio = busData;

// const deps = appBus
const user = busData.user;

function tryCall (method) {
	return _.isFunction(method) && method();
}

const PropertiesMixin = {
	properties: {
		userId: {
			editEnabled: () => user().checkRights({ employees: 'attachDetachUser' }),
			display: {
				label: 'пользователь',
				transform (v) { return v ? this.display('userName') : 'виртуальный сотрудник'; }
			}
		},
		name: {
			display: {
				transform () {
					return this.get('name.shortFull') || this.get('email') || this.id;
				}
			}
		},
		'name.last': {
			editEnabled: (m) => m.isMe() || user().checkRights({ employees: 'edit' }),
			display: {
				label: 'фамилия'
			}
		},
		'name.first': {
			editEnabled: (m) => m.isMe() || user().checkRights({ employees: 'edit' }),
			display: {
				label: 'имя'
			}
		},
		'name.middle': {
			editEnabled: (m) => m.isMe() || user().checkRights({ employees: 'edit' }),
			display: {
				label: 'отчество'
			}
		},
		'birthday.date': {
			editEnabled: (m) => m.isMe() || user().checkRights({ employees: 'edit' }),
			type: 'date',
			display: {
				label: 'день рождения',
				transform (v) {
					return v ? moment(v).format('DD MMMM YYYY') : '&ndash;';
				}
			}
		},
		email: {
			editEnabled: (m) => m.isMe() || user().checkRights({ employees: 'edit' }),
			type: 'email',
			display: {
				label: 'email',
				transform (v) {
					return v ? emailHtml(v) : '&ndash;';
				}
			}
		},
		phone: {
			editEnabled: (m) => m.isMe() || user().checkRights({ employees: 'edit' }),
			type: 'phone',
			display: {
				label: 'телефон',
				transform (v) {
					return v ? phoneHtml(v) : '&ndash;';
				}
			}
		},

		occupation: {
			editEnabled: () => user().checkRights({ employees: 'edit' }),
			display: {
				label: 'должность'
			}
		},
		roomNumber: {
			editEnabled: () => user().checkRights({ employees: 'edit' }),
			display: {
				label: 'номер кабинета'
			}
		},
		innerPhone: {
			editEnabled: () => user().checkRights({ employees: 'edit' }),
			display: {
				label: 'внутренний номер телефона'
			}
		},
		locationType: {
			editEnabled: () => user().checkRights({ employees: 'edit' }),
			type: 'enum',
			sourceValues: () => _enums.store.employeeLocations,
			display: {
				label: 'место работы',
				transform (v) {
					return _enums.get('employeeLocations', v);
				}
			}
		},
		locationNote: {
			editEnabled: () => user().checkRights({ employees: 'edit' }),
			display: {
				label: 'допонение к месту работы'
			}
		},
		note: {
			editEnabled: () => user().checkRights({ employees: 'edit' }),
			display: {
				label: 'дополнительно по сотруднику'
			}
		},
		created: {
			display: {
				label: 'дата создания',
				transform (v) {
					return v ? moment(v).format('DD MMMM YYYY, HH:mm:ss') : '&ndash;';
				},
				alternative (v) {
					return v ? moment(v).fromNow() : '';
				}
			}
		},
		modified: {
			display: {
				label: 'дата изменения',
				transform (v) {
					return v ? moment(v).format('DD MMMM YYYY, HH:mm:ss') : '&ndash;';
				},
				alternative (v) {
					return v ? moment(v).fromNow() : '';
				}
			}
		},
		hired: {
			display: {
				label: 'дата приема',
				transform (v) {
					return v ? moment(v).format('DD MMMM YYYY, HH:mm:ss') : '&ndash;';
				},
				alternative (v) {
					return v ? moment(v).fromNow() : '';
				}
			}
		},
		fired: {
			display: {
				label: 'дата увольнения',
				transform (v) {
					return v ? moment(v).format('DD MMMM YYYY, HH:mm:ss') : '&ndash;';
				},
				alternative (v) {
					return v ? moment(v).fromNow() : '';
				}
			}
		},
		status: {
			editEnabled: () => user().checkRights({ employees: 'fireHire' }),
			type: 'enum',
			sourceValues: () => _enums.store.employeeStatuses,
			display: {
				label: 'статус сотрудника',
				transform (v) {
					return _enums.get('employeeStatuses', v);
				}
			}
		},
		disabled: {
			editEnabled: () => user().checkRights({ employees: 'edit' }),
			type: 'boolean',
			sourceValues: {
				true: 'отключен',
				false: 'включен'
			},
			display: {

				label: 'системный статус',
				transform (v) {
					return v ? 'отключен' : 'включен';
				}
			}
		}
	}
};

const Model = mix(BaseModel).with(PropertiesMixin, NestedEntitiesV2mixin, EntityWithRights, EditableProperties).extend({
	cardUrlRoot: paths.url('staff:employees'),
	urlRoot: paths.api('staff:employees'),
	rightsConfig: {
		update: {
			rights: { admin: 'manageRights' }
		}
	},
	isActive () {
		return !this.isArchived() && !this.isFired() && this.isReal() && !this.isOff();
	},
	isReal () {
		return !!this.get('userId');
	},
	isVirtual () {
		return !this.isReal();
	},
	isArchived () {
		return this.get('isArchived');
	},
	isFired () {
		return this.get('status') === 'fired';
	},
	isOnline () {
		const la = Date.info(this.get('lastAction'));
		return la.valid && la.absolute.minutes < 5;
	},
	isIntern () {
		return this.get('status') === 'intern';
	},
	isOff () {
		return this.get('disabled');
	},
	isMe () {
		return user().isMe(this.id);
	},
	isMyEmployee () {
		return user().isMyEmployee(this.id);
	},
	getName () {
		return this.display('name');
	},
	getLabel () {
		return this.getName();
	},


	getDepartments () {
		const departments = dataRadio.departments().filter((d) => d.hasEmployee(this.id));
		return departments;
	},

	getDepartmentsCollection () {
		if (!this.departmentsCollection) { this.departmentsCollection = new Collection(null, { populated: true }); }

		this.departmentsCollection.reset(this.getDepartments());
		return this.departmentsCollection;
	},

	getGroups () {
		const empgroups = dataRadio.groups().filter((d) => d.hasEmployee(this.id));
		return empgroups;
	},

	getGroupsCollection () {
		if (!this.groupsCollection) { this.groupsCollection = new Collection(null, { populated: true }); }

		this.groupsCollection.reset(this.getGroups());
		return this.groupsCollection;
	},

	mainInfoCanBeChanged () {
		return busData.user().checkRights({ employees: 'edit' });
	},
	addInfoCanBeChanged () {
		return busData.user().checkRights({ employees: 'edit' });
	},
	userCanBeChanged () {
		return busData.user().checkRights({ employees: 'attachDetachUser' });
	},
	statusCanBeChanged () {
		return busData.user().checkRights({ employees: 'fireHire' });
	},
	canBeDisable () {
		return busData.user().checkRights({ employees: 'fireHire' });
	},
	actions: [
		action('detach:user', 'открепить пользователя', { employees: 'attachDetachUser' }, {
			rule () { return !!this.get('userId'); },
			places: 'page, plate'
		}),
		action('attach:user', 'прикрепить пользователя', { employees: 'attachDetachUser' }, {
			rule () { return !this.get('userId'); },
			places: 'page, plate'
		}),
		action('add:departments', 'добавить в отделы', { departments: 'manageEmployees' }, {
			places: 'page'
		}),
		action('add:groups', 'добавить в группы', { admin: 'manageGroups' }, {
			places: 'page'
		})
	],
	actionAttachUser () {
		editValue.do({
			controlView: AttachUser,
			header: 'Прикрепление пользователя',
			applyLabel: 'прикрепить',
			cancelLabel: 'отставить',
			resetButton: false
		}).then(
			(hash) => {
				if (hash.id) {
					this.post('user/' + hash.id).then((data) => {
						this.set(data, { flat: true });
					});
				} else if (hash.email) {
					this.post('joinUser?email=' + encodeURIComponent(hash.email)).then(
						(data) => {
							this.set(data, { flat: true });
						},
						(xhr) => modalError('Неудалось прикрепить пользователя', xhr)
					);
				}
			},
			() => {}
		);

		// selectDetachedUsers().then(
		// 	(id) => {
		// 		this.post('user/'+id).then((data) => {
		// 			this.set(data);
		// 			tryCall(opts.callback);
		// 		});
		// 	}
		// );
	},
	actionDetachUser (action, opts = {}) {
		confirm('Вы собираетесь открепить пользователя от сутрудника').then(
			() => this.delete('user').then((data) => {
				this.set(data);
				tryCall(opts.callback);
			}),
			() => {}
		);
	},
	addDepartments (models) {
		if (!models) return;
		const data = _(models).map((m) => _.isObject(m) ? m.id : m);
		this.post({ url: 'departments', data }).then((data) => {
			this._joinesPostProcess(data);
		});
	},
	actionAddDepartments () {
		editValue.do({
			controlType: 'departmentsSelect',
			header: 'Выберите отделы',
			multiple: true,
			excludeValues: this.getDepartmentsIds()
		}).then((values) => {
			this.addDepartments(values);
		});
	},
	getDepartmentsIds () {},
	addGroups (models) {
		if (!models) return;
		const data = _(models).map((m) => _.isObject(m) ? m.id : m);
		this.post({ url: 'departments', data }).then((data) => {
			this._joinesPostProcess(data);
		});
	},
	_joinesPostProcess (data) {
		if (data == null || !_.isObject(data)) return;
		_(data.departments || {}).each((emps, id) => updateEntity(dataRadio.departments(), id, emps));
		_(data.groups || {}).each((emps, id) => updateEntity(dataRadio.groups(), id, emps));
		function updateEntity (col, id, emps) {
			const entity = col.get(id);
			if (!entity) return;
			entity.set('employees', emps);
		}
	},
	actionAddGroups () {
		editValue.do({
			controlType: 'groupsSelect',
			header: 'Выберите группы',
			multiple: true,
			excludeValues: this.getGroupsIds()
		}).then((values) => {
			this.addDepartments(values);
		});
	},
	getGroupsIds () {}

});

const StoreModel = BbStore(Model);

export default StoreModel;
