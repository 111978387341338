import { _ } from 'vendors';
import BaseView from 'base/view';
export default BaseView.extend({
	className: 'board-list-item',
	template: _.template('<div></div>'),
	regions: {
		entity: 'div'
	},
	onRender () {
		this.showEntity();
	},
	showEntity () {
		const view = this.buildEntityView();
		this.showChildView('entity', view, { replaceElement: true });
	},
	buildEntityView () {
		const View = this.getOption('entityView');
		let options = this.getOption('entityViewOptions');
		const model = this.model.getModel();
		options = _.extend({ model }, options);
		return new View(options);
	},
	attributes: {
		'touch-action': 'none'
	}

});
