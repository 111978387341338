import Model from 'base/model';
import Collection from 'base/collection';
import NestedEntitiesV2mixin from 'mixins/model/nested-entities-v2';
import mix from 'helpers/mix';
import ActionableMixin from 'mixins/actionable';

export const BaseModel = mix(Model).with(ActionableMixin, NestedEntitiesV2mixin);

export {
	Collection
};
