import BasePage from 'pages/auth';
import { BatchModel } from 'mods/product-production/batches';
import { Layout } from './layout';

// import Layout from './layout';
// import Collection from 'base/collection';
// import { departmentsApiUrl } from './models';
// import DepartmentPage from './department';

const Page = BasePage.extend({
	// moduleRights: { productProduction: 'view' },
	// label: 'журнал производства',
	label () {
		return 'партия: ' + ((this.model && this.model.get('idString')) || '');
	},
	route: ':batchId',
	skipMenu: true,
	// faIcon: 'list-alt'
	Layout,
	onBeforeStart (ac) {
		const model = new BatchModel({ id: ac.args.batchId });
		this.addModel(model);
		this.addStartPromise(model.fetch());
	}
	// children: [DepartmentPage]
});

export default Page;
