import { _ } from 'vendors';
import Collection from 'base/collection';
import Model from 'base/model';
// import './collection';
import EditableProperties from 'mixins/editable-properties';
import Actionable from 'mixins/actionable';
import mix from 'helpers/mix';
import bus from 'bus/models';
import busData from 'bus/data';
import editValue from 'bus/edit';
import action from 'helpers/action';
// import  { SearchProducts } from './collection';
import modalError from 'helpers/modals/error';
// import smartOpen from 'helpers/smart-open';

const BaseModel = mix(Model).with(EditableProperties, Actionable);

export const JoinedProductModel = BaseModel.extend({
	properties: {
		childId: {
			controlType: 'select-api',
			modelType: 'single',
			multiple: false,
			// sourceValues: () => new bus.request('products:search'),
			controlOptions: () => ({
				fetchAtStart: false,
				apiSearch: true,
				collection: bus.request('new:products:search')
				// ((Collection) => new Collection())(bus.request('products:search')),
			}),
			display: {
				label: 'продукция',
				transform: v => (v && bus.request('new:products:model', { id: v }).getLabel()) || undefined,
				ifEmpty: '&mdash;'
			}
		},
		parentId: {
			controlType: 'select-api',
			modelType: 'single',
			multiple: false,
			// sourceValues: () => new bus.request('products:search'),
			controlOptions: () => ({
				fetchAtStart: false,
				apiSearch: true,
				collection: bus.request('new:products:search')
				// ((Collection) => new Collection())(bus.request('products:search')),
			}),
			display: {
				label: 'продукция',
				transform: v => (v && bus.request('new:products:model', { id: v }).getLabel()) || undefined,
				ifEmpty: '&mdash;'
			}
		},
		'production.amount': {
			type: 'number',
			display: {
				label: 'количество'
			}
		},
		'production.defectPercent': {
			type: 'number',
			display: {
				label: 'процент брака',
				ifEmpty: '&mdash;'
			}
		},
		'production.note': {
			type: 'text',
			display: {
				label: 'примечание, памятка',
				ifEmpty: '&mdash;'
			}
		}
	},
	getLabel () {
		let label = this._getLabel('child');
		if (!label) {
			label = this._getLabel('parent');
		}
		return label;
	},
	_getLabel (type) {
		const entity = this.get(type);
		if (!entity) return;
		return entity.fullName || this.display(type + 'Id');
	},
	_getJoinedEntityId () {
		const something = this.get('child') || this.get('parent') || this.get('childId') || this.get('parentId');
		if (_.isObject(something)) {
			return something.id;
		} else if (_.isString(something)) {
			return something;
		}
	},
	_getJoinedEntity () {
		let entity = this.get('child') || this.get('parent');
		if (!entity) {
			entity = { id: this._getJoinedEntityId() };
		}
		const model = bus.request('new:products:model', entity);
		return model;
	},
	getMeasureLabel (unitId) {
		!unitId && (unitId = this.get('child.unitMeasureId'));
		// let model = this._getJoinedEntity();
		return busData.measures(unitId, 'short');
	},
	openEntity (event) {
		const id = this._getJoinedEntityId();
		if (!id) return;
		bus.request('open:product:page', id, event);
		// let entity = bus.request('new:products:model', { id });
		// let url = entity.cardUrl();
		// if (!url) return;
		// smartOpen(url, event);
	},

	actions: [
		action('edit:produced:from', 'изменение составляющей продукции', { resourcesProduction: 'productsEdit' })
	],
	actionEditProducedFrom () {
		editValue.do({
			header: 'изменение состовляющей продукции',
			controlView: bus.request('products:joinedProductEditView'),
			applyLabel: 'закрыть',
			value: this,
			cancelButton: false,
			// cancelLabel: 'отставить',
			resetLabel: 'удалить',
			resetButtonCssClass: 'delete-button',
			resetButtonConfirm: 'Вы собираетесь исключить присоединённую продукцию'
		}).then(
			() => {},
			(reject) => {
				if (reject.reason === 'reset') {
					this.destroy({ wait: true }).then(
						() => {},
						xhr => modalError('не удалось исключить присоединённую продукцию', xhr)
					);
				}
			}
		);
	}

});

export const JoinedProductsCollection = Collection.extend({
	model: JoinedProductModel,
	url () {
		return this.parent.url() + '/joinedProducts';
	}

});

