function normalizeTxt(txt) {
	if (txt == null) { return ''; }
	
	if (typeof txt !== 'string') {
		txt = txt.toString();
	}

	return txt;
}

export function toUpperFirst(txt) {
	txt = normalizeTxt(txt);
	if (txt === '') { return txt; }
	return txt[0].toUpperCase() + txt.substring(1);
}

export function toLowerFirst() {
	txt = normalizeTxt(txt);
	if (txt === '') { return txt; }
	return txt[0].toLowerCase() + txt.substring(1);
}