import BaseView from 'base/search-collection-view';
import View from 'components/ui-card/item';
import BaseListItem from 'components/ui-list/item';
import ActionsPopover from 'behaviors/actions-popover';

const ListItem = BaseListItem.extend({
	renderOnModelChange: true,
	behaviors: [ActionsPopover.extend({ placedAt: 'plate' })],
	// topText(){
	// 	let leads = this.model.getLeads();
	// 	return _(leads).map((l) => l.display('name')).join(', ');
	// },
	text: m => m.get('name'),
	smallText () {
		// let emps = this.model.getEmployees();
		// return emps.map((e) => e.display('name')).join(', ');
	},
	bottomText () {
		// return this.model
		// 	.getRights()
		// 	.getFilledNames()
		// 	.join(', ');
	},
	// editButton: true,
	textAction: 'open:card'
});

const CardItem = View.extend({
	cardLines: {
		modelView: { viewClass: ListItem }
	}
});



export default BaseView.extend({
	// className:'',
	// collection: dataBus.groups(),
	childView: CardItem

});

