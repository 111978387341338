import Views from 'mod/employees/views';
import getRawFilters from 'mod/employees/filters/get';
import mix from 'helpers/mix';
import FDC from 'mixins/view/flex-dynamic-column';
import comparator from 'helpers/comparator';
import ViewManagedCollection from 'mixins/view-managed-collection';

const View = mix(Views.List).with(FDC, ViewManagedCollection).extend({
	id: 'staf-emps-list',
	className: 'employee-list',
	entriesManagerOptions: {
		infoOptions: true,
		filtersOptions: {
			getRaw: getRawFilters,
			instantApply: true
		}
	},
	viewComparator (v1, v2) {
		return comparator([v1, v2, (m) => m.display('name')]);
	},
	childViewOptions (a, b) {
		return {
			textAction: 'open:card'
		};
	}
});

export default View;
