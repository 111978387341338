import {
	/* actionsBlock, stagesBlock, goalsBlock, checklistBlock, tasksBlock, */
	mainBlock, activityBlock, teamBlock,
	secondInfoBlock
} from '../common-blocks';

import { productionContragentBlock, cycleBlock } from './blocks';


export function initializeProductionLayout (view) {
	mainBlock(view);
	secondInfoBlock(view);
	cycleBlock(view);
	productionContragentBlock(view);
	activityBlock(view);
	teamBlock(view);
}
