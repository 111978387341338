import Collection from 'base/collection';
import { ProdCycleProdModel } from './prodCycleProd-model';
import { componentComparator } from 'mod/productionProducts/models/prod-cycle/prodCycleProd-model';
export const ProdCycleProdsCollection = Collection.extend({
	model: ProdCycleProdModel,
	addProduct (data) {
		return this.create(data, { preloader: true, wait: true });
	},
	compare (a, b) {
		return componentComparator(a, b);

		// let ao = a.get('order') || 0;
		// let bo = b.get('order') || 0;

		// if (ao != bo) {
		// 	return bo - ao;
		// }

		// let index = a.getComponentTypeInt() - b.getComponentTypeInt();
		// if (index !== 0) {
		// 	return index;
		// }
		// let aName = a.getProductName();
		// let bName = b.getProductName();

		// return aName < bName ? -1
		// 	: aName === bName ? 0
		// 	: 1;
	}
});


export function buildCollection (model) {
	const models = [];
	const col = new ProdCycleProdsCollection(models, { parent: model });
	col.url = model.url() + '/components';
	return col;
}

export function getCollection (model) {
	if (!model.prodsCollection) {
		model.prodsCollection = buildCollection(model);
	}
	return model.prodsCollection;
}



// function deprecated() {
// let chars = 'abcdefghijk';
// for(let x = 1; x < 50; x++) {
// 	let amount = Math.random() * 100 >> 0;
// 	let id = chars[(Math.random() * (chars.length - 1) >> 0)] +
// 	chars[(Math.random() * (chars.length - 1) >> 0)] +
// 	chars[(Math.random() * (chars.length - 1) >> 0)] + '-' +
// 	chars[(Math.random() * (chars.length - 1) >> 0)] +
// 	chars[(Math.random() * (chars.length - 1) >> 0)] + '-' + x;
// 	models.push({ id, amount });
// }
// }
