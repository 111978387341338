import action from 'helpers/action';
import smartOpen from 'helpers/smart-open';

export default (Base) => Base.extend({
	constructor: function (...args) {
		Base.apply(this, args);
		this.initCardable();
	},
	initCardable () {
		this._actions || (this._actions = []);
		this._actions.push(action('open:card', 'показать', null, { disallowPlaces: 'page' }));
		this._actions.push(action('open:card:newtab', 'открыть в новой вкладке', null, { disallowPlaces: 'page' }));
	},
	actionOpenCard (action, opts) {
		const url = this.cardUrl();
		smartOpen(url, opts);
	},
	actionOpenCardNewtab (action, opts = {}) {
		opts.ctrlKey = true;
		const url = this.cardUrl();
		smartOpen(url, opts);
	},
	cardUrl () {
		return this.cardUrlRoot + '/' + this.getFineId();
	}

});
