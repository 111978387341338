import { _ } from 'vendors';
import View from 'base/view';
import { md } from '../markdown-it';

const MarkdownHelp = View.extend({
	className: 'modal-markdown-help',
	terms: [
		'# текст заголовка',
		'## текст подзаголовка',
		'### текст подподзаголовка',
		'**жирно**',
		'*наклонно*',
		'~~зачёркнуто~~',
		'`выделение`',
		`> цитируемый текст.
бывает содержит несколько
строк`
	],
	getTemplate () {
		const str = _.reduce(this.terms, (memo, term) => {
			// console.log(md.parse(term));
			memo += `<div class="markdown-item"><div>${term}</div><div>${md.render(term)}</div></div>`;
			return memo;
		}, '');
		return _.template(str);
	}
});

export default MarkdownHelp;
