import { _ } from 'vendors';
import Grouper from 'components/collection-group';
import Base from '../entries';
import Column from './column';
import BadColumn from './bad-column';
import CollectionClass from './column-collection';
export default Base.extend({

	comparatorKey: 'remindAt',
	newInTop: false,

	fetchNext () {},

	afterInitialize () {
		this.hiddenCollection = this.getOption('entries');
		this.initGrouping();
		this.createViews();
	},
	getCollection () {
		return this.hiddenCollection;
	},
	initGrouping () {
		this.grouper = new Grouper({
			context: this,
			collection: this.getCollection(),
			groups: this.getOption('groups'),
			isInContext: model => this.modelInView(model),
			CollectionClass,
			postProcessOut: out => this.triggerMethod('models:out', out)
		});
	},
	getDateRange () {
		if (this.rangeValue) return this.rangeValue;
		this.rangeValue = this.model.getDateRange();
		return this.rangeValue;
	},
	onModelsOut (out) {
		const add = _(out || []).filter(f => this.modelIsInBadColumn(f));
		if (!add.length) return;
		const bad = this.getOption('badEntries');
		bad.add(add);
	},
	modelIsInBadColumn (arg) {
		const model = _.isModel(arg) ? arg : arg.model;
		const res = this.modelIsBad(model) && this.modelCondition(model) && this.modelIsVisible(model);
		return res;
	},
	createViews () {
		if (this.model.get('state') === 'active') {
			// this.customViews.push
			this.addCustomView(new BadColumn({
				model: this.model,
				collection: this.getOption('badEntries'),
				customOrder: -1,
				entries: this.getOption('entries'),
				viewFilter: v => this.modelIsInBadColumn(v)
			}));
		}

		const groups = this.grouper.getGroups();
		let i = 0;
		_(groups).each((group) => {
			i++;
			// this.customViews.push
			this.addCustomView(new Column({
				group,
				collection: group.collection,
				customOrder: i,
				viewFilter: v => this.modelInView(v.model),
				comparatorKey: this.getOption('comparatorKey'),
				newInTop: this.getOption('newInTop')
			}));
		});
	},
	onBeforeDestroy () {
		this.grouper.destroy();
		delete this.grouper;
	},
	createGroup (date, hash, groups) {
		if (!this._isCreatable(date)) return;
		if (_.isArray(groups)) { groups.push(hash); }
		return hash;
	},
	_isCreatable (date) {
		const range = this.getDateRange();
		const isActive = this.model.get('state') === 'active';
		const now = new Date();
		const doNotCheckDate = !isActive || now < range.from;
		return doNotCheckDate || date >= now;
	}
});
