import { _ } from 'vendors';
function arrayToDate (date, source) {
	if (_.isDate(date)) { return date; } else if (_.isArray(date)) {
		const d = Date.toDay(source);
		d.setHours(...date);
		return d;
	}
}
function between (date, from, to) {
	from = arrayToDate(from, date);
	to = arrayToDate(to, date);
	return date >= from && date <= to;
}

export default between;
