import { _ } from 'vendors';
import unflat from 'helpers/unflat';
import flat from 'helpers/flat';

const notMergable = function (arg) {
	return arg == null || _.isSimple(arg) || _.isFunction(arg) || _.size(arg) === 0;
};

const mergeWith = function (dst, src) {
	if (notMergable(src)) return dst;

	_(src).each((value, key) => {
		if (notMergable(value)) { dst[key] = value; } else {
			dst[key] = {};
			mergeWith(dst[key], unflat(value));
		}
	});

	return dst;
};


function mergeTwo (itemA, itemB) {
	if (!_.isObject(itemA))	return;
	!_.isObject(itemB) && (itemB = {});
	itemA = flat(itemA);
	itemB = flat(itemB);
	return unflat(_.extend(itemA, itemB));
}

export function mergeAlt (...items) {
	return _.reduce(items, (memo, item) => {
		return mergeTwo(memo, item, { noClone: true });
	}, {});
}

export default function merge (dst, ...srcs) {
	if (!srcs) return dst;
	const mergable = srcs.filter(s => !notMergable(s));
	if (!mergable.length) return dst;

	dst || (dst = {});
	// var memo = mergeWith({}, dst);
	// return _.reduce(mergable, (memo, item) => {
	// 	mergeWith(memo, item);
	// 	return memo;
	// }, memo);
	_(srcs).each((src) => mergeWith(dst, unflat(src)));

	return dst;
}
