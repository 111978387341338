import '../api-config';
import BaseModel from 'base/card-model';
import BbStore from 'base/bb-store';
import paths from 'helpers/paths';
import mix from 'helpers/mix';

import EntityWithRights from 'mod/rights/mixins/entity-with-rights';
import EntityWithEmployees from 'mod/employees/mixins/entity-with-employees';
import EditableProperties from 'mixins/editable-properties';
import NestedEntitiesV2mixin from 'mixins/model/nested-entities-v2';
import action from 'helpers/action';
// import editValue from 'components/edit-value';
import appBus from 'bus/app';
import data from 'bus/data';
import confirm from 'helpers/modals/confirm';
import user from 'app/user';

const PropertiesMixin = {
	properties: {
		name: {
			type: 'text',
			display: {
				label: 'название отдела'
			}
		}
	}
};

const MixedBaseModel = mix(BaseModel).with(PropertiesMixin, EditableProperties, NestedEntitiesV2mixin, EntityWithRights, EntityWithEmployees);
const Model = MixedBaseModel.extend({
	cardUrlRoot: paths.url('staff:departments'),
	urlRoot: paths.api('staff:departments'),

	getLeads () {
		const emps = this.getEmployees();
		const leads = emps.filter((f) => f.get('role') === 'lead');
		return leads;
	},
	getName () {
		return this.display('name');
	},
	getParent () {
		const id = this.get('parentId');
		if (id == null) return;
		const parent = data.departments(id);
		return parent || false;
	},
	isNotDeleted () {
		if (this.get('deleted')) { return false; }

		const parent = this.getParent();

		if (parent === false) { return false; }



		if (parent != null) { return parent.isNotDeleted(); }

		return true;
	},
	// getLabel(){
	// 	return this.getName();
	// }
	infoCanBeChanged () {
		return user.checkRights({ departments: 'edit' });
	},
	actions: [
		action('add:department', 'добавить подотдел', { departments: 'createDelete' }, { places: 'page' }),
		action('remove:department', 'удалить этот отдел', { departments: 'createDelete' }, { places: 'page' })
	],
	rightsConfig: {
		update: {
			rights: { admin: 'manageRights' }
		}
	},
	employeesActionsConfig: {
		add: {
			rights: { departments: 'manageEmployees' }
		},
		remove: {
			rights: { departments: 'manageEmployees' }
		},
		changeRole: {
			rights: { departments: 'manageEmployees' }
		}
	},
	actionAddDepartment () {
		const parentId = this.id;
		const collection = this.collection;
		collection.tryAddDepartment({
			header: 'Укажите название подотдела',
			parentId
		});
	},
	actionRemoveDepartment () {
		confirm('Вы собираетесь удалить отдел ' + this.display('name')).then(() => {
			this.destroy({ wait: true });
			appBus.goUp();
		});
		// let parentId = this.id;
		// let collection = this.collection;
		// editValue({
		// 	header: 'укажите название отдела',
		// 	valueType:'text',
		// 	modelType:'single',
		// 	editOptions:{
		// 		resetButton: false,
		// 		applyLabel: 'добавить',
		// 		cancelLabel: 'не добавлять'
		// 	}
		// }).then((name) => {
		// 	let newModel = new Model({name, parentId});
		// 	newModel.save(null,{wait:true}).then(() => collection.add(newModel), () => {});
		// }, () => {});
	}

});

// Model

const StoreModel = BbStore(Model, 'department');


export default StoreModel;
