import { MeasuredValueModel } from './model';
import { InputMeasuredValueView } from './view.edit';
import editValue from 'bus/edit';

export function editMeasuredValueInModal (baseMeasureId, providedOptions) {
	const modelHash = typeof (baseMeasureId) === 'object' ? baseMeasureId : { baseMeasureId };

	if (!modelHash.baseMeasureId) {
		throw new Error('baseMeasureId not provided');
	}

	if (modelHash.storeMeasureId && !modelHash.baseUnitsInStoreUnit) {
		throw new Error('storeMeasureId was provided without baseUnitsInStoreUnit');
	}
	console.log('## model hash', modelHash);
	const model = new MeasuredValueModel(Object.assign({
		inputMeasureId: modelHash.baseMeasureId,
		baseUnitsInInputUnit: 1,
		header: undefined,
		baseAmount: undefined
	}, modelHash));

	console.log('## model', model);

	const defOptions = {
		header: (modelHash.header ? modelHash.header + (modelHash.baseAmount ? ` (${modelHash.baseAmount})` : '') : 'Добавление партии'),
		controlView: InputMeasuredValueView,
		controlViewOptions: {
			model
		},
		applyLabel: 'далее',
		resetButton: false,
		cancelLabel: 'отставить'
	};

	const options = Object.assign({}, defOptions, providedOptions);

	return editValue.do(options);
}
