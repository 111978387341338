import { _ } from 'vendors';
import BaseView from 'pages/view';
// import View from 'base/view';
// import CollectionView from 'base/collection-view';

import ModelProperties from 'components/ui-model-card/properties';
import ModelActions from 'components/ui-model-card/actions';
import CardBlock from 'components/ui-card-block/block';
import h from 'components/ui-model-card/helpers';

import createNestedContragent from 'mod/contragents/views/nested/create';
import createNestedProcess from 'mod/commonProcesses/views/nested/create';

import ToDoBlock from 'mod/tasks/views/todo';
import TransportPoints from 'mod/tasks/views/transportRequest/transportPoints';

// import createLogs from 'components/logs/views/create-nested';

import { ActivityListUiBlock } from 'mods/logs/views';
import nestedEmployees from 'mod/employees/views/entity-nested-employees';

import mix from 'helpers/mix';
// import ViewSocketsHandler from 'components/sockets/view-handler-mixin';

import SocketEntityEvents from 'components/sockets/entity-events-mixin';

import { FileService, selectFiles } from 'components/files';
// import paths from 'helpers/paths';
// const TimeSpan = window.TimeSpan;

import busEdit from 'bus/edit';

import { createPurchaseRequestBlockContext } from 'mod/tasks/views/purchaseRequest/in-task/block-view';

import { HamburgerContentView } from 'pages/views';

function transportAddressEdit (field, cb) {
	busEdit.do({
		header: 'Изменение адреса',
		applyOnChange: false,
		destroyOnChange: false,
		controlType: 'address',
		controlOptions: {
			contragent: true,
			predefinedValues: [
				{
					key: 'ГК Пальма, на нагорном',
					value: {
						address: 'Россия, Москва, Нагорный проезд, 12к1',
						lat: 55.685706,
						lng: 37.606963
					}
				}
			]
		},
		cancelLabel: 'отставить',
		// returnPromise: true,
		value: Object.assign({}, this.model.get('typeDetails.' + field))
	}).then(data => {
		const res = _.extend({}, this.model.get('typeDetails'));
		res[field] = _.clone(data);
		const cn = data && data.contragent && data.contragent.contactName;
		cn && (res.contactName = cn);
		cb(res);
	}).catch((err) => {
		if (err.reason === 'cancel') return;
		const res = _.extend({}, this.model.get('typeDetails'));
		res[field] = {};
		cb(res);
	});
}


// export default 
export default mix(BaseView).with(SocketEntityEvents).extend({
	className: 'tasks-task',
	template: BaseView.emptyTemplate,
	modelEvents: {
		'open:selectFilesToUpload' () {
			selectFiles(blobs => this.fileSvc.addBlobs(blobs));
		}
	},
	getTransportRequestBlock () {
		if (this.model.get('form') !== 'transportRequest') return undefined;
		const details = this.model.get('typeDetails');
		if (!details) return;
		// console.log('>>>', details);
		const context = {
			viewClass: CardBlock,
			viewOptions: {
				cssClassModifiers: ['transportrequest-container'],
				header: { text: 'детали заявки', addClass: 'bg-blue' },
				content: TransportPoints,
				contentOptions: {
					model: this.model,
					details,
					points: details.points,
					modelEvents: {
						'change:typeDetails' () {
							this._reinitCollection();
						}
					}
				},
				action: ['print:transport:request', 'edit:transport:request']
			}
		};
		return context;
	},
	getTransportRequestProps () {
		if (this.model.get('form') !== 'transportRequest') return [];
		const updateTypeDetails = (typeDetails) => {
			this.model.save(null, { attrs: { typeDetails }, method: 'patch', wait: true }).then(() => {
				this.model.set({ typeDetails });
			}).catch(err => {
				console.log(err);
			});
		};
		return [
			h.ui('typeDetails.fromAddress', {
				editButton: m => m.isNotClosed() && m.isMy(),
				onEditClick () {
					transportAddressEdit.call(this, 'fromAddress', updateTypeDetails);
				}
			}),
			h.ui('typeDetails.toAddress', {
				editButton: m => m.isNotClosed() && m.isMy(),
				onEditClick () {
					transportAddressEdit.call(this, 'toAddress', updateTypeDetails);
				}
			}),
			h.ui('typeDetails.contactName', {
				editButton: m => m.isNotClosed() && m.isMy(),
				onEditClick () {
					busEdit.do({
						header: 'контактное лицо',
						cancelLabel: 'отставить',
						value: this.model.get('typeDetails.contactName')
					}).then(data => {
						const res = _.extend({}, this.model.get('typeDetails'));
						res.contactName = data;
						updateTypeDetails(res);
					}).catch(err => {
						if (err.reason === 'cancel') return;
						const res = _.extend({}, this.model.get('typeDetails'));
						res.contactName = undefined;
						updateTypeDetails(res);
					});
				}
			}),
			h.ui('typeDetails.cargo', {
				editButton: m => m.isNotClosed() && m.isMy(),
				onEditClick () {
					busEdit.do({
						header: 'опишите груз',
						cancelLabel: 'отставить',
						value: this.model.get('typeDetails.cargo')
					}).then(data => {
						const res = _.extend({}, this.model.get('typeDetails'));
						res.cargo = data;
						updateTypeDetails(res);
					}).catch(err => {
						if (err.reason === 'cancel') return;
						const res = _.extend({}, this.model.get('typeDetails'));
						res.cargo = undefined;
						updateTypeDetails(res);
					});
				}
			})
		];
	},
	getPurchaseRequestBlock () {
		if (this.model.get('form') !== 'purchaseRequest') return undefined;
		const details = this.model.get('typeDetails');
		// console.log('DET ITEMS', details);
		if (!details) return;

		return createPurchaseRequestBlockContext(this.model, details);
	},
	initialize () {
		const m = this.model;
		const transportRequestBlock = this.getTransportRequestBlock();
		const purchaseRequestBlock = !transportRequestBlock && this.getPurchaseRequestBlock();
		const notImportantIfPurchase = !!purchaseRequestBlock;

		const itr = !!transportRequestBlock;

		const doNotShowActivity = !!purchaseRequestBlock;

		// let transportRequestProps = this.getTransportRequestProps();

		this.createNested('main', {
			viewClass: ModelProperties.extend({ className: 'card lined with-border' }),
			viewOptions: {
				header: { text: 'id: #' + m.id, addClass: '' },
				flatPatch: true,
				properties: [
					!itr && h.ui('toDo', { editButton: (m) => m.isToDoCanBeChanged() }),
					m.hasResource() && h.ui('resource.type', {
						editButton: false,
						clickable: true,
						className: 'whole-btn',
						onTextClick (e) {
							m.openResourcePage(e);
						}
					}),
					h.ui('dates.remindAt', {
						editButton: m => m.isMy(),
						cssClassModifiers: [(mm) => mm.isNotClosed() && mm.isInPast() ? 'past' : mm.isNotClosed() && mm.isNear() ? 'near' : '']
					}),
					h.ui('dates.deadline', {
						editButton: (m) => m.isDeadlineCanBeChanged(),
						cssClassModifiers: [(mm) => mm.isNotClosed() && mm.isInPast() ? 'past' : mm.isNotClosed() && mm.isNear() ? 'near' : '']
					}),
					// ...transportRequestProps,
					h.ui('description', { editButton: (m) => m.isToDoCanBeChanged() }),
					h.ui('aproximateDuration', {
						editButton: m => m.isMy(),
					}),
					h.ui('urgentImportantPriority', {
						editButton: m => m.isEditable(),
					}),
					h.ui('numericPriority', {
						editButton: m => m.isEditable(),
					}),
					
					// h.ui('type', {editButton: (m) => m.isTypeCanBeChanged(), half:true } ),
					// h.ui('dates.strictDeadline', {editButton:(m) => m.isStrictCanBeChanged(), half:true } ),
					// h.ui('state', {
					// 	half: true,
					// 	cssClassModifiers: [(mm) => mm.get('result'), (mm) => mm.get('state')]
					// }),
					// h.ui('stateDate', { half: true }),
				]
			},
			regionTemplate: { replaceElement: true }


		});

		this.fileSvc = new FileService({
			shouldUseNotifier: true,
			shouldUseUploadOnNewFiles: true,
			uploadOptions: {
				url: _.result(this.model, 'url') + '/upload'
			}
		});
		this.on('destroy', () => this.fileSvc.destroy());
		this.on('render', () => this.fileSvc.start());


		if (transportRequestBlock) {
			this.createNested('transportRequest', transportRequestBlock);
		}

		this.createNested('third', {
			viewClass: ModelActions.extend({ className: 'card lined with-border' }),
			viewOptions: {
				actions: ['upload:files', 'accept', 'return:to:work', 'check:fail', 'put:on:hold', 'cancel', 'complete', 'take'],
				emptyViewText: (m) => m.isClosed() ? 'задача завершена' : 'нет доступных действий'
			},
			regionTemplate: { replaceElement: true }


		});

		this.createNested('todoPane', {
			viewClass: ToDoBlock,
			viewOptions: {
				// header: 'Вехи',
				// modelEvents: {
				// 	'new:state'(){
				// 		this.render();
				// 	}
				// },
				rights: {
					change: m.isEditable(true),
					delete: m.isEditable(true)
				},
				canBeSwapped () {
					return m.isEditable(true);
				}
			}

		});

		if (purchaseRequestBlock) {
			this.createNested('purchaseRequest', purchaseRequestBlock);
		}

		if (!doNotShowActivity) {
			this.createNested('activity', {
				viewClass: ActivityListUiBlock,
				viewOptions: {
					model: this.model,
					fileSvc: this.fileSvc,
					logConfig: {
						entities: []
					}
				}

			});
		}

		createNestedProcess(this, this.model.getProcess({ fetch: true }));
		createNestedContragent(this, this.model.getContragent({ fetch: true }));



		this.createNested('main2', {
			viewClass: ModelProperties.extend({ className: 'card lined with-border' }),
			viewOptions: {
				flatPatch: true,
				properties: [
					h.ui('type', { editButton: (m) => m.isTypeCanBeChanged(), half: true }),
					h.ui('dates.strictDeadline', { editButton: (m) => m.isStrictCanBeChanged(), half: true }),
					h.ui('state', {
						half: true,
						cssClassModifiers: [(mm) => mm.get('result'), (mm) => mm.get('state')]
					}),
					h.ui('stateDate', { half: true })
				]
			},
			regionTemplate: { replaceElement: true }


		});

		this.createNested('second', {
			viewClass: ModelProperties.extend({ className: 'card lined with-border' }),
			viewOptions: {
				// header:{ text: 'а так же', addClass:'bg-main-dark'},
				properties: [
					h.ui('responsibleId', { editButton: (m) => m.isEditable(notImportantIfPurchase), half: true }),
					h.ui('modified', { half: true }),
					h.ui('checkerId', { editButton: (m) => m.isEditable(), half: true }),
					h.ui('dates.onCheck', { half: true }),
					h.ui('creatorId', { half: true }),
					h.ui('created', { half: true })
				]
			},
			regionTemplate: { replaceElement: true }

		});

		this.createNested('employees',
			nestedEmployees(m,
				{
					type: 'taskObservers',
					viewOptions: {
						className: 'nested-employees',
						modelEvents: {
							'new:state' () {
								this.render();
							}
						}
					}
				},
				{
					viewOptionsHeader: 'Наблюдатели',
					addChildViewOptions: {
						// bottomText: m => enums.get('processEmployeeRoles',m.get('joinedAs')),
						optionsButton: false, // (em) => em.isMe(),
						// closeButton: employee => employee.isMe() || m.isMy(),
						cssClassModifiers: [
							'task-observer'
							// m => m.get('joinedAs')
						],
						employeeId: v => v.model.get('employeeId')
					},
					contentOptions: {
						searchDisabled: true
					}

				}
			)
		);


	},
	onRender () {
		this.showAllNested();

		this.updateStatus();
	},
	updateStatus () {
		if (this.model.isInitial() && this.model.isMy()) {
			this.model.actionAccept();
		}
	},
	socketEntityEvents: {
		'task:changed task:deadline:changed task:responsible:changed task:checker:changed' (ev) {
			this.model.set(ev.data, { flat: true });
		}
	}
});


HamburgerContentView.extend({
	template: `
	<h1>хохо!</h1>
	<section>
		<headerView />
		<hr/>
		<footerView />
	</section>
	<article></article>
	`,
	childViewContainer: 'article',
	childViewContainerPrepend: true,
	initialize() {
		this.fileSvc = new FileService({
			shouldUseNotifier: true,
			shouldUseUploadOnNewFiles: true,
			uploadOptions: {
				url: _.result(this.model, 'url') + '/upload'
			}
		});
		this.on('destroy', () => this.fileSvc.destroy());
		this.on('render', () => this.fileSvc.start());
	},
	// getChildrenViews() {

	// 	const m = this.model;
	// 	const transportRequestBlock = this.getTransportRequestBlock();
	// 	const purchaseRequestBlock = !transportRequestBlock && this.getPurchaseRequestBlock();
	// 	const notImportantIfPurchase = !!purchaseRequestBlock;

	// 	const itr = !!transportRequestBlock;

	// 	const doNotShowActivity = !!purchaseRequestBlock;
	// 	const falsy = true;
	// 	return [
	// 		falsy ? {
	// 			class: ModelProperties,
	// 			viewName: 'footerView',
	// 			className: 'card lined with-border',
	// 			header: { text: 'id: #' + m.id, addClass: '' },
	// 			flatPatch: true,
	// 			properties: [
	// 				!itr && h.ui('toDo', { editButton: (m) => m.isToDoCanBeChanged() }),
	// 				m.hasResource() && h.ui('resource.type', {
	// 					editButton: false,
	// 					clickable: true,
	// 					className: 'whole-btn',
	// 					onTextClick (e) {
	// 						m.openResourcePage(e);
	// 					}
	// 				}),
	// 				h.ui('dates.remindAt', {
	// 					editButton: m => m.isMy(),
	// 					cssClassModifiers: [(mm) => mm.isNotClosed() && mm.isInPast() ? 'past' : mm.isNotClosed() && mm.isNear() ? 'near' : '']
	// 				}),
	// 				h.ui('dates.deadline', {
	// 					editButton: (m) => m.isDeadlineCanBeChanged(),
	// 					cssClassModifiers: [(mm) => mm.isNotClosed() && mm.isInPast() ? 'past' : mm.isNotClosed() && mm.isNear() ? 'near' : '']
	// 				}),
	// 				// ...transportRequestProps,
	// 				h.ui('description', { editButton: (m) => m.isToDoCanBeChanged() })
	// 				// h.ui('type', {editButton: (m) => m.isTypeCanBeChanged(), half:true } ),
	// 				// h.ui('dates.strictDeadline', {editButton:(m) => m.isStrictCanBeChanged(), half:true } ),
	// 				// h.ui('state', {
	// 				// 	half: true,
	// 				// 	cssClassModifiers: [(mm) => mm.get('result'), (mm) => mm.get('state')]
	// 				// }),
	// 				// h.ui('stateDate', { half: true }),
	// 			]
	// 		} : []
	// 	]
	// },

	childrenViews: {
		nonheaderView: v => v.getMainBlock(),
		actionsBlock: v => v.getActionsBlock()
	},

	getMainBlock() {
		const v = this;
		return {
			class: ModelProperties,
			// viewName: 'footerView',
			className: 'card lined with-border',
			header: { text: 'id: #' + v.model.id, addClass: '' },
			flatPatch: true,
			properties: [
				//!itr && h.ui('toDo', { editButton: (m) => m.isToDoCanBeChanged() }),
				v.model.hasResource() && h.ui('resource.type', {
					editButton: false,
					clickable: true,
					className: 'whole-btn',
					onTextClick (e) {
						m.openResourcePage(e);
					}
				}),
				h.ui('dates.remindAt', {
					editButton: m => m.isMy(),
					cssClassModifiers: [(mm) => mm.isNotClosed() && mm.isInPast() ? 'past' : mm.isNotClosed() && mm.isNear() ? 'near' : '']
				}),
				h.ui('dates.deadline', {
					editButton: (m) => m.isDeadlineCanBeChanged(),
					cssClassModifiers: [(mm) => mm.isNotClosed() && mm.isInPast() ? 'past' : mm.isNotClosed() && mm.isNear() ? 'near' : '']
				}),
				// ...transportRequestProps,
				h.ui('description', { editButton: (m) => m.isToDoCanBeChanged() })
				// h.ui('type', {editButton: (m) => m.isTypeCanBeChanged(), half:true } ),
				// h.ui('dates.strictDeadline', {editButton:(m) => m.isStrictCanBeChanged(), half:true } ),
				// h.ui('state', {
				// 	half: true,
				// 	cssClassModifiers: [(mm) => mm.get('result'), (mm) => mm.get('state')]
				// }),
				// h.ui('stateDate', { half: true }),
			]
		}
	},
	getActionsBlock() {
		return {
			class: ModelActions.extend({  }),
			className: 'card lined with-border',
			actions: ['upload:files', 'accept', 'return:to:work', 'check:fail', 'put:on:hold', 'cancel', 'complete', 'take'],
			emptyViewText: (m) => m.isClosed() ? 'задача завершена' : 'нет доступных действий'
		}
	},

	_isFormOf(formType) {
		return this.model.get('form') === formType;
	},

	isTransportRequest() {
		return this._isFormOf('transportRequest');
		// this.model.get('form') === 'transportRequest';
	},
	isPurchaseRequest() {
		return this._isFormOf('purchaseRequest');
	},

	getTransportRequestBlock() {
		if (!this.isTransportRequest()) { return; }

		const details = this.model.get('typeDetails');
		if (!details) return;
		// console.log('>>>', details);

		const context = {
			class: CardBlock,
			cssClassModifiers: ['transportrequest-container'],
			header: { text: 'детали заявки', addClass: 'bg-blue' },
			content: TransportPoints,
			contentOptions: {
				model: this.model,
				details,
				points: details.points,
				modelEvents: {
					'change:typeDetails' () {
						this._reinitCollection();
					}
				}
			},
			action: ['print:transport:request', 'edit:transport:request']
		};

		return context;
	},
	getPurchaseRequestBlock() {
		if (!this.isPurchaseRequest()) { return; }

		const details = this.model.get('typeDetails');
		if (!details) return;
		
		const context = createPurchaseRequestBlockContext(this.model, details);

		if (context) {
			return {
				class: context.viewClass,
				...(context.viewOptions || {})
			}
		}
		
	},

});