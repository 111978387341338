import { _ } from 'vendors';
import 'mods/processes/common/models/processes-board';
import BasePage from 'pages/auth';
import Layout from './layout';
import NewLayout from './new-layout';
import Model from 'mod/commonProcesses/models/process';
import busModels from 'bus/models';
import ProcessTasksBoard from './tasksBoard';
import { BaseProcessPage } from './BaseProcessPage';
import { activityContent, goalsContent, mainContent, subProcsContent, tasksContent, teamContent } from './common-blocks';
import { productsToBuyContent } from './common-blocks/productsToBuy';
import { productionContragentContent } from './production';
import action from 'helpers/action';
import { invokeValue } from '../../../utils';
import { DashboardView } from './DashboardView';
import { isNewDesign } from './designSwitch';
// import PageError from 'pages/error';
// import busApp from 'bus/app';
// import paths from 'helpers/paths';

const PrevPage = BasePage.extend({
	id: 'commonprocess:card',
	route: ':id',
	skipMenu: true,
	 Layout,
	// Layout: NewLayout,
	 wrapLayout: true,
	children: [ProcessTasksBoard],
	socketGroups: (m) => 'commonprocess:' + m.id.toString().toLowerCase(),
	onBeforeStart (ac) {
		this.initializeModel(ac.args.id);
		const board = busModels.request('processes:board:instance');
		this.addStartPromise(board.getModelIds({ fetchIfNot: true }));
	},
	initializeModel (id) {
		if (this.model && this.model.id !== id) {
			this.stopListening(this.model);
			delete this.model;
		}
		const model = this.model || new Model({ id });
		this.addModel(model);

		this.addStartPromise(new Promise((resolve, reject) => {
			model.fetch().then(
				() => {
					// if (model.isActable()) {
					resolve();
					// }
					// else {
					// 	reject(PageError.NotAuthorized('Вы не можете просматривать детали этого процесса'));
					// }
				},
				(xhr) => {
					reject(xhr);
				}
			);
		}));

		// this.listenTo(model, 'change:responsibleId',() => {
		// 	if (!model.isActable()) {

		// 		this.stop().then(() => busApp.navigate(paths.url('procs')));
		// 		//busApp.navigate(paths.url('tasks'));
		// 		//this.restart();
		// 	}
		// });
	}
});


const fake = {
	props: { menuName: 'карточка', content: v => mainContent(v) },
	goals: { 
		menuName: 'цели', 
		content: v => goalsContent(v),
		actions: [
			action('add:goal', 'добавить цель'),
		] 
	},
	'products-to-buy': { 
		menuName: 'закупаемая продукция', 
		content: DashboardView,
			// v => productsToBuyContent(v),
		isAvailableForSubmenu: p => p.modelIn('buy'),
	},
	'producing': { 
		menuName: 'производство', 
		content: v => productionContragentContent(v),
		isAvailableForSubmenu: p => p.modelIn('productionLine'),
	},
	activity: {
		menuName: 'журнал',
		content: v => activityContent(v)
	},
	team: {
		menuName: 'команда',
		content: v => teamContent(v)
	},
	tasks: { 
		menuName: 'задачи', 
		content: v => tasksContent(v) 
	},
	'sub-procs': {
		menuName: 'подпроцессы',
		content: v => subProcsContent(v)
	},
	board: ProcessTasksBoard,
	links: { 
		menuName: 'ссылки',
		isAvailableForSubmenu: false,
		actions: [
			action('add:link', 'добавить ссылку'),
		]
	},
	files: { 
		menuName: 'файлы',
		isAvailableForSubmenu: false,
		actions: [
			action('upload:file', 'добавить файл'),
		] 
	}
}

const fakeChilds = _.map(fake, (menuName, route) => {
	
	if (typeof menuName === 'function') {
		return menuName;
	}
	
	let opts;
	if (typeof menuName === 'object') {
		opts = menuName;
		menuName = undefined;
	}
	const ext = Object.assign({ menuName, route }, opts);
	// console.error('ext', ext);
	return BaseProcessPage.extend(ext);
});

const Page = BaseProcessPage.extend({
	id: 'commonprocess:card',
	route: ':id',
	skipMenu: true,
	entityPageRoot: true,
	menuName: 'состояние',
	children: fakeChilds,
	content(v) {
		if (invokeValue(v.page?.shouldUseNewLayout, v.page, v.page)) {
			// return mainContent(v);
			return { 
				class: DashboardView,
				ac: v.getOption('ac')
			};
		}
		console.error('no content', v.page);
		// return this.shouldUseNewLayout() 
		// 	? mainContent(v)
		// 	: undefined;
	},
	shouldUseNewLayout() {

		if (!isNewDesign()) {
			return false;
		}

		return this.model.get('type') === 'process';
	},
	getWrapLayout() {
		return !this.shouldUseNewLayout();
	},
	Layout() {
		return this.shouldUseNewLayout() ? NewLayout : Layout;
	}
	// getLayout() {
	// 	debugger;
	// 	if (this.model.get('type') !== 'process') {
	// 		return BasePage.prototype.getLayout.apply(this, arguments);
	// 	} else {
	// 		return BaseProcessPage.prototype.getLayout.apply(this, arguments);
	// 	}
	// }
	// Layout,
	// wrapLayout: true,
});

export default Page;
