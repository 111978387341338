import BaseView from 'pages/view';
import template from './layout.html';

import ModelProperties from 'components/ui-model-card/properties';
import h from 'components/ui-model-card/helpers';

import nestedRights from 'mod/rights/views/entity-nested-rights';
import Departments from './departments';
import Groups from './groups';
import UiCardBlock from 'components/ui-card-block/block';
import user from 'app/user';

export const RightsLayoutView = BaseView.extend({
	className: 'staff-emp',
	template,
	initialize () {
		const m = this.model;

		if (user.checkRights({ admin: 'manageGroups' })) {
			this.createNested('groups', {
				viewClass: UiCardBlock,
				viewOptions: {
					header: 'группы',
					headerOptions: {
						className: 'bg-main-dark'
					},
					content: Groups,
					contentOptions () {
						return {
							model: m,
							collection: m.getGroupsCollection()
						};
					},
					action: 'add:groups'
				},
				regionTemplate: { replaceElement: true }
			});
		}

		// this.createNested('employees', nestedEmployees(m, {withRoles:true}));
		if (user.checkRights({ admin: 'manageRights' })) {
			this.createNested('rights', nestedRights(m, { shouldFetch: true }));
		}
	},
	onRender () {
		this.showAllNested();
	}
});
