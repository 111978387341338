import View from 'base/view';

export const headerButtons = [
	{ id: 'table', name: 'таблица' },
	{ id: 'source-product', name: 'структура от сырья' },
	{ id: 'product-source', name: 'структура от продукции' }
];

export const SearchView = View.extend({
	className: 'search-by-name',
	template: '<i></i><input value="<%= value %>"/>',
	events: {
		'input input' (event) {
			this.triggerInput(false);
		},
		'keyup input' (event) {
			if (event.keyCode === 13) {
				this.triggerInput(true);
			}
		}
	},
	triggerInput (done) {

		const val = this.getInputValue();
		this.trigger('user:input', val, done);

	},
	getInputValue () {
		let val = this.$('input').val();
		if ((val || '').trim() === '') {
			val = undefined;
		}
		return val;
	},
	templateContext () {
		return {
			value: this.getOption('value') || ''
		};
	}
});
