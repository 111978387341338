import BaseModel from 'base/model';
// import Model from 'base/card-model';
// import mix from 'helpers/mix';
// import action from 'helpers/action';
import EditableProperties from 'mixins/editable-properties';
import paths from 'helpers/paths';
import modals from 'helpers/modals';

import createTaskAction from 'mod/tasks/create-task-action';
import busData from 'bus/data';

export const ProcessGoalModel = BaseModel.mixWith(EditableProperties).extend({
	urlPattern: paths.api('processes', ':processId/goals/:id'),
	isOptional () {
		return this.get('optional');
	},
	isClosed () {
		return !!this.get('closed');
	},
	isJoinedCanceled () {
		const task = this.getJoinedTask();
		if (!task) { return; }
		return task.result === 'canceled';
	},
	isJoinedFailed () {
		const task = this.getJoinedTask();
		if (!task) { return; }
		return task.result === 'failed';
	},
	getClosedDate () {
		const date = this.get('closed');
		if (!date) return;
		const di = Date.info(date);
		return di.valid ? di.date : undefined;
	},
	hasJoined () {
		return !!this.getJoined();
	},
	hasJoinedTask () {
		return !!this.getJoinedTask();
	},
	hasJoinedProcess () {
		return !!this.getJoinedProcess();
	},
	getJoined () {
		return this.getJoinedTask() || this.getJoinedProcess();
	},
	getJoinedTask () {
		return this.get('joinedTask');
	},
	getJoinedProcess () {
		return this.get('joinedProcess');
	},
	toggleClosed () {
		if (this.hasJoined()) return;
		if (!this.isClosed()) {
			modals.confirmReason('Эта цель достигнута?').then(
				reason => this.apiPost('close', { message: reason }).then(
					data => this.set(data)
				)
			).catch(() => {});
		} else {
			modals.confirm('Снять отметку о достижении цели?').then(
				() => this.apiPost('open').then(
					() => this.set({ closed: null, closeComment: null })
				)
			).catch(() => {});
		}
	},
	setDisabled () {
		modals.confirm('Исключить эту опциональную цель из списка целей?').then(
			() => this.apiPost('disable').then(() => {
				this.set('isDisabled', true);
				this.collection.sort();
			})
		).catch(() => {});
	},
	acceptOptional () {
		modals.confirm('Добавить эту опциональную цель в список целей?').then(
			() => this.apiPost('convertToRequired').then(() => {
				this.set('optional', false);
				this.collection.sort();
			})
		).catch(() => {});
	},
	createJoinedTask () {
		const goalModel = this;
		createTaskAction({
			actionOptions: {
				value: {
					toDo: this.get('name')
				}
			},
			createCallback (task) {
				goalModel.apiPost('createTask', task).then(data => {
					if (data && data.id) {
						goalModel.set({
							name: data.toDo,
							joinedTask: data
						});
						goalModel.collection.sort();
					}
				});
			},
			success (model) {
				busData.trigger('task:created', model);
			}
		});

		// modals.confirmReason().then(reason => console.log('reason', reason));
		// modals.confirm('Поставить задачу на основании этой цели?').then(
		// 	() => this.apiPost('createTask').then(data => {
		// 		if (data && data.id) {
		// 			this.set('joinedTask', data);
		// 			this.collection.sort();
		// 		}
		// 	})
		// ).catch(() => {});
	}
});
