import View from 'components/ui-list/item';
// import PromiseButtons from 'components/promise-buttons/mixin';
// import mix from 'helpers/mix';
export default View.extend({
	tagName: 'li',
	text: (m) => m.display('shortName'),
	smallText: (m) => m.display('inn'),
	editButton: true,
	onEditClick () { this.model.executeAction('edit'); }
});
