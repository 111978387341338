import { _ } from 'vendors';
import { View, BackboneView } from './vendors';

const mixin = _.pick(View.prototype,
	'_setOptions', 'mergeOptions', 'triggerMethod'
);

export const DumbView = BackboneView.extend({
	constructor: function (options) {
		this._setOptions(options);
		BackboneView.apply(this, arguments);
	},
	...mixin,
	_triggerEventOnBehaviors () { },
	render () {
		this.triggerMethod('before:render');
		this.updateElClass();
		const html = this.buildHtml();
		this.$el.html(html);
		this.triggerMethod('render');
	},
	updateElClass () {
		let className = this.className;
		if (typeof className === 'function') {
			className = className.call(this, this);
		}
		if (className) {
			this.el.className = className;
		} else {
			this.el.removeAttribute('class');
		}
	},
	buildHtml () {
		return this.getOption('html') || '';
	},
	getOption (key, options) {

		if (typeof options === 'boolean') {
			options = { invoke: options };
		} else if (typeof options !== 'object') {
			options = {};
		}

		const { invoke, invokeArgs = this, invokeContext = this } = options;

		let value = this.options[key];
		if (value === undefined) {
			value = this[key];
		}

		if (invoke && typeof value === 'function') {
			if (invokeArgs && Array.isArray(invokeArgs)) {
				value = value.apply(invokeContext, invokeArgs);
			} else if (invokeArgs !== undefined) {
				value = value.call(invokeContext, invokeArgs);
			} else {
				value = value.call(invokeContext);
			}
		}
		return value;
	},
	hasOption (key) {
		return this.options[key] !== undefined;
	},
	destroy () {
		if (this._isDestroyed || this._isDestroying) { return; }
		this.triggerMethod('before:destroy', this);
		this._isDestroying = true;
		this.remove();
		this._isDestroyed = true;
		this.triggerMethod('destroy', this);
	}
});
