// import { buildFilters } from 'components/filters-v2';
import { commonFilters } from '../common-filters';
export const rawFilters = {
	...commonFilters

	// productId: {
	// 	label: 'продукция',
	// 	valueType: 'string'
	// },
	// actorId: {
	// 	valueType: 'string'
	// },
	// type: {
	// 	valueType: 'enum',
	// 	valueSubType: 'productBatchTransferTypes',
	// 	multiple: true
	// }
};

// export const productionLogFilters = (extend) => {
// 	return buildFilters(filters, { extend });
// };
