import { PageWithDivisionsLayout } from 'components/page-sub-division/PageWithDivisionsLayout';
import { commonCollectionMixin } from './common';
import { TableContentHeaderView } from './TableContentHeaderView';
import { TableContentView } from './TableContentView';
import { TableRowsListView } from './TableRowsListView';
import { TableRowView } from './TableRowView';


export const TableLayoutView = PageWithDivisionsLayout.extend({
	...commonCollectionMixin,
	baseClassName: 'page-table-layout',
	ContentView: TableContentView,
	ListView: TableRowsListView,
	RowView: TableRowView,

	initialize () {
		this._initialize();
	},

	_initialize () {
		this.initializeCollection();
		this.initializeFilterModel();
	},


	getFilterModelData () {

	},

	buildFilterModel () {
		if (!this.FilterModel) { return; }
		const data = this.getFilterModelData();

		return new this.FilterModel(data);
	},

	initializeFilterModel () {
		this.filterModel = this.buildFilterModel();
		const initial = this.getFilterModelInitialValues();
		this.filterModel.setValues(initial);

	},

	getFilterModelInitialValues () {},

	getContentHeaderView () {
		const options = this.getOption('contentHeader') || {};
		return {
			class: TableContentHeaderView,
			...this._getChildOptions(),
			configButton: this.getOption('configButton', true),
			printButton: this.getOption('printButton', true),
			csvButton: this.getOption('csvButton', true),
			excludeFilters: this.getOption('excludeFilters'),
			...options
		};
	},

	_getChildOptions () {
		return {
			collection: this.getOption('collection'),
			model: this.model,
			filterModel: this.getOption('filterModel'),
			tableSchema: this.getOption('tableSchema'),
			layoutView: this
		};
	},

	_getContentOptions () {
		return {
			Collection: this.getOption('Collection'),

			ListView: this.getOption('ListView'),
			listOptions: this.getOption('listOptions', true),
			listClassName: this.getOption('listClassName'),
			listHeaderEnabled: this.getOption('listHeaderEnabled', true),

			rowClickUrl: this.getOption('rowClickUrl', false),
			RowView: this.getOption('RowView'),
			rowOptions: this.getOption('rowOptions', true),
			rowUseWrapper: this.getOption('rowUseWrapper', true),
			rowClassName: this.getOption('rowClassName'),


			cells: this.getOption('cells', false),
			cellOptions: this.getOption('cellOptions', true),

			thisClassName: this.getOption('contentClassName'),
			viewFilter: this.getOption('viewFilter', false),
			viewComparator: this.getOption('viewComparator', false),
			disableFetch: this.getOption('disableFetch', true),
			tableConfig: this.getOption('tableConfig', true)
		};
	},

	getContentView () {
		const options = {
			class: this.getOption('ContentView'),
			...this._getChildOptions(),
			...this._getContentOptions()
		};
		return options;
	}

});
