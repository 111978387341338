import View from 'components/ui-list/item';
// import beh from 'behaviors';
// import popoverInfoView from './person-popover-info';

export default View.extend({
	// behaviors:[beh.InfoPopover],
	tagName: 'li',
	addClass: 'contact-in-list',
	topText: (m) => m.display('purpose'),
	bottomText: (m) => m.display('note'),
	text: (m) => m.display('value'),
	leftButtons () {
		const res = [];
		const _class = ((this.model.getType() || '') + ' not-action').trim();
		res.push({ icon: 1, class: _class });

		if (this.model.isPrimary()) { res.push({ icon: 1, class: 'primary not-action' }); }

		return res;
	},
	editButton: true,
	onEditClick () {
		this.model.executeAction('edit');
	}
	// smallText(){
	// 	let contactValue = this.model.getFirstContactValue();
	// 	if(!contactValue) return 'без контактов';
	// 	return contactValue.display('value');
	// },
	// popoverInfoView,
});
