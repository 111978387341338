
import CollectionView from 'base/collection-view';
import { BlockTextHeader } from 'components/ui2';
import { getCollection } from '../prod-cycle/prodCycles-collection';

import UiListItem from 'components/ui-list/item';
import busData from 'bus/data';
import user from 'app/user';
import smartOpen from 'helpers/smart-open';

import enums from 'helpers/enums';

const EmptyView = UiListItem.extend({
	text: 'не участвует в проивзодстве в качестве сырья'
});

const CycleView = UiListItem.extend({
	initialize (options) {
		this.mergeOptions(options, ['product']);
	},
	className: 'ui-list-item product-prod-cycle-item',
	cssClassModifiers () {
		return [
			this.model.isStarted() ? 'started' : '',
			this.model.get('state') === 'test' ? 'test' : ''
		];
	},
	text: m => m.get('name'),
	bottomText: m => busData.employeeName(m.get('responsibleId')),
	topText: m => enums.get('productionCycleStates', m.get('state')),
	leftButtons () {
		// const isMy = this.model.isMy();
		// const isStarted = this.model.isStarted();
		// return [
		// 	isMy && isStarted ? { icon: true, class: 'stop', title: 'остановить', 'data-trigger': 'stop:cycle' } : null,
		// 	isMy && !isStarted ? { icon: true, class: 'start', title: 'запустить', 'data-trigger': 'start:cycle' } : null,
		// 	{ icon: true, class: 'list-icon', title: 'перейти на страницу производственного цикла', 'data-trigger': 'show:cycle' }
		// ];
		return [
			{ icon: true, class: 'open-page', title: 'перейти на страницу', 'data-trigger': 'open:page' }
		];
	},
	isMy () {
		return user.isMe(this.model.get('responsibleId'));
	},

	onOpenPageClick (event) {
		const prodId = this.model.get('productId');
		const url = this.model.pageUrl({ productId: prodId });
		smartOpen(url, event);
	}

});



export const UiUsedInProdBlock = CollectionView.extend({
	initialize () {
		if (document.location.hash) {
			this.cycleId = document.location.hash.substring(1);
		}
	},
	className: 'double-width product-used-in-cycles-block ui2-block max-whole-column',
	// getCollection () {
	// 	return getCollection(this.model);
	// },
	// getCycle (id) {
	// 	const col = this.getCollection();
	// 	const res = col.get(id);
	// 	return res;
	// },
	customViews () {
		console.log('U-LYA-LYA', this);
		const product = this.model;
		const collection = getCollection(this.model);
		return [
			new BlockTextHeader('Участвует в производстве'),
			new CollectionView({
				emptyView: EmptyView,
				childView: CycleView,
				collection,
				viewFilter (v) {
					return v.model.get('productId') !== product.id;
				}
			})
			// new ProdCyclesView(defs),
			// user.checkRights({ productProduction: 'editProduction' })
			// 	? new BlockFooterTextAction({ template: 'создать производственный цикл', clickAction: 'add:prod:cycle:click' })
			// 	: null
		];
		// if (this.cycleId) {
		// 	return this.getCycleCustomViews(this.cycleId);
		// } else {
		// 	return this.getListCustomViews();
		// }
	},
	// getCycleCustomViews (cycleId) {
	// 	const col = this.getCollection();
	// 	if (!col.isFetched()) {
	// 		this.listenToOnce(col, 'sync', this.render);
	// 		col.fetch({ preloader: true });
	// 		return [];
	// 	}

	// 	const cycle = this.getCycle(cycleId);
	// 	const product = this.model;
	// 	const defs = { model: cycle, product };
	// 	return [
	// 		new BlockTextHeader('Производственный цикл: ' + cycle.get('name')),
	// 		new ProdCycleProductsView(defs),
	// 		(user.checkRights({ productProduction: 'editProduction' }) && cycle.canBeEdited())
	// 			? new BlockFooterTextAction({ tagName: 'div', template: 'добавить сырье', clickAction: 'add:cycle:prod:click', cycle, product })
	// 			: null,
	// 		new BlockFooterTextAction({ template: 'назад к списку', clickAction: 'show:list:click' })
	// 	];
	// },
	// getListCustomViews () {
	// 	const defs = { model: this.model, collection: this.getCollection() };
	// 	return [
	// 		new BlockTextHeader('Производственные циклы'),
	// 		new ProdCyclesView(defs),
	// 		user.checkRights({ productProduction: 'editProduction' })
	// 			? new BlockFooterTextAction({ template: 'создать производственный цикл', clickAction: 'add:prod:cycle:click' })
	// 			: null
	// 	];
	// },
	childViewEvents: {
		// 'add:cycle:prod:click' (childView) {
		// 	const cycle = childView.getOption('cycle');
		// 	const collection = getCycleProdCollection(cycle);
		// 	this.model.executeAction('add:produce:cycle:product', collection, cycle, this.model);
		// },
		// 'add:prod:cycle:click' () {
		// 	this.model.executeAction('add:produce:cycle', this.getCollection());
		// },
		// 'show:list:click' () {
		// 	this.setCycleId();
		// },
		// 'show:cycle' (cycle) {
		// 	const id = cycle.id || _.uniqueId();
		// 	this.setCycleId(id);
		// }
	}
	// setCycleId (id, { shouldRender = true, shouldChangeHash = true } = {}) {
	// 	this.cycleId = id;
	// 	if (shouldChangeHash) {
	// 		document.location.hash = this.cycleId || '';
	// 	}
	// 	if (shouldRender) {
	// 		this.render();
	// 	}
	// }
});
