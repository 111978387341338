import Base from 'components/ui-list/item';
import ActionsPopover from 'behaviors/actions-popover';
import busData from 'bus/data';
export const _meas = v => busData.measures(v, 'short');

const fixNull = txt => {
	if (!txt) return;
	if (txt.toString().trim() === '') return;
	return txt;
};

export const ProductListItemView = Base.extend({
	behaviors: [ActionsPopover],
	cssClassModifiers: [
		'production-item',
		m => m.get('isArchived') ? 'archived' : '',
		m => m.get('isDeleted') ? 'deleted' : '',
		m => m.get('isAbstractGroup') ? 'abstract' : '',
		m => !m.get('unitMeasureId') ? 'notmeasured' : '',
		(m, v) => v.splitValue('origin'),
		(m, v) => v.splitValue('purpose')
	],
	splitValue (key) {
		const value = this.model.get(key);
		if (!value) { return ''; }
		return value.split(/\s*,\s/).join(' ');
	},
	text: (m, v) => v.getSourceIcon() + v.getTopName(),
		// fixNull(m.get('shortName')) ? m.display('shortName') : m.display('fullName'),
	smallText: (m, v) => v.getBottomName(),
		// (m,v) => fixNull(m.get('shortName')) ? m.display('fullName') : null,
	topText: (m) => m.display('typeId'),
	bottomText: m => `<span class="half">${(m.display('origin') || '')}</span><span class="half text-right">${(m.display('purpose') || '')}</span>`,
	textAction: 'open:card',
	optionsButton: true,
	getSourceIcon () {
		return '<i></i> ';
	},
	getTopName () {
		const { fullName, shortName } = this.model.attributes;
		if (!shortName) {
			return fullName;
		}
		return shortName;
	},
	getBottomName () {
		const topName = this.getTopName();
		const { fullName } = this.model.attributes;
		if (topName !== fullName) {
			return fullName;
		}
		return '';
	},
	rightItems: (m, v) => [
		{
			template: '<div class="ui2-atom fixed"><span><%= baseUnit %></span><small><%= storeUnit %></small></div>',
			templateContext: {
				baseUnit: v._getUnit('unitMeasureId'),
				storeUnit: v._getUnit('storeUnitMeasureId')
			}
		}
	],
	_getUnit (key) {
		const value = this.model.get(key);
		if (!value) {
			return '&mdash;';
		}
		return _meas(value);
	}

	// infoButton:true
});

export default ProductListItemView;
