import { _ } from 'vendors';
import Yat from 'marionette.yat';
const get = Yat.Functions.common.getByPath;
const set = Yat.Functions.common.setByPath;
function has (obj, path) {
	if (!path) return;
	if (!_.isObject(obj)) return false;
	if (!_.isString(path)) path = path.toString();
	const chunks = path.split('.');
	const first = chunks.shift();

	if (first in obj) {
		if (_.size(chunks)) { return has(obj[first], chunks.join('.')); } else { return true; }
	} else if (_.isModel(obj)) {
		return has(obj.attributes, path);
	} else return false;
}
export default {
	get, set, has
};
