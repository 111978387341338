import '../api-config';
import paths from 'helpers/paths';
import model from './model';
import Collection from 'base/collection';

const Types = Collection.extend({
	model,
	urlPattern: paths.api('res:production:types')
});


export default Types;
