import { _ } from 'vendors';
// import { SmartLogsCollection } from '../models/logs-collection';
import CollectionView from 'base/collection-view';
// import View from 'base/view';
import { entryConfig } from '../models/entry-config';
import { ActivityListItem } from './activity-list-item';
import PreventWheelMixin from 'mixins/view/prevent-wheel';
import ScrollableMixin from 'mixins/view/scroll-handler';
import FetchOnScrollMixin from 'mixins/collection-view/fetch-on-scroll';
import Fetchable from 'mixins/collection-view/fetchable';

import mix from 'helpers/mix';
import busEvents from 'bus/app-events';


const BaseView = mix(CollectionView).with(ScrollableMixin, PreventWheelMixin, Fetchable, FetchOnScrollMixin);

export const ActivityListItems = BaseView.extend({
	tagName: 'section',
	className: 'log-entries',
	emptyView: null,
	childView: ActivityListItem,

	scrollDirection: 'top',
	allowedScrollEdges: ['top'],

	childViewOptions () {
		// console.warn('log config', this.getOption('logConfig'));
		const cfg = _.extend({}, entryConfig, this.getOption('logConfig'));

		return {
			logConfig: cfg, // this.getOption('logConfig'),
			parentEntity: this.model
		};
	},
	initialize (options) {
		this.mergeOptions(options, ['filtersModel']);
		this.fetch();

		this.listenToOnce(this.collection, 'processed', this.scrollToBottom);

		this.listenToSockets();

		this.listenTo(this.filtersModel, 'change:active', this.fetch);

		this.listenTo(this.filtersModel, 'search-text', this.fetch);
	},

	listenToSockets () {
		const entity = this.model.socketEntity;
		if (!entity) return;
		if (this.model.id == null) return;

		const entityLowered = entity.toLowerCase() + ':' + this.model.id.toString().toLowerCase();
		this.listenTo(busEvents, entityLowered + ':comment', (event) => {
			this.collection.addEntry(event.data, { source: 'sockets' });
		});
		this.listenTo(busEvents, entityLowered + ':log', (log) => {
			if (log.data.type === 'comment') return;
			this.collection.addEntry(log.data, { source: 'sockets' });
		});
	},
	onRender () {
		this.triggerMethod('ready');
	},
	// listenToLast(){
	// 	let last = this.collection.last();
	// 	let view = this.children.findByModel(last);
	// 	if (this._lastView && this._lastView != view) {
	// 		this.stopListening(this._lastView, 'childview:childview:attach', this._onLastViewEntryAttached);
	// 	}
	// 	if (!view || this._lastView === view) return;
	// 	this.listenTo(view, 'childview:childview:attach', this._onLastViewEntryAttached);
	// 	this._lastView = view;
	// },
	// _onLastViewEntryAttached(){
	// 	this.scrollToBottom();
	// },
	getScrollEl () {
		return this.$el;
	},
	onBeforeNewItem () {
		this._lastScrollHeight = this.el.scrollHeight;
	},
	onNewItem (newItem) {
		if (!newItem.isMy()) {
			if (this._lastScrollPosition.bottom + 5 < this._lastScrollHeight) {
				return;
			}
		}
		this.scrollToBottom();
	},
	scrollToBottom () {
		this.el.scrollTo(0, this.el.scrollHeight);
		!this._lastScrollPosition && (this._lastScrollPosition = {});
		this._lastScrollPosition.bottom = this.el.scrollHeight;
		this._lastScrollHeight = this.el.scrollHeight;
	},
	onBeforeDestroy () {
		this.collection.off();
		this.stopListening(busEvents);
	},
	collectionEvents: {
		'new:item': 'onNewItem',
		'before:new:item': 'onBeforeNewItem'
	},
	childViewEvents: {
		'expecting:new' () {
			// this.on('all', c => //console.log(c));
		}
	},
	getQueryData () {
		const search = { listType: this.filtersModel.getActive().id };
		const text = this.filtersModel.getSearchText();
		if (text) {
			search.text = text;
		}
		return search;
	},
	fetch () {
		this.trigger('edges:clear');
		const col = this.getCollection();
		const queryData = _.extend({}, this.getQueryData());
		this.once('render:children', () => {
			this.scrollToBottom();
		});

		// this._topEdgeTriggered = true;

		return col.fetchBackend({
			data: queryData,
			preloader: this.$el,
			reset: true
		});
	},
	fetchNext () {
		const col = this.getCollection();
		if (col.fetchingInProcess) return;
		const res = col.getResultJSON();
		if (res.endOfData) return;

		const take = 100;
		const skip = (res.skiped || 0) + take;
		const page = {
			'result.skip': skip,
			'result.take': take
		};
		const queryData = _.extend({}, this.getQueryData(), page);
		const prevHeight = this.el.scrollHeight;
		this.once('render:children', () => {
			const offset = this.el.scrollHeight - prevHeight;
			const top = this._lastScrollPosition.top + offset;
			this.el.scrollTo(0, top);
		});
		return col.fetchBackend({
			data: queryData,
			preloader: this.$el
		});
	}
});

export const ActivityListItems2 = BaseView.extend({
	tagName: 'section',
	className: 'log-entries',
	emptyView: null,
	childView: ActivityListItem,

	scrollDirection: 'top',
	allowedScrollEdges: ['top'],

	fetch () {
		this.trigger('edges:clear');

		this.once('render:children', () => {
			this.scrollToBottom();
		});

		const queryData = _.extend({}, this.getQueryData());

		const fetchOptions = {
			data: queryData,
			preloader: this.$el,
			reset: true
		};
		// return col.fetch().then(() => {
		// 	this.collection.processEntries(col.models);
		// });
		return this._fetch(fetchOptions);
	},
	_fetch (fetchOptions) {
		const col = this.getOption('backendCollection');
		return col.fetch(fetchOptions).then(() => {
			this.collection.processEntries(col.models, fetchOptions && fetchOptions.reset);
		});
	},
	fetchNext () {
		const col = this.getOption('backendCollection');
		if (col.fetchingInProcess) return;
		const res = col.getResultJSON();
		if (res.endOfData) return;

		const take = 100;
		const skip = (res.skiped || 0) + take;
		const page = {
			'result.skip': skip,
			'result.take': take
		};
		const queryData = _.extend({}, this.getQueryData(), page);
		const prevHeight = this.el.scrollHeight;
		this.once('render:children', () => {
			const offset = this.el.scrollHeight - prevHeight;
			const top = this._lastScrollPosition.top + offset;
			this.el.scrollTo(0, top);
		});
		const fetchOptions = {
			data: queryData,
			preloader: this.$el
		};
		return this._fetch(fetchOptions);
	},
	getQueryData () {
		const search = { listType: this.filtersModel.getActive().id };
		const text = this.filtersModel.getSearchText();
		if (text) {
			search.text = text;
		}
		return search;
	},
	initialize (options) {
		this.mergeOptions(options, ['filtersModel']);
		// this.listenToOnce(this.collection, 'processed', this.scrollToBottom);
		this.listenTo(this.filtersModel, 'change:active search-text', this.fetch);

		this.fetch();
	},
	scrollToBottom () {
		this.el.scrollTo(0, this.el.scrollHeight);
		!this._lastScrollPosition && (this._lastScrollPosition = {});
		this._lastScrollPosition.bottom = this.el.scrollHeight;
		this._lastScrollHeight = this.el.scrollHeight;
	}
});
