import modals from 'helpers/modals';

import View from 'base/view';
import CollapasableBlock from './collapasable-block';
import CollectionView from 'base/collection-view';

import Collection from 'base/collection';
// import CardView from 'components/ui-card/item';
// import BaseListItem from 'components/ui-list/item';
// import ProductListItem from 'mods/resources/production/products/views/list-item';

// import { ClickableBehavior } from './common';
import { ClickableBehavior } from 'behaviors';
import smartOpen from 'helpers/smart-open';
import { productPageBaseUrl } from 'mods/resources/production/products/cfg';
import { _ } from 'vendors';
import { Model } from 'backbone';

import user from 'app/user';

import modalError from 'helpers/modals/error';
import { ProcessView } from './ProcessView';

function chunkContainText (value, text, startIndex = 0) {
	return value.indexOf(text, startIndex);
}
function valueContainText (value, text) {
	value = value.toString().toLowerCase();
	if (typeof text === 'string') {
		return chunkContainText(value, text) > -1;
	} else if (Array.isArray(text)) {

		if (text.length === 0) { return false; }

		let lastIndex = 0;
		for (const chunk of text) {
			const chunkIndex = chunkContainText(value, chunk, lastIndex);
			if (chunkIndex < lastIndex) {
				return false;
			}
			lastIndex = chunkIndex + chunk.length;
		}
		return true;
	}
	return false;
}
function arrayContainsText (arr, text) {
	return arr.some(element => {
		if (element == null || element === '') { return false; }
		return element.toString().toLowerCase().indexOf(text) > -1;
	});
}
function objectContainsText (obj, text) {
	return _.some(obj, (value, key) => {
		if (value == null || value === '') {
			return false;
		}
		const type = typeof value;
		if (Array.isArray(value)) {
			return arrayContainsText(value, text);
		} else if (type === 'object') {
			return objectContainsText(value, text);
		} else {
			return valueContainText(value, text);
		}
	});
}


const FilterModel = Model.extend({
	contains: ['name'],
	filterView (view) {
		const model = view.model;
		return this.filterModel(model);
	},
	filterModel (model) {
		const containsOk = this.contains.every(key => {

			let value = this.get(key);
			if (value == null || value === '') {
				return true;
			}
			value = value.toString().toLowerCase().trim();
			if (value.indexOf(' ') > -1) {
				value = value.split(' ').filter(f => !!f);
			}
			const attrs = _.pick(model.attributes, 'fullName', 'shortName');
			return objectContainsText(attrs, value);
		});
		if (!containsOk) {
			return false;
		}
		return true;
	}
});
const ActionButtonModel = Model.extend({
	invoke (key, ...args) {
		let value = this.get(key);
		if (typeof value === 'function') {
			value = value.call(this, ...args);
		}
		return value;
	}
});
const ActionButtonView = View.extend({
	tagName: 'button',
	className: 'product-action-button',
	template: '<%= text %>',
	cssClassModifiers: [
		(m) => m.get('actionId')
	],
	events: {
		click (event) {
			event.stopPropagation();
			event.preventDefault();
			const action = this.model.get('action');
			if (typeof action !== 'function') {
				console.warn('missing action for ', this.model.get('actionId'), this);
				return;
			}
			const data = this.getOption('departmentModel');
			const product = this.getOption('productModel');
			action.call(this.model, product, data);
		}
	}
});

function detectProcess (processes, product, department) {
	if (processes.length === 1) {
		return processes;
	}

	const myprocs = processes.filter(proc => {
		if (user.isMe(proc.owner.id)) {
			return true;
		}
		return proc.team.some(emp => user.isMe(emp.id));
	});

	return myprocs.length ? myprocs : processes;

}

function showProcess (prodProcs, product, department) {
	const foundProcesses = detectProcess(prodProcs, product, department);

	if (foundProcesses.length > 1) {
		console.log('>> SHOW MODAL', foundProcesses);

		const content = new CollectionView({
			collection: new Collection(foundProcesses),
			childView: ProcessView
		});

		modals.show({
			header: 'Выберите подходящий процесс',
			content,
			type: 'short'
		});
	} else {
		const proc = foundProcesses[0];
		smartOpen('/procs/' + proc.id, { ctrlKey: true });
	}
}

const productActionDefaults = {
	acceptAtStore: {
		isAvailable (product, department) {
			// if (product.id in department.productProcesses) {
			// }
			if (product.get('deepness') !== 'input') {
				return false;
			}
			return product.id in department.productMoveProcessesOutput;
		},
		defaults: {
			text: 'принять на склад',
			action (product, department) {
				const prodProcs = department.productMoveProcessesOutput[product.id];
				if (!prodProcs) {
					modalError('В системе пока нет производственного процеса, его необходимо создать');
					return;
				}
				showProcess(prodProcs, product, department);

			}
		}
	},
	produce: {
		isAvailable (product, department) {
			// if (product.id in department.productProcesses) {
			// }
			return product.get('deepness') !== 'input';
		},
		defaults: {
			text: 'произвести',
			// detectProcess (processes, product, department) {
			// 	if (processes.length === 1) {
			// 		return processes;
			// 	}

			// 	const myprocs = processes.filter(proc => {
			// 		if (user.isMe(proc.owner.id)) {
			// 			return true;
			// 		}
			// 		return proc.team.some(emp => user.isMe(emp.id));
			// 	});

			// 	return myprocs.length ? myprocs : processes;

			// },
			action (product, department) {
				const prodProcs = department.productProcesses[product.id];
				if (!prodProcs) {
					modalError('В системе пока нет производственного процеса, его необходимо создать');
					return;
				}
				showProcess(prodProcs, product, department);
			}
		}
	},
	moveToStore: {
		isAvailable (product, department) {
			// if (product.id in department.productProcesses) {
			// }
			if (product.get('deepness') !== 'output') {
				return false;
			}
			return product.id in department.productMoveProcesses;
		},
		defaults: {
			text: 'сдать на склад',
			action (product, department) {
				const prodProcs = department.productMoveProcesses[product.id];
				if (!prodProcs) {
					modalError('В системе пока нет производственного процеса, его необходимо создать');
					return;
				}
				showProcess(prodProcs, product, department);

			}
		}
	}

};

const ProductActionsView = CollectionView.extend({
	initialize () {
		this.initializeCollection();
	},
	initializeCollection () {
		const models = this.getActionsModels();
		this.collection = new Collection(models, { model: ActionButtonModel });
	},
	getActionsModels () {
		const department = this.getOption('departmentModel');
		const actions = _.reduce(productActionDefaults, (actions, actionObj, actionId) => {
			const model = Object.assign({}, actionObj.defaults, { actionId });
			if (!actionObj.isAvailable || actionObj.isAvailable(this.model, department)) {
				actions.push(model);
			}
			return actions;
		}, []);


		return actions;
	},
	childView: ActionButtonView,
	childViewOptions () {
		return {
			productModel: this.model,
			departmentModel: this.getOption('departmentModel')
		};
	}
});

const ProductCard = View.extend({
	className: 'ui2-block with-wrapper production-department-product-card clickable',
	template: `<div class="wrapper">
<div class="ui2-molecule info">
    <div class="name"><%= name %></div>
</div>
<div class="ui2-molecule amounts">
    <div class="ui2-atom">
        <b>на складе</b>
        <span><%= storeAmountDisplay %></span>
    </div>
    <div class="ui2-atom">
        <b>у конт-тов</b>
        <span><%= contragentAmountDisplay %></span>
    </div>
    <div class="ui2-atom">
        <b>всего</b>
        <span><%= totalAmountDisplay %></span>
    </div>
</div>
<div class="ui2-molecule actions"></div>
</div>`,
	behaviors: [ClickableBehavior],
	regions: {
		actions: '.actions'
	},
	initialize () {
		if (!this.getOption('listenForFilterModel')) { return; }

		this.filterModel = this.getOption('filterModel');
		this.listenTo(this.filterModel, 'filter', () => {
			const passed = this.filterModel.filterView(this);
			this.trigger('filter:pass', passed);
		});
	},
	getName () {
		const { shortName, fullName } = this.model.attributes;
		return (shortName || '').trim() ? shortName : fullName;
	},
	templateContext () {
		const { contragentAmount, storeAmount } = this.model.attributes;
		const name = this.getName();
		const contragentAmountDisplay = contragentAmount;
		const storeAmountDisplay = storeAmount;
		const totalAmountDisplay = storeAmount + contragentAmount;
		return {
			name,
			contragentAmountDisplay,
			storeAmountDisplay,
			totalAmountDisplay
		};
	},
	onClicked (event) {
		const options = { ctrlKey: !event.ctrlKey };

		const url = productPageBaseUrl + this.model.id;
		smartOpen(url, options);
	},
	onRender () {
		this.showActions();
	},
	showActions () {
		const view = this.buildActionsView();
		if (!view) { return; }
		this.showChildView('actions', view);
	},
	buildActionsView () {
		const departmentModel = this.getOption('departmentModel');
		return new ProductActionsView({ model: this.model, departmentModel });

	}
});

const ProductionList = CollectionView.extend({
	className: 'production-list',
	childView: ProductCard,
	viewComparator: v => v.getName(),
	childViewOptions () {
		return {
			departmentModel: this.model
		};
	}

});

const viewsConfig = {
	input: {
		header: 'Сырье',
		viewFilter: v => v.model.get('deepness') === 'input'
	},
	intermediate: {
		header: 'Полуфабрикаты',
		viewFilter: v => v.model.get('deepness') === 'intermediate'
	},
	output: {
		header: 'Готовая продукция',
		viewFilter: v => v.model.get('deepness') === 'output'
	}

};

const ProductionColumn = View.extend({
	className: 'column',
	template: '<header><%= header %></header><section></section>',
	regions: {
		section: 'section'
	},
	initialize () {
		this.cfg = viewsConfig[this.getOption('key')];
	},
	onRender () {
		const collection = this.collection;
		const model = this.model;
		const filterModel = this.getOption('filterModel');
		const cfg = this.cfg;
		const cfgViewFilter = cfg.viewFilter;
		const viewFilter = v => {
			if (cfgViewFilter && !cfgViewFilter(v)) {
				return false;
			}
			if (filterModel && filterModel.filterView && !filterModel.filterView(v)) {
				return false;
			}
			return true;
		};
		this.header = cfg.header;
		const view = new ProductionList({ collection, model, viewFilter });
		view.listenTo(filterModel, 'filter', () => view.filter());
		this.showChildView('section', view, { replaceElement: true });
	},
	templateContext () {
		return {
			header: this.cfg.header
		};
	}
});

const ThreeColumns = CollectionView.extend({
	tagName: 'section',
	className: 'three-columns',
	customViews () {
		const collection = new Collection(this.model.get('products'));
		const filterModel = this.getOption('filterModel');
		return [
			new ProductionColumn({ collection, model: this.model, key: 'input', filterModel }),
			new ProductionColumn({ collection, model: this.model, key: 'intermediate', filterModel }),
			new ProductionColumn({ collection, model: this.model, key: 'output', filterModel })
		];
	}
});

const treeProdsNestedsKeys = {
	fromSource: 'producedIds',
	fromProduction: 'componentIds'
};

const TreeItem = View.extend({
	className: 'product-tree-entry',
	template: '<div></div><section></section>',
	regions: {
		prod: 'div',
		nested: 'section'
	},
	onRender () {
		this.showProd();
		this.showNested();
	},
	_onFilterPassed (passed) {
		this._filterPassed = passed;
		this.trigger('filter:passed', passed);
	},
	showProd () {
		const filterModel = this.getOption('filterModel');
		const listenForFilterModel = (this.getNestedModels() || []).length === 0;
		const departmentModel = this.getOption('departmentModel');
		// const productModel = this.getOption('productModel');
		const view = new ProductCard({
			model: this.model,
			filterModel,
			listenForFilterModel,
			departmentModel
			// productModel
		});
		// this.listenTo(view, 'filter:passed', () => this._onFilterPassed);
		this.showChildView('prod', view, { replaceElement: true });
	},
	showNested () {
		const models = this.getNestedModels();
		if (!models.length) return;
		const filterModel = this.getOption('filterModel');
		const collection = new Collection(models);
		const view = new CollectionView({
			tagName: 'section',
			className: 'nesteds',
			collection,
			childView: TreeItem,
			childViewOptions: {
				allModels: this.getOption('allModels'),
				direction: this.getOption('direction'),
				departmentModel: this.getOption('departmentModel'),
				filterModel
			},
			// childViewEvents () {
				// const passedHandler = _.debounce(() => {
				// 	this.filter();
				// 	this.trigger('filter:passed', !this.children.length);
				// }, 10);
				// return {
				// 	'filter:passed': passedHandler
				// };
			// },
			viewFilter (child) {
				return child.isFilterPassed();
			}
		});
		this.nestedChildViews = view.children;

		// this.listenTo(view, 'filter:passed', (passed) => {
		// 	console.log('[in child]', this.model.get('fullName'));
		// 	this._onFilterPassed(passed);
		// });
		view.listenTo(filterModel, 'filter', () => view.filter());
		this.showChildView('nested', view, { replaceElement: true });
	},
	getNestedModels () {
		const dir = this.getOption('direction');
		const nestedKey = treeProdsNestedsKeys[dir];
		const ids = this.model.get(nestedKey) || [];
        // console.log('**', dir, nestedKey, ids);
		const all = this.getOption('allModels');
		return all.models.filter(f => ids.indexOf(f.id) > -1);
	},
	isFilterPassed () {
		const filterModel = this.getOption('filterModel');
		if (filterModel.filterView(this)) {
			return true;
		}
		if (this.nestedChildViews) {
			const children = this.nestedChildViews._views;
			return children.some(childView => childView.isFilterPassed());
		}
		return false;
	}
});

const treeProdsInitialFilters = {
	fromSource: m => !m.componentIds || !m.componentIds.length,
	fromProduction: m => !m.producedIds || !m.producedIds.length
};
const ProductionTree = CollectionView.extend({
	tagName: 'section',
	childView: TreeItem,
	childViewOptions () {
		return {
			allModels: this.allModels,
			direction: this.getOption('direction'),
			departmentModel: this.model,
			filterModel: this.getOption('filterModel')
		};
	},
	initialize () {
		this.allModels = this.buildAllCollection();
		const models = this.getInitialModels();
		this.collection = new Collection(models);
		this.filterModel = this.getOption('filterModel');
		this.listenTo(this.filterModel, 'filter', () => this.filter());
	},
	getProduction () {
		return this.model.get('products');
	},
	buildAllCollection () {
		const prods = this.getProduction();
		return new Collection(prods);
	},
	getInitialModels () {
		const dir = this.getOption('direction');
		const prods = this.getProduction();
		return prods.filter(treeProdsInitialFilters[dir]);
	},
	viewFilter (child) {
		return child.isFilterPassed();
	}
	// viewFilter (view) {
	// 	const filterModel = this.filterModel;
	// 	if (!this.filterModel) { return true; }
	// 	if (this.filterModel.filterModel(view.model)) {
	// 		return true;
	// 	}
	// 	return view.getNestedModels().some(model => this.filterModel.filterModel(model));
	// }

});


const headerButtons = [
	{ id: 'table', name: 'таблица' },
	{ id: 'source-product', name: 'структура от сырья' },
	{ id: 'product-source', name: 'структура от продукции' }
];

const SearchView = View.extend({
	className: 'search-by-name',
	template: '<i></i><input value="<%= value %>"/>',
	events: {
		'input input' (event) {
			let val = this.$('input').val();
			if ((val || '').trim() === '') {
				val = undefined;
			}
			this.trigger('user:input', val);
		}
	},
	templateContext () {
		return {
			value: this.getOption('value') || ''
		};
	}
});

export default CollapasableBlock.extend({
	addClas: 'production',
	header: 'Продукция',
	currentTab: 'table',
	initialize (options) {
		this.filterModel = new FilterModel();
		this.initializeCollapsable(options);
	},
	buildContentView () {
		let view;
		const filterModel = this.filterModel;
		switch (this.currentTab) {
		case 'table':
			view = new ThreeColumns({ model: this.model, filterModel }); break;
		case 'source-product':
			view = new ProductionTree({ model: this.model, filterModel, direction: 'fromSource' }); break;
		case 'product-source':
			view = new ProductionTree({ model: this.model, filterModel, direction: 'fromProduction' }); break;
		}
		return view;
	},
	buildHeaderView () {
		const blockView = this;
		const filterModel = this.filterModel;
		const view = new CollectionView({
			className: 'header-view',
			customViews () {

				const searchView = new SearchView({ value: filterModel.get('name') });
				this.listenTo(searchView, 'user:input', (text) => blockView.triggerMethod('search:by:name', text));
				const buttonsView = this;

				const views = headerButtons.map((btn, i) => {
					let className = 'tab-btn';
					if (blockView.currentTab === btn.id) {
						className += ' active';
					}
					const view = new View({
						btnId: btn.id,
						className,
						template: `<button class="${btn.id}"><i></i><span>${btn.name}</span></button>`,
						events: {
							click (e) {
								if (this.$el.hasClass('active')) {
									return;
								}
								blockView.triggerMethod('header:tab', btn.id);
								buttonsView._views.forEach(element => {
									if (element !== this) {
										element.$el.removeClass('active');
									} else {
										element.$el.addClass('active');
									}
                                    // console.log('#>', element.getOption('btnId'), btn.id, element.getOption('btnId') === btn.id)
                                    // if (element.getOption('btnId') === btn.id) {
                                    // } else {
                                    // }
								});
							}
						}
					});
					return view;
				});
				views.unshift(searchView);
				buttonsView._views = views;

				return views;
			}
		});
		return view;
	},
	onSearchByName (text) {
		this.filterModel.set('name', text);
		this.filterModel.trigger('filter');
	},
	onHeaderTab (tabId) {
		this.currentTab = tabId;
		this.render();
	}
});
