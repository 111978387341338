import { _ } from 'vendors';
import { HamburgerView } from 'base/view-hamburger';
import View from 'base/view';
import enums from 'helpers/enums';

// import { Ui2AtomView } from '../../../components/ui2';

const TextFilterItem = View.extend({
	className: 'ui2-atom fixed filter-field-item',
	template: '<i><%= label %></i><span><input /></span>', // _.template(`asdasd`)
	initialize () {
		this.onInput = _.debounce(this._onInput.bind(this), 400);
		this.property = this.getOption('property');
		this.filterModel = this.getOption('filterModel');
	},
	templateContext () {
		return {
			label: this.getOption('label')
		};
	},
	_onInput () {
		let value = this.$('input').val();
		if (value === '') { value = undefined; }

		this.filterModel.set(this.property, value);
		this.filterModel.trigger('search', this.filterModel);
	},
	events: {
		'input input' () {
			this.onInput();
		}
	}
});


const SelectFilterItem = View.extend({
	className: 'ui2-atom fixed filter-field-item',
	template: '<i><%= label %></i><span><select><%= optionsHtml %></select></span>', // _.template(`asdasd`)
	initialize () {
        // this.onInput = _.debounce(this._onInput.bind(this), 400);
		this.property = this.getOption('property');
		this.filterModel = this.getOption('filterModel');
	},
	buildOpionsHtml () {
		const options = this.getOption('selectOptions');
		const selectedValue = this.filterModel.get(this.property);
		const html = _.reduce(options, (m, label, value) => {
			if (value === 'none') {
				value = undefined;
				label = 'все';
			}
			const selected = value === selectedValue ? ' selected' : '';
			if (value == null) {
				value = '';
			}
			m += `<option value="${value}"${selected}>${label}</option>`;
			return m;
		}, '');
		return html;
	},
	templateContext () {
		return {
			label: this.getOption('label'),
			optionsHtml: this.buildOpionsHtml()
		};
	},
	onInput () {
		let value = this.$('select').val();
		if (value === '') { value = undefined; }

		this.filterModel.set(this.property, value);
		this.filterModel.trigger('search', this.filterModel);
	},
	events: {
		'change select' () {
			this.onInput();
		}
	}
});

const Amounts = View.extend({
	className: 'ui2-atom fixed filtered-amount',
	template: '<i>нашлось</i><span></span><b></b>',
	initialize () {
		this.filterModel = this.getOption('filterModel');
        // console.log('-amounts-', this.collection);
        // this._update();
		this.listenTo(this.filterModel, 'after:filter', this._update);
		this.listenTo(this.collection, 'batch:newAmount', this._updateOne);
		this.on('render', this._update);
        // this.listenTo(this.collection, 'fetch:complete', () => {
        //     console.log('-filter amounts on fetch complete-', this.collection);
        // });
	},

	_doIfOk (batch, product, action) {
		if (!this.filterModel.filterBatch(batch, product)) return;
		if (action) {
			action(batch, product);
		}
	},

	_update () {
		// const fltr = this.filterModel;
		setTimeout(() => {
			const amounts = this.collection.models.reduce((memo, product) => {
				let atleastOne;
				(product.get('batches') || []).forEach(batch => {
					this._doIfOk(batch, product, () => {
						atleastOne = true;
						memo.batches++;
					});
				});
				if (atleastOne) { memo.products++; }
				return memo;
			}, { products: 0, batches: 0 });

			this._amounts = amounts;
			this._updateHtml();
		}, 0);
	},
	_updateOne (product, batchid, newvalue, batch) {
		this._update();
	},
	_updateHtml () {
		const amounts = this._amounts;
		this.$('span').html(amounts.batches);
		this.$('b').html(amounts.products);
	}
});

export const FilterContainerView = HamburgerView.extend({
	className: 'filter-items ui2-molecule',
	template: false,
	customViews () {
		return [
			new TextFilterItem({
				filterModel: this.getOption('filterModel'),
				property: 'productName',
				label: 'название продукции содержит'
			}),
			new TextFilterItem({
				filterModel: this.getOption('filterModel'),
				property: 'batchName',
				label: 'номер партии содержит'
			}),
			new SelectFilterItem({
				filterModel: this.getOption('filterModel'),
				property: 'origin',
				label: 'источник',
				selectOptions: enums.store.productOrigins
			}),
			new SelectFilterItem({
				filterModel: this.getOption('filterModel'),
				property: 'purpose',
				label: 'назначение',
				selectOptions: enums.store.productPurposes
			}),
			new Amounts({
				filterModel: this.getOption('filterModel'),
				collection: this.collection
			})
		];
	}
});
