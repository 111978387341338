import BaseCard from 'components/cards/card-view';
import smartOpen from 'helpers/smart-open';
import user from 'app/user';

export default BaseCard.extend({
	className: 'card page-link-card no-icon enlarged',
	cssClassModifiers: [
		(m, v) => v.isMy() ? 'my' : ''
	],
	// templateContext () {
	// 	return {
	// 		label: this.model.get('name'),
	// 		icon: undefined
	// 	};
	// },
	getLabel () { return this.model.get('name'); },
	getIcon () { },
	getFaIcon () { },
	events: {
		'click' (e) {
			const url = '/prod/deps/' + this.model.id;
			return smartOpen(url, e);
		}
	},
	isMy () {
		const emps = this.model.get('employees');
		return emps.some(emp => user.isMe(emp.eId));
	}
});
