import Mn from 'backbone.marionette';
import { _ } from 'vendors';
const multiplies = {
	days: 86400000,
	hours: 3600000,
	minutes: 60000,
	seconds: 1000,
	milliseconds: 1
};

const keys = {
	days: 'д',
	hours: 'ч',
	minutes: 'м',
	seconds: 'c',
	milliseconds: 'мс'
};


const parseTimeSpan = function (value) {
	if (_.isObject(value)) {
		const hash = _.pick(value, 'days', 'hours', 'minutes', 'seconds', 'milliseconds');
		if (!_.size(hash)) return;
		if (!_.isNumber(_.reduce(hash, (agg, i) => agg + (i || 0), 0))) { return; }

		hash.sign = value.sign === '-' ? '-' : '+';


		if (hash.milliseconds > 999) {
			hash.milliseconds = parseInt(hash.milliseconds.toString().substring(0, 3), 10);
		}

		return hash;
	} else if (_.isString(value)) {
		const sign = value.charAt(0) === '-' ? '-' : '+';
		const pat = /^-*(\d+\.)*(\d{2}:\d{2}:\d{2})(\.\d+)*$/gmi;
		if (!pat.test(value)) { return; }
		const chunks = value.replace(pat, '$1|$2|$3').split('|');
		const time = chunks[1].split(':');

		const res = {
			sign,
			days: parseInt(chunks[0].replace(/\D/g, '') || 0, 10),
			hours: parseInt(time[0].replace(/\D/g, '') || 0, 10),
			minutes: parseInt(time[1].replace(/\D/g, '') || 0, 10),
			seconds: parseInt(time[2].replace(/\D/g, '') || 0, 10),
			milliseconds: parseInt(chunks[2].replace(/\D/g, '') || 0, 10)
		};

		return parseTimeSpan(res);
	} else if (_.isNumber(value) && !isNaN(value)) {
		const hash = {};
		hash.sign = value > 0 ? '+' : '-';

		const _day = multiplies.days;
		hash.days = Math.floor(value / _day);

		value = value % _day;
		const _hour = multiplies.hours;
		hash.hours = Math.floor(value / _hour);

		value = value % _hour;
		const _minute = multiplies.minutes;
		hash.minutes = Math.floor(value / _minute);

		value = value % _minute;
		const _second = multiplies.seconds;
		hash.seconds = Math.floor(value / _second);

		hash.milliseconds = value % _second;

		return hash;
	}
};

const TimeSpan = function (value) {
	const hash = _.isObject(value) ? value : parseTimeSpan(value);
	this.value = _.clone(hash);

	return this;
};

_.extend(TimeSpan, {
	extend: Mn.Object.extend,
	parse (value) {
		const hash = parseTimeSpan(value);
		if (hash == null) return;
		return new TimeSpan(hash);
	},
	toString (val, opts) {
		!opts && (opts = { format: 'max' });
		const ts = TimeSpan.parse(val);
		return ts ? ts.toString(opts) : '';
	}
});

_.extend(TimeSpan.prototype, {
	parse (value) {
		return parseTimeSpan(value);
	},
	getSignKoef () {
		return this.getValue().sign === '-' ? -1 : 1;
	},
	getValue () {
		return _.omit(this.value, 'sign');
	},
	_getChunks () {
		const hash = this.getValue();
		const chunks = _.map(hash, (value, key) => {
			if (!value) return '';
			const postfix = keys[key] || '';
			return value.toString() + postfix;
		});
		return _(chunks).filter(f => !!f);
	},
	toString (opts = {}) {
		const chunks = this._getChunks();

		if (opts.format) {
			if (opts.format === 'max') {
				return this.value.sign + chunks[0];
			}
		} else {
			return this.value.sign + chunks.join(' ');
		}
	},
	valueOf () {
		return _.reduce(this.getValue(), (agg, val, key) => agg + (val * multiplies[key]), 0) * this.getSignKoef();
	}
});


window.TimeSpan = TimeSpan;


export default TimeSpan;
