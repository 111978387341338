import View from 'components/ui-list/item';
import ActionsPopover from 'behaviors/actions-popover';

import twinContext from 'helpers/date/twin-context';
import twinTemplate from 'helpers/date/twin.html';

// import smartDate from 'helpers/smart-date';

function employee (m, key) {
	const nested = m.get(key, { nested: true });
	return nested && nested.display && nested.display('name');
}

export default View.extend({
	tagName: 'li',
	behaviors: [ActionsPopover],
	cssClassModifiers: [
		'task-list-item',
		(m) => m.get('type'),
		(m) => m.get('state'),
		(m) => m.isInPast() && m.isNotClosed() ? 'past' : '',
		(m) => m.isToday() ? 'today' : '',
		(m) => m.isNear() && m.isNotClosed() ? 'near' : '',
		(m) => m.isMy() && m.isNotClosed() ? 'my' : '',
		(m) => m.amIChecker() ? 'checker' : '',
		(m) => m.amICreator() ? 'creator' : '',
		(m) => m.isMy() && m.isStrict() ? 'strict' : '',
		(m) => m.isActable() ? 'actable' : '',
		(m) => m.isContragent() ? 'on-contragent' : '',
		(m) => m.isProcess() ? 'on-process' : ''
	],

	text (m) {
		return m.get('toDo');
	},

	topText: (m) => {
		const res = [];
		if (m.isMy()) {
			res.push('<span></span>');
		} else {
			const name = employee(m, 'responsibleId') || '';
			res.push('<span>' + name + '</span>');
		}
		if (m.amIChecker()) {
			res.push('<span>я проверяю</span>');
		} else {
			const name = employee(m, 'checkerId') || '';
			res.push('<span>' + name + '</span>');
		}

		return res.join('');
	},
	bottomText: (m) => {
		const status = ((m.amIChecker() || m.isMy()) && m.get('state') !== 'inProcess' && m.display('state')) || '';

		const when = (m.amIChecker() && m.display('modified', { format: 'fromNow' })) || '';
		const html = `<span>${status}</span><span>${when}</span>`;
		return html;
	},
	smallText () {
		const contragent = this.model.display('contragent');
		const process = this.model.display('process');
		return process || contragent;
	},
	leftItems: [
		{
			template: twinTemplate,
			templateContext (m) {
				return twinContext(m.getDisplayDate());
			},
			condition: (m) => m.hasValidDate()
		}
	],


	infoButton: true,
	optionsButton: (m) => m.isActable(),
	textAction: 'open:card'
});
