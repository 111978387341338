import { BaseCollectionView } from 'base/base-collection-view';
import { CycleListItemView } from './cycle-list-item-view.js';

export const CyclesListView = BaseCollectionView.extend({
	baseClassName: 'elastic-content with-borders',
	childView: CycleListItemView,
	childViewOptions () {
		return {
			parent: this.model
		};
	}
	// initialize () {
	// 	this.collection.reset(this.model.get('concretes'));
	// }
});


// export const
