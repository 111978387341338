import Base from 'components/ui-list/item';
import ActionsPopover from 'behaviors/actions-popover';
export default Base.extend({
	behaviors: [ActionsPopover],
	dynamicClassName () {
		const res = ['user'];
		const usr = this.getUser();
		if (!usr) { return res.join(' '); }
		usr.isOnline() && res.push('online');
		return res.join(' ');
	},
	getUser () {
		return this.model;
	},
	smallText: m => m.display('employeeId'),
	optionsButton: true
});
