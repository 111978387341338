import { _ } from 'vendors';
import BaseModel from 'base/model';
import DayModel from './day-model';
import DaysCollection from './days-collection';


export default BaseModel.extend({
	constructor: function () {
		this.on('change', this.onChange);
		BaseModel.apply(this, arguments);

		this.on('month:selected', (v) => this.setMonth(v));
		this.on('year:selected', (v) => this.setYear(v));
	},
	initialize (data, opts = {}) {
		this.valueModel = opts.valueModel;
	},
	onChange: function () {
		const n = new Date();
		this.now = new Date(n.getFullYear(), n.getMonth(), n.getDate());
		this.date = new Date(this.get('year'), this.get('month'), 1);
		this.trigger('ready', this);
	},
	year: function () {
		return this.date.getFullYear();
	},
	month: function () {
		return this.date.getMonth();
	},
	daysCount: function () {
		const d = new Date(this.date.valueOf());
		d.setMonth(d.getMonth() + 1);
		d.setDate(0);
		return d.getDate();
	},
	createDayDate: function (day) {
		return new Date(this.get('year'), this.get('month'), day);
	},
	createDayModel: function (date) {
		return new DayModel({ id: date.valueOf(), value: date }, {
			month: this,
			value: this.valueModel
		});
	},
	_update: function () {
		this.set({
			year: this.date.getFullYear(),
			month: this.date.getMonth()
		}, { silent: true });
		this.days.reset(this.buildDays(), { parse: true });
		this.trigger('change', this);
	},
	addMonth: function (num) {
		this.date.setMonth(this.date.getMonth() + num);
		this._update();
	},
	setMonth: function (num) {
		this.date.setMonth(num);
		this._update();
	},
	setYear: function (num) {
		this.date.setYear(num);
		this._update();
	},

	getDaysCollection () {
		if (this.days) return this.days;
		this.days = new DaysCollection(this.buildDays(), { parse: true, valueModel: this.valueModel });
		return this.days;
	},
	buildDays () {
		const daysCount = this.daysCount();
		const days = [];
		for (let x = 1; x < daysCount + 1; x++) {
			const date = this.createDayDate(x);
			days.push(this.createDayModel(date));
		}

		// let col = new DaysCollection(null, { model: DayModel });
		// col.valueModel = this.get('valueModel');

		const first = _(days).first();
		const last = _(days).last();
		// col.reset(hash);



		const fd = first.getDay();
		if (fd > 1) {
			for (let x = 0; x < fd - 1; x++) {
				days.unshift(this.createDayModel(this.createDayDate(-x)));
			}
		}

		const ld = last.getDay();
		if (ld < 7) {
			const lastDate = new Date(last.id);
			for (let x = ld + 1; x < 8; x++) {
				lastDate.setDate(lastDate.getDate() + 1);
				days.push(this.createDayModel(new Date(lastDate.valueOf())));
			}
		}

		return {
			first,
			last,
			days
		};
	}
});
