// import Collection from 'base/collection';
import { BaseBlockLayout } from '../base-block-layout';
import { buildTabsHeader } from './tabs';
import { ProcessesListView } from './processes-list-view';

export const BlockLayout = BaseBlockLayout.extend({
	// getCollection() {
	// 	//return getStoreCollection(this.model);
	// 	if (!this._collection) {
	// 		this._collection = new Collection();
	// 		this._collection.url = this.buildCollectionUrl();
	// 	}
	// 	return this._collection;
	// },
	// buildCollectionUrl() {

	// },
	// canManuallyAdd()
	// {
	// 	return this.model.storeBatchCanBeManuallyAdded();
	// },
	customViews () {
		const defs = {
			model: this.model
		};

		const tabId = this.getOption('tabId');
		const isRoot = tabId === 'root';
		const state = isRoot ? 'active' : 'completed';
		// поиск по номеру партии...
		return [
			buildTabsHeader(tabId, this.ac, isRoot),
			// new JustInputView({
			// 	...defs,
			// 	placeholder: 'поиск по номеру партии...',
			// 	onChange(text) {
			// 		this.collection.trigger('id:contains', text);
			// 	},
			// }),
			// new PartiesHeaders(defs),
			new ProcessesListView({
				className: 'elastic-content',
				state,
				...defs
			})
			// new AmountView(defs),

            // this.canManuallyAdd()
			// 	?  new BlockFooterTextAction({ text: 'добавить партию', clickAction: 'add:party:click' })
			// 	: null
		];
	}
	// childViewEvents: {
	// 	'add:party:click'() {
	// 		this.model.executeAction('add:store:party', this.getCollection());
	// 	}
	// }
});
