
import Base from '../entries';
export default Base.extend({
	newInTop: false,
	afterInitialize () {
		this.collection = this.getOption('entries');
	},
	modelIsBad: () => false,
	viewFilter (v) {
		return this.isCustomView(v) || this.modelInView(v.model);
	},
	sortWithCollection: true
});
