import { PageWithDivisionsLayout } from 'components/page-sub-division/PageWithDivisionsLayout';
import BasePage from './auth';

export const pageWithDivisionsMixin = {
	Layout: PageWithDivisionsLayout,
	layoutShowOptions: {
		replaceElement: true
	},
	spreadUpModel (model) {
		if (this.model) { return; }
		this.model = model;
		if (this.isDivisionsRoot()) { return; }
		const parent = this.getParent();
		if (parent.spreadUpModel) {
			parent.spreadUpModel(model);
		}
	},
	isDivisionsRoot () {
		return this.getOption('divisionRoot') === true;
	},
	getDivisionRootPage () {
		if (this.isDivisionsRoot()) {
			return this;
		}
		const parent = this.getParent();
		if (!parent.getDivisionRootPage) {
			return null;
		}
		return parent.getDivisionRootPage();
	},
	getHeader () {

		let label = this.getOption('label', true);
		const root = this.getDivisionRootPage();
		if (root !== this) {
			label = root.getHeader() + ': ' + label;
		}
		return label;
	}

};

export const PageWithDivisions = BasePage.extend({
	...pageWithDivisionsMixin
});
