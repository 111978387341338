
import { ReportSchema } from './report-schema';
import { getSchemaRaw } from '../schemas';
const namedSchemas = new Map();


export function buildReportSchema (name, raw, options) {
	const reportSchemaOptions = Object.assign({}, options, { name, rawSchema: raw });
	const reportSchema = new ReportSchema(reportSchemaOptions);

	namedSchemas.set(name, reportSchema);

	return reportSchema;
}


export function getOrBuildReportSchema (name, raw, options) {
	let schema = namedSchemas.get(name);
	if (!schema) {
		schema = buildReportSchema(name, raw, options);
	}
	return schema;
}

export function getReportSchema (reportType) {
	const schema = namedSchemas.get(reportType);
	if (schema) return schema;
	const reportData = getSchemaRaw(reportType);
	if (!reportData) {
		throw new Error('report not defined: ' + reportType);
	}
	const [raw, valuesMixin, filters] = reportData;
	return getOrBuildReportSchema(reportType, raw, { valuesMixin, filters });
}
