import '../api-config';
import BaseModel from 'base/model';
import Actionable from 'mixins/actionable';
import BbStore from 'base/bb-store';
import mix from 'helpers/mix';
// import emps from '../singleton';
import busData from 'bus/data';
import action from 'helpers/action';
import editValue from 'components/edit-value';
import enums from 'helpers/enums';
import { invokeValue } from '../../../../utils';

const rolesOrder = ['basic', 'likeLead', 'leadAssistent', 'lead'];

const PropertiesMixin = {
	properties: {
		eId: {
			nested: (eId) => busData.employees(eId)
		},
		name: {
			display: {
				transform () {
					const emp = this.getEmployee();
					return emp && emp.display('name');
				}
			}
		},
		role: {
			display: {
				transform (v) {
					return enums.get('employeeGroupEmployeeRoles', v);
				}
			}
		}
	}
};
const Base = mix(BaseModel).with(Actionable, PropertiesMixin);
const Model = Base.extend({
	// constructor(){
	// 	Base.apply(this, arguments);

	// },

	getAction () {
		this.initEmployeeInEntityActions();
		return Base.prototype.getAction.apply(this, arguments);
	},
	getActions () {
		this.initEmployeeInEntityActions();
		return Base.prototype.getActions.apply(this, arguments);
	},
	/* initialize(hash, opts = {}){
		this.parent = opts.parent;
		this.parentId = this.parent.id;
	}, */
	getEmployee () {
		return busData.employees().get(this.get('eId'));
	},
	isVirtual () {
		const emp = this.getEmployee();
		if (!emp) return true;
		return emp.isVirtual();
	},
	getParentEntity () {
		return (this.collection || {}).parent;
	},
	initEmployeeInEntityActions () {
		console.error('	init actions emp')
		if (this._employeeInEntityActionsInitialized) return;
		this._actions || (this._actions = []);

		const entityParent = this.getParentEntity();
		const empsActions = entityParent && entityParent.employeesActionsConfig;

		const changeRoleRights = empsActions && empsActions.changeRole && empsActions.changeRole.rights;
		if (changeRoleRights) {
			const rights = invokeValue(changeRoleRights, entityParent, entityParent);
			const changeRoleAction = action('change:role', 'изменить роль', changeRoleRights);
			this._actions.push(changeRoleAction); 
		}

		// var empsActions = this.parent && this.parent.employeesActionsConfig && this.parent.employeesActionsConfig;
		// var rights = empsActions && empsActions.changeRole && empsActions.changeRole.rights;
		// if (rights)
		// 	this._actions.push(action('change:role','изменить роль', rights));

		const emp = this.getEmployee();
		if (emp) { this._actions.push(...emp.getActions({ placedAt: 'plate' })); } else { Console.warn('employee not found', this.get('eId')); }

		this._employeeInEntityActionsInitialized = true;
	},
	actionChangeRole () {
		editValue({
			header: 'Роль в отделе',
			controlType: 'select',
			sourceValues: enums.store.employeeGroupEmployeeRoles,
			value: this.get('role'),
			editOptions: {
				resetButton: false,
				cancelLabel: 'закрыть без изменений',
				applyLabel: 'применить изменения'
			}
		}).then((role) => {
			this.patch({ role }, { wait: true });
		}, () => {});
	},
	actionOpenCard (...args) {
		const emp = this.getEmployee();
		return emp.actionOpenCard(...args);
	},
	actionOpenCardNewtab (...args) {
		const emp = this.getEmployee();
		return emp.actionOpenCardNewtab(...args);
	},
	// url(){
	// 	return this.parent.url() + '/employees/' + this.get('eId');
	// },
	roleOrder () {
		const role = this.get('role');
		return (rolesOrder.indexOf(role) + 1) * -1;
	},
	url () {
		const parentEntity = this.getParentEntity();

		return parentEntity && parentEntity.url() + '/employees/' + this.get('eId');
	}
});

const StoreModel = BbStore(Model);

export default StoreModel;
