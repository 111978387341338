import Yat from 'marionette.yat';
const Behavior = Yat.Behaviors.Behavior;
export default Behavior.extend({
	events: {
		click () {
			this.view._folded = !this.view._folded;
			if (this.view._folded) {
				this.view.$el.addClass('folded');
			} else {
				this.view.$el.removeClass('folded');
			}
		}
	}
});
