import { moment } from 'vendors';
export default function dateTwin (date) {
	const di = Date.info(date);
	const mom = moment(di.date);
	const time = mom.format('[в] LT');

	if (Date.sameDay(di.date)) {
		return {
			top: time,
			bottom: ''
		};
	} else if (Date.isTomorrow(di.date)) {
		return {
			top: time,
			bottom: 'завтра'
		};
	} else if (Date.sameMonth(di.date)) {
		return {
			top: mom.format('DD.MM'),
			bottom: time
		};
	} else if (!Date.sameYear(di.date)) {
		return {
			top: mom.format('YYYY'),
			bottom: mom.format('DD MMM')
		};
	} else {
		return {
			top: mom.format('DD.MM'),
			bottom: time
		};
	}
}
