import { _ } from 'vendors';
import isRange from './is-range';
import isSingle from './is-single';


export default function getModelType (model, opts = {}) {
	let type;
	if (isRange(model)) { type = 'range'; } else if (isSingle(model)) { type = 'single'; } else { type = 'unknown'; }
	if (_.isObject(opts.types)) { opts.types[type] = 1; }
	return type;
}
