import { _ } from 'vendors';
import enums from 'helpers/enums';

function nullable (v1, v2) {
	if (v1 == null && v2 == null) { return true; } else if (v1 == null && v2 != null) { return false; } else if (v1 != null && v2 == null) { return false; }
}

function isBetween (value, range) {
	const n = nullable(value, range);
	if (n != null) { return false; }

	const from = range.from;
	const to = range.to;

	return (from == null || (value >= range.from)) && (to == null || (value <= range.to));
}
function isEqual (v1, v2) {
	if (nullable(v1, v2) === false) { return; }

	if (v1 === v2) { return true; } else if (v1.toString() === v2.toString()) { return true; } else { return false; }
}
function compareOne (model, filter, cfg = {}) {
	if (_.isSimple(filter)) {
		if (cfg.type === 'enum') { return enums.has(filter, model); } else { return isEqual(model, filter, cfg); }
	} else if (filter.value) { return isEqual(model, filter.value); } else if (filter.from || filter.to) { return isBetween(model, filter); }
}

export default function compare (value, values, cfg) {
	if (!_.isArray(values)) { return compare(value, [values], cfg); }

	return _(values).some((v) => compareOne(value, v, cfg));
}
