import { _ } from 'vendors';
import ModelProperties from 'components/ui-model-card/properties';
import h from 'components/ui-model-card/helpers';
import Model from '../models/model';
import ControlMixin from 'components/controls/mixin';

function initModel (model) {
	if (model) return;
	this.model = new Model();
}

const defs = { editButton: true, addClass: 'whole-btn', textAction: 'edit' };
const Base = ControlMixin(ModelProperties);
export default Base.extend({
	constructor (opts) {
		initModel.call(this, this.model || opts.model);
		Base.apply(this, arguments);
		this.on('render', () => this.trigger('content:ready'));
	},
	className: 'card lined with-border create-product-type',
	properties: [
		h.ui('one', _.clone(defs)),
		h.ui('few', _.clone(defs)),
		h.ui('many', _.clone(defs)),
		h.ui('short', _.clone(defs))
	],
	getValue () {
		return this.model.toJSON();
	},
	modelEvents: {
		'change' () {
			this.triggerChange();
		}
	},
	validate (data) {
		return !(!data || !data.one || !data.short);
	}

});
