import BasePage from 'pages/auth';
import EditAmounts from './edit-amounts';
// import Layout from './layout';
// import { StoreCollection } from 'mods/store/collection';
// import { ReportLayout, getOrBuildReportSchema } from 'mods/reports';
// import { momentSchema } from './schemas';
// import { StoreModelMethods } from './list-item';

const Page = BasePage.extend({
	moduleRights: { productsStore: 'view' },
	label: 'Склад',
	route: 'store',
	children: [EditAmounts],
	icon: 'store'
	// Layout: ReportLayout,
	// layoutOptions: {
	// 	reportSchema: getOrBuildReportSchema('store-static', momentSchema, { valuesMixin: StoreModelMethods })
	// },
	// onBeforeStart(ac) {
	// 	if (!this.collection) {
	// 		this.addCollection(new StoreCollection());
	// 	}
	// }
});

export default Page;
