import Base from './base-value';
import transform from './transform-before-get';
// import labelConverters from './label-converters';

function toDate (value) {
	if (!value) return undefined;
	if (value instanceof Date) return value;
	const date = new Date(value);
	if (isNaN(date.valueOf())) return undefined;
	return date;
}

function convertToDate (obj) {
	if ('from' in obj) {
		obj.from = toDate(obj.from);
	}
	if ('to' in obj) {
		obj.to = toDate(obj.to);
	}
	return obj;
}

function normalizeField (obj, key, newkey) {
	if (key in obj) {
		const value = obj[key];
		delete obj[key];
		obj[newkey] = value;
	}
}

function normalize (obj) {
	normalizeField(obj, 'f', 'from');
	normalizeField(obj, 't', 'to');
}

function convert (obj, type) {
	normalize(obj);
	switch (type) {
	case 'date': return convertToDate(obj);
	default: return obj;
	}
}


const Model = Base.extend({

	type: 'range',
	urlKeys: {
		from: 'f',
		to: 't'
	},
	defaults: {
		from: undefined,
		to: undefined
	},
	getValue () {
		const from = this.get('from', { transform });
		const to = this.get('to', { transform });
		if (from != null || to != null) {
			return { from, to };
		}
	},
	setValue (value) {
		this.set({
			to: value && value.to,
			from: value && value.from
		});
	},
	parse (value, valueType) {
		if (valueType && typeof valueType === 'object') {
			valueType = undefined;
		}
		if (!valueType) {
			if (this) {
				if (this.valueType) {
					valueType = this.valueType;
				} else if (this.collection && this.collection.valueType) {
					valueType = this.collection.valueType;
				} else {
					console.warn('failed to detect valueType', value);
				}
			}
		}

		if (valueType && value && typeof value === 'object') {
			const res = convert(value, valueType);
			return res;
		}

		return value;
	}
	/* getLabel(){
		let value = this.getValue();
		if(value == null) return '';
		let res = [];
		if(value.from != null){
			res.push('от ' + labelConverters.convert(value.from, this.valueType));
		}
		if(value.to != null){
			res.push('до ' + labelConverters.convert(value.to, this.valueType));
		}
		return res.join(' ');
	},
	getValue(){
		let value = this.toValue();
		return _.size(value) ? value : undefined;
	} */
});


export default Model;
