import { _ } from 'vendors';
import Input from 'components/controls/input';
import View from 'base/view';

import mix from 'helpers/mix';
import ControlsMixin from 'components/controls/mixin';

import Predefined from 'components/controls/predefined';


export default mix(View).with(ControlsMixin).extend({
	_type: 'input-placeholdered',
	className: 'placeholdered placeholdered-input',
	template: _.template('<div><input/></div><% if (predefined) {%><div class="predefined-values"></div><% } %>'),
	regions: {
		input: { el: 'input', replaceElement: true },
		predefined: '.predefined-values'
	},
	templateContext () {
		return {
			predefined: this.hasPredefinedValues()
		};
	},
	optionsConfig: {
		tagName: 0,
		className: 0,
		label: 0,
		inputAttrs: 'attrs',
		inputOptions: 0,
		controlName: 0
	},
	hasPredefinedValues () {
		return !!this.getPredefinedValues();
	},
	getPredefinedValues () {
		const values = this.getOption('predefinedValues');
		if (!values || (Array.isArray(values) && !values.length)) {
			return;
		}
		return values;
	},
	getInputOptions () {
		const opts = _.extend({}, this.options);

		_(this.optionsConfig).each((type, key) => {
			if (_.isString(type)) {
				opts[type] = opts[key];
			}
			delete opts[key];
		});
		const inputOptions = this.getOption('inputOptions') || {};
		if (this.inputOptions || this.options.inputOptions) {
			if (!('value' in inputOptions)) {
				inputOptions.value = this.value;
			}
		}
		const result = _.extend(opts, inputOptions);
		return result;
	},
	showInput () {
		const opts = _.extend(this.getInputOptions(), {
			proxyTo: this
		});

		if (this.renderCounts) {
			opts.focusOnRender = false;
		}

		const view = new Input(opts);
		this.showChildView('input', view);
	},
	showPredefined () {
		const values = this.getPredefinedValues();
		if (!values) return;
		const predefined = new Predefined({ values });
		const region = this.getRegion('predefined');
		try {
			region.show(predefined);
		} catch (E) {
			/**/
		}
	},
	onRender () {
		!this.renderCounts && (this.renderCounts = 0);

		this.showInput();
		this.showPredefined();
		this.trigger('content:ready');

		this.renderCounts++;
	},
	onChildviewPredefinedClick (view) {
		this.value = view.model.get('value');
		this.triggerChange();
		this.render();
	},
	attributes () {
		const attrs = _.extend({}, this.getOption('attrs'), {
			'data-placeholder': this.getOption('label')
		});
		return attrs;
	},
	controlsEvents: {
		'control:change' (...args) {
			this.triggerChange(...args);
		},
		'control:done' (...args) {
			this.triggerDone(...args);
		},
		'control:validate:error' (...args) {
			this._triggerEvent('validate:error', ...args);
		}
	}
});
