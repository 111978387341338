import BaseCollection from 'base/collection';
import BaseModel from 'base/model';
import user from 'app/user';

export const CheckListModel = BaseModel.extend({
	isChecked () {
		return this.get('checked') === true;
	},
	getCheckedState () {
		return this.isChecked() ? 'checked' : 'unchecked';
	},
	toggleChecked () {
		if (this.isChecked()) {
			this.set({ checked: false, employeeId: undefined });
		} else {
			const last = new Date();
			const hash = { checked: true, last, employeeId: user.getIdentityId() };
			if (!this.get('last')) {
				hash.first = last;
			}
			this.set(hash);
		}
		this.collection.trigger('edited');
	},
	applyChanges (hash) {
		this.set(hash);
	}
});

export const CheckListCollection = BaseCollection.extend({
	constructor () {
		BaseCollection.apply(this, arguments);
		this._initializeEdithandler();
	},
	_initializeEdithandler () {
		if (this._editHandlerInitialized) return;
		this.on('edited', this._onEdited);
		this._editHandlerInitialized = true;
	},
	_onEdited () {
		if (!this.parent) return;
		this.parent.trigger('checkList:edited');
	},
	model: CheckListModel
});
