import smartOpen from 'helpers/smart-open';

export const clickableMixin = {
	initializeClickableMixin () {
		this.addStateClassName('clickable');
		this.ensureClickable();
		if (this.model) {
			this.listenTo(this.model, 'change', this.ensureClickable);
		}
		this.on('before:render', this.ensureClickable);
	},
	ensureClickable () {
		const url = this.getUrl();
		if (url) {
			this._registerUrl();
		} else {
			this._unregisterUrl();
		}
	},
	getUrl () {
		return this.getOption('url', true);
	},
	_registerUrl () {
		if (this.state('clickable')) { return; }
		this.delegate('click', event => this._clickableHandler(event));
		this.state('clickable', true);
	},
	_unregisterUrl () {
		if (!this.state('clickable')) { return; }

		this.undelegate('click');
		this.state('clickable', false);

	},
	_clickableHandler (event) {
		const url = this.getUrl();
		if (!url) { return; }
		const newtab = this.getOption('openInNewTab', true);
		let ctrlKey = !!event.ctrlKey;
		if (newtab) {
			ctrlKey = !ctrlKey;
		}
		smartOpen(url, { ctrlKey });
	}
};
