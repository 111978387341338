import enums from 'helpers/enums';

const enumval = (key, v) => enums.get(key, v);

// const positiveNegative = value => {
//     return value === 0 ? ''
//         : value > 0 ? ' positive'
//         : ' negative'
// }

export const productFields = [
	{
		key: 'product.origin',
		headerShort: 'И',
		header: 'Источник',
		addValueToClass: true,
		title: '<%= display("product.origin")%>',
		content: '<i></i>',
		display: v => enumval('productOrigins', v)
	},
	{
		key: 'product.purpose',
		headerShort: 'Н',
		header: 'Назначение',
		addValueToClass: true,
		title: '<%= display("purpose")%>',
		content: '<i></i>',
		value: v => {
			const p = v.model.get('product.purpose') || '';
			return p.split(/\s*,\s/g).join(' ');
		},
		display: v => enumval('productPurposes', v)
	},
	{
		key: 'product.fullName',
		header: 'Продукция',
		addValueToTitle: true
	}
];

export const contragentFields = [
	{
		key: 'contragent.name',
		header: 'Контрагент',
		addValueToTitle: true
	}
];
