import BaseLayout from '../base-layout';
// import UiCardBlock from 'components/ui-card-block/block';
import { BlockLayout } from './block-layout';

// export const ProductProcessesUiBlock = UiCardBlock.extend({
// 	addClass: 'double-width whole-column',
// 	header:{ text: 'процессы', addClass:'bg-main-dark'},
// 	content: BlockContent,
// 	contentOptions(){
// 		return {
// 			// tagName:'ul',
// 			// addClass: 'scrolled-y',
// 			model: this.model
// 		};
// 	},
// });

export const ProductProcessesUiBlock = BaseLayout.extend({
	processesContents () {
		return [
			new BlockLayout({ model: this.model, ac: this.actionContext, tabId: 'root' })
		];
	},
	closedContents () {
		return [
			new BlockLayout({ model: this.model, ac: this.actionContext, tabId: 'closed' })
		];
	}
});
