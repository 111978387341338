import { _ } from 'vendors';
import View from 'base/view';
import mix from 'helpers/mix';
import ControlsMixin from 'components/controls/mixin';
import InputPlaceholdered from 'components/controls/input-placeholdered';
import Checks from 'components/controls/checks';
import PersonName from './name';
import template from './template.html';
import testValue from 'helpers/validate/test-value';
import flat from 'helpers/flat';
import unflat from 'helpers/unflat';

export default mix(View).with(ControlsMixin).extend({
	className: 'c-person',
	template,
	regions: {
		name: { el: '.c-p-name', replaceElement: false },
		primary: { el: '.c-p-primary', replaceElement: false },
		note: { el: '.c-p-note', replaceElement: false }
	},
	initialize () {
		this.initValue();
	},
	initValue () {
		const value = _.extend({}, this.getOption('value', { deep: false }));
		const raw = flat(_.pick(value, 'contactName', 'person', 'primary', 'note'));
		this.value = unflat(raw);
		this.initialValue = unflat(raw);
		// this.prepareInitialValues();
	},
	onRender () {
		this.showName();
		this.showPrimary();
		this.showNote();
		this.trigger('content:ready');
	},
	showName () {
		const view = new PersonName({
			proxyTo: this,
			controlName: 'name',
			value: this.getValue()
		});
		this.showChildView('name', view);
	},
	showNote () {
		const value = (this.getValue() || {}).note;
		const view = new InputPlaceholdered({
			proxyTo: this,
			controlName: 'note',
			type: 'text',
			maxLength: 512,
			label: 'дополнительно',
			focusOnRender: false,
			value
		});
		this.showChildView('note', view);
	},
	showPrimary () {
		const view = new Checks({
			className: 'buttons-group show-selected-icon h41px',
			proxyTo: this,
			controlName: 'primary',
			type: 'checkbox',
			value: this.value.primary,
			valueType: 'boolean',
			sourceValues: {
				true: 'основной'
			}
		});
		this.showChildView('primary', view);
	},
	controlsEvents: {
		'note:change' (value) {
			this.value.note = value;
			this.triggerChange();
		},
		'note:done' () {
			// this.value.note = value;
			this.triggerChange();
			this.triggerDone();
		},
		'primary:change' (value) {
			this.value.primary = _.toBoolean(value);
			this.triggerChange();
		},
		'name:change' (value) {
			const result = {};
			_(value).each((propVal, prop) => {
				this.value[prop] = propVal;
				result[prop] = propVal;
			});
			this.triggerChange();
		},
		'name:validate:error' (value) {
			this.triggerChange(value);
		},
		'name:done' () {
			this.triggerChange();
			this.triggerDone();
		}
	},
	validate (_value, opts = {}) {
		opts.errors || (opts.errors = []);
		const value = _value || this.getValue();
		// let check = false;
		// !value || ()
		// if (!value) {

		// } else if (!value.person && !value.contactName) {

		// }
		// if (value.contactName != null) {
		// 	check = true;
		// } else {
		// }
		const pn = (((value || {}).person || {}).name || {});
		const pnv = _.reduce(pn, (memo, val, key) => {
			memo += (val || '');
			return memo;
		}, (value || {}).contactName || '');
		const check = !!pnv.trim().length;
		// let check = value != null && (value.contactName != null || (_.isObject(value.person) && _.size(value.person.name)>0));

		if (testValue(check, 'invalidContact', opts.errors)) {
			return true;
		} else {
			return false;
		}
	}
});
