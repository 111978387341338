import { _ } from 'vendors';
import busData from 'bus/data';
import busModels from 'bus/models';
import { buildMediaUrl } from 'helpers/media-url';
import enums from 'helpers/enums';

const TimeSpan = window.TimeSpan;

function anchor (type, label, id) {
	return `<span class="chat-anchor ${type}-anchor" data-id="${id}"><i></i><span>${label}</span></span>`;
}

function employeeHtmlById (id) {
	if (!id) return '';
	const emp = busData.employees(id);
	if (!emp) return '';
	return anchor('user', emp.display('name'), id);
	// return `<span class="user-anchor"><i></i><span>${emp.display('name')}</span></span>`;
}

function changes (modelName, change) {
	if (modelName === 'ProcessTeamMember') {
		console.log('- proc team member changes -');
	}
	let label, old, fresh;
	try {
		label = busModels.label(modelName, change.path); // model.getPropertyLabel(change.path);
	} catch (exc) {
		console.log('going to throw label...');
		throw exc;
	}
	try {
		old = busModels.display(modelName, change.path, change.old) || '&lt;без значения&gt;';
	} catch (exc) {
		console.log('going to throw old...');
		old = busModels.display(modelName, change.path, change.old) || '&lt;без значения&gt;';
		throw exc;
	}
	try {
		fresh = busModels.display(modelName, change.path, change.new) || '&lt;без значения&gt;';
	} catch (exc) {
		console.log('going to throw fresh...');
		throw exc;
	}
	return `<span class="line"><label>${label}:</label><span class="old-value">${old}</span><span class="new-value">${fresh}</span></span>`;
}

function htmlAccent (text, classes = '') {
	return `<span class="accent ${classes}">${text}</span>`;
}


function personName (person, name) {
	if (!person) {
		return name;
	}
	const pname = person.name || {};
	return pname.shortFull;
}

function getProductionCycleSubject (model, data) {
	console.log('#', data);
	let text = model.display('type');
	if (data && data.productionCycle) {
		text += ': ' + data.productionCycle.name;
	}
	return text;
}

function getProductionCycleComponentName (model, data) {
	const json = data && data.json;
	if (!json) { return ''; }
	return anchor('product', json.name, json.id);
}



const filesApi = {
	filename: (type, name) => `<i class="icon media ${type}"></i><span>${name}</span>`,
	filelink: (text, url, filename, contentType, type, download) => `<a auth-href="${url}" ${download ? ' data-download="true"':''} class="file-link" target="_blank" data-filename="${filename}" data-contenttype="${contentType}" data-type="${type}">${text}</a>`,
	fullHtml(url, filename, contentType, type) {
		const htmlFile = this.filename(type, filename);
		const res = this.filelink('открыть ' + htmlFile, url, filename, contentType, type)
		+ ' или ' + this.filelink('скачать', url, filename, contentType, type, true);
		return res;
	}
}


export const entryConfig = {
	entities: ['task'],
	negativeTypes: [
		'contragentContactValueDeleted', 'contragentContactDeleted'
	],
	entrySubjects: {
		comment: false,
		fileUploaded: false,
		taskRestarted (modelJson) {
			const json = Array.isArray(modelJson.json) ? modelJson.json[0] : modelJson.json;
			if (json.old === 'initial') {
				return 'задача взята в работу';
			} else {
				return this.display('type');
			}
		},
		processTeamMemberChanged (data) {
			let text = this.display('type');
			if (!data || !data.json || !data.json.teamMemberId) {
				return text;
			}
			text += ': ' + employeeHtmlById(data.json.teamMemberId);
			return text;
		},
		// productionCycleCreated (data) {
		// 	return getProductionCycleSubject(this, data);
		// },
		// productionCycleChanged (data) {
		// 	return getProductionCycleSubject(this, data);
		// },
		// productionCycleArchived (data) {
		// 	return getProductionCycleSubject(this, data);
		// },
		// productionCycleStarted (data) {
		// 	return getProductionCycleSubject(this, data);
		// },
		// productionCycleStoped (data) {
		// 	return getProductionCycleSubject(this, data);
		// },
		// productionCycleComponentRemoved (data) {
		// 	return getProductionCycleSubject(this, data);
		// },
		// productionCycleComponentAdded (data) {
		// 	return getProductionCycleSubject(this, data);
		// },
		// productionCycleComponentChanged (data) {
		// 	return getProductionCycleSubject(this, data);
		// },
		default () { return this.display('type'); }
	},
	entryTexts: {
		fileUploaded: modelJson => {
			if (enums.has(modelJson.marks || '', 'incorrect')) {
				return `<s>${modelJson.json.name}</s>`;
			}
			// console.log(modelJson);
			let html;
			let result;
			const contentType = modelJson.json.contentType;
			if (contentType.startsWith('image')) {
				const embedUrl = buildMediaUrl(modelJson.json, { option: 'embed', addToken: true });
				const url = buildMediaUrl(modelJson.json);
				html = `<img src="${embedUrl}" data-downloadurl="${url}">`;
				result = `<span data-action="modal">${html}</span>`;
			} else if (contentType.startsWith('audio')) {
				const embedUrl = buildMediaUrl(modelJson.json, { option: 'embed', addToken: true });
				result = `<audio id="${_.uniqueId('audio')}" controls controlslist="novolume"><source src="${embedUrl}" type="${contentType}"></audio>`;
			} 
			else if (contentType.startsWith('video')) {
				const json = modelJson.json;
				let embedUrl = buildMediaUrl(modelJson.json, { option: 'embed', addToken: true });
				const url = buildMediaUrl(modelJson.json);
				const embedError = filesApi.fullHtml(url, json.name, json.contentType, json.type);
				// embedUrl = "http://api.palma-med.loc/test.mp4";
				result = `<video src="${embedUrl}" width="320" height="240" controls>${embedError}</video>` 
				+ '<div>' + filesApi.filelink('скачать', url, json.name, json.contentType, json.type, true) + '</div>';
			}
// 			else if (contentType.startsWith('video')) {

// 				const embedUrl = buildMediaUrl(modelJson.json, { option: 'embed', addToken: true });
// 				const url = buildMediaUrl(json) + '/download';
// // `<video src="${embedUrl}">
// //   Ваш браузер не смог встроить видео, но вы всё еще можете его <a auth-href="${url}" class="file-link">скачать</a>
// // </video>`

// const json = modelJson.json;
// html = `<i class="icon media ${json.type}"></i><span>${json.name}</span>`;
// result = `
// 	<a auth-href="${url}" class="file-link" target="_blank" data-filename="${modelJson.json.name}" data-contenttype="${json.contentType}" data-type="${json.type}">открыть ${html}</a>
// 	или <a auth-href="${url}/download" class="file-link" target="_blank" data-filename="${modelJson.json.name}" data-contenttype="${json.contentType}" data-type="${json.type}">скачать</a>
// `;



// 			} 
			else {

				const json = modelJson.json;
				const url = buildMediaUrl(json);

				// html = `<i class="icon media ${json.type}"></i><span>${json.name}</span>`;
				// const l = (filename, forceDownload) => `<a auth-href="${url}" ${forceDownload ? ' data-download="true"':''} class="file-link" target="_blank" data-filename="${modelJson.json.name}" data-contenttype="${json.contentType}" data-type="${json.type}">${filename}</a>`;
				// result = l('открыть ' + html) + ' или ' + l('скачать', true);

				result = filesApi.fullHtml(url, json.name, json.contentType, json.type);

				// const url = buildMediaUrl(json);
				// const json = modelJson.json;
				// html = `<i class="icon media ${json.type}"></i><span>${json.name}</span>`;
				// const add = `<a auth-href="${url}" class="file-link" target="_blank" data-filename="${modelJson.json.name}" data-contenttype="${json.contentType}" data-type="${json.type}">скачать</a>`;
				// result = `<a auth-href="${url}" data-download="true" class="file-link" target="_blank" data-filename="${modelJson.json.name}" data-contenttype="${json.contentType}" data-type="${json.type}">открыть ${html}</a> или так ` + add;
					// или 
			}
			return result;
		},
		taskDeadlineChanged: json => {
			return htmlAccent(TimeSpan.toString(json.json[0].difference), (json.json[0].positive).toString());
		},
		processTeamAdded: json => {
			return _.map(json.json, employeeHtmlById).join('');
		},
		processTeamRemoved: json => {
			return _.map(json.json, employeeHtmlById).join('');
		},
		processTeamMemberChanged: (json, ...rest) => {
			if (!json || !json.json || !json.json.changes) { return ''; }
			const jsonChanges = json.json.changes;
			const ModelName = 'ProcessTeamMember';
			const changesHtml = _.map(jsonChanges, change => {
				switch (change.path) {
				// case 'checkList':
				// 	return `<span class="line">${htmlAccent('изменены заметки')}</span>`;
				default:
					return changes(ModelName, change);
				}
			}).join('');

			return changesHtml;
		},
		taskObserversAdded: json => {
			return _.map(json.json, employeeHtmlById).join('');
		},
		taskObserversRemoved: json => {
			return _.map(json.json, employeeHtmlById).join('');
		},
		processChanged: (json, model) => {
			return _.map(json.json, change => {
				switch (change.path) {
				case 'checkList':
					return `<span class="line">${htmlAccent('изменены заметки')}</span>`;
				default:
					return changes('Process', change);
				}
			}).join('');
		},
		taskChanged: (json, model) => {
			return _.map(json.json, change => {
				switch (change.path) {
				case 'checkList':
					return `<span class="line">${htmlAccent('изменены заметки')}</span>`;
				default:
					return changes('EmployeeTask', change); // changes('Task', change);
				}
			}).join('');
		},
		taskCheckerChanged: json => {
			return employeeHtmlById(json.json[0].new);
		},
		taskResponsibleChanged: json => {
			return employeeHtmlById(json.json[0].new);
		},
		contragentChanged: (json) => {
			return _.map(json.json, change => {
				switch (change.path) {
				default:
					return changes('Contragent', change);
				}
			}).join('');
		},
		contragentDetailsChanged: (json) => {
			return _.map(json.json.changes, change => {
				switch (change.path) {
				default:
					return changes('BankDetails', change);
				}
			}).join('');
		},
		contragentDetailsCreated: json => htmlAccent(json.json.shortName || json.json.fullName),
		contragentContactCreated: json => htmlAccent(personName(json.json.person, json.json.contactName)),
		contragentContactValueCreated: json => htmlAccent(json.json.person.name) + ': ' + htmlAccent(json.json.contact.value.value),
		contragentContactValueDeleted: json => htmlAccent(json.json.person.name) + ': ' + htmlAccent(json.json.contact.value.value),
		contragentContactDeleted: json => htmlAccent(json.json.name),
		contragentContactChanged: (json) => {
			const htmlchanges = _.map(json.json.changes, change => {
				switch (change.path) {
				default:
					return changes('Contact', change);
				}
			}).join('');

			return htmlAccent(json.json.entity.name, 'person') + htmlchanges;
		},
		contragentContactValueChanged: (json) => {
			const htmlchanges = _.map(json.json.changes, change => {
				switch (change.path) {
				case 'id':
				case 'value.id':
				case 'value.flatValue':
					return '';
				default:
					return changes('ContactValue', change);
				}
			}).join('');

			return htmlAccent(json.json.person.name + ': ' + json.json.contact.value.value, 'person-contact') + htmlchanges;
		},
		resourceProductChanged: (json) => {
			return _.map(json.json, change => {
				switch (change.path) {
				default:
					return changes('AppProduct', change);
				}
			}).join('');
		},
		productionCycleComponentRemoved (data) {
			return getProductionCycleComponentName(this, data);
		},
		productionCycleComponentAdded (data) {
			return getProductionCycleComponentName(this, data);
		},
		productionCycleComponentChanged (data) {
			const json = data.json;
			if (!json) { return; }

			const name = anchor('product', json.productName, json.productId);

			return name;
		}
	}
};
