import { $ } from 'vendors';
let fileEl;

function getFileEl () {
	if (!fileEl) {
		fileEl = $('<input type="file" />');
	} else {
		fileEl.off('change');
		fileEl.val('');
	}
	return fileEl;
}

export function selectFiles (resolve) {
	const $i = getFileEl();
	$i.one('change', () => {
		const files = $i.get(0).files;
		if (files && files.length) {
			const blobs = [];
			for (let x = 0; x < files.length; x++) { blobs.push(files[x]); }

			resolve(blobs);
		}
	});
	$i.click();
}
