import { _ } from 'vendors';
import SearchCollection from 'base/search-collection-view';
import Employee from 'mod/employees/views/list-item';

export default SearchCollection.extend({
	collectionTagName: 'ul',
	collectionClassName: 'entity-employees',
	className: 'plain',
	emptyView: Employee.Empty,
	childView: Employee.extend({
		tagName: 'li',
		getParent () {
			return this.getOption('parent');
		},
		closeButton () {
			const parent = this.getParent();
			return parent && parent.employeesCanBeRemoved([this.model]);
		},
		getEmployee () {
			return this.model.getEmployee();
		},
		text () {
			return this.getEmployee().display('name');
		},
		topText () {
			if (!this.getOption('withRoles')) return;
			const role = this.model.get('role');
			return role !== 'basic' ? this.model.display('role') : null;
		},
		onCloseClick () {
			this.trigger('remove:employee:click', this.model);
		}
	}),
	childViewOptions () {
		const addChildViewOptions = this.getOption('addChildViewOptions');
		const options = _.extend({
			withRoles: this.getOption('withRoles'),
			parent: this.model
		}, addChildViewOptions);
		// console.log('#### =options> ', options, addChildViewOptions);
		return options;
	},
	childViewTriggers: {
		'childview:remove:employee:click': 'remove:employee:click'
	},
	viewComparator (v) {
		const name = v.model.display('name');
		return name;
	},
	vviewComparator (v1, v2) {
		return _.comparator(
			[v1, v2, (m) => m.roleOrder()]
			, [v1, v2, (m) => m.isVirtual()]
			, [v1, v2, (m) => m.display('name')]
		);
	}
});
