import { _ } from 'vendors';
import Models from './models';
import data from 'bus/data';
import busEvents from 'bus/app-events';
// import flat from 'helpers/flat';

busEvents.request('Handler', {
	radioEvents: {
		groupChanged (ev) {
			const group = this.getModelByEvent(ev);
			if (!group) return;
			group.set(ev.data);
		},
		groupRightsChanged (ev) {
			const group = this.getModelByEvent(ev);
			if (!group) return;

			const newValue = _.extend({}, group.get('rights'), ev.data.rights);
			group.set('rights', newValue, { flat: false, parent: group });
		},
		groupEmployeesAdded (ev) {
			const group = this.getModelByEvent(ev);
			if (!group) return;
			group.getEmployees().add(ev.data, { parent: group });
		},
		groupEmployeesRemoved (ev) {
			const group = this.getModelByEvent(ev);
			if (!group) return;
			group.getEmployees().reset(ev.data, { parent: group });
		}
	},
	getModelByEvent (ev) {
		return ev.dataId && groups.get(ev.dataId);
	}
});
const Groups = Models.Collection.extend({});
const groups = new Groups();

data.groups = function (...args) {
	return data.request('groups', ...args);
};

data.reply('groups', (id) => {
	if (id == null) return groups;
	return groups.get(id);
});


export default groups;
