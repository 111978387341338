import { tableSchemaValue } from '../../schema-table/tableSchema-value';
import { Ui2AtomView } from '../atom';
import { tableRowCommonMixin } from './common';

export const UiTableCellView = Ui2AtomView.extend({

	...tableRowCommonMixin,

	baseClassName: 'ui2-atom table-cell',
	afterInitializeEnabled: true,
	showSmall: true,

	constructor: function () {
		Ui2AtomView.apply(this, arguments);
		this.initializeClassNameMixin();
		this.tableConfig = this.getOption('tableConfig', true) || {};
		this.tableSchema = this.getOption('tableSchema', true) || {};
		this.noLabels = this.tableConfig.cellsWithoutLabels === true;
		const noLabels = this.getOption('noLabels', true);
		if (noLabels != null) {
			this.noLabels = noLabels;
		}
		if (this.getOption('afterInitializeEnabled')) {
			this.triggerMethod('after:initialize');
		}
		if (this.getOption('shouldTriggerClick', true)) {
			this.delegate('click', () => this.triggerMethod('click', this));
		}
	},

	schemaValue (key) {
		return tableSchemaValue(this, key, this.tableSchema, this.model);
		// if (!this.tableSchema) {
		// 	console.warn('# no tableSchema provided');
		// }
		// const cellSchema = this.tableSchema[key];
		// if (cellSchema && cellSchema.value) {
		// 	return cellSchema.value.call(this, this, this);
		// }
		// return this.model.get(key);
	},


	getBottomTextHtml (ifEmpty) {
		if (this.noLabels) { return ''; }
		const key = this.isHeader() ? 'headerB' : 'b';
		return this.getHtmlValue(key + 'ottomText', ifEmpty);
	},
	getTextHtml (ifEmpty) {
		const key = this.isHeader() ? 'headerT' : 't';
		return this.getHtmlValue(key + 'ext', ifEmpty);
	},
	getSmallTextHtml (ifEmpty) {
		const key = this.isHeader() ? 'headerS' : 's';
		return this.getHtmlValue(key + 'mallText', ifEmpty);
	},
	getTopTextHtml (ifEmpty) {
		if (this.noLabels) { return ''; }
		const key = this.isHeader() ? 'headerT' : 't';
		return this.getHtmlValue(key + 'opText', ifEmpty);
	}

});
