import { _ } from 'vendors';

import measuresCollection from '../modules/resources/production/measures/singleton';

function isEmpty (arg) {
	return arg == null || arg === '';
}

export function measures (id, opts = 'short') {
	opts || (opts = {});

	if (arguments.length === 0) return measuresCollection;

	if (id == null) return id;

	const item = measuresCollection.get(id);
	if (!item || opts == null) return item;

	if (_.isString(opts)) { return item.display(opts); }

	if (opts.transform) { return opts.transform(item); }

	return item;
}

function convertToMeasure (value, sourceUnitsInDestinationUnit, destinationUnitsInSourceUnit) {

	if (isEmpty(value)) {
		return;
	}

	if (sourceUnitsInDestinationUnit) {
		value = value / sourceUnitsInDestinationUnit;
	} else if (destinationUnitsInSourceUnit) {
		value = value * destinationUnitsInSourceUnit;
	} else {
		value = '#ERR';
	}
	if (isNaN(value)) {
		value = '#ERR';
	}
	return value;
}
export function measuredAmount (options) {

	let {
		sourceAmount,
		sourceMeasureId,
		destinationMeasureId,
		sourceUnitsInDestinationUnit,
		destinationUnitsInSourceUnit,
		display,
		ifEmpty
	} = options;

	let value;
	if (destinationMeasureId !== undefined) {
		value = convertToMeasure(sourceAmount, sourceUnitsInDestinationUnit, destinationUnitsInSourceUnit);
	} else {
		value = sourceAmount;
		destinationMeasureId = sourceMeasureId;
	}
	if (!display) {
		return value;
	}
	if (isEmpty(value)) {
		return ifEmpty || '';
	}
	if (display === true) {
		// 'short' by default if display not provided in measures()
		display = undefined;
	}
	let unit = measures(destinationMeasureId, display) || '';
	if (unit) {
		unit = ' <small class="unit">' + unit + '</small>';
	}
	return _.displayNum(value, 4) + unit;
}
