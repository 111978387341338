
import Collection from 'base/collection';
import { TreeItem } from './TreeItem';
import { clearFilterFlags } from './common';
import { TreeCollection } from './TreeCollection';

const treeProdsInitialFilters = {
	fromSource: m => !m.componentIds || !m.componentIds.length,
	fromProduction: m => !m.producedIds || !m.producedIds.length
};

export const ProductionTree = TreeCollection.extend({
	tagName: 'section',
	// className: undefined
	childView: TreeItem,
	childViewOptions () {
		return {
			allModels: this.allModels,
			direction: this.getOption('direction'),
			departmentModel: this.model,
			filterModel: this.getOption('filterModel')
		};
	},
	initialize () {
		this.allModels = this.buildAllCollection();
		const models = this.getInitialModels();
		this.collection = new Collection(models);
		this.filterModel = this.getOption('filterModel');
		this.listenTo(this.filterModel, 'filter', () => this.filter());
	},
	getProduction () {
		return this.model.get('products');
	},
	buildAllCollection () {
		const prods = this.getProduction();
		return new Collection(prods);
	},
	getInitialModels () {
		const dir = this.getOption('direction');
		const prods = this.getProduction();
		return prods.filter(treeProdsInitialFilters[dir]);
	},
	onBeforeFilter () {
		console.log(':before:filter:', this.isRendered());
		this._clearViewsFilterFlags(this.children._views);
		// this.children._views.forEach(view => clearFilterFlags(view));
	},
	_clearViewsFilterFlags (views) {
		views.forEach(view => this._clearViewFilterFlags(view));
	},
	_clearViewFilterFlags (view) {
		clearFilterFlags(view);
		if (view.nestedChildViews) {
			this._clearViewsFilterFlags(view.nestedChildViews._views);
		}
	}
	// viewFilter (child) {
	// 	return child.isFilterPassed();
	// }
	// viewFilter (view) {
	// 	const filterModel = this.filterModel;
	// 	if (!this.filterModel) { return true; }
	// 	if (this.filterModel.filterModel(view.model)) {
	// 		return true;
	// 	}
	// 	return view.getNestedModels().some(model => this.filterModel.filterModel(model));
	// }

});
