import ModelProperties from 'components/ui-model-card/properties';
import ControlMixin from 'components/controls/mixin';
import h from 'components/ui-model-card/helpers';
import Model from 'base/model';
import EditableProperties from 'mixins/editable-properties';
import busData from 'bus/data';

const BaseChange = ControlMixin(ModelProperties);

export const AddToStoreModel = EditableProperties(Model).extend({
	enablePropertyChangeEvents: true,

	properties: {

		productName: {
			display: {
				label: 'Продукт',
				ifEmtpy: '&mdash;'
				// transform: (value, opts, model) => {
				// 	return model.productName;
				// }
			}
		},

		unitMeasureId: {
			display: {
				label: 'Учетная ед.',
				ifEmtpy: '&mdash;',
				transform: (value, opts, model) => {
					return busData.measures(value, 'short');
				}
			}
		},

		storeUnitMeasureId: {
			display: {
				label: 'Слкадская ед.',
				ifEmtpy: '&mdash;',
				transform: (value, opts, model) => {
					return busData.measures(value, 'short');
				}
			}
		},

		baseUnitsInStoreUnit: {
			display: {
				label: 'Учётных в 1 слкадской',
				ifEmtpy: '&mdash;',
				transform: (value, opts, model) => {
					const st_m = model.display('storeUnitMeasureId');
					const b_m = model.display('unitMeasureId');
					const b_in_s = model.get('baseUnitsInStoreUnit');
					return `1 ${st_m} = ${b_in_s} ${b_m}`;
					// return model.baseUnitsInStoreUnit;
				}
			}
		},

		amount: {
			display: {
				label: 'Укажите количество',
				ifEmpty: '&mdash;'
				// transform: (v,o,m) => v || (m && m.get('fullName'))
			},
			label: 'Количество',
			type: 'number'
		},


		transferMeasureId: {
			display: {
				label: 'ед. измерения операции',
				ifEmtpy: '&mdash;',
				transform: (value, opts, model) => {
					if (!value) return 'установите';
					return busData.measures(value, 'short');
				}
			},
			sourceValues: () => busData.measures(),
			controlType: 'select'
		},

		baseUnitsInTransferUnit: {
			display: {
				label: 'Сколько это учётных ед.',
				ifEmtpy: '&mdash;',
				transform: (value, opts, model) => {
					const bt_m = model.display('transferMeasureId');
					const b_m = model.display('unitMeasureId');
					const b_in_bt = model.get('baseUnitsInTransferUnit');
					if (!b_in_bt) {
						return 'установите';
					}
					return `1 ${bt_m} = ${b_in_bt} ${b_m}`;
				}
			}
		},


		calculatedAmount: {
			display: {
				label: 'Изменение',
				transform (value, opts, model) {
					const amount = model.get('amount') || 0;
					if (amount === 0 || !amount) {
						return 'укажите количество';
					}
					const bt_m = model.display('transferMeasureId');
					const b_m = model.display('unitMeasureId');
					const b_in_bt = model.get('baseUnitsInTransferUnit');
					if (!bt_m || !b_m || !b_in_bt) {
						return 'заполните форму';
					}
					const baseAmount = b_in_bt * amount;
					let add = '';
					if (b_m !== bt_m) {
						add = ` <small>(${amount} ${bt_m})</small>`;
					}

					const isWithdraw = model.get('isWithdraw');
					const sign = isWithdraw ? '&ndash; ' : '+ ';
					return `${sign}${baseAmount} ${b_m}${add}`;

					// //console.log('chpok', this, arguments)
					// return this.getBaseUnitsAmount();
				}
			}
		},

		idString: {
			display: {
				label: 'Номер партии',
				ifEmpty: '&mdash;'
			}

		},

		// batchUnitMeasureId: {
		// 	display: {
		// 		label:'ед. измерения партии',
		// 		ifEmpty: '&mdash;',
		// 		transform: (v) => busData.measures(v, 'short')
		// 	},
		// 	sourceValues: () => busData.measures(),
		// 	controlType: 'select',
		// },

		// batchUnitsInStoreUnit: {
		// 	display: {
		// 		label:'количество этих единиц в одной складской',
		// 		ifEmpty: '&mdash;'
		// 	},
		// 	type: 'number',
		// },

		comment: {
			display: {
				label: 'комментарий',
				ifEmpty: '&mdash;'
			}
		}

	},

	onChangeTransferMeasureId (model, value) {
		let b_in_bt;
		// const bm = this.get('unitMeasureId');
		if (value === this.get('unitMeasureId')) {
			b_in_bt = 1;
		} else if (value === this.get('storeUnitMeasureId')) {
			b_in_bt = this.get('baseUnitsInStoreUnit');
		}
		this.set('baseUnitsInTransferUnit', b_in_bt);
		// _.defer(() => this.set('baseUnitsInTransferUnit', b_in_bt))
	}

	// getBaseUnitsAmount() {
	// 	let buInSu  = this.get('batchUnitsInStoreUnit') || 0;
	// 	let amount = this.get('amount') || 0;
	// 	let amountInSUnits = amount * buInSu;
	// 	let baseUnit = busData.measures(this.baseUnit, 'short');
	// 	buInSu = this.baseUnitsInStoreUnit;
	// 	//console.log('~~ ', this.get('batchUnitsInStoreUnit'), this.get('amount'), this.baseUnit, this.baseUnitsInStoreUnit);
	// 	return _.displayNum(amountInSUnits * buInSu) + baseUnit;
	// }
});



const addDefs = { editButton: true, addClass: 'whole-btn', textAction: 'edit' };
const addOpts = function (...args) {
	const res = Object.assign({}, addDefs, ...args);
	if (!res.editButton && res.textAction === 'edit') {
		res.textAction = '';
	}
	if (!res.editButton && res.addClass === 'whole-btn') {
		res.addClass = '';
	}
	return res;
};

export const AddStoreParty = BaseChange.extend({
	initialize () {
		this.once('render', () => this.triggerChange());
		console.log('ADD MODEL', this.model);
	},
	className: 'card lined with-border add-product-contragent',
	properties: (m, v) => {
		return [
			h.ui('productName', { addClass: 'bg-lightblue gray-text' }),
			h.ui('unitMeasureId', { third: true, addClass: 'bg-lightblue gray-text' }),
			h.ui('storeUnitMeasureId', { third: true, addClass: 'bg-lightblue gray-text' }),
			h.ui('baseUnitsInStoreUnit', { third: true, addClass: 'bg-lightblue gray-text' }),

			h.ui('idString', addOpts({ value: (m && m.idString) || undefined, addClass: 'margin-t' })),
			h.ui('amount', addOpts({ third: true })),
			h.ui('transferMeasureId', addOpts({ third: true })),
			h.ui('baseUnitsInTransferUnit', addOpts({ third: true })),
		// m.get('idString') ? null : h.ui('batchUnitMeasureId', addOpts({ half: true })),
		// m.get('idString') ? null : h.ui('batchUnitsInStoreUnit', addOpts({ half: true })),
			h.ui('comment', addOpts()),
			h.ui('calculatedAmount', { addClass: 'bg-lightorange big-text margin-t' })
		];
	},
	getValue () {
		return this.model.toJSON();
		// return _.extend({joinAs:'purchase'}, this.model.toJSON());
	},
	modelEvents: {
		'change' () {
			this.triggerChange();
		}
	},
	validate (value) {
		if (!value) return false;
		// const maxValue = this.getOption('maxValue');



		let { idString, amount, transferMeasureId, baseUnitsInTransferUnit } = value;
		amount = parseFloat(amount, 10);
		const amountPositive = !isNaN(amount) && amount > 0;
		const amountNotMoreThan = true; // maxValue ? amount <= maxValue : true;
		const res = amountPositive && amountNotMoreThan && idString && transferMeasureId && baseUnitsInTransferUnit;
		// console.log('VALIDATE:', res, '=',amountPositive ,amountNotMoreThan ,idString , transferMeasureId,baseUnitsInTransferUnit)
		return res;
		// let { idString, amount, batchUnitMeasureId, batchUnitsInStoreUnit } = value;
		// amount = parseFloat(amount, 10);
		// let amountPositive = !isNaN(amount) && amount > 0;
		// let amountNotMoreThan = maxValue ? amount < maxValue : true;
		// return amountPositive && amountNotMoreThan && idString && batchUnitMeasureId && batchUnitsInStoreUnit > 0;
	}
});

export const RemoveFromParty = BaseChange.extend({
	initialize () {
		this.once('render', () => this.triggerChange());
	},
	className: 'card lined with-border add-product-contragent',
	properties: (m, v) => ([

		h.ui('productName', { addClass: 'bg-lightblue gray-text' }),
		h.ui('unitMeasureId', { third: true, addClass: 'bg-lightblue gray-text' }),
		h.ui('storeUnitMeasureId', { third: true, addClass: 'bg-lightblue gray-text' }),
		h.ui('baseUnitsInStoreUnit', { third: true, addClass: 'bg-lightblue gray-text' }),
		//= ==================
		h.ui('idString', addOpts({ value: (m && m.idString) || undefined, addClass: 'margin-t' })),
		h.ui('amount', addOpts({ third: true })),
		h.ui('transferMeasureId', addOpts({ third: true })),
		h.ui('baseUnitsInTransferUnit', addOpts({ third: true })),
		//= ==================
		h.ui('comment', addOpts()),
		h.ui('calculatedAmount', { addClass: 'bg-lightorange big-text margin-t' })

	]),
	getValue () {
		return this.model.toJSON();
		// return _.extend({joinAs:'purchase'}, this.model.toJSON());
	},
	modelEvents: {
		'change' () {
			this.triggerChange();
		}
	},
	validate (value) {
		// if (!value) return false;
		// let maxValue = this.getOption('maxValue');
		// let { amount } = value;
		// amount = parseFloat(amount, 10);
		// let amountPositive = !isNaN(amount) && amount > 0;
		// let amountNotMoreThan = maxValue ? amount <= maxValue : true;
		// return amountPositive && amountNotMoreThan;

		if (!value) return false;
		const maxValue = this.getOption('maxValue');


		let { idString, amount, transferMeasureId, baseUnitsInTransferUnit } = value;
		amount = parseFloat(amount, 10);
		const amountPositive = !isNaN(amount) && amount > 0;
		const baseAmount = amount * baseUnitsInTransferUnit;

		const amountNotMoreThan = maxValue ? baseAmount <= maxValue : true;
		const res = amountPositive && amountNotMoreThan && idString && transferMeasureId && baseUnitsInTransferUnit;
		// console.log('VALIDATE:', res, '=', maxValue, amountPositive ,amountNotMoreThan ,idString , transferMeasureId,baseUnitsInTransferUnit)
		return res;
	}
});
