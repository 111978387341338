import '../api-config';
import paths from 'helpers/paths';
import Model from 'base/card-model';
import mix from 'helpers/mix';
import EditableProperties from 'mixins/editable-properties';
import NestedEntitiesV2mixin from 'mixins/model/nested-entities-v2';
import EntityWithLogs2 from 'mods/logs/entity-with-logs-2-mixin';
import busData from 'bus/data';
import action from 'helpers/action';
import openAdd from '../views/open-add';

const ModelProperties = {
	properties: {
		name: {
			display: {
				label: 'Название типа'
			}
		},
		parentId: {
			nested: v => (v && busData.productTypes(v)) || undefined,
			display: {
				label: 'является подтипом для',
				transform: v => busData.productTypeName(v),
				ifEmpty: '&mdash;'
			}
		}
	}
};

const ModelActions = {
	actions: [
		action('add:type', 'добавить подтип', { resourcesProduction: 'typesEdit' })
	],
	actionAddType () {
		return openAdd(this);
	}
};


const Base = mix(Model).with(EditableProperties, ModelProperties, ModelActions, NestedEntitiesV2mixin, EntityWithLogs2);

export default Base.extend({
	cardUrlRoot: paths.url('res:production:types'),
	urlRootPattern: paths.api('res:production:types'),
	isCommentable: () => false,
	mainInfoCanBeChanged () {
		return busData.user().checkRights({ resourcesProduction: 'typesEdit' });
	},
	getParent () {
		return this.get('parentId', { nested: true });
	},
	getFullName (force) {
		const name = this.display('name');
		const parent = this.getParent();
		if (!parent) {
			return force ? name : undefined;
		}
		force = true;
		const prefix = (parent && parent.getFullName(force)) || '';
		return prefix + ' &rarr; ' + name;
	}
});
