import getLabel from './get-label';
import getName from './get-name';
import getValue from './get-value';
import getProperty from './safe-call';

import wrap from './wrap';
import unwrap from './unwrap';

import setByPath from './set-get-by-path/set-by-path'
import getByPath from './set-get-by-path/get-by-path'

import flattenObject from './flatten-unflatten/flatten-object';
import unFlattenObject from './flatten-unflatten/unflatten-object';

import isView from './is-view';
import camelCase from './camel-case';
import paramStringToObject from './param-string-to-object';
import safeCall from './safe-call';
import notValue from './not-value';

export default {
	getLabel, getName, getValue, 
	getProperty, 
	wrap, unwrap, 
	setByPath, getByPath, 
	flattenObject, unFlattenObject, 
	isView, 
	camelCase, 
	paramStringToObject, 
	safeCall,
	notValue
}
