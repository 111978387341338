import { _ } from 'vendors';
import getModelType from './get-model-type';
import compareTypes from './compare-types';
import checkItem from './check-item';


export default function checkData (data, opts) {
	if (data == null) return;
	if (!_.isObject(data)) return 'data is not an object';
	if (_.size(data) === 0) return;

	let type = opts.type || opts.modelType;
	const firstType = getModelType(data[0]);
	if (!type) type = firstType;

	if (type === 'unknown') { return 'model type can not be detected'; }

	opts.type = type;

	if (!compareTypes(type, firstType)) { return 'data first item is in conflict with provided type'; }
	const rawTypes = {};
	const inconsistent = _(data).some((item) => !checkItem(item, type, rawTypes));
	if (inconsistent) {
		const types = _(rawTypes).map((v, t) => t).join(', ');
		return `data items are in conflict with provided type: ${type} and ${types}`;
	}
}
