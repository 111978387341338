export function downloadCsv (text, filename) {
	if (!filename.endsWith('.csv')) {
		filename = filename + '.csv';
	}

	const element = document.createElement('a');
	element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(text));
	element.setAttribute('download', filename);
	element.style.display = 'none';
	document.body.appendChild(element);

	element.click();

	document.body.removeChild(element);
}
