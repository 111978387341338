import { HamburgerView } from 'base/view-hamburger';
import Collection from 'base/collection';
import CollectionView from 'base/collection-view';
import View from 'base/view';
import { ProductCard } from './ProductCard';
import { _ } from 'vendors';
import { viewsConfig } from './viewsConfig';

const ProductionList = CollectionView.extend({
	className: 'production-list',
	childView: ProductCard,
	viewComparator: v => v.getName(),
	childViewOptions () {
		return {
			departmentModel: this.model
		};
	}

});

const ProductionColumn = View.extend({
	className: 'column',
	template: '<header><%= header %></header><section></section>',
	regions: {
		section: 'section'
	},
	initialize (options) {
		this.mergeOptions(options, ['header', 'viewFilter', 'filterModel']);
	},
	onRender () {
		const view = this.buildListView();
		this.showChildView('section', view, { replaceElement: true });
	},
	buildListView () {

		const options = _.pick(this, 'collection', 'model', 'viewFilter', 'filterModel');
		const view = new ProductionList(options);

		if (this.filterModel && this.viewFilter) {
			view.listenTo(this.filterModel, 'filter', () => view.filter());
		}

		return view;
	},
	templateContext () {
		return {
			header: this.header || ''
		};
	}
});


export const ProductsTableLayout = HamburgerView.extend({
	tagName: 'section',
	className: 'three-columns',
	customViews () {
		const products = this.model.get('products') || [];
		const groupedProducts = _.groupBy(products, prod => prod.deepness);
		// const collection = new Collection(this.model.get('products'));

		return [
			this.buildColumnView('input', groupedProducts),
			this.buildColumnView('intermediate', groupedProducts),
			this.buildColumnView('output', groupedProducts)
		];
	},
	buildColumnView (key, groupedProducts) {

		const options = this.getColumnViewOptions(key, groupedProducts);
		if (!options) { return; }

		const view = new ProductionColumn(options);
		return view;
	},
	getColumnViewOptions (key, groupedProducts) {
		const products = groupedProducts[key];
		if (!products || !products.length) { return; }

		const filterModel = this.getOption('filterModel');

		const options = {
			header: viewsConfig[key]?.header,
			collection: new Collection(products),
			model: this.model,
			filterModel
		};

		if (filterModel) {
			options.viewFilter = (view) => {
				if (filterModel && filterModel.filterModel && !filterModel.filterModel(view.model)) {
					return false;
				}
				return true;
			};
		}
		return options;
	}
});
