
import mix from '../helpers/mix';
import Stateable from '../mixins/stateable';
import YatObject from '../YatObject';
import Bb from 'backbone';
import Mn from 'backbone.marionette';
import _ from 'underscore';
import $ from 'jquery';
import YatError from '../YatError';

const IDENTITY_CHANNEL = 'identity';

let Base = mix(YatObject).with(Stateable);

const Ajax = {
	
	tokenUrl:'',
	nativeAjax: $.ajax,

	ajax(...args){
		return this.ensureToken()
			.then(() => { 
				//console.log('__ ajax arguments:', ...args);
				
				let options;

				if(args && args.length == 1 && _.isObject(args[0])){
					options = args[0];
				}
				if(args && args.length == 2 && !_.isObject(args[0]) && _.isObject(args[1])){
					options = args[1];
				}

				options && (options.headers = _.extend({}, options.headers, this.getAjaxHeaders()));

				return this.nativeAjax.apply($, args);
			})
			.catch((error) =>{ 
				return error;
			});
	},
	tokenXhr(url, data, method = 'POST'){
		return this.nativeAjax({ url, data, method });
	},
	ensureToken(opts = {}){
		let refresh = this.isRefreshNecessary(opts);

		if(!refresh) return Promise.resolve();

		let url = this.getOption('refreshTokenUrl');
		let data = this.getRefreshTokenData();
		return this.requestToken(data, url, _.extend({},opts, {refresh:true}));
	},
	requestToken(data, url, options = {}){
		url || (url = this.getOption('tokenUrl'));
		if(!url) return Promise.reject('token url not specified');
		let promise = new Promise((resolve, reject) => {
			this.tokenXhr(url, data)
				.then(
					(token) => { 
						this.setToken(token, options).then(() => resolve(), () => reject());
					},
					(error) => {
						let tokenError = YatError.Token(error);
						reject(tokenError);
						this.triggerMethod('token:error', tokenError);
						this.reset(options);
					}
				);
		});
		return promise;
	},
	getAjaxHeaders(){
		this._ajaxHeaders || (this._ajaxHeaders = {});
		return _.extend({}, this._ajaxHeaders, this.getOption('ajaxHeaders'));
	},
	replaceBackboneAjax(){
		let token = this.getTokenValue();
		if(!token)
			Bb.ajax = $.ajax;
		else
			Bb.ajax = (...args) => this.ajax(...args);
	},
	updateAjaxHeaders(){
		this._ajaxHeaders || (this._ajaxHeaders = {});
		let token = this.getTokenValue();
		let headers = this._ajaxHeaders;
		if(token){
			headers.Authorization = 'Bearer ' + token;
			headers.Accept = 'application/json';
		}else{
			delete headers.Authorization;
		}
	},
}

const Token = {
	tokenExpireOffset: undefined,
	hasToken(){
		return !!this.getToken();
	},
	getToken(){
		return this.token;
	},
	getTokenValue(){
		let token = this.getToken();
		return token && token.access_token;
	},
	getRefreshTokenData(){
		let token = this.getToken() || {};
		return {
			'grant_type':'refresh_token',
			'refresh_token': token.refresh_token
		};
	},
	getTokenExpires(){
		let token = this.getToken();
		return (token || {}).expires;
	},
	getTokenSeconds(){
		let expires = this.getTokenExpires();
	
		if(expires == null || isNaN(expires.valueOf())) {
			//console.warn('expires is null or wrong');
			return 0;
		}
	
		let offset = this.getProperty('tokenExpireOffset');
		if(offset == null) offset = 30000; //30 sec
	
		var deadline = expires.valueOf() - offset;
		var deadlineMs = deadline - Date.now();
		return deadlineMs > 0 ? deadlineMs / 1000 : 0;
	},	
	isRefreshNecessary(opts){
		
		if(opts.force === true) return true;

		let token = this.getTokenValue();
		if(!token) return false;	
		return !this.getTokenSeconds();
	},

	setToken(token, opts = {}){
		
		token = this.parseToken(token, opts);
		let broadcastToken = !opts.silent || (!!opts.silent && !!opts.loudToken);
		broadcastToken && this.triggerMethod('before:token:change', token, opts);
		
		this.token = token;
		this._onTokenChange(opts);

		broadcastToken && this.triggerMethod('token:change', this.token, opts);
		
		return this.trySyncUser(opts);

	},
	parseToken(token){
		if(token == null) return token;

		if(token != null && _.isObject(token))
			token.expires = new Date(Date.now() + (token.expires_in * 1000));

		return token;
	},
	_onTokenChange(opts){
		this.updateAjaxHeaders();
		this.replaceBackboneAjax();		
	},	
}

const Auth = {
	authenticated: false,	
	isAuth(){
		return this.authenticated === true;
	},
	isAnonym(){ return !this.isAuth();},
	isMe(value){
		return value && this.isAuth() && this.me == value;
	},
	setMe(value){
		this.me = value;
	},
}

const User = {
	trySyncUser(opts = {}){
		let user = this.getUser();
		if(!user || opts.identity === false) {
			opts.source = 'trySyncUser';
			this.triggerChange(opts);
			return Promise.resolve();
		}
		return this.syncUser(opts);
	},
	syncUser(opts = {}){
		let user = this.getUser();
		if(!user) return Promise.resolve();

		return user.fetch().then(() => { 
			this.applyUser(user, opts);
		}, (err) => {
			this.syncUserError(err, opts);
		});
	},
	syncUserError(err, opts){
		this.reset(opts);
	},
	applyUser(user, opts = {}){
		let id = user == null ? null 
				: user.getIdentityId ? user.getIdentityId()
				: user.id;
				
		this.setMe(id);
		let auth = _.isFunction(user.isAuthenticated) && user.isAuthenticated();
		this.authenticated = auth;
		opts.source = 'applyUser';
		this.triggerChange(opts);
	},
	getUser(){
		return this.user;
	},
	setUser(user, opts){
		this.user = user;
		this.applyUser(user, opts);
	},
	isUser(){
		return this.isAuth() && this.user && !!this.user.id;
	},
}

const Identity = mix(YatObject).with(Auth, Ajax, Token, User).extend({
	initialize(){
		this.ajaxHeaders = {};		
	},
	triggerChange(opts = {}){

		if(_.isFunction(opts.beforeChange))
			opts.beforeChange();

		!opts.silent && this.triggerMethod('change');
	},
	reset(opts = {}){		
		let resetOptions = _.extend({}, opts, {identity: false, silent:true});
		let user = this.getUser();
		user && user.clear();
		this.setToken(null, resetOptions);
		this.applyUser(user, resetOptions);		
		this.triggerMethod('reset');
		opts.source = 'reset';
		this.triggerChange(opts);
	},
	init(opts = {}){
		let user = opts.user;
		let token = opts.token;
		delete opts.user;
		delete opts.token;
		this.setUser(user, opts);
		this.setToken(token, opts);
	}
});

let identity = new Identity();
export default identity;
