import { _ } from 'vendors';
import BaseObject from 'base/object';

export default BaseObject.extend({
	initialize (opts) {
		this.mergeOptions(opts, [
			'collection', 'multiple',
			'getItemKey', 'getItemValue', 'getItemIndex', 'getItemByIndex', 'children', 'childrenFilter',
			'initialValue', 'valueType'
		]);
		this.store = {};
	},
	_getChildren () {
		return this.children();
	},
	_getChildrenViews () {
		const views = this._getChildren()._views;
		return views;
	},
	selectInitialValues (silent = false) {
		let values = this.initialValue;
		if (values == null) return;

		if (!_.isArray(values)) { values = [values]; }

		const children = this._getChildrenViews();

		_.each(children, (f) => {
			const val = this.getItemKey(f);
			if (values.indexOf(val) > -1) { this.select(f, silent); }
		});
	},
	setCollection (col) {
		this.collection = col;
	},

	getItemKey (item) {
		return item.model.id;
	},
	getItemValue (item) {
		return item.model;
	},
	getItemIndex (item) {
		const children = this._getChildren();
		return children.findIndexByView(item);
	},


	getItemByIndex (index) {
		const children = this._getChildren();
		return children.findByIndex(index);
	},
	getItemsRange (leftIndex, rightIndex, ignore) {
		const result = [];
		const get = this.getItemByIndex;

		for (let x = leftIndex; x <= rightIndex; x++) {
			if (x === ignore) continue;
			const item = get.call(this, x);
			if (item != null) {
				if ('isAttached' in item) {
					if (!(item.isAttached() && item.isRendered())) { continue; }
				}
				result.push(item);
			}
		}
		return result;
	},
	set (key, item, silent) {
		this.store[key] = item;
		if (!silent) { this.trigger('select', key, item); }
		return { [key]: item };
	},
	unset (key, item, silent) {
		delete this.store[key];
		if (!silent) { this.trigger('unselect', key, item); }
		return { [key]: item };
	},
	unsetAll () {
		const keyPairs = _.extend({}, this.store);
		this.store = {};
		const changes = {
			unselected: keyPairs,
			selected: {}
		};
		this.triggerChange(changes);
	},
	has (key) {
		return key in this.store;
	},


	select (item, silent) {
		this.set(this.getItemKey(item), item, silent);
	},
	unselect (item) {
		this.unset(this.getItemKey(item), item);
	},
	toggle (item, silent) {
		const result = {
			selected: {},
			unselected: {}
		};

		const key = this.getItemKey(item);
		if (this.has(key)) { _.extend(result.unselected, this.unset(key, item, silent)); } else { _.extend(result.selected, this.set(key, item, silent)); }

		return result;
	},

	clicked (item, opts = {}) {
		if (this.multiple) {
			if (opts.event && opts.event.shiftKey && this.tryToggleRange(item)) {
				delete this.lastItemIndex;
				return;
			}

			this.lastItemIndex = this.getItemIndex(item);
			this.toggle(item);
		} else {
			this.unsetAll();
			this.select(item);
		}
	},

	isSelected (item) {
		const key = this.getItemKey(item);
		return this.has(key);
	},

	tryToggleRange (item) {
		if (this.lastItemIndex == null) return;
		const i1 = this.lastItemIndex;
		const ignoreIndex = i1;
		const i2 = this.getItemIndex(item);

		const left = _([i1, i2]).min();
		const right = _([i1, i2]).max();

		const range = this.getItemsRange(left, right, ignoreIndex);
		const result = {
			selected: {},
			unselected: {}
		};
		_(range).each((item) => {
			const t = this.toggle(item, true);
			_.extend(result.selected, t.selected);
			_.extend(result.unselected, t.unselected);
		});
		this.triggerChange(result);
		return true;
	},
	triggerChange (changes) {
		this.trigger('changes', changes);
	},
	getValue () {
		const values = _(this.store).map((m) => m.model);
		if (this.multiple) { return values; } else { return values[0]; }
	}
});
