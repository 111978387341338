import { _ } from 'vendors';
import '../api-config';
import BaseModel from 'base/model';
import Model from 'base/card-model';
import paths from 'helpers/paths';
import EditableProperties from 'mixins/editable-properties';
import mix from 'helpers/mix';
import enums from 'helpers/enums';
import busData from 'bus/data';

// import smartDate from 'helpers/date/smart';
import { dateTransform } from 'helpers/date/smart';
import action from 'helpers/action';
import modalError from 'helpers/modals/error';

import editValue from 'bus/edit';
// import CollectionView from 'base/collection-view';
import View from 'base/view';
// import NestedContragent from 'mod/contragents/mixins/entity-contragent';
// import NestedEntities from 'mixins/model/nested-entities';


import { CheckListCollection } from 'mod/tasks/models/checklist';
import EntityWithEmployees from 'mod/employees/mixins/entity-with-employees';
import { ObserversCollection } from './observers';
import Input from 'components/controls/input';

import busModels from 'bus/models';
import NestedEntitiesV2mixin from 'mixins/model/nested-entities-v2';
import EntityWithLogs2 from 'mods/logs/entity-with-logs-2-mixin';
import EntityWithChecklist from '../mixins/entity-with-checklist';

// import BbStore from 'base/bb-store';
// import { BackendLogsCollection } from 'mods/logs/models/backend-logs-collection';
// import provideComment from 'helpers/provide-comment';

import modalsConfirm, { confirmReason } from 'helpers/modals/confirm';

import { toNewPoints, fetchContactValues } from './utils';
import EditTransportRequest from '../views/transportRequest/editTransportPoints';
import PrintView from 'base/print-view';


const departmentName = v => {
	const dep = busData.departments(v);
	if (dep) {
		// console.log(' ?????? --- ==== ', dep)
		return dep.display('name');
	}
};

const employeeName = function (id, nobody, me) {
	if (id == null) { return nobody; }

	if (busData.user().isMe(id)) { return me; }

	const emp = busData.employees(id);
	if (emp == null) { return nobody; } else { return emp.display('name'); }
};

/*
const dateTransform = (v, opts={}) => {
	let di = Date.info(v);
	if(!di.valid) return '';
	if(opts.format === 'fromNow')
		return moment(di.date).fromNow();
	return smartDate.main(di.date, opts.smartDateOptions);
};
*/

const transportRequestPointLabel = v => {
	if (!v) return;
	if (!v.contragent) return v.address;
	let text = v.contragent.name;
	if (v.contragent.contactName && (v.contragent.name.toLowerCase().trim() !== v.contragent.contactName.toLowerCase().trim())) {
		text += ' ' + v.contragent.contactName;
	}
	if (text) {
		text += '<br>';
	}
	text += v.address;
	return text;
};


const deadlineStrictnes = {
	false: 'обычный',
	true: 'жёсткий'
};

const PropertiesMixin = {
	properties: {
		type: {
			type: 'enum',
			sourceValues: () => enums.store.employeeTaskTypes,
			editOptions: {
				applyOnChange: true
			},
			display: {
				label: 'тип задачи',
				ifEmpty: 'дело',
				transform: (v) => enums.get('employeeTaskTypes', v)
			}
		},
		toDo: {
			display: {
				label: 'что надо сделать?',
				ifEmpty: 'не установлено'
			}
		},
		description: {
			type: 'bigtext',
			display: {
				label: 'описание задачи',
				ifEmpty: 'не установлено'
			}
		},
		state: {
			display: {
				label: 'состояние',
				transform: (v, o, m) => {
					if (v !== 'closed') { return enums.get('employeeTaskStates', v); }

					switch (m.get('result')) {
					case 'succeeded': return 'завершена';
					case 'failed': return 'провалена';
					case 'canceled': return 'отменена';
					default: return enums.get('employeeTaskStates', v);
					}
				}
			}
		},
		'dates.deadline': {
			type: 'datetime',
			controlOptions () {
				return {
					defaultTime: '12:00',
					// startTime: Date.create({minutes:2}),
					predefinedValues: {
						'через 15 минут': Date.create({ minutes: 15 }),
						'через час': Date.create({ hours: 1 }),
						завтра: Date.create({ days: 1 }),
						послезавтра: Date.create({ days: 2 })
					},
					revertPredefinedValues: true
				};
			},
			display: {
				label: 'крайний срок',
				transform: (v, o) => dateTransform(v, _.extend(o, { smartDateOptions: { time: true } })), // (v) => smartDate.main(v,{time:true})
				alternative: (v) => dateTransform(v, { format: 'fromNow', smartDateOptions: { time: true } })
			}
		},
		'dates.remindAt': {
			type: 'datetime',
			controlOptions () {
				return {
					defaultTime: '12:00',
					// startTime: Date.create({minutes:2}),
					predefinedValues: {
						'через 15 минут': Date.create({ minutes: 15 }),
						'через час': Date.create({ hours: 1 }),
						завтра: Date.create({ days: 1 }),
						послезавтра: Date.create({ days: 2 })
					},
					revertPredefinedValues: true
				};
			},
			display: {
				label: 'когда надо напомнить?',
				transform: (v, o) => dateTransform(v, _.extend(o, { smartDateOptions: { time: true } })), // (v) => smartDate.main(v,{time:true})
				alternative: (v) => dateTransform(v, { format: 'fromNow', smartDateOptions: { time: true } })
			}
		},
		'dates.strictDeadline': {
			type: 'boolean',
			sourceValues: deadlineStrictnes,
			display: {
				label: 'тип крайнего срока',
				transform: (v) => deadlineStrictnes[v || false]
			}
		},
		modified: {
			display: {
				label: 'последнее изменение',
				transform: dateTransform,
				alternative: (v) => dateTransform(v, { format: 'fromNow' })
			}
		},
		created: {
			display: {
				label: 'когда поставлена',
				transform: dateTransform,
				alternative: (v) => dateTransform(v, { format: 'fromNow' })
			}
		},
		'dates.onCheck': {
			display: {
				label: 'когда отправлена на проверку',
				transform: dateTransform
			}
		},
		'dates.started': {
			display: {
				label: 'взята в работу',
				transform: dateTransform,
				alternative: (v) => dateTransform(v, { format: 'fromNow' })
			}
		},
		'dates.closed': {
			display: {
				transform: dateTransform,
				alternative: (v) => dateTransform(v, { format: 'fromNow' })
			}
		},
		stateDate: {
			display: {
				label () {
					switch (this.get('result')) {
					case 'succeeded': return 'завершена';
					case 'failed': return 'провалена';
					case 'canceled': return 'отменена';
					default: return 'в работе с';
					}
				},
				alternative () {
					if (this.isClosed()) {
						return this.display('dates.closed', { alternative: true });
					} else {
						return this.display('dates.started', { alternative: true }) || this.display('created', { alternative: true });
					}
				},
				transform () {
					if (this.isClosed()) {
						return this.display('dates.closed');
					} else {
						return this.display('dates.started') || this.display('created');
					}
				}
			}
		},
		responsibleId: {
			notNull: true,
			nested: (eId) => busData.employees(eId),
			sourceValues: () => busData.employees(),
			controlType: 'employeesSelect',
			display: {
				label: 'ответственный',
				ifEmpty: 'моя задача',
				transform: (v) => employeeName(v, 'никто', 'моя задача')// m.whoResponsible()
			}
		},
		departmentId: {
			notNull: true,
			// nested: (eId) => busData.employees(eId),
			sourceValues: () => busData.departments(),
			controlType: 'departmentsSelect',
			display: {
				label: 'отдел',
				ifEmpty: '&mdash;',
				transform: (v) => departmentName(v)// m.whoResponsible()
			}
		},
		checkerId: {
			nested: (eId) => busData.employees(eId),
			sourceValues: () => busData.employees(),
			controlType: 'employeesSelect',
			display: {
				label: 'проверяющий',
				ifEmpty: 'не требует проверки',
				transform: (v) => employeeName(v, 'не требует проверки', 'я проверяю')
			}
		},
		creatorId: {
			notNull: true,
			nested: (eId) => busData.employees(eId),
			display: {
				label: 'поставлена',
				transform: (v) => employeeName(v, 'неизвестно кем', 'мной')
			}
		},
		'lastComment.authorId': {
			display: {
				transform: (v) => employeeName(v, '', 'я')
			}
		},
		'typeDetails.fromAddress': {
			display: {
				label: 'откуда',
				transform: transportRequestPointLabel
			}
		},
		'typeDetails.toAddress': {
			display: {
				label: 'куда',
				transform: transportRequestPointLabel
			}
		},
		'typeDetails.cargo': {
			display: {
				label: 'груз'
			}
		},
		'typeDetails.contactName': {
			display: {
				label: 'контактное лицо'
			}
		},
		contragentId: {
			controlType: 'select-api',
			sourceValues: () => busModels.request('new:contragents:search', { fetch: true }),
			edit: {
				beforeChange (mod) {
					const json = mod == null ? null : mod.toJSON();
					this.set('contragent', json, { silent: true });
					return (mod && mod.id) || null;
				}
			},
			editOptions: {
				addClass: 'long-content'
			},
			controlOptions: {
				multiple: false,
				fetchAtStart: true,
				apiSearch: true,
				shouldReturnModel: true
			},
			display: {
				label: 'контрагент',
				ifEmpty: 'отсутствует',
				transform () {
					return this.get('contragent.name');
				}
			}
		},
		processId: {
			controlType: 'select-api',
			sourceValues: () => busModels.request('new:processes:search', { fetch: true }),
			edit: {
				beforeChange (mod) {
					const json = mod == null ? null : mod.toJSON();
					this.set('process', json, { silent: true });
					return (mod && mod.id) || null;
				}
			},
			editOptions: {
				addClass: 'long-content'
			},
			controlOptions: {
				multiple: false,
				fetchAtStart: true,
				apiSearch: true,
				shouldReturnModel: true
			},
			display: {
				label: 'процесс',
				ifEmpty: 'отсутствует',
				transform () {
					return this.get('process.name');
				}
			}
		},
		contragent: {
			display: {
				transform () {
					const c = this.getContragent();
					return c && c.display('name');
				}
			}
		},
		process: {
			display: {
				transform () {
					return this.get('process.name');
				}
			}
		},
		'resource.type': {
			display: {
				label: 'ресурс',
				transform () {
					return this.get('resource.name');
				}
			}
		},
		aproximateDuration: {
			type: 'enum',
			sourceValues: () => enums.store.aproximateDurations,
			editOptions: {
				applyOnChange: true
			},
			display: {
				label: 'предположительная длительность',
				ifEmpty: 'не указано',
				transform: (v) => v && v !== 'none' ? enums.get('aproximateDurations', v) : undefined
			}
		},
		urgentImportantPriority: {
			type: 'enum',
			sourceValues: () => enums.store.urgentImportantPrioritets,
			editOptions: {
				applyOnChange: true
			},
			display: {
				label: 'приоритет',
				ifEmpty: 'не указано',
				transform: (v) => v ? enums.get('urgentImportantPrioritets', v) : undefined
			}
		},
		numericPriority: {
			type: 'number',
			display: {
				label: 'числовой приоритет',
				ifEmpty: 'не указано',
			}
		}
	}
};

const IsChangeAvailableMixin = {
	isActable () {
		return this.isMy() || this.amIChecker() || this.amICreator() || this.amIAdmin();
	},
	isEditable (notImportant) {
		return (this.amICreator() || this.amIChecker() || (notImportant && this.isMy()) || this.amIAdmin()) && this.isNotClosed();
	},
	isDeadlineCanBeChanged () {
		return this.isEditable();
	},
	isToDoCanBeChanged () {
		return this.isEditable(true);
	},
	isTypeCanBeChanged () {
		return this.isEditable(true);
	},
	isStrictCanBeChanged () {
		return this.isEditable(true);
	},
	isCancelable () {
		return this.isNotClosed() && (this.amICreator() || (this.isMy() && this.isWithoutChecker()) || this.amIAdmin());
	},
	isCompletable () {
		return (this.isMy() && this.isInProcess()) ||
				(this.isOnCheck() && this.amIChecker());
	},
	isFailable () {
		return this.isOnCheck() && this.amIChecker();
	},
	isCommentable () {
		return this.isNotClosed() && this.isActable();
	}
};

const ConditionMixin = {
	isTransportRequest () {
		return this.get('form') === 'transportRequest';
	},
	isContragent () {
		return !this.get('process') && !!this.get('contragent');
	},
	isProcess () {
		return !!this.get('process');
	},

	isInPast () {
		const di = this.getDateInfo('remindAt');
		return di.valid && di.when === 'past' && this.isNotClosed();
	},
	isToday () {
		const di = this.getDateInfo('remindAt');
		return di.valid && di.calendar.days === 0;
	},
	isNear () {
		const di = this.getDateInfo('remindAt');
		return !this.isInPast() && !this.isToday() && di.valid && di.calendar.days < 4;
	},
	isDeadlineNear () {
		const di = this.getDateInfo('deadline');
		return di.valid && di.calendar.days < 4;
	},
	isInitial () {
		return this.get('state') === 'initial';
	},
	isOnHold () {
		return this.get('state') === 'onHold';
	},
	isInProcess () {
		return this.get('state') === 'inProcess';
	},
	isNotClosed () {
		return this.get('state') !== 'closed';
	},
	isClosed () {
		return !this.isNotClosed();
	},
	isClosing () {
		return this.get('state') === 'checking';
	},
	isOnCheck () {
		return this.get('state') === 'onChecking';
	},
	isStrict () {
		return this.get('dates.strictDeadline') === true;
	},
	isMy () {
		return busData.user().isMe(this.get('responsibleId'));
	},
	hasNoResponsible () {
		return this.get('responsibleId') == null;
	},
	amIAdmin () {
		return busData.user().isAdmin();
	},
	amIChecker () {
		return busData.user().isMe(this.get('checkerId'));
	},
	amICreator () {
		return busData.user().isMe(this.get('creatorId'));
	},
	amIWatcher () {
		const myId = busData.user().getIdentityId();
		return !!this.getEmployee(myId);
	},
	canIWatch () {
		if (this.isProcess()) {
			return false; // :-(
		} else {
			return false; // TODO: should be true if employeer
		}
	},
	isWithoutChecker () {
		return !this.get('checkerId');
	},
	isVisible (notActive) {
		const actor = this.hasNoResponsible() || this.isMy() || this.amIChecker() || this.amICreator();
		// this.isClosed() || (this.isMy() && !this.amIChecker() && this.isOnCheck());
		if (notActive) {
			return this.isClosed() && actor;
		} else {
			return this.isNotClosed() && actor;
			/*
			return (this.isMy() && !this.amIChecker() && this.isNotClosed() && !this.isOnCheck())
				||
				(this.amIChecker() && this.isOnCheck())
				||
				(!this.isMy() && this.amICreator() && this.isNotClosed());
				*/
		}
	}
};

const ActionsMixin = {
	actions: [
		action('print:transport:request', 'проверить детали заявки', null, {
			order: -101,
			rule: m => m.isTransportRequest()
		}),
		action('edit:transport:request', 'редактировать детали', null, {
			order: -101,
			rule: m => m.isTransportRequest() && m.isActable()
		}),
		action('upload:files', 'прикрепить файлы', null, {
			order: -100,
			// rule: (m) => m.isActable(),
			rule: m => !m.hasNoResponsible(),
			places: 'page'
		}),
		action('accept', 'взять в работу', null, {
			order: -100,
			rule: (m) => (m.isMy() && m.isInitial()) || m.hasNoResponsible(),
			places: 'page, plate'
		}),
		action('return:to:work', 'вернуть в работу', null, {
			order: -99,
			rule: (m) => m.isActable() && (m.isOnHold() || m.isClosed() || m.isOnCheck()),
			places: 'page, plate'
		}),
		// action('edit:deadline','изменить крайний срок', null, {
		// 	order: -98,
		// 	rule: (m) => m.isDeadlineCanBeChanged(),
		// 	places: 'page, plate',
		// }),
		action('edit:remindAt', 'изменить дату напоминания', null, {
			order: -98,
			// rule: (m) => m.isDeadlineCanBeChanged(),
			places: 'page, plate'
		}),
		action('strict:on', 'сделать крайний срок жестким', null, {
			order: -97,
			rule: (m) => m.isStrictCanBeChanged() && !m.isStrict(),
			places: 'page, plate'
		}),
		action('strict:off', 'снять жёсткость c крайнего срока', null, {
			order: -97,
			rule: (m) => m.isStrictCanBeChanged() && m.isStrict(),
			places: 'page, plate'
		}),
		action('put:on:hold', 'временно отложить', null, {
			order: -91,
			rule: (m) => m.isMy() && m.isInProcess(),
			places: 'page, plate'
		}),
		action('complete', m => {
			if (m.isOnCheck()) {
				return 'подтвердить завершение';
			} else {
				return 'завершить';
			}
		}, null, {
			order: -90,
			rule: m => {
				if (m.isPurchaseRequest()) {
					return false;
					// m.purchaseRequestHasNoFreeProducts() && m.purchaseRequestHasAnyProcess() && m.isCompletable();
				} else {
					return m.isCompletable();
				}
			},
			places: 'page, plate'
		}),

		action('cancel', 'отменить', null, {
			order: -89,
			rule: (m) => m.isCancelable(),
			places: 'page, plate'
		}),

		action('check:fail', 'вернуть в работу', null, {
			order: -89,
			rule: (m) => m.isFailable(),
			places: 'page, plate'
		}),

		action('watch:task', 'наблюдать за задачей', null, {
			places: 'page, plate',
			rule: m => !m.amIWatcher() && m.canIWatch()
		}),
		action('stop:watch:task', 'прекратить наблюдать', null, {
			places: 'page, plate',
			rule: m => m.amIWatcher()
		}),
		action('add:employees', 'добавить наблюдателей', null, {
			places: 'page, plate',
			rule: m => m.isActable()
		}),

		action('add:check:item', 'добавить пункт', null, {
			order: -89,
			rule: (m) => (m.isNotClosed() && m.isEditable(true)) || m.isNew(),
			places: 'page'
		}),

		action('change:process:junction', m => !m.getProcess() ? 'привязать к процессу' : 'открепить от процесса', null, {
			rule: m => m.isNew() || m.isActable(),
			places: 'page'
			// hidden: true,
		}),
		action('change:contragent:junction', m => !m.getContragent() ? 'привязать к контрагенту' : 'открепить от контрагента', null, {
			rule: m => m.isNew() || m.isActable(),
			// rule: m => !m.getContragent(),
			places: 'page'
			// hidden: true,
		})

		// action('edit','редактировать', null, {
		// 	rule: (m) => m.isEditable()
		// }),
	],


	actionEditTransportRequest () {
		// let details = this.get('typeDetails');
		const taskModel = this;
		const content = new View({
			className: 'edit-transport-details in-modal',
			template: _.template('<div></div>'),
			regions: {
				content: '> div'
			},
			onRender () {
				const view = this.edit = new EditTransportRequest({
					taskModel,
					toggleModalButtons: true
				});
				this.showChildView('content', view);
			},
			childViewTriggers: {
				'switch:resolve': 'switch:resolve'
			}
		});

		const options = {
			header: 'редактирование деталей заявки на транспорт',
			content,
			resolve () {
				return content.edit.model.toJSON();
			}
		};
		editValue.doNext(options).then((typeDetails) => {
			taskModel.save({ typeDetails }, { attrs: { typeDetails }, method: 'patch', wait: true });
		});
	},

	_tryPatch (hash, errorMessage) {
		this.save(hash, { wait: true, patch: true, flat: true }).then(
			() => {},
			(xhr) => modalError(errorMessage, xhr)
		);
	},
	actionUploadFiles () {
		this.trigger('open:selectFilesToUpload');
	},
	async actionPrintTransportRequest () {
		const dets = this.get('typeDetails');
		const points = toNewPoints(dets);
		const initiator = this.get('creatorId', { nested: true }).display('name');
		if (!points) {
			console.log('NOT AVAILABLE', dets);
			return;
		}

		const stylesTemplate = `
		<style>
			table {
				width: 100%;
			}
			td {
				padding: 15px;
				border: 1px solid #000;
			}
			td:first-child {
				width: 150px;
				text-align: center;
			}
		</style>
		`;

		const pointTemplate = _.template(`
		<table>
			<tr><td>ИНИЦИАТОР ЗАЯВКИ</td><td><%= initiator %></td></tr>
			<tr><td>КОНТРАГЕНТ</td><td><%= contragent %></td></tr>
			<tr><td>КОНТ. ЛИЦО</td><td><%= contact %></td></tr>
			<tr><td>АДРЕС</td><td><%= address %></td></tr>
			<tr><td>ЗАБИРАЕМ</td><td><%= takeCargo %></td></tr>
			<tr><td>ОТДАЁМ</td><td><%= dropCargo %></td></tr>
			<tr><td>КОГДА</td><td><%= workHours %></td></tr>
			<tr><td>СЛЕДУЕТ ПРЕДУПРЕДИТЬ</td><td><%= notifyFirst %></td></tr>
			<tr><td>ДЕТАЛИ</td><td><%= notes %></td></tr>
		</table>
		<br><br><br>
		`);

		const g = (c, ...keys) => {
			const key = keys.shift();
			if (keys.length) {
				return c && g(c[key], keys);
			} else {
				return c && c[key];
			}
		};
		const gs = (c, s) => {
			return g(c, ...s.split('.'));
		};

		// let pointsHtml = await (async () => {

		// 	return html;
		// });

		let html = ''; // stylesTemplate;
		for (let x = 0; x < points.length; x++) {
			const point = points[x];
			const model = {
				initiator,
				contragent: gs(point, 'contragent.name'),
				address: gs(point, 'address.address'),
				takeCargo: point.takeCargo,
				dropCargo: point.dropCargo,
				workHours: point.workHours,
				notifyFirst: point.notifyFirst
			};
			let values = await fetchContactValues(point);
			if (!values) {
				values = [];
			}
			model.contact = [gs(point, 'contact.value'), ...values.filter(m => /phone/i.test(m.type)).map(m => {
				if (/phone/i.test(m.type)) {
					return [_.displayPhone(m.value), m.addValue].join(' ');
				} else {
					return m.value;
				}
			})].filter(f => f).join(', ');
			model.notes = point.comment; // [point.workHours, point.comment].join('<br/>');
			html += pointTemplate(model);
		}


		// let html = stylesTemplate + pointsHtml;
		// points.map(async point => {

		// 	let model = {
		// 		contragent: gs(point, 'contragent.name'),
		// 		address: gs(point, 'address.address'),
		// 		takeCargo: point.takeCargo,
		// 		dropCargo: point.dropCargo
		// 	};
		// 	let values = await fetchContactValues(point);
		// 	model.contact = gs(point, 'contact.name') + (['', ...values.map(m => m.value)].join(', '));
		// 	model.notes = [point.workHours, point.comment].join('<br/>');
		// 	return pointTemplate(model);
		// }).join('');

		/* BUGGED version
		let w = window.open();
		w.document.write(html);
		w.print();
		w.close();
		*/


		const view = new PrintView({ addClass: 'full-screen', styles: stylesTemplate, content: html });
		view.render();
		view.$el.appendTo(document.body);
	},

	actionChangeProcessJunction () {
		const entity = this;
		if (this.getProcess()) {
			modalsConfirm('подтвердите открепление от процесса').then(() => {
				entity.save(null, { attrs: { processId: null }, wait: true, method: 'patch' });
			});
		} else {
			const options = {
				header: 'выберите процесс',
				controlType: 'select-api',
				editOptions: {
					addClass: 'long-content'
				},
				controlOptions: {
					multiple: false,
					fetchAtStart: true,
					apiSearch: true,
					shouldReturnModel: true,
					collection: busModels.request('new:processes:search', { fetch: true })
				}
			};
			editValue.do(options).then((model) => {
				entity.save(null, { attrs: { processId: model.id }, wait: true, method: 'patch' });
			}, () => {});
		}
	},
	actionChangeContragentJunction () {
		const entity = this;
		if (this.getContragent()) {
			modalsConfirm('подтвердите открепление от контрагента').then(() => {
				entity.save(null, { attrs: { contragentId: null }, wait: true, method: 'patch' });
			});
		} else {
			const options = {
				header: 'выберите контрагента',
				controlType: 'select-api',
				editOptions: {
					addClass: 'long-content'
				},
				controlOptions: {
					multiple: false,
					fetchAtStart: true,
					apiSearch: true,
					shouldReturnModel: true,
					collection: busModels.request('new:contragents:search', { fetch: true })
				}
			};
			editValue.do(options).then((model) => {
				entity.save(null, { attrs: { contragentId: model.id }, wait: true, method: 'patch' });
			}, () => {});
		}
	},
	actionAccept () {
		if (this.isMy()) {
			this._setNewState('inProcess', 'Не удалось взять задачу в работу');
		} else if (this.hasNoResponsible()) {
			this._tryPatch({
				responsibleId: busData.user().getIdentityId(),
				state: 'inProcess'
			}, 'Не удалось изменить жесткость крайнего срока');
		}
	},
	actionReturnToWork () { this._setNewState('inProcess', 'Не удалось вернуть задачу в работу'); },
	actionPutOnHold () { this._setNewState('onHold', 'Не удалось отложить задачу'); },
	_setNewState (state, label) { this._tryPatch({ state }, label); },

	actionStrictOn () { this._setNewStrict(true); },
	actionStrictOff () { this._setNewStrict(false); },
	_setNewStrict (strictDeadline) {
		this._tryPatch({ dates: { strictDeadline } }, 'Не удалось изменить жесткость крайнего срока');
	},

	actionComplete () {
		const oncheck = this.isOnCheck();
		const myconfirm = oncheck ? modalsConfirm : confirmReason;
		const question = oncheck ? 'Подтверждаете завершение задачи?' : 'Для завершения задачи укажите причину или оставьте комментарий в поле ниже';
		myconfirm(question).then(reason => {
			this._setClose('complete', 'завершить', reason);
		}).catch(() => {});
	},
	actionCancel () { this._setClose('cancel', 'отменить'); },
	actionCheckFail () { this._setClose('fail', 'вернуть в работу'); },
	_setClose (url, label, message) {
		let data;
		if (message) {
			data = { message };
		}
		console.log('url', url);
		this.post({ url, data }).then(
			(data) => this.set(data),
			(xhr) => modalError(`Не удалось ${label} задачу`, xhr)
		);
	},

	actionEditDeadline () {
		this.editProperty('dates.deadline', {
			reject: (m, xhr) => modalError('Не удалось изменить крайний срок', xhr)
		});
	},
	actionEditRemindAt () {
		this.editProperty('dates.remindAt', {
			reject: (m, xhr) => modalError('Не удалось изменить дату напоминания', xhr)
		});
	},

	actionAddCheckItem () {
		// let model = this;
		const col = this.getCheckList();
		editValue.do({
			header: 'добавление пункта',
			applyLabel: 'добавить',
			resetButton: false,
			cancelLabel: 'отставить',
			editOptions: {
				controlView: Input
			}
		}).then(text => {
			text && col.add({ text, index: col.length });
		});
	}

};

const PurchaseRequestMixin = {
	purchaseRequestHasNoFreeProducts () {
		const typeDet = this.attributes.typeDetails;
		const items = typeDet && typeDet.items;
		const res = items && items.every(i => i.tempProcessId);
		// console.log('NOFREE ITEMS', res, items);
		return res;
	},
	purchaseRequestHasAnyProcess () {
		const typeDet = this.attributes.typeDetails;
		const processes = typeDet && typeDet.processes;
		return processes && processes.length;
	}
};

const Base = mix(Model).with(
	PropertiesMixin, EditableProperties, /* NestedEntities, NestedCollections, */
	ConditionMixin, ActionsMixin, IsChangeAvailableMixin
	, NestedEntitiesV2mixin, EntityWithLogs2, EntityWithChecklist, EntityWithEmployees
	, PurchaseRequestMixin
);

const Task = Base.extend({
	constructor () {
		Base.apply(this, arguments);
		this.initParentId();
	},
	initParentId () {
		if (!this.parent || !this.parent.id) return;
		const prop = this.parent.taskEntity ? this.parent.taskEntity : this.parent.entity;
		if (_.isFunction(prop)) return;
		const key = prop + 'Id';
		this.set(key, this.parent.id);
	},
	cardUrlRoot: paths.url('tasks'),
	urlRoot: paths.api('tasks'),
	socketEntity: 'employeeTask',
	nestedEntities: {
		contragent: () => busModels.getModel('Contragent'),
		process: () => busModels.getModel('CommonProcess'),
		checkList: CheckListCollection,
		observers: { class: ObserversCollection, parse: true }
		// contragent:() => ({
		// 	Model: busModels.getModel('contragent'),
		// }),
		// process: () => ({
		// 	Model: busModels.getModel('process'),
		// })
	},
	employeeIdAttribute: 'employeeId',
	employeesAttribute: 'observers',
	nestedEmployeeMap (employeeId) {
		return {
			id: employeeId,
			employeeId
		};
	},
	employeesActionsConfig: {
		actions () {
			// action('watch:task','наблюдать за задачей', null, { places: 'page' })
			return [action('remove:employee', 'исключить из наблюдателей', null, { hidden: true })];
		}
	},
	employeesCanBeRemoved (employees) {
		if (this.isActable()) return true;

		if (employees) {
			if (_.isArray(employees)) {
				const allCanBeRemoved = _.every(employees, emp => {
					return emp.isMe && emp.isMe();
				});
				if (allCanBeRemoved) {
					return true;
				}
			}
		}
		return false;
	},
	employeesCanBeAdded () {
		return this.isActable();
	},
	getProcess () {
		if (this.get('process') == null) return;
		return this.entity('process');
	},
	getContragent () {
		if (this.get('contragent') == null) return;
		return this.entity('contragent');
	},

	getCheckList () {
		return this.entity('checkList');
	},
	addEmployeesToCollection (data) {
		const emps = this.getEmployees();
		emps.add(data, { parse: true });
	},
	getEmployee (employeeId) {
		if (employeeId == null) {
			return;
		}
		const emps = this.getEmployees();
		if (_.isObject(employeeId) && employeeId instanceof BaseModel) {
			return emps.get(employeeId);
		}
		return emps.findWhere({ employeeId });
	},
	getEmployees () {
		return this.entity('observers');
	},
	getTaskForm () {
		return this.get('form');
	},
	isPurchaseRequest () {
		return this.getTaskForm() === 'purchaseRequest';
	},
	isProductTask () {
		return this.get('resource.type') === 'product';
	},
	hasResource () {
		return !!this.get('resource.id');
	},
	openResourcePage (e) {
		if (!this.hasResource()) return;
		const type = this.get('resource.type');
		switch (type) {
		case 'product':
			return busModels.request('open:product:page', this.get('resource.id'), e);
		default:
		}
	},
	/* nestedCollections:{
		// logs:() => ({
		// 	Collection: busModels.getModel('logs'),
		// }),
		checkList: {
			Collection: CheckListCollection
		}
	}, */
	hasValidDate () {
		const date = this.getDisplayDate();
		return !!date;
	},
	getDisplayDate () {
		return this.isClosed() ? this.getClosedDate() : this.getRemindAt();
	},
	getClosedDate () {
		const di = Date.info(this.get('dates.closed'));
		return di.valid && di.date;
	},
	getDateInfo (key) {
		return Date.info(this.get('dates.' + key));
	},
	getDate (key) {
		const di = this.getDateInfo(key);
		return di.date;
	},
	getDeadlineInfo () {
		return Date.info(this.get('dates.deadline'));
	},
	getRemindAt () {
		return this.getDate('remindAt');
	},
	getDeadline () {
		const di = this.getDeadlineInfo();
		return di.valid && di.date;
	},
	whoResponsible () {
		const emp = this.get('responsibleId', { nested: true });
		if (!emp) { return 'никто'; } else if (this.isMy()) { return 'моя задача'; } else { return emp.display('name'); }
	},
	whoChecker () {
		const emp = this.get('checkerId', { nested: true });
		if (!emp) { return 'не требует проверки'; } else if (this.amIChecker()) { return 'я'; } else { return emp.display('name'); }
	},
	whoCreator () {
		const emp = this.get('creatorId', { nested: true });
		if (!emp) { return 'неизвестно'; } else if (this.amICreator()) { return 'мной'; } else if (_.isModel(emp)) { return emp.display('name'); } else { return emp; }
	},

	empName (path, ifUndef, ifMe) {
		!ifUndef && (ifUndef = '&ndash;');
		!ifMe && (ifMe = 'я');
		return employeeName(this.get(path), ifUndef, ifMe);
	}
});

busModels.reply('EmployeeTask', () => Task);

export default Task;

/*
const StoredTask = BbStore(Task,'EmployeeTask');

export default StoredTask;
*/
