import { _ } from 'vendors';
import View from 'base/view';
import CollectionView from 'base/collection-view';
import UiItemView from 'components/ui-list/item';
import editValue from 'bus/edit';
import bus from 'bus/models';
import Collection from 'base/collection';
import smartOpen from 'helpers/smart-open';
import Object from 'base/object';
import busData from 'bus/data';
import user from 'app/user';
const localBus = new Object();

const CycleProcess = UiItemView.extend({
	addClass: 'whole-btn as-button',
	text: m => m.get('name'),
	cssClassModifiers: [
		m => user.isMe(m.get('ownerId')) ? 'my-process' : 'notmy-process'
	],
	bottomText: m => busData.employeeName(m.get('ownerId')),
	onTextClick (e1, e2, event) {
		localBus.trigger('close');
		smartOpen('/procs/' + this.model.id, event);
	}
});

const EmptyView = UiItemView.extend({
	text: 'нет активных процессов'
});

export const CycleProcessesView = CollectionView.extend({
	childView: CycleProcess,
	emptyView: EmptyView,
	initialize () {
		if (!this.collection) {
			this.collection = new Collection(this.getOption('items') || []);
		}
		this.listenTo(localBus, 'add', newone => this.collection.add(newone));
	}
});



export const ShowOrCreateProcessesView = View.extend({
	template: `
		<div class="processes margin-v"></div>
		<div class="add-new margin-v">
			<button class="create">создать процесс</button>
		</div>
	`,
	initialize (opts) {
		this.mergeOptions(opts, ['apiModel']);
		this.listenTo(localBus, 'close', () => this.destroy());
	},
	regions: {
		processes: '.processes'
	},
	onRender () {
		this.showProcesses();
	},
	showProcesses () {
		const view = new CycleProcessesView({ items: this.getOption('items') });
		this.showChildView('processes', view);
	},
	events: {
		'click .create' (e) {
			const options = {
				header: 'Выберите контрагента',
				controlOptions: {
					controlType: 'select-api',
					modelType: 'single',
					multiple: false,
					fetchAtStart: true,
					apiSearch: true,
					collection: bus.request('new:contragents:search')
				},
				display: {
					label: 'контрагенты',
					transform (v) {
						return v ? bus.request('new:contragents:model', { id: v }).display('name') : undefined;
					},
					ifEmpty: '&mdash;'
				}
			};
			editValue.do(options).then(contragentId => {
				this.createProcess(contragentId);
			}, _.noop);
		}
	},
	createProcess (contragentId) {
		if (contragentId == null) return;

		this.apiModel.save(null, { attrs: { contragentId }, wait: true, method: 'POST' }).then((newone, ...args) => {
			localBus.trigger('add', newone);
			// console.log('# returned', ...args);
			// console.log('this json', this.apiModel.toJSON());
			// console.log('this', this.apiModel);
		});
	}
});
