import CardsLayout from 'components/cards/cards-layout';
import CardView from './card-view';
import smartOpen from 'helpers/smart-open';

const View = CardsLayout.extend({
	childView: CardView,
	onChildviewClick (view, event) {
		if (!view || !view.model) return;
		const url = view.model.get('url');
		return smartOpen(url, event);
	}
});

export default View;
