import { _ } from 'vendors';
const valuePrototypes = {
	single: {
		modelType: 'single',
		multiple: false
	},
	range: {
		modelType: 'range',
		multiple: false
	},
	daterange: {
		modelType: 'range',
		multiple: false,
		valueType: 'date'
	},
	text: {
		modelType: 'single',
		multiple: false,
		valueType: 'text'
	},
	number: {
		modelType: 'single',
		multiple: false,
		valueType: 'number'
	},
	enum (opts = {}) {
		const sourceValues = _.result(opts, 'sourceValues');

		const hash = {
			modelType: 'single',
			multiple: opts.multiple === true,
			valueType: 'enum',
			sourceValues
		};

		return hash;
	}
};
export default valuePrototypes;
