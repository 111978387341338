import { _ } from 'vendors';
import CollectionView from 'base/collection-view';
import ActionView from './action-view';
import Collection from 'base/collection';
import getEventFields from 'helpers/get-event-fields';
import { MnCollectionView, DumbView } from 'base/views';

const withHeaderMixin = {
	template: _.template('<header><%= header %></header><div></div>'),
	childViewContainer: '> div',
	templateContext() {
		console.warn('-- header template --');
		return {
			header: this.header
		}
	}
}


const ActionsLayout = MnCollectionView.extend({
	className: 'actions with-border',
	destroyAfterClick: true,
	initialize (options = {}) {
		const header = this.getOption('header', true);
		if(header) {
			console.warn('--acts lay--', header);
			
			this.on('render', () => {
				const headerView = new DumbView({ html: header, tagName: 'header' });
				this.addChildView(headerView, { at: 0 });
			});
			// Object.assign(this, withHeaderMixin);
		}
		this._initActions(options);
	},
	_initActions (opts) {
		console.log('[ init actions ]');
		if (this.collection) return;
		console.log('[ init raw ]');
		if (opts.raw) {
			this.collection = new Collection(opts.raw);
		} else {
			console.log('[ init triggerOn ]', this.model);
			const triggerOn = this.getTriggerOn();
			if (!triggerOn || !_.isFunction(triggerOn.getActions)) return;
			console.log('[ add to coll ]');
			this.collection = new Collection(triggerOn.getActions(opts));
		}
	},
	getTriggerOn () {
		return this.model || this.getOption('triggerOn');
	},
	_getActionOptions (actionModel, event) {
		const eventHash = getEventFields(event);

		return _.extend({ inModal: this.inModal, actionModel }, eventHash);
	},
	childViewEvents: {
		'text:click' (action, view, event) {
			const triggerOn = this.getTriggerOn();
			const actionOptions = this._getActionOptions(action, event);
			!!triggerOn && _.isFunction(triggerOn.executeAction) && triggerOn.executeAction(action.toJSON(), actionOptions);

			// let destroyAfterClick = this.getOption('destroyAfterClick');
			// if(destroyAfterClick){
			// 	this.destroy();
			// }
		}
	},
	childViewOptions () {
		return {
			triggerOn: this.model || this.getOption('triggerOn')
		};
	},
	viewComparator (v1, v2) {
		const m1 = v1.model;
		const m2 = v2.model;
		if (m1 == null) { return -1; } else if (m2 == null) { return 1; }
		return _.comparator(v1, v2, (m) => m.get('order') || 0);
	},
	childView: ActionView,

});

export default ActionsLayout;
