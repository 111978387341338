import { _ } from 'vendors';
import testValue from 'helpers/validate/test-value';
import View from 'base/view';
import enums from 'helpers/enums';
import template from './layout.html';

// import ContactValue from 'components/contacts/models/value-model';
import Contact from 'components/contacts/models/contact-value-model';

import InputPlaceholdered from 'components/controls/input-placeholdered';
import Phone from './phone';
// import MobilePhone from './phone';
import Email from './email';
import Website from './website';
import Social from './social';

import Values from 'components/values';

import CvType from 'components/controls/inline-select-trigger';
import Checks from 'components/controls/checks';
import ControlsMixin from 'components/controls/mixin';

import mix from 'helpers/mix';

import AddressInput from './address';

const MobilePhone = Phone;

export {
	AddressInput
};

export default mix(View).with(ControlsMixin).extend({
	className: 'c-contact-value',
	template,
	cssClassModifiers: [
		(m, v) => v.getType()
	],
	regions: {
		value: { el: '.c-cv-control', replaceElement: false },
		add: { el: '.c-cv-add', replaceElement: false },
		description: { el: '.c-cv-description', replaceElement: false },
		primary: { el: '.c-cv-primary', replaceElement: false },
		type: { el: '.c-cv-type', replaceElement: false }
	},
	initialize () {
		this.initModel();
	},
	initModel (opts = {}) {
		if (!this.model || opts.force) {
			const value = this.getOption('value');
			if (value && value instanceof Contact) {
				this.model = value;
			} else {
				this.model = new Contact(value);
			}
		}
		this.contactValue = this.model.getValueModel();

		this.initialValue = this.model.toJSON();

		const type = this.getType();

		if (!this.contactValue.get('type')) {
			this.contactValue.set('type', type);
		}
		// if(!this.contactValue.get('value')){
		// 	this.contactValue.set('value',this.getOption('value'));
		// }
	},

	getType () {
		return (this.contactValue && this.contactValue.get('type')) || this.getOption('type') || 'phone';
	},

	getAddOptions () {
		const type = this.getType();
		if (type !== 'phone') return;

		return {
			proxyTo: this,
			controlName: 'add',
			type: 'number',
			maxLength: 6,
			value: this.model.get('addValue'),
			label: 'добавочный'
		};
	},
	onRender () {
		this.showType();
		this.showControl();
		this.showAdd();
		this.showDescription();
		this.showPrimary();
	},
	showControl () {
		const type = this.getType();

		const View = type === 'phone'
			? Phone
			: type === 'mobilePhone'
				? MobilePhone
				: type === 'email'
					? Email
					: type === 'site'
						? Website
						: type === 'social'
							? Social
							: type === 'address'
								? AddressInput
								: InputPlaceholdered;

		const view = new View({
			proxyTo: this,
			controlName: 'value',
			value: this.contactValue.get('value'),
			valueNote: this.contactValue.get('note'),
			type: this.getType(),
			noValue: type === 'address'
		});

		if (!this._addressListening) {
			this._addressListening = true;
		}
		this.listenToOnce(view, 'address', addr => {
			this.setContactValueProperty('note', JSON.stringify(_.pick(addr, 'lat', 'lng')), true);
			this.setContactValueProperty('value', addr.address, true);
			this.setContactValueProperty('flatValue', addr.address);
			// console.log('~~~', this, addr.address);
			this.showControl();
			// this.triggerChange();
			// this.render();
		});

		this.showChildView('value', view);
	},
	showAdd () {
		const addOpts = this.getAddOptions();
		if (this.isRendered()) {
			const region = this.getRegion('add');
			if (addOpts) { this._showAdd(addOpts); } else { region.empty(); }
		} else {
			if (!addOpts) return;
			this._showAdd(addOpts);
		}
	},
	_showAdd (opts) {
		const view = new InputPlaceholdered(opts);
		this.showChildView('add', view);
	},
	showDescription () {
		const view = new InputPlaceholdered({
			proxyTo: this,
			controlName: 'note',
			type: 'text',
			maxLength: 512,
			label: 'дополнительно',
			value: this.model.get('note')
		});
		this.showChildView('description', view);
	},
	showPrimary () {
		const view = new Checks({
			className: 'buttons-group show-selected-icon h41px',
			proxyTo: this,
			controlName: 'primary',
			type: 'checkbox',
			value: this.model.get('primary'),
			valueType: 'boolean',
			sourceValues: {
				true: 'основной'
			}
		});
		this.showChildView('primary', view);
	},
	showType () {
		const properties = {
			'value.display.label': 'тип',
			'value.display.transform': (v) => enums.get('contactValues', v)
		};
		const model = new Values.Single({
			value: this.getType()
		}, {
			properties
		});

		const view = new CvType({
			proxyTo: this,
			controlName: 'contactValueType',
			header: 'Выберите тип',
			className: 'as-button',
			valueType: 'enum',
			resetValue: 'phone',
			model,
			noConfirm: true,
			sourceValues: enums.store.contactValues
		});
		this.showChildView('type', view);
	},

	onTypeChange () {
		this.showAdd();
		this.showControl();
		this.refreshCssClass();
	},

	controlsEvents: {
		'contactValueType:change' (value) {
			// console.log('CHANGE --', value);
			this.setContactValueProperty('type', value);
			this.setContactProperty('addValue', undefined);
			this.triggerMethod('type:change');
		},
		'value:change' (value) {
			this.setContactValueProperty('value', value);
		},
		'value:validate:error' () {
			this.setContactValueProperty('value');
		},
		'primary:change' (value) {
			this.setContactProperty('primary', _.toBoolean(value));
		},
		'note:change' (value) {
			this.setContactProperty('note', value);
		},
		'add:change' (value) {
			this.setContactProperty('addValue', value);
		}
	},


	getContactValue () {
		if (this.contactValue) return this.contactValue;
		this.contactValue = this.model.getContactValue();
		return this.contactValue;
	},
	getContact () {
		return this.model;
	},
	setContactProperty (key, value, silent) {
		const model = this.getContact();
		model.set(key, value);
		!silent && this.triggerChange(this.getValue());
	},
	setContactValueProperty (key, value, silent) {
		const model = this.getContactValue();
		model.set(key, value);
		!silent && this.triggerChange(this.getValue());
	},
	getValue () {
		const contact = this.getContact().toJSON();
		const value = this.getContactValue().toJSON();
		contact.value = value;

		return contact;
	},
	validate (value, opts = {}) {
		opts.errors || (opts.errors = []);

		if (
			testValue(!!value.value.value, 'emptyContactValue', opts.errors)
		) { return true; } else { return false; }
	}
});
