import { _, $ } from 'vendors';
import View from 'base/view';
// import CollectionView from 'base/collection-view';
// import Collection from 'base/collection';
// import Model from 'base/model';

export const BlockTextHeader = View.extend({
	constructor (options) {
		if (typeof options === 'string') {
			options = { template: options };
		} else if (typeof options !== 'object') {
			options = undefined;
		}
		View.apply(this, [options]);
	},
	tagName: 'header',
	className: 'just-text bg-main-dark'
});

export const BlockFooterTextAction = View.extend({
	tagName: 'footer',
	className: 'ui2-line-action just-text',
	template: '<%= text %>',
	templateContext () {
		return {
			text: this.getOption('text')
		};
	},
	events: {
		'click' (event) {
			const click = this.getOption('clickAction');
			if (typeof click === 'string') {
				this.triggerMethod(click, this, event);
			}
		}
	}
	// triggers(){
	// 	return click && {
	// 		click
	// 	} || undefined;
	// }
});


export const JustInputView = View.extend({
	className: 'ui2-inputLine',
	template: '<input type="search" placeholder="<%= placeholder %>" />',
	initialize () {
		this.delegate('input input', _.debounce(this._input.bind(this), 400));
	},
	ui: { inp: 'input' },
	_input () {
		const val = this.ui.inp.val();
		this.triggerMethod('change', val);
	},
	templateContext () {
		return {
			placeholder: this.getOption('placeholder') || '...'
		};
	}
});



const attributeArrayValueProcessors = {
	default: arr => arr.filter(f => !!f).join(', '),
	class: arr => {
		const res = arr.filter(f => !!f).join(' ');
		return res;
	}
};

function buildInlineAttrs (attrs) {
	if (!attrs) return '';
	const pairs = _.reduce(attrs, (memo, value, key) => {
		if (Array.isArray(value)) {
			const proc = attributeArrayValueProcessors[key] || attributeArrayValueProcessors.default;
			value = proc(value);
			if (value === '') {
				value = null;
			}
		}
		if (value == null) return memo;
		const pair = `${key}="${value}"`;
		memo.push(pair);
		return memo;
	}, []);
	let res = pairs.join(' ');
	if (res) {
		res = ' ' + res;
	}
	return res;
}

function buildTag (tag, content, attrs) {
	const inlineAttrs = buildInlineAttrs(attrs);
	return `<${tag}${inlineAttrs}>${content}</${tag}>`;
}

export const BlockHeaderWithRightButtons = View.extend({
	tagName: 'header',
	className: 'right-buttons bg-main-dark',
	template: '<span><%=text%></span><%= buttonsHtml %>',
	initialize () {
		this.initializeButtons();
	},
	initializeButtons () {
		this.delegate('click [data-button]:not(:disabled):(.disabled)', this._clickHandler.bind(this));
	},
	_clickHandler (event) {
		const btn = $(event.target).closest('button');
		const id = btn.data('button');
		this.triggerMethod('click:' + id);
	},
	_buttonHtml (button) {
		const attrs = {
			class: [button.id, button.class],
			title: button.title,
			'data-button': button.id
		};
		const contents = [`<span>${button.text}</span>`];
		if (button.icon === true) {
			contents.unshift('<i></i>');
		}
		return buildTag('button', contents.join(''), attrs);
	},
	_buttonsHtml (buttons) {
		buttons = buttons || this.getOption('buttons') || [];
		return buttons.map(this._buttonHtml).join('');
	},
	templateContext () {
		return {
			text: this.getOption('text'),
			buttonsHtml: this._buttonsHtml()
		};
	}
});
