import Model from 'base/model';
export const TabTriggerModel = Model.extend({
	makeActive () {
		this.collection.each(m => {
			if (m.isActive()) { m.makeInactive(); }
		});
		this.set('active', true);
	},
	makeInactive () {
		this.set('active', false);
	},
	isActive () {
		return this.get('active') === true;
	}
});
