module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="label">\n	Установленная дата:\n</div>\n';
 if(isSingle) { 
__p+='<div class="value"><span class="empty">&mdash;</span></div>';
 } 
__p+='\n';
 if(isRange) { 
__p+='\n<div class="from"><span class="empty">&mdash;</span></div>\n<div class="to"><span class="empty">&mdash;</span></div>\n';
 } 
__p+='\n';
}
return __p;
};
