import BaseView from 'base/view';
import Entries from './entries';


export default BaseView.extend({
	id: 'measures-search',
	className: 'measures-types fdc-container',
	isInvulnerable: true,
	template: BaseView.emptyTemplate,
	regions: {
		content: '.content-region'
	},
	initialize () {
		this.createNested('content', {
			viewClass: Entries,
			regionTemplate: { replaceElement: true }
		});
	},
	onRender () {
		if (this._wasRendered) return;
		this.showContent();
		this._wasRendered = true;
	}

});
