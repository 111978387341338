import Collection from 'base/collection';
import PagedCollectionMixin from 'mixins/paged-collection';
import mix from 'helpers/mix';

import '../api-config';
import paths from 'helpers/paths';
import Process from './process';
import busModels from 'bus/models';

const PagedCollection = mix(Collection).with(PagedCollectionMixin);

const ProcessesPagedCollection = PagedCollection.extend({
	_classType: 'ProcessesPagedCollection',
	constructor (data, opts = {}) {
		this.state = opts.state || 'active';
		Collection.apply(this, arguments);
	},
	model: Process,
	url: paths.api('processes'),
	getState () {
		return this.state;
	},
	fetch (opts = {}) {
		const state = this.getState();
		if (!opts.url) {
			opts.url = this.url + '/' + state;
		}
		console.log(' -- FETCH PROCS --', opts);
		return Collection.prototype.fetch.call(this, opts);
	}
});


busModels.reply('processes:paged', () => {
	return ProcessesPagedCollection;
});

busModels.reply('new:processes:search', (opts = {}) => {
	const col = new Collection([], { model: Process });
	col.url = paths.api('processes') + '/search';
	if (opts.fetch) {
		col.fetch(opts.fetchOptions);
	}
	return col;
});


export default ProcessesPagedCollection;

