import { _ } from 'vendors';
import originalGet from 'base/model/original-get';
// import originalSet from 'base/model/original-set';
// import flat from 'helpers/flat';
import Yat from 'marionette.yat';
const __ = Yat.Functions.common;
export default (Base) => {
	const NewBase = Base.extend({
		ownGetByPath: true,

		_rawGet (key) {
			if (!key) return;
			if (key.indexOf('.') > -1) {
				try {
					return __.getByPath(this, key);
				} catch (exc) {
					console.error('_rawGet: getByPath', key, 'exception', this);
					throw exc;
				}
			} else {
				try {
					if (this.attributes) {
						return originalGet.call(this, key);
					}
				} catch (exc) {
					console.error('_rawGet: originalGet', key, 'exception', this);
					throw exc;
				}
			}
		},
		getPropertyConfig (key) {
			return (this.properties || {})[key] || {};
		},
		getPropertyNested (key) {
			const prop = this.getPropertyConfig(key);
			return _.isFunction(prop.nested) && prop.nested;
		},
		get (key, opts = {}) {
			if (key == null) return;

			let value;
			if (!opts.skipGet) {
				value = this._rawGet(key);
			}

			if ('value' in opts) { value = opts.value; }

			if (!_.size(opts)) return value;
			const getNested = this.getPropertyNested(key);

			if (opts.nested && getNested && value != null) {
				const result = getNested(value, this);
				if (result) return result;
			}

			if (_.isFunction(opts.transform) && !opts.raw) { value = opts.transform.call(this, value, opts, this); }

			const prop = this.getPropertyConfig(key);

			if (_.isFunction(prop.transform) && !opts.raw) {
				value = prop.transform.call(this, value, opts, this);
			}

			if (opts.display === true) {
				const display = this.getPropertyDisplay(key);
				if (opts.alternative) {
					value = _.isFunction(display.alternative) && display.alternative.call(this, value, _.extend({}, opts, prop), this);
				} else if (_.isFunction(display.transform)) {
					value = display.transform.call(this, value, opts, this);
				}
				if (display.ifEmpty && _.notValue(value)) { return display.ifEmpty; }
			}

			return value;
		},
		display (key, opts = {}) {
			_.extend(opts, { display: true });
			return this.get(key, opts);
		},
		hasDisplayOptions (key) {
			return !!_.size(this.getPropertyDisplay(key));
		},

		getPropertyDisplay (key) {
			if (_.notValue(key)) return {};
			return this.getPropertyConfig(key).display || {};
		},

		getPropertyLabel (key) {
			const display = this.getPropertyDisplay(key);
			if (_.isString(display.label)) { return display.label; } else if (_.isFunction(display.label)) { return display.label.call(this, this.get(key), this); } else { return key; }
		},

		getPropertyRights (key) {
			return ((this.properties || {})[key] || {}).rights || {};
		}



	});

	return NewBase;
};
