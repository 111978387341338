import Radio from './radio';
const appBus = Radio.channel('app');
appBus.navigate = function (...args) {
	appBus.request('navigate', ...args);
};
appBus.goUp = function () {
	appBus.request('go:up');
};
appBus.currentPage = function () {
	return appBus.request('current:page');
};
export default appBus;
