import { moment } from 'vendors';
import View from 'base/view';
import busData from 'bus/data';

export default View.extend({
	className: 'transaction-item ui2-molecule',
	template: `<div class="date"><%= moment(created).format("DD.MM.YY hh:mm:ss") %></div>
    <div class="prod"><%= prod %></div>
    <div class="move"><%= move %></div>
    <div class="amount"><%= amount %></div>
    <div class="actor"><%= actor %></div>`,

	templateContext () {
		let destName = this.model.get('destinationContragent.name');
		destName = destName ? '<big>' + destName + '</big>' : '<small>склад</small>';

		let srcName = this.model.get('sourceContragent.name');
		srcName = srcName ? '<big>' + srcName + '</big>' : '<small>склад</small>';

		const move = srcName + ' &rarr; ' + destName;

		return {
			prod: this.model.get('product.name') + ' &mdash; <span>' + this.model.get('batchIdString') + '</span>',
			move,
			actor: busData.employeeName(this.model.get('actorId')),
			moment
		};
	}
});
