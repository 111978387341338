import CollectionView from 'base/collection-view';
import childView from './department-card';
import comparator from 'helpers/comparator';

export default CollectionView.extend({
	className: 'pages-cards',
	childView,
	viewComparator (v1, v2) {
		return comparator(
			[v1, v2, (m, v) => v.isMy() ? -1 : 0],
			[v1, v2, (m, v) => v.model.get('name')]
		);
	}
});
