import paths from 'helpers/paths';

function rootUrl (employeeId) {
	return paths.urls.api(`employees/${employeeId}/`, { version: 'v3' });
}

export function buildApiUrl (employeeId, add) {
	const base = rootUrl(employeeId);
	if (!add) { return base; }

	let url = base + '/' + add;
	url = url.replace(/([\w\d_-])\/+/gmi, '$1/');
	return url;
}
