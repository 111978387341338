import CollectionView from 'base/collection-view';
import Collection from 'base/collection';
import { TabTriggerView } from './tab-trigger';
import { TabTriggerModel } from './tab-trigger-model';
import { _ } from 'vendors';

export const TabTriggersView = CollectionView.extend({
	cssClassModifiers: [
		'common-tab-triggers'
	],
	initialize () {
		if (!this.collection) {
			const tabs = this.getOption('tabs');
			const tabsModels = _.map(tabs, (label, id) => ({ label, id }));
			this.collection = new Collection(tabsModels, { model: TabTriggerModel });
		}
	},
	childView: TabTriggerView,
	onRender () {
		if (!this.collection.some(m => m.isActive())) {
			const active = this.getOption('activeTab');
			const model = this.collection.get(active);
			model && model.makeActive();
		}
	},
	getCurrentTab () {
		const model = this.collection.findWhere({ active: true });
		return model;
	},
	collectionEvents: {
		'change:active' (model) {
			if (!model.isActive()) return;
			this.triggerMethod('tab', model);
			const triggerOn = this.getOption('triggerOn');
			const method = triggerOn.triggerMethod || triggerOn.trigger;
			if (!method) return;
			method.call(triggerOn, 'tab', model);
		}
	}
});
