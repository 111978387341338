import proto from 'components/values/get-prototype';
import enums from 'helpers/enums';
import { getBuyGoals } from '../../buy/goal-codes';

// const activeArchive = {
// 	true: 'действующие процессы',
// 	false: 'действующих контрагентов'
// };

const processTypes = { common: 'Простой процесс', buy: 'Процесс закупки', productProduction: 'Процесс производства' };

const filtersPrototypes = [
	{
		id: 'cmnproc:text',
		valueId: 'text',
		label: 'поиск по ключевому слову',
		hidden: true,
		valuePrototype: proto('text'),
		textSearch: true
	},
	{
		id: 'cmnproc:noatasks',
		valueId: 'noatasks',
		label: 'наличие задач',
		pinned: true,
		// values: [{value: 'client'}],
		valueLabel: (val) => enums.get({ false: 'не важно', true: 'процессы без задач' }, val),
		valuePrototype: () => proto('enum', {
			multiple: false,
			sourceValues: () => ({ false: 'не важно', true: 'процессы без задач' })
		})
	},
	{
		id: 'cmnproc:type',
		valueId: 'proctype',
		label: 'тип процесса',
		pinned: true,
		valueLabel: (val) => enums.get(processTypes, val),
		valuePrototype: () => proto('enum', {
			multiple: false,
			sourceValues: processTypes
		})
	},
	{
		id: 'cmnproc:goalcompleted',
		valueId: 'goalcompleted',
		label: 'достигнута цель',
		pinned: true,
		// valueLabel: (val) => enums.get({ common: 'Простой процесс', buy: 'Процесс закупки'},val),
		valuePrototype: proto('text'),
		dependsOn: {
			'cmnproc:type': 'common'
		}
	},
	{
		id: 'cmnproc:goalcompletedcode',
		valueId: 'goalcompletedcode',
		label: 'достигнута цель',
		pinned: true,
		valueLabel: (val) => enums.get(getBuyGoals(), val),
		valuePrototype: proto('enum', {
			multiple: true,
			sourceValues: () => getBuyGoals()
		}),
		dependsOn: {
			'cmnproc:type': 'buy'
		}
	},
	{
		id: 'cmnproc:goalcompleteddate',
		valueId: 'goalcompletedbetween',
		label: 'период достижения цели',
		dependsOn: {
			any: true,
			values: {
				'cmnproc:goalcompleted': true,
				'cmnproc:goalcompletedcode': true
			}
		},
		pinned: true,
		valuePrototype: proto('daterange')
	}
	// {
	// 	id:'cmnproc:roles',
	// 	valueId:'roles',
	// 	label: 'роль',
	// 	pinned: true,
	// 	//values: [{value: 'client'}],
	// 	valueLabel: (val) => enums.get('contragentRoles',val),
	// 	valuePrototype: () => proto('enum',{
	// 		multiple:true,
	// 		sourceValues:() => enums.store.contragentRoles,
	// 	}),
	// },
	// {
	// 	id:'cmnproc:archive',
	// 	valueId:'archive',
	// 	label: 'показывать только',
	// 	pinned: true,
	// 	values: [{value: false}],
	// 	valueLabel: (val) => activeArchive[val],
	// 	valuePrototype: {
	// 		controlType: 'select',
	// 		modelType: 'single',
	// 		valueType: 'boolean',
	// 		multiple: false,
	// 		sourceValues: activeArchive,
	// 	},
	// },

];

export default filtersPrototypes;
