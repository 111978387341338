import Model from 'base/model';
import { _ } from 'vendors';

function chunkContainText (value, text, startIndex = 0) {
	return value.indexOf(text, startIndex);
}

function valueContainText (value, text) {
	value = value.toString().toLowerCase();
	if (typeof text === 'string') {
		return chunkContainText(value, text) > -1;
	} else if (Array.isArray(text)) {

		if (text.length === 0) { return false; }

		let lastIndex = 0;
		for (const chunk of text) {
			const chunkIndex = chunkContainText(value, chunk, lastIndex);
			if (value.startsWith('материал')) {
				// console.log(text, chunk, chunkIndex, value);
			}

			if (chunkIndex < lastIndex) {
				return false;
			}
			lastIndex = chunkIndex + chunk.length;
		}
		return true;
	}
	return false;
}
function arrayContainsText (arr, text) {
	return arr.some(element => {
		if (element == null || element === '') { return false; }
		return element.toString().toLowerCase().indexOf(text) > -1;
	});
}
function objectContainsText (obj, text) {
	return _.some(obj, (value, key) => {
		if (value == null || value === '') {
			return false;
		}
		const type = typeof value;
		if (Array.isArray(value)) {
			return arrayContainsText(value, text);
		} else if (type === 'object') {
			return objectContainsText(value, text);
		} else {
			return valueContainText(value, text);
		}
	});
}


export const FilterModel = Model.extend({
	contains: ['name'],
	filterView (view) {
		const model = view.model;
		return this.filterModel(model);
	},
	filterModel (model) {
		const containsOk = this.contains.every(key => {

			let value = this.get(key);
			if (value == null || value === '') {
				return true;
			}
			value = value.toString().toLowerCase().trim();
			if (value.indexOf(' ') > -1) {
				value = value.split(' ').filter(f => !!f);
			}
			const attrs = _.pick(model.attributes, 'fullName', 'shortName');
			return objectContainsText(attrs, value);
		});
		if (!containsOk) {
			return false;
		}
		return true;
	}
});
