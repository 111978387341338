import Base from 'components/ui-list/item';
import ActionsPopover from 'behaviors/actions-popover';


export default Base.extend({
	behaviors: [ActionsPopover],
	cssClassModifiers: [
		'measure'
	],
	text: m => m.display('one'),
	// smallText: m => m.getFullName(),
	textAction: 'open:card',
	optionsButton: true
	// infoButton:true
});
