import { GetInfoMixin, _meas } from './common';
import UiListItem from 'components/ui-list/item';
import { _ } from 'vendors';
import { bus } from './bus';

export const ComponentPartyView = UiListItem.extend({
	...GetInfoMixin,
	renderOnModelChange: false,
	modelEvents: {},
	initialize () {
		this.listenTo(this.model.collection, 'recalced', this.render);
		this.takeInputValue = _.debounce(this._takeInputValue.bind(this), 500);
	},
	addClass: 'cycle-component-party',
	topText: 'партия №',
	getText () {
		console.log('>>>>', this.model.attributes);
		const G = k => this.model.get(k);
		// const amount = G('baseAmount');
		const id = G('idString');
		let concreteName = G('concreteName') || '';
		if (concreteName) {
			concreteName = `<span class="concrete">${concreteName}</span>`;
		}
		return `${concreteName}<span class="party-identifier">${id}</span>`;
        // `партия № ${id} (${_.displayNum(amount,4)})`;
	},
	rightItems () {
		const amount = _.displayNum(this.model.get('baseAmount') || 0, 4);
		const unit = _meas(this.getOption('component').get('baseUnitMeasureId'));
		return [
			{
				template: `<div><i>объем</i><span>${amount} ${unit}</span></div>`
			},
			{
				template: `<div><input value="${this.model.get('postAmount') || ''}"/></div>`
			}
		];
	},
	rightButtons: [
		{ class: 'remove', icon: true, 'data-trigger': 'remove:party' }
	],
	ui: { inp: 'input' },
	onRemovePartyClick () {
		bus.notconfirmed();
		this.model.destroy();
	},
	addEvents: {
		'input input' () {
			this.takeInputValue();
		}
	},
	_takeInputValue () {
		const value = this.ui.inp.val();
		this.model.setPostAmount(value);
		bus.notconfirmed();
	}
    // getComponent() {
    //     return this.getOption('component');
    // },

});

export const EmptyComponentPartyView = UiListItem.extend({
	addClass: 'no-party',
	text: '- добавьте партию -'
});
