import View from 'pages/view';
import template from './template.html';

import ModelProperties from 'components/ui-model-card/properties';
import h from 'components/ui-model-card/helpers';

import nestedEmployees from 'mod/employees/views/entity-nested-employees';
import nestedRights from 'mod/rights/views/entity-nested-rights';
import UiCardBlock from 'components/ui-card-block/block';
import Departments from './departments';
import user from 'app/user';

export default View.extend({
	className: 'department-card',
	template,
	renderOnModelChange: false,
	initialize () {
		const m = this.model;
		this.createNested('system', {
			viewClass: ModelProperties.extend({ className: 'card lined with-border' }),
			viewOptions: {
				header: { text: 'детали', addClass: 'bg-main-dark' },
				properties: [
					h.ui('name', { editButton: m.infoCanBeChanged() })
				]
			},
			regionTemplate: { replaceElement: true }
		});

		this.createNested('children', {
			viewClass: UiCardBlock,
			viewOptions: {
				header: 'Подотделы',
				headerOptions: {
					className: 'bg-main-dark'
				},
				action: 'add:department',
				content: Departments,
				contentOptions () {
					return {
						model: m
					};
				}
			},
			regionTemplate: { replaceElement: true }
		});

		this.createNested('employees', nestedEmployees(m, { type: 'departments', withRoles: true }, { addChildViewOptions: { employeeId: v => v.model.get('eId') } }));

		if (user.checkRights({ admin: 'manageRights' })) { this.createNested('rights', nestedRights(m)); }
	},
	onRender () {
		this.showAllNested();
	}

});
