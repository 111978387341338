import { CollectionView } from 'base/views';

export const TreeCollection = CollectionView.extend({
	tagName: 'section',
	// className: 'nesteds',
	// collection,
	// childView: TreeItem,
	childViewOptions () {
		return {
			allModels: this.getOption('allModels'),
			direction: this.getOption('direction'),
			departmentModel: this.getOption('departmentModel'),
			parentTreeItem: this.getOption('parentTreeItem'),
			filterModel: this.getOption('filterModel')
		};
	},

	viewFilter (child) {
		return child.isFilterPassed();
	},

	onFilter () {
		this.children._views.forEach(view => {
			view.filter();
		});
	}

});
