import Contacts from '../models/collection';
export default (Base) => Base.extend({
	constructor () {
		Base.apply(this, arguments);
		const model = this;
		this.registerNestedEntity('contacts', { class: Contacts, options: () => ({ parent: model }) });
	},
	getContacts () {
		return this.entity('contacts');
	},
	getFirstContact () {
		const contacts = this.getContacts();
		return contacts.first();
	}
});
