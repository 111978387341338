import { _ } from 'vendors';
import Model from 'base/model';
import View from 'base/view';
import UiCard from 'components/ui-card/item';
import UiList from 'components/ui-list/item';
import CollectionView from 'base/collection-view';
import Collection from 'base/collection';
import { buildViewByKey } from 'helpers/buildViewByKey';
import { ProcButtonView } from './ButtonView';
// import busEdit from 'bus/edit';
// import busModels from 'bus/models';
// import { buildProductsContext, selectProducts, selectContragent, addProducts } from './utils';
import { addProducts } from './utils';
import enums from 'helpers/enums';
import busData from 'bus/data';
import { commonPriceInfos } from 'mod/productionProducts/models/product-contragent';
import ProcessModel from 'mod/commonProcesses/models/process';
import ProcessItemView from 'mod/commonProcesses/views/list-item-view';
// import paths from 'helpers/paths';
import modals from 'helpers/modals';
import { toLocaleStringTransform } from 'utils';

const ProcProduct = Model.extend({
	properties: {
		'product.details.price': {
			modelType: 'single',
			multiple: false,
			valueType: 'number',
			display: {
				label: 'стоимость за 1 единицу',
				ifEmpty: '&mdash;',
				transform: toLocaleStringTransform
			}
		},
		'product.details.priceInfo': {
			multiple: true,
			valueType: 'enum',
			sourceValues: () => commonPriceInfos,
			controlType: 'select',
			display: {
				label: 'уточнения по цене',
				transform: (v) => enums.get(commonPriceInfos, v)
			}
		},
		'product.unitMeasureId': {
			sourceValues: () => busData.measures(),
			controlType: 'select',
			display: {
				label: 'единица измерения количества',
				transform: (v) => busData.measures(v, 'one')
			}
		},
		'product.details.unitMeasureId': {
			sourceValues: () => busData.measures(),
			controlType: 'select',
			display: {
				label: 'единица измерения закупки',
				transform: (v) => busData.measures(v, 'one')
			}
		},
		'product.details.purchaseUnitToStoreUnit': {
			modelType: 'single',
			multiple: false,
			valueType: 'number',
			display: {
				label: 'сколько складских единиц в одной закупочной единице',
				ifEmpty: '&mdash;',
				transform: toLocaleStringTransform
			}
		}
	}
});

const ProcProd = View.extend({
	cssClassModifiers: ['product', 'ui-list-item'],
	template: _.template(`
		<div class="ui-list-item">
			<span><%= _v.getName() %></span>
		</div>
		<div class="ui-list-item">
			<b><%= _v.getUnits() %></b>
			<span><%=_v.getInfo()%></span>
		</div>
		<button class="close danger"><i></i></button>
	`),
	getName () {
		const m = this.model;
		return m.get('product.name') || (m.get('product.newproduct') + ' [отсутствует в каталоге]');
	},
	getUnits () {
		return this.model.display('product.unitMeasureId');
	},
	getInfo () {
		const m = this.model;
		return m.get('product.amount');
	},
	triggers: {
		'click .close': 'remove'
	}
});

const ProcProds = CollectionView.extend({
	initialize () {
		this.collection = new Collection(null, { model: ProcProduct });
		this._reinitCollection();
	},
	_reinitCollection () {
		const items = this.model.get('productsToBuy');
		this.collection.reset(items);
	},
	childView: ProcProd,
	childViewEvents: {
		'remove' (view) {
			this.collection.remove(view.model);
			this.trigger('remove:prod', view.model.get('product.id'), this.collection.toJSON());
		}
	}
});

const ProcName = UiList.extend({
	topText: m => '#' + m.get('tempId'),
	text: m => m.get('contragentName'),
	closeButton: true,
	triggers: {
		'click .close': 'remove:proc'
	}
});

const ProcView = UiCard.extend({
	cssClassModifiers: ['proc-item'],
	cardLines: {
		main: { viewClass: ProcName },
		products: { viewClass: ProcProds }
	},
	childViewTriggers: {
		'remove:proc': 'remove:proc'
	},
	childViewEvents: {
		'remove:prod' (prodid, prods2buy) {
			this.model.set('productsToBuy', prods2buy);
			this.trigger('remove:prod', prodid, this.model);
		}
	}
});

export const PurchaseProcesses = CollectionView.extend({
	initialize () {
		this.collection = new Collection();
		this._reinitCollection();
		this.listenTo(this.model, 'change:typeDetails.processes', this._reinitCollection);
	},
	_reinitCollection () {
		const procs = this.model.get('typeDetails.processes') || [];
		this.collection.reset(procs);
	},
	childViewOptions () {
		return {
			taskModel: this.model
		};
	},
	childView: ProcView,
	emptyView: View.extend({ template: () => '... пусто ...' }),
	childViewEvents: {
		'remove:proc' (v) {
			const tempId = v.model.get('tempId');
			const details = this.model.get('typeDetails');
			const items = details.items;
			items.forEach(item => {
				if (item.tempProcessId === tempId) {
					item.tempProcessId = null;
				}
			});
			this.collection.remove(v.model);
			details.processes = this.collection.toJSON();

			this.model.save(null, {
				wait: true,
				attrs: { typeDetails: details },
				method: 'PATCH'
			}).then(() => {
				this.model.trigger('change:typeDetails.items');
			});
		},
		'remove:prod' (prodid, proc) {
			const details = this.model.get('typeDetails');
			const items = details.items;
			items.forEach(item => {
				if (item.productionId === prodid || (item.productionId == null && item.newproduct === prodid)) {
					item.tempProcessId = null;
				}
			});
			const prod2buy = proc.get('productsToBuy');
			if (!prod2buy || !prod2buy.length) {
				this.collection.remove(proc);
			}
			details.processes = this.collection.toJSON();
			this.model.save(null, {
				wait: true,
				attrs: { typeDetails: details },
				method: 'PATCH'
			}).then(() => {
				this.model.trigger('change:typeDetails.items');
			});
		}
	}
});



const PurchasesButtons = CollectionView.extend({
	initialize () {
		const btns = [
			{
				id: 'addnew',
				name: 'добавить процесс',
				filter: m => !m.purchaseRequestHasNoFreeProducts()
			},
			{
				id: 'startprocs',
				name: 'запустить процессы и завершить эту задачу',
				filter: m => m.purchaseRequestHasNoFreeProducts() && m.purchaseRequestHasAnyProcess() && m.isCompletable()
			}
		];
		this.collection = new Collection(btns);
		this.listenTo(this.model, 'change:typeDetails.items', this.sort);
	},
	childView: ProcButtonView,
	childViewOptions () {
		return {
			clickEventTrigger: 'purchase:action',
			parentModel: this.model
		};
	},
	childViewEvents: {
		'purchase:action' (view) {
			this.trigger('purchase:action:' + view.model.id);
		}
	},
	viewFilter (v) {
		const fltr = v.model.get('filter');
		const res = fltr(this.model);
		return res;
	}
});


const ReadyPurchaseProcesses = CollectionView.extend({
	initialize () {
		const items = this.model.get('causedProcesses') || [];
		this.collection = new Collection(items, { model: ProcessModel });
	},
	childView: ProcessItemView
});


export const Purchases = View.extend({
	renderOnModelChange: true,
	className: 'purchase-processes-tab plain',
	template: _.template('<div class="processes-place">procs</div><div class="buttons-place">btns</div>'),
	regions: {
		processes: '> .processes-place',
		buttons: '> .buttons-place'
	},
	onRender () {
		const buttons = this.buildViewByKey('buttons');
		if (buttons) {
			this.showChildView('buttons', buttons);
		}
		const processes = this.buildViewByKey('processes');
		if (processes) {
			this.showChildView('processes', processes);
		}
	},
	nestedViewOptions () {
		return {
			model: this.model
		};
	},
	buildViewByKey () {
		return buildViewByKey(this, ...arguments);
	},
	buttons: PurchasesButtons,
	processes () {
		const m = this.model;
		return m.isClosed()
			? ReadyPurchaseProcesses
			: PurchaseProcesses;
	},
	childViewEvents: {
		'purchase:action:addnew' () {
			addProducts({ model: this.model });
		},
		'purchase:action:startprocs' () {
			const processes = this.model.attributes.typeDetails.processes;
			const url = this.model.url() + '/start-purchase';
			const post = {
				taskId: this.model.id,
				processes
			};

			modals.confirm('Будут заведены соответствующие процессы закупок а эта задача завершена').then(() => {
				this.model.save(null, {
					url,
					wait: true,
					attrs: post,
					method: 'POST'
				}).then(() => {
					modals.message('Готово');
				}).catch(() => {
					modals.error('произошла ошибка на стороне сервера');
				});
			}).catch(() => {});
		}
	}
});
