export const emptyStates = {
	waiting: 'подождите пожалуйста',
	empty: 'ничего не нашлось',
	notFound: 'нет записей',
	default: 'пусто'
};

export const emptyViewMixin = {
	thisClassName: 'empty-view',
	getEmptyText () {
		const id = this.getOption('emptyState');
		const text = emptyStates[id] || emptyStates.default;
		return text;
	}
};
