// import BaseView from 'pages/view';
// import ModelProperties from 'components/ui-model-card/properties';
// import h from 'components/ui-model-card/helpers';
import UiCardBlock from 'components/ui-card-block/block';
import PersonsList from 'components/contacts/views/persons-list';
// import createNestedTasks from 'mod/tasks/views/entity-tasks-2';
import { ActivityListUiBlock } from 'mods/logs/views';

import nestedDetails from 'components/bank-details/entity-nested-details';

// import { FileService, selectFiles } from 'components/files';
// import ModelActions from 'components/ui-model-card/actions';

// import JoinedProducts from './joined-products';
// import ContragentPagesBlockView from './pagesBlock';

import PageLayout from './pageLayout';

// import createNestedComments from 'components/comments/create-nested-comments';

// import createLogs from 'components/logs/views/create-nested';

// import displayContactValue from 'components/contacts/models/display-value';


// const TimeSpan = window.TimeSpan;

/*
const template = _.template('');



export default BaseView.extend({
	className:'contragent-page',
	template,
	modelEvents:{
		'open:selectFilesToUpload'(){
			selectFiles(blobs => {
				this.fileSvc.addBlobs(blobs);
			});
		}
	},
	initialize(){

		let m = this.model;
		//let contragent = this.model;

		this.fileSvc = new FileService({
			shouldUseNotifier: true,
			shouldUseUploadOnNewFiles: true,
			uploadOptions: {
				url: _.result(this.model, 'url') + '/upload',
			},
		});
		this.on('destroy', () => this.fileSvc.destroy());
		this.on('render', () => this.fileSvc.start());

		this.createNested('main', {
			viewClass: ModelProperties.extend({className:'card lined with-border'}),
			viewOptions: {
				header:{ text: 'основное', addClass:'bg-main-dark'},
				properties:[
					h.ui('name', {editButton: m.mainInfoCanBeChanged() } ),
					h.ui('roles', {editButton: m.mainInfoCanBeChanged() } ),
					h.ui('occupation', {editButton: m.mainInfoCanBeChanged() } ),
					h.ui('note', {editButton: m.mainInfoCanBeChanged() } ),
					//h.ui('id', {editButton: false } ),
				],
			},
			regionTemplate: { replaceElement:true }
		});

		this.createNested('pages', {
			viewClass: ContragentPagesBlockView,
			viewOptions: {
				currentSubpage: '/',
			},
			regionTemplate: { replaceElement:true }
		});

		this.createNested('third', {
			viewClass: ModelActions.extend({className:'card lined with-border'}),
			viewOptions: {
				refreshOnModelEvents: ['change:actions'],
				actions: ['upload:files'],
				emptyViewText: '...',
			},
			regionTemplate: { replaceElement:true }
		});

		this.createNested('contacts', {
			viewClass: UiCardBlock,
			viewOptions: {
				header:{ text: 'контакты', addClass:'bg-main-dark'},
				content: PersonsList,
				contentOptions(){
					return {
						addClass: 'scrolled-y',
						collection: m.getContacts()
					};
				},
				action:'add:person'
			},
			regionTemplate: { replaceElement:true }
		});

		nestedDetails(this);


		this.createNested('activity', {
			viewClass: ActivityListUiBlock,
			viewOptions:{
				model: this.model,
				fileSvc: this.fileSvc,
			}
		});

		createNestedTasks(this, {
			completedTasksEnabled: true,
			modelEvents:{
				'new:state'(){
					this.render();
				}
			},
		});

		this.createNested('joinedContragents', {
			viewClass: UiCardBlock,
			viewOptions: {
				header:{ text: 'продукция', addClass:'bg-main-dark'},
				content: JoinedProducts,
				contentOptions(){
					return {
						tagName:'ul',
						addClass: 'scrolled-y',
						collection: m.getJoinedProducts(),
						childViewOptions:{
							tagName:'li',
							type: 'product'
						}
					};
				},
				action:'add:joined:product'
			},
			regionTemplate: { replaceElement:true }
		});


	},
	onRender(){
		this.showAllNested();
	},
});

*/

export default PageLayout.extend({
	currentSubpage: '',
	initializeNesteds () {
		const m = this.model;
		this.createNested('activity', {
			viewClass: ActivityListUiBlock,
			viewOptions: {
				model: this.model,
				fileSvc: this.fileSvc
			}
		});

		nestedDetails(this);

		this.createNested('contacts', {
			viewClass: UiCardBlock,
			viewOptions: {
				header: { text: 'контакты', addClass: 'bg-main-dark' },
				content: PersonsList,
				contentOptions () {
					return {
						addClass: 'scrolled-y',
						collection: m.getContacts()
					};
				},
				action: 'add:person'
			},
			regionTemplate: { replaceElement: true }
		});
	}
});
