import { _, moment } from 'vendors';
import twinContext from './twin-context';
import twinTemplate from './twin.html';

function smartDate (d1, opts = {}) {
	const i = Date.info(d1);
	if (!i.valid) return;
	let main;
	let dif = moment(i.date).fromNow();
	opts.main && (dif = `(${dif})`);
	if (i.sameDay) { main = moment(i.date).format('LT'); } else if (i.sameMonth) { main = moment(i.date).format('Do MMM'); } else if (i.sameYear) { main = moment(i.date).format('Do MMM'); } else { main = moment(i.date).format('ll'); }

	if (opts.time && !i.sameDay) { main += ', в ' + moment(i.date).format('LT'); }

	const res = [];
	opts.main && res.push(main);
	opts.difference && res.push(dif);
	const dateStr = res.join(' ');
	return dateStr;
}

smartDate.main = function (value, opts = {}) {
	return smartDate(value, _.extend({}, opts, { main: true }));
};
smartDate.diff = function (value, opts = {}) {
	return smartDate(value, _.extend({}, opts, { difference: true }));
};
smartDate.full = function (value, opts = {}) {
	return smartDate(value, _.extend({}, opts, { main: true, difference: true }));
};

smartDate.twinHtml = function (date, opts = {}) {
	return twinTemplate(twinContext(date, opts));
};

export const dateTransform = (v, opts = {}) => {
	const di = Date.info(v);
	if (!di.valid) return '';
	if (opts.format === 'fromNow') { return moment(di.date).fromNow(); }
	return smartDate.main(di.date, opts.smartDateOptions);
};

export default smartDate;
