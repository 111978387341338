// import View from 'base/view';
import { LogTransferBlock } from '../common';
import TransactionsView from './transactions-view';

export default LogTransferBlock.extend({
	cssClassModifiers: ['contragents-logs'],
	availableFilters: ['contragents', 'products', 'period'],
	buildEntriesView (filters) {
		return new TransactionsView({ filters, departmentModel: this.model });
	}
});
