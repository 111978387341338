import { UiSchemaMoleculeView } from 'components/ui2/molecule';
import { buildSchema } from '../models/buildSchema';

export const ListItemView = UiSchemaMoleculeView.extend({
	thisClassName: 'build',
	modelSchema: buildSchema,
	leftItems: [
		{
			thisClassName: 'amount fixed',
			text: v => v.display('amountPostfix'),
			topText: 'объём'
		}
	],
	content: {
		thisClassName: 'prod fixed',
		text: v => v.display('productName'),
		topText: 'продукция'
	},
	rightItems: [
		{
			thisClassName: 'batch elastic',
			text: v => v.display('productBatchIdString'),
			topText: 'партия'
		},
		{
			thisClassName: 'created fixed',
			topText: v => v.display('actorId'),
			text: v => v.display('createdFromNow'),
			bottomText: v => v.display('created')
		}
	]
});
// UiSchemaMoleculeView.extend({});
