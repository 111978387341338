
export default CollectionView => CollectionView.extend({
	buildChildView () {
		const view = CollectionView.prototype.buildChildView.apply(this, arguments);
		view._isModelView = true;
		return view;
	}
	/* _addChild(view, index) {
		view._isCustomView = !view._isModelView;

		if(_.isObject(index)) {
			index = index.index;
		}

		if (index != null) {
			view._index = index;
		}

		return CollectionView.prototype._addChild.apply(this, arguments);
	}, */
});
