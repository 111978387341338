import { _ } from 'vendors';
import View from 'base/view';
import InfoView from './info';
import FiltersView from './filters';
import ActionsLayout from 'components/actions/list';

export { EntriesManagerView } from './EntriesManagerView';

export default View.extend({
	className: 'entries-manager',
	template: _.template(''),
	initialize () {
		this.initInfo();
		this.initFilters();
		this.initSelect();
		this.initActions();
	},
	onRender () {
		this.showAllNested();
	},
	initInfo () {
		if (!this.getOption('infoOptions')) return;

		this.createNested('info', {
			skipModels: true,
			viewClass: InfoView,
			viewOptions: this.getOption('infoOptions'),
			regionTemplate: { replaceElement: true }
		});
	},
	initFilters () {
		const opts = this.getOption('filtersOptions');
		const instance = opts && opts.instance;

		if (!instance) return;

		this.createNested('filters', {
			skipModels: true,
			viewClass: FiltersView,
			viewOptions: {
				instance,
				collection: instance.getCollection()
			},
			regionTemplate: { replaceElement: true }
		});
	},
	initSelect () {},
	initActions () {
		if (!this.getOption('actionsEnabled')) return;

		this.createNested('actions', {
			viewClass: ActionsLayout,
			viewOptions: {
				addClass: 'card lined',
				context: this.getOption('view')
			},
			regionTemplate: { replaceElement: true }
		});
	}
});
