import { _ } from 'vendors';

function wheelDownToScrollRightHandler (e) {
	const oe = e.originalEvent;
	this.$el.scrollLeft(this.$el.scrollLeft() + oe.deltaY);
}

function setupListener () {
	if (!(this.getOption('scrollDirection') === 'right' && !this._wheelDownToScrollRightInitialized)) { return; }

	const onWheel = _.bind(wheelDownToScrollRightHandler, this);
	this.$el.on('wheel', onWheel);
	this.on('before:destroy', () => this.$el.off('wheel', onWheel));

	this._wheelDownToScrollRightInitialized = true;
}


export default (Base) => Base.extend({
	constructor () {
		Base.apply(this, arguments);
		setupListener.call(this);
	}
});
