import modals from 'helpers/modals';
import busEdit from 'bus/edit';
import busModels from 'bus/models';
import { loadScript, MapView } from 'components/yandex-map';
import InputPlaceholdered from 'components/controls/input-placeholdered';
import { _ } from 'vendors';

const AddressInput = InputPlaceholdered.extend({
	initialize () {
		this.noValue = this.getOption('noValue');
		if (!this.noValue) {
			this.value = this.getOption('value') || {};
		}
	},
	className: 'address-input',
	template: _.template(`
		<div>
			<input/>
			<% if(contragent) { %><button class="contragent-icon"><i></i></button><% } %>
			<button class="map-icon"><i></i></button>
		</div>
		<div class="predefined-values"></div>
	`),
	templateContext () {
		return {
			contragent: this.getOption('contragent')
		};
	},
	events: {
		'click .map-icon' () {
			loadScript().then(() => {
				let opts;
				if (this.noValue) {
					opts = {
						address: this.getOption('value'),
						coords: this.getOption('valueNote')
					};
				} else {
					let coords;
					if (this.value.lat && this.value.lng) {
						coords = [this.value.lat, this.value.lng];
					}
					opts = {
						address: this.value.address,
						coords// _.pick(this.value, 'lat', 'lng')
					};
				}

				const view = new MapView(opts);
				const modal = modals.show(view, { type: 'full', css: { wrapper: 'yat-modal-wrapper yandex-map full-screen' } });

				this.listenToOnce(modal, 'childview:address', addr => {
					modal.destroy();
					this.triggerMethod('address', addr);
				});
			});
		},
		'click .contragent-icon' () {
			busEdit.do({
				header: 'Выбор адреса контрагента',
				controlType: 'select-api',
				controlOptions: {
					shouldReturnModel: true,
					collectionChildViewMixin: {
						text: m => m.get('contragentName'),
						smallText: m => m.get('contactName') + '<br/>' + m.get('address')
					}
				},
				sourceValues: () => busModels.request('new:contragents:address:search', { fetch: true })
			}).then(data => {
				if (!this.noValue) {
					const raw = _.pick(data.attributes, 'address');
					const contragent = {
						id: data.get('contragentId'),
						name: data.get('contragentName'),
						contactName: data.get('contactName')
					};
					_.extend(raw, data.attributes.coords, { contragent });
					this.triggerMethod('address', raw);
				}
			});
		}
	},
	inputOptions () {
		if (this.noValue) {
			return {
				value: this.getOption('value')
			};
		} else {
			return {
				value: this.value.address
			};
		}
	},
	controlsEvents: {
		'control:change' (text) {
			if (!this.noValue) {
				this.value.address = text;
			}
			this.triggerChange(text);
		},
		'control:done' (text) {
			if (!this.noValue) {
				this.value.address = text;
			}
			this.triggerDone(text);
		},
		'control:validate:error' (...args) {
			this._triggerEvent('validate:error', ...args);
		}
	},
	onAddress (address) {
		if (!this.noValue) {
			this.value = Object.assign({}, this.value, address);
			this.triggerChange(address.address);
			this.render();
		}
	},
	onChildviewPredefinedClick (view) {
		const addr = _.extend({}, view.model.get('value'));
		if (!this.noValue) {
			this.value = addr;
		} else {
			this.value = addr.address;
		}
		this.render();
	}

});


export const AdvancedAddress = InputPlaceholdered.extend({
	initialize () {
		this.value = _.extend({}, this.getOption('value'));
	},
	className: 'address-input',
	template: _.template(`
		<div>
			<input/>
			<% if(contragent) { %><button class="contragent-icon"><i></i></button><% } %>
			<button class="map-icon"><i></i></button>
		</div>
		<div class="predefined-values"></div>
	`),
	templateContext () {
		return {
			contragent: this.getOption('contragent')
		};
	},
	events: {
		'click .map-icon' () {
			loadScript().then(() => {
				const opts = {
					address: this.value.address,
					coords: this.value.coords ? Object.assign({}, this.value.coords) : undefined
				};

				const view = new MapView(opts);
				const modal = modals.show(view, { type: 'full', css: { wrapper: 'yat-modal-wrapper yandex-map full-screen' } });

				this.listenToOnce(modal, 'childview:address', addr => {
					modal.destroy();
					this.triggerMethod('address', addr);
				});
			});
		},
		'click .contragent-icon' () {
			busEdit.do({
				header: 'Выбор адреса контрагента',
				controlType: 'select-api',
				controlOptions: {
					shouldReturnModel: true,
					collectionChildViewMixin: {
						text: m => m.get('contragentName'),
						smallText: m => m.get('contactName') + '<br/>' + m.get('address')
					}
				},
				sourceValues: () => busModels.request('new:contragents:address:search', { fetch: true })
			}).then(data => {
				if (!this.noValue) {
					const raw = _.pick(data.attributes, 'address');
					const contragent = {
						id: data.get('contragentId'),
						name: data.get('contragentName'),
						contactName: data.get('contactName')
					};
					_.extend(raw, data.attributes.coords, { contragent });
					this.triggerMethod('address', raw);
				}
			});
		}
	},
	inputOptions () {
		return {
			value: this.value.address
		};
	},
	controlsEvents: {
		'control:change' (text) {
			this.value.address = text;
			this.triggerChange();
		},
		'control:done' (text) {
			this.value.address = text;
			this.triggerDone(text);
		},
		'control:validate:error' (...args) {
			this._triggerEvent('validate:error', ...args);
		}
	},
	onAddress (address) {
		const coords = address.lat != null
			? {
				lat: address.lat,
				lng: address.lng
			}
			: undefined;
		address = address.address;

		this.value = coords ? { address, coords } : { address };
		this.triggerChange();
		this.render();
	},
	onChildviewPredefinedClick (view) {
		const newval = view.model.get('value');
		if (!newval) {
			this.value = newval;
		} else {
			this.value = {
				label: view.model.get('key'),
				address: newval.address,
				coords: newval.coords ? _.extend({}, newval.coords) : undefined
			};
		}
		this.triggerChange();
		this.render();
	}

});

export default AddressInput;
