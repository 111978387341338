import { _ } from 'vendors';
import Single from './single';
import Range from './range';
import SingleCollection from './single-collection';
import RangeCollection from './range-collection';
import factory from './factory';
import getPrototype from './get-prototype';
export default _.extend({
	Single,
	SingleCollection,
	Range,
	RangeCollection,
	getPrototype
}, factory);
