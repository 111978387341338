import editValue from 'bus/edit';
import { AcceptReadyProductLayout } from './AcceptReadyProductLayout';
import { ContragentBatchesCollection } from './ContragentBatchesCollection';


const openModal = function (process, batches) {
	return editValue.do({
		modalOptions: {
			className: 'accept-production-modal',
			css: {
				wrapper: 'flex-modal-wrapper',
				bg: 'flex-modal-bg',
				contentWrapper: 'flex-modal-content-wrapper',
				box: 'flex-modal-box',
				close: 'flex-modal-close',
				header: 'flex-modal-header',
				content: 'flex-modal-content',
				actions: 'flex-modal-actions',
				resolve: 'flex-modal-resolve',
				reject: 'flex-modal-reject'
			},
			modifiers: {
				'after:render': null,
				refresh: null
			}
		},
		header: 'Приёмка готовой продукции и списание ресурсов у контрагента',
		applyLabel: 'далее',
		resetButton: false,
		cancelButton: false,
		controlView: AcceptReadyProductLayout,
		controlViewOptions: {
			process,
			batches,
			readyOnRender: true
		}
	});
};

export function AcceptReadyProductInModal (process) {
	const contragentBatches = ContragentBatchesCollection.create(process);
	return contragentBatches.fetch().then(() => openModal(process, contragentBatches));
}
