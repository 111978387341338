import { _ } from 'vendors';
import View from 'base/view';
import mix from 'helpers/mix';
import ControlsMixin from 'components/controls/mixin';
import Checks from 'components/controls/checks';

import ContactName from './contact';
import HumanName from './human';
import template from './template.html';
import testValue from 'helpers/validate/test-value';

function detectType (value) {
	const person = value.person || {};
	const pval = _(person).map((c) => c != null ? c.toString() : '').join('');
	return !value.contactName || pval.length ? 'human' : 'contact';
}

export default mix(View).with(ControlsMixin).extend({
	className: 'c-person-name',
	template,
	regions: {
		type: { el: '.type', replaceElement: true },
		control: { el: '.control', replaceElement: true }
	},
	initialize () {
		this.initValue();
	},
	initValue () {
		this.value = _.extend({}, this.getOption('value'));
		this.initialValue = _.extend({}, this.getOption('value'));
		this.prepareInitialValues();
		this.type = detectType(this.value);
	},
	onRender () {
		this.showType();
		this.showControl(this.getType());
		this.trigger('content:ready');
	},
	getType () {
		return this.type || 'human';
	},
	showType () {
		const view = new Checks({
			proxyTo: this,
			controlName: 'type',
			type: 'radio',
			value: this.getType(),
			sourceValues: {
				human: 'контактное лицо',
				contact: 'общий контакт'
			}
		});
		this.showChildView('type', view);
	},
	showControl (type, onSwap) {
		const View = type === 'human' ? HumanName : ContactName;
		const value = this.getTypeValue(type, onSwap);

		const view = new View({
			proxyTo: this,
			controlName: type,
			value,
			focusOnRender: false
		});
		this.showChildView('control', view);
	},
	getTypeValue (type, useInitial) {
		type || (type = this.getType());
		if (type === 'human') {
			const cname = (this.value.person || {}).name;
			if (!useInitial) {
				return cname;
			} else {
				const cnametext = _.reduce(cname, (memo, v, k) => memo += (v || '').trim(), ''); // eslint-disable-line
				return (!!cnametext.length && cname) || (this.initialValue.person || {}).name;
			}
		} else {
			return this.value.contactName || (useInitial && this.initialValue.contactName) || null;
		}
		// let res = type === 'human' ? (this.value.person || {}).name : this.value.contactName;
		// return res;
	},
	controlsEvents: {
		'type:change' (value) {
			this.type = value;
			this.triggerMethod('type:change', value);
			const tvalue = {};
			if (value === 'human') {
				tvalue.person = { name: (this.value.person || {}).name };
				tvalue.contactName = null;
			} else {
				tvalue.contactName = this.value.contactName || this.initialValue.contactName || null;
			}
			this.triggerChange(tvalue);
		},
		'human:change' (name) {
			const person = _.extend({}, this.initialValue.person, { name });
			const value = {
				person,
				contactName: null
			};
			this.value.person = person;
			this.value.contactName = null;
			this.triggerChange(value);
		},
		'human:validate:error' () {
			const person = _.extend({}, this.initialValue.person);
			delete person.name;
			const value = {
				person: {},
				contactName: null
			};
			this.value.contactName = null;
			this.value.person = person;
			// console.log('human:validate:error', person);
			this.triggerChange(value);
		},
		'contact:change' (contactName) {
			this.value.contactName = contactName;
			this.value.person = null;
			const value = {
				person: null,
				contactName
			};
			this.triggerChange(value);
		},
		'contact:done' () {
			const value = {
				person: null,
				contactName: this.value.contactName
			};
			this.triggerDone(value);
		},
		'human:done' () {
			const value = {
				person: this.value.person,
				contactName: null
			};
			this.triggerDone(value);
		}

	},
	onTypeChange (type) {
		this.showControl(type, true);
	},
	validate (value, opts = {}) {
		opts.errors || (opts.errors = []);
		let test = false;
		test = !!value && (value.contactName || (value.person || {}).name);
		// if (!value || (!value.contactName || value.person)) {
		// 	test = false;
		// } else if (value.person) {
		// 	test = (value.person || {}).name != null;
		// }
		// let test = value.contactName != null || (value.person != null && value.person.name != null);
		if (testValue(test, 'invalidContactName', opts.errors)) { return true; } else { return false; }
	}
});
