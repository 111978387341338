import { _ } from 'vendors';
import { CheckListCollection } from '../models/checklist';
import editValue from 'bus/edit';
import Input from 'components/controls/input';
import action from 'helpers/action';

export default Base => Base.extend({
	constructor () {
		this._actions || (this._actions = []);
		this._actions.push(action('add:check:item', 'добавить пункт', null, {
			order: -89,
			rule: (m) => m.isNotClosed() && m.isEditable(true),
			places: 'page'
		}));
		Base.apply(this, arguments);
		this.registerNestedEntity('checkList', {
			class: CheckListCollection
		});
		this.on('checkList:edited', this.handleCheckListChange);
	},
	handleCheckListChange () {
		if (this.isNew()) return;
		const checkList = this.getCheckList().toJSON(); // this.get('checkList');
		// console.log('BEFORE SAVE CHACK', checkList);
		this.save(null, { wait: true, preloader: true, attrs: { checkList }, method: 'patch' });
	},
	hasCheckList () {
		const cl = this.get('checkList');
		if (!cl) return false;
		if (Array.isArray(cl)) {
			return !!cl.length;
		}
		if (typeof cl === 'object') {
			return !!_.size(cl);
		}
		return false;
	},
	getCheckList () {
		return this.entity('checkList');
	},
	actionAddCheckItem () {
		// let model = this;
		const col = this.getCheckList();
		editValue.do({
			header: 'добавление пункта',
			applyLabel: 'добавить',
			resetButton: false,
			cancelLabel: 'отставить',
			editOptions: {
				controlView: Input
			}
		}).then(text => {
			text && col.add({ text, index: col.length });
			col.trigger('edited');
		});
	}
	// _onChangeCheckList(){
	// 	if(!this.isNew()){
	// 		this.save(null,{ preloader: true, attrs: {checkList: this.get('checkList'), method: 'patch'}});
	// 	}
	// }
});

