import { _ } from 'vendors';
const testValue = function (condition, error, errors = []) {
	if (_.isFunction(condition)) { condition = condition(); }

	if (condition) { return true; } else {
		error && errors.push(error);
		return false;
	}
};
export default testValue;
