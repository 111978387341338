import BasePage from 'pages/auth';
import Layout from './layout';
// import Emps from './emps';
// import Deps from './deps';

const Page = BasePage.extend({
	name: 'contragents search page',
	label: 'Поиск контрагентов',
	route: 'search',
	// children:[Emps, Deps],
	icon: 'search contragents',
	Layout,
	layoutOptions: {
		rebuild: false
	}
});

export default Page;
