import Card from 'components/ui-card/item';
// import Item from 'components/ui-list/item';

export default Card.extend({
	addClass: 'bg-light-yellow',
	cardLines: [
		{
			name: 'result',
			text () {
				const founded = this.model.get('founded');

				return founded == null ? '&mdash;' : founded;
			},
			topText: 'нашлось',
			addClass: 'half'
		},
		{
			name: 'sorting',
			text () {
				return 'по умолчанию';
			},
			topText: 'сортировка',
			editButton: true,
			addClass: 'half'
		}
	],
	intialize () {
		console.log('-info-initialize-', this);
	}
});
