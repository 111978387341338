import Base from 'base/collection-view';
import Item from './card-item';
import Empty from 'components/empty-view';

export default Base.extend({
	childView: Item,
	emptyView: Empty,
	attributes () {
		return {
			'data-scrollable': 'contragents'
		};
	}
});
