import { _ } from 'vendors';
import Base from 'components/ui-list/item';
import ActionsPopover from 'behaviors/actions-popover';
import employees from '../singleton';
import smartOpen from 'helpers/smart-open';
// import DynamicClass from 'mixins/view/dynamic-class';
// import mix from 'helpers/mix';

export default Base.extend({
	behaviors: [ActionsPopover],
	cssClassModifiers: [
		() => 'employee',
		(m, v) => v.model.get('role'),
		(m, v) => v.getEmployee().isOnline() ? 'online' : 'offline',
		(m, v) => v.getEmployee().isVirtual() ? 'virtual' : '',
		(m, v) => v.getEmployee().isArchived() ? 'archived' : '',
		(m, v) => v.getEmployee().isActive() ? 'active' : '',
		(m, v) => v.getEmployee().isOff() ? 'off' : '',
		(m, v) => v.getEmployee().isFired() ? 'fired' : '',
		(m, v) => v.getEmployee().isIntern() ? 'intern' : ''
	],
	getEmployee () {
		return this.model;
	},
	smallText () {
		return this.getEmployee().get('note');
	},
	bottomText () {
		return this.getEmployee().get('occupation');
	},
	topText () {
		const first = this.getEmployee().get('name.first');
		const middle = this.getEmployee().get('name.middle');
		const name = [];
		first && (name.push(first));
		middle && (name.push(middle));
		return name.join(' ');
	},
	// textAction: 'open:card',
	optionsButton: true,
	infoButton: false,
	rightButtons: [
		(m, v) => {
			if (v.getOption('textAction') === 'open:card') { return; }
			return { class: 'open-card', icon: true, 'data-trigger': 'open:card', title: 'открыть карточку в новой вкладке' };
		}
	],
	isOptionsButtonAvailable () {
		const available = this.getOption('optionsButtonAvailable');
		if (available == null || !_.isBoolean(available)) {
			return true;
		} else {
			return available;
		}
	},
	onOpenCardClick (m, v, e) {
		const id = this.getOption('employeeId', true);
		const employee = employees.get(id);
		if (employee == null) {
			console.error('employee not found, id: ', id);
			return;
		}
		const url = employee.cardUrl();
		smartOpen(url, { ctrlKey: true });
	}

});
