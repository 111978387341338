import { _, $, moment } from 'vendors';
import View from 'base/view';
import CollectionView from 'base/collection-view';
import smartDate from 'helpers/date/smart';
import { entryConfig } from '../models/entry-config';
import ActionsPopover from 'behaviors/actions-popover';
import { md } from './markdown-it';
import modals from 'helpers/modals';

const CommentEntryActionsPopover = ActionsPopover.extend({
	position: 'left'
});

const internalLinkPattern = new RegExp(`(https?:\\/\\/${document.location.host})([^\\s]+)`);
const taskLabelPattern = /\[\/tasks\/(\d+)\]/gmi;
const commentEntityHtml = _.template('<a href="<%= url %>"><i></i><span><%= text %></span></a>');
const taskTextPattern = /задача [№#](\d+)(?:($|\D))/;
const processTextPattern = /процесс [№#](\d+)(?:($|\D))/;
const taskOrProcessPattern = /(процесс|задача) [№#](\d+)(?:($|\D))/gm;
const entryTemplate =
`<div class="date-time"><%= dateTime %></div>
<div class="text-wrapper">
	<% if (markers) {%><div class="markers"><%= markers %></div><% } %>
	<% if (subject) {%><header><%= subject %></header><% } %>
	<div class="text"><%= text %></div>
</div>
<% if(options) {%>
<div class="options">
	<button class="options"><i></i></button>
</div>
<% } %>
`;

const allowedOrigins = {
	'http://app.pm.loc:9020':1,
	'https://app.palma-med.ru':1,
	'http://app.palma-med.ru':1
}

function isThisAppUrl(origin) {
	return document.location.origin === origin || origin in allowedOrigins;
}

function getInternalUrlContext(pathname) {
	const original = pathname;
	pathname = pathname.substring(1);
	let chunks = pathname.split('/');
	if (chunks[0] === 'tasks') {
		return {
			type: 'task',
			entityId: chunks[1],
			text: 'задача #' + chunks[1]
		}
	}
	else if (chunks[0] === 'procs') {
		return {
			type: 'process',
			entityId: chunks[1],
			text: 'процесс #' + chunks[1]
		}
	}
	return {
		text: original,
		type: 'other'
	}
}

function buildEntityLink(type, entityId, internalUrl, text) {
	return `<a href="${internalUrl}" data-url-type="${type}" data-entity-id="${entityId}">${text}</a>`
}

const textBuilders = {
	'процесс': id => buildEntityLink('process', id, '/procs/' + id, 'процесс #' + id),
	'задача': id => buildEntityLink('task', id, '/tasks/' + id, 'задача #' + id),
}

function buildEntityLinkFromText(text, id) {
	const builder = textBuilders[text];
	if(!builder) { return; }
	return builder(id);
}
const CommentEntryItem = View.extend({
	className: 'comment-entry',
	template: _.template(entryTemplate),
	behaviors: [CommentEntryActionsPopover],
	renderOnModelChange: true,
	cssClassModifiers: [
		m => m.get('type'),
		(m, v) => v.getPositive(),
		m => (m.get('marks') || '').replace(/,\s*/g, ' ')
	],
	events: {
		'click [data-action="modal"]' (e) {
			const $img = $(e.target).closest('[data-action]').find('img');
			const href = $img.data('downloadurl');
			const view = new View({
				className: 'image-in-modal-content',
				template: `<img src="${$img.get(0).src}"><a href="javascript:" auth-href="${href}">открыть оригинал изображения</a>`
			});
			modals.show({ className: 'image-in-modal', content: view, type: 'full' });
		}
	},
	getParentEntity () {
		return this.getOption('parentEntity');
	},
	getMarkersHtml () {
		let html = '';
		const markers = (this.model.get('marks') || '').split(/,\s*/);
		_.each(markers, marker => {
			if (!marker || marker === 'incorrect') return;
			html += `<i class="${marker}"></i>`;
		});
		return html;
	},
	templateContext () {
		const date = this.model.getDate();
		const dateTime = moment(date).format('HH:mm'); // date.getHours() + ':' + date.getMinutes();
		const subject = this.getSubject();
		const text = this.getText();
		const markers = this.getMarkersHtml();
		const options = this.model.isEditable();
		return {
			dateTime,
			text,
			subject,
			markers,
			options
		};
	},
	_transformText (text) {

		// text = text.replace(internalLinkPattern, '[$2]($2)');

		// text = md.render(text);
		// text = text.replace(taskOrProcessPattern, (match, group) => {
		// 	console.log(match);
		// 	console.log('	',group);
		// 	return match;
		// });
		// text = text.replace(/https?:\/\/\S+/gm, (match, group) => {
		// 	try {
		// 		var url = new URL(match);
		// 		if (!isThisAppUrl(url.origin)) { 
		// 			return match; 
		// 		}

		// 		var internalUrl = url.pathname + (url.query || '') + (url.hash || '');
		// 		var { text, type, entityId } = getInternalUrlContext(url.pathname);
		// 		return buildEntityUrl(type, entityId, internalUrl, text);
		// 		// `<a href="${internalUrl}" data-url-type="${type}" data-entity-id="${entityId}">${text}</a>`;
		// 	} catch {
		// 		return match;
		// 	}
			
		// });		


		// text = text.replace(internalLinkPattern, '[$2]($2)');

		
		// text = md.render(text);
		text = text.replace(taskOrProcessPattern, (match, typeText, entityId) => {
			return buildEntityLinkFromText(typeText, entityId) || match;
		});
		text = text.replace(/https?:\/\/\S+/gm, (match, group) => {
			try {
				var url = new URL(match);
				if (!isThisAppUrl(url.origin)) { 
					return match; 
				}

				var internalUrl = url.pathname + (url.query || '') + (url.hash || '');
				var { text, type, entityId } = getInternalUrlContext(url.pathname);
				return buildEntityLink(type, entityId, internalUrl, text);
				// `<a href="${internalUrl}" data-url-type="${type}" data-entity-id="${entityId}">${text}</a>`;
			} catch {
				return match;
			}
			
		});
		text = md.render(text);
		return text;
	},
	getText () {
		let text = this.model.get('text');
		const type = this.model.get('type');

		// console.log(type);
		// if(type === 'contragentContactValueChanged') {
		// 	//console.log('>> ', this.model.toJSON());
		// }


		if (text || type === 'comment') { return this._transformText(text); }

		const cfg = this.getLogConfig('entryTexts', { default: {} });
		// console.log('cfg', cfg);
		const typeCfg = cfg[type];

		// console.warn('#log entry:', type, typeCfg, cfg);

		if (!typeCfg) return;

		const json = this.model.toJSON();
		// console.log("!", typeCfg, json);
		text = typeCfg.call(this.model, json, this.getParentEntity());

		return this._transformText(text);
	},
	getSubject () {
		const cfg = this.getLogConfig('entrySubjects', { default: {} });
		const type = this.model.get('type');
		const typeCfg = cfg[type];
		const json = this.model.toJSON();
		if (typeCfg != null) {
			if (typeCfg === false) return;
			return typeCfg.call(this.model, json, this.getParentEntity());
		} else if (cfg.default != null) {
			return cfg.default.call(this.model, json, this.getParentEntity());
		}
	},
	getLogConfig (key, opts = {}) {
		// const cfg = _.extend({}, entryConfig, this.getOption('logConfig'));
		const cfg = this.getOption('logConfig');
		if (!arguments.length) return cfg;
		const value = cfg[key];
		// console.error('# CFG', key, value, cfg);
		if (value != null) {
			return value;
		}
		return opts.default;
	},
	getPositive () {
		if (this.model.has('positive')) {
			return this.model.get('positive') ? 'positive' : 'negative';
		}
		const type = this.model.get('type');

		if (this.getLogConfig('positiveTypes', { default: [] }).indexOf(type) > -1) {
			return 'positive';
		}
		if (this.getLogConfig('negativeTypes', { default: [] }).indexOf(type) > -1) {
			return 'negative';
		}
	}

});

const CommentEntries = CollectionView.extend({
	className: 'comment-entries',
	cssClassModifiers: [
		(m, v) => v.collection.length < 2 ? 'single' : ''
	],
	childView: CommentEntryItem,
	emptyView: null,
	childViewOptions () {
		return {
			logConfig: this.getOption('logConfig'),
			parentEntity: this.getOption('parentEntity')
		};
	},
	collectionEvents: {
		'add:new' () {
			this.trigger('expecting:new');
		}
	}
});

const template =
`
<div class="info">
	<div class="date"><%= date %></div>
	<div class="icon"><i></i></div>
	<div class="author-entity">
		<div class="author"><%= author %></div>
		<% if (entityHtml) {%><div class="entity <%= entityType%>"><%= entityHtml %></div><% } %>
	</div>
	<div class="options">
		<button><i></i></button>
	</div>
</div>
<div class="entries-wrapper">
	<div class="angle"></div>
	<div class="entries"></div>
</div>
`;

export const ActivityListItem = View.extend({
	className: 'activity-list-item',
	cssClassModifiers: [
		m => m.isComment() ? 'comment' : 'not-comment',
		m => m.isMy() ? 'my' : 'not-my',
		m => !m.isMy() ? m.evenOrOdd() ? 'even' : 'odd' : ''
	],
	template: _.template(template),
	regions: {
		entries: '.entries'
	},
	modelEvents: {
		'entries:prepared' () {
			this.refreshDate();
			this.entriesView.render();
		}
	},
	childViewEvents: {
		'expecting:new' () {
			this.trigger('expecting:new');
		}
	},
	refreshDate () {
		this.$('.info .date').html(this.getDate());
	},
	onRender () {
		this.showEntries();
	},
	showEntries () {
		const view = this.entriesView = new CommentEntries({
			collection: this.model.entity('entries'),
			logConfig: this.getOption('logConfig'),
			parentEntity: this.getOption('parentEntity')
		});
		this.showChildView('entries', view, { replaceElement: true });
	},
	getParentEntity () {
		return this.getOption('parentEntity');
	},
	getAuthor () {
		const entry = this.getFirstEntry();
		return entry.display('actorId');
	},
	getDate () {
		const entry = this.getFirstEntry();
		const created = entry.get('created', { convert: 'date' });
		return smartDate.twinHtml(created);
	},
	getFirstEntry () {
		return this.model.getFirstEntry();
	},
	getLogConfig (key, opts = {}) {
		const cfg = _.extend({}, entryConfig, this.getOption('logConfig'));
		if (!arguments.length) return cfg;
		const value = cfg[key];
		if (value != null) {
			return value;
		}
		return opts.default;
	},
	buildCommentEntityHtml (data) {
		const htmlContext = { url: undefined, text: data.value.name };
		switch (data.type) {
		case 'task':
			htmlContext.url = '/tasks/' + data.value.id;
			break;
		}
		data.html = commentEntityHtml(htmlContext);
		return data;
	},
	getCommentEntity () {
		const entities = this.getLogConfig('entities', { default: [] });
		let founded = {};
		if (!entities.length) return founded;
		const first = this.getFirstEntry();
		_.some(entities, allowed => {
			const val = first.get(allowed);
			if (_.isObject(val)) {
				founded = { type: allowed, value: val };
				return true;
			}
		});

		founded.type && (founded = this.buildCommentEntityHtml(founded));
		return founded;
	},
	templateContext () {
		const entity = this.getCommentEntity();
		const entityType = entity.type;
		const entityHtml = entity.html;
		return {
			date: this.getDate(),
			author: this.getAuthor(),
			entity: !!entityHtml,
			entityType,
			entityHtml
		};
	}
});
