import BasePage from 'pages/auth';
import Layout from './layout';
// import Collection from 'mod/productionProducts/models/collection';
import Product from './product';
// import Deps from './deps';

const Page = BasePage.extend({
	moduleRights: { resources: 'view', resourcesProduction: 'view' },
	label: 'Список продукции',
	route: 'products',
	children: [Product],
	icon: 'production',
	onBeforeStart () {
		// if(!this.collection) {
		// 	let col = new Collection();
		// 	this.addCollection(col);
		// 	this.addStartPromise(col.fetchIfNot());
		// }
	},
	Layout
});

export default Page;
