// import { FilterModel } from '../ReportFilterModel';
import { filters } from './filters';
import { ReportCollection } from './ReportCollection';
import { tableSchema } from './tableSchema';
// import { TableLayoutView } from 'components/schema-table';
// import { commonTableLayoutMixin } from '../table-layout-mixin';
import { CommonReportLayout } from '../common-report-layout';

const raw = [
	'product',

	'totalBefore',

	'storeBefore',
	'contragentsBefore',
	'inProductsBefore',

	'produced',
	'purchased',
	'manualIncrement',

	'issuedToContragent',
	'takedFromContragent',

	'usedForProduction',
	'sold',
	'returnedToManufacturer',
	'defect',
	'utilized',
	'manualDecrement',

	'inProductsAfter',
	'contragentsAfter',
	'storeAfter',
	'totalAfter'

];

const src = [
	'product',

	'totalBefore',
	// 'storeBefore',
	// 'contragentsBefore',
	'inProductsBefore',
	'expectedInProductsBefore',

	'incrementNotManual',

	// 'decrementSum',
	'decrementCalculated',

	'usedForProduction',

	'inProductsAfter',
	'expectedInProductsAfter',
	// 'contragentsAfter',
	// 'storeAfter',
	'totalAfter'

];

export const SourcesReportLayout = CommonReportLayout.extend({

	rawFilters: filters,

	___cells: [
		'product',
		'totalBefore',
		'storeBefore',
		'contragentsBefore',
		'inProductsBefore',
		'appeared',
		'specificIncrement',
		'specificDecrement',
		'customDecrement',
		'storeAfter',
		'contragentsAfter',
		'inProductsAfter',
		'totalAfter'
		// 'totalDelta',
		// 'storeDelta',
		// 'contragentsDelta',
		// 'inProductsDelta'
		// 'amountPurchased',
		// 'amountProduced',
		// 'amountUsed',
		// 'amountTransfered',
		// 'amountTransferedBack',
		// 'amountFired'
	],
	cells: [
		...src
	],
	tableSchema,
	Collection: ReportCollection
	// rowOptions: {
	// 	baseClassName: 'table-report-entry',
	// 	thisClassName: 'product-before-after',
	// 	tagName: 'tr',
	// 	useWrapper: false,
	// 	useMolecule: false
	// },
	// listOptions: {
	// 	tagName: 'table',
	// 	baseClassName: 'strict-table'
	// },
	// cellOptions: {
	// 	tagName: 'td',
	// 	baseClassName: 'table-cell'
	// },
	// tableConfig: {
	// 	headersInside: true,
	// 	cellsWithoutLabels: true
	// }
});
