import AddType from './add';
import editValue from 'bus/edit';

import busData from 'bus/data';
import modalError from 'helpers/modals/error';

export default function openAddDialogue (parent) {
	return editValue.do({
		header: 'Добавление типа продукции',
		controlView: AddType,
		controlOptions: {
			parent
		},
		applyLabel: 'добавить тип',
		resetButton: false,
		cancelLabel: 'отставить'
	}).then(
		(data) => {
			return busData.productTypes().create(data, { preloader: true, wait: true }).then(
				() => {},
				(xhr) => modalError('неудалось добавить тип продукции', xhr)
			);
		},
		() => {}
	);
}
