import { _ } from 'vendors';
/*
import modals from 'helpers/modals';
import View from 'base/view';

const ImageModal = View.extend({
	template: _.noop,
	onRender(){
		let img = this.getImage();
		this.$el.append(img);
	},
	getImage(){
		return this.getOption('image');
	}
});
*/

export function pasteImageHandler (event, handler) {
	const clipboard = event.originalEvent.clipboardData;
	if (!clipboard || !clipboard.items) return;

	const items = clipboard.items;
	const images = _.reduce(items, (memo, item) => {
		if (!item.type.startsWith('image')) return memo;
		const blob = item.getAsFile();
		memo.push(blob);
		return memo;
	}, []);

	if (images.length) { handler(images); }

	return images;
}



// function processBlobImage(imageBlob)
// {
// 	let image = new Image();
// 	let URL = window.URL || window.webkitURL;
// 	image.src = URL.createObjectURL(imageBlob);

// 	modals.show(new ImageModal({ image }));

// }
