import viewClass from './block';
export default function create (context, model) {
	// if(!_.isModel(model)) return;
	context.createNested('process', {
		skipModels: true,
		viewClass,
		viewOptions () {
			// let viewModel = context.model.getProcess();
			return {
				model,
				actionsModel: context.model,
				refreshOnActionsModelEvents: ['change:processId']
			};
		}
	});
}
