import BaseModel from 'base/model';
// import Collection from 'base/collection';
// import busModels from 'bus/models';
// import enums from 'helpers/enums';
// import busData from 'bus/data';
import EditableProperties from 'mixins/editable-properties';
import { ensureCollectionModels } from './utils';
import busData from 'bus/data';
// import paths from 'helpers/paths';

export const PurchaseItemModel = BaseModel.mixWith(EditableProperties).extend({
	properties: {
		productionId: {
			controlType: 'productsSelect',
			edit: {
				applyValue (model, property, value) {
					if (!value) {
						value = { productionId: undefined, name: undefined, unitMeasureId: undefined };
					} else {
						const unitMeasureId = value.purchaseUnitMeasureId || value.unitMeasureId;
						// console.log('? ? ? ? ->', value, unitMeasureId);
						value = { productionId: value.id, name: value.shortName || value.fullName, fullName: value.fullName, unitMeasureId };
					}
					model.set(value);
				}
			},
			display: {
				label: 'продукция из каталога',
				transform: (v, opts, m) => {
					if (!v) return;
					if (m) {
						return m.get('name');
					}
					const col = busData.request('products:store');
					m = col.get(v);
					return (m && m.display('fullName')) || '';
				}
			}
		},
		amount: {
			modelType: 'single',
			multiple: false,
			valueType: 'number',
			display: {
				label: 'количество'
			}
		},
		newproduct: {
			display: {
				label: 'название, если отсутствует в каталоге'
			}
		},
		unitMeasureId: {
			sourceValues: () => busData.measures(),
			controlType: 'select',
			display: {
				label: 'единица измерения',
				transform: (v) => busData.measures(v, 'one')
			}
		}
	},
	validate () {
		const production = this.get('productionId');
		const newname = this.get('newproduct');
		const amount = parseInt(this.get('amount'), 10);
		const meassure = this.get('unitMeasureId');
		return (production || newname) && !isNaN(amount) && amount > 0 && meassure; // (!newname || (newname && meassure));
	}
});


export default BaseModel.mixWith(EditableProperties).extend({
	defaults () {
		return {
			type: 'purchaseRequest'
		};
	},
	getRawItems (nullable) {
		const items = this.get('items');
		return ensureCollectionModels(items, nullable);
	},
	getTodoString () {
		const items = this.getRawItems();
		const txt = items.map(item => (item.name || item.newproduct || ' &mdash; ') + ' x ' + item.amount).join('; ');
		return txt;
	}
});
