
import { _, $ } from 'vendors';
import { getYandexMap } from 'vendors/yandex-map';
import BaseView from 'base/view';
import Model from 'base/model';

export const MapView = BaseView.extend({
	renderOnModelChange: false,
	className: 'full-screen yandex-map',
	template: _.template(`
		<div></div>
		<section class="overlay-address-info">
			<header>Установите адрес с помощью строки поиска или кликнув мышкой в нужное место на карте</header>
			<div>
				<label>адрес: </label><span class="addr">&mdash;</span>
			</div>
			<div>
				<label>коорд: </label><span class="coord">&mdash;</span>
			</div>
			<button disabled>установить этот адрес</button>
		</section>
	`),
	initialize () {
		this.state = new Model();
		this.listenTo(this.state, 'change', this.onStateChange);
	},
	onStateChange () {
		let cls = this.getOption('className');
		const res = [cls];
		_.each(this.state.attributes, (val, key) => {
			if (!val) return;
			if (val === true) {
				val = key;
			}
			res.push(val.toLowerCase());
		});
		cls = res.join(' ').trim();
		if (!cls) {
			// this.el.className = '';
			this.$el.removeAttr('class');
		} else {
			// this.el.className = cls;
			this.$el.attr('class', cls);
		}
	},
	onRender () {
		const ymaps = getYandexMap();
		ymaps.ready(this.initializeMap.bind(this));
	},
	ui: {
		map: '> div',
		addr: '.addr',
		coord: '.coord',
		btn: '.overlay-address-info button'
	},
	events: {
		'click @ui.btn' (e) {
			if (this.ui.btn.prop('disabled')) return;
			if (!this.value || !_.isArray(this.value.coords)) {
				// console.log('invalid address');
				return;
			}
			this.triggerMethod('address', { address: this.value.address, lat: this.value.coords[0], lng: this.value.coords[1] });
		}
	},
	createPlaceMark (map, { coords, address } = {}) {
		const mark = new ymaps.Placemark(coords, {
			balloonContentHeader: address,
			balloonContentBody: coords.join(' '),
			balloonContentFooter: '',
			iconCaption: address || 'поиск...',
			hintContent: coords
		}, {
			preset: 'islands#violetDotIconWithCaption',
			draggable: true
		});
		mark.events.add('dragend', () => {
			this.getAddress(mark.geometry.getCoordinates(), mark);
		});
		map.geoObjects.add(mark);
		this.placemark = mark;
		this.setValue({ address, coords });
		return mark;
	},
	updatePlacemark ({ coords, address, setCoords } = {}) {
		setCoords && this.placemark.geometry.setCoordinates(coords);
		this.placemark.properties
			.set({
				iconCaption: address,
				hintContent: coords,
				balloonContentHeader: address,
				balloonContentBody: coords
			});
		this.setValue({ address, coords });
	},
	getAddress (coords, myPlacemark) {
		myPlacemark && myPlacemark.properties.set('iconCaption', 'поиск...');
		ymaps.geocode(coords).then((res) => {
			const firstGeoObject = res.geoObjects.get(0);
			const address = firstGeoObject.getAddressLine();
			myPlacemark.properties.set({
				iconCaption: address,
				balloonContentHeader: address,
				balloonContentBody: coords,
				hintContent: coords
			});
			this.setValue({ address, coords });
		});
	},
	setValue (value) {
		this.value = value;
		this.ui.addr.html(value.address);
		this.ui.coord.html(value.coords.join(' '));
		this.ui.btn.prop('disabled', false);
	},
	initializeMap () {
		// let myPlacemark;

		const myMap = new ymaps.Map(this.ui.map.get(0), {
			center: [55.76, 37.64],
			zoom: 7,
			controls: []
		});

		const mySearchControl = new ymaps.control.SearchControl({
			options: {
				// provider: 'yandex#search',
				noPlacemark: true
			}
		});
		myMap.controls.add(mySearchControl);
		mySearchControl
			.getLayout()
			.then(lay => {
				const yel = lay.getElement();
				const inp = $(yel).find('input');

				inp.get(0).addEventListener('focus', () => {
					this.state.set('searching', true);
				});
				inp.get(0).addEventListener('blur', () => {
					setTimeout(() => {
						this.state.set('searching', false);
					}, 500);
				});
			});


		mySearchControl.events.add('resultselect', (e) => {
			// this.state.set('searching', false);
			mySearchControl.getResult(e.get('index')).then(res => {
				const coords = res.geometry.getCoordinates();
				const address = res.getAddressLine();
				if (this.placemark) {
					this.updatePlacemark({ coords, address, setCoords: true });
				} else {
					this.createPlaceMark(myMap, { coords, address });
				}
			});
		});

		myMap.events.add('click', (e) => {
			const coords = e.get('coords');
			let mark;
			// Если метка уже создана – просто передвигаем ее.
			if (this.placemark) {
				mark = this.placemark;
				mark.geometry.setCoordinates(coords);
			} else {
				// Если нет – создаем.
				mark = this.createPlaceMark(myMap, { coords });
			}
			this.getAddress(coords, mark);
		});

		const address = this.getOption('address');
		let coords = this.getOption('coords');

		if (_.isString(coords)) {
			try {
				coords = JSON.parse(coords);
				if (coords.lat && coords.lng) {
					coords = [coords.lat, coords.lng];
				}
			} catch (e) {
				coords = undefined;
			}
		} else if (_.isObject(coords) && !_.isArray(coords)) {
			coords = [coords.lat, coords.lng];
		}

		if (_.isArray(coords)) {
			this.createPlaceMark(myMap, { coords, address });
			myMap.setZoom(16);
			myMap.setCenter(coords);
		}
	}
});

