export default (Base) => Base.extend({
	constructor: function () {
		Base.apply(this, arguments);
		this._initFetchExt();
		this.on('reset', this._onResetFetchExt);
	},
	_initFetchExt () {
		this.fetchingInProcess = false;
		this.fetchedTimes = 0;
	},
	fetch (...args) {
		const fetch = Base.prototype.fetch;
		this.trigger('before:fetch', ...args);
		this.fetchingInProcess = true;
		return fetch.apply(this, args)
			.then(
				(data) => {
					this.updateResult(data);
					this.fetchingInProcess = false;
					this.fetchedTimes || (this.fetchedTimes = 0);
					this.fetchedTimes++;
					this.postProcessFetchedData(data);
					this.trigger('fetch:success', data, this.result);
					this.trigger('fetch:complete', true, data, this.result);
					return Promise.resolve(data);
				},
				(xhr, ...failArgs) => {
					this.fetchingInProcess = false;
					this.trigger('fetch:complete', false, xhr, ...failArgs);
					return Promise.reject(xhr);
				}
			);
	},
	fetchIfNot (...args) {
		if (!this.fetchedTimes) {
			if (!this.currentFetching) {
				this.once('fetch:complete', () => delete this.currentFetching);
				this.currentFetching = this.fetch(...args);
			}
			return this.currentFetching;
		} else {
			return Promise.resolve();
		}
	},
	updateResult () {},
	postProcessFetchedData () {},
	isFetching () {
		return this.fetchingInProcess;
	},
	isNeverFetched () {
		return !(!!this.fetchedTimes || this.isFetching());
	},
	isFetched () {
		return !this.isNeverFetched();
	},
	_onResetFetchExt (data, options = {}) {
		if (options.reinit !== true) return;
		this._initFetchExt();
	}
});
