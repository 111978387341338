export const stateMixin = {
	_initializeState () {
		if (this._state) { return; }
		this._state = {};
		const state = this.getOption('initialState');
		if (state && typeof state === 'object') {
			this.state(state);
		}
		let method;
		if (this.updateClassName) {
			method = this.updateClassName.bind(this);
		} else if (this.refreshCssClass) {
			method = this.refreshCssClass.bind(this);
		}
		if (method) {
			this.on('state', method);
		}
	},
	state (arg1, arg2, arg3) {
		if (arguments.length === 1 && typeof arg1 !== 'object') {

			return this._getState(arg1);

		}

		return this._setState.apply(this, arguments);
	},
	_getState (key) {
		this._initializeState();
		const state = this._state;
		const value = state[key];
		if (typeof value === 'string') {
			return value;
		} else if (!value) {
			return '';
		} else {
			return key;
		}
	},
	_setState (arg1, arg2, arg3) {
		let hash;
		let options;

		if (typeof arg1 !== 'object') {
			hash = { [arg1]: arg2 };
			options = arg3;
		} else {
			hash = arg1;
			options = arg2;
		}

		const { silent } = (options || {});

		this._initializeState();

		const state = this._state;

		for (const key in hash) {
			const value = hash[key];
			state[key] = value;
			if (!silent) {
				this.triggerMethod('state:' + key, value);
			}
		}

		if (!silent) {
			this.triggerMethod('state', hash);
		}
	}
};
