import BasePage from 'pages/auth';
// import Layout from './layout';
import { BoardView } from 'components/board';

import ProcessModel from 'mods/processes/common/models/process';
import CardView from 'mods/processes/common/views/card-view';
// import SelectApi from 'components/controls/select-api';
// import Collection from 'mod/commonProcesses/models/processes';
import busModels from 'bus/models';
import paths from 'helpers/paths';

const Page = BasePage.extend({
	label: 'Доска процессов',
	route: 'board',
	// children:[Production],
	icon: 'board',
	Layout: BoardView,
	layoutOptions: {
		model: busModels.request('processes:board:instance'),
		fetchOnInitialize: true,
		boardConfig: {
			boardType: 'employeeProcesses',
			apiUrl: paths.api('processes:board'),
			entityConfig: {
				defaults: {
					modelType: 'process',
					parentEntityType: 'employee'
				},
				Model: ProcessModel,
				View: CardView
			},
			// addItemControl: SelectApi,
			addItemControlOptions: () => ({
				header: 'добавление процесса на доску',
				controlType: 'select-api',
				editOptions: {
					addClass: 'long-content'
				},
				controlOptions: {
					multiple: true,
					fetchAtStart: true,
					apiSearch: true,
					shouldReturnModel: true,
					collection: busModels.request('new:processes:search', { fetch: true })
				}
			})
		},
		className: 'board-processes'
	},
	hidden: true,
	wrapLayout: true
});

export default Page;
