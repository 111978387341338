import { BaseView } from '../base-view';
import { emptyViewMixin } from 'mixins/view/empty-view';


export const EmptyView = BaseView.extend({

	...emptyViewMixin,

	template: '<%= text %>',
	templateContext () {
		return {
			text: this.getEmptyText()
		};
	}
});
