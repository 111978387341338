import { $ } from 'vendors';
// const emptyValue = {};
export const getInputNumberValue = function (inputEl) {
	const $el = $(inputEl);
	const inputValue = $el.val();

	let val = (inputValue || '').replace(/,/, '.');

	if (val.length && val.endsWith('.')) { val = val.substring(0, val.length - 1); }

	const normalizedValue = val;

	let numberValue = normalizedValue.length ? parseFloat(normalizedValue, 10) : undefined; // || undefined;

	if (numberValue != null && isNaN(numberValue)) { numberValue = undefined; }

	return {
		inputValue,
		normalizedValue,
		numberValue
	};
};


const numPat = /\d/;
const dotPat = /[,.]/;

export const preventNonNumberTextInput = function (e) {
	const key = e.key;
	if (key == null || key.length !== 1) { return; }

	const num = numPat.test(key);
	const dot = !num && dotPat.test(key) && !dotPat.test((e.target.value || ''));
	const allowed = num || dot;
	if (!allowed) {
		e.preventDefault();
	}
};
