import View from 'base/view';
import { BatchLinesView } from './batch-lines-view';
import smartOpen from 'helpers/smart-open';
// import busData from 'bus/data';

import { productPageCardUrl } from 'app/constants';

const compiledComatexts = {};

function normalizeComaSpace (text, prefix = '') {
	if (text == null || text === '') { return; }

	const storeKey = prefix + text;

	if (text.indexOf(',') === -1) { return prefix + text; }

	let compiledText = compiledComatexts[storeKey];
	if (compiledText == null) {
		let chunks = text.split(/\s*,\s*/g);
		if (prefix) {
			chunks = chunks.map(chunk => prefix + chunk);
		}
		compiledText = chunks.join(' ').trim();
		compiledComatexts[storeKey] = compiledText;
	}
	return compiledText;
}

export const ProductLineView = View.extend({
	className: 'product-line-item',
	cssClassModifiers: [
		(m) => normalizeComaSpace(m.get('origin'), 'origin-'),
		(m) => normalizeComaSpace(m.get('purpose'), 'purpose-')
	],
	template: `
        <div class="product-info ui2-molecule">
            <div class="icon caret"><i></i></div>
            <div class="icon origin"><i></i></div>
            <div class="icon purpose"><i></i></div>
            <!--<div class="batches-count fixed ui2-atom">
                <div><span><%= (batches || []).length %></span><span class="fa fa-caret-down"></span></div>
            </div>-->
            <div class="ui2-atom main">
                <div><%= name %> <sup>(<%= (batches || []).length %>)</sup></div>
            </div>
            <div class="fixed open-page" title="открыть страницу продукции в новой вкладке"><button><i class="fa fa-sign-in"></i></button></div>
        </div>
        <div class="batches-container"></div>
    `,
	regions: {
		batches: '.batches-container'
	},
	onRender () {
        // console.log('-product-render-');
		const view = new BatchLinesView({ model: this.model, filterModel: this.getOption('filterModel') });
		this.showChildView('batches', view);
	},
	events: {
		'click .open-page' (event) {
			event.stopPropagation();
			const url = productPageCardUrl + '/' + this.model.id + '/store/logs';
			// busData.request('product:page:url') + '/' + this.model.id + '/store/logs';
			smartOpen(url, { ctrlKey: true });
		},
		'click .product-info' () {
			this.$el.toggleClass('collapsed');
		}
	}
    // modelEvents: {
    //     'batch:newAmount'() {
    //         console.log('-new amount-', arguments);
    //     }
    // }
});
