import Model from 'base/model';
import mix from 'helpers/mix';
import NestedEntitiesV2 from 'mixins/model/nested-entities-v2';
import { EntriesLogsCollection } from './backend-logs-collection';
import user from 'app/user';

export const SmartLogModel = mix(Model).with(NestedEntitiesV2).extend({
	logModelType: 'smart',
	initialize (data, options) {
		Object.assign(this, options);
		const entries = this.getEntries();
		entries.url = this.commentsUrl;

		const model = this;
		this.listenTo(entries, 'remove', () => {
			if (entries.length === 0) {
				if (model.collection) {
					model.collection.remove(model);
				}
				model.destroy();
				model.off();
			}
		});
	},
	addEntry (entry, opts = {}) {
		const { reverseOrder = false, silent, preprocess } = opts;
		const col = this.entity('entries');
		const options = reverseOrder ? { at: 0, silent } : undefined;
		entry.urlRoot = col.url;
		col.add(entry, options);
		if (!preprocess) {
			col.trigger('new:item', this, opts);
		}
		return this;
	},
	getEntries () {
		return this.entity('entries');
	},
	getFirstEntry () {
		return this.getEntries().first();
	},
	getLastEntry () {
		return this.getEntries().last();
	},
	isComment () {
		const first = this.getFirstEntry();
		return first.get('type') === 'comment';
	},
	nestedEntities: {
		entries: EntriesLogsCollection
	},
	isMy () {
		const entry = this.getFirstEntry();
		return user.isMe(entry.get('actorId'));
	},
	evenOrOdd () {
		return !!this.get('evenOrOdd');
	}
});
