import Collection from 'base/collection';
import model from './day-model';
export default Collection.extend({
	model,
	parse (data) {
		this.first = data.first;
		this.last = data.last;
		return data.days;
	},
	initialize (data, opts = {}) {
		this.valueModel = opts.valueModel;
		this.listenTo(this.valueModel, 'refresh', this.refresh);
	},
	refresh () {
		this.invoke('trigger', 'refresh');
	}
});
