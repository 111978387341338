import BasePage from 'pages/auth';

import { ReportLayout, getReportSchema, ReportCollection } from 'mods/reports';

// import Emps from './emps';
// import Deps from './deps';

export const ReportPage = BasePage.extend({
	route () { return this.reportType; },
	icon () { return this.reportType; },
	// name() { return this.reportType + ' reports page'; },
	initialize () {
		this.name = this.reportType + ' reports page';
	},
	Layout: ReportLayout,
	layoutOptions () {
		const options = this.getOption('reportLayoutOptions') || {};

		return {
			collection: new ReportCollection(null, { type: this.reportType }),
			reportSchema: getReportSchema(this.reportType),
			...options
		};
	}
});

