import { Model, Collection, Router, View as BackboneView } from 'backbone';
import { View, CollectionView, NextCollectionView, Region, Object, Application, Behavior, VERSION, Events } from 'backbone.marionette';
console.log('-MN VER-', VERSION, Events);

const MyNextCollectionView = NextCollectionView.extend({
	filterChildView (view, { force } = {}) {
		const viewFilter = this._getFilter();
		if (force || (viewFilter && !viewFilter(view))) {
			this._detachChildView(view);
		}
	}
});



export {
	Model, Collection, Router, BackboneView,
	View, CollectionView, MyNextCollectionView as NextCollectionView, Region, Object, Application, Behavior
};
