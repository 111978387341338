import BasePage from 'pages/auth';
import store from './store';
import products from './products';
import contragents from './contragents';
import sources from './sources';
// import Deps from './deps';

const Page = BasePage.extend({
	name: 'reports page',
	label: 'Отчеты',
	route: 'reports',
	children: [store, products, /* sources, */ contragents],
	icon: 'reports'
});

export default Page;
