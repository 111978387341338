import { BaseBlockLayout } from '../base-block-layout';

import { BaseHeaderView } from 'base/base-view';

import { BlockFooterTextAction } from 'components/ui2';
import { getConcretesCollection } from './concretes-collection';
import { ProductsListView } from './products-list-view';
import { UiBlockView } from 'components/ui2/block';

export const ConcretesBlockLayout = UiBlockView.extend({
	getCollection () {
		return getConcretesCollection(this.model);
	},
	canManuallyAdd () {
		return this.model.concretesCanBeManuallyAdded();
	},
	wholeColumn: true,
	doubleWidth: true,
	header: 'список продукции в группе',
	actionInFooter: 'add:concrete:product',
	content: v => ({
		class: ProductsListView,
		model: v.model,
		collection: v.getCollection()
	})
	// customViews () {
	// 	const defs = {
	// 		model: this.model, collection: this.getCollection()
	// 	};



	// 	// поиск по номеру партии...
	// 	return [
	// 		new BaseHeaderView({ text: 'список продукции в группе' }),
	// 		// buildTabsHeader('concretes', this.ac, true),
	// 		// new JustInputView({
	// 		// 	...defs,
	// 		// 	placeholder: 'поиск по номеру партии...',
	// 		// 	onChange (text) {
	// 		// 		this.collection.trigger('id:contains', text);
	// 		// 	}
	// 		// }),
	// 		// new PartiesHeaders(defs),
	// 		new ProductsListView({
	// 			className: 'elastic-content',
	// 			...defs
	// 		}),
	// 		// new AmountView(defs),
	// 		// user.checkRights({ productsStore: 'acceptProducts' })
	// 		this.canManuallyAdd()
	// 			? new BlockFooterTextAction({ text: 'добавить продукцию', clickAction: 'add:product:click' })
	// 			: null
	// 	];
	// },
	// childViewEvents: {
	// 	'add:concrete:product:click' () {
	// 		// this.model.executeAction('add:concrete:product', this.getCollection());
	// 	}
	// }
});
