import CollectionView from 'base/collection-view';
import childView from './list-item';
import comparator from 'helpers/comparator';
export default CollectionView.extend({
	tagName: 'ul',
	childView,
	emptyView: childView.Empty,
	viewComparator (v1, v2) {
		return comparator(
			[v2, v1, (m) => m.get('isPrimary')],
			[v1, v2, (m) => m.display('shortName')]
		);
	},
	collectionEvents: {
		change: 'sort'
	}
	// childViewEvents:{
	// 	'remove:detail'(detail){
	// 		this.model.executeAction('remove:detail',)
	// 	}
	// }
});
