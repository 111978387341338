import BasePage from 'pages/auth';
import Administrators from './administrators/';
import Groups from './groups/';
import Configs from './configs';
import Requests from './requests';
import LoginAs from './login-as';

const adminChildren = [Requests, Groups, Administrators, Configs];

adminChildren.push(LoginAs);

const Page = BasePage.extend({
	moduleRights: { admin: 'view' },
	order: 900,
	id: 'admin',
	name: 'admin page',
	label: 'Администрирование',
	route: 'admin',
	icon: 'admin',
	children: adminChildren
});

export default Page;
