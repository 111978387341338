import { _ } from 'vendors';
import { BaseCollectionView } from 'base/collection-view';
import View from 'base/view';
import UiCardBlock from 'components/ui-card-block/block';
import ListItem from 'components/ui-list/item';
// import OrdererBehavior from 'behaviors/orderer';
import { SwappableBehavior } from 'behaviors/sortable';
import editValue from 'bus/edit';
import ControlMixin from 'components/controls/mixin';

import PlaceholderedInput from 'components/controls/input-placeholdered';
import { PlaceholderedTextarea } from 'components/controls/textarea';
// import moment from 'moment';
import smartDate from 'helpers/date/smart';
import busData from 'bus/data';
import smartOpen from 'helpers/smart-open';

const EditToDoItem = ControlMixin(View).extend({
	template: _.noop,
	initialize () {
		this.prepareInitialValues();
		this.value = this.getOption('value');
		this.createNested('name', {
			viewClass: PlaceholderedInput,
			viewOptions: {
				controlName: 'text',
				proxyTo: this,
				value: this.getValue().text,
				label: 'пункт'
			}
		});
		this.createNested('comment', {
			viewClass: PlaceholderedTextarea,
			viewOptions: {
				controlName: 'comment',
				proxyTo: this,
				value: this.getValue().comment,
				label: 'комментарий'
			}
		});
	},
	onRender () {
		this.showAllNested();
	},
	controlsEvents: {
		'text:change' (value) {
			this.value.text = value;
			this.triggerChange();
		},
		'text:done' (value) {
			this.value.text = value;
			this.triggerChange();
		},
		'comment:change' (value) {
			this.value.comment = value;
			this.triggerChange();
		},
		'comment:done' (value) {
			this.value.comment = value;
			this.triggerChange();
		}
	}
});

const ToDoItem = ListItem.extend({
	renderOnModelChange: true,
	tagName: 'li',
	isSwapEnabled () {
		return !!this.getOption('canBeSwapped');
	},
	isEditEnabled () {
		return this.checkRights('change');
	},
	hasProduct() {
		return this.model.get('productId') != null;
	},
	text: m => m.get('text'),
	smallText: m => m.get('comment'),
	bottomText: (m, v) => v.lastChanges(),
	// m => m.isChecked() && m.get('last') ? smartDate.main(m.get('last'))  : '', //moment(m.get('last')).fromNow()
	lastChanges () {
		const m = this.model;
		if (!m.isChecked()) return '';

		const last = m.get('last');
		if (!last) return '';
		return _.map([
			smartDate.main(m.get('last')),
			busData.employeeNameOrMe(m.get('employeeId'), '', 'я')
		], item => `<span>${item}</span>`).join('');
	},
	leftButtons: [
		(m, v) => v.isEditEnabled() ? { class: 'checked', icon: true } : ''
	],
	rightButtons: [
		// m => !m.isChecked() ? { class:'edit', icon: true } : null,
		(m, v) => v.hasProduct() ? 'product' : undefined,
		(m, v) => v.isSwapEnabled() ? 'orderer' : undefined,
	],
	cssClassModifiers: [
		'check-list-item',
		(m, v) => v.isEditEnabled() ? 'whole-btn' : '',
		m => m.getCheckedState()
	],
	addEvents: {
		'click .checked' (e) {
			e.stopPropagation();
			if (!this.checkRights('change')) return;
			this.model.toggleChecked();
			// .set('checked', !this.model.get('checked'));
		},
		'click .product' (e) {
			e.stopPropagation();
			const prodId = this.model.get('productId');
			if (!prodId) { return; }
			const url = '/res/prod/products/' + prodId;
			smartOpen(url, e);
		},
	},
	onTextClick (v1, v2, event) {
		if (!this.checkRights('change')) return;
		const remove = this.checkRights('delete')
			? {
				resetButton: true,
				resetLabel: 'удалить',
				resetButtonCssClass: 'delete-button'
			}
			: {};

		let options = {
			header: 'изменение пункта',
			value: this.model.toJSON(),
			applyLabel: 'сохранить',
			cancelLabel: 'отставить',
			editOptions: {
				controlView: EditToDoItem
			}
		};

		options = _.extend({}, options, remove);

		const model = this.model;
		const col = model.collection;
		event.stopPropagation();

		editValue.do(options).then(value => {
			this.model.applyChanges(_.omit(value, 'id'));
			col.trigger('edited');
		},
		(reject) => {
			if (!this.checkRights('delete')) return;
			if (reject.reason === 'reset') {
				col.remove(model);
				col.trigger('edited');
			}
		});
	},
	checkRights (type) {
		const rights = this.getOption('rights') || {};
		return !!rights[type];
	}
});

const ToDoList = BaseCollectionView.extend({
	renderAllCustoms: false,
	tagName: 'ul',
	childView: ToDoItem,
	emptyView: ListItem.extend({ text: '... пусто ...' }),
	behaviors: [SwappableBehavior.extend({ selector: '.orderer', property: 'index', swapOptions: { silent: true } })], // [OrdererBehavior],
	initialize () {
		const takedIndexes = {};
		const missedModels = [];
		const freeIndexes = [];
		this.collection.each(model => {
			const index = model.get('index');
			if (_.isNumber(index)) {
				takedIndexes[index] = true;
			} else {
				missedModels.push(model);
			}
		});
		if (!missedModels.length) return;
		for (let x = 0; x < this.collection.length; x++) {
			if (!takedIndexes[x]) {
				freeIndexes.push(x);
			}
		}
		_.each(missedModels, model => {
			model.set('index', freeIndexes.pop(), { silent: true });
		});
	},
	viewComparator (v1, v2) {
		return v1.model.get('index') - v2.model.get('index');
	},
	onInteractionEnd (view, changed) {
		if (!changed || !changed.length) {
			return;
		}
		this.collection.trigger('change');
		this.collection.trigger('edited');
	},
	childViewOptions () {
		return {
			rights: this.getOption('rights'),
			canBeSwapped: this.getOption('canBeSwapped', { force: false })
		};
	}
});

export default UiCardBlock.extend({

	header: 'заметки',
	content: ToDoList,
	contentOptions () {
		return {
			collection: this.model.getCheckList(),
			rights: this.getOption('rights', { force: false }),
			canBeSwapped: this.getOption('canBeSwapped', { force: false })
		};
	},
	action: 'add:check:item'
});
