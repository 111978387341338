import { _, $, BackboneModel, BackboneCollection, BackboneView, BackboneRouter, MnObject } from 'vendors';

const simples = [
	Date, String, Number, Boolean
];

export function isJq (v) {
	if (v == null) return false;
	if (v instanceof $) return true;
    // return v.jquery && v.length > 0; <- ???
}

export function isNotJq (v) {
	return !isJq(v);
}

export function isNotValue (v) {
	return v == null;
}

export function isValue (v) {
	return v != null;
}

export function isSimpleValue (v) {
	const type = typeof v;

	if (type === 'function') { return false; }

	const issimple = v == null || type !== 'object' || simples.some(simple => v instanceof simple);

	return issimple;
}

export function isEmpty (v) {
	if (isNotValue(v)) return true;
	const type = typeof v;
	if (type === 'function') return false;
	if (type === 'string' && v.trim() === '') return true;
	if (Array.isArray(v) && v.length === 0) return true;
	if (type === 'object') return _.size(v) === 0;
	return false;
}

export function isObject(arg) {
	return arg && typeof arg === 'object';
}

export function isEmptyObject (v) {
	if (typeof v !== 'object') {
		throw new Error('isEmptyObject accepts only objects');
	}
	return Object.keys(v).length === 0;
		// _.size(v) === 0;
}

export function isNotEmpty (v) {
	return !isEmpty(v);
}

export function isString(arg) {
	return typeof arg === 'string';
}

export function isFunction(arg) {
	return typeof arg === 'function';
}

export function isModel (arg) {
	return arg instanceof BackboneModel;
}

export function isView (arg) {
	return arg instanceof BackboneView;
}

export function isCollection (arg) {
	return arg instanceof BackboneCollection;
}

function isClass (arg, ctor) {
	return arg && arg.prototype && (arg === ctor || arg.prototype instanceof ctor);
}

export function isModelClass (arg) {
	return isClass(arg, BackboneModel);
}

export function isViewClass (arg) {
	return isClass(arg, BackboneView);
}

export function isNotFalsyValue (value, ...exclude) {
	if (exclude.length && exclude.indexOf(value) > -1) {
		return true;
	}
	return !!value;
}


export const knownCtors = [
	BackboneModel,
	BackboneCollection,
	BackboneView,
	BackboneRouter,
	MnObject
];

export function isKnownCtor (arg) {
	return arg && knownCtors.some(ctor => isClass(arg, ctor));
}
