import { _ } from 'vendors';
// import Yat from 'marionette.yat';

// const isKnownCtor = Yat.Helpers.isKnownCtor;
import { Model, Collection, Router } from 'backbone';
import { View, CollectionView, Application, Object, Region } from 'backbone.marionette';

export const knownCtors = [Model, Collection, Router, View, CollectionView, Application, Object, Region];

function isKnownCtor (arg) {
	const isFn = _.isFunction(arg);
	const result = _(knownCtors).some((ctor) => arg === ctor || arg.prototype instanceof ctor);
	return isFn && result;
}

export default isKnownCtor;
