// import Collection from 'base/collection';
import CollectionView from 'base/collection-view';
import childView from 'mod/processes/common/views/list-item-view';
import busModels from 'bus/models';

export const ProcessesListView = CollectionView.extend({
	initialize () {
		const Collection = busModels.request('processes:paged');
		this.collection = new Collection([], { state: this.getOption('state') });
        // console.log(' -- PROCS COLL --', this.collection);
		this.collection.fetch({ data: { 'productid.v': this.model.id, 'noactor.v': true } });
	},
	childView
});
