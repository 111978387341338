
import { UiMoleculeView } from 'components/ui2/molecule';

export const CommonListItemView = UiMoleculeView.extend({
	proxyChildEvents: false,
	thisClassName: 'ui2-box-item ui2-list-item-margined with-borders',
	stateClassNames: ['selected'],

	selectButton: true,

	// content: {
	// 	text: v => v.model.get('name')
	// },

	initialize () {
		this.initializeBefore();
		this.initializeSelected();
		// this.on('all', (e, ...rest) => console.warn(e, rest));
	},
	initializeBefore () { },
	initializeSelected () {
		this.selector = this.getOption('selector', true);
		const event = this.selector.isSelected(this) ? 'selected' : 'unselected';
		this.triggerMethod(event);
	},
	childViewEvents: {
		'select:click' (v, e = {}) {
			e.stopPropagation();
			const { shiftKey } = e;
			this.trigger('select:click', this, { shiftKey });
		}
	},
	onSelected () {
		this.state('selected', true);
	},
	onUnselected () {
		this.state('selected', false);
	}
});
