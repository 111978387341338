import { _, moment } from 'vendors';
import Model from 'base/model';
import mix from 'helpers/mix';
import Actionable from 'mixins/actionable';
import action from 'helpers/action';
// import BbStore from 'base/bb-store';
import busModels from 'bus/models';

const BaseNotify = Model.extend({
	constructor () {
		Model.apply(this, arguments);
		if (!_.isDate(this.get('date'))) { this.set('date', new Date(), { silent: true }); }
		this.updateViewedTimer();
		this.updateDestroyTimer();
		this.on('change:viewedDelay', this.updateViewedTimer);
		this.on('change:destroyDelay', this.updateDestroyTimer);
		// this.initViewedDelay();
	},
	destroy () {
		this._isDestroyed = true;
		clearTimeout(this.viewedTimer);
		clearTimeout(this.destroyTimer);
		return Model.prototype.destroy.apply(this, arguments);
	},
	isDestroyed () {
		return !!this._isDestroyed;
	},
	updateViewedTimer () {
		clearTimeout(this.viewedTimer);
		const delay = this.getViewedDelay();
		if (!this.isViewed() && delay > 0) {
			this.viewedTimer = setTimeout(() => this.triggerViewed(), delay);
		}
	},
	updateDestroyTimer () {
		clearTimeout(this.destroyTimer);
		const delay = this.getDestroyDelay();
		if (!this.isDestroyed() && delay > 0) {
			this.destroyTimer = setTimeout(() => this.triggerDestroy(), delay);
		}
	},
	// initViewedDelay(){
	// 	let delay = this.getViewedDelay();
	// 	if (delay > 0) {
	// 		this.viewedTimer = setTimeout(() => this.triggerViewed(), delay);
	// 	}
	// },

	getViewedDelay () {
		if (this.isViewed()) { return; }

		let delay = this.get('viewedDelay');
		if (delay == null) { delay = 10000; }

		if (delay > 0 && delay <= 30) { delay = delay * 1000; }

		return delay;
	},
	getDestroyDelay () {
		let delay = this.get('destroyDelay');

		if (delay > 0 && delay <= 30) { delay = delay * 1000; }

		return delay;
	},

	triggerViewed () {
		const store = this.get('store');
		if (store === false) {
			this.trigger('trigger:destroy');
		} else {
			this.trigger('trigger:viewed');
		}
	},
	triggerDestroy () {
		this.trigger('trigger:destroy');
	},
	setViewed () {
		this.set('viewed', true);
	},
	parse (data) {
		const di = Date.info(data.date);
		data.date = (di.valid && di.date) || new Date();
		return data;
	},
	isViewed () {
		return this.get('viewed');
	},
	shouldBeStored () {
		return this.get('store') !== false;
	},
	getTopText () {
		const date = this.get('date');
		return `<span>${moment(date).fromNow()}</span> в <span>${moment(date).format('HH:mm DD MMM')}</span>`;
	},
	getType () {},
	getEventType () {},
	getName () {},
	getText () {},
	getSmallText () {},
	getBottomText () {},
	getClickAction () {}
});


const SocketEvent = mix(BaseNotify).with(Actionable).extend({


	actions: [
		action('open:card')
	],
	getClickAction: () => 'open:card',
	actionOpenCard () {
		const modelName = this.get('dataName');
		const id = this.get('dataId');
		const Model = busModels.getModel(modelName);
		// BbStore.get(modelName);
		if (Model == null) return;
		const model = new Model({ id });
		this.set('viewed', true);
		if (model.executeAction) { return model.executeAction('open:card'); }
	},


	getEventType () {
		const event = this.get('event');
		if (event === 'comment') { return event; } else if (event.startsWith('task')) { return 'task'; } else { return 'common'; }
	},
	getType () {
		const event = this.get('event') || '';
		return event;
	},
	getName () {
		const event = this.get('event') || '';
		return event;
	},



	getText () {
		const event = this.get('event');
		switch (event) {
		case 'taskChecking':
			return 'задача отправлена на проверку';
		case 'taskChecked':
			return 'задача возвращена в работу';
		case 'comment':
			return this.get('data.text');
		case 'taskCreated':
			return 'поступила новая задача';
		case 'taskResponsibleChanged':
			return 'у задачи поменялся ответственный';
		default:
			return event;
		}
	},
	getSmallText () {
		const event = this.get('event');
		const name = this.get('dataLabel') || this.get('dataId');

		switch (event) {
		case 'taskChecking':
		case 'taskChecked':
		case 'taskCreated':
		case 'taskResponsibleChanged':
			return name;
		case 'comment':
			return this.getCommentText();
		default:
			return '';
		}
	},

	getBottomText () {
		return this.getActorName();
	},


	getActorName () {
		return this.get('actor.name');
	},
	getCommentText () {
		const html = (name, type) => `<i class="i-${(type || 'no-icon')}"></i><span>${name}</span>`;
		const name = this.get('data.task.name');
		if (name) { return html(name, 'task'); }

		return '';
	}


});



const SystemEvent = BaseNotify.extend({
	isNew: () => true,
	initialize () {
		this.overrideViewdDelay();
	},
	overrideViewdDelay () {
		let delay = this.get('viewedDelay');
		if (delay == null && this.get('type') === 'wait') {
			delay = 0;
		}
		this.set('viewedDelay', delay, { silent: true });
	},
	getType: () => 'system',
	getName () { return this.get('type'); },
	getText () { return this.get('text'); }
});


export {
	SocketEvent,
	SystemEvent,
	BaseNotify as BaseEvent
};
