import { _ } from 'vendors';
// import Yat from 'marionette.yat';
// import Converters from './converters';
import BaseModel from 'base/model';
// const BaseModel = Yat.Model;


const Model = BaseModel.extend({
	urlKeys: {
	},
	initialize (attrs, options) {
		this.valueType = _.result(options, 'valueType');
	},
	parse (data) {
		if (!data.id && this) {
			data.id = _.uniqueId(this.type);
		}
		if (this && data.valueType) {
			this.valueType = data.valueType;
			delete data.valueType;
		}
		return data;
	},
	hasValue () {
		const v = this.getValue();
		return v != null;
	},
	toUrl (_key, index) {
		const key = index ? `${_key}[${index}]` : _key;
		const result = {};
		_(this.urlKeys).each((_url, prop) => {
			let value = this.get(prop);
			const url = key + '.' + _url;
			if (value != null) {
				if (typeof value === 'object') {
					value = JSON.stringify(value).replace(/"/gmi, '');
				}
				// console.log('value ->', value);
				result[url] = value;
			}
		});
		if (_.size(result)) { return result; }
	}
	/* _normalizeUrlHashOptions(opts) {
		var options = _.extend({}, opts);
		var prefix = options.prefix ? options.prefix : '';
		var indexedPrefix = options.index ? prefix + '[' + options.index + ']' : prefix;
		var rdyPrefix = indexedPrefix !== '' ? indexedPrefix + '.' : '';
		options.prefix = rdyPrefix || '';
		return options;
	},
	normalizeValue(value){
		return value == null || value === '' ? undefined : value;
	},
	getConverted(name, converter) {
		return this.convertValue(this.get(name), converter);
	},
	convertValue(value, converter){
		value = this.normalizeValue(value);
		converter || (converter = Converters[this.valueType]);
		if(_.isFunction(converter))
			return converter(value);
		else
			return value;
	},
	toValue(){
		let json = this.toJSON();
		let keys = _(json).keys();
		_(keys).each((key) => {
			if(json[key] == null || json[key]=='')
				delete json[key];
		});
		return json;
	},
	toUrlHash(opts){
		let json = this.toValue();
		let res = {};
		_(json).each((value, name) => {
			name = name in this.urlKeys ? this.urlKeys[name] : name;
			res[name] = this.convertValue(value, opts.converter);
		});
		return res;
	} */
});

export default Model;
