import DefaultPage from './default';
import PageErr from 'pages/error';
import user from 'app/user';

const AnonPage = DefaultPage.extend({
	preventStart: function () {
		if (user.isAuthenticated()) { return PageErr.Redirect(''); }
	}
});

export default AnonPage;
