import BaseLayout from '../base-layout';

import { UsedInProductionBlockLayout } from './block-layout';

export const UsedInProductionLayout = BaseLayout.extend({
	usedInProdContents () {
		return [
			new UsedInProductionBlockLayout({ model: this.model, ac: this.actionContext })
		];
	}
});

