import SearchCollectionView from 'base/search-collection-view';
import comparator from 'helpers/comparator';
import getLabel from 'helpers/get-label';
import BaseCollectionView from 'base/collection-view';
import UserInList from 'mod/users/views/list-item';

const UsersListNcv = BaseCollectionView.extend({
	tagName: 'ul',
	initialize () {
		const admins = this.getOption('admins');
		if (!admins) return;
		this.listenTo(admins, 'update', this.render);
	},
	emptyView: UserInList.Empty,
	childView: UserInList.extend({
		tagName: 'li',
		cssClassModifiers: [
			() => 'one-line'
		],
		plusButton: true
	}),
	childViewEvents: {
		'plus:click' (model) {
			model.executeAction('add:to:admins', { admins: this.getOption('admins') });
		}
	},
	viewFilter (v) {
		const admins = this.getOption('admins');
		return !admins.has(v.model.id);
	},
	viewComparator (v1, v2) {
		return comparator(v1, v2, (m) => getLabel(m));
	}
});

const UsersList = SearchCollectionView.extend({
	// myName:'ditrih',
	collectionView: UsersListNcv,
	collectionViewOptions () {
		return {
			admins: this.getOption('admins')
		};
	}
});
export default UsersList;
