import { BatchTransferLogLayoutView } from 'components/batch-transfer-log';

import { commonTableLayoutMixin } from './table-layout-mixin';


export const BatchLogsLayout = BatchTransferLogLayoutView.extend({
	...commonTableLayoutMixin

});

