export default (Base) => Base.extend({
	constructor () {
		// this.on('render', this.triggerHeader);
		Base.apply(this, arguments);
	}
	// triggerHeader(){
	// 	this.trigger('header:change',this.getHeader());
	// },
	// getHeader(){
	// 	return this.getDefaultHeader() || this.getOption('header');
	// },
	// getDefaultHeader(){
	// 	return this.model && this.model.display('name');
	// }
});
