import paths from 'helpers/paths';

paths.set('api', 'processes', 'processes');
paths.set('api', 'processes:board', 'defaultBoard', { relative: true });

paths.set('url', 'processes', 'procs');
paths.set('api', 'processes:purchaseRequest', 'purchase-request', { relative: true });
paths.set('api', 'processes:goals', 'goals', { relative: true });
paths.set('url', 'processes:start', 'start', { relative: true });
paths.set('url', 'processes:active', 'active', { relative: true });
paths.set('url', 'processes:completed', 'completed', { relative: true });
paths.set('url', 'processes:all', 'all', { relative: true });
paths.set('url', 'processes:board', 'board', { relative: true });
