import { moment } from 'vendors';

import View from 'base/view';
import { InlineControlMixin } from '../common/inline-mixin';
import editValue from 'bus/edit';

export const InlineDaterangeControl = View.extend({
	template: '<label><%= label %></label><div class="control-value"><%= valueText %></div>',

	...InlineControlMixin,

	getValueLabel () {
		if (!this.value) {
			return 'не указан';
		}

		const chunks = [];
		let splitter = ' ';
		if (this.value.from && this.value.to) {
			chunks.push(moment(this.value.from).format('DD.MM.YY'));
			chunks.push(moment(this.value.to).format('DD.MM.YY'));
			splitter = ' &mdash; ';
		} else if (!this.value.from) {
			chunks.push('до');
			chunks.push(moment(this.value.to).format('DD.MM.YY'));
		} else {
			chunks.push('от');
			chunks.push(moment(this.value.from).format('DD.MM.YY'));
		}
		return chunks.join(splitter);
	},

	setValue (val) {
		this.value = val;
		this.render();
		this.trigger('control:change', this.value);
	},

	_delegateControlEvents () {
		this.delegate('click', '.control-value', () => {
			editValue.do({
				header: 'укажите период',
				asPromise: true,
				modelType: 'range',
				valueType: 'date',
				value: this.value
                // modalOptions:{
                //     addBodyCssClass: false,
                // },
                // addBodyCssClass: false,
                // editOptions:{
                //     resetButton:false,
                //     cancelLabel: 'отставить',
                //     applyLabel: 'добавить',
                //     beforeApply: (text, opts) => text && this._addComment(text, opts),
                // },
			}).then(
				(x) => {
					this.setValue(x);
				},
				(x) => {
					if (x.reason === 'reset') {
						this.setValue(undefined);
					}
				}
			);
		});
	},

	templateContext () {
		const label = this.getLabel();
		const valueText = this.getValueLabel();
		return {
			label,
			valueText
		};
	}
});
