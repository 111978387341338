import { _ } from 'vendors';
import Bb from 'backbone';

function isBb (arg) {
	return arg instanceof Bb.Model;
}
function testValue (src, val) {
	src = (src || '').toString().toLowerCase();

	return src.indexOf(val) > -1;
}

function testProperty (what, property, value, context, passedPath = '', opts = {}) {
	const path = (passedPath || '') + property;

	if (opts.isBb) {
		const bbvalue = opts.origin.get(path, { nested: true });
		if (isBb(bbvalue)) { 
			return isAnyPropertyContains(bbvalue, what); 
		} else if (_.isSimple(value)) {
			if (testValue(bbvalue, what, path)) {
				// console.warn('#match', what, path, bbvalue);
				return true;
			} else {
				const dvalue = opts.origin.display(path);
				const res = testValue(dvalue, what, path);
				// if (res) {
				// 	console.warn('#match', what, path, dvalue);
				// }
				return res;
			}
		} else if (_.isObject(value)) {
			passedPath = path + '.';
			return isAnyPropertyContains(value, what, passedPath, opts);
		}
		console.warn('#no simple match');
	} else if (isBb(context)) {
		return isAnyPropertyContains(context, what);
	} else {
		if (_.isSimple(value)) {
			return testValue(value, what, path);
		} else if (_.isObject(value)) {
			passedPath = path + '.';
			return isAnyPropertyContains(value, what, passedPath, opts);
		}
	}
}


export default function isAnyPropertyContains (context, what, passedPath = '', opts = {}) {
	if (!_.isObject(context)) return;
	if (what == null) return;

	what = what.toLowerCase();
	if (opts.origin == null) {
		opts.origin = context;
		opts.isBb = isBb(context);
	}
	const lookIn = isBb(context) ? context.attributes : context;
	let debugProp;
	let debugValue;
	const res = _.some(lookIn, (value, name) => {
		const propres = testProperty(what, name, value, context, passedPath, opts);
		if (propres) {
			debugValue = value;
			debugProp = name;
		}
		return propres;
	});

	// if (res) {
	// 	console.warn('#match', what, debugProp, debugValue);
	// } else {
	// 	console.log('no-match', what);
	// }

	return res;

	// return _(lookIn).some((value, name) => {
	// 	return testProperty(what, name, value, context, passedPath, opts);
	// 	/*
	// 	let path = (opts.path || '') + name;
	// 	if(value == null) return;

	// 	if(_.isSimple(value)){
	// 		let found = testValue(value, what);
	// 		if(found || !isBb) return found;
	// 		return testValue(context.display(path), what);
	// 	}else if(_.isObject(value)){
	// 		opts.path = path + '.';
	// 		return isAnyPropertyContains(value, what, opts);
	// 	} */
	// });
}
