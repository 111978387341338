import CollectionView from './checks';
import View from 'base/view';
import mix from 'helpers/mix';
import ControlsMixin from 'components/controls/mixin';
import Values from 'components/values';
import { _ } from 'vendors';

export default mix(View).with(ControlsMixin).extend({
	className: 'buttons-group show-selected-icon',
	template: _.template('<div></div>'),
	regions: {
		buttons: { el: 'div', replaceElement: true }
	},
	initialize () {
		this.initValues();
	},
	initValues () {
		this.initialValues = [];
		let value = this.getOption('value');
		if (value == null) return;

		// let original = value;

		if (_.isString(value)) { value = value.split(/\s*,\s*/gmi); }

		if (!_.isArray(value)) { value = [value]; }

		// value = _(value).map((v) => _.toBoolean(v));

		this.initialValues = value;


		// if(_.isArray(value))
		// 	this.initialValues = value;
		// else if(_.isString(value) && value.indexOf(',')>-1)
		// 	this.initialValues = value.split(/\s*,\s*/gmi);
		// else
		// 	this.initialValues = [value];
	},
	onRender () {
		this.showButtons();
	},
	buildCollection () {
		const values = this.getOption('sourceValues');
		const valueType = this.getOption('valueType');
		return Values.collection(values, { valueType });
	},
	getCollection () {
		if (this.collection) { return this.collection; }
		this.collection = this.buildCollection();

		return this.collection;
	},
	showButtons () {
		const collection = this.getCollection();
		const customViews = this.getOption('customViews', { force: false });

		const view = this.buttons = new CollectionView({
			collection,
			type: this.getOption('type'),
			name: this.cid,
			initialValues: this.initialValues || [],
			customViews
		});
		this.showChildView('buttons', view);
	},
	getValue () {
		let values = this.buttons.getValue();
		if (!this.isMultiple()) { values = values[0]; }
		return values;
	},
	childViewEvents: {
		'change' () {
			this.triggerChange(this.getValue());
		}
	}
});
