import { _ } from 'vendors';
import ModelProperties from 'components/ui-model-card/properties';
import h from 'components/ui-model-card/helpers';
// import Model from './model';

// import modalError from 'helpers/modals/error';
// import PromiseButtons from 'components/promise-buttons/mixin';
import mix from 'helpers/mix';


import ControlsMixin from 'components/controls/mixin';

// function initModel(model, parent){
// 	if(model) {
// 		this.initialModel = model.toJSON();
// 		return;
// 	}
// 	this.model = new Model(null, { parent });
// }

const defs = { editButton: true, addClass: 'whole-btn', textAction: 'edit' };

// const PromisedProps = mix(ModelProperties).with(PromiseButtons);

export default mix(ModelProperties).with(ControlsMixin).extend({
	// constructor(opts){
	// 	initModel.call(this, this.model || opts.model, this.parent || opts.parent);
	// 	PromisedProps.apply(this, arguments);
	// 	this.on('render', this.validate);
	// },
	// asPromise: true,
	readyOnRender: true,
	initialize () {
		if (!this.model && _.isModel(this.getOption('value'))) {
			this.model = this.getOption('value');
		}
	},
	className: 'card lined with-border create-task',
	// header:{ text: 'основное', addClass:'bg-main-dark'},

	properties: [
		h.ui('shortName', _.clone(defs)),
		h.ui('legalAddress', _.clone(defs)),
		h.ui('mailAddress', _.clone(defs)),
		h.ui('inn', _.clone(defs)),
		h.ui('kpp', _.clone(defs)),
		h.ui('okpo', _.clone(defs)),
		h.ui('bik', _.clone(defs)),
		h.ui('account', _.clone(defs)),
		h.ui('correspondentAccount', _.clone(defs)),
		h.ui('bankName', _.clone(defs)),
		h.ui('phone', _.clone(defs)),
		h.ui('email', _.clone(defs)),
		h.ui('person', _.clone(defs)),
		h.ui('isPrimary', _.clone(defs)),
		h.ui('note', _.clone(defs))
	],

	getValue () {
		return this.model.toJSON();
	},
	validate () {
		const value = this.getValue();
		return !!value.shortName;
	},
	modelEvents: {
		'change' () {
			this.triggerChange();
		}
	}
	// getNestedActionContext(){
	// 	return this.getPromiseButtonsContext();
	// },

	// validateResolve(){
	// 	let errors = this.model.validate();
	// 	if (!errors) {
	// 		return true;
	// 	}

	// 	modalError('Необходимо кое-что исправить:', errors);

	// 	return false;
	// },

	// resolveResult: (v) => v.model,
	// cancelResult: (v) => v.initialModel,


	// modelEvents:{
	// 	'change':'onModelChange',
	// },

	// onModelChange(){
	// 	this.validate();
	// },
	// validate(){



	// 	let condition = true;
	// 	if(condition) {
	// 		this.trigger('switch:resolve','unlock');
	// 	} else {
	// 		this.trigger('switch:resolve','lock');
	// 	}
	// }
});
