import BaseCollection from './base-collection';
import PagedCollection from 'mixins/paged-collection';
import mix from 'helpers/mix';
import paths from 'helpers/paths';

import bus from 'bus/models';
import busData from 'bus/data';
import { initializeCollectionGuidNames } from '../../../../../utils/guidNames';

export const SearchProducts = BaseCollection.extend({
	constructor: function() {
		BaseCollection.apply(this, arguments);
		initializeCollectionGuidNames(this, model => model.getName());
	},
	urlPattern: paths.api('res:production:products:search'),

});

const store = new SearchProducts([]);

export const searchProductsCollection = store;

busData.reply('products:store', () => store);

bus.reply('products:search', () => {
	return SearchProducts;
});

bus.reply('new:products:search', ({ fetch } = {}) => {
	const col = store;
	if (fetch) {
		col.fetch();
	}
	return col;
});

busData.reply('from:products:search', id => {
	return busData.request('fetched-store', store.model, id);
});

export default mix(BaseCollection).with(PagedCollection).extend({
	urlPattern: paths.api('res:production:products')
});
