module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<button class="c-dt-go-left"><i></i></button>\n<span class="c-dt-c-month">'+
((__t=( monthName ))==null?'':__t)+
'</span>\n<span class="c-dt-c-year">'+
((__t=( year ))==null?'':__t)+
'</span>\n<button class="c-dt-go-right"><i></i></button>\n';
}
return __p;
};
