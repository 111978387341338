import $ from 'jquery';
import Mn from 'backbone.marionette';
import _ from 'underscore';
import App from './YatApp';
import GetNameLabel from './mixins/get-name-label';
import mixin from './helpers/mix';
import identity from './singletons/identity';
import YatError from './YatError';



const PMRouterMixin = {

	routerClass: Mn.AppRouter,

	createRouter(opts = {}){		
		let options = this.getRouterOptions();
		let Router = this.getProperty('routerClass');
		let router = new Router(options);

		if(opts.doNotSet !== true)
			this.setRouter(router);

		return router;
	},

	createRouteContext(pattern, url){
		return this.router && this.router.createRouteContext(pattern, url);
	},
	_createRoutesHash(opts = {}){

		if(this._routesHash && opts.rebuild !== true) return this._routesHash;

		let hash = {};

		let routes = this.getChildrenRoutes();

		_.extend(hash, ...routes);

		this._routesHash = hash;

		return hash;

	},
	getChildrenRoutes(){
		let children = this.getChildren();
		let routes = [];
		_(children).each((page) => {
			if(_.isFunction(page.getRoutes)){
				let pageRoutes = page.getRoutes();
				Array.prototype.push.apply(routes, pageRoutes);
			}
		});
		return routes;
	},
	getRouterOptions(){
		let hash = this._createRoutesHash();
		let appRoutes = {};
		let controller = {};
		_(hash).each((handlerContext, key) => {
			appRoutes[key] = key;
			controller[key] = this.createRouterControllerAction(handlerContext, key)
		});
		return { appRoutes, controller };
	},
	createRouterControllerAction(handlerContext, key){
		let page = handlerContext.page;
		return (...args) => {
			if(handlerContext.action)
				return handlerContext.action(handlerContext, ...args);
			else
				return this.processRoute(handlerContext, ...args);
		};
	},
	setRouter(router){
		if(this.router && _.isFunction(this.router.destroy)){
			this.router.destroy();
		}
		this.router = router;
	},
	getRouter(){
		return this.router;
	},
	processRoute(context, routeData, ...args){
		//console.log('PROCESS ROUTE: ', arguments);
		routeData.rawRoutePattern = context.id;
		routeData.route = context.route;
		routeData.page = context.page;
		return this.startPage(context.page, routeData, ...args);
	},
	execute(route, routeData = {}){
		let page = this.getPage(route);
		_.extend(routeData, { startType:'execute' })
		if(page)
			page.start(routeData);
		else
			throw new YatError.NotFound('Route not found'); 
	},
	navigate(url, opts = {trigger:true}){

		let router = this.getRouter();

		if(router)
			router.navigate(url, opts);
		//else
			//console.warn('router not found');

	},

	navigateToRoot(page){

		let current = page || this.getState('currentPage');
		if(!current) throw new YatError({message:"navigateToRoot: root not found"});
		this.navigate(current.getRoot().url());

	},	

};





let Base = mixin(App).with(GetNameLabel, PMRouterMixin);

let YatPageManager = Base.extend({
	constructor(...args){
		Base.apply(this, args);
		this._initializeYatPageManager(...args);
	},
	_initializeYatPageManager(opts = {}){
		this.mergeOptions(opts, ['id','name','label']);
		this._registerPageHandlers(opts);
		//this._registerIdentityHandlers();
		this.createRouter();
	},

	throwChildErrors:true,


	startPage(page, routeData, ...args){
		this._lastStart = {
			page, routeData, args: args || []
		};

		return page.start(routeData, ...args).then(
			() => {}, 
			(error) => {
				//console.log('catching error');
				if(this.getProperty('throwChildErrors') === true){				
					throw error;
				}
				routeData || (routeData = {});			
				this._processStartError(page, routeData, error);
			}
		);

	},

	createErrorEvent(page, name, ...args){
		name && (name = ':' + String(name));
		!name && (name='');
		let error = {
			event: `error${name}`,
			args: [page].concat(args || [])
		};
		return error;
	},
	_processStartError(page, routeData = {}, error){
		routeData.startError = error;
		let errorEvents = [this.createErrorEvent(page, null, routeData)];
		let customErrors = this.processStartError(page, routeData, error);
		customErrors && !_.isArray(customErrors) && (customErrors = [customErrors]);
		this.triggerErrors(errorEvents.concat(customErrors || []));
	},
	processStartError(page, routeData, error){
		let errors = [];
		if(error.status){
			errors.push(this.createErrorEvent(page, error.status, routeData));
		}
		return errors;
	},
	triggerErrors(errorEvents){
		//console.log('triggering errors', errorEvents);
		_(errorEvents).each((errorEvent) => {

			this.triggerMethod(errorEvent.event, ...(errorEvent.args || []));
		});
	},
	restartRoutedPage(){
		if(!this._lastStart) return;
		let ls = this._lastStart;
		this.startPage(ls.page, ls.routeData, ...ls.args);
		//this.routedPage && this.startPage(this.routedPage, );
	},	
	getLinks(){
		let children = this.getStartableChildren();
		if(!children) return [];
		return _(children).chain()			
			.map((child) => child.getLinks())
			.filter((child) => !!child)
			.flatten()
			.value();
	},
	getPage(key){

		let found = _(this._routesHash)
			.find((pageContext, route) => {
				if(route === key) return true;
				if(pageContext.page.getName() === key) return true;
			});
		if(found)
			return found.page;

		return this.findChild((child) => child.matchUrl(key));
		
	},
	getCurrentPage(){
		return this.getState('currentPage');
	},
	isCurrentPage(page){
		let current = this.getCurrentPage();
		return page === current;
	},

	getStartableChildren(){
		return this.getChildren({
			filter: (c) => !c.getProperty('preventStart')
		});
	},	


	//childrenable: settle manager reference to all children
	_buildChildOptions: function(def){
		return _.extend(def, this.getProperty('childOptions'), {
			manager: this
		});
	},	

	_registerPageHandlers(){
		this.on('page:before:start', this._pageBeforeStart);
		this.on('page:start', this._pageStart);
		this.on('page:start:decline', this._pageDecline);
	},

	_pageBeforeStart(page){
		let current = this.getState('currentPage');
		if(current && current != page){
			current.stop();
		}
	},

	_pageStart(page, ...args){
		this.setState('currentPage', page);
	},
	_pageDecline(...args){ },



	


});

export default YatPageManager;
