import { _ } from 'vendors';
export const InlineControlMixin = {

	initialize () {
		this._initializeControl();
	},

	_initializeControl () {
		this.value = this.getOption('value');
		this.initValue = this.getOption('value');
		this._delegateControlEvents();
	},
	_delegateControlEvents () {},

	getLabel () {
		return this.getOption('label');
	},

	_buildControlAttrs () {
		const attrs = Object.assign({}, this.getOption('controlAttrs'));
		const name = this.getOption('controlName');
		if (name) {
			attrs.name = name;
		}
		const cssClass = this.getOption('controlCssClass');
		if (cssClass) {
			attrs.class = cssClass;
		}
		return this._buildControlAttrsString(this.buildControlAttrs(attrs));
	},

    // override this if need
	buildControlAttrs (attrs) {
		return attrs;
	},

	_buildControlAttrsString (hash) {
		if (!hash) return '';
		let textAttrs = _.reduce(hash, (memo, value, key) => {
			if (value == null || value === '') { return memo; }
			memo.push(key + '="' + value + '"');
			return memo;
		}, []).join('&');
		if (textAttrs) {
			textAttrs = ' ' + textAttrs;
		}
		return textAttrs;
	}
};
