import { _ } from 'vendors';
export default (Base) => Base.extend({
	constructor(data) {
		Base.apply(this, arguments);
		this.updateResult(data);
	},
	parse(data) {
		this.updateResult(data);
		if (_.isArray(data)) {
			return data;
		} else {
			return data.items;
		}
	},
	updateResult(data) {
		if (!this.result) { return; }
		let hash;
		if (!data || _.isArray(data)) {

			const founded = data ? data.length : 0;
			hash = {
				skiped: undefined,
				founded,
				returned: founded,
				endOfData: true
			};
			// console.log('= SIMPLE =', hash);
		} else {
			hash = {
				skiped: data.skiped,
				founded: data.founded,
				returned: data.returned,
				endOfData: data.endOfData
			};
		}
		this.result.set(hash);
	}
});
