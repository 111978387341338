import { _, $ } from 'vendors';
import MnObject from 'base/object';
import Model from 'base/model';

export const Uploader = MnObject.extend({

	// #region OPTIONS
	filesPerSession: 1,
	maximumSessions: 5,
	// #endregion

	upload (options = {}) {
		this._triggerInvoke('start', options, [options]);
		const { blobs, url } = options;
		if (!blobs || !blobs.length) {
			return this.rejectUpload('no:files', options);
		}
		if (!url) {
			return this.rejectUpload('no:url', options);
		}

		this._triggerInvoke('before:sessions', options, [options]);
		const sessions = this._createSessions(blobs, options);
		const promise = this._uploadSessions(sessions, options);

		return promise.then(
			data => this.resolveUpload(data, sessions, options),
			xhr => this.rejectUpload(xhr, sessions, options)
		);
	},

	_createSessions (blobs, options) {
		const sessions = [];
		const sessionBlobs = [];
		const filesPerSession = this.getOption('filesPerSession');
		_.each(blobs, (blob) => {
			sessionBlobs.push(blob);
			if (sessionBlobs.length === filesPerSession) {
				const session = this.createSession(_.clone(sessionBlobs), options);
				sessions.push(session);
				sessionBlobs.length = 0;
			}
		});
		return sessions;
	},

	_uploadSessions (sessions = [], options) {
		if (!sessions || !sessions.length) { return Promise.resolve(); }

		const maximumSessions = this.getOption('maximumSessions');
		let index = 0;
		const taked = [];
		while (sessions.length) {
			taked.push(sessions.shift());
			index++;
			if (index === maximumSessions) { break; }
		}
		const promises = _.map(taked, session => this.uploadSession(session, options));
		_.each(sessions, session => this._triggerInvoke('session:awaiting', options, [session, options]));
		return Promise.all(promises).then(() => this._uploadSessions(sessions, options));
	},

	// #region UploadSession
	_normalizeBlobsSession (arg, options) {
		let session;
		if (_.isArray(arg)) {
			session = this.createSession(arg, options);
		} else {
			session = arg;
		}
		return session;
	},
	uploadSession (arg, options = {}) {
		const session = this._normalizeBlobsSession(arg, options);
		this._triggerInvoke('before:session', options, [session, options]);
		return this.sendUploadRequest(session, options);
	},
	createSession (blobs, options, sessionMemo = {}) {
		const formData = new FormData();

		let { data, getFormKey } = options;

		this._addFormData(formData, data);
		_.extend(sessionMemo, data, { id: _.uniqueId('us'), files: [] });

		if (!getFormKey) {
			getFormKey = (blob, index) => `files[${index}]`;
		}
		const names = {};
		_.each(blobs, (blob, index) => {
			const name = this._getBlobName(blob, index, names);
			names[name] = 1;
			const formKey = getFormKey(blob, index, name);
			formData.append(formKey, blob, name);
			sessionMemo.files.push(blob);
		});
		sessionMemo.formData = formData;
		sessionMemo.date = new Date();
		// sessionMemo.fileNames = _.keys(names);
		return sessionMemo;
	},
	_addFormData (formData, data) {
		if (!data) return;
		if (_.isFunction(data)) {
			data = data();
		}
		if (!_.isObject(data)) return;

		_.each(data, (value, key) => {
			formData.append(key, value);
		});
	},
	_getBlobName (blob, index, names) {
		let name = blob.name;
		if (!name) {
			name = 'file_' + index;
		}
		if (name in names) {
			const chunks = name.split('.');
			if (chunks.length === 1) {
				name += '_' + index;
			} else {
				const ext = chunks.pop();
				name = `${chunks.join('.')}_${index}.${ext}`;
			}
		}
		return name;
	},

	sendUploadRequest (session, options = {}) {
		const formData = session.formData;
		const model = new Model();
		const promise = model.save(null, {
			url: options.url,
			wait: true,
			data: formData,
			cache: false,
			contentType: false,
			processData: false,
			xhr: () => {
				const xhr = $.ajaxSettings.xhr();
				xhr.onprogress = event => this._triggerInvoke('download:progress', options, [event, session, options]);
				xhr.upload.onprogress = event => this._triggerInvoke('upload:progress', options, [event, session, options]);
				xhr.upload.onload = event => this._triggerInvoke('upload:complete', options, [event, session, options]);
				return xhr;
			}
		}).then(
			data => this.resolveSession(data, session, options),
			xhr => this.rejectSession(xhr, session, options)
		);
		return promise;
	},



	// #endregion



	// #region Promises helpers

	_invoke (method, args = []) {
		if (_.isFunction(method)) { return method(...args); }
	},

	_triggerInvoke (event, options = {}, args) {
		const method = _.camelCase(event);
		this._invoke(options[method], args);
		this.triggerMethod(event, ...args);
		if (options.proxyEventsTo) {
			this._proxyTriggerInvoke(options.proxyEventsTo, event, args);
			// let proxy = options.proxyEventsTo.triggerMethod || options.proxyEventsTo.trigger;
			// proxy && proxy.call(options.proxyEventsTo, event, ...args);
		}
	},
	_proxyTriggerInvoke (target, event, args) {
		if (!target) return;
		if (_.isArray(target)) {
			const uniques = [];
			_.each(target, child => {
				if (uniques.indexOf(child) >= 0) return;
				uniques.push(child);
				this._proxyTriggerInvoke(child, event, args);
			});
		} else {
			const method = target.triggerMethod || target.trigger;
			method && method.call(target, event, ...args);
		}
	},
	resolveSession (data, session, options = {}) {
		this._triggerInvoke('session', options, [data, session, options]);
		return Promise.resolve(data);
	},
	rejectSession (reason, session, options = {}) {
		const { shouldReject } = options;
		this._triggerInvoke('session:fail', options, [reason, session, options]);
		if (shouldReject) {
			return Promise.reject(reason);
		} else {
			return Promise.resolve();
		}
	},
	rejectUpload (reason, sessions, options = {}) {
		this._triggerInvoke('fail', options, [reason, sessions, options]);
		this._triggerInvoke('end', options, [sessions, options]);
	},
	resolveUpload (data, sessions, options) {
		this._triggerInvoke('success', options, [data, sessions, options]);
		this._triggerInvoke('end', options, [sessions, options]);
		return Promise.resolve(data);
	}
	// #endregion

});

export const fileUploader = new Uploader();


export function upload (options = {}) {
	return fileUploader.upload(options);
}
