import BaseView from 'pages/view';
import ModelProperties from 'components/ui-model-card/properties';
import h from 'components/ui-model-card/helpers';

// import createLogs from 'components/logs/views/create-nested';
// import busData from 'bus/data';

// import UiCardBlock from 'components/ui-card-block/block';



export default BaseView.extend({
	className: 'measures-page',
	template: BaseView.emptyTemplate,
	initialize () {
		const model = this.model;


		this.createNested('main', {
			viewClass: ModelProperties.extend({ className: 'card lined with-border' }),
			viewOptions: {
				header: { text: 'основное', addClass: 'bg-main-dark' },
				properties: [
					h.ui('one', { editButton: model.mainInfoCanBeChanged() }),
					h.ui('few', { editButton: model.mainInfoCanBeChanged() }),
					h.ui('many', { editButton: model.mainInfoCanBeChanged() }),
					h.ui('short', { editButton: model.mainInfoCanBeChanged() })
				]
			},
			regionTemplate: { replaceElement: true }
		});

		// this.createNested('subtypes',{
		// 	viewClass: Subtypes,
		// 	viewOptions:{
		// 		model,
		// 		header: 'подтипы'
		// 	}
		// });
	},
	onRender () {
		this.showAllNested();
	}
});
