import { _ } from 'vendors';
import BaseCollection from 'base/collection';
import model from './contact-value-model';
import cmp from 'helpers/comparator';

const comparator = (m1, m2) => cmp(
	[m1, m2, (m) => m.getTypeOrder()],
	[m2, m1, (m) => m.get('primary')],
	[m2, m1, (m) => m.get('order')]
);

const ValuesCollection = BaseCollection.extend({
	model,
	comparator,
	initialize (data, opts = {}) {
		this._initializeParent(opts.parent);
	},
	_initializeParent (parent) {
		this.parent = parent;
		if (this.parent == null) {
			throw Error('Initialize EntityContactValues collection error, parent not provided');
		}
		this._onParentIdChange();
		this.listenTo(this.parent, 'change:id', this._onParentIdChange);
	},
	_onParentIdChange () {
		this.parentId = this.parent.id;

		if (_.isFunction(this.parent.url)) { this.url = this.parent.url() + '/values'; }
	},
	hasAnyContactValue () {
		return this.filter((f) => f.hasValue()).length > 0;
	}
});

export default ValuesCollection;
