import { _ } from 'vendors';
import Yat from 'marionette.yat';
import ViewMng from 'helpers/nested-view-manager';
import DynamicClass from 'mixins/view/dynamic-class';
// import { classNameMixin } from 'mixins/view/className';
import mix from 'helpers/mix';
import busViews from 'bus/views';
import { commonViewMixin } from 'mixins/view/common-view';
import { isView } from 'utils/is';

const Base = mix(Yat.View).with(DynamicClass);

const View = Base.extend({
	constructor () {
		// this._initDomPromise();
		Base.apply(this, arguments);
		if (this.getOption('renderOnModelChange') && this.model) {
			this.listenTo(this.model, 'change', this.rerender);
		}
		if (this.isInModal()) { this.onModalIn(); }

		this.initializeTagTitle();

		// if (this.options.tagTitle || this.tagTitle) {
		// 	const title = this.getOption('tagTitle', { args: [this.model || this, this] });
		// 	if (title) {
		// 		this.el.title = title;
		// 	}
		// }
		this.initializeClassNameMixin();
	},
	...commonViewMixin,
	getTemplate () {
		if (this._compiledTemplate) {
			return this._compiledTemplate;
		}

		const template = this.getOption('template', false);
		if (typeof (template) === 'string') {
			this._compiledTemplate = _.template(template);
		} else {
			this._compiledTemplate = template;
		}
		return this._compiledTemplate;
	},
	renderOnModelChange: false,
	// _initDomPromise(){
	// 	if(this.domPromise) return;

	// 	this.domPromise = new Promise((resolve) => {
	// 		this.once('dom:refresh', () => resolve());
	// 	});

	// },
	rerender () {
		if (this.isAttached()) {
			// console.log('#rerender', this.model && this.model.attributes);
			return this.render();
		} else {
			// console.log('#rerender fails', this.model && this.model.attributes);
		}
	},
	isInModal () {
		return isView(this.getOption('inModal'));
	},
	onModalIn () {
		this.$el.addClass('in-modal');
	},
	onModalOut () {
		this.$el.removeClass('in-modal');
	},
	createNested (name, opts) {
		return new ViewMng(_.extend({}, opts, {
			name,
			context: this
		}));
	},
	showAllNested (opts) {
		_(this._nestedViews).each((nested) => nested.show(opts));
	},
	getOption (key, options) {
		if (options == null || typeof options === 'boolean') {
			const invoke = options == null ? true : !!options;
			options = { invoke, force: invoke };
		}
		if (!options.args) {
			options.invokeArgs = this;
		}
		return Base.prototype.getOption.call(this, key, options);
	},
	hasOption (key) {
		return this.options[key] !== undefined;
	}

});

View.mixWith = function () {
	return mix(this).with(...arguments);
};

View.emptyTemplate = _.template('');

busViews.setView('BaseView', View);

export default View;
