// import { Collection } from './Collection';
import childView from './ListItemView';
import { ListView } from '../common';



export default ListView.extend({

	// Collection,
	collectionAddUrl: 'tasks/active',
	childView

});
