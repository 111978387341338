// import CollectionView from 'base/collection-view';
// import { PartiesListView, PartiesHeaders, AmountView } from './parties';
import { getPagedData, TransactionsView } from './logs';
// import user from 'app/user';
import { buildTabsHeader } from './tabs';
import { /* BlockFooterTextAction, */ JustInputView } from 'components/ui2';
import { BaseBlockLayout } from '../base-block-layout';
// import { PagedData } from '../../../../../../base/models';

export const HistoryBlockLayout = BaseBlockLayout.extend({
	// getCollection() {
	// 	return getLogsCollection(this.model);
	// },
	customViews () {
		const pd = getPagedData(this.model);
		const defs = {
			model: this.model,
			pagedData: pd
		};
		return [
			buildTabsHeader('logs', this.ac),
			new JustInputView({
				placeholder: 'поиск по номеру партии...',
				onChange (text) {
					pd.setFilter('batchIdString', text);
					// this.collection.trigger('id:contains', text);
				}
			}),
			new TransactionsView(defs)
		];
	}
	// childViewEvents: {
	// 	'add:party:click'() {
	// 		this.model.executeAction('add:store:party', this.getCollection());
	// 	}
	// }
});
