import { UiSchemaMoleculeView } from 'components/ui2/molecule';
import { batchSchema } from '../models/batchSchema';

export const ListItemView = UiSchemaMoleculeView.extend({
	thisClassName: 'batch',
	modelSchema: batchSchema,
	content: {
		thisClassName: 'prod elastic',
		text: v => v.display('idString'),
		showSmall: true,
		smallText: v => v.display('product.fullName'),
		topText: v => v.display('origin'),
	},
	rightItems: [
		{
			thisClassName: 'amount fixed',
			text: v => v.display('baseAmountPostfix'),
			topText: 'на складе'
		},
		{
			thisClassName: 'created fixed',
			topText: 'создана',
			text: v => v.display('created'),
			//bottomText: v => v.display('created')
		}
	]
});
// UiSchemaMoleculeView.extend({});
