import { _ } from 'vendors';
import { HamburgerView } from 'base/view-hamburger';
import View from 'base/view';
import { Ui2AtomView } from '../../../components/ui2';
import modals from 'helpers/modals';
import { confirmReason } from 'helpers/modals/confirm';
import messageModal from 'helpers/modals/message';
import Model from 'base/model';

const FilterSetItemView = Ui2AtomView.extend({
	className: 'ui2-atom clickable filter-set-item',
	initialize () {
		this.filterModel = this.getOption('filterModel');
		this.listenTo(this.filterModel, 'change:modelsSet', this.onChangeModelsSet);
        // this.filterModel.on('all', (e) => console.log('-f:'+e+'-'));
		this.onChangeModelsSet();
	},
	onChangeModelsSet () {
		const thisSetName = this.getOption('filterSetName');
		const modelSetName = this.filterModel.get('modelsSet');

		if (thisSetName === modelSetName) {
			this.$el.prop('disabled', true);
			this.$el.addClass('active');
		} else {
			this.$el.prop('disabled', false);
			this.$el.removeClass('active');
		}
	},
	getText () {
		return this.filterModel.getModelsSetCount(this.getOption('filterSetName'), this.collection);
	},
	events: {
		'click' (e) {
            // let oldVal = this.filterModel.get('modelsSet');
			const filterSetName = this.getOption('filterSetName');
			this.filterModel.set('modelsSet', filterSetName);
		}
	}
});


const SaveButtonView = View.extend({
	tagName: 'button',
	template: _.template('<%= label %>'),
	templateContext () {
		return {
			label: this.getOption('label')
		};
	},
	events: {
		click (e) {
			if (this.$el.prop('disabled') || this.$el.hasClass('disabled')) {
				e.preventDefault();
				return;
			}
			console.log('-inbutton-');
			this.triggerMethod('save:click');
		}
	}
});

export const ActionsItemsView = HamburgerView.extend({
	className: 'ui2-molecule actions-items',
    // initialize() {
    //     this.collection.on('all', e => console.log('-c-', e));
    // },
	collectionEvents: {
		'batch:newAmount' () {
			this.render();
		}
	},
	customViews () {
		const filterModel = this.getOption('filterModel');
		const views = [];

		const filtersButtons = _.map(filterModel.modelsSets, (cfg, id) => {
			return new FilterSetItemView({
				topText: cfg.label,
				text: 0,
				filterSetName: id,
				filterModel,
				collection: this.collection
			});
		});

		views.push(...filtersButtons);



		views.push(new SaveButtonView({
			label: 'сохранить',
			filterModel,
			collection: this.collection,
			onSaveClick: () => {
				this.handleSaveClick();
			}
		}));

		return views;
	},
	handleSaveClick () {
		// const filterModel = this.getOption('filterModel');
		const hasChanges = this.collection.some(model => model.hasNewAmounts());
		if (hasChanges) {
			confirmReason('Подтвердите сохранение изменений', {
				defaultValue: 'массовая периодическая ручная корректировка',
				label: 'комментарий транзакций'
			}).then((comment) => {
				const batches = this.collection.reduce((taked, prod) => {
					const newams = prod.batches.filter(b => b.has('newAmount')).map(m => _.pick(m.attributes, 'id', 'productId', 'newAmount'));
					taked.push(...newams);
					return taked;
				}, []);

				const url = _.result(this.collection, 'url') + '/new';
				const attrs = { comment, amounts: batches };
				const model = new Model();
				model.url = url;
				model.save(attrs, { wait: true, preloader: true })
					.then((changed) => {
						this.triggerMethod('after:save');
						console.log('-very-ok-', changed);
					}, () => {
						modals.show({
							header: 'ошибка',
							text: '<p>что-то пошло не так, изменения не сохранены</>',
							type: 'full',
							show: {
								resolve: true,
								reject: false
							}
						});
					});
				console.log('posted:', attrs);
			});
		} else {
			messageModal('Изменения отсутсвуют, нечего соохранять');
            // modals.show({
            //     header:'',
            //     text: '<p>изменения отсуствуют</p>',
            //     // blockResolve: true,
            //     // content:  new Layout({
            //     //     model: new Model()
            //     // }),
            //     // asPromise: true,
            //     // returnPromise: true,
            //     type:'full',
            //     show:{
            //         resolve:true,
            //         reject:false,
            //     },
            //     // labels:{
            //     //     resolve:'далее',
            //     //     reject: 'отставить'
            //     // }
            // });
		}
	}

});
