import modals from './base-modals';

modals.addTypeConfig('message', {
	css: {
		wrapper: 'yat-modal-wrapper message'
	},
	show: {
		bg: true,
		close: true
	},
	options: {
		closeOnClickOutside: true,
		closeOnPromise: true,
		preventClose: false,
		asPromise: false
	}

});
modals.addTypeConfig('message-flex', {
	css: {
		wrapper: 'yat-modal-wrapper flex-modal message'
	},
	show: {
		bg: true,
		close: true
	},
	options: {
		closeOnClickOutside: true,
		closeOnPromise: true,
		preventClose: false,
		asPromise: false
	},
	modifiers: {
		'after:render': { },
		refresh: { }
	}

});

export default function modalMessage (text, header) {
	return modals.show({
		text,
		header,
		type: 'message'
	});
}
