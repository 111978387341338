import { _ } from 'vendors';
import flat from 'helpers/flat';
import validateError from './error';


export default function validate (hash, rules = {}) {
	if (!_.size(rules)) return;

	const flatHash = flat(hash);
	const errors = [];



	_(rules).each((fieldRule, key) => {
		const value = flatHash[key];
		if (!fieldRule) return;

		if (fieldRule.required && (value == null || value === '')) { errors.push(validateError('required', fieldRule.required.message, key, fieldRule)); }

		_(fieldRule.validators).each((validator) => {
			if (!_.isFunction(validator.func)) return;
			if (!validator.func(value, hash)) { errors.push(validateError('validator', validator.message, key, fieldRule)); }
		});
	});

	if (!errors.length) return;

	return errors;
}
