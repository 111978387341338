import proto from 'components/values/get-prototype';
import enums from 'helpers/enums';

const employeeSystemStatuses = {
	false: 'включен',
	true: 'отключен'
};
const employeeVirtualStatuses = {
	false: 'сотрудник',
	true: 'виртуальный (без пользователя)'
};

const filtersPrototypes = [
	{
		id: 'employees:text',
		valueId: 'text',
		label: 'поиск по ключевому слову',
		hidden: true,
		pinned: true,
		textSearch: true,
		valuePrototype: proto('text')
	},
	{
		id: 'employees:status',
		valueId: 'status',
		label: 'статус сотрудника',
		hidden: false,
		pinned: true,
		valueLabel: (val) => enums.get('employeeStatuses', val),
		values: [{ value: 'intern, regular' }],
		valuePrototype: () => proto('enum', {
			multiple: true,
			sourceValues: () => enums.store.employeeStatuses
		})
	},
	{
		id: 'employees:disabled',
		valueId: 'disabled',
		label: 'системный статус',
		hidden: false,
		pinned: true,
		multiple: true,
		values: [{ value: false }],
		valueLabel: (val) => employeeSystemStatuses[val] || '',
		valuePrototype: {
			controlType: 'select',
			modelType: 'single',
			valueType: 'boolean',
			multiple: true,
			sourceValues: employeeSystemStatuses
		}
	},
	{
		id: 'employees:virtual',
		valueId: 'isVirtual',
		label: 'тип сотрудника',
		hidden: false,
		pinned: true,
		multiple: true,
		values: [{ value: false }],
		valueLabel: (val) => employeeVirtualStatuses[val] || '',
		valuePrototype: {
			controlType: 'select',
			modelType: 'single',
			valueType: 'boolean',
			multiple: true,
			sourceValues: employeeVirtualStatuses
		}
	}
];

export default filtersPrototypes;
