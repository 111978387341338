import { _, moment } from 'vendors';
import BaseView from 'base/view';
export default BaseView.extend({
	className: 'c-dt-month-weekdays',
	template: _.template('<%= weekdays %>'),
	templateContext () {
		let weekdays = '';
		for (let x = 0; x < 7; x++) { weekdays += '<b>' + moment().weekday(x).format('dd') + '</b>'; }
		return {
			weekdays
		};
	}
});
