import _ from 'underscore';
import transform from '../transform-before-get';

function checkValueInMask (value) {
	let result = this.mask[value];
	if (result == null) {
		result = this.missingKeyResult;
	}
	return result === true;
}

function addAllowedToFilterMask (fltr, values) {
	if (values == null) {
		console.log('no allowed');
		fltr.missingKeyResult = true;
		return;
	}

	const isArr = Array.isArray(values);
	_.each(values, (value, key) => {
		const checkValue = isArr ? value : key;
		fltr.mask[checkValue] = true;
	});

	fltr.missingKeyResult = false;
}

function addExcludedToFilterMask (fltr, values) {

	// TODO: implement and check exclude logic

}


function createValuesFilterMask (opts) {
	const { allowedValues, excludeValues, valuesFilterMask } = opts;

	if (!(allowedValues || excludeValues || valuesFilterMask)) {
		return;
	}


	const filter = {
		mask: valuesFilterMask || {},
		check: checkValueInMask
	};

	addExcludedToFilterMask(filter, excludeValues);
	addAllowedToFilterMask(filter, allowedValues);

	return filter;
}

export default function objectToArray (data, opts) {
	const reverse = opts.swapKeyAndValue;
	const skipEmpty = opts.skipEmpty;

	const filterMask = createValuesFilterMask(opts);

	const res = _.reduce(data, (memo, value, key) => {
		let id = reverse ? value : key;
		const label = reverse ? key : value;

		id = transform(id, opts.valueType);
		// console.log('=== >> ', skipEmpty, label, id, opts);
		if (skipEmpty && (label == null || label === '')) {
			return memo;
		}

		// костыль
		if (id === 'none' && (label == null || label === '')) {
			return memo;
		}

		const model = { id, value: id, label };

		if (filterMask) {
			if (!filterMask.check(model.value)) { return memo; }
			// console.log('# check', filterMask.missingKeyResult, model.value, filterMask.check(model.value));
		}

		memo.push(model);
		return memo;
	}, []);

	return res;
}
