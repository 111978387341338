import { _ } from 'vendors';
import Bb from 'backbone';
// import user from 'app/user';
// import appBus from 'bus/app';
import busData from 'bus/data';

export default (Base) => {
	const Actionable = Base.extend({
		constructor: function (...args) {
			Base.apply(this, args);
			this._actions || (this._actions = []);
			this.initActionable();
		},

		initActionable () {
			this.on('before:action:execute', this._onBeforeActionExecute);
		},
		_onBeforeActionExecute (opts = {}) {
			opts.inModal && opts.inModal.destroy();
		},
		_getProperty (name) {
			// if(this._actionsPopulated) return this._actions;
			let actions;
			if (this instanceof Bb.Model) {
				actions = _.result(this, name);
			} else if (_.isFunction(this.getProperty)) { actions = this.getProperty(name); } else { actions = this[name]; }

			actions || (actions = []);
			this._actions || (this._actions = []);
			// Array.prototype.push.apply(this._actions, actions);
			return [].concat(this._actions, actions);
			// return this._actions;
		},
		_filterActionPlace (action, options = {}) {
			// debugger;
			if (options.ignorePlace) return true;

			const placedAt = options.placedAt;
			let places = action.places;
			if (_.isString(places)) { places = places.split(/\s*,\s*/gmi); }
			let disallowPlaces = action.disallowPlaces;
			if (_.isString(disallowPlaces)) { disallowPlaces = disallowPlaces.split(/\s*,\s*/gmi); }

			const noPlaces = !places || !places.length;
			const noDisallowPlaces = !disallowPlaces || !disallowPlaces.length;

			if (noPlaces && noDisallowPlaces) return true;

			// if (!!places && !(places instanceof Array))
			// 	places = [places];
			// if (!!disallowPlaces && !(disallowPlaces instanceof Array))
			// 	disallowPlaces = [disallowPlaces];

			if (disallowPlaces && !!placedAt && disallowPlaces.indexOf(placedAt) > -1) {
				return false;
			}

			if (placedAt && places && places.indexOf(placedAt) === -1) { return false; }

			return true;
		},
		_filterActionRule (action, options = {}) {
			return action &&
					(!action.rule ||
						(_.isFunction(action.rule) &&
							action.rule.call(this, this, action, options)
						)
					);
		},
		_filterActionRights (action, options = {}) {
			const user = busData.user();
			const check = action &&
					((action.rights &&
						_.isFunction(user.checkRights) &&
						user.checkRights(action.rights, options)) ||
					!action.rights);

			return check;
		},
		getActions (opts = {}) {
			opts.context = this;
			const actions = this._getProperty('actions') || [];
			const filtered = actions.filter(action => {

				const place = this._filterActionPlace(action, opts);
				if (!place) { return false; }

				const rule = this._filterActionRule(action, opts);
				if (!rule) { return false; }

				const rights = this._filterActionRights(action, opts);
				if (!rights) { return false; }

				if (typeof opts.filter === 'function') {
					if (!opts.filter(action)) {
						return false;
					}
				}

				if (!opts.hidden && action.hidden) {
					return false;
				}

				return true;
			});

			// const filtered = _(actions).filter((action) => {
			// 	const result = this._filterActionPlace(action, opts) &&
			// 		this._filterActionRule(action, opts) &&
			// 		this._filterActionRights(action, opts) &&
			// 		(!opts.filter || (_.isFunction(opts.filter) && opts.filter(action))) &&
			// 		(!!opts.hidden || !action.hidden);

			// 	return result;
			// });

			return _.map(filtered, action => {
				const fixed = _.clone(action);
				if (_.isFunction(fixed.label)) {
					fixed.label = fixed.label.call(this, this);
				}
				return fixed;
			});
		},
		getAction (id, opts) {
			const actions = this.getActions(opts);
			return _(actions).findWhere({ id });
		},
		executeAction (_action, ...rest) {
			const id = _.isString(_action) ? _action : _action.id;
			const exaction = this.getAction(id, { hidden: true, ignorePlace: true });
			// var action = _action.id;
			// var acts = this.getActions({ hidden:true });
			// var exaction = _(acts).findWhere({ id: action });

			const method = exaction && _.camelCase('action:' + exaction.id, { first: false });

			if (!exaction || !_.isFunction(this[method])) {
				if (!exaction) { Console.warn('action ' + id + ' on model not found'); } else { Console.warn('method ' + method + ' on model not found'); }
			} else {
				this.trigger('before:action:execute', ...rest);
				console.log('#CH:', arguments);
				return this[method](exaction, ...rest);
			}
		}

	});
	Actionable.Actionable = true;

	return Actionable;
};
