import { _ } from 'vendors';
import View from 'base/view';
// import UiItem from 'components/ui-list/item';
import CollectionView from 'base/collection-view';
import busEdit from 'bus/edit';
import Collection from 'base/collection';
import ModelProperties from 'components/ui-model-card/properties';
import h from 'components/ui-model-card/helpers';
import { RequestTypeEmployee } from 'mod/configs/models/requestResponsible';

const Item = ModelProperties.extend({

	className: 'card lined with-border request-type-responsible',
	// header:{ text: 'основное', addClass:'bg-main-dark'},

	properties: [
		h.ui('responsibleId', {
			closeButton: true,
			onCloseClick () {
				this.model.destroy();
			}
			// leftButtons: [ { class: 'move-up', icon: true }, { icon: true, class: 'move-down' }  ],
		})
	],
	onCloseClick () {
		console.log('CHPOCK');
	},
	events: {
		'click .close' () {
			console.log('CHPOCK');
		}
	}
});

const Items = CollectionView.extend({
	// className: 'ui-list',
	childView: Item,
	viewComparator: (v1, v2) => v1.model.get('order') - v2.model.get('order'),
	initialize () {
		this.collection = new Collection([], { model: RequestTypeEmployee });
		this._reinitCollection();
		this.listenTo(this.model, 'change:employees', this._reinitCollection);
	},
	_reinitCollection () {
		this.collection.reset(this.model.get('employees') || []);
	}
});

export default View.extend({
	template: _.template('<section></section><footer><button class="add add-employee">добавить сотрудника</button></footer>'),
	regions: {
		items: '> section'
	},
	onRender () {
		const view = new Items({ model: this.model });
		this.showChildView('items', view);
	},
	events: {
		'click .add-employee' () {
			busEdit.do({
				header: 'Выберите сотрудника',
				controlType: 'employeesSelect'
			}).then(id => {
				this.model.save(null, {
					url: this.model.url() + '/add-employees',
					data: JSON.stringify([id]),
					wait: true,
					method: 'POST',
					contentType: 'application/json'
				});
			});
		}
	}
});
