import { _ } from 'vendors';
import BaseView from 'base/view';
// import beh from 'behaviors';

export default BaseView.extend({
	className: 'c-dt-day',
	// behaviors:[beh.DynamicClass.extend({refreshOnViewRender: true })],
	dynamicClassName () {
		const res = [];

		if (this.isCurrent()) { res.push('current'); }

		if (this.isOutOfMonth()) { res.push('out-of-month'); }

		if (this.isHoliday()) { res.push('holiday'); }

		if (this.isSelected()) {
			res.push('selected');

			const index = this.model.collection.indexOf(this.model);

			if (!this.isSelected(this.getClone(-1)) || (index % 7 === 0)) { res.push('l'); }


			if (!this.isSelected(this.getClone(1)) || (index % 7 === 6)) { res.push('r'); }

			if (!this.isSelected(this.getClone(7))) { res.push('b'); }

			if (!this.isSelected(this.getClone(-7))) { res.push('t'); }

			if (this.isLastSelected()) { res.push('last'); }
		}

		return res.join(' ');
	},
	getClone (days) {
		const d = new Date(this.model.get('value').valueOf());

		if (!days) return days;

		d.setDate(d.getDate() + days);

		return d;
	},
	template: _.template('<%= day %>'),
	triggers: { click: 'click' },
	modelEvents: {
		'refresh' () {
			this.triggerMethod('refresh');
		}
	},
	isCurrent () { return this.model.isCurrent(); },
	isOutOfMonth () { return this.model.isOutOfMonth(); },
	isHoliday () { return this.model.isHoliday(); },
	isSelected (d) { return this.model.isSelected(d); },
	isLastSelected () { return this.model.isLastSelected(); },
	templateContext () {
		return {
			day: this.model.getDate()
		};
	}
});
