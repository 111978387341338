import { _ } from 'vendors';
import View from 'base/view';
import CollectionView from 'base/collection-view';
import Collection from 'base/collection';
import TransportRequestModel, { TransportPointModel } from '../../models/requestTransport-model';
import EditPoint from './editTransportPoint';
import ControlMixin from 'components/controls/mixin';
import { tramsformOldRequest } from '../../models/utils';
import busData from 'bus/data';

const validate = function (collection) {
	return collection.every(model => model.validate());
};

const PointsView = CollectionView.extend({
	childView: EditPoint,
	childViewOptions () {
		const cv = this;
		return {
			inCollectionView: true,
			onIsFirstInCollection () {
				return cv.children._views[0] === this;
			}
		};
	},
	initialize () {
		if (!this.collection) {
			const points = this.model.getRawPoints();
			this.collection = new Collection(points, { model: TransportPointModel });
		}
	},
	onAddOne () {
		this.collection.add({});
	},
	collectionEvents: {
		change () {
			this.validate();
		},
		add () {
			this.validate();
		},
		remove () {
			this.validate();
		}
	},
	validate () {
		const jsonData = this.collection.toJSON();
		this.model.set('points', jsonData);
		const valid = this.collection.every(model => model.validate());
		if (!valid) {
			this.trigger('control:validate:error', jsonData);
		} else {
			this.trigger('control:change', jsonData);
		}
		return valid;
	}
});

export default CollectionView.mixWith(ControlMixin).extend({
	initialize () {
		if (!this.model) {
			this._initializeModel();
		}
	},
	_initializeModel () {
		const task = this.getOption('taskModel');
		if (!task) return;
		const details = task.get('typeDetails');
		let rawModel = details;
		if (!details.points) {
			rawModel = tramsformOldRequest(details);
		}
		this.model = new TransportRequestModel(rawModel);
		return this.model;
	},
	customViews () {
		const points = new PointsView({ model: this.model });
		this._toggleLock = validate.bind(this, points.collection);
		let button = new View({
			className: 'sign-plus add-item',
			template: _.template('<i></i>'),
			tagName: 'button',
			events: {
				click () {
					points.triggerMethod('add:one');
				}
			}
		});

		if (!busData.user().checkRights({ tasks: 'createTransportRequestExtended' })) {
			button = null;
		}

		return [
			points,
			button
		];
	},
	childViewTriggers: {
		'control:validate:error': 'wrapped:control:validate:error',
		'control:change': 'wrapped:control:change'
	},
	onWrappedControlChange (data) {
		// this.model.set('points', data);

		this.triggerChange(data);
		this.toggleLock(true);
	},
	onWrappedControlValidateError () {
		// this.model.set('points', data);

		this._triggerEvent('validate:error', undefined);
		this.toggleLock(false);
	},
	onRender () {
		this.toggleLock();
	},
	toggleLock (result) {
		if (!this.getOption('toggleModalButtons')) {
			return;
		}
		if (result == null) {
			result = this._toggleLock();
		}
		if (result) {
			this.trigger('switch:resolve', 'unlock');
		} else {
			this.trigger('switch:resolve', 'lock');
		}
	}
});
