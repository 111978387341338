import View from 'base/view';
import template from './date-switcher.html';
import { listsKeys } from './model';



export default View.extend({
	className: 'card lined switcher date-switcher',
	template,
	renderOnModelChange: true,
	cssClassModifiers: [
		(m) => m.canGoBackward() ? '' : 'no-backward',
		(m) => m.get('view') in listsKeys ? 'no-controls' : ''
	],
	events: {
		'click .forward' () { this.model.executeAction('date:forward'); },
		'click .backward' () { this.model.executeAction('date:backward'); },
		'click .date' () {
			if (this.model.get('view') in listsKeys) {
				this.model.executeAction('view:change');
			} else {
				this.model.executeAction('date:change');
			}
		},
		'click .edit' () { this.model.executeAction('view:change'); }
	},
	templateContext () {
		return {
			_date: this.model.display('date'),
			_label: this.model.display('view')
		};
	}
});
