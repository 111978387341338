import { buildFilters } from 'components/filters-v2';

const _filters = {
	departmentId: {
		valueType: 'string'
	},
	productId: {
		valueType: 'string'
	},
	actorId: {
		valueType: 'string'
	},
	period: {
		label: 'период',
		valueType: 'datetime',
		range: true
	}
};

export const filters = (extend) => {
	return buildFilters(_filters, { extend });
};
