// import user from 'app/user';
import { ProductProcessesUiBlock } from './ui-block';
export default 	{
	id: 'processes',
	label: 'Процессы',
	// filter: () => user.checkRights({ productsStore: 'view' }),
	Layout: ProductProcessesUiBlock,
	children: [
		{
			id: 'closed',
			label: 'Завершенные процессы',
			// filter: () => user.checkRights({ productsStore: 'view' }),
			Layout: ProductProcessesUiBlock
		}
	]
};
