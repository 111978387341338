import BaseView from 'base/view';
import template from './layout.html';
import Entries from './entries';
// import Views from 'mod/employees/views';
// import mix from 'helpers/mix';
// import FDC from 'mixins/view/flex-dynamic-column';
// import comparator from 'helpers/comparator';
// import EntriesManager from 'components/entries-manager';

export default BaseView.extend({
	className: 'staff-emps fdc-container',
	template,
	regions: {
		content: '.content-region'
	},
	initialize () {
		this.createNested('content', {
			viewClass: Entries,
			regionTemplate: { replaceElement: true }
		});
	},
	onRender () {
		this.showContent();
	}

});
