import { _ } from 'vendors';

function tryFetchNext() {
	const disabled = this.getOption('fetchNextDisabled') === true;
	// Console.log('-fetchNextDisabled-', disabled);
	if (!_.isFunction(this.fetchNext) || disabled) return;

	const col = this.getCollection();

	if (!col.isEndOfData()) {
		this.fetchNext();
	}
}

function onFetchComplete() {
	// Console.log('-fetch-complete-', this._fetchSomeMoreItems);
	this.trigger('edges:clear');
	if (this._fetchSomeMoreItems === true) {
		delete this._fetchSomeMoreItems;
		const res = this.fetchNext();
		if (res === undefined) {
			delete this._fetchSomeMoreItems;
		}
	}
}
let counter = 0;

function onRenderChildren() {
	// console.log('### render-children ###');
	const disabled = this.getOption('fetchNextDisabled') === true;
	const eod = this.getCollection().isEndOfData();
	const notRendered = !this.isRendered();
	// console.log('>>>', eod, disabled, notRendered);
	if (eod || disabled || notRendered) { return; }

	const scrolls = this.getScrollVariants();
	const direction = this.getOption('scrollDirection');

	if ((direction === 'right' && scrolls.left) || (direction === 'bottom' && scrolls.top)) {

		this._fetchSomeMoreItems = true;
	}

	// if (this.isRendered()) { }


	counter++;
}


export default (Base) => Base.extend({


	constructor() {
		if (!this.getCollection) { this.getCollection = () => this.collection; }

		Base.apply(this, arguments);


		this.initializeFetchOnScroll();
	},
	initializeFetchOnScroll() {
		const direction = this.getOption('scrollDirection');


		this.on('scrolled:to:' + direction, _.bind(tryFetchNext, this));

		const col = this.getCollection();

		if (col) {
			this.listenTo(col, 'fetch:complete', _.bind(onFetchComplete, this));
			this.on('render:children', _.bind(onRenderChildren, this));
		}
	}

});
