import { _ } from 'vendors';
import DefaultEntriesManager from 'components/entries-manager';
import createFiltersInstance from 'components/filters/create-instance';

export default (Base) => {
	const Mixed = Base.extend({

		constructor () {
			Base.apply(this, arguments);
			this.initializeEntriesManager();
		},

		getCollection () {
			return this.collection;
		},

		_entriesManagerOptions () {
			return _.extend({
				view: this,
				entries: this.getCollection(),
				collectionPlace: 'top'
			}, this.getOption('entriesManagerOptions', { args: [this] }));
		},

		initializeEntriesManager () {
			this.initializeFiltersInstance();
			this.initializeManagerView();
		},

		initializeFiltersInstance () {
			if (!this.filtersInstance) {
				const mngOpts = this._entriesManagerOptions();
				if (!mngOpts.filtersOptions) return;
				const opts = _.extend({}, mngOpts.filtersOptions, {
					view: this,
					entries: this.getCollection()
				});
				const instance = createFiltersInstance(opts);
				this.filtersInstance = instance;

				this.once('before:destroy', () => this.filtersInstance.destroy());
			} else {
				this.filtersInstance.setView(this);
			}
		},

		getEntriesManagerClass () {
			return this.getOption('entriesManager') || DefaultEntriesManager;
		},

		initializeManagerView () {
			this.customViews || (this.customViews = []);
			const create = () => this.buildEntriesManagerView();
			// let EntriesManager = this.getEntriesManagerClass();
			// {
			// 	new EntriesManager(this.buildEntriesManagerOptions())
			// };

			// this.customViews.unshift(create);
			this.addCustomView(create);
			console.warn('-entries manager initialized-')
		},

		buildEntriesManagerOptions () {
			const opts = this._entriesManagerOptions();
			if (opts.infoOptions) {
				if (!_.isObject(opts.infoOptions)) { opts.infoOptions = {}; }

				opts.infoOptions.model = this.getCollection().result;
			}
			if (opts.filtersOptions) {
				opts.filtersOptions.instance = this.filtersInstance;
			}
			return opts;
		},

		buildEntriesManagerView () {
			const EntriesManager = this.getEntriesManagerClass();
			const options = this.buildEntriesManagerOptions();
			const view = new EntriesManager(options);
			return view;
		},

		fetchNext () {
			const col = this.getCollection();
			if (col.fetchingInProcess) return;
			const res = col.getResultJSON();
			if (res.endOfData) return;
			const take = 50;
			const page = {
				'result.skip': (res.skiped || 0) + (res.returned || take),
				'result.take': take
			};
			return this.fetch(page, { remove: false });
		},

		getFetchData (fetchData) {
			return _.extend({}, this.filtersInstance && this.filtersInstance.toUrl(), fetchData);
		},
		getFetchOptions (fetchData, opts = { }) {
			opts.parse = true;
			return Base.prototype.getFetchOptions.call(this, fetchData, opts);
		}

	});

	return Mixed;
};
